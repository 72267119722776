import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {approveIncomingAch, IncomingAch} from "../../resources/incomingAch"
import {AddToast} from "react-toast-notifications"
import {Location, NavigateFunction} from "react-router-dom"

const modalExitDelay = 1000

interface ApproveIncomingAchModalProps {
	incomingAch: IncomingAch
	close: () => void
	onSuccess: () => void
	addToast: AddToast
	navigate: NavigateFunction
	location: Location
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

function closeAndExit(close: () => void, addToast: AddToast, navigate: NavigateFunction, location: Location) {
	const path = location?.state?.previousPage || "/incoming-achs"
	return function () {
		setTimeout(() => {
			close()
			addToast("ACH Approved", {appearance: "success"})
			navigate && navigate(path)
		}, modalExitDelay)
	}
}

export function ApproveIncomingAchModal({
	incomingAch,
	close,
	onSuccess,
	addToast,
	navigate,
	location,
}: ApproveIncomingAchModalProps) {
	const accessToken = useAccessToken()
	const [state, approve] = useAsyncResultIdle(approveIncomingAch)

	return (
		<AsyncResultModal
			title="Approve ACH"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSuccess={closeAndExit(close, addToast, navigate, location)}
			onSubmit={() => approve(accessToken, incomingAch.id)}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure?
		</AsyncResultModal>
	)
}
