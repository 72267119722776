import React from "react"
import {AsyncResult, AsyncResultRequestState} from "../../types/asyncResult"
import Skeleton from "react-loading-skeleton"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../MainSection/MainSection"
import {ErrorDocument} from "../../resources/common"
import {Err} from "../../components/Err/Err"

interface AsyncResultProps<T, E> {
	children(props: {value: T}): JSX.Element
	pendingComponent?: JSX.Element
	asyncResult: AsyncResultRequestState<T, E>
	[x: string]: any
}

interface AsyncResultZipProps<T1, T2, E> {
	children(props: {value1: T1; value2: T2}): JSX.Element
	pendingComponent?: JSX.Element
	asyncResult1: AsyncResultRequestState<T1, E>
	asyncResult2: AsyncResultRequestState<T2, E>
	[x: string]: any
}

function Pending() {
	return (
		<>
			<TitleBar>
				<Skeleton />
			</TitleBar>
			<MainSection>
				<div className="box">
					<table className="table is-hoverable is-fullwidth is-clickable">
						<thead>
							<tr>
								<th>
									<Skeleton />
								</th>
								<th>
									<Skeleton />
								</th>
								<th>
									<Skeleton />
								</th>
								<th>
									<Skeleton />
								</th>
								<th>
									<Skeleton />
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
							<tr>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
						</tbody>
					</table>
					<nav className="pagination" role="navigation" aria-label="pagination">
						<button className="pagination-previous button">
							<Skeleton />
						</button>
						<button className="pagination-next button">
							<Skeleton />
						</button>
						<ul className="pagination-list"></ul>
					</nav>
				</div>
			</MainSection>
		</>
	)
}

export function AsyncResultComponent<T>({
	asyncResult,
	children,
	pendingComponent = <Pending />,
	...rest
}: AsyncResultProps<T, ErrorDocument>) {
	return asyncResult.match(
		() => pendingComponent,
		(value) => children({value, ...rest}),
		(err) => <Err err={err} />
	)
}

export function AsyncResultZipComponent<T1, T2>({
	asyncResult1,
	asyncResult2,
	children,
	pendingComponent = <Pending />,
	...rest
}: AsyncResultZipProps<T1, T2, ErrorDocument>) {
	return AsyncResult.zip(asyncResult1, asyncResult2).match(
		() => pendingComponent,
		([value1, value2]) => children({value1, value2, ...rest}),
		(err) => <Err err={err} />
	)
}
