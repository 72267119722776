import React, {useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Account} from "../../resources/account"
import {useAccessToken} from "../../services/auth"
import {createAdjustment} from "../../resources/adjustment"
import {toCents, toDollars} from "../../utilities/numbers"

interface CreateAdjustmentModalProps {
	account: Account
	closeModal: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function CreateAdjustmentModal({account, closeModal, onSuccess}: CreateAdjustmentModalProps) {
	const accessToken = useAccessToken()
	const [amount, setAmount] = useState(0)
	const [direction, setDirection] = useState("Debit")
	const [description, setDescription] = useState("")
	const [internalNote, setInternalNote] = useState("")
	const [state, create] = useAsyncResultIdle(createAdjustment)

	return (
		<AsyncResultModal
			title="Create Account Adjustment"
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-success"
			state={state}
			buttonText="Create"
			successText="Created"
			onSubmit={() => create(accessToken, account.id, amount, direction, description, internalNote)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Direction</label>
				<div className="control">
					<div className="select">
						<select onChange={(e) => setDirection(e.target.value)} value={direction}>
							<option value="Debit">Debit</option>
							<option value="Credit">Credit</option>
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Amount $</label>
				<div className="control">
					<input
						className="input"
						type="number"
						value={toDollars(amount)}
						step=".01"
						required
						onChange={(e) => setAmount(toCents(e.target.value))}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Description</label>
				<div className="control">
					<textarea
						className="textarea is-static has-fixed-size"
						value={description ?? ""}
						required
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Internal Note</label>
				<div className="control">
					<textarea
						className="textarea is-static has-fixed-size"
						value={internalNote ?? ""}
						required
						onChange={(e) => setInternalNote(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
