import React from "react"
import {AnnualIncome, AnnualIncomeMapping} from "../../resources/application"
import {Editable, EditableDropDownField} from "../HorizontalField/EditableHorizontalField"

export interface AnnualIncomeProps {
	annualIncome?: AnnualIncome
	setAnnualIncome: (value: AnnualIncome) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: AnnualIncome) => void
	className?: string
}

export function EditableAnnualIncomeField(props: AnnualIncomeProps) {
	if (props.annualIncome) {
		return (
			<Editable
				label="Annual Income"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setAnnualIncome}
				value={props.annualIncome}
				initial={props.annualIncome}
				update={props.update}
				className={props.className}
				possibleValues={AnnualIncomeMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}
	return null
}
