import BigNumber from "bignumber.js"
import numeral from "numeral"

export function toDollars(amount: number) {
	const n = new BigNumber(amount).div(100)
	return n.toFixed(2)
}

export function toCents(amount: string) {
	const n = new BigNumber(amount).multipliedBy(100)
	return n.toNumber()
}

export function toShortNumberal(amount: number) {
	return amount > 1200000 ? numeral(amount).format("$0,0.00a") : numeral(amount).format("$0,0.00")
}

export function toShortDollars(amount: number, options = {optionalDecimalPoint: true}) {
	const dollars = toDollars(amount)
	if (options?.optionalDecimalPoint) {
		return numeral(dollars).format("$111[.]0a", Math.floor)
	}

	return numeral(dollars).format("$111.0a", Math.floor)
}

export function toLongDollars(
	amount: number,
	options: {
		decimalPoint: "optional" | boolean
	} = {decimalPoint: "optional"}
) {
	const dollars = toDollars(amount)

	if (options?.decimalPoint) {
		if (options?.decimalPoint === "optional") {
			return numeral(dollars).format("$111,111,111[.]11")
		}

		return numeral(dollars).format("$111,111,111.11")
	}

	return numeral(dollars).format("$111,111,111")
}

export function div(value: number, total: number) {
	return new BigNumber(value).div(total).toNumber()
}

export function mul(a: number, b: number) {
	return new BigNumber(a).multipliedBy(b).toNumber()
}

export function calcPercent(value: number, total: number, floor = true) {
	let percent = 100
	if (total) {
		percent = mul(div(value, total), 100)
	}
	if (floor) {
		percent = Math.floor(percent)
	}

	return percent
}

export function formatPercent(percent: number, format = "111%") {
	return numeral(div(percent, 100)).format(format)
}
