import {getResource} from "./common"

export type BankRouting = {
	type: "bankRouting"
	id: string
	attributes: {
		createdAt: Date
		name: string
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export async function getBankRouting(accessToken: string, bankRoutingId: string) {
	const result = await getResource<BankRouting>(`bank-routings/${bankRoutingId}`, accessToken)

	return result.map((v) => v.data)
}

export async function findBankRoutings(accessToken: string) {
	const result = await getResource<Array<BankRouting>>("bank-routings", accessToken)

	return result.map((v) => v.data)
}
