import React from "react"
import {useParams} from "react-router-dom"
import {OrgAuthProvider, useCustomerAccessToken} from "../../services/orgAuth"
import {AuthorizationComponent} from "../Authorization/Authorization"

export function Internal({authorizationId}: {authorizationId: string}) {
	const accessToken = useCustomerAccessToken()

	return <AuthorizationComponent authorizationId={authorizationId} accessToken={accessToken} />
}

export default function OrgAuthorizationPage() {
	const {authorizationId = ""} = useParams<{authorizationId: string}>()

	return (
		<OrgAuthProvider>
			<Internal authorizationId={authorizationId} />
		</OrgAuthProvider>
	)
}
