import {useAccessToken, useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import {
	ApplicationDocument,
	ApplicationType,
	ApplicationTypeIcons,
	BusinessApplication,
	evaluateApplicationLocation,
	EvaluationOutcome,
	hasAdditionalInformation,
	isApplicationDocument,
	updateBusinessApplication,
} from "../../resources/application"
import React, {useEffect, useState} from "react"
import TitleBar, {MetaItemProps, TitleBarAction} from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import {CreatedAtTag, EvaluationOutcomeTag, ApplicationStatusTag, Tags, UpdatedAtTag} from "../../components/Tag"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import {
	Editable,
	EditableAddress,
	EditableDate,
	EditablePhone,
	EditableHiddenString,
	EditableFullName,
	EditableString,
	EditableOptionalString,
	OptionalEditable,
	EditableYear,
} from "../../components/HorizontalField/EditableHorizontalField"
import ApplicationDocuments from "../../components/ApplicationDocuments/ApplicationDocuments"
import BeneficialOwners from "../../components/BeneficialOwners/BeneficialOwners"
import moment from "moment"
import {useModal} from "react-modal-hook"
import {UnitOrBankUserOnly, UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {
	DenyApplicationModal,
	ApproveApplicationModal,
	CancelApplicationModal,
} from "../../components/Applications/ApplicationModals"
import {OkDocument, Resource} from "../../resources/common"
import LinkButton from "../../components/LinkButton/LinkButton"
import ApplicationDecision from "../../components/ApplicationDecision/ApplicationDecision"
import {emailPreview} from "../../components/InformationHiding/InformationHiding"
import TagsViewer from "../../components/Tags/TagsViewer"
import {EntityEvaluation, EvaluationFlags} from "../../components/EvaluationFlags/EvaluationFlags"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {isUndefined} from "lodash"
import {stateRegex} from "../../utilities/validation"
import {BusinessRequestDocumentModal} from "../../components/RequestDocumentModal/RequestDocumentModal"
import IdentificationNumber, {getDocumentTitle} from "../../components/IdentificationNumber/IdentificationNumber"
import {BeneficialOwnerResource, isBeneficialOwner} from "../../resources/beneficialOwner"
import {OrgName} from "../../components/OrgName/OrgName"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import Icon from "../../components/Icon/Icon"
import {EditableBusinessVerticalField} from "../../components/ApplicationAdditionalInformationFields/BusinessVerticalField"
import {EditableOccupationField} from "../../components/ApplicationAdditionalInformationFields/OccupationField"
import {EditableAnnualIncomeField} from "../../components/ApplicationAdditionalInformationFields/AnnualIncomeField"
import {EditableSourceOfIncomeField} from "../../components/ApplicationAdditionalInformationFields/SourceOfIncomeField"
import {EditableBusinessAnnualRevenueField} from "../../components/ApplicationAdditionalInformationFields/AnnualRevenueField"
import {EditableCashFlowField} from "../../components/ApplicationAdditionalInformationFields/CashFlowField"
import {EditableBusinessNumberOfEmployeesField} from "../../components/ApplicationAdditionalInformationFields/NumberOfEmployeesField"
import {Accordion} from "../../components/Accordion/Accordion"
import {CountriesOfOperation} from "../../components/ApplicationAdditionalInformationFields/CountriesOfOperation"
import {EditableWebsiteField} from "../../components/EditableWebsiteField/EditableWebsiteField"
import {EditableBeneficialOwnerAdditionalInformation} from "../../components/BeneficialOwnerAdditionalInformation/EditableBeneficialOwnerAdditionalInformation"
import {isOrg, Org} from "../../resources/org"
import {getAuditLogEventsHeaderMetaItems} from "../Applications/auditLogMetaItems"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"

function BusinessApplicationInternal({
	business,
	refresh,
	documents,
	beneficialOwners,
	customEvaluationFlags,
	included,
}: {
	business: BusinessApplication
	documents: ApplicationDocument[]
	beneficialOwners: BeneficialOwnerResource[]
	refresh: () => void
	customEvaluationFlags: string[]
	included?: Resource[]
}) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [showApprove, hideApprove] = useModal(() => (
		<ApproveApplicationModal close={hideApprove} application={business} onSuccess={refresh} />
	))

	const [showDeny, hideDeny] = useModal(() => (
		<DenyApplicationModal close={hideDeny} application={business} onSuccess={refresh} accessToken={accessToken} />
	))

	const [showCancel, hideCancel] = useModal(() => (
		<CancelApplicationModal close={hideCancel} application={business} onSuccess={refresh} />
	))

	const [showRequestDocument, hideRequestDocument] = useModal(() => (
		<BusinessRequestDocumentModal
			application={business}
			beneficialOwners={beneficialOwners}
			close={hideRequestDocument}
			accessToken={accessToken}
			onSuccess={refresh}
		/>
	))

	const {attributes} = business
	const {officer, contact} = attributes

	const officerDocumentTitle = getDocumentTitle(
		officer.ssn,
		officer.maskedSSN,
		officer.passport,
		officer.maskedPassport,
		officer.matriculaConsular,
		officer.maskedMatriculaConsular
	)

	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)
	const [address, setAddress] = useState(attributes.address)
	const [phone, setPhone] = useState(attributes.phone)
	const [ein, setEin] = useState(attributes.ein)
	const [stateOfIncorporation, setStateOfIncorporation] = useState(attributes.stateOfIncorporation)
	const [dba, setDba] = useState(attributes.dba)
	const [name, setName] = useState(attributes.name)
	const [contactName, setContactName] = useState(contact.fullName)
	const [contactEmail, setContactEmail] = useState(contact.email)
	const [contactPhone, setContactPhone] = useState(contact.phone)
	const [website, setWebsite] = useState(attributes.website)

	const [officerName, setOfficerName] = useState(officer.fullName)
	const [officerDob, setOfficerDob] = useState(officer.dateOfBirth ? moment(officer.dateOfBirth).toDate() : undefined)
	const [officerAddress, setOfficerAddress] = useState(officer.address)
	const [officerPhone, setOfficerPhone] = useState(officer.phone)
	const [officerEmail, setOfficerEmail] = useState(officer.email)
	const [officerOccupation, setOfficerOccupation] = useState(officer.occupation)
	const [officerAnnualIncome, setOfficerAnnualIncome] = useState(officer.annualIncome)
	const [officerSourceOfIncome, setOfficerSourceOfIncome] = useState(officer.sourceOfIncome)

	const [updateState, updateApp] = useAsyncResultIdle(updateBusinessApplication)
	const [annualRevenue, setAnnualRevenue] = useState(attributes.annualRevenue)
	const [numberOfEmployees, setNumberOfEmployees] = useState(attributes.numberOfEmployees)
	const [cashFlow, setCashFlow] = useState(attributes.cashFlow)
	const [yearOfIncorporation, setYearOfIncorporation] = useState(attributes.yearOfIncorporation)
	const countriesOfOperation = attributes.countriesOfOperation
	const [businessVertical, setBusinessVertical] = useState(attributes.businessVertical)

	const archived = attributes.archived
	const isApplicationEditable = !archived && attributes.status != "Canceled"
	const isHiddenIconVisible = !useIsUnitComplianceUser()
	const canCancel = attributes.status !== "Approved" && attributes.status !== "Denied" && isApplicationEditable
	const dbaComponent = useIsUnitUser() ? (
		<Editable
			label="Doing Business As"
			isEditing={isEditing === "DBA"}
			isDisabled={!isUndefined(isEditing)}
			isAllowed={useIsUnitUser() && isApplicationEditable}
			isUpdating={isUpdating}
			onStartEdit={() => setIsEditing("DBA")}
			onCancel={refresh}
			setValue={setDba}
			value={dba}
			initial={dba}
			update={() => {
				updateApp(accessToken, business, {dba: dba})
				setIsUpdating(true)
			}}
			pattern={"^.*$"}
			className={attributes.archived ? "archived-field" : ""}
		>
			{EditableOptionalString}
		</Editable>
	) : null

	let boStatus = EvaluationOutcome.Approved

	beneficialOwners.filter(isBeneficialOwner).forEach((bo) => {
		if (boStatus === EvaluationOutcome.Denied) return

		switch (bo.attributes.status) {
			case EvaluationOutcome.Approved:
				break
			case EvaluationOutcome.Denied:
				boStatus = EvaluationOutcome.Denied
				break
			case EvaluationOutcome.PendingReview:
				boStatus = EvaluationOutcome.PendingReview
				break
		}
	})

	const canApprove = attributes.status !== "Approved" && isApplicationEditable
	const canDeny = attributes.status !== "Approved" && attributes.status !== "Denied" && isApplicationEditable

	const org = included?.find((r) => isOrg(r) && r.id == business.relationships.org.data.id)

	const auditLogEventsHeaderMetaItems = getAuditLogEventsHeaderMetaItems({
		applicationName: name,
		applicationType: ApplicationType.business,
		pageIcon: ApplicationTypeIcons[ApplicationType.business],
		org: org as Org,
	})

	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()

	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Application Updated Successfully", {appearance: "success"})
			setIsUpdating(false)
			refresh()
		} else if (updateState.isErr()) {
			addToast(updateState.error.errors[0].detail || updateState.error.errors[0].title, {appearance: "error"})
			setIsUpdating(false)
			refresh()
		}
	}, [updateState])

	const customerLink = business.relationships.customer
		? `/customers/business/${business.relationships.customer.data.id}`
		: undefined

	return (
		<>
			<TitleBar
				title={attributes.name}
				breadcrumbs={[{text: "Applications", path: "/applications/"}]}
				meta={[
					{text: "Business", icon: "toolbox"},
					...(customerLink
						? [
								{
									text: "Customer",
									icon: "user-geometric-action-money-fund---users",
									path: customerLink,
								} as MetaItemProps,
						  ]
						: []),
				]}
				status={attributes.archived ? {text: "Archived", color: "#B0B2B5", backgroundColor: "#EBECEE"} : undefined}
			>
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				<TitleBarAction>
					<HasPermission resource="application.status.cancel" action="update">
						{canCancel && (
							<button className="button button-cancel mr-0 is-flex is-align-items-center" onClick={showCancel}>
								<Icon icon={"user-geometric-action-cross-remove"} size={16} />
								<span>Cancel Application</span>
							</button>
						)}
					</HasPermission>
				</TitleBarAction>
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="application"
					resourceId={business.id}
				/>
				<div className="columns is-mobile">
					<div className="column is-narrow">
						<div className="buttons">
							{business.attributes.evaluationEntityId ? (
								<LinkButton to={`https://app.alloy.co/entities/${business.attributes.evaluationEntityId}`}>
									Alloy
								</LinkButton>
							) : null}
						</div>
					</div>
					<div className="column">
						<UnitUserOnly>
							<div className="buttons is-right">
								{attributes.status == "AwaitingDocuments" || attributes.status == "PendingReview" ? (
									<button
										className="button is-info"
										disabled={attributes.status !== "AwaitingDocuments" && attributes.status !== "PendingReview"}
										onClick={() => showRequestDocument()}
									>
										Request Documents
									</button>
								) : null}
								<button className="button is-danger" disabled={!canDeny} onClick={() => showDeny()}>
									Deny
								</button>

								<button className="button is-success" disabled={!canApprove} onClick={() => showApprove()}>
									Approve
								</button>
							</div>
						</UnitUserOnly>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<Tags>
							<CreatedAtTag createdAt={attributes.createdAt} />
							<UpdatedAtTag updatedAt={attributes.updatedAt} />
							<ApplicationStatusTag status={attributes.status} />
							<UnitUserOnly>
								<EvaluationOutcomeTag value={attributes.evaluationOutcome} label="Business Evaluation" />
								<EvaluationOutcomeTag value={officer.status} label="Officer Evaluation" />
								<EvaluationOutcomeTag value={boStatus} label="Beneficial Owners Evaluation" />
							</UnitUserOnly>
						</Tags>
					</div>
				</div>

				<div className="columns">
					<div className="column is-3">
						<div className="columns">
							<div className="column">
								<ApplicationDecision
									application={business}
									fieldClassName={attributes.archived ? "archived-field" : ""}
								/>
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Contact</p>
									</div>
									<div className="card-content">
										<Editable
											label="Name"
											isEditing={isEditing === "Contact Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Contact Name")}
											onCancel={refresh}
											setValue={setContactName}
											value={contactName}
											initial={contactName}
											update={() => {
												updateApp(accessToken, business, {contactName: contactName})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableFullName}
										</Editable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Contact Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Contact Phone")}
											onCancel={refresh}
											setValue={setContactPhone}
											value={contactPhone}
											initial={contactPhone}
											update={() => {
												updateApp(accessToken, business, {contactPhone: contactPhone})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										{optionalHorizontalField(
											"Phone Location ",
											attributes.phoneLocationDetails
												? Object.values(attributes.phoneLocationDetails)
														.filter((a) => a)
														.join(", ")
												: undefined,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										{optionalHorizontalField(
											"IP Address",
											attributes.ip,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										{optionalHorizontalField(
											"IP Location",
											attributes.ipLocationDetails
												? Object.values(attributes.ipLocationDetails)
														.filter((a) => a)
														.join(", ")
												: undefined,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										<OptionalEditable
											label="Email"
											isEditing={isEditing === "Contact Email"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Contact Email")}
											onCancel={refresh}
											setValue={setContactEmail}
											value={contactEmail}
											initial={contactEmail}
											update={() => {
												updateApp(accessToken, business, {contactEmail: contactEmail})
												setIsUpdating(true)
											}}
											inputType="email"
											previewFunction={emailPreview}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
									</div>
								</div>
							</div>
						</div>

						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Business Information</p>
									</div>
									<div className="card-content">
										<UnitOrBankUserOnly>
											<HorizontalField label="Org">
												<OrgName orgId={business.relationships.org.data.id} included={included} element={"span"} />
											</HorizontalField>
										</UnitOrBankUserOnly>
										{business.relationships.customer ? (
											<HorizontalField label="Customer Id" fieldClassName={attributes.archived ? "archived-field" : ""}>
												<input
													type="text"
													readOnly
													value={business.relationships.customer.data.id}
													className="input is-static"
												/>
											</HorizontalField>
										) : null}

										<HorizontalField label="Application Id">
											<input type="text" className="input is-static" readOnly value={business.id} />
										</HorizontalField>
										<Editable
											label="Name"
											isEditing={isEditing === "Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Name")}
											onCancel={refresh}
											setValue={setName}
											value={name}
											initial={name}
											update={() => {
												updateApp(accessToken, business, {name: name})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>
										{dbaComponent}
										{attributes.entityType ? (
											<HorizontalField label="Entity Type" fieldClassName={attributes.archived ? "archived-field" : ""}>
												<input type="text" readOnly value={attributes.entityType} className="input is-static" />
											</HorizontalField>
										) : undefined}
										<OptionalEditable
											label="EIN"
											isEditing={isEditing === "EIN"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("EIN")}
											onCancel={refresh}
											setValue={setEin}
											value={ein}
											initial={ein}
											update={() => {
												updateApp(accessToken, business, {ein: ein})
												setIsUpdating(true)
											}}
											pattern="^\d{9}$"
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
										<Editable
											label="Incorporation State"
											isEditing={isEditing === "Incorporation State"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Incorporation State")}
											onCancel={refresh}
											setValue={setStateOfIncorporation}
											value={stateOfIncorporation}
											initial={stateOfIncorporation}
											update={() => {
												updateApp(accessToken, business, {stateOfIncorporation: stateOfIncorporation})
												setIsUpdating(true)
											}}
											pattern={stateRegex}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Phone")}
											onCancel={refresh}
											setValue={setPhone}
											value={phone}
											initial={phone}
											update={() => {
												updateApp(accessToken, business, {phone: phone})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										<EditableWebsiteField
											website={website}
											setWebsite={setWebsite}
											isEditing={isEditing === "Website"}
											onStartEdit={() => setIsEditing("Website")}
											onCancel={refresh}
											update={(v) => {
												updateApp(accessToken, business, {website: v})
												setIsUpdating(true)
											}}
											isArchived={archived}
											isDisabled={!isUndefined(isEditing)}
											isUpdating={isUpdating}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											hasNoWebsite={attributes.hasNoWebsite}
										/>
										<OptionalEditable
											label="Address"
											isEditing={isEditing === "Address"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Address")}
											onCancel={refresh}
											setValue={setAddress}
											value={address}
											initial={address}
											update={(v) => {
												updateApp(accessToken, business, {address: v})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableAddress}
										</OptionalEditable>
										<Accordion title={"Additional Information"} theme="light" noContentPadding>
											<EditableBusinessVerticalField
												businessVertical={businessVertical}
												setBusinessVertical={setBusinessVertical}
												isEditing={isEditing === "BusinessVertical"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={useIsUnitUser() && isApplicationEditable}
												onStartEdit={() => setIsEditing("BusinessVertical")}
												onCancel={refresh}
												update={(v) => {
													updateApp(accessToken, business, {businessVertical: v})
													setIsUpdating(true)
												}}
												className={attributes.archived ? "archived-field" : ""}
											/>
											<OptionalEditable
												label="Year of Incorporation"
												isEditing={isEditing === "YearOfIncorporation"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={useIsUnitUser() && isApplicationEditable}
												onStartEdit={() => setIsEditing("YearOfIncorporation")}
												onCancel={refresh}
												setValue={setYearOfIncorporation}
												value={yearOfIncorporation}
												initial={yearOfIncorporation}
												update={(v) => {
													updateApp(accessToken, business, {yearOfIncorporation: v})
													setIsUpdating(true)
												}}
											>
												{EditableYear}
											</OptionalEditable>
											<EditableBusinessAnnualRevenueField
												annualRevenue={annualRevenue}
												setAnnualRevenue={setAnnualRevenue}
												isEditing={isEditing === "AnnualRevenue"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={useIsUnitUser() && isApplicationEditable}
												onStartEdit={() => setIsEditing("AnnualRevenue")}
												onCancel={refresh}
												update={(v) => {
													updateApp(accessToken, business, {annualRevenue: v})
													setIsUpdating(true)
												}}
												className={attributes.archived ? "archived-field" : ""}
											/>

											<EditableCashFlowField
												cashFlow={cashFlow}
												setCashFlow={setCashFlow}
												isEditing={isEditing === "CashFlow"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={useIsUnitUser() && isApplicationEditable}
												onStartEdit={() => setIsEditing("CashFlow")}
												onCancel={refresh}
												update={(v) => {
													updateApp(accessToken, business, {cashFlow: v})
													setIsUpdating(true)
												}}
												className={attributes.archived ? "archived-field" : ""}
											/>

											<CountriesOfOperation countriesOfOperation={countriesOfOperation} isArchived={archived} />

											<EditableBusinessNumberOfEmployeesField
												numberOfEmployees={numberOfEmployees}
												setNumberOfEmployees={setNumberOfEmployees}
												isEditing={isEditing === "NumberOfEmployees"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={useIsUnitUser() && isApplicationEditable}
												onStartEdit={() => setIsEditing("NumberOfEmployees")}
												onCancel={refresh}
												update={(v) => {
													updateApp(accessToken, business, {numberOfEmployees: v})
													setIsUpdating(true)
												}}
												className={attributes.archived ? "archived-field" : ""}
											/>
											{beneficialOwners.some((bo) => hasAdditionalInformation(bo.attributes)) ? (
												<div key={"beneficialOwnerInformation"} className={"beneficial-owners-more-info-container"}>
													<div>Beneficial Owners</div>
													{beneficialOwners.map((bo) => (
														<EditableBeneficialOwnerAdditionalInformation
															key={bo.id}
															beneficialOwner={bo}
															setIsEditing={setIsEditing}
															isEditing={isEditing}
															refresh={refresh}
															editingDisabled={false}
															setIsUpdating={setIsUpdating}
															isApplicationEditable={isApplicationEditable}
														/>
													))}
												</div>
											) : null}
										</Accordion>
									</div>
								</div>
							</div>
						</div>

						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Officer Information</p>
									</div>
									<div className="card-content">
										<Editable
											label="Name"
											isEditing={isEditing === "Officer Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Officer Name")}
											onCancel={refresh}
											setValue={setOfficerName}
											value={officerName}
											initial={officerName}
											update={() => {
												updateApp(accessToken, business, {officerName: officerName})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableFullName}
										</Editable>
										{optionalHorizontalField(
											"Title",
											officer.title,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										<IdentificationNumber
											data={{
												initialSsn: officer.ssn ?? officer.maskedSSN ?? "",
												initialPassport: [officer.nationality ?? "", officer.passport ?? officer.maskedPassport ?? ""],
												initialMatriculaConsular: officer.matriculaConsular ?? officer.maskedMatriculaConsular ?? "",

												documentTitle: officerDocumentTitle,
												archived,
											}}
											state={{
												isEditing,
												isEditable: isApplicationEditable,
												isUpdating,
												isHiddenIconVisible,
											}}
											actions={{
												setIsEditing,
												refresh,
												updateSSN: (ssn: string) => updateApp(accessToken, business, {officerSSN: ssn}),
												updatePassport: (passport: [string, string]) =>
													updateApp(accessToken, business, {
														officerPassport: passport[1],
														officerNationality: passport[0],
													}),
												updateMatriculaConsular: (matriculaConsular: string) =>
													updateApp(accessToken, business, {officerMatriculaConsular: matriculaConsular}),

												setIsUpdating,
											}}
										/>
										<OptionalEditable
											label="Birth Date"
											isEditing={isEditing === "Officer Birth Date"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Officer Birth Date")}
											onCancel={refresh}
											setValue={setOfficerDob}
											value={officerDob}
											initial={officerDob}
											update={(v) => {
												updateApp(accessToken, business, {officerDob: v})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableDate}
										</OptionalEditable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Officer Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Officer Phone")}
											onCancel={refresh}
											setValue={setOfficerPhone}
											value={officerPhone}
											initial={officerPhone}
											update={() => {
												updateApp(accessToken, business, {officerPhone: officerPhone})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										<OptionalEditable
											label="Email"
											isEditing={isEditing === "Officer Email"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Officer Email")}
											onCancel={refresh}
											setValue={setOfficerEmail}
											value={officerEmail}
											initial={officerEmail}
											update={() => {
												updateApp(accessToken, business, {officerEmail: officerEmail})
												setIsUpdating(true)
											}}
											inputType="email"
											previewFunction={emailPreview}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
										<OptionalEditable
											label="Address"
											isEditing={isEditing === "Address"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Address")}
											onCancel={refresh}
											setValue={setOfficerAddress}
											value={officerAddress}
											initial={officerAddress}
											update={(v) => {
												updateApp(accessToken, business, {officerAddress: v})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableAddress}
										</OptionalEditable>
										{hasAdditionalInformation(officer) ? (
											<Accordion title={"Additional Information"} theme={"light"} noContentPadding={true}>
												<div>
													<EditableOccupationField
														occupation={officerOccupation}
														setOccupation={setOfficerOccupation}
														isEditing={isEditing === "Occupation"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={useIsUnitUser() && isApplicationEditable}
														onStartEdit={() => setIsEditing("Occupation")}
														onCancel={refresh}
														update={(v) => {
															updateApp(accessToken, business, {officerOccupation: v})
															setIsUpdating(true)
														}}
													/>
													<EditableAnnualIncomeField
														annualIncome={officerAnnualIncome}
														setAnnualIncome={setOfficerAnnualIncome}
														isEditing={isEditing === "OfficerAnnualIncome"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={useIsUnitUser() && isApplicationEditable}
														onStartEdit={() => setIsEditing("OfficerAnnualIncome")}
														onCancel={refresh}
														update={(v) => {
															updateApp(accessToken, business, {officerAnnualIncome: v})
															setIsUpdating(true)
														}}
													/>

													<EditableSourceOfIncomeField
														sourceOfIncome={officerSourceOfIncome}
														setSourceOfIncome={setOfficerSourceOfIncome}
														isEditing={isEditing === "OfficerSourceOfIncome"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={useIsUnitUser() && isApplicationEditable}
														onStartEdit={() => setIsEditing("OfficerSourceOfIncome")}
														onCancel={refresh}
														update={(v) => {
															updateApp(accessToken, business, {officerSourceOfIncome: v})
															setIsUpdating(true)
														}}
													/>
												</div>
											</Accordion>
										) : null}
									</div>
								</div>
							</div>
						</div>
						{Object.keys(attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={attributes.tags} fieldClassName={attributes.archived ? "archived-field" : ""} />
										</div>
									</div>
								</div>
							</div>
						) : null}
					</div>
					<div className="column">
						<div className="card">
							<div className="card-header">
								<p className="card-header-title">Evaluation Flags</p>
							</div>
							<div className="card-content grid-card-content">
								<EvaluationFlags title="Business" flags={attributes.evaluationFlags?.concat(customEvaluationFlags)} />
								<EntityEvaluation
									title="Officer"
									flags={attributes.officer.evaluationFlags}
									idTheftScore={officer.idTheftScore}
								/>
								{beneficialOwners.map((bo) => (
									<EntityEvaluation
										key={bo.attributes.fullName.first + bo.attributes.fullName.last}
										title={`Beneficial Owner - ${bo.attributes.fullName.first} ${bo.attributes.fullName.last}`}
										flags={bo.attributes.evaluationFlags}
										idTheftScore={bo.attributes.idTheftScore}
									/>
								))}
							</div>
						</div>

						<div className="columns">
							<div className="column">
								<ApplicationDocuments application={business} documents={documents} refresh={refresh} />
							</div>
						</div>
						<div className="columns">
							<div className="column">
								<BeneficialOwners refresh={refresh} beneficialOwners={beneficialOwners} />
							</div>
						</div>
					</div>
				</div>
			</MainSection>
		</>
	)
}

export default function BusinessApplicationPage({
	value,
	refresh,
}: {
	value: OkDocument<BusinessApplication>
	refresh: () => void
}) {
	const customEvaluationFlags: string[] = []
	if (value.data.attributes.phoneLocationDetails && value.data.attributes.address) {
		customEvaluationFlags.push(
			evaluateApplicationLocation(value.data.attributes.address, value.data.attributes.phoneLocationDetails, "Phone")
		)
	}

	if (value.data.attributes.ipLocationDetails && value.data.attributes.address) {
		customEvaluationFlags.push(
			evaluateApplicationLocation(value.data.attributes.address, value.data.attributes.ipLocationDetails, "IP")
		)
	}

	return (
		<BusinessApplicationInternal
			business={value.data}
			documents={value.included?.filter(isApplicationDocument) || []}
			beneficialOwners={(value.included?.filter(isBeneficialOwner) || []).map((bo) => ({
				...bo,
				relationships: {application: {data: {id: value.data.id, type: "application"}}},
			}))}
			refresh={refresh}
			customEvaluationFlags={customEvaluationFlags}
			included={value.included}
		/>
	)
}
