import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	CardDesign,
	createCardDesign,
	findCardDesigns,
	getCardDesign,
	updateCardDesign,
} from "../../resources/cardDesign"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, Org} from "../../resources/org"
import Loading from "../../components/Loading/Loading"
import {AsyncResultIdle, AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {ErrorDocument} from "../../resources/common"
import Admonition from "../../components/Admonitions/Admonition"

type CloseFunc = () => void
type RefreshFunc = () => void
type Mode = "create" | "edit"

interface CreateModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	mode: "create"
	orgs: Array<Org>
}

interface EditModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	mode: "edit"
	cardDesign: CardDesign
}

function Modal({close, refresh, ...props}: CreateModalProps | EditModalProps) {
	const [findState, find] = useAsyncResultIdle(findCardDesigns)
	const accessToken = useAccessToken()
	const [createState, create] = useAsyncResultIdle(createCardDesign)
	const [updateState, update] = useAsyncResultIdle(updateCardDesign)
	const [name, setName] = useState(props.mode == "create" ? "" : props.cardDesign.attributes.name)
	const [cardQualifier, setCardQualifier] = useState(
		props.mode == "create" ? "" : props.cardDesign.attributes.cardQualifier
	)
	const [lowerMark, setLowerMark] = useState(props.mode == "create" ? true : props.cardDesign.attributes.lowerMark)
	const [orgId, setOrgId] = useState(
		props.mode == "create" ? props.orgs[0].id.toString() : props.cardDesign.relationships.org.data.id
	)
	const [state, setState] = useState<AsyncResultIdleRequestState<any, ErrorDocument>>(AsyncResultIdle.idle)
	const [existingCardQualifiers, setExistingCardQualifiers] = useState<string[]>([])
	const existingDesignsLimit = 25 // This use case shouldn't return more than 1 cardDesign

	const isNew = props.mode == "create"

	useEffect(() => {
		findState.match(
			() => setState(AsyncResultIdle.idle),
			() => setState(AsyncResultIdle.pending),
			(result) => {
				setExistingCardQualifiers(
					result.data.map(
						(d) => `Card design with this card qualifier already exists (name: ${d.attributes.name} id: ${d.id}).`
					)
				)
				if (result.data.length == 0) {
					isNew
						? create(accessToken, name, cardQualifier, orgId, lowerMark)
						: update(accessToken, (props as any).cardDesign.id, name, cardQualifier, lowerMark)
				} else {
					setState(AsyncResultIdle.ok)
				}
			},
			(err) => setState(AsyncResultIdle.err(err))
		)
	}, [findState])

	useEffect(() => {
		createState.match(
			() => setState(AsyncResultIdle.idle),
			() => setState(AsyncResultIdle.pending),
			() => setState(AsyncResultIdle.ok),
			(err) => setState(AsyncResultIdle.err(err))
		)
	}, [createState])

	useEffect(() => {
		updateState.match(
			() => setState(AsyncResultIdle.idle),
			() => setState(AsyncResultIdle.pending),
			() => setState(AsyncResultIdle.ok),
			(err) => setState(AsyncResultIdle.err(err))
		)
	}, [updateState])

	function getExistingCQModal(
		buttonText: string,
		successText: string,
		state: AsyncResultIdleRequestState<any, ErrorDocument>,
		onSubmit: Function
	) {
		return (
			<AsyncResultModal
				classname={"card-design-approve-modal"}
				buttonText={buttonText}
				onSubmit={onSubmit}
				buttonClassname={"is-success"}
				errorToText={(err) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
				successText={successText}
				close={close}
				title={"Would you like to proceed?"}
				state={state}
				onSuccess={refresh}
			>
				<Admonition
					type={"is-warning"}
					message={existingCardQualifiers.map((msg) => (
						<p key={msg}>
							{msg}
							<br />
						</p>
					))}
					title={"Note"}
				/>
			</AsyncResultModal>
		)
	}

	function getMainModal(
		title: string,
		buttonText: string,
		successText: string,
		namePlaceholder: string,
		cardQualifierPlaceholder: string
	) {
		return (
			<AsyncResultModal
				title={title}
				onSubmit={() => find(accessToken, 0, existingDesignsLimit, [orgId], cardQualifier)}
				close={close}
				state={state}
				buttonClassname="is-success"
				buttonText={buttonText}
				successText={successText}
				errorToText={(err: ErrorDocument) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
				onSuccess={refresh}
			>
				{isNew ? (
					<div className="field">
						<label className="label">Org</label>
						<div className="select">
							<select value={orgId} onChange={(e) => setOrgId(e.target.value)}>
								{props.orgs.map((o) => (
									<option key={o.id} value={o.id.toString()}>
										{o.attributes.name}
									</option>
								))}
							</select>
						</div>
					</div>
				) : (
					<></>
				)}
				<div className="field">
					<label className="label">Name</label>
					<div className="control">
						<input
							className="input"
							type="text"
							placeholder={namePlaceholder}
							value={name}
							required
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<label className="label">Card Qualifier</label>
					<div className="control">
						<input
							className="input"
							type="text"
							placeholder={cardQualifierPlaceholder}
							value={cardQualifier}
							required
							pattern="^\d{3}$"
							onChange={(e) => setCardQualifier(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<label className="label">
						<input type="checkbox" checked={lowerMark} onChange={(e) => setLowerMark(e.target.checked)} /> Lower Mark
					</label>
				</div>
			</AsyncResultModal>
		)
	}

	if (existingCardQualifiers.length == 0) {
		if (isNew) {
			return getMainModal("Create new card design", "Create", "Created", "The design name", "The card qualifier")
		} else {
			return getMainModal("Edit card design", "Update", "Updated", "Card Design name", "Card Qualifier")
		}
	} else {
		if (isNew) {
			return getExistingCQModal("Create Anyway", "Created", createState, () =>
				create(accessToken, name, cardQualifier, orgId, lowerMark)
			)
		} else {
			return getExistingCQModal("Update Anyway", "Updated", updateState, () =>
				update(accessToken, (props as any).cardDesign.id, name, cardQualifier, lowerMark)
			)
		}
	}
}

export default function CardDesignModal({
	close,
	refresh,
	mode,
	cardDesignId,
}: {
	close: CloseFunc
	refresh: RefreshFunc
	mode: Mode
	cardDesignId: string | undefined
}) {
	const accessToken = useAccessToken()

	if (mode == "edit" && cardDesignId) {
		const cardDesignAsync = useAsyncResult<CardDesign, ErrorDocument>(() => getCardDesign(accessToken, cardDesignId))

		return cardDesignAsync.match(
			() => <Loading />,
			(cardDesign) => <Modal close={close} refresh={refresh} cardDesign={cardDesign} mode={"edit"} />,
			(_) => null
		)
	} else {
		// TODO: when we will have more than 1000 orgs we will have to support searching
		const orgs = useAsyncResult(() => findOrgs(accessToken, 0, 10000))

		return orgs.match(
			() => <Loading />,
			(orgs) => <Modal orgs={orgs} close={close} refresh={refresh} mode={"create"} />,
			(_) => null
		)
	}
}
