import React, {useEffect, useState} from "react"
import {kebabCase, startCase} from "lodash"
import {useAccessToken, useIsUnitUser} from "../../services/auth"
import {useModal} from "react-modal-hook"
import {RejectApplicationDocumentModal} from "./RejectApplicationDocument"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {ApplicationDocumentModal} from "./ApplicationDocumentModal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import LinkButton from "../LinkButton/LinkButton"
import {useQueryState} from "use-location-state"
import {openLinkNewTab} from "../../utilities/file"
import {useToasts} from "react-toast-notifications"
import {faCircleNotch, faUpload} from "@fortawesome/free-solid-svg-icons"
import {
	DataTable,
	DataTableActionHeader,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
} from "../DataTable/DataTable"
import {
	Application,
	ApplicationDocument,
	ApplicationDocumentSide,
	ApplicationDocumentStatus,
	ApplicationDocumentType,
	approveDocument,
	DocumentLink,
	getDocumentLink,
	uploadDocument,
} from "../../resources/application"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {hasPermission} from "../../services/permission"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {AsyncResult} from "../../types/asyncResult"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import {TagsViewerKeyOnly} from "../Tags/TagsViewerKeyOnly"

interface DocumentProps {
	document: ApplicationDocument
	application: Application
	accessToken: string
	refresh: () => void
}

const NO_UPLOAD_DOCUMENTS = ["SelfieVerification"]

function Document({document, application, accessToken, refresh}: DocumentProps) {
	const isUnitUser = useIsUnitUser()

	const [docId, setDocId] = useQueryState("docid", "")
	const [docSide, setDocSide] = useQueryState("side", "")

	const {addToast} = useToasts()

	const hasDocStorageId =
		document.attributes.status != "Required" && !isUnitUser && hasPermission("application.document.file", "get")
	const documentLink =
		!isUnitUser && document.attributes.status !== ApplicationDocumentStatus.Required
			? useAsyncResult(
					() => getDocumentLink(application.id, document.id, accessToken, ApplicationDocumentSide.Front),
					[hasDocStorageId]
			  )
			: AsyncResult.pending<OkDocument<DocumentLink>, ErrorDocument>()

	useEffect(() => {
		docId === document.id && docSide === "front" && showFrontDocModal()
		docId === document.id && docSide === "back" && showBackDocModal()
	}, [])

	async function openLinkinNewTab(side: ApplicationDocumentSide) {
		const response = await getDocumentLink(application.id, document.id, accessToken, side)
		response.match(
			(d) => openLinkNewTab(d.data.attributes.link),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
	}

	const [showFrontDocModal, hideFrontDocModal] = useModal(() => (
		<ApplicationDocumentModal
			application={application}
			document={document}
			accessToken={accessToken}
			refresh={refresh}
			documentSide={ApplicationDocumentSide.Front}
			setDocSideQueryParam={setDocSide}
			close={() => {
				hideFrontDocModal()
				setDocId("")
				setDocSide("")
			}}
		/>
	))

	const [showBackDocModal, hideBackDocModal] = useModal(() => (
		<ApplicationDocumentModal
			application={application}
			document={document}
			accessToken={accessToken}
			refresh={refresh}
			documentSide={ApplicationDocumentSide.Back}
			setDocSideQueryParam={setDocSide}
			close={() => {
				hideBackDocModal()
				setDocId("")
				setDocSide("")
			}}
		/>
	))

	const [showReject, hideReject] = useModal(() => (
		<RejectApplicationDocumentModal
			close={hideReject}
			applicationId={application.id}
			documentId={document.id}
			onSuccess={refresh}
		/>
	))
	const [isFileLoading, setIsFileLoading] = useState(false)

	const canApprove =
		application.attributes.status !== "Approved" &&
		application.attributes.status !== "Denied" &&
		document.attributes.status !== ApplicationDocumentStatus.Approved

	const canReject =
		application.attributes.status !== "Approved" &&
		application.attributes.status !== "Denied" &&
		document.attributes.status !== ApplicationDocumentStatus.Invalid

	let isUploadEnabled
	let documentSide: ApplicationDocumentSide = ApplicationDocumentSide.Front
	let statusClassname

	switch (document.attributes.status) {
		case ApplicationDocumentStatus.ReceivedBack:
		case ApplicationDocumentStatus.Required:
			statusClassname = "has-text-info"
			isUploadEnabled = true
			documentSide = ApplicationDocumentSide.Front

			break
		case ApplicationDocumentStatus.ReceivedFront:
			statusClassname = "has-text-info"
			isUploadEnabled = true
			documentSide = ApplicationDocumentSide.Back

			break
		case ApplicationDocumentStatus.Invalid:
			statusClassname = "has-text-danger"
			isUploadEnabled = true
			documentSide = ApplicationDocumentSide.Front
			break
		case ApplicationDocumentStatus.Approved:
			statusClassname = "has-text-success"
			isUploadEnabled = false
			break
		case ApplicationDocumentStatus.PendingReview:
			statusClassname = "has-text-warning"
			isUploadEnabled = false
			break
	}

	if (
		NO_UPLOAD_DOCUMENTS.includes(document.attributes.documentType) ||
		!hasPermission("application.document", "create")
	) {
		isUploadEnabled = false
	}

	const evaluationLinks: Array<JSX.Element> = []
	if (isUnitUser) {
		if (document.attributes.evaluationId != null) {
			evaluationLinks.push(
				<LinkButton
					key="evaluation"
					to={`https://app.alloy.co/entities/${application.attributes.evaluationEntityId}/evaluations/${document.attributes.evaluationId}`}
				>
					Evaluation
				</LinkButton>
			)
		}
		if (document.attributes.frontDocumentStoreId != null) {
			evaluationLinks.push(
				<LinkButton
					key="front"
					callback={() => {
						setDocId(document.id)
						setDocSide("front")
						showFrontDocModal()
					}}
				>
					Document
				</LinkButton>
			)
		} else if (document.attributes.frontDocumentId != null) {
			evaluationLinks.push(
				<LinkButton key="front" to={`https://app.alloy.co/documents/${document.attributes.frontDocumentId}/download/`}>
					Document
				</LinkButton>
			)
		}

		if (document.attributes.backDocumentStoreId != null) {
			evaluationLinks.push(
				<LinkButton
					key="back"
					callback={() => {
						setDocId(document.id)
						setDocSide("back")
						showBackDocModal()
					}}
				>
					Back Document
				</LinkButton>
			)
		} else if (document.attributes.backDocumentId != null) {
			evaluationLinks.push(
				<LinkButton key="back" to={`https://app.alloy.co/documents/${document.attributes.backDocumentId}/download/`}>
					Back Document
				</LinkButton>
			)
		}

		if (document.attributes.additionalDocumentStoreId != null) {
			evaluationLinks.push(
				<LinkButton
					key="additional"
					callback={() => {
						openLinkinNewTab(ApplicationDocumentSide.Additional)
					}}
				>
					{document.attributes.documentType == ApplicationDocumentType.selfieVerification
						? "Selfie"
						: "Additional Document"}
				</LinkButton>
			)
		} else if (document.attributes.additionalDocumentId != null) {
			evaluationLinks.push(
				<LinkButton
					key="additional"
					to={`https://app.alloy.co/documents/${document.attributes.additionalDocumentId}/download/`}
				>
					{document.attributes.documentType === ApplicationDocumentType.selfieVerification
						? "Selfie"
						: "Additional Document"}
				</LinkButton>
			)
		}
	} else {
		if (hasDocStorageId && documentLink.isOk()) {
			evaluationLinks.push(
				<LinkButton
					key="front"
					callback={() => {
						setDocId(document.id)
						setDocSide("front")
						showFrontDocModal()
					}}
				>
					Document
				</LinkButton>
			)
		}
	}

	function onFileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files

		if (files) {
			const file = files[0]

			if (file) {
				setIsFileLoading(true)
				uploadDocument(accessToken, application.id, document.id, file, file.type, documentSide)
					.then(() => {
						setIsFileLoading(false)
						refresh()
					})
					.catch(() => setIsFileLoading(false))
			}
		}
	}
	const isReasonCodeOther = document.attributes.reasonCode === "Other"

	return (
		<DataTableRow>
			<DataTableCell>{document.id}</DataTableCell>
			<DataTableCell>{startCase(document.attributes.documentType)}</DataTableCell>
			<DataTableCell className={statusClassname}>
				{document.attributes.status == ApplicationDocumentStatus.Invalid ? (
					<TagsViewerKeyValue
						tags={{
							[startCase(document.attributes.status)]: isReasonCodeOther
								? "Other"
								: startCase(document.attributes.reasonCode) ?? "",
						}}
						keyClassName={kebabCase(`${document.attributes.status}-status-tag`)}
						valueClassName="status-tag-invalid-reason"
						tooltipContent={
							isReasonCodeOther && (
								<>
									<span className="status-tag-tooltip-header">Rejected document reason:</span>
									<span className="status-tag-tooltip-content">{document.attributes.reason}</span>
								</>
							)
						}
					/>
				) : (
					<TagsViewerKeyOnly
						keys={[startCase(document.attributes.status)]}
						keyClassName={kebabCase(`${document.attributes.status}-status-tag`)}
					/>
				)}
			</DataTableCell>
			<DataTableCell>{document.attributes.name}</DataTableCell>
			<DataTableCell>
				{isUploadEnabled && (
					<div className="file">
						<label className="file-label">
							<input className="file-input" type="file" onChange={onFileUploaded} accept=".png,.jpg,.pdf" />
							<span className="file-cta">
								<span className="file-icon">
									<FontAwesomeIcon icon={isFileLoading ? faCircleNotch : faUpload} spin={isFileLoading} />
								</span>
								<span className="file-label">Choose a file…</span>
							</span>
						</label>
					</div>
				)}
			</DataTableCell>
			<DataTableCell>
				<HasPermission resource="application.document.file" action="get">
					<div className="buttons are-small">{evaluationLinks}</div>
				</HasPermission>
			</DataTableCell>
			<DataTableCell>
				{document.attributes.documentType !== ApplicationDocumentType.clientRequested && (
					<UnitUserOnly>
						<div className="buttons are-small">
							<button
								className="button is-danger"
								disabled={!canReject}
								onClick={(e) => {
									e.preventDefault()
									showReject()
								}}
							>
								Reject
							</button>
							<button
								className="button is-success"
								disabled={!canApprove}
								onClick={(e) => {
									e.preventDefault()
									approveDocument(accessToken, application.id, document.id).then(() => refresh())
								}}
							>
								Approve
							</button>
						</div>
					</UnitUserOnly>
				)}
			</DataTableCell>
		</DataTableRow>
	)
}

export default function ApplicationDocuments({
	application,
	documents,
	refresh,
}: {
	application: Application
	documents: ApplicationDocument[]
	refresh: () => void
}) {
	const accessToken = useAccessToken()

	const rows = documents.map((d) => (
		<Document key={d.id} document={d} application={application} accessToken={accessToken} refresh={refresh} />
	))

	return (
		<DataTableCard className={"app-docs-card"}>
			<DataTableActionHeader title={"Documents"} />
			<div className={"app-docs-table"}>
				<DataTable hoverable={false} clickable={false} fullHeight={false}>
					<DataTableHead>
						<DataTableRow>
							<DataTableCell>Id</DataTableCell>
							<DataTableCell>Type</DataTableCell>
							<DataTableCell>Status</DataTableCell>
							<DataTableCell>For</DataTableCell>
							<DataTableCell>Upload Document</DataTableCell>
							<HasPermission resource="application.document.file" action="get">
								<DataTableCell>Links</DataTableCell>
							</HasPermission>
							<DataTableCell></DataTableCell>
						</DataTableRow>
					</DataTableHead>
					<tbody>{rows}</tbody>
				</DataTable>
			</div>
		</DataTableCard>
	)
}
