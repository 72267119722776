import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {Adjustments, AdjustmentsColumns} from "../../components/Adjustments/Adjustments"

export default function AdjustmentsPage(): ReactElement {
	return (
		<>
			<TitleBar title={"Adjustments"} />

			<MainSectionTable>
				<Adjustments
					fullHeight={true}
					enableUpload={true}
					includedColumns={[
						AdjustmentsColumns.id,
						AdjustmentsColumns.org,
						AdjustmentsColumns.account,
						AdjustmentsColumns.customer,
						AdjustmentsColumns.direction,
						AdjustmentsColumns.amount,
						AdjustmentsColumns.status,
						AdjustmentsColumns.user,
						AdjustmentsColumns.description,
						AdjustmentsColumns.note,
						AdjustmentsColumns.createdAt,
						AdjustmentsColumns.action,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
