import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Err} from "../Err/Err"
import React from "react"
import {getEvaluatedInterestSettings, getFedInterestRate} from "../../resources/bank"
import {InterestRate} from "../../resources/interestRate"
import {InterestSettings} from "../../resources/interestSettings"
import {InterestAndDepositFeeView, InterestAndDepositFeeViewPending} from "./InterestAndDepositFeeView"
import {AsyncResultZipComponent} from "../../containers/AsyncResult/AsyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"

export default function InterestAndDepositFeeSummary({orgId, accessToken}: {orgId: string; accessToken: string}) {
	const evaluatedInterestSettingsResult = useAsyncResult<OkDocument<InterestSettings>, ErrorDocument>(() =>
		getEvaluatedInterestSettings(accessToken, orgId)
	)
	const fedInterestRateResult = useAsyncResult<OkDocument<InterestRate>, ErrorDocument>(() =>
		getFedInterestRate(accessToken)
	)

	return (
		<AsyncResultZipComponent
			asyncResult1={evaluatedInterestSettingsResult}
			asyncResult2={fedInterestRateResult}
			pendingComponent={<InterestAndDepositFeeViewPending />}
		>
			{({value1: evaluatedInterestSettings, value2: fedInterestRate}) => {
				const evaluatedInterestRatePercentage =
					evaluatedInterestSettings.data.attributes.evaluatedInterestRatePercentage
				if (evaluatedInterestRatePercentage !== undefined) {
					return (
						<InterestAndDepositFeeView
							overallInterestRatePercentage={evaluatedInterestRatePercentage}
							fedUpperBound={fedInterestRate.data.attributes.upperBoundRate}
							fedLowerBound={fedInterestRate.data.attributes.lowerBoundRate}
							interestFormula={evaluatedInterestSettings.data.attributes.interestFormula}
						/>
					)
				}
				return <Err err={"Failed to get evaluated interest rate percentage"} />
			}}
		</AsyncResultZipComponent>
	)
}
