import React from "react"
import {IconProps} from "./types"

export function MP3Icon({disabled}: IconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={81} height={81} fill="none">
			<rect width={80} height={80} x={0.575} y={0.793} fill="#fff" rx={4.646} />
			<path
				fill="#F4F5F7"
				d="M22.037 66.964h36.946a4.592 4.592 0 0 0 4.592-4.592V31.768a3.516 3.516 0 0 0-1.013-2.468L49.014 15.64a3.458 3.458 0 0 0-2.449-1.023H22.037a3.45 3.45 0 0 0-2.448 1.022 3.504 3.504 0 0 0-1.014 2.468v45.367c0 .926.365 1.813 1.014 2.468a3.447 3.447 0 0 0 2.448 1.022Z"
			/>
			<path
				fill="#000"
				fillRule="evenodd"
				d="M48.85 29.557a3.12 3.12 0 0 1 3.691 3.065v14.236l.003.156a5.236 5.236 0 1 1-2.003-4.119V32.622a1.118 1.118 0 0 0-1.323-1.1l-12.709 2.384a1.119 1.119 0 0 0-.912 1.099v16.007a5.236 5.236 0 1 1-2-3.88V35.004a3.118 3.118 0 0 1 2.544-3.065l12.709-2.383Zm1.691 17.334a3.236 3.236 0 1 0 0 .123v-.123Zm-16.944 4.36v-.188a3.236 3.236 0 1 0 .004.28 1.007 1.007 0 0 1-.004-.092Z"
				clipRule="evenodd"
				fillOpacity={disabled ? 0.5 : 1}
			/>
			<path
				fill="#E2E4E7"
				d="M47.044 28.393V14.617c.735 0 1.53.612 1.837.919L62.656 29.31c.735.735.919 2.143.919 2.755H50.717a3.674 3.674 0 0 1-3.673-3.673Z"
			/>
		</svg>
	)
}
