import React, {ReactNode} from "react"
import classNames from "classnames"
import {useMeasure} from "react-use"

const TABLET_WIDTH = 768

interface GridContainerProps {
	rows?: number
	columns?: number
	gap?: string
	responsive?: boolean
	responsiveWidth?: number
	className?: string
	split?: "auto" | "equal"
	style?: Record<string, any>
	children?: ReactNode
}

/**
 * Renders a div container with display: grid
 * @param split - grid items size, how grid items take space - auto or equal size
 * @param rows - number of rows in grid
 * @param columns - number of columns in grid
 * @param gap - gap between items in grid
 * @param responsive - render a single column when container is narrow
 * @param responsiveWidth - can pass this instead of responsive - bellow this width, will render a single column
 * @param className - will be added to classes of the container div
 * @param style - will be added to the style of the container div
 * @param children - child components to render
 * @constructor
 * @example <GridContainer rows={2} className="custom-class" responsive>...</GridContainer>
 */
export function GridContainer({
	split = "auto",
	rows = 1,
	columns = 1,
	gap = "10px",
	responsive,
	responsiveWidth,
	className = "",
	style,
	children,
}: GridContainerProps) {
	const [ref, {width = 0}] = useMeasure<HTMLDivElement>()
	const isResponsive = (responsive || responsiveWidth) && width < (responsiveWidth || TABLET_WIDTH)
	const columnsStyle = isResponsive ? {gridTemplateColumns: "auto"} : {}

	const divStyle: Record<string, any> = {
		"--rows": rows,
		"--cols": columns,
		"--gap": gap,
		"--split": split === "auto" ? "auto" : "1fr",
		...columnsStyle,
		...style,
	}

	const classes = classNames("grid-container", {"grid-container-responsive": responsive}, className)

	return (
		<div ref={ref} className={classes} style={divStyle}>
			{children}
		</div>
	)
}
