import React, {useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {archiveCustomer, ArchiveReason, Customer} from "../../resources/customer"
import {startCase} from "lodash"

interface CloseCustomerModalProps {
	customer: Customer
	closeModal: () => void
	onSuccess: () => void
}

function closeAndRefresh(refresh: () => void, close: () => void, delay = 0) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, delay)
	}
}

export function ArchiveCustomerModal({customer, closeModal, onSuccess}: CloseCustomerModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState<ArchiveReason | undefined>()
	const [state, archive] = useAsyncResultIdle(archiveCustomer)
	return (
		<AsyncResultModal
			title="Archive Customer"
			close={closeAndRefresh(onSuccess, closeModal)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Archive Customer"
			successText="Customer has been archived"
			onSubmit={() => archive(accessToken, customer.id, reason as ArchiveReason)}
			onSuccess={closeAndRefresh(onSuccess, closeModal, 1000)}
			errorToText={(err) => err.errors[0].title}
		>
			<p>
				Archiving a Customer is a final action that cannot be undone. <br />
				Once a Customer has been archived, they become read-only and no changes can be made to anything associated with
				that customer.
				<br />
				Are you sure you want to archive this customer?
			</p>
			<br />
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<div className="select">
						<select required defaultValue="" onChange={(e) => setReason(e.target.value as ArchiveReason)}>
							<option value="" disabled>
								Select Archive Reason
							</option>
							{Object.keys(ArchiveReason).map((k, i) => (
								<option value={k} key={i}>
									{startCase(k)}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</AsyncResultModal>
	)
}
