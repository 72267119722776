import React from "react"
import Card from "../../../containers/Card/Card"
import DepositProductField from "./DepositProductField/DepositProductField"
import {DepositProductOverdraftProps} from "./types"
import {EditMode} from "../../../resources/common"
import classNames from "classnames"
import {toInteger} from "lodash"

export default function Overdraft({editMode, values, onChange, setFieldValue, onBlur}: DepositProductOverdraftProps) {
	const _onChange = (value: any) => {
		if (value.target.value !== "on") {
			setFieldValue("overdraftDaysLimit", toInteger(value.target.value))
		} else {
			onChange(value)
		}
	}

	return (
		<Card
			className="deposit-product-card-title"
			title={
				<span className="card-title" id="Overdraft">
					Overdraft
				</span>
			}
		>
			<div className={classNames("form-row four checkbox", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="checkbox"
					inputType="input"
					name="enableOverdraft"
					subName="overdraftDaysLimit"
					label="Overdraft"
					subLabel="Overdraft daily limit"
					checked={values.enableOverdraft}
					value={values.enableOverdraft}
					subValue={values.overdraftDaysLimit ?? ""}
					onChange={(value) => _onChange(value)}
					onBlur={onBlur}
				/>
			</div>
		</Card>
	)
}
