import {ErrorDocument, getResource} from "./common"
import {Result} from "neverthrow"

interface Relationship {
	data: {
		type: string
		id: string
	}
}

export interface ChargeCardSettings {
	creditProgramSize: number
	creditOperationalAccount: string
	orgLoanAccount: string
	orgGeneralLedgerAccount: string
	[k: string]: unknown
}

export interface ReserveAccountSettings {
	minimumReserveBalance?: number
}

export interface ClientOrgBankAgreement {
	type: string
	id: string
	attributes: {
		chargeCardSettings: ChargeCardSettings
		reserveAccountSettings: ReserveAccountSettings
	}
	relationships: {
		org: Relationship
		bank: Relationship
	}
}

export async function findClientOrgBankAgreement(
	accessToken: string,
	orgs?: string[]
): Promise<Result<Array<ClientOrgBankAgreement>, ErrorDocument>> {
	const result = await getResource<Array<ClientOrgBankAgreement>>("client-org-bank-agreements", accessToken, {
		filter: {
			orgs,
		},
	})
	return result.map((v) => v.data)
}
