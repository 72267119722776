import React, {CSSProperties, ReactNode} from "react"
import classNames from "classnames"

export enum CircleSize {
	xs = "8px",
	sm = "12px",
	md = "16px",
	lg = "24px",
	xl = "32px",
}

export interface CircleProps {
	size?: CircleSize | string
	color?: string
	center?: boolean
	className?: string
	children?: ReactNode | string
}

export function Circle({size, color, center, className, children}: CircleProps) {
	const style = {
		"--circle-size": `${size}`,
		...(color ? {"--circle-color": `${color}`} : {}),
	} as CSSProperties

	const CircleInner = () => (
		<div className={classNames("circle", className)} style={style}>
			{children}
		</div>
	)

	if (center) {
		return (
			<div className={classNames("flex-container", "flex-center", {"circle-color": color})}>
				<CircleInner />
			</div>
		)
	}

	return <CircleInner />
}
