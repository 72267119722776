import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	createTransactionMonitoringSettings,
	TransactionMonitoringSettings,
} from "../../resources/transactionMonitoringSettings"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import {Attributes} from "./Attributes"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {EditMode, ErrorDocument} from "../../resources/common"

function Page() {
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const {addToast} = useToasts()
	const [state, create] = useAsyncResultIdle(createTransactionMonitoringSettings)
	const [name, setName] = useState("")
	// DS
	const [achLoadsDaily, setAchLoadsDaily] = useState<string>("")
	const [achLoadsMonthly, setAchLoadsMonthly] = useState<string>("")
	const [cardLoadsDaily, setCardLoadsDaily] = useState<string>("")
	const [cardLoadsMonthly, setCardLoadsMonthly] = useState<string>("")
	const [cashLoadsPerTransaction, setCashLoadsPerTransaction] = useState<string>("")
	const [cashLoadsDaily, setCashLoadsDaily] = useState<string>("")
	const [cashLoadsMonthly, setCashLoadsMonthly] = useState<string>("")
	const [totalCashMonthly, setTotalCashMonthly] = useState<string>("")
	const [checkLoadsDaily, setCheckLoadsDaily] = useState<string>("")
	const [checkLoadsMonthly, setCheckLoadsMonthly] = useState<string>("")
	const [wireTransferLoadsDaily, setWireTransferLoadsDaily] = useState<string>("")
	const [wireTransferLoadsMonthly, setWireTransferLoadsMonthly] = useState<string>("")
	const [totalPurchasesDaily, setTotalPurchasesDaily] = useState<string>("")
	const [totalPurchasesMonthly, setTotalPurchasesMonthly] = useState<string>("")
	const [achDebitDaily, setAchDebitDaily] = useState<string>("")
	const [achDebitMonthly, setAchDebitMonthly] = useState<string>("")
	const [totalATMTransactionsDaily, setTotalATMTransactionsDaily] = useState<string>("")
	const [totalATMTransactionsMonthly, setTotalATMTransactionsMonthly] = useState<string>("")
	const [totalDebitsDaily, setTotalDebitsDaily] = useState<string>("")
	const [totalDebitsMonthly, setTotalDebitsMonthly] = useState<string>("")
	const [totalCreditsDaily, setTotalCreditsDaily] = useState<string>("")
	const [totalCreditsMonthly, setTotalCreditsMonthly] = useState<string>("")
	const [P2PTransfersDebitDaily, setP2PTransfersDebitDaily] = useState<string>("")
	const [P2PTransfersDebitMonthly, setP2PTransfersDebitMonthly] = useState<string>("")
	const [P2PTransfersCreditDaily, setP2PTransfersCreditDaily] = useState<string>("")
	const [P2PTransfersCreditMonthly, setP2PTransfersCreditMonthly] = useState<string>("")
	const [totalP2PTransfersMonthly, setTotalP2PTransfersMonthly] = useState<string>("")
	const [networkLoadsMonthly, setNetworkLoadsMonthly] = useState<string>("")
	const [manualLoadsMonthly, setManualLoadsMonthly] = useState<string>("")
	const [automatedLoadsMonthly, setAutomatedLoadsMonthly] = useState<string>("")

	const [cashLoadsDailyCount, setCashLoadsDailyCount] = useState<string>("")
	const [cashLoadsWeeklyCount, setCashLoadsWeeklyCount] = useState<string>("")
	const [cashLoadsWeeklyVolume, setCashLoadsWeeklyVolume] = useState<string>("")
	const [totalATMTransactionsDailyCount, setTotalATMTransactionsDailyCount] = useState<string>("")
	const [totalATMTransactionsWeeklyVolume, setTotalATMTransactionsWeeklyVolume] = useState<string>("")
	const [totalATMTransactionsWeeklyCount, setTotalATMTransactionsWeeklyCount] = useState<string>("")
	const [internationalATMTransactionsDailyVolume, setInternationalATMTransactionsDailyVolume] = useState<string>("")
	const [internationalATMTransactionsDailyCount, setInternationalATMTransactionsDailyCount] = useState<string>("")
	const [internationalATMTransactionsWeeklyVolume, setInternationalATMTransactionsWeeklyVolume] = useState<string>("")
	const [internationalATMTransactionsWeeklyCount, setInternationalATMTransactionsWeeklyCount] = useState<string>("")
	const [outgoingWireTransferLoadsDailyVolume, setOutgoingWireTransferLoadsDailyVolume] = useState<string>("")
	const [p2pTransfersDebitWeeklyVolume, setP2PTransfersDebitWeeklyVolume] = useState<string>("")
	const [p2pTransfersCreditWeeklyVolume, setP2PTransfersCreditWeeklyVolume] = useState<string>("")
	const [c2cTransfersDebitWeeklyVolume, setC2CTransfersDebitWeeklyVolume] = useState<string>("")
	const [c2cTransfersCreditWeeklyVolume, setC2CTransfersCreditWeeklyVolume] = useState<string>("")
	const [outgoingWireTransferLoadsMonthlyVolume, setOutgoingWireTransferLoadsMonthlyVolume] = useState<string>("")

	// CA
	const [varPlinAmount, setVarPlinAmount] = useState<number>(250)
	const [varPloutAmount, setVarPloutAmount] = useState<number>(250)
	const [varHfinNumber, setVarHfinNumber] = useState<number>(3)
	const [varHfoutNumber, setVarHfoutNumber] = useState<number>(3)
	const [varPlmhinNumber, setVarPlmhinNumber] = useState<number>(2)
	const [varPlmhinAmount, setVarPlmhinAmount] = useState<number>(500)
	const [varPlmhinFactor, setVarPlmhinFactor] = useState<number>(2)
	const [varPlmhoutNumber, setVarPlmhoutNumber] = useState<number>(2)
	const [varPlmhoutAmount, setVarPlmhoutAmount] = useState<number>(500)
	const [varPlmhoutFactor, setVarPlmhoutFactor] = useState<number>(2)
	const [varHfhainNumber, setVarHfhainNumber] = useState<number>(1)
	const [varHfhainFactor, setVarHfhainFactor] = useState<number>(2)
	const [varHfhaoutNumber, setVarHfhaoutNumber] = useState<number>(1)
	const [varHfhaoutFactor, setVarHfhaoutFactor] = useState<number>(2)
	const [varStrinNumber, setVarStrinNumber] = useState<number>(1)
	const [varStroutNumber, setVarStroutNumber] = useState<number>(1)
	const [varPgainAmount, setVarPgainAmount] = useState<number>(250)
	const [varPgainPercent, setVarPgainPercent] = useState<number>(50)
	const [varPgaoutAmount, setVarPgaoutAmount] = useState<number>(250)
	const [varPgaoutPercent, setVarPgaoutPercent] = useState<number>(50)
	const [varHsumcIAmount, setVarHsumcIAmount] = useState<number>(2000)
	const [varHsumcOAmount, setVarHsumcOAmount] = useState<number>(5000)
	const [varHvalcOAmount, setVarHvalcOAmount] = useState<number>(2500)
	const [varHvalcIAmount, setVarHvalcIAmount] = useState<number>(1000)
	const [varPbalBalance, setVarPbalBalance] = useState<number>(3000)
	const [varPbalPercentage, setVarPbalPercentage] = useState<number>(90)
	const [varPHsumiAmount, setVarPHsumiAmount] = useState<number>(10000)
	const [varPHsumoAmount, setVarPHsumoAmount] = useState<number>(10000)
	const [varOutinAmount, setVarOutinAmount] = useState<number>(5000)
	const [varOutinPercentageLow, setVarOutinPercentageLow] = useState<number>(90)
	const [varOutinPercentageHigh, setVarOutinPercentageHigh] = useState<number>(150)
	const [varInoutAmount, setVarInoutAmount] = useState<number>(5000)
	const [varInoutPercentageLow, setVarInoutPercentageLow] = useState<number>(90)
	const [varInoutPercentageHigh, setVarInoutPercentageHigh] = useState<number>(150)
	const [varHrcouAmount, setVarHrcouAmount] = useState<number>(5000)
	const [varNumcrdrfNumber, setVarNumcrdrfNumber] = useState<number>(5)
	const [varCrefsumAmount, setVarCrefsumAmount] = useState<number>(100)
	const [varCrefsumNumber, setVarCrefsumNumber] = useState<number>(1)
	const [varCrefsumFactor, setVarCrefsumFactor] = useState<number>(1.5)
	const [varHrmrchntAmount, setVarHrmrchntAmount] = useState<number>(500)
	const [varHasumiAmount, setVarHasumiAmount] = useState<number>(2000)
	const [varHasumiFactor, setVarHasumiFactor] = useState<number>(3)
	const [varHasumoAmount, setVarHasumoAmount] = useState<number>(2000)
	const [varHasumoFactor, setVarHasumoFactor] = useState<number>(3)

	useEffect(() => {
		if (state.isOk()) {
			addToast("Transaction Monitoring Settings Created Successfully", {appearance: "success"})
			navigate(`/transaction-monitoring/${state.value.id}`, {replace: true})
		}
	}, [state])

	const submit = () => {
		const newFields = {
			// ds
			...(achLoadsDaily !== "" ? {achLoadsDaily: achLoadsDaily} : {}),
			...(achLoadsMonthly !== "" ? {achLoadsMonthly: achLoadsMonthly} : {}),
			...(cardLoadsDaily !== "" ? {cardLoadsDaily: cardLoadsDaily} : {}),
			...(cardLoadsMonthly !== "" ? {cardLoadsMonthly: cardLoadsMonthly} : {}),
			...(cashLoadsPerTransaction !== "" ? {cashLoadsPerTransaction: cashLoadsPerTransaction} : {}),
			...(cashLoadsDaily !== "" ? {cashLoadsDaily: cashLoadsDaily} : {}),
			...(cashLoadsMonthly !== "" ? {cashLoadsMonthly: cashLoadsMonthly} : {}),
			...(totalCashMonthly !== "" ? {totalCashMonthly: totalCashMonthly} : {}),
			...(checkLoadsDaily !== "" ? {checkLoadsDaily: checkLoadsDaily} : {}),
			...(checkLoadsMonthly !== "" ? {checkLoadsMonthly: checkLoadsMonthly} : {}),
			...(wireTransferLoadsDaily !== "" ? {wireTransferLoadsDaily: wireTransferLoadsDaily} : {}),
			...(wireTransferLoadsMonthly !== "" ? {wireTransferLoadsMonthly: wireTransferLoadsMonthly} : {}),
			...(totalPurchasesDaily !== "" ? {totalPurchasesDaily: totalPurchasesDaily} : {}),
			...(totalPurchasesMonthly !== "" ? {totalPurchasesMonthly: totalPurchasesMonthly} : {}),
			...(achDebitDaily !== "" ? {achDebitDaily: achDebitDaily} : {}),
			...(achDebitMonthly !== "" ? {achDebitMonthly: achDebitMonthly} : {}),
			...(totalATMTransactionsDaily !== "" ? {totalATMTransactionsDaily: totalATMTransactionsDaily} : {}),
			...(totalATMTransactionsMonthly !== "" ? {totalATMTransactionsMonthly: totalATMTransactionsMonthly} : {}),
			...(totalDebitsDaily !== "" ? {totalDebitsDaily: totalDebitsDaily} : {}),
			...(totalDebitsMonthly !== "" ? {totalDebitsMonthly: totalDebitsMonthly} : {}),
			...(totalCreditsDaily !== "" ? {totalCreditsDaily: totalCreditsDaily} : {}),
			...(totalCreditsMonthly !== "" ? {totalCreditsMonthly: totalCreditsMonthly} : {}),
			...(P2PTransfersDebitDaily !== "" ? {P2PTransfersDebitDaily: P2PTransfersDebitDaily} : {}),
			...(P2PTransfersDebitMonthly !== "" ? {P2PTransfersDebitMonthly: P2PTransfersDebitMonthly} : {}),
			...(P2PTransfersCreditDaily !== "" ? {P2PTransfersCreditDaily: P2PTransfersCreditDaily} : {}),
			...(P2PTransfersCreditMonthly !== "" ? {P2PTransfersCreditMonthly: P2PTransfersCreditMonthly} : {}),
			...(totalP2PTransfersMonthly !== "" ? {totalP2PTransfersMonthly: totalP2PTransfersMonthly} : {}),
			...(networkLoadsMonthly !== "" ? {networkLoadsMonthly: networkLoadsMonthly} : {}),
			...(manualLoadsMonthly !== "" ? {manualLoadsMonthly: manualLoadsMonthly} : {}),
			...(automatedLoadsMonthly !== "" ? {automatedLoadsMonthly: automatedLoadsMonthly} : {}),
			...(cashLoadsDailyCount !== "" ? {cashLoadsDailyCount: cashLoadsDailyCount} : {}),
			...(cashLoadsWeeklyCount !== "" ? {cashLoadsWeeklyCount: cashLoadsWeeklyCount} : {}),
			...(cashLoadsWeeklyVolume !== "" ? {cashLoadsWeeklyVolume: cashLoadsWeeklyVolume} : {}),
			...(totalATMTransactionsDailyCount !== ""
				? {totalATMTransactionsDailyCount: totalATMTransactionsDailyCount}
				: {}),
			...(totalATMTransactionsWeeklyVolume !== ""
				? {totalATMTransactionsWeeklyVolume: totalATMTransactionsWeeklyVolume}
				: {}),
			...(totalATMTransactionsWeeklyCount !== ""
				? {totalATMTransactionsWeeklyCount: totalATMTransactionsWeeklyCount}
				: {}),
			...(internationalATMTransactionsDailyVolume !== ""
				? {internationalATMTransactionsDailyVolume: internationalATMTransactionsDailyVolume}
				: {}),
			...(internationalATMTransactionsDailyCount !== ""
				? {internationalATMTransactionsDailyCount: internationalATMTransactionsDailyCount}
				: {}),
			...(internationalATMTransactionsWeeklyVolume !== ""
				? {internationalATMTransactionsWeeklyVolume: internationalATMTransactionsWeeklyVolume}
				: {}),
			...(internationalATMTransactionsWeeklyCount !== ""
				? {internationalATMTransactionsWeeklyCount: internationalATMTransactionsWeeklyCount}
				: {}),
			...(outgoingWireTransferLoadsDailyVolume !== ""
				? {outgoingWireTransferLoadsDailyVolume: outgoingWireTransferLoadsDailyVolume}
				: {}),
			...(p2pTransfersDebitWeeklyVolume !== "" ? {p2pTransfersDebitWeeklyVolume: p2pTransfersDebitWeeklyVolume} : {}),
			...(p2pTransfersCreditWeeklyVolume !== ""
				? {p2pTransfersCreditWeeklyVolume: p2pTransfersCreditWeeklyVolume}
				: {}),
			...(c2cTransfersDebitWeeklyVolume !== "" ? {c2cTransfersDebitWeeklyVolume: c2cTransfersDebitWeeklyVolume} : {}),
			...(c2cTransfersCreditWeeklyVolume !== ""
				? {c2cTransfersCreditWeeklyVolume: c2cTransfersCreditWeeklyVolume}
				: {}),
			...(outgoingWireTransferLoadsMonthlyVolume !== ""
				? {outgoingWireTransferLoadsMonthlyVolume: outgoingWireTransferLoadsMonthlyVolume}
				: {}),
			//ca
			var_plin_amount: varPlinAmount,
			var_plout_amount: varPloutAmount,
			var_hfin_number: varHfinNumber,
			var_hfout_number: varHfoutNumber,
			var_plmhin_number: varPlmhinNumber,
			var_plmhin_amount: varPlmhinAmount,
			var_plmhin_factor: varPlmhinFactor,
			var_plmhout_number: varPlmhoutNumber,
			var_plmhout_amount: varPlmhoutAmount,
			var_plmhout_factor: varPlmhoutFactor,
			var_hfhain_number: varHfhainNumber,
			var_hfhain_factor: varHfhainFactor,
			var_hfhaout_number: varHfhaoutNumber,
			var_hfhaout_factor: varHfhaoutFactor,
			var_strin_number: varStrinNumber,
			var_strout_number: varStroutNumber,
			var_pgain_amount: varPgainAmount,
			var_pgain_percent: varPgainPercent,
			var_pgaout_amount: varPgaoutAmount,
			var_pgaout_percent: varPgaoutPercent,
			var_hsumc_i_amount: varHsumcIAmount,
			var_hsumc_o_amount: varHsumcOAmount,
			var_hvalc_o_amount: varHvalcOAmount,
			var_hvalc_i_amount: varHvalcIAmount,
			var_pbal_balance: varPbalBalance,
			var_pbal_percentage: varPbalPercentage,
			var_p_hsumi_amount: varPHsumiAmount,
			var_p_hsumo_amount: varPHsumoAmount,
			var_outin_amount: varOutinAmount,
			var_outin_percentage_low: varOutinPercentageLow,
			var_outin_percentage_high: varOutinPercentageHigh,
			var_inout_amount: varInoutAmount,
			var_inout_percentage_low: varInoutPercentageLow,
			var_inout_percentage_high: varInoutPercentageHigh,
			var_hrcou_amount: varHrcouAmount,
			var_numcrdrf_number: varNumcrdrfNumber,
			var_crefsum_amount: varCrefsumAmount,
			var_crefsum_number: varCrefsumNumber,
			var_crefsum_factor: varCrefsumFactor,
			var_hrmrchnt_amount: varHrmrchntAmount,
			var_hasumi_amount: varHasumiAmount,
			var_hasumi_factor: varHasumiFactor,
			var_hasumo_amount: varHasumoAmount,
			var_hasumo_factor: varHasumoFactor,
		}

		create(accessToken, name, newFields)
	}

	const disabled = state.match(
		() => false,
		() => true,
		(_) => true,
		(_) => false
	)

	return (
		<>
			<TitleBar title={"New Transaction Monitoring Settings"} />
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
						</div>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						submit()
					}}
				>
					<fieldset disabled={disabled}>
						<Attributes
							editMode={EditMode.NEW}
							name={name}
							setName={setName}
							//ds
							achLoadsDaily={achLoadsDaily}
							achLoadsMonthly={achLoadsMonthly}
							cardLoadsDaily={cardLoadsDaily}
							cardLoadsMonthly={cardLoadsMonthly}
							cashLoadsPerTransaction={cashLoadsPerTransaction}
							cashLoadsDaily={cashLoadsDaily}
							cashLoadsMonthly={cashLoadsMonthly}
							totalCashMonthly={totalCashMonthly}
							checkLoadsDaily={checkLoadsDaily}
							checkLoadsMonthly={checkLoadsMonthly}
							wireTransferLoadsDaily={wireTransferLoadsDaily}
							wireTransferLoadsMonthly={wireTransferLoadsMonthly}
							totalPurchasesDaily={totalPurchasesDaily}
							totalPurchasesMonthly={totalPurchasesMonthly}
							achDebitDaily={achDebitDaily}
							achDebitMonthly={achDebitMonthly}
							totalATMTransactionsDaily={totalATMTransactionsDaily}
							totalATMTransactionsMonthly={totalATMTransactionsMonthly}
							totalDebitsDaily={totalDebitsDaily}
							totalDebitsMonthly={totalDebitsMonthly}
							totalCreditsDaily={totalCreditsDaily}
							totalCreditsMonthly={totalCreditsMonthly}
							P2PTransfersDebitDaily={P2PTransfersDebitDaily}
							P2PTransfersDebitMonthly={P2PTransfersDebitMonthly}
							P2PTransfersCreditDaily={P2PTransfersCreditDaily}
							P2PTransfersCreditMonthly={P2PTransfersCreditMonthly}
							totalP2PTransfersMonthly={totalP2PTransfersMonthly}
							networkLoadsMonthly={networkLoadsMonthly}
							manualLoadsMonthly={manualLoadsMonthly}
							automatedLoadsMonthly={automatedLoadsMonthly}
							cashLoadsDailyCount={cashLoadsDailyCount}
							cashLoadsWeeklyCount={cashLoadsWeeklyCount}
							cashLoadsWeeklyVolume={cashLoadsWeeklyVolume}
							totalATMTransactionsDailyCount={totalATMTransactionsDailyCount}
							totalATMTransactionsWeeklyVolume={totalATMTransactionsWeeklyVolume}
							totalATMTransactionsWeeklyCount={totalATMTransactionsWeeklyCount}
							internationalATMTransactionsDailyVolume={internationalATMTransactionsDailyVolume}
							internationalATMTransactionsDailyCount={internationalATMTransactionsDailyCount}
							internationalATMTransactionsWeeklyVolume={internationalATMTransactionsWeeklyVolume}
							internationalATMTransactionsWeeklyCount={internationalATMTransactionsWeeklyCount}
							outgoingWireTransferLoadsDailyVolume={outgoingWireTransferLoadsDailyVolume}
							p2pTransfersDebitWeeklyVolume={p2pTransfersDebitWeeklyVolume}
							p2pTransfersCreditWeeklyVolume={p2pTransfersCreditWeeklyVolume}
							c2cTransfersDebitWeeklyVolume={c2cTransfersDebitWeeklyVolume}
							c2cTransfersCreditWeeklyVolume={c2cTransfersCreditWeeklyVolume}
							outgoingWireTransferLoadsMonthlyVolume={outgoingWireTransferLoadsMonthlyVolume}
							//ca
							varPlinAmount={varPlinAmount}
							varPloutAmount={varPloutAmount}
							varHfinNumber={varHfinNumber}
							varHfoutNumber={varHfoutNumber}
							varPlmhinNumber={varPlmhinNumber}
							varPlmhinAmount={varPlmhinAmount}
							varPlmhinFactor={varPlmhinFactor}
							varPlmhoutNumber={varPlmhoutNumber}
							varPlmhoutAmount={varPlmhoutAmount}
							varPlmhoutFactor={varPlmhoutFactor}
							varHfhainNumber={varHfhainNumber}
							varHfhainFactor={varHfhainFactor}
							varHfhaoutNumber={varHfhaoutNumber}
							varHfhaoutFactor={varHfhaoutFactor}
							varStrinNumber={varStrinNumber}
							varStroutNumber={varStroutNumber}
							varPgainAmount={varPgainAmount}
							varPgainPercent={varPgainPercent}
							varPgaoutAmount={varPgaoutAmount}
							varPgaoutPercent={varPgaoutPercent}
							varHsumcIAmount={varHsumcIAmount}
							varHsumcOAmount={varHsumcOAmount}
							varHvalcOAmount={varHvalcOAmount}
							varHvalcIAmount={varHvalcIAmount}
							varPbalBalance={varPbalBalance}
							varPbalPercentage={varPbalPercentage}
							varPHsumiAmount={varPHsumiAmount}
							varPHsumoAmount={varPHsumoAmount}
							varOutinAmount={varOutinAmount}
							varOutinPercentageLow={varOutinPercentageLow}
							varOutinPercentageHigh={varOutinPercentageHigh}
							varInoutAmount={varInoutAmount}
							varInoutPercentageLow={varInoutPercentageLow}
							varInoutPercentageHigh={varInoutPercentageHigh}
							varHrcouAmount={varHrcouAmount}
							varNumcrdrfNumber={varNumcrdrfNumber}
							varCrefsumAmount={varCrefsumAmount}
							varCrefsumNumber={varCrefsumNumber}
							varCrefsumFactor={varCrefsumFactor}
							varHrmrchntAmount={varHrmrchntAmount}
							varHasumiAmount={varHasumiAmount}
							varHasumiFactor={varHasumiFactor}
							varHasumoAmount={varHasumoAmount}
							varHasumoFactor={varHasumoFactor}
							//ds
							setAchLoadsDaily={setAchLoadsDaily}
							setAchLoadsMonthly={setAchLoadsMonthly}
							setCardLoadsDaily={setCardLoadsDaily}
							setCardLoadsMonthly={setCardLoadsMonthly}
							setCashLoadsPerTransaction={setCashLoadsPerTransaction}
							setCashLoadsDaily={setCashLoadsDaily}
							setCashLoadsMonthly={setCashLoadsMonthly}
							setTotalCashMonthly={setTotalCashMonthly}
							setCheckLoadsDaily={setCheckLoadsDaily}
							setCheckLoadsMonthly={setCheckLoadsMonthly}
							setWireTransferLoadsDaily={setWireTransferLoadsDaily}
							setWireTransferLoadsMonthly={setWireTransferLoadsMonthly}
							setTotalPurchasesDaily={setTotalPurchasesDaily}
							setTotalPurchasesMonthly={setTotalPurchasesMonthly}
							setAchDebitDaily={setAchDebitDaily}
							setAchDebitMonthly={setAchDebitMonthly}
							setTotalATMTransactionsDaily={setTotalATMTransactionsDaily}
							setTotalATMTransactionsMonthly={setTotalATMTransactionsMonthly}
							setTotalDebitsDaily={setTotalDebitsDaily}
							setTotalDebitsMonthly={setTotalDebitsMonthly}
							setTotalCreditsDaily={setTotalCreditsDaily}
							setTotalCreditsMonthly={setTotalCreditsMonthly}
							setP2PTransfersDebitDaily={setP2PTransfersDebitDaily}
							setP2PTransfersDebitMonthly={setP2PTransfersDebitMonthly}
							setP2PTransfersCreditDaily={setP2PTransfersCreditDaily}
							setP2PTransfersCreditMonthly={setP2PTransfersCreditMonthly}
							setTotalP2PTransfersMonthly={setTotalP2PTransfersMonthly}
							setNetworkLoadsMonthly={setNetworkLoadsMonthly}
							setManualLoadsMonthly={setManualLoadsMonthly}
							setAutomatedLoadsMonthly={setAutomatedLoadsMonthly}
							setCashLoadsDailyCount={setCashLoadsDailyCount}
							setCashLoadsWeeklyCount={setCashLoadsWeeklyCount}
							setCashLoadsWeeklyVolume={setCashLoadsWeeklyVolume}
							setTotalATMTransactionsDailyCount={setTotalATMTransactionsDailyCount}
							setTotalATMTransactionsWeeklyVolume={setTotalATMTransactionsWeeklyVolume}
							setTotalATMTransactionsWeeklyCount={setTotalATMTransactionsWeeklyCount}
							setInternationalATMTransactionsDailyVolume={setInternationalATMTransactionsDailyVolume}
							setInternationalATMTransactionsDailyCount={setInternationalATMTransactionsDailyCount}
							setInternationalATMTransactionsWeeklyVolume={setInternationalATMTransactionsWeeklyVolume}
							setInternationalATMTransactionsWeeklyCount={setInternationalATMTransactionsWeeklyCount}
							setOutgoingWireTransferLoadsDailyVolume={setOutgoingWireTransferLoadsDailyVolume}
							setP2PTransfersDebitWeeklyVolume={setP2PTransfersDebitWeeklyVolume}
							setP2PTransfersCreditWeeklyVolume={setP2PTransfersCreditWeeklyVolume}
							setC2CTransfersDebitWeeklyVolume={setC2CTransfersDebitWeeklyVolume}
							setC2CTransfersCreditWeeklyVolume={setC2CTransfersCreditWeeklyVolume}
							setOutgoingWireTransferLoadsMonthlyVolume={setOutgoingWireTransferLoadsMonthlyVolume}
							//ca
							setVarPlinAmount={setVarPlinAmount}
							setVarPloutAmount={setVarPloutAmount}
							setVarHfinNumber={setVarHfinNumber}
							setVarHfoutNumber={setVarHfoutNumber}
							setVarPlmhinNumber={setVarPlmhinNumber}
							setVarPlmhinAmount={setVarPlmhinAmount}
							setVarPlmhinFactor={setVarPlmhinFactor}
							setVarPlmhoutNumber={setVarPlmhoutNumber}
							setVarPlmhoutAmount={setVarPlmhoutAmount}
							setVarPlmhoutFactor={setVarPlmhoutFactor}
							setVarHfhainNumber={setVarHfhainNumber}
							setVarHfhainFactor={setVarHfhainFactor}
							setVarHfhaoutNumber={setVarHfhaoutNumber}
							setVarHfhaoutFactor={setVarHfhaoutFactor}
							setVarStrinNumber={setVarStrinNumber}
							setVarStroutNumber={setVarStroutNumber}
							setVarPgainAmount={setVarPgainAmount}
							setVarPgainPercent={setVarPgainPercent}
							setVarPgaoutAmount={setVarPgaoutAmount}
							setVarPgaoutPercent={setVarPgaoutPercent}
							setVarHsumcIAmount={setVarHsumcIAmount}
							setVarHsumcOAmount={setVarHsumcOAmount}
							setVarHvalcOAmount={setVarHvalcOAmount}
							setVarHvalcIAmount={setVarHvalcIAmount}
							setVarPbalBalance={setVarPbalBalance}
							setVarPbalPercentage={setVarPbalPercentage}
							setVarPHsumiAmount={setVarPHsumiAmount}
							setVarPHsumoAmount={setVarPHsumoAmount}
							setVarOutinAmount={setVarOutinAmount}
							setVarOutinPercentageLow={setVarOutinPercentageLow}
							setVarOutinPercentageHigh={setVarOutinPercentageHigh}
							setVarInoutAmount={setVarInoutAmount}
							setVarInoutPercentageLow={setVarInoutPercentageLow}
							setVarInoutPercentageHigh={setVarInoutPercentageHigh}
							setVarHrcouAmount={setVarHrcouAmount}
							setVarNumcrdrfNumber={setVarNumcrdrfNumber}
							setVarCrefsumAmount={setVarCrefsumAmount}
							setVarCrefsumNumber={setVarCrefsumNumber}
							setVarCrefsumFactor={setVarCrefsumFactor}
							setVarHrmrchntAmount={setVarHrmrchntAmount}
							setVarHasumiAmount={setVarHasumiAmount}
							setVarHasumiFactor={setVarHasumiFactor}
							setVarHasumoAmount={setVarHasumoAmount}
							setVarHasumoFactor={setVarHasumoFactor}
						/>
						<div className="columns">
							<div className="column">
								<div className="card">
									<Footer state={state} />
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</MainSection>
		</>
	)
}

function Footer({state}: {state: AsyncResultIdleRequestState<TransactionMonitoringSettings, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Create</button>
				),
				() => (
					<button className="button is-success is-loading">Creating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].title}</div>
						<button className="button is-success">Create</button>
					</>
				)
			)}
		</div>
	)
}

export default function NewTransactionMonitoringSettings() {
	return <Page />
}
