import {Address, createResource, createResourceFromFile, getFile, getResource} from "./common"
import {IncomingAch} from "./incomingAch"
import {PendingReviewReasons} from "./incomingWire"

type Customer = {
	type: string
	id: string
}

type Transaction = {
	type: string
	id: number
}

type Counterparty = {
	type: string
	id: number
}

type Account = {
	type: string
	id: string
}

type Org = {
	type: "org"
	id: string
}

type Bank = {
	type: "bank"
	id: string
}

type Card = {
	type: "card"
	id: string
}

type RecurringPayment = {
	type: string
	id: number
}

export enum PaymentType {
	ACHPayment = "achPayment",
	BookPayment = "bookPayment",
	WirePayment = "wirePayment",
	BillPayment = "billPayment",
	pushToCardPayment = "pushToCardPayment",
	CardToCardPayment = "cardToCardPayment",
}

export type AchPayment = {
	type: PaymentType.ACHPayment
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		direction: keyof typeof PaymentDirection
		description: string
		status: PaymentStatus
		settlementDate?: Date
		reason?: string
		tags?: {[key: string]: string}
		addenda?: string
		counterparty: AchCounterparty
		expectedCompletionDate?: Date
		counterpartyVerificationMethod?: keyof typeof CounterpartyVerificationMethod
		sameDay?: boolean
		secCode?: string
		traceNumber?: string
		clearingDaysOverride?: number
	}
	relationships: {
		account: {
			data: Account
		}
		customer?: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		transaction?: {
			data: Transaction
		}
		counterparty?: {
			data: Counterparty
		}
		recurringPayment?: {
			data: RecurringPayment
		}
		org?: {
			data: Org
		}
		bank?: {
			data: Bank
		}
	}
}

export type BookPayment = {
	type: PaymentType.BookPayment
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		direction: PaymentDirection
		description: string
		status: PaymentStatus
		reason?: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: Account
		}
		customer?: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		transaction?: {
			data: Transaction
		}
		counterpartyAccount: {
			data: {
				type: string
				id: number
			}
		}
		counterpartyCustomer: {
			data: {
				type: string
				id: number
			}
		}
		recurringPayment?: {
			data: RecurringPayment
		}
		org?: {
			data: Org
		}
		bank?: {
			data: Bank
		}
	}
}

export type WireCounterparty = {
	name: string
	routingNumber: string
	accountNumber: string
	address: Address
}

export type WireDepositInstitution = {
	routingNumber: string
	name: string
	address?: string
}

export type WireFinancialInstitution = {
	identifierType?: string
	identifier?: string
	fiName?: string
	fiAddress?: string
}

export type WireInvolvedInstitutions = {
	receiverDepositInstitution: WireDepositInstitution
	senderDepositInstitution?: WireDepositInstitution
	intermediaryFinancialInstitution?: WireFinancialInstitution
	instructingFinancialInstitution?: WireFinancialInstitution
	originatorFinancialInstitution?: WireFinancialInstitution
}

export type WirePayment = {
	type: PaymentType.WirePayment
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		direction: PaymentDirection
		description: string
		status: PaymentStatus
		counterparty: WireCounterparty
		reason?: string
		pendingReviewReasons?: PendingReviewReasons
		imadOmad?: {
			omad: string
			imad: string
		}
		imad?: string
		tags?: {[key: string]: string}
		involvedInstitutions?: WireInvolvedInstitutions
	}
	relationships: {
		account: {
			data: Account
		}
		customer?: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		transaction?: {
			data: Transaction
		}
		counterpartyAccount: {
			data: {
				type: string
				id: number
			}
		}
		org?: {
			data: Org
		}
		bank?: {
			data: Bank
		}
		approvals?: {
			data: {
				id: string[]
			}
		}
	}
}

export type BillPayment = {
	type: PaymentType.BillPayment
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		direction: PaymentDirection
		description: string
		status: PaymentStatus
		reason?: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: Account
		}
		customer?: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		transaction?: {
			data: Transaction
		}
		org?: {
			data: Org
		}
		bank?: {
			data: Bank
		}
	}
}

export type CardToCardPayment = {
	type: PaymentType.CardToCardPayment
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		direction: PaymentDirection
		description: string
		status: PaymentStatus
		reason?: string
		tags?: {[key: string]: string}
		astraRoutineId?: string
	}
	relationships: {
		account: {
			data: Account
		}
		customer?: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		transaction?: {
			data: Transaction
		}
		org?: {
			data: Org
		}
		bank?: {
			data: Bank
		}
		card?: {
			data: Card
		}
	}
}

export type UploadBulkWiresResult = {
	succees: string
	skipped: string
	failed: string
}

export type Payment = AchPayment | BookPayment | WirePayment | BillPayment | CardToCardPayment

type Cents = number

export enum PaymentDirection {
	Debit = "Debit",
	Credit = "Credit",
}

export enum PaymentFeature {
	SameDay = "SameDay",
	RecurringPayment = "RecurringPayment",
	ClearingDaysOverride = "ClearingDaysOverride",
}

export enum CounterpartyVerificationMethod {
	Plaid = "Plaid",
}

type AchCounterparty = {
	name: string
	accountNumber: string
	routingNumber: string
	accountType: AccountType
}

type AccountType = "Savings" | "Checking"

export enum PaymentStatus {
	Pending = "Pending",
	Rejected = "Rejected",
	Clearing = "Clearing",
	Sent = "Sent",
	Canceled = "Canceled",
	Returned = "Returned",
	PendingReview = "PendingReview",
}

export enum PaymentStatusPhase {
	AwaitingProcessing = "AwaitingProcessing",
	WaitingForNotice = "WaitingForNotice",
	WaitingForAcknowledgment = "WaitingForAcknowledgment",
	Acknowledged = "Acknowledged",
}

type PlaidData = {
	type: "bookPayment"
	id: string
	attributes: {
		accountBalances: any
		identity: any
	}
}

export type PaymentStatusResponse = {
	type: "paymentStatus"
	id: string
	attributes: {
		status: PaymentStatus
		statusPhase: any
	}
}

export async function findPayments(
	accessToken: string,
	offset: number,
	sort: string,
	requestType: "json" | "csv",
	limit?: number | null,
	statusFilter?: PaymentStatus[],
	statusPhaseFilter?: string[],
	orgs?: string[],
	banks?: string[],
	typeFilter?: string[],
	directionFilter?: string[],
	featureFilter?: string[],
	since?: string,
	until?: string,
	accountId?: string,
	fromAmount?: Cents | "",
	toAmount?: Cents | ""
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const bankFilter = banks && banks.length > 0 ? banks : undefined
	const query = {
		page: {
			limit: limit,
			offset,
		},
		filter: {
			status: statusFilter,
			statusPhase: statusPhaseFilter,
			orgs: orgFilter,
			banks: bankFilter,
			type: typeFilter,
			direction: directionFilter,
			feature: featureFilter,
			since: since != "" ? since : null,
			until: until != "" ? until : null,
			accountId: accountId,
			fromAmount: fromAmount != "" ? fromAmount : null,
			toAmount: toAmount != "" ? toAmount : null,
		},
		sort: sort,
		include: "customer,org,bank",
	}

	switch (requestType) {
		case "csv":
			query.include = "org"
			return await getFile("payments", accessToken, query)
		default:
			return await getResource<Array<Payment>>("payments", accessToken, query)
	}
}

export async function getPayment(accessToken: string, id: string, include?: string) {
	return await getResource<Payment>(`payments/${id}`, accessToken, {include})
}

export function isAchPayment(payment: Payment): payment is AchPayment {
	return payment.type === PaymentType.ACHPayment
}

export function isCardToCardPayment(payment: Payment): payment is CardToCardPayment {
	return payment.type === PaymentType.CardToCardPayment
}

export function isPlaidVerificationMethod(achPayment: AchPayment) {
	return achPayment.attributes.counterpartyVerificationMethod == CounterpartyVerificationMethod.Plaid
}

export function isWirePayment(payment: Payment): payment is WirePayment {
	return payment.type === PaymentType.WirePayment
}

export function isBookPayment(payment: Payment): payment is BookPayment {
	return payment.type === PaymentType.BookPayment
}

export async function approvePayment(accessToken: string, paymentId: string) {
	const data = {
		type: "paymentApprove",
	}
	return await createResource<IncomingAch>(`payments/${paymentId}/approve`, accessToken, data)
}

export async function wirePaymentProcessed(accessToken: string, paymentId: string) {
	const result = await createResource<WirePayment>(`payments/${paymentId}/wire/process`, accessToken, null)
	return result.map((v) => v.data)
}

export async function resendWirePayment(accessToken: string, paymentId: string, imad: string) {
	const data = {
		attributes: {
			paymentId: paymentId,
			imad: imad,
		},
	}
	const result = await createResource<WirePayment>(`payments/${paymentId}/wire/resend`, accessToken, data)
	return result.map((v) => v.data)
}

export async function wirePaymentSent(accessToken: string, paymentId: string, imad: string, omad: string) {
	const data = {
		type: "wirePaymentSent",
		attributes: {
			imad,
			omad,
		},
	}
	const result = await createResource<WirePayment>(`payments/${paymentId}/wire/sent`, accessToken, data)
	return result.map((v) => v.data)
}

export async function rejectPayment(accessToken: string, paymentId: string, reason: string) {
	const data = {
		type: "paymentReject",
		attributes: {
			reason,
		},
	}
	return await createResource<IncomingAch>(`payments/${paymentId}/reject`, accessToken, data)
}

export async function cancelPayment(accessToken: string, paymentId: string) {
	const data = {
		type: "paymentCancel",
	}
	return await createResource<IncomingAch>(`payments/${paymentId}/cancel`, accessToken, data)
}

export async function clearAchPayment(accessToken: string, paymentId: string) {
	const data = {
		type: "paymentClear",
	}
	return await createResource<IncomingAch>(`payments/${paymentId}/clear`, accessToken, data)
}

export async function getPaymentPlaidData(accessToken: string, id: string) {
	return await getResource<PlaidData>(`payments/${id}/plaid`, accessToken)
}

export async function getPaymentStatus(accessToken: string, id: string) {
	const result = await getResource<PaymentStatusResponse>(`payments/${id}/status`, accessToken)
	return result.map((v) => v.data)
}

export async function createLinkedCounterpartyAchPayment(
	accessToken: string,
	accountId: string,
	counterpartyId: string,
	amount: number,
	description: string,
	direction: PaymentDirection = PaymentDirection.Credit
) {
	const data = {
		type: "achPayment",
		attributes: {
			amount: amount,
			direction: direction,
			description: description,
		},
		relationships: {
			account: {
				data: {
					type: "depositAccount",
					id: accountId,
				},
			},
			counterparty: {
				data: {
					type: "counterparty",
					id: counterpartyId,
				},
			},
		},
	}
	return await createResource<Payment>("payments", accessToken, data)
}

export function getExpectedCompletionTimeField(payment: AchPayment) {
	if (payment.attributes.direction == "Credit") {
		return payment.attributes.expectedCompletionDate
	} else {
		return payment.attributes.settlementDate
	}
}

export async function uploadBulkWires(accessToken: string, inputFile: any, type: string, fileName: string) {
	return await createResourceFromFile<UploadBulkWiresResult>(
		"payments/wire/bulk",
		accessToken,
		inputFile,
		type,
		fileName
	)
}
