import React, {useRef} from "react"
import {useClickAway, useToggle} from "react-use"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"

export interface ActionButtonProps {
	enableActions: boolean
	children: React.ReactNode
}

export interface ActionItemProps {
	onClick: () => void
	title: string
	icon: IcomoonIconName
	color?: string
	withTopBorder?: boolean
	disabled?: boolean
	stopPropagationOnClick?: boolean
	props?: React.HTMLAttributes<any>
}

export function ActionButton({enableActions, children}: ActionButtonProps) {
	const ref = useRef(null)
	const [isActive, toggleIsActive] = useToggle(false)
	useClickAway(ref, () => toggleIsActive(false))

	return (
		<div
			ref={ref}
			className={classNames(
				"action-buttons-dots dropdown is-right is-vertical-middle custom",
				isActive && "is-active",
				!enableActions && "action-disabled"
			)}
			onClick={(e) => {
				if (enableActions) {
					toggleIsActive()
				}
				e.preventDefault()
				e.stopPropagation()
			}}
		>
			<div className="dropdown-trigger">
				<Icon icon={"navigation-menu-vertical--interface-essential"} size={16} />
			</div>
			<div className="dropdown-menu" role="menu">
				<div className="dropdown-content">{children}</div>
			</div>
		</div>
	)
}

export function ActionItem({
	onClick,
	icon,
	title,
	color,
	withTopBorder,
	disabled,
	stopPropagationOnClick,
	...props
}: ActionItemProps) {
	return (
		<a
			{...props}
			className={classNames(
				"dropdown-item",
				"action-buttons-action-item",
				withTopBorder && "with-top-border",
				disabled && "dropdown-item-disabled"
			)}
			style={{color: color}}
			onClick={(e) => {
				if (!disabled) {
					e.preventDefault()
					if (stopPropagationOnClick ?? true) {
						e.stopPropagation()
					}
					onClick()
				}
			}}
		>
			<div className="is-flex is-align-items-center">
				<Icon icon={icon} size={16} />
				<span>{title}</span>
			</div>
		</a>
	)
}
