import {getResource} from "./common"
import {AuditLogEventChanges} from "../components/AuditLogEventsView/AuditLogEventsView"

export type AuditLogResource = "deposit-product" | "customer" | "account" | "application"

type AuditLogAttributes = {
	createdAt: string
	actorUserId: string
	actorUserRole: string
	actorUserType: string
	actorUserName: string
	actorBankId?: string
	actorOrgId?: string
	resourceType: AuditLogResource
	resourceId: string
	intent: string
	changes?: AuditLogEventChanges[]
	actionTimestamp: string
}

export type AuditLog = {
	type: string
	id: string
	attributes: AuditLogAttributes
	relationships: {
		banks: {
			data: [
				{
					type: string
					id: string
				}
			]
		}
		org: {
			data: {
				type: string
				id: string
			}
		}
	}
}

export async function findAuditLogs(
	accessToken: string,
	offset: number,
	limit: number,
	resourceIds: string[],
	resourceTypes: AuditLogResource[]
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			resourceIds: resourceIds,
			resourceTypes: resourceTypes,
		},
	}

	return await getResource<Array<AuditLog>>("audit-log", accessToken, query)
}
