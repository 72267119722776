import {createResource, getResource, updateResource} from "./common"
import {isEmpty, omitBy} from "lodash"
import jsonMergePatch from "json-merge-patch"

export type Webhook = {
	type: "webhook"
	id: string
	attributes: {
		createdAt: Date
		label: string
		url: string
		status: string
		contentType: "Json" | "JsonAPI"
		deliveryMode: DeliveryMode
		token?: string
		includeResources: boolean
		subscriptionType: keyof typeof WebhookSubscriptionType
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
	}
	token: string | null
}

export enum WebhookSubscriptionType {
	All = "All",
	OnlyAuthorizationRequest = "Only Authorization Requests",
	NotAuthorizationRequest = "Excluding Authorization Requests",
	OnlyCapitalPartnerEvents = "Only Capital Partner Events",
}

export enum WebhookStatus {
	Enabled = "Enabled",
	Disabled = "Disabled",
	Unavailable = "Unavailable",
}

export type DeliveryMode = "AtMostOnce" | "AtLeastOnce"

export async function findWebhooks(accessToken: string, offset: number, limit: number, since?: string, until?: string) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			since: since != "" ? since : null,
			until: until != "" ? until : null,
		},
		include: "org",
	}

	return await getResource<Array<Webhook>>("webhooks", accessToken, query)
}

export async function getWebhook<Webhook>(accessToken: string, id: string) {
	const result = await getResource<Webhook>(`webhooks/${id}`, accessToken)

	return result.map((v) => v.data)
}

export async function createWebhook(
	accessToken: string,
	label: string,
	url: string,
	token: string | null,
	contentType: "Json" | "JsonAPI",
	deliveryMode: DeliveryMode,
	includeResources: boolean,
	subscriptionType: keyof typeof WebhookSubscriptionType
) {
	const data = {
		type: "webhook",
		attributes: {
			...omitBy(
				{
					label,
					url,
					token,
					contentType,
					deliveryMode,
					subscriptionType,
				},
				isEmpty
			),
			includeResources,
		},
	}
	const result = await createResource<Webhook>("webhooks", accessToken, data)
	return result.map((v) => v.data)
}

export async function updateWebhook(
	accessToken: string,
	label: string,
	url: string,
	token: string | undefined,
	contentType: "Json" | "JsonAPI",
	deliveryMode: DeliveryMode,
	includeResources: boolean,
	webhook: Webhook,
	subscriptionType: keyof typeof WebhookSubscriptionType
) {
	const currentWebhook = omitBy(
		{
			type: "webhook",
			attributes: {
				label: webhook.attributes.label,
				url: webhook.attributes.url,
				token: webhook.attributes.token,
				contentType: webhook.attributes.contentType,
				deliveryMode: webhook.attributes.deliveryMode,
				includeResources: webhook.attributes.includeResources,
				subscriptionType: webhook.attributes.subscriptionType,
			},
		},
		isEmpty
	)
	const newWebhook = omitBy(
		{
			type: "webhook",
			attributes: {
				label,
				url,
				token,
				contentType,
				deliveryMode,
				includeResources,
				subscriptionType,
			},
		},
		isEmpty
	)
	const result = await updateResource<Webhook>(
		`webhooks/${webhook.id}`,
		accessToken,
		jsonMergePatch.generate(currentWebhook, newWebhook)
	)

	return result.map((v) => v.data)
}

export async function toggleWebhookStatus(accessToken: string, webhookId: string, currentStatus: WebhookStatus) {
	const action = currentStatus === WebhookStatus.Enabled ? "disable" : "enable"

	const result = await createResource<Webhook>(`webhooks/${webhookId}/${action}`, accessToken, null)

	return result.map((v) => v.data)
}
