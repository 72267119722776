import React, {ChangeEvent, useCallback, useEffect, useRef} from "react"
import {IcomoonIconName} from "../Icon/icons"
import Button from "../Button/Button"
import {FileDetails} from "../FileDetails/FileDetails"
import ReactTooltip from "react-tooltip"
import {useToasts} from "react-toast-notifications"
import classNames from "classnames"
import {formatFileSize} from "../../utilities/file"
import {FileIconButton} from "../FileIconButton/FileIconButton"

export interface FileUploaderProps {
	selectedFile?: File
	setSelectedFile: React.Dispatch<React.SetStateAction<File | undefined>>
	onFileChange?: (file: File | undefined) => void
	onRemoveFile?: () => void
	buttonLabel?: string
	buttonIcon?: IcomoonIconName
	buttonClassName?: string
	accept?: HTMLInputElement["accept"]
	sizeLimit?: number
	disabled?: boolean
	tooltip?: string
	tooltipClassName?: string
	className?: string
}

export function FileUploader({
	selectedFile,
	setSelectedFile,
	onFileChange,
	onRemoveFile,
	buttonLabel = "Upload",
	buttonIcon = "common-file-upload---files-&-folders",
	buttonClassName = "",
	accept,
	sizeLimit = 100 * 1024 * 1024, // 100 MB
	disabled,
	tooltip,
	tooltipClassName,
	className,
}: FileUploaderProps) {
	const {addToast} = useToasts()
	const fileInputRef = useRef<HTMLInputElement>(null)

	const clearInputValue = useCallback(() => {
		if (fileInputRef.current) {
			fileInputRef.current.value = ""
		}
	}, [fileInputRef])

	useEffect(() => {
		if (!selectedFile) {
			clearInputValue()
		}
	}, [selectedFile, clearInputValue])

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0]
		if (file && file.size > sizeLimit) {
			const errorMsg = `File size must be less than ${formatFileSize(sizeLimit)}`
			addToast(errorMsg, {appearance: "error"})
			clearInputValue()
			return
		}
		setSelectedFile(file)
		onFileChange?.(file)
	}

	const handleClick = () => {
		fileInputRef.current?.click()
	}

	const handleRemoveFile = () => {
		setSelectedFile(undefined)
		onRemoveFile?.()
		clearInputValue()
		ReactTooltip.hide()
	}

	const buttonDisabled = !!selectedFile || disabled

	return (
		<div className={classNames("file-uploader-container", className)}>
			<section className="file-uploader-viewer">
				{selectedFile && (
					<>
						<div className="file-uploader-file-details-container">
							<FileDetails name={selectedFile?.name} size={selectedFile?.size} />
						</div>
						<FileIconButton
							tooltip="Remove"
							icon="bin-1--interface-essential"
							tooltipKey={`file-uploader-remove-file-${selectedFile.name}`}
							onClick={handleRemoveFile}
							containerClassName="file-uploader-icon-button"
						/>
					</>
				)}
			</section>
			<section>
				<input
					type="file"
					accept={accept}
					onChange={handleFileChange}
					ref={fileInputRef}
					className="file-uploader-input"
				/>
				<Button
					onClick={handleClick}
					size="normal"
					prefixIcon={buttonIcon}
					theme="neutral"
					variant="outline"
					disabled={buttonDisabled}
					className={buttonClassName}
					tooltip={tooltip}
					tooltipKey="file-uploader-upload-button"
					tooltipClassName={tooltipClassName}
					tooltipDisabled={buttonDisabled}
				>
					{buttonLabel}
				</Button>
			</section>
		</div>
	)
}
