import {useNavigate, useParams} from "react-router-dom"
import {
	useAccessToken,
	useIsBankAdminUser,
	useIsItUser,
	useIsOrgAdmin,
	useIsUnitComplianceUser,
	useIsUnitUser,
} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getUser, User, changeUserStatus, patchUser} from "../../resources/user"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {useEffect, useState} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import HorizontalField from "../../components/HorizontalField/HorizontalField"
import moment from "moment"
import {ApiTokens, ApiTokensColumns} from "../../components/ApiTokens/ApiTokens"
import {isUndefined, startCase} from "lodash"
import {EditableUserRoleField, UserRoleField} from "../../components/Users/UserRoleField"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {fetchOrgId} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import {EditablePhone, OptionalEditable} from "../../components/HorizontalField/EditableHorizontalField"
import {useModal} from "react-modal-hook"
import {DisableUserModal} from "../../components/Users/DisableUserModal"

type RefreshFunc = () => void

function UserInternal({
	value: [user, orgId],
	refresh,
}: {
	value: [OkDocument<User>, string | undefined]
	refresh: RefreshFunc
}) {
	const navigate = useNavigate()
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const {attributes} = user.data
	const {type: userType} = user.data
	const [statusChangeState, changeStatus] = useAsyncResultIdle(changeUserStatus)
	const [phone, setPhone] = useState(attributes.phone)
	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)
	const [updateState, updateApp] = useAsyncResultIdle(patchUser)
	const isHiddenIconVisible = !useIsUnitComplianceUser()
	const isAllowedToChangePhone = useIsUnitUser() || useIsOrgAdmin() || useIsItUser()
	const [showDisableModal, hideDisableModal] = useModal(() => (
		<DisableUserModal close={hideDisableModal} refresh={refresh} user={user.data} />
	))

	let userOrgId = orgId
	if (user.included) {
		const org = user.included.find((r) => r.type === "org")
		if (org && org.type == "org") userOrgId = org.id
	}

	useEffect(() => {
		statusChangeState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(e.errors[0].title)
		)
	}, [statusChangeState])

	const typesToShowEditUserRole = ["orgUser", "bankUser", "unitUser"]
	const UserRolesField = () => {
		const [role, setRole] = useState(attributes.role)

		if (typesToShowEditUserRole.includes(userType)) {
			const accessToken = useAccessToken()
			const [, setIsUpdating] = useState(false)
			const {addToast} = useToasts()
			useEffect(() => {
				if (updateState.isOk()) {
					addToast("User Updated Successfully", {appearance: "success"})
					setIsUpdating(false)
					refresh()
				} else if (updateState.isErr()) {
					const error = updateState.error.errors[0]
					const msg = error.detail ? error.detail : error.title
					addToast(msg, {appearance: "warning"})
					setIsUpdating(false)
				}
			}, [updateState])

			return (
				<EditableUserRoleField
					orgId={userOrgId}
					role={role}
					type={userType}
					setRole={setRole}
					isEditing={isEditing === "UserRole"}
					isDisabled={!isUndefined(isEditing)}
					isAllowed={true}
					onStartEdit={() => setIsEditing("UserRole")}
					onCancel={refresh}
					update={() => {
						updateApp(accessToken, user.data, {role})
						setIsUpdating(true)
					}}
				/>
			)
		}

		return <UserRoleField role={role} />
	}

	return (
		<>
			<TitleBar
				title={attributes.username}
				breadcrumbs={[{text: "Users", path: "/users/"}]}
				status={attributes.isDisabled ? {color: "#B0B2B5", backgroundColor: "#EBECEE", text: "Disabled"} : undefined}
			>
				<ActionButton enableActions={true}>
					<ActionItem
						title={"Change Password"}
						icon={"security-password-lock--programing-apps-websites"}
						onClick={() => navigate(`/change-password/${user.data.id}`)}
					/>
					<ActionItem
						title={attributes.isDisabled ? "Enable User" : "Disable User"}
						icon={attributes.isDisabled ? "user-geometric-action-check-approve" : "user-geometric-action-cross-remove"}
						onClick={() =>
							attributes.isDisabled
								? changeStatus(accessToken, user.data.id, !attributes.isDisabled)
								: showDisableModal()
						}
					/>
				</ActionButton>
			</TitleBar>

			<MainSection>
				<div className="columns">
					<div className="column is-4">
						<div className="card">
							<div className="card-header">
								<p className="card-header-title">User Information</p>
							</div>
							<div className="card-content">
								<HorizontalField label="Username">
									<input type="text" readOnly value={attributes.username} className="input is-static" />
								</HorizontalField>
								<HorizontalField label="User Type">
									<input type="text" readOnly value={startCase(userType)} className="input is-static" />
								</HorizontalField>
								{UserRolesField()}
								<HorizontalField label="Created At">
									<input
										type="text"
										readOnly
										value={moment(attributes.createdAt).format("L")}
										className="input is-static"
									/>
								</HorizontalField>
								<OptionalEditable
									label="Phone"
									isEditing={isEditing === "Phone"}
									isDisabled={!isUndefined(isEditing)}
									isAllowed={isAllowedToChangePhone}
									isUpdating={isUpdating}
									isHiddenIconVisible={isHiddenIconVisible}
									onStartEdit={() => setIsEditing("Phone")}
									onCancel={refresh}
									setValue={setPhone}
									value={phone ?? {countryCode: "", number: ""}}
									initial={phone ?? {countryCode: "", number: ""}}
									update={() => {
										updateApp(accessToken, user.data, {phone})
										setIsUpdating(true)
									}}
								>
									{EditablePhone}
								</OptionalEditable>
							</div>
						</div>
					</div>
					<div className="column">
						{useIsBankAdminUser() ? null : (
							<ApiTokens
								enableTitle
								userId={user.data.id}
								includedColumns={[
									ApiTokensColumns.id,
									ApiTokensColumns.description,
									ApiTokensColumns.createdAt,
									ApiTokensColumns.expiration,
									ApiTokensColumns.sourceIPs,
									ApiTokensColumns.actions,
								]}
							/>
						)}
					</div>
				</div>
			</MainSection>
		</>
	)
}

export default function UserPage() {
	const {userId = ""} = useParams<{userId: string}>()
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const user = useAsyncResult<OkDocument<User>, ErrorDocument>(() => getUser(accessToken, userId), [refreshToken])
	const orgId = useAsyncResult(() => fetchOrgId(accessToken), [])

	return (
		<AsyncResultComponent asyncResult={AsyncResult.zip(user, orgId)} refresh={refresh}>
			{({value}) => {
				return <UserInternal value={value as [OkDocument<User>, string]} refresh={refresh} />
			}}
		</AsyncResultComponent>
	)
}
