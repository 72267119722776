import {ErrorDocument, getResource} from "./common"
import {Result} from "neverthrow"

interface Relationship {
	data: {
		type: string
		id: string
	}
}

export interface ClientLendingProgramAttributes {
	name: string
	programSizeLimit: number
	programSizeAllocated: number
	creditOperationalAccount?: string
	orgLoanAccount: string
	orgGeneralLedgerAccount: string
}

export interface ClientLendingProgram {
	type: string
	id: string
	attributes: ClientLendingProgramAttributes
	relationships: {
		org: Relationship
		bank: Relationship
	}
}

export async function findClientLendingPrograms(
	accessToken: string
): Promise<Result<Array<ClientLendingProgram>, ErrorDocument>> {
	const result = await getResource<Array<ClientLendingProgram>>("lending-programs/client", accessToken)
	return result.map((v) => v.data)
}
