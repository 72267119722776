import {Result} from "neverthrow"
import {AsyncResultRequestState, AsyncResult} from "../types/asyncResult"
import {DependencyList, useEffect, useMemo} from "react"
import useAsyncResultIdle from "./useAsyncResultIdle"

export function useAsyncResult<T, E>(
	fn: (...args: []) => Promise<Result<T, E>>,
	deps: DependencyList = []
): AsyncResultRequestState<T, E> {
	const [state, callback] = useAsyncResultIdle<T, E, []>(fn, deps)

	const asyncResult = useMemo(
		() =>
			state.match<AsyncResultRequestState<T, E>>(
				() => AsyncResult.pending(),
				() => AsyncResult.pending(),
				(value) => AsyncResult.ok(value),
				(err) => AsyncResult.err(err)
			),
		[state]
	)

	useEffect(() => {
		callback().then((result) =>
			result.match<AsyncResultRequestState<T, E>>(
				(value) => AsyncResult.ok(value),
				(err) => AsyncResult.err(err)
			)
		)
	}, [callback])

	return asyncResult
}
