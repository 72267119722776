import React from "react"
import moment from "moment"
import classNames from "classnames"
import {AssignmentStage} from "../../resources/assignments"
import {getAssignmentIsDisabled} from "../../pages/Assignments/AssignmentsView"
interface AssignmentPageItemTitleAndDescriptionProps {
	stage: AssignmentStage
	title: string
	description?: string | JSX.Element
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
	disabled?: boolean
}

const dateFormat = "MMM DD YYYY, hh:mm A"

export function AssignmentPageItemTitleAndDescription({
	stage,
	title,
	description,
	createdAt,
	statusUpdatedAt,
	isOwnersItem,
	disabled,
}: AssignmentPageItemTitleAndDescriptionProps) {
	const showOwnersIndicator = isOwnersItem && !getAssignmentIsDisabled(stage, true)
	return (
		<div className={classNames("assignment-page-item-header-content-container", showOwnersIndicator && "owners-item")}>
			<div
				className={classNames("assignment-page-item-header-title-and-date-container", description && "has-description")}
			>
				{showOwnersIndicator && <div className="assignment-page-item-header-dot" />}
				<div className={classNames("assignment-page-item-header-title", disabled && "disabled")}>{title}</div>
				<div className="assignment-page-item-header-date">
					{stage === AssignmentStage.Completed && statusUpdatedAt
						? `Completed ${moment(statusUpdatedAt).format(dateFormat)}`
						: `Created ${moment(createdAt).format(dateFormat)}`}
				</div>
			</div>
			{description && (
				<div className={classNames("assignment-page-item-header-description", disabled && "disabled")}>
					{description}
				</div>
			)}
		</div>
	)
}
