import {useAccessToken} from "../../services/auth"
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react"
import {
	ApplicationFormAdditionalDisclosure,
	deleteDomain,
	OrgSettings,
	setupDomain,
	update,
} from "../../resources/orgSettings"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import Editor from "@monaco-editor/react"
import {useModal} from "react-modal-hook"
import ClientDomainConfig from "./ClientDomainConfig"
import classNames from "classnames"
import {ApplicationFormPreviewModal} from "./ApplicationFormPreviewModal"
import {ConfirmationModal} from "../../components/ConfirmationModal/ConfirmationModal"
import KeyValueEditor from "../../components/KeyValueEditor/KeyValueEditor"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import DeleteDomainSettingsModal from "../../components/OrgAndBankSettings/DeleteDomainSettingsModal"
import SetupDomainSettingsModal from "../../components/OrgAndBankSettings/SetupDomainSettingsModal"
import Admonition from "../../components/Admonitions/Admonition"

// Relevant for when the user has to save changes before a preview:
// Using a module variable as a flag instead of a component state because the whole component (ApplicationFormSettings)
// will be "refreshed" after a successful form submit (we want to remember to open the preview modal without using React
// state, because React state will be reset to their default values)
let shouldShowPreviewModal = false

export default function ApplicationFormSettings({
	orgSettings,
	refresh,
}: {
	orgSettings: OrgSettings
	refresh: () => void
}) {
	const accessToken = useAccessToken()
	const [state, patch] = useAsyncResultIdle(update)
	const [showPreviewModal, hidePreviewModal] = useModal(() => (
		<ApplicationFormPreviewModal close={hidePreviewModal} accessToken={accessToken} />
	))
	const [showPreviewSaveConfirmationModal, hidePreviewSaveConfirmationModal] = useModal(
		() => (
			<PreviewSaveConfirmationModal
				close={hidePreviewSaveConfirmationModal}
				onSubmit={previewSaveConfirmationModalConfirmed}
			/>
		),
		[createPatchObject]
	)
	const formElement = useRef<HTMLFormElement>(null)

	const cssEditorPlaceholder = `/*
    The CSS on the application form can be fully customized for your needs.
    We recommend overriding our preset CSS variables, for example:
        :root {
            --header-background-color: #d5ffd5;
            --stage-indicator-background-color: #cef1ce;
            --success-color: green;
        }
        
    A full list of our preset CSS variables can be obtained by inspecting the application form <html> tag 
    using the browser's Developer Tools (see the ":root" selector there)
*/`

	const [applicationFormLogo, setApplicationFormLogo] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormLogo ?? undefined
	)
	const [applicationFormFavicon, setApplicationFormFavicon] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormFavicon ?? undefined
	)
	const [applicationFormBrandName, setApplicationFormBrandName] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormBrandName ?? undefined
	)
	const [applicationFormRedirectUrl, setApplicationFormRedirectUrl] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormRedirectUrl ?? undefined
	)
	const [applicationFormRedirectButtonText, setApplicationFormRedirectButtonText] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormRedirectButtonText ?? undefined
	)
	const [applicationFormPrivacyPolicyUrl, setApplicationFormPrivacyPolicyUrl] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormPrivacyPolicyUrl ?? undefined
	)
	const [applicationFormElectronicDisclosuresUrl, setApplicationFormElectronicDisclosuresUrl] = useState<
		string | undefined
	>(orgSettings?.attributes.applicationFormSettings.applicationFormElectronicDisclosuresUrl ?? undefined)
	const [applicationFormDepositTermsUrl, setApplicationFormDepositTermsUrl] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormDepositTermsUrl ?? undefined
	)
	const [applicationFormClientTermsUrl, setApplicationFormClientTermsUrl] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormClientTermsUrl ?? undefined
	)
	const [applicationFormCardholderTermsUrl, setApplicationFormCardholderTermsUrl] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormCardholderTermsUrl ?? undefined
	)
	const [applicationFormCashAdvancedTermsUrl, setApplicationFormCashAdvancedTermsUrl] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormCashAdvancedTermsUrl ?? undefined
	)
	const [applicationFormDebitCardDisclosureUrl, setApplicationFormDebitCardDisclosureUrl] = useState<
		string | undefined
	>(orgSettings?.attributes.applicationFormSettings.applicationFormDebitCardDisclosureUrl ?? undefined)
	const [applicationFormAdditionalDisclosures, setApplicationFormAdditionalDisclosures] = useState<
		Array<ApplicationFormAdditionalDisclosure> | undefined
	>(orgSettings?.attributes.applicationFormSettings.applicationFormAdditionalDisclosures ?? [])
	const [applicationFormContactUsEmail, setApplicationFormContactUsEmail] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormContactUsEmail ?? undefined
	)
	const [applicationFormCustomCss, setApplicationFormCustomCss] = useState<string | undefined>(
		orgSettings?.attributes.applicationFormSettings.applicationFormCustomCss ?? cssEditorPlaceholder
	)
	const [applicationFormValidatePhoneNumber, setApplicationFormValidatePhoneNumber] = useState(
		orgSettings?.attributes.applicationFormSettings.applicationFormValidatePhoneNumber ?? undefined
	)
	const {addToast} = useToasts()

	const [showSetupModal, hideSetupModal] = useModal(() => (
		<SetupDomainSettingsModal
			close={hideSetupModal}
			refresh={refresh}
			setupDomain={setupDomain}
			domainExampleText="Example: 'apply.yourdomain.com'"
		>
			<Admonition
				type="is-info"
				title="Note"
				message={
					<>
						To complete the subdomain configuration, you have 72 hours to add the records to you DNS provider. Learn
						more{" "}
						<a
							className="link"
							href="https://docs.unit.co/application-forms/#customizing-the-application-form-domain"
							rel="noreferrer"
							target="_blank"
						>
							here
						</a>
						.
					</>
				}
			/>
		</SetupDomainSettingsModal>
	))
	const [showEditModal, hideEditModal] = useModal(() => (
		<DeleteDomainSettingsModal
			domainName={orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain ?? ""}
			close={hideEditModal}
			refresh={refresh}
			deleteDomain={deleteDomain}
		/>
	))

	useEffect(() => {
		// On first render only (componentDidMount), handle showing the preview modal
		// (only relevant if the user had to save changes before preview)
		if (shouldShowPreviewModal) {
			showPreviewModal()
			shouldShowPreviewModal = false
		}
	}, [])

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Updated Successfully", {appearance: "success"})
			refresh()
		}
	}, [state])

	function onFileUploaded(
		e: React.ChangeEvent<HTMLInputElement>,
		setStateFunction: Dispatch<SetStateAction<string | undefined>>
	) {
		const files = e.target.files

		if (files) {
			const file = files[0]

			if (file) {
				const reader = new FileReader()

				reader.addEventListener(
					"load",
					function () {
						setStateFunction(reader.result ? reader.result.toString() : undefined)
					},
					false
				)

				if (file) {
					reader.readAsDataURL(file)
				}
			}
		}
	}

	function onFaviconImageLoadedSquareValidation(e: React.ChangeEvent<HTMLImageElement>) {
		const inputElement = document.querySelector(".favicon-input") as HTMLInputElement

		if (e.target.width !== e.target.height) {
			inputElement.setCustomValidity("Please use a square PNG image for the favicon")
		} else {
			inputElement.setCustomValidity("")
		}
	}

	function createPatchObject() {
		return {
			applicationFormLogo: applicationFormLogo == "" ? undefined : applicationFormLogo,
			applicationFormFavicon: applicationFormFavicon == "" ? undefined : applicationFormFavicon,
			applicationFormBrandName: applicationFormBrandName == "" ? undefined : applicationFormBrandName,
			applicationFormRedirectUrl: applicationFormRedirectUrl == "" ? undefined : applicationFormRedirectUrl,
			applicationFormRedirectButtonText:
				applicationFormRedirectButtonText == "" ? undefined : applicationFormRedirectButtonText,
			applicationFormPrivacyPolicyUrl:
				applicationFormPrivacyPolicyUrl == "" ? undefined : applicationFormPrivacyPolicyUrl,
			applicationFormElectronicDisclosuresUrl:
				applicationFormElectronicDisclosuresUrl == "" ? undefined : applicationFormElectronicDisclosuresUrl,
			applicationFormDepositTermsUrl: applicationFormDepositTermsUrl == "" ? undefined : applicationFormDepositTermsUrl,
			applicationFormClientTermsUrl: applicationFormClientTermsUrl == "" ? undefined : applicationFormClientTermsUrl,
			applicationFormCardholderTermsUrl:
				applicationFormCardholderTermsUrl == "" ? undefined : applicationFormCardholderTermsUrl,
			applicationFormCashAdvancedTermsUrl:
				applicationFormCashAdvancedTermsUrl == "" ? undefined : applicationFormCashAdvancedTermsUrl,
			applicationFormDebitCardDisclosureUrl:
				applicationFormDebitCardDisclosureUrl == "" ? undefined : applicationFormDebitCardDisclosureUrl,
			applicationFormContactUsEmail: applicationFormContactUsEmail == "" ? undefined : applicationFormContactUsEmail,
			applicationFormCustomCss: applicationFormCustomCss == "" ? undefined : applicationFormCustomCss,
			applicationFormAdditionalDisclosures:
				applicationFormAdditionalDisclosures?.length == 0 ? undefined : applicationFormAdditionalDisclosures,
			applicationFormValidatePhoneNumber,
		}
	}

	function hasFormChanged() {
		// Comparing between initial settings and the would-be settings (before the save) to understand if the form has
		// been changed in any way
		const currentFormSettings = orgSettings.attributes.applicationFormSettings
		const patchObject: Record<any, any> = createPatchObject()

		// Remove any undefined values from the patch object (they're not interesting in the context of comparing values)
		Object.keys(patchObject).forEach((key) => (patchObject[key] === undefined ? delete patchObject[key] : {}))

		// Actual comparison using a key-sorted JSON string
		return (
			JSON.stringify(currentFormSettings, Object.keys(currentFormSettings).sort()) !==
			JSON.stringify(patchObject, Object.keys(patchObject).sort())
		)
	}

	async function previewSaveConfirmationModalConfirmed() {
		if (formElement.current) {
			if (formElement.current.checkValidity()) {
				shouldShowPreviewModal = true
				submitForm()
			} else {
				formElement.current.reportValidity()
			}
		}
	}

	function submitForm(e?: React.FormEvent<HTMLFormElement>) {
		if (e) {
			e.preventDefault()
		}

		return patch(accessToken, {
			applicationFormSettings: createPatchObject(),
		})
	}

	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<h3> Application Form </h3>
				<form ref={formElement} onSubmit={(e) => submitForm(e)}>
					<div className="field ">
						<label className="label">Domain Name</label>
						{!orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain ? (
							<p className="field has-text-grey">
								Click setup to configure your application form URL with your own subdomain
							</p>
						) : null}
						<div className="field has-addons is-horizontal">
							{orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain ? (
								<div className="control is-expanded">
									<input
										className="input"
										required
										type="text"
										value={orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain ?? ""}
										placeholder="A subdomain you own"
										disabled
									/>
								</div>
							) : null}
							<button
								type="button"
								className={classNames(
									"button",
									orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain
										? "is-danger"
										: "is-success"
								)}
								onClick={(e) => {
									e.preventDefault()
									if (orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain) {
										showEditModal()
									} else {
										showSetupModal()
									}
								}}
							>
								{orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain ? "Delete" : "Setup"}
							</button>
						</div>
					</div>
					<ClientDomainConfig refresh={refresh} orgSettings={orgSettings} />
					<fieldset>
						<div className="field">
							<label className="label">Name</label>
							<div className="control">
								<input
									className="input"
									required
									type="text"
									value={applicationFormBrandName ?? ""}
									placeholder={"Unit Finance"}
									onChange={(e) => setApplicationFormBrandName(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Logo (SVG)</label>
							<div className="field-body">
								<div className="file">
									<label className="file-label">
										<input
											className="file-input"
											type="file"
											onChange={(e) => onFileUploaded(e, setApplicationFormLogo)}
											accept=".svg"
											required={!applicationFormLogo}
										/>
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload" />
											</span>
											<span className="file-label">Choose a file…</span>
										</span>
									</label>
								</div>
							</div>
							{applicationFormLogo ? <img className="org-settings-logo" src={applicationFormLogo} /> : null}
						</div>
						<div className="field">
							<label className="label">Favicon (Square PNG)</label>
							<div className="field-body">
								<div className="file">
									<label className="file-label">
										<input
											className="file-input favicon-input"
											type="file"
											onChange={(e) => onFileUploaded(e, setApplicationFormFavicon)}
											accept=".png"
											required={!applicationFormFavicon}
										/>
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload" />
											</span>
											<span className="file-label">Choose a file…</span>
										</span>
									</label>
								</div>
							</div>
							{applicationFormFavicon ? (
								<img
									className="org-settings-logo"
									src={applicationFormFavicon}
									onLoad={(e) => onFaviconImageLoadedSquareValidation(e as React.ChangeEvent<HTMLImageElement>)}
								/>
							) : null}
						</div>
						<div className="field">
							<label className="label">Privacy Policy URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormPrivacyPolicyUrl ?? ""}
									onChange={(e) => setApplicationFormPrivacyPolicyUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Consent to Electronic Disclosures URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormElectronicDisclosuresUrl ?? ""}
									onChange={(e) => setApplicationFormElectronicDisclosuresUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Deposit Terms & Conditions URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormDepositTermsUrl ?? ""}
									onChange={(e) => setApplicationFormDepositTermsUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Client Terms of Service URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormClientTermsUrl ?? ""}
									onChange={(e) => setApplicationFormClientTermsUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Cardholder Terms and Conditions URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormCardholderTermsUrl ?? ""}
									onChange={(e) => setApplicationFormCardholderTermsUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Cash Advance Terms and Conditions URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormCashAdvancedTermsUrl ?? ""}
									onChange={(e) => setApplicationFormCashAdvancedTermsUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Debit Card Disclosure URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormDebitCardDisclosureUrl ?? ""}
									onChange={(e) => setApplicationFormDebitCardDisclosureUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Additional Disclosures</label>
							<div className="control">
								<KeyValueEditor
									keyValues={applicationFormAdditionalDisclosures ?? []}
									setKeyValues={setApplicationFormAdditionalDisclosures}
									keyName={"title"}
									valueName={"url"}
									itemName={"Disclosure"}
									keyPlaceholder={"Disclosure name"}
									valuePlaceholder={"Disclosure URL"}
									valueInputType={"url"}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Contact Us Email</label>
							<div className="control">
								<input
									className="input"
									type="email"
									value={applicationFormContactUsEmail ?? ""}
									onChange={(e) => setApplicationFormContactUsEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Redirect URL</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={applicationFormRedirectUrl ?? ""}
									onChange={(e) => setApplicationFormRedirectUrl(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Redirect Button Text</label>
							<div className="control">
								<input
									className="input"
									type="text"
									value={applicationFormRedirectButtonText ?? ""}
									onChange={(e) => setApplicationFormRedirectButtonText(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Custom CSS</label>
							<div className="control">
								<Editor
									language="css"
									height={300}
									theme="vs-dark"
									value={applicationFormCustomCss ?? ""}
									onChange={setApplicationFormCustomCss}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label" id="validatePhoneNumber">
								<input
									type="checkbox"
									checked={applicationFormValidatePhoneNumber}
									onChange={(e) => setApplicationFormValidatePhoneNumber(e.target.checked)}
								/>{" "}
								Validate Phone Number
								<label className="tooltip-container">
									<a data-tip="React-tooltip" data-event="click focus">
										<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
										<ReactTooltip
											place="right"
											effect="solid"
											className="tooltip-info"
											globalEventOff="click"
											border={true}
											borderColor="#C4C4C4"
											backgroundColor="#F5F5F5"
											textColor="black"
											data-html={true}
										>
											You should only disable this if you are validating and pre-populating the phone number. When the
											phone number validation is disabled, the customer will not be able to edit the phone number from
											within the application form
										</ReactTooltip>
									</a>
								</label>
							</label>
						</div>
					</fieldset>
					<div className="columns">
						<div className="column mt-4">
							<button
								type="button"
								className="button is-info mr-2"
								onClick={(e) => {
									e.preventDefault()
									if (hasFormChanged()) {
										showPreviewSaveConfirmationModal()
									} else {
										showPreviewModal()
									}
								}}
							>
								Preview
							</button>
							{state.match(
								() => (
									<button type="submit" className="button is-success">
										Update
									</button>
								),
								() => (
									<button type="submit" className="button is-success is-loading">
										Updating
									</button>
								),
								(_) => null,
								(err) => (
									<>
										<div className="has-text-danger">
											{err.errors ? err.errors[0].title : "An unexpected error has occurred"}
										</div>
										<button type="submit" className="button is-success">
											Update
										</button>
									</>
								)
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export function PreviewSaveConfirmationModal({close, onSubmit}: {close: () => void; onSubmit: () => void}) {
	return (
		<ConfirmationModal
			close={close}
			onSubmit={onSubmit}
			title={"Save Changes"}
			okButtonClassname={"button is-success is-outlined"}
			cancelButtonClassname="button is-white"
			okButtonText={"Save"}
		>
			<p> Please save all changes before preview </p>
		</ConfirmationModal>
	)
}
