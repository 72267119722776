import classNames from "classnames"
import React, {useState, useEffect} from "react"
import {useHeadsObserver} from "../../hooks/useHeadsObserver"

const INITIAL_ROOT_MARGIN_TOP = 10
export default function TableOfContent({querySelector}: {querySelector: string}) {
	const navBarHeight = Number(
		getComputedStyle(document.documentElement)
			.getPropertyValue("--navbar-height")
			?.replace(/[^0-9]/g, "")
	)
	const titleBarHeight = Number(
		getComputedStyle(document.documentElement)
			.getPropertyValue("--titlebar-height")
			?.replace(/[^0-9]/g, "")
	)
	const rootMarginTop = -1 * (INITIAL_ROOT_MARGIN_TOP + navBarHeight + titleBarHeight)
	const [headings, setHeadings] = useState<{elem: string; id: string; text: string}[]>([])
	const {activeId, setActiveId} = useHeadsObserver(querySelector, `${rootMarginTop}px 0px -35% 0px`)
	useEffect(() => {
		const elements = Array.from(document.querySelectorAll(querySelector)).map((elem: any) => ({
			elem,
			id: elem.id,
			text: elem.innerText,
		}))
		setHeadings(elements)
	}, [])

	const scrollToView = (id: any) => {
		setActiveId(id)
		const elementPosition = (document.getElementById(id)?.getBoundingClientRect()?.top ?? 0) + window.scrollY ?? 0
		const top = elementPosition - navBarHeight - titleBarHeight - 64
		window.scrollTo(0, top)
	}

	return (
		<div className="table-of-content">
			<nav>
				<ul>
					{headings.map((heading) => (
						<li key={heading.id}>
							<a
								className={classNames("table-of-content-title", activeId === heading.id && "active")}
								href={`#${heading.id}`}
								onClick={(e) => {
									e.preventDefault()
									scrollToView(heading.id)
								}}
							>
								<div className="border-effect"></div>
								<span className="title-text">{heading.text}</span>
							</a>
						</li>
					))}
				</ul>
			</nav>
		</div>
	)
}
