import React, {createElement, CSSProperties, FunctionComponent} from "react"

type IconSetItem = {
	grid: number
	paths: string[]
	tags: string[]
	width?: number
}

type IconSet = {
	icons: IconSetItem[]
}

const style: CSSProperties = {
	display: "inline-block",
	stroke: "currentColor",
	fill: "currentColor",
}

export const IcoMoon: FunctionComponent<{
	iconSet: IconSet
	icon: string
	color?: string
	size?: string | number
	className?: string
	style?: React.CSSProperties
	[name: string]: any
}> = ({iconSet, icon, size, title, removeInlineStyle, native, SvgComponent, PathComponent, ...props}) => {
	if (!iconSet || !icon) return null

	const currentIcon = iconSet.icons.find((item) => {
		return item.tags[0] === icon
	})

	if (!currentIcon) return null

	if (native) {
		style.display = "flex"
		style.flexDirection = "row"
		style.flexWrap = "wrap"
	}

	props.style = {
		...(removeInlineStyle ? {} : style),
		...(size ? {width: size, height: size} : {}),
		...(props.style || {}),
	}

	const {width = "1024"} = currentIcon

	props.viewBox = `0 0 ${width} 1024`

	const childs = currentIcon.paths.map((path, index) => {
		const pathProps = {
			d: path,
			key: icon + index,
			// ...(!disableFill ? currentIcon.attrs[index] : {}),
		}

		return PathComponent ? createElement(PathComponent, pathProps) : createElement("path", pathProps)
	})

	if (title && !native) {
		childs.push(createElement("title", {key: title}, title))
	}

	return SvgComponent ? createElement(SvgComponent, props, childs) : createElement("svg", props, childs)
}

export const iconList = (iconSet: IconSet) => {
	if (iconSet && Array.isArray(iconSet.icons)) {
		return iconSet.icons.map((icon) => icon.tags[0])
	}

	return null
}
