import {toLower} from "lodash"

export type FileMetadata = {
	name: string
	size: number
}

export enum FileType {
	MP3 = "MP3",
	MP4 = "MP4",
	JPEG = "JPEG",
	JPG = "JPG",
	PNG = "PNG",
	WAV = "WAV",
	PDF = "PDF",
	DOCX = "DOCX",
}

export const convertFileExtensionToFileType = (extension?: string): FileType | undefined => {
	switch (toLower(extension)) {
		case "mp3":
			return FileType.MP3
		case "mp4":
			return FileType.MP4
		case "jpeg":
			return FileType.JPEG
		case "jpg":
			return FileType.JPG
		case "png":
			return FileType.PNG
		case "wav":
			return FileType.WAV
		case "pdf":
			return FileType.PDF
		case "docx":
			return FileType.DOCX
		default:
			return undefined
	}
}

export function fileReader(file: File) {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.onload = () => {
			resolve(reader.result)
		}

		reader.readAsBinaryString(file)
	})
}

export function openLinkNewTab(link: string) {
	const a = document.createElement("a")
	document.body.appendChild(a)
	a.target = "_blank"
	a.style.display = "none"
	a.href = link
	a.click()
	URL.revokeObjectURL(a.href)
	a.remove()
}

export function downloadFile(filename: string, data: any, fileType: string) {
	const fileData = new Blob([data], {type: fileType})
	const a = document.createElement("a")
	document.body.appendChild(a)
	a.style.display = "none"
	a.href = URL.createObjectURL(fileData)
	a.download = filename
	a.click()
	URL.revokeObjectURL(a.href)
	a.remove()
}

export const formatFileSize = (sizeInBytes: number): string => {
	const kilobyte = 1000
	const megabyte = kilobyte * 1000
	const gigabyte = megabyte * 1000

	if (sizeInBytes < kilobyte) {
		return `${sizeInBytes} B`
	} else if (sizeInBytes < megabyte) {
		return `${(sizeInBytes / kilobyte).toFixed(2)} KB`
	} else if (sizeInBytes < gigabyte) {
		return `${(sizeInBytes / megabyte).toFixed(2)} MB`
	} else {
		return `${(sizeInBytes / gigabyte).toFixed(2)} GB`
	}
}

export const extractExtensionAndFileName = (name?: string): {extension?: string; nameWithoutExtension: string} => {
	if (!name) {
		return {nameWithoutExtension: ""}
	}
	const lastDotIndex = name.lastIndexOf(".")
	if (lastDotIndex !== -1) {
		const extension = name.slice(lastDotIndex + 1)
		const nameWithoutExtension = name.slice(0, lastDotIndex)
		return {extension, nameWithoutExtension}
	} else {
		return {nameWithoutExtension: name}
	}
}

export const getMimeTypeByFileName = (fileName?: string): string | undefined => {
	const fileExtension = extractExtensionAndFileName(fileName).extension
	switch (toLower(fileExtension)) {
		case "jpeg":
		case "jpg":
			return "image/jpeg"
		case "png":
			return "image/png"
		case "pdf":
			return "application/pdf"
		case "docx":
			return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		case "mp3":
			return "audio/mpeg"
		case "mp4":
			return "video/mp4"
		case "wav":
			return "audio/wav"
		default:
			return undefined
	}
}
