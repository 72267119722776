import React from "react"
import {useAccessToken, useIsUnitUser} from "../../services/auth"
import {useParams} from "react-router"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getReceivedPayment} from "../../resources/receivedPayments"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {kebabCase, startCase} from "lodash"
import classNames from "classnames"
import {ReceivedPaymentComponent} from "../../components/ReceivedPayments/ReceivedPayment"
import ReactJson from "react-json-view"
import {IncomingAch} from "../../resources/incomingAch"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import Card from "../../containers/Card/Card"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"

export function IncomingAchItemData({rawData}: {rawData: object}) {
	return (
		<UnitUserOnly>
			<div className={"received-payments-incoming-ach-details"}>
				<Card title={"ACH Item"}>
					<ReactJson src={rawData} displayDataTypes={false} />
				</Card>
			</div>
		</UnitUserOnly>
	)
}

export default function ReceivedPaymentPage() {
	const accessToken = useAccessToken()
	const {receivedPaymentId = ""} = useParams<{receivedPaymentId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const isUnitUser = useIsUnitUser()
	const receivedPaymentAsyncResultReq = useAsyncResult(
		() => getReceivedPayment(accessToken, receivedPaymentId, "customer,account,incomingAch"),
		[refreshToken]
	)

	return (
		<div className={"received-payments-full-page"}>
			<AsyncResultComponent asyncResult={receivedPaymentAsyncResultReq}>
				{({value: receivedPaymentResponse}) => {
					const receivedPayment = receivedPaymentResponse.data
					const incomingAch =
						receivedPaymentResponse.included &&
						(receivedPaymentResponse.included.find((i) => i.type === "incomingAch") as IncomingAch)
					const accountId = receivedPayment.relationships.account?.data.id
					const customerId = receivedPayment.relationships.customer?.data.id
					const receivedPaymentTransactionId = receivedPayment.relationships.receivePaymentTransaction?.data.id
					const incomingAchItemId = receivedPayment.relationships.incomingAchItem?.data.id
					return (
						<>
							<TitleBar
								title={`${startCase(receivedPayment.type).replace("Received Payment", "")} #${receivedPaymentId}`}
								breadcrumbs={[{text: "Received Payments", path: "/received-payments/"}]}
								status={{
									text: startCase(receivedPayment.attributes.status),
									className: classNames("incoming-payments-status", kebabCase(receivedPayment.attributes.status)),
								}}
								meta={[
									{
										text: "Account",
										icon: "bank-account--business-products",
										path: `/accounts/${accountId}`,
									},
									{
										text: "Customer",
										icon: "user-geometric-action-money-fund---users",
										path: `/customer/${customerId}`,
									},
									...(accountId && receivedPaymentTransactionId
										? [
												{
													text: "Transaction",
													icon: "money-transfer--business-products",
													path: `/transaction/${accountId}/${receivedPaymentTransactionId}`,
												} as MetaItemProps,
										  ]
										: []),
									...(incomingAchItemId && isUnitUser
										? [
												{
													text: "Incoming ACH",
													icon: "money-transfer--business-products",
													path: `/incoming-achs/${incomingAchItemId}`,
												} as MetaItemProps,
										  ]
										: []),
								]}
							/>
							<MainSectionLayout
								outerWrapperClassname={classNames(incomingAch && isUnitUser && "received-payments-full-with-details")}
								renderAfter={
									incomingAch && isUnitUser ? <IncomingAchItemData rawData={incomingAch.attributes.item} /> : null
								}
								fullWidth={incomingAch && isUnitUser}
							>
								<ReceivedPaymentComponent
									receivedPaymentId={receivedPaymentId}
									accessToken={accessToken}
									useDrawer={false}
									refresh={refresh}
									receivedPaymentAsyncResult={receivedPaymentAsyncResultReq}
								/>
							</MainSectionLayout>
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}
