import React, {createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef} from "react"
import qs from "querystring"
import moment from "moment"
import {Result} from "neverthrow"
import {useLocalStorage} from "react-use"
import useAsyncResultIdle from "../hooks/useAsyncResultIdle"
import {AsyncResultIdleRequestState} from "../types/asyncResultIdle"
import {post} from "../utilities/http"
import {assertIsDefined} from "../utilities/assert"
import {useCookies} from "react-cookie"
import {parse} from "tldts"
import {isUndefined} from "lodash"
import {ApiTokenPresent} from "../resources/apiToken"
import {deleteResource} from "../resources/common"
import {useToasts} from "react-toast-notifications"
import {claimsContext} from "./claims"

export type AuthenticateFn = (
	username: string,
	password: string,
	assessmentToken: string
) => Promise<Result<{}, AuthError>>

export const enum UserType {
	Unit = "unit",
	Org = "org",
	Bank = "bank",
}

export function isUnitUser(userType: string | undefined): userType is UserType.Unit {
	return userType === UserType.Unit
}

export function isOrgUser(userType: string | undefined): userType is UserType.Org {
	return userType === UserType.Org
}

export function isBankUser(userType: string | undefined): userType is UserType.Bank {
	return userType === UserType.Bank
}

export type Claims = {
	userType: UserType
	role: string
	userId: string
	org: string
	orgId: string
	bankId: string
	isUnitPilot: boolean
	sub: string
}

export type Token = {
	type: TokenType
	expires: Date
	accessToken: string
	isSSO: boolean
}

export type TwoFactorToken = {
	type: TokenType
	verificationToken: string
	expires: Date
	phone: string
}

export enum TokenType {
	TwoFactorToken = "2fa",
	AccessToken = "bearer",
}

export type AuthError = {
	error: string
	code?: string
}
type AuthContextState = [Token | undefined, (token: Token | undefined) => void, () => void]

const authContext = createContext<AuthContextState>([
	undefined,
	(_token: Token | undefined) => {
		// no context yet
	},
	() => {
		// no remove function
	},
])

export function AuthProvider({children}: PropsWithChildren<{}>) {
	const [storedToken, setToken, removeToken] = useLocalStorage<Token | undefined>("token", getTokenFromCookie())
	const token = useMemo(
		() => (storedToken && moment(storedToken.expires).isAfter() ? storedToken : undefined),
		[storedToken]
	)

	const timeout = useRef<ReturnType<typeof setTimeout>>()
	const [, , removeTutorialToken] = useLocalStorage<ApiTokenPresent | undefined>("tutorialToken")

	useEffect(() => {
		if (isUndefined(token)) {
			removeTutorialToken()
			removeToken()
		}
	}, [token])

	function expireToken() {
		// TODO: Re-login if no-inactivity?
		setToken(undefined)
	}

	function getTokenFromCookie() {
		const domain = () => {
			const parsedUrl = parse(window.location.hostname)
			return parsedUrl.domain ? parsedUrl.domain : window.location.hostname
		}

		const [cookies, , removeCookie] = useCookies(["dash_access_data"])
		try {
			const tokenData = cookies.dash_access_data
			return {
				type: TokenType.AccessToken,
				accessToken: tokenData.access_token,
				expires: moment()
					.add(tokenData.expires_in - 60, "seconds")
					.toDate(),
				isSSO: tokenData.is_sso,
			}
		} catch (err) {
		} finally {
			removeCookie("dash_access_data", {domain: domain(), path: "/"})
		}
		return undefined
	}

	useEffect(() => {
		if (timeout.current) {
			clearTimeout(timeout.current)
			timeout.current = undefined
		}

		if (token) timeout.current = setTimeout(expireToken, moment(token.expires).diff(moment()))
	}, [token])

	useEffect(() => {
		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current)
				timeout.current = undefined
			}
		}
	}, [])

	return <authContext.Provider value={[token, setToken, removeToken]}>{children}</authContext.Provider>
}

export function useTwoFactorAuthentication(): [
	AsyncResultIdleRequestState<Token, AuthError>,
	(code: string, twoFactorToken: string) => Promise<Result<Token, AuthError>>,
	AsyncResultIdleRequestState<TwoFactorToken, AuthError>,
	(twoFactorToken: string) => Promise<Result<TwoFactorToken, AuthError>>
] {
	const [, setToken] = useContext(authContext)

	const [state, authenticate] = useAsyncResultIdle(async function (code: string, twoFactorToken: string) {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		}
		const data = qs.stringify({verificationToken: twoFactorToken, code: code})
		const response = await post<SuccessResponse, ErrorResponse>(
			`${process.env.API_URL}/token/checkVerification`,
			data,
			config
		)
		return response
			.asyncMap(async (authResult) => {
				switch (authResult.token_type) {
					case TokenType.AccessToken:
						const token: Token = {
							type: TokenType.AccessToken,
							accessToken: authResult.access_token,
							expires: moment()
								.add(authResult.expires_in - 60, "seconds")
								.toDate(),
							isSSO: authResult.is_sso,
						}
						setToken(token)
						return token
					case TokenType.TwoFactorToken:
						throw new Error("2fa token not expected")
				}
			})
			.mapErr((e) => {
				if (e.errors) {
					return {error: e.errors.find((m) => !isUndefined(m.title))?.title || "Authentication Failure"}
				} else {
					return e.error_description ? {error: e.error_description, code: e.code} : {error: e.error, code: e.code}
				}
			})
	}, [])

	const [resendState, resend] = useAsyncResultIdle(async function (twoFactorToken: string) {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		}
		const data = qs.stringify({verificationToken: twoFactorToken})
		const response = await post<SuccessResponse, ErrorResponse>(
			`${process.env.API_URL}/token/createVerification`,
			data,
			config
		)
		return response
			.asyncMap(async (authResult) => {
				switch (authResult.token_type) {
					case TokenType.AccessToken:
						throw new Error("bearer token not expected")
					case TokenType.TwoFactorToken:
						const twoFactorTokenResponse = {
							type: TokenType.TwoFactorToken,
							verificationToken: authResult.verification_token,
							expires: moment()
								.add(authResult.expires_in - 60, "seconds")
								.toDate(),
							phone: authResult.phone,
						}
						return twoFactorTokenResponse
				}
			})
			.mapErr((e) => {
				if (e.errors) {
					return {error: e.errors.find((m) => !isUndefined(m.title))?.title || "Authentication Failure"}
				} else {
					return e.error_description ? {error: e.error_description, code: e.code} : {error: e.error, code: e.code}
				}
			})
	})

	return [state, authenticate, resendState, resend]
}

export function useAuthentication(): [AsyncResultIdleRequestState<Token | TwoFactorToken, AuthError>, AuthenticateFn] {
	const [, setToken] = useContext(authContext)

	const [state, authenticate] = useAsyncResultIdle(async function (
		username: string,
		password: string,
		assessmentToken: string
	) {
		const config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			auth: {
				username: username,
				password: password,
				assessmentToken: assessmentToken,
			},
		}

		const data = qs.stringify({grant_type: "client_credentials", assessment_token: assessmentToken})
		const response = await post<SuccessResponse, ErrorResponse>(`${process.env.API_URL}/token`, data, config)

		return response
			.asyncMap(async (authResult) => {
				switch (authResult.token_type) {
					case TokenType.AccessToken:
						const token = {
							type: TokenType.AccessToken,
							accessToken: authResult.access_token,
							expires: moment()
								.add(authResult.expires_in - 60, "seconds")
								.toDate(),
							isSSO: authResult.is_sso,
						}
						setToken(token)
						return token
					case TokenType.TwoFactorToken:
						const twoFactorTokenResponse = {
							type: TokenType.TwoFactorToken,
							verificationToken: authResult.verification_token,
							expires: moment()
								.add(authResult.expires_in - 60, "seconds")
								.toDate(),
							phone: authResult.phone,
						}
						return twoFactorTokenResponse
				}
			})
			.mapErr((e) => {
				if (e.errors) {
					return {error: e.errors.find((m) => !isUndefined(m.title))?.title || "Authentication Failure"}
				} else {
					return e.error_description ? {error: e.error_description, code: e.code} : {error: e.error, code: e.code}
				}
			})
	},
	[])

	return [state, authenticate]
}

export function useLogout() {
	const token = useAccessToken()
	const [, , removeToken] = useContext(authContext)
	const [, , remove] = useLocalStorage<ApiTokenPresent | undefined>("tutorialToken")
	const {addToast} = useToasts()

	return function () {
		deleteResource("token", token).then((result) => {
			if (result.isOk()) {
				if (window.zE) {
					// Turn off zendesk on logout
					window.zE("webWidget", "logout")
					window.zE("webWidget", "updateSettings", {
						webWidget: {
							contactForm: {
								suppress: true,
							},
						},
					})
				}
				remove()
				removeToken()
			} else {
				addToast("Something went wrong", {appearance: "error"})
			}
		})
	}
}

export function useIsAuthenticated(): boolean {
	const [token] = useContext(authContext)
	return !!token && token.type === TokenType.AccessToken
}

export function useIsSSOAuthenticated(): boolean {
	const [token] = useContext(authContext)
	return !!token && token.isSSO
}

export function useAccessToken() {
	const [token] = useContext(authContext)
	assertIsDefined(token)
	return token.accessToken
}

export function useUserType(userClaims?: Claims) {
	const claims = userClaims ?? useContext(claimsContext)
	assertIsDefined(claims)

	if (claims.userType) return claims.userType
	else {
		switch (claims.role) {
			case "super":
				return UserType.Unit
			case "compliance":
				return UserType.Unit
			case "compliance-readonly":
				return UserType.Unit
			case "admin":
				return UserType.Org
			default:
				return claims.role
		}
	}
}

export function useUserRole(userClaims?: Claims) {
	const claims = userClaims ?? useContext(claimsContext)
	assertIsDefined(claims)
	return claims.role
}

export function useUserId() {
	const claims = useContext(claimsContext)
	assertIsDefined(claims)
	return claims.userId
}

export function useIsOrgUser(claims?: Claims) {
	const type = useUserType(claims)
	return isOrgUser(type)
}

export function useIsBankUser(claims?: Claims) {
	const type = useUserType(claims)
	return isBankUser(type)
}

export function useIsPartnerUser() {
	const type = useUserType()
	return type === "partner"
}

export function useIsItUser() {
	const role = useUserRole()
	return role === "it"
}

function isTokenMatchesRole(role?: string, userClaims?: Claims) {
	const claims = userClaims ?? useContext(claimsContext)
	assertIsDefined(claims)
	return !role || claims.role === role
}

export function useIsBankAdminUser() {
	const userType = useUserType()
	return isBankUser(userType) && isTokenMatchesRole("bank")
}

export function useIsBankAdminOrOperationsUser() {
	const userType = useUserType()
	return isBankUser(userType) && (isTokenMatchesRole("bank-operations") || isTokenMatchesRole("bank"))
}

export function useIsUnitTypeUser(role?: string, claims?: Claims) {
	const userType = useUserType(claims)
	return isUnitUser(userType) && isTokenMatchesRole(role, claims)
}

export function useIsUnitSuperUser() {
	return useIsUnitTypeUser("super")
}

export function useIsUnitAdminUser(claims?: Claims) {
	return (
		useIsUnitTypeUser("super", claims) ||
		useIsUnitTypeUser("compliance", claims) ||
		useIsUnitTypeUser("unit-admin", claims)
	)
}

export function useIsUnitAdminReadonlyUser(claims?: Claims) {
	return (
		useIsUnitTypeUser("super", claims) ||
		useIsUnitTypeUser("compliance", claims) ||
		useIsUnitTypeUser("compliance-readonly", claims) ||
		useIsUnitTypeUser("unit-admin", claims)
	)
}

export function useIsUnitSalesUser() {
	return useIsUnitTypeUser("unit-sales")
}

export function useIsUnitComplianceUser() {
	return useIsUnitTypeUser("compliance")
}

export function useIsUnitUser(claims?: Claims) {
	return useIsUnitTypeUser(undefined, claims)
}

export function useIsOrgAdmin() {
	const claims = useContext(claimsContext)
	assertIsDefined(claims)
	return claims.role === "admin"
}

export function useIsOrgOrUnitAdminUser() {
	return useIsUnitAdminUser() || useIsOrgAdmin()
}

export function useIsOrgUnitPilot() {
	const claims = useContext(claimsContext)
	assertIsDefined(claims)
	return claims.isUnitPilot
}

export function useUserClaimsData(userClaims?: Claims) {
	const claims = userClaims ?? useContext(claimsContext)
	assertIsDefined(claims)
	return claims
}

export function useIsUser(userId: string) {
	return useUserId() === userId
}

type accessTokenResponse = {
	access_token: string
	expires_in: number
	token_type: TokenType.AccessToken
	is_sso: boolean
}

type twoFactorTokenResponse = {
	verification_token: string
	token_type: TokenType.TwoFactorToken
	expires_in: number
	phone: string
}

type SuccessResponse = accessTokenResponse | twoFactorTokenResponse

type ErrorResponse = {
	error: string
	errors: Array<{status: string; title: string}>
	error_description: string
	code?: string
}
