import classNames from "classnames"
import React from "react"
import {DepositProductFieldViewProps} from "../types"
import numberal from "numeral"
import {isBoolean} from "lodash"
import Icon from "../../../../components/Icon/Icon"
import ReactTooltip from "react-tooltip"

export default function DepositProductFieldView({
	label,
	subLabel,
	value,
	subValue,
	inputPrefix = "",
	inputSuffix = "",
	inputFormat = "0,0",
	infoIconTooltipText,
	enableField,
}: DepositProductFieldViewProps) {
	const getValue = () => {
		if (isBoolean(value)) {
			if (value) {
				return "Enabled"
			} else {
				return "Disabled"
			}
		}
		return value
	}

	const getNumeralValue = (val: any) => {
		if (typeof val === "number") {
			if (/[a-zA-Z]/.test(inputPrefix)) {
				return `${inputPrefix} ${numberal(val).format(`${inputFormat}${inputSuffix}`)}`
			}
			if (/[a-zA-Z]/.test(inputSuffix)) {
				return `${numberal(val).format(`${inputPrefix}${inputFormat}`)} ${inputSuffix}`
			}
			return numberal(val).format(`${inputPrefix}${inputFormat}${inputSuffix}`)
		}
		return `${inputPrefix} ${val} ${inputSuffix}`
	}

	const getSubValue = () => {
		if (subValue !== undefined && subValue !== "") {
			return getNumeralValue(subValue)
		} else if (value !== undefined && !isBoolean(value)) {
			return getNumeralValue(value)
		}
		return "Not set"
	}

	const getDynamicColorClass = () => {
		const value = getValue()
		if (enableField && value === "Enabled") return "color-enabled"
		else if (enableField && value === "Disabled") return "color-disabled"
		return ""
	}

	return (
		<div className="textfield">
			<div className="content">
				<div className="label">
					<span className="label-text">{label}</span>
					{subValue !== undefined && <span className={classNames("sub-label-text", getValue())}>{getValue()}</span>}
				</div>
				<div className="value">
					{enableField ? (
						<span
							className={classNames("value-text", getDynamicColorClass())}
						>{`${inputPrefix} ${getValue()} ${inputSuffix}`}</span>
					) : (
						<span className={classNames("value-text", getSubValue() === "Not set" && "not-set")}>{getSubValue()}</span>
					)}
					{subLabel && <span className="sub-label-text">{subLabel}</span>}
					{infoIconTooltipText && (
						<span className="info-circle-icon">
							<Icon
								icon="information-circle--interface-essential"
								size={12}
								data-tip="React-tooltip"
								data-for="dynamic-clearing-ach-tooltip"
							/>
							<ReactTooltip className="tooltip-info" id="dynamic-clearing-ach-tooltip" effect="solid">
								{infoIconTooltipText}
							</ReactTooltip>
						</span>
					)}
				</div>
			</div>
		</div>
	)
}
