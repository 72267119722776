import {getResource} from "./common"
import {Claims} from "../services/auth"
import {get} from "../utilities/http"

export type Permission = {
	resource: string
	actions: [string]
}

export type Permissions = {
	type: "permissions"
	attributes: {
		permissions: [Permission]
	}
}

export type AuthData = {
	claims: Claims
	passwordSetAt: Date
}

export async function getPermissions(accessToken: string) {
	const result = await getResource<Permissions>("permissions", accessToken)

	return result.map((v) => v.data)
}

export async function getClaims(accessToken: string) {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	}
	return await get<AuthData, Error>(`${process.env.API_URL}/token/v2`, {headers})
}
