import BlueRidgeBankLogo from "../images/bankLogos/blue-ridge-bank-logo.svg"
import ChoiceFinancialGroupLogo from "../images/bankLogos/choice-financial-group-logo.svg"
import PiermontBankLogo from "../images/bankLogos/piermont-bank-logo.svg"
import ThreadBankLogo from "../images/bankLogos/thread-bank-logo.svg"
import MvbBankLogo from "../images/bankLogos/mvb-bank-logo.svg"
import {isSandboxOrLocalEnv} from "./environment"

const BANKS: Record<string, BankDetails> = {
	"1": createBankDetails("Blue Ridge", BlueRidgeBankLogo),
	"2": createBankDetails("Choice Financial Group", ChoiceFinancialGroupLogo),
	"4": createBankDetails("Piermont", PiermontBankLogo),
	"5": createBankDetails("Thread", ThreadBankLogo),
	"6": createBankDetails("MVB", MvbBankLogo),
}

const SANDBOX_BANKS: Record<string, BankDetails> = {
	"1": createBankDetails("Bank", ""),
	"2": createBankDetails("Choice Bank", ChoiceFinancialGroupLogo),
	"16": createBankDetails("Leumi Bank", ""),
}

interface BankDetails {
	name: string
	logo: string
}

function createBankDetails(name: string, logo: string): BankDetails {
	return {name, logo}
}

export function getBankDetails(bankId: string): BankDetails {
	return (isSandboxOrLocalEnv() ? SANDBOX_BANKS[bankId] : BANKS[bankId]) || createBankDetails("", "")
}
