import React from "react"
import {CombinedAssignmentItem} from "../AssignmentNavigator/AssignmentNavigator"
import {AssignmentNavigatorItemsList} from "../AssignmentNavigatorItemsList/AssignmentNavigatorItemsList"

export type GroupedAssignments = {
	title: string
	assignment: CombinedAssignmentItem[]
}

interface AssignmentNavigatorGroupedByItemStageProps {
	groupedAssignments: GroupedAssignments[]
	navigatorItemRef: React.RefObject<HTMLLIElement>
}

export function AssignmentNavigatorWithGrouping({
	groupedAssignments,
	navigatorItemRef,
}: AssignmentNavigatorGroupedByItemStageProps) {
	return (
		<>
			{groupedAssignments.map(({title, assignment}, index) => (
				<div key={index} className="assignment-navigator-with-grouping-group-container">
					<div className="assignment-navigator-with-grouping-items-title-and-count">
						<span className="assignment-navigator-with-grouping-items-title">{title}</span>({assignment.length})
					</div>
					<AssignmentNavigatorItemsList
						assignments={assignment}
						navigatorItemRef={navigatorItemRef}
						noItemsMessage={`No ${title} Items`}
					/>
				</div>
			))}
		</>
	)
}
