import React, {useEffect} from "react"
import {useAccessToken, useUserType} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {findBins, Bin} from "../../resources/bin"
import {useModal} from "react-modal-hook"
import {ErrorDocument, Resource} from "../../resources/common"
import {OrgName} from "../OrgName/OrgName"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import EditBinModal from "./EditBinModal"
import {useQueryState} from "use-location-state"
import {Filter} from "../Filter/Filter"
import moment from "moment"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResult} from "../../types/asyncResult"
import SearchBox from "../../components/SearchBox/SearchBox"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {BankName} from "../BankName/BankName"

interface BinsProps {
	limit?: number
	enableTitle?: boolean
	fullHeight?: boolean
	refreshToken?: number
}

function BinRow({bin, included, refresh}: {bin: Bin; included?: Resource[]; refresh: () => void}) {
	const [showModal, hideModal] = useModal(() => <EditBinModal BinId={bin.id} close={hideModal} refresh={refresh} />)

	return (
		<DataTableRow onClick={showModal}>
			<DataTableCell>{bin.id}</DataTableCell>
			<OrgName orgId={bin.relationships.org.data.id} included={included} />
			<DataTableCell>
				<BankName bankId={bin.relationships.bank.data.id} included={included} />
			</DataTableCell>
			<DataTableCell>{bin.attributes.name}</DataTableCell>
			<DataTableCell>{bin.attributes.institutionId}</DataTableCell>
			<DataTableCell>{bin.attributes.bin}</DataTableCell>
			<DataTableCell>{bin.attributes.binType}</DataTableCell>
			<DataTableCell>{bin.attributes.product}</DataTableCell>
			<DataTableCell>{bin.attributes.printer}</DataTableCell>
			<DataTableCell>{bin.attributes.brand}</DataTableCell>
		</DataTableRow>
	)
}

export function Bins({limit = 25, fullHeight, enableTitle, refreshToken}: BinsProps) {
	const prefix = "bin"
	const accessToken = useAccessToken()
	const userType = useUserType()
	const [_, refresh] = useRefreshToken()

	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) => findBins(accessToken, offset, limit, filteredOrgs, query, "org,bank"),
		(x) => x.data.length,
		[refreshToken, _, filteredOrgs.join(","), query],
		prefix
	)

	const orgs =
		userType === "unit"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()
	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Bins", 500, onSearch)
	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	return (
		<DataTableCard className={"bins-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={searchBox}
				filters={[orgFilter]}
				title={enableTitle ? "Bins" : null}
			/>
			<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
				{({value: bins}) => {
					return (
						<div className={"bins-table"}>
							<DataTable
								isEmpty={bins.data.length === 0}
								fullHeight={fullHeight}
								stickyAction={fullHeight}
								noContentText={"No bins found"}
							>
								<DataTableHead>
									<DataTableRow>
										<DataTableCell>Id</DataTableCell>
										<DataTableCell>Org</DataTableCell>
										<DataTableCell>Bank</DataTableCell>
										<DataTableCell>name</DataTableCell>
										<DataTableCell>Institution Id</DataTableCell>
										<DataTableCell>Bin</DataTableCell>
										<DataTableCell>Type</DataTableCell>
										<DataTableCell>Product</DataTableCell>
										<DataTableCell>Printer</DataTableCell>
										<DataTableCell>Brand</DataTableCell>
									</DataTableRow>
								</DataTableHead>
								<DataTableBody>
									{bins.data.map((bin) => {
										return <BinRow key={bin.id} bin={bin} refresh={refresh as () => void} />
									})}
								</DataTableBody>
							</DataTable>
							<PagingNavBar
								hasResults={hasResults}
								hasPrev={hasPrev}
								hasNext={hasNext}
								prev={prev}
								next={next}
								isShow={true}
								meta={bins.meta}
							/>
						</div>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
