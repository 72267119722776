import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {getWebhook, updateWebhook, Webhook, WebhookSubscriptionType} from "../../resources/webhook"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import Loading from "../../components/Loading/Loading"
import {ErrorDocument} from "../../resources/common"
import {noop, startCase} from "lodash"

type CloseFunc = () => void
type RefreshFunc = () => void

interface Props {
	close: CloseFunc
	refresh: RefreshFunc
	webhook: Webhook
	readOnly?: boolean
}

export function EditWebhookModalContent({close, refresh, webhook, readOnly = false}: Props) {
	const accessToken = useAccessToken()
	const [state, update] = useAsyncResultIdle(updateWebhook)
	const [label, setLabel] = useState(webhook.attributes.label)
	const [url, setUrl] = useState(webhook.attributes.url)
	const [deliveryMode] = useState(webhook.attributes.deliveryMode)
	const [webhookToken, setWebhookToken] = useState(webhook.attributes.token ?? "")
	const [contentType, setContentType] = useState<"Json" | "JsonAPI">(webhook.attributes.contentType)
	const [subscriptionType, setSubscriptionType] = useState<keyof typeof WebhookSubscriptionType>(
		webhook.attributes.subscriptionType
	)
	const [includeResources, setIncludeResources] = useState(webhook.attributes.includeResources)
	return (
		<AsyncResultModal
			title={readOnly ? "View webhook" : "Edit webhook"}
			onSubmit={() =>
				update(
					accessToken,
					label,
					url,
					webhookToken,
					contentType,
					deliveryMode,
					includeResources,
					webhook,
					subscriptionType
				)
			}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Update"
			successText="Updated"
			errorToText={(err) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
			onSuccess={refresh}
			readOnly={readOnly}
		>
			<div className="field">
				<label className="label">Label</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="A label describing the webhook"
						value={label}
						required
						onChange={readOnly ? noop : (e) => setLabel(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Url</label>
				<div className="control">
					<input
						className="input"
						type="url"
						placeholder="https://example.com"
						value={url}
						required
						onChange={readOnly ? noop : (e) => setUrl(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Token</label>
				<div className="control">
					<input
						className="input"
						type="token"
						placeholder="Optional token to sign the webhook payload"
						value={webhookToken}
						onChange={readOnly ? noop : (e) => setWebhookToken(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Subscription Type</label>
				<div className="control">
					<div className="select">
						<select
							onChange={
								readOnly ? noop : (e) => setSubscriptionType(e.target.value as keyof typeof WebhookSubscriptionType)
							}
							value={subscriptionType}
						>
							{Object.entries(WebhookSubscriptionType).map(([key, value]) => {
								return (
									<option key={key} value={key}>
										{startCase(value)}
									</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Request Content Type</label>
				<div className="control">
					<label className="radio">
						<input
							type="radio"
							name="answer"
							defaultChecked={contentType == "Json"}
							onClick={readOnly ? noop : () => setContentType("Json")}
						/>
						Json <span className="is-family-code has-background-grey-lighter">application/json</span>
					</label>
				</div>
				<div className="control">
					<label className="radio">
						<input
							type="radio"
							name="answer"
							defaultChecked={contentType == "JsonAPI"}
							onClick={readOnly ? noop : () => setContentType("JsonAPI")}
						/>
						JsonAPI <span className="is-family-code has-background-grey-lighter">application/vnd.api+json</span>
					</label>
				</div>
			</div>
			<div className="field">
				<label className="label centered">
					<input
						type="checkbox"
						checked={includeResources}
						onChange={readOnly ? noop : (e) => setIncludeResources(e.target.checked)}
					/>{" "}
					Include Full resource in events
					<label className="tooltip-container">
						<div data-tip="React-tooltip" data-event="click focus">
							<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
							<ReactTooltip
								place="right"
								effect="solid"
								className="tooltip-info"
								globalEventOff="click"
								border={true}
								borderColor="#C4C4C4"
								backgroundColor="#F5F5F5"
								textColor="black"
								data-html={true}
							>
								When this checkbox is checked, every webhook event that has a relationship with a resource will have the
								full resource included in the payload of the event (see docs{" "}
								<a rel="noreferrer" target="_blank" href="https://docs.unit.co/webhooks#include-resource">
									here
								</a>
								)
							</ReactTooltip>
						</div>
					</label>
				</label>
			</div>
		</AsyncResultModal>
	)
}

interface EditWebhookModalProps {
	webhookId: string
	close: CloseFunc
	refresh: RefreshFunc
	readOnly?: boolean
}
export default function EditWebhookModal({webhookId, refresh, close, readOnly = false}: EditWebhookModalProps) {
	const accessToken = useAccessToken()

	const webhookAsync = useAsyncResult<Webhook, ErrorDocument>(() => getWebhook(accessToken, webhookId))

	return webhookAsync.match(
		() => <Loading />,
		(webhook) => <EditWebhookModalContent close={close} refresh={refresh} webhook={webhook} readOnly={readOnly} />,
		(_) => null
	)
}
