import React from "react"
import {Box} from "../../containers/Box/Box"
import {ValueAndUnits} from "../ValueAndUnits/ValueAndUnits"

export function BalancesBox({balances}: {balances: number}) {
	return (
		<Box className="end-customer-deposits-box-container">
			<span className="title">Balances</span>
			<ValueAndUnits
				value={balances}
				unitsPosition="Prefix"
				units="$"
				className={"value"}
				formatMethod="letter_suffix"
			/>
		</Box>
	)
}
