import React, {useEffect} from "react"
import {useAccessToken, useIsUnitUser, useUserType} from "../../services/auth"
import {useParams} from "react-router-dom"
import TitleBar from "../../components/TitleBar/TitleBar"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"
import {getTransaction, Transaction} from "../../resources/transaction"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {
	Dispute,
	getDispute,
	setDisputeAction,
	showDeferredProvisionalCredit,
	StatusTypeValue,
	updateDispute,
} from "../../resources/dispute"
import {ErrorDocument} from "../../resources/common"
import {kebabCase} from "lodash"
import classNames from "classnames"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useToasts} from "react-toast-notifications"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {hasPermission} from "../../services/permission"
import {useModal} from "react-modal-hook"
import {DisputeDecisionModal} from "../../components/Disputes/DisputeDecisionModal"
import {TransactionInformation} from "../../components/Disputes/TransactionInformation"
import {DisputeInformation} from "../../components/Disputes/Dispute"

let accessToken = ""

const disputeLostPrefillReasons = [
	"No Chargeback Rights - Tokenization",
	"No Chargeback Rights - Chip Terminal",
	"No Chargeback Rights - Chip Present",
	"No Chargeback Rights - Visa Secure",
	"No Chargeback Rights - Fuel Station",
	"No PreArb Response - Account Closed",
	"Terminal Response No Error - Account Closed",
	"Under Business Minimum",
]

function DisputeInternal({dispute, value: tx, refresh}: {dispute: Dispute; value: Transaction; refresh: () => void}) {
	const {addToast} = useToasts()
	const [stateUpdate, update] = useAsyncResultIdle(updateDispute)
	const [stateAction, action] = useAsyncResultIdle(setDisputeAction)
	const customerId = dispute.relationships.customer.data.id
	const canUpdate = hasPermission("dispute", "update")
	const [showResolveLostModal, hideResolveLostModal] = useModal(() => (
		<DisputeDecisionModal
			action="resolve-lost"
			id={dispute.id}
			title="Resolve Lost"
			options={disputeLostPrefillReasons}
			close={hideResolveLostModal}
			onSuccess={refresh}
		/>
	))
	function _useEffect(state: AsyncResultIdleRequestState<Dispute, ErrorDocument>) {
		return () => {
			if (state.isErr()) {
				const errors = state.error.errors[0]
				const msg = errors.detail ? errors.detail : errors.title
				addToast(msg, {appearance: "error"})
			} else if (state.isOk()) {
				addToast("Dispute Updated Successfully", {appearance: "success"})
				refresh()
			}
		}
	}

	useEffect(_useEffect(stateAction), [stateAction])
	useEffect(_useEffect(stateUpdate), [stateUpdate])

	function ActionButtons() {
		const userType = useUserType()

		function Button({
			label,
			route,
			onClick,
		}: {label: string; route: string; onClick?: undefined} | {label: string; route?: undefined; onClick: Function}) {
			const buttonClickAction = () => (route ? action(accessToken, dispute.id, route) : onClick ? onClick() : null)
			const button = (
				<button className="button is-light" id={label} onClick={buttonClickAction}>
					{label}
				</button>
			)
			return (
				<td>
					{stateAction.match(
						() => button,
						() => (
							<button className="button is-light is-loading">Updating...</button>
						),
						() => button,
						() => button
					)}
				</td>
			)
		}

		if (customerId && userType === "unit") {
			return (
				<div className="card-content">
					<div className="table-container">
						<table className="table is-narrow">
							<tbody>
								<tr>
									{!showDeferredProvisionalCredit(dispute) && (
										<Button label={"Credit Provisionally"} route={"credit-provisionally"} />
									)}
									<Button label={"Deny"} route={"deny"} />
									<Button label={"Resolve Won"} route={"resolve-won"} />
									<Button label={"Resolve Lost"} onClick={showResolveLostModal} />
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		return null
	}

	return (
		<>
			<TitleBar
				title={`#${dispute.id}`}
				breadcrumbs={[{text: "Disputes", path: "/disputes"}]}
				status={{
					text: StatusTypeValue(dispute.attributes.status),
					className: classNames("disputes-status", kebabCase(dispute.attributes.status)),
				}}
				meta={[
					{
						text: "Customer",
						icon: "user-geometric-action---users",
						path: `/customers/business/${dispute.relationships.customer.data.id}`,
					},
					{
						text: "Account",
						icon: "bank-account--business-products",
						path: `/accounts/${dispute.relationships.account.data.id}`,
					},
					{
						text: "Transaction",
						icon: "money-transfer--business-products",
						path: `/transaction/${dispute.relationships.account.data.id}/${dispute.relationships.transaction.data.id}`,
					},
				]}
			>
				<ActionButtons />
			</TitleBar>
			<MainSectionLayout
				outerWrapperClassname="dispute-full-page-wrapper"
				renderAfter={<TransactionInformation transaction={tx} />}
				fullWidth
			>
				<DisputeInformation
					accessToken={accessToken}
					dispute={dispute}
					canUpdate={canUpdate}
					refresh={refresh}
					update={update}
				/>
			</MainSectionLayout>
		</>
	)
}

function DisputeTransaction({value: dispute, refresh}: {value: Dispute; refresh: () => void}) {
	const transactionAsync = useAsyncResult<Transaction, ErrorDocument>(() =>
		getTransaction(
			accessToken,
			String(dispute.relationships.account.data.id),
			String(dispute.relationships.transaction.data.id)
		)
	)
	return (
		<AsyncResultComponent dispute={dispute} asyncResult={transactionAsync} refresh={refresh}>
			{({value: value}) => {
				return <DisputeInternal dispute={dispute} value={value as Transaction} refresh={refresh} />
			}}
		</AsyncResultComponent>
	)
}

export default function DisputePage() {
	accessToken = useAccessToken()
	const isUnitUser = useIsUnitUser()
	const [refreshToken, refresh] = useRefreshToken()
	const {disputeId = ""} = useParams<{disputeId: string}>()
	const disputeAsync = useAsyncResult(
		() => getDispute(accessToken, disputeId, isUnitUser ? {dispute: "externalId"} : undefined),
		[refreshToken]
	)

	return (
		<AsyncResultComponent asyncResult={disputeAsync} refresh={refresh}>
			{({value: value}) => {
				return <DisputeTransaction value={value as Dispute} refresh={refresh} />
			}}
		</AsyncResultComponent>
	)
}
