import classNames from "classnames"
import {kebabCase, startCase} from "lodash"
import React from "react"
import Skeleton from "react-loading-skeleton"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import Card from "../../containers/Card/Card"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Account} from "../../resources/account"
import {
	CheckOriginationCounterparty,
	CheckPayment,
	CheckPaymentAdditionalVerificationStatus,
	CheckPaymentsStatus,
	getCheckPayment,
	getCheckPaymentImage,
} from "../../resources/checkPayments"
import {Address, ErrorDocument, OkDocument} from "../../resources/common"
import {Customer} from "../../resources/customer"
import {Org} from "../../resources/org"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {CheckImageCard} from "../CheckDeposits/CheckDepositInfo"
import {DrawerHeader} from "../Drawer/Drawer"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardKey, KeyValueCardValue} from "../KeyValueCard/KeyValueCard"
import IconTag from "../Tag/IconTag"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import numeral from "numeral"
import {LinkMetaBox, LinkMetaBoxContainer} from "../LinkMetaBox/LinkMetaBox"
import {useModal} from "react-modal-hook"
import Icon from "../Icon/Icon"
import {ReturnCheckPaymentModal} from "./ReturnCheckPaymentModal"
import moment from "moment"
import {CustomerCard} from "../Customers/CustomerCard"
import {BeneficialOwnerCards} from "../BeneficialOwners/BeneficalOwnerCard"
import {AccountCard} from "../Accounts/AccountCard"
import {UserType, useUserType} from "../../services/auth"
import {CheckImageSide} from "../../resources/checkDeposits"
import {addressText} from "../../utilities/helpers"
import Button from "../Button/Button"
import {ApproveCheckPaymentModal} from "./ApproveCheckPaymentModal"

enum ActionButtons {
	Reject = "Reject",
	Approve = "Approve",
	Return = "Return",
}

export const getButtonsToShow = (
	status: keyof typeof CheckPaymentsStatus,
	userType: string,
	additionalVerificationStatus: CheckPaymentAdditionalVerificationStatus
): ActionButtons[] => {
	const buttons: ActionButtons[] = []
	switch (userType) {
		case UserType.Org:
			if (status === CheckPaymentsStatus.Processed || status === CheckPaymentsStatus.PendingReview) {
				buttons.push(ActionButtons.Return)
			}
			if (additionalVerificationStatus === CheckPaymentAdditionalVerificationStatus.Required) {
				buttons.push(ActionButtons.Approve)
			}
			return buttons
		case UserType.Unit:
			if (status === CheckPaymentsStatus.Processed || status === CheckPaymentsStatus.PendingReview) {
				buttons.push(ActionButtons.Return)
			}
			return buttons
		default:
			return buttons
	}
}

interface CheckPaymentProps {
	checkPaymentId: string
	checkPaymentAsyncResult?: AsyncResultRequestState<OkDocument<CheckPayment>, ErrorDocument>
	accessToken: string
	useDrawer: boolean
	refresh: () => void
	org?: Org
	customers?: Customer[]
	accounts?: Account[]
}

function CheckPaymentPending() {
	return (
		<div className="check-content">
			<div className="check-image-card">
				<a className="single-check-deposit-link-with-icon">
					<Skeleton />
				</a>

				<div className="check-image-card-container">
					<div className={"skeleton-placeholder"}>
						<Skeleton />
					</div>
				</div>

				<div className="check-image-card-preview-container">
					<Skeleton />
					<div className="check-image-card-preview-container-wrapper">
						<Skeleton />
					</div>
				</div>
			</div>

			<KeyValueCard>
				<KeyValueCardContainer>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
				</KeyValueCardContainer>
			</KeyValueCard>

			<KeyValueCard>
				<KeyValueCardContainer>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}

export function isReturnWindowValid(returnCutoffTime: Date) {
	const now = moment.utc()
	const cutoff = moment.utc(returnCutoffTime)
	return cutoff.isAfter(now)
}

function CheckPaymentInternal({
	checkPayment,
	useDrawer,
	refresh,
	account,
	accessToken,
	customers,
	org,
}: {
	checkPayment: CheckPayment
	accessToken: string
	refresh: () => void
	useDrawer: boolean
	customers?: Customer[]
	org?: Org
	account?: Account
}) {
	const userType = useUserType()
	const {attributes} = checkPayment
	const id = checkPayment.id
	const status = attributes.status
	const pendingReviewReasons = attributes.pendingReviewReasons
	const transactionId = checkPayment.relationships.transaction?.data.id
	const customerId = checkPayment.relationships.customer?.data.id
	const accountId = checkPayment.relationships.account.data.id
	const counterpartyDetails = {
		payeeName: checkPayment.attributes.payeeName,
		counterpartyRoutingNumber: checkPayment.attributes.counterpartyRoutingNumber,
		counterpartyAccountNumber: checkPayment.attributes.counterpartyAccountNumber,
	}
	const originated = checkPayment.attributes.originated
	const frontImageState = useAsyncResult(() => getCheckPaymentImage({accessToken, id, side: CheckImageSide.Front}))
	const backImageState = useAsyncResult(() => getCheckPaymentImage({accessToken, id, side: CheckImageSide.Back}))
	const additionalVerificationStatus = checkPayment.attributes.additionalVerificationStatus
	const frontImage = frontImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)
	const backImage = backImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)

	return (
		<>
			{useDrawer && (
				<DrawerHeader title={`Check #${checkPayment.id}`}>
					<div className={classNames("check-payments-status", kebabCase(status))}>{startCase(status)}</div>
				</DrawerHeader>
			)}
			<div className="check-content">
				{useDrawer && (
					<CheckImageCard
						frontImage={frontImage}
						backImage={backImage}
						id={checkPayment.id}
						status={status}
						originated={originated}
					/>
				)}
				{pendingReviewReasons && <CheckPaymentReviewReasons pendingReviewReasons={pendingReviewReasons} />}
				{status == CheckPaymentsStatus.InDelivery && <CheckPaymentDeliveryDetails checkPayment={checkPayment} />}
				<CheckPaymentDetails checkPayment={checkPayment} />
				{account && <AccountCard account={account} accessToken={accessToken} showLimits={!useDrawer} />}
				{customers?.map((customer) => {
					return (
						<React.Fragment key={customer.id}>
							<CustomerCard org={org} customer={customer} customerId={customer.id} />
							{customer.relationships.application && customer.type === "businessCustomer" && (
								<BeneficialOwnerCards
									applicationId={customer.relationships.application?.data.id}
									accessToken={accessToken}
								/>
							)}
						</React.Fragment>
					)
				})}
				<CounterPartyDetails
					payeeName={counterpartyDetails.payeeName}
					routingNumber={counterpartyDetails.counterpartyRoutingNumber}
					accountNumber={counterpartyDetails.counterpartyAccountNumber}
					checkOriginationCounterparty={checkPayment.attributes.counterparty}
				/>
				{useDrawer && <RelatedSection customerId={customerId} accountId={accountId} transactionId={transactionId} />}
				{useDrawer &&
				checkPayment.attributes.returnCutoffTime &&
				isReturnWindowValid(checkPayment.attributes.returnCutoffTime) ? (
					<CheckActionButtons
						checkPaymentId={checkPayment.id}
						refresh={refresh}
						buttons={getButtonsToShow(status, userType, additionalVerificationStatus)}
						title=""
						disabled={false}
					/>
				) : null}
			</div>
		</>
	)
}

export function CheckPaymentComponent({
	checkPaymentId,
	checkPaymentAsyncResult,
	accessToken,
	useDrawer,
	refresh,
	customers,
	accounts,
}: CheckPaymentProps) {
	const checkPaymentAsyncResultReq =
		checkPaymentAsyncResult ||
		useAsyncResult(() => getCheckPayment(accessToken, checkPaymentId, "org,customer,account"))

	return (
		<AsyncResultComponent asyncResult={checkPaymentAsyncResultReq} pendingComponent={<CheckPaymentPending />}>
			{({value: checkPayment}) => {
				const checkPaymentCustomerIds = [
					...(checkPayment.data.relationships.customers?.data.map(({id}) => id) ?? []),
					checkPayment.data.relationships.customer?.data.id,
				]
				const checkPaymentCustomers = customers?.filter(({id}) => checkPaymentCustomerIds.includes(id))
				const account = accounts?.find(({id}) => id === checkPayment.data.relationships.account.data.id)
				return (
					<CheckPaymentInternal
						useDrawer={useDrawer}
						checkPayment={checkPayment.data}
						customers={checkPaymentCustomers}
						accessToken={accessToken}
						refresh={refresh}
						account={account}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}

function AddressText({address}: {address: Address}) {
	return <span className={"address-placeholder"}>{addressText(address)}</span>
}

function CounterPartyDetails({
	payeeName,
	routingNumber,
	accountNumber,
	checkOriginationCounterparty,
}: {
	payeeName?: string
	routingNumber?: string
	accountNumber?: string
	checkOriginationCounterparty?: CheckOriginationCounterparty
}) {
	const {name, address, counterpartyMoved} = checkOriginationCounterparty || {}
	return (
		<KeyValueCard title="Counterparty Details">
			<KeyValueCardContainer>
				{(name || payeeName) && (
					<>
						<KeyValueCardKey> Payee Name</KeyValueCardKey>
						<KeyValueCardValue> {name ?? payeeName} </KeyValueCardValue>
					</>
				)}
				{address && (
					<>
						<KeyValueCardKey> Address</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<AddressText address={address} />{" "}
						</KeyValueCardValue>
					</>
				)}
				{routingNumber && (
					<>
						<KeyValueCardKey> Routing Number</KeyValueCardKey>
						<KeyValueCardValue> {routingNumber} </KeyValueCardValue>
					</>
				)}
				{accountNumber && (
					<>
						<KeyValueCardKey> Account Number</KeyValueCardKey>
						<KeyValueCardValue> {accountNumber} </KeyValueCardValue>
					</>
				)}
				{counterpartyMoved && (
					<>
						<KeyValueCardKey> Counterparty Moved</KeyValueCardKey>
						<KeyValueCardValue> {counterpartyMoved.toString()} </KeyValueCardValue>
					</>
				)}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

function RelatedSection({
	accountId,
	transactionId,
	customerId,
}: {
	accountId?: string
	transactionId?: number
	customerId?: string
}) {
	return (
		<div className="check-payment-related-section">
			<KeyValueCard>
				<KeyValueCardContainer>
					<LinkMetaBoxContainer>
						<LinkMetaBox text={"Account"} icon={"bank-account--business-products"} path={`/accounts/${accountId}`} />
						{accountId && transactionId && (
							<LinkMetaBox
								text={"Transaction"}
								icon={"money-transfer--business-products"}
								path={`/transaction/${accountId}/${transactionId}`}
							/>
						)}

						{customerId && (
							<LinkMetaBox
								key={`tag-${customerId}`}
								text={`Customer #${customerId}`}
								icon={"user-geometric-action-money-fund---users"}
								path={`/customers/${customerId}`}
							/>
						)}
					</LinkMetaBoxContainer>
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}

function CheckPaymentReviewReasons({pendingReviewReasons}: {pendingReviewReasons: string[]}) {
	return (
		<Card title="Review Reasons" className="check-payments-review-card">
			<div className="check-payments-review-reason">
				{pendingReviewReasons.map((reviewReason, i) => {
					return (
						<React.Fragment key={i}>
							<IconTag icon="award-flag-rewards-rating-filled" iconSize={15}>
								{reviewReason}
							</IconTag>
						</React.Fragment>
					)
				})}
			</div>
		</Card>
	)
}

export function CheckActionButtons({
	checkPaymentId,
	refresh,
	buttons,
	title,
	disabled,
}: {
	checkPaymentId: string
	refresh: () => void
	buttons: ActionButtons[]
	disabled: boolean
	title: string
}) {
	if (!buttons) return null

	const [showReturnCheck, hideReturnCheck] = useModal(() => (
		<ReturnCheckPaymentModal id={checkPaymentId} closeModal={hideReturnCheck} onSuccess={refresh} />
	))

	const [showApproveCheck, hideApproveCheck] = useModal(() => (
		<ApproveCheckPaymentModal closeModal={hideApproveCheck} id={checkPaymentId} onSuccess={refresh} />
	))

	return (
		<div className="drawer-action-buttons buttons is-right">
			{buttons.includes(ActionButtons.Return) && (
				<button
					className="button is-danger is-outlined"
					onClick={() => showReturnCheck()}
					disabled={disabled}
					title={title}
				>
					<span className="icon">
						<Icon icon="interface-validation-check--interface-essential" size={12} />
					</span>
					<span> Mark as returned </span>
				</button>
			)}
			{buttons.includes(ActionButtons.Approve) && (
				<Button onClick={showApproveCheck} theme="success">
					Approve
				</Button>
			)}
		</div>
	)
}

function CheckPaymentDeliveryDetails({checkPayment}: {checkPayment: CheckPayment}) {
	const {expectedDelivery, deliveryStatus, trackedAt, postalCode} = checkPayment.attributes
	return (
		<div>
			<KeyValueCard title={`Estimated Arrival: ${moment(expectedDelivery).format("dddd L")}`}>
				<KeyValueCardContainer>
					{deliveryStatus && (
						<>
							<KeyValueCardKey> Delivery Status</KeyValueCardKey>
							<KeyValueCardValue> {startCase(deliveryStatus)} </KeyValueCardValue>
						</>
					)}
					{trackedAt && (
						<>
							<KeyValueCardKey> Tracked At</KeyValueCardKey>
							<KeyValueCardValue> {moment(trackedAt).format("dddd L LT")} </KeyValueCardValue>
						</>
					)}
					{postalCode && (
						<>
							<KeyValueCardKey> Postal Code</KeyValueCardKey>
							<KeyValueCardValue> {postalCode} </KeyValueCardValue>
						</>
					)}
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}

function CheckPaymentDetails({checkPayment}: {checkPayment: CheckPayment}) {
	const {
		amount,
		checkNumber,
		tags,
		createdAt,
		description,
		returnCutoffTime,
		returnStatusReason,
		additionalVerificationStatus,
		sendAt,
		memo,
		rejectReason,
		originated,
	} = checkPayment.attributes
	const ORIGINATED_DISPLAY_VALUE = "True"
	return (
		<div className="check-payment-details">
			<KeyValueCard title={"Check Details"}>
				<KeyValueCardContainer>
					{checkNumber && (
						<>
							<KeyValueCardKey> Check Number</KeyValueCardKey>
							<KeyValueCardValue> {checkNumber} </KeyValueCardValue>
						</>
					)}

					{amount && (
						<>
							<KeyValueCardKey> Check Amount</KeyValueCardKey>
							<KeyValueCardValue> {numeral(amount / 100).format("$0,0.00")} </KeyValueCardValue>
						</>
					)}

					{createdAt && (
						<>
							<KeyValueCardKey> Created At</KeyValueCardKey>
							<KeyValueCardValue>{moment(createdAt).format("L LT")}</KeyValueCardValue>
						</>
					)}

					{sendAt && (
						<>
							<KeyValueCardKey> Send At</KeyValueCardKey>
							<KeyValueCardValue>{moment(sendAt).format("L LT")}</KeyValueCardValue>
						</>
					)}

					{tags && (
						<>
							<KeyValueCardKey> Tags</KeyValueCardKey>
							<KeyValueCardValue>
								<TagsViewerKeyValue tags={tags} />
							</KeyValueCardValue>
						</>
					)}

					{description && (
						<>
							<KeyValueCardKey> Description</KeyValueCardKey>
							<KeyValueCardValue> {description} </KeyValueCardValue>
						</>
					)}

					{memo && (
						<>
							<KeyValueCardKey> Memo</KeyValueCardKey>
							<KeyValueCardValue> {memo} </KeyValueCardValue>
						</>
					)}

					{returnCutoffTime && (
						<>
							<KeyValueCardKey> Return Cutoff Time</KeyValueCardKey>
							<KeyValueCardValue>{moment(returnCutoffTime).format("L LT")}</KeyValueCardValue>
						</>
					)}
					{returnStatusReason && (
						<>
							<KeyValueCardKey> Return Reason</KeyValueCardKey>
							<KeyValueCardValue>{returnStatusReason}</KeyValueCardValue>
						</>
					)}
					{rejectReason && (
						<>
							<KeyValueCardKey> Reject Reason</KeyValueCardKey>
							<KeyValueCardValue>{rejectReason}</KeyValueCardValue>
						</>
					)}
					{additionalVerificationStatus && (
						<>
							<KeyValueCardKey> Additional Verification Status</KeyValueCardKey>
							<KeyValueCardValue>{startCase(additionalVerificationStatus)}</KeyValueCardValue>
						</>
					)}
					{originated && (
						<>
							<KeyValueCardKey> Originated</KeyValueCardKey>
							<KeyValueCardValue>{ORIGINATED_DISPLAY_VALUE}</KeyValueCardValue>
						</>
					)}
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}
