import {Password} from "../../components/Users/Shared"
import {escapeRegExp} from "../../utilities/validation"
import {isNewPasswordValid} from "../../services/password"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import React, {Dispatch, SetStateAction} from "react"
import {useIsUser} from "../../services/auth"

interface Props {
	userId: string
	oldPassword: string | undefined
	setOldPassword: Dispatch<SetStateAction<string | undefined>>
	newPassword: string
	setNewPassword: Dispatch<SetStateAction<string>>
	isPasswordGenerated: boolean
	setIsPasswordGenerated: Dispatch<SetStateAction<boolean>>
	confirmNewPassword: string
	setConfirmNewPassword: Dispatch<SetStateAction<string>>
}

export function ChangePasswordAttributes(props: Props) {
	return (
		<>
			{useIsUser(props.userId) ? (
				<div className="field">
					<label className="label">Old Password</label>
					<div className="control">
						<input
							className="input"
							type="password"
							placeholder="Old Password"
							value={props.oldPassword ? props.oldPassword : ""}
							required
							onChange={(e) => props.setOldPassword(e.target.value)}
						/>
					</div>
				</div>
			) : null}
			<div className="field">
				<Password
					password={props.newPassword}
					setPassword={props.setNewPassword}
					onGenerated={() => props.setIsPasswordGenerated(true)}
					title="New Password"
					isExpended
				/>
			</div>

			{!props.isPasswordGenerated ? (
				<div className="field">
					<label className="label">Confirm Password</label>
					<div className="control has-icons-right">
						<input
							className="input"
							type="password"
							placeholder="Confirm Password"
							title="Password doesn't match"
							value={props.confirmNewPassword}
							autoComplete="new-password"
							pattern={escapeRegExp(props.newPassword)}
							onChange={(e) => props.setConfirmNewPassword(e.target.value)}
							required
						/>
						{isNewPasswordValid(props.newPassword, props.confirmNewPassword, props.isPasswordGenerated) ? (
							<span className="icon is-small is-right">
								<FontAwesomeIcon size="sm" icon={faCheck} />
							</span>
						) : (
							""
						)}
					</div>
				</div>
			) : null}
		</>
	)
}
