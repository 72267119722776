import React from "react"
import {OrgSettings, patchAstraSecrets, patchPlaidSecrets} from "../../resources/orgSettings"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {IntegrationCredentials} from "../../components/IntegrationCredentials/IntegrationCredentials"
import Admonition from "../../components/Admonitions/Admonition"
import {ShowMoreParagraph} from "../../components/ShowMoreParagraph/ShowMoreParagraph"

export default function IntegrationSettings({orgSettings}: {orgSettings: OrgSettings; refresh: () => void}) {
	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<GridContainer gap={"52px"} responsive={true} columns={2}>
					<div className={"org-settings-content-integrations"}>
						<h3> Integration Credentials </h3>
						<p className="field has-text-grey">
							In order to facilitate API calls on behalf of the clients, Unit requires clients to submit their Org
							credentials. We keep it safe in a secured AWS Secret Storage, and use it for dedicated services and
							purposes only.
						</p>
						<IntegrationCredentials
							providerLogo={"Plaid"}
							providerName={"Plaid"}
							providerLink={"https://dashboard.plaid.com/team/keys"}
							description={
								<>
									{" "}
									Integration needed for <b> ACH Debit </b>{" "}
								</>
							}
							credentialsCallback={patchPlaidSecrets}
							saved={orgSettings.attributes.plaidSecretsSet}
						/>

						<IntegrationCredentials
							providerLogo={"Astra"}
							providerName={"Astra"}
							providerLink={"https://dashboard-sandbox.astra.finance/"}
							description={
								<>
									Integration needed for <b> Fund account using Card </b>
								</>
							}
							credentialsCallback={patchAstraSecrets}
							saved={orgSettings.attributes.astraSecretsSet}
						/>
					</div>

					<Admonition
						icon={"lock-square-lock-1--interface-essential"}
						type={"is-security-info"}
						title={"We are committed to your security"}
						message={
							<ShowMoreParagraph
								summary={
									<div className={"org-settings-content-integrations-security-notice"}>
										<p>
											Unit’s implementation of the least privilege principle is ensured in every access to each
											resource. The access policies for AWS Secret Manager are also designed based on the same
											principle.
										</p>
									</div>
								}
							>
								<div className={"org-settings-content-integrations-security-notice"}>
									<p>
										Moreover, any modifications made to the AWS Secret Manager are executed through Terraform, and such
										modifications require a corresponding change in the code, which subsequently necessitates review and
										approval.
									</p>

									<p>
										In addition, the number of users with admin access is limited, and only those authorized personnel
										can access the AWS Secret Manager for management and troubleshooting purposes.{" "}
									</p>

									<p>
										Additionally, all access activities are subjected to an audit process to maintain accountability and
										transparency.
									</p>
								</div>
							</ShowMoreParagraph>
						}
					/>
				</GridContainer>
			</div>
		</div>
	)
}
