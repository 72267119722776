import React from "react"
import {Box} from "../../containers/Box/Box"
import {ValueAndUnits} from "../ValueAndUnits/ValueAndUnits"

export function AccountsBox({accounts}: {accounts: number}) {
	return (
		<Box className="end-customer-deposits-box-container">
			<span className="title">Accounts</span>
			<ValueAndUnits value={accounts} className={"value"} formatMethod="letter_suffix" roundLowValues />
		</Box>
	)
}
