import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {addCustomerKnownNames} from "../../resources/customerKnownNames"

interface ModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	customerId: string
}

type CloseFunc = () => void
type RefreshFunc = () => void

const modalCloseDelay = 1000

function closeAndRefresh(refresh: () => void, close: () => void, delay = 0) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, delay)
	}
}

export default function AddAliasCustomerModal({customerId, close, refresh}: ModalProps) {
	const accessToken = useAccessToken()
	const [alias, setAlias] = useState("")
	const [state, add] = useAsyncResultIdle(addCustomerKnownNames)

	return (
		<AsyncResultModal
			title="Known Alias"
			close={close}
			buttonClassname="is-success"
			state={state}
			onSubmit={() => add(accessToken, customerId, alias)}
			buttonText="Add"
			successText="Added successfully"
			errorToText={(err) => err.errors[0].title}
			onSuccess={() => {
				closeAndRefresh(refresh, close, modalCloseDelay)()
			}}
		>
			<div className="field">
				<div className="control">
					<input
						required
						className="input"
						type="text"
						placeholder="Add Known Alias"
						value={alias}
						onChange={(e) => setAlias(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
