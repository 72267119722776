import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {OrgSettings, SignatureAlgorithm, update} from "../../resources/orgSettings"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"

export default function SamlSettings({orgSettings, refresh}: {orgSettings: OrgSettings; refresh: () => void}) {
	const accessToken = useAccessToken()
	const [state, patch] = useAsyncResultIdle(update)
	const [orgWorkspace, setOrgWorkspace] = useState(
		orgSettings.attributes.samlSettings ? orgSettings.attributes.samlSettings?.orgWorkspace : ""
	)
	const [entryPoint, setEntryPoint] = useState(
		orgSettings.attributes.samlSettings ? orgSettings.attributes.samlSettings?.entryPoint : ""
	)
	const [signatureAlgorithm, setSignatureAlgorithm] = useState<SignatureAlgorithm>(
		orgSettings.attributes.samlSettings ? orgSettings.attributes.samlSettings.signatureAlgorithm : "sha1"
	)
	const [cert, setCert] = useState(orgSettings.attributes.samlSettings ? orgSettings.attributes.samlSettings?.cert : "")
	const [enforceSSOLogin, setEnforceSSOLogin] = useState(orgSettings.attributes.samlSettings?.enforceSSOLogin)
	const {addToast} = useToasts()

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Settings Updated Successfully", {appearance: "success"})
			refresh()
		}
	}, [state])

	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<h3> SAML </h3>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						if (orgWorkspace && entryPoint && cert && signatureAlgorithm) {
							patch(accessToken, {
								samlSettings: {
									orgWorkspace: orgWorkspace,
									entryPoint: entryPoint,
									cert: cert,
									signatureAlgorithm: signatureAlgorithm,
									enforceSSOLogin: enforceSSOLogin,
								},
							})
						}
					}}
				>
					<fieldset>
						<div className="field">
							<label className="label">Workspace Unique Name / Identifier</label>
							<div className="control">
								<input
									className="input"
									type="text"
									value={orgWorkspace}
									required
									onChange={(e) => setOrgWorkspace(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Identity Provider Entry-point</label>
							<div className="control">
								<input
									className="input"
									type="url"
									value={entryPoint}
									required
									onChange={(e) => setEntryPoint(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Signature Algorithm</label>
							<div className="control">
								<select
									value={signatureAlgorithm}
									onChange={(e) => setSignatureAlgorithm(e.target.value as SignatureAlgorithm)}
								>
									<option key="sha1" value="sha1">
										sha1
									</option>
									<option key="sha256" value="sha256">
										sha256
									</option>
									<option key="sha512" value="sha512">
										sha512
									</option>
								</select>
							</div>
						</div>
						<div className="field">
							<label className="label">Identity Provider Certificate</label>
							<div className="control">
								<textarea
									placeholder="Certificate"
									className="textarea has-fixed-size"
									value={cert}
									required
									onChange={(e) => setCert(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">
								<input
									type="checkbox"
									checked={enforceSSOLogin}
									onChange={(e) => setEnforceSSOLogin(e.target.checked)}
								/>
								&nbsp;Enforce SSO Login
							</label>
						</div>
					</fieldset>
					<div className="columns">
						<div className="column mt-4">
							{state.match(
								() => (
									<button className="button is-success">Update</button>
								),
								() => (
									<button className="button is-success is-loading">Updating</button>
								),
								(_) => null,
								(err) => (
									<>
										<div className="has-text-danger">{err.errors[0].title}</div>
										<button className="button is-success">Update</button>
									</>
								)
							)}
						</div>
					</div>
				</form>
				<hr />
				<label className="label">Unit (Service Provider) Settings:</label>
				{optionalHorizontalField(
					"Assertion URL",
					`${process.env.API_URL}/sso/saml/login/callback/${orgWorkspace ? orgWorkspace : "<workspace>"}`
				)}
				{optionalHorizontalField(
					"Issuer",
					`${process.env.API_URL}/sso/saml/metadata/${orgWorkspace ? orgWorkspace : "<workspace>"}`
				)}
			</div>
		</div>
	)
}
