import React, {ReactElement, useEffect} from "react"
import {useAccessToken} from "../../services/auth"
import {kebabCase, range, startCase} from "lodash"
import Skeleton from "react-loading-skeleton"
import numeral from "numeral"
import moment from "moment"
import {usePaging} from "../../hooks/usePaging"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {useNavigate} from "react-router-dom"
import {find, Authorization, release, AuthorizationStatus, DeclineReason} from "../../resources/authorization"
import {isOrg} from "../../services/orgAuth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useModal} from "react-modal-hook"
import {ConfirmationModal} from "../ConfirmationModal/ConfirmationModal"
import {useToasts} from "react-toast-notifications"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
} from "../DataTable/DataTable"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"
import classNames from "classnames"
import {Filter} from "../Filter/Filter"
import {useQueryState} from "use-location-state"
import {useRefreshToken} from "../../hooks/useRefreshToken"

export enum AccountAuthorizationsColumns {
	id = "Id",
	last4Digits = "Card Last 4 Digits",
	merchantName = "Merchant Name",
	status = "Status",
	reason = "Reason",
	createdAt = "Created At",
	amount = "Amount",
	actions = "Actions",
}

type AllowedAccountAuthorizationsColumns =
	| AccountAuthorizationsColumns.id
	| AccountAuthorizationsColumns.last4Digits
	| AccountAuthorizationsColumns.merchantName
	| AccountAuthorizationsColumns.status
	| AccountAuthorizationsColumns.reason
	| AccountAuthorizationsColumns.createdAt
	| AccountAuthorizationsColumns.amount
	| AccountAuthorizationsColumns.actions

const reasons = new Map<DeclineReason, string>([
	[DeclineReason.CardVerificationValueFailed, "CVV verification failed"],
	[DeclineReason.IncorrectPIN, "PIN verification failed"],
	[DeclineReason.ExceedsAmountLimit, "Exceeds account/card limits"],
	[DeclineReason.InsufficientFunds, "Insufficient funds"],
	[DeclineReason.RequestedFunctionNotSupported, "Other"],
	[DeclineReason.AllowablePINTriesExceeded, "Too many PIN verification attempts"],
	[DeclineReason.SuspectedFraud, "Suspected Fraud"],
	[DeclineReason.ClosedAccount, "Closed Account"],
	[DeclineReason.DoNotHonor, "Other"],
	[DeclineReason.CardholderBlocked, "The card is inactive / blocked"],
	[DeclineReason.CardStolen, "The card was stolen"],
	[DeclineReason.RestrictedCard, "Restricted country"],
	[DeclineReason.InsufficientFundsDuringStandIn, "Insufficient funds during stand in"],
	[DeclineReason.ExceedsStandInLimit, "Exceeds stand in limit"],
])

export const getDeclineReason = (reason: DeclineReason, status: AuthorizationStatus) => {
	if (status === AuthorizationStatus.Declined) {
		return reasons.get(reason) ?? "Other"
	}
	return "—"
}
function AuthorizationRow({
	au,
	refreshPage,
	includedColumns,
}: {
	au: Authorization
	showCustomerColumn?: boolean
	showOrgColumn?: boolean
	refreshPage: () => void
	includedColumns: Array<AccountAuthorizationsColumns>
}) {
	const navigate = useNavigate()
	const isOrgAuthorization = isOrg()
	const [releaseState, releaseAction] = useAsyncResultIdle(release)
	const accessToken = useAccessToken()
	const {addToast} = useToasts()

	const [showReleaseConfirmationModal, hideReleaseConfirmationModal] = useModal(
		() => (
			<ConfirmationModal
				title="Release Authorization"
				close={hideReleaseConfirmationModal}
				okButtonClassname={"button is-success is-outlined"}
				cancelButtonClassname="button is-white"
				onSubmit={() => {
					releaseAction(accessToken, au.id)
				}}
			>
				You chose to cancel an authorization for <b>{au.attributes.amount}</b>, on the card ending in{" "}
				<b>{au.attributes.cardLast4Digits}</b>. This will release the hold on the account, Are you sure you want to
				release the authorization?
			</ConfirmationModal>
		),
		[]
	)

	useEffect(() => {
		releaseState.match(
			() => null,
			() => null,
			() => refreshPage(),
			(e) => addToast(e.errors[0].title, {appearance: "error"})
		)
	}, [releaseState])

	const contentColumns: Partial<Record<AllowedAccountAuthorizationsColumns, ReactElement>> = {}

	if (includedColumns.includes(AccountAuthorizationsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell>
				<span className="data-table-id"> {au.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.last4Digits)) {
		contentColumns["Card Last 4 Digits"] = <DataTableCell>{au.attributes.cardLast4Digits}</DataTableCell>
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.merchantName)) {
		contentColumns["Merchant Name"] = <DataTableCell>{au.attributes.merchant.name}</DataTableCell>
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("authorization-status", kebabCase(au.attributes.status))}>
					{startCase(au.attributes.status)}
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.reason)) {
		contentColumns["Reason"] = (
			<DataTableCell>
				<div className="declined-reason">{getDeclineReason(au.attributes.declineReason, au.attributes.status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(au.attributes.createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<b>{numeral(au.attributes.amount / 100).format("$0,0.00")}</b>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountAuthorizationsColumns.actions)) {
		contentColumns["Actions"] = (
			<DataTableActionButton enableActions={true}>
				<DataTableActionItem
					title={"Release an authorization"}
					icon={"streamline-icon-navigation-arrows-right-2@20x20 (1)"}
					onClick={() => showReleaseConfirmationModal()}
				/>
			</DataTableActionButton>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/${isOrgAuthorization ? "org-" : ""}authorization/${au.id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function AuthorizationsTable({
	authorizations,
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	isUsingPaging,
	refreshPage,
	includedColumns,
	sortFunction,
	sortBy,
}: {
	authorizations: Array<Authorization>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	refreshPage: () => void
	includedColumns: Array<AllowedAccountAuthorizationsColumns>
	sortFunction: () => void
	sortBy: string
}) {
	return (
		<div className={"authorizations-table"}>
			<DataTable
				isEmpty={authorizations.length === 0}
				fullHeight={false}
				stickyAction={false}
				noContentText={"No authorizations found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === AccountAuthorizationsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{authorizations.map((au) => (
						<AuthorizationRow
							au={au}
							includedColumns={includedColumns}
							key={`${au.relationships.account?.data.id}_${au.id}`}
							refreshPage={refreshPage}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
			/>
		</div>
	)
}

function Pending() {
	return (
		<div className="table-container">
			<table className="table">
				<thead>
					<tr>
						<th>Id</th>
						<th>Card Last 4 Digits</th>
						<th>Amount</th>
						<th>Merchant Name</th>
						<th>Status</th>
						<th>Reason</th>
						<th>Created At</th>
					</tr>
				</thead>
				<tbody>
					{range(5).map((i) => (
						<tr key={i}>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export function AccountAuthorizations({
	accountId,
	limit = 10,
	isUsingPaging = true,
	token,
	refreshPage,
	includedColumns,
	enableStatusFilter = true,
}: {
	accountId: string
	limit?: number
	isUsingPaging?: boolean
	token?: string
	refreshPage: () => void
	includedColumns: Array<AllowedAccountAuthorizationsColumns>
	enableStatusFilter?: boolean
}) {
	const [refreshToken] = useRefreshToken()
	const accessToken = token ?? useAccessToken()
	const prefix = "auth"
	const statusesOptions = new Map(Object.values(AuthorizationStatus).map((v) => [v, v]))
	const [statuses, setStatuses] = useQueryState<AuthorizationStatus[]>(`${prefix}-filter[statuses]`, [
		AuthorizationStatus.Authorized,
	])
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")

	function toggleSort() {
		setSortBy(sortBy === "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}
	function getAuthorizations(offset: number, limit: number) {
		return find(accessToken, offset, limit, accountId, statuses, sortBy)
	}

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		getAuthorizations,
		(x) => x.data.length,
		[statuses.join(","), refreshToken, sortBy],
		`${prefix}-`,
		true
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	return (
		<DataTableCard className={"account-authorizations-card"}>
			<DataTableActionHeader title={"Authorizations"} filters={[...(enableStatusFilter ? [statusFilter] : [])]} />
			<AsyncResultComponent asyncResult={result} pendingComponent={<Pending />}>
				{({value: authorizations}) => {
					return (
						<AuthorizationsTable
							authorizations={authorizations.data}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={isUsingPaging}
							refreshPage={refreshPage}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
