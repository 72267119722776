import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {findOrgs, Org} from "../../resources/org"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {createBankUser, createOrgUser, createPartnerUser, createUnitUser} from "../../resources/user"
import {nameRegex, Password, Username} from "./Shared"
import Loading from "../../components/Loading/Loading"
import {Bank, findBanks} from "../../resources/bank"
import {AsyncResult} from "../../types/asyncResult"
import {SelectUserRoleField, UserRolesExplanationTooltip} from "./UserRoleField"
import {findPartners, Partner} from "../../resources/partner"
import PhoneNumber from "../PhoneNumberLine/PhoneNumber"

type CloseFunc = () => void
type RefreshFunc = () => void

interface UserTypeProps {
	userType: string
	setUserType: (userType: string) => void
}

interface ModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	orgs: Array<Org>
	banks: Array<Bank>
	partners: Array<Partner>
}

function UserType({userType, setUserType}: UserTypeProps) {
	return (
		<div className="field">
			<label className="label">User Type</label>
			<div className="select">
				<select value={userType} onChange={(e) => setUserType(e.target.value)}>
					<option value="orgUser">Org User</option>
					<option value="unitUser">Unit User</option>
					<option value="bankUser">Bank User</option>
					<option value="partnerUser">Partner User</option>
				</select>
			</div>
		</div>
	)
}

function UnitModal({close, refresh, orgs: _orgs, ...props}: ModalProps & UserTypeProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createUnitUser)
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [role, setRole] = useState("unit-admin")
	const [countryCode, setCountryCode] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")

	return (
		<AsyncResultModal
			title="Create new Unit user"
			onSubmit={() => create(accessToken, username, password, role, {countryCode, number: phoneNumber})}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<UserType {...props} />
			<div className="field">
				<label className="label">
					User Role <UserRolesExplanationTooltip type="unitUser" />
				</label>
				<SelectUserRoleField type="unitUser" role={role} setRole={setRole} />
			</div>
			<Username username={username} setUsername={setUsername} />
			<Password password={password} setPassword={setPassword} />
			<div className="field">
				<PhoneNumber
					countryCodeValue={countryCode}
					countryCodeOnChange={setCountryCode}
					phoneNumberValue={phoneNumber}
					phoneNumberOnChange={setPhoneNumber}
				/>
			</div>
		</AsyncResultModal>
	)
}

function BankModal({close, refresh, banks, ...props}: ModalProps & UserTypeProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createBankUser)
	const [bankId, setBankId] = useState(banks[0].id)
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [role, setRole] = useState("admin")
	const [countryCode, setCountryCode] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")

	return (
		<AsyncResultModal
			title="Create new bank user"
			onSubmit={() => create(accessToken, username, password, bankId, role, {countryCode, number: phoneNumber})}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<UserType {...props} />
			<div className="field">
				<label className="label">Bank</label>
				<div className="select">
					<select value={bankId} onChange={(e) => setBankId(e.target.value)}>
						{banks.map((b) => (
							<option key={b.id} value={b.id.toString()}>
								{b.attributes.name}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="field">
				<label className="label">
					User Role <UserRolesExplanationTooltip type="bankUser" />
				</label>
				<SelectUserRoleField type="bankUser" role={role} setRole={setRole} />
			</div>
			<Username username={username} setUsername={setUsername} />
			<Password password={password} setPassword={setPassword} />
			<div className="field">
				<label className="label">Phone Number</label>
				<div className="field-body">
					<p className="control">
						<a className="button is-static">+</a>
					</p>
					<div className="control">
						<input
							className="input"
							placeholder="Country Code"
							type="text"
							value={countryCode}
							pattern="^(1|7|(\d{2,3})|(1\d{3}))$"
							required
							onChange={(e) => setCountryCode(e.target.value)}
						/>
					</div>
					<div className="control w-100">
						<input
							className="input"
							placeholder="Number"
							type="text"
							value={phoneNumber}
							pattern="^\d+$"
							required
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
					</div>
				</div>
			</div>
		</AsyncResultModal>
	)
}

function OrgUserModal({close, refresh, orgs, ...props}: ModalProps & UserTypeProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createOrgUser)
	const [username, setUsername] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [countryCode, setCountryCode] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [orgId, setOrgId] = useState(orgs[0].id.toString())
	const [role, setRole] = useState("admin")

	return (
		<AsyncResultModal
			title="Create new org user"
			onSubmit={() =>
				create(accessToken, orgId, username, firstName, lastName, role, {countryCode, number: phoneNumber})
			}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<UserType {...props} />
			<Username username={username} setUsername={setUsername} />
			<div className="field">
				<label className="label">First Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="First Name"
						value={firstName}
						pattern={nameRegex}
						title="No special characters are allowed"
						required
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Last Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Last Name"
						value={lastName}
						pattern={nameRegex}
						title="No special characters are allowed"
						required
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Phone Number</label>
				<div className="field-body">
					<p className="control">
						<a className="button is-static">+</a>
					</p>
					<div className="control">
						<input
							className="input"
							placeholder="Country Code"
							type="text"
							value={countryCode}
							pattern="^(1|7|(\d{2,3})|(1\d{3}))$"
							required
							onChange={(e) => setCountryCode(e.target.value)}
						/>
					</div>
					<div className="control w-100">
						<input
							className="input"
							placeholder="Number"
							type="text"
							value={phoneNumber}
							pattern="^\d+$"
							required
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">
					User Role <UserRolesExplanationTooltip type="orgUser" orgId={orgId} />
				</label>
				<SelectUserRoleField type="orgUser" role={role} setRole={setRole} orgId={orgId} />
			</div>
			<div className="field">
				<label className="label">Org</label>
				<div className="select">
					<select required defaultValue="" onChange={(e) => setOrgId(e.target.value)}>
						<option value="" disabled>
							Select org
						</option>
						{orgs.map((o) => (
							<option key={o.id} value={o.id.toString()}>
								{o.attributes.name}
							</option>
						))}
					</select>
				</div>
			</div>
		</AsyncResultModal>
	)
}

function PartnerModal({close, refresh, partners, ...props}: ModalProps & UserTypeProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createPartnerUser)
	const [username, setUsername] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [role, setRole] = useState("partner")
	const [partnerId, setPartnerId] = useState("")
	const [countryCode, setCountryCode] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")

	return (
		<AsyncResultModal
			title="Create new partner user"
			onSubmit={() =>
				create(accessToken, username, firstName, lastName, role, {countryCode, number: phoneNumber}, partnerId)
			}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<UserType {...props} />
			<Username username={username} setUsername={setUsername} />
			<div className="field">
				<label className="label">First Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="First Name"
						value={firstName}
						pattern={nameRegex}
						title="No special characters are allowed"
						required
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Last Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Last Name"
						value={lastName}
						pattern={nameRegex}
						title="No special characters are allowed"
						required
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Phone Number</label>
				<div className="field-body">
					<p className="control">
						<a className="button is-static">+</a>
					</p>
					<div className="control">
						<input
							className="input"
							placeholder="Country Code"
							type="text"
							value={countryCode}
							pattern="^(1|7|(\d{2,3})|(1\d{3}))$"
							required
							onChange={(e) => setCountryCode(e.target.value)}
						/>
					</div>
					<div className="control w-100">
						<input
							className="input"
							placeholder="Number"
							type="text"
							value={phoneNumber}
							pattern="^\d+$"
							required
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">
					User Role <UserRolesExplanationTooltip type="partnerUser" />
				</label>
				<SelectUserRoleField type="partnerUser" role={role} setRole={setRole} partnerId={partnerId} />
			</div>
			<div className="field">
				<label className="label">Partner</label>
				<div className="select">
					<select required defaultValue="" onChange={(e) => setPartnerId(e.target.value)}>
						<option value="" disabled>
							Select Partner
						</option>
						{partners.map((o) => (
							<option key={o.id} value={o.id.toString()}>
								{o.attributes.name}
							</option>
						))}
					</select>
				</div>
			</div>
		</AsyncResultModal>
	)
}

function Modal(props: ModalProps) {
	const [userType, setUserType] = useState("orgUser")
	switch (userType) {
		case "orgUser":
			return <OrgUserModal {...props} userType={userType} setUserType={setUserType} />
		case "unitUser":
			return <UnitModal {...props} userType={userType} setUserType={setUserType} />
		case "bankUser":
			return <BankModal {...props} userType={userType} setUserType={setUserType} />
		case "partnerUser":
			return <PartnerModal {...props} userType={userType} setUserType={setUserType} />
		default:
			return null
	}
}

export default function UnitNewUserModal({close, refresh}: {close: CloseFunc; refresh: RefreshFunc}) {
	const accessToken = useAccessToken()

	// TODO: when we will have more than 1000 orgs we will have to support searching
	const orgs = useAsyncResult(() => findOrgs(accessToken, 0, 10000))
	const banks = useAsyncResult(() => findBanks(accessToken, 0, 1000))
	const partners = useAsyncResult(() => findPartners(accessToken))
	return AsyncResult.zip3(orgs, banks, partners).match(
		() => <Loading />,
		([orgs, banks, partners]) => (
			<Modal orgs={orgs} banks={banks} partners={partners} close={close} refresh={refresh} />
		),
		(_) => null
	)
}
