import React from "react"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import TitleBar from "../../components/TitleBar/TitleBar"
import {Webhooks, WebhooksColumns} from "../../components/Webhooks/Webhooks"
import {useIsUnitUser} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useModal} from "react-modal-hook"
import NewWebhookModal from "../../components/Webhooks/NewWebhookModal"
import Icon from "../../components/Icon/Icon"

export default function WebhooksPage() {
	const [showModal, hideModal] = useModal(() => <NewWebhookModal close={hideModal} refresh={refresh} />)
	const [refreshToken, refresh] = useRefreshToken()

	return (
		<>
			<TitleBar title={"Webhooks"}>
				<button className="button button-create" onClick={() => showModal()}>
					<Icon icon="interface-add-1" size={12} /> Create
				</button>
			</TitleBar>

			<MainSectionTable>
				<Webhooks
					refreshToken={refreshToken}
					includedColumns={[
						WebhooksColumns.id,
						...(useIsUnitUser() ? [WebhooksColumns.org] : []),
						WebhooksColumns.label,
						WebhooksColumns.url,
						WebhooksColumns.status,
						WebhooksColumns.contentType,
						WebhooksColumns.subscriptionType,
						WebhooksColumns.delivery,
						WebhooksColumns.createdAt,
						WebhooksColumns.toggle,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
