import React from "react"
import {Payment} from "../../resources/payment"
import {Customer} from "../../resources/customer"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {BusinessApplication, getApplication} from "../../resources/application"
import {Account} from "../../resources/account"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import Assignments from "../Assignments/Assignments"

export interface PaymentAssignmentsProps {
	payment: Payment
	included: Array<Account | Customer>
	businessApplicationId: string | undefined
	accessToken: string
	refreshToken: number
	refresh: () => void
}

export function PaymentAssignments({
	payment,
	included,
	businessApplicationId,
	accessToken,
	refreshToken,
	refresh,
}: PaymentAssignmentsProps) {
	if (!businessApplicationId) {
		return <Assignments resource={payment} included={included} refreshToken={refreshToken} refresh={refresh} />
	}
	const applicationAsyncResultReq = useAsyncResult(
		() => getApplication(accessToken, businessApplicationId),
		[refreshToken]
	)

	return (
		<AsyncResultComponent asyncResult={applicationAsyncResultReq}>
			{(applicationResponse) => {
				const applicationData = applicationResponse.value.data
				const payload = {
					businessApplication: applicationData as BusinessApplication,
				}
				return (
					<Assignments
						resource={payment}
						included={included}
						payload={payload}
						refreshToken={refreshToken}
						refresh={refresh}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}
