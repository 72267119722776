import axios, {AxiosRequestConfig, AxiosResponse} from "axios"
import {Result, ok, err} from "neverthrow"

export async function post<T, E>(url: string, data: any, config: AxiosRequestConfig): Promise<Result<T, E>> {
	try {
		const response = await axios.post<T>(url, data, config)
		return ok(response.data)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			return err(response.data)
		} else return err(error)
	}
}

export async function getResponse<E>(url: string, config: AxiosRequestConfig): Promise<Result<any, E>> {
	try {
		const response = await axios.get<any>(url, config)
		return ok(response)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			if (response.config.responseType == "blob" && response.data instanceof Blob)
				return err(JSON.parse(await response.data.text()))
			return err(response.data)
		} else return err(error)
	}
}

export async function get<T, E>(url: string, config: AxiosRequestConfig): Promise<Result<T, E>> {
	try {
		const response = await axios.get<T>(url, config)
		return ok(response.data)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			if (response.config.responseType == "blob" && response.data instanceof Blob)
				return err(JSON.parse(await response.data.text()))
			return err(response.data)
		} else return err(error)
	}
}

export async function patch<T, E>(url: string, data: any, config: AxiosRequestConfig): Promise<Result<T, E>> {
	try {
		const response = await axios.patch<T>(url, data, config)
		return ok(response.data)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			return err(response.data)
		} else return err(error)
	}
}

export async function deleteVerb<T, E>(url: string, config: AxiosRequestConfig): Promise<Result<T, E>> {
	try {
		const response = await axios.delete<T>(url, config)
		return ok(response.data)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			return err(response.data)
		} else return err(error)
	}
}

export async function put<T, E>(url: string, data: any, config: AxiosRequestConfig): Promise<Result<T, E>> {
	try {
		const response = await axios.put<T>(url, data, config)
		return ok(response.data)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			return err(response.data)
		} else return err(error)
	}
}

export async function rawPost<T, E>(
	url: string,
	data: any,
	config: AxiosRequestConfig
): Promise<Result<AxiosResponse<T>, AxiosResponse<E>>> {
	try {
		const response = await axios.post<T>(url, data, config)
		return ok(response)
	} catch (error) {
		if (error.isAxiosError && error.response) {
			const response = error.response as AxiosResponse<E>
			return err(response)
		} else return err(error)
	}
}
