import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {Payment, wirePaymentSent} from "../../resources/payment"

interface WirePaymentSentModalProps {
	payment: Payment
	close: () => void
	onSuccess: () => void
}

function Reason({setOmad, setImad}: {setOmad: (omad: string) => void; setImad: (imad: string) => void}) {
	return (
		<div className="field">
			<label className="label">Imad</label>
			<input type="text" placeholder="Imad" required onChange={(e) => setImad(e.target.value)} className="input" />
			<label className="label">Omad</label>
			<input type="text" placeholder="Omad" required onChange={(e) => setOmad(e.target.value)} className="input" />
		</div>
	)
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function WirePaymentSentModal({payment, close, onSuccess}: WirePaymentSentModalProps) {
	const accessToken = useAccessToken()
	const [omad, setOmad] = useState<string>("")
	const [imad, setImad] = useState<string>("")
	const [state, sent] = useAsyncResultIdle(wirePaymentSent)

	return (
		<AsyncResultModal
			title="Wire Payment IMAD/OMAD"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Update"
			successText="Updated"
			onSubmit={() => sent(accessToken, payment.id, imad, omad)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<Reason setOmad={setOmad} setImad={setImad} />
			</div>
		</AsyncResultModal>
	)
}
