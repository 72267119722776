import React, {ReactNode} from "react"
import Card from "../../../containers/Card/Card"
import {GridContainer} from "../../../containers/GridContainer/GridContainer"
import {DailyCardSpend, DailyCardSpendSkeleton} from "./DailyCardSpend"
import {TotalOwedByCustomers, TotalOwedByCustomersSkeleton} from "./TotalOwedByCustomers"
import {FundingAccount, FundingAccountSkeleton} from "./FundingAccount"
// eslint-disable-next-line
import {BankProgramLimit, BankProgramLimitSkeleton} from "./BankProgramLimit"
import {useMeasure} from "react-use"
import classNames from "classnames"
import {useAccessToken} from "../../../services/auth"
import {useAsyncResult} from "../../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../../containers/AsyncResult/AsyncResult"
import {hasPermission} from "../../../services/permission"
import {ClientLendingProgramAttributes, findClientLendingPrograms} from "../../../resources/clientLendingProgram"

function ChargeCardsPlanOverviewInternal({
	clientLendingProgram,
	bankId,
}: {
	clientLendingProgram: ClientLendingProgramAttributes
	bankId: string
}) {
	const {
		name,
		orgLoanAccount,
		orgGeneralLedgerAccount,
		creditOperationalAccount,
		programSizeLimit,
		programSizeAllocated,
	} = clientLendingProgram

	return (
		<ChargeCardsPlanOverviewContainer name={name}>
			<DailyCardSpend orgLoanAccountId={orgLoanAccount} />
			<TotalOwedByCustomers orgGeneralLedgerAccountId={orgGeneralLedgerAccount} />
			{creditOperationalAccount ? (
				<FundingAccount creditOperationalAccountId={creditOperationalAccount} bankId={bankId} />
			) : null}
			<BankProgramLimit creditProgramLimit={programSizeLimit} accountsCreditLimit={programSizeAllocated} />
		</ChargeCardsPlanOverviewContainer>
	)
}

export function ChargeCardsPlanOverviewSkeleton() {
	return (
		<ChargeCardsPlanOverviewContainer>
			<DailyCardSpendSkeleton />
			<TotalOwedByCustomersSkeleton />
			<FundingAccountSkeleton />
			<BankProgramLimitSkeleton />
		</ChargeCardsPlanOverviewContainer>
	)
}

function ChargeCardsPlanOverviewContainer({name, children}: {name?: string; children: Array<ReactNode>}) {
	const [ref = null, {width = 0}] = useMeasure<HTMLDivElement>()
	const isMobile = width < 600
	const classes = classNames("flex-container", "gap-10", "w-100", "min-w-300", {"flex-dir-col": isMobile})
	const [dailyCardSpend, totalOwedByCustomers, fundingAccount, bankProgramSize] = children
	const title = name ? `${name} Overview` : "Charge Cards Plan Overview"
	return (
		<Card title={title}>
			<GridContainer columns={3} split="equal" className="w-100 min-h-300 max-w-100p" responsiveWidth={900}>
				<div ref={ref} className={classes}>
					{dailyCardSpend}
					{totalOwedByCustomers}
				</div>
				{fundingAccount}
				{bankProgramSize}
			</GridContainer>
		</Card>
	)
}

export function ChargeCardsPlanOverview() {
	const accessToken = useAccessToken()
	const creditsSettings = useAsyncResult(() => findClientLendingPrograms(accessToken))
	return hasPermission("clientOrgBankAgreement.chargeCardSettings", "get") ? (
		<AsyncResultComponent asyncResult={creditsSettings} pendingComponent={<ChargeCardsPlanOverviewSkeleton />}>
			{({value}) => (
				<>
					{value?.map((clientLendingProgram, key) => {
						return (
							<ChargeCardsPlanOverviewInternal
								key={key}
								clientLendingProgram={clientLendingProgram.attributes}
								bankId={clientLendingProgram.relationships.bank.data.id}
							/>
						)
					})}
				</>
			)}
		</AsyncResultComponent>
	) : null
}
