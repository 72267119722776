import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import IncomingWires, {IncomingWiresColumns} from "../../components/IncomingWires/IncomingWires"
import {useIsUnitUser} from "../../services/auth"

export default function IncomingWiresPage(): ReactElement {
	return (
		<>
			<TitleBar title={"Incoming Wires"} />

			<MainSectionTable>
				<IncomingWires
					includedColumns={[
						IncomingWiresColumns.id,
						IncomingWiresColumns.org,
						...(useIsUnitUser() ? [IncomingWiresColumns.bank] : []),
						IncomingWiresColumns.accountId,
						IncomingWiresColumns.customer,
						IncomingWiresColumns.amount,
						IncomingWiresColumns.status,
						IncomingWiresColumns.businessFunctionCode,
						IncomingWiresColumns.isMonetary,
						IncomingWiresColumns.cycleDate,
						IncomingWiresColumns.createdAt,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
