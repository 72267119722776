import {Result} from "neverthrow"
import {DependencyList, useEffect, useState} from "react"
import {AsyncResult, AsyncResultRequestState} from "../types/asyncResult"
import {concat, isNil} from "lodash"
import {useQueryState} from "use-location-state"

export type NextFunction = () => void
export type PrevFunction = () => void
export type SetLimit = (limit: number) => void

function isTotalLargerThenLimit(result: any) {
	const {total, limit, offset} = result?.meta?.pagination || {}
	return isNil(total) || isNil(limit) || isNil(offset) || +total > +limit + +offset
}

export function usePaging<T, E>(
	initialLimit: number,
	get: (offset: number, limit: number) => Promise<Result<T, E>>,
	fLength: (x: T) => number,
	deps: DependencyList = [],
	prefix = "",
	isPendingOnNavigation = false,
	avoidQueryState = false
): [AsyncResultRequestState<T, E>, boolean, boolean, PrevFunction, NextFunction, boolean, SetLimit, boolean] {
	const [queryStateOffset, setQueryStateOffset] = useQueryState<number>(prefix + "page[offset]", 0)
	const [stateOffset, setStateOffset] = useState<number>(0)
	const [limit, setLimit] = useState(initialLimit)
	const [result, setResult] = useState<AsyncResultRequestState<T, E>>(AsyncResult.pending())
	const [hasPrev, setHasPrev] = useState(false)
	const [hasNext, setHasNext] = useState(false)
	const [hasResults, setHasResults] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const setOffset = (newValue: number) => (avoidQueryState ? setStateOffset(newValue) : setQueryStateOffset(newValue))
	const getOffset = () => (avoidQueryState ? stateOffset : queryStateOffset)

	useEffect(() => {
		;(async () => {
			setIsLoading(true)
			const offset = getOffset()
			if (isPendingOnNavigation) {
				setResult(AsyncResult.pending())
			}

			const result = await get(offset, limit)
			setIsLoading(false)

			result.match(
				(value) => {
					setResult(AsyncResult.ok(value))
					setHasPrev(offset != 0)
					setHasNext(fLength(value) === limit && isTotalLargerThenLimit(value))
					setHasResults(fLength(value) > 0 || offset > 0)
				},
				(err) => setResult(AsyncResult.err(err))
			)
		})()
	}, concat(deps, getOffset(), limit))

	const next = () => setOffset(getOffset() + limit)
	const prev = () => setOffset(getOffset() - limit < 0 ? 0 : getOffset() - limit)

	const setLimitAndResetOffset = (newLimit: number) => {
		setOffset(0)
		setLimit(newLimit)
	}

	return [result, hasPrev, hasNext, prev, next, hasResults, setLimitAndResetOffset, isLoading]
}
