import React, {ComponentClass, FunctionComponent} from "react"
import {UnitOrBankUserOnly} from "../../containers/PermissionedUser/PermissionedUser"

type ElementProps<P> = Pick<Props<P> & P, Exclude<keyof P, "element">>

interface Props<P> {
	element: FunctionComponent<ElementProps<P>> | ComponentClass<ElementProps<P>> | string
}

export function OrgHeader<P extends {}>({element, ...props}: Props<P> & P) {
	return <UnitOrBankUserOnly>{React.createElement<ElementProps<P>>(element, props)}</UnitOrBankUserOnly>
}

OrgHeader.defaultProps = {
	element: "th",
}
