import React, {ReactElement, useState} from "react"
import Icon from "../Icon/Icon"
import classNames from "classnames"
import {useCopyToClipboard} from "react-use"
import {useNavigate} from "react-router-dom"

type Props = {
	open: boolean
	onClose: () => void
	children?: React.ReactNode
	direction: "left" | "right" | "top" | "bottom"
	duration?: number
	overlayOpacity?: number
	overlayColor?: string
	style?: React.CSSProperties
	zIndex?: number
	size?: number | string
	className?: string
	title?: string | ReactElement
	blockBody?: boolean
	linkToCopy?: string
	linkToExpand?: string
}

const getDirectionStyle = (dir: string, size?: number | string): {} | React.CSSProperties => {
	switch (dir) {
		case "left":
			return {
				top: 0,
				left: 0,
				transform: "translate3d(-100%, 0, 0)",
				width: size,
				height: "100vh",
			}
		case "right":
			return {
				top: 0,
				right: 0,
				transform: "translate3d(100%, 0, 0)",
				width: size,
				height: "100vh",
			}
		case "bottom":
			return {
				left: 0,
				right: 0,
				bottom: 0,
				transform: "translate3d(0, 100%, 0)",
				width: size,
				height: size,
			}
		case "top":
			return {
				left: 0,
				right: 0,
				top: 0,
				transform: "translate3d(0, -100%, 0)",
				width: size,
				height: size,
			}

		default:
			return {}
	}
}

export function DrawerHeader({title, children}: {title: string; children: React.ReactNode}) {
	return (
		<div className="drawer-title">
			<div className={"drawer-header"}>
				<h3> {title} </h3>
				{children}
			</div>
		</div>
	)
}

function DrawerCopyButton({link}: {link: string}) {
	const [, copyToClipboard] = useCopyToClipboard()
	const [isVisible, setIsVisible] = useState(false)

	return (
		<>
			<button
				className="copy-to-clipboard"
				data-tracking-label="copy-to-clipboard-from-drawer"
				onClick={() => {
					setIsVisible(false)
					copyToClipboard(link)
					setTimeout(() => {
						setIsVisible(true)
					}, 10)
				}}
			>
				<Icon icon="hyperlink-2--interface-essential" color="#545558" size={16} />
			</button>
			<div className={classNames("copied-label-container", isVisible && "show-and-fade-out")}>
				<Icon icon={"interface-validation-check--interface-essential"} size={12} />
				<span className="copied-label">Copied</span>
			</div>
		</>
	)
}

function DrawerExpandButton({link}: {link: string}) {
	const navigate = useNavigate()

	return (
		<button
			className="expand-button"
			onClick={() => {
				navigate(`${link}`)
			}}
		>
			<Icon icon="expand-diagonal-4--interface-essential" color="#545558" size={16} />
		</button>
	)
}

function DrawerCloseButton({onClose}: {onClose: () => void}) {
	return (
		<button className="drawer-close-button" onClick={onClose}>
			<Icon icon="interface-delete-interface-essential" size={16} color="#545558" />
		</button>
	)
}

export default function Drawer({
	open,
	onClose = () => null,
	children,
	direction,
	duration = 200,
	overlayOpacity = 0.2,
	overlayColor = "#000",
	style,
	zIndex = 100,
	size = "auto",
	className,
	title = "",
	linkToCopy,
	linkToExpand,
	blockBody = true,
}: Props): JSX.Element {
	const overlayStyles: React.CSSProperties = {
		backgroundColor: `${overlayColor}`,
		opacity: `${overlayOpacity}`,
		zIndex: zIndex,
	}

	const drawerStyles: React.CSSProperties = {
		zIndex: zIndex + 1,
		transitionDuration: `${duration}ms`,
		...getDirectionStyle(direction, size),
		...style,
	}

	const enableOverlay = open && blockBody

	return (
		<div className="drawer">
			{enableOverlay && <div className={"drawer-overlay"} style={overlayStyles} onClick={onClose} />}
			<div style={drawerStyles} className={classNames("drawer-container", className, open && "drawer-open")}>
				<div className="drawer-top-menu">
					<div className="drawer-top-menu-buttons-container">
						{linkToCopy && (
							<div className="drawer-copy">
								<DrawerCopyButton link={linkToCopy} />
							</div>
						)}
						{linkToExpand && (
							<div className="drawer-expand">
								<DrawerExpandButton link={linkToExpand} />
							</div>
						)}
						<div className="drawer-close">
							<DrawerCloseButton onClose={onClose} />
						</div>
					</div>
				</div>
				{title && (
					<div className="drawer-title">
						<h3> {title} </h3>
					</div>
				)}
				{children}
			</div>
		</div>
	)
}
