import React from "react"
import {WireCounterparty, WireInvolvedInstitutions} from "../../resources/payment"
import {addressText} from "../../utilities/helpers"
import {KeyValueCardView} from "../RenderKeyValueCard/KeyValueCardView"

export function WirePaymentCounterpartyFields({
	counterpartyFields,
	involvedInstitutions,
	singleColumn,
	disabled,
	className,
}: {
	counterpartyFields: Partial<WireCounterparty>
	involvedInstitutions?: WireInvolvedInstitutions
	singleColumn?: boolean
	disabled?: boolean
	className?: string
}) {
	const {name, routingNumber, accountNumber, address} = counterpartyFields
	const {receiverDepositInstitution} = involvedInstitutions || {}
	const {name: bankName, address: bankAddress} = receiverDepositInstitution || {}
	const addressElement = address && <span className={"address-placeholder"}>{addressText(address)}</span>
	const counterpartyDetails = {
		...(name ? {counterparty: name} : {}),
		...(address ? {address: addressElement} : {}),
		...(routingNumber ? {routingNumber} : {}),
		...(accountNumber ? {accountNumber} : {}),
		...(bankName ? {bankName} : {}),
		...(bankAddress ? {bankAddress} : {}),
	}
	return (
		<KeyValueCardView
			details={counterpartyDetails}
			title="Counterparty Details"
			singleColumn={singleColumn}
			disabled={disabled}
			className={className}
		/>
	)
}
