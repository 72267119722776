import React, {useEffect, useState} from "react"
import {OrgSettings, update} from "../../resources/orgSettings"
import {useAccessToken} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export default function GeneralSettings({orgSettings, refresh}: {orgSettings: OrgSettings; refresh: () => void}) {
	const accessToken = useAccessToken()
	const [state, patch] = useAsyncResultIdle(update)
	const [preventDuplicateIndividualApplications, setPreventDuplicateIndividualApplications] = useState<boolean>(
		orgSettings?.attributes.generalSettings.preventDuplicateIndividualApplications
	)
	const [showSensitiveDataForPhysicalCards, setShowSensitiveDataForPhysicalCards] = useState<boolean>(
		orgSettings?.attributes.generalSettings.showSensitiveDataForPhysicalCards
	)
	const [enableTagInheritanceForCards, setEnableTagInheritanceForCards] = useState(
		orgSettings?.attributes.generalSettings.enableTagInheritanceForCards
	)

	const [enableAPIBasedAuthorizationRequests, setEnableAPIBasedAuthorizationRequests] = useState<boolean>(
		orgSettings?.attributes.generalSettings.programmaticAuthorizationHttpPostURL !== null
	)

	const [currentAuthorizationRequestsURL, setAPIBasedAuthorizationRequestsURL] = useState(
		orgSettings?.attributes.generalSettings?.programmaticAuthorizationHttpPostURL ?? null
	)

	const [enableSecretToken, setEnableSecretToken] = useState<boolean>(
		orgSettings?.attributes.generalSettings.secretToken !== null &&
			enableAPIBasedAuthorizationRequests &&
			currentAuthorizationRequestsURL != null
	)

	const [currentSecretToken, setSecretToken] = useState(orgSettings?.attributes.generalSettings?.secretToken ?? null)

	const {addToast} = useToasts()

	useEffect(() => {
		if (state.isOk()) {
			addToast("General Settings Updated Successfully", {appearance: "success"})
			refresh()
		}
	}, [state])

	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<h3> General </h3>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						patch(accessToken, {
							generalSettings: {
								preventDuplicateIndividualApplications: preventDuplicateIndividualApplications,
								showSensitiveDataForPhysicalCards: showSensitiveDataForPhysicalCards,
								enableTagInheritanceForCards: enableTagInheritanceForCards,
								programmaticAuthorizationHttpPostURL: enableAPIBasedAuthorizationRequests
									? currentAuthorizationRequestsURL
									: null,
								secretToken: enableSecretToken ? currentSecretToken : null,
							},
						})
					}}
				>
					<fieldset>
						<div className="field">
							<label className="label">
								<input
									type="checkbox"
									checked={preventDuplicateIndividualApplications}
									onChange={(e) => setPreventDuplicateIndividualApplications(e.target.checked)}
								/>{" "}
								Prevent duplicate individual applications
							</label>
						</div>
						<div className="field">
							<label className="label">
								<input
									type="checkbox"
									checked={showSensitiveDataForPhysicalCards}
									onChange={(e) => setShowSensitiveDataForPhysicalCards(e.target.checked)}
								/>{" "}
								Show sensitive data for physical cards
							</label>
						</div>
						<div className="field">
							<label className="label">
								<input
									type="checkbox"
									checked={enableTagInheritanceForCards}
									onChange={(e) => setEnableTagInheritanceForCards(e.target.checked)}
								/>{" "}
								Enable tag inheritance for Cards
								<label className="tooltip-container">
									<a data-tip="React-tooltip" data-for="tag-inheritance-tooltip" data-event="click focus">
										<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
										<ReactTooltip
											id="tag-inheritance-tooltip"
											place="right"
											effect="solid"
											className="tooltip-info"
											globalEventOff="click"
											border={true}
											borderColor="#C4C4C4"
											backgroundColor="#F5F5F5"
											textColor="black"
											data-html={true}
										>
											Any tags added to the card will be{" "}
											<a rel="noreferrer" target="_blank" href="https://docs.unit.co/#tag-inheritance">
												inherited
											</a>{" "}
											by all related{" "}
											<a rel="noreferrer" target="_blank" href="https://docs.unit.co/cards-authorization-requests/">
												Authorization Requests
											</a>
											,{" "}
											<a rel="noreferrer" target="_blank" href="https://docs.unit.co/cards-authorizations/">
												Authorizations
											</a>{" "}
											and{" "}
											<a rel="noreferrer" target="_blank" href="https://docs.unit.co/transactions/">
												transactions
											</a>
										</ReactTooltip>
									</a>
								</label>
							</label>
						</div>
						<div className="field">
							<label className="label">
								<input
									type="checkbox"
									checked={enableAPIBasedAuthorizationRequests}
									onChange={(e) => setEnableAPIBasedAuthorizationRequests(e.target.checked)}
								/>
								Enable API based authorization requests
								<label className="tooltip-container">
									<a
										data-tip="React-tooltip"
										data-for="api-based-authorization-requests-tooltip"
										data-event="click focus"
									>
										<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
										<ReactTooltip
											id="api-based-authorization-requests-tooltip"
											place="right"
											effect="solid"
											className="tooltip-info"
											globalEventOff="click"
											border={true}
											borderColor="#C4C4C4"
											backgroundColor="#F5F5F5"
											textColor="black"
											data-html={true}
										>
											<a rel="noreferrer" target="_blank" href="https://guides.unit.co/webhooks#unit-ips">
												If you want to whitelist unit ips
											</a>{" "}
										</ReactTooltip>
									</a>
								</label>
							</label>
						</div>
						{enableAPIBasedAuthorizationRequests && (
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="url"
										placeholder="Enter HTTPS Url"
										value={currentAuthorizationRequestsURL ?? undefined}
										required={enableAPIBasedAuthorizationRequests}
										pattern="https:.*"
										onChange={(e) => {
											e.target.setCustomValidity("")

											if (!e.target.validity.valid) {
												e.target.setCustomValidity("Please enter a valid HTTPS URL.")
											}

											setAPIBasedAuthorizationRequestsURL(e.target.value)
										}}
									/>
								</div>
							</div>
						)}
						{enableAPIBasedAuthorizationRequests && (
							<div className="field">
								<label className="label">
									<input
										type="checkbox"
										checked={enableSecretToken}
										onChange={(e) => setEnableSecretToken(e.target.checked)}
									/>
									Add optional secret
									<label className="tooltip-container">
										<a data-tip="React-tooltip" data-for="secret-token-tooltip" data-event="click focus">
											<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
											<ReactTooltip
												id="secret-token-tooltip"
												place="right"
												effect="solid"
												className="tooltip-info"
												globalEventOff="click"
												border={true}
												borderColor="#C4C4C4"
												backgroundColor="#F5F5F5"
												textColor="black"
												data-html={true}
											>
												<a
													rel="noreferrer"
													target="_blank"
													href="https://docs.unit.co/cards-authorization-requests/#securing-your-http-servers"
												>
													Securing your http servers
												</a>{" "}
											</ReactTooltip>
										</a>
									</label>
								</label>
							</div>
						)}
						{enableAPIBasedAuthorizationRequests && (
							<div className="field">
								<div className="control">
									<input
										className="input"
										type="text"
										placeholder="This Secret will include with every authorization request"
										value={currentSecretToken ?? undefined}
										required={enableSecretToken}
										onChange={(e) => {
											setSecretToken(e.target.value)
										}}
									/>
								</div>
							</div>
						)}
					</fieldset>
					<div className="columns">
						<div className="column mt-4">
							{state.match(
								() => (
									<button className="button is-success">Update</button>
								),
								() => (
									<button className="button is-success is-loading">Updating</button>
								),
								(_) => null,
								(err) => (
									<>
										<div className="has-text-danger">{err.errors[0].title}</div>
										<button className="button is-success">Update</button>
									</>
								)
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
