import React from "react"
import classNames from "classnames"
import {kebabCase, startCase} from "lodash"

interface ModalHeaderProps {
	id: string
	status: string
	close: () => void
}

export function ApplicationDocumentModalHeader(props: ModalHeaderProps) {
	const {id, status, close} = props

	return (
		<header>
			<h3>Document #{id}</h3>
			<div className={classNames("application-documents-status", kebabCase(status))}>{startCase(status)}</div>
			<button className="modal-close is-large" aria-label="close" onClick={close}></button>
		</header>
	)
}
