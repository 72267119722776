import React, {useEffect, useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import {createBatchAccount} from "../../resources/account"
import {findDepositProducts} from "../../resources/depositProduct"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findBanks} from "../../resources/bank"
import {findOrgs} from "../../resources/org"

type CloseFunc = () => void
type RefreshFunc = () => void

interface NewModalProps {
	close: CloseFunc
	refresh: RefreshFunc
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		close()
		refresh()
	}
}

export function NewBatchAccountModal({close, refresh}: NewModalProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createBatchAccount)
	const [name, setName] = useState<string>("")
	const [depositProduct, setDepositProduct] = useState<string | undefined>("")
	const [org, setOrg] = useState<string | undefined>(undefined)
	const [bank, setBank] = useState<string | undefined>(undefined)
	const [depositProductsState, find] = useAsyncResultIdle(findDepositProducts)
	const banksResult = useAsyncResult(() => findBanks(accessToken, 0, 1000))
	const orgsResult = useAsyncResult(() => findOrgs(accessToken, 0, 10000))

	useEffect(() => {
		if (bank && org) {
			setDepositProduct(undefined)
			find(accessToken, 0, 100, [org])
		}
	}, [org, bank])

	return (
		<AsyncResultModal
			title="New Batch Account"
			onSubmit={() => {
				if (org && bank) {
					create(accessToken, org ?? "", name, depositProduct ?? "")
				}
			}}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refreshAndClose(refresh, close)}
		>
			<div className="field">
				<label className="label">Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Account name"
						value={name}
						required
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Org</label>
				<div className="control">
					<div className="select">
						<select value={org} required onChange={(e) => setOrg(e.target.value)} disabled={!orgsResult.isOk()}>
							<option key="default" defaultValue={undefined} />
							{orgsResult.match(
								() => null,
								(orgs) =>
									orgs.map((o) => (
										<option key={o.id} value={o.id.toString()}>
											{o.attributes.name}
										</option>
									)),
								() => null
							)}
						</select>
					</div>
				</div>
			</div>

			<div className="field">
				<label className="label">Bank</label>
				<div className="control">
					<div className="select">
						<select value={bank} required onChange={(e) => setBank(e.target.value)} disabled={!banksResult.isOk()}>
							<option key="default" defaultValue={undefined} />
							{banksResult.match(
								() => null,
								(banks) =>
									banks.map((b) => (
										<option key={b.id} value={b.id.toString()}>
											{b.attributes.name}
										</option>
									)),
								() => null
							)}
						</select>
					</div>
				</div>
			</div>

			<div className="field">
				<label className="label">Deposit Product</label>
				<div className="control">
					<div className="select">
						<select
							value={depositProduct}
							required
							onChange={(e) => setDepositProduct(e.target.value)}
							disabled={!depositProductsState.isOk()}
						>
							<option key="default" defaultValue={undefined} />
							{depositProductsState.match(
								() => null,
								() => null,
								(deposit) =>
									deposit.data
										.filter((d) => d.relationships?.bank?.data?.id == bank && d.relationships?.org?.data?.id == org)
										.map((d) => (
											<option key={d.id} value={d.attributes.name}>
												{d.attributes.name}
											</option>
										)),
								() => null
							)}
						</select>
					</div>
				</div>
			</div>
		</AsyncResultModal>
	)
}
