import React, {useEffect, useState} from "react"
import {BeneficialOwnerResource, updateBeneficialOwner} from "../../resources/beneficialOwner"
import {AnnualIncomeMapping, OccupationMapping, SourceOfIncomeMapping} from "../../resources/application"
import {useAccessToken, useIsUnitUser} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {EditableOccupationField} from "../ApplicationAdditionalInformationFields/OccupationField"
import {isUndefined} from "lodash"
import {EditableAnnualIncomeField} from "../ApplicationAdditionalInformationFields/AnnualIncomeField"
import {EditableSourceOfIncomeField} from "../ApplicationAdditionalInformationFields/SourceOfIncomeField"
import {useToasts} from "react-toast-notifications"

export interface EditableBeneficialOwnerAdditionalInformationProps {
	beneficialOwner: BeneficialOwnerResource
	isEditing: string | undefined
	refresh: () => void
	setIsEditing: (v: string | undefined) => void
	setIsUpdating: (v: boolean) => void
	isApplicationEditable: boolean
	editingDisabled: boolean
}

export function EditableBeneficialOwnerAdditionalInformation(props: EditableBeneficialOwnerAdditionalInformationProps) {
	const accessToken = useAccessToken()
	const [occupation, setOccupation] = useState(props.beneficialOwner.attributes.occupation)
	const [annualIncome, setAnnualIncome] = useState(props.beneficialOwner.attributes.annualIncome)
	const [sourceOfIncome, setSourceOfIncome] = useState(props.beneficialOwner.attributes.sourceOfIncome)
	const occupationText = OccupationMapping.get(props.beneficialOwner.attributes.occupation as string)
	const annualIncomeText = AnnualIncomeMapping.get(props.beneficialOwner.attributes.annualIncome as string)
	const sourceOfIncomeText = SourceOfIncomeMapping.get(props.beneficialOwner.attributes.sourceOfIncome as string)
	const beneficialOwner = props.beneficialOwner

	const [updateState, updateBo] = useAsyncResultIdle(updateBeneficialOwner)
	const {addToast} = useToasts()
	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Beneficial Owner Updated Successfully", {appearance: "success"})
			props.setIsUpdating(false)
			props.refresh()
		} else if (updateState.isErr()) {
			addToast(updateState.error.errors[0].detail || updateState.error.errors[0].title, {appearance: "error"})
			props.setIsUpdating(false)
			props.refresh()
		}
	}, [updateState])

	return occupationText || annualIncomeText || sourceOfIncomeText ? (
		<div className={"bo-more-info-inner-container"}>
			<div className={"bo-more-info-label"}>
				{beneficialOwner.attributes.fullName.first} {beneficialOwner.attributes.fullName.last}
			</div>
			{occupation ? (
				<EditableOccupationField
					occupation={occupation}
					setOccupation={setOccupation}
					isEditing={props.isEditing === `BeneficialOwner${beneficialOwner.id}Occupation`}
					isDisabled={!isUndefined(props.isEditing)}
					isAllowed={useIsUnitUser() && props.isApplicationEditable && !props.editingDisabled}
					onStartEdit={() => props.setIsEditing(`BeneficialOwner${beneficialOwner.id}Occupation`)}
					onCancel={props.refresh}
					update={(v) => {
						updateBo(accessToken, props.beneficialOwner, {occupation: v})
						props.setIsUpdating(true)
					}}
				/>
			) : null}
			{annualIncome ? (
				<EditableAnnualIncomeField
					annualIncome={annualIncome}
					setAnnualIncome={setAnnualIncome}
					isEditing={props.isEditing === `BeneficialOwner${beneficialOwner.id}AnnualIncome`}
					isDisabled={!isUndefined(props.isEditing)}
					isAllowed={useIsUnitUser() && props.isApplicationEditable && !props.editingDisabled}
					onStartEdit={() => props.setIsEditing(`BeneficialOwner${beneficialOwner.id}AnnualIncome`)}
					onCancel={props.refresh}
					update={(v) => {
						updateBo(accessToken, beneficialOwner, {annualIncome: v})
						props.setIsUpdating(true)
					}}
				/>
			) : null}
			{sourceOfIncome ? (
				<EditableSourceOfIncomeField
					sourceOfIncome={sourceOfIncome}
					setSourceOfIncome={setSourceOfIncome}
					isEditing={props.isEditing === `BeneficialOwner${beneficialOwner.id}SourceOfIncome`}
					isDisabled={!isUndefined(props.isEditing)}
					isAllowed={useIsUnitUser() && props.isApplicationEditable && !props.editingDisabled}
					onStartEdit={() => props.setIsEditing(`BeneficialOwner${beneficialOwner.id}SourceOfIncome`)}
					onCancel={props.refresh}
					update={(v) => {
						updateBo(accessToken, beneficialOwner, {sourceOfIncome: v})
						props.setIsUpdating(true)
					}}
				/>
			) : null}
		</div>
	) : null
}
