import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {useIsUnitUser} from "../../services/auth"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {NewBatchAccountModal} from "./NewBatchAccountModal"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useModal} from "react-modal-hook"
import Icon from "../../components/Icon/Icon"

export function BatchAccountsPage(): ReactElement {
	const isUnitUser = useIsUnitUser()
	const [refreshToken, refresh] = useRefreshToken()
	const [showCreateModal, hideCreateModal] = useModal(() => {
		return <NewBatchAccountModal close={hideCreateModal} refresh={refresh} />
	})

	return (
		<>
			<TitleBar title={"Batch Accounts"}>
				<UnitUserOnly>
					<button className="button button-create" onClick={() => showCreateModal()}>
						<Icon icon="interface-add-1" size={12} /> Create
					</button>
				</UnitUserOnly>
			</TitleBar>

			<MainSectionTable>
				<Accounts
					enableOrgFilter
					enableBankFilter={isUnitUser}
					enableStatusFilter
					enableSearch
					enableOverdraftStatusFilter
					enableTypeFilter
					limit={40}
					refreshToken={refreshToken}
					type={"batch"}
					fullHeight={true}
					includedColumns={[
						AccountsColumns.id,
						...(isUnitUser ? [AccountsColumns.org] : []),
						...(isUnitUser ? [AccountsColumns.bank] : []),
						AccountsColumns.balance,
						AccountsColumns.product,
						AccountsColumns.routingNumber,
						AccountsColumns.accountNumber,
						AccountsColumns.createdAt,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
