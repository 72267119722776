import React, {PropsWithChildren} from "react"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"
import ReactTooltip from "react-tooltip"

export type ButtonProps = {
	size?: "small" | "normal" | "medium" | "large"
	theme?: "neutral" | "info" | "warning" | "danger" | "success"
	variant?: "primary" | "subtle" | "outline" | "flat"
	loading?: boolean
	prefixIcon?: IcomoonIconName
	suffixIcon?: IcomoonIconName
	disabled?: boolean
	onClick?: () => void
	circular?: boolean
	tooltip?: string
	tooltipKey?: string
	tooltipDisabled?: boolean
	tooltipClassName?: string
	className?: string
}

const getVariantClassName = (variant: ButtonProps["variant"]) => {
	switch (variant) {
		case "primary": {
			return "is-black"
		}
		case "subtle": {
			return "is-light"
		}
		case "outline": {
			return "is-outlined"
		}
		case "flat": {
			return "is-inverted is-black"
		}
		default:
			return null
	}
}

const getIconSize = (size: ButtonProps["size"]) => {
	switch (size) {
		case "small": {
			return "12px"
		}
		case "normal": {
			return "16px"
		}
		case "medium": {
			return "20px"
		}
		case "large": {
			return "24px"
		}
		default:
			return "16px"
	}
}

export function Button({
	size = "normal",
	theme = "neutral",
	variant = "primary",
	loading,
	prefixIcon,
	suffixIcon,
	disabled,
	onClick,
	circular,
	tooltip,
	tooltipKey,
	tooltipDisabled,
	tooltipClassName,
	className,
	children,
}: PropsWithChildren<ButtonProps>) {
	const iconSize = getIconSize(size)
	const iconClassNames = classNames("icon", `is-${size}`)
	return (
		<>
			<button
				className={classNames(
					"button dashboard-button",
					size && `is-${size}`,
					theme && theme !== "neutral" && `is-${theme}`,
					variant && getVariantClassName(variant),
					loading && "is-loading",
					circular && "is-circular is-rounded",
					className
				)}
				disabled={disabled}
				onClick={onClick}
				data-tip={tooltip}
				data-for={tooltipKey}
				data-tip-disable={tooltipDisabled}
			>
				{prefixIcon && (
					<span className={iconClassNames}>
						<Icon icon={prefixIcon} size={iconSize} />
					</span>
				)}
				{children && <span>{children}</span>}
				{suffixIcon && (
					<span className={iconClassNames}>
						<Icon icon={suffixIcon} size={iconSize} />
					</span>
				)}
			</button>
			{tooltip && <ReactTooltip id={tooltipKey} place="top" effect="solid" className={tooltipClassName} multiline />}
		</>
	)
}

export default Button
