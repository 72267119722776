import {createResource, getResource, updateResource} from "./common"

export type InterestSettings = {
	type: "interestSettings"
	id: string
	attributes: {
		createdAt: Date
		updatedAt: Date
		name: string
		interestFormula: string
		unitInterest: number
		evaluatedInterestRatePercentage?: number
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export async function createInterestSettings(
	accessToken: string,
	bankId: string,
	interestFormula: string,
	unitInterest: number,
	name?: string
) {
	return await createResource<InterestSettings>("interest-settings", accessToken, {
		type: "interestSettings",
		attributes: {
			name,
			interestFormula,
			unitInterest,
		},
		relationships: {
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
		},
	})
}

export async function updateInterestSettings(
	accessToken: string,
	id: string,
	bankId: string,
	interestFormula: string,
	unitInterest?: number,
	name?: string
) {
	return await updateResource<InterestSettings>(`interest-settings/${id}`, accessToken, {
		type: "interestSettings",
		attributes: {
			name,
			interestFormula,
			unitInterest,
		},
		relationships: {
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
		},
	})
}

export async function getInterestSettings(accessToken: string, interestSettingsId: string, bankId: string) {
	const result = await getResource<InterestSettings>(
		`banks/${bankId}/interest-settings/${interestSettingsId}`,
		accessToken
	)

	return result.map((v) => v.data)
}

export async function findInterestSettings(
	accessToken: string,
	offset: number,
	limit: number,
	filterByBank?: string,
	filterByFormula?: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			bankId: filterByBank,
			formula: filterByFormula,
		},
		include: "bank",
	}

	return await getResource<Array<InterestSettings>>("interest-settings", accessToken, query)
}

export async function getEvaluatedInterest(accessToken: string, interestSettingsId: string) {
	return await getResource<InterestSettings>(`interest-settings/${interestSettingsId}/evaluation`, accessToken)
}
