import {IndividualCustomer} from "../../resources/customer"
import React from "react"
import {useNavigate} from "react-router-dom"

function AccountOwnerRow({accountOwner}: {accountOwner: IndividualCustomer}) {
	const navigate = useNavigate()
	return (
		<tr
			onClick={(e) => {
				e.preventDefault()
				navigate(`/individual/${accountOwner.id}`)
			}}
		>
			<td>{accountOwner.id}</td>
			<td>{`${accountOwner.attributes.fullName.first} ${accountOwner.attributes.fullName.last}`}</td>
		</tr>
	)
}

export function AccountOwners({accountOwners}: {accountOwners: IndividualCustomer[]}) {
	return (
		<div>
			<div className="table-container">
				<table className="table is-hoverable is-fullwidth">
					<thead>
						<tr>
							<th>Id</th>
							<th>Name</th>
						</tr>
					</thead>
					<tbody>
						{accountOwners.map((c) => (
							<AccountOwnerRow key={c.id} accountOwner={c} />
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}
