import React, {useEffect} from "react"
import {OrgAuthProvider, useCustomerToken} from "../../services/orgAuth"
import OrgAndCustomerAccountsSummary from "../../components/OrgAccountsSummary/OrgAndCustomerAccountsSummary"
import {useAccessToken, useIsOrgUnitPilot, useIsUnitUser, useUserClaimsData} from "../../services/auth"
import {useLocalStorage} from "react-use"
import {useModal} from "react-modal-hook"
import {UnitPilotWelcomeModal} from "../../components/UnitPilotWelcomeModal/UnitPilotWelcomeModal"
import DashboardTutorial from "../../components/DashboardTutorial/DashboardTutorial"
import {isProdEnv} from "../../utilities/environment"
import {MainSection} from "../../containers/MainSection/MainSection"
import OrgAccountsPlaceholder from "../../components/OrgAccountsSummary/OrgAccountsPlaceholder"
import {Err} from "../../components/Err/Err"
import {ChargeCardsPlanOverview} from "./ChargeCardsPlanOverview/ChargeCardsPlanOverview"
import DepositsOverTime from "../../components/TotalDeposits/DepositsOverTime"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"

function OrgAccounts() {
	const customerToken = useCustomerToken()
	const orgId = useUserClaimsData().orgId
	return (
		<OrgAndCustomerAccountsSummary
			customerId={customerToken.attributes.customerId}
			customerAccountsAccessToken={customerToken.attributes.token}
			orgId={orgId}
		/>
	)
}

export default function OrgDashboard() {
	const accessToken = useAccessToken()
	const [showUnitPilotWelcomeModalLocalStorage, setShowUnitPilotWelcomeModalLocalStorage] = useLocalStorage(
		"showUnitPilotWelcomeModal",
		"true"
	)
	const [showUnitPilotWelcomeModal, closeUnitPilotWelcomeModal] = useModal(() => (
		<UnitPilotWelcomeModal
			close={() => {
				closeUnitPilotWelcomeModal()
				setShowUnitPilotWelcomeModalLocalStorage("false")
			}}
		/>
	))
	const isOrgUnitPilot = useIsOrgUnitPilot()
	const isUnitUser = useIsUnitUser()

	useEffect(() => {
		if (isOrgUnitPilot && showUnitPilotWelcomeModalLocalStorage === "true") {
			showUnitPilotWelcomeModal()
		}
	}, [])

	let dashboardTutorial = null
	if (isOrgUnitPilot) {
		dashboardTutorial = <DashboardTutorial type="pilot" />
	} else if (!isProdEnv()) {
		dashboardTutorial = <DashboardTutorial type="sandbox" />
	}
	return (
		<div>
			<MainSection>
				{dashboardTutorial}
				{isOrgUnitPilot ? (
					<OrgAccountsPlaceholder />
				) : (
					<OrgAuthProvider onError={(e) => <Err err={e} />}>
						<OrgAccounts />
					</OrgAuthProvider>
				)}
				<ChargeCardsPlanOverview />
				<DepositsOverTime />
				<Transactions
					disableShadow={true}
					token={accessToken}
					fullHeight={false}
					limit={20}
					enableSearch={true}
					excludeGlTransaction={true}
					enableDirectionFilter={true}
					dataTableClassName={isUnitUser ? "" : "homepage-large-title"}
					includedColumns={[
						TransactionsColumns.id,
						TransactionsColumns.accountId,
						TransactionsColumns.customer,
						TransactionsColumns.type,
						TransactionsColumns.summary,
						TransactionsColumns.balance,
						TransactionsColumns.amount,
						TransactionsColumns.createdAt,
					]}
				/>
			</MainSection>
		</div>
	)
}
