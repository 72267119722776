import React from "react"
import Icon from "../Icon/Icon"

export function UnitPilotLimitsNotice({text}: {text: string}) {
	return (
		<div className="unit-pilot-limits-notice">
			<Icon icon={"lock-square-lock-1--interface-essential"} size={20} className="icon top-icon" />
			<p>{text}</p>
			<div>
				<a target="_blank" rel="noreferrer" href={"mailto:sales@unit.co"} className="button is-outlined">
					<Icon icon={"email-envelope-1--emails"} size={12} className="letter-icon" /> Contact us to move to production
				</a>
			</div>
		</div>
	)
}
