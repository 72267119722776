import React from "react"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"
import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getAccountsMetrics} from "../../resources/account"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import Skeleton from "react-loading-skeleton"
import {toShortNumberal} from "../../utilities/numbers"

function OverviewItem({icon, title, value}: {icon: IcomoonIconName; title: string; value: string | number}) {
	return (
		<div className={"overview-item"}>
			<div className={"overview-item-title"}>
				<Icon icon={icon} size={20} />
				<span>{title}</span>
			</div>
			<span className={"overview-item-value"}>{value}</span>
		</div>
	)
}

function OverviewPending() {
	return (
		<>
			<div className={"overview-item"}>
				<div className={"overview-item-title"}>
					<Skeleton />
				</div>
				<span className={"overview-item-value"}>
					<Skeleton />
				</span>
			</div>

			<div className={"overview-item"}>
				<div className={"overview-item-title"}>
					<Skeleton />
				</div>
				<span className={"overview-item-value"}>
					<Skeleton />
				</span>
			</div>

			<div className={"overview-item"}>
				<div className={"overview-item-title"}>
					<Skeleton />
				</div>
				<span className={"overview-item-value"}>
					<Skeleton />
				</span>
			</div>
		</>
	)
}

export function DepositsOverTimeOverview() {
	const accessToken = useAccessToken()
	const accountMetricsResult = useAsyncResult(() => getAccountsMetrics(accessToken))

	return (
		<AsyncResultComponent asyncResult={accountMetricsResult} pendingComponent={<OverviewPending />}>
			{({value: accountMetrics}) => {
				const totalBalance = accountMetrics.data.attributes.totalDepositBalance / 100
				const numberOfDepositAccounts = accountMetrics.data.attributes.numberOfDepositAccounts
				return (
					<div className={"deposits-over-time-overview"}>
						<OverviewItem
							icon={"bank-account--business-products"}
							title={"Deposit Accounts"}
							value={numberOfDepositAccounts}
						/>
						<OverviewItem
							icon={"payment-box-incoming"}
							title={"Total Deposits"}
							value={toShortNumberal(totalBalance)}
						/>
					</div>
				)
			}}
		</AsyncResultComponent>
	)
}
