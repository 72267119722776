import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {createSandboxClient, SandboxClient} from "../../resources/org"
import {ErrorDocument} from "../../resources/common"
import React, {useState} from "react"
import {useAccessToken} from "../../services/auth"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {generate} from "generate-password"

function Footer({state}: {state: AsyncResultIdleRequestState<SandboxClient, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Create</button>
				),
				() => (
					<button className="button is-success is-loading">Creating</button>
				),
				(_) => (
					<div className="has-text-success">Succeed</div>
				),
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].detail}</div>
						<button className="button is-success">Create</button>
					</>
				)
			)}
		</div>
	)
}

function Page({isSandbox}: {isSandbox: boolean}) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createSandboxClient)
	const [orgName, setOrgName] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [primaryEmail, setPrimaryEmail] = useState("")
	const emailTemplateId = isSandbox ? "d-8a0d7bb2095149f4967c15dbd407e100" : "d-54ec158e396048d8a8741e3ca6fb78dd"
	const password = generate({
		length: 10,
		numbers: true,
		symbols: true,

		strict: true,
		exclude: "({}[]()/'\"`~,;:.<>?-+=|_)",
	})

	const submit = () => {
		create(accessToken, orgName, firstName, lastName, primaryEmail, password, emailTemplateId)
	}

	return (
		<>
			<TitleBar title={isSandbox ? "New Sandbox Client" : "New Unit Pilot Client"} />
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
						</div>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						submit()
					}}
				>
					<div className="columns">
						<div className="column">
							<div className="card">
								<div className="card-header"></div>
								<div className="card-content">
									<div className="field is-horizontal">
										<div className="field-label is-normal">
											<label className="label">Org Name</label>
										</div>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="text"
													placeholder="Pied Piper"
													value={orgName}
													required
													onChange={(e) => setOrgName(e.target.value)}
												/>
											</div>
											<div className="ml-10 control">{"{{orgName}}"}</div>
										</div>
									</div>

									<div className="field is-horizontal">
										<div className="field-label is-normal">
											<label className="label">First Name</label>
										</div>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="text"
													placeholder="Pied Piper"
													value={firstName}
													required
													onChange={(e) => setFirstName(e.target.value)}
												/>
											</div>
											<div className="ml-10 control">{"{{firstName}}"}</div>
										</div>
									</div>

									<div className="field is-horizontal">
										<div className="field-label is-normal">
											<label className="label">Last Name</label>
										</div>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="text"
													placeholder="Pied Piper"
													value={lastName}
													required
													onChange={(e) => setLastName(e.target.value)}
												/>
											</div>
											<div className="ml-10 control">{"{{lastName}}"}</div>
										</div>
									</div>

									<div className="field is-horizontal">
										<div className="field-label is-normal">
											<label className="label">Primary Email</label>
										</div>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="email"
													placeholder="Pied Piper"
													value={primaryEmail}
													required
													onChange={(e) => setPrimaryEmail(e.target.value)}
												/>
											</div>
											<div className="ml-10 control">{"{{primaryEmail}}"}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="card">
								<Footer state={state} />
							</div>
						</div>
					</div>
				</form>
			</MainSection>
		</>
	)
}

export function NewSandboxClient() {
	return <Page isSandbox={true} />
}

export function NewUnitPilotClient() {
	return <Page isSandbox={false} />
}
