import React from "react"
import {
	IndividualCustomer,
	BusinessCustomer,
	Customer,
	isIndividualCustomer,
	isBusinessCustomer,
	getCustomerName,
} from "../../resources/customer"
import {Address, AuthorizedUser} from "../../resources/common"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardSubHeader,
	KeyValueCardValue,
} from "../KeyValueCard/KeyValueCard"
import moment, {Moment} from "moment"
import {startCase, upperFirst} from "lodash"
import Icon from "../Icon/Icon"
import {addressText} from "../../utilities/helpers"
import {CustomerKnownNames} from "../../resources/customerKnownNames"
import {KeyValueCardView} from "../RenderKeyValueCard/KeyValueCardView"

interface CustomerCardViewProps {
	title?: string
	customer: Customer
	orgName?: string | JSX.Element
	showBusinessDetails?: boolean
	showBusinessContactDetails?: boolean
	singleColumn?: boolean
	liteVersion?: boolean
	customerKnownNames?: CustomerKnownNames
	disabled?: boolean
	className?: string
}

function CreatedAt({date}: {date: Moment}) {
	return (
		<span data-chromatic="ignore">
			{upperFirst(date.fromNow())} <span className="optional"> ({date.format("L LT")}) </span>
		</span>
	)
}

function AddressText({address}: {address: Address}) {
	return <span className={"address-placeholder"}>{addressText(address)}</span>
}

export function NameWithId({name, id}: {name: string; id?: string}) {
	return (
		<div className={"customer-name-with-id"}>
			{name} <span className="with-divider" />
			<a href={`/customers/${id}`} target={"_blank"} rel={"noreferrer"} className={"link"}>
				<span className="optional"> #{id} </span>
			</a>
		</div>
	)
}

function TypeWithIcon({customer}: {customer: Customer}) {
	switch (customer.type) {
		case "individualCustomer":
			return (
				<div className={"customer-icon-with-name-container"}>
					<div className={"customer-icon-with-name individual"}>
						<Icon size={12} icon={"user-geometric-action---users"} />
					</div>
					<span> Individual </span>
				</div>
			)

		case "businessCustomer":
			return (
				<div className={"customer-icon-with-name-container"}>
					<div className={"customer-icon-with-name business"}>
						<Icon size={12} icon={"toolbox"} />
					</div>
					<span> Business </span>
				</div>
			)
		case "businessFBOCustomer":
			return (
				<div className={"customer-icon-with-name-container"}>
					<div className={"customer-icon-with-name business"}>
						<Icon size={12} icon={"toolbox"} />
					</div>
					<span> Business FBO</span>
				</div>
			)
		case "individualWalletCustomer":
			return (
				<div className={"customer-icon-with-name-container"}>
					<div className={"customer-icon-with-name individual"}>
						<Icon size={12} icon={"user-geometric-action---users"} />
					</div>
					<span>Individual Wallet</span>
				</div>
			)
	}
}

function AuthorizedUsers({authorizedUsers}: {authorizedUsers: AuthorizedUser[]}) {
	return (
		<div className={"customer-authorized-users"}>
			{authorizedUsers.map((authorizedUser) => {
				return (
					<div key={authorizedUser.email} className={"customer-authorized-user"}>
						{authorizedUser.fullName.first} {authorizedUser.fullName.last}
					</div>
				)
			})}
		</div>
	)
}

function KnownNames({customerKnownNames}: {customerKnownNames: CustomerKnownNames}) {
	return (
		<div className={"customer-authorized-users"}>
			{customerKnownNames.attributes.names.map((name) => {
				return (
					<div key={name} className={"customer-authorized-user"}>
						{name}
					</div>
				)
			})}
		</div>
	)
}

function IndividualCard({
	customer,
	title,
	orgName,
	customerKnownNames,
	singleColumn,
	liteVersion = false,
	disabled,
	className,
}: {
	customer: IndividualCustomer
	title?: string
	orgName?: string | React.ReactNode
	customerKnownNames?: CustomerKnownNames
	singleColumn?: boolean
	liteVersion?: boolean
	disabled?: boolean
	className?: string
}) {
	const type = <TypeWithIcon customer={customer} />
	const name = <NameWithId name={getCustomerName(customer)} id={customer.id} />
	const phone =
		customer.attributes.phone && `+${customer.attributes.phone.countryCode} ${customer.attributes.phone.number}`
	const email = customer.attributes.email
	const address = customer.attributes.address && <AddressText address={customer.attributes.address} />
	const doingBusinessAs = customer.attributes.dba
	const createdAt = <CreatedAt date={moment(customer.attributes.createdAt)} />
	const authorizedUsers = customer.attributes.authorizedUsers.length > 0 && (
		<AuthorizedUsers authorizedUsers={customer.attributes.authorizedUsers} />
	)
	const knownAliases = customerKnownNames && customerKnownNames.attributes.names.length > 0 && (
		<KnownNames customerKnownNames={customerKnownNames} />
	)

	const customerDetails = liteVersion
		? {
				...(name ? {name} : {}),
				...(phone ? {phone} : {}),
		  }
		: {
				...(orgName ? {orgName} : {}),
				...(type ? {type} : {}),
				...(name ? {name} : {}),
				...(knownAliases ? {knownAliases} : {}),
				...(doingBusinessAs ? {doingBusinessAs} : {}),
				...(phone ? {phone} : {}),
				...(email ? {email} : {}),
				...(address ? {address} : {}),
				...(authorizedUsers ? {authorizedUsers} : {}),
				...(createdAt ? {createdAt} : {}),
		  }

	return (
		<KeyValueCardView
			details={customerDetails}
			title={title}
			singleColumn={singleColumn}
			disabled={disabled}
			className={className}
		/>
	)
}

function BusinessCard({
	customer,
	title,
	orgName,
	customerKnownNames,
	showBusinessDetails = true,
	showBusinessContactDetails = true,
	singleColumn,
	liteVersion,
	disabled,
	className,
}: {
	customer: BusinessCustomer
	title?: string
	orgName?: string | JSX.Element
	customerKnownNames?: CustomerKnownNames
	showBusinessDetails?: boolean
	showBusinessContactDetails?: boolean
	singleColumn?: boolean
	liteVersion?: boolean
	disabled?: boolean
	className?: string
}) {
	const type = <TypeWithIcon customer={customer} />
	const name = <NameWithId name={getCustomerName(customer)} id={customer.id} />
	const phone =
		customer.attributes.phone && `+${customer.attributes.phone.countryCode} ${customer.attributes.phone.number}`
	const address = customer.attributes.address && <AddressText address={customer.attributes.address} />
	const doingBusinessAs = customer.attributes.dba
	const createdAt = <CreatedAt date={moment(customer.attributes.createdAt)} />
	const authorizedUsers = <AuthorizedUsers authorizedUsers={customer.attributes.authorizedUsers} />
	const knownAliases = customerKnownNames && customerKnownNames.attributes.names.length > 0 && (
		<KnownNames customerKnownNames={customerKnownNames} />
	)

	const bcName = `${customer.attributes.contact.fullName.first} ${customer.attributes.contact.fullName.last}`
	const bcPhone =
		customer.attributes.contact.phone &&
		`+${customer.attributes.contact.phone.countryCode} ${customer.attributes.contact.phone.number}`
	const bcEmail = customer.attributes.contact.email && customer.attributes.contact.email

	const generalDetails = liteVersion
		? {
				...(name ? {name} : {}),
				...(knownAliases ? {knownAliases} : {}),
		  }
		: {
				...(orgName ? {orgName} : {}),
				...(type ? {type} : {}),
				...(name ? {name} : {}),
				...(knownAliases ? {knownAliases} : {}),
		  }

	const businessDetails = liteVersion
		? {
				...(phone ? {phone} : {}),
		  }
		: {
				...(doingBusinessAs ? {doingBusinessAs} : {}),
				...(authorizedUsers ? {authorizedUsers} : {}),
				...(address ? {address} : {}),
				...(phone ? {phone} : {}),
				...(createdAt ? {createdAt} : {}),
		  }

	const businessContactDetails = {
		name: bcName,
		phone: bcPhone,
		email: bcEmail,
	}

	return (
		<>
			<KeyValueCard title={title} singleColumn={singleColumn} disabled={disabled} className={className}>
				<KeyValueCardSubHeader title={"General"} />
				<KeyValueCardContainer>
					{Object.entries(generalDetails).map(([key, value]) => {
						return (
							<React.Fragment key={key}>
								<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
								<KeyValueCardValue disabled={disabled}> {value} </KeyValueCardValue>
							</React.Fragment>
						)
					})}
				</KeyValueCardContainer>
				{showBusinessDetails && (
					<>
						<KeyValueCardSubHeader title={"Business Details"} />
						<KeyValueCardContainer>
							{Object.entries(businessDetails).map(([key, value]) => {
								return (
									<React.Fragment key={key}>
										<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
										<KeyValueCardValue disabled={disabled}> {value} </KeyValueCardValue>
									</React.Fragment>
								)
							})}
						</KeyValueCardContainer>
					</>
				)}
			</KeyValueCard>

			{showBusinessContactDetails && (
				<KeyValueCard title={"Business Contact"} singleColumn={singleColumn} disabled={disabled} className={className}>
					<KeyValueCardContainer>
						{Object.entries(businessContactDetails).map(([key, value]) => {
							return (
								<React.Fragment key={key}>
									<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
									<KeyValueCardValue disabled={disabled}> {value} </KeyValueCardValue>
								</React.Fragment>
							)
						})}
					</KeyValueCardContainer>
				</KeyValueCard>
			)}
		</>
	)
}

export function CustomerCardView({
	title = "Customer Details",
	customer,
	orgName = "",
	showBusinessDetails = true,
	showBusinessContactDetails = true,
	singleColumn,
	liteVersion = false,
	customerKnownNames,
	disabled,
	className,
}: CustomerCardViewProps) {
	if (isIndividualCustomer(customer)) {
		return (
			<IndividualCard
				customer={customer}
				title={title}
				orgName={orgName}
				customerKnownNames={customerKnownNames}
				singleColumn={singleColumn}
				liteVersion={liteVersion}
				disabled={disabled}
				className={className}
			/>
		)
	}

	if (isBusinessCustomer(customer)) {
		return (
			<BusinessCard
				customer={customer}
				title={title}
				orgName={orgName}
				customerKnownNames={customerKnownNames}
				showBusinessDetails={showBusinessDetails}
				showBusinessContactDetails={showBusinessContactDetails}
				singleColumn={singleColumn}
				liteVersion={liteVersion}
				disabled={disabled}
				className={className}
			/>
		)
	}

	return <> </>
}
