import {Resource} from "../../resources/common"
import React from "react"
import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import Skeleton from "react-loading-skeleton"
import {Customer, getCustomer} from "../../resources/customer"

const getCustomerName = (customer: Customer): string => {
	switch (customer.type) {
		case "individualCustomer":
		case "individualWalletCustomer":
			return `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		case "businessCustomer":
		case "businessFBOCustomer":
			return customer.attributes.name
	}
}
export function CustomerName({
	customerId,
	included,
	token,
}: {
	customerId: string
	included?: Resource[]
	token?: string
}) {
	if (included) {
		const customer = included.find((r) => r.id === customerId)
		if (customer) {
			return <>{getCustomerName(customer as Customer)}</>
		}
	}

	const accessToken = token ?? useAccessToken()

	// TODO: cache the requests
	const result = useAsyncResult(() => getCustomer(accessToken, customerId), [customerId])

	return result.match(
		() => <Skeleton />,
		(customer) => <>{getCustomerName(customer.data)}</>,
		(_err) => <>Error</>
	)
}
