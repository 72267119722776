import React, {useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	Account,
	BankReason,
	closeAccount,
	CloseReason,
	FraudReason,
	isBatchAccount,
	isClosed,
	reopenAccount,
} from "../../resources/account"
import {useAccessToken, useIsBankAdminOrOperationsUser, useIsUnitUser} from "../../services/auth"
import Admonition from "../../components/Admonitions/Admonition"

interface CloseAccountModalProps {
	account: Account
	closeModal: () => void
	onSuccess: () => void
}

interface StringToReasonSet {
	[key: string]: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function CloseAccountModal({account, closeModal, onSuccess}: CloseAccountModalProps) {
	const accessToken = useAccessToken()
	const isUnit = useIsUnitUser()
	const [reason, setReason] = useState<CloseReason | undefined>()
	const [fraudReason, setFraudReason] = useState<FraudReason | undefined>()
	const [bankReason, setBankReason] = useState<BankReason | undefined>()
	const [reasonText, setReasonText] = useState("")
	const [closeState, setCloseState] = useAsyncResultIdle(closeAccount)
	const [reopenState, setReopenState] = useAsyncResultIdle(reopenAccount)

	function createReasonCallback(reason: CloseReason, fraudReason?: FraudReason, bankReason?: BankReason) {
		return () => {
			setReason(reason)
			setFraudReason(fraudReason)
			setBankReason(bankReason)
		}
	}
	const byCustomerReason = {"By Customer": createReasonCallback(CloseReason.ByCustomer)}

	const fraudReasons = {
		"Fraud - ACH Activity": createReasonCallback(CloseReason.Fraud, FraudReason.ACHActivity),
		"Fraud - Card Activity": createReasonCallback(CloseReason.Fraud, FraudReason.CardActivity),
		"Fraud - Check Activity": createReasonCallback(CloseReason.Fraud, FraudReason.CheckActivity),
		"Fraud - Application Activity": createReasonCallback(CloseReason.Fraud, FraudReason.ApplicationHistory),
		"Fraud - Account Activity": createReasonCallback(CloseReason.Fraud, FraudReason.AccountActivity),
		"Fraud - Client Identified": createReasonCallback(CloseReason.Fraud, FraudReason.ClientIdentified),
		"Fraud - Identity Theft": createReasonCallback(CloseReason.Fraud, FraudReason.IdentityTheft),
		"Fraud - Linked to Fraudulent Customer": createReasonCallback(
			CloseReason.Fraud,
			FraudReason.LinkedToFraudulentCustomer
		),
	}
	const negativeBalanceReason = {
		"Negative Balance": createReasonCallback(CloseReason.NegativeBalance),
	}
	const programChange = {
		"Program Change": createReasonCallback(CloseReason.ProgramChange),
	}
	const breachOfTermsAndConditions = {
		"Breach Of Terms and Conditions": createReasonCallback(CloseReason.BreachOfTermsAndConditions),
	}
	const noAccountActivity = {
		"No Account Activity": createReasonCallback(CloseReason.NoAccountActivity),
	}

	const byBankReason = {
		"By Bank - Prohibited Business": createReasonCallback(CloseReason.ByBank, undefined, BankReason.ProhibitedBusiness),
		"By Bank - Missing CDD/EDD": createReasonCallback(CloseReason.ByBank, undefined, BankReason.MissingCddEdd),
		"By Bank - Non-US Operations": createReasonCallback(CloseReason.ByBank, undefined, BankReason.NonUsOperations),
		"By Bank - Suspected Fraud": createReasonCallback(CloseReason.ByBank, undefined, BankReason.SuspectedFraud),
		"By Bank - Unfunded": createReasonCallback(CloseReason.ByBank, undefined, BankReason.Unfunded),
		"By Bank - Inactive": createReasonCallback(CloseReason.ByBank, undefined, BankReason.Inactive),
	}

	function getCloseReasonSet(): StringToReasonSet {
		if (isBatchAccount(account)) {
			return byCustomerReason
		}
		if (useIsBankAdminOrOperationsUser()) {
			return byBankReason
		}
		const byBank = isUnit ? byBankReason : {}
		return {
			...byCustomerReason,
			...fraudReasons,
			...negativeBalanceReason,
			...programChange,
			...breachOfTermsAndConditions,
			...noAccountActivity,
			...byBank,
		}
	}

	const closeReasonToSetState: StringToReasonSet = getCloseReasonSet()

	if (!isClosed(account))
		return (
			<AsyncResultModal
				title="Close Account"
				close={refreshAndClose(onSuccess, closeModal)}
				buttonClassname="is-danger"
				state={closeState}
				buttonText="Close"
				successText="Account Closed"
				onSubmit={() => setCloseState(accessToken, account, reason as CloseReason, reasonText, fraudReason, bankReason)}
				errorToText={(err) => err.errors[0].title}
			>
				<div className="field">
					<label className="label">Reason</label>
					<div className="control">
						<div className="select">
							<select required defaultValue="" onChange={(e) => closeReasonToSetState[e.target.value as string]()}>
								<option value="" disabled>
									Select Close Reason
								</option>
								{Object.keys(closeReasonToSetState).map((k, i) => (
									<option value={k} key={i}>
										{k}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<div className="field">
					<label className="label">Notes</label>
					<div className="control">
						<textarea
							className="textarea"
							maxLength={255}
							placeholder="Why are you closing the account?"
							value={reasonText}
							onChange={(e) => setReasonText(e.target.value)}
						/>
					</div>
				</div>
				<Admonition
					type={"is-danger"}
					message={
						<p>
							{" "}
							Closing an account prevents the customer from initiating any new activity. It is designed as a final
							state, and closed accounts should only be reopened under exceptional circumstances. Closing an account
							closes all cards associated with the account.{" "}
						</p>
					}
					title={"Warning"}
				/>
			</AsyncResultModal>
		)

	return (
		<AsyncResultModal
			title="Reopen Account"
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-success"
			state={reopenState}
			buttonText="Reopen Account"
			successText="Account Reopened"
			onSubmit={() => setReopenState(accessToken, account)}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure?
			<br /> <br />
			<Admonition
				type={"is-danger"}
				message={
					<p>
						{" "}
						Reopening the account should only be used under exceptional circumstances, and only if the client had an
						active account within the last 30 days. Reopening an account does not have any impact on cards.{" "}
					</p>
				}
				title={"Warning"}
			/>
		</AsyncResultModal>
	)
}
