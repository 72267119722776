import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {useToasts} from "react-toast-notifications"
import {useAccessToken, useIsUnitUser, useUserType} from "../../services/auth"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {ReactElement, useEffect, useRef} from "react"
import {useQueryState} from "use-location-state"
import {usePaging} from "../../hooks/usePaging"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {Filter} from "../Filter/Filter"
import {kebabCase, startCase} from "lodash"
import {useNavigate} from "react-router-dom"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {ErrorDocument, Resource} from "../../resources/common"
import classNames from "classnames"
import numeral from "numeral"
import ReactTooltip from "react-tooltip"
import moment from "moment"
import {BankName} from "../BankName/BankName"
import {findIncomingChecks, IncomingCheck, IncomingCheckStatus} from "../../resources/incomingCheck"
import SearchBox from "../SearchBox/SearchBox"
import {Cents} from "../../resources/transaction"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {Bank, findBanks} from "../../resources/bank"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {CurrencyRangeFilter} from "../Filter/CurrencyRangeFilter"
import {downloadFile} from "../../utilities/file"

function OrgName({orgId, included}: {orgId: string | undefined | null; included?: Resource[]}) {
	if (included && orgId) {
		const org = included.find((r) => r.type == "org" && r.id === orgId) as Org
		if (org) {
			return <td>{org.attributes.name}</td>
		}
	}
	return <td></td>
}

export enum IncomingChecksColumns {
	id = "Id",
	org = "Org",
	bank = "Bank",
	accountId = "Account",
	customer = "Customer",
	amount = "Amount",
	status = "Status",
	createdAt = "Created At",
}

type AllowedIncomingChecksColumns =
	| IncomingChecksColumns.id
	| IncomingChecksColumns.org
	| IncomingChecksColumns.bank
	| IncomingChecksColumns.accountId
	| IncomingChecksColumns.customer
	| IncomingChecksColumns.amount
	| IncomingChecksColumns.status
	| IncomingChecksColumns.createdAt

interface IncomingChecksProps {
	limit?: number
	includedColumns: AllowedIncomingChecksColumns[]
	enableStatusFilter?: boolean
	title?: string
	fullHeight?: boolean
	stickyAction?: boolean
	enablePaging?: boolean
	enableBankFilter?: boolean
	enableOrgFilter?: boolean
	enableAmountFilter?: boolean
	enableDateFilter?: boolean
	enableExport?: boolean
	enableSearch?: boolean
}

interface IncomingChecksTableProps {
	incomingChecks: IncomingCheck[]
	includedColumns: AllowedIncomingChecksColumns[]
	isUsingPaging: boolean
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	refresh: () => void
	include?: Resource[]
	fullHeight?: boolean
	stickyAction?: boolean
}

interface IncomingCheckRowProps {
	incomingCheck: IncomingCheck
	includedColumns: AllowedIncomingChecksColumns[]
	refresh: () => void
	include?: Resource[]
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

function IncomingCheckRow({incomingCheck: incomingCheck, includedColumns, include, onClick}: IncomingCheckRowProps) {
	const navigate = useNavigate()
	const id = incomingCheck.id
	const org = incomingCheck.relationships.org?.data.id
	const bank = incomingCheck.relationships.bank.data.id
	const customerId = incomingCheck.relationships.customer?.data.id
	const accountId = incomingCheck.relationships.account?.data.id
	const status = incomingCheck.attributes.status
	const createdAt = incomingCheck.attributes.createdAt
	const amount = incomingCheck.attributes.amount

	const customer =
		customerId && include
			? include.find(
					(r) => (r.type == "individualCustomer" || r.type == "businessCustomer") && r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedIncomingChecksColumns, ReactElement>> = {}

	if (includedColumns.includes(IncomingChecksColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingChecksColumns.org)) {
		contentColumns["Org"] = <OrgName orgId={org} included={include} />
	}

	if (useIsUnitUser() && includedColumns.includes(IncomingChecksColumns.bank) && bank) {
		contentColumns["Bank"] = (
			<DataTableCell>
				<BankName bankId={bank.toString()} included={include} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingChecksColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingChecksColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingChecksColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<span className="incoming-checks-amount">{numeral(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingChecksColumns.status)) {
		const tooltipValue = undefined

		contentColumns["Status"] = (
			<DataTableCell>
				<div
					data-tip="React-tooltip"
					data-for={`status-reason-tooltip-${id}`}
					className={classNames("incoming-check-status", kebabCase(status))}
				>
					{startCase(status)}

					<ReactTooltip
						className="tooltip-info"
						id={`status-reason-tooltip-${id}`}
						disable={!tooltipValue}
						effect="solid"
					>
						{tooltipValue}
					</ReactTooltip>
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingChecksColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow onClick={(e) => onClick && onClick(e)}>{content}</DataTableRow>
}

export function IncomingChecksTable({
	incomingChecks,
	includedColumns,
	hasResults,
	isUsingPaging,
	hasPrev,
	hasNext,
	prev,
	next,
	refresh,
	include,
	fullHeight = true,
	stickyAction = true,
}: IncomingChecksTableProps) {
	const noContent = incomingChecks.length === 0
	const ref = useRef(null)
	const navigate = useNavigate()

	return (
		<div ref={ref} className={"incoming-checks-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={stickyAction}
				noContentText={"No incoming checks found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === IncomingChecksColumns.createdAt) {
								return <DataTableCell key={column[1]}>{column[1]}</DataTableCell>
							}

							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{incomingChecks.map((check) => (
						<IncomingCheckRow
							incomingCheck={check}
							key={check.id}
							include={include}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								navigate(`/incoming-checks/${check.id}`)
							}}
							refresh={refresh}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
			/>
		</div>
	)
}

export default function IncomingChecks({
	limit = 25,
	includedColumns,
	stickyAction = false,
	fullHeight = false,
	enablePaging = true,
	enableStatusFilter = true,
	enableBankFilter = true,
	enableOrgFilter = true,
	enableAmountFilter = true,
	enableDateFilter = true,
	enableSearch = true,
	enableExport = true,
	title,
}: IncomingChecksProps) {
	const prefix = "incoming-check"
	const accessToken = useAccessToken()
	const userType = useUserType()
	const {addToast} = useToasts()
	const [refreshToken, refresh] = useRefreshToken()
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [filteredBanks, setFilteredBanks] = useQueryState<string[]>(`${prefix}-filter[banks]`, [])
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search by Incoming Check Id", 500, onSearch)
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")

	const [statuses, setStatuses] = useQueryState(
		`${prefix}-filter[status]`,
		enableStatusFilter ? [IncomingCheckStatus.PendingReview] : []
	)
	const statusesOptions = new Map(Object.values(IncomingCheckStatus).map((v) => [v, startCase(v)]))
	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findIncomingChecks(
				accessToken,
				offset,
				query,
				limit,
				statuses,
				filteredOrgs,
				filteredBanks,
				since,
				until,
				fromAmount,
				toAmount,
				"json"
			),
		(x) => x.data.length,
		[
			refreshToken,
			since,
			until,
			query,
			[...statuses, ...filteredOrgs, ...filteredBanks].join(","),
			fromAmount,
			toAmount,
		],
		`${prefix}-`,
		true
	)

	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const banks = useIsUnitUser()
		? useAsyncResult(() => findBanks(accessToken, 0, 10000), [])
		: AsyncResult.pending<Bank[], ErrorDocument>()
	const bankFilter = banks.match(
		() => null,
		(banks) => (
			<Filter
				title="Banks"
				isSearchable={true}
				setStatuses={setFilteredBanks}
				statuses={filteredBanks}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						banks
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((bank) => [bank.id, bank.attributes.name])
					)
				}
			/>
		),
		(_) => null
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={setFromAmount}
			to={toAmount}
			setTo={setToAmount}
		/>
	)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	async function exportIncomingChecks() {
		const response = await findIncomingChecks(
			accessToken,
			0,
			query,
			null,
			statuses,
			filteredOrgs,
			filteredBanks,
			since,
			until,
			fromAmount,
			toAmount,
			"csv"
		)

		response.match(
			(cd) => downloadFile("IncomingChecks.csv", cd, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
		return true
	}

	return (
		<DataTableCard className={"incoming-checks-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={enableSearch ? searchBox : null}
				dateTimePicker={enableDateFilter ? dateTime : null}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableBankFilter ? [bankFilter] : []),
					...(enableStatusFilter ? [statusFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
				]}
				title={title}
				exportFunc={enableExport ? exportIncomingChecks : undefined}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: incomingChecks}) => {
					return (
						<IncomingChecksTable
							fullHeight={fullHeight}
							incomingChecks={incomingChecks.data}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							include={incomingChecks.included}
							includedColumns={includedColumns}
							isUsingPaging={enablePaging}
							stickyAction={stickyAction}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
