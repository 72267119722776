import {useState} from "react"

//The purpose of this hook is to provide a simple way to trigger a re-render of a component by incrementing a counter.
//This can be useful in scenarios where you need to refresh some part of your component's state or re-fetch data.
export function useRefreshToken(): [number, () => void] {
	const [count, setCounter] = useState(0)
	function inc() {
		setCounter((c) => c + 1)
	}

	return [count, inc]
}
