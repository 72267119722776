import React, {PropsWithChildren} from "react"
import {EvaluationOutcome} from "../../resources/application"
import {startCase} from "lodash"
import classNames from "classnames"
import moment from "moment"

export function Tag({label, value, className}: {label: string; value: string; className?: string}) {
	const tagClassname = classNames("tag", className)

	return (
		<div className="tags has-addons is-medium">
			<span className="tag is-dark">{label}</span>
			<span className={tagClassname} data-chromatic="ignore">
				{value}
			</span>
		</div>
	)
}

export function Tags({children}: PropsWithChildren<{}>) {
	const wrapped = React.Children.map(children, (child) => <div className="control">{child}</div>)

	return <div className="field is-grouped is-grouped-multiline">{wrapped}</div>
}

export function ApplicationStatusTag({status}: {status: string}) {
	let statusClassname
	switch (status) {
		case "AwaitingDocuments":
			statusClassname = "is-info"
			break
		case "PendingReview":
			statusClassname = "is-warning"
			break
		case "Approved":
			statusClassname = "is-success"
			break
		case "Denied":
			statusClassname = "is-danger"
			break
		case "Canceled":
			statusClassname = "is-danger"
			break
		case "Pending":
			statusClassname = "is-info"
			break
	}

	return <Tag value={startCase(status)} label="Status" className={statusClassname} />
}

export function CreatedAtTag({createdAt}: {createdAt: Date}) {
	return <Tag value={moment(createdAt).format("L LT")} label="Created At" className="is-white" />
}

export function UpdatedAtTag({updatedAt}: {updatedAt?: Date}) {
	return updatedAt ? <Tag value={moment(updatedAt).format("L LT")} label="Updated At" className="is-white" /> : <></>
}

export function EvaluationOutcomeTag({label, value}: {label: string; value: EvaluationOutcome}) {
	let className

	switch (value) {
		case EvaluationOutcome.Approved:
			className = "is-success"
			break
		case EvaluationOutcome.Denied:
			className = "is-danger"
			break
		case EvaluationOutcome.PendingReview:
			className = "is-warning"
			break
	}

	return <Tag value={startCase(value)} label={label} className={className} />
}
