import React from "react"
import {
	AnnualIncomeMapping,
	BusinessAnnualRevenueMapping,
	BusinessApplication,
	BusinessNumberOfEmployeesMapping,
	BusinessVerticalMapping,
	CashFlowMapping,
	hasAdditionalInformation,
	OccupationMapping,
	SourceOfIncomeMapping,
} from "../../resources/application"
import {Accordion} from "../Accordion/Accordion"
import {optionalHorizontalField} from "../HorizontalField/HorizontalField"
import {CountriesOfOperation} from "../ApplicationAdditionalInformationFields/CountriesOfOperation"

import {OkDocument} from "../../resources/common"
import {isBeneficialOwner} from "../../resources/beneficialOwner"
import {EditableBeneficialOwnerAdditionalInformation} from "../BeneficialOwnerAdditionalInformation/EditableBeneficialOwnerAdditionalInformation"

export function BusinessAdditionalInformationFields({
	application,
	isArchived,
}: {
	application: OkDocument<BusinessApplication>
	isArchived: boolean
}) {
	const attributes = application.data.attributes
	const beneficialOwners = application.included?.filter(isBeneficialOwner) || []
	const archivedClassName = isArchived ? "archived-field" : ""
	const businessVertical = BusinessVerticalMapping.get(attributes.businessVertical as string)
	const numberOfEmployees = BusinessNumberOfEmployeesMapping.get(attributes.numberOfEmployees as string)
	const annualRevenue = BusinessAnnualRevenueMapping.get(attributes.annualRevenue as string)
	const cashFlow = CashFlowMapping.get(attributes.cashFlow as string)

	const officer = attributes.officer
	const officerOccupation = OccupationMapping.get(officer.occupation as string)
	const officerAnnualIncome = AnnualIncomeMapping.get(attributes.officer.annualIncome as string)
	const officerSourceOfIncome = SourceOfIncomeMapping.get(attributes.officer.sourceOfIncome as string)

	return (
		<Accordion title={"Additional Information"} theme={"light"} noContentPadding={true}>
			{businessVertical
				? optionalHorizontalField("Business Vertical", businessVertical, undefined, archivedClassName)
				: null}

			{!attributes.hasNoWebsite && attributes.website
				? optionalHorizontalField("Website", attributes.website, undefined, archivedClassName)
				: null}

			{numberOfEmployees
				? optionalHorizontalField("Number of Employees", numberOfEmployees, undefined, archivedClassName)
				: null}

			{attributes.yearOfIncorporation
				? optionalHorizontalField("Year of Incorporation", attributes.yearOfIncorporation, undefined, archivedClassName)
				: null}

			{annualRevenue ? optionalHorizontalField("Annual Revenue", annualRevenue, undefined, archivedClassName) : null}

			{cashFlow ? optionalHorizontalField("Cash Flow", cashFlow, undefined, archivedClassName) : null}

			{attributes.countriesOfOperation ? (
				<CountriesOfOperation countriesOfOperation={attributes.countriesOfOperation} isArchived={isArchived} />
			) : null}

			{hasAdditionalInformation(officer) ? (
				<div className={"officer-additional-information"}>
					<div className={"officer-additional-information-label"}>Officer</div>
					{officerOccupation
						? optionalHorizontalField("Occupation", officerOccupation, undefined, archivedClassName)
						: null}
					{officerAnnualIncome
						? optionalHorizontalField("Annual Income", officerAnnualIncome, undefined, archivedClassName)
						: null}
					{officerSourceOfIncome
						? optionalHorizontalField("Source of Income", officerSourceOfIncome, undefined, archivedClassName)
						: null}
				</div>
			) : null}

			{beneficialOwners.some((bo) => hasAdditionalInformation(bo.attributes)) ? (
				<div key={"beneficialOwnerInformation"} className={"beneficial-owners-more-info-container"}>
					<div>Beneficial Owners</div>
					{beneficialOwners.map((bo) => (
						<EditableBeneficialOwnerAdditionalInformation
							key={bo.id}
							beneficialOwner={bo}
							isEditing={undefined}
							refresh={() => undefined}
							setIsEditing={() => undefined}
							setIsUpdating={() => undefined}
							isApplicationEditable={false}
							editingDisabled={true}
						/>
					))}
				</div>
			) : null}
		</Accordion>
	)
}
