import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import DepositProducts, {
	AllowedDepositProductsColumns,
	DepositProductsColumns,
} from "../../components/DepositProducts/DepositProducts"
import {useIsBankUser, useIsUnitUser, UserType} from "../../services/auth"
import {useNavigate} from "react-router-dom"
import Icon from "../../components/Icon/Icon"
import {HasPermission} from "../../containers/PermissionedUser/Permission"

const DEFAULT_INCLUDED_COLUMNS: Array<AllowedDepositProductsColumns> = [
	DepositProductsColumns.id,
	DepositProductsColumns.name,
	DepositProductsColumns.createdAt,
]

const UNIT_USER_INCLUDED_COLUMNS: Array<AllowedDepositProductsColumns> = [
	DepositProductsColumns.id,
	DepositProductsColumns.status,
	DepositProductsColumns.org,
	DepositProductsColumns.name,
	DepositProductsColumns.bank,
	DepositProductsColumns.createdAt,
]

const BANK_USER_INCLUDED_COLUMNS: Array<AllowedDepositProductsColumns> = [
	DepositProductsColumns.id,
	DepositProductsColumns.name,
	DepositProductsColumns.org,
	DepositProductsColumns.createdAt,
]

const INCLUDED_COLUMNS: Record<string, Array<AllowedDepositProductsColumns>> = {
	[UserType.Unit]: UNIT_USER_INCLUDED_COLUMNS,
	[UserType.Bank]: BANK_USER_INCLUDED_COLUMNS,
}

function includedColumns(userType: string): Array<AllowedDepositProductsColumns> {
	return INCLUDED_COLUMNS[userType] || DEFAULT_INCLUDED_COLUMNS
}

export default function DepositProductsPage(): ReactElement {
	const navigate = useNavigate()
	const unitUserType = useIsUnitUser() && UserType.Unit
	const bankUserType = useIsBankUser() && UserType.Bank
	const userType = unitUserType || bankUserType || ""

	return (
		<>
			<TitleBar title={"Deposit Products"}>
				<HasPermission resource="depositProduct" action="create">
					<button className="button button-create" onClick={() => navigate("/deposit-product/new")}>
						<Icon icon="interface-add-1" size={12} /> Create
					</button>
				</HasPermission>
			</TitleBar>

			<MainSectionTable>
				<DepositProducts limit={40} enableTitle={false} fullHeight={true} includedColumns={includedColumns(userType)} />
			</MainSectionTable>
		</>
	)
}
