import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {CardTransactionFunding, createOrgBankAgreement, OrgBankAgreement} from "../../resources/orgBankAgreement"
import Loading from "../../components/Loading/Loading"
import React, {useEffect, useState} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {EditMode, ErrorDocument} from "../../resources/common"
import {useNavigate} from "react-router-dom"
import {useToasts} from "react-toast-notifications"
import {Bank, findBanks} from "../../resources/bank"
import {AsyncResult} from "../../types/asyncResult"
import {findOrgs, getCapitalPartnerOrgs, Org} from "../../resources/org"
import {OrgBankAgreementAttributes} from "./OrgBankAgreementAttributes"
import {
	DepositAccount,
	getAccountsWithPurpose,
	getReserveAccounts,
	getRevenueAccounts,
	getSponsoredAccounts,
	getOverdraftReserveAccounts,
	getOrgGeneralLedgerAccounts,
	OrgGeneralLedgerAccount,
	getOrgLoanAccounts as getOrgLoanAccounts,
	OrgLoanAccount,
} from "../../resources/account"
import {findInterestSettings, InterestSettings} from "../../resources/interestSettings"

function Footer({state}: {state: AsyncResultIdleRequestState<OrgBankAgreement, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Create</button>
				),
				() => (
					<button className="button is-success is-loading">Creating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].title}</div>
						<button className="button is-success">Create</button>
					</>
				)
			)}
		</div>
	)
}

function Page({
	orgs,
	banks,
	interestSettings,
}: {
	orgs: Array<Org>
	banks: Array<Bank>
	interestSettings: Array<InterestSettings>
}) {
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const {addToast} = useToasts()
	const unitOrgId = "1"
	const [state, create] = useAsyncResultIdle(createOrgBankAgreement)
	const [orgId, setOrgId] = useState<string>("")
	const [bankId, setBankId] = useState<string>("")
	const [revenueAccountId, setRevenueAccountId] = useState<string>("")
	const [reserveAccountId, setReserveAccountId] = useState<string>("")
	const [overdraftReserveAccountId, setOverdraftReserveAccountId] = useState<string | undefined>("")
	const [pushToCardAccountId, setPushToCardAccountId] = useState<string | undefined>("")
	const [bankInterchangeShare, setBankInterchangeShare] = useState<number>(0)
	const [orgInterchangeShare, setOrgInterchangeShare] = useState<number>(0)
	const [unitInterest, setUnitInterest] = useState<number>(0)
	const [isUpdatableDepositProduct, setIsUpdatableDepositProduct] = useState<boolean>(false)
	const [isWirePaymentEnabled, setIsWirePaymentEnabled] = useState<boolean>(false)
	const [isBillPaymentEnabled, setIsBillPaymentEnabled] = useState<boolean>(false)
	const [isCheckDepositEnabled, setIsCheckDepositEnabled] = useState<boolean>(false)
	const [isOverdraftEnabled, setIsOverdraftEnabled] = useState<boolean>(false)
	const [isPushToCardEnabled, setIsPushToCardEnabled] = useState<boolean>(false)
	const [isCreditEnabled, setIsCreditEnabled] = useState<boolean>(false)
	const [revenueAccounts, setRevenueAccounts] = useState<DepositAccount[]>([])
	const [reserveAccounts, setReserveAccounts] = useState<DepositAccount[]>([])
	const [sponsoredInterestAccounts, setSponsoredInterestAccounts] = useState<DepositAccount[]>([])
	const [provisionalCreditOperatingAccounts, setProvisionalCreditOperatingAccounts] = useState<DepositAccount[]>([])
	const [provisionalCreditReserveAccounts, setProvisionalCreditReserveAccounts] = useState<DepositAccount[]>([])
	const [sponsoredInterestAccountId, setSponsoredInterestAccountId] = useState<string | undefined>(undefined)
	const [overdraftReserveAccounts, setOverdraftReserveAccounts] = useState<DepositAccount[] | undefined>([])
	const [pushToCardAccounts, setPushToCardAccounts] = useState<DepositAccount[] | undefined>([])
	const [provisionalCreditOperatingAccountId, setProvisionalCreditOperatingAccountId] = useState<string | undefined>(
		undefined
	)
	const [provisionalCreditReserveAccountId, setProvisionalCreditReserveAccountId] = useState<string | undefined>(
		undefined
	)
	const [isDacaEnabled, setIsDacaEnabled] = useState<boolean>(false)
	const [cardTransactionFunding, setCardTransactionFunding] = useState<CardTransactionFunding>(
		CardTransactionFunding.AccountOfCard
	)
	const [isChargebackEnabled, setIsChargebackEnabled] = useState<boolean>(false)
	const [isTrustApplicationsEnabled, setIsTrustApplicationsEnabled] = useState<boolean>(false)
	const [isAutoArchiveAccountsEnabled, setIsAutoArchiveAccountsEnabled] = useState<boolean>(true)
	const [autoArchiveAccountsAfterDays, setAutoArchiveAccountsAfterDays] = useState<number>(60)
	const [isAchTransactionReturnEnabled, setIsAchTransactionReturnEnabled] = useState<boolean>(false)
	const [isIncreasePilotCustomerLimits, setIsIncreasePilotCustomerLimits] = useState<boolean>(false)
	const [isSameDayAchEnabled, setIsSameDayAchEnabled] = useState<boolean>(false)
	const [isEnforceLimitsCustomerLevel, setIsEnforceLimitsCustomerLevel] = useState<boolean>(false)
	const [isActivationOfPhysicalCardsForOnlineUseEnabled, setIsActivationOfPhysicalCardsForOnlineUseEnabled] =
		useState<boolean>(false)
	const [isCashDepositEnabled, setIsCashDepositEnabled] = useState<boolean>(false)
	const [cashDepositSettlementAccountId, setCashDepositSettlementAccountId] = useState<string | undefined>(undefined)
	const [cashDepositSettlementAccounts, setCashDepositSettlementAccounts] = useState<DepositAccount[]>([])
	const [allowOrgReleaseAuthHolds, setAllowOrgReleaseAuthHolds] = useState<boolean>(false)
	const [allowInlineAchDebit, setAllowInlineAchDebit] = useState<boolean>(false)
	const [allowCounterpartyAchDebit, setAllowCounterpartyAchDebit] = useState<boolean>(false)
	const [capitalPartnerOrgs, setCapitalPartnerOrgs] = useState<Org[]>([])
	const [orgGeneralLedgerAccounts, setOrgGeneralAccounts] = useState<OrgGeneralLedgerAccount[]>([])
	const [creditOperationalAccounts, setCreditOperationalAccounts] = useState<DepositAccount[]>([])
	const [orgLoanAccounts, setOrgLoanAccounts] = useState<OrgLoanAccount[]>([])
	const [, getOrgLoanAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getOrgLoanAccounts(accessToken, bankId, orgId)
	)
	const [isEnforceDailyACHDebitOrgLevel, setIsEnforceDailyACHDebitOrgLevel] = useState<boolean>(false)
	const [dailyACHDebitOrgLevel, setDailyACHDebitOrgLevel] = useState<number | undefined>(undefined)
	const [overrideOriginatedAchSecCodeEnabled, setOverrideOriginatedAchSecCodeEnabled] = useState<boolean>(false)
	const [isCoverNegativeBalanceOnClose, setIsCoverNegativeBalanceOnClose] = useState<boolean>(true)
	const [isEnforceDailyACHCreditOrgLevel, setIsEnforceDailyACHCreditOrgLevel] = useState<boolean>(false)
	const [dailyACHCreditOrgLevel, setDailyACHCreditOrgLevel] = useState<number | undefined>(undefined)
	const [isCheckWritingEnabled, setIsCheckWritingEnabled] = useState<boolean>(false)
	const [isCheckWritingOriginationEnabled, setIsCheckWritingOriginationEnabled] = useState<boolean>(false)

	const [isCardTransactionEnrichmentEnabled, setIsCardTransactionEnrichmentEnabled] = useState<boolean>(false)
	const [isReactivatingSuspectedFraudCardsEnabled, setIsReactivatingSuspectedFraudCardsEnabled] =
		useState<boolean>(false)
	const [isSplitInterestDailyEnabled, setIsSplitInterestDailyEnabled] = useState<boolean>(true)
	const [minimumReserveBalance, setMinimumReserveBalance] = useState<number | undefined>(undefined)
	const [sweepCode, setSweepCode] = useState<number | undefined>(undefined)
	const [isCardSchemeFeeEnabled, setIsCardSchemeFeeEnabled] = useState<boolean>(true)
	const [interestSettingsId, setInterestSettingsId] = useState<string>("")
	const [isLastFourSSNEnabled, setIsLastFourSSNEnabled] = useState<boolean>(false)
	const [allowCreatingFBOBusinessCustomer, setAllowCreatingFBOBusinessCustomerEnabled] = useState<boolean>(false)
	const [overdraftLimitReserveRatio, setOverdraftLimitReserveRatio] = useState<number | undefined>(undefined)
	const [accountCreationBlocked, setAccountCreationBlocked] = useState<boolean>(false)
	const [authorizedUsersCreationBlocked, setAuthorizedUsersCreationBlocked] = useState<boolean>(false)
	const [financialAccountsSupported, setFinancialAccountsSupported] = useState<boolean>(false)
	const [isReprocessReceivedAchDebitEnabled, setIsReprocessReceivedAchDebitEnabled] = useState<boolean>(false)
	const [isResetPinCardEnabled, setIsResetPinCardEnabled] = useState<boolean>(false)
	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Bank Agreement Created Successfully", {appearance: "success"})
			navigate(`/org-bank-agreements/${state.value.id}`, {replace: true})
		}
	}, [state])

	const [, getRevenueAccountsFromState] = useAsyncResultIdle((bankId) => getRevenueAccounts(accessToken, bankId))
	const [, getReserveAccountsFromState] = useAsyncResultIdle((bankId) => getReserveAccounts(accessToken, bankId))
	const [, getSponsoredInterestAccountsFromState] = useAsyncResultIdle((bankId) =>
		getSponsoredAccounts(accessToken, bankId)
	)
	const [, getOverdraftReserveAccountsFromState] = useAsyncResultIdle((bankId) =>
		getOverdraftReserveAccounts(accessToken, bankId)
	)
	const [, getProvisionalCreditOperatingAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getAccountsWithPurpose(accessToken, bankId, orgId, "credit_operating")
	)
	const [, getProvisionalCreditReserveAccountsFromState] = useAsyncResultIdle((bankId) =>
		getAccountsWithPurpose(accessToken, bankId, unitOrgId, "credit_reserve")
	)
	const [, getCashDepositSettlementAccountsFromState] = useAsyncResultIdle((bankId) =>
		getAccountsWithPurpose(accessToken, bankId, unitOrgId, "cash_deposit_settlement")
	)
	const [, getCreditOperationalAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getAccountsWithPurpose(accessToken, bankId, orgId, "credit_operational_account")
	)
	const [, getCapitalPartnerOrgsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getCapitalPartnerOrgs(accessToken, bankId, orgId)
	)
	const [, getPushToCardAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getAccountsWithPurpose(accessToken, bankId, orgId, "push_to_card_operational_account")
	)

	const [, getOrgGeneralAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getOrgGeneralLedgerAccounts(accessToken, bankId, orgId)
	)

	useEffect(() => {
		if (bankId !== "") {
			resolveRevenueAccounts(bankId)
			resolveReserveAccounts(bankId)
			resolveSponsoredInterestAccounts(bankId)
			resolveOverdraftReserveAccounts(bankId)
			resolveBankInterchangeShare(bankId)
			resolveProvisionalCreditReserveAccounts(bankId)
			resolveCashDepositSettlementAccounts(bankId)
		}
	}, [bankId])

	useEffect(() => {
		if (bankId !== "" && orgId !== "") {
			resolveProvisionalCreditOperatingAccounts(bankId, orgId)
			resolveOrgGeneralLedgerAccounts(bankId, orgId)
			resolveCreditOperationalAccounts(bankId, orgId)
			resolvePushToCardOperationalAccounts(bankId, orgId)
			resolveOrgLoanAccounts(bankId, orgId)
			resolveCapitalPartnerOrgs(bankId, orgId)
		}
	}, [bankId, orgId])

	async function resolveRevenueAccounts(bankId: string) {
		setRevenueAccounts(
			(await getRevenueAccountsFromState(bankId)).match(
				(result) => {
					setRevenueAccountId(result[0].id)
					return result
				},
				() => []
			)
		)
	}

	async function resolveReserveAccounts(bankId: string) {
		setReserveAccounts(
			(await getReserveAccountsFromState(bankId)).match(
				(result) => {
					setReserveAccountId(result[0].id)
					return result
				},
				() => []
			)
		)
	}

	async function resolveSponsoredInterestAccounts(bankId: string) {
		setSponsoredInterestAccounts(
			(await getSponsoredInterestAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveOverdraftReserveAccounts(bankId: string) {
		setOverdraftReserveAccounts(
			(await getOverdraftReserveAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveProvisionalCreditOperatingAccounts(bankId: string, orgId: string) {
		setProvisionalCreditOperatingAccounts(
			(await getProvisionalCreditOperatingAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveOrgGeneralLedgerAccounts(bankId: string, orgId: string) {
		setOrgGeneralAccounts(
			(await getOrgGeneralAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveCreditOperationalAccounts(bankId: string, orgId: string) {
		setCreditOperationalAccounts(
			(await getCreditOperationalAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveCapitalPartnerOrgs(bankId: string, orgId: string) {
		setCapitalPartnerOrgs(
			(await getCapitalPartnerOrgsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolvePushToCardOperationalAccounts(bankId: string, orgId: string) {
		setPushToCardAccounts(
			(await getPushToCardAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveOrgLoanAccounts(bankId: string, orgId: string) {
		setOrgLoanAccounts(
			(await getOrgLoanAccountsFromState(bankId, orgs.find((x) => x.id == orgId)?.attributes.name)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveProvisionalCreditReserveAccounts(bankId: string) {
		setProvisionalCreditReserveAccounts(
			(await getProvisionalCreditReserveAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveCashDepositSettlementAccounts(bankId: string) {
		setCashDepositSettlementAccounts(
			(await getCashDepositSettlementAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	function resolveBankInterchangeShare(bankId: string) {
		setBankInterchangeShare(banks.find((x) => x.id === bankId)?.attributes.bankInterchangeShare || 0.1)
	}

	const submit = () => {
		create(
			accessToken,
			bankId,
			orgId,
			revenueAccountId,
			reserveAccountId,
			sponsoredInterestAccountId,
			provisionalCreditOperatingAccountId,
			provisionalCreditReserveAccountId,
			cashDepositSettlementAccountId,
			bankInterchangeShare,
			orgInterchangeShare,
			unitInterest,
			isUpdatableDepositProduct,
			isWirePaymentEnabled,
			isBillPaymentEnabled,
			isCheckDepositEnabled,
			cardTransactionFunding,
			isOverdraftEnabled,
			isCreditEnabled,
			isPushToCardEnabled,
			isCoverNegativeBalanceOnClose,
			interestSettingsId,
			financialAccountsSupported,
			isReprocessReceivedAchDebitEnabled,
			isResetPinCardEnabled,
			overdraftReserveAccountId,
			pushToCardAccountId,
			isDacaEnabled,
			isChargebackEnabled,
			isTrustApplicationsEnabled,
			isAutoArchiveAccountsEnabled,
			autoArchiveAccountsAfterDays,
			isAchTransactionReturnEnabled,
			isIncreasePilotCustomerLimits,
			isSameDayAchEnabled,
			isEnforceLimitsCustomerLevel,
			isActivationOfPhysicalCardsForOnlineUseEnabled,
			isCashDepositEnabled,
			allowOrgReleaseAuthHolds,
			allowInlineAchDebit,
			allowCounterpartyAchDebit,
			isEnforceDailyACHDebitOrgLevel,
			dailyACHDebitOrgLevel,
			overrideOriginatedAchSecCodeEnabled,
			isEnforceDailyACHCreditOrgLevel,
			dailyACHCreditOrgLevel,
			isCheckWritingEnabled,
			isCardTransactionEnrichmentEnabled,
			isReactivatingSuspectedFraudCardsEnabled,
			isSplitInterestDailyEnabled,
			minimumReserveBalance,
			sweepCode,
			isLastFourSSNEnabled,
			allowCreatingFBOBusinessCustomer,
			isCheckWritingOriginationEnabled,
			overdraftLimitReserveRatio,
			accountCreationBlocked,
			authorizedUsersCreationBlocked
		)
	}

	const disabled = state.match(
		() => false,
		() => true,
		(_) => true,
		(_) => false
	)

	return (
		<>
			<TitleBar title={"New Org Bank Agreement"} />
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
						</div>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						submit()
					}}
				>
					<fieldset disabled={disabled}>
						<OrgBankAgreementAttributes
							editMode={EditMode.NEW}
							bankId={bankId}
							setBankId={setBankId}
							orgId={orgId}
							setOrgId={setOrgId}
							banks={banks}
							orgs={orgs}
							revenueAccounts={revenueAccounts}
							reserveAccounts={reserveAccounts}
							sponsoredInterestAccounts={sponsoredInterestAccounts}
							overdraftReserveAccounts={overdraftReserveAccounts}
							pushToCardAccounts={pushToCardAccounts}
							provisionalCreditOperatingAccounts={provisionalCreditOperatingAccounts}
							provisionalCreditReserveAccounts={provisionalCreditReserveAccounts}
							cashDepositSettlementAccounts={cashDepositSettlementAccounts}
							bankInterchangeShare={bankInterchangeShare}
							setBankInterchangeShare={setBankInterchangeShare}
							orgInterchangeShare={orgInterchangeShare}
							setOrgInterchangeShare={setOrgInterchangeShare}
							revenueAccountId={revenueAccountId}
							setRevenueAccountId={setRevenueAccountId}
							reserveAccountId={reserveAccountId}
							setReserveAccountId={setReserveAccountId}
							sponsoredInterestAccountId={sponsoredInterestAccountId}
							setSponsoredInterestAccountId={setSponsoredInterestAccountId}
							provisionalCreditOperatingAccountId={provisionalCreditOperatingAccountId}
							setProvisionalCreditOperatingAccountId={setProvisionalCreditOperatingAccountId}
							provisionalCreditReserveAccountId={provisionalCreditReserveAccountId}
							setProvisionalCreditReserveAccountId={setProvisionalCreditReserveAccountId}
							unitInterest={unitInterest}
							setUnitInterest={setUnitInterest}
							isUpdatableDepositProduct={isUpdatableDepositProduct}
							setIsUpdatableDepositProduct={setIsUpdatableDepositProduct}
							isWirePaymentEnabled={isWirePaymentEnabled}
							setIsWirePaymentEnabled={setIsWirePaymentEnabled}
							isBillPaymentEnabled={isBillPaymentEnabled}
							setIsBillPaymentEnabled={setIsBillPaymentEnabled}
							isCheckDepositEnabled={isCheckDepositEnabled}
							setIsCheckDepositEnabled={setIsCheckDepositEnabled}
							isOverdraftEnabled={isOverdraftEnabled}
							isPushToCardEnabled={isPushToCardEnabled}
							setIsOverdraftEnabled={setIsOverdraftEnabled}
							setIsPushToCardEnabled={setIsPushToCardEnabled}
							isCreditEnabled={isCreditEnabled}
							setIsCreditEnabled={setIsCreditEnabled}
							overdraftReserveAccountId={overdraftReserveAccountId}
							pushToCardAccountId={pushToCardAccountId}
							setOverdraftReserveAccountId={setOverdraftReserveAccountId}
							setPushToCardAccountId={setPushToCardAccountId}
							cashDepositSettlementAccountId={cashDepositSettlementAccountId}
							setCashDepositSettlementAccountId={setCashDepositSettlementAccountId}
							isDacaEnabled={isDacaEnabled}
							setIsDacaEnabled={setIsDacaEnabled}
							cardTransactionFunding={cardTransactionFunding}
							setCardTransactionFunding={setCardTransactionFunding}
							isChargebackEnabled={isChargebackEnabled}
							setIsChargebackEnabled={setIsChargebackEnabled}
							setIsAutoArchiveAccountsEnabled={setIsAutoArchiveAccountsEnabled}
							isAutoArchiveAccountsEnabled={isAutoArchiveAccountsEnabled}
							setAutoArchiveAccountsAfterDays={setAutoArchiveAccountsAfterDays}
							autoArchiveAccountsAfterDays={autoArchiveAccountsAfterDays}
							isTrustApplicationsEnabled={isTrustApplicationsEnabled}
							setIsTrustApplicationsEnabled={setIsTrustApplicationsEnabled}
							isAchTransactionReturnEnabled={isAchTransactionReturnEnabled}
							setIsAchTransactionReturnEnabled={setIsAchTransactionReturnEnabled}
							isIncreasePilotCustomerLimits={isIncreasePilotCustomerLimits}
							setIsIncreasePilotCustomerLimits={setIsIncreasePilotCustomerLimits}
							isSameDayAchEnabled={isSameDayAchEnabled}
							setIsSameDayAchEnabled={setIsSameDayAchEnabled}
							isEnforceLimitsCustomerLevel={isEnforceLimitsCustomerLevel}
							setIsEnforceLimitsCustomerLevel={setIsEnforceLimitsCustomerLevel}
							isActivationOfPhysicalCardsForOnlineUseEnabled={isActivationOfPhysicalCardsForOnlineUseEnabled}
							setIsActivationOfPhysicalCardsForOnlineUseEnabled={setIsActivationOfPhysicalCardsForOnlineUseEnabled}
							isCashDepositEnabled={isCashDepositEnabled}
							setIsCashDepositEnabled={setIsCashDepositEnabled}
							allowOrgReleaseAuthHolds={allowOrgReleaseAuthHolds}
							setAllowOrgReleaseAuthHolds={setAllowOrgReleaseAuthHolds}
							allowInlineAchDebit={allowInlineAchDebit}
							setAllowInlineAchDebit={setAllowInlineAchDebit}
							allowCounterpartyAchDebit={allowCounterpartyAchDebit}
							setAllowCounterpartyAchDebit={setAllowCounterpartyAchDebit}
							capitalPartnerOrgs={capitalPartnerOrgs}
							orgGeneralLedgerAccounts={orgGeneralLedgerAccounts}
							creditOperationalAccounts={creditOperationalAccounts}
							orgLoanAccounts={orgLoanAccounts}
							isEnforceDailyACHDebitOrgLevel={isEnforceDailyACHDebitOrgLevel}
							setIsEnforceDailyACHDebitOrgLevel={setIsEnforceDailyACHDebitOrgLevel}
							dailyACHDebitOrgLevel={dailyACHDebitOrgLevel}
							setDailyACHDebitOrgLevel={setDailyACHDebitOrgLevel}
							overrideOriginatedAchSecCodeEnabled={overrideOriginatedAchSecCodeEnabled}
							setOverrideOriginatedAchSecCodeEnabled={setOverrideOriginatedAchSecCodeEnabled}
							isCoverNegativeBalanceOnClose={isCoverNegativeBalanceOnClose}
							setIsCoverNegativeBalanceOnClose={setIsCoverNegativeBalanceOnClose}
							isEnforceDailyACHCreditOrgLevel={isEnforceDailyACHCreditOrgLevel}
							setIsEnforceDailyACHCreditOrgLevel={setIsEnforceDailyACHCreditOrgLevel}
							dailyACHCreditOrgLevel={dailyACHCreditOrgLevel}
							setDailyACHCreditOrgLevel={setDailyACHCreditOrgLevel}
							isCheckWritingEnabled={isCheckWritingEnabled}
							setIsCheckWritingEnabled={setIsCheckWritingEnabled}
							isCardTransactionEnrichmentEnabled={isCardTransactionEnrichmentEnabled}
							setIsCardTransactionEnrichmentEnabled={setIsCardTransactionEnrichmentEnabled}
							isReactivatingSuspectedFraudCardsEnabled={isReactivatingSuspectedFraudCardsEnabled}
							setIsReactivatingSuspectedFraudCardsEnabled={setIsReactivatingSuspectedFraudCardsEnabled}
							isSplitInterestDailyEnabled={isSplitInterestDailyEnabled}
							setIsSplitInterestDailyEnabled={setIsSplitInterestDailyEnabled}
							minimumReserveBalance={minimumReserveBalance}
							setMinimumReserveBalance={setMinimumReserveBalance}
							sweepCode={sweepCode}
							setSweepCode={setSweepCode}
							setIsCardSchemeFeeEnabled={setIsCardSchemeFeeEnabled}
							isCardSchemeFeeEnabled={isCardSchemeFeeEnabled}
							interestSettingsId={interestSettingsId}
							setInterestSettingsId={setInterestSettingsId}
							interestSettings={interestSettings}
							isLastFourSSNEnabled={isLastFourSSNEnabled}
							setIsLastFourSSNEnabled={setIsLastFourSSNEnabled}
							allowCreatingFBOBusinessCustomer={allowCreatingFBOBusinessCustomer}
							setAllowCreatingFBOBusinessCustomerEnabled={setAllowCreatingFBOBusinessCustomerEnabled}
							isCheckWritingOriginationEnabled={isCheckWritingOriginationEnabled}
							setIsCheckWritingOriginationEnabled={setIsCheckWritingOriginationEnabled}
							overdraftLimitReserveRatio={overdraftLimitReserveRatio}
							setOverdraftLimitReserveRatio={setOverdraftLimitReserveRatio}
							accountCreationBlocked={accountCreationBlocked}
							setAccountCreationBlocked={setAccountCreationBlocked}
							authorizedUsersCreationBlocked={authorizedUsersCreationBlocked}
							setAuthorizedUsersCreationBlocked={setAuthorizedUsersCreationBlocked}
							financialAccountsSupported={financialAccountsSupported}
							setFinancialAccountsSupported={setFinancialAccountsSupported}
							isReprocessReceivedAchDebitEnabled={isReprocessReceivedAchDebitEnabled}
							setIsReprocessReceivedAchDebitEnabled={setIsReprocessReceivedAchDebitEnabled}
							isResetPinCardEnabled={isResetPinCardEnabled}
							setIsResetPinCardEnabled={setIsResetPinCardEnabled}
						/>

						<div className="columns">
							<div className="column">
								<div className="card">
									<Footer state={state} />
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</MainSection>
		</>
	)
}

export default function NewOrgBankAgreement() {
	const accessToken = useAccessToken()

	const interestSettings = useAsyncResult(() => findInterestSettings(accessToken, 0, 1000))
	const orgs = useAsyncResult(() => findOrgs(accessToken, 0, 10000))
	const banks = useAsyncResult(() => findBanks(accessToken, 0, 1000))

	return AsyncResult.zip3(orgs, banks, interestSettings).match(
		() => <Loading />,
		([orgs, banks, interestSettings]) => <Page orgs={orgs} banks={banks} interestSettings={interestSettings.data} />,
		(_) => null
	)
}
