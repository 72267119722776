import {Editable, EditableDropDownField} from "../HorizontalField/EditableHorizontalField"
import {Occupation, OccupationMapping} from "../../resources/application"
import React from "react"

export interface EditableOccupationProps {
	occupation?: Occupation
	setOccupation: (value: Occupation) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: Occupation) => void
	className?: string
}

export function EditableOccupationField(props: EditableOccupationProps) {
	if (props.occupation) {
		return (
			<Editable
				label="Occupation"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setOccupation}
				value={props.occupation}
				initial={props.occupation}
				update={props.update}
				className={props.className}
				possibleValues={OccupationMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}
	return null
}
