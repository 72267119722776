import React from "react"
import ReactTooltip from "react-tooltip"
import {BoundsTooltip} from "./BoundsTooltip"

export function InterestFormulaBox({
	fedUpperBound,
	fedLowerBound,
	interestFormula,
}: {
	fedUpperBound: number
	fedLowerBound: number
	interestFormula: string
}) {
	return (
		<div data-tip="React-tooltip" data-for={`interest-formula-box-tooltip`} className="interest-formula-box-container">
			<span className="formula-text">{interestFormula}</span>
			<ReactTooltip
				className="tooltip-info formula-text-tooltip-container"
				id="interest-formula-box-tooltip"
				disable={false}
				effect="solid"
				offset={{left: -180}}
			>
				<BoundsTooltip fedUpperBound={fedUpperBound} fedLowerBound={fedLowerBound} />
			</ReactTooltip>
		</div>
	)
}
