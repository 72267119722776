import React, {useEffect} from "react"
import {
	BatchAccount,
	DepositAccount,
	getBatchAccounts,
	isBatchAccount,
	isDepositOrFBOAccount,
} from "../../resources/account"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import AccountBox from "./AccountBox"
import {useIsOrgUser} from "../../services/auth"
import Card from "../../containers/Card/Card"
import {Err} from "../Err/Err"
import {findClientOrgBankAgreement} from "../../resources/clientOrgBankAgreement"

function getDepositAccountsByTag(accounts: Array<DepositAccount | BatchAccount>, AccountType: string) {
	return accounts.filter((a) => isDepositOrFBOAccount(a) && a.attributes.tags?.purpose == AccountType)
}

function getBatchAccount(accounts: Array<DepositAccount | BatchAccount>) {
	return accounts.filter((a) => isBatchAccount(a))
}

function getAccountIdString(isOrgUser: boolean, accountId: string) {
	return !isOrgUser ? `#${accountId}` : undefined
}

export function Accounts({
	accounts,
	accessToken,
	showIcon,
	orgId,
}: {
	accounts: Array<DepositAccount | BatchAccount>
	accessToken: string
	showIcon?: boolean
	orgId: string
}) {
	const revenue = getDepositAccountsByTag(accounts, "revenue")
	const reserve = getDepositAccountsByTag(accounts, "reserve")
	const creditReserve = getDepositAccountsByTag(accounts, "credit_reserve")
	const overdraftReserve = getDepositAccountsByTag(accounts, "overdraft_reserve")
	const batch = getBatchAccount(accounts)

	const agreements = useAsyncResult(() => findClientOrgBankAgreement(accessToken, [orgId]))

	useEffect(() => {
		const accountBoxes = document.querySelectorAll(
			".account-box-data-container"
		) as unknown as HTMLCollectionOf<HTMLElement>
		let maxWidth = 0
		for (let i = 0; i < accountBoxes.length; i++) {
			const offsetWidth = accountBoxes[i].offsetWidth
			if (maxWidth < offsetWidth) {
				maxWidth = offsetWidth
			}
		}

		for (let i = 0; i < accountBoxes.length; i++) {
			accountBoxes[i].style.width = maxWidth + 180 + "px"
		}
	}, [])

	const isOrgUser = useIsOrgUser()

	return (
		<Card
			title={"Org Accounts"}
			icon={showIcon ? "money-note-1--business-products" : undefined}
			className="org-accounts-summary-container"
		>
			<div className="org-accounts-summary">
				{accounts.length > 0 ? (
					<>
						{revenue.map((r) => (
							<AccountBox
								key={r.id}
								account={r}
								title="Revenue"
								additionalTitle={getAccountIdString(isOrgUser, r.id)}
								amount={r.attributes.balance}
								accessToken={accessToken}
								className="revenue-account"
								icon={"analytic-currency--business-products"}
							/>
						))}

						{reserve.map((reserveAccount) => (
							<AccountBox
								key={reserveAccount.id}
								account={reserveAccount}
								title="Reserve"
								additionalTitle={getAccountIdString(isOrgUser, reserveAccount.id)}
								amount={reserveAccount.attributes.balance}
								accessToken={accessToken}
								className="reserve-account"
								icon={"payment-box-reserve"}
								minimumBalance={agreements.match(
									() => undefined,
									(agreement) =>
										agreement.find(
											(a) =>
												isDepositOrFBOAccount(reserveAccount) &&
												a.relationships.org.data.id == orgId &&
												reserveAccount.relationships.bank.data.id == a.relationships.bank.data.id
										)?.attributes.reserveAccountSettings.minimumReserveBalance,
									() => undefined
								)}
							/>
						))}

						{batch.map((r) => (
							<AccountBox
								key={r.id}
								account={r}
								title="Batch"
								additionalTitle={getAccountIdString(isOrgUser, r.id)}
								amount={r.attributes.balance}
								accessToken={accessToken}
								className="batch-account"
								icon={"hierarchy-square"}
							/>
						))}

						{creditReserve.map((r) => (
							<AccountBox
								key={r.id}
								account={r}
								title="Credit Reserve"
								additionalTitle={getAccountIdString(isOrgUser, r.id)}
								amount={r.attributes.balance}
								accessToken={accessToken}
								className="reserve-account"
								icon={"payment-box-reserve"}
							/>
						))}

						{overdraftReserve.map((r) => (
							<AccountBox
								key={r.id}
								account={r}
								title="Overdraft Reserve"
								additionalTitle={getAccountIdString(isOrgUser, r.id)}
								amount={r.attributes.balance}
								accessToken={accessToken}
								className="reserve-account"
								icon={"payment-box-reserve"}
							/>
						))}
					</>
				) : (
					<div>There are no Org Accounts associated with this Org.</div>
				)}
			</div>
		</Card>
	)
}

export function Pending() {
	return (
		<Card title={"Org Accounts"} icon={"money-note-1--business-products"} className="org-accounts-summary-container">
			<div className="org-accounts-summary">
				<AccountBox className="revenue-account" icon={"analytic-currency--business-products"} />
				<AccountBox className="reserve-account" icon={"payment-box-reserve"} />
				<AccountBox className="batch-account" icon={"hierarchy-square"} />
			</div>
		</Card>
	)
}

export default function OrgAccountsSummary({
	orgId,
	accessToken,
	showIcon,
}: {
	orgId: string
	accessToken: string
	showIcon?: boolean
}) {
	const orgAccounts = useAsyncResult(() => getBatchAccounts(accessToken, 0, 100, orgId))
	return orgAccounts.match(
		() => <Pending />,
		(orgAccounts) => (
			<Accounts accessToken={accessToken} accounts={[...orgAccounts.data]} showIcon={showIcon} orgId={orgId} />
		),
		(err) => <Err err={err} />
	)
}
