import React from "react"
import {IconProps} from "./types"

export function MP4Icon({disabled}: IconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="81" height="81" fill="none">
			<rect width="80" height="80" x="0.575" y="0.793" fill="#fff" rx="4.646"></rect>
			<path
				fill="#F4F5F7"
				d="M22.057 66.632h36.947a4.592 4.592 0 004.592-4.592V31.436a3.516 3.516 0 00-1.013-2.468l-13.549-13.66a3.46 3.46 0 00-2.448-1.023H22.057c-.918 0-1.798.368-2.447 1.022a3.504 3.504 0 00-1.014 2.468v45.367c0 .926.364 1.813 1.014 2.468a3.447 3.447 0 002.447 1.022z"
			></path>
			<path
				stroke="#000"
				strokeWidth="2"
				strokeOpacity={disabled ? 0.5 : 1}
				d="M50.721 42.37a1 1 0 010 1.724L35.088 53.3a1 1 0 01-1.507-.861V34.026a1 1 0 011.507-.861l15.633 9.206z"
			></path>
			<path
				fill="#E2E4E7"
				d="M47.065 28.06V14.286c.734 0 1.53.612 1.836.918L62.677 28.98c.734.735.918 2.143.918 2.755H50.738a3.673 3.673 0 01-3.673-3.673z"
			></path>
		</svg>
	)
}
