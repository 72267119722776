import {useAccessToken} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {approveIncomingWire, IncomingWire, WireCounterparty} from "../../resources/incomingWire"
import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {CounterpartyAccountType, CounterpartyAccountTypeEnum} from "../../resources/counterparty"

interface ApproveIncomingWireModalProps {
	incomingWire: IncomingWire
	close: () => void
	onSuccess: () => void
}
function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ApproveIncomingWireMissingDetails({incomingWire, close, onSuccess}: ApproveIncomingWireModalProps) {
	const accessToken = useAccessToken()

	const currAccountId = incomingWire.relationships.account?.data.id
	const wireCounterparty = incomingWire.attributes.wireCounterparty
	const noDetailsMissing = !!(currAccountId && wireCounterparty)

	const [state, approve] = useAsyncResultIdle(approveIncomingWire)
	const [accountId, setAccountId] = useState<string>("")

	const [wireCounterpartyName, setWireCounterPartyName] = useState<string>("")
	const [wireCounterpartyRoutingNumber, setWireCounterPartyRoutingNumber] = useState<string>("")
	const [wireCounterpartyAccountNumber, setWireCounterPartyAccountNumber] = useState<string>("")
	const [wireCounterpartyAccountType, setWireCounterPartyAccountType] = useState<string>("")

	const updatedAccountId = !currAccountId ? accountId : undefined
	const updatedWireCounterparty = !wireCounterparty
		? ({
				name: wireCounterpartyName,
				accountNumber: wireCounterpartyAccountNumber,
				routingNumber: wireCounterpartyRoutingNumber,
				accountType: wireCounterpartyAccountType,
		  } as WireCounterparty)
		: undefined

	return (
		<AsyncResultModal
			title="Approve Incoming Wire"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSubmit={() => {
				approve(accessToken, incomingWire.id, Number(updatedAccountId), updatedWireCounterparty)
			}}
			errorToText={(err) => err.errors[0].title}
		>
			{!currAccountId && (
				<div className="field">
					<label className="label">Account Id</label>
					<div className="control">
						<input
							className="input"
							type="text"
							pattern="^[0-9]*$"
							value={accountId}
							onChange={(e) => setAccountId(e.target.value)}
						/>
					</div>
				</div>
			)}
			{!wireCounterparty && (
				<>
					<div className="field">
						<label className="label">Counterparty Name</label>
						<div className="control">
							<input
								required
								className="input"
								type="text"
								pattern="^[a-zA-Z0-9\s]*$"
								value={wireCounterpartyName}
								onChange={(e) => setWireCounterPartyName(e.target.value)}
							/>
						</div>
					</div>
					<div className="field">
						<label className="label">Counterparty Routing Number</label>
						<div className="control">
							<input
								required
								className="input"
								type="text"
								pattern="^[0-9]*$"
								value={wireCounterpartyRoutingNumber}
								onChange={(e) => setWireCounterPartyRoutingNumber(e.target.value)}
							/>
						</div>
					</div>
					<div className="field">
						<label className="label">Counterparty Account Number</label>
						<div className="control">
							<input
								required
								className="input"
								type="text"
								pattern="^[0-9]*$"
								value={wireCounterpartyAccountNumber}
								onChange={(e) => setWireCounterPartyAccountNumber(e.target.value)}
							/>
						</div>
					</div>
					<div className="field">
						<label className="label">Counterparty Account Type</label>
						<div className="control">
							<div className="select">
								<select
									required
									value={wireCounterpartyAccountType}
									onChange={(e) => setWireCounterPartyAccountType(e.target.value as CounterpartyAccountType)}
								>
									<option key="empty" value=""></option>
									{Object.values(CounterpartyAccountTypeEnum).map((accountTypeOption) => (
										<option key={accountTypeOption} value={accountTypeOption}>
											{accountTypeOption}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</>
			)}
			{noDetailsMissing && "Are you sure?"}
		</AsyncResultModal>
	)
}
