import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {setDisputeAction} from "../../resources/dispute"

interface DisputeDecisionModalProps {
	action: string
	id: string
	title: string
	options: string[]
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function DisputeDecisionModal({action, id, title, options, close, onSuccess}: DisputeDecisionModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState("")
	const [state, setDispute] = useAsyncResultIdle(setDisputeAction)

	return (
		<AsyncResultModal
			title={title}
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText={title}
			successText="Success"
			onSubmit={() => setDispute(accessToken, id, action, reason)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Common reasons</label>

				<div className="select is-fullwidth">
					<select
						defaultValue=""
						onChange={(e) => {
							setReason(e.target.value)
						}}
					>
						{options.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
						<option value="">Other</option>
					</select>
				</div>
			</div>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<textarea
						required
						className="textarea"
						placeholder={"Please enter the reason for this dispute decision"}
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
