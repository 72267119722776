import React, {ReactElement, useEffect} from "react"
import {useAccessToken, useIsUnitTypeUser, useUserType} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useQueryState} from "use-location-state"
import SearchBox from "../../components/SearchBox/SearchBox"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import moment from "moment"
import {AccountType} from "../../resources/account"
import {useNavigate} from "react-router-dom"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import {Filter} from "../Filter/Filter"
import classNames from "classnames"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {accountNumberPreview, InformationHiding} from "../InformationHiding/InformationHiding"
import {isProdEnv} from "../../utilities/environment"
import ReactTooltip from "react-tooltip"
import Icon from "../Icon/Icon"
import {findLinkedAccounts, LinkedAccount, LinkedAccountStatusEnum} from "../../resources/linkedAccount"
import {OrgName} from "../OrgName/OrgName"
import {kebabCase, startCase} from "lodash"

export enum LinkedAccountsColumns {
	id = "Id",
	org = "Org",
	customerName = "Customer",
	status = "Status",
	accountNumber = "Account Number",
	routingNumber = "Routing Number",
	createdAt = "Created At",
}

type AllowedLinkedAccountsColumns =
	| LinkedAccountsColumns.id
	| LinkedAccountsColumns.org
	| LinkedAccountsColumns.customerName
	| LinkedAccountsColumns.status
	| LinkedAccountsColumns.accountNumber
	| LinkedAccountsColumns.routingNumber
	| LinkedAccountsColumns.createdAt

interface LinkedAccountsProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedLinkedAccountsColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	type?: keyof typeof AccountType
	customerId?: string
	enableSearch?: boolean
	enableOrgFilter?: boolean
	enableStatusFilter?: boolean
	refreshToken?: number
	enableTutorial?: boolean
}

interface LinkedAccountsTableProps {
	linkedAccounts: Array<LinkedAccount>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedLinkedAccountsColumns>
	meta?: Meta
	fullHeight?: boolean
	enableTutorial?: boolean
	isFiltering?: boolean
	sortFunction: () => void
	sortBy: string
}

interface CustomerRowProps {
	linkedAccount: LinkedAccount
	include?: Resource[]
	includedColumns: Array<AllowedLinkedAccountsColumns>
}

function LinkedAccountRow({linkedAccount, include, includedColumns}: CustomerRowProps) {
	const navigate = useNavigate()

	const id = linkedAccount.id
	// Filter out account types that do not have org, like OrgLoanAccount and GLAccount
	const org = "org" in linkedAccount.relationships ? linkedAccount.relationships.org.data.id : null
	const name = linkedAccount.attributes.name
	const createdAt = linkedAccount.attributes.createdAt
	const status = linkedAccount.attributes.status
	const routingNumber = linkedAccount.attributes.routingNumber
	const accountNumber = linkedAccount.attributes.accountNumber

	const contentColumns: Partial<Record<AllowedLinkedAccountsColumns, ReactElement>> = {}

	if (includedColumns.includes(LinkedAccountsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className={classNames("data-table-id")}>{id}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(LinkedAccountsColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} />
	}

	if (includedColumns.includes(LinkedAccountsColumns.customerName)) {
		contentColumns["Customer"] = <DataTableCell>{name}</DataTableCell>
	}

	if (includedColumns.includes(LinkedAccountsColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("linked-account-status", kebabCase(status))}>{startCase(status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(LinkedAccountsColumns.routingNumber)) {
		contentColumns["Routing Number"] = <DataTableCell>{routingNumber}</DataTableCell>
	}

	if (includedColumns.includes(LinkedAccountsColumns.accountNumber)) {
		if (accountNumber) {
			contentColumns["Account Number"] = (
				<DataTableCell>
					<InformationHiding
						element={(accountNumber) => <>{accountNumber}</>}
						getValue={() => accountNumber ?? ""}
						placeholder={accountNumberPreview(accountNumber)}
					/>
				</DataTableCell>
			)
		} else {
			contentColumns["Account Number"] = <DataTableCell />
		}
	}

	if (includedColumns.includes(LinkedAccountsColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})
	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/linked-accounts/${id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function LinkedAccountsTable({
	linkedAccounts,
	includedColumns,
	hasPrev,
	hasNext,
	prev,
	next,
	hasResults,
	isUsingPaging,
	include,
	meta,
	fullHeight,
	enableTutorial,
	isFiltering,
	sortFunction,
	sortBy,
}: LinkedAccountsTableProps) {
	const noContent = linkedAccounts.length === 0
	const tutorialCallButton = document.querySelector(".tutorial-call-button")

	useEffect(() => {
		if (!noContent && tutorialCallButton) {
			tutorialCallButton.classList.add("tutorial-call-button-with-animation")
		}
	}, [])

	return (
		<div className={"accounts-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={
					!isFiltering ? (
						<>
							No{" "}
							<a
								className="doc-link"
								href="https://docs.unit.co/payments-linked-accounts/"
								target="_blank"
								rel="noreferrer"
							>
								{" "}
								accounts
							</a>{" "}
							yet
						</>
					) : (
						"No linked accounts found"
					)
				}
				noContentCtaButton={
					!isProdEnv() &&
					enableTutorial &&
					!useIsUnitTypeUser() && (
						<button
							className="tutorial-cta-button"
							data-tracking-label="tutorial-accounts-cta"
							onClick={(e) => {
								const target = e.target as HTMLButtonElement
								target.classList.add("tutorial-cta-button-was-used")

								if (tutorialCallButton) {
									ReactTooltip.show(tutorialCallButton)
								}
							}}
						>
							<Icon icon={"programming-code--programing-apps-websites"} color="white" />
							Test Create Account API
						</button>
					)
				}
			>
				<DataTableHead>
					{/*<DataTableRow>*/}
					{/*    {Object.entries(includedColumns).map((column) => {*/}
					{/*        return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>*/}
					{/*    })}*/}
					{/*</DataTableRow>*/}
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === LinkedAccountsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{linkedAccounts.map((a) => (
						<LinkedAccountRow include={include} includedColumns={includedColumns} linkedAccount={a} key={a.id} />
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export function FindLinkedAccounts({
	limit = 25,
	includedColumns,
	enableTitle,
	fullHeight,
	customerId,
	enableSearch,
	enableStatusFilter,
	enableOrgFilter,
	refreshToken,
	enableTutorial = false,
}: LinkedAccountsProps) {
	const prefix = "la"
	const accessToken = useAccessToken()
	const userType = useUserType()

	const statusesOptions = new Map(
		[LinkedAccountStatusEnum.PendingReview, LinkedAccountStatusEnum.Approved, LinkedAccountStatusEnum.Declined].map(
			(v) => [v, v]
		)
	)
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const [statuses, setStatuses] = useQueryState<LinkedAccountStatusEnum[]>(`${prefix}-filter[status]`, [])
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [filteredByOrg, setFilteredByOrg] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const isFiltering = query != "" || filteredByOrg.length != 0 || statuses.length != 0

	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable={true}
				setStatuses={setFilteredByOrg}
				statuses={filteredByOrg}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findLinkedAccounts({
				accessToken: accessToken,
				offset: offset,
				limit: limit,
				customerId: customerId,
				status: statuses,
				orgs: filteredByOrg,
				sort: sortBy,
				query: query,
			}),
		(x) => x.data.length,
		[query, refreshToken, sortBy, [...statuses, ...filteredByOrg].join(",")],
		prefix,
		true
	)
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Linked Accounts", 500, onSearch)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	const filters = [...(enableOrgFilter ? [orgFilter] : []), ...(enableStatusFilter ? [statusFilter] : [])]
	return (
		<DataTableCard className={"accounts-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={enableSearch ? searchBox : null}
				filters={filters.length === 0 ? null : filters}
				title={enableTitle ? "Linked Accounts" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: linkedAccounts}) => {
					return (
						<LinkedAccountsTable
							fullHeight={fullHeight}
							linkedAccounts={linkedAccounts.data}
							include={linkedAccounts.included}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={true}
							includedColumns={includedColumns}
							meta={linkedAccounts.meta}
							enableTutorial={enableTutorial}
							isFiltering={isFiltering}
							sortFunction={toggleSort}
							sortBy={sortBy}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
