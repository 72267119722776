import React, {useEffect, useState} from "react"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {DetailsList} from "../DetailsList/DetailsList"
import {AssignmentPageItem} from "../AssignmentPageItem/AssignmentPageItem"
import {
	Assignee,
	AssigneeType,
	AssignmentStage,
	AssignmentStatus,
	CallbackAssignmentItemPayload,
	CallbackDocumentMetadata,
	getDocument,
} from "../../resources/assignments"
import {AssignmentPageItemCallbackFooter} from "../AssignmentPageItemCallbackFooter/AssignmentPageItemCallbackFooter"
import CallbackInstructionsAndGuidance from "../CallbackInstructionsAndGuidance/CallbackInstructionsAndGuidance"
import AssignmentWireDetails from "../AssignmentWireDetails/AssignmentWireDetails"
import {WirePayment} from "../../resources/payment"
import {Customer} from "../../resources/customer"
import {BusinessApplication} from "../../resources/application"
import {useResetScrollTop} from "../../hooks/useResetScrollTop"
import {Claims, useUserClaimsData} from "../../services/auth"
import {AssignmentPageCompletedItemHeader} from "../AssignmentPageCompletedItemHeader/AssignmentPageCompletedItemHeader"
import {Downloader} from "../Downloader/Downloader"
import {AddToast, useToasts} from "react-toast-notifications"
import {downloadFile, getMimeTypeByFileName} from "../../utilities/file"

export type ApiRequestData = {
	accessToken: string
	id: string
	refresh: () => void
}

interface AssignmentPageItemCallbackProps {
	id: string
	assignees: Assignee[]
	status: AssignmentStatus
	stage: AssignmentStage
	title: string
	description?: string | JSX.Element
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
	payload: CallbackAssignmentItemPayload
	wire?: WirePayment
	customer?: Customer
	businessApplication?: BusinessApplication
	accessToken: string
	refresh: () => void
	disabled?: boolean
	claims?: Claims
}

export const onDownloadClick = async ({
	preLoadedCallbackDocumentMetadata,
	addToast,
	accessToken,
	id,
}: {
	preLoadedCallbackDocumentMetadata?: CallbackDocumentMetadata
	addToast: AddToast
	accessToken: string
	id: string
}) => {
	if (!preLoadedCallbackDocumentMetadata) {
		return
	}
	const fileType = getMimeTypeByFileName(preLoadedCallbackDocumentMetadata.documentName)
	if (!fileType) {
		addToast("File type is not supported", {appearance: "error"})
		return
	}
	const getDocumentResult = await getDocument({accessToken, id, fileType})
	getDocumentResult.match(
		(file) => {
			downloadFile(preLoadedCallbackDocumentMetadata.documentName, file, fileType)
		},
		(err) => {
			addToast(err.errors?.[0].title ?? "Downloading encountered an error", {appearance: "error"})
		}
	)
}

function HeaderWithDownloader({
	assignees,
	status,
	reasonText,
	preLoadedCallbackDocumentMetadata,
	handleDownload,
}: {
	assignees: Assignee[]
	status: AssignmentStatus
	reasonText?: string
	preLoadedCallbackDocumentMetadata?: CallbackDocumentMetadata
	handleDownload: () => Promise<void>
}) {
	return (
		<AssignmentPageCompletedItemHeader assignees={assignees} status={status} reasonText={reasonText}>
			{preLoadedCallbackDocumentMetadata && (
				<div className="assignment-page-item-callback-header-downloader">
					<Downloader
						fileName={preLoadedCallbackDocumentMetadata.documentName}
						sizeInBytes={preLoadedCallbackDocumentMetadata.documentSize}
						onDownloadClick={handleDownload}
						buttonClassName="assignment-page-item-callback-downloader-button"
					/>
				</div>
			)}
		</AssignmentPageCompletedItemHeader>
	)
}

export function AssignmentPageItemCallback({
	id,
	assignees,
	status,
	stage,
	title,
	description,
	createdAt,
	statusUpdatedAt,
	isOwnersItem,
	payload: {callbackSummary: preLoadedCallbackSummary, callbackDocumentMetadata: preLoadedCallbackDocumentMetadata},
	wire,
	customer,
	businessApplication,
	accessToken,
	refresh,
	disabled,
	claims,
}: AssignmentPageItemCallbackProps) {
	const [callSummaryTextValue, setCallSummaryTextValue] = useState("")
	const [file, setFile] = useState<File | undefined>(undefined)
	const instructionsAndGuidanceContentRef = React.useRef<HTMLDivElement>(null)
	const wireDetailsContentRef = React.useRef<HTMLDivElement>(null)
	const userClaims = useUserClaimsData(claims)
	useResetScrollTop(instructionsAndGuidanceContentRef, [id])
	useResetScrollTop(wireDetailsContentRef, [id])
	useEffect(() => {
		setFile(undefined)
		setCallSummaryTextValue("")
	}, [id])

	const {addToast} = useToasts()
	const handleDownload = async () => {
		await onDownloadClick({
			preLoadedCallbackDocumentMetadata,
			addToast,
			accessToken,
			id,
		})
	}
	const pageItemFooter = !disabled ? (
		<AssignmentPageItemCallbackFooter
			assignees={assignees}
			preLoadedData={{
				preLoadedCallbackSummary,
				preLoadedCallbackDocumentMetadata,
			}}
			callSummaryTextValue={callSummaryTextValue}
			setTextValue={setCallSummaryTextValue}
			file={file}
			setFile={setFile}
			apiRequestData={{
				accessToken,
				id,
				refresh,
			}}
			disabled={disabled}
			wireId={wire?.id ?? "-"}
			handleDownload={handleDownload}
		/>
	) : undefined
	const orgAssigneeNames = assignees
		.filter(({type}) => type === AssigneeType.Org)
		.map(({name}) => name)
		.filter((name): name is string => name !== undefined)
	return (
		<AssignmentPageItem
			assignees={assignees}
			status={status}
			stage={stage}
			title={title}
			description={description}
			createdAt={createdAt}
			statusUpdatedAt={statusUpdatedAt}
			isOwnersItem={isOwnersItem}
			disabled={disabled}
			pageItemFooter={pageItemFooter}
			pageCompletedItemHeader={
				<HeaderWithDownloader
					assignees={assignees}
					status={status}
					reasonText={preLoadedCallbackSummary}
					preLoadedCallbackDocumentMetadata={preLoadedCallbackDocumentMetadata}
					handleDownload={handleDownload}
				/>
			}
		>
			<GridContainer rows={1} columns={2} gap="8px" className="has-full-height assignment-page-item-callback-grid">
				<DetailsList
					title="Callback"
					icon="phone-forward-arrow"
					className="scrollable-container"
					contentRef={instructionsAndGuidanceContentRef}
					disabled={disabled}
				>
					<CallbackInstructionsAndGuidance disabled={disabled} orgNames={orgAssigneeNames} />
				</DetailsList>
				<DetailsList
					title="Wire Details"
					icon="payment-box-incoming"
					className="scrollable-container"
					contentRef={wireDetailsContentRef}
					disabled={disabled}
				>
					<AssignmentWireDetails
						wire={wire}
						customer={customer}
						businessApplication={businessApplication}
						disabled={disabled}
						singleColumn={true}
						liteVersion={true}
						claims={userClaims}
					/>
				</DetailsList>
			</GridContainer>
		</AssignmentPageItem>
	)
}
