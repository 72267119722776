import React, {useRef} from "react"
import {useIsUnitUser, useLogout, useUserClaimsData} from "../../services/auth"
import {useAccessToken, useIsBankUser} from "../../services/auth"
import {useToggle, useClickAway} from "react-use"
import {UnitOrOrgOrPartnerOrBankAdminOnlyUser} from "../../containers/PermissionedUser/PermissionedUser"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import Icon from "../Icon/Icon"
import classNames from "classnames"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getStatementLogoByToken, StatementLogo} from "../../resources/orgSettings"
import {ErrorDocument} from "../../resources/common"
import {AsyncResultRequestState} from "../../types/asyncResult"
import logoDark from "../../images/logo-dark.svg"
import {NavLink} from "../NavLink/NavLink"

function OrgLogoOrTitle({
	statementLogoResult,
	orgName,
	fallbackToOrgName = true,
}: {
	statementLogoResult?: AsyncResultRequestState<StatementLogo, ErrorDocument>
	orgName: string
	fallbackToOrgName?: boolean
}) {
	const isBankUser = useIsBankUser()
	if (!statementLogoResult || isBankUser) {
		return <img src={logoDark} alt="Unit" />
	}

	return (
		<>
			{statementLogoResult?.match(
				() => null,
				(result) => {
					const statementLogo = result.attributes.statementLogo
					if (!statementLogo) {
						return <>{fallbackToOrgName ? orgName : null}</>
					}

					return <img src={statementLogo} alt={orgName} />
				},
				() => (
					<>{fallbackToOrgName ? orgName : null}</>
				)
			)}
		</>
	)
}

export default function NavbarUserDropdown() {
	const ref = useRef(null)
	const [isDropdownActive, toggleDropdown] = useToggle(false)
	useClickAway(ref, () => toggleDropdown(false))
	const dropdownClassname = classNames("navbar-user-dropdown dropdown is-right", isDropdownActive && "is-active")
	const userTokenData = useUserClaimsData()
	const accessToken = useAccessToken()
	const logout = useLogout()
	const orgName = userTokenData.org
	const statementLogoResult = useIsUnitUser() ? undefined : useAsyncResult(() => getStatementLogoByToken(accessToken))
	const userFirstLetter = userTokenData.sub ? userTokenData.sub[0] : "U"

	return (
		<div className={dropdownClassname} ref={ref}>
			<div className="navbar-user-dropdown-trigger" onClick={() => toggleDropdown()}>
				<div className="navbar-user-dropdown-org-info">
					<OrgLogoOrTitle statementLogoResult={statementLogoResult} orgName={orgName} />
				</div>
				<div className="navbar-user-dropdown-user-letter">{userFirstLetter}</div>
			</div>
			<div className="dropdown-menu">
				<div className="dropdown-content">
					<div className="navbar-user-dropdown-content-details">
						<div className="navbar-user-dropdown-user-letter large">{userFirstLetter}</div>
						<div className="navbar-user-dropdown-content-username">{userTokenData.sub}</div>
						<div className="navbar-user-dropdown-content-org-logo">
							<OrgLogoOrTitle statementLogoResult={statementLogoResult} orgName={orgName} fallbackToOrgName={false} />
						</div>
						<button className="button" onClick={logout}>
							Logout
						</button>
					</div>
					<ul className="navbar-user-dropdown-content-links" onClick={toggleDropdown}>
						<UnitOrOrgOrPartnerOrBankAdminOnlyUser>
							<HasPermission resource="user" action="get">
								<li>
									<NavLink end className="has-icon" activeClassName="is-active" to="/users">
										<span className="icon">
											<Icon icon="user-group" size={16} />
										</span>
										<span className="menu-item-label">Manage Users</span>
									</NavLink>
								</li>
							</HasPermission>
						</UnitOrOrgOrPartnerOrBankAdminOnlyUser>
						<li>
							<NavLink end className="has-icon" activeClassName="is-active" to="/change-password">
								<span className="icon">
									<Icon icon="security-password-lock--programing-apps-websites" size={16} />
								</span>
								<span className="menu-item-label">Change Password</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
