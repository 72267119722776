import {useAccessToken} from "../../services/auth"
import React from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getByToken, OrgSettings} from "../../resources/orgSettings"
import classNames from "classnames"
import SamlSettings from "./SamlSettings"
import StatementSettings from "./StatementSettings"
import ApplicationFormSettings from "./ApplicationFormSettings"
import DashboardSettings from "./DashboardSettings"
import GeneralSettings from "./GeneralSettings"
import JwtSettings from "./JwtSettings"
import {useQueryState} from "use-location-state"
import IntegrationSettings from "./IntegrationSettings"

type OrgSettingsTabs =
	| "General"
	| "SAML"
	| "Statements"
	| "Application Form"
	| "Dashboard Settings"
	| "JWT Settings"
	| "Integrations"

function TabContent({
	currentTab,
	orgSettings,
	refresh,
}: {
	currentTab: OrgSettingsTabs
	orgSettings: OrgSettings
	refresh: () => void
}) {
	if (currentTab == "General") {
		return <GeneralSettings orgSettings={orgSettings} refresh={refresh} />
	} else if (currentTab == "SAML") {
		return <SamlSettings orgSettings={orgSettings} refresh={refresh} />
	} else if (currentTab == "Statements") {
		return <StatementSettings orgSettings={orgSettings} refresh={refresh} />
	} else if (currentTab == "Application Form") {
		return <ApplicationFormSettings orgSettings={orgSettings} refresh={refresh} />
	} else if (currentTab == "Dashboard Settings") {
		return <DashboardSettings orgSettings={orgSettings} refresh={refresh} />
	} else if (currentTab == "JWT Settings") {
		return <JwtSettings orgSettings={orgSettings} refresh={refresh} />
	} else if (currentTab == "Integrations") {
		return <IntegrationSettings orgSettings={orgSettings} refresh={refresh} />
	}

	return null
}

function OrgSettingsInternal({
	orgSettings,
	refresh,
	currentTab,
	setCurrentTab,
}: {
	orgSettings: OrgSettings
	refresh: () => void
	currentTab: OrgSettingsTabs
	setCurrentTab: (tab: OrgSettingsTabs) => void
}) {
	return (
		<>
			<TitleBar title={"Org Settings"} className={"title-bar-no-border"} />
			<nav className="org-settings tabs-container">
				<div className="tabs">
					<ul>
						<li className={classNames(currentTab == "General" && "is-active")} onClick={() => setCurrentTab("General")}>
							<a>General</a>
						</li>
						<li className={classNames(currentTab == "SAML" && "is-active")} onClick={() => setCurrentTab("SAML")}>
							<a>SAML</a>
						</li>
						<li
							className={classNames(currentTab == "Statements" && "is-active")}
							onClick={() => setCurrentTab("Statements")}
						>
							<a>Branding</a>
						</li>
						<li
							className={classNames(currentTab == "Application Form" && "is-active")}
							onClick={() => setCurrentTab("Application Form")}
						>
							<a>Application Form</a>
						</li>
						<li
							className={classNames(currentTab == "Dashboard Settings" && "is-active")}
							onClick={() => setCurrentTab("Dashboard Settings")}
						>
							<a>Dashboard Settings</a>
						</li>
						<li
							className={classNames(currentTab == "JWT Settings" && "is-active")}
							onClick={() => setCurrentTab("JWT Settings")}
						>
							<a>JWT Settings</a>
						</li>

						<li
							className={classNames(currentTab == "Integrations" && "is-active")}
							onClick={() => setCurrentTab("Integrations")}
						>
							<a>Integrations</a>
						</li>
					</ul>
				</div>
			</nav>

			<MainSection>
				<TabContent currentTab={currentTab} refresh={refresh} orgSettings={orgSettings} />
			</MainSection>
		</>
	)
}

export default function OrgSettingsPage() {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const [currentTab, setCurrentTab] = useQueryState<OrgSettingsTabs>("tab", "General")
	const orgSettingsAsync = useAsyncResult(() => getByToken(accessToken), [refreshToken])

	return (
		<AsyncResultComponent asyncResult={orgSettingsAsync}>
			{({value: orgSettings}) => {
				return (
					<OrgSettingsInternal
						orgSettings={orgSettings as OrgSettings}
						refresh={refresh}
						currentTab={currentTab}
						setCurrentTab={(t) => {
							setCurrentTab(t)
							refresh()
						}}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}
