import React, {CSSProperties} from "react"
import classNames from "classnames"

export enum ProgressBarSize {
	xs = "4px",
	sm = "9px",
	md = "12px",
	lg = "16px",
	xl = "20px",
}

interface ProgressBarProps {
	percent: number
	size?: ProgressBarSize | string
	color?: string
	className?: string
}

export function getPercentColor(percent: number): string {
	if (percent >= 90) {
		return "bg-secondary-red"
	}

	if (percent >= 70) {
		return "bg-secondary-orange"
	}

	return "bg-main-green"
}

export function ProgressBar({percent, color, size = ProgressBarSize.md, className}: ProgressBarProps) {
	const cssProperties = {
		"--progress-bar-width": `${Math.min(Number.isNaN(percent) ? 0 : percent, 100)}%`,
		"--progress-bar-size": `${size}`,
		...(color ? {"--progress-bar-color": `${color}`} : {}),
	} as CSSProperties

	return (
		<div role="progressbar" aria-valuenow={percent} className="progress-bar-container">
			<div className={classNames("progress-bar-max", {"progress-bar-color": color}, className)} style={cssProperties} />
			<div
				className={classNames(
					percent < 100 ? "progress-bar-percent-partial" : "progress-bar-percent-full",
					{"progress-bar-color": color},
					className
				)}
				style={cssProperties}
			/>
		</div>
	)
}
