import {createResource, deleteResource, getResource, updateResource} from "./common"

export type CounterpartyType = "Business" | "Person" | "Unknown"
export type CounterpartyPermission = "CreditOnly" | "CreditAndDebit" | "DebitOnly"
export type CounterpartyAccountType = "Checking" | "Savings"
export type LinkedAccountStatus = "PendingReview" | "Approved" | "Declined" | "Deleted"

export type LinkedAccount = {
	id: string
	attributes: {
		name: string
		routingNumber: string
		accountNumber: string
		plaidProcessorToken: string
		accountType: CounterpartyAccountType
		counterpartyType: CounterpartyType
		permissions: CounterpartyPermission
		status: LinkedAccountStatus
		createdAt: Date
		updatedAt: Date
		deletedAt: Date
		tags: {[key: string]: string}
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: number
			}
		}
		org: {
			data: {
				type: "org"
				id: number
			}
		}
	}
}

export enum LinkedAccountStatusEnum {
	PendingReview = "PendingReview",
	Approved = "Approved",
	Declined = "Declined",
}

export interface FindLinkedAccountsRequestParam {
	accessToken: string
	offset: number
	limit?: number
	customerId?: string
	status?: LinkedAccountStatusEnum[]
	orgs?: string[]
	sort?: string
	query?: string
}

export async function findLinkedAccounts(request: FindLinkedAccountsRequestParam) {
	const query = {
		include: "org",
		filter: {
			customerId: request.customerId,
			status: request.status,
			orgs: request.orgs && request.orgs.length > 0 ? request.orgs : null,
			query: request.query != "" ? request.query : null,
		},
		page: {
			limit: request.limit,
			offset: request.offset,
		},
		sort: request.sort,
	}
	return await getResource<Array<LinkedAccount>>(path, request.accessToken, query)
}

export async function getLinkedAccount(accessToken: string, id: string, include?: string) {
	return await getResource<LinkedAccount>(`${path}/${id}`, accessToken, {include})
}

export async function deleteLinkedAccount(accessToken: string, linkedAccount: LinkedAccount) {
	return await deleteResource<LinkedAccount>(`${path}/${linkedAccount.id}`, accessToken)
}

export interface LinkedAccountCreateRequestParam {
	accessToken: string
	customerId: string
	name: string
	plaidProcessorToken: string
	accountType: CounterpartyAccountType
	type: CounterpartyType
	permissions: CounterpartyPermission
	tags?: {[key: string]: string}
}

const path = "linked-accounts"

export async function createLinkedAccount(request: LinkedAccountCreateRequestParam) {
	const requestBody = {
		type: "achLinkedAccount",
		attributes: {
			name: request.name,
			plaidProcessorToken: request.plaidProcessorToken,
			type: request.type,
			accountType: request.accountType,
			permissions: request.permissions,
			tags: request.tags,
		},
		relationships: {
			customer: {
				data: {
					type: "customer",
					id: request.customerId,
				},
			},
		},
	}
	return await createResource<LinkedAccount>(path, request.accessToken, requestBody)
}

export async function updateLinkedAccount(
	accessToken: string,
	id: string,
	processorToken?: string,
	tags?: {[key: string]: string}
) {
	interface PatchAttributes {
		plaidProcessorToken?: string
		tags?: {[key: string]: string}
	}

	const requestBody = {
		type: "patchAchLinkedAccount",
		attributes: {
			plaidProcessorToken: processorToken,
			tags: tags,
		} as PatchAttributes,
	}

	return await updateResource<LinkedAccount>(`${path}/${id}`, accessToken, requestBody)
}

export async function declineLinkedAccount(accessToken: string, id: string, reason: string) {
	const requestBody = {
		type: "declineLinkedAccount",
		attributes: {
			reason: reason,
		},
	}

	return await createResource<LinkedAccount>(`${path}/${id}/decline`, accessToken, requestBody)
}

export async function approveLinkedAccount(accessToken: string, id: string) {
	return await createResource<LinkedAccount>(`${path}/${id}/approve`, accessToken, undefined)
}

type PlaidData = {
	id: string
	attributes: {
		identity: any
	}
}

export async function getLinkedAccountPlaidData(accessToken: string, id: string) {
	return await getResource<PlaidData>(`linked-accounts/${id}/plaid`, accessToken)
}
