import React, {useRef, useState} from "react"
import {useClickAway, useToggle} from "react-use"
import {concat, noop, without} from "lodash"
import classNames from "classnames"
import SearchBox from "../SearchBox/SearchBox"
import {isUndefined} from "lodash"
import {OutlinedFilter} from "./Themes/OutlinedFilter"
import {SimpleFilter} from "./Themes/SimpleFilter"
import {IcomoonIconName} from "../Icon/icons"

export type CommonFilterProps<K> = {
	dropdownClassname: string
	filterRef: React.RefObject<HTMLInputElement>
	toggleDropdown: (nextValue?: any) => void
	showFilterSummary: boolean
	title?: string
	statuses: K[]
	toggleAll: typeof noop
	toggleNone: typeof noop
	isSearchable: boolean
	searchBox: JSX.Element
	buttons: JSX.Element[]
}
export function Filter<K>({
	options,
	statuses,
	setStatuses,
	title,
	onFilterFunc,
	isSearchable = false,
	simpleTheme = false,
	filterIcon,
}: {
	options: Map<K, string>
	statuses: K[]
	setStatuses: (statuses: K[]) => void
	title?: string
	onFilterFunc?: typeof noop
	isSearchable?: boolean
	simpleTheme?: boolean
	filterIcon?: IcomoonIconName
}) {
	const all = options
	const ref = useRef(null)
	const [isDropdownActive, toggleDropdown] = useToggle(false)
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
	const [searchBox] = SearchBox("", "Search", 0, onSearch, true)
	const showFilterSummary = statuses.length > 0 && statuses.length != options.size

	useClickAway(ref, () => toggleDropdown(false))
	const dropdownClassname = classNames("dropdown", isDropdownActive && "is-active")

	function toggle(status: K) {
		if (statuses.includes(status)) setStatuses(without(statuses, status))
		else if (!statuses.includes(status)) setStatuses(concat(statuses, status))
	}

	function toggleAll() {
		setStatuses(Array.from(all.keys()))
		toggleDropdown()
	}

	function toggleNone() {
		setStatuses([])
	}

	function onSearch(searchTerm: string) {
		if (searchTerm === "") {
			setSearchTerm(undefined)
		} else {
			setSearchTerm(searchTerm)
		}
	}

	const buttons = Array.from(all.entries())
		.filter(([, value]) => {
			if (isSearchable) {
				if (!isUndefined(searchTerm)) {
					if (!value.toLowerCase().includes(searchTerm.toLowerCase())) {
						return false
					}
				}
			}
			return true
		})
		.map(([key, value]: [key: K, value: string]) => (
			<label key={value} className="panel-block no-border-bottom wide-panel simple-checkbox">
				<input
					type="checkbox"
					onChange={(_) => {
						toggle(key)
						onFilterFunc ? onFilterFunc() : null
					}}
					checked={statuses.includes(key)}
				/>
				{value}
			</label>
		))

	const commonFilterProps = {
		dropdownClassname,
		filterRef: ref,
		toggleDropdown,
		showFilterSummary,
		title,
		statuses,
		toggleAll,
		toggleNone,
		isSearchable,
		searchBox,
		toggle,
		onFilterFunc,
		buttons,
	}

	return simpleTheme ? (
		<SimpleFilter {...commonFilterProps} filterIcon={filterIcon} />
	) : (
		<OutlinedFilter {...commonFilterProps} />
	)
}

Filter.defaultProps = {
	title: "Filter",
}
