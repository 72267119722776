import React, {PropsWithChildren, useState} from "react"
import Icon from "../Icon/Icon"
import classNames from "classnames"
import {useEsc} from "../../hooks/useEsc"

interface ImageGalleryModalProps {
	header?: JSX.Element
	close: () => void
	gallery: Array<JSX.Element>
	description: Array<string>
	disableRotation?: boolean
}

type RotationDegrees = 0 | 90 | 180 | 270

export function ImageGalleryModal(props: PropsWithChildren<ImageGalleryModalProps>) {
	const [currentIndex, setCurrentIndex] = useState<number>(0)

	const [degree, setDegree] = useState<RotationDegrees>(0)

	useEsc(props.close)

	const switchImage = () => {
		const nextId = currentIndex === 0 ? 1 : 0
		setCurrentIndex(nextId)
		setDegree(0)
	}

	return (
		<div className="gallery-modal modal is-active">
			<div className="modal-background" onClick={() => props.close()} />
			<div className="modal-header">
				<div className="modal-header-custom"> {props.header} </div>

				<div className="modal-header-default">
					<span
						onClick={(e) => {
							if (props.disableRotation) {
								return
							}
							e.stopPropagation()
							switch (degree) {
								case 0:
									setDegree(90)
									break
								case 90:
									setDegree(180)
									break
								case 180:
									setDegree(270)
									break
								default:
									setDegree(0)
									break
							}
						}}
					>
						<Icon icon="design-tool-rotate-clockwise" size={20} />
					</span>

					<span
						onClick={(e) => {
							e.stopPropagation()
							props.close()
						}}
					>
						<Icon icon="interface-delete-interface-essential" size={20} />
					</span>
				</div>
			</div>
			<div className="modal-body">
				<div className={classNames("modal-content", `transform-${degree}`)}>{props.gallery[currentIndex]}</div>
				<div className="modal-footer" onClick={switchImage}>
					<div className={"modal-footer-text"}> {props.description[currentIndex]} </div>
					{props.gallery[currentIndex === 0 ? 1 : 0]}
				</div>
			</div>
		</div>
	)
}
