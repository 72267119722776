import React from "react"
import moment from "moment"
interface AssignmentNavigatorItemDateProps {
	date: Date
}

export function AssignmentNavigatorItemDate({date}: AssignmentNavigatorItemDateProps) {
	const momentDate = moment(date)
	const formattedDate = momentDate.format("MMM DD, YYYY")
	const formattedTime = momentDate.format("hh:mm A")
	return (
		<div className="assignment-navigator-item-date">
			<span className="date-container">{formattedDate}</span>
			<span className="time-container">{formattedTime}</span>
		</div>
	)
}
