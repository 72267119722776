import React from "react"
import classNames from "classnames"
import {getRiskLevel} from "../EvaluationScores/EvaluationScores"

export function EvaluationFlags({title, flags}: {title: string; flags?: Array<string>}) {
	return (
		<div className="flags">
			<div className="label">{title}</div>
			<div className="flags-container">
				<Flags flags={flags} />
			</div>
		</div>
	)
}

export function IdTheftScore({idTheftScore}: {idTheftScore: number}) {
	return (
		<div>
			<div className="horizontal-separator" />
			<div className="scores-container">
				<div className="score-name">SentiLink ID Theft Score</div>
				<div className="scores">
					<div className={classNames("flag-icon", "flag-" + getRiskLevel("idTheftScore", idTheftScore))} />{" "}
				</div>
				<div className="score-value">{idTheftScore}</div>
			</div>
		</div>
	)
}

export function EntityEvaluation({
	title,
	flags,
	idTheftScore,
}: {
	title: string
	flags?: Array<string>
	idTheftScore?: number
}) {
	return (
		<div>
			<EvaluationFlags title={title} flags={flags} />
			{idTheftScore ? <IdTheftScore idTheftScore={idTheftScore} /> : null}
		</div>
	)
}

function Flags({flags}: {flags?: Array<string>}) {
	if (flags) {
		return (
			<>
				{flags.map((f) => (
					<div key={f} className="flag-pair">
						<div className={classNames("flag-icon", "flag-" + f.split(" ").join("-").toLowerCase())} />
						<div className="flag-name">{f}</div>
					</div>
				))}
			</>
		)
	}

	return <div>No Evaluation flags</div>
}
