import {IcomoonIconName} from "../../components/Icon/icons"
import {Org} from "../../resources/org"
import {AuditLogEventsHeaderMetaItemProps} from "../../components/AuditLogEventsHeader/AuditLogEventsHeader"

interface getAuditLogEventsHeaderMetaItemsProps {
	customerName: string
	customerType: string
	pageIcon: IcomoonIconName
	org?: Org
}
export const getAuditLogEventsHeaderMetaItems = ({
	customerName,
	customerType,
	pageIcon,
	org,
}: getAuditLogEventsHeaderMetaItemsProps): AuditLogEventsHeaderMetaItemProps[] => {
	return [
		{
			text: customerName,
			icon: "user-contact",
			tooltipText: "Name",
		},
		{
			text: customerType,
			icon: pageIcon,
			tooltipText: "Type",
		},
		...(!!org
			? [
					{
						text: org.attributes.name,
						icon: "user-hierachy2" as IcomoonIconName,
						tooltipText: "Org Name",
					},
			  ]
			: []),
	]
}
