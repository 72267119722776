import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, Org} from "../../resources/org"
import {Bank, findBanks} from "../../resources/bank"
import {AsyncResult} from "../../types/asyncResult"
import Loading from "../../components/Loading/Loading"
import React, {Dispatch, SetStateAction, useEffect, useState} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {createDepositProduct, DepositProduct} from "../../resources/depositProduct"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {EditMode, ErrorDocument} from "../../resources/common"
import {Attributes, DepositProductData} from "./Attributes"
import {useLocation, useNavigate} from "react-router-dom"
import {useToasts} from "react-toast-notifications"
import {BankRouting, findBankRoutings} from "../../resources/bankRouting"
import {
	findTransactionMonitoringSettings,
	TransactionMonitoringSettings,
} from "../../resources/transactionMonitoringSettings"
import {Bin, findBins} from "../../resources/bin"
import {findInterestSettings, InterestSettings} from "../../resources/interestSettings"

function Footer({state}: {state: AsyncResultIdleRequestState<DepositProduct, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Create</button>
				),
				() => (
					<button className="button is-success is-loading">Creating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{`${err.errors[0].title} - ${err.errors[0].detail}`}</div>
						<button className="button is-success">Create</button>
					</>
				)
			)}
		</div>
	)
}

type Props = {
	orgs: Array<Org>
	banks: Array<Bank>
	bankRoutings: Array<BankRouting>
	bankId: string
	setBankId: Dispatch<SetStateAction<string>>
	bankRoutingId: string
	setBankRoutingId: Dispatch<SetStateAction<string>>
	transactionMonitoringSettings: Array<TransactionMonitoringSettings>
	bins: Array<Bin>
	interestSettings: Array<InterestSettings>
	interestSettingsId: string
	setInterestSettingsId: Dispatch<SetStateAction<string | null>>
	initialProps?: DepositProductData
}

function Page({
	orgs,
	banks,
	bankRoutings,
	bankId,
	setBankId,
	bankRoutingId,
	setBankRoutingId,
	transactionMonitoringSettings,
	bins,
	interestSettings,
	interestSettingsId,
	setInterestSettingsId,
	initialProps,
}: Props) {
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const {addToast} = useToasts()

	const defaultMonthlyAchDebitSoftLimit = (initialProps?.monthlyACHDebitLimit ?? 2000000) * 0.9
	const defaultDailyAchDebitSoftLimit = (initialProps?.dailyACHDebitLimit ?? 50000) * 0.9

	const defaultMonthlyCheckDepositSoftLimit = (initialProps?.monthlyCheckDepositLimit ?? 2000000) * 0.9
	const defaultDailyCheckDepositSoftLimit = (initialProps?.dailyCheckDepositLimit ?? 100000) * 0.9

	const defaultMonthlyWireSoftLimit = initialProps?.monthlyWireSoftLimit ?? 0
	const defaultDailyWireSoftLimit = initialProps?.dailyWireSoftLimit ?? 0

	const defaultMonthlyCheckPaymentSoftLimit = initialProps?.monthlyCheckPaymentSoftLimit ?? 10000000
	const defaultDailyCheckPaymentSoftLimit = initialProps?.dailyCheckPaymentSoftLimit ?? 10000000

	const defaultMaxNumberOfPhysicalCards = () => {
		if (initialProps) return initialProps.maxNumberOfPhysicalCards
		else if (orgs[0].attributes.isUnitPilot) return 0
		else return null
	}

	const defaultMaxNumberOfVirtualCards = () => {
		if (initialProps) return initialProps.maxNumberOfVirtualCards
		else if (orgs[0].attributes.isUnitPilot) return 0
		else return null
	}

	const [state, create] = useAsyncResultIdle(createDepositProduct)
	const [name, setName] = useState(initialProps?.name ? initialProps.name + " duplicate" : "")
	const [orgId, setOrgId] = useState(initialProps?.orgId ?? orgs[0].id.toString())
	const [transactionMonitoringSettingsId, setTransactionMonitoringSettingsId] = useState(
		initialProps?.transactionMonitoringSettingsId ?? transactionMonitoringSettings[0]
			? transactionMonitoringSettings[0].id.toString()
			: "0"
	)
	const [incomingAchFee, setIncomingAchFee] = useState(initialProps?.incomingACHFee ?? 0)
	const [outgoingAchFee, setOutgoingAchFee] = useState(initialProps?.outgoingACHFee ?? 0)
	const [dailyAchDebitLimit, setDailyAchDebitLimit] = useState(initialProps?.dailyACHDebitLimit ?? 50000)
	const [dailyAchCreditLimit, setDailyAchCreditLimit] = useState(initialProps?.dailyACHCreditLimit ?? 50000)
	const [monthlyAchDebitLimit, setMonthlyAchDebitLimit] = useState(initialProps?.monthlyACHDebitLimit ?? 2000000)
	const [monthlyAchCreditLimit, setMonthlyAchCreditLimit] = useState(initialProps?.monthlyACHCreditLimit ?? 2000000)
	const [dailyAchDebitSoftLimit, setDailyAchDebitSoftLimit] = useState(
		initialProps?.dailyACHDebitSoftLimit ?? defaultDailyAchDebitSoftLimit
	)
	const [monthlyAchDebitSoftLimit, setMonthlyAchDebitSoftLimit] = useState(
		initialProps?.monthlyACHDebitSoftLimit ?? defaultMonthlyAchDebitSoftLimit
	)

	const [dailyBookDebitLimit, setDailyBookDebitLimit] = useState<number | undefined | null>(
		initialProps?.dailyBookDebitLimit
	)
	const [dailyBookCreditLimit, setDailyBookCreditLimit] = useState<number | undefined | null>(
		initialProps?.dailyBookCreditLimit
	)
	const [monthlyBookDebitLimit, setMonthlyBookDebitLimit] = useState<number | undefined | null>(
		initialProps?.monthlyBookDebitLimit
	)
	const [monthlyBookCreditLimit, setMonthlyBookCreditLimit] = useState<number | undefined | null>(
		initialProps?.monthlyBookCreditLimit
	)

	const [dailyCardWithdrawalLimit, setDailyCardWithdrawalLimit] = useState(
		initialProps?.dailyCardWithdrawalLimit ?? 500000
	)
	const [dailyCardDepositLimit, setDailyCardDepositLimit] = useState(initialProps?.dailyCardDepositLimit ?? 500000)
	const [dailyCardPurchaseLimit, setDailyCardPurchaseLimit] = useState(initialProps?.dailyCardPurchaseLimit ?? 500000)
	const [dailyCardTransactionLimit, setDailyCardTransactionLimit] = useState(
		initialProps?.dailyCardTransactionLimit ?? 500000
	)
	const [achDebitClearingDays, setACHDebitClearingDays] = useState(initialProps?.achDebitClearingDays ?? 5)
	const [cardIssuingFee, setCardIssuingFee] = useState<number | null>(initialProps?.cardIssuingFee ?? null)
	const [individualBinId, setIndividualBinId] = useState<string | null>(initialProps?.individualBinId ?? null)
	const [businessBinId, setBusinessBinId] = useState<string | null>(initialProps?.businessBinId ?? null)
	const [cardQualifier, setCardQualifier] = useState<string | null>(initialProps?.cardQualifier ?? null)
	const [limitGroup, setLimitGroup] = useState<string | null>(initialProps?.limitGroup ?? null)
	const [maxNumberOfPhysicalCards, setMaxNumberOfPhysicalCards] = useState<number | null>(
		defaultMaxNumberOfPhysicalCards()
	)
	const [maxNumberOfVirtualCards, setMaxNumberOfVirtualCards] = useState<number | null>(
		defaultMaxNumberOfVirtualCards()
	)
	const [programmaticPurchaseAuthorizationEnabled, setProgrammaticPurchaseAuthorizationEnabled] = useState<
		boolean | null
	>(initialProps?.programmaticPurchaseAuthorizationEnabled ?? null)
	const [programmaticPurchaseAuthorizationTimeoutStrategy, setProgrammaticPurchaseAuthorizationTimeoutStrategy] =
		useState<string | null>(initialProps?.programmaticPurchaseAuthorizationTimeoutStrategy ?? null)
	const [programmaticPurchaseAuthorizationIncludeCredit, setProgrammaticPurchaseAuthorizationIncludeCredit] = useState<
		boolean | null
	>(initialProps?.programmaticPurchaseAuthorizationIncludeCredit ?? null)
	const [programmaticCardTransactionAuthorizationEnabled, setProgrammaticCardTransactionAuthorizationEnabled] =
		useState<boolean | null>(initialProps?.programmaticCardTransactionAuthorizationEnabled ?? null)
	const [
		programmaticCardTransactionAuthorizationTimeoutStrategy,
		setProgrammaticCardTransactionAuthorizationTimeoutStrategy,
	] = useState<string | null>(initialProps?.programmaticCardTransactionAuthorizationTimeoutStrategy ?? null)
	const [
		programmaticCardTransactionAuthorizationIncludeCredit,
		setProgrammaticCardTransactionAuthorizationIncludeCredit,
	] = useState<boolean | null>(initialProps?.programmaticCardTransactionAuthorizationIncludeCredit ?? null)
	const [programmaticAtmAuthorizationEnabled, setProgrammaticAtmAuthorizationEnabled] = useState<boolean | null>(
		initialProps?.programmaticAtmAuthorizationEnabled ?? null
	)
	const [programmaticAtmAuthorizationTimeoutStrategy, setProgrammaticAtmAuthorizationTimeoutStrategy] = useState<
		string | null
	>(initialProps?.programmaticAtmAuthorizationTimeoutStrategy ?? null)
	const [programmaticAdviceAuthorizationEnabled, setProgrammaticAdviceAuthorizationEnabled] = useState<boolean | null>(
		initialProps?.programmaticAdviceAuthorizationEnabled ?? null
	)
	const [isAutoCloseEnabled, setIsAutoCloseEnabled] = useState(initialProps?.isAutoCloseEnabled ?? true)
	const [autoCloseAfter, setAutoCloseAfter] = useState<number>(initialProps?.autoCloseAfter ?? 60)

	const [incomingWireFee, setIncomingWireFee] = useState(initialProps?.incomingWireFee ?? 0)
	const [outgoingWireFee, setOutgoingWireFee] = useState(initialProps?.outgoingWireFee ?? 0)

	const [orgInterestShare, setOrgInterestShare] = useState<number>(initialProps?.orgInterestShare ?? 100)
	const [customerInterestShare, setCustomerInterestShare] = useState<number>(initialProps?.customerInterestShare ?? 0)
	const [sponsoredInterestFormula, setSponsoredInterestFormula] = useState<string | undefined>(
		initialProps?.sponsoredInterestFormula ?? undefined
	)
	const [checkDepositClearingDays, setCheckDepositClearingDays] = useState(initialProps?.checkDepositClearingDays ?? 5)

	const [checkDepositMinClearingDays, setCheckDepositMinClearingDays] = useState<number | undefined | null>(
		initialProps?.checkDepositMinClearingDays ?? null
	)
	const [checkDepositMaxClearingDays, setCheckDepositMaxClearingDays] = useState<number | undefined | null>(
		initialProps?.checkDepositMaxClearingDays ?? null
	)

	const [dailyCheckDepositLimit, setDailyCheckDepositLimit] = useState(initialProps?.dailyCheckDepositLimit ?? 100000)
	const [monthlyCheckDepositLimit, setMonthlyCheckDepositLimit] = useState(
		initialProps?.monthlyCheckDepositLimit ?? 2000000
	)
	const [dailyCheckDepositSoftLimit, setDailyCheckDepositSoftLimit] = useState(
		initialProps?.dailyCheckDepositSoftLimit ?? defaultDailyCheckDepositSoftLimit
	)
	const [monthlyCheckDepositSoftLimit, setMonthlyCheckDepositSoftLimit] = useState(
		initialProps?.monthlyCheckDepositSoftLimit ?? defaultMonthlyCheckDepositSoftLimit
	)

	const [dailyWireLimit, setDailyWireLimit] = useState(initialProps?.dailyWireLimit ?? 20000000)
	const [monthlyWireLimit, setMonthlyWireLimit] = useState(initialProps?.monthlyWireLimit ?? 20000000)
	const [dailyWireSoftLimit, setDailyWireSoftLimit] = useState(
		initialProps?.dailyWireSoftLimit ?? defaultDailyWireSoftLimit
	)
	const [monthlyWireSoftLimit, setMonthlyWireSoftLimit] = useState(
		initialProps?.monthlyWireSoftLimit ?? defaultMonthlyWireSoftLimit
	)

	const [dailyCheckPaymentLimit, setDailyCheckPaymentLimit] = useState<number | undefined | null>(
		initialProps?.dailyCheckPaymentLimit != undefined ? initialProps?.dailyCheckPaymentLimit : undefined
	)
	const [monthlyCheckPaymentLimit, setMonthlyCheckPaymentLimit] = useState<number | undefined | null>(
		initialProps?.monthlyCheckPaymentLimit != undefined ? initialProps?.monthlyCheckPaymentLimit : undefined
	)
	const [dailyCheckPaymentSoftLimit, setDailyCheckPaymentSoftLimit] = useState<number | undefined | null>(
		initialProps?.dailyCheckPaymentSoftLimit != undefined
			? initialProps?.dailyCheckPaymentSoftLimit
			: defaultDailyCheckPaymentSoftLimit
	)
	const [monthlyCheckPaymentSoftLimit, setMonthlyCheckPaymentSoftLimit] = useState<number | undefined | null>(
		initialProps?.monthlyCheckPaymentSoftLimit != undefined
			? initialProps?.monthlyCheckPaymentSoftLimit
			: defaultMonthlyCheckPaymentSoftLimit
	)

	const [checkWritingEnabled, setCheckWritingEnabled] = useState(initialProps?.checkWritingEnabled ?? false)
	const [checkWritingOriginationEnabled, setCheckWritingOriginationEnabled] = useState(
		initialProps?.checkWritingOriginationEnabled ?? false
	)

	const [enableOverdraft, setEnableOverdraft] = useState(initialProps?.enableOverdraft ?? false)
	const [overdraftDaysLimit, setOverdraftDaysLimit] = useState<number | null>(initialProps?.overdraftDaysLimit ?? null)

	const [enableCheckDepositDynamicClearingDays, setEnableCheckDepositDynamicClearingDays] = useState<
		boolean | undefined
	>(initialProps?.enableCheckDepositDynamicClearingDays ?? false)

	const [enableDynamicClearingDaysAchDebit, setEnableDynamicClearingDaysAchDebit] = useState<boolean | undefined>(
		initialProps?.enableDynamicClearingDaysAchDebit ?? false
	)
	const [achDebitMinClearingDays, setAchDebitMinClearingDays] = useState<number | undefined | null>(
		initialProps?.achDebitMinClearingDays ?? null
	)
	const [achDebitMaxClearingDays, setAchDebitMaxClearingDays] = useState<number | undefined | null>(
		initialProps?.achDebitMaxClearingDays ?? null
	)

	const [isAchDebitFraudPredictEnabled, setIsAchDebitFraudPredictEnabled] = useState<boolean | undefined>(
		initialProps?.isAchDebitFraudPredictEnabled ?? false
	)

	const [isSweepEnabled, setIsSweepEnabled] = useState<boolean | undefined>(initialProps?.isSweepEnabled ?? false)

	const [enableCustomerInterestFormula, setEnableCustomerInterestFormula] = useState<boolean | undefined>(
		initialProps?.enableCustomerInterestFormula ?? false
	)
	const [customerInterestFormula, setCustomerInterestFormula] = useState(
		initialProps?.customerInterestFormula ?? undefined
	)
	const [orgInternationalServiceFee, setOrgInternationalServiceFee] = useState<number | null>(
		initialProps?.orgInternationalServiceFee ?? 0
	)

	const [additionalVerificationThreshold, setAdditionalVerificationThreshold] = useState<number | undefined | null>(
		initialProps?.additionalVerificationThreshold ?? undefined
	)

	useEffect(() => {
		if (state.isOk()) {
			addToast("Deposit Product Created Successfully", {appearance: "success"})
			navigate(`/deposit-products/${state.value.id}`, {replace: true})
		}
	}, [state])

	useEffect(() => {
		const routings = bankRoutings.filter((r) => r.relationships.bank.data.id === bankId).map((r) => r.id)

		if (!routings.includes(bankRoutingId)) setBankRoutingId(routings[0])
	}, [bankId])

	const [isOverrideInterestSettingsId, setIsOverrideInterestSettingsId] = useState(!!interestSettingsId)

	const submit = () => {
		let cardProduct = [
			cardIssuingFee,
			individualBinId,
			businessBinId,
			cardQualifier,
			limitGroup,
			maxNumberOfPhysicalCards,
			maxNumberOfVirtualCards,
			programmaticPurchaseAuthorizationEnabled,
			programmaticPurchaseAuthorizationTimeoutStrategy,
			programmaticPurchaseAuthorizationIncludeCredit,
			programmaticCardTransactionAuthorizationEnabled,
			programmaticCardTransactionAuthorizationTimeoutStrategy,
			programmaticCardTransactionAuthorizationIncludeCredit,
			programmaticAdviceAuthorizationEnabled,
			orgInternationalServiceFee,
			additionalVerificationThreshold,
		].some((i) => i != null)
			? {
					cardIssuingFee: cardIssuingFee,
					cardQualifier: cardQualifier,
					limitGroup: limitGroup,
					maxNumberOfPhysicalCards: maxNumberOfPhysicalCards,
					maxNumberOfVirtualCards: maxNumberOfVirtualCards,
					programmaticPurchaseAuthorizationEnabled: programmaticPurchaseAuthorizationEnabled ?? false,
					programmaticPurchaseAuthorizationIncludeCredit: programmaticPurchaseAuthorizationIncludeCredit ?? false,
					programmaticCardTransactionAuthorizationEnabled: programmaticCardTransactionAuthorizationEnabled ?? false,
					programmaticCardTransactionAuthorizationIncludeCredit:
						programmaticCardTransactionAuthorizationIncludeCredit ?? false,
					programmaticAtmAuthorizationEnabled: programmaticAtmAuthorizationEnabled ?? false,
					programmaticAdviceAuthorizationEnabled: programmaticAdviceAuthorizationEnabled ?? false,
					orgInternationalServiceFee: orgInternationalServiceFee ?? 0,
			  }
			: undefined
		if (cardProduct) {
			if (individualBinId != null) {
				cardProduct = Object.assign(cardProduct, {
					individualBinId: individualBinId,
				})
			}
			if (businessBinId != null) {
				cardProduct = Object.assign(cardProduct, {
					businessBinId: businessBinId,
				})
			}
			if (programmaticPurchaseAuthorizationTimeoutStrategy != null) {
				cardProduct = Object.assign(cardProduct, {
					programmaticPurchaseAuthorizationTimeoutStrategy: programmaticPurchaseAuthorizationTimeoutStrategy,
				})
			}
			if (programmaticCardTransactionAuthorizationTimeoutStrategy != null) {
				cardProduct = Object.assign(cardProduct, {
					programmaticCardTransactionAuthorizationTimeoutStrategy:
						programmaticCardTransactionAuthorizationTimeoutStrategy,
				})
			}
			if (programmaticAtmAuthorizationTimeoutStrategy != null) {
				cardProduct = Object.assign(cardProduct, {
					programmaticAtmAuthorizationTimeoutStrategy: programmaticAtmAuthorizationTimeoutStrategy,
				})
			}
		}

		const interestTerms = {
			orgShare: orgInterestShare,
			customerShare: customerInterestShare,
			sponsoredInterestFormula: sponsoredInterestFormula,
		}

		const overdraftSettings = {
			enableOverdraft,
			overdraftDaysLimit,
		}

		create(
			accessToken,
			name,
			orgId,
			bankRoutingId,
			incomingAchFee,
			outgoingAchFee,
			dailyAchDebitLimit,
			dailyAchCreditLimit,
			monthlyAchDebitLimit,
			monthlyAchCreditLimit,
			dailyAchDebitSoftLimit,
			monthlyAchDebitSoftLimit,
			dailyBookDebitLimit,
			dailyBookCreditLimit,
			monthlyBookDebitLimit,
			monthlyBookCreditLimit,
			dailyCardWithdrawalLimit,
			dailyCardDepositLimit,
			dailyCardPurchaseLimit,
			dailyCardTransactionLimit,
			achDebitClearingDays,
			incomingWireFee,
			outgoingWireFee,
			cardProduct,
			transactionMonitoringSettingsId,
			interestTerms,
			checkDepositClearingDays,
			checkDepositMinClearingDays,
			checkDepositMaxClearingDays,
			dailyCheckDepositLimit,
			monthlyCheckDepositLimit,
			dailyCheckDepositSoftLimit,
			monthlyCheckDepositSoftLimit,
			isAutoCloseEnabled,
			autoCloseAfter,
			overdraftSettings,
			dailyWireLimit,
			monthlyWireLimit,
			dailyWireSoftLimit,
			monthlyWireSoftLimit,
			dailyCheckPaymentLimit,
			monthlyCheckPaymentLimit,
			dailyCheckPaymentSoftLimit,
			monthlyCheckPaymentSoftLimit,
			checkWritingEnabled,
			interestSettingsId,
			enableCheckDepositDynamicClearingDays,
			enableDynamicClearingDaysAchDebit,
			achDebitMinClearingDays,
			achDebitMaxClearingDays,
			isAchDebitFraudPredictEnabled,
			isSweepEnabled,
			checkWritingOriginationEnabled,
			additionalVerificationThreshold,
			enableCustomerInterestFormula,
			customerInterestFormula
		)
	}

	const disabled = state.match(
		() => false,
		() => true,
		(_) => true,
		(_) => false
	)

	return (
		<>
			<TitleBar title={"New Deposit Product"} />
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
						</div>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						submit()
					}}
				>
					<fieldset disabled={disabled}>
						<Attributes
							editMode={EditMode.NEW}
							orgs={orgs}
							banks={banks}
							transactionMonitoringSettings={transactionMonitoringSettings}
							bankRoutings={bankRoutings}
							bins={bins}
							name={name}
							setName={setName}
							orgId={orgId}
							setOrgId={setOrgId}
							bankId={bankId}
							setBankId={setBankId}
							bankRoutingId={bankRoutingId}
							setBankRoutingId={setBankRoutingId}
							transactionMonitoringSettingsId={transactionMonitoringSettingsId}
							setTransactionMonitoringSettingsId={setTransactionMonitoringSettingsId}
							incomingACHFee={incomingAchFee}
							setIncomingAchFee={setIncomingAchFee}
							outgoingACHFee={outgoingAchFee}
							setOutgoingAchFee={setOutgoingAchFee}
							dailyACHDebitLimit={dailyAchDebitLimit}
							setDailyAchDebitLimit={setDailyAchDebitLimit}
							dailyACHCreditLimit={dailyAchCreditLimit}
							setDailyAchCreditLimit={setDailyAchCreditLimit}
							monthlyACHDebitLimit={monthlyAchDebitLimit}
							setMonthlyAchDebitLimit={setMonthlyAchDebitLimit}
							monthlyACHCreditLimit={monthlyAchCreditLimit}
							setMonthlyAchCreditLimit={setMonthlyAchCreditLimit}
							dailyACHDebitSoftLimit={dailyAchDebitSoftLimit}
							setDailyAchDebitSoftLimit={setDailyAchDebitSoftLimit}
							monthlyACHDebitSoftLimit={monthlyAchDebitSoftLimit}
							setMonthlyAchDebitSoftLimit={setMonthlyAchDebitSoftLimit}
							dailyBookDebitLimit={dailyBookDebitLimit}
							setDailyBookDebitLimit={setDailyBookDebitLimit}
							dailyBookCreditLimit={dailyBookCreditLimit}
							setDailyBookCreditLimit={setDailyBookCreditLimit}
							monthlyBookDebitLimit={monthlyBookDebitLimit}
							setMonthlyBookDebitLimit={setMonthlyBookDebitLimit}
							monthlyBookCreditLimit={monthlyBookCreditLimit}
							setMonthlyBookCreditLimit={setMonthlyBookCreditLimit}
							dailyCardWithdrawalLimit={dailyCardWithdrawalLimit}
							setDailyCardWithdrawalLimit={setDailyCardWithdrawalLimit}
							dailyCardDepositLimit={dailyCardDepositLimit}
							setDailyCardDepositLimit={setDailyCardDepositLimit}
							dailyCardPurchaseLimit={dailyCardPurchaseLimit}
							setDailyCardPurchaseLimit={setDailyCardPurchaseLimit}
							dailyCardTransactionLimit={dailyCardTransactionLimit}
							setDailyCardTransactionLimit={setDailyCardTransactionLimit}
							achDebitClearingDays={achDebitClearingDays}
							setACHDebitClearingDays={setACHDebitClearingDays}
							cardIssuingFee={cardIssuingFee}
							setCardIssuingFee={setCardIssuingFee}
							individualBinId={individualBinId}
							setIndividualBinId={setIndividualBinId}
							businessBinId={businessBinId}
							setBusinessBinId={setBusinessBinId}
							cardQualifier={cardQualifier}
							setCardQualifier={setCardQualifier}
							limitGroup={limitGroup}
							setLimitGroup={setLimitGroup}
							maxNumberOfPhysicalCards={maxNumberOfPhysicalCards}
							setMaxNumberOfPhysicalCards={setMaxNumberOfPhysicalCards}
							maxNumberOfVirtualCards={maxNumberOfVirtualCards}
							setMaxNumberOfVirtualCards={setMaxNumberOfVirtualCards}
							orgInterestShare={orgInterestShare}
							setOrgInterestShare={setOrgInterestShare}
							customerInterestShare={customerInterestShare}
							setCustomerInterestShare={setCustomerInterestShare}
							sponsoredInterestFormula={sponsoredInterestFormula}
							setSponsoredInterestFormula={setSponsoredInterestFormula}
							incomingWireFee={incomingWireFee}
							setIncomingWireFee={setIncomingWireFee}
							outgoingWireFee={outgoingWireFee}
							setOutgoingWireFee={setOutgoingWireFee}
							programmaticPurchaseAuthorizationEnabled={programmaticPurchaseAuthorizationEnabled}
							setProgrammaticPurchaseAuthorizationEnabled={setProgrammaticPurchaseAuthorizationEnabled}
							programmaticPurchaseAuthorizationTimeoutStrategy={programmaticPurchaseAuthorizationTimeoutStrategy}
							setProgrammaticPurchaseAuthorizationTimeoutStrategy={setProgrammaticPurchaseAuthorizationTimeoutStrategy}
							programmaticPurchaseAuthorizationIncludeCredit={programmaticPurchaseAuthorizationIncludeCredit}
							setProgrammaticPurchaseAuthorizationIncludeCredit={setProgrammaticPurchaseAuthorizationIncludeCredit}
							programmaticCardTransactionAuthorizationEnabled={programmaticCardTransactionAuthorizationEnabled}
							setProgrammaticCardTransactionAuthorizationEnabled={setProgrammaticCardTransactionAuthorizationEnabled}
							programmaticCardTransactionAuthorizationTimeoutStrategy={
								programmaticCardTransactionAuthorizationTimeoutStrategy
							}
							setProgrammaticCardTransactionAuthorizationTimeoutStrategy={
								setProgrammaticCardTransactionAuthorizationTimeoutStrategy
							}
							programmaticCardTransactionAuthorizationIncludeCredit={
								programmaticCardTransactionAuthorizationIncludeCredit
							}
							setProgrammaticCardTransactionAuthorizationIncludeCredit={
								setProgrammaticCardTransactionAuthorizationIncludeCredit
							}
							programmaticAtmAuthorizationEnabled={programmaticAtmAuthorizationEnabled}
							setProgrammaticAtmAuthorizationEnabled={setProgrammaticAtmAuthorizationEnabled}
							programmaticAtmAuthorizationTimeoutStrategy={programmaticAtmAuthorizationTimeoutStrategy}
							setProgrammaticAtmAuthorizationTimeoutStrategy={setProgrammaticAtmAuthorizationTimeoutStrategy}
							programmaticAdviceAuthorizationEnabled={programmaticAdviceAuthorizationEnabled}
							setProgrammaticAdviceAuthorizationEnabled={setProgrammaticAdviceAuthorizationEnabled}
							checkDepositClearingDays={checkDepositClearingDays}
							setCheckDepositClearingDays={setCheckDepositClearingDays}
							checkDepositMinClearingDays={checkDepositMinClearingDays}
							setCheckDepositMinClearingDays={setCheckDepositMinClearingDays}
							checkDepositMaxClearingDays={checkDepositMaxClearingDays}
							setCheckDepositMaxClearingDays={setCheckDepositMaxClearingDays}
							dailyCheckDepositLimit={dailyCheckDepositLimit}
							setDailyCheckDepositLimit={setDailyCheckDepositLimit}
							monthlyCheckDepositLimit={monthlyCheckDepositLimit}
							setMonthlyCheckDepositLimit={setMonthlyCheckDepositLimit}
							dailyCheckDepositSoftLimit={dailyCheckDepositSoftLimit}
							setDailyCheckDepositSoftLimit={setDailyCheckDepositSoftLimit}
							monthlyCheckDepositSoftLimit={monthlyCheckDepositSoftLimit}
							setMonthlyCheckDepositSoftLimit={setMonthlyCheckDepositSoftLimit}
							isAutoCloseEnabled={isAutoCloseEnabled}
							setIsAutoCloseEnabled={setIsAutoCloseEnabled}
							autoCloseAfter={autoCloseAfter}
							setAutoCloseAfter={setAutoCloseAfter}
							enableOverdraft={enableOverdraft}
							setEnableOverdraft={setEnableOverdraft}
							overdraftDaysLimit={overdraftDaysLimit}
							setOverdraftDaysLimit={setOverdraftDaysLimit}
							dailyWireLimit={dailyWireLimit}
							setDailyWireLimit={setDailyWireLimit}
							monthlyWireLimit={monthlyWireLimit}
							setMonthlyWireLimit={setMonthlyWireLimit}
							dailyWireSoftLimit={dailyWireSoftLimit}
							setDailyWireSoftLimit={setDailyWireSoftLimit}
							monthlyWireSoftLimit={monthlyWireSoftLimit}
							setMonthlyWireSoftLimit={setMonthlyWireSoftLimit}
							dailyCheckPaymentLimit={dailyCheckPaymentLimit}
							setDailyCheckPaymentLimit={setDailyCheckPaymentLimit}
							monthlyCheckPaymentLimit={monthlyCheckPaymentLimit}
							setMonthlyCheckPaymentLimit={setMonthlyCheckPaymentLimit}
							dailyCheckPaymentSoftLimit={dailyCheckPaymentSoftLimit}
							setDailyCheckPaymentSoftLimit={setDailyCheckPaymentSoftLimit}
							monthlyCheckPaymentSoftLimit={monthlyCheckPaymentSoftLimit}
							setMonthlyCheckPaymentSoftLimit={setMonthlyCheckPaymentSoftLimit}
							checkWritingEnabled={checkWritingEnabled}
							setCheckWritingEnabled={setCheckWritingEnabled}
							interestSettings={interestSettings}
							interestSettingsId={interestSettingsId}
							setInterestSettingsId={setInterestSettingsId}
							isOverrideInterestSettingsId={isOverrideInterestSettingsId}
							setIsOverrideInterestSettingsId={setIsOverrideInterestSettingsId}
							enableDynamicClearingDaysAchDebit={enableDynamicClearingDaysAchDebit}
							setEnableDynamicClearingDaysAchDebit={setEnableDynamicClearingDaysAchDebit}
							enableCheckDepositDynamicClearingDays={enableCheckDepositDynamicClearingDays}
							setEnableCheckDepositDynamicClearingDays={setEnableCheckDepositDynamicClearingDays}
							achDebitMinClearingDays={achDebitMinClearingDays}
							setAchDebitMinClearingDays={setAchDebitMinClearingDays}
							achDebitMaxClearingDays={achDebitMaxClearingDays}
							setAchDebitMaxClearingDays={setAchDebitMaxClearingDays}
							isAchDebitFraudPredictEnabled={isAchDebitFraudPredictEnabled}
							setIsAchDebitFraudPredictEnabled={setIsAchDebitFraudPredictEnabled}
							isSweepEnabled={isSweepEnabled}
							setIsSweepEnabled={setIsSweepEnabled}
							checkWritingOriginationEnabled={checkWritingOriginationEnabled}
							setCheckWritingOriginationEnabled={setCheckWritingOriginationEnabled}
							orgInternationalServiceFee={orgInternationalServiceFee}
							setOrgInternationalServiceFee={setOrgInternationalServiceFee}
							additionalVerificationThreshold={additionalVerificationThreshold}
							setAdditionalVerificationThreshold={setAdditionalVerificationThreshold}
							enableCustomerInterestFormula={enableCustomerInterestFormula}
							setEnableCustomerInterestFormula={setEnableCustomerInterestFormula}
							customerInterestFormula={customerInterestFormula}
							setCustomerInterestFormula={setCustomerInterestFormula}
						/>

						<div className="columns">
							<div className="column">
								<div className="card">
									<Footer state={state} />
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</MainSection>
		</>
	)
}

export default function NewDepositProduct() {
	const location = useLocation()
	const accessToken = useAccessToken()
	const [bankId, setBankId] = useState(location.state?.duplicateDepositProduct?.bankId ?? "1")
	const [bankRoutingId, setBankRoutingId] = useState(location.state?.duplicateDepositProduct?.bankRoutingId ?? "1")
	const [interestSettingsId, setInterestSettingsId] = useState(
		location.state?.duplicateDepositProduct?.interestSettingsId
	)

	// TODO: when we will have more than 1000 orgs we will have to support searching
	const orgs = useAsyncResult(() => findOrgs(accessToken, 0, 10000))
	const banks = useAsyncResult(() => findBanks(accessToken, 0, 1000))
	const transactionMonitoringSettings = useAsyncResult(() => findTransactionMonitoringSettings(accessToken, 0, 1000))
	const bankRoutings = useAsyncResult(() => findBankRoutings(accessToken))
	const bins = useAsyncResult(() => findBins(accessToken, 0, 1000))
	const interestSettings = useAsyncResult(() => findInterestSettings(accessToken, 0, 1000))

	return AsyncResult.zip6(orgs, banks, bankRoutings, transactionMonitoringSettings, bins, interestSettings).match(
		() => <Loading />,
		([orgs, banks, bankRoutings, transactionMonitoringSettings, bins, interestSettings]) => (
			<Page
				banks={banks}
				bankRoutings={bankRoutings}
				orgs={orgs}
				bankId={bankId}
				setBankId={setBankId}
				bankRoutingId={bankRoutingId}
				setBankRoutingId={setBankRoutingId}
				transactionMonitoringSettings={transactionMonitoringSettings}
				bins={bins.data}
				interestSettings={interestSettings.data}
				interestSettingsId={interestSettingsId}
				setInterestSettingsId={setInterestSettingsId}
				initialProps={location.state?.duplicateDepositProduct}
			/>
		),
		(_) => null
	)
}
