import {useEffect} from "react"
import {hotjarSetup} from "../utilities/environment"
import {hotjar} from "react-hotjar"

export function useHotjar(condition: boolean) {
	useEffect(() => {
		if (condition) {
			hotjar.initialize(hotjarSetup.id, hotjarSetup.version)
		}
	}, [])
}
