import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {CheckDepositColumns, CheckDeposits} from "../../components/CheckDeposits/CheckDeposits"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"
import {hasPermission} from "../../services/permission"
import {CheckDepositStatus} from "../../resources/checkDeposits"

export function CheckDepositsPage(): ReactElement {
	const isUnitUser = useIsUnitUser()
	return (
		<>
			<TitleBar title={"Check Deposits"} />

			<MainSectionTable>
				<CheckDeposits
					limit={40}
					fullHeight={true}
					includedColumns={[
						CheckDepositColumns.id,
						...(useIsUnitUser() || useIsBankUser() ? [CheckDepositColumns.org] : []),
						...(useIsUnitUser() ? [CheckDepositColumns.vendor] : []),
						CheckDepositColumns.customer,
						CheckDepositColumns.accountId,
						CheckDepositColumns.status,
						CheckDepositColumns.depositDate,
						CheckDepositColumns.expectedCompletionDate,
						CheckDepositColumns.amount,
						...(hasPermission("checkDeposit.approve", "update") ? [CheckDepositColumns.actions] : []),
					]}
					initialStatusFilter={isUnitUser ? [CheckDepositStatus.PendingReview] : []}
				/>
			</MainSectionTable>
		</>
	)
}
