import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {disableOrgBankAgreement, enableOrgBankAgreement} from "../../pages/OrgBankAgreement/OrgBankAgreement"
import {OrgBankAgreement} from "../../resources/orgBankAgreement"

interface ToggleOrgBankAgreementProps {
	orgBankAgreement: OrgBankAgreement
	disable: boolean
	close: () => void
	onSuccess: () => void
}
function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ToggleOrgBankAgreementModal({
	orgBankAgreement,
	disable,
	close,
	onSuccess,
}: ToggleOrgBankAgreementProps) {
	const accessToken = useAccessToken()
	const [state, action] = disable
		? useAsyncResultIdle(disableOrgBankAgreement)
		: useAsyncResultIdle(enableOrgBankAgreement)
	const titleText = disable ? "disable" : "enable"
	const buttonClassname = disable ? "is-danger" : "is-black"
	const buttonText = disable ? "Disable" : "Enable"
	const successText = disable ? "Disabled" : "Enabled"

	return (
		<AsyncResultModal
			title={`You are about to ${titleText} ${orgBankAgreement.attributes.name} \r\n Deposit Product`}
			close={refreshAndClose(onSuccess, close)}
			buttonClassname={buttonClassname}
			state={state}
			buttonText={buttonText}
			successText={successText}
			onSubmit={() => action(accessToken, orgBankAgreement)}
			errorToText={(err) => err.errors[0].title}
		>
			<p>Are you sure you want to continue?</p>
		</AsyncResultModal>
	)
}
