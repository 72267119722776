import React from "react"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"
import classNames from "classnames"
import ReactTooltip from "react-tooltip"

export interface AssignmentItemBadgeProps {
	text: string
	icon?: IcomoonIconName
	theme?: "light" | "dark"
	tooltip?: string
	tooltipKey?: string
}

export function AssignmentItemBadge({
	text,
	icon,
	theme = "light",
	tooltip,
	tooltipKey = "assignment-badge-tooltip",
}: AssignmentItemBadgeProps) {
	return (
		<div
			className={classNames("assignment-item-badge", theme === "dark" && "dark-theme-badge")}
			data-tip={tooltip}
			data-for={tooltipKey}
			id={tooltipKey}
		>
			<div className="icon-text">
				<span>{text}</span>
				{icon && (
					<span className="icon">
						<Icon icon={icon} size={12} />
					</span>
				)}
			</div>
			{tooltip && <ReactTooltip id={tooltipKey} place="top" effect="solid" className="assignment-item-badge-tooltip" />}
		</div>
	)
}
