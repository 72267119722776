import {getResource} from "./common"

export type InterestRate = {
	type: "InterestRate"
	id: string
	attributes: {
		createdAt: Date
		upperBoundRate: number
		lowerBoundRate: number
		effectiveFrom: Date
		effectiveFromFixed: Date
	}
}

export const getDynamicInterestRate = async (accessToken: string) =>
	await getResource<InterestRate>(`interest-rates/dynamic`, accessToken)

export const getFixedInterestRate = async (accessToken: string) =>
	await getResource<InterestRate>(`interest-rates/fixed`, accessToken)

export async function getInterestRateByCalculationMethod(accessToken: string, calculationMethod: string) {
	if (calculationMethod == "Fixed") {
		return await getFixedInterestRate(accessToken)
	}
	return await getDynamicInterestRate(accessToken)
}
