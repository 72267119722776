import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {reActivateCard, BusinessDebitCard, IndividualDebitCard} from "../../resources/card"
import {IndividualCustomer} from "../../resources/customer"
import Admonition from "../Admonitions/Admonition"
import classNames from "classnames"
import Icon from "../Icon/Icon"

interface ReplaceCardModalProps {
	card: BusinessDebitCard | IndividualDebitCard
	close: () => void
	onSuccess: () => void
	customer?: IndividualCustomer
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

function ActivateCardSuccess() {
	return (
		<div className={"replace-card-success"}>
			<div className={"replace-card-success-icon-placeholder"}>
				<Icon icon={"credit-card-check-approved--business-products"} size={32} />
			</div>
			<h3>Card activated successfully!</h3>
		</div>
	)
}

export function ActivateCardModal({card, close, onSuccess}: ReplaceCardModalProps) {
	const accessToken = useAccessToken()
	const [replaceState, setReplaceState] = useAsyncResultIdle(reActivateCard)
	const [isResult, receiveResult] = useState(false)

	return (
		<AsyncResultModal
			title="Activate Card"
			classname={classNames("card-replacement-modal", isResult && "card-replacement-modal-final")}
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-black"
			blockBodyScroll={true}
			state={replaceState}
			okComponent={<ActivateCardSuccess />}
			disableCloseOnSuccess={true}
			buttonText="Activate Card"
			successText="Done"
			enableClickAway={false}
			onSubmit={() => {
				setReplaceState(accessToken, card).then(() => {
					receiveResult(true)
				})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<Admonition
				type={"is-warning"}
				message={
					<p>
						{" "}
						The card has been flagged for high fraud risk. You should only reactivate it if you have confirmed with the
						cardholder that all previous activity on the card is not fraudulent. Are you sure you want to reactivate the
						card?{" "}
					</p>
				}
				title={"Note"}
			/>
		</AsyncResultModal>
	)
}
