import {isUndefined, startCase, uniq} from "lodash"
import numeral from "numeral"
import React, {useEffect, useRef, useState} from "react"
import {useModal} from "react-modal-hook"
import {useNavigate, useParams} from "react-router-dom"
import {useToasts} from "react-toast-notifications"
import {Adjustments, AdjustmentsColumns} from "../../components/Adjustments/Adjustments"
import {
	AccountAuthorizations,
	AccountAuthorizationsColumns,
} from "../../components/AccountAuthorizations/AccountAuthorizations"
import AccountCards, {AccountCardsColumns} from "../../components/AccountCards/AccountCards"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import {EditableDepositProductField} from "../../components/DepositProductField/DepositProductField"
import HorizontalField, {OptionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import {accountNumberPreview, InformationHiding} from "../../components/InformationHiding/InformationHiding"
import TagsViewer from "../../components/Tags/TagsViewer"
import TitleBar, {MetaItemProps, TitleBarAction} from "../../components/TitleBar/TitleBar"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {MainSection} from "../../containers/MainSection/MainSection"
import {
	UnitAdminOrgAdminBankOperationsOnly,
	UnitOrBankUserOnly,
	UnitUserOnly,
} from "../../containers/PermissionedUser/PermissionedUser"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {
	Account,
	AccountStatusAudit,
	addSecondaryAccountNumber,
	BankReason,
	CloseReason,
	CreditAccount,
	DacaStatusType,
	DepositAccount,
	FinancialBusinessFBOAccount,
	FraudReason,
	getAccountLimits,
	getAccountStatusAudit,
	getAccountWithOwners,
	isBankInAccount,
	isCreditAccount,
	isCreditOperationalAccount,
	isDepositAccount,
	isDepositOrBatchOrFBOAccount,
	isDepositOrCreditOrFBOAccountOrWallet,
	isDepositOrFBOAccount,
	isFrozen,
	isWalletAccount,
	updateDepositAccount,
	Wallet,
} from "../../resources/account"
import {Customer, CustomerType, CustomerTypeIcons, IndividualCustomer} from "../../resources/customer"
import {useAccessToken, useIsBankUser, useIsOrgAdmin, useIsUnitUser} from "../../services/auth"
import {CloseAccountModal} from "./CloseAccount"
import {CreateAdjustmentModal} from "./CreateAdjustment"
import {AccountStatements} from "../../components/Statements/AccountStatements"
import {FreezeAccountModal} from "./FreezeAccount"
import classNames from "classnames"
import {Editable, EditableString} from "../../components/HorizontalField/EditableHorizontalField"
import {hasPermission} from "../../services/permission"
import {WithdrawFromAccountModal} from "../../components/WithdarwFromAccountModal/WithdrawFromAccountModal"
import {getBankVerificationPdf} from "../../resources/accountStatement"
import {downloadFile} from "../../utilities/file"
import Drawer from "../../components/Drawer/Drawer"
import AccountLimits from "../../components/AccountLimits/AccountLimits"
import ReactTooltip from "react-tooltip"
import BodyPortal from "../../components/BodyPortal/BodyPortal"
import Payments, {PaymentColumns} from "../../components/Payments/Payments"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import Icon from "../../components/Icon/Icon"
import {TutorialButton} from "../../components/Tutorial/TutorialButton"
import {TutorialActionProps, TutorialModalProps} from "../../components/Tutorial/TutorialModal"
import {generateCreateCardRequest, generateIncomingAchRequest} from "../../components/Tutorial/TutorialPayloads"
import {AccountOwners} from "../../components/AccountOwners/AccountOwners"
import moment from "moment"
import {CreditAccountSummary} from "./CreditAccountSummary"
import {CreditOperationalAccountSummary} from "./CreditOperationalAccountSummary"
import {getBankDetails} from "../../utilities/getBankDetails"
import {AccountStatusField} from "./AccountStatusField"
import {BankNameAndLogo} from "../../components/BankNameAndLogo/BankNameAndLogo"
import {DacaStatusAction, DacaStatusModal} from "./DacaStatus"
import {OriginateWireModal} from "./OriginateWire"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import {AuditLogEventsHeaderMetaItemProps} from "../../components/AuditLogEventsHeader/AuditLogEventsHeader"
import {IcomoonIconName} from "../../components/Icon/icons"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"
import {OrgName} from "../../components/OrgName/OrgName"
import {BankName} from "../../components/BankName/BankName"

type RefreshFunc = () => void

function AccountCardsInternal({
	account,
	accessToken,
	refreshToken,
	enableTutorial,
}: {
	account: Account
	accessToken: string
	refreshToken: number
	enableTutorial: boolean
}): JSX.Element | null {
	const accountCards = (
		<AccountCards
			includedColumns={[
				AccountCardsColumns.id,
				AccountCardsColumns.last4Digits,
				AccountCardsColumns.cardHolder,
				AccountCardsColumns.expiration,
				AccountCardsColumns.status,
				AccountCardsColumns.cardType,
				AccountCardsColumns.createdAt,
				AccountCardsColumns.updatedAt,
				AccountCardsColumns.actions,
			]}
			accountId={account.id}
			accessToken={accessToken}
			externalRefreshToken={refreshToken}
			enableTutorial={enableTutorial}
		/>
	)

	if (isDepositOrCreditOrFBOAccountOrWallet(account)) {
		return accountCards
	}
	return null
}

export function BankLogoTitle({title, bankId}: {title: string; bankId: string}) {
	const {name, logo} = getBankDetails(bankId)

	return (
		<div className="flex-container w-100 justify-content-sb gap-10 card-header-title">
			<p className="flex-container font-14 line-h-1-2 align-items-end">{title}</p>
			<div className="flex-container font-14 align-items-center line-h-1-3">
				<BankNameAndLogo name={name} logo={logo} />
			</div>
		</div>
	)
}

function AccountHeader({account}: {account: Account}) {
	if (isCreditOperationalAccount(account) || isCreditAccount(account)) {
		return <BankLogoTitle title="Account Information" bankId={account?.relationships?.bank?.data?.id} />
	}

	return <p className="card-header-title">Account Information</p>
}

function AccountSummary({account}: {account: Account}) {
	if (isCreditOperationalAccount(account)) {
		return <CreditOperationalAccountSummary account={account} />
	}

	if (isCreditAccount(account)) {
		return <CreditAccountSummary account={account} />
	}

	return null
}

function AccountInternal({
	value,
	accessToken,
	refresh,
	isOrgAccount,
	accountStatusAudit,
}: {
	value: {account: Account; included: Array<Customer>}
	accessToken: string
	refresh: RefreshFunc
	isOrgAccount: boolean
	accountStatusAudit: AccountStatusAudit | undefined
}) {
	const navigate = useNavigate()
	const account = value.account
	const updatedAt = account.attributes.updatedAt
	const {attributes} = account
	const isIndividualAccount = value.included?.filter((i) => i.type == "individualCustomer").length > 0
	const accountName = attributes.name
	const isReserve = isCreditOperationalAccount(account)
	const individuals: Array<IndividualCustomer> = value.included
		?.filter((c) => c.type == "individualCustomer")
		.map((c) => c as IndividualCustomer)

	const [showCloseAccount, hideCloseAccount] = useModal(() => (
		<CloseAccountModal closeModal={hideCloseAccount} account={account} onSuccess={refresh} />
	))

	const [showReopenAccount, hideReopenAccount] = useModal(() => (
		<CloseAccountModal closeModal={hideReopenAccount} account={account} onSuccess={refresh} />
	))

	const [showFreezeAccount, hideFreezeAccount] = useModal(() => (
		<FreezeAccountModal closeModal={hideFreezeAccount} account={account} onSuccess={refresh} />
	))

	const [showCreateAdjustment, hideCreateAdjustment] = useModal(() => (
		<CreateAdjustmentModal closeModal={hideCreateAdjustment} account={account} onSuccess={refresh} />
	))

	const [showWithdrawModal, hideWithdrawModal] = useModal(
		() =>
			isDepositOrFBOAccount(account) ? (
				<WithdrawFromAccountModal
					account={account}
					accessToken={accessToken}
					close={() => {
						hideWithdrawModal()
						refresh()
					}}
				/>
			) : null,
		[]
	)

	const [showEnterDaca, hideEnterDaca] = useModal(() => (
		<DacaStatusModal action={DacaStatusAction.Enter} closeModal={hideEnterDaca} account={account} onSuccess={refresh} />
	))

	const [showTerminateDaca, hideTerminateDaca] = useModal(() => (
		<DacaStatusModal
			action={DacaStatusAction.Terminate}
			closeModal={hideTerminateDaca}
			account={account}
			onSuccess={refresh}
		/>
	))

	const [showActivateDaca, hideActivateDaca] = useModal(() => (
		<DacaStatusModal
			action={DacaStatusAction.Activate}
			closeModal={hideActivateDaca}
			account={account}
			onSuccess={refresh}
		/>
	))

	const [showDeactivateDaca, hideDeactivateDaca] = useModal(() => (
		<DacaStatusModal
			action={DacaStatusAction.Deactivate}
			closeModal={hideDeactivateDaca}
			account={account}
			onSuccess={refresh}
		/>
	))

	const [showOriginateWire, hideOriginateWire] = useModal(() => (
		<OriginateWireModal closeModal={hideOriginateWire} account={account} onSuccess={refresh} />
	))

	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)
	const {addToast} = useToasts()
	const [updateState, updateApp] = useAsyncResultIdle(updateDepositAccount)
	const [pdfResultState, getPdfRequest] = useAsyncResultIdle(getBankVerificationPdf)

	const [name, setName] = useState(attributes.name)

	const [isDrawerOpen, setIsDrawerOpen] = useState(false)

	const canUpdateAccount = (account: Account) => {
		switch (account.type) {
			case "batchAccount":
				return hasPermission("account.batch.status", "update")
			default:
				return hasPermission("account.status", "update")
		}
	}

	const toggleDrawer = () => {
		setIsDrawerOpen((prevState) => !prevState)
	}

	const [fundPayload, setFundPayload] = useState(generateIncomingAchRequest(account.id))
	const [cardPayload, setCardPayload] = useState(generateCreateCardRequest(account.id))
	const [refreshTransactionToken, refreshTransaction] = useRefreshToken()
	const [refreshCardsToken, refreshCards] = useRefreshToken()
	const ref = useRef(null)
	const tutorialProps: TutorialModalProps = {
		tooltipId: "accountTutorial",
		parentRef: ref,

		actions: [
			{
				actionName: "Fund Account",
				actionIcon: "coin-bank-note--business-products",
				actionIconClassname: "fund-account",
				title: "Simulate an Incoming ACH",
				description: "Simulate an incoming ACH to receive funds into your account",
				successMessage: "Success! You can now see your new funds in the dashboard",
				errorMessage: "There was an error receiving the funds",
				request: {
					method: "POST",
					path: "sandbox/payments",
					payload: fundPayload,
					headers: [
						{headerKey: "Content-Type", headerValue: "application/vnd.api+json"},
						{headerKey: "Cache-Control", headerValue: "no-cache"},
					],
				},
				refreshFunction: refreshTransaction,
				onSendAnotherRequest: () => {
					setFundPayload(generateIncomingAchRequest(account.id))
				},
			},
			...(isIndividualAccount
				? [
						{
							actionName: "Create Card",
							actionIcon: "credit-card-add",
							actionIconClassname: "create-card",
							title: "Test Create Card API",
							description: "Create a debit card for the account",
							successMessage: "Success! You can now see your new card in the dashboard",
							errorMessage: "There was an error creating the new card",
							request: {
								method: "POST",
								path: "cards",
								payload: cardPayload,
								headers: [
									{headerKey: "Content-Type", headerValue: "application/vnd.api+json"},
									{headerKey: "Cache-Control", headerValue: "no-cache"},
								],
							},
							refreshFunction: refreshCards,
							onSendAnotherRequest: () => {
								setCardPayload(generateCreateCardRequest(account.id))
							},
						} as TutorialActionProps,
				  ]
				: []),
		],
	}

	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Account Updated Successfully", {appearance: "success"})
			setIsUpdating(false)
			refresh()
		} else if (updateState.isErr()) {
			const error = updateState.error.errors[0]
			const msg = error.detail ? error.detail : error.title
			addToast(msg, {appearance: "warning"})
			setIsUpdating(false)
		}
	}, [updateState])

	useEffect(() => {
		pdfResultState.match(
			() => null,
			() => null,
			(pdf) => downloadFile(`Bank Verification Letter - ${account.attributes.name}.pdf`, pdf, "application/pdf"),
			(e) => {
				if (e.errors.length > 0) {
					if (e.errors[0].title == "statements settings - representative name is missing") {
						addToast(
							<span>
								<a
									href="/org-settings#tab=Statements"
									onClick={(e) => {
										e.preventDefault()
										navigate("/org-settings#tab=Statements")
									}}
								>
									statements settings
								</a>{" "}
								- representative name is missing
							</span>,
							{appearance: "warning"}
						)
					} else {
						addToast("Something went wrong", {appearance: "warning"})
					}
				} else {
					addToast("Something went wrong", {appearance: "warning"})
				}
			}
		)
	}, [pdfResultState])

	const DepositProductsField = () => {
		if (isDepositOrBatchOrFBOAccount(account)) {
			const [depositProduct, setDepositProduct] = useState(account.attributes.depositProduct)
			return (
				<EditableDepositProductField
					depositProduct={depositProduct}
					setDepositProduct={setDepositProduct}
					accountId={account.id}
					isEditing={isEditing === "DepositProduct"}
					isDisabled={!isUndefined(isEditing)}
					isAllowed={hasPermission("account.depositProduct", "update")}
					onStartEdit={() => setIsEditing("DepositProduct")}
					onCancel={refresh}
					update={() => {
						updateApp(accessToken, account.id, {depositProduct})
						setIsUpdating(true)
					}}
				/>
			)
		} else return null
	}

	function isOrgRevenueAccount() {
		return isOrgAccount && isDepositOrFBOAccount(account) && account.attributes.tags?.purpose == "revenue"
	}

	function isJointAccount() {
		return (
			isDepositAccount(account) &&
			account.relationships.customers &&
			account.relationships.customers?.data.length > 0 &&
			value.included?.length > 1
		)
	}

	function getCloseReason(closeReason: string, fraudReason?: FraudReason, bankReason?: BankReason) {
		switch (closeReason) {
			case CloseReason.Fraud:
				return `Fraud - ${startCase(fraudReason)}`
			case CloseReason.ByBank:
				return `By Bank - ${startCase(bankReason)}`
			default:
				return startCase(closeReason)
		}
	}

	function getCustomerType(customerId: string) {
		const customer = value.included.find((c) => c.id == customerId)
		if (customer) {
			switch (customer.type) {
				case "businessCustomer":
					return "business"
				case "individualCustomer":
					return "individual"
				case "businessFBOCustomer":
					return "businessFBO"
				case "individualWalletCustomer":
					return "individualWallet"
			}
		}
	}

	function getCustomerName(customerId: string) {
		const customer = value.included.find((c) => c.id == customerId)

		if (customer) {
			return customer.type === "businessCustomer" || customer.type === "businessFBOCustomer"
				? customer.attributes.name
				: `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		}
	}

	const getCustomerDataArray = (account: DepositAccount | CreditAccount | FinancialBusinessFBOAccount | Wallet) => {
		switch (account.type) {
			case "depositAccount":
				return [account.relationships.customer?.data ?? [], account.relationships?.customers?.data ?? []]
			case "creditAccount":
				return [account.relationships.customer?.data ?? []]
			case "financialBusinessFBOAccount":
				return [account.relationships.customer.data]
			case "walletAccount":
				return [account.relationships.customer?.data ?? []]
		}
	}

	const customerIds =
		!isOrgAccount && (isDepositOrFBOAccount(account) || isCreditAccount(account) || isWalletAccount(account))
			? uniq(
					getCustomerDataArray(account)
						.flat()
						.map((c) => c.id.toString())
			  )
			: []
	const customerNames: MetaItemProps[] = customerIds.map((id, index) => ({
		path: `/customers/${getCustomerType(id)}/${id}`,
		text: getCustomerName(id),
		icon: index == 0 ? "user-geometric-action-money-fund---users" : undefined,
	}))
	const firstCustomerType = customerIds.length > 0 ? getCustomerType(customerIds[0]) : undefined
	const showStatements = !isWalletAccount(account)
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()
	const isUnitOrBankUser = isUnitUser || isBankUser
	const auditLogEventsHeaderMetaItems: AuditLogEventsHeaderMetaItemProps[] = [
		{
			text: accountName,
			icon: "bank-account--business-products",
			tooltipText: "Name",
		},
		...(firstCustomerType
			? [
					{
						text: startCase(firstCustomerType),
						icon: CustomerTypeIcons[firstCustomerType as CustomerType],
						tooltipText: "Customer Type",
					},
			  ]
			: []),
		{
			text: startCase(account.type),
			icon: "bank",
			tooltipText: "Account Type",
		},
		...(isUnitOrBankUser && isDepositOrBatchOrFBOAccount(account)
			? [
					{
						text: <OrgName orgId={account.relationships?.org.data.id.toString()} element={"span"} />,
						icon: "user-hierachy2" as IcomoonIconName,
						tooltipText: "Org Name",
					},
			  ]
			: []),
	]

	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()
	return (
		<>
			<TitleBar title={accountName} breadcrumbs={[{text: "Accounts", path: "/accounts/"}]} meta={[...customerNames]}>
				{isDepositOrFBOAccount(account) ? (
					<>
						<a className="account-limits-link" href="#" onClick={toggleDrawer}>
							<AccountLimitsStatus accountId={account.id} accessToken={accessToken} />
							<span>Review account limits</span>
						</a>
					</>
				) : null}
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				<TitleBarAction>
					<ActionButton enableActions={true}>
						<UnitAdminOrgAdminBankOperationsOnly>
							{isDepositOrFBOAccount(account) && !account.attributes.dacaStatus && (
								<ActionItem title="Enter DACA" icon="bank" onClick={showEnterDaca} />
							)}
							{isDepositOrFBOAccount(account) && account.attributes.dacaStatus === DacaStatusType.Entered && (
								<ActionItem title="Terminate DACA" icon="bank" onClick={showTerminateDaca} />
							)}
							{isDepositOrFBOAccount(account) && account.attributes.dacaStatus === DacaStatusType.Entered && (
								<ActionItem
									title="Activate DACA"
									icon="lock-square-lock-1--interface-essential"
									onClick={showActivateDaca}
								/>
							)}
							{isDepositOrFBOAccount(account) && account.attributes.dacaStatus === DacaStatusType.Activated && (
								<>
									<ActionItem
										title="Deactivate DACA"
										icon="lock-square-unlock-1--interface-essential"
										onClick={showDeactivateDaca}
									/>
									<ActionItem
										onClick={showOriginateWire}
										title="Originate Wire"
										icon="money-fund-move-found--business-products"
									/>
								</>
							)}
						</UnitAdminOrgAdminBankOperationsOnly>

						{isOrgRevenueAccount() && useIsOrgAdmin() ? (
							<ActionItem title={"Withdraw"} icon={"payment-coin"} onClick={() => showWithdrawModal()} />
						) : null}

						{isDepositOrFBOAccount(account) && account.relationships.org.data.id === "2" ? (
							<ActionItem
								title={"Add Secondary Account#"}
								icon={"hierarchy-square"}
								onClick={() => addSecondaryAccountNumber(accessToken, account.id, "3").then(() => refresh())}
							/>
						) : null}

						<UnitUserOnly>
							<ActionItem
								title={"Create Adjustment"}
								icon={"scale--business-products"}
								onClick={() => showCreateAdjustment()}
							/>
						</UnitUserOnly>

						{canUpdateAccount(account) && (
							<>
								{attributes.status === "Closed" && !isCreditAccount(account) ? (
									<UnitOrBankUserOnly>
										<ActionItem
											title={"Reopen Account"}
											data-tip
											data-iscapture={true}
											data-for={"reopen-account"}
											icon={"undo-arrow--interface-essential"}
											onClick={() => showReopenAccount()}
										/>
									</UnitOrBankUserOnly>
								) : (
									<>
										<ActionItem
											data-tip
											data-iscapture={true}
											data-for={isFrozen(account) ? "unfreeze-account" : "freeze-account"}
											title={isFrozen(account) ? "Unfreeze Account" : "Freeze Account"}
											icon={"temperature-snow-flake--weather"}
											onClick={() => showFreezeAccount()}
										/>
										<ActionItem
											title={"Close Account"}
											color={"#FF506F"}
											data-tip
											data-iscapture={true}
											data-for={"close-account"}
											icon={"interface-delete-interface-essential"}
											onClick={() => showCloseAccount()}
										/>
									</>
								)}
							</>
						)}

						{isDepositOrFBOAccount(account) ? (
							<ActionItem
								title={"Download Bank Verification"}
								withTopBorder
								icon={"move-snap-down--interface-essential"}
								onClick={() => getPdfRequest(accessToken, account.id)}
							/>
						) : null}
					</ActionButton>
				</TitleBarAction>
				{isDepositOrBatchOrFBOAccount(account) ? (
					<>
						<TutorialButton tutorialModalProps={tutorialProps} buttonClassname="space-left" />
					</>
				) : null}
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="account"
					resourceId={account.id}
				/>
				<div className="columns">
					<div className="column is-4">
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<AccountHeader account={account} />
									</div>
									<div className="card-content flex-container flex-dir-col gap-15">
										<AccountSummary account={account} />
										{isCreditAccount(account) && <OptionalHorizontalField title="Type" value="Credit Account" />}
										<OptionalHorizontalField title="ID" attribute={account.id} />
										{isUnitUser && isBankInAccount(account) && (
											<HorizontalField label="Bank">
												<BankName bankId={account.relationships?.bank?.data?.id.toString()} />
											</HorizontalField>
										)}
										{isDepositOrBatchOrFBOAccount(account) && (
											<UnitOrBankUserOnly>
												<HorizontalField label="Org">
													<OrgName orgId={account.relationships?.org.data.id.toString()} element={"span"} />
												</HorizontalField>
											</UnitOrBankUserOnly>
										)}
										<Editable
											label="Name"
											isEditing={isEditing === "Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={hasPermission("account.name", "update") && isDepositOrBatchOrFBOAccount(account)}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Name")}
											onCancel={refresh}
											setValue={setName}
											value={name}
											initial={name}
											update={() => {
												updateApp(accessToken, account.id, {name})
												setIsUpdating(true)
											}}
										>
											{EditableString}
										</Editable>
										<OptionalHorizontalField title="Created At" attribute={moment(attributes.createdAt).format("L")} />
										<OptionalHorizontalField
											title="Updated At"
											attribute={updatedAt ? moment(updatedAt).format("L") : ""}
										/>
										<AccountStatusField status={attributes.status} accountStatusAudit={accountStatusAudit} />
										{isDepositOrFBOAccount(account) && (
											<OptionalHorizontalField
												title="DACA"
												attribute={isDepositOrFBOAccount(account) ? account.attributes.dacaStatus : ""}
												icon={
													account.attributes.dacaStatus === DacaStatusType.Activated
														? "alert-circle--interface-essential"
														: null
												}
												className={account.attributes.dacaStatus === DacaStatusType.Activated ? "has-text-danger" : ""}
											/>
										)}
										<OptionalHorizontalField
											title="Close Reason"
											attribute={
												attributes.closeReason
													? getCloseReason(attributes.closeReason, attributes.fraudReason, attributes.bankReason)
													: undefined
											}
										/>
										<OptionalHorizontalField
											title="Close Notes"
											attribute={attributes.closeReasonText ? attributes.closeReasonText : undefined}
											showTooltip={true}
										/>
										<OptionalHorizontalField
											title="Freeze Reason"
											attribute={attributes.freezeReason ? attributes.freezeReason : undefined}
										/>
										<OptionalHorizontalField
											title="Balance"
											attribute={numeral(attributes.balance / 100).format("$0,0.00")}
											className={attributes.balance >= 0 ? "" : "has-text-danger"}
										/>
										<OptionalHorizontalField
											title="Hold"
											attribute={isReserve ? undefined : numeral(attributes.hold / 100).format("$0,0.00")}
										/>
										<OptionalHorizontalField
											title="Withheld"
											attribute={isReserve ? numeral(attributes.reserve / 100).format("$0,0.00") : undefined}
										/>
										<OptionalHorizontalField
											title="Available"
											attribute={
												isDepositOrFBOAccount(account)
													? numeral(account.attributes.available / 100).format("$0,0.00")
													: undefined
											}
											className={
												isDepositOrFBOAccount(account) && account.attributes.available >= 0 ? "" : "has-text-danger"
											}
										/>
										<OptionalHorizontalField
											title="Overdraft Limit"
											attribute={
												isDepositOrFBOAccount(account) && !isUndefined(account.attributes.overdraftLimit)
													? numeral(account.attributes.overdraftLimit / 100).format("$0,0.00")
													: undefined
											}
										/>
										{isDepositOrBatchOrFBOAccount(account) ? DepositProductsField() : undefined}
										<OptionalHorizontalField
											title="Routing Number"
											attribute={isDepositOrBatchOrFBOAccount(account) ? account.attributes.routingNumber : undefined}
										/>
										{isDepositOrBatchOrFBOAccount(account) ? (
											account.attributes.accountNumber ? (
												<HorizontalField label="Account Number">
													<InformationHiding
														element={(accountNumber) => (
															<input type="text" readOnly value={accountNumber} className="input is-static" />
														)}
														getValue={() => account.attributes.accountNumber ?? ""}
														placeholder={accountNumberPreview(account.attributes.accountNumber)}
													/>
												</HorizontalField>
											) : account.attributes.maskedAccountNumber ? (
												<OptionalHorizontalField
													title="Account Number"
													attribute={account.attributes.maskedAccountNumber.replace(/[*]/g, "●")}
												/>
											) : null
										) : null}
										{isDepositOrFBOAccount(account) &&
										account.attributes.secondaryAccountNumber &&
										account.relationships.org.data.id === "2" ? (
											<OptionalHorizontalField
												title="Secondary Routing Number"
												attribute={
													isDepositOrFBOAccount(account)
														? account.attributes.secondaryAccountNumber?.routingNumber
														: undefined
												}
											/>
										) : null}
										{isDepositOrFBOAccount(account) &&
										account.attributes.secondaryAccountNumber &&
										account.relationships.org.data.id === "2" ? (
											<HorizontalField label="Secondary Account Number">
												<InformationHiding
													element={(accountNumber) => (
														<input type="text" readOnly value={accountNumber} className="input is-static" />
													)}
													getValue={() => account.attributes.secondaryAccountNumber?.accountNumber ?? ""}
													placeholder={accountNumberPreview(account.attributes.secondaryAccountNumber.accountNumber)}
												/>
											</HorizontalField>
										) : null}
										<OptionalHorizontalField
											title="Currency"
											attribute={isDepositOrBatchOrFBOAccount(account) ? account.attributes.currency : undefined}
										/>
										<OptionalHorizontalField
											title="Purpose"
											attribute={isDepositOrFBOAccount(account) ? account.attributes?.tags?.purpose : undefined}
										/>
									</div>
								</div>
							</div>
						</div>
						{isDepositOrFBOAccount(account) && Object.keys(account.attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={account.attributes.tags} />
										</div>
									</div>
								</div>
							</div>
						) : null}

						{isJointAccount() && (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Account Owners</p>
										</div>
										<div className="card-content">
											<AccountOwners accountOwners={individuals} />
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="column">
						<AccountCardsInternal
							account={account}
							accessToken={accessToken}
							refreshToken={refreshCardsToken}
							enableTutorial={isIndividualAccount}
						/>

						<Transactions
							fullHeight={false}
							accountId={account.id}
							limit={10}
							enableSearch={true}
							token={accessToken}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.type,
								TransactionsColumns.customer,
								TransactionsColumns.balance,
								TransactionsColumns.summary,
								TransactionsColumns.amount,
								TransactionsColumns.createdAt,
							]}
							refreshToken={refreshTransactionToken}
						/>

						{hasPermission("authorization", "get") && (
							<AccountAuthorizations
								accountId={account.id}
								limit={10}
								token={accessToken}
								refreshPage={refresh}
								includedColumns={[
									AccountAuthorizationsColumns.id,
									AccountAuthorizationsColumns.last4Digits,
									AccountAuthorizationsColumns.merchantName,
									AccountAuthorizationsColumns.status,
									AccountAuthorizationsColumns.reason,
									AccountAuthorizationsColumns.createdAt,
									AccountAuthorizationsColumns.amount,
									...(useIsUnitUser() ? [AccountAuthorizationsColumns.actions] : []),
								]}
							/>
						)}

						<Payments
							enableDirectionFilter={true}
							enableTypeFilter={true}
							enableTitle={true}
							fullHeight={false}
							accountId={account.id}
							limit={10}
							disableDrawer={true}
							token={accessToken}
							includedColumns={[
								PaymentColumns.id,
								PaymentColumns.type,
								PaymentColumns.accountId,
								PaymentColumns.customer,
								PaymentColumns.amount,
								PaymentColumns.status,
								PaymentColumns.createdAt,
								...(hasPermission("payment.achDebit.clear", "create") ? [PaymentColumns.actions] : []),
							]}
						/>
						<UnitUserOnly>
							<Adjustments
								enableTitle={true}
								accountId={account.id}
								fullHeight={false}
								enableUpload={false}
								includedColumns={[
									AdjustmentsColumns.id,
									AdjustmentsColumns.customer,
									AdjustmentsColumns.direction,
									AdjustmentsColumns.amount,
									AdjustmentsColumns.status,
									AdjustmentsColumns.user,
									AdjustmentsColumns.description,
									AdjustmentsColumns.note,
									AdjustmentsColumns.createdAt,
									AdjustmentsColumns.action,
								]}
							/>
						</UnitUserOnly>
						{showStatements && <AccountStatements accountId={account.id} limit={5} token={accessToken} />}
					</div>
				</div>
			</MainSection>

			<Drawer
				open={isDrawerOpen}
				onClose={toggleDrawer}
				direction="right"
				className="account-side-drawer"
				title={"Account Limits"}
			>
				{isDrawerOpen ? <AccountLimits accountId={account.id} accessToken={accessToken} /> : null}
			</Drawer>

			<BodyPortal>
				<ReactTooltip
					arrowColor="#FFF"
					place="bottom"
					type="light"
					effect="solid"
					id={"freeze-account"}
					className="account-button-tooltip"
				>
					<p> Freezing the account will freeze all active cards under that account </p>
				</ReactTooltip>

				<ReactTooltip
					arrowColor="#FFF"
					place="bottom"
					type="light"
					effect="solid"
					id={"unfreeze-account"}
					className="account-button-tooltip"
				>
					<p> Unfreezing an account will unfreeze all frozen cards associated with that account </p>
				</ReactTooltip>

				<ReactTooltip
					arrowColor="#FFF"
					place="bottom"
					type="light"
					effect="solid"
					id={"close-account"}
					className="account-button-tooltip"
				>
					<p> Closing the account will close all cards under that account. Closed cards cannot be re-opened </p>
				</ReactTooltip>

				<ReactTooltip
					arrowColor="#FFF"
					place="bottom"
					type="light"
					effect="solid"
					id={"reopen-account"}
					className="account-button-tooltip"
				>
					<p> Reopening an account will not reopen closed cards </p>
				</ReactTooltip>
			</BodyPortal>
		</>
	)
}

export function AccountComponent({
	accountId,
	accessToken,
	isOrgAccount = false,
}: {
	accountId: string
	accessToken: string
	isOrgAccount?: boolean
}) {
	const [refreshToken, refresh] = useRefreshToken()
	const accountAsyncResult = useAsyncResult(() => getAccountWithOwners(accessToken, accountId), [refreshToken])
	const accountStatusAuditAsyncResult = useAsyncResult(
		() => getAccountStatusAudit(accessToken, accountId),
		[refreshToken]
	)
	const accountStatusAudit = accountStatusAuditAsyncResult.match<AccountStatusAudit | undefined>(
		() => undefined,
		(a) => a,
		() => undefined
	)

	return (
		<AsyncResultComponent
			asyncResult={accountAsyncResult}
			accessToken={accessToken}
			isOrgAccount={isOrgAccount}
			accountStatusAudit={accountStatusAudit}
		>
			{({value: value}) => {
				const included = value.included as Array<IndividualCustomer>
				const account = value.account as Account
				return (
					<AccountInternal
						value={{account, included}}
						accessToken={accessToken}
						refresh={refresh}
						isOrgAccount={isOrgAccount}
						accountStatusAudit={accountStatusAudit}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}

export default function AccountPage() {
	const accessToken = useAccessToken()
	const {accountId = ""} = useParams<{accountId: string}>()

	return <AccountComponent accountId={accountId} accessToken={accessToken} />
}

export function AccountLimitsStatus({accountId, accessToken}: {accountId: string; accessToken: string}) {
	const accountLimits = useAsyncResult(() => getAccountLimits(accessToken, accountId))

	function selectStatusColor(limit: number, usage: number, soft = limit): string {
		if (limit && usage) {
			if (soft != limit && usage > soft && usage / limit < 0.85) "account-limits-reach-soft-limit"
			if (usage / limit > 0.85) return "account-limits-reach-hard-limit"
		}

		return ""
	}

	return (
		<AsyncResultComponent asyncResult={accountLimits} pendingComponent={<></>}>
			{({value: limits}) => {
				const ach = limits.attributes.ach
				const card = limits.attributes.card
				const check = limits.attributes.checkDeposit
				const limitColorsSet = new Set()

				limitColorsSet.add(selectStatusColor(ach.limits.dailyDebit, ach.totalsDaily.debits, ach.limits.dailyDebitSoft))
				limitColorsSet.add(selectStatusColor(ach.limits.dailyCredit, ach.totalsDaily.credits))
				limitColorsSet.add(
					selectStatusColor(ach.limits.monthlyDebit, ach.totalsMonthly.debits, ach.limits.monthlyDebitSoft)
				)
				limitColorsSet.add(selectStatusColor(ach.limits.monthlyCredit, ach.totalsMonthly.credits))

				limitColorsSet.add(selectStatusColor(card.limits.dailyCardTransaction, card.totalsDaily.cardTransactions))
				limitColorsSet.add(selectStatusColor(card.limits.dailyPurchase, card.totalsDaily.purchases))
				limitColorsSet.add(selectStatusColor(card.limits.dailyWithdrawal, card.totalsDaily.withdrawals))
				limitColorsSet.add(selectStatusColor(card.limits.dailyDeposit, card.totalsDaily.deposits))

				limitColorsSet.add(selectStatusColor(check.limits.daily, check.totalsDaily, check.limits.dailySoft))
				limitColorsSet.add(selectStatusColor(check.limits.monthly, check.totalsMonthly, check.limits.monthlySoft))

				return (
					<div className={"account-limits-total-status-container"}>
						<Icon icon={"icon-settings-slider-horizontal"} size={16} />
						<div
							className={classNames(
								"account-limits-total-status",
								limitColorsSet.has("account-limits-reach-soft-limit") && "account-limits-reach-soft-limit",
								limitColorsSet.has("account-limits-reach-hard-limit") && "account-limits-reach-hard-limit"
							)}
						/>
					</div>
				)
			}}
		</AsyncResultComponent>
	)
}
