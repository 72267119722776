import React from "react"
import {TextAreaWithAssigneeAvatar} from "../TextAreaWithAssigneeAvatar/TextAreaWithAssigneeAvatar"
import {Assignee, CallbackDocumentMetadata} from "../../resources/assignments"
import {Downloader} from "../Downloader/Downloader"
import {CallbackSubmitAndCancelButtons} from "./CallbackSubmitAndCancelButtons"
import {FileUploader} from "../FileUploader/FileUploader"
import {ApiRequestData} from "../AssignmentPageItemCallback/AssignmentPageItemCallback"

const callSummaryPlaceholder =
	"Provide the name and phone number of the representative you spoke\nwith and summarize their responses to the questions..."
export type PreLoadedData = {
	preLoadedCallbackSummary?: string
	preLoadedCallbackDocumentMetadata?: CallbackDocumentMetadata
}

interface AssignmentPageItemCallbackFooterProps {
	preLoadedData: PreLoadedData
	assignees: Assignee[]
	callSummaryTextValue: string
	setTextValue: React.Dispatch<React.SetStateAction<string>>
	file?: File
	setFile: React.Dispatch<React.SetStateAction<File | undefined>>
	apiRequestData: ApiRequestData
	disabled?: boolean
	wireId: string
	handleDownload: () => Promise<void>
}

export function AssignmentPageItemCallbackFooter({
	preLoadedData,
	assignees,
	callSummaryTextValue,
	setTextValue,
	file,
	setFile,
	apiRequestData,
	disabled,
	wireId,
	handleDownload,
}: AssignmentPageItemCallbackFooterProps) {
	const {preLoadedCallbackSummary, preLoadedCallbackDocumentMetadata} = preLoadedData
	const allowedFileTypes = [".mp3", ".wav", ".jpeg", ".jpg", ".png", ".pdf", ".docx", ".mp4"]
	return (
		<footer className="assignment-page-item-callback-footer">
			<div className="assignment-page-item-callback-footer-note">
				<TextAreaWithAssigneeAvatar
					textValue={preLoadedCallbackSummary ?? callSummaryTextValue}
					setTextValue={setTextValue}
					assignees={assignees}
					placeholder={callSummaryPlaceholder}
					maxLength={600}
					disabled={disabled || !!preLoadedCallbackSummary}
				/>
			</div>
			<div className="assignment-page-item-callback-footer-buttons">
				{preLoadedCallbackDocumentMetadata ? (
					<div className="downloader-aside-buttons-container">
						<Downloader
							fileName={preLoadedCallbackDocumentMetadata.documentName}
							sizeInBytes={preLoadedCallbackDocumentMetadata.documentSize}
							onDownloadClick={handleDownload}
						/>
						<div className="submit-and-cancel-buttons-container">
							<CallbackSubmitAndCancelButtons
								callSummaryTextValue={callSummaryTextValue}
								preLoadedData={preLoadedData}
								disabled={disabled}
								apiRequestData={apiRequestData}
								wireId={wireId}
							/>
						</div>
					</div>
				) : (
					<>
						<FileUploader
							selectedFile={file}
							setSelectedFile={setFile}
							buttonLabel="Attach file"
							buttonIcon="move-up-1--interface-essential"
							buttonClassName="assignment-page-item-callback-footer-button"
							accept={allowedFileTypes.join(",")}
							className="assignment-page-item-callback-footer-button"
							disabled={disabled}
						/>
						<CallbackSubmitAndCancelButtons
							callSummaryTextValue={callSummaryTextValue}
							file={file}
							setFile={setFile}
							preLoadedData={preLoadedData}
							disabled={disabled}
							apiRequestData={apiRequestData}
							wireId={wireId}
						/>
					</>
				)}
			</div>
		</footer>
	)
}
