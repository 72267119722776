import {useEffect} from "react"

const escFunction = (e: KeyboardEvent, close: () => void) => e.key === "Escape" && close()

export const useEsc = (close: () => void) => {
	useEffect(() => {
		document.addEventListener("keydown", (e) => escFunction(e, close), false)
		return () => {
			document.removeEventListener("keydown", (e) => escFunction(e, close), false)
		}
	}, [])
}
