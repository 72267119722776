import React from "react"
import {TutorialBoxes, TutorialStep} from "./TutorialBoxes"
import {useLocalStorage} from "react-use"

const unitPilotTutorialSteps: TutorialStep[] = [
	{
		title: "Read the Quickstart Guide",
		url: "https://guides.unit.co/pilot",
		isExternalLink: true,
		icon: "astronomy-rocket-1--school-science",
		backgroundColor: "#DBFD51",
	},
	{
		title: "Generate an API Token",
		url: "/api-tokens",
		isExternalLink: false,
		icon: "security-password-lock--programing-apps-websites",
		backgroundColor: "#E2E4E7",
	},
	{
		title: "Create real bank accounts",
		url: "https://docs.unit.co/deposit-accounts#create-deposit-account",
		isExternalLink: true,
		icon: "bank-account--business-products",
		backgroundColor: "#936DFF",
	},
	{
		title: "Complete transactions with real funds",
		url: "https://docs.unit.co/payments",
		isExternalLink: true,
		icon: "money-transfer--business-products",
		backgroundColor: "#30E87A",
	},
]

const sandboxTutorialSteps: TutorialStep[] = [
	{
		title: "Explore the Dashboard",
		url: "/applications#showTutorial=true",
		isExternalLink: false,
		icon: "billing-account--business-products",
		backgroundColor: "#DBFD51",
	},
	{
		title: "Review our Docs & Guides",
		url: "https://docs.unit.co",
		isExternalLink: true,
		icon: "programming-book",
		backgroundColor: "#E2E4E7",
	},
	{
		title: "Create an API Token for Authentication",
		url: "/api-tokens",
		isExternalLink: false,
		icon: "security-password-lock--programing-apps-websites",
		backgroundColor: "#936DFF",
	},
	{
		title: "Experiment with the full Unit API",
		url: "https://docs.unit.co/#postman-collection",
		isExternalLink: true,
		icon: "file-code-download",
		backgroundColor: "#30E87A",
	},
]

interface Props {
	type: "sandbox" | "pilot"
}

export default function DashboardTutorial({type}: Props) {
	const [showTutorialLocalStorage, setShowTutorialLocalStorage] = useLocalStorage("showTutorial", "true")
	const showTutorial = showTutorialLocalStorage === "true"

	switch (type) {
		case "pilot": {
			return (
				<div className="dashboard-tutorial">
					<h1>Welcome to Unit</h1>
					<TutorialBoxes
						isExpanded={showTutorial}
						title="Start Building"
						steps={unitPilotTutorialSteps}
						toggleIsExpanded={() => setShowTutorialLocalStorage(showTutorial ? "false" : "true")}
					/>
				</div>
			)
		}
		case "sandbox": {
			return (
				<div className="dashboard-tutorial is-flex is-justify-content-space-between mb-5 is-flex-wrap-wrap">
					<h1>Welcome to Unit Sandbox</h1>
					<TutorialBoxes
						isExpanded={showTutorial}
						title="Get Started"
						steps={sandboxTutorialSteps}
						toggleIsExpanded={() => setShowTutorialLocalStorage(showTutorial ? "false" : "true")}
					/>
				</div>
			)
		}
	}

	return null
}
