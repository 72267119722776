import React from "react"
import classNames from "classnames"

type TagsViewerKeyOnlyProps = {
	keys: string[]
	size?: "small" | "medium"
	keyClassName?: string
	noMargin?: boolean
}
export function TagsViewerKeyOnly({keys, size = "medium", keyClassName, noMargin}: TagsViewerKeyOnlyProps) {
	return (
		<div className="tags-key-only-viewer">
			{keys.map((key) => (
				<div
					className={classNames("tags-key-value-viewer-tag", size === "small" && "is-small")}
					style={noMargin ? {margin: 0} : {}}
					key={key}
				>
					<span className={classNames("tags-key-value-viewer-tag-key")}>
						<div className={classNames(!!keyClassName && keyClassName)}>{key}</div>
					</span>
				</div>
			))}
		</div>
	)
}
