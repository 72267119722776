import {useAccessToken, useUserId} from "../../services/auth"
import {useParams} from "react-router"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {changePassword} from "../../resources/user"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import React, {useState} from "react"
import {ChangePasswordAttributes} from "./ChangePasswordAttributes"
import logoDark from "../../images/logo-dark.svg"

type CloseFunc = () => void
type RefreshFunc = () => void

interface NewModalProps {
	close: CloseFunc
	refresh?: RefreshFunc
}

function refreshAndClose(close: () => void, refresh?: () => void) {
	return function () {
		close()
		if (refresh) refresh()
	}
}

export function ChangePasswordModal({close, refresh}: NewModalProps) {
	const accessToken = useAccessToken()
	const {userId: paramUserId} = useParams<{userId: string}>()
	const currentUserId = useUserId()
	const userId = paramUserId || currentUserId

	const [state, save] = useAsyncResultIdle(changePassword)
	const [newPassword, setNewPassword] = useState("")
	const [oldPassword, setOldPassword] = useState<string | undefined>(undefined)
	const [confirmNewPassword, setConfirmNewPassword] = useState("")
	const [isPasswordGenerated, setIsPasswordGenerated] = useState(false)

	return (
		<AsyncResultModal
			classname="change-password-modal"
			title="Change Password"
			onSubmit={() => save(accessToken, userId, oldPassword, newPassword)}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Save"
			successText="Saved"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refreshAndClose(close, refresh)}
			outerChildren={
				<div className="logo">
					<img src={logoDark} />
				</div>
			}
			enableClickAway={false}
		>
			<ChangePasswordAttributes
				userId={userId}
				oldPassword={oldPassword}
				setOldPassword={setOldPassword}
				newPassword={newPassword}
				setNewPassword={setNewPassword}
				isPasswordGenerated={isPasswordGenerated}
				setIsPasswordGenerated={setIsPasswordGenerated}
				confirmNewPassword={confirmNewPassword}
				setConfirmNewPassword={setConfirmNewPassword}
			/>
		</AsyncResultModal>
	)
}
