import qs from "querystring"
import {flatten} from "q-flat"
import {deleteVerb, get, getResponse, patch, post, put, rawPost} from "../utilities/http"
import {Org} from "./org"
import {Bank} from "./bank"
import {Application, ApplicationDocument} from "./application"
import {Account} from "./account"
import {Customer} from "./customer"
import {ResponseType} from "axios"
import {BeneficialOwnerResource} from "./beneficialOwner"
import {Partner} from "./partner"
import {Payment} from "./payment"
import {User} from "./user"
import {IncomingAch} from "./incomingAch"
import {FileMetadata} from "../utilities/file"
import {CallbackAssignment} from "./assignments"

export const apiUrl = process.env.API_URL

export interface Error {
	title: string
	status: string
	source?: {
		pointer: string
	}
	detail?: string
}

export interface ErrorDocument {
	errors: Array<Error>
}

export function isErrorDocument(err: any): err is ErrorDocument {
	return Array.isArray(err?.errors)
}

export type Permissible<T> = T | undefined

export type Resource =
	| Org
	| Bank
	| Partner
	| ApplicationDocument
	| Account
	| Customer
	| BeneficialOwnerResource
	| Payment
	| User
	| IncomingAch
	| Application
	| CallbackAssignment

export interface OkDocument<T> {
	data: T
	included?: Resource[]
	meta?: Meta
}

export async function createResource<T>(
	path: string,
	accessToken: string,
	data: any,
	additionalHeaders?: Record<string, string>
) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
		...additionalHeaders,
	}

	const url = `${apiUrl}/${path}`

	return await post<OkDocument<T>, ErrorDocument>(url, {data}, {headers})
}

export async function getResource<T>(path: string, accessToken: string, query?: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = query ? `${apiUrl}/${path}?${qs.stringify(flatten(query))}` : `${apiUrl}/${path}`
	return await get<OkDocument<T>, ErrorDocument>(url, {headers})
}

export async function getResourceAsArrayBuffer(path: string, accessToken: string, query?: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = query ? `${apiUrl}/${path}?${qs.stringify(flatten(query))}` : `${apiUrl}/${path}`

	return await get<ArrayBuffer, any>(url, {headers, responseType: "arraybuffer"})
}

export async function updateResource<T>(path: string, accessToken: string, data: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = `${apiUrl}/${path}`

	return await patch<OkDocument<T>, ErrorDocument>(url, {data}, {headers})
}

export async function deleteResource<T>(path: string, accessToken: string) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = `${apiUrl}/${path}`
	return await deleteVerb<OkDocument<T>, ErrorDocument>(url, {headers})
}

export async function uploadResource<T>({
	path,
	accessToken,
	data,
	type,
	metadata,
}: {
	path: string
	accessToken: string
	data: any
	type?: string
	metadata?: FileMetadata
}) {
	const metadataHeaders = metadata ? {"Content-Disposition": `attachment; filename="${metadata.name}"`} : {}
	const headers = {
		"Content-Type": type ? type : "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
		...metadataHeaders,
	}

	const url = `${apiUrl}/${path}`

	return await put<OkDocument<T>, ErrorDocument>(url, data, {headers})
}

export async function getFile(
	path: string,
	accessToken: string,
	query?: any,
	fileType = "text/csv",
	responseType: ResponseType = "text",
	withFullRespones = false
) {
	const headers = {
		Accept: fileType,
		Authorization: `Bearer ${accessToken}`,
	}

	const url = query ? `${apiUrl}/${path}?${qs.stringify(flatten(query))}` : `${apiUrl}/${path}`
	if (withFullRespones) {
		return await getResponse<ErrorDocument>(url, {headers, responseType})
	}
	return await get<any, ErrorDocument>(url, {headers, responseType})
}

export async function createResourceFromFile<T>(
	path: string,
	accessToken: string,
	data: any,
	type: string,
	fileName: string
) {
	const headers = {
		"Content-Type": type,
		Authorization: `Bearer ${accessToken}`,
		"file-name": fileName,
	}
	const url = `${apiUrl}/${path}`
	return await post<OkDocument<T>, ErrorDocument>(url, data, {headers})
}

export async function rawCreateResource<T>(path: string, accessToken: string, data: any, extraHeaders: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = `${apiUrl}/${path}`

	return await rawPost<OkDocument<T>, ErrorDocument>(url, data, {headers: Object.assign(extraHeaders, headers)})
}

export type FullName = {
	first: string
	last: string
}

export type Address = {
	street: string
	street2?: string
	city: string
	state?: string
	postalCode: string
	country: string
}

export type Phone = {
	countryCode: string
	number: string
}

export function toPhoneNumber(phone: Phone) {
	return ["+", phone.countryCode, phone.number].join("")
}

export type BusinessContact = {
	fullName: FullName
	email: Permissible<string>
	phone: Permissible<Phone>
}

export type AuthorizedUser = {
	fullName: FullName
	email: string
	phone: Phone
}

export enum RiskRate {
	Low = "low",
	Medium = "medium",
	High = "high",
}

export enum EditMode {
	NEW = "NEW",
	EDIT = "EDIT",
	VIEW = "VIEW",
}

export type IpLocationDetails = {
	city: string
	stateCode: string
	countryCode: string
}

export type PhoneLocationDetails = {
	city: string
	stateCode: string
	countryCode: string
}

export type Pagination = {
	offset: string
	limit: string
	total: string
}

export type Meta = {
	pagination: Pagination
}

export type EvaluationScores = {
	Socure30: number
	IDAnalyticsIDScore: number
	Iovation: number
}

export const hiddenValue = "●●●"

export function isMasked(value: string) {
	return value.includes("*")
}
