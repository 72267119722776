import React from "react"
import {useAccessToken} from "../../services/auth"
import Card from "../../containers/Card/Card"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {Customer, getCustomer} from "../../resources/customer"
import {Org} from "../../resources/org"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {CustomerKnownNames, getCustomerKnownNames} from "../../resources/customerKnownNames"
import {AsyncResultZipComponent} from "../../containers/AsyncResult/AsyncResult"
import {Application, getApplication} from "../../resources/application"

interface NamesAtUnitCardProps {
	customerAsyncResult?: AsyncResultRequestState<OkDocument<Customer>, ErrorDocument>
	customerId: string
	customer?: Customer
	org?: Org
	title?: any
}

export function NamesAtUnitCard({customerId, title = "Names at Unit"}: NamesAtUnitCardProps) {
	const accessToken = useAccessToken()
	const customerKnownNamesResult = useAsyncResult<OkDocument<CustomerKnownNames>, ErrorDocument>(() =>
		getCustomerKnownNames(accessToken, customerId)
	)
	const customer = useAsyncResult<OkDocument<Customer>, ErrorDocument>(() => getCustomer(accessToken, customerId))

	return (
		<AsyncResultZipComponent asyncResult1={customerKnownNamesResult} asyncResult2={customer}>
			{({value1: customerKnownNamesResponse, value2: customerResponse}) => {
				const applicationId = customerResponse.data.relationships.application?.data.id
				const application = applicationId
					? useAsyncResult<OkDocument<Application>, ErrorDocument>(() => getApplication(accessToken, applicationId))
					: null

				const customerKnownNamesDict: Record<string, string> = {}
				customerKnownNamesResponse.data.attributes.names.forEach((name, i) => {
					customerKnownNamesDict[`Known alias ${i + 1}`] = name
				})

				const authorizedUsersDict: Record<string, string> = {}
				customerResponse.data.attributes.authorizedUsers.forEach((authorizedUser, i) => {
					authorizedUsersDict[
						`Authorized user ${i + 1}`
					] = `${authorizedUser.fullName.first} ${authorizedUser.fullName.last}`
				})

				const beneficialOwnersDict: Record<string, string> = {}
				let officer
				let dba
				if (application && application.isOk() && application.value.data.type === "businessApplication") {
					application.value.data.attributes.beneficialOwners.forEach((bo, i) => {
						beneficialOwnersDict[`Beneficial owner ${i + 1}`] = `${bo.fullName.first} ${bo.fullName.last}`
					})

					officer = `${application.value.data.attributes.officer.fullName.first} ${application.value.data.attributes.officer.fullName.last}`
					dba = application.value.data.attributes.dba
				}

				const namesAtUnit = {
					...(Object.keys(customerKnownNamesDict).length > 0 ? customerKnownNamesDict : {}),
					...(customerResponse.data.type === "businessCustomer"
						? {
								"Business contact name": `${customerResponse.data.attributes.contact.fullName.first} ${customerResponse.data.attributes.contact.fullName.last}`,
						  }
						: {}),
					...(Object.keys(authorizedUsersDict).length > 0 ? authorizedUsersDict : {}),
					...(Object.keys(beneficialOwnersDict).length > 0 ? beneficialOwnersDict : {}),
					...(officer ? {Officer: officer} : {}),
					...(dba ? {"Doing business as": dba} : {}),
				}

				if (Object.keys(namesAtUnit).length === 0) {
					return <></>
				}

				return (
					<Card title={title}>
						<TagsViewerKeyValue tags={namesAtUnit} />
					</Card>
				)
			}}
		</AsyncResultZipComponent>
	)
}
