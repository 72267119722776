import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import React, {useEffect, useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {BusinessApplication, IndividualApplication, requestDocument} from "../../resources/application"
import {BeneficialOwnerResource} from "../../resources/beneficialOwner"

type IndividualDocType =
	| "ssn"
	| "idDocument"
	| "passport"
	| "addressVerification"
	| "matriculaConsular"
	| "selfieVerification"
type BusinessDocType = "certificateOfIncorporation" | "ein" | "businessAddressVerification"

export function IndividualRequestDocumentModal({
	close,
	accessToken,
	application,
	onSuccess,
}: {
	application: IndividualApplication
	close: () => void
	accessToken: string
	onSuccess: () => void
}) {
	const [requestDocumentState, request] = useAsyncResultIdle(requestDocument)
	const [docType, setDocType] = useState<IndividualDocType>("addressVerification")

	function getRequest() {
		switch (docType) {
			case "addressVerification":
				if (application.attributes.dateOfBirth && application.attributes.address) {
					return {
						fullName: application.attributes.fullName,
						birthDate: application.attributes.dateOfBirth,
						address: application.attributes.address,
					}
				}
				return undefined
			case "idDocument":
				if (application.attributes.dateOfBirth && application.attributes.ssn) {
					return {
						fullName: application.attributes.fullName,
						birthDate: application.attributes.dateOfBirth,
						ssn: application.attributes.ssn,
					}
				}
				return undefined
			case "passport":
				if (
					application.attributes.dateOfBirth &&
					application.attributes.passport &&
					application.attributes.nationality
				) {
					return {
						fullName: application.attributes.fullName,
						birthDate: application.attributes.dateOfBirth,
						passport: application.attributes.passport,
						nationality: application.attributes.nationality,
					}
				}
				return undefined
			case "ssn":
				if (application.attributes.dateOfBirth && application.attributes.ssn) {
					return {
						fullName: application.attributes.fullName,
						birthDate: application.attributes.dateOfBirth,
						ssn: application.attributes.ssn,
					}
				}
				return undefined
			case "matriculaConsular":
				if (application.attributes.dateOfBirth && application.attributes.matriculaConsular) {
					return {
						fullName: application.attributes.fullName,
						birthDate: application.attributes.dateOfBirth,
						matriculaConsular: application.attributes.matriculaConsular,
					}
				}
				return undefined
			case "selfieVerification":
				if (application.attributes.dateOfBirth) {
					return {
						fullName: application.attributes.fullName,
						birthDate: application.attributes.dateOfBirth,
					}
				}
				return undefined
		}
	}

	return (
		<AsyncResultModal
			title="Request document"
			close={close}
			state={requestDocumentState}
			onSubmit={() => {
				const requestData = getRequest()
				if (requestData) {
					return request(accessToken, application.id, docType, requestData)
				}
			}}
			onSuccess={() => {
				close()
				onSuccess()
			}}
			successText="Requested"
			buttonText="Request"
			buttonClassname="button is-info"
			errorToText={(err) => (err.errors ? err.errors[0].title : "An unexpected error has occurred")}
		>
			<div className="control">
				{"Document: "}
				<select value={docType} onChange={(e) => setDocType(e.target.value as IndividualDocType)}>
					<option key="addressVerification" value="addressVerification">
						Address Verification
					</option>
					<option key="selfieVerification" value="selfieVerification">
						Selfie Verification
					</option>
					{application.attributes.ssn ? (
						<option key="ssn" value="ssn">
							SSN
						</option>
					) : undefined}
					{application.attributes.matriculaConsular ? (
						<option key="matriculaConsular" value="matriculaConsular">
							Matricula Consular
						</option>
					) : undefined}
					{application.attributes.ssn ? (
						<option key="idDocument" value="idDocument">
							ID
						</option>
					) : undefined}
					{application.attributes.passport ? (
						<option key="passport" value="passport">
							Passport
						</option>
					) : undefined}
				</select>
			</div>
		</AsyncResultModal>
	)
}

type Entities = "business" | "officer" | "bo1" | "bo2" | "bo3" | "bo4"

export function BusinessRequestDocumentModal({
	close,
	accessToken,
	application,
	beneficialOwners,
	onSuccess,
}: {
	application: BusinessApplication
	beneficialOwners: BeneficialOwnerResource[]
	close: () => void
	accessToken: string
	onSuccess: () => void
}) {
	const [requestDocumentState, request] = useAsyncResultIdle(requestDocument)
	const [entity, setEntity] = useState<Entities>("business")
	const [docType, setDocType] = useState<IndividualDocType | BusinessDocType>("addressVerification")

	useEffect(() => {
		if (entity != "business" && (docType == "ein" || docType == "certificateOfIncorporation")) {
			setDocType("addressVerification")
		} else if (entity == "business" && docType != "ein" && docType != "certificateOfIncorporation") {
			setDocType("certificateOfIncorporation")
		}
	}, [entity])

	function getRequest() {
		const entity = getEntity()
		if (!entity) {
			switch (docType) {
				case "certificateOfIncorporation":
					return {
						name: application.attributes.name,
						stateOfIncorporation: application.attributes.stateOfIncorporation,
					}
				case "ein":
					if (application.attributes.ein) {
						return {
							name: application.attributes.name,
							ein: application.attributes.ein,
						}
					}
					return undefined
				case "businessAddressVerification":
					if (application.attributes.address) {
						return {
							name: application.attributes.name,
							address: application.attributes.address,
						}
					}
					return undefined
				default:
					return undefined
			}
		}
		switch (docType) {
			case "addressVerification":
				if (entity.dateOfBirth && entity.address) {
					return {
						fullName: entity.fullName,
						birthDate: entity.dateOfBirth,
						address: entity.address,
					}
				}
				return undefined
			case "idDocument":
				if (entity.dateOfBirth && entity.ssn) {
					return {
						fullName: entity.fullName,
						birthDate: entity.dateOfBirth,
						ssn: entity.ssn,
					}
				}
				return undefined
			case "passport":
				if (entity.dateOfBirth && entity.passport && entity.nationality) {
					return {
						fullName: entity.fullName,
						birthDate: entity.dateOfBirth,
						passport: entity.passport,
						nationality: entity.nationality,
					}
				}
				return undefined
			case "ssn":
				if (entity.dateOfBirth && entity.ssn) {
					return {
						fullName: entity.fullName,
						birthDate: entity.dateOfBirth,
						ssn: entity.ssn,
					}
				}
				return undefined
			case "matriculaConsular":
				if (entity.dateOfBirth && entity.matriculaConsular) {
					return {
						fullName: entity.fullName,
						birthDate: entity.dateOfBirth,
						matriculaConsular: entity.matriculaConsular,
					}
				}
				return undefined
			case "selfieVerification":
				if (entity.dateOfBirth) {
					return {
						fullName: entity.fullName,
						birthDate: entity.dateOfBirth,
					}
				}
				return undefined
		}
	}

	function getEntity() {
		switch (entity) {
			case "business":
				return undefined
			case "officer":
				return application.attributes.officer
			case "bo1":
				return beneficialOwners[0]?.attributes
			case "bo2":
				return beneficialOwners[1]?.attributes
			case "bo3":
				return beneficialOwners[2]?.attributes
			case "bo4":
				return beneficialOwners[3]?.attributes
		}
	}

	return (
		<AsyncResultModal
			title="Request document"
			close={close}
			state={requestDocumentState}
			onSubmit={() => {
				const requestData = getRequest()
				if (requestData) {
					return request(accessToken, application.id, docType, requestData)
				}
			}}
			onSuccess={() => {
				close()
				onSuccess()
			}}
			successText="Requested"
			buttonText="Request"
			buttonClassname="button is-info"
			errorToText={(err) => (err.errors ? err.errors[0].title : "An unexpected error has occurred")}
		>
			<div className="control mb-2">
				{"Entity: "}
				<select value={entity} onChange={(e) => setEntity(e.target.value as Entities)}>
					<option key="business" value="business">
						Business
					</option>
					<option
						key="officer"
						value="officer"
					>{`Officer - ${application.attributes.officer.fullName.first} ${application.attributes.officer.fullName.last}`}</option>
					{beneficialOwners.length > 0 ? (
						<option key="bo1" value="bo1">
							{`BO - ${beneficialOwners[0]?.attributes.fullName.first} ${beneficialOwners[0]?.attributes.fullName.last}`}
						</option>
					) : undefined}
					{beneficialOwners.length > 1 ? (
						<option key="bo2" value="bo2">
							{`BO - ${beneficialOwners[1]?.attributes.fullName.first} ${beneficialOwners[1]?.attributes.fullName.last}`}
						</option>
					) : undefined}
					{beneficialOwners.length > 2 ? (
						<option key="bo3" value="bo3">
							{`BO - ${beneficialOwners[2]?.attributes.fullName.first} ${beneficialOwners[2]?.attributes.fullName.last}`}
						</option>
					) : undefined}
					{beneficialOwners.length > 3 ? (
						<option key="bo4" value="bo4">
							{`BO - ${beneficialOwners[3]?.attributes.fullName.first} ${beneficialOwners[3]?.attributes.fullName.last}`}
						</option>
					) : undefined}
				</select>
			</div>
			<div className="control">
				{"Document: "}
				<select value={docType} onChange={(e) => setDocType(e.target.value as IndividualDocType)}>
					{entity !== "business" ? (
						<option key="selfieVerification" value="selfieVerification">
							Selfie Verification
						</option>
					) : null}
					{entity !== "business" ? (
						<option key="addressVerification" value="addressVerification">
							Address Verification
						</option>
					) : null}
					{entity !== "business" && getEntity()?.ssn ? (
						<option key="ssn" value="ssn">
							SSN
						</option>
					) : undefined}
					{entity !== "business" && getEntity()?.ssn ? (
						<option key="idDocument" value="idDocument">
							ID
						</option>
					) : undefined}
					{entity !== "business" && getEntity()?.matriculaConsular ? (
						<option key="matriculaConsular" value="matriculaConsular">
							Matricula Consular
						</option>
					) : undefined}
					{entity !== "business" && getEntity()?.passport ? (
						<option key="passport" value="passport">
							Passport
						</option>
					) : undefined}
					{entity == "business" ? (
						<option key="certificateOfIncorporation" value="certificateOfIncorporation">
							Certificate Of Incorporation
						</option>
					) : undefined}
					{entity == "business" ? (
						<option key="businessAddressVerification" value="businessAddressVerification">
							Address Verification
						</option>
					) : null}
					{entity == "business" ? (
						<option key="ein" value="ein">
							Employer Identification Number
						</option>
					) : undefined}
				</select>
			</div>
		</AsyncResultModal>
	)
}
