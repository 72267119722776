import React, {PropsWithChildren} from "react"
import {useNavigate} from "react-router-dom"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"
import classNames from "classnames"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import Avatar, {AvatarProps} from "../Avatar/Avatar"
import {StatusBox, StatusBoxProps} from "../StatusBox/StatusBox"

type Breadcrumb = {
	path: string
	text: string
}

export type MetaItemProps = {
	text?: string
	icon?: IcomoonIconName
	path?: string
	divider?: boolean
	targetBlank?: boolean
}

interface TitleBarProps {
	title?: string
	breadcrumbs?: Breadcrumb[]
	meta?: MetaItemProps[]
	status?: StatusBoxProps
	children?: PropsWithChildren<any>
	avatar?: AvatarProps
	noBorder?: boolean
	noPadding?: boolean
	noAlignItems?: boolean
	leftSideClassName?: string
	className?: string
}

function BreadCrumb({path, text}: Breadcrumb) {
	const navigate = useNavigate()

	return (
		<>
			{" "}
			<a
				className={"bread-crumb"}
				href={path}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					navigate(`${path}`)
				}}
			>
				{" "}
				{text}{" "}
			</a>{" "}
			<Icon className={"bread-crumb-divider"} icon={"interface-arrows-button-right--interface-essential"} size={10} />{" "}
		</>
	)
}

function MetaItem({text, icon, path, divider, targetBlank}: MetaItemProps) {
	const navigate = useNavigate()
	let content

	if (path) {
		content = (
			<a
				className={classNames("meta-item")}
				href={path}
				target={targetBlank ? "_blank" : "_self"}
				rel={"noreferrer"}
				onClick={(e) => {
					if (!targetBlank) {
						e.preventDefault()
						e.stopPropagation()
						navigate(`${path}`)
					}
				}}
			>
				{icon && <Icon icon={icon} size={14} />}
				{text}
				{divider && <div className={"round-divider"} />}
			</a>
		)
	} else {
		content = (
			<div className={classNames("meta-item")}>
				{icon && <Icon icon={icon} size={14} />}
				{text}
				{divider && <div className={"round-divider"} />}
			</div>
		)
	}

	return content
}

function MetaBar({items}: {items: MetaItemProps[]}) {
	const isMetaContainOnlyText = items.every((item) => Object.keys(item).length === 1 && Object.keys(item)[0] === "text")
	const content = items.map((item, i) => {
		return (
			<MetaItem
				key={item.text}
				text={item.text}
				icon={item.icon}
				path={item.path}
				divider={i !== items.length - 1}
				targetBlank={item.targetBlank}
			/>
		)
	})
	return <div className={classNames("title-bar-meta-bar", !isMetaContainOnlyText && "with-margin")}> {content} </div>
}

function PageTitle({title, status, breadcrumbs}: {title: string; status?: StatusBoxProps; breadcrumbs?: Breadcrumb[]}) {
	return (
		<div className={"page-title-container"}>
			{breadcrumbs ? breadcrumbs.map((bc) => <BreadCrumb key={bc.text} path={bc.path} text={bc.text} />) : null}
			<h1 className={"page-title"}>{title}</h1>
			{status ? (
				<StatusBox
					text={status.text}
					color={status.color}
					backgroundColor={status.backgroundColor}
					className={status.className}
				/>
			) : null}
		</div>
	)
}

export function TitleBarAction({children}: {children: PropsWithChildren<any>}) {
	return <div className={"title-bar-actions"}>{children}</div>
}

export default function TitleBar({
	title,
	breadcrumbs,
	meta,
	status,
	children,
	avatar,
	noBorder,
	noPadding,
	noAlignItems,
	leftSideClassName,
	className,
}: TitleBarProps) {
	return (
		<header
			className={classNames(
				"is-title-bar",
				noBorder && "no-border",
				noPadding && "no-padding",
				noAlignItems && "no-align-items",
				className
			)}
		>
			<div className={classNames("title-bar-left-side", leftSideClassName)}>
				<GridContainer rows={1} columns={2} gap={"16px"}>
					{avatar && (
						<div className="title-bar-avatar-container">
							<Avatar src={avatar.src} alt={avatar.alt} name={avatar.name} />
						</div>
					)}
					<div className="title-bar-title-meta-container">
						<GridContainer rows={2} columns={1} gap={"0"}>
							{title ? <PageTitle status={status} title={title} breadcrumbs={breadcrumbs} /> : null}
							{meta ? <MetaBar items={meta} /> : null}
						</GridContainer>
					</div>
				</GridContainer>
			</div>
			<div className={"title-bar-right-side"}>{children}</div>
		</header>
	)
}
