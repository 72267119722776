import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {OrgSettings, update} from "../../resources/orgSettings"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import {AsYouType} from "libphonenumber-js"

const PRIMARY_BRAND_COLOR_DISPLAY_NAME = "Primary brand color"
const NEUTRAL_BRAND_COLOR_DISPLAY_NAME = "Neutral brand color (headers background color)"

export default function StatementSettings({orgSettings, refresh}: {orgSettings: OrgSettings; refresh: () => void}) {
	const accessToken = useAccessToken()
	const [state, patch] = useAsyncResultIdle(update)
	const [statementFont, setStatementFont] = useState<string | undefined>(
		orgSettings?.attributes.statementsSettings.statementFont ?? undefined
	)
	const [statementLogo, setStatementLogo] = useState<string | undefined>(
		orgSettings?.attributes.statementsSettings.statementLogo ?? undefined
	)
	const [statementHeaderBackground, setStatementHeaderBackground] = useState<string | undefined>(
		orgSettings?.attributes.statementsSettings.statementHeaderBackground ?? undefined
	)
	const [statementHeaderColor, setStatementHeaderColor] = useState<string | undefined>(
		orgSettings?.attributes.statementsSettings.statementHeaderColor ?? undefined
	)
	const [statementPhone, setStatementPhone] = useState<string>(orgSettings.attributes.statementsSettings.statementPhone)
	const [statementEmail, setStatementEmail] = useState<string>(
		orgSettings?.attributes.statementsSettings.statementEmail
	)
	const [statementRepresentativeName, setStatementRepresentativeName] = useState<string | undefined>(
		orgSettings?.attributes.statementsSettings.statementRepresentativeName
	)
	const {addToast} = useToasts()

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Updated Successfully", {appearance: "success"})
			refresh()
		}
	}, [state])

	function onFileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files

		if (files) {
			const file = files[0]

			if (file) {
				const reader = new FileReader()

				reader.addEventListener(
					"load",
					function () {
						console.log(reader.result ? reader.result.toString() : null)
						// convert image file to base64 string
						setStatementLogo(reader.result ? reader.result.toString() : undefined)
					},
					false
				)

				if (file) {
					reader.readAsDataURL(file)
				}
			}
		}
	}

	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<h3> Statements </h3>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						patch(accessToken, {
							statementsSettings: {
								statementFont: statementFont == "" ? undefined : statementFont,
								statementLogo: statementLogo == "" ? undefined : statementLogo,
								statementHeaderBackground: statementHeaderBackground == "" ? undefined : statementHeaderBackground,
								statementHeaderColor: statementHeaderColor == "" ? undefined : statementHeaderColor,
								statementPhone: statementPhone,
								statementEmail: statementEmail,
								statementRepresentativeName:
									statementRepresentativeName == "" ? undefined : statementRepresentativeName,
							},
						})
					}}
				>
					<fieldset>
						<div className="field">
							<label className="label">Font Link</label>
							<div className="control">
								<input
									className="input"
									type="text"
									value={statementFont ?? ""}
									pattern={'([^)]+?.(woff|eot|woff2|ttf|svg)[^"]*)'}
									onChange={(e) => setStatementFont(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Logo</label>
							<div className="field-body">
								<div className="file">
									<label className="file-label">
										<input className="file-input" type="file" onChange={onFileUploaded} accept=".png,.jpg" />
										<span className="file-cta">
											<span className="file-icon">
												<i className="fas fa-upload"></i>
											</span>
											<span className="file-label">Choose a file…</span>
										</span>
									</label>
								</div>
							</div>
							{statementLogo ? <img className="org-settings-logo" src={statementLogo} /> : null}
						</div>
						<div className="field">
							<label className="label">{PRIMARY_BRAND_COLOR_DISPLAY_NAME}</label>
							<div className="field-body">
								<p className="control">
									<a className="button is-static">#</a>
								</p>
								<div className="control">
									<input
										className="input"
										type="text"
										value={statementHeaderBackground ?? ""}
										pattern="^[0-9a-fA-f]{6,8}$"
										onChange={(e) => setStatementHeaderBackground(e.target.value)}
									/>
								</div>
								<p className="control">
									<a
										className="button is-static"
										style={{backgroundColor: statementHeaderBackground ? "#" + statementHeaderBackground : "inherit"}}
									></a>
								</p>
							</div>
						</div>
						<div className="field">
							<label className="label">{NEUTRAL_BRAND_COLOR_DISPLAY_NAME}</label>
							<div className="field-body">
								<p className="control">
									<a className="button is-static">#</a>
								</p>
								<div className="control">
									<input
										className="input"
										type="text"
										value={statementHeaderColor ?? ""}
										pattern="^[0-9a-fA-f]{6,8}$"
										onChange={(e) => setStatementHeaderColor(e.target.value)}
									/>
								</div>
								<p className="control">
									<a
										className="button is-static"
										style={{backgroundColor: statementHeaderColor ? "#" + statementHeaderColor : "inherit"}}
									></a>
								</p>
							</div>
						</div>
						<div className="field">
							<label className="label">Representative Phone</label>
							<div className="control">
								<input
									required
									className="input"
									type="text"
									value={new AsYouType("US").input(statementPhone)}
									onChange={(e) => {
										const ayt = new AsYouType("US")
										ayt.input(e.target.value)
										if (ayt.isValid() && ayt.country == "US") {
											e.target.setCustomValidity("")
										} else {
											e.target.setCustomValidity("This is not a valid US Phone number")
										}
										setStatementPhone(ayt.getNationalNumber())
									}}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Representative Email</label>
							<div className="control">
								<input
									required
									className="input"
									type="email"
									value={statementEmail ?? ""}
									onChange={(e) => setStatementEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className="field">
							<label className="label">Representative Name</label>
							<div className="control">
								<input
									className="input"
									type="text"
									maxLength={50}
									value={statementRepresentativeName ?? ""}
									onChange={(e) => setStatementRepresentativeName(e.target.value)}
								/>
							</div>
						</div>
					</fieldset>
					<div className="columns">
						<div className="column mt-4">
							{state.match(
								() => (
									<button className="button is-success">Update</button>
								),
								() => (
									<button className="button is-success is-loading">Updating</button>
								),
								(_) => null,
								(err) => (
									<>
										<div className="has-text-danger">{err.errors[0].title}</div>
										<button className="button is-success">Update</button>
									</>
								)
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
