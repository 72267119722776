import React from "react"
import {useParams} from "react-router-dom"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useAccessToken} from "../../services/auth"
import {OkDocument} from "../../resources/common"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {
	Application,
	getApplication,
	isBusinessApplicationDocument,
	isIndividualApplicationDocument,
} from "../../resources/application"
import BusinessApplication from "../BusinessApplication/BusinessApplication"
import IndividualApplication from "../IndividualApplication/IndividualApplication"

function ApplicationInternal({value: application, refresh}: {value: OkDocument<Application>; refresh: () => void}) {
	if (isBusinessApplicationDocument(application)) {
		return <BusinessApplication value={application} refresh={refresh} />
	}
	if (isIndividualApplicationDocument(application)) {
		return <IndividualApplication value={application} refresh={refresh} />
	}

	return <React.Fragment />
}

export default function ApplicationPage() {
	const {applicationId = ""} = useParams<{applicationId: string}>()
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const asyncResult = useAsyncResult(() => getApplication(accessToken, applicationId), [refreshToken])

	return (
		<AsyncResultComponent asyncResult={asyncResult} refresh={refresh}>
			{({value}) => {
				return <ApplicationInternal value={value as OkDocument<Application>} refresh={refresh} />
			}}
		</AsyncResultComponent>
	)
}
