import {useAccessToken} from "../../services/auth"
import React, {useEffect} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {addCustomerKnownNames} from "../../resources/customerKnownNames"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {approveIncomingAch, IncomingAch} from "../../resources/incomingAch"
import {AddToast} from "react-toast-notifications"
import {Location, NavigateFunction} from "react-router-dom"

const modalExitAndCloseDelay = 1000

interface AddAliasIncomingAchModalProps {
	incomingAch: IncomingAch
	close: () => void
	onSuccess: () => void
	addToast: AddToast
	navigate: NavigateFunction
	location: Location
}

function closeAndExit(close: () => void, navigate?: NavigateFunction, location?: Location) {
	const path = location?.state?.previousPage || "/incoming-achs"

	return function () {
		setTimeout(() => {
			close()
			navigate && navigate(path)
		}, modalExitAndCloseDelay)
	}
}

function closeAndRefresh(refresh: () => void, close: () => void) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, modalExitAndCloseDelay)
	}
}

export function AddAliasIncomingAchModal({
	incomingAch,
	close,
	onSuccess,
	addToast,
	navigate,
	location,
}: AddAliasIncomingAchModalProps) {
	const accessToken = useAccessToken()
	const [state, add] = useAsyncResultIdle(addCustomerKnownNames)
	const [, approve] = useAsyncResultIdle(approveIncomingAch)
	const customerId = incomingAch.relationships?.customer?.data.id || undefined
	const alias = incomingAch.attributes.item.recipientName
	const alreadyExistText = "Already Exists"

	const onSuccessApprove = async (aliasSuccess = true) => {
		const approveResponse = await approve(accessToken, incomingAch.id)

		approveResponse.match(
			() => {
				addToast(`ACH approved ${aliasSuccess ? "and alias added successfully" : ""}`, {appearance: "success"})
				closeAndExit(close, navigate, location)()
			},
			(err) => {
				addToast(`Something went wrong: ${err.errors[0].title}`, {appearance: "warning"})
				closeAndRefresh(onSuccess, close)()
			}
		)
	}

	useEffect(() => {
		state.match(
			() => null,
			() => null,
			() => null,
			(err) => {
				if (err.errors[0].title == alreadyExistText) {
					onSuccessApprove(false)
				}
			}
		)
	}, [state])

	if (customerId) {
		return (
			<AsyncResultModal
				title="Approve and add alias"
				close={closeAndRefresh(onSuccess, close)}
				onSuccess={onSuccessApprove}
				buttonClassname="is-success"
				state={state}
				buttonText="Confirm"
				successText="Added"
				onSubmit={() => add(accessToken, customerId, alias)}
				errorToText={(err) =>
					err.errors[0].title == alreadyExistText ? "Alias already exists, approving..." : err.errors[0].title
				}
			>
				Are you sure you want to add <b>{`"${alias}"`}</b> to customer <b>{customerId}</b> known aliases and to approve
				this ACH?
			</AsyncResultModal>
		)
	}

	return null
}
