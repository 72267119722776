import React, {PropsWithChildren, useState} from "react"
import {IcomoonIconName} from "../Icon/icons"
import Skeleton from "react-loading-skeleton"
import classNames from "classnames"
import Icon from "../Icon/Icon"

interface AccordionProps {
	title: React.ReactNode | string
	icon?: IcomoonIconName
	className?: string
	initiallyExpanded?: boolean
	noContentPadding?: boolean
	loading?: boolean
	theme: "light" | "full"
}

export function Accordion({
	title,
	icon,
	initiallyExpanded,
	noContentPadding,
	loading,
	theme,
	className,
	children,
}: PropsWithChildren<AccordionProps>) {
	const expandable = Boolean(children)
	const themeMap = {
		light: "light-theme",
		full: "full-theme",
	}
	const themeClass = themeMap[theme]
	const [expanded, setExpanded] = useState(expandable && initiallyExpanded)
	return loading ? (
		<div className={classNames("accordion-container", "skeleton-container", themeClass)}>
			<Skeleton />
		</div>
	) : (
		<div className={classNames("accordion-container", themeClass, expandable && "expandable", className)}>
			<div
				className={classNames(
					"accordion-header",
					themeClass,
					"is-flex",
					"is-justify-content-space-between",
					expandable && "expandable",
					expanded && "expanded"
				)}
				onClick={() => {
					expandable && setExpanded(!expanded)
				}}
			>
				<div className="icon-text">
					{icon && (
						<span className="icon">
							<Icon icon={icon} size={12} />
						</span>
					)}
					<span className={classNames("accordion-title", themeClass)}>{title}</span>
				</div>
				{expandable && (
					<span className="icon is-flex">
						<Icon
							icon="down-arrow-filter-chevron-small"
							className={classNames("accordion-icon", expanded && "expanded")}
							size={20}
						/>
					</span>
				)}
			</div>
			{expandable && (
				<div className={classNames("accordion accordion-content-wrapper", themeClass, expanded && "expanded")}>
					<div className="accordion-content">
						<div className={classNames("accordion-content-inner-decoration", themeClass)}>
							<div className={classNames(!noContentPadding && "padded")}>{children}</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
