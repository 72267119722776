import React, {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from "react"
import ReactTooltip from "react-tooltip"
import {useClickAway, useLocalStorage} from "react-use"
import {isUndefined} from "lodash"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {ApiTokenPresent, createApiToken} from "../../resources/apiToken"
import {useAccessToken, useUserId} from "../../services/auth"
import moment from "moment"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {apiUrl, ErrorDocument, OkDocument, rawCreateResource} from "../../resources/common"
import {AxiosResponse} from "axios"
import {Result} from "neverthrow"
import JsonEditor from "../JsonEditor/JsonEditor"
import {isProdEnv} from "../../utilities/environment"
import {trackEvent} from "../../utilities/tracking"
import {IcomoonIconName} from "../Icon/icons"

type HeaderField = {
	headerKey: string
	headerValue: string
}

export type TutorialModalProps = {
	parentRef: MutableRefObject<any>
	tooltipId: string
	actions: TutorialActionProps[]
}

export type TutorialActionProps = {
	actionName: string
	actionIcon?: IcomoonIconName
	actionIconClassname?: string
	title: string
	description: string
	successMessage: string
	errorMessage: string
	request: {
		method: "GET" | "POST" | "PUT" | "DELETE"
		path: string
		payload: string
		headers: HeaderField[]
	}
	refreshFunction?: () => void
	onSendAnotherRequest?: () => void
}

export function TutorialModal(props: TutorialModalProps) {
	const tutorialCallButton = document.querySelector(`.tutorial-call-button-${props.tooltipId}`)
	const [wasUsed, setWasUsed] = useLocalStorage(`used-${props.tooltipId}`, false)
	const [shouldRest, setShouldRestart] = useState(true)
	const [shouldRestCall, setShouldRestartCall] = useState(false)
	const ref = useRef(null)
	const [selectedActionIndex, setSelectedActionIndex] = useState<number | undefined>(undefined)

	useClickAway(ref, () => ReactTooltip.hide(props.parentRef.current))

	useEffect(() => {
		if (wasUsed && tutorialCallButton) {
			tutorialCallButton.classList.add("tutorial-call-button-was-used")
		}
	})

	return (
		<ReactTooltip
			afterShow={() => {
				const sessionStorageAction = sessionStorage.getItem("tutorial_action")
				if (shouldRest || sessionStorageAction) {
					setShouldRestartCall(true)
					const selectedAction =
						sessionStorageAction && props.actions.length > 1
							? props.actions.reduce<number | undefined>((r, a, i) => {
									if (a.actionName == sessionStorageAction) {
										return i
									}
									return r
							  }, undefined)
							: undefined
					setSelectedActionIndex(selectedAction)
				}
				trackEvent(`${props.tooltipId}-tutorial-modal-open`)
				if (!wasUsed) {
					setWasUsed(true)
				}
			}}
			afterHide={() => {
				if (shouldRest) {
					sessionStorage.removeItem("tutorial_action")
					setSelectedActionIndex(undefined)
				}
			}}
			id={props.tooltipId}
			className="tutorial-modal"
			place="bottom"
			type="light"
			effect="solid"
			offset={{left: 170, bottom: 8}}
			scrollHide={false}
			clickable={true}
		>
			<div
				ref={ref}
				className={classNames(
					"tutorial-modal-container",
					(props.actions.length == 1 || !isUndefined(selectedActionIndex)) && "opened"
				)}
			>
				{props.actions.length > 1 && isUndefined(selectedActionIndex) ? (
					<ActionSelector actions={props.actions} setAction={setSelectedActionIndex} />
				) : (
					<TutorialAction
						shouldRestartCall={shouldRestCall}
						setShouldRestartCall={setShouldRestartCall}
						setShouldRestart={setShouldRestart}
						tooltipId={props.tooltipId}
						{...props.actions[selectedActionIndex ?? 0]}
						resetSectionFunction={() => setSelectedActionIndex(undefined)}
					/>
				)}
			</div>
		</ReactTooltip>
	)
}

function ActionSelector({
	actions,
	setAction,
}: {
	actions: TutorialActionProps[]
	setAction: Dispatch<SetStateAction<number | undefined>>
}) {
	return (
		<div className="action-selection">
			<div className="selection-title">What would you like to test?</div>
			<div className="actions-container">
				{actions.map((s, i) => (
					<div key={s.actionName} className="action-button" onClick={() => setAction(i)}>
						<Icon
							icon={s.actionIcon ?? "boardcasting-wifi-signal--interface-essential"}
							color="white"
							size={36}
							className={classNames("action-button-icon", s.actionIconClassname)}
						/>
						<span className="action-title">{s.actionName}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export function TutorialAction(
	props: TutorialActionProps & {
		tooltipId: string
		resetSectionFunction: () => void
		setShouldRestart: Dispatch<SetStateAction<boolean>>
		shouldRestartCall: boolean
		setShouldRestartCall: Dispatch<SetStateAction<boolean>>
	}
) {
	const [sendRequestState, sendRequest, resetRequest] = useAsyncResultIdle(rawCreateResource)

	useEffect(() => {
		if (sendRequestState.isOk()) {
			props.setShouldRestart(false)
			if (props.refreshFunction) {
				props.refreshFunction()
			}
		}
	}, [sendRequestState])

	useEffect(() => {
		if (props.shouldRestartCall) {
			resetRequest()
			props.setShouldRestart(true)
			props.setShouldRestartCall(false)
		}
	}, [props.shouldRestartCall])

	function resetTutorial() {
		resetRequest()
		props.resetSectionFunction()
	}

	return sendRequestState.match(
		() => <RequestPage {...props} sendRequest={sendRequest} />,
		() => <RequestLoading />,
		(r) => <ResponsePage {...props} response={r} isError={false} resetFunction={resetTutorial} />,
		(e) => <ResponsePage {...props} response={e} isError resetFunction={resetTutorial} />
	)
}

function RequestPage(
	props: TutorialActionProps & {
		tooltipId: string
		sendRequest: (
			path: string,
			accessToken: string,
			data: any,
			extraHeaders: any
		) => Promise<Result<AxiosResponse<OkDocument<any>>, AxiosResponse<ErrorDocument>>>
	}
) {
	const [storedApiToken] = useLocalStorage<ApiTokenPresent | undefined>("tutorialToken", undefined)
	const [apiToken, setApiToken] = useState(storedApiToken)
	const sourceHeader: HeaderField = {headerKey: "unit-request-origin", headerValue: "tutorial"}

	return (
		<div className="request-page">
			<div className="title-row">
				<div className="request-title">{props.title}</div>
				<div className="request-badge">Request</div>
			</div>
			<div className="request-description">{props.description}</div>
			<div className="request-parameters">
				<div className="parameter-row">
					<div className="request-method">
						<Field label="Type" value={props.request.method} />
					</div>
					<div className="request-url">
						<Field label="URL" value={`${apiUrl}/${props.request.path}`} />
					</div>
				</div>
				<div className="parameter-row">
					<div className="request-token">{!isProdEnv() ? <TutorialApiToken setFunction={setApiToken} /> : null}</div>
				</div>
			</div>
			<RequestTabs {...props} />
			<div className="send-request-container">
				<button
					className="send-request"
					data-tracking-label={`${props.tooltipId}-send-request`}
					onClick={() => {
						if (apiToken) {
							props.sendRequest(
								props.request.path,
								apiToken.attributes.token,
								JSON.parse(props.request.payload),
								Object.fromEntries(
									[sourceHeader].concat(props.request.headers).map((h) => [h.headerKey, h.headerValue])
								)
							)
						}
					}}
				>
					Send
				</button>
			</div>
		</div>
	)
}

function Field({label, value}: {label: string; value: string}) {
	return (
		<div className="field-element">
			<div className="field-element-label">{label}</div>
			<input readOnly value={value} className="field-element-value" />
		</div>
	)
}

function TutorialApiToken({setFunction}: {setFunction: Dispatch<SetStateAction<ApiTokenPresent | undefined>>}) {
	const accessToken = useAccessToken()
	const userId = useUserId()
	const tutorialScopes = "applications-write accounts-write cards-write"
	const [apiToken, setApiToken] = useLocalStorage<ApiTokenPresent | undefined>("tutorialToken", undefined)
	const [creationState, createTempApiToken] = useAsyncResultIdle(createApiToken)

	useEffect(() => {
		if (isUndefined(apiToken)) {
			createTempApiToken(
				accessToken,
				userId,
				"Test API call from dashboard",
				tutorialScopes,
				moment().add(moment.duration(1, "day")).toDate()
			)
		}
	}, [apiToken])

	useEffect(() => {
		if (creationState.isOk()) {
			setApiToken(creationState.value)
			setFunction(creationState.value)
		}
	}, [creationState])
	return (
		<div className="field-element">
			<div className="field-element-label">Token</div>
			<input readOnly value={apiToken ? apiToken.attributes.token : "???"} className="field-element-value" />
		</div>
	)
}

function RequestTabs(props: TutorialActionProps) {
	type Tabs = "Body" | "Headers"
	const [currentTab, setCurrentTab] = useState<Tabs>("Body")
	return (
		<div className="tabs-container">
			<div className="tabs">
				<ul>
					<li className={classNames(currentTab == "Body" && "is-active")} onClick={() => setCurrentTab("Body")}>
						<a>Body</a>
					</li>
					<li className={classNames(currentTab == "Headers" && "is-active")} onClick={() => setCurrentTab("Headers")}>
						<a>Headers</a>
					</li>
				</ul>
			</div>
			<div className="tab-content">
				{currentTab == "Body" ? (
					<>
						<JsonEditor payload={props.request.payload} />
					</>
				) : (
					<>
						{props.request.headers.map((h) => (
							<HeaderFieldElement key={h.headerKey} label={h.headerKey} value={h.headerValue} />
						))}
					</>
				)}
			</div>
		</div>
	)
}

function HeaderFieldElement({label, value}: {label: string; value: string}) {
	return (
		<div className="header-field-element">
			<input readOnly value={label} className="header-field-element-label" />
			<input readOnly value={value} className="header-field-element-value" />
		</div>
	)
}

function RequestLoading() {
	return (
		<div className="loading-page">
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
			<div className="loading-label">Sending Request...</div>
		</div>
	)
}

function ResponsePage(
	props: TutorialActionProps & {
		tooltipId: string
		response: AxiosResponse
		isError: boolean
		resetFunction: () => void
		setShouldRestart: Dispatch<SetStateAction<boolean>>
	}
) {
	return (
		<div className="response-page">
			<div className="title-row">
				<div className="request-title">{props.title}</div>
				<div className="request-badge">Response</div>
			</div>
			<div className="request-description">
				{props.isError ? (
					props.errorMessage
				) : (
					<span>
						{`${props.successMessage}. Ready to make your own API calls? Use our `}
						<a target="_blank" rel="noreferrer" href="https://docs.unit.co/#postman-collection">
							{" "}
							<span>Postman Collection</span>{" "}
							<Icon icon="maximize-square-2--interface-essential" size={10} style={{position: "relative"}} />{" "}
						</a>
					</span>
				)}
			</div>
			<div className="response-details">
				<div className="response-item">
					<div className="item-label">Status:</div>
					<div
						className={classNames("item-value", props.isError && "error-message")}
					>{`${props.response.status} ${props.response.statusText}`}</div>
				</div>
				<div className="response-item">
					<div className="item-label">Size:</div>
					<div className="item-value">{`${props.response.headers["content-length"]} Bytes`}</div>
				</div>
			</div>
			<JsonEditor payload={JSON.stringify(props.response.data)} />
			<div className="send-request-container">
				<button
					className="send-request"
					data-tracking-label={`${props.tooltipId}-${props.isError ? "Rerun Request" : "New Request"}`}
					onClick={() => {
						props.resetFunction()
						props.setShouldRestart(true)
						if (props.onSendAnotherRequest) {
							props.onSendAnotherRequest()
						}
					}}
				>
					<Icon icon={props.isError ? "synchronize-arrows-1--interface-essential" : "interface-add-1"} size={12} />
					{props.isError ? "Rerun Request" : "New Request"}
				</button>
			</div>
		</div>
	)
}
