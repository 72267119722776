import React from "react"
import {toLower} from "lodash"
import IconButton from "../IconButton/IconButton"
import classNames from "classnames"
import {IcomoonIconName} from "../Icon/icons"
interface FileDetailsProps {
	tooltip?: string
	icon: IcomoonIconName
	tooltipKey?: string
	disabled?: boolean
	loading?: boolean
	onClick?: () => void
	buttonClassName?: string
	containerClassName?: string
}

export function FileIconButton({
	tooltip,
	icon,
	tooltipKey,
	disabled,
	loading,
	onClick,
	buttonClassName,
	containerClassName,
}: FileDetailsProps) {
	const dataTip = tooltip
	const dataFor = `${toLower(dataTip)}-tooltip${tooltipKey ? `-for-${tooltipKey}` : ""}`
	return (
		<div className={classNames("file-icon-button-container", containerClassName)}>
			<IconButton
				icon={icon}
				onClick={onClick}
				size="small"
				variant="subtle"
				theme="neutral"
				disabled={disabled}
				loading={loading}
				tooltip={dataTip}
				tooltipKey={dataFor}
				tooltipDisabled={!tooltip || disabled}
				tooltipClassName="file-icon-button-tooltip"
				className={buttonClassName}
			/>
		</div>
	)
}
