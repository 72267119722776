import {
	Address,
	AuthorizedUser,
	BusinessContact,
	createResource,
	FullName,
	getFile,
	getResource,
	OkDocument,
	Permissible,
	Phone,
	RiskRate,
	updateResource,
} from "./common"
import moment from "moment"
import {IcomoonIconName} from "../components/Icon/icons"
import {isUndefined} from "lodash"

export type BusinessCustomer = {
	type: "businessCustomer"
	id: string
	attributes: {
		createdAt: Date
		name: string
		dba?: string
		address: Permissible<Address>
		phone: Permissible<Phone>
		dateOfIncorporation: Date
		stateOfIncorporation: string
		ein: Permissible<string>
		entityType: "Corporation" | "LLC"
		contact: BusinessContact
		purpose: string
		tags: {[key: string]: string}
		riskRate?: RiskRate
		overrideRiskRate?: RiskRate
		authorizedUsers: AuthorizedUser[]
		status: CustomerStatus
		archiveReason?: ArchiveReason
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		application?: {
			data: {
				type: "businessApplication"
				id: string
			}
		}
	}
}

export type IndividualCustomer = {
	type: "individualCustomer"
	id: string
	attributes: {
		createdAt: Date
		fullName: FullName
		ssn: Permissible<string>
		passport: Permissible<string>
		nationality: Permissible<string>
		matriculaConsular: Permissible<string>
		address: Permissible<Address>
		dateOfBirth: Permissible<Date>
		email: Permissible<string>
		phone: Permissible<Phone>
		tin?: string
		ip?: string
		ein: Permissible<string>
		dba?: string
		soleProprietorship: boolean
		tags: {[key: string]: string}
		riskRate?: RiskRate
		overrideRiskRate?: RiskRate
		maskedSSN?: string
		maskedPassport?: string
		maskedMatriculaConsular?: string
		authorizedUsers: AuthorizedUser[]
		status: CustomerStatus
		archiveReason?: ArchiveReason
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		application?: {
			data: {
				type: "individualApplication"
				id: string
			}
		}
	}
}

export type BusinessFBOCustomer = {
	type: "businessFBOCustomer"
	id: string
	attributes: {
		createdAt: Date
		businessName: string
		businessVertical: string
		numberOfEmployees: string
		bankName: string
		name: string
		address: Permissible<Address>
		tags: {[key: string]: string}
		status: CustomerStatus
		authorizedUsers: AuthorizedUser[]
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		// Not in use
		application?: {
			data: {
				type: "individualApplication"
				id: string
			}
		}
	}
}

export type IndividualWalletCustomer = {
	type: "individualWalletCustomer"
	id: string
	attributes: {
		createdAt: Date
		fullName: FullName
		email: Permissible<string>
		phone: Phone
		status: CustomerStatus
		tags: {[key: string]: string}
		authorizedUsers: AuthorizedUser[]
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		// Not in use
		application?: {
			data: {
				type: "individualWalletApplication"
				id: string
			}
		}
	}
}

export type Customer = IndividualCustomer | BusinessCustomer | BusinessFBOCustomer | IndividualWalletCustomer

export enum CustomerType {
	individual = "individual",
	business = "business",
	businessFBO = "businessFBO",
	individualWallet = "individualWallet",
}

export const CustomerResourceTypes = [
	"individualCustomer",
	"businessCustomer",
	"businessFBOCustomer",
	"individualWalletCustomer",
]
export enum CustomerStatus {
	Active = "Active",
	Archived = "Archived",
}

export enum ArchiveReason {
	Inactive = "Inactive",
	FraudACHActivity = "FraudACHActivity",
	FraudCardActivity = "FraudCardActivity",
	FraudCheckActivity = "FraudCheckActivity",
	FraudApplicationHistory = "FraudApplicationHistory",
	FraudAccountActivity = "FraudAccountActivity",
	FraudClientIdentified = "FraudClientIdentified",
	FraudLinkedToFraudulentCustomer = "FraudLinkedToFraudulentCustomer",
	ByBank = "ByBank",
}

export const CustomerTypeIcons: Record<CustomerType, IcomoonIconName> = {
	[CustomerType.business]: "toolbox",
	[CustomerType.individual]: "user-geometric-action---users",
	[CustomerType.businessFBO]: "toolbox",
	[CustomerType.individualWallet]: "user-geometric-action---users",
}

export interface CustomerStatusChange {
	status: string
	reason?: string
}

export async function findCustomers(
	accessToken: string,
	offset: number,
	limit: number,
	sort: string,
	searchQuery: string,
	orgs?: string[],
	filterByStatus?: CustomerStatus[],
	filterByType?: CustomerType[]
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : null

	const query = {
		page: {
			limit,
			offset,
		},
		include: "org",
		filter: {
			query: searchQuery != "" ? searchQuery : null,
			orgs: orgFilter,
			status: filterByStatus,
			types: filterByType,
		},
		sort,
	}

	return await getResource<Array<Customer>>("customers", accessToken, query)
}

export async function exportCustomers(
	accessToken: string,
	sort: string,
	searchQuery: string,
	orgs?: string[],
	types?: CustomerType[]
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : null
	const typeFilter = types && types.length > 0 ? types : null

	const query = {
		page: {
			limit: 10000,
			offset: 0,
		},
		filter: {
			query: searchQuery != "" ? searchQuery : null,
			orgs: orgFilter,
			types: typeFilter,
		},
		include: "org",
		sort,
	}

	return await getFile("customers", accessToken, query)
}

export async function getCustomer(accessToken: string, id: string) {
	const query = {
		include: "org",
		extraFields: {
			customer: "maskedSSN,maskedPassport,maskedMatriculaConsular",
		},
	}
	return await getResource<Customer>(`customers/${id}`, accessToken, query)
}

export async function getBusiness<Business>(accessToken: string, id: string) {
	const query = {
		include: "org",
	}
	return await getResource<Business>(`customers/${id}`, accessToken, query)
}

export async function updateIndividualCustomer(
	accessToken: string,
	Customer: IndividualCustomer,
	{
		ssn,
		passport,
		matriculaConsular,
		dob,
		address,
		phone,
		nationality,
		email,
		ein,
		dba,
		riskRate,
		fullName,
	}: {
		ssn?: string
		passport?: string
		nationality?: string
		matriculaConsular?: string
		dob?: Date
		address?: Address
		phone?: Phone
		email?: string
		ein?: string
		dba?: string | null
		riskRate?: RiskRate
		fullName?: FullName
	}
) {
	return await updateResource<Customer>(`customers/${Customer.id}`, accessToken, {
		type: Customer.type,
		attributes: Object.assign(
			{},
			ssn ? {ssn: ssn} : undefined,
			passport ? {passport: passport} : undefined,
			nationality ? {nationality: nationality} : undefined,
			matriculaConsular ? {matriculaConsular: matriculaConsular} : undefined,
			ein ? {ein: ein} : undefined,
			dob ? {dateOfBirth: moment(dob).format("YYYY-MM-DD")} : undefined,
			address ? {address: address} : undefined,
			phone ? {phone: phone} : undefined,
			email ? {email: email} : undefined,
			!isUndefined(dba) ? {dba: dba} : undefined,
			riskRate ? {riskRate: riskRate} : undefined,
			fullName ? {fullName: fullName} : undefined
		),
	})
}

export async function updateBusinessCustomer(
	accessToken: string,
	customer: BusinessCustomer,
	{
		contactPhone,
		contactName,
		contactEmail,
		address,
		phone,
		ein,
		dba,
		stateOfIncorporation,
		riskRate,
		name,
	}: {
		contactName?: FullName
		contactPhone?: Phone
		contactEmail?: string
		address?: Address
		phone?: Phone
		ein?: string
		dba?: string | null
		stateOfIncorporation?: string
		riskRate?: RiskRate
		name?: string
	}
) {
	return await updateResource<Customer>(`customers/${customer.id}`, accessToken, {
		type: customer.type,
		attributes: Object.assign(
			{},
			address ? {address: address} : undefined,
			phone ? {phone: phone} : undefined,
			ein ? {ein: ein} : undefined,
			!isUndefined(dba) ? {dba: dba} : undefined,
			riskRate ? {riskRate: riskRate} : undefined,
			stateOfIncorporation ? {stateOfIncorporation: stateOfIncorporation} : undefined,
			{
				contact: Object.assign(
					{},
					contactName ? {fullName: contactName} : undefined,
					contactPhone ? {phone: contactPhone} : undefined,
					contactEmail ? {email: contactEmail} : undefined
				),
			},
			name ? {name: name} : undefined
		),
	})
}

export async function updateBusinessFBOCustomer(
	accessToken: string,
	customer: BusinessFBOCustomer,
	{
		businessVertical,
		numberOfEmployees,
		bankName,
		address,
		businessName,
	}: {
		businessVertical?: string
		numberOfEmployees?: string
		bankName?: string
		address?: Address
		businessName?: string
	}
) {
	return await updateResource<Customer>(`customers/${customer.id}`, accessToken, {
		type: customer.type,
		attributes: Object.assign(
			{},
			businessVertical ? {businessVertical: businessVertical} : undefined,
			numberOfEmployees ? {numberOfEmployees: numberOfEmployees} : undefined,
			bankName ? {bankName: bankName} : undefined,
			address ? {address: address} : undefined,
			businessName ? {businessName: businessName} : undefined
		),
	})
}

export async function updateCustomerAuthorizedUsers(
	accessToken: string,
	customer: OkDocument<Customer>,
	authorizedUsers: AuthorizedUser[]
) {
	return await updateResource<Customer>(`customers/${customer.data.id}`, accessToken, {
		type: customer.data.type,
		attributes: {
			authorizedUsers: authorizedUsers,
		},
	})
}

export async function archiveCustomer(accessToken: string, customerId: string, reason: ArchiveReason) {
	const data = {
		type: "archiveCustomer",
		attributes: {
			reason: reason,
		},
	}
	return await createResource<Customer>(`customers/${customerId}/archive`, accessToken, data)
}

export async function unarchiveCustomer(accessToken: string, customerId: string) {
	return await createResource<Customer>(`customers/${customerId}/unarchive`, accessToken, {})
}

export function isBusinessDocument(customer: OkDocument<Customer>): customer is OkDocument<BusinessCustomer> {
	return customer.data.type === "businessCustomer"
}

export function isIndividualDocument(customer: OkDocument<Customer>): customer is OkDocument<IndividualCustomer> {
	return customer.data.type === "individualCustomer"
}

export function isBusinessFBODocument(customer: OkDocument<Customer>): customer is OkDocument<BusinessFBOCustomer> {
	return customer.data.type === "businessFBOCustomer"
}

export function isIndividualWalletDocument(
	customer: OkDocument<Customer>
): customer is OkDocument<IndividualWalletCustomer> {
	return customer.data.type === "individualWalletCustomer"
}

export function isBusinessCustomer(customer: Customer): customer is BusinessCustomer {
	return customer.type === "businessCustomer"
}

export function isIndividualCustomer(customer: Customer): customer is IndividualCustomer {
	return customer.type === "individualCustomer"
}

export function isBusinessFBOCustomer(customer: Customer): customer is BusinessFBOCustomer {
	return customer.type === "businessFBOCustomer"
}

export function isIndividualWalletCustomer(customer: Customer): customer is IndividualWalletCustomer {
	return customer.type === "individualWalletCustomer"
}

export function getCustomerName(customer: Customer): string {
	if (isIndividualCustomer(customer) || isIndividualWalletCustomer(customer)) {
		return `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
	}

	return customer.attributes.name || ""
}
