import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDown} from "@fortawesome/free-solid-svg-icons"
import {CommonFilterProps} from "../Filter"
import {FilterDropdownMenu} from "../FilterDropdownMenu/FilterDropdownMenu"

export function OutlinedFilter<K>({
	dropdownClassname,
	filterRef,
	toggleDropdown,
	showFilterSummary,
	title,
	statuses,
	toggleAll,
	toggleNone,
	isSearchable,
	searchBox,
	buttons,
}: CommonFilterProps<K>) {
	return (
		<div className={dropdownClassname} ref={filterRef}>
			<div className="dropdown-trigger">
				<button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => toggleDropdown()}>
					<div className={"filter-title-placeholder"}>
						{showFilterSummary && <div className="filter-title-count" />}
						<span>
							{title} {showFilterSummary && `(${statuses.length})`}
						</span>
					</div>
					<span className="icon is-small dropdown-icon">
						{" "}
						<FontAwesomeIcon icon={faAngleDown} />{" "}
					</span>
				</button>
			</div>
			<FilterDropdownMenu
				toggleAll={toggleAll}
				toggleNone={toggleNone}
				isSearchable={isSearchable}
				searchBox={searchBox}
				buttons={buttons}
			/>
		</div>
	)
}
