import React from "react"
import Icon from "../Icon/Icon"
import {Circle, CircleSize} from "../Circle/Circle"

export function LinkArrowIcon({size = 12, left}: {size?: number | string; left?: boolean}) {
	return (
		<Circle size={CircleSize.lg} className="bg-box-link">
			<Icon icon={`keyboard-${left ? "left" : "right"}-arrow--interface-essential`} size={size} />
		</Circle>
	)
}
