import {Address} from "../../resources/common"
import React, {useMemo} from "react"
import HorizontalField from "../HorizontalField/HorizontalField"
import {InformationHiding, miltilinePreview} from "../InformationHiding/InformationHiding"

export const generateAddressPreview = (address: Address) => {
	const street2 = address.street2 ? `${address.street2}\n` : ""
	const city = address.state
		? `${address.city}, ${address.state} ${address.postalCode}\n`
		: `${address.city} ${address.postalCode}\n`

	return `${address.street}\n${street2}${city}${address.country}`
}
export default function AddressReadonlyField({address}: {address: Address}) {
	const [lines, text] = useMemo(() => {
		const lines = address.street2 ? 4 : 3
		const text = generateAddressPreview(address)
		return [lines, text]
	}, [address.street, address.street2, address.city, address.state, address.postalCode, address.country])

	return (
		<>
			<HorizontalField label="Address">
				<InformationHiding
					element={(text) => (
						<textarea readOnly value={text} rows={lines} className="textarea is-static has-fixed-size" />
					)}
					getValue={() => text}
					placeholder={miltilinePreview(text)}
				/>
			</HorizontalField>
		</>
	)
}
