import React, {PropsWithChildren} from "react"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"

interface Props {
	icon: IcomoonIconName
	iconSize?: number
}

export default function IconTag({icon, iconSize = 20, children}: PropsWithChildren<Props>) {
	return (
		<div className="icon-tag">
			{icon && <Icon icon={icon} size={iconSize} />}
			<span>{children}</span>
		</div>
	)
}
