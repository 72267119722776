import React, {ReactElement, useRef, useState} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import Customers, {CustomersColumns} from "../../components/Customers/Customers"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {TutorialModalProps} from "../../components/Tutorial/TutorialModal"
import {generateCreateApplicationRequest} from "../../components/Tutorial/TutorialPayloads"
import {TutorialButton} from "../../components/Tutorial/TutorialButton"

export function CustomersPage(): ReactElement {
	const [token, refresh] = useRefreshToken()
	const [payload, setPayload] = useState(generateCreateApplicationRequest())
	const ref = useRef(null)
	const tutorialProps: TutorialModalProps = {
		tooltipId: "customerTutorial",
		parentRef: ref,

		actions: [
			{
				actionName: "Create Application",
				title: "Test Create Customer API",
				description: "Create an application. Once approved, a customer is automatically created",
				successMessage: "Success! You can now see your new application and customer in the dashboard",
				errorMessage: "There was an error creating the application",
				request: {
					method: "POST",
					path: "applications",
					payload: payload,
					headers: [
						{headerKey: "Content-Type", headerValue: "application/vnd.api+json"},
						{headerKey: "Cache-Control", headerValue: "no-cache"},
					],
				},
				refreshFunction: refresh,
				onSendAnotherRequest: () => {
					setPayload(generateCreateApplicationRequest())
				},
			},
		],
	}

	return (
		<>
			<TitleBar title={"Customers"}>
				<TutorialButton tutorialModalProps={tutorialProps} />
			</TitleBar>

			<MainSectionTable>
				<Customers
					limit={40}
					enableTitle={false}
					fullHeight={true}
					includedColumns={[
						CustomersColumns.id,
						CustomersColumns.type,
						...(useIsUnitUser() || useIsBankUser() ? [CustomersColumns.org] : []),
						CustomersColumns.status,
						CustomersColumns.name,
						CustomersColumns.createdAt,
					]}
					refreshToken={token}
				/>
			</MainSectionTable>
		</>
	)
}
