import React, {useEffect, useRef} from "react"
import {useAccessToken} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {Err} from "../Err/Err"
import {Counterparty, deleteCounterparty, findCounterparties} from "../../resources/counterparty"
import {isNil, range} from "lodash"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEllipsisV, faPen, faTimes, faSitemap} from "@fortawesome/free-solid-svg-icons"
import {useClickAway, useToggle} from "react-use"
import {useModal} from "react-modal-hook"
import {ConfirmationModal} from "../ConfirmationModal/ConfirmationModal"
import AddCounterpartyModal from "./AddCounterpartyModal"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import EditCounterpartyModal from "./EditCounterpartyModal"
import Skeleton from "react-loading-skeleton"
import Icon from "../Icon/Icon"
import ViewCounterpartyModal from "./ViewCounterpartyModal"

interface Props {
	customerId: string
	token?: string
	limit?: number
	isUsingPaging?: boolean
	isArchived: boolean
}

function Pending() {
	return (
		<div>
			{range(5).map((i) => (
				<Skeleton key={i} />
			))}
		</div>
	)
}

export function CounterpartyElement({
	counterparty,
	refresh,
	isArchived,
}: {
	counterparty: Counterparty
	refresh: () => void
	isArchived: boolean
}) {
	const isComplianceUser = true
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [isActive, toggleIsActive] = useToggle(false)
	const [deleteState, del] = useAsyncResultIdle(deleteCounterparty)

	const [showEditCounterpartyModal, hideEditCounterpartyModal] = useModal(
		() => <EditCounterpartyModal counterparty={counterparty} close={hideEditCounterpartyModal} refresh={refresh} />,
		[refresh]
	)

	const [showCounterpartyModal, hideCounterpartyModal] = useModal(
		() => <ViewCounterpartyModal counterparty={counterparty} close={hideCounterpartyModal} />,
		[refresh]
	)

	const ref = useRef(null)

	useEffect(() => {
		deleteState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(e.errors[0].title)
		)
	}, [deleteState])

	const [showCloseConfirmationModal, hideCloseConfirmationModal] = useModal(
		() => (
			<ConfirmationModal
				title="Remove counterparty"
				close={hideCloseConfirmationModal}
				okButtonText="Remove this counterparty"
				cancelButtonClassname="button is-white"
				cancelButtonText="Cancel"
				okButtonClassname={"button is-danger is-outlined"}
				onSubmit={() => {
					del(accessToken, counterparty)
				}}
			>
				Are you sure you want to remove <b>{'"' + counterparty.attributes.name + '"'}</b> from counterparties?
			</ConfirmationModal>
		),
		[counterparty]
	)

	useClickAway(ref, () => toggleIsActive(false))
	return (
		<div className="counterparty-container" onClick={showCounterpartyModal}>
			<div className="inside-wrapper">
				<div className="name-type-wrapper">
					<div className="counterparty-account-type">
						{counterparty.attributes.accountType == "Savings" && <Icon icon="saving-profit-interest" size={18} />}
						{counterparty.attributes.accountType == "Checking" && (
							<Icon icon="money-transfer--business-products" size={18} />
						)}
						<span className="counterparty-account-type-title">{counterparty.attributes.accountType.toUpperCase()}</span>
						<span className="counterparty-account-type-id">#{counterparty.id}</span>
					</div>
					<div className="counterparty-name">{counterparty.attributes.name}</div>
				</div>
				<div className="permissions-wrapper">
					<div className="permissions">{counterparty.attributes.permissions.replace(/([A-Z])/g, " $1").trim()}</div>
				</div>
			</div>
			{!isArchived ? (
				<UnitUserOnly>
					<div
						className={classNames("dropdown-options-menu", !isActive && "hide")}
						ref={ref}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							toggleIsActive()
						}}
					>
						<div className={classNames("dropdown is-right is-vertical-middle custom", isActive && "is-active")}>
							<div className="dropdown-trigger">
								<FontAwesomeIcon icon={faEllipsisV} />
							</div>
							<div className="dropdown-menu" role="menu">
								<div className="dropdown-content">
									{isComplianceUser ? (
										<a
											className={classNames("dropdown-item")}
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
												toggleIsActive(false)
												showEditCounterpartyModal()
											}}
										>
											<div className="is-flex is-align-items-center">
												<FontAwesomeIcon fixedWidth className="mr-2" icon={faPen} />
												<span>Edit Counterparty</span>
											</div>
										</a>
									) : null}
									<a
										className={classNames("dropdown-item")}
										onClick={(e) => {
											e.preventDefault()
											e.stopPropagation()
											toggleIsActive(false)
											showCloseConfirmationModal()
										}}
									>
										<div className="is-flex is-align-items-center remove-button">
											<FontAwesomeIcon fixedWidth className="mr-2" icon={faTimes} />
											<span>Remove Counterparty</span>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</UnitUserOnly>
			) : null}
		</div>
	)
}

export function Counterparties({
	counterparties,
	customerId,
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	isUsingPaging,
	refresh,
	isArchived,
}: {
	counterparties: Array<Counterparty>
	customerId: string
	hasResults?: boolean
	hasPrev?: boolean
	hasNext?: boolean
	prev?: () => void
	next?: () => void
	isUsingPaging: boolean
	refresh: () => void
	isArchived: boolean
}) {
	const [showAddCounterpartyModal, hideAddCounterpartyModal] = useModal(() => (
		<AddCounterpartyModal customerId={customerId} close={hideAddCounterpartyModal} refresh={refresh} />
	))

	return (
		<div>
			<div className={classNames("customer-counterparties-container", isArchived ? "status-archived" : "")}>
				{counterparties.length == 0 ? (
					<div className="empty-wrapper">
						<FontAwesomeIcon className="plus-icon" icon={faSitemap} />
						There are no Counterparties
					</div>
				) : null}
				{counterparties.map((a) => (
					<CounterpartyElement counterparty={a} refresh={refresh} key={a.id} isArchived={isArchived} />
				))}
				{!isArchived ? (
					<UnitUserOnly>
						<div className="button-wrapper">
							<button className="add-button button" onClick={() => showAddCounterpartyModal()}>
								<Icon className="plus-icon" icon="interface-add-1" size={12} /> {"Add Counterparty"}
							</button>
						</div>
					</UnitUserOnly>
				) : null}
			</div>
			{isUsingPaging && !isNil(hasResults) && !isNil(hasPrev) && !isNil(hasNext) && !isNil(prev) && !isNil(next) ? (
				<PagingNavBar
					hasResults={hasResults}
					hasPrev={hasPrev}
					hasNext={hasNext}
					prev={prev}
					next={next}
					isShow={isUsingPaging}
				/>
			) : null}
		</div>
	)
}

export default function ({customerId, token, limit = 5, isUsingPaging = false, isArchived}: Props) {
	const accessToken = token ?? useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()

	const [result, hasPrev, hasNext, prev, next, hasResults] = usePaging(
		limit,
		(offset, limit) => findCounterparties(accessToken, offset, limit, customerId),
		(x) => x.data.length,
		[refreshToken],
		"counterparties-"
	)

	return result.match(
		() => <Pending />,
		(counterparties) => (
			<Counterparties
				counterparties={counterparties.data}
				customerId={customerId}
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isUsingPaging={isUsingPaging}
				refresh={refresh}
				isArchived={isArchived}
			/>
		),
		(err) => <Err err={err} />
	)
}
