import {useAccessToken, useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import React, {useEffect, useState} from "react"
import TitleBar, {TitleBarAction} from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import {
	BusinessCustomer,
	CustomerStatus,
	CustomerType,
	CustomerTypeIcons,
	updateBusinessCustomer,
} from "../../resources/customer"
import Card from "../../containers/Card/Card"
import {emailPreview} from "../../components/InformationHiding/InformationHiding"
import TagsViewer from "../../components/Tags/TagsViewer"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {EditableRiskRateField, RiskRateField} from "../../components/RiskRateField/RiskRateField"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	Editable,
	EditableAddress,
	EditableFullName,
	EditableHiddenString,
	EditableOptionalString,
	EditablePhone,
	EditableString,
	OptionalEditable,
} from "../../components/HorizontalField/EditableHorizontalField"
import {isUndefined, startCase} from "lodash"
import {stateRegex} from "../../utilities/validation"
import {AuthorizedUsers} from "../CustomerAuthorizedUsers/CustomerAuthorizedUsers"
import {hasPermission} from "../../services/permission"
import CustomerKnownNamesComponent from "../../components/CustomerKnownNames/CustomerKnownNames"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {CustomerKnownNames, getCustomerKnownNames} from "../../resources/customerKnownNames"
import CustomerCounterparties from "../../components/CustomerCounterParties/CustomerCounterparties"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {useModal} from "react-modal-hook"
import {ArchiveCustomerModal} from "../Customer/ArchiveCustomerModal"
import {UnarchiveCustomerModal} from "../Customer/UnarchiveCustomerModal"
import {Disputes, DisputesColumns} from "../../components/Disputes/Disputes"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import {isOrg, Org} from "../../resources/org"
import {BusinessAdditionalInformationFields} from "../../components/BusinessAdditionalInformationFields/BusinessAdditionalInformationFields"
import {Application, BusinessApplication, getApplication} from "../../resources/application"
import {AsyncResult} from "../../types/asyncResult"
import {SpecificCustomerProps} from "../Customer/Customer"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import {getAuditLogEventsHeaderMetaItems} from "../Customer/auditLogMetaItems"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"

export default function BusinessPage({value: customer, refresh}: SpecificCustomerProps<BusinessCustomer>) {
	const accessToken = useAccessToken()
	const isUnitUser = useIsUnitUser()
	const {addToast} = useToasts()
	const business = customer.data
	const {attributes} = business
	const {contact} = attributes
	const org = customer.included?.find((r) => isOrg(r) && r.id == business.relationships.org.data.id)
	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)
	const [address, setAddress] = useState(attributes.address)
	const [phone, setPhone] = useState(attributes.phone)
	const [ein, setEin] = useState(attributes.ein)
	const [dba, setDba] = useState(attributes.dba)
	const [stateOfIncorporation, setStateOfIncorporation] = useState(attributes.stateOfIncorporation)
	const [riskRate, setRiskRate] = useState(attributes.riskRate)
	const [overrideRiskRate, setOverrideRiskRate] = useState(attributes.overrideRiskRate)
	const [contactName, setContactName] = useState(contact.fullName)
	const [contactEmail, setContactEmail] = useState(contact.email)
	const [contactPhone, setContactPhone] = useState(contact.phone)
	const [name, setName] = useState(attributes.name)
	const [updateState, updateApp] = useAsyncResultIdle(updateBusinessCustomer)
	const [refreshToken] = useRefreshToken()
	const isArchived = attributes.status == CustomerStatus.Archived
	const isCustomerEditable = !isArchived
	const isHiddenIconVisible = !useIsUnitComplianceUser()
	const [showArchiveCustomer, hideArchiveCustomer] = useModal(() => (
		<ArchiveCustomerModal closeModal={hideArchiveCustomer} customer={business} onSuccess={refresh} />
	))
	const [showUnarchiveCustomer, hideUnarchiveCustomer] = useModal(() => (
		<UnarchiveCustomerModal closeModal={hideUnarchiveCustomer} customer={business} onSuccess={refresh} />
	))
	const customerKnownNamesResult = isUnitUser
		? useAsyncResult<OkDocument<CustomerKnownNames>, ErrorDocument>(
				() => getCustomerKnownNames(accessToken, business.id),
				[refreshToken]
		  )
		: null
	const applicationId = business.relationships.application?.data.id
	const application = useAsyncResult(
		() =>
			applicationId
				? getApplication(accessToken, applicationId)
				: new Promise(() => AsyncResult.pending<Application, ErrorDocument>()),
		[refreshToken]
	)
	const applicationLink = business.relationships.application
		? `/applications/business/${business.relationships.application.data.id}`
		: undefined

	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Customer Updated Successfully", {appearance: "success"})
			setIsUpdating(false)
			refresh()
		} else if (updateState.isErr()) {
			addToast(updateState.error.errors[0].detail || updateState.error.errors[0].title, {appearance: "error"})
			setIsUpdating(false)
			refresh()
		}
	}, [updateState])

	const dbaComponent = (
		<Editable
			label="Doing Business As"
			isEditing={isEditing === "DBA"}
			isDisabled={!isUndefined(isEditing)}
			isAllowed={isCustomerEditable && hasPermission("customer", "update")}
			isUpdating={isUpdating}
			onStartEdit={() => setIsEditing("DBA")}
			onCancel={refresh}
			setValue={setDba}
			value={dba}
			initial={dba}
			update={() => {
				updateApp(accessToken, business, {dba: dba === "" ? null : dba})
				setIsUpdating(true)
			}}
			pattern={"^.*$"}
			className={isArchived ? "archived-field" : ""}
			required={false}
		>
			{EditableOptionalString}
		</Editable>
	)

	const customerType = CustomerType.business
	const customerTypeDisplayText = startCase(customerType)
	const customerTypeIcon = CustomerTypeIcons[customerType]
	const auditLogEventsHeaderMetaItems = getAuditLogEventsHeaderMetaItems({
		customerName: name,
		customerType: customerTypeDisplayText,
		pageIcon: customerTypeIcon,
		org: org as Org,
	})
	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()
	return (
		<>
			<TitleBar
				title={name}
				breadcrumbs={[{text: "Customers", path: "/customers/"}]}
				meta={[
					{text: customerTypeDisplayText, icon: customerTypeIcon},
					{text: "Application", icon: "user-id-card", path: applicationLink},
				]}
				status={isArchived ? {text: "Archived", color: "#B0B2B5", backgroundColor: "#EBECEE"} : undefined}
			>
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				{attributes.status != CustomerStatus.Archived ? (
					<HasPermission resource="customer.status" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Archive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showArchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				) : (
					<HasPermission resource="customer.status.unarchive" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Unarchive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showUnarchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				)}
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="customer"
					resourceId={business.id}
				/>
				<div className="columns">
					<div className="column is-4">
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Contact</p>
									</div>
									<div className="card-content">
										<UnitUserOnly>
											{optionalHorizontalField(
												"Org",
												org && org.type == "org" ? org.attributes.name : undefined,
												undefined,
												isArchived ? "archived-field" : ""
											)}
										</UnitUserOnly>
										<Editable
											label="Name"
											isEditing={isEditing === "Contact Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Contact Name")}
											onCancel={refresh}
											setValue={setContactName}
											value={contactName}
											initial={contactName}
											update={() => {
												updateApp(accessToken, business, {contactName: contactName})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableFullName}
										</Editable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Contact Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Contact Phone")}
											onCancel={refresh}
											setValue={setContactPhone}
											value={contactPhone}
											initial={contactPhone}
											update={() => {
												updateApp(accessToken, business, {contactPhone: contactPhone})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										<OptionalEditable
											label="Email"
											isEditing={isEditing === "Contact Email"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Contact Email")}
											onCancel={refresh}
											setValue={setContactEmail}
											value={contactEmail}
											initial={contactEmail}
											update={() => {
												updateApp(accessToken, business, {contactEmail: contactEmail})
												setIsUpdating(true)
											}}
											inputType="email"
											previewFunction={emailPreview}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Business Information</p>
									</div>
									<div className="card-content">
										<HorizontalField label="Customer Id" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" className="input is-static" readOnly value={business.id} />
										</HorizontalField>
										<Editable
											label="Name"
											isEditing={isEditing === "Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && isUnitUser}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Name")}
											onCancel={refresh}
											setValue={setName}
											value={name}
											initial={name}
											update={() => {
												updateApp(accessToken, business, {name: name})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>
										<UnitUserOnly>
											{overrideRiskRate ? (
												<>
													<RiskRateField riskRate={riskRate} />
													<EditableRiskRateField
														label="Override Risk Rate"
														riskRate={overrideRiskRate}
														setRiskRate={setOverrideRiskRate}
														isEditing={isEditing === "RiskRate"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={isCustomerEditable && isUnitUser}
														onStartEdit={() => setIsEditing("RiskRate")}
														onCancel={refresh}
														update={() => {
															updateApp(accessToken, business, {riskRate: overrideRiskRate})
															setIsUpdating(true)
														}}
														className={isArchived ? "archived-field" : ""}
													/>
												</>
											) : (
												riskRate && (
													<EditableRiskRateField
														riskRate={riskRate}
														setRiskRate={setRiskRate}
														isEditing={isEditing === "RiskRate"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={isCustomerEditable && isUnitUser}
														onStartEdit={() => setIsEditing("RiskRate")}
														onCancel={refresh}
														update={() => {
															updateApp(accessToken, business, {riskRate: riskRate})
															setIsUpdating(true)
														}}
														className={isArchived ? "archived-field" : ""}
													/>
												)
											)}
										</UnitUserOnly>
										{dbaComponent}
										<HorizontalField label="Entity Type" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" readOnly value={attributes.entityType} className="input is-static" />
										</HorizontalField>
										<OptionalEditable
											label="EIN"
											isEditing={isEditing === "EIN"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && isUnitUser}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("EIN")}
											onCancel={refresh}
											setValue={setEin}
											value={ein}
											initial={ein}
											update={() => {
												updateApp(accessToken, business, {ein: ein})
												setIsUpdating(true)
											}}
											pattern="^\d{9}$"
											className={isArchived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
										<Editable
											label="Incorporation State"
											isEditing={isEditing === "Incorporation State"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && isUnitUser}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Incorporation State")}
											onCancel={refresh}
											setValue={setStateOfIncorporation}
											value={stateOfIncorporation}
											initial={stateOfIncorporation}
											update={() => {
												updateApp(accessToken, business, {stateOfIncorporation: stateOfIncorporation})
												setIsUpdating(true)
											}}
											pattern={stateRegex}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Phone")}
											onCancel={refresh}
											setValue={setPhone}
											value={phone}
											initial={phone}
											update={() => {
												updateApp(accessToken, business, {phone: phone})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										<OptionalEditable
											label="Address"
											isEditing={isEditing === "Address"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Address")}
											onCancel={refresh}
											setValue={setAddress}
											value={address}
											initial={address}
											update={(v) => {
												updateApp(accessToken, business, {address: v})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableAddress}
										</OptionalEditable>
										{optionalHorizontalField(
											"Archive Reason",
											startCase(attributes.archiveReason),
											undefined,
											isArchived ? "archived-field" : ""
										)}

										{application.match(
											() => null,
											(d) => (
												<BusinessAdditionalInformationFields
													application={d as OkDocument<BusinessApplication>}
													isArchived={isArchived}
												/>
											),
											() => (
												<div>Failed to fetch application</div>
											)
										)}
									</div>
								</div>
							</div>
						</div>

						<Card title="Counterparties">
							<CustomerCounterparties customerId={business.id} limit={100} isArchived={isArchived} />
						</Card>

						{Object.keys(attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={attributes.tags} fieldClassName={"archived-field"} />
										</div>
									</div>
								</div>
							</div>
						) : null}
						{customerKnownNamesResult?.match(
							() => null,
							(aliases) => (
								<div className="columns">
									<div className="column">
										<div className="card">
											<div className="card-header">
												<p className="card-header-title">Known Aliases</p>
											</div>
											<div className="card-content">
												<CustomerKnownNamesComponent
													customerId={business.id}
													names={aliases.data.attributes.names}
													refresh={refresh}
													fieldClassName={"archived-field"}
												/>
											</div>
										</div>
									</div>
								</div>
							),
							() => null
						)}
					</div>
					<div className="column">
						<Accounts
							customerId={business.id}
							enableTitle
							fullHeight={false}
							includedColumns={[
								AccountsColumns.id,
								...(isUnitUser ? [AccountsColumns.bank] : []),
								AccountsColumns.balance,
								AccountsColumns.type,
								AccountsColumns.product,
								AccountsColumns.status,
								AccountsColumns.routingNumber,
								AccountsColumns.accountNumber,
								AccountsColumns.purpose,
								AccountsColumns.createdAt,
							]}
						/>

						<Transactions
							fullHeight={false}
							customerId={business.id}
							limit={10}
							isUsingPaging={true}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.type,
								TransactionsColumns.amount,
								TransactionsColumns.balance,
								TransactionsColumns.summary,
								TransactionsColumns.createdAt,
							]}
						/>

						<Disputes
							enableTitle={true}
							fullHeight={false}
							limit={10}
							customerId={business.id}
							includedColumns={[
								DisputesColumns.id,
								DisputesColumns.source,
								DisputesColumns.amount,
								DisputesColumns.createdAt,
								DisputesColumns.status,
								DisputesColumns.updatedAt,
							]}
						/>

						<AuthorizedUsers customerId={business.id} />
					</div>
				</div>
			</MainSection>
		</>
	)
}
