import {AccountStatus, AccountStatusAudit} from "../../resources/account"
import moment from "moment/moment"
import BodyPortal from "../../components/BodyPortal/BodyPortal"
import ReactTooltip from "react-tooltip"
import React from "react"
import Icon from "../../components/Icon/Icon"

export function StatusChangeTooltip({
	status,
	accountStatusAudit,
}: {
	status: AccountStatus
	accountStatusAudit: AccountStatusAudit
}) {
	const adjustedStatus = status == AccountStatus.Open ? "Opened" : status
	const setAt = accountStatusAudit.attributes.setAt ? moment(accountStatusAudit.attributes.setAt).format("ll") : null
	return (
		<span className={"flex-container flex-center margin-l-4"}>
			<Icon
				icon="information-circle--interface-essential"
				data-tip="React-tooltip"
				data-for="status-audit-tooltip"
				size={12}
			/>
			<BodyPortal>
				<ReactTooltip
					backgroundColor={"#2B2C2F"}
					class="status-audit-tooltip tooltip-info"
					place="bottom"
					effect="solid"
					data-html={true}
					id="status-audit-tooltip"
				>
					<div className={"flex-container flex-dir-col gap-4"}>
						<div className={"status-description"}>{adjustedStatus} by</div>
						<div>
							<div>{accountStatusAudit.attributes.setBy}</div>
							<div className={"set-at"}>{setAt}</div>
						</div>
					</div>
				</ReactTooltip>
			</BodyPortal>
		</span>
	)
}
