import {useAccessToken} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {reprocessIncomingAch} from "../../resources/incomingAch"
import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {IncomingAch} from "../../resources/incomingAch"

interface MissingReturnPaymentIncomingAchProps {
	incomingAch: IncomingAch
	close: () => void
	onSuccess: () => void
}

export function MissingReturnPaymentIncomingAch({incomingAch, close, onSuccess}: MissingReturnPaymentIncomingAchProps) {
	const accessToken = useAccessToken()
	const [state, reprocessIncomingAchAction] = useAsyncResultIdle(reprocessIncomingAch)
	const [paymentId, setPaymentId] = useState<string>("")
	const refreshAndClose = () => {
		onSuccess()
		close()
	}

	return (
		<AsyncResultModal
			title="Reprocess Incoming Return Ach"
			close={refreshAndClose}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSubmit={() => {
				reprocessIncomingAchAction(accessToken, incomingAch.id, paymentId)
			}}
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
		>
			<div className="field">
				<label className="label">Payment Id</label>
				<div className="control">
					<input
						autoFocus
						required
						className="input"
						type="text"
						pattern="^[0-9]*$"
						value={paymentId}
						onChange={(e) => setPaymentId(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
