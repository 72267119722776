import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react"
import {Claims, useAccessToken, useIsBankUser, useIsOrgUser, useIsUnitUser, useUserType} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import numeral from "numeral"
import {kebabCase, startCase, upperCase, upperFirst} from "lodash"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import {useQueryState} from "use-location-state"
import {Filter} from "../Filter/Filter"
import {useLocation, useNavigate} from "react-router-dom"
import ReactTooltip from "react-tooltip"
import {
	clearAchPayment,
	findPayments,
	getExpectedCompletionTimeField,
	isAchPayment,
	isWirePayment,
	Payment,
	PaymentDirection,
	PaymentFeature,
	PaymentStatus,
	PaymentStatusPhase,
	PaymentType,
	uploadBulkWires,
} from "../../resources/payment"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import moment from "moment"
import classNames from "classnames"
import {useModal} from "react-modal-hook"
import {ClearConfirmationModal, isValidForClearing} from "./ClearAchPaymentHelper"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"
import {Cents} from "../../resources/transaction"
import {CurrencyRangeFilter} from "../Filter/CurrencyRangeFilter"
import Drawer from "../Drawer/Drawer"
import {PaymentComponent} from "./Payment"
import {OrgName} from "../OrgName/OrgName"
import {downloadFile} from "../../utilities/file"
import {BankName} from "../BankName/BankName"
import {Bank, findBanks} from "../../resources/bank"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircleNotch, faUpload} from "@fortawesome/free-solid-svg-icons"
import {UnitAdminOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {isProdEnv, isSandboxOrLocalEnv} from "../../utilities/environment"
import {PaymentTabConstants, paymentTabQueryStateName} from "../../pages/Payments/Payment"

export enum PaymentColumns {
	id = "Id",
	org = "Org",
	bank = "Bank",
	type = "Type",
	accountId = "Account",
	customer = "Customer",
	amount = "Amount",
	status = "Status",
	createdAt = "Created At",
	actions = "Actions",
	reason = "Reason",
	expectedCompletionDate = "Expected Completion Date",
}

type AllowedPaymentColumns =
	| PaymentColumns.id
	| PaymentColumns.org
	| PaymentColumns.bank
	| PaymentColumns.type
	| PaymentColumns.accountId
	| PaymentColumns.customer
	| PaymentColumns.amount
	| PaymentColumns.status
	| PaymentColumns.createdAt
	| PaymentColumns.actions
	| PaymentColumns.reason
	| PaymentColumns.expectedCompletionDate

interface PaymentsProps {
	accountId?: string
	token?: string
	limit?: number
	includedColumns: AllowedPaymentColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	showTabsOnHeader?: boolean
	enableDirectionFilter?: boolean
	enableOrgFilter?: boolean
	enableBankFilter?: boolean
	enableStatusFilter?: boolean
	enableTypeFilter?: boolean
	enableStatusPhasesFilter?: boolean
	enableAmountFilter?: boolean
	enableFeaturesFilter?: boolean
	enableDateFilter?: boolean
	disableDrawer?: boolean
	enablePaging?: boolean
	paymentTypes?: PaymentType[]
	enableExport?: boolean
	title?: string
	claims?: Claims
	className?: string
	titleClassName?: string
	hideMinHeight?: boolean
	disabled?: boolean
	showUploadWireFileButton?: boolean
}

interface PaymentsTableProps {
	payments: Payment[]
	selectedPaymentId: string
	setSelectedPaymentId: (id: string) => void
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedPaymentColumns[]
	meta?: Meta
	refresh: () => void
	fullHeight?: boolean
	showTabsOnHeader?: boolean
	sortFunction: () => void
	sortBy: string
	disableDrawer?: boolean
	hideMinHeight?: boolean
	disabled?: boolean
	token?: string
}

interface PaymentRowProps {
	payment: Payment
	include?: Resource[]
	showOrgColumn?: boolean
	includedColumns: Array<AllowedPaymentColumns>
	refresh: () => void
	selectedPaymentId?: string
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

function PaymentRow({payment, include, includedColumns, refresh, selectedPaymentId, onClick}: PaymentRowProps) {
	const navigate = useNavigate()
	const id = payment.id
	const org = payment.relationships.org?.data.id
	const bank = payment.relationships.bank?.data.id
	const typeCasingFunction = isAchPayment(payment) ? upperCase : startCase
	const type =
		isAchPayment(payment) && payment.attributes.direction === PaymentDirection.Debit
			? `${typeCasingFunction(payment.type.replace("Payment", ""))} ${payment.attributes.direction}`
			: typeCasingFunction(payment.type.replace("Payment", ""))
	const customerId = payment.relationships.customer?.data.id
	const accountId = payment.relationships.account.data.id
	const status = payment.attributes.status
	const reason = payment.attributes.reason
	const createdAt = payment.attributes.createdAt
	const amount = payment.attributes.amount
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [state, clear] = useAsyncResultIdle(clearAchPayment)

	useEffect(() => {
		state.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(e.errors[0].title)
		)
	}, [state])

	const [showClearingConfirmationModal, hideClearingConfirmationModal] = useModal(
		() => (
			<ClearConfirmationModal
				payment={payment}
				close={hideClearingConfirmationModal}
				onSubmit={() => {
					clear(accessToken, payment.id)
				}}
			/>
		),
		[]
	)

	const customer =
		customerId && include
			? include.find(
					(r) =>
						(r.type == "individualCustomer" || r.type == "businessCustomer" || r.type == "businessFBOCustomer") &&
						r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer" || customer.type == "businessFBOCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedPaymentColumns, ReactElement>> = {}

	if (includedColumns.includes(PaymentColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames(id == selectedPaymentId && "selected-data-table-id", "data-table-id-cell")}>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} />
	}

	if (includedColumns.includes(PaymentColumns.bank) && bank) {
		contentColumns["Bank"] = (
			<DataTableCell>
				<BankName bankId={bank.toString()} included={include} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.type)) {
		contentColumns["Type"] = <DataTableCell>{type} </DataTableCell>
	}

	if (includedColumns.includes(PaymentColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<span className="payments-amount">{numeral(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.status)) {
		let tooltipValue: string | undefined = undefined
		if (reason) {
			tooltipValue = startCase(reason)
		}

		contentColumns["Status"] = (
			<DataTableCell>
				<div
					data-tip="React-tooltip"
					data-for={`status-reason-tooltip-${id}`}
					className={classNames("payments-status", kebabCase(status))}
				>
					{startCase(status)}

					<ReactTooltip
						className="tooltip-info"
						id={`status-reason-tooltip-${id}`}
						disable={!tooltipValue}
						effect="solid"
					>
						{tooltipValue}
					</ReactTooltip>
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(PaymentColumns.expectedCompletionDate)) {
		const creditExpectedCompletionTimeTooltipValue =
			"The funds from ACH credits that are originated before the daily cutoff, are received by Unit and released to the customer on the next business day"
		const debitExpectedCompletionTimeTooltipValue =
			"The funds from ACH debits that are originated before the daily cutoff, are received by Unit on the next business day, and held for the clearing period defined on the account’s deposit product"

		let expectedCompletionTimeValue = "-"
		let showToolTip = false

		let expectedCompletionTimeField: Date | undefined = undefined
		if (isAchPayment(payment)) {
			expectedCompletionTimeField = getExpectedCompletionTimeField(payment)
			if (expectedCompletionTimeField) {
				expectedCompletionTimeValue = moment(expectedCompletionTimeField).format("MM/DD/YYYY")
				showToolTip = true
			}
		}

		let expectedCompletionTimeTooltipValue: string | undefined = undefined
		if (isAchPayment(payment) && showToolTip) {
			if (payment.attributes.direction == "Credit") {
				expectedCompletionTimeTooltipValue = creditExpectedCompletionTimeTooltipValue
			} else {
				expectedCompletionTimeTooltipValue = debitExpectedCompletionTimeTooltipValue
			}
		}

		contentColumns["Expected Completion Date"] = (
			<DataTableCell>
				<span className="is-flex is-align-items-center ml-1">
					<span data-tip="React-tooltip" data-for={`payment-expected-completion-time-tooltip-${id}`}>
						{expectedCompletionTimeValue}
					</span>
					<ReactTooltip
						className="tooltip-info"
						id={`payment-expected-completion-time-tooltip-${id}`}
						disable={!showToolTip}
						effect="solid"
					>
						{expectedCompletionTimeTooltipValue}
					</ReactTooltip>
				</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(PaymentColumns.actions)) {
		contentColumns["Actions"] = (
			<DataTableActionButton enableActions={isValidForClearing(payment)}>
				{isValidForClearing(payment) ? (
					<>
						<DataTableActionItem
							title={"Clear Payment"}
							icon={"money-fund-move-found--business-products"}
							onClick={() => showClearingConfirmationModal()}
						/>
					</>
				) : null}
			</DataTableActionButton>
		)
	}

	if (includedColumns.includes(PaymentColumns.reason)) {
		contentColumns["Reason"] = <DataTableCell>{reason ? startCase(reason) : " - "}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			className={classNames(id === selectedPaymentId && "selected-data-table-row")}
			onClick={(e) => onClick && onClick(e)}
		>
			{content}
		</DataTableRow>
	)
}

export function PaymentsTable({
	payments,
	selectedPaymentId,
	setSelectedPaymentId,
	fullHeight,
	showTabsOnHeader,
	includedColumns,
	hasNext,
	hasPrev,
	hasResults,
	isUsingPaging,
	include,
	refresh,
	meta,
	prev,
	next,
	sortFunction,
	disableDrawer = false,
	sortBy,
	hideMinHeight,
	disabled,
	token,
}: PaymentsTableProps) {
	const navigate = useNavigate()
	const location = useLocation()
	const accessToken = token ?? useAccessToken()
	const isUnitUser = useIsUnitUser()
	const noContent = payments.length === 0
	const [isDrawerOpen, setIsDrawerOpen] = useState(!!selectedPaymentId)
	const ref = useRef(null)
	const isRedirectedFromAssignments = location.pathname.includes("/assignments")

	const toggleDrawer = (reopenWithId = false) => {
		setIsDrawerOpen((prevState) => !prevState)
		if (reopenWithId) {
			setTimeout(() => {
				setIsDrawerOpen(true)
			}, 200)
		}
	}
	useEffect(() => {
		if (!selectedPaymentId) {
			setIsDrawerOpen(false)
		}
	}, [selectedPaymentId])

	return (
		<div ref={ref} className={"payments-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				stickyActionWithTabsOnHeader={fullHeight && showTabsOnHeader}
				noContentText={"No payments found"}
				hideMinHeight={hideMinHeight}
				disabled={disabled}
			>
				<DataTableHead className={classNames(!fullHeight && "no-sticky")}>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === PaymentColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}

							const columnHeader = column[1] === PaymentColumns.id ? upperCase(column[1]) : column[1]

							return <DataTableCell key={column[0]}>{columnHeader}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{payments.map((payment) => (
						<PaymentRow
							payment={payment}
							key={payment.id}
							include={include}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								const sandboxAndLocalOrgIds = ["3475", "2654", "5297", "5314", "5326", "5328", "5329", "5335", "5336"]
								const sandboxAndLocalBankIds = ["20", "26"]
								const showAssignmentsOnSandBox =
									isSandboxOrLocalEnv() &&
									(sandboxAndLocalOrgIds.includes(payment.relationships.org?.data.id ?? "") ||
										sandboxAndLocalBankIds.includes(payment.relationships.bank?.data.id ?? "") ||
										isUnitUser) // For MS1 Only, remove when needed.
								const showAssignmentsOnProd = isProdEnv()
								const isShowAssignments =
									isWirePayment(payment) &&
									!!payment.relationships.approvals &&
									!isRedirectedFromAssignments &&
									(showAssignmentsOnSandBox || showAssignmentsOnProd) // For MS1 Only, remove when needed.
								if (!disableDrawer && !isShowAssignments) {
									setSelectedPaymentId(payment.id)
									toggleDrawer(true)
									return
								}
								navigate(
									isShowAssignments
										? `/payments/${payment.id}/assignments#${paymentTabQueryStateName}=${PaymentTabConstants.FullWireDetails}`
										: `/payments/${payment.id}`
								)
							}}
							selectedPaymentId={selectedPaymentId}
							refresh={refresh}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>

			{!disableDrawer && (
				<Drawer
					open={isDrawerOpen}
					onClose={() => {
						toggleDrawer(false)
						setSelectedPaymentId("")
					}}
					direction="right"
					className="check-deposits-side-drawer"
					size={"50%"}
					zIndex={33}
					blockBody={true}
					linkToCopy={`${window.location.origin}/payments/${selectedPaymentId}`}
				>
					{isDrawerOpen && (
						<PaymentComponent
							refresh={refresh}
							paymentId={selectedPaymentId}
							accessToken={accessToken}
							useDrawer={true}
						/>
					)}
				</Drawer>
			)}
		</div>
	)
}

export default function Payments({
	limit = 25,
	token,
	includedColumns,
	fullHeight,
	showTabsOnHeader,
	enableTitle,
	accountId,
	paymentTypes,
	enableDirectionFilter = false,
	enableOrgFilter = false,
	enableBankFilter = false,
	enableStatusFilter = false,
	enableTypeFilter = false,
	enableStatusPhasesFilter = false,
	enableAmountFilter = false,
	enableFeaturesFilter = false,
	disableDrawer = false,
	enableDateFilter = true,
	enablePaging = true,
	enableExport = true,
	title = "Payments",
	claims,
	className,
	titleClassName,
	hideMinHeight,
	disabled,
	showUploadWireFileButton = true,
}: PaymentsProps) {
	const prefix = "paym"
	const accessToken = token ?? useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType(claims)
	const isOrgToken = useIsOrgUser(claims)
	const {addToast} = useToasts()
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [filteredBanks, setFilteredBanks] = useQueryState<string[]>(`${prefix}-filter[banks]`, [])
	const statusesOptions = new Map(
		[
			PaymentStatus.Pending,
			PaymentStatus.PendingReview,
			PaymentStatus.Rejected,
			PaymentStatus.Clearing,
			PaymentStatus.Sent,
			PaymentStatus.Canceled,
			PaymentStatus.Returned,
		].map((v) => [v, startCase(v)])
	)
	const statusPhasesOptions = new Map(
		[
			PaymentStatusPhase.AwaitingProcessing,
			PaymentStatusPhase.WaitingForNotice,
			PaymentStatusPhase.WaitingForAcknowledgment,
			PaymentStatusPhase.Acknowledged,
		].map((v) => [v, startCase(v)])
	)
	const directionsOptions = new Map([PaymentDirection.Credit, PaymentDirection.Debit].map((v) => [v, v]))
	const typesOptions = new Map(Object.entries(PaymentType).map((t) => [upperFirst(t[1]), startCase(t[0])]))
	const featuresOptions = new Map(
		[PaymentFeature.RecurringPayment, PaymentFeature.SameDay, PaymentFeature.ClearingDaysOverride].map((v) => [
			v,
			startCase(v),
		])
	)
	const [statuses, setStatuses] = useQueryState(
		`${prefix}-filter[status]`,
		enableStatusFilter ? [isOrgToken ? PaymentStatus.Pending : PaymentStatus.PendingReview] : []
	)
	const paymentTypesToFilter = paymentTypes?.map((t) => upperFirst(t))
	const [types, setTypes] = useQueryState(`${prefix}-filter[type]`, paymentTypesToFilter ? paymentTypesToFilter : [])
	const [statusPhases, setStatusPhases] = useQueryState<string[]>(`${prefix}-filter[statusPhase]`, [])
	const [directions, setDirections] = useQueryState<string[]>(`${prefix}-filter[direction]`, [])
	const [features, setFeatures] = useQueryState<string[]>(`${prefix}-filter[feature]`, [])
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [selectedPaymentId, setSelectedPaymentId] = useQueryState("id", "")

	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {
			setSelectedPaymentId("")
		}
		return null
	}, [])

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false)
	}, [])

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findPayments(
				accessToken,
				offset,
				sortBy,
				"json",
				limit,
				statuses,
				statusPhases,
				filteredOrgs,
				filteredBanks,
				types,
				directions,
				features,
				since,
				until,
				accountId,
				fromAmount,
				toAmount
			),
		(x) => x.data.length,
		[
			refreshToken,
			since,
			until,
			sortBy,
			[...statuses, ...statusPhases, ...types, ...filteredOrgs, ...directions, ...features, ...filteredBanks].join(","),
			fromAmount,
			toAmount,
		],
		`${prefix}-`,
		true
	)

	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const banks = useIsUnitUser(claims)
		? useAsyncResult(() => findBanks(accessToken, 0, 10000), [])
		: AsyncResult.pending<Bank[], ErrorDocument>()
	const bankFilter = banks.match(
		() => null,
		(banks) => (
			<Filter
				title="Banks"
				isSearchable={true}
				setStatuses={setFilteredBanks}
				statuses={filteredBanks}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						banks
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((bank) => [bank.id, bank.attributes.name])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const directionFilter = (
		<Filter
			statuses={directions}
			setStatuses={setDirections}
			onFilterFunc={() => reset(limit)}
			options={directionsOptions}
			title="Direction"
		/>
	)

	const statusPhasesFilter = (
		<Filter
			statuses={statusPhases}
			setStatuses={setStatusPhases}
			onFilterFunc={() => reset(limit)}
			options={statusPhasesOptions}
			title="&nbsp;Status Phase &nbsp;"
		/>
	)

	const typesFilter = (
		<Filter
			statuses={types}
			setStatuses={setTypes}
			onFilterFunc={() => reset(limit)}
			options={typesOptions}
			title="Type"
		/>
	)

	const featuresFilter = (
		<Filter
			statuses={features}
			setStatuses={setFeatures}
			onFilterFunc={() => reset(limit)}
			options={featuresOptions}
			title="Feature"
		/>
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={setFromAmount}
			to={toAmount}
			setTo={setToAmount}
		/>
	)

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	async function exportPayments() {
		const response = await findPayments(
			accessToken,
			0,
			sortBy,
			"csv",
			null,
			statuses,
			statusPhases,
			filteredOrgs,
			filteredBanks,
			types,
			directions,
			features,
			since,
			until,
			accountId,
			fromAmount,
			toAmount
		)

		response.match(
			(d) => downloadFile("Payments.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
		return true
	}

	const [isFileLoading, setIsFileLoading] = useState(false)

	function onFileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files
		if (files) {
			const file = files[0]

			if (file) {
				setIsFileLoading(true)
				uploadBulkWires(accessToken, file, "text/csv", file.name)
					.then((result) => {
						setIsFileLoading(false)
						result.match(
							() =>
								addToast(`Successfully uploaded wires, summary will be sent by email.`, {
									appearance: "success",
								}),
							(errorResult) => {
								addToast(`${errorResult.errors[0].title} - ${errorResult.errors[0].detail}`, {appearance: "error"})
							}
						)
						refresh()
					})
					.catch(() => setIsFileLoading(false))
			}
		}
	}

	return (
		<DataTableCard className={"payments-card"} cardClassName={className}>
			{showUploadWireFileButton && (
				<UnitAdminOnly claims={claims}>
					<div className="file column is-right is-flex is-align-items-right">
						<a className="is-flex is-align-items-right ml-1" data-tip="React-tooltip">
							<label className="file-label">
								<input
									className="file-input"
									disabled={isFileLoading}
									type="file"
									onChange={onFileUploaded}
									accept=".text,.csv"
								/>
								<span className={classNames("file-cta", isFileLoading && "file-input-disabled")}>
									<span className="file-icon">
										<FontAwesomeIcon icon={isFileLoading ? faCircleNotch : faUpload} spin={isFileLoading} />
									</span>
									<span className="file-label">Upload Manual Wires CSV file</span>
								</span>
							</label>
						</a>
					</div>
				</UnitAdminOnly>
			)}

			<DataTableActionHeader
				enableSticky={fullHeight}
				dateTimePicker={enableDateFilter ? dateTime : null}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableBankFilter ? [bankFilter] : []),
					...(enableStatusFilter ? [statusFilter] : []),
					...(enableDirectionFilter ? [directionFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
					...(enableStatusPhasesFilter && (useIsUnitUser() || useIsBankUser()) ? [statusPhasesFilter] : []),
					...(enableTypeFilter ? [typesFilter] : []),
					...(enableFeaturesFilter ? [featuresFilter] : []),
				]}
				exportFunc={enableExport ? exportPayments : undefined}
				titleClassName={classNames(titleClassName, disabled && "disabled")}
				title={enableTitle ? title : null}
				className={classNames(showTabsOnHeader && "with-title-bar-tabs")}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: payments}) => {
					return (
						<PaymentsTable
							payments={payments.data}
							meta={payments.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={enablePaging}
							include={payments.included}
							fullHeight={fullHeight}
							showTabsOnHeader={showTabsOnHeader}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
							selectedPaymentId={selectedPaymentId}
							setSelectedPaymentId={setSelectedPaymentId}
							disableDrawer={disableDrawer}
							hideMinHeight={hideMinHeight}
							disabled={disabled}
							token={accessToken}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
