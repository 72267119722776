import React from "react"
import classNames from "classnames"

interface Props {
	value: string
	className?: string
	onChange: (e: any) => void
	label?: string
}

export default function IdentificationTypeSelect(props: Props) {
	const {value, onChange, label, className} = props
	const options = [
		{value: "ssn", text: "SSN"},
		{key: "passport", value: "passport", text: "Passport"},
		{value: "matriculaConsular", text: "Matricula Consular"},
	]

	const selectComp = (
		<div className={classNames(className)}>
			<select value={value} onChange={(e) => onChange(e.target.value)}>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.text}
					</option>
				))}
			</select>
		</div>
	)
	return label ? (
		<div className="field">
			<label className="label">{label}</label>
			{selectComp}
		</div>
	) : (
		<div className="control">{selectComp}</div>
	)
}
