import React from "react"
import {AssignmentNavigatorWithGrouping, GroupedAssignments} from "./AssignmentNavigatorWithGrouping"
import {CombinedAssignmentItem} from "../AssignmentNavigator/AssignmentNavigator"
import {AssignmentStage} from "../../resources/assignments"

interface AssignmentNavigatorGroupedByStageProps {
	assignments: CombinedAssignmentItem[]
	navigatorItemRef: React.RefObject<HTMLLIElement>
}

export function AssignmentNavigatorGroupedByStage({
	assignments,
	navigatorItemRef,
}: AssignmentNavigatorGroupedByStageProps) {
	const pendingAssignments = assignments.filter(({stage}) => stage === AssignmentStage.Pending)
	const completedAssignments = assignments.filter(({stage}) => stage === AssignmentStage.Completed)

	const groupedAssignments: GroupedAssignments[] = [
		{title: "Pending", assignment: pendingAssignments},
		{title: "Completed", assignment: completedAssignments},
	]

	return <AssignmentNavigatorWithGrouping groupedAssignments={groupedAssignments} navigatorItemRef={navigatorItemRef} />
}
