import {useAccessToken, useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import React, {useEffect, useState} from "react"
import TitleBar, {TitleBarAction} from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import {
	BusinessFBOCustomer,
	CustomerStatus,
	CustomerType,
	CustomerTypeIcons,
	updateBusinessFBOCustomer,
} from "../../resources/customer"
import Card from "../../containers/Card/Card"
import TagsViewer from "../../components/Tags/TagsViewer"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	Editable,
	EditableAddress,
	EditableString,
	OptionalEditable,
} from "../../components/HorizontalField/EditableHorizontalField"
import {hasPermission} from "../../services/permission"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {CustomerKnownNames, getCustomerKnownNames} from "../../resources/customerKnownNames"
import CustomerCounterparties from "../../components/CustomerCounterParties/CustomerCounterparties"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {useModal} from "react-modal-hook"
import {ArchiveCustomerModal} from "../Customer/ArchiveCustomerModal"
import {UnarchiveCustomerModal} from "../Customer/UnarchiveCustomerModal"
import {Disputes, DisputesColumns} from "../../components/Disputes/Disputes"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import {AuthorizedUsers} from "../CustomerAuthorizedUsers/CustomerAuthorizedUsers"
import {isUndefined, startCase} from "lodash"
import {isOrg, Org} from "../../resources/org"
import {SpecificCustomerProps} from "../Customer/Customer"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import {getAuditLogEventsHeaderMetaItems} from "../Customer/auditLogMetaItems"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"

export default function BusinessFBOPage({value: customer, refresh}: SpecificCustomerProps<BusinessFBOCustomer>) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const businessFBO = customer.data
	const {attributes} = businessFBO
	const org = customer.included?.find((r) => isOrg(r) && r.id == businessFBO.relationships.org.data.id)
	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)
	const [address, setAddress] = useState(attributes.address)
	const [businessName, setBusinessName] = useState(attributes.businessName)
	const [name] = useState(attributes.name)
	const [updateState, updateApp] = useAsyncResultIdle(updateBusinessFBOCustomer)
	const [refreshToken] = useRefreshToken()
	const [businessVertical, setBusinessVertical] = useState(attributes.businessVertical)
	const [numberOfEmployees, setNumberOfEmployees] = useState(attributes.numberOfEmployees)
	const [bankName, setBankName] = useState(attributes.bankName)
	const isArchived = attributes.status == CustomerStatus.Archived
	const isCustomerEditable = !isArchived
	!useIsUnitComplianceUser()
	const [showArchiveCustomer, hideArchiveCustomer] = useModal(() => (
		<ArchiveCustomerModal closeModal={hideArchiveCustomer} customer={businessFBO} onSuccess={refresh} />
	))
	const [showUnarchiveCustomer, hideUnarchiveCustomer] = useModal(() => (
		<UnarchiveCustomerModal closeModal={hideUnarchiveCustomer} customer={businessFBO} onSuccess={refresh} />
	))
	useIsUnitUser()
		? useAsyncResult<OkDocument<CustomerKnownNames>, ErrorDocument>(
				() => getCustomerKnownNames(accessToken, businessFBO.id),
				[refreshToken]
		  )
		: null
	const applicationLink = undefined

	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Customer Updated Successfully", {appearance: "success"})
			setIsUpdating(false)
			refresh()
		} else if (updateState.isErr()) {
			addToast(updateState.error.errors[0].detail || updateState.error.errors[0].title, {appearance: "error"})
			setIsUpdating(false)
			refresh()
		}
	}, [updateState])
	const customerType = CustomerType.businessFBO
	const customerTypeDisplayText = startCase(customerType)
	const customerTypeIcon = CustomerTypeIcons[customerType]
	const auditLogEventsHeaderMetaItems = getAuditLogEventsHeaderMetaItems({
		customerName: name,
		customerType: customerTypeDisplayText,
		pageIcon: customerTypeIcon,
		org: org as Org,
	})
	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()
	return (
		<>
			<TitleBar
				title={name}
				breadcrumbs={[{text: "Customers", path: "/customers/"}]}
				meta={[
					{text: customerTypeDisplayText, icon: customerTypeIcon},
					{text: "Application", icon: "user-id-card", path: applicationLink},
				]}
				status={isArchived ? {text: "Archived", color: "#B0B2B5", backgroundColor: "#EBECEE"} : undefined}
			>
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				{attributes.status != CustomerStatus.Archived ? (
					<HasPermission resource="customer.status" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Archive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showArchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				) : (
					<HasPermission resource="customer.status.unarchive" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Unarchive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showUnarchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				)}
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="customer"
					resourceId={businessFBO.id}
				/>
				<div className="columns">
					<div className="column is-4">
						<div className="columns">
							<div className="column"></div>
						</div>
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Business FBO Information</p>
									</div>
									<div className="card-content">
										<UnitUserOnly>
											{optionalHorizontalField(
												"Org",
												org && org.type == "org" ? org.attributes.name : undefined,
												undefined,
												isArchived ? "archived-field" : ""
											)}
										</UnitUserOnly>
										<HorizontalField label="Customer Id" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" className="input is-static" readOnly value={businessFBO.id} />
										</HorizontalField>

										<HorizontalField label="Name" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" className="input is-static" readOnly value={businessFBO.attributes.name} />
										</HorizontalField>
										<Editable
											label="Business Name"
											isEditing={isEditing === "Business Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Business Name")}
											onCancel={refresh}
											setValue={setBusinessName}
											value={businessName}
											initial={businessName}
											update={() => {
												updateApp(accessToken, businessFBO, {businessName: businessName})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>
										<Editable
											label="Business Vertical"
											isEditing={isEditing === "Business Vertical"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Business Vertical")}
											onCancel={refresh}
											setValue={setBusinessVertical}
											value={businessVertical}
											initial={businessVertical}
											update={() => {
												updateApp(accessToken, businessFBO, {businessVertical: businessVertical})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>

										<Editable
											label="Number Of Employees"
											isEditing={isEditing === "Number Of Employees"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Number Of Employees")}
											onCancel={refresh}
											setValue={setNumberOfEmployees}
											value={numberOfEmployees}
											initial={numberOfEmployees}
											update={() => {
												updateApp(accessToken, businessFBO, {numberOfEmployees: numberOfEmployees})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>

										<Editable
											label="Bank Name"
											isEditing={isEditing === "Bank Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Bank Name")}
											onCancel={refresh}
											setValue={setBankName}
											value={bankName}
											initial={bankName}
											update={() => {
												updateApp(accessToken, businessFBO, {bankName: bankName})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableString}
										</Editable>

										<OptionalEditable
											label="Address"
											isEditing={false}
											isDisabled={true}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Address")}
											onCancel={refresh}
											setValue={setAddress}
											value={address}
											initial={address}
											update={(v) => {
												updateApp(accessToken, businessFBO, {address: v})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableAddress}
										</OptionalEditable>
									</div>
								</div>
							</div>
						</div>

						<Card title="Counterparties">
							<CustomerCounterparties customerId={businessFBO.id} limit={100} isArchived={isArchived} />
						</Card>

						{Object.keys(attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={attributes.tags} fieldClassName={"archived-field"} />
										</div>
									</div>
								</div>
							</div>
						) : null}
					</div>
					<div className="column">
						<Accounts
							customerId={businessFBO.id}
							enableTitle
							fullHeight={false}
							includedColumns={[
								AccountsColumns.id,
								...(useIsUnitUser() ? [AccountsColumns.bank] : []),
								AccountsColumns.balance,
								AccountsColumns.type,
								AccountsColumns.product,
								AccountsColumns.status,
								AccountsColumns.routingNumber,
								AccountsColumns.accountNumber,
								AccountsColumns.purpose,
								AccountsColumns.createdAt,
							]}
						/>

						<Transactions
							fullHeight={false}
							customerId={businessFBO.id}
							limit={10}
							isUsingPaging={true}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.type,
								TransactionsColumns.amount,
								TransactionsColumns.balance,
								TransactionsColumns.summary,
								TransactionsColumns.createdAt,
							]}
						/>

						<Disputes
							enableTitle={true}
							fullHeight={false}
							limit={10}
							customerId={businessFBO.id}
							includedColumns={[
								DisputesColumns.id,
								DisputesColumns.source,
								DisputesColumns.amount,
								DisputesColumns.createdAt,
								DisputesColumns.status,
								DisputesColumns.updatedAt,
							]}
						/>

						<AuthorizedUsers customerId={businessFBO.id} />
					</div>
				</div>
			</MainSection>
		</>
	)
}
