import React from "react"
import {
	BusinessNumberOfEmployees,
	BusinessNumberOfEmployeesMapping,
	SoleProprietorshipNumberOfEmployees,
	SoleProprietorshipNumberOfEmployeesMapping,
} from "../../resources/application"
import {Editable, EditableDropDownField} from "../HorizontalField/EditableHorizontalField"

export interface EditableSolePropNumberOfEmployeesProps {
	numberOfEmployees?: SoleProprietorshipNumberOfEmployees
	setNumberOfEmployees: (value: SoleProprietorshipNumberOfEmployees) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: SoleProprietorshipNumberOfEmployees) => void
	className?: string
}

export function EditableSolePropNumberOfEmployeesField(props: EditableSolePropNumberOfEmployeesProps) {
	if (props.numberOfEmployees) {
		return (
			<Editable
				label="Number of Employees"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setNumberOfEmployees}
				value={props.numberOfEmployees}
				initial={props.numberOfEmployees}
				update={props.update}
				className={props.className}
				possibleValues={SoleProprietorshipNumberOfEmployeesMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}

	return null
}

export interface EditableBusinessNumberOfEmployeesProps {
	numberOfEmployees?: BusinessNumberOfEmployees
	setNumberOfEmployees: (value: BusinessNumberOfEmployees) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: BusinessNumberOfEmployees) => void
	className?: string
}

export function EditableBusinessNumberOfEmployeesField(props: EditableBusinessNumberOfEmployeesProps) {
	if (props.numberOfEmployees) {
		return (
			<Editable
				label="Number of Employees"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setNumberOfEmployees}
				value={props.numberOfEmployees}
				initial={props.numberOfEmployees}
				update={props.update}
				className={props.className}
				possibleValues={BusinessNumberOfEmployeesMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}

	return null
}
