import {ImageGalleryModal} from "../Modals/ImageGalleryModal"
import React from "react"
import classNames from "classnames"
import {kebabCase, startCase} from "lodash"

interface CheckImageModalProps {
	frontImage: JSX.Element
	backImage: JSX.Element
	status: string
	id: string
	closeModal: () => void
}

function CheckImageModalHeader({id, status}: {id: string; status: string}) {
	return (
		<div className="check-deposit-image-modal-header">
			<div className="check-deposit-image-modal-header-id"># {id}</div>

			<div className={classNames("check-deposit-status", kebabCase(status))}>{startCase(status)}</div>
		</div>
	)
}

export function FrontCheckImageModal({frontImage, backImage, closeModal, id, status}: CheckImageModalProps) {
	return (
		<ImageGalleryModal
			close={closeModal}
			gallery={[frontImage, backImage]}
			description={["Front Side", "Back Side"]}
			header={<CheckImageModalHeader id={id} status={status} />}
		/>
	)
}

export function BackCheckImageModal({backImage, frontImage, closeModal, id, status}: CheckImageModalProps) {
	return (
		<ImageGalleryModal
			close={closeModal}
			gallery={[backImage, frontImage]}
			description={["Back Side", "Front Side"]}
			header={<CheckImageModalHeader id={id} status={status} />}
		/>
	)
}
