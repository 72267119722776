import {ToastProps, ToastProvider} from "react-toast-notifications"
import classNames from "classnames"
import React, {ReactChildren, ReactElement} from "react"

export function BulmaToast(props: ToastProps) {
	const className = classNames(
		"notification",
		props.appearance === "error" && "is-danger",
		props.appearance !== "error" && "is-" + props.appearance
	)

	return (
		<div className={className}>
			{props.onDismiss ? <button className="delete" onClick={() => props.onDismiss()} /> : null}

			{props.children}
		</div>
	)
}

export function BulmaToastProvider({children}: {children: ReactChildren | ReactElement}) {
	return (
		<ToastProvider autoDismiss={true} placement="bottom-right" components={{Toast: BulmaToast}}>
			{children}
		</ToastProvider>
	)
}
