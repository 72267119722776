import Icon from "../Icon/Icon"
import React, {Dispatch, Fragment, SetStateAction, useCallback, useEffect, useState} from "react"
import classNames from "classnames"
import {
	ACTIVITY_REASONS,
	Application,
	denyApplication,
	DOCUMENT_REASONS,
	EntityDeclineReason,
	EvaluationOutcome,
	IDENTITY_REASONS,
	REASON_CODES_TO_MESSAGE,
} from "../../resources/application"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Resource} from "../../resources/common"
import Chips from "../Chips/Chips"
import {GridContainer} from "../../containers/GridContainer/GridContainer"

export default function ApplicationDeny({
	application,
	onSuccess,
	accessToken,
}: {
	application: Application
	onSuccess: () => void
	resources?: Resource[]
	accessToken: string
}) {
	const getInitialReasons = useCallback((): [Map<string, string[]>, Map<string, EvaluationOutcome>] => {
		function getSingleEntityReasons(
			reasons: Map<string, string[]>,
			entityName: string,
			reasonCodes?: string[],
			outcome?: EvaluationOutcome
		) {
			reasons.set(
				entityName,
				reasonCodes && outcome != "Approved"
					? Array.from(
							new Set(
								reasonCodes.map((r) => REASON_CODES_TO_MESSAGE.get(r)).filter((m) => m != undefined)
							) as Set<string>
					  )
					: []
			)
		}

		const reasons = new Map<string, string[]>()
		const outcome = new Map<string, EvaluationOutcome>()

		if (application.type == "individualApplication" || application.type == "businessApplication") {
			const entityName = application.type == "individualApplication" ? "Individual" : "Business"
			outcome.set(entityName, application.attributes.evaluationOutcome)
			getSingleEntityReasons(
				reasons,
				entityName,
				application.attributes.evaluationCodes,
				application.attributes.evaluationOutcome
			)
		}
		if (application.type == "businessApplication") {
			if (application.attributes.officer) {
				outcome.set("Officer", application.attributes.officer.status)
				getSingleEntityReasons(
					reasons,
					"Officer",
					application.attributes.officer?.evaluationCodes,
					application.attributes.officer.status
				)
			}
			application.attributes.beneficialOwners.forEach((bo) => {
				outcome.set(`Beneficial owner - ${bo.fullName.first} ${bo.fullName.last}`, bo.status)
				getSingleEntityReasons(
					reasons,
					`Beneficial owner - ${bo.fullName.first} ${bo.fullName.last}`,
					bo.evaluationCodes,
					bo.status
				)
			})
		}
		return [reasons, outcome]
	}, [])

	const [initialReasons, evaluationOutcome] = getInitialReasons()
	const [allReasons, setReasons] = useState(initialReasons)
	const [isLoading, setIsLoading] = useState(false)
	const [currentEntity, setCurrentEntity] = useState<string | undefined>(undefined)

	const setEntity = useCallback(
		(entity: string, reason: string) => {
			setReasons((prev) => {
				const old = prev.get(entity) ?? []
				if (old.includes(reason)) {
					return new Map<string, string[]>(
						prev.set(
							entity,
							old.filter((r) => r != reason)
						)
					)
				} else {
					return new Map<string, string[]>(prev.set(entity, old.concat(reason)))
				}
			})
		},
		[allReasons, setReasons]
	)

	const getDeclineReasons = useCallback((): EntityDeclineReason[] => {
		return Array.from(allReasons.entries())
			.map(([e, r]) => {
				return {
					entity: e,
					reasons: r,
				}
			})
			.filter((e) => e.reasons.length > 0)
	}, [allReasons])

	const [reason, setReason] = useState("")
	const [state, deny] = useAsyncResultIdle(denyApplication)

	useEffect(() => {
		state.match(
			() => null,
			() => setIsLoading(true),
			() => {
				setIsLoading(false)
				onSuccess()
			},
			() => setIsLoading(false)
		)
	}, [state])

	return (
		<div className="application-deny">
			{currentEntity ? (
				<ApplicationDenyEntityPage
					entity={currentEntity}
					setCurrentEntity={setCurrentEntity}
					setEntity={(r) => setEntity(currentEntity, r)}
					selectedReasons={allReasons.get(currentEntity) ?? ([] as string[])}
					initialReasons={initialReasons.get(currentEntity) ?? ([] as string[])}
					evaluationOutcome={evaluationOutcome.get(currentEntity)}
				/>
			) : (
				<ApplicationDenyMainPage
					initialReasons={initialReasons}
					selectedReasons={allReasons}
					evaluationOutcome={evaluationOutcome}
					application={application}
					setReason={setReason}
					reason={reason}
					setEntity={setEntity}
					setCurrentEntity={setCurrentEntity}
					denyApplication={() => deny(accessToken, application, reason, getDeclineReasons())}
					isLoading={isLoading}
				/>
			)}
		</div>
	)
}

function ApplicationDenyEntityPage(props: {
	entity: string
	setEntity: (reason: string) => void
	initialReasons: string[]
	selectedReasons: string[]
	evaluationOutcome?: EvaluationOutcome
	setCurrentEntity: Dispatch<SetStateAction<string | undefined>>
}) {
	return (
		<>
			<div className="decline-reasons-header">
				<div className="application-deny-title">
					<span>Decline Reasons for</span>
					<div className="entity-title">
						<div className={classNames("entity-outcome", getOutcomeClassname(props.evaluationOutcome))}></div>
						<div className="entity-name">{props.entity}</div>
					</div>
				</div>
			</div>
			<IndividualSingleEntity
				initialReasons={props.initialReasons}
				reasons={props.selectedReasons}
				setReason={props.setEntity}
			/>
			<div className="full-divider" />
			<div className="application-deny-bottom">
				<button className="button is-black" onClick={(_) => props.setCurrentEntity(undefined)}>
					Done
				</button>
			</div>
		</>
	)
}

function ApplicationDenyMainPage(props: {
	application: Application
	setEntity: (entity: string, reason: string) => void
	selectedReasons: Map<string, string[]>
	initialReasons: Map<string, string[]>
	evaluationOutcome: Map<string, EvaluationOutcome>
	reason: string
	setReason: Dispatch<SetStateAction<string>>
	setCurrentEntity: Dispatch<SetStateAction<string | undefined>>
	denyApplication: () => void
	isLoading: boolean
}) {
	const entityName = props.application.type == "individualApplication" ? "Individual" : "Business"

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault()
				props.denyApplication()
			}}
		>
			<div className="application-deny-title">Decline Reasons</div>
			{props.application.type === "businessApplication" ? (
				//multi entity (business)
				<MultiEntity
					reasons={props.selectedReasons}
					evaluationOutcome={props.evaluationOutcome}
					setCurrentEntity={props.setCurrentEntity}
				/>
			) : (
				//single entity (individual only)
				<IndividualSingleEntity
					initialReasons={props.initialReasons.get(entityName) ?? ([] as string[])}
					reasons={props.selectedReasons.get(entityName) ?? ([] as string[])}
					setReason={(reason) => props.setEntity(entityName, reason)}
				/>
			)}
			<div className="full-divider" />
			<div className="field decline-notes">
				<textarea
					required
					className="textarea"
					placeholder="Notes (internal)"
					value={props.reason}
					onChange={(e) => props.setReason(e.target.value)}
				/>
			</div>
			<div className="application-deny-bottom">
				<input type="submit" disabled={props.isLoading} className="button is-danger" value="Deny application" />
				<div className="decline-note">
					<Icon icon="alert-circle--interface-essential" size={16} />
					<span>Note that decline reasons will be exposed to the client</span>
				</div>
			</div>
		</form>
	)
}

function IndividualSingleEntity({
	setReason,
	reasons,
	initialReasons,
}: {
	setReason: (reason: string) => void
	reasons: string[]
	initialReasons: string[]
}) {
	return (
		<>
			<IndividualReasons initialReasons={initialReasons} selectedReasons={reasons} setSelected={setReason} />
		</>
	)
}

function MultiEntity({
	reasons,
	evaluationOutcome,
	setCurrentEntity,
}: {
	reasons: Map<string, string[]>
	evaluationOutcome: Map<string, EvaluationOutcome>
	setCurrentEntity: Dispatch<SetStateAction<string | undefined>>
}) {
	return (
		<div className="application-entities-list">
			{Array.from(reasons.keys()).map((e) => (
				<div className="application-entity" key={e}>
					<div className="entity-header">
						<div className="entity-title">
							<div className={classNames("entity-outcome", getOutcomeClassname(evaluationOutcome.get(e)))}></div>
							<div className="entity-name">{e}</div>
						</div>
						<div className="entity-edit-icon" onClick={(_) => setCurrentEntity(e)}>
							<Icon icon={"pencil-1--interface-essential"} size={16} />{" "}
						</div>
					</div>
					{reasons.get(e)?.length == 0 && evaluationOutcome.get(e) == "Approved" ? (
						<div className="auto-approved">Auto-approved</div>
					) : (
						<div className="reasons-section">
							<Chips labels={reasons.get(e) ?? []} readonly={true} />
						</div>
					)}
				</div>
			))}
		</div>
	)
}

function IndividualReasons({
	initialReasons,
	selectedReasons,
	setSelected,
}: {
	initialReasons: string[]
	selectedReasons: string[]
	setSelected: (reason: string) => void
}) {
	const documentReasons = DOCUMENT_REASONS.filter((r) => !initialReasons.includes(r))
	const identityReasons = IDENTITY_REASONS.filter((r) => !initialReasons.includes(r))
	const activityReasons = ACTIVITY_REASONS.filter((r) => !initialReasons.includes(r))

	return (
		<Fragment>
			<div className="all-reasons-section">
				{initialReasons.length > 0 ? (
					<div className="reasons-section">
						<div className="reason-subtitle">Selected based on Evaluation Flags:</div>
						<Chips labels={initialReasons} selectedChips={selectedReasons} setSelected={setSelected} />
					</div>
				) : null}
				<div className="reason-subtitle">
					{initialReasons.length > 0 ? "All Other reasons:" : "Select all relevant reasons:"}
				</div>
				<GridContainer gap="20px" className="reasons-section">
					<div>
						<div className="section-title"> Documents</div>
						<Chips labels={documentReasons} selectedChips={selectedReasons} setSelected={setSelected} />
					</div>
					<div>
						<div className="section-title"> Identity</div>
						<Chips labels={identityReasons} selectedChips={selectedReasons} setSelected={setSelected} />
					</div>
					<div>
						<div className="section-title"> Activity</div>
						<Chips labels={activityReasons} selectedChips={selectedReasons} setSelected={setSelected} />
					</div>
				</GridContainer>
			</div>
		</Fragment>
	)
}

function getOutcomeClassname(e?: EvaluationOutcome) {
	if (e) {
		if (e == "Approved") {
			return "approved"
		}
		if (e == "Denied") {
			return "denied"
		}
		if (e == "PendingReview") {
			return "pending-review"
		}
	}
	return
}
