import {Address} from "../resources/common"
import moment from "moment"

export const getEnumKeyByValue = (enumObject: Record<any, any>, value: string) => {
	return Object.keys(enumObject)[Object.values(enumObject).indexOf(value)]
}

export const b64DecodeArrayBuffer = (buffer: ArrayBuffer) => {
	return btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ""))
}

export function flattenObject(obj: Record<string, any>, prefix = "", result: Record<string, any> = {}) {
	// Preserve empty objects and arrays, they are lost otherwise
	if (prefix && typeof obj === "object" && obj !== null && Object.keys(obj).length === 0) {
		result[prefix] = Array.isArray(obj) ? [] : {}
		return result
	}

	prefix = prefix ? prefix + "." : ""

	for (const i in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, i)) {
			if (typeof obj[i] === "object" && obj[i] !== null) {
				// Recursion on deeper objects
				flattenObject(obj[i], prefix + i, result)
			} else {
				result[prefix + i] = obj[i]
			}
		}
	}
	return result
}

export function addressText(address: Address) {
	const street2 = address.street2 ? `${address.street2}\n` : ""
	const city = address.state
		? `${address.city}, ${address.state} ${address.postalCode}\n`
		: `${address.city} ${address.postalCode}\n`

	return `${address.street}\n${street2}${city}${address.country}`
}

export function extractDataFromFromImadOmad(imadOrOmad: string) {
	// example imad: 20220208MMQFMPK0000034
	// example omad: 20220208MMQFMPLY00038702081333FT01
	if (imadOrOmad.length < 22) {
		return
	}

	return {
		cycleDate: moment(imadOrOmad.slice(0, 8), "YYYYMMDD").format("L"),
		sequenceNumber: imadOrOmad.slice(16, 22),
	}
}
