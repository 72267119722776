import React from "react"
import {Box} from "../../containers/Box/Box"
import {ValueAndUnits} from "../ValueAndUnits/ValueAndUnits"
import {InterestFormulaBox} from "../InterestFormulaBox/InterestFormulaBox"
import Skeleton from "react-loading-skeleton"

export function OverallInterest({
	overallInterestRatePercentage,
	fedUpperBound,
	fedLowerBound,
	interestFormula,
}: {
	overallInterestRatePercentage: number
	fedUpperBound: number
	fedLowerBound: number
	interestFormula: string
}) {
	return (
		<Box className="overall-rate-box-container">
			<span className="title">Overall Rate</span>
			<ValueAndUnits
				value={Number(overallInterestRatePercentage.toFixed(2))}
				unitsPosition={"Suffix"}
				units={"%"}
				className={"value"}
			/>
			<InterestFormulaBox
				fedUpperBound={fedUpperBound}
				fedLowerBound={fedLowerBound}
				interestFormula={interestFormula}
			/>
		</Box>
	)
}

export function OverallInterestPending() {
	return (
		<Box className="overall-rate-box-pending-container">
			<Skeleton width={96} height={20} />
			<Skeleton width={52} height={24} />
			<Skeleton width={246} height={12} />
		</Box>
	)
}
