import React from "react"
import {useParams} from "react-router-dom"
import {OrgAuthProvider, useCustomerAccessToken} from "../../services/orgAuth"
import {TransactionComponent} from "../Transaction/Transaction"

export function Internal({accountId, transactionId}: {accountId: string; transactionId: string}) {
	const accessToken = useCustomerAccessToken()

	return <TransactionComponent accountId={accountId} transactionId={transactionId} accessToken={accessToken} />
}

export default function OrgTransactionPage() {
	const {accountId = "", transactionId = ""} = useParams<{accountId: string; transactionId: string}>()

	return (
		<OrgAuthProvider>
			<Internal accountId={accountId} transactionId={transactionId} />
		</OrgAuthProvider>
	)
}
