import React, {useRef} from "react"
import Icon from "../Icon/Icon"
import {useToggle, useClickAway} from "react-use"
import classNames from "classnames"
import {IcomoonIconName} from "../Icon/icons"
import {useIsBankUser, useIsOrgUser, useIsPartnerUser, useIsUnitUser} from "../../services/auth"
import {NavLink} from "../NavLink/NavLink"

interface DropdownItemProps {
	label: string
	link: string
	icon: IcomoonIconName
}

function DropdownItem({icon, link, label}: DropdownItemProps) {
	return (
		<li>
			<NavLink end className="has-icon" activeClassName="is-active" to={link}>
				<span className="icon">
					<Icon size={16} icon={icon} />
				</span>
				<span className="menu-item-label">{label}</span>
			</NavLink>
		</li>
	)
}

function conditionalDropdownItem(condition: boolean, item: DropdownItemProps) {
	if (condition) {
		return [item]
	}
	return []
}

export default function NavbarDeveloperDropdown() {
	const ref = useRef(null)
	const [isDropdownActive, toggleDropdown] = useToggle(false)
	useClickAway(ref, () => toggleDropdown(false))
	const dropdownClassname = classNames(
		"navbar-developer-dropdown dropdown is-centered",
		isDropdownActive && "is-active"
	)

	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()
	const isOrgUser = useIsOrgUser()
	const isPartnerUser = useIsPartnerUser()

	const dropdownItems: Array<DropdownItemProps> = [
		...conditionalDropdownItem(isOrgUser, {label: "Org Settings", icon: "settings-cog-1", link: "/org-settings"}),
		...conditionalDropdownItem(isUnitUser || isOrgUser, {
			label: "Webhooks",
			icon: "hierarchy-circle-triangle",
			link: "/webhooks",
		}),
		// ...conditionalDropdownItem(!isUnitUser, {label: "Deposit Products", icon: "list-bullet-point-square", link: "/deposit-products"}),
		...conditionalDropdownItem(isUnitUser || isOrgUser || isPartnerUser, {
			label: "API Tokens",
			icon: "file-code-lock",
			link: "/api-tokens",
		}),
		...conditionalDropdownItem(isBankUser, {label: "Bank Settings", icon: "settings-cog-1", link: "/bank-settings"}),
	]

	return (
		<div className={dropdownClassname} ref={ref}>
			<div className="navbar-developer-dropdown-trigger" onClick={() => toggleDropdown()}>
				<Icon icon={"page-coding-3"} />
				<span className="navbar-developer-dropdown-trigger-text">{"  Developer  "}</span>
				<span className="navbar-developer-dropdown-trigger-arrow">
					<Icon icon={"interface-arrows-button-down--interface-essential"} size={10} />
				</span>
			</div>
			<div className="dropdown-menu">
				<div className="dropdown-content">
					<ul className="navbar-developer-dropdown-content-links" onClick={toggleDropdown}>
						<li>
							<a
								className="has-icon"
								href="https://docs.unit.co"
								target="_blank"
								rel="noreferrer"
								data-tracking-label="navbar-developer-api-docs"
							>
								<span className="icon">
									<Icon size={16} icon="programming-book" />
								</span>
								<span className="menu-item-label">API Docs</span>
								<span className="external-link-icon">
									<Icon size={12} icon="maximize-square-4--interface-essential" />
								</span>
							</a>
						</li>
						{dropdownItems.map((item) => (
							<DropdownItem key={item.label} {...item} />
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}
