import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDown} from "@fortawesome/free-solid-svg-icons"
import Icon from "../../Icon/Icon"
import classNames from "classnames"
import {IcomoonIconName} from "../../Icon/icons"
import {CommonFilterProps} from "../Filter"
import {FilterDropdownMenu} from "../FilterDropdownMenu/FilterDropdownMenu"

export function SimpleFilter<K>({
	dropdownClassname,
	filterRef,
	toggleDropdown,
	showFilterSummary,
	title,
	statuses,
	toggleAll,
	toggleNone,
	isSearchable,
	searchBox,
	buttons,
	filterIcon,
}: CommonFilterProps<K> & {filterIcon?: IcomoonIconName}) {
	return (
		<div className={dropdownClassname} ref={filterRef}>
			<div className="dropdown-trigger filter-dropdown-trigger-simple-theme">
				<button
					className="button is-ghost simple-theme"
					aria-haspopup="true"
					aria-controls="dropdown-menu"
					onClick={() => toggleDropdown()}
				>
					{filterIcon && (
						<span className="icon is-small">
							<span className="icon dropdown-icon">
								{" "}
								<Icon icon={filterIcon} className="simple-filter-icon" />
							</span>
						</span>
					)}
					<div className="filter-title-placeholder">
						{showFilterSummary && <div className={classNames("filter-title-count", filterIcon && "has-filter-icon")} />}
						<span>
							{title} {showFilterSummary && `(${statuses.length})`}
						</span>
					</div>
					<span className="icon is-small dropdown-icon">
						{" "}
						<FontAwesomeIcon icon={faAngleDown} className="simple-filter-icon" />{" "}
					</span>
				</button>
			</div>
			<FilterDropdownMenu
				toggleAll={toggleAll}
				toggleNone={toggleNone}
				isSearchable={isSearchable}
				searchBox={searchBox}
				buttons={buttons}
			/>
		</div>
	)
}
