import {useAccessToken} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import React, {ReactElement, useState} from "react"
import moment from "moment"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {Filter} from "../Filter/Filter"
import {useQueryState} from "use-location-state"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import numeral from "numeral"
import {
	Adjustment,
	createBulkAdjustments,
	findAdjustments,
	StatusType,
	StatusTypeValue,
} from "../../resources/adjustment"
import {OrgName} from "../OrgName/OrgName"
import {useNavigate} from "react-router-dom"
import {UserName} from "../Users/UserName"
import {ActionButtons} from "./ActionButtons"
import {GetCustomer} from "./Helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircleNotch, faUpload} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import {useToasts} from "react-toast-notifications"
import {Meta, Resource} from "../../resources/common"
import classNames from "classnames"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {UnitAdminOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {getCustomerName} from "../../resources/customer"

export enum AdjustmentsColumns {
	id = "Id",
	org = "Org",
	account = "Account",
	customer = "Customer",
	direction = "Direction",
	amount = "Amount",
	status = "Status",
	user = "User",
	description = "Description",
	note = "Note",
	createdAt = "Created At",
	action = "Action",
}

type AllowedAdjustmentsColumns =
	| AdjustmentsColumns.id
	| AdjustmentsColumns.org
	| AdjustmentsColumns.account
	| AdjustmentsColumns.customer
	| AdjustmentsColumns.direction
	| AdjustmentsColumns.amount
	| AdjustmentsColumns.status
	| AdjustmentsColumns.user
	| AdjustmentsColumns.description
	| AdjustmentsColumns.note
	| AdjustmentsColumns.createdAt
	| AdjustmentsColumns.action

interface AdjustmentsProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedAdjustmentsColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	enableUpload?: boolean
	accountId?: string
	enableStatusFilter?: boolean
	enablePagination?: boolean
}

interface AdjustmentsTableProps {
	adjustments: Array<Adjustment>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedAdjustmentsColumns>
	meta?: Meta
	token?: string
	refresh: () => void
	fullHeight?: boolean
	singleDisputeView?: boolean
}

export function AdjustmentRow({
	adjustment,
	refresh,
	included,
	includedColumns,
}: {
	adjustment: Adjustment
	refresh: () => void
	included?: Resource[]
	includedColumns: Array<AllowedAdjustmentsColumns>
}) {
	const navigate = useNavigate()
	const customerId = adjustment.relationships.customer?.data.id
	const status = adjustment.attributes.statusHistory.slice(-1)[0]
	const statusType = status.type

	const contentColumns: Partial<Record<AllowedAdjustmentsColumns, ReactElement>> = {}

	if (includedColumns.includes(AdjustmentsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {adjustment.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.org)) {
		contentColumns["Org"] = adjustment.relationships.org?.data.id ? (
			<OrgName orgId={adjustment.relationships.org.data.id.toString()} included={included} />
		) : (
			<DataTableCell />
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.account)) {
		contentColumns["Account"] = (
			<DataTableCell>
				{" "}
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (adjustment.relationships.account.data.id) {
							navigate(`/accounts/${adjustment.relationships.account.data.id}`)
						}
					}}
				>
					{adjustment.relationships.account.data.id}
				</a>{" "}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.customer)) {
		contentColumns["Customer"] = customerId ? (
			<DataTableCell>
				{" "}
				{GetCustomer(
					String(customerId),
					(customer) => (
						<a
							className="link"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
							}}
						>
							{getCustomerName(customer)}
						</a>
					),
					included
				)}{" "}
			</DataTableCell>
		) : (
			<DataTableCell />
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.direction)) {
		contentColumns["Direction"] = <DataTableCell> {adjustment.attributes.direction} </DataTableCell>
	}

	if (includedColumns.includes(AdjustmentsColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell> {numeral(adjustment.attributes.amount / 100).format("$0,0.00")} </DataTableCell>
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.status)) {
		contentColumns["Status"] = <DataTableCell> {StatusTypeValue(statusType)} </DataTableCell>
	}

	if (includedColumns.includes(AdjustmentsColumns.user)) {
		contentColumns["User"] = (
			<UserName
				userId={status.byUserId.toString()}
				onResult={(user) => <DataTableCell>{user.attributes.username}</DataTableCell>}
			/>
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.description)) {
		contentColumns["Description"] = <DataTableCell> {adjustment.attributes.description} </DataTableCell>
	}

	if (includedColumns.includes(AdjustmentsColumns.note)) {
		contentColumns["Note"] = <DataTableCell> {adjustment.attributes.internalNote} </DataTableCell>
	}

	if (includedColumns.includes(AdjustmentsColumns.createdAt)) {
		contentColumns["Created At"] = (
			<DataTableCell> {moment(adjustment.attributes.createdAt).format("L LT")} </DataTableCell>
		)
	}

	if (includedColumns.includes(AdjustmentsColumns.action)) {
		contentColumns["Action"] = (
			<DataTableCell>
				{" "}
				<ActionButtons adj={adjustment} refresh={refresh} />{" "}
			</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/adjustments/${adjustment.id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function AdjustmentsTable({
	adjustments,
	includedColumns,
	hasPrev,
	hasNext,
	prev,
	next,
	hasResults,
	isUsingPaging,
	refresh,
	meta,
	fullHeight,
}: AdjustmentsTableProps): ReactElement {
	const noContent = adjustments.length === 0

	return (
		<div className={classNames("adjustments-table")}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={"No adjustments found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{adjustments.map((adjustment) => {
						return (
							<AdjustmentRow
								key={adjustment.id}
								adjustment={adjustment}
								refresh={refresh}
								includedColumns={includedColumns}
							/>
						)
					})}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export function Adjustments({
	limit = 25,
	includedColumns,
	enableUpload,
	enableTitle,
	fullHeight,
	accountId,
}: AdjustmentsProps) {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()

	const [filteredAdjustmentStatuses, setFilteredAdjustmentsStatuses] = useQueryState<string[]>(
		"filter[status]",
		Object.keys(StatusType)
	)
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) => findAdjustments(accessToken, offset, limit, accountId, filteredAdjustmentStatuses),
		(x) => x.data.length,
		[filteredAdjustmentStatuses.join(","), refreshToken]
	)

	const statusFilter = (
		<Filter
			title="Status"
			setStatuses={setFilteredAdjustmentsStatuses}
			statuses={filteredAdjustmentStatuses}
			onFilterFunc={() => reset(limit)}
			options={new Map(Object.keys(StatusType).map((t) => [t, StatusTypeValue(t)]))}
		/>
	)

	const [isFileLoading, setIsFileLoading] = useState(false)
	const {addToast} = useToasts()

	function onFileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files
		if (files) {
			const file = files[0]

			if (file) {
				setIsFileLoading(true)
				createBulkAdjustments(accessToken, file, "text/csv", file.name)
					.then((result) => {
						setIsFileLoading(false)
						result.match(
							(okResult) =>
								addToast(`Successfully added ${okResult.data.relationships.adjustments.data.length} new adjustments`, {
									appearance: "success",
								}),
							(errorResult) => {
								addToast(`${errorResult.errors[0].title} - ${errorResult.errors[0].detail}`, {appearance: "error"})
							}
						)
						refresh()
					})
					.catch(() => setIsFileLoading(false))
			}
		}
	}

	return (
		<DataTableCard className={"disputes-card"}>
			<UnitAdminOnly>
				{enableUpload ? (
					<div className="file column is-right is-flex is-align-items-right">
						<a className="is-flex is-align-items-right ml-1" data-tip="React-tooltip">
							<label className="file-label">
								<input
									className="file-input"
									disabled={isFileLoading}
									type="file"
									onChange={onFileUploaded}
									accept=".text,.csv"
								/>
								<span className={classNames("file-cta", isFileLoading && "file-input-disabled")}>
									<span className="file-icon">
										<FontAwesomeIcon icon={isFileLoading ? faCircleNotch : faUpload} spin={isFileLoading} />
									</span>
									<span className="file-label">Upload Adjustments CSV file</span>
								</span>
							</label>
							<ReactTooltip
								place="bottom"
								effect="solid"
								className="tooltip-info"
								border={true}
								borderColor="#C4C4C4"
								backgroundColor="#F5F5F5"
								textColor="black"
							>
								<span>
									<b>CSV file columns order:</b> [Direction (Credit/Debit)] [Account ID] [Amount(dollars) xxx.xx]
									[Description] [Internal notes]
								</span>
							</ReactTooltip>
						</a>
					</div>
				) : null}
			</UnitAdminOnly>
			<DataTableActionHeader
				enableSticky={fullHeight}
				filters={[statusFilter]}
				title={enableTitle ? "Adjustments" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: adjustment}) => {
					return (
						<AdjustmentsTable
							adjustments={adjustment.data}
							meta={adjustment.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={true}
							include={adjustment.included}
							token={accessToken}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
