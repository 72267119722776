import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import Admonition from "../../components/Admonitions/Admonition"
import {rejectCheckDeposit} from "../../resources/checkDeposits"

interface RejectCheckModalProps {
	id: string
	closeModal: () => void
	onSuccess: () => void
}

const presetReasons = [
	"Blank Check",
	"Duplicate Check",
	"Incomplete Check",
	"Date Issue",
	"Missing Signature",
	"Missing Endorsement",
	"Beneficiary Name Mismatch",
	"Amount Mismatch",
	"Altered / Fictitious",
	"Suspected Counterfeit",
	"Partial Endorsement",
	"Ineligible Item",
	"Non-Compliant Image",
	"Other",
]

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function RejectCheckModal({id, closeModal, onSuccess}: RejectCheckModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState(presetReasons[0])
	const [reasonText, setReasonText] = useState<string | undefined>(undefined)
	const [rejectedState, setRejectedState] = useAsyncResultIdle(rejectCheckDeposit)

	return (
		<AsyncResultModal
			title={`You Are About To Reject Check ID #${id}`}
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-danger"
			state={rejectedState}
			buttonText="Reject Check"
			successText="Check is rejected"
			onSubmit={() => {
				setRejectedState({id, accessToken, reason, reasonText: reasonText?.trim()})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<div className="select">
						<select onChange={(e) => setReason(e.target.value)} value={reason}>
							{presetReasons.map((reason) => {
								return (
									<option key={reason} value={reason}>
										{reason}
									</option>
								)
							})}
						</select>
					</div>
					<div className="control">
						<br />
						<label className="label">Notes</label>
						<textarea
							className="textarea"
							placeholder="Why are you rejecting this check deposit?"
							value={reasonText}
							onChange={(e) => setReasonText(e.target.value)}
							disabled={reason !== "Other"}
							required={reason === "Other"}
						/>
					</div>
				</div>
			</div>
			<Admonition
				type={"is-danger"}
				message={
					<p>
						{
							"Rejecting a check is a final action that cannot be undone. The rejection reason and text specified will be visible to clients."
						}
					</p>
				}
				title={"Warning"}
			/>
		</AsyncResultModal>
	)
}
