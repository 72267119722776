import React from "react"
import {BankSettings, isBankSettingsWithLegalDocs} from "../../resources/bankSettings"
import DomainSetup from "./DomainSetup"
import DnsDomainView from "./DnsDomainView"

export default function LegalDocs({bankSettings, refresh}: {bankSettings: BankSettings; refresh: () => void}) {
	return (
		<div className="card">
			<div className="bank-settings-content">
				<h3> Legal Docs </h3>
				<form>
					{isBankSettingsWithLegalDocs(bankSettings) ? (
						<DnsDomainView
							dnsDomain={bankSettings.attributes.legalDocsSettings.dnsDomain}
							refresh={refresh}
							status={bankSettings.attributes.legalDocsSettings.certificateStatus}
						/>
					) : (
						<DomainSetup refresh={refresh} />
					)}
				</form>
			</div>
		</div>
	)
}
