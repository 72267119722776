import React, {PropsWithChildren} from "react"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"

export default function HorizontalField({
	label,
	children,
	labelClassName,
	fieldClassName,
	useLabelTitle = false,
	showLabel = true,
}: PropsWithChildren<{
	label: string
	labelClassName?: string
	fieldClassName?: string
	useLabelTitle?: boolean
	showLabel?: boolean
}>) {
	return (
		<div className={classNames("static-field justify-content-sb", fieldClassName)}>
			{showLabel ? (
				<label className={classNames("label", labelClassName)} title={useLabelTitle ? label : undefined}>
					{label}
				</label>
			) : null}
			<div className="control">{children}</div>
		</div>
	)
}

export function optionalHorizontalField(
	attributeLabel: string,
	attribute?: string,
	labelClassName?: string,
	fieldClassName?: string
) {
	return attribute ? (
		<HorizontalField label={attributeLabel} labelClassName={labelClassName} fieldClassName={fieldClassName}>
			<input type="text" readOnly value={attribute} className="input is-static" />
		</HorizontalField>
	) : (
		<></>
	)
}

interface OptionalHorizontalFieldProps {
	title: string
	attribute?: string
	value?: string
	showTooltip?: boolean
	className?: string
	icon?: IcomoonIconName | null
}

export function OptionalHorizontalField({
	title,
	attribute,
	value,
	showTooltip = false,
	className,
	icon,
}: OptionalHorizontalFieldProps) {
	const valueToDisplay = value || attribute || ""
	const tooltip = showTooltip ? valueToDisplay : ""
	const classes = classNames("is-static", {"show-tooltip": showTooltip}, className)

	return valueToDisplay ? (
		<HorizontalField label={title}>
			<div className="flex-container justify-content-start align-items-center">
				<span className={classes} title={tooltip}>
					{icon ? (
						<>
							{valueToDisplay} <Icon icon={icon} style={{verticalAlign: "top"}} />
						</>
					) : (
						valueToDisplay
					)}
				</span>
			</div>
		</HorizontalField>
	) : null
}
