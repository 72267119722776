import React from "react"
import Card from "../../../containers/Card/Card"
import DepositProductField from "./DepositProductField/DepositProductField"
import CardTitle from "../CardTitle"
import {DepositProductLimitsProps} from "./types"
import Icon from "../../../components/Icon/Icon"
import {EditMode} from "../../../resources/common"
import classNames from "classnames"
import {toInteger} from "lodash"
import Admonition from "../../../components/Admonitions/Admonition"
import {AccountLimitsDescription} from "../../../components/AccountLimits/AccountLimits"

export default function Limits({editMode, values, setFieldValue, onBlur}: DepositProductLimitsProps) {
	const getLinkLabel = (text: string, link: string) => (
		<a className="" target="_blank" rel="noreferrer" href={link}>
			{text}
		</a>
	)

	return (
		<Card
			className="deposit-product-card-title"
			title={CardTitle({
				elementId: "Limits",
				title: "Limits",
				description: <AccountLimitsDescription />,
				linkText: "Read the account limits guide",
				linkUrl: "https://guides.unit.co/limits/",
				editMode: editMode,
			})}
		>
			<div className="account-limits-block-header">
				<div className="account-limits-block-header-icon-placeholder">
					<Icon icon={"money-transfer--business-products"} size={20} color="black" />
				</div>
				<h4>Originated ACH Payments Limits</h4>
			</div>
			<div className="sub-title">Daily</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyACHDebitSoftLimit"
					label="Debit soft limits"
					subLabel="Daily limit"
					value={values.dailyACHDebitSoftLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyACHDebitSoftLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyACHDebitLimit"
					label="Debit hard limits"
					subLabel="Daily limit"
					value={values.dailyACHDebitLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyACHDebitLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyACHCreditLimit"
					label="Credit limits"
					subLabel="Daily limit"
					value={values.dailyACHCreditLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyACHCreditLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className="sub-title">Monthly</div>
			<div className={classNames("form-row four vertical-divider", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyACHDebitSoftLimit"
					label="Debit soft limits"
					subLabel="Monthly limit"
					value={values.monthlyACHDebitSoftLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyACHDebitSoftLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyACHDebitLimit"
					label="Debit hard limits"
					subLabel="Monthly limit"
					value={values.monthlyACHDebitLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyACHDebitLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyACHCreditLimit"
					label="Credit limits"
					subLabel="Monthly limit"
					value={values.monthlyACHCreditLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyACHCreditLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className="account-limits-block-header">
				<div className="account-limits-block-header-icon-placeholder">
					<Icon icon={"money-transfer--business-products"} size={20} color="black" />
				</div>
				<h4>Originated Book Payments Limits</h4>
			</div>
			<div className="sub-title">Daily</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyBookDebitLimit"
					label="Debit limits"
					subLabel="Daily limit"
					value={values.dailyBookDebitLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyBookDebitLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyBookCreditLimit"
					label="Credit limits"
					subLabel="Daily limit"
					value={values.dailyBookCreditLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyBookCreditLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className="sub-title">Monthly</div>
			<div className={classNames("form-row four vertical-divider", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyBookDebitLimit"
					label="Debit limits"
					subLabel="Monthly limit"
					value={values.monthlyBookDebitLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyBookDebitLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyBookCreditLimit"
					label="Credit limits"
					subLabel="Monthly limit"
					value={values.monthlyBookCreditLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyBookCreditLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className="account-limits-block-header">
				<div className="account-limits-block-header-icon-placeholder">
					<Icon icon={"credit-card-money-fund--business-products"} size={20} color="black" />
				</div>
				<h4>Card Limits</h4>
			</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyCardPurchaseLimit"
					label={getLinkLabel("Card Purchases", "https://docs.unit.co/resources#transaction-purchase")}
					subLabel="Daily limit"
					value={values.dailyCardPurchaseLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyCardPurchaseLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyCardTransactionLimit"
					label={getLinkLabel("Card Transactions", "https://docs.unit.co/resources/#transaction-card")}
					subLabel="Daily limit"
					value={values.dailyCardTransactionLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyCardTransactionLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyCardDepositLimit"
					label="ATM Deposit"
					subLabel="Daily limit"
					value={values.dailyCardDepositLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyCardDepositLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyCardWithdrawalLimit"
					label="ATM Withdrawal"
					subLabel="Daily limit"
					value={values.dailyCardWithdrawalLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyCardWithdrawalLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className={classNames("form-row vertical-divider", editMode === EditMode.VIEW && "view")}>
				<Admonition
					type="is-info"
					icon="information-circle--interface-essential"
					title={"info"}
					message={
						<ul>
							<li>
								Card limits apply to the aggregate transaction volume of all cards that belong to a certain account
								(including authorization
								<a className="title-info-text" target="_blank" rel="noreferrer" href={"https://guides.unit.co/limits/"}>
									<span className="text">holds</span>
									<Icon icon="maximize-square-2--interface-essential" size={10} />
								</a>{" "}
								).
							</li>
							<li>
								You may define
								<a className="title-info-text" target="_blank" rel="noreferrer" href={"https://guides.unit.co/limits/"}>
									<span className="text">card specific limits</span>
									<Icon icon="maximize-square-2--interface-essential" size={10} />
								</a>
								that meet your business case, and they will be enforced in addition to the account level limits.
							</li>
						</ul>
					}
				/>
			</div>
			<div className="account-limits-block-header">
				<div className="account-limits-block-header-icon-placeholder">
					<Icon icon={"check-write--business-products"} size={20} color="black" />
				</div>
				<h4>Check Deposits</h4>
			</div>
			<div className="sub-title">Daily</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyCheckDepositSoftLimit"
					label="Soft limits"
					subLabel="Daily limit"
					value={values.dailyCheckDepositSoftLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyCheckDepositSoftLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyCheckDepositLimit"
					label="Hard limits"
					subLabel="Daily limit"
					value={values.dailyCheckDepositLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyCheckDepositLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className="sub-title">Monthly</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyCheckDepositSoftLimit"
					label="Soft limits"
					subLabel="Monthly limit"
					value={values.monthlyCheckDepositSoftLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyCheckDepositSoftLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyCheckDepositLimit"
					label="Hard limits"
					subLabel="Monthly limit"
					value={values.monthlyCheckDepositLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyCheckDepositLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>

			<div className="account-limits-block-header">
				<div className="account-limits-block-header-icon-placeholder">
					<Icon icon={"check-write--business-products-1"} size={20} color="black" />
				</div>
				<h4>Wires</h4>
			</div>
			<div className="sub-title">Daily</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyWireSoftLimit"
					label="Soft limits"
					subLabel="Daily limit"
					value={values.dailyWireSoftLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyWireSoftLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="dailyWireLimit"
					label="Hard limits"
					subLabel="Daily limit"
					value={values.dailyWireLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("dailyWireLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className="sub-title">Monthly</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyWireSoftLimit"
					label="Soft limits"
					subLabel="Monthly limit"
					value={values.monthlyWireSoftLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyWireSoftLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="monthlyWireLimit"
					label="Hard limits"
					subLabel="Monthly limit"
					value={values.monthlyWireLimit / 100}
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("monthlyWireLimit", toInteger(value) * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
		</Card>
	)
}
