import React, {PropsWithChildren} from "react"
import {useNavigate} from "react-router"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faLink} from "@fortawesome/free-solid-svg-icons"

const absoluteUrl = /^https?:\/\/|^\/\//i

export default function LinkButton({to, children, callback}: PropsWithChildren<{to?: string; callback?: () => void}>) {
	const navigate = useNavigate()

	const onClick =
		callback ||
		((e: React.MouseEvent) => {
			if (to) {
				e.preventDefault()
				if (absoluteUrl.test(to)) window.open(to, "_blank")
				else navigate(to)
			}
		})

	return (
		<button className="button is-inverted is-link" onClick={onClick}>
			<span className="icon">
				<FontAwesomeIcon icon={faLink} />
			</span>
			<span>{children}</span>
		</button>
	)
}
