import React, {CSSProperties, useState} from "react"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useEsc} from "../../hooks/useEsc"
import {useToolbar} from "../../hooks/useToolbar"
import {ApplicationDocumentModalAside} from "./ApplicationDocumentModalAside"
import {ImageTransformControls} from "../ImageTransformControls/ImageTransformControls"
import {ModalPdfNav} from "../Modals/ModalPdfNav"
import {ModalOppositeSideButton} from "./ModalOppositeSideButton"
import {Document, Page} from "react-pdf/dist/esm/entry.parcel2"
import {PDFDocumentProxy} from "pdfjs-dist"
import {SetQueryStateItemFn} from "use-location-state"
import {
	Application,
	ApplicationDocument,
	ApplicationDocumentSide,
	backSupportedDocumentTypes,
	getDocumentLink,
} from "../../resources/application"

interface ApplicationDocumentModalProps {
	close: () => void
	application: Application
	document: ApplicationDocument
	accessToken: string
	documentSide: ApplicationDocumentSide
	refresh: () => void
	setDocSideQueryParam: SetQueryStateItemFn<string>
}

export function ApplicationDocumentModal(props: ApplicationDocumentModalProps) {
	const {close, application, document, accessToken, documentSide, refresh, setDocSideQueryParam} = props

	const {zoom, rotation, activeDocumentSide} = useToolbar(documentSide)
	const isBackSupportDocumentType = backSupportedDocumentTypes.includes(document.attributes.documentType)

	const [numPages, setNumPages] = useState(0)
	const [pageNumber, setPageNumber] = useState(1)

	const frontDocumentLinkState = useAsyncResult(() =>
		getDocumentLink(application.id, document.id, accessToken, ApplicationDocumentSide.Front)
	)
	const frontDocumentLink = frontDocumentLinkState.match(
		() => undefined,
		(image) => image,
		() => null
	)

	const backDocumentLinkState = isBackSupportDocumentType
		? useAsyncResult(() => getDocumentLink(application.id, document.id, accessToken, ApplicationDocumentSide.Back))
		: null
	const backDocumentLink = backDocumentLinkState
		? backDocumentLinkState.match(
				() => undefined,
				(image) => image,
				() => null
		  )
		: null

	const isFrontPdf = frontDocumentLink?.data.attributes.contentType.includes("pdf")
	const isBackPdf = backDocumentLink?.data.attributes.contentType.includes("pdf")

	const isActiveSidePdf =
		(activeDocumentSide.val === ApplicationDocumentSide.Front && isFrontPdf) ||
		(activeDocumentSide.val === ApplicationDocumentSide.Back && isBackPdf)

	const inlineImgStyle = {"--img-rotation": rotation.val + "deg", "--img-scale": zoom.val} as CSSProperties

	const handlePdfSuccess = ({numPages}: PDFDocumentProxy) => setNumPages(numPages)

	useEsc(close)

	return (
		<div className="app-doc-modal modal is-active">
			<div className="modal-content">
				<div className="doc-wrapper">
					{frontDocumentLinkState.isOk() && (
						<>
							{isActiveSidePdf ? (
								<Document
									onLoadSuccess={handlePdfSuccess}
									file={
										activeDocumentSide.val === ApplicationDocumentSide.Front
											? frontDocumentLink?.data.attributes.link
											: backDocumentLink?.data.attributes.link
									}
								>
									<Page pageNumber={pageNumber} scale={zoom.val} rotate={rotation.val} />
								</Document>
							) : (
								<img
									className="doc-img"
									alt="document image"
									style={inlineImgStyle}
									src={
										activeDocumentSide.val === ApplicationDocumentSide.Front
											? frontDocumentLink?.data.attributes.link
											: backDocumentLink?.data.attributes.link
									}
								/>
							)}

							<footer aria-label="toolbar" className="toolbar">
								<ImageTransformControls
									changeRotation={rotation.changeRotation}
									zoomIn={zoom.zoomIn}
									zoomOut={zoom.zoomOut}
									zoomVal={zoom.val}
								/>

								{(isFrontPdf || isBackPdf) && numPages > 1 && (
									<ModalPdfNav pageNumber={pageNumber} numPages={numPages} setPageNumber={setPageNumber} />
								)}

								{isBackSupportDocumentType && !isFrontPdf && !isBackPdf && frontDocumentLink && backDocumentLink && (
									<ModalOppositeSideButton
										activeDocumentSide={activeDocumentSide.val}
										toggleActiveSide={activeDocumentSide.toggleActiveSide}
										backDocumentLink={backDocumentLink.data.attributes.link}
										frontDocumentLink={frontDocumentLink.data.attributes.link}
										setDocSideQueryParam={setDocSideQueryParam}
									/>
								)}
							</footer>
						</>
					)}
				</div>

				<ApplicationDocumentModalAside
					close={close}
					application={application}
					document={document}
					accessToken={accessToken}
					refresh={refresh}
				/>
			</div>
		</div>
	)
}
