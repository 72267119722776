import React, {RefObject, useEffect, useRef} from "react"
import {ContentLoader} from "../ContentLoader/ContentLoader"
import {useIntersection} from "react-use"

interface InfiniteScrollProps {
	observerElementRef: RefObject<HTMLSpanElement>
	callback: () => void
	isLoading: boolean
	className?: string
}
export function InfiniteScroll({observerElementRef, callback, isLoading, className}: InfiniteScrollProps) {
	const mockRef = useRef(null)
	const intersection = useIntersection(isLoading ? mockRef : observerElementRef, {threshold: 0})
	const isElementVisible = intersection?.isIntersecting

	useEffect(() => {
		if (isElementVisible && !isLoading) {
			callback()
		}
	}, [isElementVisible, isLoading])
	return <ContentLoader className={className} />
}
