import {useAccessToken, useUserClaimsData} from "../../services/auth"
import React from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getByToken} from "../../resources/bankSettings"
import classNames from "classnames"
import {useQueryState} from "use-location-state"
import {BankSettings} from "../../resources/bankSettings"
import LegalDocs from "./LegalDocs"

type BankSettingsTabs = "Legal"

function TabContent({
	currentTab,
	bankSettings,
	refresh,
}: {
	currentTab: BankSettingsTabs
	bankSettings: BankSettings
	refresh: () => void
}) {
	if (currentTab == "Legal") {
		return <LegalDocs bankSettings={bankSettings} refresh={refresh} />
	}
	return null
}

function BankSettingsInternal({
	bankSettings,
	refresh,
	currentTab,
	setCurrentTab,
}: {
	bankSettings: BankSettings
	refresh: () => void
	currentTab: BankSettingsTabs
	setCurrentTab: (tab: BankSettingsTabs) => void
}) {
	return (
		<>
			<TitleBar title={"Bank Settings"} className={"title-bar-no-border"} />
			<nav className="bank-settings tabs-container">
				<div className="tabs">
					<ul>
						<li className={classNames(currentTab == "Legal" && "is-active")} onClick={() => setCurrentTab("Legal")}>
							<a>Legal</a>
						</li>
					</ul>
				</div>
			</nav>

			<MainSection>
				<TabContent currentTab={currentTab} refresh={refresh} bankSettings={bankSettings} />
			</MainSection>
		</>
	)
}

export default function BankSettingsPage() {
	const accessToken = useAccessToken()
	const claims = useUserClaimsData()
	const [refreshToken, refresh] = useRefreshToken()
	const [currentTab, setCurrentTab] = useQueryState<BankSettingsTabs>("tab", "Legal")
	const bankSettingsAsync = useAsyncResult(() => getByToken(accessToken, claims), [refreshToken])

	return (
		<AsyncResultComponent asyncResult={bankSettingsAsync}>
			{({value: bankSettings}) => {
				return (
					<BankSettingsInternal
						bankSettings={bankSettings}
						refresh={refresh}
						currentTab={currentTab}
						setCurrentTab={(t) => {
							setCurrentTab(t)
							refresh()
						}}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}
