import * as Yup from "yup"

const validationErrorMessages = {
	counterpartyName: {
		required: "Please enter counterparty name",
	},
	counterpartyType: {
		required: "Please enter counterparty type",
		invalid: "The counterparty type entered is invalid",
	},
	accountType: {
		required: "Please enter account type",
		invalid: "The account type entered is invalid",
	},
	permissions: {
		required: "Please enter permissions",
		invalid: "The permissions entered is invalid",
	},
	accountNumber: {
		required: "Please enter account number",
		invalid: "The account number entered is invalid",
	},
	routingNumber: {
		required: "Please enter routing number",
		invalid: "The routing number entered is invalid",
	},
}

export const createCounterpartySchema = {
	counterpartyName: Yup.string().required(validationErrorMessages.counterpartyName.required),
	permissions: Yup.string()
		.required(validationErrorMessages.permissions.required)
		.oneOf(["CreditOnly", "CreditAndDebit", "DebitOnly"], validationErrorMessages.permissions.invalid),
	counterpartyType: Yup.string()
		.required(validationErrorMessages.counterpartyType.required)
		.oneOf(["Business", "Person", "Unknown"], validationErrorMessages.counterpartyType.invalid),
	accountType: Yup.string()
		.required(validationErrorMessages.accountType.required)
		.oneOf(["Checking", "Savings"], validationErrorMessages.accountType.invalid),
	routingNumber: Yup.string()
		.required(validationErrorMessages.routingNumber.required)
		.matches(/^\d{9}$/, validationErrorMessages.routingNumber.invalid),
	accountNumber: Yup.string()
		.required(validationErrorMessages.accountNumber.required)
		.matches(/^\d{1,17}$/, validationErrorMessages.accountNumber.invalid),
}
