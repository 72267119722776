import {createResource, createResourceFromFile, getFile, getResource, updateResource} from "./common"
import {Cents} from "./transaction"

export type Source = "DebitCard" | "ACH"

export enum StatusType {
	InvestigationStarted = "Investigation Started",
	ProvisionallyCredited = "Provisionally Credited",
	Denied = "Denied",
	ResolvedLost = "Resolved Lost",
	ResolvedWon = "Resolved Won",
}

export enum DisputeType {
	Issuer = "Issuer",
	Fraud = "Fraud",
	NonFraud = "NonFraud",
}

export type Status = {
	type: StatusType
	updatedAt: Date
	updatedBy?: string
}

export type BussinessDayDTO = {
	type: "bussinessDayDTO"
	attributes: {
		date: Date
		text: string
	}
}

export type DeferredProvisionalCreditWillBeGrantedDTO = {
	type: "deferredProvisionalCreditWillBeGranted"
	id: string
	attributes: {
		provisionalCreditDate: Date
	}
}

export type DeferredProvisionalCreditNotGrantedDTO = {
	type: "deferredProvisionalCreditNotGranted"
}

export type DeferredProvisionalCreditDTO =
	| DeferredProvisionalCreditWillBeGrantedDTO
	| DeferredProvisionalCreditNotGrantedDTO

export type Dispute = {
	type: "dispute"
	id: string
	attributes: {
		source: Source
		externalId: string
		link: string
		description: string
		disputeType: DisputeType
		statusHistory: Status[]
		status: StatusType
		createdAt: Date
		updatedAt?: Date
		amount: Cents
		decisionReason?: string
		deferredProvisionalCredit?: DeferredProvisionalCreditDTO
		possibleBussinesDays: BussinessDayDTO[]
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: string
			}
		}
		account: {
			data: {
				type: "account"
				id: string
			}
		}
		transaction: {
			data: {
				type: "transaction"
				id: string
			}
		}
		merchantCreditTransactionId?: {
			data: {
				type: "merchantCreditTransactionId"
				id: string
			}
		}
		org?: {
			data: {
				type: "org"
				id: string
			}
		}
	}
}

export type uploadBulkDisputesResult = {
	type: string
	attributes: {
		newDisputes: number
		resolvedWon: number
		resolvedLost: number
		denied: number
		noDecision: number
		noActionNeeded: number
	}
}

type Include = "customer,org" | "" | "org" | undefined

export function isDeferredProvisionalCreditWillBeGrantedDTO(dto: DeferredProvisionalCreditDTO) {
	return (
		(dto as DeferredProvisionalCreditWillBeGrantedDTO).attributes !== undefined &&
		(dto as DeferredProvisionalCreditWillBeGrantedDTO).attributes.provisionalCreditDate !== undefined
	)
}

function isTodayAfterDate(dateToCheck: Date): boolean {
	const today = new Date()
	today.setHours(0, 0, 0, 0)
	const date = new Date(dateToCheck)

	return today > date
}

export function showDeferredProvisionalCredit(dispute: Dispute) {
	return (
		dispute.attributes.status.toString() !== "ProvisionallyCredited" &&
		(dispute.attributes.deferredProvisionalCredit || false) &&
		isDeferredProvisionalCreditWillBeGrantedDTO(dispute.attributes.deferredProvisionalCredit) &&
		!isTodayAfterDate(
			(dispute.attributes.deferredProvisionalCredit as DeferredProvisionalCreditWillBeGrantedDTO).attributes
				.provisionalCreditDate
		)
	)
}

export function StatusTypeValue(key: string): string {
	return StatusType[key as keyof typeof StatusType]
}

export async function getDispute(accessToken: string, id: string, extraFields?: Record<"dispute", string>) {
	const query = {
		extraFields: extraFields,
	}

	const result = await getResource<Dispute>(`disputes/${id}`, accessToken, query)
	return result.map((v) => v.data)
}

export async function findDisputes(
	accessToken: string,
	offset: number,
	requestType: "json" | "csv",
	limit?: number | null,
	customerId?: string,
	statuses?: string[],
	transactionId?: number | string,
	orgs?: string[],
	searchQuery?: string,
	include?: Include
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			customerId,
			statuses,
			transactionId,
			orgs: orgFilter,
			query: searchQuery != "" ? searchQuery : null,
		},
		include: include,
	}

	switch (requestType) {
		case "csv":
			return await getFile("disputes", accessToken, query)
		default:
			return await getResource<Array<Dispute>>("disputes", accessToken, query)
	}
}

export async function createDispute(
	accessToken: string,
	externalId: string,
	link: string | null,
	description: string | null,
	disputeType: string,
	accountId: string,
	transactionId: string,
	amount: number,
	autoGrantProvisionalCredit: boolean
) {
	const result = await createResource<Dispute>("disputes", accessToken, {
		type: "dispute",
		attributes: {
			externalId,
			link,
			description,
			amount,
			disputeType,
			...(autoGrantProvisionalCredit && {
				deferredProvisionalCredit: {
					type: "automaticallyGrantDeferredProvisionalCredit",
				},
			}),
		},
		relationships: {
			account: {
				data: {
					type: "account",
					id: accountId,
				},
			},
			transaction: {
				data: {
					type: "transaction",
					id: transactionId,
				},
			},
		},
	})
	return result.map((v) => v.data)
}

export async function uploadBulkDispute(accessToken: string, inputFile: any, type: string, fileName: string) {
	const result = await createResourceFromFile<uploadBulkDisputesResult>(
		"disputes/bulk",
		accessToken,
		inputFile,
		type,
		fileName
	)
	return result.map((v) => v.data)
}

export async function setDisputeAction(accessToken: string, id: string, action: string, decisionReason?: string) {
	const result = await createResource<Dispute>(
		`disputes/${id}/${action}`,
		accessToken,
		decisionReason ? {decisionReason} : null
	)
	return result.map((v) => v.data)
}

export async function updateDispute(
	accessToken: string,
	id: string,
	description: string,
	disputeType: DisputeType,
	amount: number | null,
	externalId?: string
) {
	const result = await updateResource<Dispute>(`disputes/${id}`, accessToken, {
		type: "dispute",
		attributes: {
			description,
			amount,
			disputeType,
			externalId,
		},
	})
	return result.map((v) => v.data)
}

export async function cancelDeferedProvisionalCredit(accessToken: string, id: string) {
	const result = await updateResource<Dispute>(`disputes/${id}`, accessToken, {
		type: "dispute",
		attributes: {
			deferredProvisionalCredit: {
				type: "deferredProvisionalCreditNotGranted",
			},
		},
	})
	return result.map((v) => v.data)
}

export async function changeDeferedProvisionalDateCredit(accessToken: string, id: string, date: string) {
	const result = await updateResource<Dispute>(`disputes/${id}`, accessToken, {
		type: "dispute",
		attributes: {
			deferredProvisionalCredit: {
				type: "deferredProvisionalCreditWillBeGranted",
				attributes: {
					provisionalCreditDate: date,
				},
			},
		},
	})
	return result.map((v) => v.data)
}
