import React, {useEffect} from "react"
import {Route, Routes} from "react-router-dom"

import {
	useIsBankUser,
	useIsItUser,
	useIsOrgUser,
	useIsPartnerUser,
	useIsUnitSalesUser,
	useIsUnitSuperUser,
	useIsUnitUser,
	useUserClaimsData,
} from "../../services/auth"
import {ApplicationsPage} from "../../pages/Applications/ApplicationsPage"
import Orgs from "../../pages/Orgs/Orgs"
import {CustomersPage} from "../../pages/Customers/Customers"
import Users from "../../pages/Users/Users"
import UserDetails from "../../pages/Users/UserDetails"
import DepositProducts from "../../pages/DepositProducts/DepositProducts"
import Webhooks from "../../pages/Webhooks/Webhooks"
import ApiTokensPage from "../../pages/ApiTokens/ApiTokens"
import ChangePassword from "../../pages/ChangePassword/ChangePassword"
import NewDepositProduct from "../../pages/DepositProduct/NewDepositProduct"
import DepositProduct from "../../pages/DepositProduct/DepositProduct"
import {BatchAccountsPage} from "../../pages/BatchAccounts/BatchAccounts"
import Org from "../../pages/Orgs/Org"
import NewOrg from "../../pages/Orgs/NewOrg"
import OrgDashboard from "../../pages/OrgDashboard/OrgDashboard"
import OrgAccountPage from "../../pages/OrgDashboard/OrgAccountPage"
import AccountPage from "../../pages/Account/Account"
import TransactionPage from "../../pages/Transaction/Transaction"
import OrgTransactionPage from "../../pages/OrgDashboard/OrgTransactionPage"
import UnitDashboard from "../../pages/UnitDashboard/UnitDashboard"
import DisputesPage from "../../pages/Disputes/Disputes"
import DisputePage from "../../pages/Disputes/Dispute"
import CardDesigns from "../../pages/CardDesigns/CardDesigns"
import IncomingAchPage from "../../pages/IncomingAch/IncomingAch"
import TransactionMonitoringSettingsList from "../../pages/TransactionMonitoring/TransactionMonitoringSettingsList"
import TransactionMonitoringSettings from "../../pages/TransactionMonitoring/TransactionMonitoringSettings"
import NewTransactionMonitoringSettings from "../../pages/TransactionMonitoring/NewTransactionMonitoringSettings"
import {BinsPage} from "../../pages/Bins/Bins"
import PaymentPage from "../../pages/Payments/Payment"
import OrgPaymentPage from "../../pages/OrgDashboard/OrgPaymentPage"
import Payments from "../../pages/Payments/Payments"
import AuthorizationPage from "../../pages/Authorization/Authorization"
import OrgAuthorizationPage from "../../pages/OrgDashboard/OrgAuthorizationPage"
import Customer from "../../pages/Customer/Customer"
import Adjustments from "../../pages/Adjustments/Adjustments"
import AdjustmentPage from "../../pages/Adjustments/Adjustment"
import OrgSettings from "../../pages/OrgSettings/OrgSettings"
import {documentTitle} from "../../index"
import OrgBankAgreements from "../../pages/OrgBankAgreement/OrgBankAgreements"
import NewOrgBankAgreement from "../../pages/OrgBankAgreement/NewOrgBankAgreement"
import OrgBankAgreement from "../../pages/OrgBankAgreement/OrgBankAgreement"
import {hasPermission} from "../../services/permission"
import {NewSandboxClient, NewUnitPilotClient} from "../../pages/Orgs/NewClient"
import {AccountsPage} from "../../pages/Accounts/Accounts"
import UnitPilotOverview from "../../pages/UnitPilotOverview/UnitPilotOverview"
import {CheckDepositsPage} from "../../pages/CheckDeposits/CheckDeposits"
import {isLocalEnv, isSandboxEnv} from "../../utilities/environment"
import Sidebar from "../../components/Sidebar/Sidebar"
import Navbar from "../../components/Navbar/Navbar"
import {initializeClickTracking} from "../../utilities/tracking"
import {RedirectWithLocationChange} from "./RedirectWithLocationChange"
import ReceivedPaymentsPage from "../../pages/ReceivedPayments/ReceivedPayments"
import ReceivedPayment from "../../pages/ReceivedPayments/ReceivedPayment"
import CheckDepositPage from "../../pages/CheckDeposits/CheckDeposit"
import ApplicationPage from "../../pages/Applications/ApplicationPage"
import {Redirect} from "react-router6-redirect"
import IncomingWiresPage from "../../pages/IncomingWires/IncomingWires"
import IncomingWirePage from "../../pages/IncomingWires/IncomingWire"
import {AuthenticatedRoute} from "../AuthenticatedRoute/AuthenticatedRoute"
import CheckPaymentsPage from "../../pages/CheckPayments/CheckPayments"
import CheckPaymentPage from "../../pages/CheckPayments/CheckPayment"
import InterestSettingsPage from "../../pages/InterestSettings/InterestSettings"
import {LinkedAccountsPage} from "../../pages/LinkedAccounts/LinkedAccounts"
import LinkedAccountPage from "../../pages/LinkedAccounts/LinkedAccount"
import IncomingChecksPage from "../../pages/IncomingChecks/IncomingChecks"
import IncomingCheckPage from "../../pages/IncomingChecks/IncomingCheck"
import IncomingAchsPage from "../../pages/IncomingAchs/IncomingAchsPage"
import BankSettings from "../../pages/BankSettings/BankSettings"

export function DefaultLayout() {
	const userTokenData = useUserClaimsData()
	const isSuperUser = useIsUnitSuperUser()

	useEffect(() => {
		document.title = documentTitle

		if (isLocalEnv() || isSandboxEnv()) {
			initializeClickTracking()
		}

		try {
			if (window.zE) {
				window.zE("webWidget", "reset")

				// Enable zendesk widget
				window.zE("webWidget", "updateSettings", {
					webWidget: {
						contactForm: {
							suppress: false,
							selectTicketForm: {
								"*": "What can we help you with?",
							},
						},
						zIndex: 30,
					},
				})

				// Identify user
				window.zE("webWidget", "identify", {
					name: "",
					email: userTokenData.sub,
					organization: userTokenData.org,
				})

				// Prefill contact form
				if (!isSuperUser && userTokenData.sub) {
					window.zE("webWidget", "prefill", {
						email: {
							value: userTokenData.sub,
							readOnly: true, // optional
						},
					})
				}
			}
		} catch (e) {
			console.log("Error initializing zendesk widget", e)
		}
	}, [])

	let homeRoute
	const isUsersLimited = useIsPartnerUser() || useIsItUser()
	const isOrgLimited = useIsOrgUser()
	if (isUsersLimited) {
		homeRoute = <Route path="/" element={<RedirectWithLocationChange path={"/users"} />} />
	} else if (isOrgLimited) {
		homeRoute = <Route path="/" element={<OrgDashboard />} />
	} else {
		homeRoute = <Route path="/*" element={<UnitDashboard />} />
	}
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()
	const isUnitOrBankUser = isUnitUser || isBankUser
	return (
		<>
			<Navbar />
			{!useIsPartnerUser() && !useIsItUser() ? <Sidebar /> : null}

			<Routes>
				<Route path="/applications" element={<ApplicationsPage />} />
				<Route path="/customers" element={<CustomersPage />} />
				<Route path="/orgs" element={<Orgs />} />
				<Route path="/orgs/new" element={<NewOrg />} />
				{useIsUnitUser() ? <Route path="/sandbox-client/new" element={<NewSandboxClient />} /> : null}
				{useIsUnitSuperUser() || useIsUnitSalesUser() ? (
					<Route path="/unit-pilot-client/new" element={<NewUnitPilotClient />} />
				) : null}
				<Route path="/orgs/:orgId" element={<Org />} />
				{hasPermission("user", "get") ? <Route path="/users" element={<Users />} /> : null}
				<Route path="/user-details/:userId/*" element={<UserDetails />} />
				<Route path="/webhooks" element={<Webhooks />} />
				<Route path="/org-settings" element={<OrgSettings />} />
				<Route path="/bank-settings" element={<BankSettings />} />
				<Route path="/pilot-overview" element={<UnitPilotOverview />} />
				<Route path="/card-designs" element={<CardDesigns />} />
				<Route path="/deposit-products" element={<DepositProducts />} />
				<Route path="/deposit-products/new" element={<NewDepositProduct />} />
				<Route path="/deposit-products/:depositProductId" element={<DepositProduct />} />
				<Route path="/disputes" element={<DisputesPage />} />
				<Route path="/disputes/:disputeId" element={<DisputePage />} />
				<Route path="/api-tokens" element={<ApiTokensPage />} />
				<Route path="/change-password" element={<ChangePassword />} />
				<Route path="/change-password/:userId" element={<ChangePassword />} />
				<Route path="/applications/individual/:applicationId" element={<ApplicationPage />} />
				<Route path="/applications/business/:applicationId" element={<ApplicationPage />} />
				<Route path="/applications/:applicationId" element={<ApplicationPage />} />
				<Route path="/customers/individual/:customerId/*" element={<Customer />} />
				<Route path="/customers/business/:customerId/*" element={<Customer />} />
				<Route path="/customers/businessFBO/:customerId/*" element={<Customer />} />
				<Route path="/customers/individualWallet/:customerId/*" element={<Customer />} />
				<Route path="/customers/:customerId/*" element={<Customer />} />
				<Route path="/onboarding/*" element={<Redirect to="/applications" />} />
				<Route path="/accounts/:accountId/*" element={<AccountPage />} />
				<Route path="/batch-accounts/*" element={<BatchAccountsPage />} />
				<Route path="/transaction/:accountId/:transactionId/*" element={<TransactionPage />} />
				<Route path="/org-transaction/:accountId/:transactionId/*" element={<OrgTransactionPage />} />
				<Route key="payments" path="/payments" element={<Payments />} />
				<Route key="checkPayments" path="/check-payments" element={<CheckPaymentsPage />} />
				<Route path="/check-payments/:checkPaymentId/*" element={<CheckPaymentPage />} />
				<Route key="receivedPayments" path="/received-payments" element={<ReceivedPaymentsPage />} />
				<Route path="/received-payments/:receivedPaymentId/*" element={<ReceivedPayment />} />
				<Route key="accounts" path="/accounts" element={<AccountsPage />} />
				<Route path="/payments/:paymentId/assignments/*" element={<PaymentPage assignmentsView={true} />} />
				<Route path="/payments/:paymentId/*" element={<PaymentPage />} />
				<Route path="/authorization/:authorizationId/*" element={<AuthorizationPage />} />
				<Route path="/org-payment/:paymentId/*" element={<OrgPaymentPage />} />
				{hasPermission("checkDeposit", "get") && (
					<Route key="incoming-checks" path="/check-deposits" element={<CheckDepositsPage />} />
				)}
				<Route path="/check-deposits/:checkId/*" element={<CheckDepositPage />} />
				<Route path="/org-account/:accountId/*" element={<OrgAccountPage />} />
				<Route path="/org-authorization/:authorizationId/*" element={<OrgAuthorizationPage />} />
				{hasPermission("incomingWire", "get") && (
					<Route key="incoming-wires" path="/incoming-wires" element={<IncomingWiresPage />} />
				)}
				{hasPermission("incomingWire", "get") && (
					<Route key="incoming-wires" path="/incoming-wires/:incomingWireId" element={<IncomingWirePage />} />
				)}
				{hasPermission("incomingAch", "get") && (
					<Route key="incoming-achs" path="/incoming-achs" element={<IncomingAchsPage />} />
				)}
				{hasPermission("incomingAch", "get") && (
					<Route key="incoming-achs" path="/incoming-achs/:incomingAchId/*" element={<IncomingAchPage />} />
				)}
				{hasPermission("incomingCheck", "get") && (
					<Route key="incoming-checks" path="/incoming-checks" element={<IncomingChecksPage />} />
				)}
				{hasPermission("incomingCheck", "get") && (
					<Route key="incoming-checks" path="/incoming-checks/:incomingCheckId" element={<IncomingCheckPage />} />
				)}
				{isUnitOrBankUser
					? [
							<Route key="org-bank-agreements" path="/org-bank-agreements" element={<OrgBankAgreements />} />,
							<Route
								key={"/org-bank-agreements/:name"}
								path="/org-bank-agreements/:id"
								element={<OrgBankAgreement />}
							/>,
					  ]
					: null}
				{useIsUnitUser()
					? [
							<Route key="/adjustments" path="/adjustments" element={<Adjustments />} />,
							<Route key="/adjustments/:adjustmentId" path="/adjustments/:adjustmentId" element={<AdjustmentPage />} />,
							<Route
								key="transaction-monitoring"
								path="/transaction-monitoring"
								element={<TransactionMonitoringSettingsList />}
							/>,
							<Route
								key="transaction-monitoring/new"
								path="/transaction-monitoring/new"
								element={<NewTransactionMonitoringSettings />}
							/>,
							<Route
								key="transaction-monitoring/:settingsId"
								path="/transaction-monitoring/:settingsId"
								element={<TransactionMonitoringSettings />}
							/>,
							<Route key="bins" path="/bins/*" element={<BinsPage />} />,
							<Route
								key="/org-bank-agreements/new"
								path="/org-bank-agreements/new"
								element={<NewOrgBankAgreement />}
							/>,
							<Route path="/linked-accounts/*" key={"/linked-accounts"} element={<LinkedAccountsPage />} />,
							<Route
								path="/linked-accounts/:linkedAccountId/*"
								key={"/linked-accounts/:linkedAccountId"}
								element={<LinkedAccountPage />}
							/>,
					  ]
					: null}
				{/* Legacy routes redirects (old routes that shouldn't be used anymore, but we redirect them in case they were) */}
				<Route path="/org/new" element={<Redirect to="/orgs/new" />} />
				<Route path="/deposit-product/new" element={<Redirect to="/deposit-products/new" />} />
				<Route
					path="/deposit-product/:depositProductId"
					element={<Redirect to="/deposit-products/:depositProductId" />}
				/>
				<Route path="/dispute/:disputeId" element={<Redirect to="/disputes/:disputeId" />} />
				<Route
					path="/application/individual/:applicationId"
					element={<Redirect to="/applications/individual/:applicationId" />}
				/>
				<Route
					path="/application/business/:applicationId"
					element={<Redirect to="/applications/business/:applicationId" />}
				/>
				<Route path="/individual/:customerId" element={<Redirect to="/customers/individual/:customerId" />} />
				<Route path="/business/:customerId" element={<Redirect to="/customers/business/:customerId" />} />
				<Route path="/businessFBO/:customerId" element={<Redirect to="/customers/businessFBO/:customerId" />} />
				<Route
					path="/individualWallet/:customerId"
					element={<Redirect to="/customers/individualWallet/:customerId" />}
				/>
				<Route path="/customer/:customerId" element={<Redirect to="/customers/:customerId" />} />
				<Route path="/account/:accountId" element={<Redirect to="/accounts/:accountId" />} />
				<Route path="/payment/:paymentId" element={<Redirect to="/payments/:paymentId" />} />
				<Route path="/adjustment/:adjustmentId" element={<Redirect to="/adjustments/:adjustmentId" />} />,
				<Route path="/incoming-ach/:incomingAchId" element={<Redirect to="/incoming-achs/:incomingAchId" />} />,
				<Route path="/incoming-check/:incomingCheckId" element={<Redirect to="/incoming-checks/:incomingCheckId" />} />,
				<Route key="/interest-settings" path="/interest-settings" element={<InterestSettingsPage />} />,
				<Route path="/transactions-monitoring" element={<Redirect to="/transaction-monitoring" />} />,
				<Route path="/org-bank-agreement/new" element={<Redirect to="/org-bank-agreements/new" />} />,
				<Route path="/org-bank-agreement/:id" element={<Redirect to="/org-bank-agreements/:id" />} />
				{homeRoute}
			</Routes>
		</>
	)
}

export function AuthenticatedDefaultLayout() {
	return (
		<AuthenticatedRoute>
			<DefaultLayout />
		</AuthenticatedRoute>
	)
}
