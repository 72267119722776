import React from "react"
import {MainSection} from "../../containers/MainSection/MainSection"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import TitleBar from "../../components/TitleBar/TitleBar"
import Icon from "../../components/Icon/Icon"

export default function UnitPilotOverview() {
	return (
		<>
			<TitleBar title={"Pilot Overview"} />
			<div className="unit-pilot-overview">
				<MainSection>
					<div className="columns">
						<div className="column is-9 is-flex is-flex-direction-column">
							<div className="card is-flex-grow-1">
								<div className="card-header no-border-bottom">
									<p className="card-header-title">What you get from pilot</p>
								</div>
								<div className="card-content">
									<ul className="grid-list two-columns">
										<li>
											<FontAwesomeIcon className="icon" icon={faCheck} />
											<span>Test core features of the Unit platform.</span>
										</li>
										<li>
											<FontAwesomeIcon className="icon" icon={faCheck} />
											<span>Use the API and Dashboard to create accounts, cards, payments, and more.</span>
										</li>
										<li>
											<FontAwesomeIcon className="icon" icon={faCheck} />
											<span>Use real accounts and funds.</span>
										</li>
										<li>
											<FontAwesomeIcon className="icon" icon={faCheck} />
											<span>Start building now.</span>
										</li>
									</ul>
								</div>
							</div>
							<div className="card is-flex-grow-1">
								<div className="card-header no-border-bottom">
									<p className="card-header-title">Unit pilot limitations</p>
								</div>
								<div className="card-content">
									<ul className="grid-list three-columns two-rows">
										<li className="card-list-item">
											<div className="item-title">
												<Icon className={"icon"} icon="man-business-suitcase---users" size={20} />
												Up to 1 Individual & 1 Business account
											</div>
											<div className="item-description">
												You may create two customers - One individual and one business, and up to one account per
												customer
											</div>
										</li>
										<li className="card-list-item">
											<div className="item-title">
												<Icon
													className={"icon"}
													icon="account-credit-card-mobile-smartphone-wiring--business-products"
													size={20}
												/>
												1 Virtual card
											</div>
											<div className="item-description">
												You may create one virtual card for each account. Creation of physical cards has been disabled
											</div>
										</li>
										<li className="card-list-item">
											<div className="item-title">
												<Icon className={"icon"} icon="billing-warning--business-products" size={20} />
												Amount limits
											</div>
											<div className="item-description">
												All transaction limits are set to low daily and monthly amounts
											</div>
										</li>
										<li className="card-list-item">
											<div className="item-title">
												<Icon className={"icon"} icon="analytic-percentage--business-products" size={20} />
												No fees
											</div>
											<div className="item-description">
												All fees are set to $0, and no interest or interchange revenue is distributed
											</div>
										</li>
										<li className="card-list-item">
											<div className="item-title">
												<Icon className={"icon"} icon="money-transfer--business-products" size={20} />
												Disabled high-risk financial products
											</div>
											<div className="item-description">
												Outgoing wires, ACH debits and check deposits are not enabled
											</div>
										</li>
										<li className="card-list-item">
											<div className="item-title">
												<Icon className="icon" icon="user-geometric-action-lock---users" size={20} />
												Creating users is disabled
											</div>
											<div className="item-description">Creating new Dashboard users has been disabled</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="column is-3 is-full-height">
							<div className="card announcement">
								<div className="card-content">
									<div className="icon-wrapper">
										<Icon className="icon" icon="astronomy-rocket-1--school-science" size={14} color="#FFFFFF" />
									</div>
									<div className="announcement-title">Ready to go live?</div>
									<div className="list-title">What you get by going live:</div>
									<ul className="bullet-list">
										<li>Every feature available</li>
										<li>Create unlimited customers</li>
										<li>Create unlimited, real bank accounts</li>
										<li>Create unlimited virtual and physical cards</li>
										<li>Configurable limits</li>
										<li>Collect customer fees</li>
										<li>Gain interest and interchange revenue</li>
										<li>Reuse code you’ve already written</li>
										<li>Available after a signed contract</li>
									</ul>
									<a href={"mailto:sales@unit.co"} className="button is-black announcement-button">
										Contact Unit to go live
									</a>
								</div>
							</div>
						</div>
					</div>
				</MainSection>
			</div>
		</>
	)
}
