import React from "react"
import Icon from "../Icon/Icon"
import classNames from "classnames"

interface ContentLoaderProps {
	className?: string
}
export function ContentLoader({className}: ContentLoaderProps) {
	return (
		<span className={classNames("content-loader", className)}>
			<Icon icon="Icons_loader" size={24} className="spin" />
		</span>
	)
}
