import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import {Customer, updateCustomerAuthorizedUsers} from "../../resources/customer"
import {OkDocument} from "../../resources/common"

type CloseFunc = () => void
type RefreshFunc = () => void

interface NewModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	customer: OkDocument<Customer>
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		close()
		refresh()
	}
}

export function NewCustomerAuthorizedUserModal({close, refresh, customer}: NewModalProps) {
	const authorizedUsers = customer.data.attributes.authorizedUsers
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(updateCustomerAuthorizedUsers)

	const [firstName, setFirstName] = useState<string>("")
	const [lastName, setLastName] = useState<string>("")
	const [email, setEmail] = useState<string>("")
	const [countryCode, setCountryCode] = useState<string>("")
	const [number, setNumber] = useState<string>("")

	return (
		<AsyncResultModal
			title="Create new authorized user"
			onSubmit={() => {
				// Clone array before adding the new element to avoid showing the new object in case of an error
				const cloned = Object.assign([], authorizedUsers)

				cloned.push({
					fullName: {
						first: firstName,
						last: lastName,
					},
					email: email,
					phone: {
						countryCode: countryCode,
						number: number,
					},
				})

				create(accessToken, customer, cloned)
			}}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refreshAndClose(refresh, close)}
		>
			<div className="field">
				<label className="label">First Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="First Name"
						value={firstName}
						required
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Last Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Last Name"
						value={lastName}
						required
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Email</label>
				<div className="control">
					<input
						className="input"
						type="email"
						placeholder="Email"
						value={email}
						required
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Phone - Country Code</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Country Code"
						value={countryCode}
						required
						pattern="^(1|7|(\d{2,3})|(1\d{3}))$"
						onChange={(e) => setCountryCode(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Phone - Number</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Number"
						value={number}
						required
						pattern="^\d+$"
						onChange={(e) => setNumber(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
