import React, {useMemo} from "react"
import {BeneficialOwnerResource} from "../../resources/beneficialOwner"
import {
	DataTable,
	DataTableActionHeader,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
} from "../DataTable/DataTable"
import {startCase} from "lodash"
import {hiddenValue, isMasked} from "../../resources/common"
import {
	datePreview,
	emailPreview,
	InformationHiding,
	phonePreview,
	ssnPreview,
} from "../InformationHiding/InformationHiding"
import {useIsUnitAdminUser, useIsUnitComplianceUser} from "../../services/auth"
import moment from "moment"
import {useModal} from "react-modal-hook"
import EditBeneficialOwnerModal from "./EditBeneficialOwnerModal"
import {useToasts} from "react-toast-notifications"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"

interface BeneficialOwnerRowProps {
	beneficialOwner: BeneficialOwnerResource
	refresh: () => void
}

function BeneficialOwnerRow({beneficialOwner: bo, refresh}: BeneficialOwnerRowProps) {
	const document =
		bo.attributes.ssn ||
		bo.attributes.maskedSSN ||
		bo.attributes.passport ||
		bo.attributes.maskedPassport ||
		bo.attributes.matriculaConsular ||
		bo.attributes.maskedMatriculaConsular
			? bo.attributes.ssn || bo.attributes.maskedSSN
				? `SSN ${bo.attributes.ssn ?? bo.attributes.maskedSSN}`
				: bo.attributes.passport || bo.attributes.maskedPassport
				? `${bo.attributes.nationality ?? ""} PASSPORT ${bo.attributes.passport ?? bo.attributes.maskedPassport}`
				: `MatriculaConsular ${bo.attributes.matriculaConsular ?? bo.attributes.maskedMatriculaConsular}`
			: undefined
	const {address} = bo.attributes

	const addressText = useMemo(() => {
		const street2 = address?.street2 ? `${address.street2}, ` : ""
		const city = address?.state
			? `${address?.city}, ${address.state} ${address.postalCode}, `
			: `${address?.city} ${address?.postalCode}, `

		return `${address?.street}, ${street2}${city}${address?.country}`
	}, [address?.street, address?.street2, address?.city, address?.state, address?.postalCode, address?.country])

	let statusClassname
	switch (bo.attributes.status) {
		case "Approved":
			statusClassname = "has-text-success"
			break
		case "Denied":
			statusClassname = "has-text-danger"
			break
		case "PendingReview":
			statusClassname = "has-text-warning"
			break
	}
	const {addToast} = useToasts()
	const isUnitComplianceUser = useIsUnitComplianceUser()
	const isUnitAdmin = useIsUnitAdminUser()

	const [showEditModal, hideEditModal] = useModal(() => (
		<EditBeneficialOwnerModal bo={bo} refresh={refresh} addToast={addToast} close={hideEditModal} />
	))

	return (
		<DataTableRow>
			<DataTableCell>
				{bo.attributes.fullName.first} {bo.attributes.fullName.last}
			</DataTableCell>
			<DataTableCell className={statusClassname}>{startCase(bo.attributes.status)}</DataTableCell>
			<DataTableCell>
				{document ? (
					isMasked(document) ? (
						document.replace(/[*]/g, "●")
					) : (
						<InformationHiding
							element={(document) => <>{document}</>}
							getValue={() => document ?? ""}
							placeholder={document ? ssnPreview(document) : ""}
							defaultIsHidden={!isUnitComplianceUser}
						/>
					)
				) : (
					hiddenValue
				)}
			</DataTableCell>
			<DataTableCell>
				{bo.attributes.dateOfBirth ? (
					<InformationHiding
						element={(value) => <>{value}</>}
						getValue={() => moment(bo.attributes.dateOfBirth).format("L")}
						placeholder={datePreview()}
						defaultIsHidden={!isUnitComplianceUser}
					/>
				) : (
					hiddenValue
				)}
			</DataTableCell>
			<DataTableCell>{bo.attributes.percentage ? `${bo.attributes.percentage}%` : "N/A"}</DataTableCell>
			<DataTableCell>
				{bo.attributes.phone ? (
					<InformationHiding
						element={(phoneNumber) => <>{phoneNumber}</>}
						getValue={() => `+${bo.attributes.phone?.countryCode} ${bo.attributes.phone?.number}`}
						placeholder={phonePreview(bo.attributes.phone.number)}
						defaultIsHidden={!isUnitComplianceUser}
					/>
				) : (
					hiddenValue
				)}
			</DataTableCell>
			<DataTableCell>
				{bo.attributes.email ? (
					<InformationHiding
						element={(email) => <>{email}</>}
						getValue={() => bo.attributes.email ?? ""}
						placeholder={emailPreview(bo.attributes.email)}
						defaultIsHidden={!isUnitComplianceUser}
					/>
				) : (
					hiddenValue
				)}
			</DataTableCell>
			<DataTableCell>{bo.attributes.address ? addressText : hiddenValue}</DataTableCell>
			{isUnitAdmin && (
				<DataTableActionButton enableActions={true}>
					<DataTableActionItem title={"Edit"} icon={"pencil-1--interface-essential"} onClick={() => showEditModal()} />
				</DataTableActionButton>
			)}
		</DataTableRow>
	)
}

export default function BeneficialOwners({
	beneficialOwners,
	refresh,
}: {
	beneficialOwners: BeneficialOwnerResource[]
	refresh: () => void
}) {
	const rows = beneficialOwners.map((bo, index) => (
		<BeneficialOwnerRow refresh={refresh} beneficialOwner={bo} key={index} />
	))

	return (
		<DataTableCard className={"app-owners"}>
			<DataTableActionHeader title={" Beneficial Owners"} />
			<div className={"app-docs-table"}>
				<DataTable fullHeight={false} hoverable={false} clickable={false}>
					<DataTableHead>
						<DataTableRow>
							<DataTableCell>Name</DataTableCell>
							<DataTableCell>Evaluation</DataTableCell>
							<DataTableCell>Document</DataTableCell>
							<DataTableCell>Birth Date</DataTableCell>
							<DataTableCell>Percentage</DataTableCell>
							<DataTableCell>Phone</DataTableCell>
							<DataTableCell>Email</DataTableCell>
							<DataTableCell>Address</DataTableCell>
							{useIsUnitComplianceUser() && <DataTableCell>Actions</DataTableCell>}
						</DataTableRow>
					</DataTableHead>
					<tbody>{rows}</tbody>
				</DataTable>
			</div>
		</DataTableCard>
	)
}
