import React, {ReactElement} from "react"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {ApiToken, getUserApiTokens, isExpired, revokeApiToken} from "../../resources/apiToken"
import {useAccessToken} from "../../services/auth"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import moment from "moment"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {Meta, Resource} from "../../resources/common"
import classNames from "classnames"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"

export enum ApiTokensColumns {
	id = "Id",
	description = "Description",
	createdAt = "Created At",
	expiration = "Expiration",
	sourceIPs = "Source IPs",
	actions = "Actions",
}

type AllowedApiTokensColumns =
	| ApiTokensColumns.id
	| ApiTokensColumns.description
	| ApiTokensColumns.createdAt
	| ApiTokensColumns.expiration
	| ApiTokensColumns.sourceIPs
	| ApiTokensColumns.actions

interface ApiTokensProps {
	userId: string
	includedColumns: AllowedApiTokensColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	refreshToken?: number
}

interface ApiTokensTableProps {
	apiTokens: ApiToken[]
	include?: Resource[]
	includedColumns: AllowedApiTokensColumns[]
	meta?: Meta
	refresh: () => void
	fullHeight?: boolean
	userId: string
	accessToken: string
}

interface ApiTokenRowProps {
	accessToken: string
	userId: string
	apiToken: ApiToken
	refresh: () => void
	includedColumns: AllowedApiTokensColumns[]
}

function ApiTokenRow({apiToken, includedColumns, accessToken, refresh, userId}: ApiTokenRowProps) {
	const [, revoke] = useAsyncResultIdle((token) => revokeApiToken(accessToken, userId, token.id))
	const revokeAndRefresh = (t: ApiToken) => revoke(t).then(refresh)

	const id = apiToken.id
	const description = apiToken.attributes.description
	const createdAt = apiToken.attributes.createdAt
	const expiration = apiToken.attributes.expiration
	const sourceIp = apiToken.attributes.sourceIp

	const contentColumns: Partial<Record<AllowedApiTokensColumns, ReactElement>> = {}

	if (includedColumns.includes(ApiTokensColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ApiTokensColumns.description)) {
		contentColumns["Description"] = <DataTableCell>{description}</DataTableCell>
	}

	if (includedColumns.includes(ApiTokensColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(ApiTokensColumns.expiration)) {
		contentColumns["Expiration"] = <DataTableCell>{moment(expiration).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(ApiTokensColumns.sourceIPs)) {
		contentColumns["Source IPs"] = <DataTableCell style={{wordBreak: "break-word"}}>{sourceIp}</DataTableCell>
	}

	if (includedColumns.includes(ApiTokensColumns.actions)) {
		contentColumns["Actions"] = (
			<DataTableActionButton enableActions={true}>
				<DataTableActionItem
					title={"Revoke"}
					icon={"interface-delete-interface-essential"}
					onClick={() => revokeAndRefresh(apiToken)}
				/>
			</DataTableActionButton>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow className={classNames(isExpired(apiToken) && "expired")}>{content}</DataTableRow>
}

function ApiTokensTable({apiTokens, includedColumns, refresh, fullHeight, userId, accessToken}: ApiTokensTableProps) {
	return (
		<div className={"api-tokens-table"}>
			<DataTable
				isEmpty={apiTokens.length === 0}
				fullHeight={fullHeight}
				stickyAction={false}
				hoverable={false}
				clickable={false}
				noContentText={"No API tokens found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							return (
								<DataTableCell
									className={classNames(column[1] === ApiTokensColumns.actions && "has-text-centered")}
									key={column[0]}
								>
									{column[1]}
								</DataTableCell>
							)
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{apiTokens.map((token) => {
						return (
							<ApiTokenRow
								key={token.id}
								accessToken={accessToken}
								userId={userId}
								apiToken={token}
								refresh={refresh}
								includedColumns={includedColumns}
							/>
						)
					})}
				</DataTableBody>
			</DataTable>
		</div>
	)
}

export function ApiTokens({userId, fullHeight, includedColumns, enableTitle, refreshToken}: ApiTokensProps) {
	const accessToken = useAccessToken()
	const [_, refresh] = useRefreshToken()
	const result = useAsyncResult(() => getUserApiTokens(accessToken, userId), [refreshToken, _])

	return (
		<DataTableCard className={"api-tokens-card"}>
			<DataTableActionHeader enableSticky={false} title={enableTitle ? "Api Tokens " : null} />
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: apiTokens}) => {
					return (
						<ApiTokensTable
							apiTokens={apiTokens.data}
							meta={apiTokens.meta}
							refresh={refresh}
							include={apiTokens.included}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							userId={userId}
							accessToken={accessToken}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
