import React, {PropsWithChildren} from "react"
import {AssigneeAvatarWithName} from "../AssigneeAvatarWithName/AssigneeAvatarWithName"
import {AssignmentItemStatus} from "../AssignmentItemStatus/AssignmentItemStatus"
import {Assignee, AssignmentStatus} from "../../resources/assignments"
import {AssignmentItemBadge} from "../AssignmentItemBadge/AssignmentItemBadge"
import {FileMetadata} from "../../utilities/file"

type FileToDownload = {
	fileMetadata: FileMetadata
	handleDownload: () => void
}
interface AssignmentPageCompletedItemHeaderProps {
	assignees: Assignee[]
	status: AssignmentStatus
	reason?: string
	reasonText?: string
	fileToDownload?: FileToDownload
}

export function AssignmentPageCompletedItemHeader({
	assignees,
	status,
	reason,
	reasonText,
	children,
}: PropsWithChildren<AssignmentPageCompletedItemHeaderProps>) {
	return (
		<section className="assignment-page-completed-item-header">
			<div className="assignment-page-completed-item-header-status">
				<AssigneeAvatarWithName assignees={assignees} size="medium" />
				<AssignmentItemStatus status={status} theme="dark" />
				{reason && (
					<AssignmentItemBadge text={reason} theme="dark" tooltip="Reason" tooltipKey="assignment-reason-tooltip" />
				)}
			</div>
			{reasonText && (
				<textarea
					className="text-area-with-avatar-textarea textarea has-fixed-size assignment-page-completed-item-header-reason-text"
					rows={4}
					value={reasonText}
					disabled
				/>
			)}
			{children}
		</section>
	)
}
