import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {createDispute, DisputeType} from "../../resources/dispute"
import {Transaction} from "../../resources/transaction"
import {toNumber} from "lodash"
import numeral from "numeral"

type CloseFunc = () => void
type RefreshFunc = () => void

export const descriptionPrefill = [
	"Card Lost/Stolen",
	"Unauthorized",
	"Duplicate Transaction",
	"Terminal Dispense Error",
	"Goods/Services Not Received",
	"Returned Merchandise",
	"Debit Card Billed Twice",
	"Defective Merchandice/Not as Described",
	"Cancelled Services/Merchandise/Reservation",
	"Paid by Other Means",
	"Incorrect Amount",
]

interface NewModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	accountId: number
	transaction: Transaction
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		close()
		refresh()
	}
}

export function NewDisputeModal({close, refresh, accountId, transaction}: NewModalProps) {
	const accessToken = useAccessToken()
	const txAmount = transaction.attributes.amount
	const [state, create] = useAsyncResultIdle(createDispute)
	const [externalId, setExternalId] = useState<string>("")
	const [link, setLink] = useState<string>("")
	const [description, setDescription] = useState<string | null>(null)
	const [disputeType, setDisputeType] = useState<string>("")
	const [amount, setAmount] = useState<number>(txAmount)

	return (
		<AsyncResultModal
			title="New dispute"
			onSubmit={() =>
				create(
					accessToken,
					externalId,
					link ? link : null,
					description,
					disputeType,
					String(accountId),
					String(transaction.id),
					amount,
					disputeType === DisputeType.Fraud || disputeType === DisputeType.NonFraud
				)
			}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refreshAndClose(refresh, close)}
		>
			<div className="field">
				<label className="label">External ID</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="External Ticket Id"
						value={externalId}
						maxLength={255}
						required
						onChange={(e) => setExternalId(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">External Ticket Url</label>
				<div className="control">
					<input
						className="input"
						type="url"
						placeholder="https://example.com"
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Amount $</label>
				<div className="control">
					<input
						className="input"
						type="number"
						placeholder={numeral(txAmount / 100).format("$0,0.00")}
						value={amount / 100 || undefined}
						pattern="^\$[0-9]+(\.[0-9][0-9])?$"
						required
						onChange={(e) => setAmount(toNumber(toNumber(e.target.value).toFixed(2)) * 100)}
					/>
				</div>
			</div>

			<div className="field">
				<label className="label">Reason</label>
				<div className="select is-fullwidth mb-2">
					<select
						required
						onChange={(e) => {
							setDisputeType(e.target.value)
						}}
					>
						<option selected={disputeType == ""} value="" disabled></option>
						{Object.values(DisputeType).map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="field">
				<label className="label">Description</label>
				<div className="select is-fullwidth mb-2">
					<select
						defaultValue=""
						onChange={(e) => {
							setDescription(e.target.value)
						}}
					>
						{descriptionPrefill.map((option) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
						<option value="">Other</option>
					</select>
				</div>
				<div className="control">
					<textarea
						placeholder="Description"
						className="textarea has-fixed-size"
						value={description ?? ""}
						required
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
