import React from "react"
import Card from "../../../containers/Card/Card"
import DepositProductField from "./DepositProductField/DepositProductField"
import {DepositProductClearingProps} from "./types"
import {EditMode} from "../../../resources/common"
import classNames from "classnames"
import {toInteger} from "lodash"

export default function Clearing({editMode, values, setFieldValue, onBlur}: DepositProductClearingProps) {
	const achDebitMinClearingDays = 3
	const checkDebitMinClearingDays = 5
	const infoTooltip =
		"When Dynamic is Enabled, you will be able to override the default clearing days in the Deposit Product and control when funds will become available in the end-customer’s Deposit Account"
	const getLinkLabel = (text: string, link: string) => (
		<a className="" target="_blank" rel="noreferrer" href={link}>
			{text}
		</a>
	)

	const options = (startValue: number) => {
		const optionList = []
		for (let index = 0; index <= 10; index++) {
			optionList.push({
				value: `${index}`,
				label: `${index} day${index > 1 ? "s" : ""} + ${index < startValue ? "disabled" : ""}`,
			})
		}
		return optionList
	}

	return (
		<Card
			className="deposit-product-card-title"
			title={
				<span className="card-title" id="Clearing">
					Clearing
				</span>
			}
		>
			<a className="sub-title link">{getLinkLabel("ACH Debit clearing", "https://guides.unit.co/ach/#clearing")}</a>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<div className="form-row view four-connect">
					<DepositProductField
						mode={editMode}
						type="select"
						name="achDebitMinClearingDays"
						label={"Minimum clearing days"}
						value={values.achDebitMinClearingDays ?? "-"}
						onChange={(value) => setFieldValue("achDebitMinClearingDays", toInteger(value))}
						inputSuffix={values.achDebitMinClearingDays ? "days" : ""}
						onBlur={onBlur}
					/>
					<DepositProductField
						mode={editMode}
						type="select"
						name="achDebitClearingDays"
						label={"Default clearing days"}
						value={values.achDebitClearingDays.toString()}
						onChange={(value) => setFieldValue("achDebitClearingDays", toInteger(value))}
						inputSuffix="days"
						onBlur={onBlur}
					>
						{options(achDebitMinClearingDays).map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</DepositProductField>
					<DepositProductField
						mode={editMode}
						type="select"
						name="achDebitMaxClearingDays"
						label={"Maximum clearing days"}
						value={values.achDebitMaxClearingDays ?? "-"}
						onChange={(value) => setFieldValue("achDebitMaxClearingDays", toInteger(value))}
						inputSuffix={values.achDebitMaxClearingDays ? "days" : ""}
						onBlur={onBlur}
					/>
				</div>
				<DepositProductField
					mode={editMode}
					type="select"
					name="Dynamic"
					label={"Dynamic"}
					value={values.enableDynamicClearingDaysAchDebit}
					onChange={(value) => setFieldValue("enableDynamicClearingDaysAchDebit", toInteger(value))}
					onBlur={onBlur}
					infoIconTooltipText={infoTooltip}
					enableField={true}
				/>
			</div>
			<a className="sub-title link">
				{getLinkLabel("Check Deposit clearing", "https://guides.unit.co/check-deposits/#clearing")}
			</a>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<div className={classNames("form-row four-connect", editMode === EditMode.VIEW && "view")}>
					<DepositProductField
						mode={editMode}
						type="select"
						name="checkDepositMinClearingDays"
						label={"Minimum clearing days"}
						value={values.checkDepositMinClearingDays ?? "-"}
						onChange={(value) => setFieldValue("checkDepositMinClearingDays", toInteger(value))}
						inputSuffix={values.checkDepositMinClearingDays ? "days" : ""}
						onBlur={onBlur}
					/>
					<DepositProductField
						mode={editMode}
						type="select"
						name="checkDepositClearingDays"
						label={"Default clearing days"}
						value={values.checkDepositClearingDays.toString() ?? "5"}
						onChange={(value) => setFieldValue("checkDepositClearingDays", toInteger(value))}
						inputSuffix="days"
						onBlur={onBlur}
					>
						{options(checkDebitMinClearingDays).map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</DepositProductField>
					<DepositProductField
						mode={editMode}
						type="select"
						name="checkDepositMaxClearingDays"
						label={"Maximum clearing days"}
						value={values.checkDepositMaxClearingDays ?? "-"}
						onChange={(value) => setFieldValue("checkDepositMaxClearingDays", toInteger(value))}
						inputSuffix={values.checkDepositMaxClearingDays ? "days" : ""}
						onBlur={onBlur}
					/>
				</div>
				<DepositProductField
					mode={editMode}
					type="select"
					name="Dynamic"
					label={"Dynamic"}
					value={values.enableCheckDepositDynamicClearingDays ?? "Disabled"}
					onChange={(value) => setFieldValue("enableCheckDepositDynamicClearingDays", toInteger(value))}
					onBlur={onBlur}
					infoIconTooltipText={infoTooltip}
					enableField={true}
				/>
			</div>
		</Card>
	)
}
