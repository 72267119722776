import {Adjustment, setAdjustmentAction, Status, StatusType, StatusTypeValue} from "../../resources/adjustment"
import {useAccessToken} from "../../services/auth"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {ErrorDocument} from "../../resources/common"
import React, {useEffect} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons"

export function ActionButtons({adj, refresh}: {adj: Adjustment; refresh: (status?: Status) => void}) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [stateAction, action] = useAsyncResultIdle(setAdjustmentAction)
	const status = adj.attributes.statusHistory.slice(-1)[0]
	const statusType = status.type

	function actionResult(state: AsyncResultIdleRequestState<Adjustment, ErrorDocument>) {
		return () => {
			if (state.isErr()) {
				const errors = state.error.errors[0]
				const msg = errors.detail ? errors.detail : errors.title
				addToast(msg, {appearance: "error"})
			} else if (state.isOk()) {
				const currentStatus = state.value.attributes.statusHistory.slice(-1)[0]
				addToast("Adjustment Updated Successfully", {appearance: "success"})
				refresh(currentStatus)
			}
		}
	}

	useEffect(actionResult(stateAction), [stateAction])

	function Button({id, icon, route}: {id: string; icon: any; route: string}) {
		const button = (
			<button
				className="button is-small"
				id={id.concat(route)}
				onClick={(e) => {
					e.stopPropagation()
					action(accessToken, adj.id, route)
				}}
			>
				<span className="icon">
					<FontAwesomeIcon icon={icon} />
				</span>
			</button>
		)
		return (
			<td>
				{stateAction.match(
					() => button,
					() => (
						<button className="button is-light is-loading">Updating...</button>
					),
					() => button,
					() => button
				)}
			</td>
		)
	}

	if (StatusTypeValue(statusType) === StatusType.WaitingForSecondSignature) {
		return (
			<div className="table-container">
				<table className="table is-narrow">
					<tbody>
						<tr>
							<Button id={adj.id} icon={faThumbsUp} route={"approve"} />
							<Button id={adj.id} icon={faThumbsDown} route={"cancel"} />
						</tr>
					</tbody>
				</table>
			</div>
		)
	} else return null
}
