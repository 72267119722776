import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import Users, {CreateUserButton, UsersColumns} from "../../components/Users/Users"
import {useIsBankAdminUser, useIsOrgUser, useIsPartnerUser, useIsUnitUser} from "../../services/auth"
import {useModal} from "react-modal-hook"
import {OrgNewUserModal} from "../../components/Users/OrgNewUserModal"
import {BankNewUserModal} from "../../components/Users/BankNewUserModal"
import UnitNewUserModal from "../../components/Users/UnitNewUserModal"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {PartnerNewUserModal} from "../../components/Users/PartnerNewUserModal"

export default function UsersPage(): ReactElement {
	const isOrgToken = useIsOrgUser()
	const isPartnerToken = useIsPartnerUser()
	const isBankToken = useIsBankAdminUser()
	const [refreshToken, refresh] = useRefreshToken()

	const [showModal, hideModal] = useModal(() => {
		if (isOrgToken) return <OrgNewUserModal close={hideModal} refresh={refresh} />
		else if (isPartnerToken) return <PartnerNewUserModal close={hideModal} refresh={refresh} />
		else if (isBankToken) return <BankNewUserModal close={hideModal} refresh={refresh} />
		else return <UnitNewUserModal close={hideModal} refresh={refresh} />
	})

	return (
		<>
			<TitleBar title={"Users"}>
				<CreateUserButton showModal={showModal} />
			</TitleBar>

			<MainSectionTable>
				<Users
					refreshToken={refreshToken}
					enableOrgFilter={useIsUnitUser()}
					fullHeight={true}
					limit={40}
					includedColumns={[
						UsersColumns.id,
						...(useIsUnitUser() ? [UsersColumns.org] : []),
						UsersColumns.name,
						UsersColumns.type,
						UsersColumns.role,
						UsersColumns.createdAt,
						UsersColumns.actions,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
