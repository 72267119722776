import React, {Dispatch, SetStateAction, useEffect} from "react"
import {toInteger, toNumber} from "lodash"
import {Org} from "../../resources/org"
import {InterestSettings} from "../../resources/interestSettings"
import {Bank} from "../../resources/bank"
import {LimitGroup, LimitGroupType, TimeoutStrategy, TimeoutStrategyType} from "../../resources/depositProduct"
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {BankRouting} from "../../resources/bankRouting"
import {TransactionMonitoringSettings} from "../../resources/transactionMonitoringSettings"
import {EditMode} from "../../resources/common"
import {Bin} from "../../resources/bin"
import classNames from "classnames"
import Icon from "../../components/Icon/Icon"
import BodyPortal from "../../components/BodyPortal/BodyPortal"
import ReactTooltip from "react-tooltip"

interface Props extends DepositProductData {
	editMode: EditMode
	orgs: Array<Org>
	banks: Array<Bank>
	bankRoutings: Array<BankRouting>
	transactionMonitoringSettings: Array<TransactionMonitoringSettings>
	bins: Array<Bin>
	setName: Dispatch<SetStateAction<string>>
	setOrgId: Dispatch<SetStateAction<string>>
	setBankId: Dispatch<SetStateAction<string>>
	setBankRoutingId: Dispatch<SetStateAction<string>>
	setIncomingAchFee: Dispatch<SetStateAction<number>>
	setOutgoingAchFee: Dispatch<SetStateAction<number>>
	setDailyAchDebitLimit: Dispatch<SetStateAction<number>>
	setDailyAchCreditLimit: Dispatch<SetStateAction<number>>
	setMonthlyAchDebitLimit: Dispatch<SetStateAction<number>>
	setMonthlyAchCreditLimit: Dispatch<SetStateAction<number>>
	setDailyAchDebitSoftLimit: Dispatch<SetStateAction<number>>
	setMonthlyAchDebitSoftLimit: Dispatch<SetStateAction<number>>
	setDailyBookDebitLimit: Dispatch<SetStateAction<number | undefined | null>>
	setDailyBookCreditLimit: Dispatch<SetStateAction<number | undefined | null>>
	setMonthlyBookDebitLimit: Dispatch<SetStateAction<number | undefined | null>>
	setMonthlyBookCreditLimit: Dispatch<SetStateAction<number | undefined | null>>
	setDailyCardWithdrawalLimit: Dispatch<SetStateAction<number>>
	setDailyCardDepositLimit: Dispatch<SetStateAction<number>>
	setDailyCardPurchaseLimit: Dispatch<SetStateAction<number>>
	setDailyCardTransactionLimit: Dispatch<SetStateAction<number>>
	setACHDebitClearingDays: Dispatch<SetStateAction<number>>
	setCardIssuingFee: Dispatch<SetStateAction<number | null>>
	setIndividualBinId: Dispatch<SetStateAction<string | null>>
	setBusinessBinId: Dispatch<SetStateAction<string | null>>
	setCardQualifier: Dispatch<SetStateAction<string | null>>
	setLimitGroup: Dispatch<SetStateAction<string | null>>
	setMaxNumberOfPhysicalCards: Dispatch<SetStateAction<number | null>>
	setMaxNumberOfVirtualCards: Dispatch<SetStateAction<number | null>>
	setOrgInterestShare: Dispatch<SetStateAction<number>>
	setCustomerInterestShare: Dispatch<SetStateAction<number>>
	setSponsoredInterestFormula: Dispatch<SetStateAction<string | undefined>>
	setIncomingWireFee: Dispatch<SetStateAction<number>>
	setOutgoingWireFee: Dispatch<SetStateAction<number>>
	setTransactionMonitoringSettingsId: Dispatch<SetStateAction<string>>
	setProgrammaticPurchaseAuthorizationEnabled: Dispatch<SetStateAction<boolean | null>>
	setProgrammaticPurchaseAuthorizationTimeoutStrategy: Dispatch<SetStateAction<string | null>>
	setProgrammaticPurchaseAuthorizationIncludeCredit: Dispatch<SetStateAction<boolean | null>>
	setProgrammaticCardTransactionAuthorizationEnabled: Dispatch<SetStateAction<boolean | null>>
	setProgrammaticCardTransactionAuthorizationTimeoutStrategy: Dispatch<SetStateAction<string | null>>
	setProgrammaticCardTransactionAuthorizationIncludeCredit: Dispatch<SetStateAction<boolean | null>>
	setCheckDepositClearingDays: Dispatch<SetStateAction<number>>
	setCheckDepositMinClearingDays: Dispatch<SetStateAction<number | undefined | null>>
	setCheckDepositMaxClearingDays: Dispatch<SetStateAction<number | undefined | null>>
	setDailyCheckDepositLimit: Dispatch<SetStateAction<number>>
	setMonthlyCheckDepositLimit: Dispatch<SetStateAction<number>>
	setDailyCheckDepositSoftLimit: Dispatch<SetStateAction<number>>
	setMonthlyCheckDepositSoftLimit: Dispatch<SetStateAction<number>>
	setProgrammaticAtmAuthorizationEnabled: Dispatch<SetStateAction<boolean | null>>
	setProgrammaticAtmAuthorizationTimeoutStrategy: Dispatch<SetStateAction<string | null>>
	setProgrammaticAdviceAuthorizationEnabled: Dispatch<SetStateAction<boolean | null>>
	setIsAutoCloseEnabled: Dispatch<SetStateAction<boolean>>
	setAutoCloseAfter: Dispatch<SetStateAction<number>>
	setEnableOverdraft: Dispatch<SetStateAction<boolean>>
	setOverdraftDaysLimit: Dispatch<SetStateAction<number | null>>
	setDailyWireLimit: Dispatch<SetStateAction<number>>
	setMonthlyWireLimit: Dispatch<SetStateAction<number>>
	setDailyWireSoftLimit: Dispatch<SetStateAction<number>>
	setMonthlyWireSoftLimit: Dispatch<SetStateAction<number>>
	setDailyCheckPaymentLimit: Dispatch<SetStateAction<number | undefined | null>>
	setMonthlyCheckPaymentLimit: Dispatch<SetStateAction<number | undefined | null>>
	setDailyCheckPaymentSoftLimit: Dispatch<SetStateAction<number | undefined | null>>
	setMonthlyCheckPaymentSoftLimit: Dispatch<SetStateAction<number | undefined | null>>
	setCheckWritingEnabled: Dispatch<SetStateAction<boolean>>
	isOverrideInterestSettingsId: boolean
	setIsOverrideInterestSettingsId: Dispatch<SetStateAction<boolean>>
	interestSettings: Array<InterestSettings>
	setInterestSettingsId: Dispatch<SetStateAction<string | null>>
	enableCheckDepositDynamicClearingDays: boolean | undefined
	setEnableCheckDepositDynamicClearingDays: Dispatch<SetStateAction<boolean | undefined>>
	enableDynamicClearingDaysAchDebit: boolean | undefined
	setEnableDynamicClearingDaysAchDebit: Dispatch<SetStateAction<boolean | undefined>>
	achDebitMinClearingDays: number | undefined | null
	setAchDebitMinClearingDays: Dispatch<SetStateAction<number | undefined | null>>
	achDebitMaxClearingDays: number | undefined | null
	setAchDebitMaxClearingDays: Dispatch<SetStateAction<number | undefined | null>>
	setIsAchDebitFraudPredictEnabled: Dispatch<SetStateAction<boolean | undefined>>
	setIsSweepEnabled: Dispatch<SetStateAction<boolean | undefined>>
	setCheckWritingOriginationEnabled: Dispatch<SetStateAction<boolean>>
	orgInternationalServiceFee: number | undefined | null
	setOrgInternationalServiceFee: Dispatch<SetStateAction<number | null>>
	setAdditionalVerificationThreshold: Dispatch<SetStateAction<number | undefined | null>>
	setEnableCustomerInterestFormula: Dispatch<SetStateAction<boolean | undefined>>
	setCustomerInterestFormula: Dispatch<SetStateAction<string | undefined>>
}

export interface DepositProductData {
	name: string
	orgId: string
	bankId: string
	bankRoutingId: string
	incomingACHFee: number
	outgoingACHFee: number
	dailyACHDebitLimit: number
	dailyACHCreditLimit: number
	monthlyACHDebitLimit: number
	monthlyACHCreditLimit: number
	dailyACHDebitSoftLimit: number
	monthlyACHDebitSoftLimit: number
	dailyBookDebitLimit: number | undefined | null
	dailyBookCreditLimit: number | undefined | null
	monthlyBookDebitLimit: number | undefined | null
	monthlyBookCreditLimit: number | undefined | null
	dailyCardWithdrawalLimit: number
	dailyCardDepositLimit: number
	dailyCardPurchaseLimit: number
	dailyCardTransactionLimit: number
	achDebitClearingDays: number
	cardIssuingFee: number | null
	individualBinId: string | null
	businessBinId: string | null
	cardQualifier: string | null
	limitGroup: string | null
	maxNumberOfPhysicalCards: number | null
	maxNumberOfVirtualCards: number | null
	orgInterestShare: number
	customerInterestShare: number
	sponsoredInterestFormula: string | undefined
	incomingWireFee: number
	outgoingWireFee: number
	transactionMonitoringSettingsId: string
	programmaticPurchaseAuthorizationEnabled: boolean | null
	programmaticPurchaseAuthorizationTimeoutStrategy: string | null
	programmaticPurchaseAuthorizationIncludeCredit: boolean | null
	programmaticCardTransactionAuthorizationEnabled: boolean | null
	programmaticCardTransactionAuthorizationTimeoutStrategy: string | null
	programmaticCardTransactionAuthorizationIncludeCredit: boolean | null
	checkDepositClearingDays: number
	checkDepositMinClearingDays: number | undefined | null
	checkDepositMaxClearingDays: number | undefined | null
	dailyCheckDepositLimit: number
	monthlyCheckDepositLimit: number
	dailyCheckDepositSoftLimit: number
	monthlyCheckDepositSoftLimit: number
	programmaticAtmAuthorizationEnabled: boolean | null
	programmaticAtmAuthorizationTimeoutStrategy: string | null
	programmaticAdviceAuthorizationEnabled: boolean | null
	isAutoCloseEnabled: boolean
	autoCloseAfter: number
	enableOverdraft: boolean
	overdraftDaysLimit: number | null
	dailyWireLimit: number
	monthlyWireLimit: number
	dailyWireSoftLimit: number
	monthlyWireSoftLimit: number
	dailyCheckPaymentLimit: number | undefined | null
	monthlyCheckPaymentLimit: number | undefined | null
	dailyCheckPaymentSoftLimit: number | undefined | null
	monthlyCheckPaymentSoftLimit: number | undefined | null
	checkWritingEnabled: boolean
	interestSettingsId: string | undefined | null
	enableCheckDepositDynamicClearingDays: boolean | undefined
	enableDynamicClearingDaysAchDebit: boolean | undefined
	achDebitMaxClearingDays: number | undefined | null
	achDebitMinClearingDays: number | undefined | null
	isAchDebitFraudPredictEnabled: boolean | undefined
	isSweepEnabled: boolean | undefined
	checkWritingOriginationEnabled: boolean
	orgInternationalServiceFee: number | undefined | null
	additionalVerificationThreshold: number | undefined | null
	enableCustomerInterestFormula: boolean | undefined
	customerInterestFormula: string | undefined
}

export function Attributes(props: Props) {
	function clearCard() {
		props.setCardIssuingFee(null)
		props.setBusinessBinId(null)
		props.setIndividualBinId(null)
		props.setLimitGroup(null)
		props.setCardQualifier(null)
		props.setMaxNumberOfPhysicalCards(null)
		props.setMaxNumberOfVirtualCards(null)
		props.setProgrammaticPurchaseAuthorizationEnabled(null)
		props.setProgrammaticPurchaseAuthorizationTimeoutStrategy(null)
		props.setProgrammaticPurchaseAuthorizationIncludeCredit(null)
		props.setProgrammaticCardTransactionAuthorizationEnabled(null)
		props.setProgrammaticCardTransactionAuthorizationTimeoutStrategy(null)
		props.setProgrammaticCardTransactionAuthorizationIncludeCredit(null)
		props.setProgrammaticAtmAuthorizationEnabled(null)
		props.setProgrammaticAtmAuthorizationTimeoutStrategy(null)
		props.setProgrammaticAdviceAuthorizationEnabled(null)
		props.setOrgInternationalServiceFee(null)
	}

	function calcMaxAllocation(x: number) {
		return 100 - x
	}

	function isDefaultDebitClearingOptionDisabled(value: number) {
		const {achDebitMaxClearingDays: maxDays, achDebitMinClearingDays: minDays} = props
		return !!maxDays && value > maxDays && !!minDays && value < minDays
	}

	function isAchDebitMaxClearingOptionDisabled(value: number) {
		return value < props.achDebitClearingDays
	}

	function isAchDebitMinClearingOptionDisabled(value: number) {
		return value > props.achDebitClearingDays
	}

	function isDefaultCheckDepositClearingOptionDisabled(value: number) {
		const {checkDepositMaxClearingDays: maxDays, checkDepositMinClearingDays: minDays} = props
		return !!maxDays && value > maxDays && !!minDays && value < minDays
	}

	function isCheckDepositMaxClearingOptionDisabled(value: number) {
		return value < props.checkDepositClearingDays
	}

	function isCheckDepositMinClearingOptionDisabled(value: number) {
		return value > props.checkDepositClearingDays
	}

	useEffect(() => {
		if (props.individualBinId) {
			const bin = props.bins?.find((b) => b.id == props.individualBinId)
			if (bin) {
				const binOrg = bin.relationships.org.data.id
				const binBank = bin.relationships.bank.data.id
				if (binBank != props.bankId || (binOrg != props.orgId && binOrg != "1")) {
					props.setIndividualBinId(() => null)
				}
			}
		}
		if (props.businessBinId) {
			const bin = props.bins?.find((b) => b.id == props.businessBinId)
			if (bin) {
				const binOrg = bin.relationships.org.data.id
				const binBank = bin.relationships.bank.data.id

				if (binBank != props.bankId || (binOrg != props.orgId && binOrg != "1")) {
					props.setBusinessBinId(() => null)
				}
			}
		}
	}, [props.orgId, props.bankId])

	return (
		<>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Basic</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Name</label>
								</div>
								<div className="field-body">
									<div className="control">
										<input
											className="input"
											type="text"
											placeholder="checking"
											value={props.name}
											required
											onChange={(e) => props.setName(e.target.value)}
											disabled={props.editMode != EditMode.NEW}
										/>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Org</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.orgId}
											onChange={(e) => props.setOrgId(e.target.value)}
											disabled={props.editMode != EditMode.NEW}
										>
											{props.orgs.map((o) => (
												<option key={o.id} value={o.id.toString()}>
													{o.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Bank</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.bankId}
											onChange={(e) => props.setBankId(e.target.value)}
											disabled={props.editMode != EditMode.NEW}
										>
											{props.banks.map((b) => (
												<option key={b.id} value={b.id.toString()}>
													{b.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Routing</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.bankRoutingId}
											onChange={(e) => props.setBankRoutingId(e.target.value)}
											disabled={props.editMode != EditMode.NEW}
										>
											{props.bankRoutings
												.filter((r) => r.relationships.bank.data.id === props.bankId)
												.map((r) => (
													<option key={r.id} value={r.id.toString()}>
														{r.attributes.name}
													</option>
												))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Org Interest Share</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.orgInterestShare}
												required
												min="0"
												max={calcMaxAllocation(props.customerInterestShare)}
												onChange={(e) => props.setOrgInterestShare(toInteger(e.target.value))}
											/>
										</div>
										<p className="control">
											<a className="button is-static">%</a>
										</p>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Customer Interest Share</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.customerInterestShare}
												required
												min="0"
												max={calcMaxAllocation(props.orgInterestShare)}
												onChange={(e) => props.setCustomerInterestShare(toInteger(e.target.value))}
											/>
										</div>
										<p className="control">
											<a className="button is-static">%</a>
										</p>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Sponsored Interest Formula</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.sponsoredInterestFormula}
												min="0"
												onChange={(e) => props.setSponsoredInterestFormula(e.target.value)}
											/>
										</div>
										<p className="control">
											<a className="button is-static">bps</a>
										</p>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Transaction Monitoring Settings</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.transactionMonitoringSettingsId}
											onChange={(e) => props.setTransactionMonitoringSettingsId(e.target.value)}
											required
										>
											{props.transactionMonitoringSettings.map((a) => (
												<option key={a.id} value={a.id.toString()}>
													{a.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Enable auto-close of accounts</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={props.isAutoCloseEnabled}
													onChange={(e) => {
														props.setIsAutoCloseEnabled(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
										<div className="control is-align-items-center is-flex">
											<label className={classNames("mr-2", props.editMode == EditMode.VIEW && "has-text-grey")}>
												Auto close after:{" "}
											</label>
											<div className="control">
												<input
													className="input"
													type="number"
													min="0"
													value={toNumber(props.autoCloseAfter)}
													disabled={props.editMode == EditMode.VIEW || !props.isAutoCloseEnabled}
													onChange={(e) => props.setAutoCloseAfter(toNumber(e.target.value))}
												/>
											</div>
											<p className="control">
												<a className="button is-static">Days</a>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Interest</p>
						</div>
						<div className="card-content">
							<div className="field is-align-items-center is-horizontal">
								<p className="control mr-6">
									<label className="checkbox">
										<input
											type="checkbox"
											checked={props.isOverrideInterestSettingsId}
											onChange={(e) => {
												const isEnabled = e.target.checked
												props.setIsOverrideInterestSettingsId(isEnabled)
												if (!isEnabled) props.setInterestSettingsId(null)
											}}
										/>{" "}
										Override Org interest settings
									</label>
								</p>
								<div className="control is-align-items-center is-flex">
									<label className={classNames("mr-2", props.editMode == EditMode.VIEW && "has-text-grey")}>
										Settings:{" "}
									</label>
									<div className="field-body">
										<div className="select">
											<select
												required
												value={props.interestSettingsId ?? undefined}
												onChange={(e) => props.setInterestSettingsId(e.target.value)}
												disabled={props.editMode == EditMode.VIEW || !props.isOverrideInterestSettingsId}
											>
												<option key="0" defaultValue=""></option>
												{props.interestSettings
													.filter((interestSettings) => interestSettings.relationships.bank.data.id === props.bankId)
													.map(({id, attributes: {name}}) => (
														<option key={id} value={id.toString()}>
															{name}
														</option>
													))}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-content">
							<div className="field is-align-items-center is-horizontal">
								<p className="control mr-6">
									<label className="checkbox">
										<input
											type="checkbox"
											checked={props.enableCustomerInterestFormula}
											onChange={(e) => {
												const isEnabled = e.target.checked
												props.setEnableCustomerInterestFormula(isEnabled)
											}}
										/>{" "}
										Enable Customer Interest Formula
									</label>
								</p>
								<div className="field is-horizontal">
									<div className="field-label is-normal">
										<label className="label">
											Formula:
											<Icon
												className="ml-2"
												data-tip={
													"Add customer fixed rate in basis points (300 = 3%) or apply a customer interest formula (use upper case letters for the formula, for example:  FED_UPPER_BOUND - 20)"
												}
												icon={"information-circle--interface-essential"}
												size={16}
											/>
											<BodyPortal>
												<ReactTooltip type="dark" effect="solid" className={"tooltip-info"} />
											</BodyPortal>
										</label>
									</div>
								</div>
								<div className="control is-align-items-center is-flex">
									<div className="field-body">
										<div className="control">
											<input
												className="input wider-column"
												type="text"
												value={props.customerInterestFormula}
												min="0"
												onChange={(e) => props.setCustomerInterestFormula(e.target.value)}
												disabled={props.editMode == EditMode.VIEW || !props.enableCustomerInterestFormula}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">ACH</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Incoming ACH Fee</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">¢</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.incomingACHFee}
												required
												onChange={(e) => props.setIncomingAchFee(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Outgoing ACH Fee</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">¢</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.outgoingACHFee}
												required
												onChange={(e) => props.setOutgoingAchFee(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Dynamic Clearing Period (ACH Debit)</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.enableDynamicClearingDaysAchDebit}
													onChange={(e) => {
														props.setEnableDynamicClearingDaysAchDebit(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Default Debit Clearing Period</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<div className="select">
												<select
													value={props.achDebitClearingDays.toString() ?? "5"}
													onChange={(e) => props.setACHDebitClearingDays(toInteger(e.target.value))}
												>
													<option key="0" value="0" disabled>
														Immediate
													</option>
													<option key="1" value="1" disabled>
														1 day
													</option>
													<option key="2" value="2" disabled>
														2 days
													</option>
													<option key="3" value="3" disabled={isDefaultDebitClearingOptionDisabled(3)}>
														3 days
													</option>
													<option key="4" value="4" disabled={isDefaultDebitClearingOptionDisabled(4)}>
														4 days
													</option>
													<option key="5" value="5" disabled={isDefaultDebitClearingOptionDisabled(5)}>
														5 days
													</option>
													<option key="6" value="6" disabled={isDefaultDebitClearingOptionDisabled(6)}>
														6 days
													</option>
													<option key="7" value="7" disabled={isDefaultDebitClearingOptionDisabled(7)}>
														7 days
													</option>
													<option key="8" value="8" disabled={isDefaultDebitClearingOptionDisabled(8)}>
														8 days
													</option>
													<option key="9" value="9" disabled={isDefaultDebitClearingOptionDisabled(9)}>
														9 days
													</option>
													<option key="10" value="10" disabled={isDefaultDebitClearingOptionDisabled(10)}>
														10 days
													</option>
												</select>
											</div>
										</p>
										<div className="control is-align-items-center is-flex">
											<div className="field-label is-normal">
												<label className="label">Max Debit Clearing Period</label>
											</div>
											<div className="select mr-6">
												<select
													value={props.achDebitMaxClearingDays?.toString()}
													onChange={(e) => props.setAchDebitMaxClearingDays(toInteger(e.target.value))}
													disabled={!props.enableDynamicClearingDaysAchDebit}
												>
													<option hidden disabled selected={!props.achDebitMaxClearingDays} value={undefined}></option>
													<option key="0" value="0" disabled={isAchDebitMaxClearingOptionDisabled(0)}>
														Immediate
													</option>
													<option key="1" value="1" disabled={isAchDebitMaxClearingOptionDisabled(1)}>
														1 day
													</option>
													<option key="2" value="2" disabled={isAchDebitMaxClearingOptionDisabled(2)}>
														2 days
													</option>
													<option key="3" value="3" disabled={isAchDebitMaxClearingOptionDisabled(3)}>
														3 days
													</option>
													<option key="4" value="4" disabled={isAchDebitMaxClearingOptionDisabled(4)}>
														4 days
													</option>
													<option key="5" value="5" disabled={isAchDebitMaxClearingOptionDisabled(5)}>
														5 days
													</option>
													<option key="6" value="6" disabled={isAchDebitMaxClearingOptionDisabled(6)}>
														6 days
													</option>
													<option key="7" value="7" disabled={isAchDebitMaxClearingOptionDisabled(7)}>
														7 days
													</option>
													<option key="8" value="8" disabled={isAchDebitMaxClearingOptionDisabled(8)}>
														8 days
													</option>
													<option key="9" value="9" disabled={isAchDebitMaxClearingOptionDisabled(9)}>
														9 days
													</option>
													<option key="10" value="10" disabled={isAchDebitMaxClearingOptionDisabled(10)}>
														10 days
													</option>
												</select>
											</div>
										</div>
										<div className="control is-align-items-center is-flex">
											<div className="field-label is-normal">
												<label className="label">Min Debit Clearing Period</label>
											</div>
											<div className="select">
												<select
													value={props.achDebitMinClearingDays?.toString()}
													onChange={(e) => props.setAchDebitMinClearingDays(toInteger(e.target.value))}
													disabled={!props.enableDynamicClearingDaysAchDebit}
												>
													<option hidden disabled selected={!props.achDebitMinClearingDays} value={undefined}></option>
													<option key="0" value="0" disabled>
														Immediate
													</option>
													<option key="1" value="1" disabled>
														1 day
													</option>
													<option key="2" value="2" disabled>
														2 days
													</option>
													<option key="3" value="3" disabled={isAchDebitMinClearingOptionDisabled(3)}>
														3 days
													</option>
													<option key="4" value="4" disabled={isAchDebitMinClearingOptionDisabled(4)}>
														4 days
													</option>
													<option key="5" value="5" disabled={isAchDebitMinClearingOptionDisabled(5)}>
														5 days
													</option>
													<option key="6" value="6" disabled={isAchDebitMinClearingOptionDisabled(6)}>
														6 days
													</option>
													<option key="7" value="7" disabled={isAchDebitMinClearingOptionDisabled(7)}>
														7 days
													</option>
													<option key="8" value="8" disabled={isAchDebitMinClearingOptionDisabled(8)}>
														8 days
													</option>
													<option key="9" value="9" disabled={isAchDebitMinClearingOptionDisabled(9)}>
														9 days
													</option>
													<option key="10" value="10" disabled={isAchDebitMinClearingOptionDisabled(10)}>
														10 days
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Limits</p>
						</div>
						<div className="card-content">
							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">ACH</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily ACH Debit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyACHDebitLimit / 100}
												required
												onChange={(e) => props.setDailyAchDebitLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily ACH Credit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyACHCreditLimit / 100}
												required
												onChange={(e) => props.setDailyAchCreditLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily ACH Debit Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyACHDebitSoftLimit / 100}
												required
												onChange={(e) => props.setDailyAchDebitSoftLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly ACH Debit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyACHDebitLimit / 100}
												required
												onChange={(e) => props.setMonthlyAchDebitLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly ACH Credit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyACHCreditLimit / 100}
												required
												onChange={(e) => props.setMonthlyAchCreditLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly ACH Debit Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyACHDebitSoftLimit / 100}
												required
												onChange={(e) => props.setMonthlyAchDebitSoftLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Book Transfer</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Book Debit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyBookDebitLimit != undefined ? props.dailyBookDebitLimit / 100 : undefined}
												onChange={(e) => {
													if ((e.target.value ?? "") === "") {
														props.setDailyBookDebitLimit(null)
													} else {
														props.setDailyBookDebitLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Book Credit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyBookCreditLimit != undefined ? props.dailyBookCreditLimit / 100 : undefined}
												onChange={(e) => {
													if ((e.target.value ?? "") === "") {
														props.setDailyBookCreditLimit(null)
													} else {
														props.setDailyBookCreditLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Book Debit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyBookDebitLimit != undefined ? props.monthlyBookDebitLimit / 100 : undefined}
												onChange={(e) => {
													if ((e.target.value ?? "") === "") {
														props.setMonthlyBookDebitLimit(null)
													} else {
														props.setMonthlyBookDebitLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Book Credit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={
													props.monthlyBookCreditLimit != undefined ? props.monthlyBookCreditLimit / 100 : undefined
												}
												onChange={(e) => {
													if ((e.target.value ?? "") === "") {
														props.setMonthlyBookCreditLimit(null)
													} else {
														props.setMonthlyBookCreditLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Card</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily ATM Withdrawal Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyCardWithdrawalLimit / 100}
												required
												onChange={(e) => props.setDailyCardWithdrawalLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily ATM Deposit Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyCardDepositLimit / 100}
												required
												onChange={(e) => props.setDailyCardDepositLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Purchase Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyCardPurchaseLimit / 100}
												required
												onChange={(e) => props.setDailyCardPurchaseLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Transaction Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyCardTransactionLimit / 100}
												required
												onChange={(e) => props.setDailyCardTransactionLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Check Deposit</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyCheckDepositLimit / 100}
												required
												onChange={(e) => props.setDailyCheckDepositLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyCheckDepositSoftLimit / 100}
												required
												onChange={(e) => props.setDailyCheckDepositSoftLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyCheckDepositLimit / 100}
												required
												onChange={(e) => props.setMonthlyCheckDepositLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyCheckDepositSoftLimit / 100}
												required
												onChange={(e) => props.setMonthlyCheckDepositSoftLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Wires</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyWireLimit / 100}
												required
												onChange={(e) => props.setDailyWireLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.dailyWireSoftLimit / 100}
												required
												onChange={(e) => props.setDailyWireSoftLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyWireLimit / 100}
												required
												onChange={(e) => props.setMonthlyWireLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.monthlyWireSoftLimit / 100}
												required
												onChange={(e) => props.setMonthlyWireSoftLimit(toInteger(e.target.value) * 100)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Check Payments</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={
													props.dailyCheckPaymentLimit != undefined ? props.dailyCheckPaymentLimit / 100 : undefined
												}
												onChange={(e) => {
													if (e.target.value === "") {
														props.setDailyCheckPaymentLimit(null)
													} else {
														props.setDailyCheckPaymentLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Daily Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={
													props.dailyCheckPaymentSoftLimit != undefined
														? props.dailyCheckPaymentSoftLimit / 100
														: undefined
												}
												onChange={(e) => {
													if (e.target.value === "") {
														props.setDailyCheckPaymentSoftLimit(null)
													} else {
														props.setDailyCheckPaymentSoftLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={
													props.monthlyCheckPaymentLimit != undefined ? props.monthlyCheckPaymentLimit / 100 : undefined
												}
												onChange={(e) => {
													if (e.target.value === "") {
														props.setMonthlyCheckPaymentLimit(null)
													} else {
														props.setMonthlyCheckPaymentLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Monthly Soft Limit</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={
													props.monthlyCheckPaymentSoftLimit != undefined
														? props.monthlyCheckPaymentSoftLimit / 100
														: undefined
												}
												onChange={(e) => {
													if (e.target.value === "") {
														props.setMonthlyCheckPaymentSoftLimit(null)
													} else {
														props.setMonthlyCheckPaymentSoftLimit(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Wire</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Incoming Wire Fee</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">¢</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.incomingWireFee}
												required
												onChange={(e) => props.setIncomingWireFee(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Outgoing Wire Fee</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">¢</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.outgoingWireFee}
												required
												onChange={(e) => props.setOutgoingWireFee(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Cards</p>
							{props.editMode != EditMode.VIEW ? (
								<span className="card-header-icon" aria-label="more options">
									<span className="icon" onClick={clearCard}>
										<FontAwesomeIcon icon={faTrashAlt} />
									</span>
								</span>
							) : null}
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Individual Bin</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.individualBinId ?? ""}
											onChange={(e) => props.setIndividualBinId(e.target.value == "" ? null : e.target.value)}
										>
											<option key="default" defaultValue="" />
											{props.bins
												?.filter(
													(b) =>
														b.relationships.bank.data.id == props.bankId &&
														(b.relationships.org.data.id == props.orgId || b.relationships.org.data.id == "1")
												)
												.map((b) => (
													<option key={b.id} value={b.id.toString()}>
														{b.attributes.name}
													</option>
												))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Business BIN</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.businessBinId ?? ""}
											onChange={(e) => props.setBusinessBinId(e.target.value == "" ? null : e.target.value)}
										>
											<option key="default" defaultValue="" />
											{props.bins
												?.filter(
													(b) =>
														b.relationships.bank.data.id == props.bankId &&
														(b.relationships.org.data.id == props.orgId || b.relationships.org.data.id == "1")
												)
												.map((b) => (
													<option key={b.id} value={b.id.toString()}>
														{b.attributes.name}
													</option>
												))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Card Qualifier</label>
								</div>
								<div className="field-body">
									<div className="control">
										<input
											className="input"
											type="text"
											placeholder="The card qualifier"
											value={props.cardQualifier ?? ""}
											pattern="^\d{3}$"
											onChange={(e) => props.setCardQualifier(e.target.value ?? null)}
										/>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Limit Group</label>
								</div>
								<div className="field-body">
									<div className="select">
										<select
											value={props.limitGroup ?? ""}
											onChange={(e) => props.setLimitGroup(e.target.value ?? null)}
										>
											{<option key={1} defaultValue=""></option>}
											{Object.keys(LimitGroup).map((o) => (
												<option key={o} value={o}>
													{LimitGroup[o as LimitGroupType]}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Max Number Of Physical Cards</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.maxNumberOfPhysicalCards ?? ""}
												onChange={(e) => props.setMaxNumberOfPhysicalCards(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Max Number Of Virtual Cards</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.maxNumberOfVirtualCards ?? ""}
												onChange={(e) => props.setMaxNumberOfVirtualCards(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Card Issuing Fee</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">¢</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.cardIssuingFee ?? ""}
												onChange={(e) => props.setCardIssuingFee(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Programmatic Purchase Authorization</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.programmaticPurchaseAuthorizationEnabled}
													onChange={(e) => {
														props.setProgrammaticPurchaseAuthorizationEnabled(e.target.checked)
														props.setProgrammaticPurchaseAuthorizationIncludeCredit(false)

														if (!e.target.checked) {
															props.setProgrammaticPurchaseAuthorizationTimeoutStrategy(null)
															props.setProgrammaticPurchaseAuthorizationIncludeCredit(null)
														}
													}}
												/>{" "}
												Enable
											</label>
										</p>
										<div className="control is-align-items-center is-flex">
											<label className={classNames("mr-2", props.editMode == EditMode.VIEW && "has-text-grey")}>
												On timeout, automatically:{" "}
											</label>
											<div className="select">
												<select
													value={props.programmaticPurchaseAuthorizationTimeoutStrategy ?? ""}
													onChange={(e) =>
														props.setProgrammaticPurchaseAuthorizationTimeoutStrategy(e.target.value ?? null)
													}
													disabled={!props.programmaticPurchaseAuthorizationEnabled}
												>
													{<option key={1} defaultValue="" />}
													{Object.keys(TimeoutStrategy).map((o) => (
														<option key={o} value={o}>
															{TimeoutStrategy[o as TimeoutStrategyType]}
														</option>
													))}
												</select>
											</div>
										</div>
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.programmaticPurchaseAuthorizationIncludeCredit}
													onChange={(e) => {
														props.setProgrammaticPurchaseAuthorizationIncludeCredit(e.target.checked)
													}}
													disabled={!props.programmaticPurchaseAuthorizationEnabled}
												/>{" "}
												Include Credit Transactions
											</label>
										</p>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Programmatic Card Transaction Authorization</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.programmaticCardTransactionAuthorizationEnabled}
													onChange={(e) => {
														props.setProgrammaticCardTransactionAuthorizationEnabled(e.target.checked)
														props.setProgrammaticCardTransactionAuthorizationIncludeCredit(false)

														if (!e.target.checked) {
															props.setProgrammaticCardTransactionAuthorizationTimeoutStrategy(null)
															props.setProgrammaticCardTransactionAuthorizationIncludeCredit(null)
														}
													}}
												/>{" "}
												Enable
											</label>
										</p>
										<div className="control is-align-items-center is-flex">
											<label className={classNames("mr-2", props.editMode == EditMode.VIEW && "has-text-grey")}>
												On timeout, automatically:{" "}
											</label>
											<div className="select">
												<select
													value={props.programmaticCardTransactionAuthorizationTimeoutStrategy ?? ""}
													onChange={(e) =>
														props.setProgrammaticCardTransactionAuthorizationTimeoutStrategy(e.target.value ?? null)
													}
													disabled={!props.programmaticCardTransactionAuthorizationEnabled}
												>
													{<option key={1} defaultValue="" />}
													{Object.keys(TimeoutStrategy).map((o) => (
														<option key={o} value={o}>
															{TimeoutStrategy[o as TimeoutStrategyType]}
														</option>
													))}
												</select>
											</div>
										</div>
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.programmaticCardTransactionAuthorizationIncludeCredit}
													onChange={(e) => {
														props.setProgrammaticCardTransactionAuthorizationIncludeCredit(e.target.checked)
													}}
													disabled={!props.programmaticCardTransactionAuthorizationEnabled}
												/>{" "}
												Include Credit Transactions
											</label>
										</p>
									</div>
								</div>
							</div>

							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Programmatic ATM Authorization </label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.programmaticAtmAuthorizationEnabled}
													onChange={(e) => {
														props.setProgrammaticAtmAuthorizationEnabled(e.target.checked)

														if (!e.target.checked) props.setProgrammaticAtmAuthorizationTimeoutStrategy(null)
													}}
												/>{" "}
												Enable
											</label>
										</p>
										<div className="control is-align-items-center is-flex">
											<label className={classNames("mr-2", props.editMode == EditMode.VIEW && "has-text-grey")}>
												On timeout, automatically:{" "}
											</label>
											<div className="select">
												<select
													value={props.programmaticAtmAuthorizationTimeoutStrategy ?? ""}
													onChange={(e) => props.setProgrammaticAtmAuthorizationTimeoutStrategy(e.target.value ?? null)}
													disabled={!props.programmaticAtmAuthorizationEnabled}
												>
													{<option key={1} defaultValue="" />}
													{Object.keys(TimeoutStrategy).map((o) => (
														<option key={o} value={o}>
															{TimeoutStrategy[o as TimeoutStrategyType]}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Programmatic Advice Authorization </label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.programmaticAdviceAuthorizationEnabled}
													onChange={(e) => {
														props.setProgrammaticAdviceAuthorizationEnabled(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">International card activity fee markup (bps) </label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.orgInternationalServiceFee ?? 0}
												min="0"
												max="10000"
												onChange={(e) => props.setOrgInternationalServiceFee(toInteger(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Checks</p>
						</div>
						<div className="card-content">
							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Check Deposit</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Dynamic Clearing Period (Check Deposit)</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.enableCheckDepositDynamicClearingDays}
													onChange={(e) => {
														props.setEnableCheckDepositDynamicClearingDays(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Check Deposit Clearing Period</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<div className="select">
												<select
													value={props.checkDepositClearingDays.toString() ?? "5"}
													onChange={(e) => props.setCheckDepositClearingDays(toInteger(e.target.value))}
												>
													<option key="0" value="0" disabled>
														Immediate
													</option>
													<option key="1" value="1" disabled>
														1 day
													</option>
													<option key="2" value="2" disabled>
														2 days
													</option>
													<option key="3" value="3" disabled={isDefaultCheckDepositClearingOptionDisabled(3)}>
														3 days
													</option>
													<option key="4" value="4" disabled={isDefaultCheckDepositClearingOptionDisabled(4)}>
														4 days
													</option>
													<option key="5" value="5" disabled={isDefaultCheckDepositClearingOptionDisabled(5)}>
														5 days
													</option>
													<option key="6" value="6" disabled={isDefaultCheckDepositClearingOptionDisabled(6)}>
														6 days
													</option>
													<option key="7" value="7" disabled={isDefaultCheckDepositClearingOptionDisabled(7)}>
														7 days
													</option>
													<option key="8" value="8" disabled={isDefaultCheckDepositClearingOptionDisabled(8)}>
														8 days
													</option>
													<option key="9" value="9" disabled={isDefaultCheckDepositClearingOptionDisabled(9)}>
														9 days
													</option>
													<option key="10" value="10" disabled={isDefaultCheckDepositClearingOptionDisabled(10)}>
														10 days
													</option>
												</select>
											</div>
										</p>
										<div className="control is-align-items-center is-flex">
											<div className="field-label is-normal">
												<label className="label">Max Check Deposit Clearing Period</label>
											</div>
											<div className="select mr-6">
												<select
													value={props.checkDepositMaxClearingDays?.toString()}
													onChange={(e) => props.setCheckDepositMaxClearingDays(toInteger(e.target.value))}
													disabled={!props.enableCheckDepositDynamicClearingDays}
												>
													<option
														hidden
														disabled
														selected={!props.checkDepositMaxClearingDays}
														value={undefined}
													></option>
													<option key="0" value="0" disabled={isCheckDepositMaxClearingOptionDisabled(0)}>
														Immediate
													</option>
													<option key="1" value="1" disabled={isCheckDepositMaxClearingOptionDisabled(1)}>
														1 day
													</option>
													<option key="2" value="2" disabled={isCheckDepositMaxClearingOptionDisabled(2)}>
														2 days
													</option>
													<option key="3" value="3" disabled={isCheckDepositMaxClearingOptionDisabled(3)}>
														3 days
													</option>
													<option key="4" value="4" disabled={isCheckDepositMaxClearingOptionDisabled(4)}>
														4 days
													</option>
													<option key="5" value="5" disabled={isCheckDepositMaxClearingOptionDisabled(5)}>
														5 days
													</option>
													<option key="6" value="6" disabled={isCheckDepositMaxClearingOptionDisabled(6)}>
														6 days
													</option>
													<option key="7" value="7" disabled={isCheckDepositMaxClearingOptionDisabled(7)}>
														7 days
													</option>
													<option key="8" value="8" disabled={isCheckDepositMaxClearingOptionDisabled(8)}>
														8 days
													</option>
													<option key="9" value="9" disabled={isCheckDepositMaxClearingOptionDisabled(9)}>
														9 days
													</option>
													<option key="10" value="10" disabled={isCheckDepositMaxClearingOptionDisabled(10)}>
														10 days
													</option>
												</select>
											</div>
										</div>
										<div className="control is-align-items-center is-flex">
											<div className="field-label is-normal">
												<label className="label">Min Check Deposit Clearing Period</label>
											</div>
											<div className="select">
												<select
													value={props.checkDepositMinClearingDays?.toString()}
													onChange={(e) => props.setCheckDepositMinClearingDays(toInteger(e.target.value))}
													disabled={!props.enableCheckDepositDynamicClearingDays}
												>
													<option
														hidden
														disabled
														selected={!props.checkDepositMinClearingDays}
														value={undefined}
													></option>
													<option key="0" value="0" disabled>
														Immediate
													</option>
													<option key="1" value="1" disabled>
														1 day
													</option>
													<option key="2" value="2" disabled>
														2 days
													</option>
													<option key="3" value="3" disabled={isCheckDepositMinClearingOptionDisabled(3)}>
														3 days
													</option>
													<option key="4" value="4" disabled={isCheckDepositMinClearingOptionDisabled(4)}>
														4 days
													</option>
													<option key="5" value="5" disabled={isCheckDepositMinClearingOptionDisabled(5)}>
														5 days
													</option>
													<option key="6" value="6" disabled={isCheckDepositMinClearingOptionDisabled(6)}>
														6 days
													</option>
													<option key="7" value="7" disabled={isCheckDepositMinClearingOptionDisabled(7)}>
														7 days
													</option>
													<option key="8" value="8" disabled={isCheckDepositMinClearingOptionDisabled(8)}>
														8 days
													</option>
													<option key="9" value="9" disabled={isCheckDepositMinClearingOptionDisabled(9)}>
														9 days
													</option>
													<option key="10" value="10" disabled={isCheckDepositMinClearingOptionDisabled(10)}>
														10 days
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="is-widget-label">
								<h3 className="subtitle is-spaced">Check Payment</h3>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Check Writing</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={props.checkWritingEnabled}
													onChange={(e) => {
														if (!e.target.checked) {
															props.setCheckWritingOriginationEnabled(false)
														}
														props.setCheckWritingEnabled(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Check Writing Origination</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={props.checkWritingOriginationEnabled}
													disabled={!props.checkWritingEnabled}
													onChange={(e) => {
														props.setCheckWritingOriginationEnabled(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Additional Verification Threshold</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<p className="control">
											<a className="button is-static">$</a>
										</p>
										<div className="control">
											<input
												className="input"
												type="number"
												value={
													props.additionalVerificationThreshold != undefined
														? props.additionalVerificationThreshold / 100
														: undefined
												}
												onChange={(e) => {
													if (e.target.value === "") {
														props.setAdditionalVerificationThreshold(null)
													} else {
														props.setAdditionalVerificationThreshold(toInteger(e.target.value) * 100)
													}
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Overdraft</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Overdraft Enabled</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={props.enableOverdraft}
													onChange={(e) => {
														props.setEnableOverdraft(e.target.checked)

														if (!e.target.checked) {
															props.setOverdraftDaysLimit(null)
														}
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">
										Overdraft Days Limit
										<Icon
											className="ml-2"
											data-tip={"Accounts in overdraft for the days limit will be automatically frozen."}
											icon={"information-circle--interface-essential"}
											size={16}
										/>
										<BodyPortal>
											<ReactTooltip type="dark" effect="solid" className={"tooltip-info"} />
										</BodyPortal>
									</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												disabled={!props.enableOverdraft}
												min="0"
												value={props.overdraftDaysLimit ?? ""}
												onChange={(e) =>
													props.setOverdraftDaysLimit(e.target.value == "" ? null : toInteger(e.target.value))
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">ACH Debit Fraud Prediction</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Enabled</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.isAchDebitFraudPredictEnabled}
													onChange={(e) => {
														props.setIsAchDebitFraudPredictEnabled(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Sweep</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Enabled</label>
								</div>
								<div className="field-body">
									<div className="field is-align-items-center is-horizontal">
										<p className="control mr-6">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={!!props.isSweepEnabled}
													onChange={(e) => {
														props.setIsSweepEnabled(e.target.checked)
													}}
												/>{" "}
												Enable
											</label>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
