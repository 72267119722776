import React from "react"
import {Box} from "../../containers/Box/Box"
import Skeleton from "react-loading-skeleton"
export function PendingBox() {
	return (
		<Box className="end-customer-deposits-box-pending-container">
			<Skeleton width={96} height={20} />
			<Skeleton width={52} height={24} />
		</Box>
	)
}
