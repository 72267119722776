import {useAccessToken} from "../../services/auth"
import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Result} from "neverthrow"
import {ErrorDocument} from "../../resources/common"
import {BankSettings} from "../../resources/bankSettings"
import {OrgSettings} from "../../resources/orgSettings"

type CloseFunc = () => void
type RefreshFunc = () => void

interface DeleteDomainSettingsModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	domainName: string
	deleteDomain: (accessToken: string) => Promise<Result<BankSettings | OrgSettings, ErrorDocument>>
}

export default function DeleteDomainSettingsModal({
	close,
	refresh,
	domainName,
	deleteDomain,
}: DeleteDomainSettingsModalProps) {
	const accessToken = useAccessToken()
	const [state, update] = useAsyncResultIdle(deleteDomain)

	return (
		<AsyncResultModal
			title="Delete Domain"
			onSubmit={() => update(accessToken)}
			close={close}
			state={state}
			buttonClassname="is-danger"
			buttonText="Delete"
			successText="Deleted"
			errorToText={(err) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
			onSuccess={refresh}
		>
			<div className="field">
				<label className="label">Domain Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="A sub domain you own"
						value={domainName}
						required
						disabled
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
