import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons"
import React, {Dispatch, SetStateAction} from "react"
import {useAccessToken} from "../../services/auth"
import {CertificateStatus} from "../../resources/bankSettings"

type KeyValue = {
	key: string
	value: string
}

interface DnsRecordsProps {
	status: CertificateStatus
	dnsRecords: Array<KeyValue>
	isLoading: boolean
	setIsLoading: Dispatch<SetStateAction<boolean>>
	verifyDomain: (accessToken: string) => void
	errorMessage?: string
}

export default function DnsRecordsView({
	status,
	dnsRecords,
	isLoading,
	setIsLoading,
	verifyDomain,
	errorMessage,
}: DnsRecordsProps) {
	const accessToken = useAccessToken()

	return (
		<div className="field">
			<div className="box">
				<span className="has-text-weight-bold">
					DNS Settings -{" "}
					<span className={status == CertificateStatus.Verified ? "has-text-success" : "has-text-warning"}>
						{status}
					</span>
				</span>
				<p className="mt-2 mb-2">
					Please config these records in your DNS provider within 72 hours to verify the domain ownership.
				</p>
				<table className="table is-fullwidth">
					<thead>
						<tr>
							<td>Record Name</td>
							<td>Type</td>
							<td>Record Name</td>
						</tr>
					</thead>
					<tbody>
						{dnsRecords.map((d) => (
							<tr key={d.key}>
								<td>{d.key}</td>
								<td>CNAME</td>
								<td>{d.value}</td>
							</tr>
						))}
					</tbody>
				</table>
				{status == CertificateStatus.Unverified ? (
					<button
						className="button is-success"
						disabled={isLoading}
						onClick={(e) => {
							e.preventDefault()
							setIsLoading(true)
							verifyDomain(accessToken)
						}}
					>
						{isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Verify"}
					</button>
				) : null}
				{!!errorMessage ? <p className="has-text-danger mt-2"> {errorMessage} </p> : null}
			</div>
		</div>
	)
}
