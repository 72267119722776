import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {deleteLinkedAccount, LinkedAccount} from "../../resources/linkedAccount"
import {NavigateFunction} from "react-router-dom"

interface DeleteLinkedAccountModalProps {
	linkedAccount: LinkedAccount
	close: () => void
	onSuccess: () => void
	back: NavigateFunction
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function DeleteLinkedAccountModal({linkedAccount, close, onSuccess, back}: DeleteLinkedAccountModalProps) {
	const accessToken = useAccessToken()
	const [state, deleteLinkedAccountInternal] = useAsyncResultIdle(deleteLinkedAccount)
	const sleep = new Promise((res) => setTimeout(res, 1000))

	return (
		<AsyncResultModal
			title="Delete Linked Account"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Delete"
			successText="Deleted"
			onSubmit={async () => {
				const res = await deleteLinkedAccountInternal(accessToken, linkedAccount)
				if (res.isOk()) {
					await sleep
					back(-1)
				}
			}}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure you want to delete this linked account?
		</AsyncResultModal>
	)
}
