import React from "react"
import classNames from "classnames"
import numberal from "numeral"

export type UnitsPosition = "Prefix" | "Suffix"
const formatNumberWithLettersSuffix = (num: number, roundLowValues = false) => {
	const map = [
		{suffix: "t", threshold: 1e12, precision: 1},
		{suffix: "b", threshold: 1e9, precision: 1},
		{suffix: "m", threshold: 1e6, precision: 1},
		{suffix: "k", threshold: 1e3, precision: 1},
		{suffix: "", threshold: 1, precision: roundLowValues ? 0 : 1},
	]
	const found = map.find((x) => Math.abs(num) >= x.threshold)
	if (found) {
		return (num / found.threshold).toFixed(found.precision) + found.suffix
	}
	return num.toString()
}

const formatNumberWithCommas = (number: number) => {
	return numberal(number).format("0,0.00")
}

const formatByMethod = (formatMethod: FormatMethod, number: number, roundLowValues = false) => {
	switch (formatMethod) {
		case "letter_suffix":
			return formatNumberWithLettersSuffix(number, roundLowValues)
		case "with_commas":
			return formatNumberWithCommas(number)
		case "none":
		default:
			return number.toString()
	}
}
export type FormatMethod = "letter_suffix" | "with_commas" | "none"
export type ValueAndUnitsSize = "small" | "medium"
export type ValueAndUnitsTheme = "dark" | "light"

export function ValueAndUnits({
	value,
	unitsPosition,
	units,
	className,
	formatMethod = "none",
	roundLowValues,
	size = "medium",
	theme = "dark",
}: {
	value: number
	unitsPosition?: UnitsPosition
	units?: string
	className?: string
	formatMethod?: FormatMethod
	roundLowValues?: boolean
	size?: ValueAndUnitsSize
	theme?: ValueAndUnitsTheme
}) {
	const contentClasses = classNames(size === "small" && "small", theme === "light" && "light")
	return (
		<div className={classNames("value-and-units-container", className)}>
			{!!units && unitsPosition === "Prefix" && <span className={classNames("units", contentClasses)}>{units}</span>}
			<span className={classNames("value", contentClasses)}>{formatByMethod(formatMethod, value, roundLowValues)}</span>
			{!!units && unitsPosition === "Suffix" && <span className={classNames("units", contentClasses)}>{units}</span>}
		</div>
	)
}
