import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken, useUserType} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {ApiTokenPresent, createApiToken} from "../../resources/apiToken"
import moment from "moment"
import {useCopyToClipboard} from "react-use"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Err} from "../Err/Err"
import {getUser, User} from "../../resources/user"
import {ErrorDocument, OkDocument} from "../../resources/common"
import LeftRightSlots from "../../containers/LeftRightSlots/LeftRightSlots"
import Icon from "../Icon/Icon"
import ReactTooltip from "react-tooltip"
import BodyPortal from "../BodyPortal/BodyPortal"
import classNames from "classnames"

type CloseFunc = () => void
type RefreshFunc = () => void

type ScopeModel = {
	checkedRead: boolean
	checkedWrite: boolean
	read: string
	write: string
}

function getExpiration(expiresIn: string) {
	return moment().add(moment.duration(expiresIn)).toDate()
}

function Success({apiToken}: {apiToken: ApiTokenPresent}) {
	const [, copyToClipboard] = useCopyToClipboard()
	const [isVisible, setIsVisible] = useState(false)

	const copyToClipboardHandler = () => {
		setIsVisible(false)
		copyToClipboard(apiToken.attributes.token)
		setTimeout(() => {
			setIsVisible(true)
		}, 10)
	}

	return (
		<>
			<section className="modal-card-body">
				<div className="field">
					<label className="label">
						Copy your new token and save it somewhere safe. Created tokens cannot be retrieved a second time.
					</label>
					<div className="textarea">{apiToken.attributes.token}</div>
				</div>
			</section>
			<footer className="modal-card-foot">
				<div className="copy-to-clipboard-container">
					<div className={classNames("copied-label-container", isVisible && "show-and-fade-out")}>
						<Icon icon={"interface-validation-check--interface-essential"} size={12} />
						<span className="copied-label">Copied!</span>
					</div>
					<button type="button" className="button is-info" onClick={copyToClipboardHandler}>
						<Icon icon="paginate-filter-document-copy" size={16} />
						<span className="text">Copy</span>
					</button>
				</div>
			</footer>
		</>
	)
}

export default function NewApiTokenModal({
	close,
	refresh,
	userIdentifier,
}: {
	close: CloseFunc
	refresh: RefreshFunc
	userIdentifier: string
}) {
	const accessToken = useAccessToken()
	const userId = userIdentifier
	const userType = useUserType()
	const isPartnerUser = userType == "partner"
	const [state, create] = useAsyncResultIdle(createApiToken)
	const [description, setDescription] = useState("")
	const [expiresIn, setExpiresIn] = useState("P1Y")
	const [sourceIp, setSourceIp] = useState("")
	const [presetScopes, setPresetScopes] = useState<Map<string, ScopeModel>>(
		new Map(
			isPartnerUser
				? []
				: [
						["Application", {checkedRead: true, checkedWrite: true, read: "applications", write: "applications-write"}],
						["Customers", {checkedRead: true, checkedWrite: false, read: "customers", write: "customers-write"}],
						["Customer Tags", {checkedRead: false, checkedWrite: true, read: "", write: "customer-tags-write"}],
						["Customer Token", {checkedRead: false, checkedWrite: true, read: "", write: "customer-token-write"}],
						["Accounts", {checkedRead: true, checkedWrite: true, read: "accounts", write: "accounts-write"}],
						["Cards", {checkedRead: true, checkedWrite: false, read: "cards", write: "cards-write"}],
						[
							"Cards Sensitive",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "cards-sensitive",
								write: "cards-sensitive-write",
							},
						],
						[
							"Transactions",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "transactions",
								write: "transactions-write",
							},
						],
						["Authorizations", {checkedRead: true, checkedWrite: false, read: "authorizations", write: ""}],
						["Statements", {checkedRead: true, checkedWrite: false, read: "statements", write: ""}],
						["Payments", {checkedRead: true, checkedWrite: false, read: "payments", write: "payments-write"}],
						[
							"Payments Counterparties",
							{
								checkedRead: false,
								checkedWrite: true,
								read: "",
								write: "payments-write-counterparty",
							},
						],
						[
							"Payments Linked Accounts",
							{
								checkedRead: false,
								checkedWrite: true,
								read: "",
								write: "payments-write-linked-account",
							},
						],
						["Payments ACH", {checkedRead: false, checkedWrite: true, read: "", write: "ach-payments-write"}],
						["Payments Wire", {checkedRead: false, checkedWrite: true, read: "", write: "wire-payments-write"}],
						["Repayments", {checkedRead: true, checkedWrite: false, read: "repayments", write: "repayments-write"}],
						[
							"Payments ACH Debit",
							{
								checkedRead: false,
								checkedWrite: true,
								read: "",
								write: "payments-write-ach-debit",
							},
						],
						[
							"Counterparties",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "counterparties",
								write: "counterparties-write",
							},
						],
						[
							"Batch Releases",
							{
								checkedRead: true,
								checkedWrite: true,
								read: "batch-releases",
								write: "batch-releases-write",
							},
						],
						[
							"Linked Accounts",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "linked-accounts",
								write: "linked-accounts-write",
							},
						],
						["Webhooks", {checkedRead: true, checkedWrite: true, read: "webhooks", write: "webhooks-write"}],
						["Events", {checkedRead: true, checkedWrite: true, read: "events", write: "events-write"}],
						[
							"Authorization Requests",
							{
								checkedRead: true,
								checkedWrite: true,
								read: "authorization-requests",
								write: "authorization-requests-write",
							},
						],
						[
							"Cash Deposits",
							{
								checkedRead: true,
								checkedWrite: true,
								read: "cash-deposits",
								write: "cash-deposits-write",
							},
						],
						[
							"Check Deposits",
							{
								checkedRead: true,
								checkedWrite: true,
								read: "check-deposits",
								write: "check-deposits-write",
							},
						],
						[
							"Received Payments",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "received-payments",
								write: "received-payments-write",
							},
						],
						["Disputes", {checkedRead: true, checkedWrite: false, read: "disputes", write: ""}],
						["Chargebacks", {checkedRead: true, checkedWrite: false, read: "chargebacks", write: "chargebacks-write"}],
						["Rewards", {checkedRead: true, checkedWrite: false, read: "rewards", write: "rewards-write"}],
						[
							"Check Payments",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "check-payments",
								write: "check-payments-write",
							},
						],
						[
							"Credit Decisions",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "credit-decisions",
								write: "credit-decisions-write",
							},
						],
						[
							"Lending Program",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "lending-programs",
								write: "lending-programs-write",
							},
						],
						[
							"Card Fraud Case",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "card-fraud-cases",
								write: "card-fraud-cases-write",
							},
						],
						[
							"Credit Application",
							{
								checkedRead: true,
								checkedWrite: false,
								read: "credit-applications",
								write: "credit-applications-write",
							},
						],
						[
							"Migrations",
							{
								checkedRead: false,
								checkedWrite: false,
								read: "migrations",
								write: "migrations-write",
							},
						],
				  ]
		)
	)

	const expiration = ["P1D", "P90D", "P180D", "P270D", "P1Y"]

	function getSelectedStates() {
		const selectedScopes: Array<string> = []
		presetScopes.forEach((v, _) => {
			if (v.checkedRead) selectedScopes.push(v.read)
			if (v.checkedWrite) selectedScopes.push(v.write)
		})

		return selectedScopes
	}

	function setAll(state: boolean) {
		presetScopes.forEach((v, k) => {
			const newModel = Object.assign({}, v)
			if (v.read !== "" && v.checkedRead !== state) {
				newModel.checkedRead = state
			}

			if (v.write !== "" && v.checkedWrite !== state) {
				newModel.checkedWrite = state
			}

			presetScopes.set(k, Object.assign({}, v, newModel))
			setPresetScopes(new Map(presetScopes))
		})
	}

	function buildScopesTable() {
		const scopesArray: Array<JSX.Element> = []
		presetScopes.forEach((v, k) => {
			scopesArray.push(
				<tr key={k}>
					<td>{k}</td>
					<td>
						{v.read !== "" ? (
							<input
								type="checkbox"
								checked={v.checkedRead}
								disabled={v.read === ""}
								onChange={(_) => {
									presetScopes.set(k, Object.assign({}, v, {checkedRead: !v.checkedRead}))
									setPresetScopes(new Map(presetScopes))
								}}
							/>
						) : (
							"—"
						)}
					</td>
					<td>
						{v.write !== "" ? (
							<input
								type="checkbox"
								checked={v.checkedWrite}
								onChange={(_) => {
									presetScopes.set(k, Object.assign({}, v, {checkedWrite: !v.checkedWrite}))
									setPresetScopes(new Map(presetScopes))
								}}
							/>
						) : (
							"—"
						)}
					</td>
				</tr>
			)
		})

		return scopesArray
	}

	function IsOrgUser(userId: string, onResult: () => JSX.Element) {
		const accessToken = useAccessToken()
		const result = useAsyncResult<OkDocument<User>, ErrorDocument>(() => getUser(accessToken, userId), [userId])
		return result.match(
			() => null,
			(user) => (user.data.type === "orgUser" ? onResult() : null),
			(err) => <Err err={err} />
		)
	}

	const tokenDetails = (
		<>
			<div className="field">
				<label className="label">
					Description<sup>*</sup>
				</label>
				<div className="control">
					<input
						required
						className="input is-medium input-box"
						type="text"
						value={description}
						placeholder="Describe the purpose of the token"
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Expiration</label>
				<div className="control">
					<div>
						<select required value={expiresIn} onChange={(e) => setExpiresIn(e.target.value)}>
							{expiration.map((exp) => (
								<option key={exp} value={exp}>
									{moment.duration(exp).humanize(true)}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			{IsOrgUser(userId, () => (
				<div className="field">
					<label className="label">
						{"Source IPs "}
						<span className={"source-ips-optional"}>
							{" "}
							(Optional)
							<Icon
								data-tip={
									"Optional. Creating a whitelist will restrict the originating IP addresses that have API access using your token."
								}
								icon={"information-circle--interface-essential"}
								size={16}
							/>
							<BodyPortal>
								<ReactTooltip
									type="light"
									arrowColor="#FFFFFF"
									effect="solid"
									className={"source-ips-tooltip"}
									place={"top"}
								/>
							</BodyPortal>
						</span>
					</label>
					<div className="control">
						<div className="control">
							<input
								className="input is-medium input-box"
								type="text"
								pattern="^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?))*$"
								value={sourceIp}
								placeholder="List source IPs separated by comma"
								onChange={(e) => setSourceIp(e.target.value)}
							/>
						</div>
					</div>
				</div>
			))}
		</>
	)

	const tokenScopes = (
		<>
			<div className="field">
				<div className={"scopes-table"}>
					<span> Scopes </span>
					<table className="table scopes">
						<thead>
							<tr>
								<th>
									<label>
										<input
											type="checkbox"
											onChange={(e) => {
												const checked = e.target.checked
												if (checked) setAll(true)
												else setAll(false)
											}}
										/>
										Select All
									</label>
								</th>
								<th>Read</th>
								<th>Write</th>
							</tr>
						</thead>
						<tbody>{buildScopesTable()}</tbody>
					</table>
				</div>
			</div>
		</>
	)

	return (
		<AsyncResultModal
			title="Create new API token"
			onSubmit={() =>
				create(accessToken, userId, description, getSelectedStates().join(" "), getExpiration(expiresIn), sourceIp)
			}
			close={close}
			disableCloseOnSuccess={true}
			state={state}
			buttonClassname="is-black"
			buttonText="Create"
			successText="Created"
			successComponent={(token: ApiTokenPresent) => <Success apiToken={token} />}
			errorToText={(err) => err.errors[0].title}
			onSuccess={refresh}
			classname={"create-api-token-modal"}
		>
			<p className={"api-tokens-description"}>
				{isPartnerUser
					? "Use your Partner API Token to create onBehalf token"
					: "Use your Org API Tokens to authenticate incoming requests"}
			</p>

			{isPartnerUser ? tokenDetails : <LeftRightSlots left={tokenDetails} right={tokenScopes} />}
		</AsyncResultModal>
	)
}
