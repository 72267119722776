import React, {ReactElement, useEffect, useRef} from "react"
import {IncomingAch, IncomingAchStatus, findIncomingAch} from "../../resources/incomingAch"
import {ErrorDocument, Meta, Resource, RiskRate} from "../../resources/common"
import {useNavigate} from "react-router-dom"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"
import classNames from "classnames"
import {OrgName} from "../../components/OrgName/OrgName"
import {kebabCase, startCase} from "lodash"
import moment from "moment"
import {useAccessToken, useUserType} from "../../services/auth"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {useQueryState} from "use-location-state"
import {Cents} from "../../resources/transaction"
import {usePaging} from "../../hooks/usePaging"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Org, findOrgs, getOrgName} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {Filter} from "../../components/Filter/Filter"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../../components/DatePicker/DatePicker"
import {CurrencyRangeFilter} from "../../components/Filter/CurrencyRangeFilter"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import SearchBox from "../../components/SearchBox/SearchBox"
import numeral from "numeral"

export enum IncomingAchColumns {
	id = "Id",
	account = "Account",
	customer = "Customer",
	org = "Org",
	amount = "Amount",
	status = "Status",
	createdAt = "Created At",
}

type AllowedIncomingAchColumns =
	| IncomingAchColumns.id
	| IncomingAchColumns.account
	| IncomingAchColumns.customer
	| IncomingAchColumns.org
	| IncomingAchColumns.amount
	| IncomingAchColumns.status
	| IncomingAchColumns.createdAt

interface IncomingAchsTableProps {
	incomingAchs: IncomingAch[]
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedIncomingAchColumns[]
	meta?: Meta
	fullHeight?: boolean
	sortFunction: (columnName: string) => void
	sortBy: string
}

interface IncomingAchRowProps {
	incomingAch: IncomingAch
	include?: Resource[]
	showOrgColumn?: boolean
	includedColumns: AllowedIncomingAchColumns[]
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

interface IncomingAchsProps {
	accountId?: string
	token?: string
	limit?: number
	includedColumns: AllowedIncomingAchColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	enableOrgFilter?: boolean
	enableStatusFilter?: boolean
	enableAmountFilter?: boolean
	enableDateFilter?: boolean
	enablePaging?: boolean
	enableExport?: boolean
	title?: string
}

function IncomingAchRow({incomingAch, include, includedColumns, onClick}: IncomingAchRowProps) {
	const navigate = useNavigate()
	const id = incomingAch.id
	const accountId = incomingAch.relationships.account?.data.id
	const customerId = incomingAch.relationships.customer?.data.id
	const org = incomingAch.relationships.org?.data.id
	const amount = incomingAch.attributes.item.amount
	const status = incomingAch.attributes.status
	const createdAt = incomingAch.attributes.createdAt

	const customer =
		customerId && include
			? include.find(
					(r) =>
						(r.type == "individualCustomer" || r.type == "businessCustomer" || r.type == "businessFBOCustomer") &&
						r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer" || customer.type == "businessFBOCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedIncomingAchColumns, ReactElement>> = {}

	if (includedColumns.includes(IncomingAchColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={"data-table-id-cell"}>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingAchColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingAchColumns.account)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingAchColumns.org)) {
		contentColumns["Org"] = org ? (
			<OrgName orgId={org.toString()} included={include} />
		) : (
			<DataTableCell>N/A</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingAchColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<span className="payments-amount">{numeral(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingAchColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div
					data-tip="React-tooltip"
					data-for={`status-reason-tooltip-${id}`}
					className={classNames("incomingach-status", kebabCase(status))}
				>
					{startCase(status)}
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingAchColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow onClick={(e) => onClick && onClick(e)}>{content}</DataTableRow>
}

export function IncomingAchsTable({
	incomingAchs,
	fullHeight,
	includedColumns,
	hasNext,
	hasPrev,
	hasResults,
	isUsingPaging,
	include,
	meta,
	prev,
	next,
	sortFunction,
	sortBy,
}: IncomingAchsTableProps) {
	const navigate = useNavigate()
	const noContent = incomingAchs.length === 0
	const ref = useRef(null)

	return (
		<div ref={ref} className="payments-table">
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={"No incomingAchs found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === IncomingAchColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("createdAt")}
										key={column[1]}
										sortable={sortBy.includes("createdAt")}
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							if (column[1] === IncomingAchColumns.amount) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("amount")}
										key={column[1]}
										sortable={sortBy.includes("amount")}
										sortApplied={sortBy === "amount"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{incomingAchs.map((incomingAch) => (
						<IncomingAchRow
							incomingAch={incomingAch}
							key={incomingAch.id}
							include={include}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								navigate(`/incoming-achs/${incomingAch.id}`, {state: {previousPage: location.pathname + location.hash}})
							}}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export default function IncomingAchs({
	limit = 25,
	token,
	includedColumns,
	fullHeight,
	enableTitle,
	enableOrgFilter = false,
	enableStatusFilter = false,
	enableAmountFilter = false,
	enableDateFilter = false,
	enablePaging = true,
	title = "Incoming Achs",
}: IncomingAchsProps) {
	const prefix = "incachs"
	const accessToken = token ?? useAccessToken()
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [statuses, setStatuses] = useQueryState("filter[status]", [IncomingAchStatus.PendingReview])
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")

	const onSearch = (searchTerm: string) => {
		setQuery(searchTerm)
		reset(limit)
	}
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search by Account/Customer/Amount", 500, onSearch)
	const statusesOptions = new Map(
		[
			IncomingAchStatus.PendingReview,
			IncomingAchStatus.Processed,
			IncomingAchStatus.MarkedForReturn,
			IncomingAchStatus.Warehoused,
			IncomingAchStatus.Returned,
			IncomingAchStatus.Errored,
		].map((v) => [v, startCase(v)])
	)
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findIncomingAch(
				query,
				accessToken,
				offset,
				limit,
				sortBy,
				statuses,
				filteredOrgs,
				since,
				until,
				fromAmount,
				toAmount
			),
		(x) => x.data.length,
		[since, until, [...statuses, ...filteredOrgs].join(","), fromAmount, toAmount, query, sortBy],
		`${prefix}-`
	)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])
	const userType = useUserType()

	const allOrgsData =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const noOrgData: Org = {
		id: "-1",
		type: "org",
		attributes: {
			createdAt: new Date(Date.now()),
			name: "N/A",
			defaultCustomerRiskRate: RiskRate.Low,
			orgInterchangeShare: 0,
			enableTwoFactorAuthentication: false,
		},
	}

	const orgFilter = allOrgsData.match(
		() => null,
		(orgs) => {
			orgs.push(noOrgData)
			return (
				<Filter
					title="Orgs"
					isSearchable
					setStatuses={setFilteredOrgs}
					statuses={filteredOrgs}
					onFilterFunc={() => reset(limit)}
					options={
						new Map<string, string>(
							orgs
								.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
								.map((org) => [org.id, getOrgName(org)])
						)
					}
				/>
			)
		},
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={setFromAmount}
			to={toAmount}
			setTo={setToAmount}
		/>
	)

	function toggleSort(columnName: string) {
		const sortParam = sortBy == `-${columnName}` ? columnName : `-${columnName}`
		setSortBy(sortParam)
		reset(limit)
	}

	return (
		<DataTableCard className={"payments-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				dateTimePicker={enableDateFilter ? dateTime : null}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableStatusFilter ? [statusFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
				]}
				searchBox={searchBox}
				title={enableTitle ? title : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: incomingAchs}) => {
					return (
						<IncomingAchsTable
							incomingAchs={incomingAchs.data}
							meta={incomingAchs.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={enablePaging}
							include={incomingAchs.included}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
