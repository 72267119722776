import React from "react"
import AccountBox from "./AccountBox"
import Card from "../../containers/Card/Card"

export default function OrgAccountsPlaceholder() {
	return (
		<Card title={"Org Accounts"} icon={"money-note-1--business-products"} className="org-accounts-summary-container">
			<div className="org-accounts-summary">
				<AccountBox
					title="Revenue"
					amount={0}
					className="revenue-account"
					icon={"analytic-currency--business-products"}
				/>
				<AccountBox title="Reserve" amount={0} className="reserve-account" icon={"payment-box-reserve"} />
				<AccountBox title="Batch" amount={0} className="batch-account" icon={"hierarchy-square"} />
			</div>
		</Card>
	)
}
