import React from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {getPaymentPlaidData, isAchPayment, Payment} from "../../resources/payment"
import {useAccessToken} from "../../services/auth"
import Icon from "../Icon/Icon"
import Skeleton from "react-loading-skeleton"
import ReactJson from "react-json-view"
import Loading from "../Loading/Loading"
import {getLinkedAccountPlaidData, LinkedAccount} from "../../resources/linkedAccount"

function PlaidNoData({button, error, skeleton}: {button?: JSX.Element; error?: string; skeleton?: boolean}) {
	return (
		<div className={"plaid-data-empty"}>
			{skeleton ? (
				<div className={"skeleton-placeholder"}>
					<Skeleton />
				</div>
			) : (
				<>
					<div className={"plaid-data-empty-placeholder"}>
						<Icon icon={"Plaid_logo"} />
						<p> No Plaid data yet </p>
						{button}
					</div>
					<span className="error-message">{error}</span>
				</>
			)}
		</div>
	)
}

export default function PlaidData({payment}: {payment: Payment}) {
	const accessToken = useAccessToken()
	const [plaidData, getPlaidData] = useAsyncResultIdle(getPaymentPlaidData)
	const isLoading = plaidData.match(
		() => false,
		() => true,
		() => false,
		() => false
	)
	const getPlaidButton = (
		<button
			className="button button-get-plaid-data"
			disabled={isLoading}
			onClick={() => {
				getPlaidData(accessToken, payment.id)
			}}
		>
			{" "}
			Get Plaid Data{" "}
		</button>
	)

	if (!isAchPayment(payment)) {
		return null
	}

	return (
		<div className={"plaid-data"}>
			{plaidData.match(
				() => (
					<PlaidNoData button={getPlaidButton} />
				),
				() => (
					<Loading />
				),
				(p) => (
					<>
						<ReactJson
							style={{backgroundColor: "#fff"}}
							name="Balance"
							src={p.data.attributes.accountBalances}
							displayDataTypes={false}
						/>
						<ReactJson
							style={{backgroundColor: "#fff"}}
							name="Identity"
							src={p.data.attributes.identity}
							displayDataTypes={false}
						/>
					</>
				),
				(e) => (
					<PlaidNoData button={getPlaidButton} error={`${e.errors[0].title} - ${e.errors[0].detail}`} />
				)
			)}
		</div>
	)
}

export function PlaidDataForLinkedAccount({linkedAccount}: {linkedAccount: LinkedAccount}) {
	const accessToken = useAccessToken()
	const [plaidData, getPlaidData] = useAsyncResultIdle(getLinkedAccountPlaidData)
	const isLoading = plaidData.match(
		() => false,
		() => true,
		() => false,
		() => false
	)
	const getPlaidButton = (
		<button
			className="button button-get-plaid-data"
			disabled={isLoading}
			onClick={() => {
				getPlaidData(accessToken, linkedAccount.id)
			}}
		>
			{" "}
			Get Plaid Data{" "}
		</button>
	)

	return (
		<div className={"plaid-data"}>
			{plaidData.match(
				() => (
					<PlaidNoData button={getPlaidButton} />
				),
				() => (
					<Loading />
				),
				(p) => (
					<>
						<ReactJson
							style={{backgroundColor: "#fff"}}
							name="Identity"
							src={p.data.attributes.identity}
							displayDataTypes={false}
						/>
					</>
				),
				(e) => (
					<PlaidNoData button={getPlaidButton} error={`${e.errors[0].title} - ${e.errors[0].detail}`} />
				)
			)}
		</div>
	)
}
