import React, {PropsWithChildren} from "react"

export function MainSection({children}: PropsWithChildren<{}>) {
	return <section className="section is-main-section is-mobile">{children}</section>
}

export function MainSectionTable({children}: PropsWithChildren<{}>) {
	return <section className="section is-main-section is-table-container-section">{children}</section>
}

export function MainSectionItem({children}: PropsWithChildren<{}>) {
	return <section className="section is-main-section is-item-container-section is-mobile">{children}</section>
}

export function MainSectionItemFullWidth({children}: PropsWithChildren<{}>) {
	return (
		<section className="section is-main-section is-item-container-section-full-width is-mobile">{children}</section>
	)
}
