import React, {CSSProperties, Dispatch, useEffect, useState} from "react"
import Icon from "../Icon/Icon"
import {ActionButton, ActionItem} from "../ActionButton/ActionButton"
import {useToolbar} from "../../hooks/useToolbar"
import {ApplicationDocumentSide} from "../../resources/application"
import Skeleton from "react-loading-skeleton"
import classNames from "classnames"

type ImageProps = {
	src: string
	alt: string
}

enum ImageOrientation {
	Landscape = "Landscape",
	Portrait = "Portrait",
	Square = "Square",
}

const getImageClassNameByOrientation = (orientation: ImageOrientation | undefined) => {
	if (!orientation) {
		return ""
	}
	return orientation === ImageOrientation.Portrait || orientation === ImageOrientation.Square
		? "image-card-preview-portrait-orientation"
		: "image-card-preview-landscape-orientation"
}

const getCtaButtonClassNameByOrientation = (orientation: ImageOrientation | undefined, isRotated: boolean) => {
	if (!isRotated || orientation === ImageOrientation.Square) {
		return ""
	}
	return orientation === ImageOrientation.Landscape
		? "image-card-floating-cta-landscape-orientation"
		: "image-card-floating-cta-portrait-orientation"
}

const loadImage = (
	image: ImageProps,
	setImageElement: Dispatch<React.SetStateAction<HTMLImageElement | undefined>>,
	setImageOrientation: Dispatch<React.SetStateAction<ImageOrientation | undefined>>
) => {
	const {src, alt} = image
	const img = new Image()
	img.onload = () => {
		const naturalImageOrientation =
			img.naturalWidth === img.naturalHeight
				? ImageOrientation.Square
				: img.naturalWidth > img.naturalHeight
				? ImageOrientation.Landscape
				: ImageOrientation.Portrait
		img.classList.add(getImageClassNameByOrientation(naturalImageOrientation), "image-card-preview")
		const div = document.getElementById("image-card-preview-inner-container")
		div?.appendChild(img)
		setImageOrientation(naturalImageOrientation)
		setImageElement(img)
	}
	img.alt = alt
	img.src = src
}
export function ImageCard({
	title,
	image,
	prefixIcon,
}: {
	title?: string
	image: ImageProps
	prefixIcon?: React.ReactNode
}) {
	const [imageElement, setImageElement] = useState<HTMLImageElement>()
	const [overlayStyle, setOverlayStyle] = useState<CSSProperties>()
	const [imageOrientation, setImageOrientation] = useState<ImageOrientation>()

	useEffect(() => {
		loadImage(image, setImageElement, setImageOrientation)
	}, [loadImage])

	useEffect(() => {
		if (!imageElement) {
			return
		}
		setOverlayStyle({width: imageElement?.offsetWidth, height: imageElement?.offsetHeight})
	}, [imageElement])
	const onExpandImage = () => {
		if (!image.src) {
			return
		}
		window.open(image.src, "_blank")
	}

	const {rotation} = useToolbar(ApplicationDocumentSide.Additional)
	const isRotated = rotation.val === 90 || rotation.val === 270
	const imageClassNameByOrientation = getImageClassNameByOrientation(imageOrientation)
	const ctaStyleByOrientation = getCtaButtonClassNameByOrientation(imageOrientation, isRotated)

	const inlineImgStyle = {
		"--img-rotation": rotation.val + "deg",
	} as CSSProperties

	return (
		<section className={"image-card"}>
			{title && (
				<div className="image-card-header">
					<div className={"image-card-title"}>
						{prefixIcon && <div className={"image-card-prefix-icon"}>{prefixIcon}</div>}
						<h3>{title}</h3>
					</div>
					<div className={"image-card-actions"}>
						{imageElement && (
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Rotate"}
									icon={"design-tool-rotate-clockwise"}
									onClick={rotation.changeRotation}
									stopPropagationOnClick={false}
								/>
								<ActionItem
									title={"Expand"}
									icon={"expand-diagonal-1--interface-essential"}
									onClick={onExpandImage}
									stopPropagationOnClick={false}
								/>
							</ActionButton>
						)}
					</div>
				</div>
			)}
			<div className={"image-card-preview-container"}>
				{imageElement ? (
					<div className={"image-card-preview-overlay"}>
						<div
							className={classNames("image-card-preview-mask", imageClassNameByOrientation)}
							style={{
								...inlineImgStyle,
								...overlayStyle,
							}}
						></div>
						<div
							className={classNames("image-card-floating-cta-container", imageClassNameByOrientation)}
							style={{
								...inlineImgStyle,
								...overlayStyle,
							}}
						>
							<button className={classNames("image-card-floating-cta", ctaStyleByOrientation)} onClick={onExpandImage}>
								<Icon
									icon={"expand-diagonal-4--interface-essential"}
									color={"#FFFFFF"}
									size={16}
									style={{transform: "scaleX(-1)"}}
								/>
							</button>
						</div>
					</div>
				) : (
					<Skeleton
						height={305}
						width={244}
						className={"image-card-preview-portrait-orientation image-card-preview-basic"}
					/>
				)}
				<div id={"image-card-preview-inner-container"} style={inlineImgStyle}></div>
			</div>
		</section>
	)
}
