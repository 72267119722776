import React from "react"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getAccount, Account} from "../../resources/account"
import {isAchPayment, Payment, PaymentDirection, PaymentStatus} from "../../resources/payment"
import {useAccessToken} from "../../services/auth"
import {toDollars} from "../../utilities/numbers"
import {ConfirmationModal} from "../ConfirmationModal/ConfirmationModal"

function getAccountByPayment(payment: Payment) {
	const accessToken = useAccessToken()
	const accountAsync = useAsyncResult(() =>
		getAccount<Account>(accessToken, String(payment.relationships.account.data.id))
	)
	return accountAsync.match(
		() => null,
		(acc) => acc,
		(_) => null
	)
}

export function isValidForClearing(payment: Payment) {
	return (
		isAchPayment(payment) &&
		payment.attributes.status === PaymentStatus.Clearing &&
		payment.attributes.direction == PaymentDirection.Debit
	)
}

export function ClearConfirmationModal({
	payment,
	close,
	onSubmit,
}: {
	payment: Payment
	close: () => void
	onSubmit: () => void
}) {
	const account = getAccountByPayment(payment)
	const amount = toDollars(payment.attributes.amount)

	return (
		<ConfirmationModal
			close={close}
			onSubmit={onSubmit}
			title={"Clear Payment"}
			okButtonClassname={"button is-success is-outlined"}
			cancelButtonClassname="button is-white"
		>
			<div>
				<b>
					You are about to clear {amount} into account {account?.attributes.name}.
				</b>
				<span>
					<p>Clearing the payment ahead of the scheduled clearing time increases the risk of return-related losses.</p>
					<p>Are you sure you want to continue?</p>
				</span>
			</div>
		</ConfirmationModal>
	)
}
