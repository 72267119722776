import {createResource, getResource} from "./common"
import {Cents} from "./transaction"
export enum AuthorizationStatus {
	Authorized = "Authorized",
	Completed = "Completed",
	Canceled = "Canceled",
	Declined = "Declined",
}

export enum DeclineReason {
	CardVerificationValueFailed = "CardVerificationValueFailed",
	IncorrectPIN = "IncorrectPIN",
	ExceedsAmountLimit = "ExceedsAmountLimit",
	InsufficientFunds = "InsufficientFunds",
	RequestedFunctionNotSupported = "RequestedFunctionNotSupported",
	AllowablePINTriesExceeded = "AllowablePINTriesExceeded",
	SuspectedFraud = "SuspectedFraud",
	ClosedAccount = "ClosedAccount",
	DoNotHonor = "DoNotHonor",
	CardholderBlocked = "CardholderBlocked",
	CardStolen = "CardStolen",
	RestrictedCard = "RestrictedCard",
	InsufficientFundsDuringStandIn = "InsufficientFundsDuringStandIn",
	ExceedsStandInLimit = "ExceedsStandInLimit",
}

export type Authorization = {
	type: "authorization"
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		cardLast4Digits: string
		merchant: {
			name: string
			type: string
			category: string
			location?: string
		}
		status: AuthorizationStatus
		declineReason: DeclineReason
		recurring: boolean
	}
	relationships: {
		account?: {
			data: {
				type: string
				id: number | null
			}
		}
		customer?: {
			data: {
				type: string
				id: number | null
			}
		}
	}
}

export async function find(
	accessToken: string,
	offset: number,
	limit: number,
	accountId: string,
	statuses: AuthorizationStatus[],
	sort: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			accountId,
			statuses: statuses && statuses.length > 0 ? statuses : null,
			includeNonAuthorized: true,
		},
		sort: sort,
	}

	return await getResource<Array<Authorization>>("authorizations", accessToken, query)
}

export async function get(accessToken: string, authorizationId: string, includeNonAuthorized: boolean) {
	return await getResource<Authorization>(`authorizations/${authorizationId}`, accessToken, {
		filter: {includeNonAuthorized},
	})
}

export async function release(accessToken: string, authorizationId: string) {
	return await createResource<Authorization>(`authorizations/${authorizationId}/release`, accessToken, {})
}
