export function removeEmptyAttributes(object: Record<string, any>) {
	Object.entries(object).forEach(([key, value]) => {
		if (value && typeof value === "object") {
			removeEmptyAttributes(value)
		}
		if ((value && typeof value === "object" && !Object.keys(value).length) || value === "") {
			if (Array.isArray(object)) {
				object.splice(parseInt(key), 1)
			} else {
				delete object[key]
			}
		}
	})
	return object
}
