import {createResource, getResource, updateResource} from "./common"
import jsonMergePatch from "json-merge-patch"
import {has} from "lodash"
import {removeEmptyAttributes} from "../utilities/objects"
import {AccountStatus, AccountType, findAccounts, FindAccountsRequestParam} from "./account"

export type DepositProduct = {
	type: "depositProduct"
	id: string
	attributes: {
		createdAt: Date
		name: string
		incomingACHFee: number
		outgoingACHFee: number
		dailyACHDebitLimit: number
		dailyACHCreditLimit: number
		monthlyACHDebitLimit: number
		monthlyACHCreditLimit: number
		dailyACHDebitSoftLimit: number
		monthlyACHDebitSoftLimit: number
		dailyBookDebitLimit?: number | undefined | null
		dailyBookCreditLimit?: number | undefined | null
		monthlyBookDebitLimit?: number | undefined | null
		monthlyBookCreditLimit?: number | undefined | null
		dailyCardWithdrawalLimit: number
		dailyCardDepositLimit: number
		dailyCardPurchaseLimit: number
		dailyCardTransactionLimit: number
		achDebitClearingDays: number
		interestTerms?: InterestTerms
		cardProduct?: CardProduct
		incomingWireFee: number
		outgoingWireFee: number
		checkDepositClearingDays: number
		checkDepositMinClearingDays: number
		checkDepositMaxClearingDays: number
		dailyCheckDepositLimit: number
		monthlyCheckDepositLimit: number
		dailyCheckDepositSoftLimit: number
		monthlyCheckDepositSoftLimit: number
		isAutoCloseEnabled?: boolean
		autoCloseAfter?: number
		overdraftSettings?: OverdraftSettings
		dailyWireLimit: number
		monthlyWireLimit: number
		dailyWireSoftLimit: number
		monthlyWireSoftLimit: number
		dailyCheckPaymentLimit?: number | null | undefined
		monthlyCheckPaymentLimit?: number | null | undefined
		dailyCheckPaymentSoftLimit?: number | null | undefined
		monthlyCheckPaymentSoftLimit?: number | null | undefined
		checkWritingEnabled: boolean
		status: Status
		enableCheckDepositDynamicClearingDays?: boolean
		enableDynamicClearingDaysAchDebit?: boolean
		achDebitMinClearingDays?: number
		achDebitMaxClearingDays?: number
		isAchDebitFraudPredictEnabled?: boolean
		isSweepEnabled?: boolean
		checkWritingOriginationEnabled: boolean
		additionalVerificationThreshold?: number | null | undefined
		enableCustomerInterestFormula?: boolean
		customerInterestFormula?: string | undefined
	}
	relationships?: {
		org?: {
			data: {
				type: "org"
				id: string
			}
		}
		bank?: {
			data: {
				type: "bank"
				id: string
			}
		}
		bankRouting?: {
			data: {
				type: "bankRouting"
				id: string
			}
		}
		revenueAccount: {
			data: {
				type: "account"
				id: string
			}
		}
		transactionMonitoringSettings?: {
			data: {
				type: "transactionMonitoringSettings"
				id: string
			}
		}
		interestSettings?: {
			data: {
				type: "interestSetting"
				id: string
			}
		}
	}
}

export type InterestTerms = {
	orgShare: number | undefined
	customerShare: number | undefined
	sponsoredInterestFormula: string | undefined
}

export type OverdraftSettings = {
	enableOverdraft: boolean
	overdraftDaysLimit: number | null
}

export enum Status {
	Enabled = "Enabled",
	Disabled = "Disabled",
}

export type CardProduct = {
	cardQualifier: string | null
	cardIssuingFee: number | null
	individualBinId?: string | null
	businessBinId?: string | null
	limitGroup: string | null
	maxNumberOfPhysicalCards: number | null
	maxNumberOfVirtualCards: number | null
	programmaticPurchaseAuthorizationEnabled: boolean | null
	programmaticPurchaseAuthorizationTimeoutStrategy?: string | null
	programmaticCardTransactionAuthorizationEnabled: boolean | null
	programmaticCardTransactionAuthorizationTimeoutStrategy?: string | null
	programmaticAtmAuthorizationEnabled: boolean | null
	programmaticAtmAuthorizationTimeoutStrategy?: string | null
	programmaticPurchaseAuthorizationIncludeCredit?: boolean | null
	programmaticCardTransactionAuthorizationIncludeCredit?: boolean | null
	programmaticAdviceAuthorizationEnabled?: boolean | null
	orgInternationalServiceFee?: number | null
}

export type BIN = {
	bin: string
	institutionId: string
}

export type LimitGroupType = keyof typeof LimitGroup

export enum LimitGroup {
	LZ1706 = "Decline All",
	LZ1704 = "Up To 50",
	LZ1705 = "Up To 100",
	LZ1701 = "Up To 500",
	LZ1702 = "Up To 1000",
	LZ1703 = "Up To 2000",
}

export type TimeoutStrategyType = keyof typeof TimeoutStrategy

export enum TimeoutStrategy {
	Approve = "Approve",
	Decline = "Decline",
}

export type AutoCloseAfterType = keyof typeof AutoCloseAfter

export enum AutoCloseAfter {
	Days30 = "30 Days",
	Days60 = "60 Days",
	Days90 = "90 Days",
}

export async function findDepositProducts(
	accessToken: string,
	offset: number,
	limit: number,
	orgs?: string[],
	searchQuery?: string
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : null
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			status,
			orgs: orgFilter,
			query: searchQuery != "" ? searchQuery : null,
		},
		include: "bank,org",
	}

	return await getResource<Array<DepositProduct>>("deposit-products", accessToken, query)
}

export async function getDepositProduct(accessToken: string, id: string) {
	const result = await getResource<DepositProduct>(`deposit-products/${id}`, accessToken)

	return result.map((v) => v.data)
}

export async function createDepositProduct(
	accessToken: string,
	name: string,
	orgId: string,
	bankRoutingId: string,
	incomingACHFee: number,
	outgoingACHFee: number,
	dailyACHDebitLimit: number,
	dailyACHCreditLimit: number,
	monthlyACHDebitLimit: number,
	monthlyACHCreditLimit: number,
	dailyACHDebitSoftLimit: number,
	monthlyACHDebitSoftLimit: number,
	dailyBookDebitLimit: number | undefined | null,
	dailyBookCreditLimit: number | undefined | null,
	monthlyBookDebitLimit: number | undefined | null,
	monthlyBookCreditLimit: number | undefined | null,
	dailyCardWithdrawalLimit: number,
	dailyCardDepositLimit: number,
	dailyCardPurchaseLimit: number,
	dailyCardTransactionLimit: number,
	achDebitClearingDays: number,
	incomingWireFee: number,
	outgoingWireFee: number,
	cardProduct: CardProduct | undefined,
	transactionMonitoringSettingsId: string,
	interestTerms: InterestTerms,
	checkDepositClearingDays: number,
	checkDepositMinClearingDays: number | undefined | null,
	checkDepositMaxClearingDays: number | undefined | null,
	dailyCheckDepositLimit: number,
	monthlyCheckDepositLimit: number,
	dailyCheckDepositSoftLimit: number,
	monthlyCheckDepositSoftLimit: number,
	isAutoCloseEnabled: boolean,
	autoCloseAfter: number,
	overdraftSettings: OverdraftSettings,
	dailyWireLimit: number,
	monthlyWireLimit: number,
	dailyWireSoftLimit: number,
	monthlyWireSoftLimit: number,
	dailyCheckPaymentLimit: number | undefined | null,
	monthlyCheckPaymentLimit: number | undefined | null,
	dailyCheckPaymentSoftLimit: number | undefined | null,
	monthlyCheckPaymentSoftLimit: number | undefined | null,
	checkWritingEnabled: boolean,
	interestSettingsId: string | undefined | null,
	enableCheckDepositDynamicClearingDays: boolean | undefined,
	enableDynamicClearingDaysAchDebit: boolean | undefined,
	achDebitMinClearingDays: number | undefined | null,
	achDebitMaxClearingDays: number | undefined | null,
	isAchDebitFraudPredictEnabled: boolean | undefined,
	isSweepEnabled: boolean | undefined,
	checkWritingOriginationEnabled: boolean,
	additionalVerificationThreshold: number | undefined | null,
	enableCustomerInterestFormula: boolean | undefined,
	customerInterestFormula: string | undefined | null
) {
	const interestSettings = interestSettingsId
		? {
				data: {
					type: "interestSettings",
					id: interestSettingsId,
				},
		  }
		: undefined
	const result = await createResource<DepositProduct>("deposit-products", accessToken, {
		type: "depositProduct",
		attributes: {
			name,
			incomingACHFee,
			outgoingACHFee,
			dailyACHDebitLimit,
			dailyACHCreditLimit,
			monthlyACHDebitLimit,
			monthlyACHCreditLimit,
			dailyACHDebitSoftLimit,
			monthlyACHDebitSoftLimit,
			dailyBookDebitLimit,
			dailyBookCreditLimit,
			monthlyBookDebitLimit,
			monthlyBookCreditLimit,
			dailyCardWithdrawalLimit,
			dailyCardDepositLimit,
			dailyCardPurchaseLimit,
			dailyCardTransactionLimit,
			achDebitClearingDays,
			cardProduct,
			incomingWireFee,
			outgoingWireFee,
			interestTerms,
			checkDepositClearingDays,
			checkDepositMinClearingDays,
			checkDepositMaxClearingDays,
			dailyCheckDepositLimit,
			monthlyCheckDepositLimit,
			dailyCheckDepositSoftLimit,
			monthlyCheckDepositSoftLimit,
			isAutoCloseEnabled,
			autoCloseAfter,
			overdraftSettings,
			dailyWireLimit,
			monthlyWireLimit,
			dailyWireSoftLimit,
			monthlyWireSoftLimit,
			dailyCheckPaymentLimit,
			monthlyCheckPaymentLimit,
			dailyCheckPaymentSoftLimit,
			monthlyCheckPaymentSoftLimit,
			checkWritingEnabled,
			enableDynamicClearingDaysAchDebit,
			enableCheckDepositDynamicClearingDays,
			achDebitMinClearingDays,
			achDebitMaxClearingDays,
			isAchDebitFraudPredictEnabled,
			isSweepEnabled,
			checkWritingOriginationEnabled,
			additionalVerificationThreshold,
			enableCustomerInterestFormula,
			customerInterestFormula,
		},
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
			bankRouting: {
				data: {
					type: "bankRouting",
					id: bankRoutingId,
				},
			},
			transactionMonitoringSettings: {
				data: {
					type: "transactionMonitoringSettings",
					id: transactionMonitoringSettingsId,
				},
			},
			interestSettings,
		},
	})
	return result.map((v) => v.data)
}

export async function updateDepositProduct(
	accessToken: string,
	depositProduct: DepositProduct,
	cardProduct: CardProduct | null,
	interestTerms: InterestTerms,
	editedAttributes: Record<string, any>,
	editedRelationships: Record<string, any>
) {
	const depositProductAttributes = {...depositProduct.attributes, ...editedAttributes}
	const newData = removeEmptyAttributes(depositProductAttributes)

	let cardProductPatch: {} | null = null

	if (cardProduct) {
		cardProductPatch = jsonMergePatch.generate(depositProduct.attributes.cardProduct ?? {}, cardProduct)

		if (cardProductPatch && has(cardProductPatch, "individualBin")) {
			cardProductPatch = Object.assign(cardProductPatch ?? {}, {individualBin: cardProduct.individualBinId})
		}

		if (cardProductPatch && has(cardProductPatch, "businessBin")) {
			cardProductPatch = Object.assign(cardProductPatch ?? {}, {businessBin: cardProduct.businessBinId})
		}
	}

	const attributesPatch = jsonMergePatch.generate(depositProduct.attributes, newData)
	const patch = {
		...attributesPatch,
		interestTerms,
		cardProduct: cardProductPatch,
		overdraftSettings: editedAttributes.overdraftSettings,
	}
	const patchData = {
		type: "updateDepositProduct",
		attributes: patch,
		relationships: {
			transactionMonitoringSettings: {
				data: {
					type: "transactionMonitoringSettings",
					id: editedRelationships.transactionMonitoringSettingsId,
				},
			},
			interestSettings: {
				data: {
					type: "interestSetting",
					id: editedRelationships.interestSettingsId,
				},
			},
		},
	}
	const result = await updateResource<DepositProduct>(`deposit-products/${depositProduct.id}`, accessToken, patchData)
	return result.map((v) => v.data)
}

export async function disableDepositProduct(accessToken: string, depositProduct: DepositProduct) {
	return await createResource<DepositProduct>(`deposit-products/${depositProduct.id}/disable`, accessToken, {})
}

export async function enableDepositProduct(accessToken: string, depositProduct: DepositProduct) {
	return await createResource<DepositProduct>(`deposit-products/${depositProduct.id}/enable`, accessToken, {})
}

export async function findActiveAssociatedAccounts(accessToken: string, depositAccountId: number) {
	const request: FindAccountsRequestParam = {
		accessToken,
		offset: 0,
		limit: 1,
		depositProductId: depositAccountId,
		types: [AccountType.deposit],
		status: [AccountStatus.Open],
		requestType: "json",
	}
	return await findAccounts(request)
}
