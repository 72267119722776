import React, {useEffect, useState} from "react"
import OTPInput from "../../components/OTPInput/OTPInput"
import LoginContainer from "./LoginContainer"
import {useLocation, useNavigate} from "react-router-dom"
import {AuthError, Token, TwoFactorToken, useIsAuthenticated, useTwoFactorAuthentication} from "../../services/auth"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import Icon from "../../components/Icon/Icon"
import moment from "moment"
import classNames from "classnames"
import Back from "../../components/Back/Back"

type TwoFactorLoginProps = {
	twoFactorToken: TwoFactorToken
}

const MFA = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [twoFactorToken, setTwoFactorToken] = useState<TwoFactorToken | undefined>(undefined)
	const [isMissingPhone, setIsMissingPhone] = useState<boolean>(false)

	useEffect(() => {
		if (location.state && location.state.twoFactorToken) {
			setTwoFactorToken(location.state.twoFactorToken)
		} else if (location.state && location.state.isMissingPhone) {
			setIsMissingPhone(true)
		} else {
			navigate("/login", {replace: true})
		}
	}, [navigate, location])

	return (
		<LoginContainer>
			<div className="mfa-page">
				<div className="mfa-container">
					{twoFactorToken ? <MfaLogin twoFactorToken={twoFactorToken} /> : null}
					{isMissingPhone ? <MfaMissingPhone /> : null}
					<Back />
				</div>
			</div>
		</LoginContainer>
	)
}

const Error = ({
	authenticationState,
}: {
	authenticationState: AsyncResultIdleRequestState<Token | TwoFactorToken, AuthError>
}) => {
	return authenticationState.match(
		() => null,
		() => null,
		(_) => null,
		(error) => <p className="help is-danger mfa-error">{error.error}</p>
	)
}

const MfaLogin = ({twoFactorToken}: TwoFactorLoginProps) => {
	const navigate = useNavigate()
	const location = useLocation()

	const {from} = location.state || {from: {pathname: "/"}}
	const [authenticationState, authenticate, resendState, resend] = useTwoFactorAuthentication()
	const isAuthenticated = useIsAuthenticated()
	const [showTooManyAttempts, setShowTooManyAttempts] = useState<boolean>(false)
	const [showSent, setShowSent] = useState<boolean>(false)
	const [timeUnit, setTimeUnit] = useState<string>("minutes")

	const TooManyAttempts = ({expirationDate}: {expirationDate: Date}) => {
		const [timeLeft, setTimeLeft] = useState<number>(expirationDate.getTime() - new Date().getTime())

		useEffect(() => {
			const interval = setInterval(() => {
				setTimeLeft(expirationDate.getTime() - new Date().getTime())
				if (expirationDate.getTime() - new Date().getTime() < 0) {
					setShowTooManyAttempts(false)
				}
			}, 1000)

			return () => {
				clearInterval(interval)
			}
		}, [])

		useEffect(() => {
			if (timeLeft < 60) {
				setTimeUnit("seconds")
			} else if (timeLeft <= 0) {
				setTimeUnit("minutes")
			}
		}, [timeLeft])

		const formatted = moment.utc(timeLeft).format("mm:ss")

		return (
			<p className="help is-danger">
				Rate limit exceeded. Please try again in {formatted} {timeUnit}. <br />
				If you lost access to your phone please contact your admin for assistance.
			</p>
		)
	}

	const inprogress = authenticationState.match(
		() => false,
		() => true,
		(_) => false,
		(_) => false
	)

	const resendProgress = resendState.match(
		() => false,
		() => true,
		(_) => false,
		(_) => false
	)

	useEffect(
		() =>
			authenticationState.iter(() => {
				return navigate(from, {replace: true})
			}),
		[authenticationState]
	)

	useEffect(() => {
		isAuthenticated ? navigate(from, {replace: true}) : null
	}, [isAuthenticated])

	useEffect(() => {
		resendState.mapErr((err) => {
			if (err.code === "two_factor_max_send_attempts_reached") {
				setShowTooManyAttempts(true)
			}
		})
	}, [resendState])

	useEffect(() => {
		resendState.iter(() => {
			setShowSent(true)
			setTimeout(() => {
				setShowSent(false)
			}, 2000)
		})
	}, [resendState])

	return (
		<>
			<div className="mfa-title">
				Two-Factor
				<br />
				Authentication
			</div>
			<div className="mfa-description">
				Enter the 6-digit code we sent to {twoFactorToken.phone}.
				<br />
				Did not receive the code?{" "}
				{showSent ? (
					<span className="mfa-sent">Code sent</span>
				) : (
					<span
						className={classNames("mfa-clickable", resendProgress && "mfa-disabled")}
						onClick={() => resend(twoFactorToken.verificationToken)}
					>
						Resend code{" "}
					</span>
				)}
				{resendProgress ? <Icon icon="Icons_loader" size={18} className="mfa-loading spin" /> : null}
			</div>
			<div className="mfa-input">
				<OTPInput
					isError={authenticationState.isErr()}
					disabled={inprogress || resendProgress}
					numOfDigits={6}
					submit={(code) => authenticate(code, twoFactorToken.verificationToken)}
				/>
			</div>
			{resendState.isErr() ? (
				showTooManyAttempts ? (
					<TooManyAttempts expirationDate={twoFactorToken.expires} />
				) : (
					<Error authenticationState={resendState} />
				)
			) : (
				<Error authenticationState={authenticationState} />
			)}
		</>
	)
}

const MfaMissingPhone = () => {
	return (
		<>
			<div className={"mfa-title"}>
				Two-Factor
				<br />
				authentication is
				<br />
				required
			</div>
			<div className={"mfa-description"}>
				Each time you log in with your email and password you will be <br />
				required to enter a phone verification code. Please contact your <br />
				administrator to configure your phone settings and try again.
			</div>
		</>
	)
}

export default MFA
