import React from "react"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"
import classNames from "classnames"

interface ItemsListProps {
	title: string
	items: string[]
	itemIcon?: IcomoonIconName
	showNumbering?: boolean
	showBulletOutline?: boolean
	disabled?: boolean
	iconSize?: number
	className?: string
}

interface ItemProps {
	title: string
	icon?: IcomoonIconName
	number?: number
	showBulletOutline?: boolean
	iconSize?: number
	disabled?: boolean
}

function Item({title, icon, number, showBulletOutline, iconSize = 12, disabled}: ItemProps) {
	return (
		<div className="item">
			{(number || icon) && (
				<div className={classNames(showBulletOutline ? "item-bullet" : "item-no-bullet", disabled && "disabled")}>
					{number ?? (icon && <Icon icon={icon} size={iconSize} />)}
				</div>
			)}
			<div className={classNames("item-content", disabled && "disabled")}>{title}</div>
		</div>
	)
}

export default function ItemsList({
	title,
	items,
	itemIcon,
	showNumbering,
	showBulletOutline,
	disabled,
	iconSize,
	className = "",
}: ItemsListProps) {
	return (
		<div className="items-list-container">
			<div className={classNames("items-list-header", disabled && "disabled")}>{title}</div>
			<div className={classNames("items-list", className)}>
				{items.map((item, index) => (
					<Item
						key={index}
						title={item}
						number={showNumbering ? index + 1 : undefined}
						icon={itemIcon}
						showBulletOutline={showBulletOutline}
						iconSize={iconSize}
						disabled={disabled}
					/>
				))}
			</div>
		</div>
	)
}
