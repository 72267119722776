import React, {useEffect, useState} from "react"
import {Token, TokenType, TwoFactorToken, useAuthentication, useIsAuthenticated} from "../../services/auth"
import {useLocation, useNavigate} from "react-router"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {isProdEnv, isSandboxEnv} from "../../utilities/environment"
import {Link} from "react-router-dom"
import {documentTitle, reCaptchaSiteKey} from "../../index"
import {useLocalStorage} from "react-use"
import LoginContainer from "./LoginContainer"
import classNames from "classnames"
import useReCaptcha from "../../hooks/useReCaptcha"

function Error({
	authenticationState,
}: {
	authenticationState: AsyncResultIdleRequestState<Token | TwoFactorToken, {error: string; code?: string}>
}) {
	return authenticationState.match(
		() => null,
		() => null,
		(_) => null,
		(error) => <p className="help is-danger">{error.error}</p>
	)
}

export default function () {
	const navigate = useNavigate()
	const location = useLocation()

	const {from} = location.state || {from: {pathname: "/"}}

	const [, setWorkspaceName] = useLocalStorage<string | undefined>("workspaceName", undefined)
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [authenticationState, authenticate] = useAuthentication()
	const isAuthenticated = useIsAuthenticated()
	const inprogress = authenticationState.match(
		() => false,
		() => true,
		(_) => false,
		(_) => false
	)
	const [isReCaptchaLoading, setIsReCaptchaLoading] = useState(false)
	useReCaptcha()

	// Redirect back when logged in
	useEffect(
		() =>
			authenticationState.iter((authToken) => {
				switch (authToken.type) {
					case TokenType.AccessToken:
						return navigate(from, {replace: true})
					case TokenType.TwoFactorToken:
						return navigate("/login/two-factor", {state: {from, twoFactorToken: authToken}})
				}
			}),
		[authenticationState]
	)

	useEffect(() => {
		authenticationState.mapErr((err) => {
			if (err.code === "two_factor_user_missing_phone_number") {
				navigate("/login/two-factor", {state: {from, isMissingPhone: true}})
			}
		})
	}, [authenticationState])

	useEffect(() => {
		isAuthenticated ? navigate(from, {replace: true}) : null
	}, [isAuthenticated])

	useEffect(() => {
		document.title = documentTitle
	}, [])

	function getEnvTitle() {
		if (isSandboxEnv()) {
			return "Sign in to Sandbox"
		}
		return "Sign in"
	}

	const buttonClassnames = classNames("button is-medium", {"is-loading": inprogress || isReCaptchaLoading})

	return (
		<LoginContainer>
			<div className="login-container is-flex is-flex-grow-1 is-flex-direction-column is-align-self-center is-justify-content-center">
				<span className="label login-title">{getEnvTitle()}</span>
				{!isProdEnv() ? (
					<div className="sign-up-link">
						Don&apos;t have an account?{" "}
						<Link to={"/signup"}>
							<span className="sign-up-icon">→</span>
							<b>Sign up now</b>
						</Link>
					</div>
				) : null}
				<form
					className="login-form"
					onSubmit={(e) => {
						e.preventDefault()
						setIsReCaptchaLoading(true)
						grecaptcha.enterprise.ready(async () => {
							if (reCaptchaSiteKey) {
								const token = await grecaptcha.enterprise.execute(reCaptchaSiteKey, {action: "LOGIN"})
								setIsReCaptchaLoading(false)
								setWorkspaceName(undefined)
								return authenticate(username, password, token)
							}
						})
					}}
				>
					<div className="field">
						<label className="label">Email</label>
						<div className="control">
							<input
								className="input is-medium"
								type="text"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								placeholder="Username"
								required
								autoFocus
								autoComplete="on"
							/>
						</div>
					</div>
					<div className="field">
						<label className="label">Password</label>
						<div className="control">
							<input
								className="input is-medium"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Password"
								autoComplete="on"
								required
							/>
						</div>
						<Error authenticationState={authenticationState} />
					</div>
					<div className="field login-buttons">
						<div className="control is-flex is-align-items-center buttons">
							<button type="submit" className={buttonClassnames}>
								Sign in
							</button>
							<Link to="/sso" className="sso-link">
								Sign in with SSO
							</Link>
						</div>
					</div>
				</form>
			</div>
		</LoginContainer>
	)
}
