import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {useIsUnitUser} from "../../services/auth"
import IncomingChecks, {IncomingChecksColumns} from "../../components/IncomingChecks/IncomingChecks"

export default function IncomingChecksPage(): ReactElement {
	return (
		<>
			<TitleBar title={"Incoming Checks"} />

			<MainSectionTable>
				<IncomingChecks
					includedColumns={[
						IncomingChecksColumns.id,
						IncomingChecksColumns.org,
						...(useIsUnitUser() ? [IncomingChecksColumns.bank] : []),
						IncomingChecksColumns.accountId,
						IncomingChecksColumns.customer,
						IncomingChecksColumns.amount,
						IncomingChecksColumns.status,
						IncomingChecksColumns.createdAt,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
