import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import IncomingAchs, {IncomingAchColumns} from "./IncomingAchs"

export default function IncomingAchsPage(): ReactElement {
	return (
		<>
			<TitleBar title="Incoming ACH" />

			<MainSectionTable>
				<IncomingAchs
					fullHeight={true}
					enableOrgFilter={true}
					enableStatusFilter={true}
					enableAmountFilter={true}
					enableDateFilter={true}
					includedColumns={[
						IncomingAchColumns.id,
						IncomingAchColumns.account,
						IncomingAchColumns.customer,
						IncomingAchColumns.org,
						IncomingAchColumns.amount,
						IncomingAchColumns.status,
						IncomingAchColumns.createdAt,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
