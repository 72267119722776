import {Address, FullName, Permissible, Phone, Resource, updateResource} from "./common"
import {AnnualIncome, EvaluationOutcome, Occupation, SourceOfIncome} from "./application"
import moment from "moment"

export type BeneficialOwner = {
	fullName: FullName
	ssn?: Permissible<string>
	passport?: Permissible<string>
	matriculaConsular?: Permissible<string>
	nationality?: Permissible<string>
	address: Permissible<Address>
	dateOfBirth: Permissible<Date>
	email: Permissible<string>
	phone: Permissible<Phone>
	status: EvaluationOutcome
	percentage?: number
	evaluationFlags?: string[]
	maskedSSN?: string
	maskedPassport?: string
	maskedMatriculaConsular?: string
	idTheftScore?: number
	evaluationCodes?: string[]
	occupation?: Occupation
	annualIncome?: AnnualIncome
	sourceOfIncome?: SourceOfIncome
}

export type BeneficialOwnerResource = {
	type: "beneficialOwner"
	id: string
	attributes: BeneficialOwner
	relationships: {
		application: {
			data: {
				type: "application"
				id: string
			}
		}
	}
}

export function isBeneficialOwner(resource: Resource): resource is BeneficialOwnerResource {
	return resource.type === "beneficialOwner"
}

export async function updateBeneficialOwner(
	accessToken: string,
	beneficialOwner: BeneficialOwnerResource,
	{
		occupation,
		annualIncome,
		sourceOfIncome,
	}: {
		occupation?: Occupation
		annualIncome?: AnnualIncome
		sourceOfIncome?: SourceOfIncome
	}
) {
	const query = {
		type: "beneficialOwner",
		attributes: Object.assign(
			{},
			occupation ? {occupation: occupation} : undefined,
			annualIncome ? {annualIncome: annualIncome} : undefined,
			sourceOfIncome ? {sourceOfIncome: sourceOfIncome} : undefined
		),
		relationships: {
			application: {
				data: {
					type: "businessApplication",
					id: beneficialOwner.relationships.application.data.id,
				},
			},
		},
	}

	return await updateResource<BeneficialOwnerResource>(`beneficial-owner/${beneficialOwner.id}`, accessToken, query)
}

export async function updateBeneficialOwnerDeprecated(
	accessToken: string,
	updatedBeneficialOwner: BeneficialOwnerResource
) {
	const {fullName, ssn, passport, nationality, matriculaConsular, dateOfBirth, address, phone, email, percentage} =
		updatedBeneficialOwner.attributes
	const query = {
		type: "beneficialOwner",
		attributes: Object.assign(
			{},
			fullName ? {fullName: fullName} : undefined,
			ssn ? {ssn: ssn} : undefined,
			passport ? {passport: passport} : undefined,
			nationality ? {nationality: nationality} : undefined,
			matriculaConsular ? {matriculaConsular: matriculaConsular} : undefined,
			dateOfBirth ? {dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD")} : undefined,
			address ? {address: address} : undefined,
			phone ? {phone: phone} : undefined,
			email ? {email: email} : undefined,
			percentage ? {percentage: percentage} : undefined
		),
		relationships: {
			application: {
				data: {
					type: "businessApplication",
					id: updatedBeneficialOwner.relationships.application.data.id,
				},
			},
		},
	}

	return await updateResource<BeneficialOwnerResource>(
		`beneficial-owner/${updatedBeneficialOwner.id}`,
		accessToken,
		query
	)
}
