import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	CounterpartyAccountType,
	CounterpartyPermission,
	CounterpartyType,
	createCounterparty,
} from "../../resources/counterparty"
import KeyValueEditor from "../KeyValueEditor/KeyValueEditor"
import {Formik} from "formik"
import * as Yup from "yup"
import {createCounterpartySchema} from "./CounterpartyValidationSchema"
import {focusFirstInvalidField} from "../Address/Address"
import Textfield from "../Address/Textfield"
import Dropdown from "../Address/Dropdown"

type CloseFunc = () => void
type RefreshFunc = () => void

const modalCloseDelay = 1000

function closeAndRefresh(refresh: () => void, close: () => void, delay = 0) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, delay)
	}
}

interface ModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	customerId: string
}

export type Tag = {key: string; value: string}

function Modal({customerId, close, refresh}: ModalProps) {
	const validationSchema = Yup.object().shape(createCounterpartySchema)
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createCounterparty)
	const [tags, setTags] = useState<Array<Tag> | undefined>([])

	const initialFormValues = {
		counterpartyName: "",
		routingNumber: "",
		accountNumber: "",
		accountType: "" as CounterpartyAccountType | "",
		counterpartyType: "" as CounterpartyType | "",
		permissions: "" as CounterpartyPermission | "",
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				if (values.accountType && values.counterpartyType && values.permissions) {
					create({
						accessToken,
						customerId,
						name: values.counterpartyName,
						routingNumber: values.routingNumber,
						accountNumber: values.accountNumber,
						accountType: values.accountType,
						type: values.counterpartyType,
						permissions: values.permissions,
						tags: tags?.reduce((a, v) => ({...a, [v.key]: v.value}), {}),
					})
				}
			}}
		>
			{({values, handleSubmit, handleChange, errors, isValid, handleBlur}) => (
				<AsyncResultModal
					title="Add Counterparty"
					classname="add-counterparty-modal"
					onSubmit={(e: React.FormEvent<HTMLFormElement> | undefined) => {
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
					close={close}
					state={state}
					enableClickAway={false}
					buttonClassname="is-black"
					buttonText="Add"
					successText="Added successfully"
					errorToText={(err) => {
						return err.errors[0].detail ? err.errors[0].detail : err.errors[0].title
					}}
					onSuccess={() => {
						closeAndRefresh(refresh, close, modalCloseDelay)()
					}}
				>
					<Textfield
						label="Counterparty name"
						placeholder="Add counterparty name"
						name="counterpartyName"
						value={values.counterpartyName}
						onChange={handleChange}
						onBlur={() => null}
					/>
					<div className="field-row-wrapper">
						<div className="field">
							<Dropdown
								name="counterpartyType"
								label="Counterparty Type"
								value={values.counterpartyType}
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value="" disabled>
									Select Counterparty Type
								</option>
								<option value="Business">Business</option>
								<option value="Person">Person</option>
								<option value="Unknown">Unknown</option>
							</Dropdown>
						</div>
						<div className="field">
							<Dropdown
								name="permissions"
								label="Permissions"
								value={values.permissions}
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value="" disabled>
									Select Permissions
								</option>
								<option value="CreditOnly">Credit Only</option>
								<option value="CreditAndDebit">Credit And Debit</option>
								<option value="DebitOnly">Debit Only</option>
							</Dropdown>
						</div>
					</div>
					<div className="field-row-wrapper">
						<div className="field">
							<Textfield
								label="Routing Number"
								placeholder="000000000"
								name="routingNumber"
								value={values.routingNumber}
								onChange={handleChange}
								onBlur={() => null}
							/>
						</div>

						<div className="field">
							<Textfield
								label="Account Number"
								placeholder="00000000000"
								name="accountNumber"
								value={values.accountNumber}
								onChange={handleChange}
								onBlur={() => null}
							/>
						</div>
					</div>
					<div className="field-row-wrapper">
						<div className="field">
							<Dropdown
								name="accountType"
								label="Account Type"
								value={values.accountType}
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value="" disabled>
									Select Account Type
								</option>
								<option value="Checking">Checking</option>
								<option value="Savings">Savings</option>
							</Dropdown>
						</div>
					</div>

					<div className="field tags-editor">
						<label className="label">Tags (optional)</label>
						<KeyValueEditor
							keyValues={tags ?? []}
							setKeyValues={(e: React.SetStateAction<Tag[] | undefined>) => {
								setTags(e)
							}}
							keyName={"key"}
							valueName={"value"}
							itemName={"Tag"}
							keyPlaceholder={"Tag Key"}
							valuePlaceholder={"Tag Value"}
							valueInputType={"text"}
						/>
					</div>
				</AsyncResultModal>
			)}
		</Formik>
	)
}

export default function AddCounterpartyModal({
	customerId,
	close,
	refresh,
}: {
	customerId: string
	close: CloseFunc
	refresh: RefreshFunc
}) {
	return <Modal customerId={customerId} close={close} refresh={refresh} />
}
