import {createResource, createResourceFromFile, getResource} from "./common"

export enum StatusType {
	WaitingForSecondSignature = "Pending Approval",
	Posted = "Posted",
	Canceled = "Canceled",
}

export type Status = {
	type: StatusType
	byUserId: number
	updatedAt: Date
}

export type Adjustment = {
	id: string
	type: string
	attributes: {
		amount: number
		direction: string
		description: string
		internalNote: string
		statusHistory: Status[]
		createdAt: Date
	}
	relationships: {
		org?: {
			data: {
				type: "org"
				id: number
			}
		}
		customer?: {
			data: {
				type: "customer"
				id: number
			}
		}
		account: {
			data: {
				type: "account"
				id: number
			}
		}
		transaction?: {
			data: {
				type: "transaction"
				id: number
			}
		}
	}
}

export type createBulkAdjustmentsResult = {
	type: string
	relationships: {
		adjustments: {
			data: [
				{
					type: "adjustment"
					id: string
				}
			]
		}
	}
}

export function StatusTypeValue(key: string): string {
	return StatusType[key as keyof typeof StatusType]
}

export async function getAdjustment(accessToken: string, id: string) {
	const result = await getResource<Adjustment>(`adjustments/${id}`, accessToken)
	return result.map((v) => v.data)
}

export async function getAccountAdjustments(accessToken: string, offset: number, limit: number, accountId: string) {
	return findAdjustments(accessToken, offset, limit, accountId)
}

export async function filterByStatuses(accessToken: string, offset: number, limit: number, statuses: string[]) {
	return findAdjustments(accessToken, offset, limit, undefined, statuses)
}

export async function findAdjustments(
	accessToken: string,
	offset: number,
	limit: number,
	accountId?: string,
	statuses?: string[],
	transactionId?: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			accountId,
			statuses,
			transactionId,
		},
		include: "org,customer",
	}

	return await getResource<Array<Adjustment>>("adjustments", accessToken, query)
}

export async function createAdjustment(
	accessToken: string,
	accountId: string,
	amount: number,
	direction: string,
	description: string,
	internalNote: string
) {
	const result = await createResource<Adjustment>("adjustments", accessToken, {
		type: "adjustment",
		attributes: {
			amount,
			direction,
			description,
			internalNote,
		},
		relationships: {
			account: {
				data: {
					id: accountId,
				},
			},
		},
	})
	return result.map((v) => v.data)
}

export async function setAdjustmentAction(accessToken: string, id: string, action: string) {
	const result = await createResource<Adjustment>(`adjustments/${id}/${action}`, accessToken, null)
	return result.map((v) => v.data)
}

export async function createBulkAdjustments(accessToken: string, inputFile: any, type: string, fileName: string) {
	return await createResourceFromFile<createBulkAdjustmentsResult>(
		"adjustments/bulk",
		accessToken,
		inputFile,
		type,
		fileName
	)
}
