import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {returnCheckDeposit} from "../../resources/checkDeposits"
import Admonition from "../Admonitions/Admonition"

interface ReturnCheckModalProps {
	id: string
	closeModal: () => void
	onSuccess: () => void
}

const presetReasons = {
	"Not Sufficient Funds": "A",
	"Uncollected Funds Hold": "B",
	"Stop Payment": "C",
	"Closed Account": "D",
	"Unable to Locate Account": "E",
	"Frozen/Blocked Account": "F",
	"Stale Dated": "G",
	"Post Dated": "H",
	"Endorsement Missing": "I",
	"Endorsement Irregular": "J",
	"Signature(s) Missing": "K",
	"Signature(s) Irregular": "L",
	"Non-Cash Item": "M",
	"Altered/Fictitious": "N",
	"Unable to Process": "O",
	"Item Exceeds Dollar Limit": "P",
	"Not Authorized": "Q",
	"Branch/Account Sold": "R",
	"Refer to Maker": "S",
	"Stop Payment Suspect": "T",
	"Unusable Image": "U",
	"Image Fails Security Check": "V",
	"Cannot Determine Amount": "W",
	"Duplicate Presentment": "Y",
	Forgery: "Z",
	"Unavailable Funds": "2",
	Ineligible: "3",
	"Not Our Item (Wrong Bank)": "4",
	"Retired ABA": "5",
	"Suspected Counterfeit": "6",
	Counterfeit: "7",
	"Warranty Breach": "8",
	"Amounts Differ": "9",
	"Double Post": "10",
	"Amount Not Authorized": "11",
	"Payee Deceased": "12",
	"Personal Endorsement Missing": "13",
	"Check Fraud - Breach of Warranty": "14",
	"Non Conforming Image": "15",
	"Non Conforming IRD": "16",
	"Do Not Represent - Exceeds Presentment Limits": "19",
	"Suspected Altered": "20",
	"Altered/Fictitious Amount": "21",
	"Altered/Fictitious Date": "22",
	"Altered/Fictitious Payee Signature": "23",
	"Altered/Fictitious Payee Name": "24",
	"Altered/Fictitious Maker": "25",
	"Unable to Process/Mutilated Item - Missing Payee": "26",
	"Unable to Process/Mutilated Item - Missing Amount": "27",
	"Unable to Process/Mutilated Item - Missing Maker's Signature": "28",
	"Signature Irregular - Not on File": "31",
	"Signature Irregular - Two Signatures Required": "32",
	"Signature Irregular - Unauthorized Signature": "33",
	"Signature Irregular - Questionable": "34",
	"Unable to Locate Account - Invalid Account": "35",
	"Unable to Locate Account - Divested Account": "36",
	"MISSING IMAGE (F&B)": "41",
	"MISSING IMAGE FRONT": "42",
	"MISSING IMAGE BACK": "43",
	"FAILS IQA - UNUSABLE": "50",
	"INVALID DATA FOR TYPE": "70",
	"MISS MAND DATA": "71",
	"NA COMP/FORMAT": "72",
	"MISMATCH TYPE 50/52": "74",
	"IMAGE DECOMP ERROR": "75",
	"INVAL IMAGE STRUCTURE": "76",
	"VARIABLE DATA MISMATCH": "77",
	"INVALID ITEM IN BUNDLE": "78",
	"CLIP COORD INVALID": "79",
	"MISMATCHED MICR": "80",
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ReturnCheckModal({id, closeModal, onSuccess}: ReturnCheckModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState(presetReasons["Altered/Fictitious"])
	const [rejectedState, setReturnedState] = useAsyncResultIdle(returnCheckDeposit)

	return (
		<AsyncResultModal
			title={`You Are About to Return Check ID #${id}`}
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-danger"
			state={rejectedState}
			buttonText="Mark as returned"
			successText="Check is returned"
			onSubmit={() => {
				setReturnedState({id, accessToken, reason})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<div className="select">
						<select onChange={(e) => setReason(e.target.value)} value={reason}>
							{Object.entries(presetReasons).map((reason) => {
								return (
									<option key={reason[1]} value={reason[1]}>
										{reason[1]} - {reason[0]}
									</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
			<Admonition
				type={"is-danger"}
				message={
					<p>
						{
							"Marking a check deposit as returned is a final action and cannot be undone. This will not actually initiate a return, it will only change the check deposit status to reflect that it has been returned by the bank that issued the check"
						}
					</p>
				}
				title={"Warning"}
			/>
		</AsyncResultModal>
	)
}
