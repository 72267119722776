import React, {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFire, faKey, faRocket} from "@fortawesome/free-solid-svg-icons"
import partners from "../../images/partners.svg"
import {Link} from "react-router-dom"
import {useNavigate} from "react-router"
import classNames from "classnames"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {useSignUp} from "../../services/signUp"
import {ErrorDocument} from "../../resources/common"
import {useToasts} from "react-toast-notifications"
import {useIsAuthenticated} from "../../services/auth"
import {envName, reCaptchaSiteKey} from "../../index"
import unitSandboxLogo from "../../images/unit-sandbox-logo.svg"
import {isSandboxEnv} from "../../utilities/environment"
import TagManager from "react-gtm-module"
import Icon from "../../components/Icon/Icon"
import {nameRegex} from "../../components/Users/Shared"
import useReCaptcha from "../../hooks/useReCaptcha"

function Error({authenticationState}: {authenticationState: AsyncResultIdleRequestState<any, ErrorDocument>}) {
	return authenticationState.match(
		() => null,
		() => null,
		(_) => null,
		(error) => (
			<p className="help is-danger">{error.errors[0].detail ? error.errors[0].detail : error.errors[0].title}</p>
		)
	)
}

export default function SignUp() {
	const {addToast} = useToasts()
	const navigate = useNavigate()

	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [companyName, setCompanyName] = useState("")
	const [subscribe, setSubscribe] = useState(true)
	const [signupState, signup] = useSignUp()
	const [isReCaptchaLoading, setIsReCaptchaLoading] = useState(false)
	const inProgress = signupState.match(
		() => false,
		() => true,
		(_) => false,
		(_) => false
	)
	const isAuthenticated = useIsAuthenticated()
	useReCaptcha()

	// Redirect to home if logged in
	useEffect(() => {
		isAuthenticated ? navigate("/", {replace: true}) : null
	}, [])

	useEffect(() => {
		document.title = "Unit | Sign up" + " - " + envName
		document.body.className = "signup-page"

		return () => {
			document.body.classList.remove("signup-page")
		}
	}, [])

	// Redirect back when sign up complete successfully
	useEffect(
		() =>
			signupState.iter(() => {
				addToast("Account created successfully", {appearance: "success"})
				navigate("/login")
			}),
		[signupState]
	)

	useEffect(() => {
		if (isSandboxEnv()) {
			const tagManagerArgs = {
				gtmId: "GTM-T3TK3PX",
			}
			TagManager.initialize(tagManagerArgs)
		}
	}, [])

	const buttonClassnames = classNames("button is-medium is-fullwidth", {"is-loading": inProgress || isReCaptchaLoading})
	const description = (
		<>
			<div className="description-title">Build banking in minutes</div>
			<div className="description-items">
				<div className="item-row is-flex is-align-items-center">
					<FontAwesomeIcon className="item-icon" icon={faKey} />
					<div className="is-flex-grow-1">
						<div className="item-description">
							Use Unit API and white-labeled user interfaces to test and simulate accounts, cards, payments, and more.
						</div>
					</div>
				</div>
				<div className="item-row is-flex is-align-items-center">
					<FontAwesomeIcon className="item-icon" icon={faFire} />
					<div className="is-flex-grow-1">
						<div className="item-description">
							Access the Unit Dashboard for full visibility into your customer activity.
						</div>
					</div>
				</div>
				<div className="item-row is-flex is-align-items-center">
					<FontAwesomeIcon className="item-icon" icon={faRocket} />
					<div className="is-flex-grow-1">
						<div className="item-description">Move to your Live environment once you’re ready to launch.</div>
					</div>
				</div>
			</div>
		</>
	)

	return (
		<>
			<section className={classNames("columns sign-up")}>
				<div className="column is-5 is-full-height is-half is-flex sign-up-form-side is-justify-content-center">
					<a href="https://www.unit.co/" className="sign-up-back-to-unit">
						<div className={"sign-up-back-to-unit-ellipse"}>
							<Icon icon={"keyboard-left-arrow--interface-essential"} size={12} />
						</div>
						unit.co
					</a>

					<div className="description-container is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
						<img className="unit-signup-logo" src={unitSandboxLogo} />
						{description}
						<div className="partners-container">
							<div className="partners-title">Partnered with leading fintech brands</div>
							<div className="partners is-flex">
								<img className="is-flex-grow-1" src={partners} />
							</div>
						</div>
					</div>
				</div>
				<div className="column is-7 is-full-height sign-up-form-container is-flex is-justify-content-center">
					<div className="sign-up-form-sub-container is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
						<div className="sign-up-form-box is-flex is-flex-direction-column">
							<span className="label sign-up-title">Sign up for an account</span>
							<span className="login-link">
								Already have an account?{" "}
								<Link to="/login">
									<span className="login-icon">→</span>
									<b>Sign in</b>
								</Link>
							</span>
							<form
								className="sign-up-form"
								onSubmit={(e) => {
									e.preventDefault()
									setIsReCaptchaLoading(true)
									grecaptcha.enterprise.ready(async () => {
										if (reCaptchaSiteKey) {
											const token = await grecaptcha.enterprise.execute(reCaptchaSiteKey, {action: "SIGN_UP"})
											setIsReCaptchaLoading(false)
											signup(firstName, lastName, companyName, username, password, token, subscribe)
										}
									})
								}}
							>
								<div className="field-group columns">
									<div className="field column">
										<label className="label">First Name</label>
										<div className="control">
											<input
												className="input is-medium"
												type="text"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
												placeholder="Richard"
												pattern={nameRegex}
												title="No special characters are allowed"
												required
											/>
										</div>
									</div>
									<div className="field column">
										<label className="label">Last Name</label>
										<div className="control">
											<input
												className="input is-medium"
												type="text"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
												placeholder="Hendricks"
												pattern={nameRegex}
												title="No special characters are allowed"
												required
											/>
										</div>
									</div>
								</div>
								<div className="field">
									<label className="label">Email</label>
									<div className="control">
										<input
											className="input is-medium"
											name="email"
											type="email"
											value={username}
											onChange={(e) => setUsername(e.target.value)}
											placeholder="richard@piedpiper.com"
											required
										/>
									</div>
								</div>
								<div className="field">
									<label className="label">Company Name</label>
									<div className="control">
										<input
											className="input is-medium"
											type="text"
											value={companyName}
											onChange={(e) => setCompanyName(e.target.value)}
											placeholder="Pied Piper"
											required
										/>
									</div>
								</div>
								<div className="field">
									<label className="label">Password</label>
									<div className="control">
										<input
											className="input is-medium"
											type="password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											placeholder="Password"
											pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{12,}$"
											title="Password should be combination of one uppercase, one lower case, one special char, one digit and minimum 12 characters"
											required
										/>
									</div>
								</div>
								<label className="follow is-flex is-align-items-center">
									<input type="checkbox" checked={subscribe} onChange={(e) => setSubscribe(e.target.checked)} />
									Opt in to receive updates and news from Unit.
								</label>
								<div className="field sign-up-button">
									<div className="control">
										<button type="submit" className={buttonClassnames}>
											Create Account
										</button>
									</div>
								</div>
								<Error authenticationState={signupState} />
								<span className="disclosure">
									By creating an account, you are agreeing to our{" "}
									<a href="https://www.unit.co/privacy-policy" target="_blank" rel="noreferrer">
										Privacy Policy
									</a>
									{", "} and{" "}
									<a href="https://www.unit.co/electronic-communication-disclosure" target="_blank" rel="noreferrer">
										Electronics Communication Policy.{" "}
									</a>
								</span>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
