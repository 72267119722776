import React from "react"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {noop} from "lodash"
import ReactTooltip from "react-tooltip"

export type ChipProps = {
	label: string
	onClick?: () => void
	isSelected?: boolean
	readonly?: boolean
	tooltip?: string
	tooltipKeyPrefix?: string
}

export type ChipsProps = {
	labels: string[]
	selectedChips?: string[]
	setSelected?: (label: string) => void
	readonly?: boolean
	tooltips?: (string | undefined)[]
	tooltipKeyPrefix?: string
}

function Chip({label, onClick, isSelected, readonly, tooltip, tooltipKeyPrefix}: ChipProps) {
	const tooltipKeyString = (tooltipKeyPrefix ? tooltipKeyPrefix + "-" : "") + label
	const tooltipKey = tooltip ? tooltipKeyString : undefined
	return (
		<div data-tip={tooltip} data-for={tooltipKey} id={tooltipKey}>
			{readonly ? (
				<div className="chip">{label}</div>
			) : (
				<div className={classNames("chip", "edit", isSelected && "selected")} onClick={onClick}>
					{label}
					<div className={classNames("icon-button", "chip-action-icon", isSelected && "selected")}>
						<Icon icon="add-plus-small" size={16} />
					</div>
				</div>
			)}
			{tooltip && <ReactTooltip id={tooltipKey} className="chip-tooltip" place="bottom" effect="solid" />}
		</div>
	)
}
export function Chips({
	labels,
	selectedChips = [],
	setSelected = noop,
	readonly,
	tooltips = [],
	tooltipKeyPrefix,
}: ChipsProps) {
	return (
		<div className="chips-container">
			{labels.map((label, index) => (
				<Chip
					key={label}
					label={label}
					onClick={() => setSelected(label)}
					isSelected={selectedChips.includes(label)}
					readonly={readonly}
					{...(tooltips.length > 0 && {tooltip: tooltips[index]})}
					{...(tooltipKeyPrefix && {tooltipKeyPrefix})}
				/>
			))}
		</div>
	)
}

export default Chips
