import {useAccessToken, useIsBankUser, useIsUnitUser} from "../../services/auth"
import {compact, isEmpty} from "lodash"
import {usePaging} from "../../hooks/usePaging"
import {findOrgsWithPaginationMeta, getOrgName, Org, OrgMode} from "../../resources/org"
import {Filter} from "../../components/Filter/Filter"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {useEffect} from "react"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import moment from "moment"
import numeral from "numeral"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {useNavigate} from "react-router-dom"
import {isLocalEnv, isSandboxEnv} from "../../utilities/environment"
import {useQueryState} from "use-location-state"
import SearchBox from "../../components/SearchBox/SearchBox"
import TitleBar from "../../components/TitleBar/TitleBar"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"
import Icon from "../../components/Icon/Icon"
import {Meta} from "../../resources/common"
import {HasPermission} from "../../containers/PermissionedUser/Permission"

function OrgTableRow({org, showDepositsData}: {org: Org; showDepositsData: boolean}) {
	const navigate = useNavigate()
	const orgBalances = org.attributes.orgBalancesSummary?.balances ?? 0

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/orgs/${org.id}`)
			}}
		>
			<DataTableCell>{org.id}</DataTableCell>
			<DataTableCell>{getOrgName(org)}</DataTableCell>
			{showDepositsData && <DataTableCell>{numeral(orgBalances / 100).format("$0,0.00")}</DataTableCell>}
			<td>{moment(org.attributes.createdAt).format("L")}</td>
		</DataTableRow>
	)
}

export default function Orgs() {
	const PAGE_SIZE = 25
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const showDepositsData = useIsUnitUser() || useIsBankUser()
	const [query, setQuery] = useQueryState("filter[query]", "")
	const [orgModes, setOrgModes] = useQueryState<OrgMode[]>("filter[mode]", [OrgMode.Standard])
	const extraFieldsSeparatedByCommas = compact([showDepositsData ? "orgBalancesSummary" : undefined]).join(",")
	const extraFields = !isEmpty(extraFieldsSeparatedByCommas) ? extraFieldsSeparatedByCommas : undefined
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		PAGE_SIZE,
		(offset, limit) => findOrgsWithPaginationMeta(accessToken, offset, limit, false, query, orgModes, extraFields),
		(x) => x.data.length,
		[query, orgModes.join(",")]
	)
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Orgs", 500, onSearch)
	const orgModeOptions = new Map([OrgMode.Standard, OrgMode.Pilot].map((v) => [v, v]))

	const modeFilter = (
		<Filter
			statuses={orgModes}
			setStatuses={setOrgModes}
			onFilterFunc={() => reset(PAGE_SIZE)}
			options={orgModeOptions}
			title="Mode"
		/>
	)

	const SandBoxClientButton = () => {
		if (!(isSandboxEnv() || isLocalEnv())) {
			return null
		}

		return (
			<button className="button button-create" onClick={() => navigate("/sandbox-client/new")}>
				{" "}
				<Icon icon="interface-add-1" size={12} /> Create Sandbox Client
			</button>
		)
	}

	const CreateNewOrgButton = () => {
		return (
			<button className="button button-create" onClick={() => navigate("/orgs/new")}>
				<Icon icon="interface-add-1" size={12} /> Create
			</button>
		)
	}

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(PAGE_SIZE)
	}

	return (
		<>
			<TitleBar title={"Orgs"}>
				<HasPermission resource="org" action="create">
					<CreateNewOrgButton />
				</HasPermission>
				<HasPermission resource="org" action="create">
					<SandBoxClientButton />
				</HasPermission>
			</TitleBar>

			<MainSectionTable>
				<DataTableCard className={"orgs-card"}>
					<DataTableActionHeader enableSticky={true} searchBox={searchBox} filters={[modeFilter]} />

					<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
						{({value: orgs}) => {
							const rows = (orgs as {data: Org[]}).data.map((o) => (
								<OrgTableRow key={o.id} org={o} showDepositsData={showDepositsData} />
							))

							return (
								<>
									<DataTable
										stickyAction={true}
										fullHeight={true}
										isEmpty={rows.length === 0}
										noContentText={"No orgs found"}
									>
										<DataTableHead>
											<tr>
												<th>Id</th>
												<th>Name</th>
												{showDepositsData && <th>Deposits</th>}
												<th>Created At</th>
											</tr>
										</DataTableHead>
										<DataTableBody>{rows}</DataTableBody>
									</DataTable>
									<PagingNavBar
										hasResults={hasResults}
										hasPrev={hasPrev}
										hasNext={hasNext}
										prev={prev}
										next={next}
										meta={(orgs as {meta: Meta}).meta}
									/>
								</>
							)
						}}
					</AsyncResultComponent>
				</DataTableCard>
			</MainSectionTable>
		</>
	)
}
