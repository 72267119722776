import React from "react"
import HorizontalField from "../HorizontalField/HorizontalField"
import classNames from "classnames"

const sources: {[key: string]: {[key: string]: any}} = {
	Socure30: {source: "Socure 30", description: "Sigma Fraud Score", low: [-Infinity, 0.33], high: [0.66, Infinity]},
	IDAnalyticsIDScore: {source: "ID Analytics", description: "ID Score", low: [-Infinity, 700], high: [850, Infinity]},
	Iovation: {source: "Iovation", description: "SureScore", low: [10000, Infinity], high: [-Infinity, -5001]},
	idTheftScore: {source: "Identity Theft", description: "SentiLink", low: [-Infinity, 899], high: [900, Infinity]},
}

function getSourceName(key: string) {
	return sources[key]["source"]
}

function getSourceDescription(key: string) {
	return sources[key]["description"]
}

export function getRiskLevel(key: string, value: number) {
	if (value >= sources[key]["low"][0] && value <= sources[key]["low"][1]) {
		return "low-risk"
	} else if (value >= sources[key]["high"][0] && value <= sources[key]["high"][1]) {
		return "high-risk"
	} else {
		return "moderate-risk"
	}
}

export default function EvaluationScores({scores}: {scores: Record<string, number>}) {
	return (
		<>
			{Object.keys(scores).map((k) => (
				<HorizontalField
					key={getSourceName(k)}
					label={getSourceName(k)}
					fieldClassName={"vertical-field-score"}
					showLabel={false}
				>
					<div className="scores">
						<div className="flag-pair">
							<div className={classNames("flag-icon", "flag-" + getRiskLevel(k, scores[k]))} />
						</div>
					</div>
					<div className="score-source">
						<label className="description">{getSourceDescription(k)}</label>
						<label className="source-name">{getSourceName(k)}</label>
					</div>
					<div className="score-value">
						<span />
						{scores[k]}
						<span />
					</div>
				</HorizontalField>
			))}
		</>
	)
}
