import React from "react"
import AuditLogDetailsHeader from "../AuditLogDetailsHeader/AuditLogDetailsHeader"
import AuditLogDetailsBox from "../AuditLogDetailsBox/AuditLogDetailsBox"
import {isEmpty} from "lodash"
import EmptyStateGraphics from "../Timeline/EmptyStateGraphics"
import {Accordion} from "../Accordion/Accordion"
import classNames from "classnames"
import {UserRole, UserType} from "../Users/types"

export type PatchPayload = {
	attributeName?: string
	newValue?: string
	oldValue?: string
	valueType?: string
}

export type AuditLogEventChanges = {
	patch?: PatchPayload
}

export type AuditLogEvent = {
	intent: string
	changes?: AuditLogEventChanges[]
	originalIndex: number
}

export type AuditLogEventsByUserAndTimestamp = {
	actorUserRole: string
	actorUserName?: string
	actorUserType: string
	actionTimestamp: string
	events: AuditLogEvent[]
}

export interface AuditLogEventsViewProps {
	auditLogEventsByUserAndTimestamp: AuditLogEventsByUserAndTimestamp[]
	loading?: boolean
	shouldAnimateEvent?: (index: number) => boolean
}
export function AuditLogEventsView({
	auditLogEventsByUserAndTimestamp,
	loading,
	shouldAnimateEvent = () => false,
}: AuditLogEventsViewProps) {
	if (loading) {
		return (
			<div className="audit-log-events-timeline">
				{Array.from(Array(2).keys()).map((index) => (
					<div key={index} className="audit-log-events-grouping">
						<div className="timeline-header">
							<div className="circle" />
							<AuditLogDetailsHeader loading />
						</div>
						<ul className="timeline-content">
							{Array.from(Array(4).keys()).map((_, index) => (
								<li key={index} className="timeline-content-item">
									<Accordion title="loading" loading theme="full" />
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		)
	}
	return isEmpty(auditLogEventsByUserAndTimestamp) ? (
		<div className="audit-log-events-empty-state">
			<EmptyStateGraphics />
			<span>No records available yet</span>
		</div>
	) : (
		<div className="audit-log-events-timeline">
			{auditLogEventsByUserAndTimestamp.map((groupedEvents, index) => (
				<div key={index} className="audit-log-events-grouping">
					<div
						className={classNames(
							"timeline-header",
							shouldAnimateEvent(groupedEvents.events[0].originalIndex) && "fade-in-from-bottom"
						)}
					>
						<div className="circle" />
						<AuditLogDetailsHeader
							timestamp={new Date(groupedEvents.actionTimestamp)}
							userRole={groupedEvents.actorUserRole as UserRole}
							userType={groupedEvents.actorUserType as UserType}
							userName={groupedEvents.actorUserName}
						/>
					</div>
					<ul className="timeline-content">
						{groupedEvents.events.map(({intent, changes, originalIndex}: AuditLogEvent, summaryIndex: number) => (
							<li
								key={summaryIndex}
								className={classNames(
									"timeline-content-item",
									shouldAnimateEvent(originalIndex) && "fade-in-from-bottom"
								)}
							>
								<AuditLogDetailsBox title={intent} changes={changes} />
							</li>
						))}
					</ul>
				</div>
			))}
		</div>
	)
}
export default AuditLogEventsView
