import React from "react"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"
import ReactTooltip from "react-tooltip"

interface AuditLogEventsHeaderProps {
	meta: AuditLogEventsHeaderMetaItemProps[]
}

export interface AuditLogEventsHeaderMetaItemProps {
	text?: string | JSX.Element
	icon?: IcomoonIconName
	divider?: boolean
	tooltipText?: string
}

function AuditLogEventsHeaderMetaTag({text, icon, divider, tooltipText}: AuditLogEventsHeaderMetaItemProps) {
	return (
		<div className={"audit-log-events-header-meta-item"}>
			{icon && <Icon icon={icon} size={12} />}
			<span data-tip={tooltipText} data-for={`meta-tooltip-for-${text}`} className="meta-text-container">
				{text}
			</span>
			<ReactTooltip id={`meta-tooltip-for-${text}`} place="top" effect="solid" className="meta-item-tooltip" />
			{divider && <div className={"round-divider"} />}
		</div>
	)
}

export function AuditLogEventsHeader({meta}: AuditLogEventsHeaderProps) {
	const content = meta.map(({text, icon, tooltipText}, i) => {
		const key = typeof text === "string" ? text : `meta-item-${i}`
		return (
			<AuditLogEventsHeaderMetaTag
				key={key}
				text={text}
				icon={icon}
				tooltipText={tooltipText}
				divider={i !== meta.length - 1}
			/>
		)
	})
	return (
		<GridContainer rows={2} columns={1} gap={"0"}>
			<div className="audit-log-events-header-container">
				<h1 className="audit-log-events-header-title">Audit Log</h1>
				<div className="audit-log-events-header-meta-container">{content}</div>
			</div>
		</GridContainer>
	)
}

export default AuditLogEventsHeader
