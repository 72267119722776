import {useAccessToken, useIsOrgUnitPilot, useUserType} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import {changeUserStatus, findUsers, User} from "../../resources/user"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {ReactElement, useEffect} from "react"
import moment from "moment"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {OrgName} from "../OrgName/OrgName"
import {startCase} from "lodash"
import {useNavigate} from "react-router-dom"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {Filter} from "../Filter/Filter"
import {useQueryState} from "use-location-state"
import classNames from "classnames"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import {getRoleDisplayName} from "./UserRoleField"
import {UnitPilotLimitsNotice} from "../UnitPilotLimitsNotice/UnitPilotLimitsNotice"
import SearchBox from "../SearchBox/SearchBox"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"
import Icon from "../Icon/Icon"
import {hasPermission} from "../../services/permission"
import {useModal} from "react-modal-hook"
import {DisableUserModal} from "./DisableUserModal"

export enum UsersColumns {
	id = "Id",
	org = "Org",
	name = "Name",
	type = "Type",
	role = "Role",
	createdAt = "Created At",
	actions = "Actions",
}

type AllowedUsersColumns =
	| UsersColumns.id
	| UsersColumns.org
	| UsersColumns.name
	| UsersColumns.type
	| UsersColumns.role
	| UsersColumns.createdAt
	| UsersColumns.actions

interface UsersProps {
	token?: string
	limit?: number
	includedColumns: AllowedUsersColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	enableOrgFilter?: boolean
	refreshToken?: number
}

interface UsersTableProps {
	users: User[]
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedUsersColumns[]
	meta?: Meta
	refresh: () => void
	fullHeight?: boolean
	refreshToken?: number
}

interface UsersRowProps {
	user: User
	include?: Resource[]
	includedColumns: Array<AllowedUsersColumns>
	refresh: () => void
}

function UserRow({user, include, includedColumns, refresh}: UsersRowProps) {
	const navigate = useNavigate()
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const isDisabled = user.attributes.isDisabled
	const [statusChangeState, changeStatus] = useAsyncResultIdle(changeUserStatus)
	const [showDisableModal, hideDisableModal] = useModal(() => (
		<DisableUserModal close={hideDisableModal} refresh={refresh} user={user} />
	))

	useEffect(() => {
		statusChangeState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(e.errors[0].title)
		)
	}, [statusChangeState])

	const contentColumns: Partial<Record<AllowedUsersColumns, ReactElement>> = {}

	if (includedColumns.includes(UsersColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {user.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(UsersColumns.org)) {
		contentColumns["Org"] =
			user.type === "orgUser" ? (
				<OrgName orgId={user.relationships.org.data.id.toString()} included={include} />
			) : (
				<DataTableCell />
			)
	}

	if (includedColumns.includes(UsersColumns.name)) {
		contentColumns["Name"] = (
			<DataTableCell>
				{user.attributes.username}
				{isDisabled ? (
					<span className="tag is-light ml-2 has-background-grey-lighter has-text-grey">Disabled</span>
				) : null}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(UsersColumns.type)) {
		contentColumns["Type"] = <DataTableCell> {startCase(user.type)} </DataTableCell>
	}

	if (includedColumns.includes(UsersColumns.role)) {
		contentColumns["Role"] = <DataTableCell> {getRoleDisplayName(user.attributes.role)} </DataTableCell>
	}

	if (includedColumns.includes(UsersColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell> {moment(user.attributes.createdAt).format("L")} </DataTableCell>
	}

	if (includedColumns.includes(UsersColumns.actions)) {
		contentColumns["Actions"] = (
			<DataTableActionButton enableActions={true}>
				<DataTableActionItem
					title={isDisabled ? "Enable" : "Disable"}
					icon={isDisabled ? "user-geometric-action-check-approve" : "user-geometric-action-cross-remove"}
					onClick={() => (isDisabled ? changeStatus(accessToken, user.id, !isDisabled) : showDisableModal())}
				/>
			</DataTableActionButton>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			className={classNames(user.attributes.isDisabled && "has-text-grey-light")}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				navigate(`/user-details/${user.id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

export function CreateUserButton({showModal}: {showModal: () => void}) {
	const canCreateUser = hasPermission("user", "create")

	if (canCreateUser)
		return (
			<div>
				<button className="button button-create" onClick={() => showModal()}>
					<Icon icon="interface-add-1" size={12} /> Create
				</button>
			</div>
		)

	return null
}

export function UsersTable({
	users,
	fullHeight,
	includedColumns,
	hasNext,
	hasPrev,
	hasResults,
	isUsingPaging,
	include,
	refresh,
	meta,
	prev,
	next,
}: UsersTableProps) {
	const noContent = users.length === 0
	return (
		<div className={"users-table"}>
			<DataTable isEmpty={noContent} fullHeight={fullHeight} stickyAction={fullHeight} noContentText={"No users found"}>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{users.map((u) => (
						<UserRow key={u.id} user={u} includedColumns={includedColumns} include={include} refresh={refresh} />
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export default function Users({
	limit = 25,
	includedColumns,
	enableTitle,
	fullHeight,
	enableOrgFilter,
	refreshToken,
}: UsersProps) {
	const accessToken = useAccessToken()
	const userType = useUserType()
	const [_, refresh] = useRefreshToken()

	const [query, setQuery] = useQueryState("filter[query]", "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>("filter[orgs]", [])
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) => findUsers(accessToken, offset, limit, filteredOrgs, query),
		(x) => x.data.length,
		[refreshToken, _, filteredOrgs.join(","), query]
	)

	const orgs =
		userType === "unit"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Users", 500, onSearch)
	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	const filters = [...(enableOrgFilter ? [orgFilter] : [])]

	return (
		<DataTableCard className={"users-card"}>
			<DataTableActionHeader
				searchBox={searchBox}
				enableSticky={fullHeight}
				filters={filters.length === 0 ? null : filters}
				title={enableTitle ? "Users" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: users}) => {
					return (
						<>
							<UsersTable
								users={users.data}
								meta={users.meta}
								hasResults={hasResults}
								hasPrev={hasPrev}
								hasNext={hasNext}
								prev={prev}
								next={next}
								refresh={refresh}
								isUsingPaging={true}
								include={users.included}
								fullHeight={fullHeight}
								includedColumns={includedColumns}
							/>
							{useIsOrgUnitPilot() && users.data.length >= 1 ? (
								<UnitPilotLimitsNotice text={"Maximum 1 user is available in Unit pilot"} />
							) : null}
						</>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
