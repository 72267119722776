import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
import {Account} from "../../resources/account"
import {CheckPayment} from "../../resources/checkPayments"
import {Meta, Resource} from "../../resources/common"
import {Customer, CustomerResourceTypes} from "../../resources/customer"
import {useAccessToken} from "../../services/auth"
import {DataTable, DataTableBody, DataTableCell, DataTableHead, DataTableRow} from "../DataTable/DataTable"
import Drawer from "../Drawer/Drawer"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {CheckPaymentComponent} from "./CheckPayment"
import {CheckPaymentRow} from "./CheckPaymentRow"
import {AllowedCheckPaymentsColumns, CheckPaymentsColumns} from "./CheckPayments"

interface CheckPaymentsTableProps {
	checkPayments: CheckPayment[]
	selectedCheckPayment: string
	setSelectedCheckPayment: (id: string) => void
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedCheckPaymentsColumns[]
	meta?: Meta
	refresh: () => void
	fullHeight?: boolean
	sortFunction: () => void
	sortBy: string
	disableDrawer?: boolean
}

export function CheckPaymentsTable({
	checkPayments,
	selectedCheckPayment: selectedCheckPaymentId,
	setSelectedCheckPayment: setSelectedCheckPaymentId,
	fullHeight,
	includedColumns,
	hasNext,
	hasPrev,
	hasResults,
	isUsingPaging,
	include,
	refresh,
	meta,
	prev,
	next,
	sortFunction,
	disableDrawer = false,
	sortBy,
}: CheckPaymentsTableProps) {
	const navigate = useNavigate()
	const accessToken = useAccessToken()
	const noContent = checkPayments.length === 0
	const [isDrawerOpen, setIsDrawerOpen] = useState(!!selectedCheckPaymentId)
	const ref = useRef(null)

	const accounts = include && (include.filter(({type}) => type === "depositAccount") as Account[])
	const customers = include && (include.filter((i) => CustomerResourceTypes.includes(i.type)) as Customer[])

	const toggleDrawer = (reopenWithId = false) => {
		setIsDrawerOpen((prevState) => !prevState)
		if (reopenWithId) {
			setTimeout(() => {
				setIsDrawerOpen(true)
			}, 200)
		}
	}
	useEffect(() => {
		if (!selectedCheckPaymentId) {
			setIsDrawerOpen(false)
		}
	}, [selectedCheckPaymentId])

	return (
		<div ref={ref} className={"payments-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={"No check payments found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === CheckPaymentsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}

							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{checkPayments.map((checkPayment) => (
						<CheckPaymentRow
							checkPayment={checkPayment}
							key={checkPayment.id}
							include={include}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								if (!disableDrawer) {
									setSelectedCheckPaymentId(checkPayment.id)
									toggleDrawer(true)
									return
								}
								navigate(`/check-payments/${checkPayment.id}`)
							}}
							selectedCheckPaymentId={selectedCheckPaymentId}
							refresh={refresh}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>

			{!disableDrawer && (
				<Drawer
					open={isDrawerOpen}
					onClose={() => {
						toggleDrawer(false)
						setSelectedCheckPaymentId("")
					}}
					direction="right"
					className="check-deposits-side-drawer"
					size={"50%"}
					zIndex={33}
					blockBody={true}
					linkToCopy={`${window.location.origin}/check-payments/${selectedCheckPaymentId}`}
				>
					{isDrawerOpen && (
						<CheckPaymentComponent
							refresh={refresh}
							checkPaymentId={selectedCheckPaymentId}
							accessToken={accessToken}
							useDrawer={true}
							accounts={accounts}
							customers={customers}
						/>
					)}
				</Drawer>
			)}
		</div>
	)
}
