import {useAsyncResult} from "../../hooks/useAsyncResult"
import Skeleton from "react-loading-skeleton"
import {useAccessToken} from "../../services/auth"
import React, {ComponentClass, FunctionComponent} from "react"
import {getUser, User} from "../../resources/user"
import {ErrorDocument, OkDocument} from "../../resources/common"

type ElementProps<P> = Pick<Props<P> & P, Exclude<keyof P, "element" | "userId" | "onResult">>

interface Props<P> {
	userId: string
	element: FunctionComponent<ElementProps<P>> | ComponentClass<ElementProps<P>> | string
	onResult: (user: User) => JSX.Element
}

function UserNameInternal<P>({userId, element, onResult, ...props}: Props<P> & P) {
	const accessToken = useAccessToken()
	const result = useAsyncResult<OkDocument<User>, ErrorDocument>(() => getUser(accessToken, userId), [userId])

	return result.match(
		() => React.createElement<ElementProps<P>>(element, props, <Skeleton />),
		(user) => onResult(user.data),
		(_) => React.createElement<ElementProps<P>>(element, props, "Error")
	)
}

export function UserName<P extends {}>(props: Props<P> & P) {
	return <UserNameInternal {...props} />
}

UserName.defaultProps = {
	element: "td",
}
