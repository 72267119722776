import React, {ReactNode} from "react"
import classNames from "classnames"
import Skeleton from "react-loading-skeleton"

interface BoxProps {
	borderColor?: string
	backgroundColor?: string
	className?: string
	loading?: boolean
	children?: React.ReactNode
}

export function Box({className, borderColor, backgroundColor, loading, children}: BoxProps): JSX.Element {
	const classes = classNames("box-container", {skeleton: loading, "wh-100": loading}, className)
	const content = loading ? <Skeleton width="100%" height="100%" count={1} /> : children
	const style = {
		...(borderColor ? {borderColor} : {}),
		...(backgroundColor ? {backgroundColor} : {}),
	}
	return (
		<div className={classes} style={style}>
			{content}
		</div>
	)
}

export function BoxInfo({
	title,
	end,
	titleLast,
	className,
	children,
}: {
	title: string
	end?: boolean
	titleLast?: boolean
	className?: string
	children: ReactNode
}) {
	const boxInfoTitle = (
		<p key="box-info-title" className={classNames("flex-container box-info-title", {"justify-content-end": end})}>
			{title}
		</p>
	)
	const boxInfoValue = (
		<div key="box-info-children" className="flex-container box-info-value font-24 font-bold line-h-1-2">
			{children}
		</div>
	)
	const content = [boxInfoTitle, boxInfoValue]

	return (
		<div className={classNames("flex-container flex-dir-col", className)}>
			{titleLast ? content.reverse() : content}
		</div>
	)
}
