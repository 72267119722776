import React, {Dispatch, InputHTMLAttributes, PropsWithChildren, useCallback} from "react"
import {CA_COUNTRY_CODE, caStates, US_COUNTRY_CODE, usStates} from "../../resources/address"

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
	countryValue: string
	value: string | undefined
	onChange: Dispatch<any>
}

export default function StateDropdown({value, countryValue, onChange, className}: PropsWithChildren<Props>) {
	const getRelevantStates = useCallback(() => {
		const statesToMap = countryValue == US_COUNTRY_CODE ? usStates : countryValue == CA_COUNTRY_CODE ? caStates : {}
		return Object.keys(statesToMap).map((state) => (
			<option key={state} value={state}>
				{statesToMap[state]}
			</option>
		))
	}, [countryValue])

	return (
		<select required className={className} value={value} onChange={onChange}>
			<option key="default" defaultValue="" />
			{getRelevantStates()}
		</select>
	)
}
