import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import Admonition from "../Admonitions/Admonition"
import {returnCheckPayment} from "../../resources/checkPayments"

interface ReturnCheckModalProps {
	id: string
	closeModal: () => void
	onSuccess: () => void
}

const reasons = {
	"Stop Payment": "StopPayment",
	"Frozen Or Blocked Account": "FrozenAccount",
	"Stale Dated": "StaleDated",
	"Post Dated": "PostDated",
	"Not Valid Check Or Cash Item": "NotValidCheckOrCashItem",
	"Altered Or Fictitious": "AlteredOrFictitious",
	"Item Exceeds Dollar Limit": "ItemExceedsDollarLimit",
	"Not Authorized": "NotAuthorized",
	"Refer to Maker": "ReferToMaker",
	"Unusable Image": "UnusableImage",
	"Duplicate Presentment": "DuplicatePresentment",
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ReturnCheckPaymentModal({id, closeModal, onSuccess}: ReturnCheckModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState(reasons["Altered Or Fictitious"])
	const [rejectedState, setReturnedState] = useAsyncResultIdle(returnCheckPayment)

	return (
		<AsyncResultModal
			title={`You Are About to Return Check ID #${id}`}
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-danger"
			state={rejectedState}
			buttonText="Mark as returned"
			successText="Check is returned"
			onSubmit={() => {
				setReturnedState({id, accessToken, reason})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<div className="select">
						<select onChange={(e) => setReason(e.target.value)} value={reason}>
							{Object.entries(reasons).map((reason) => {
								return (
									<option key={reason[1]} value={reason[1]}>
										{reason[1]} - {reason[0]}
									</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
			<Admonition
				type={"is-danger"}
				message={
					<p>
						{
							"Marking a check payment as returned is a final action and cannot be undone. The check will be returned and no funds will be sent to the bank that deposited the check."
						}
					</p>
				}
				title={"Warning"}
			/>
		</AsyncResultModal>
	)
}
