import React from "react"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import Skeleton from "react-loading-skeleton"
import {startCase} from "lodash"
import {TagsViewerKeyOnly} from "../Tags/TagsViewerKeyOnly"
import {getTypeAndRoleCombined} from "../Users/UserRoleField"
import {UserRole, UserType} from "../Users/types"

export interface AuditLogDetailsHeaderProps {
	timestamp?: Date
	userRole?: UserRole
	userType?: UserType
	userName?: string
	loading?: boolean
}

export function AuditLogDetailsHeader({timestamp, userRole, userType, userName, loading}: AuditLogDetailsHeaderProps) {
	const formatDate = (inputDate: Date): {formattedDate: string; formattedTime: string} => {
		const dateOptions: Intl.DateTimeFormatOptions = {year: "numeric", month: "short", day: "numeric"}
		const timeOptions: Intl.DateTimeFormatOptions = {hour: "2-digit", minute: "2-digit", hour12: true}

		const date = new Date(inputDate)
		const formattedDate = date.toLocaleString("en-US", dateOptions)
		const formattedTime = date.toLocaleString("en-US", timeOptions)

		return {formattedDate, formattedTime}
	}

	let formattedDate, formattedTime
	if (!!timestamp) {
		;({formattedDate, formattedTime} = formatDate(timestamp))
	}
	const userRoleWithUserType = userType && userRole ? startCase(getTypeAndRoleCombined(userType, userRole)) : ""
	return loading ? (
		<div className="is-flex is-justify-content-space-between is-align-items-baseline audit-log-details-header-full-width">
			<Skeleton width="150px" />
			<Skeleton width="150px" />
		</div>
	) : (
		<div className="is-flex is-justify-content-space-between is-align-items-baseline audit-log-details-header-full-width">
			<div className="audit-log-details-header-date-container">
				{formattedDate && <span className="audit-log-details-header-date">{formattedDate}</span>}
				{formattedTime && <span className="audit-log-details-header-time">{formattedTime}</span>}
			</div>
			{userName ? (
				<TagsViewerKeyValue tags={{[`${userRoleWithUserType} User`]: userName}} size="small" noMargin light />
			) : (
				<TagsViewerKeyOnly keys={[`${userRoleWithUserType} User`]} size="small" noMargin />
			)}
		</div>
	)
}
export default AuditLogDetailsHeader
