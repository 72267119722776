import React, {ReactElement, useEffect} from "react"
import {
	useAccessToken,
	useIsOrgUser,
	useIsUnitTypeUser,
	useIsUnitUser,
	useUserClaimsData,
	useUserType,
} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {Customer, CustomerStatus, CustomerType, exportCustomers, findCustomers} from "../../resources/customer"
import {useQueryState} from "use-location-state"
import SearchBox from "../../components/SearchBox/SearchBox"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import {Filter} from "../Filter/Filter"
import moment from "moment"
import {useToasts} from "react-toast-notifications"
import {downloadFile} from "../../utilities/file"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import classNames from "classnames"
import {useNavigate} from "react-router-dom"
import {OrgName} from "../OrgName/OrgName"
import {kebabCase, startCase} from "lodash"
import Icon from "../Icon/Icon"
import {isProdEnv, isSandboxEnv} from "../../utilities/environment"
import ReactTooltip from "react-tooltip"

export enum CustomersColumns {
	id = "Id",
	type = "Type",
	org = "Org",
	status = "Status",
	name = "Name",
	createdAt = "Created At",
}

type AllowedCustomersColumns =
	| CustomersColumns.id
	| CustomersColumns.type
	| CustomersColumns.org
	| CustomersColumns.status
	| CustomersColumns.name
	| CustomersColumns.createdAt

interface CustomersProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedCustomersColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	refreshToken?: number
}

interface CustomersTableProps {
	customers: Array<Customer>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedCustomersColumns>
	meta?: Meta
	isFiltering?: boolean
	fullHeight?: boolean
	sortFunction: () => void
	sortBy: string
}

interface CustomerRowProps {
	customer: Customer
	include?: Resource[]
	includedColumns: Array<AllowedCustomersColumns>
}

const customerTypeToFilterOptions: Record<CustomerType, string> = {
	[CustomerType.individual]: "Individual Customer",
	[CustomerType.business]: "Business Customer",
	[CustomerType.businessFBO]: "Business FBO Customer",
	[CustomerType.individualWallet]: "Individual Wallet Customer",
}

const WALLET_ALLOWED_ORG_ID = "4299"
const isOrgUserForWallet = () => {
	const orgId = useUserClaimsData().orgId
	return useIsOrgUser() && isSandboxEnv() && orgId === WALLET_ALLOWED_ORG_ID
}
function filterCustomerTypesByUserType(): CustomerType[] {
	if (useIsUnitUser()) {
		return Object.values(CustomerType)
	}
	if (isOrgUserForWallet()) {
		return [CustomerType.individual, CustomerType.business, CustomerType.individualWallet]
	}
	return [CustomerType.individual, CustomerType.business]
}
function CustomerRow({customer, include, includedColumns}: CustomerRowProps) {
	const navigate = useNavigate()

	const id = customer.id
	const org = customer.relationships.org?.data.id
	const status = customer.attributes.status
	const createdAt = customer.attributes.createdAt
	let name = ""
	let typeIcon: JSX.Element | null = null
	let typeLink = ""

	switch (customer.type) {
		case "businessCustomer":
			name = customer.attributes.name
			typeIcon = <Icon icon={"toolbox"} />
			typeLink = "business"
			break
		case "individualCustomer":
			name = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
			typeIcon = <Icon icon={"user-geometric-action---users"} />
			typeLink = "individual"
			break
		case "businessFBOCustomer":
			name = customer.attributes.name
			typeIcon = <Icon icon={"toolbox"} />
			typeLink = "businessFBO"
			break
		case "individualWalletCustomer":
			name = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
			typeIcon = <Icon icon={"user-geometric-action---users"} />
			typeLink = "individualWallet"
	}

	const contentColumns: Partial<Record<AllowedCustomersColumns, ReactElement>> = {}

	if (includedColumns.includes(CustomersColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className={classNames("data-table-id")}>{id}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CustomersColumns.type)) {
		contentColumns["Type"] = (
			<DataTableCell>
				<span className="icon">{typeIcon}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CustomersColumns.org)) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} element={DataTableCell} />
	}

	if (includedColumns.includes(CustomersColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("customers-status", kebabCase(status))}>{startCase(status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CustomersColumns.name)) {
		contentColumns["Name"] = <DataTableCell>{name}</DataTableCell>
	}

	if (includedColumns.includes(CustomersColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})
	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/${typeLink}/${id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function CustomersTable({
	customers,
	includedColumns,
	hasPrev,
	hasNext,
	prev,
	next,
	hasResults,
	isUsingPaging,
	include,
	meta,
	isFiltering,
	fullHeight,
	sortFunction,
	sortBy,
}: CustomersTableProps) {
	const tutorialCallButton = document.querySelector(".tutorial-call-button")
	const noContent = customers.length === 0

	useEffect(() => {
		if (!noContent && tutorialCallButton) {
			tutorialCallButton.classList.add("tutorial-call-button-with-animation")
		}
	}, [])

	return (
		<div className={"customers-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={
					<>
						{!isFiltering ? (
							<>
								No{" "}
								<a className="doc-link" href="https://docs.unit.co/#applications" target="_blank" rel="noreferrer">
									customers
								</a>{" "}
								yet <br /> <br />
								<span className={"no-context-explanation"}>
									{" "}
									Once applications are created and approved, customers will automatically be created{" "}
								</span>
							</>
						) : (
							"No customers found"
						)}
					</>
				}
				noContentCtaButton={
					!isProdEnv() &&
					!isFiltering &&
					!useIsUnitTypeUser() && (
						<button
							className="tutorial-cta-button"
							data-tracking-label="tutorial-customers-cta"
							onClick={(e) => {
								const target = e.target as HTMLButtonElement
								target.classList.add("tutorial-cta-button-was-used")
								if (tutorialCallButton) {
									ReactTooltip.show(tutorialCallButton)
								}
							}}
						>
							<Icon icon={"programming-code--programing-apps-websites"} color="white" />
							Test Create Customer API
						</button>
					)
				}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === CustomersColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{customers.map((customer) => (
						<CustomerRow key={customer.id} customer={customer} includedColumns={includedColumns} include={include} />
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export default function Customers({
	limit = 25,
	includedColumns,
	enableTitle,
	fullHeight,
	refreshToken,
}: CustomersProps) {
	const prefix = "cust"
	const userType = useUserType()
	const {addToast} = useToasts()
	const accessToken = useAccessToken()
	const statusFilterOptions = new Map([CustomerStatus.Active, CustomerStatus.Archived].map((v) => [v, v]))
	const typeFilterOptions = new Map(
		filterCustomerTypesByUserType().map((type) => [type, customerTypeToFilterOptions[type]])
	)
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [statuses, setStatuses] = useQueryState<CustomerStatus[]>(`${prefix}-filter[status]`, [CustomerStatus.Active])
	const [filteredTypes, setFilteredTypes] = useQueryState<CustomerType[]>(`${prefix}-filter[types]`, [])
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) => findCustomers(accessToken, offset, limit, sortBy, query, filteredOrgs, statuses, filteredTypes),
		(x) => x.data.length,
		[sortBy, query, filteredOrgs.join(","), statuses.join(","), refreshToken, filteredTypes.join(",")],
		prefix,
		true
	)
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Customers", 500, onSearch)

	const isFiltering = filteredOrgs.length != 0 || query != ""
	const orgs =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusFilterOptions}
			title="Status"
		/>
	)

	const typeFilter = (
		<Filter
			statuses={filteredTypes}
			setStatuses={setFilteredTypes}
			onFilterFunc={() => reset(limit)}
			options={typeFilterOptions}
			title="Type"
		/>
	)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	async function exportCustomersFunc() {
		const response = await exportCustomers(accessToken, sortBy, query, filteredOrgs, filteredTypes)

		response.match(
			(d) => downloadFile("Customers.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)

		return true
	}

	return (
		<DataTableCard className={"customers-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={searchBox}
				exportFunc={exportCustomersFunc}
				filters={[typeFilter, orgFilter, statusFilter]}
				title={enableTitle ? "Customers" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: customers}) => {
					return (
						<CustomersTable
							fullHeight={fullHeight}
							isFiltering={isFiltering}
							customers={customers.data}
							include={customers.included}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={true}
							includedColumns={includedColumns}
							meta={customers.meta}
							sortFunction={toggleSort}
							sortBy={sortBy}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
