import React from "react"
import {getBatchAccounts, getCustomerAccounts} from "../../resources/account"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Err} from "../Err/Err"
import {AsyncResult} from "../../types/asyncResult"
import {Accounts, Pending} from "./OrgAccountsSummary"
import {useAccessToken} from "../../services/auth"

export default function OrgAndCustomerAccountsSummary({
	orgId,
	customerId,
	customerAccountsAccessToken,
	showIcon,
}: {
	orgId: string
	customerId: string
	customerAccountsAccessToken: string
	showIcon?: boolean
}) {
	const accessToken = useAccessToken()
	const customerAccounts = useAsyncResult(() => getCustomerAccounts(customerAccountsAccessToken, 0, 10, customerId))
	const orgAccounts = useAsyncResult(() => getBatchAccounts(accessToken, 0, 100, orgId))

	return AsyncResult.zip(customerAccounts, orgAccounts).match(
		() => <Pending />,
		([customerAccounts, orgAccounts]) => (
			<Accounts
				accessToken={accessToken}
				accounts={[...customerAccounts, ...orgAccounts.data]}
				showIcon={showIcon}
				orgId={orgId}
			/>
		),
		(err) => <Err err={err} />
	)
}
