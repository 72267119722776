import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {isWirePayment, Payment, rejectPayment} from "../../resources/payment"

interface RejectPaymentModalProps {
	payment: Payment
	close: () => void
	onSuccess: () => void
}

function Reason({reason, setReason, isWire}: {reason: string; setReason: (reason: string) => void; isWire: boolean}) {
	return (
		<div className="field">
			<label className="label">Reason</label>
			<div className="select">
				<select
					value={reason}
					onChange={(e) => {
						setReason(e.target.value)
					}}
				>
					<option value="SuspectedFraud">Suspected Fraud</option>
					<option value="ClientRequest">Client Request</option>
					<option value="InsufficientFunds">Insufficient funds</option>
					<option value="CounterpartyInsufficientFunds">Counterparty Insufficient funds</option>
					<option value="PlaidTokenError">Plaid Token Error</option>
					<option value="NameMismatch">Name Mismatch</option>
					<option value="PlaidTokenExpired">Plaid Token Expired</option>
					<option value="PlaidNotSupported">Plaid Not Supported</option>
					{isWire ? (
						<>
							<option key="WireRejected">Wire Rejected</option>
							<option key="InvalidRoutingNumber">Invalid Routing Number</option>
						</>
					) : null}
				</select>
			</div>
		</div>
	)
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function RejectPaymentModal({payment, close, onSuccess}: RejectPaymentModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState("SuspectedFraud")
	const [state, deny] = useAsyncResultIdle(rejectPayment)
	const isWire = isWirePayment(payment)

	return (
		<AsyncResultModal
			title="Reject Payment"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Reject"
			successText="Rejected"
			onSubmit={() => deny(accessToken, payment.id, reason)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<Reason reason={reason} setReason={setReason} isWire={isWire} />
			</div>
		</AsyncResultModal>
	)
}
