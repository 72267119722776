import React, {PropsWithChildren} from "react"
import Icon from "../Icon/Icon"
import classNames from "classnames"

interface ConfirmationModalProps {
	classname?: string
	title: string
	okButtonText: string
	okButtonClassname?: string
	cancelButtonText: string
	cancelButtonClassname?: string
	onSubmit: () => void
	close: () => void
}

export function ConfirmationModal(props: PropsWithChildren<ConfirmationModalProps>) {
	return (
		<div className={classNames("modal", "is-active", "confirmation-modal", props.classname)}>
			<div className="modal-background" />
			<form
				onSubmit={(e) => {
					e.preventDefault()
					props.onSubmit()
					props.close()
				}}
			>
				<div className="modal-card">
					<header className="modal-card-head">
						<label className="delete" onClick={() => props.close()}>
							{" "}
							<Icon icon="interface-delete-interface-essential" size={16} />
						</label>
					</header>
					<section className="modal-card-body">
						<h3 className="modal-card-title confirmation-modal-card-title">{props.title}</h3>
						<fieldset>{props.children}</fieldset>
					</section>
					<footer className="modal-card-foot">
						<input
							type="button"
							className={props.cancelButtonClassname}
							value={props.cancelButtonText}
							onClick={props.close}
						/>
						<input type="submit" className={props.okButtonClassname} value={props.okButtonText} />
					</footer>
				</div>
			</form>
		</div>
	)
}

ConfirmationModal.defaultProps = {
	buttonDisabled: false,
	okButtonText: "OK",
	okButtonClassname: "button is-success mr-2",
	cancelButtonText: "Cancel",
	cancelButtonClassname: "button has-background-grey-lighter",
}
