import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {getClientDomainConfig, verifyDomain, DnsRecords, CertificateStatus} from "../../resources/bankSettings"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {ErrorDocument} from "../../resources/common"
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import DnsRecordsView from "../../components/OrgAndBankSettings/DnsRecords"

function ClientDomainConfigInternal({
	dnsRecords,
	refresh,
	status,
}: {
	dnsRecords: DnsRecords
	refresh: () => void
	status: CertificateStatus
}) {
	const [isLoading, setIsLoading] = useState(false)
	const [verifyDomainState, updateVerifyDomain] = useAsyncResultIdle(verifyDomain)

	useEffect(() => {
		verifyDomainState.match(
			() => setIsLoading(false),
			() => setIsLoading(true),
			() => {
				setIsLoading(false)
				refresh()
			},
			() => setIsLoading(false)
		)
	}, [verifyDomainState])

	const dnsRecordsConverted = [
		{key: dnsRecords.attributes.domain.key, value: dnsRecords.attributes.domain.value},
		{key: dnsRecords.attributes.certificate.key, value: dnsRecords.attributes.certificate.value},
	]

	const errorMessage = verifyDomainState.isErr()
		? [verifyDomainState.error.errors[0].title, verifyDomainState.error.errors[0].detail]
				.filter((message) => message !== undefined)
				.join(",")
		: undefined

	return (
		<DnsRecordsView
			status={status}
			dnsRecords={dnsRecordsConverted}
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			verifyDomain={updateVerifyDomain}
			errorMessage={errorMessage}
		/>
	)
}

function ClientDomainConfigError({retry}: {retry: () => void}) {
	return (
		<div className="field">
			<div className="box">
				<span className="has-text-weight-bold">
					DNS Settings - <span className="has-text-warning">Failed to retrieve data</span>
				</span>
				<p className="mt-2 mb-2">Please try again</p>

				<button
					type="button"
					className="button is-success"
					onClick={(e) => {
						e.preventDefault()
						retry()
					}}
				>
					Retry
				</button>
			</div>
		</div>
	)
}

function ClientDomainConfigPending() {
	return (
		<div className="field">
			<div className="box">
				<span className="has-text-weight-bold">
					DNS Settings -{" "}
					<span>
						<FontAwesomeIcon className="card-icon" icon={faCircleNotch} spin />
					</span>
				</span>
			</div>
		</div>
	)
}

type RefreshFunc = () => void

interface ClientDomainConfigProps {
	refresh: RefreshFunc
	status: CertificateStatus
}

export default function ClientDomainConfig({refresh, status}: ClientDomainConfigProps) {
	const accessToken = useAccessToken()
	const [retryToken, retry] = useRefreshToken()
	const dnsRecordsAsync = useAsyncResult<DnsRecords, ErrorDocument>(
		() => getClientDomainConfig(accessToken),
		[retryToken]
	)

	return dnsRecordsAsync.match(
		() => <ClientDomainConfigPending />,
		(dnsRecords) => <ClientDomainConfigInternal refresh={refresh} dnsRecords={dnsRecords} status={status} />,
		(_) => <ClientDomainConfigError retry={retry} />
	)
}
