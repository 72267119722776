import React, {ChangeEvent} from "react"
import {AssigneeAvatar} from "../AssigneeAvatar/AssigneeAvatar"
import {Assignee} from "../../resources/assignments"
export interface TextAreaWithAssigneeAvatarProps {
	textValue: string
	setTextValue: React.Dispatch<React.SetStateAction<string>>
	assignees: Assignee[]
	placeholder?: string
	maxLength?: number
	disabled?: boolean
}

export function TextAreaWithAssigneeAvatar({
	textValue,
	setTextValue,
	assignees,
	placeholder,
	maxLength,
	disabled,
}: TextAreaWithAssigneeAvatarProps) {
	const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setTextValue(e.target.value)
	}
	return (
		<div className="text-area-with-avatar-container">
			<AssigneeAvatar assignees={assignees} size="medium" />
			<textarea
				className="text-area-with-avatar-textarea textarea has-fixed-size"
				rows={4}
				value={textValue}
				onChange={handleTextChange}
				placeholder={disabled ? undefined : placeholder}
				maxLength={maxLength}
				disabled={disabled}
			/>
		</div>
	)
}
