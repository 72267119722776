import React, {useEffect, useRef, useState} from "react"
import TitleBar, {TitleBarAction} from "../../components/TitleBar/TitleBar"
import {useAccessToken, useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import {MainSection} from "../../containers/MainSection/MainSection"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import moment from "moment"
import {
	CustomerStatus,
	CustomerType,
	CustomerTypeIcons,
	IndividualCustomer,
	updateIndividualCustomer,
} from "../../resources/customer"
import Card from "../../containers/Card/Card"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import TagsViewer from "../../components/Tags/TagsViewer"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {EditableRiskRateField, RiskRateField} from "../../components/RiskRateField/RiskRateField"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import {
	Editable,
	EditableAddress,
	EditableDate,
	EditableFullName,
	EditableHiddenString,
	EditableOptionalString,
	EditablePhone,
	OptionalEditable,
} from "../../components/HorizontalField/EditableHorizontalField"
import {isUndefined, startCase} from "lodash"
import {emailPreview} from "../../components/InformationHiding/InformationHiding"
import {hasPermission} from "../../services/permission"
import {AuthorizedUsers} from "../CustomerAuthorizedUsers/CustomerAuthorizedUsers"
import CustomerKnownNamesComponent from "../../components/CustomerKnownNames/CustomerKnownNames"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {CustomerKnownNames, getCustomerKnownNames} from "../../resources/customerKnownNames"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import CustomerCounterparties from "../../components/CustomerCounterParties/CustomerCounterparties"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {useModal} from "react-modal-hook"
import {ArchiveCustomerModal} from "../Customer/ArchiveCustomerModal"
import {UnarchiveCustomerModal} from "../Customer/UnarchiveCustomerModal"
import {Disputes, DisputesColumns} from "../../components/Disputes/Disputes"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import {TutorialModalProps} from "../../components/Tutorial/TutorialModal"
import {generateCreateAccountRequest} from "../../components/Tutorial/TutorialPayloads"
import {TutorialButton} from "../../components/Tutorial/TutorialButton"
import IdentificationNumber, {getDocumentTitle} from "../../components/IdentificationNumber/IdentificationNumber"
import {isOrg, Org} from "../../resources/org"
import {Application, getApplication, IndividualApplication} from "../../resources/application"
import {IndividualAdditionalInformationFields} from "../../components/IndividualAdditionalInformationFields/IndividualAdditionalInformation"
import {AsyncResult} from "../../types/asyncResult"
import {SpecificCustomerProps} from "../Customer/Customer"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import {getAuditLogEventsHeaderMetaItems} from "../Customer/auditLogMetaItems"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"

export function Individual({value: customer, refresh}: SpecificCustomerProps<IndividualCustomer>) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const individual = customer.data
	const {attributes} = individual
	const name = `${attributes.fullName.first} ${attributes.fullName.last}`
	const documentTitle = getDocumentTitle(
		attributes.ssn,
		attributes.maskedSSN,
		attributes.passport,
		attributes.maskedPassport,
		attributes.matriculaConsular,
		attributes.maskedMatriculaConsular
	)
	const org = customer.included?.find((r) => isOrg(r) && r.id == individual.relationships.org.data.id)
	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)
	const [ein, setEin] = useState(attributes.ein)
	const [dba, setDba] = useState(attributes.dba)
	const [dob, setDob] = useState(attributes.dateOfBirth ? moment(attributes.dateOfBirth).toDate() : undefined)
	const [address, setAddress] = useState(attributes.address)
	const [phone, setPhone] = useState(attributes.phone)
	const [email, setEmail] = useState(attributes.email)
	const [riskRate, setRiskRate] = useState(attributes.riskRate)
	const [overrideRiskRate, setOverrideRiskRate] = useState(attributes.overrideRiskRate)
	const [fullName, setFullName] = useState(attributes.fullName)
	const [updateState, updateApp] = useAsyncResultIdle(updateIndividualCustomer)
	const [refreshToken] = useRefreshToken()
	const [payload, setPayload] = useState(generateCreateAccountRequest(individual.id))
	const [accountsRefreshToken, refreshAccounts] = useRefreshToken()

	const isArchived = attributes.status == CustomerStatus.Archived
	const isCustomerEditable = !isArchived
	const isHiddenIconVisible = !useIsUnitComplianceUser()

	const applicationId = individual.relationships.application?.data.id
	const application = useAsyncResult(
		() =>
			applicationId
				? getApplication(accessToken, applicationId)
				: new Promise(() => AsyncResult.pending<Application, ErrorDocument>()),
		[refreshToken]
	)

	const [showArchiveCustomer, hideArchiveCustomer] = useModal(() => (
		<ArchiveCustomerModal closeModal={hideArchiveCustomer} customer={individual} onSuccess={refresh} />
	))
	const [showUnarchiveCustomer, hideUnarchiveCustomer] = useModal(() => (
		<UnarchiveCustomerModal closeModal={hideUnarchiveCustomer} customer={individual} onSuccess={refresh} />
	))

	const customerKnownNamesResult = useIsUnitUser()
		? useAsyncResult<OkDocument<CustomerKnownNames>, ErrorDocument>(
				() => getCustomerKnownNames(accessToken, individual.id),
				[refreshToken]
		  )
		: null

	const applicationLink = individual.relationships.application
		? `/applications/individual/${individual.relationships.application.data.id}`
		: undefined

	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Customer Updated Successfully", {appearance: "success"})
			setIsUpdating(false)
			refresh()
		} else if (updateState.isErr()) {
			addToast(updateState.error.errors[0].detail || updateState.error.errors[0].title, {appearance: "error"})
			setIsUpdating(false)
			refresh()
		}
	}, [updateState])

	const ref = useRef(null)
	const tutorialProps: TutorialModalProps = {
		tooltipId: "accountTutorial",
		parentRef: ref,

		actions: [
			{
				actionName: "Create Account",
				title: "Test Create Account API",
				description: "Create a deposit account for your customer",
				successMessage: "Success! You can now see your new deposit account in the dashboard",
				errorMessage: "There was an error creating the deposit account",
				request: {
					method: "POST",
					path: "accounts",
					payload: payload,
					headers: [
						{headerKey: "Content-Type", headerValue: "application/vnd.api+json"},
						{headerKey: "Cache-Control", headerValue: "no-cache"},
					],
				},
				refreshFunction: refreshAccounts,
				onSendAnotherRequest: () => {
					setPayload(generateCreateAccountRequest(individual.id))
				},
			},
		],
	}
	const customerType = CustomerType.individual
	const customerTypeDisplayText = startCase(customerType)
	const customerTypeIcon = CustomerTypeIcons[customerType]
	const auditLogEventsHeaderMetaItems = getAuditLogEventsHeaderMetaItems({
		customerName: name,
		customerType: customerTypeDisplayText,
		pageIcon: customerTypeIcon,
		org: org as Org,
	})
	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()
	return (
		<>
			<TitleBar
				title={name}
				breadcrumbs={[{text: "Customers", path: "/customers/"}]}
				meta={[
					{text: customerTypeDisplayText, icon: customerTypeIcon},
					{text: "Application", icon: "user-id-card", path: applicationLink},
				]}
				status={isArchived ? {text: "Archived", color: "#B0B2B5", backgroundColor: "#EBECEE"} : undefined}
			>
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				{attributes.status != CustomerStatus.Archived ? (
					<HasPermission resource="customer.status" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Archive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showArchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				) : (
					<HasPermission resource="customer.status.unarchive" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Unarchive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showUnarchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				)}
				<TutorialButton tutorialModalProps={tutorialProps} buttonClassname="space-left" />
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="customer"
					resourceId={individual.id}
				/>
				<div className="columns">
					<div className="column is-4">
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Individual Information</p>
									</div>
									<div className="card-content">
										<UnitUserOnly>
											{optionalHorizontalField(
												"Org",
												org && org.type == "org" ? org.attributes.name : undefined,
												undefined,
												isArchived ? "archived-field" : ""
											)}
										</UnitUserOnly>
										<HorizontalField label="Customer Id" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" className="input is-static" readOnly value={individual.id} />
										</HorizontalField>
										<Editable
											label="Name"
											isEditing={isEditing === "Full Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Full Name")}
											onCancel={refresh}
											setValue={setFullName}
											value={fullName}
											initial={fullName}
											update={() => {
												updateApp(accessToken, individual, {fullName: fullName})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableFullName}
										</Editable>
										<UnitUserOnly>
											{overrideRiskRate ? (
												<>
													<RiskRateField riskRate={riskRate} />
													<EditableRiskRateField
														label="Override Risk Rate"
														riskRate={overrideRiskRate}
														setRiskRate={setOverrideRiskRate}
														isEditing={isEditing === "RiskRate"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={isCustomerEditable && useIsUnitUser()}
														onStartEdit={() => setIsEditing("RiskRate")}
														onCancel={refresh}
														update={() => {
															updateApp(accessToken, individual, {riskRate: overrideRiskRate})
															setIsUpdating(true)
														}}
														className={attributes.status == CustomerStatus.Archived ? "archived-field" : ""}
													/>
												</>
											) : (
												riskRate && (
													<EditableRiskRateField
														riskRate={riskRate}
														setRiskRate={setRiskRate}
														isEditing={isEditing === "RiskRate"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={isCustomerEditable && useIsUnitUser()}
														onStartEdit={() => setIsEditing("RiskRate")}
														onCancel={refresh}
														update={() => {
															updateApp(accessToken, individual, {riskRate: riskRate})
															setIsUpdating(true)
														}}
														className={attributes.status == CustomerStatus.Archived ? "archived-field" : ""}
													/>
												)
											)}
										</UnitUserOnly>
										<HorizontalField label="Created At" fieldClassName={isArchived ? "archived-field" : ""}>
											<input
												type="text"
												readOnly
												value={moment(attributes.createdAt).format("L LT")}
												className="input is-static"
											/>
										</HorizontalField>
										<IdentificationNumber
											data={{
												initialSsn: attributes.ssn ?? attributes.maskedSSN ?? "",
												initialPassport: [
													attributes.nationality ?? "",
													attributes.passport ?? attributes.maskedPassport ?? "",
												],
												initialMatriculaConsular:
													attributes.matriculaConsular ?? attributes.maskedMatriculaConsular ?? "",
												documentTitle,
												archived: isArchived,
											}}
											state={{
												isEditing,
												isEditable: isCustomerEditable,
												isUpdating,
												isHiddenIconVisible,
											}}
											actions={{
												setIsEditing,
												refresh,
												updateSSN: (ssn: string) => updateApp(accessToken, individual, {ssn}),
												updatePassport: (passport: [string, string]) =>
													updateApp(accessToken, individual, {passport: passport[1], nationality: passport[0]}),
												updateMatriculaConsular: (matriculaConsular: string) =>
													updateApp(accessToken, individual, {matriculaConsular}),
												setIsUpdating,
											}}
										/>
										<OptionalEditable
											label="Birth Date"
											isEditing={isEditing === "Birth Date"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Birth Date")}
											onCancel={refresh}
											setValue={setDob}
											value={dob}
											initial={dob}
											update={(v) => {
												updateApp(accessToken, individual, {dob: v})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableDate}
										</OptionalEditable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer", "update")}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Phone")}
											onCancel={refresh}
											setValue={setPhone}
											value={phone}
											initial={phone}
											update={() => {
												updateApp(accessToken, individual, {phone: phone})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										<OptionalEditable
											label="Email"
											isEditing={isEditing === "Email"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer.email", "update")}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Email")}
											onCancel={refresh}
											setValue={setEmail}
											value={email}
											initial={email}
											update={() => {
												updateApp(accessToken, individual, {email: email})
												setIsUpdating(true)
											}}
											inputType="email"
											previewFunction={emailPreview}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
										<OptionalEditable
											label="EIN"
											isEditing={isEditing === "EIN"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("EIN")}
											onCancel={refresh}
											setValue={setEin}
											value={ein}
											initial={ein}
											update={() => {
												updateApp(accessToken, individual, {ein: ein})
												setIsUpdating(true)
											}}
											pattern="^\d{9}$"
											className={isArchived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
										{attributes.soleProprietorship ? (
											<Editable
												label="Doing Business As"
												isEditing={isEditing === "DBA"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={isCustomerEditable && hasPermission("customer", "update")}
												isUpdating={isUpdating}
												onStartEdit={() => setIsEditing("DBA")}
												onCancel={refresh}
												setValue={setDba}
												value={dba}
												initial={dba}
												update={() => {
													updateApp(accessToken, individual, {dba: dba === "" ? null : dba})
													setIsUpdating(true)
												}}
												pattern={"^.*$"}
												className={isArchived ? "archived-field" : ""}
												required={false}
											>
												{EditableOptionalString}
											</Editable>
										) : null}
										{optionalHorizontalField(
											"Sole Proprietorship",
											attributes.soleProprietorship ? "Yes" : "No",
											undefined,
											isArchived ? "archived-field" : ""
										)}
										<OptionalEditable
											label="Address"
											isEditing={isEditing === "Address"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={isCustomerEditable && hasPermission("customer.address", "update")}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Address")}
											onCancel={refresh}
											setValue={setAddress}
											value={address}
											initial={address}
											update={(v) => {
												updateApp(accessToken, individual, {address: v})
												setIsUpdating(true)
											}}
											className={isArchived ? "archived-field" : ""}
										>
											{EditableAddress}
										</OptionalEditable>
										{optionalHorizontalField(
											"Archive Reason",
											startCase(attributes.archiveReason),
											undefined,
											isArchived ? "archived-field" : ""
										)}

										{application.match(
											() => null,
											(a) => (
												<IndividualAdditionalInformationFields
													individualApplication={a as OkDocument<IndividualApplication>}
													isArchived={isArchived}
												/>
											),
											() => (
												<div>Failed to fetch application</div>
											)
										)}
									</div>
								</div>
							</div>
						</div>

						<Card title="Counterparties">
							<CustomerCounterparties customerId={individual.id} limit={100} isArchived={isArchived} />
						</Card>

						{Object.keys(attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title archived-field">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={attributes.tags} fieldClassName={isArchived ? "archived-field" : ""} />
										</div>
									</div>
								</div>
							</div>
						) : null}
						{customerKnownNamesResult?.match(
							() => null,
							(aliases: any) => (
								<div className="columns">
									<div className="column">
										<div className="card">
											<div className="card-header">
												<p className="card-header-title">Known Aliases</p>
											</div>
											<div className="card-content">
												<CustomerKnownNamesComponent
													customerId={individual.id}
													names={aliases.data.attributes.names}
													refresh={refresh}
													fieldClassName={"archived-field"}
												/>
											</div>
										</div>
									</div>
								</div>
							),
							() => null
						)}
					</div>
					<div className="column">
						<Accounts
							customerId={individual.id}
							enableTitle
							fullHeight={false}
							includedColumns={[
								AccountsColumns.id,
								...(useIsUnitUser() ? [AccountsColumns.bank] : []),
								AccountsColumns.balance,
								AccountsColumns.type,
								AccountsColumns.product,
								AccountsColumns.status,
								AccountsColumns.routingNumber,
								AccountsColumns.accountNumber,
								AccountsColumns.purpose,
								AccountsColumns.createdAt,
							]}
							refreshToken={accountsRefreshToken}
							enableTutorial={true}
						/>
						<Transactions
							fullHeight={false}
							customerId={individual.id}
							limit={10}
							isUsingPaging={true}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.type,
								TransactionsColumns.amount,
								TransactionsColumns.balance,
								TransactionsColumns.summary,
								TransactionsColumns.createdAt,
							]}
						/>

						<Disputes
							enableTitle={true}
							fullHeight={false}
							limit={10}
							customerId={individual.id}
							includedColumns={[
								DisputesColumns.id,
								DisputesColumns.source,
								DisputesColumns.amount,
								DisputesColumns.createdAt,
								DisputesColumns.status,
								DisputesColumns.updatedAt,
							]}
						/>

						<AuthorizedUsers customerId={individual.id} />
					</div>
				</div>
			</MainSection>
		</>
	)
}
