import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDown} from "@fortawesome/free-solid-svg-icons"
import React, {useEffect, useRef, useState} from "react"
import {Calendar} from "./Calendar"
import ReactTooltip from "react-tooltip"
import {useClickAway, useToggle} from "react-use"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import moment, {Moment} from "moment"
import {v4 as uuidv4} from "uuid"
import {noop} from "lodash"

export enum DatePickerPresetKeys {
	allTime = "All time",
	last7Days = "Last 7 days",
	last30Days = "Last 30 days",
	lastMonth = "Last month",
	thisMonth = "This month",
	last3Months = "Last 3 months",
	last6Months = "Last 6 months",
	custom = "Custom",
}

export enum DateFormat {
	usa = "MM/DD/YY",
	world = "DD/MM/YY",
}

type AllowedPresetKeys =
	| DatePickerPresetKeys.allTime
	| DatePickerPresetKeys.last7Days
	| DatePickerPresetKeys.last30Days
	| DatePickerPresetKeys.lastMonth
	| DatePickerPresetKeys.thisMonth
	| DatePickerPresetKeys.last3Months
	| DatePickerPresetKeys.last6Months
	| DatePickerPresetKeys.custom

type AllowedPresetValues = {since: Moment | null; until: Moment | null}
type Preset = Map<AllowedPresetKeys, AllowedPresetValues | null>

interface DatePickerProps {
	presets: Array<AllowedPresetKeys>
	onDateChanged?: (since: string, until: string) => void
	onDateRangeChanged?: (preset: DatePickerPresetKeys) => void
	dateFormat?: DateFormat.usa | DateFormat.world
	calendarPosition?: "top" | "right" | "bottom" | "left"
	simpleTheme?: boolean
	disableCustomEdit?: boolean
	showKeyInsteadOfRange?: boolean
}

function handleCustomDayInput(
	inputEvent: React.FormEvent<HTMLSpanElement>,
	currentValues: Array<Moment>,
	onChange: (s: Moment, u: Moment) => void,
	dateFormat: DateFormat.usa | DateFormat.world
): void {
	const target = inputEvent.currentTarget

	const inputValue = target.innerText
	const [sinceFromInput, untilFromInput] = inputValue.split("-")
	let since = moment(sinceFromInput, dateFormat)
	let until = moment(untilFromInput, dateFormat)

	if (!since.isValid() || since > moment()) {
		since = currentValues[0]
	}

	if (!until.isValid() || until > moment()) {
		until = currentValues[1]
	}

	if (!since.isValid() && !until.isValid()) {
		since = moment()
		until = moment()
	}

	if (since > until) {
		;[since, until] = [until, since]
	}

	onChange(since, until)
}

const presetMap: Preset = new Map<AllowedPresetKeys, AllowedPresetValues>([
	[DatePickerPresetKeys.allTime, {since: null, until: null}],
	[DatePickerPresetKeys.last7Days, {since: moment().subtract(7, "days"), until: moment()}],
	[DatePickerPresetKeys.last30Days, {since: moment().subtract(30, "days"), until: moment()}],
	[DatePickerPresetKeys.lastMonth, {since: moment().subtract(1, "month"), until: moment()}],
	[DatePickerPresetKeys.thisMonth, {since: moment().startOf("month"), until: moment()}],
	[DatePickerPresetKeys.last3Months, {since: moment().subtract(3, "month"), until: moment()}],
	[DatePickerPresetKeys.last6Months, {since: moment().subtract(6, "month"), until: moment()}],
])

const renderRadioButtonWithLabel = (preset: AllowedPresetKeys, dateRange: DatePickerPresetKeys, isCustom: boolean) => (
	<div className="control">
		<input
			readOnly
			type="radio"
			name="dateRange"
			id="dateRange"
			value={isCustom ? dateRange : preset}
			checked={dateRange === preset}
		/>
		<label className="radio" htmlFor="dateRange">
			{preset}
		</label>
	</div>
)

export default function DatePickerWithPresets({
	presets,
	onDateChanged = noop,
	onDateRangeChanged = noop,
	dateFormat = DateFormat.usa,
	calendarPosition = "left",
	simpleTheme,
	disableCustomEdit,
	showKeyInsteadOfRange,
}: DatePickerProps) {
	const [since, setSince] = useState<Moment | null>(null)
	const [until, setUntil] = useState<Moment | null>(null)
	const [dateRange, setDateRange] = useState(presets[0])

	const [dropDownPlaceholder, setDropDownPlaceholder] = useState<string>(presets[0])
	const [isDropdownActive, toggleDropdown] = useToggle(false)

	const ref = useRef(null)
	const tooltipRef = useRef<HTMLAnchorElement>(null)

	const emptyDatePickerPlaceholder = `${dateFormat} - ${dateFormat}`

	useEffect(() => {
		if (dateRange === DatePickerPresetKeys.custom && isDropdownActive) {
			setTimeout(() => {
				if (tooltipRef.current) {
					ReactTooltip.rebuild()
					ReactTooltip.show(tooltipRef.current)
				}
			}, 0)
		}
	}, [dateRange, isDropdownActive])

	useEffect(() => {
		onDateChanged(since ? since.startOf("d").toISOString() : "", until ? until.endOf("d").toISOString() : "")
	}, [since, until])

	useEffect(() => {
		onDateRangeChanged(dateRange)
	}, [dateRange])

	useClickAway(ref, () => toggleDropdown(false))
	const dropdownClassname = classNames(
		"dropdown",
		isDropdownActive && "is-active",
		dropDownPlaceholder == emptyDatePickerPlaceholder && "empty-placeholder"
	)

	const tooltipId = `calendar-${uuidv4()}`

	return (
		<div className="date-picker-with-presets" ref={ref}>
			<div className={dropdownClassname}>
				<div className={classNames("dropdown-trigger", simpleTheme && "filter-dropdown-trigger-simple-theme")}>
					<button
						className={classNames("button", simpleTheme && "is-ghost simple-theme")}
						aria-haspopup="true"
						aria-controls="dropdown-menu3"
						onClick={() => toggleDropdown()}
					>
						<div className="date-picker-with-presets-placeholder icon-container">
							<span className="icon is-small">
								{" "}
								<Icon icon={"calendar-2--interface-essential"} size={16} />{" "}
							</span>
							<span
								suppressContentEditableWarning={true}
								contentEditable={!disableCustomEdit}
								onBlur={(e) => {
									handleCustomDayInput(
										e,
										[since ?? moment(), until ?? moment()],
										(s, u) => {
											setSince(s)
											setUntil(u)
											setDateRange(DatePickerPresetKeys.custom)
											e.currentTarget.innerText = `${s.format(dateFormat)}-${u.format(dateFormat)}`
											setDropDownPlaceholder(`${s.format(dateFormat)}-${u.format(dateFormat)}`)
										},
										dateFormat
									)
								}}
								onClick={() => {
									if (disableCustomEdit) {
										return
									}
									if (dropDownPlaceholder === DatePickerPresetKeys.allTime) {
										setDropDownPlaceholder(emptyDatePickerPlaceholder)
									}

									setTimeout(() => {
										if (tooltipRef.current) {
											toggleDropdown(true)
											ReactTooltip.rebuild()
											ReactTooltip.show(tooltipRef.current)
										}
									}, 0)
								}}
								onKeyDown={(e) => {
									if (disableCustomEdit) {
										return
									}
									if (dropDownPlaceholder === emptyDatePickerPlaceholder) {
										setDropDownPlaceholder("")
									}
									if (e.key === "Enter") {
										e.currentTarget.blur()
									}
								}}
								className={classNames(
									!disableCustomEdit && "date-picker-with-presets-placeholder-text",
									dropDownPlaceholder === emptyDatePickerPlaceholder &&
										"date-picker-with-presets-placeholder-enter-text"
								)}
							>
								{dropDownPlaceholder}
							</span>
						</div>

						<span className="icon is-small dropdown-icon">
							{" "}
							<FontAwesomeIcon icon={faAngleDown} className={classNames(simpleTheme && "simple-filter-icon")} />{" "}
						</span>
					</button>
				</div>
				<div className="dropdown-menu" role="menu">
					<div className="dropdown-content">
						{presets.map((preset, i) => {
							if (preset === DatePickerPresetKeys.custom) {
								return (
									<a
										key={i}
										href="#"
										className="dropdown-item"
										data-tip={tooltipId}
										data-iscapture={true}
										data-event="click"
										ref={tooltipRef}
										data-for={tooltipId}
										onClick={(e) => {
											setDateRange(preset)
											e.stopPropagation()
											e.preventDefault()
										}}
									>
										{renderRadioButtonWithLabel(preset, dateRange, true)}
									</a>
								)
							}
							return (
								<a
									key={i}
									href="#"
									className="dropdown-item"
									onClick={(e) => {
										e.preventDefault()
										const presetSince = presetMap.get(preset)?.since
										const presetUntil = presetMap.get(preset)?.until
										if (preset === DatePickerPresetKeys.allTime) {
											setDropDownPlaceholder(DatePickerPresetKeys.allTime)
										} else {
											setDropDownPlaceholder(
												showKeyInsteadOfRange
													? preset
													: `${presetSince?.format(dateFormat)}-${presetUntil?.format(dateFormat)}`
											)
										}
										setDateRange(preset)

										if (presetSince !== undefined && presetUntil !== undefined) {
											setSince(presetSince)
											setUntil(presetUntil)
										}
									}}
								>
									{renderRadioButtonWithLabel(preset, dateRange, false)}
								</a>
							)
						})}
					</div>
				</div>

				<ReactTooltip
					arrowColor="#FFF"
					place={calendarPosition}
					type="light"
					globalEventOff="click"
					effect="solid"
					id={tooltipId}
					className={"date-picker-calendar"}
					clickable={true}
				>
					<Calendar
						maxDate={moment()}
						startDate={since}
						endDate={until}
						onChange={(startDate, endDate) => {
							if (endDate && startDate) {
								setDropDownPlaceholder(`${startDate.format(dateFormat)}-${endDate.format(dateFormat)}`)
							} else {
								if (!startDate && endDate) {
									setDropDownPlaceholder(`${endDate.format(dateFormat)}-${endDate.format(dateFormat)}`)
								} else if (startDate && !endDate) {
									setDropDownPlaceholder(`${startDate.format(dateFormat)}-${startDate.format(dateFormat)}`)
								}
							}

							setSince(startDate)
							setUntil(endDate)
						}}
					/>
				</ReactTooltip>
			</div>
		</div>
	)
}
