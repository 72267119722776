import React from "react"
import {NavLink as BaseNavLink, NavLinkProps} from "react-router-dom"

interface BackwardsCompatibleNavLinkProps extends NavLinkProps {
	activeClassName?: string
	activeStyle?: Record<string, any>
}

/**
 * Wrapper component around react-router v6 NavLink with support for v5 activeClassName & activeStyle props.
 * We do this to have a smooth upgrade path from v5 to v6.
 * See here for more details:
 * https://reactrouter.com/en/v6.3.0/upgrading/v5#remove-activeclassname-and-activestyle-props-from-navlink-
 */
const NavLink: React.ForwardRefExoticComponent<
	BackwardsCompatibleNavLinkProps & React.RefAttributes<HTMLAnchorElement>
> = React.forwardRef(({activeClassName, activeStyle, ...props}, ref) => {
	return (
		<BaseNavLink
			ref={ref}
			{...props}
			className={({isActive}) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(" ")}
			style={({isActive}) => ({
				...props.style,
				...(isActive ? activeStyle : null),
			})}
		/>
	)
})

NavLink.displayName = "NavLink"

export {NavLink}
