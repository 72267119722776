import React, {PropsWithChildren} from "react"
import Icon from "../Icon/Icon"
import classNames from "classnames"
import {IcomoonIconName} from "../Icon/icons"

interface DetailsListProps {
	title: string
	icon?: IcomoonIconName
	description?: string
	disabled?: boolean
	theme?: "primary" | "secondary"
	additionalHeaderContent?: React.ReactNode
	contentRef?: React.Ref<HTMLDivElement>
	className?: string
}

export function DetailsList({
	title,
	icon,
	description,
	theme = "primary",
	disabled,
	additionalHeaderContent,
	contentRef,
	className,
	children,
}: PropsWithChildren<DetailsListProps>) {
	return (
		<section className={classNames("details-list", theme === "secondary" && "secondary", className)} ref={contentRef}>
			<div className="details-list-title-and-description-container">
				<div className="is-flex is-justify-content-space-between">
					<div className="icon-text">
						{icon && (
							<span className="icon">
								<Icon icon={icon} size={20} className={classNames("details-list-icon", disabled && "disabled")} />
							</span>
						)}
						<span className={classNames("details-list-title", disabled && "disabled")}>{title}</span>
					</div>
					{additionalHeaderContent}
				</div>
				{description && <div className="details-list-description">{description}</div>}
			</div>
			{children}
		</section>
	)
}
