import {useEffect} from "react"
import {reCaptchaSiteKey} from "../index"

const useReCaptcha = () => {
	useEffect(() => {
		const script = document.createElement("script")
		script.src = `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaSiteKey}`
		script.async = true
		document.head.appendChild(script)

		return () => {
			document.head.removeChild(script)
		}
	}, [reCaptchaSiteKey])
}

export default useReCaptcha
