import React from "react"
import iconSet from "./icons.json"
import {IcoMoon} from "./IcoMoon"
import {IcomoonIconName} from "./icons"

interface Props {
	icon: IcomoonIconName
	color?: string
	size?: string | number
	className?: string
	style?: React.CSSProperties
}

export default function Icon({icon, size = 20, ...rest}: Props) {
	return <IcoMoon icon={icon} iconSet={iconSet} size={size} {...rest} />
}
