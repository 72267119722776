import React from "react"

export default function Loading({title}: {title: string}) {
	return (
		<div className="pageloader is-right-to-left is-white is-active">
			<span className="title">{title}</span>
		</div>
	)
}

Loading.defaultProps = {
	title: "Loading",
}
