import React from "react"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {DetailsList} from "../DetailsList/DetailsList"
import {AssignmentPageItem} from "../AssignmentPageItem/AssignmentPageItem"
import {
	Assignee,
	AssignmentActionRequestType,
	AssignmentStage,
	AssignmentStatus,
	CallbackReviewApproveRequest,
	CallbackReviewAssignmentItemPayload,
	CallbackReviewRejectRequest,
	update,
} from "../../resources/assignments"
import AssignmentWireDetails from "../AssignmentWireDetails/AssignmentWireDetails"
import {WirePayment} from "../../resources/payment"
import {Customer} from "../../resources/customer"
import {BusinessApplication} from "../../resources/application"
import {FileMetadata} from "../../utilities/file"
import {CallbackReviewDetails} from "../CallbackReviewDetails/CallbackReviewDetails"
import {useResetScrollTop} from "../../hooks/useResetScrollTop"
import {AssignmentPageItemApproveRejectFooter} from "../AssignmentPageItemApproveRejectFooter/AssignmentPageItemApproveRejectFooter"
import {useModal} from "react-modal-hook"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AssignmentApproveRejectModal} from "../AssignmentApproveRejectModal/AssignmentApproveRejectModal"
import {AssignmentPageCompletedItemHeader} from "../AssignmentPageCompletedItemHeader/AssignmentPageCompletedItemHeader"
import {Claims, useUserClaimsData} from "../../services/auth"

interface AssignmentPageItemCallbackReviewProps {
	id: string
	assignees: Assignee[]
	status: AssignmentStatus
	stage: AssignmentStage
	title: string
	description?: string | JSX.Element
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
	payload?: CallbackReviewAssignmentItemPayload
	wire?: WirePayment
	customer?: Customer
	businessApplication?: BusinessApplication
	accessToken: string
	refresh: () => void
	disabled?: boolean
	claims?: Claims
}

export function AssignmentPageItemCallbackReview({
	id,
	assignees,
	status,
	stage,
	title,
	description,
	createdAt,
	statusUpdatedAt,
	isOwnersItem,
	payload,
	wire,
	customer,
	businessApplication,
	accessToken,
	refresh,
	disabled,
	claims,
}: AssignmentPageItemCallbackReviewProps) {
	const wireDetailsContentRef = React.useRef<HTMLDivElement>(null)
	const callbackDetailsContentRef = React.useRef<HTMLDivElement>(null)
	useResetScrollTop(wireDetailsContentRef, [id])
	useResetScrollTop(callbackDetailsContentRef, [id])

	const userClaims = useUserClaimsData(claims)
	const {callbackDocumentMetadata, reasonText} = payload ?? {}
	const files: FileMetadata[] = callbackDocumentMetadata
		? [{name: callbackDocumentMetadata.documentName, size: callbackDocumentMetadata.documentSize}]
		: []

	const [showApprove, hideApprove] = useModal(() => {
		const successText = "Approved successfully"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = () => {
			const data: CallbackReviewApproveRequest = {
				type: AssignmentActionRequestType.callbackReviewApproveRequest,
			}
			return callback({accessToken, id, data})
		}
		return (
			<AssignmentApproveRejectModal
				title="Approve Item"
				description={
					<p>
						Are you sure you want to approve <span className="has-text-weight-bold">{`Callback Review #${id}?`}</span>
					</p>
				}
				state={state}
				onSubmit={onSubmit}
				primaryButtonText="Approve"
				primaryButtonClassname="is-success"
				closeModal={hideApprove}
				onSuccess={refresh}
				successText={successText}
			/>
		)
	}, [id])

	const [showReject, hideReject] = useModal(() => {
		const successText = "Rejected successfully"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = ({reasonText}: {reasonText: string}) => {
			const data: CallbackReviewRejectRequest = {
				type: AssignmentActionRequestType.callbackReviewRejectRequest,
				attributes: {
					reasonText,
				},
			}
			return callback({accessToken, id, data})
		}
		return (
			<AssignmentApproveRejectModal
				title="Reject Item"
				description={
					<div>
						<p>
							Are you sure you want to reject <span className="has-text-weight-bold">{`Callback Review #${id}?`}</span>
						</p>
						<p>
							This action will automatically reject{" "}
							<span className="has-text-weight-bold">{`wire payment #${wire?.id}.`}</span>
						</p>
					</div>
				}
				state={state}
				onSubmit={onSubmit}
				withReasonText
				reasonTextPlaceholder="Why are you rejecting this callback?"
				primaryButtonText="Reject"
				primaryButtonClassname="is-danger"
				closeModal={hideReject}
				onSuccess={refresh}
				successText={successText}
				successClassname="has-text-black"
			/>
		)
	}, [id])

	return (
		<AssignmentPageItem
			assignees={assignees}
			status={status}
			stage={stage}
			title={title}
			description={description}
			createdAt={createdAt}
			statusUpdatedAt={statusUpdatedAt}
			isOwnersItem={isOwnersItem}
			disabled={disabled}
			pageItemFooter={
				!disabled ? (
					<AssignmentPageItemApproveRejectFooter
						approveLabel="Approve Item"
						rejectLabel="Reject Item"
						onApprove={showApprove}
						onReject={showReject}
					/>
				) : undefined
			}
			pageCompletedItemHeader={
				<AssignmentPageCompletedItemHeader assignees={assignees} status={status} reasonText={reasonText} />
			}
		>
			<GridContainer rows={1} columns={2} gap="8px" className="has-full-height assignment-page-item-default-inner-grid">
				<DetailsList
					title="Wire Summary"
					icon="payment-box-incoming"
					className="scrollable-container"
					contentRef={wireDetailsContentRef}
					disabled={disabled}
				>
					<AssignmentWireDetails
						wire={wire}
						customer={customer}
						businessApplication={businessApplication}
						disabled={disabled}
						singleColumn={true}
						liteVersion={true}
						claims={userClaims}
					/>
				</DetailsList>
				<DetailsList
					title="Callback Details"
					icon="phone-forward-arrow"
					className="scrollable-container"
					contentRef={callbackDetailsContentRef}
					disabled={disabled}
				>
					{payload && (
						<CallbackReviewDetails
							assignee={payload.evidenceCollector}
							payload={{files, callSummary: payload.callbackSummary}}
							disabled={disabled}
							accessToken={accessToken}
							id={id}
						/>
					)}
				</DetailsList>
			</GridContainer>
		</AssignmentPageItem>
	)
}
