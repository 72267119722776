import React, {PropsWithChildren} from "react"
import {hasPermission} from "../../services/permission"
import {PermissionResource} from "../../types/permissionResources"
import {PermissionAction} from "../../types/permissionActions"

export type AskPermission = {
	resource: PermissionResource
	action: PermissionAction
}

export function HasPermission({children, action, resource}: PropsWithChildren<AskPermission>) {
	return hasPermission(resource, action) ? <>{children}</> : null
}
