import React from "react"
import {AssigneeAvatarWithName} from "../AssigneeAvatarWithName/AssigneeAvatarWithName"
import {AssignmentItemStatus} from "../AssignmentItemStatus/AssignmentItemStatus"
import {Assignee, AssignmentStatus} from "../../resources/assignments"
interface AssignmentPageItemHeaderProps {
	assignees: Assignee[]
	status: AssignmentStatus
}

export function AssignmentPageItemHeader({assignees, status}: AssignmentPageItemHeaderProps) {
	return (
		<section>
			<div className="assignment-page-item-header-assignee-and-status-container">
				<AssigneeAvatarWithName assignees={assignees} size="medium" />
				<div className="assignment-page-item-header-status-container">
					<AssignmentItemStatus status={status} />
				</div>
			</div>
		</section>
	)
}
