import React, {ReactNode} from "react"
import Button, {ButtonProps} from "../Button/Button"

type IconButtonProps = {
	size?: ButtonProps["size"]
	theme?: ButtonProps["theme"]
	variant?: ButtonProps["variant"]
	loading?: ButtonProps["loading"]
	icon: ButtonProps["prefixIcon"]
	tooltip?: ButtonProps["tooltip"]
	tooltipKey?: ButtonProps["tooltipKey"]
	tooltipDisabled?: ButtonProps["tooltipDisabled"]
	tooltipClassName?: ButtonProps["tooltipClassName"]
	disabled?: ButtonProps["disabled"]
	onClick: ButtonProps["onClick"]
	className?: ButtonProps["className"]
	children?: ReactNode
}

export function IconButton({
	size,
	theme,
	variant,
	loading,
	icon,
	tooltip,
	tooltipKey,
	tooltipDisabled,
	tooltipClassName,
	disabled,
	onClick,
	className,
}: IconButtonProps) {
	return (
		<Button
			size={size}
			theme={theme}
			variant={variant}
			loading={loading}
			disabled={disabled}
			onClick={onClick}
			prefixIcon={icon}
			circular
			tooltip={tooltip}
			tooltipKey={tooltipKey}
			tooltipDisabled={tooltipDisabled}
			tooltipClassName={tooltipClassName}
			className={className}
		/>
	)
}

export default IconButton
