import {DepositAccount} from "../../resources/account"
import {calcPercent, div, toLongDollars} from "../../utilities/numbers"
import {Box, BoxInfo} from "../../containers/Box/Box"
import React from "react"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {ProgressBar, ProgressBarSize, getPercentColor} from "../../components/ProgressBar/ProgressBar"

export function CreditOperationalAccountSummary({account}: {account: DepositAccount}) {
	const {balance, reserve} = account.attributes
	const percent = calcPercent(reserve, balance)
	const color = getPercentColor(percent)

	return (
		<Box className="account-box">
			<GridContainer rows={2} className="wh-100">
				<div className="flex-container w-100 justify-content-sb gap-10">
					<BoxInfo title="Balance">
						<p className="font-32">{toLongDollars(balance)}</p>
					</BoxInfo>
				</div>
				<ProgressBar percent={percent} className={color} size={ProgressBarSize.sm} />
				<BoxInfo className="font-12 margin-t-20" title="Total reserved for bank repayment" titleLast>
					<p className="font-12">{toLongDollars(reserve)}</p>
				</BoxInfo>
			</GridContainer>
		</Box>
	)
}
