import React from "react"
import {startCase} from "lodash"
import {IcomoonIconName} from "../Icon/icons"
import {AssignmentStatus} from "../../resources/assignments"
import {AssignmentItemBadge, AssignmentItemBadgeProps} from "../AssignmentItemBadge/AssignmentItemBadge"

export interface AssignmentItemStatusProps {
	status?: AssignmentStatus
	theme?: AssignmentItemBadgeProps["theme"]
}

const getStatusIcon = (status?: AssignmentStatus): IcomoonIconName | undefined => {
	switch (status) {
		case AssignmentStatus.AwaitingApproval:
		case AssignmentStatus.AwaitingSubmission:
		case AssignmentStatus.AwaitingReview:
			return "hourglass-loading-clock-time-pending"
		case AssignmentStatus.Approved:
		case AssignmentStatus.Submitted:
		case AssignmentStatus.FalsePositive:
			return "approve-v-check-validation"
		case AssignmentStatus.Rejected:
		case AssignmentStatus.Cancelled:
		case AssignmentStatus.AutomaticallyClosed:
		case AssignmentStatus.Escalated:
			return "disable-delete--interface-essential"
		default:
			return undefined
	}
}
export function AssignmentItemStatus({status, theme}: AssignmentItemStatusProps) {
	const statusIcon = getStatusIcon(status)
	return <AssignmentItemBadge text={startCase(status)} icon={statusIcon} theme={theme} />
}
