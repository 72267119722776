import React, {useState} from "react"
import {
	convertFileExtensionToFileType,
	extractExtensionAndFileName,
	FileType,
	formatFileSize,
} from "../../utilities/file"
import IconButton from "../IconButton/IconButton"
import classNames from "classnames"
import {FileDetails} from "../FileDetails/FileDetails"
import {FileIconButton} from "../FileIconButton/FileIconButton"
import {ImageIcon} from "./FileIcons/Image"
import {DefaultIcon} from "./FileIcons/Default"
import {DOCXIcon} from "./FileIcons/DOCX"
import {PDFIcon} from "./FileIcons/PDF"
import {MP3Icon} from "./FileIcons/MP3"
import {MP4Icon} from "./FileIcons/MP4"

interface DownloaderProps {
	fileName: string
	sizeInBytes: number
	expanded?: boolean
	disabled?: boolean
	enforceEnabledDownloadButton?: boolean
	onDownloadClick: () => Promise<void>
	buttonClassName?: string
}

function getFileIcon(fileType?: FileType, disabled?: boolean) {
	switch (fileType) {
		case FileType.MP3:
		case FileType.WAV:
			return <MP3Icon disabled={disabled} />
		case FileType.JPEG:
		case FileType.JPG:
		case FileType.PNG:
			return <ImageIcon disabled={disabled} />
		case FileType.PDF:
			return <PDFIcon disabled={disabled} />
		case FileType.DOCX:
			return <DOCXIcon disabled={disabled} />
		case FileType.MP4:
			return <MP4Icon disabled={disabled} />
		default:
			return <DefaultIcon />
	}
}

const getFileType = (fileName?: string): FileType | undefined => {
	const fileExtension = extractExtensionAndFileName(fileName).extension
	return convertFileExtensionToFileType(fileExtension)
}

export function Downloader({
	fileName,
	sizeInBytes,
	expanded,
	onDownloadClick,
	disabled,
	enforceEnabledDownloadButton,
	buttonClassName,
}: DownloaderProps) {
	const handleDownloadFile = async () => {
		setLoading(true)
		await onDownloadClick()
		setLoading(false)
	}
	const [loading, setLoading] = useState<boolean>(false)
	const fileIcon = getFileIcon(getFileType(fileName), disabled)
	const isDownloadButtonDisabled = disabled && !enforceEnabledDownloadButton
	return expanded ? (
		<div className="downloader-expanded-container">
			{fileIcon && <div className="downloader-expanded-file-icon">{fileIcon}</div>}
			<div className="downloader-expanded-file-details">
				<span className={classNames("downloader-expanded-file-name", disabled && "disabled")}>{fileName}</span>
				<span className={classNames("downloader-expanded-file-size", disabled && "disabled")}>
					{formatFileSize(sizeInBytes)}
				</span>
			</div>
			<div className="downloader-expanded-download">
				<IconButton
					theme="neutral"
					variant="subtle"
					icon="move-snap-down--interface-essential"
					onClick={handleDownloadFile}
					tooltip="Download"
					tooltipKey={`download-${fileName}`}
					tooltipDisabled={isDownloadButtonDisabled}
					tooltipClassName="downloader-download-icon-button-tooltip"
					disabled={isDownloadButtonDisabled}
					loading={loading}
				/>
			</div>
		</div>
	) : (
		<div className="downloader-container">
			<div className="downloader-file-details-container">
				<FileDetails name={fileName} size={sizeInBytes} operation="download" disabled={disabled} />
			</div>
			<FileIconButton
				tooltip="Download"
				icon="move-snap-down--interface-essential"
				tooltipKey={fileName}
				disabled={isDownloadButtonDisabled}
				loading={loading}
				onClick={handleDownloadFile}
				buttonClassName={buttonClassName}
				containerClassName="downloader-icon-button"
			/>
		</div>
	)
}
