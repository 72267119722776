import classNames from "classnames"
import {kebabCase, startCase} from "lodash"
import moment from "moment"
import React, {ReactElement} from "react"
import {useNavigate} from "react-router-dom"
import ReactTooltip from "react-tooltip"
import {CheckPayment} from "../../resources/checkPayments"
import {Resource} from "../../resources/common"
import {DataTableCell, DataTableRow} from "../DataTable/DataTable"
import {OrgName} from "../OrgName/OrgName"
import {AllowedCheckPaymentsColumns, CheckPaymentsColumns} from "./CheckPayments"
import numeral from "numeral"
import {Customer} from "../../resources/customer"

interface CheckPaymentRowProps {
	checkPayment: CheckPayment
	include?: Resource[]
	showOrgColumn?: boolean
	includedColumns: AllowedCheckPaymentsColumns[]
	refresh: () => void
	selectedCheckPaymentId?: string
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

export function CheckPaymentRow({
	checkPayment,
	include,
	includedColumns,
	selectedCheckPaymentId,
	onClick,
}: CheckPaymentRowProps) {
	const navigate = useNavigate()
	const id = checkPayment.id
	const org = checkPayment.relationships.org?.data.id
	const customerId = checkPayment.relationships.customer?.data.id
	const customersIds = checkPayment.relationships.customers?.data
	const accountId = checkPayment.relationships.account.data.id
	const status = checkPayment.attributes.status
	const returnReason = checkPayment.attributes.returnStatusReason
	const rejectReason = checkPayment.attributes.rejectReason
	const createdAt = checkPayment.attributes.createdAt
	const amount = checkPayment.attributes.amount
	const sendAt = checkPayment.attributes?.sendAt

	const customer =
		customerId && include
			? include.find(
					(r) => (r.type == "individualCustomer" || r.type == "businessCustomer") && r.id == customerId.toString()
			  )
			: undefined

	const customers =
		customersIds && include
			? (include.filter((i) => ["individualCustomer", "businessCustomer"].includes(i.type)) as Customer[])
			: undefined

	let customerName = ""

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer") {
			customerName = customer.attributes.name
		}
	}

	if (customers) {
		customers.forEach((customer) => {
			const name =
				customer.type === "businessCustomer" || customer.type === "businessFBOCustomer"
					? customer.attributes.name
					: `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
			if (customerName === "") {
				customerName += name
			} else {
				customerName += ", " + name
			}
		})
	}

	const contentColumns: Partial<Record<AllowedCheckPaymentsColumns, ReactElement>> = {}

	if (includedColumns.includes(CheckPaymentsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell
				className={classNames(id == selectedCheckPaymentId && "selected-data-table-id", "data-table-id-cell")}
			>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckPaymentsColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} />
	}

	if (includedColumns.includes(CheckPaymentsColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckPaymentsColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckPaymentsColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<span className="payments-amount">{numeral(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckPaymentsColumns.status)) {
		let tooltipValue: string | undefined = undefined
		if (returnReason) {
			tooltipValue = startCase(returnReason)
		}

		if (rejectReason) {
			tooltipValue = startCase(rejectReason)
		}

		contentColumns["Status"] = (
			<DataTableCell>
				<div
					data-tip="React-tooltip"
					data-for={`status-reason-tooltip-${id}`}
					className={classNames("check-payments-status", kebabCase(status))}
				>
					{startCase(status)}

					<ReactTooltip
						className="tooltip-info"
						id={`status-reason-tooltip-${id}`}
						disable={!tooltipValue}
						effect="solid"
					>
						{tooltipValue}
					</ReactTooltip>
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckPaymentsColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(CheckPaymentsColumns.sendAt)) {
		contentColumns["Send At"] = <DataTableCell>{sendAt ? moment(sendAt).format("L LT") : ""}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			className={classNames(id === selectedCheckPaymentId && "selected-data-table-row")}
			onClick={(e) => onClick && onClick(e)}
		>
			{content}
		</DataTableRow>
	)
}
