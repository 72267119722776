import {
	Address,
	BusinessContact,
	createResource,
	ErrorDocument,
	EvaluationScores,
	FullName,
	getFile,
	getResource,
	IpLocationDetails,
	OkDocument,
	Permissible,
	Phone,
	PhoneLocationDetails,
	Resource,
	RiskRate,
	updateResource,
	uploadResource,
} from "./common"
import {ok, Result} from "neverthrow"
import moment from "moment"
import {BeneficialOwner, BeneficialOwnerResource} from "./beneficialOwner"
import {IcomoonIconName} from "../components/Icon/icons"

export enum EvaluationOutcome {
	Approved = "Approved",
	Denied = "Denied",
	PendingReview = "PendingReview",
}

export enum Occupation {
	ArchitectOrEngineer = "Architect or Engineer",
	BusinessAnalystAccountantOrFinancialAdvisor = "Business Analyst Accountant or Financial Advisor",
	CommunityAndSocialServicesWorker = "Community And Social Services Worker",
	ConstructionMechanicOrMaintenanceWorker = "Construction Mechanic or Maintenance Worker",
	Doctor = "Doctor",
	Educator = "Educator",
	EntertainmentSportsArtsOrMedia = "Entertainment Sports Arts or Media",
	ExecutiveOrManager = "Executive or Manager",
	FarmerFishermanForester = "Farmer Fisherman Forester",
	FoodServiceWorker = "Food Service Worker",
	GigWorker = "Gig Worker",
	HospitalityOfficeOrAdministrativeSupportWorker = "Hospitality Office or Administrative Support Worker",
	HouseholdManager = "Household Manager",
	JanitorHousekeeperLandscaper = "Janitor Housekeeper Landscaper",
	Lawyer = "Lawyer",
	ManufacturingOrProductionWorker = "Manufacturing or Production Worker",
	MilitaryOrPublicSafety = "Military or Public Safety",
	NurseHealthcareTechnicianOrHealthcareSupport = "Nurse Healthcare Technician or Healthcare Support",
	PersonalCareOrServiceWorker = "Personal Care or Service Worker",
	PilotDriverOperator = "Pilot Driver Operator",
	SalesRepresentativeBrokerAgent = "Sales Representative Broker Agent",
	ScientistOrTechnologist = "Scientist or Technologist",
	Student = "Student",
}
export const OccupationMapping = new Map(Object.entries(Occupation))

export enum AnnualIncome {
	UpTo10k = "Up to 10k",
	Between10kAnd25k = "Between 10k and 25k",
	Between25kAnd50k = "Between 25k and 50k",
	Between50kAnd100k = "Between 50k and 100k",
	Between100kAnd250k = "Between 100k and 250k",
	Over250k = "Over 250k",
}

export const AnnualIncomeMapping = new Map(Object.entries(AnnualIncome))

export enum BusinessAnnualRevenue {
	UpTo250k = "Up to 250K",
	Between250kAnd500k = "Between 250K and 500K",
	Between500kAnd1m = "Between 500K and 1M",
	Between1mAnd5m = "Between 1M and 5M",
	Over5m = "Over 5M",
}

export const BusinessAnnualRevenueMapping = new Map(Object.entries(BusinessAnnualRevenue))

export enum SoleProprietorshipAnnualRevenue {
	UpTo50k = "Up to 50K",
	Between50kAnd100k = "Between 50K and 100K",
	Between100kAnd200k = "Between 100K and 200K",
	Between200kAnd500k = "Between 200K and 500K",
	Over500k = "Over 500K",
}

export const SoleProprietorshipAnnualRevenueMapping = new Map(Object.entries(SoleProprietorshipAnnualRevenue))

export enum SoleProprietorshipNumberOfEmployees {
	One = "1",
	Between2And5 = "Between 2 and 5",
	Between5And10 = "Between 5 and 10",
	Over10 = "Over 10",
}

export const SoleProprietorshipNumberOfEmployeesMapping = new Map(Object.entries(SoleProprietorshipNumberOfEmployees))

export enum BusinessNumberOfEmployees {
	UpTo10 = "Up to 10",
	Between10And50 = "Between 10 and 50",
	Between50And100 = "Between 50 and 100",
	Between100And500 = "Between 100 and 500",
	Over500 = "Over 500",
}
export const BusinessNumberOfEmployeesMapping = new Map(Object.entries(BusinessNumberOfEmployees))

export enum BusinessVertical {
	AdultEntertainmentDatingOrEscortServices = "Adult Entertainment Dating or Escort Services",
	AgricultureForestryFishingOrHunting = "Agriculture Forestry Fishing or Hunting",
	ArtsEntertainmentAndRecreation = "Arts Entertainment and Recreation",
	BusinessSupportOrBuildingServices = "Business Support or Building Services",
	Cannabis = "Cannabis",
	Construction = "Construction",
	DirectMarketingOrTelemarketing = "Direct Marketing or Telemarketing",
	EducationalServices = "Educational Services",
	FinancialServicesCryptocurrency = "Financial Services Cryptocurrency",
	FinancialServicesDebitCollectionOrConsolidation = "Financial Services Debit Collection or Consolidation",
	FinancialServicesMoneyServicesBusinessOrCurrencyExchange = "Financial Services Money Services Business or Currency Exchange",
	FinancialServicesOther = "Financial Services Other",
	FinancialServicesPaydayLending = "Financial Services Payday Lending",
	GamingOrGambling = "Gaming or Gambling",
	HealthCareAndSocialAssistance = "Health Care and Social Assistance",
	HospitalityAccommodationOrFoodServices = "Hospitality Accommodation or Food Services",
	LegalAccountingConsultingOrComputerProgramming = "Legal Accounting Consulting or Computer Programming",
	Manufacturing = "Manufacturing",
	Mining = "Mining",
	Nutraceuticals = "Nutraceuticals",
	PersonalCareServices = "Personal Care Services",
	PublicAdministration = "Public Administration",
	RealEstate = "Real Estate",
	ReligiousCivicAndSocialOrganizations = "Religious Civic and Social Organizations",
	RepairAndMaintenance = "Repair and Maintenance",
	RetailTrade = "Retail Trade",
	TechnologyMediaOrTelecom = "Technology Media or Telecom",
	TransportationOrWarehousing = "Transportation or Warehousing",
	Utilities = "Utilities",
	WholesaleTrade = "Wholesale Trade",
}
export const BusinessVerticalMapping = new Map(Object.entries(BusinessVertical))

export enum CashFlow {
	Predictable = "Predictable",
	Unpredictable = "Unpredictable",
}

export const CashFlowMapping = new Map(Object.entries(CashFlow))

export enum SourceOfIncome {
	EmploymentOrPayrollIncome = "Employment or Payroll Income",
	PartTimeOrContractorIncome = "Part Time or Contractor Income",
	InheritancesAndGifts = "Inheritances and Gifts",
	PersonalInvestments = "Personal Investments",
	BusinessOwnershipInterests = "Business Ownership Interests",
	GovernmentBenefits = "Government Benefits",
}
export const SourceOfIncomeMapping = new Map(Object.entries(SourceOfIncome))

export enum ApplicationDocumentType {
	addressVerification = "AddressVerification",
	idDocument = "IdDocument",
	passport = "Passport",
	socialSecurityCard = "SocialSecurityCard",
	certificateOfIncorporation = "CertificateOfIncorporation",
	employerIdentificationNumberConfirmation = "EmployerIdentificationNumberConfirmation",
	powerOfAttorney = "PowerOfAttorney",
	clientRequested = "ClientRequested",
	selfieVerification = "SelfieVerification",
	matriculaConsular = "MatriculaConsularCard",
}

export const backSupportedDocumentTypes = [
	ApplicationDocumentType.idDocument,
	ApplicationDocumentType.selfieVerification,
]

export type DocumentLink = {
	type: string
	attributes: {
		link: string
		contentType: string
	}
}

export enum IndustryType {
	Retail = "Retail",
	Wholesale = "Wholesale",
	Restaurants = "Restaurants",
	Hospitals = "Hospitals",
	Construction = "Construction",
	Insurance = "Insurance",
	Unions = "Unions",
	RealEstate = "RealEstate",
	FreelanceProfessional = "FreelanceProfessional",
	OtherProfessionalServices = "OtherProfessionalServices",
	OnlineRetailer = "OnlineRetailer",
	OtherEducationServices = "OtherEducationServices",
}

export type Officer = {
	fullName: FullName
	ssn: Permissible<string>
	passport: Permissible<string>
	nationality: Permissible<string>
	matriculaConsular: Permissible<string>
	address: Permissible<Address>
	dateOfBirth: Permissible<Date>
	email: Permissible<string>
	phone: Permissible<Phone>
	status: EvaluationOutcome
	title?: string
	evaluationFlags?: string[]
	maskedSSN?: string
	maskedPassport?: string
	maskedMatriculaConsular?: string
	idTheftScore?: number
	evaluationCodes?: string[]
	occupation?: Occupation
	sourceOfIncome?: SourceOfIncome
	annualIncome?: AnnualIncome
}

export enum ApplicationDocumentStatus {
	Required = "Required",
	ReceivedBack = "ReceivedBack",
	ReceivedFront = "ReceivedFront",
	Invalid = "Invalid",
	Approved = "Approved",
	PendingReview = "PendingReview",
}

export type ApplicationDocument = {
	type: "document"
	id: string
	attributes: {
		documentType: ApplicationDocumentType
		status: ApplicationDocumentStatus
		passport: string
		matriculaConsular: string
		ssn: string
		nationality: string
		name: string
		address: Permissible<Address>
		dateOfIncorporation: Date
		ein: string
		dateOfBirth: Date
		frontDocumentId?: string
		frontDocumentStoreId?: string
		backDocumentId?: string
		backDocumentStoreId?: string
		additionalDocumentId?: string
		additionalDocumentStoreId?: string
		evaluationId?: string
		reason?: string
		reasonCode?: string
		updatedAt?: Date
		evaluationEntityId?: string
	}
}

export enum ApplicationDocumentSide {
	Front = "Front",
	Back = "Back",
	Additional = "Additional",
}

export enum ApplicationStatus {
	AwaitingDocuments = "AwaitingDocuments",
	PendingReview = "PendingReview",
	Approved = "Approved",
	Denied = "Denied",
	Pending = "Pending",
	Canceled = "Canceled",
}

export enum DecisionMethodType {
	Manually = "Manually",
	Automatically = "Automatically",
	InProgress = "InProgress",
}

export enum ApproveMethodType {
	Manually = "Manually",
	Automatically = "Automatically",
}

export type BusinessApplication = {
	type: "businessApplication"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		status: keyof typeof ApplicationStatus
		name: string
		dba?: string
		address: Permissible<Address>
		phone: Permissible<Phone>
		decisionMethod?: ApproveMethodType
		decisionUserId?: string
		decisionReason?: string
		decisionReasonCodes?: EntityDeclineReason[]
		decisionDateTime?: Date
		dateOfIncorporation: Date
		stateOfIncorporation: string
		ein: Permissible<string>
		entityType?: "Corporation" | "LLC"
		contact: BusinessContact
		officer: Officer
		beneficialOwners: Array<BeneficialOwner>
		purpose: string
		evaluationOutcome: EvaluationOutcome
		evaluationEntityId?: string
		ip?: string
		website?: string
		hasNoWebsite?: boolean
		tags: {[key: string]: string}
		riskRate?: RiskRate
		evaluationFlags?: string[]
		ipLocationDetails?: IpLocationDetails
		phoneLocationDetails?: PhoneLocationDetails
		archived: boolean
		industry?: IndustryType
		businessVertical?: BusinessVertical
		yearOfIncorporation?: string
		numberOfEmployees?: BusinessNumberOfEmployees
		annualRevenue?: BusinessAnnualRevenue
		countriesOfOperation?: string[]
		cashFlow?: CashFlow
		evaluationCodes?: string[]
	}
	relationships: {
		documents: {
			data: Array<{type: "document"; id: string}>
		}
		customer?: {
			data: {
				type: "businessCustomer"
				id: string
			}
		}
		org: {
			data: {
				type: "org"
				id: string
			}
		}
	}
	included: Array<BeneficialOwnerResource>
}

export type IndividualApplication = {
	type: "individualApplication"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		status: keyof typeof ApplicationStatus
		fullName: FullName
		ssn: Permissible<string>
		passport: Permissible<string>
		nationality: Permissible<string>
		matriculaConsular: Permissible<string>
		address: Permissible<Address>
		dateOfBirth: Permissible<Date>
		email: Permissible<string>
		phone: Permissible<Phone>
		decisionMethod?: ApproveMethodType
		decisionUserId?: string
		decisionReason?: string
		decisionReasonCodes?: EntityDeclineReason[]
		decisionDateTime?: Date
		tin?: string
		evaluationOutcome: EvaluationOutcome
		evaluationEntityId?: string
		ip?: string
		ein: Permissible<string>
		dba?: string
		soleProprietorship: boolean
		tags: {[key: string]: string}
		riskRate?: RiskRate
		evaluationFlags?: string[]
		evaluationScores?: EvaluationScores
		ipLocationDetails?: IpLocationDetails
		phoneLocationDetails?: PhoneLocationDetails
		maskedSSN?: string
		maskedPassport?: string
		maskedMatriculaConsular?: string
		archived: boolean
		industry?: IndustryType
		idTheftScore?: number
		evaluationCodes?: string[]
		occupation?: Occupation
		sourceOfIncome?: SourceOfIncome
		annualIncome?: AnnualIncome
		numberOfEmployees?: SoleProprietorshipNumberOfEmployees
		annualRevenue?: SoleProprietorshipAnnualRevenue
		businessVertical?: BusinessVertical
		website?: string
		hasNoWebsite?: boolean
	}
	relationships: {
		documents: {
			data: Array<{type: "document"; id: string}>
		}
		customer?: {
			data: {
				type: "individualCustomer"
				id: string
			}
		}
		org: {
			data: {
				type: "org"
				id: string
			}
		}
	}
}

export type Application = BusinessApplication | IndividualApplication

export type RequestPassport = {
	fullName: FullName
	birthDate: Date
	passport?: string
	nationality?: string
}

export type RequestSSN = {
	fullName: FullName
	birthDate: Date
	ssn?: string
}

export type RequestMatriculaConsular = {
	fullName: FullName
	birthDate: Date
	matriculaConsular?: string
}
export type RequestAddressVerification = {
	fullName: FullName
	birthDate: Date
	address: Address
}

export type RequestBusinessAddressVerification = {
	name: string
	address: Address
}

export type RequestSelfieVerification = {
	fullName: FullName
	birthDate: Date
}

export type RequestCertificateOfIncorporation = {
	name: string
	stateOfIncorporation: string
}

export type RequestEmployerIdentificationNumberConfirmation = {
	name: string
	ein: string
}

export type EntityDeclineReason = {
	entity: string
	reasons?: string[]
}

export type RequestDocumentRequest =
	| RequestPassport
	| RequestSSN
	| RequestAddressVerification
	| RequestBusinessAddressVerification
	| RequestCertificateOfIncorporation
	| RequestEmployerIdentificationNumberConfirmation
	| RequestMatriculaConsular
	| RequestSelfieVerification

export function isApplicationDocument(resource: Resource): resource is ApplicationDocument {
	return resource.type === "document"
}

export function evaluateApplicationLocation(
	address: Address,
	locationDetails: PhoneLocationDetails | IpLocationDetails,
	locationType: "Phone" | "IP"
) {
	const stateValues = [address.state, locationDetails.stateCode]
	const countryValues = [address.country, locationDetails.countryCode]

	const stateMatch = new Set(stateValues).size === 1
	const countryMatch = new Set(countryValues).size === 1

	if (stateMatch && countryMatch) {
		return `${locationType} Location Match`
	}
	if (stateMatch && !countryMatch) {
		return `${locationType} Location Country Mismatch`
	}
	if (!stateMatch && countryMatch) {
		return `${locationType} Location State Mismatch`
	}

	return `${locationType} Location Mismatch`
}

export async function findApplication(
	accessToken: string,
	offset: number,
	limit: number,
	status: ApplicationStatus[],
	sort: string,
	searchQuery: string,
	orgs?: string[],
	decisionMethod?: DecisionMethodType[]
): Promise<Result<OkDocument<Array<Application>>, ErrorDocument>> {
	if (status.length === 0) return ok({data: []})
	if (decisionMethod?.length === 0) return ok({data: []})

	const searchQueryValue = searchQuery != "" ? searchQuery : null

	const orgFilter = orgs && orgs.length > 0 ? orgs : null

	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			status: status,
			query: searchQueryValue,
			orgs: orgFilter,
			decisionMethod: decisionMethod,
		},
		extraFields: {
			application: "evaluationOutcome",
		},
		include: "org",
		sort,
	}
	return await getResource<Array<Application>>("applications", accessToken, query)
}

export async function exportApplications(
	accessToken: string,
	status: ApplicationStatus[],
	sort: string,
	searchQuery: string,
	orgs?: string[]
): Promise<Result<OkDocument<Array<Application>>, ErrorDocument>> {
	if (status.length === 0) return ok({data: []})

	const searchQueryValue = searchQuery != "" ? searchQuery : null

	const orgFilter = orgs && orgs.length > 0 ? orgs : null

	const query = {
		page: {
			limit: 10000,
			offset: 0,
		},
		filter: {
			status: status,
			query: searchQueryValue,
			orgs: orgFilter,
		},
		include: "org",
		sort,
	}

	return await getFile("applications", accessToken, query)
}

export async function approveApplication(
	accessToken: string,
	application: Application,
	reason: string,
	riskRate: RiskRate
) {
	const data = {
		type: "applicationApprove",
		attributes: {
			reason,
			riskRate,
		},
	}

	return await createResource<Application>(`applications/${application.id}/approve`, accessToken, data)
}

export async function denyApplication(
	accessToken: string,
	application: Application,
	reason: string,
	reasons: EntityDeclineReason[]
) {
	const data = {
		type: "applicationDeny",
		attributes: {
			reason,
			reasons,
		},
	}

	return await createResource<Application>(`applications/${application.id}/deny`, accessToken, data)
}

export async function cancelApplication(accessToken: string, application: Application, reason: string) {
	const data = {
		type: "applicationCancel",
		attributes: {
			reason,
		},
	}

	return await createResource<Application>(`applications/${application.id}/cancel`, accessToken, data)
}

export async function getIndividualApplication(accessToken: string, id: string) {
	return await getResource<IndividualApplication>(`applications/${id}`, accessToken, {
		extraFields: {
			application:
				"evaluationFlags,evaluationOutcome,maskedSSN,maskedPassport,maskedMatriculaConsular,decisionReasonCodes,decisionDateTime",
		},
		include: "org",
	})
}

export async function downloadDocument(
	applicationId: string,
	documentId: string,
	accessToken: string,
	side: ApplicationDocumentSide
) {
	return await getFile(
		`applications/${applicationId}/documents/${documentId}/download/${side.toLocaleLowerCase()}`,
		accessToken,
		undefined,
		undefined,
		"arraybuffer",
		true
	)
}

export async function getBusinessApplication(accessToken: string, id: string) {
	return await getResource<BusinessApplication>(`applications/${id}`, accessToken, {
		extraFields: {
			application: "evaluationFlags,evaluationOutcome,decisionReasonCodes,decisionDateTime",
			officer: "evaluationFlags,maskedSSN,maskedPassport,maskedMatriculaConsular",
			beneficialOwner: "evaluationFlags,maskedSSN,maskedPassport,maskedMatriculaConsular",
		},
		include: "org",
	})
}

export async function getApplication(accessToken: string, id: string) {
	return await getResource<Application>(`applications/${id}`, accessToken, {
		extraFields: {
			application:
				"evaluationFlags,evaluationOutcome,maskedSSN,maskedPassport,maskedMatriculaConsular,decisionReasonCodes,decisionDateTime",
			officer: "evaluationFlags,maskedSSN,maskedPassport,maskedMatriculaConsular",
			beneficialOwner: "evaluationFlags,maskedSSN,maskedPassport,maskedMatriculaConsular",
		},
		include: "org",
	})
}

export async function approveDocument(accessToken: string, applicationId: string, documentId: string) {
	return await createResource<Application>(
		`applications/${applicationId}/documents/${documentId}/approve`,
		accessToken,
		null
	)
}

export async function rejectDocument(
	accessToken: string,
	applicationId: string,
	documentId: string,
	reasonCode: string,
	reason: string
) {
	const data = {
		type: "documentReject",
		attributes: {
			reasonCode,
			reason,
		},
	}

	return await createResource<Application>(
		`applications/${applicationId}/documents/${documentId}/reject`,
		accessToken,
		data
	)
}

export async function uploadDocument(
	accessToken: string,
	applicationId: string,
	documentId: string,
	documentFile: any,
	documentType: string | undefined,
	documentSide: ApplicationDocumentSide
) {
	return await uploadResource<Application>({
		path: `applications/${applicationId}/documents/${documentId}${
			documentSide == ApplicationDocumentSide.Back ? "/back" : ""
		}`,
		accessToken,
		data: documentFile,
		type: documentType,
	})
}

export async function updateIndividualApplication(
	accessToken: string,
	application: Application,
	{
		ssn,
		passport,
		matriculaConsular,
		dob,
		address,
		phone,
		nationality,
		email,
		ein,
		dba,
		fullName,
		industry,
		occupation,
		sourceOfIncome,
		annualIncome,
		annualRevenue,
		businessVertical,
		numberOfEmployees,
		website,
	}: {
		ssn?: string
		passport?: string
		nationality?: string
		matriculaConsular?: string
		dob?: Date
		address?: Address
		phone?: Phone
		email?: string
		ein?: string
		dba?: string
		fullName?: FullName
		industry?: IndustryType
		occupation?: Occupation
		sourceOfIncome?: SourceOfIncome
		annualIncome?: AnnualIncome
		businessVertical?: BusinessVertical
		annualRevenue?: SoleProprietorshipAnnualRevenue
		numberOfEmployees?: SoleProprietorshipNumberOfEmployees
		website?: string
	}
) {
	return await updateResource<Application>(`applications/${application.id}`, accessToken, {
		type: application.type,
		attributes: Object.assign(
			{},
			fullName ? {fullName: fullName} : undefined,
			ssn ? {ssn: ssn} : undefined,
			passport ? {passport: passport} : undefined,
			nationality ? {nationality: nationality} : undefined,
			matriculaConsular ? {matriculaConsular: matriculaConsular} : undefined,
			dob ? {dateOfBirth: moment(dob).format("YYYY-MM-DD")} : undefined,
			address ? {address: address} : undefined,
			phone ? {phone: phone} : undefined,
			email ? {email: email} : undefined,
			ein ? {ein: ein} : undefined,
			dba ? {dba: dba} : undefined,
			industry ? {industry: industry} : undefined,
			occupation ? {occupation: occupation} : undefined,
			sourceOfIncome ? {sourceOfIncome: sourceOfIncome} : undefined,
			annualIncome ? {annualIncome: annualIncome} : undefined,
			businessVertical ? {businessVertical: businessVertical} : undefined,
			annualRevenue ? {annualRevenue: annualRevenue} : undefined,
			numberOfEmployees ? {numberOfEmployees: numberOfEmployees} : undefined,
			website ? {website: website} : undefined
		),
	})
}

export async function updateBusinessApplication(
	accessToken: string,
	application: Application,
	{
		officerName,
		officerSSN,
		officerPassport,
		officerMatriculaConsular,
		officerDob,
		officerAddress,
		officerPhone,
		officerNationality,
		officerEmail,
		officerOccupation,
		officerSourceOfIncome,
		officerAnnualIncome,
		contactPhone,
		contactName,
		contactEmail,
		address,
		phone,
		ein,
		stateOfIncorporation,
		dba,
		name,
		industry,
		businessVertical,
		yearOfIncorporation,
		numberOfEmployees,
		annualRevenue,
		countriesOfOperation,
		cashFlow,
		website,
	}: {
		officerName?: FullName
		officerSSN?: string
		officerPassport?: string
		officerNationality?: string
		officerMatriculaConsular?: string
		officerDob?: Date
		officerAddress?: Address
		officerPhone?: Phone
		officerEmail?: string
		officerOccupation?: Occupation
		officerSourceOfIncome?: SourceOfIncome
		officerAnnualIncome?: AnnualIncome
		contactName?: FullName
		contactPhone?: Phone
		contactEmail?: string
		address?: Address
		phone?: Phone
		ein?: string
		stateOfIncorporation?: string
		dba?: string
		name?: string
		industry?: IndustryType
		businessVertical?: BusinessVertical
		yearOfIncorporation?: string
		numberOfEmployees?: BusinessNumberOfEmployees
		annualRevenue?: BusinessAnnualRevenue
		countriesOfOperation?: string[]
		cashFlow?: CashFlow
		website?: string
	}
) {
	return await updateResource<Application>(`applications/${application.id}`, accessToken, {
		type: application.type,
		attributes: Object.assign(
			{},
			address ? {address: address} : undefined,
			phone ? {phone: phone} : undefined,
			ein ? {ein: ein} : undefined,
			stateOfIncorporation ? {stateOfIncorporation: stateOfIncorporation} : undefined,
			dba ? {dba: dba} : undefined,
			name ? {name: name} : undefined,
			industry ? {industry: industry} : undefined,
			businessVertical ? {businessVertical: businessVertical} : undefined,
			yearOfIncorporation ? {yearOfIncorporation: yearOfIncorporation} : undefined,
			numberOfEmployees ? {numberOfEmployees: numberOfEmployees} : undefined,
			annualRevenue ? {annualRevenue: annualRevenue} : undefined,
			countriesOfOperation ? {countriesOfOperation: countriesOfOperation} : undefined,
			cashFlow ? {cashFlow: cashFlow} : undefined,
			website ? {website: website} : undefined,
			{
				officer: Object.assign(
					{},
					officerName ? {fullName: officerName} : undefined,
					officerSSN ? {ssn: officerSSN} : undefined,
					officerPassport ? {passport: officerPassport} : undefined,
					officerNationality ? {nationality: officerNationality} : undefined,
					officerMatriculaConsular ? {matriculaConsular: officerMatriculaConsular} : undefined,
					officerDob ? {dateOfBirth: moment(officerDob).format("YYYY-MM-DD")} : undefined,
					officerAddress ? {address: officerAddress} : undefined,
					officerPhone ? {phone: officerPhone} : undefined,
					officerEmail ? {email: officerEmail} : undefined,
					officerOccupation ? {occupation: officerOccupation} : undefined,
					officerSourceOfIncome ? {sourceOfIncome: officerSourceOfIncome} : undefined,
					officerAnnualIncome ? {annualIncome: officerAnnualIncome} : undefined
				),
			},
			{
				contact: Object.assign(
					{},
					contactName ? {fullName: contactName} : undefined,
					contactPhone ? {phone: contactPhone} : undefined,
					contactEmail ? {email: contactEmail} : undefined
				),
			}
		),
	})
}

export async function requestDocument(
	accessToken: string,
	applicationId: string,
	documentType: string,
	request: RequestDocumentRequest
) {
	const data = {
		type: documentType,
		attributes: {
			...request,
		},
	}

	return await createResource<Application>(`applications/${applicationId}/documents/request`, accessToken, data)
}

export enum ApplicationType {
	individual = "Individual",
	business = "Business",
}

export const ApplicationTypeIcons: Record<ApplicationType, IcomoonIconName> = {
	[ApplicationType.business]: "toolbox",
	[ApplicationType.individual]: "user-geometric-action---users",
}

export function getApplicationName(application: Application) {
	switch (application.type) {
		case "businessApplication":
			return application.attributes.name
		case "individualApplication":
			return `${application.attributes.fullName.first} ${application.attributes.fullName.last}`
	}
}

export function isBusinessApplicationDocument(
	application: OkDocument<Application>
): application is OkDocument<BusinessApplication> {
	return application.data.type === "businessApplication"
}

export function isIndividualApplicationDocument(
	application: OkDocument<Application>
): application is OkDocument<IndividualApplication> {
	return application.data.type === "individualApplication"
}

export async function getDocumentLink(
	applicationId: string,
	documentId: string,
	accessToken: string,
	side: ApplicationDocumentSide
) {
	return await getResource<DocumentLink>(
		`applications/${applicationId}/documents/${documentId}/link/${side.toLocaleLowerCase()}`,
		accessToken
	)
}

export function hasAdditionalInformation({
	occupation,
	annualIncome,
	sourceOfIncome,
}: {
	occupation?: Occupation
	annualIncome?: AnnualIncome
	sourceOfIncome?: SourceOfIncome
}) {
	return occupation || annualIncome || sourceOfIncome
}

export const DOCUMENT_REASONS = [
	"Altered/Fictitious Documents", //not exists
	"Multiple Failed Requests for Documents", //not exists
]

export const IDENTITY_REASONS = [
	"Identity - failed to verify / high risk",
	"SSN - failed to verify / high risk", //not exists
	"DOB - failed to verify / high risk", //not exists
	"Address - failed to verify / high risk",
	"Name - failed to verify / high risk", //not exists
	"Phone - failed to verify / high risk",
	"Email - high risk",
	"Device/IP - high risk",
]

export const ACTIVITY_REASONS = [
	"Applicant on Unit's internal Denylist", //not exists
	"Fraud Warning", //not exists
	"Fraud Risk", //not exists
	"Suspicious connection to other applications", //not exists
	"Adverse media match",
	"OFAC or other watchlist",
]

export const REASON_CODES_TO_MESSAGE = new Map([
	["R561", "Email - high risk"],
	["R208", "Identity - failed to verify / high risk"],
	["R610", "Address - failed to verify / high risk"],
	["R705", "Address - failed to verify / high risk"],
	["R608", "Phone - failed to verify / high risk"],
	["594", "Identity - failed to verify / high risk"],
	["R566", "Email - high risk"],
	["R601", "Phone - failed to verify / high risk"],
	["641", "Email - high risk"],
	["R606", "Phone - failed to verify / high risk"],
	["230", "Identity - failed to verify / high risk"],
	["R568", "Email - high risk"],
	["R560", "Other"],
	["R617", "Phone - failed to verify / high risk"],
	["I564", "Email - high risk"],
	["R919", "Address - failed to verify / high risk"],
	["R616", "Phone - failed to verify / high risk"],
	["R604", "Phone - failed to verify / high risk"],
	["627", "Device/IP - high risk"],
	["628", "Device/IP - high risk"],
	["R615", "Phone - failed to verify / high risk"],
	["675", "Email - high risk"],
	["593", "Identity - failed to verify / high risk"],
	["R207", "Identity - failed to verify / high risk"],
	["R551", "Email - high risk"],
	["I713", "Address - failed to verify / high risk"],
	["R637", "Phone - failed to verify / high risk"],
	["R636", "Phone - failed to verify / high risk"],
	["R928", "Identity - failed to verify / high risk"],
	["R563", "Email - high risk"],
	["R605", "Phone - failed to verify / high risk"],
	["R620", "Phone - failed to verify / high risk"],
	["R622", "Phone - failed to verify / high risk"],
	["R922", "Identity - failed to verify / high risk"],
	["R619", "Device/IP - high risk"],
	["R642", "Device/IP - high risk"],
	["R720", "Address - failed to verify / high risk"],
	["574", "Phone - failed to verify / high risk"],
	["R902", "Identity - failed to verify / high risk"],
	["R709", "Address - failed to verify / high risk"],
	["R903", "Identity - failed to verify / high risk"],
	["592", "Identity - failed to verify / high risk"],
	["R940", "Identity - failed to verify / high risk"],
	["R934", "Identity - failed to verify / high risk"],
	["R933", "Identity - failed to verify / high risk"],
	["483", "Phone - failed to verify / high risk"],
	["R941", "Phone - failed to verify / high risk"],
	["I917", "Identity - failed to verify / high risk"],
	["R901", "Identity - failed to verify / high risk"],
	["R628", "Phone - failed to verify / high risk"],
	["R569", "Email - high risk"],
	["R713", "Address - failed to verify / high risk"],
	["R948", "Address - failed to verify / high risk"],
	["564", "Phone - failed to verify / high risk"],
	["R701", "Address - failed to verify / high risk"],
	["R939", "Identity - failed to verify / high risk"],
	["R627", "Phone - failed to verify / high risk"],
	["R703", "Address - failed to verify / high risk"],
	["R916", "Address - failed to verify / high risk"],
	["R930", "Identity - failed to verify / high risk"],
	["596", "Identity - failed to verify / high risk"],
	["R947", "Phone - failed to verify / high risk"],
	["R920", "Identity - failed to verify / high risk"],
	["R978", "Identity - failed to verify / high risk"],
	["R624", "Phone - failed to verify / high risk"],
	["233", "Identity - failed to verify / high risk"],
	["R980", "Identity - failed to verify / high risk"],
	["R955", "Identity - failed to verify / high risk"],
	["613", "Identity - failed to verify / high risk"],
	["614", "Identity - failed to verify / high risk"],
	["R204", "Identity - failed to verify / high risk"],
	["R702", "Address - failed to verify / high risk"],
	["R954", "Document verification failed (Technical)"],
	["R927", "Identity - failed to verify / high risk"],
	["R911", "Identity - failed to verify / high risk"],
	["I202", "Document verification failed (Technical)"],
	["R631", "Phone - failed to verify / high risk"],
	["238", "Identity - failed to verify / high risk"],
	["R957", "Identity - failed to verify / high risk"],
	["591", "Identity - failed to verify / high risk"],
	["R613", "Phone - failed to verify / high risk"],
	["R603", "Phone - failed to verify / high risk"],
	["R956", "Identity - failed to verify / high risk"],
	["R712", "Address - failed to verify / high risk"],
	["R961", "Address - failed to verify / high risk"],
	["R626", "Phone - failed to verify / high risk"],
	["595", "Identity - failed to verify / high risk"],
	["R565", "Email - high risk"],
	["597", "Identity - failed to verify / high risk"],
	["232", "Identity - failed to verify / high risk"],
	["R907", "Identity - failed to verify / high risk"],
	["R557", "Email - high risk"],
	["R206", "Identity - failed to verify / high risk"],
	["R621", "Phone - failed to verify / high risk"],
	["R205", "Identity - failed to verify / high risk"],
	["867", "Address - failed to verify / high risk"],
	["658", "Identity - failed to verify / high risk"],
	["R963", "Address - failed to verify / high risk"],
	["180", "Device/IP - high risk"],
	["567", "Phone - failed to verify / high risk"],
	["580", "Email - high risk"],
	["R909", "Identity - failed to verify / high risk"],
	["R567", "Email - high risk"],
	["R629", "Phone - failed to verify / high risk"],
	["R558", "Email - high risk"],
	["R602", "Phone - failed to verify / high risk"],
	["R611", "Phone - failed to verify / high risk"],
	["R707", "Address - failed to verify / high risk"],
	["I714", "Address - failed to verify / high risk"],
	["I911", "Address - failed to verify / high risk"],
	["R630", "Phone - failed to verify / high risk"],
	["520", "Address - failed to verify / high risk"],
	["R625", "Phone - failed to verify / high risk"],
	["524", "Address - failed to verify / high risk"],
	["R202", "Identity - failed to verify / high risk"],
	["600", "Phone - failed to verify / high risk"],
	["646", "Phone - failed to verify / high risk"],
	["R203", "Identity - failed to verify / high risk"],
	["163", "Address - failed to verify / high risk"],
	["657", "Address - failed to verify / high risk"],
	["R562", "Email - high risk"],
	["R187", "Address - failed to verify / high risk"],
	["R708", "Address - failed to verify / high risk"],
	["R350", "Identity - failed to verify / high risk"],
	["R635", "Phone - failed to verify / high risk"],
	["R913", "Identity - failed to verify / high risk"],
	["248", "Identity - failed to verify / high risk"],
	["589", "Address - failed to verify / high risk"],
	["500", "Identity - failed to verify / high risk"],
	["569", "Phone - failed to verify / high risk"],
	["R977", "Identity - failed to verify / high risk"],
	["507", "Identity - failed to verify / high risk"],
	["498", "Address - failed to verify / high risk"],
	["R188", "Address - failed to verify / high risk"],
	["R201", "Identity - failed to verify / high risk"],
	["499", "Identity - failed to verify / high risk"],
	["R564", "Email - high risk"],
	["R351", "Identity - failed to verify / high risk"],
	["I904", "Document verification failed (Technical)"],
	["616", "Phone - failed to verify / high risk"],
	["R559", "Email - high risk"],
	["R520", "Email - high risk"],
	["R641", "Device/IP - high risk"],
	["R704", "Address - failed to verify / high risk"],
	["R932", "Address - failed to verify / high risk"],
	["R979", "Identity - failed to verify / high risk"],
	["I931", "Identity - failed to verify / high risk"],
	["157", "Address - failed to verify / high risk"],
	["506", "Identity - failed to verify / high risk"],
	["626", "Device/IP - high risk"],
	["639", "Address - failed to verify / high risk"],
	["R352", "Identity - failed to verify / high risk"],
	["733", "Address - failed to verify / high risk"],
	["10", "Identity - failed to verify / high risk"],
	["576", "Phone - failed to verify / high risk"],
	["88", "Identity - failed to verify / high risk"],
	["573", "Phone - failed to verify / high risk"],
	["612", "Identity - failed to verify / high risk"],
	["554", "Identity - failed to verify / high risk"],
	["504", "Identity - failed to verify / high risk"],
	["R403", "Device/IP - high risk"],
	["R406", "Device/IP - high risk"],
	["I401", "Device/IP - high risk"],
	["I402", "Device/IP - high risk"],
	["I403", "Device/IP - high risk"],
	["I410", "Device/IP - high risk"],
	["I411", "Device/IP - high risk"],
	["I412", "Device/IP - high risk"],
	["I413", "Device/IP - high risk"],
	["I414", "Device/IP - high risk"],
	["I415", "Device/IP - high risk"],
	["R845", "Identity - failed to verify / high risk"],
	["I856", "Identity - failed to verify / high risk"],
	["R904", "Address - failed to verify / high risk"],
	["R905", "Phone - failed to verify / high risk"],
	["R906", "Identity - failed to verify / high risk"],
	["R908", "Identity - failed to verify / high risk"],
	["R912", "Identity - failed to verify / high risk"],
	["R914", "Phone - failed to verify / high risk"],
	["R915", "Phone - failed to verify / high risk"],
	["R917", "Address - failed to verify / high risk"],
	["R918", "Identity - failed to verify / high risk"],
	["R921", "Phone - failed to verify / high risk"],
	["R929", "Identity - failed to verify / high risk"],
	["R931", "Address - failed to verify / high risk"],
	["I999", "Identity - failed to verify / high risk"],
	["R936", "Phone - failed to verify / high risk"],
	["R937", "Phone - failed to verify / high risk"],
	["R938", "Address - failed to verify / high risk"],
	["R942", "Phone - failed to verify / high risk"],
	["R943", "Phone - failed to verify / high risk"],
	["R945", "Phone - failed to verify / high risk"],
	["R953", "Identity - failed to verify / high risk"],
	["R958", "Address - failed to verify / high risk"],
	["R962", "Phone - failed to verify / high risk"],
	["R966", "Identity - failed to verify / high risk"],
	["R106", "Identity - failed to verify / high risk"],
	["R110", "Email - high risk"],
	["R111", "Identity - failed to verify / high risk"],
	["R112", "Identity - failed to verify / high risk"],
	["R113", "Phone - failed to verify / high risk"],
	["R129", "Adverse media match"],
	["R130", "Adverse media match"],
	["R131", "Adverse media match"],
	["R967", "Phone - failed to verify / high risk"],
	["R968", "Phone - failed to verify / high risk"],
	["R998", "Identity - failed to verify / high risk"],
	["R999", "Identity - failed to verify / high risk"],
	["R355", "Identity - failed to verify / high risk"],
	["R401", "Device/IP - high risk"],
	["321", "Phone - failed to verify / high risk"],
	["510", "Identity - failed to verify / high risk"],
	["514", "Identity - failed to verify / high risk"],
	["539", "Address - failed to verify / high risk"],
	["636", "Address - failed to verify / high risk"],
	["637", "Identity - failed to verify / high risk"],
	["568", "Phone - failed to verify / high risk"],
	["572", "Phone - failed to verify / high risk"],
])

export const REASON_MESSAGE_TO_TOOLTIP = new Map([
	[
		"adverse media match",
		"The individual had a possible adverse media hit. The vendor conducted a web search based on the customer’s name and certain keywords such as “arrested” or “convicted”. Possible adverse media hits could include financial crimes, fraud, narcotics, sexual crimes, terrorism or violent crimes, amongst other things.",
	],
	[
		"fraud warning",
		"Both of the vendors have assigned a high risk fraud score to the applicant. Flags contributing to fraud score including things like application history, type of phone, age of email, email domain, IP location, etc.",
	],
	[
		"fraud risk",
		"One of the vendors has assigned a high risk fraud score to the applicant. Flags contributing to fraud score including things like application history, type of phone, age of email, email domain, IP location, etc.",
	],
	[
		"ofac or other watchlist",
		"There is a potential Office of Foreign Assets (OFAC) match. OFAC sanctions lists consist of individuals as well as countries or organizations that anyone in the US is not legally able to do business with.",
	],
	["multiple failed requests for documents", "The applicant has repeatedly provided invalid documents."],
	["altered/fictitious documents", "The applicant submitted a document that has been altered or a fake document."],
	[
		"previous activity on the unit platform",
		"Unit has previously identified that the applicant is associated with high risk potential",
	],
	["identity - failed to verify / high risk", "Vendor was unable to verify the full identity of the applicant."],
	["ssn - failed to verify / high risk", "Vendor was unable to verify the applicant’s SSN"],
	["dob - failed to verify / high risk", "Vendor was unable to verify the applicant’s date of birth"],
	[
		"address - failed to verify / high risk",
		"Vendor was unable to verify the applicant’s address or the address provided was determined to be undeliverable or a high risk address.",
	],
	[
		"phone - failed to verify / high risk",
		"The phone number provided was determined to be high risk. Reasons may include VoIP phone numbers, prepaid phone numbers, new phone numbers, inactive phone numbers, phones from a state where the customer has no address history, etc.",
	],
	[
		"name - failed to verify / high risk",
		"Vendor was unable to verify the applicant’s name or the applicant applied using a nickname/alias.",
	],
	[
		"email - high risk",
		"The email address provided was determined to be high risk. Reasons include high risk email domains, emails that appear unrelated to the applicant, new/inactive emails, etc.",
	],
	[
		"device/ip - high risk",
		"The device associated with the application was determined to be high risk. Reasons may include the same device being used for other unrelated applications, foreign devices, high fraud scores assigned to the device, IP address a significant distance from the physical address, etc.",
	],
	[
		"applicant on unit's internal denylist",
		"Unit maintains an internal denylist of individuals that are known bad actors on our platform or highly suspicious.",
	],
	[
		"suspicious connection to other applications",
		"Information from this application is linked to other unrelated applications that were submitted or this applicant is linked to other fraudulent applications on the Unit platform.",
	],
])
