import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDown} from "@fortawesome/free-solid-svg-icons"
import React, {useRef, useState} from "react"
import {useClickAway, useEffectOnce, useToggle} from "react-use"
import classNames from "classnames"
import {GreaterThan, InBetween, LessThan} from "./RangeFilterItem"
import {isUndefined, startCase} from "lodash"

export type RangePreset = {
	name: string
	from: number | ""
	to: number | ""
}

type limitValue = number | ""
type setLimitValue = (value: number | "") => void

interface RangeFilterWithPresetsProps {
	placeholder: string
	presets: RangePreset[]
	from: limitValue
	setFrom: setLimitValue
	to: limitValue
	setTo: setLimitValue
}

export function CurrencyRangeFilter({placeholder, presets, from, setFrom, to, setTo}: RangeFilterWithPresetsProps) {
	const [isDropdownActive, toggleDropdown] = useToggle(false)
	const ref = useRef(null)
	const [status, setStatus] = useState("All")

	useClickAway(ref, () => toggleDropdown(false))
	const dropdownClassname = classNames("dropdown", isDropdownActive && "is-active")

	const presetsFromValues = presets.map((preset) => preset.from.toString())
	const presetsToValues = presets.map((preset) => preset.to.toString())

	const [fromDefault, setFromDefault] = useState(from)
	const [toDefault, setToDefault] = useState(to)

	useEffectOnce(() => {
		const usedPreset = presets.find((preset) => preset.from.toString() === from && preset.to.toString() === to)
		if (from === "" && to === "") {
			setStatus("All")
		}
		if (usedPreset) {
			setStatus(usedPreset.name)
		} else {
			if (from == "" && to != "" && !presetsToValues.includes(to.toString())) {
				setStatus("Less than")
			}
			if (to == "" && from != "" && !presetsFromValues.includes(from.toString())) {
				setStatus("Greater than")
			}
			if (from != "" && to != "") {
				setStatus("In between")
			}
		}

		setFromDefault(from)
		setToDefault(to)
	})

	useClickAway(ref, () => {
		if (from === "" && to === "") {
			setStatus("All")
		}
	})

	return (
		<div className="radio-filter" ref={ref}>
			<div className={dropdownClassname}>
				<div className="dropdown-trigger">
					<button
						className="button"
						aria-haspopup="true"
						aria-controls="dropdown-menu3"
						onClick={() => toggleDropdown()}
					>
						<div className={"filter-title-placeholder"}>
							{status != "All" && <div className="filter-title-count" />}
							<span>{placeholder}</span>
						</div>
						<span className="icon is-small dropdown-icon">
							{" "}
							<FontAwesomeIcon icon={faAngleDown} />{" "}
						</span>
					</button>
				</div>
				<div className="dropdown-menu" role="menu">
					<div className="dropdown-content">
						<a
							className={"dropdown-item"}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								setStatus("All")
								setTo("")
								setFrom("")
							}}
						>
							<div className="control">
								<label className="radio">
									<input readOnly type="radio" name={"radio-filter-all"} checked={status === "All"} />
									{"All"}
								</label>
							</div>
						</a>

						{Object.entries(presets).map(([_, preset]) => {
							return (
								<a
									key={preset.name}
									className={"dropdown-item"}
									onClick={(e) => {
										e.preventDefault()
										e.stopPropagation()
										setStatus(preset.name)
										if (!isUndefined(preset.to)) {
											setTo(preset.to)
										} else {
											setTo("")
										}
										if (!isUndefined(preset.from)) {
											setFrom(preset.from)
										} else {
											setFrom("")
										}
									}}
								>
									<div className="control">
										<label className="radio">
											<input readOnly type="radio" name={startCase(preset.name)} checked={status === preset.name} />
											{preset.name}
										</label>
									</div>
								</a>
							)
						})}

						<GreaterThan
							selected={status === "Greater than"}
							defaultValue={fromDefault ? fromDefault / 100 : undefined}
							onSelect={() => {
								setTo("")
								setFrom("")
								setStatus("Greater than")
							}}
							onChange={(value) => {
								setFrom(value)
							}}
						/>

						<LessThan
							selected={status === "Less than"}
							defaultValue={toDefault ? toDefault / 100 : undefined}
							onSelect={() => {
								setTo("")
								setFrom("")
								setStatus("Less than")
							}}
							onChange={(value) => {
								setTo(value)
							}}
						/>

						<InBetween
							selected={status === "In between"}
							onSelect={() => {
								setTo("")
								setFrom("")
								setStatus("In between")
							}}
							onChangeFrom={(value) => {
								setFrom(value)
							}}
							onChangeTo={(value) => {
								setTo(value)
							}}
							defaultFrom={fromDefault ? fromDefault / 100 : undefined}
							defaultTo={toDefault ? toDefault / 100 : undefined}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
