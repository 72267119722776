import React from "react"
import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getDynamicInterestRate} from "../../resources/interestRate"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {formatPercent} from "../../utilities/numbers"
import {InterestRateItem} from "./InterestRateItem"

const RATE_FORMAT = "111.[11]%"

const InterestRatePending = () => (
	<>
		<InterestRateItem icon={"Percentage Increase"} />
		<InterestRateItem icon={"Percentage Decrease"} />
	</>
)

export function InterestRateOverview() {
	const accessToken = useAccessToken()
	const interestRateResult = useAsyncResult(() => getDynamicInterestRate(accessToken))
	return (
		<div className={"interest-rate-overview"}>
			<AsyncResultComponent asyncResult={interestRateResult} pendingComponent={<InterestRatePending />}>
				{({value: interestRate}) => {
					const {
						data: {
							attributes: {upperBoundRate, lowerBoundRate, effectiveFrom},
						},
					} = interestRate
					const upperTargetRange = upperBoundRate / 100
					const lowerTargetRange = lowerBoundRate / 100
					const additionalInfoString = `Effective from ${effectiveFrom}`
					return (
						<>
							<InterestRateItem
								icon={"Percentage Increase"}
								title={"Upper target range"}
								value={formatPercent(upperTargetRange, RATE_FORMAT)}
								additionalInfo={additionalInfoString}
							/>
							<InterestRateItem
								icon={"Percentage Decrease"}
								title={"Lower target range"}
								value={formatPercent(lowerTargetRange, RATE_FORMAT)}
								additionalInfo={additionalInfoString}
							/>
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}

export const InterestRate = () => (
	<div className="card">
		<div className="interest-rate-container">
			<h3>Federal Funds Rate</h3>
			<div className="columns is-align-items-center">
				<div className="column is-5 is-align-self-flex-end">
					<InterestRateOverview />
				</div>
			</div>
		</div>
	</div>
)
