import React from "react"
import {useParams} from "react-router-dom"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useAccessToken} from "../../services/auth"
import {
	Customer,
	getCustomer,
	isBusinessDocument,
	isBusinessFBODocument,
	isIndividualDocument,
	isIndividualWalletDocument,
} from "../../resources/customer"
import {OkDocument} from "../../resources/common"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import Business from "../Business/Business"
import {Individual} from "../Individual/Individual"
import BusinessFBO from "../BusinessFBO/BusinessFBO"
import {IndividualWallet} from "../IndividualWallet/IndividualWallet"

export type SpecificCustomerProps<T> = {
	value: OkDocument<T>
	refresh: () => void
}

function CustomerInternal({value: customer, refresh}: {value: OkDocument<Customer>; refresh: () => void}) {
	const commonProps = {refresh}
	if (isBusinessDocument(customer)) {
		return <Business value={customer} {...commonProps} />
	}
	if (isIndividualDocument(customer)) {
		return <Individual value={customer} {...commonProps} />
	}

	if (isBusinessFBODocument(customer)) {
		return <BusinessFBO value={customer} {...commonProps} />
	}

	if (isIndividualWalletDocument(customer)) {
		return <IndividualWallet value={customer} {...commonProps} />
	}

	return <React.Fragment />
}

export default function CustomerPage() {
	const {customerId = ""} = useParams<{customerId: string}>()
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const asyncResult = useAsyncResult(() => getCustomer(accessToken, customerId), [refreshToken])

	return (
		<AsyncResultComponent asyncResult={asyncResult} refresh={refresh}>
			{({value}) => {
				return <CustomerInternal value={value as OkDocument<Customer>} refresh={refresh} />
			}}
		</AsyncResultComponent>
	)
}
