import {createResource, ErrorDocument, getFile, getResource, OkDocument, Resource, uploadResource} from "./common"
import {Result} from "neverthrow"
import {FileMetadata} from "../utilities/file"
import {WireCounterparty} from "./payment"
import {OfacAddress, OfacHitsReviewReason} from "./incomingWire"
import {Dispatch, SetStateAction} from "react"

export enum AssigneeType {
	Unit = "Unit",
	Org = "Org",
	Bank = "Bank",
}

export enum AssignmentStatus {
	AwaitingApproval = "AwaitingApproval",
	AwaitingSubmission = "AwaitingSubmission",
	AwaitingReview = "AwaitingReview",
	Approved = "Approved",
	Submitted = "Submitted",
	Cancelled = "Cancelled",

	Rejected = "Rejected",
	AutomaticallyClosed = "AutomaticallyClosed",
	Escalated = "Escalated",
	FalsePositive = "FalsePositive",
}

export enum AssignmentStage {
	Pending = "Pending",
	Completed = "Completed",
}

export type Assignee = {
	type: AssigneeType
	name?: string
}

export enum AssignmentType {
	callbackAssignment = "callbackAssignment",
	callbackReviewAssignment = "callbackReviewAssignment",
	ofacCheckAssignment = "ofacCheckAssignment",
	generalReviewAssignment = "generalReviewAssignment",
}

export enum AssignmentActionRequestType {
	// Callback
	callbackSubmitRequest = "callbackSubmitRequest",
	callbackCancelRequest = "callbackCancelRequest",
	callbackSubmitDocumentRequest = "callbackSubmitDocumentRequest",
	// Callback Review
	callbackReviewApproveRequest = "callbackReviewApproveRequest",
	callbackReviewRejectRequest = "callbackReviewRejectRequest",
	// Ofac Check
	ofacCheckFalsePositiveRequest = "ofacCheckFalsePositiveRequest",
	ofacCheckEscalateRequest = "ofacCheckEscalateRequest",
	// General Review
	generalReviewApproveRequest = "generalReviewApproveRequest",
	generalReviewRejectRequest = "generalReviewRejectRequest",
}

export enum OfacCheckAssignmentFalsePositiveReason {
	NAV = "NAV",
	NAE = "NAE",
	NAI = "NAI",
	NMM = "NMM",
	DOB = "DOB",
	ADD = "ADD",
}

export enum GeneralReviewRejectReason {
	SuspectedFraud = "SuspectedFraud",
	ClientRequest = "ClientRequest",
	InsufficientFunds = "InsufficientFunds",
	InvalidName = "InvalidName",
	InvalidRoutingNumber = "InvalidRoutingNumber",
	Other = "Other",
}

export type AssignmentItemId = string

export type CallbackDocumentMetadata = {
	documentName: string
	documentSize: number
}

export type OfacHit = OfacHitsReviewReason
export type AssignmentOfacAddress = OfacAddress

export type OfacMatchDetails = {
	SDNName: string
	addresses: JSX.Element
	aliases: string
	SDNType: string
	program: string
	remarks: string
}

export type CallbackAssignmentItemPayload = {
	callbackSummary?: string
	callbackDocumentMetadata?: CallbackDocumentMetadata
}

export type CallbackReviewAssignmentItemPayload = {
	callbackSummary: string
	callbackDocumentMetadata?: CallbackDocumentMetadata
	evidenceCollector: Assignee
	reasonText?: string
}

export type OfacCheckAssignmentItemPayload = {
	ofacHit: OfacHit
	wireDetails: Partial<WireCounterparty>
	reason?: OfacCheckAssignmentFalsePositiveReason
	reasonText?: string
}

export type GeneralReviewAssignmentItemPayload = {
	reason?: GeneralReviewRejectReason
	reasonText?: string
}

export type AssignmentItemPayload =
	| CallbackAssignmentItemPayload
	| CallbackReviewAssignmentItemPayload
	| OfacCheckAssignmentItemPayload
	| GeneralReviewAssignmentItemPayload

export interface AssignmentItemCommonProps {
	id: AssignmentItemId
	title: string
	description?: string | JSX.Element
	status: AssignmentStatus
	stage: AssignmentStage
	assignees: Assignee[]
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
}

export interface CallbackAssignmentItem extends AssignmentItemCommonProps {
	type: AssignmentType.callbackAssignment
	payload: CallbackAssignmentItemPayload
}

export interface CallbackReviewAssignmentItem extends AssignmentItemCommonProps {
	type: AssignmentType.callbackReviewAssignment
	payload?: CallbackReviewAssignmentItemPayload
}

export interface OfacCheckAssignmentItem extends AssignmentItemCommonProps {
	type: AssignmentType.ofacCheckAssignment
	payload: OfacCheckAssignmentItemPayload
}

export interface GeneralReviewAssignmentItem extends AssignmentItemCommonProps {
	type: AssignmentType.generalReviewAssignment
	payload: GeneralReviewAssignmentItemPayload
}

export type AssignmentItem =
	| CallbackAssignmentItem
	| CallbackReviewAssignmentItem
	| OfacCheckAssignmentItem
	| GeneralReviewAssignmentItem

export enum AssignmentResourceType {
	OutgoingWire = "OutgoingWire",
	IncomingWire = "IncomingWire",
}

type AssignmentCommonAttributes = {
	createdAt: Date
	assignee: AssigneeType
	status: AssignmentStatus
	stage: AssignmentStage
	statusUpdatedAt: Date
	resourceType: AssignmentResourceType
}

export type CallbackAssignmentAttributes = AssignmentCommonAttributes & {
	callbackSummary?: string
	callbackDocumentMetadata?: CallbackDocumentMetadata
}

export type CallbackReviewAssignmentAttributes = AssignmentCommonAttributes & {
	reasonText?: string
}

export type OfacCheckAssignmentAttributes = AssignmentCommonAttributes & {
	ofacHit: OfacHitsReviewReason
	counterpartyDetails: WireCounterparty
	reason?: OfacCheckAssignmentFalsePositiveReason
	reasonText?: string
}

export type GeneralReviewAssignmentAttributes = AssignmentCommonAttributes & {
	reason?: GeneralReviewRejectReason
	reasonText?: string
}

export type AssignmentAttributes =
	| CallbackAssignmentAttributes
	| CallbackReviewAssignmentAttributes
	| OfacCheckAssignmentAttributes
	| GeneralReviewAssignmentAttributes

export type AssignmentCommonRelationships = {
	approval: {
		data: {
			type: "approval"
			id: string
		}
	}
	org: {
		data: {
			type: "org"
			id: string
		}
	}
	bank: {
		data: {
			type: "bank"
			id: string
		}
	}
	customer: {
		data: {
			type: "customer"
			id: string
		}
	}
	payment?: {
		data: {
			type: "payment"
			id: string
		}
	}
}

type CallbackAssignmentRelationships = AssignmentCommonRelationships

export type CallbackReviewAssignmentRelationships = AssignmentCommonRelationships & {
	callbackAssignment: {
		data: {
			type: "callbackAssignment"
			id: string
		}
	}
}

type OfacCheckAssignmentRelationships = AssignmentCommonRelationships

type GeneralReviewAssignmentRelationships = AssignmentCommonRelationships

export type AssignmentRelationships =
	| CallbackAssignmentRelationships
	| CallbackReviewAssignmentRelationships
	| OfacCheckAssignmentRelationships
	| GeneralReviewAssignmentRelationships

export type CallbackAssignment = {
	type: AssignmentType.callbackAssignment
	id: string
	attributes: CallbackAssignmentAttributes
	relationships: CallbackAssignmentRelationships
}

export type CallbackReviewAssignment = {
	type: AssignmentType.callbackReviewAssignment
	id: string
	attributes: AssignmentCommonAttributes
	relationships: CallbackReviewAssignmentRelationships
}

export type OfacCheckAssignment = {
	type: AssignmentType.ofacCheckAssignment
	id: string
	attributes: OfacCheckAssignmentAttributes
	relationships: OfacCheckAssignmentRelationships
}

export type GeneralReviewAssignment = {
	type: AssignmentType.generalReviewAssignment
	id: string
	attributes: AssignmentCommonAttributes
	relationships: GeneralReviewAssignmentRelationships
}

export type Assignment = CallbackAssignment | CallbackReviewAssignment | OfacCheckAssignment | GeneralReviewAssignment

export function isCallbackAssignment(resource: Resource): resource is CallbackAssignment {
	return resource.type === AssignmentType.callbackAssignment
}

type assignmentSort = "createdAt" | "-createdAt" | "statusUpdatedAt" | "-statusUpdatedAt" | "deadline" | "-deadline"

export type FindAssignmentsArgs = {
	approvals?: string[]
	accessToken: string
	include?: string
	offset?: number
	limit?: number | null
	ids?: string[]
	orgs?: string[]
	banks?: string[]
	stages?: string[]
	assignmentTypes?: string[]
	assignees?: string[]
	resourceTypes?: string[]
	since?: string
	until?: string
	sort?: assignmentSort
}
export async function findAssignments({
	approvals,
	accessToken,
	include,
	offset,
	limit,
	ids,
	orgs,
	banks,
	stages,
	assignmentTypes,
	assignees,
	resourceTypes,
	since,
	until,
	sort,
}: FindAssignmentsArgs): Promise<Result<OkDocument<Assignment[]>, ErrorDocument>> {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const banksFilter = banks && banks.length > 0 ? banks : undefined
	const assignmentTypesFilter = assignmentTypes && assignmentTypes.length > 0 ? assignmentTypes : undefined
	const stagesFilter = stages && stages.length > 0 ? stages : undefined
	const assigneesFilter = assignees && assignees.length > 0 ? assignees : undefined
	const resourceTypesFilter = resourceTypes && resourceTypes.length > 0 ? resourceTypes : undefined
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			ids,
			approvals,
			orgs: orgFilter,
			banks: banksFilter,
			stages: stagesFilter,
			assignmentTypes: assignmentTypesFilter,
			assignees: assigneesFilter,
			resources: resourceTypesFilter,
			since: since != "" ? since : null,
			until: until != "" ? until : null,
		},
		sort,
		include,
	}
	return await getResource<Array<Assignment>>("assignments", accessToken, query)
}

export async function submitDocument({
	accessToken,
	id,
	documentFile,
	documentMetadata,
	documentType,
}: {
	accessToken: string
	id: string
	documentFile: File
	documentMetadata?: FileMetadata
	documentType: string
}) {
	return await uploadResource<Assignment>({
		path: `assignments/${id}/document`,
		accessToken,
		data: documentFile,
		type: documentType,
		metadata: documentMetadata,
	})
}

export async function submitDocumentAndSubmitAssignment({
	accessToken,
	id,
	document,
	documentMetadata,
	documentType,
	setFile,
	summary,
}: {
	accessToken: string
	id: string
	document?: File
	documentMetadata?: FileMetadata
	documentType?: string
	setFile?: Dispatch<SetStateAction<File | undefined>>
	summary: string
}) {
	const callbackSubmitRequestData: CallbackSubmitRequest = {
		type: AssignmentActionRequestType.callbackSubmitRequest,
		attributes: {
			summary,
		},
	}
	if (!document) {
		return await update({accessToken, id, data: callbackSubmitRequestData})
	}
	const submitDocumentResult = await submitDocument({
		accessToken,
		id,
		documentFile: document,
		documentMetadata,
		documentType: documentType ?? "application/pdf",
	})
	return submitDocumentResult
		.asyncMap(async () => {
			return await update({accessToken, id, data: callbackSubmitRequestData})
		})
		.andThen((submitResult) => {
			setFile?.(undefined)
			return submitResult
		})
}

export async function update({
	accessToken,
	id,
	data,
}: {
	accessToken: string
	id: string
	data: AssignmentActionRequest
}) {
	return await createResource<Assignment>(`assignments/${id}/update`, accessToken, data)
}

export type CallbackSubmitRequest = {
	type: AssignmentActionRequestType.callbackSubmitRequest
	attributes: {
		summary: string
	}
}

export type CallbackCancelRequest = {
	type: AssignmentActionRequestType.callbackCancelRequest
	attributes: {
		summary: string
	}
}

export type CallbackSubmitDocumentRequest = {
	type: AssignmentActionRequestType.callbackSubmitDocumentRequest
	attributes: {
		documentKey: string
		documentName: string
		documentSize: number
	}
}

type CallbackActionRequest = CallbackSubmitRequest | CallbackCancelRequest | CallbackSubmitDocumentRequest

export type CallbackReviewApproveRequest = {
	type: AssignmentActionRequestType.callbackReviewApproveRequest
}

export type CallbackReviewRejectRequest = {
	type: AssignmentActionRequestType.callbackReviewRejectRequest
	attributes: {
		reasonText: string
	}
}

type CallbackActionUpdateRequest = CallbackReviewApproveRequest | CallbackReviewRejectRequest

export type OfacCheckFalsePositiveRequest = {
	type: AssignmentActionRequestType.ofacCheckFalsePositiveRequest
	attributes: {
		reason: OfacCheckAssignmentFalsePositiveReason
		reasonText: string
	}
}

export type OfacCheckEscalateRequest = {
	type: AssignmentActionRequestType.ofacCheckEscalateRequest
	attributes: {
		reasonText: string
	}
}

type OfacCheckActionRequest = OfacCheckFalsePositiveRequest | OfacCheckEscalateRequest

export type GeneralReviewApproveRequest = {
	type: AssignmentActionRequestType.generalReviewApproveRequest
	attributes: {
		reasonText?: string
	}
}

export type GeneralReviewRejectRequest = {
	type: AssignmentActionRequestType.generalReviewRejectRequest
	attributes: {
		reason: GeneralReviewRejectReason
		reasonText?: string
	}
}

type GeneralReviewActionRequest = GeneralReviewApproveRequest | GeneralReviewRejectRequest

type AssignmentActionRequest =
	| CallbackActionRequest
	| CallbackActionUpdateRequest
	| OfacCheckActionRequest
	| GeneralReviewActionRequest

export async function getDocument({accessToken, id, fileType}: {accessToken: string; id: string; fileType: string}) {
	return await getFile(`assignments/${id}/document`, accessToken, {}, fileType, "blob")
}
