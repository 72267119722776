import React from "react"
import {useAsyncResult} from "../../hooks/useAsyncResult"

import {useModal} from "react-modal-hook"
import {PlaidDataForLinkedAccount} from "../PlaidData/PlaidData"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import classNames from "classnames"

import {KeyValueCardPending} from "../KeyValueCard/KeyValueCard"
import Icon from "../Icon/Icon"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {CustomerCard} from "../Customers/CustomerCard"
import {useIsUnitUser} from "../../services/auth"
import {Org} from "../../resources/org"
import {Customer} from "../../resources/customer"
import {BeneficialOwnerCards} from "../BeneficialOwners/BeneficalOwnerCard"
import {Account} from "../../resources/account"

import {getLinkedAccount, LinkedAccount, LinkedAccountStatusEnum} from "../../resources/linkedAccount"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {DeclineLinkedAccountModal} from "./DeclineLinkedAccount"
import {ApproveLinkedAccountModal} from "./ApproveLinkedAccount"
import {DeleteLinkedAccountModal} from "./DeleteLinkedAccount"
import {useNavigate} from "react-router-dom"

interface LinkedAccountComponentProps {
	linkedAccountId: string
	linkedAccountAsyncResult?: AsyncResultRequestState<OkDocument<LinkedAccount>, ErrorDocument>
	accessToken: string
	useDrawer: boolean
	refresh: () => void
	org?: Org
	customers?: Customer[]
	account?: Account
}

function LinkedAccountInfoPending() {
	return (
		<div className="linked-accounts-content">
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
		</div>
	)
}

export function LinkedAccountButtons({
	linkedAccount,
	refresh,
}: {
	linkedAccount: LinkedAccount
	refresh: () => void
	accessToken: string
}) {
	const goBack = useNavigate()

	const [showDecline, hideDecline] = useModal(() => (
		<DeclineLinkedAccountModal close={hideDecline} linkedAccount={linkedAccount} onSuccess={refresh} />
	))
	const [showApprove, hideApprove] = useModal(() => (
		<ApproveLinkedAccountModal close={hideApprove} linkedAccount={linkedAccount} onSuccess={refresh} />
	))
	const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(
		() => (
			<DeleteLinkedAccountModal
				close={hideDeleteConfirmationModal}
				linkedAccount={linkedAccount}
				onSuccess={refresh}
				back={goBack}
			/>
		),
		[]
	)

	const canDecline = linkedAccount.attributes.status == LinkedAccountStatusEnum.PendingReview
	const canApprove = linkedAccount.attributes.status == LinkedAccountStatusEnum.PendingReview

	return (
		<>
			{
				<button className="button is-danger is-outlined is-right" onClick={() => showDeleteConfirmationModal()}>
					<span className="icon">
						<Icon icon="disable-delete--interface-essential" size={12} />
					</span>
					<span> Delete </span>
				</button>
			}

			{canDecline && (
				<button className="button is-danger" onClick={() => showDecline()}>
					<span className="icon">
						<Icon icon="interface-delete-interface-essential" size={12} />
					</span>
					<span> Decline </span>
				</button>
			)}

			{canApprove && (
				<button className="button is-success" onClick={() => showApprove()}>
					<span className="icon">
						<Icon icon="interface-validation-check--interface-essential" size={12} />
					</span>
					<span> Approve </span>
				</button>
			)}
		</>
	)
}

function LinkedAccountInternal({
	accessToken,
	customers,
	org,
}: {
	linkedAccount: LinkedAccount
	accessToken: string
	refresh: () => void
	customers?: Customer[]
	org?: Org
}) {
	return (
		<>
			<div className={classNames("linked-accounts-content", "pr-3")}>
				{useIsUnitUser() && (
					<>
						{customers?.map((customer) => {
							return (
								<React.Fragment key={customer.id}>
									<CustomerCard org={org} customer={customer} customerId={customer.id} />
									{customer.relationships.application && customer.type === "businessCustomer" && (
										<BeneficialOwnerCards
											applicationId={customer.relationships.application?.data.id}
											accessToken={accessToken}
										/>
									)}
								</React.Fragment>
							)
						})}
					</>
				)}
			</div>
		</>
	)
}

export function LinkedAccountPlaidData({linkedAccount}: {linkedAccount: LinkedAccount}) {
	return (
		<UnitUserOnly>
			<div className={"linked-accounts-plaid-card"}>
				<div className={"linked-accounts-plaid-card-header"}>
					<span> Plaid Details </span>
				</div>
				<PlaidDataForLinkedAccount linkedAccount={linkedAccount} />
			</div>
		</UnitUserOnly>
	)
}

export function LinkedAccountComponent({
	linkedAccountId,
	accessToken,
	refresh,
	linkedAccountAsyncResult,
	customers,
	org,
}: LinkedAccountComponentProps) {
	const include = useIsUnitUser()
	const linkedAccountAsyncResultReq =
		linkedAccountAsyncResult ||
		useAsyncResult(
			() => getLinkedAccount(accessToken, linkedAccountId, include ? "customer,org" : undefined),
			[refresh]
		)

	return (
		<AsyncResultComponent asyncResult={linkedAccountAsyncResultReq} pendingComponent={<LinkedAccountInfoPending />}>
			{({value: linkedAccount}) => {
				return (
					<LinkedAccountInternal
						org={org}
						linkedAccount={linkedAccount.data}
						customers={customers}
						accessToken={accessToken}
						refresh={refresh}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}
