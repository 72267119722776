import React from "react"
import ReactTooltip from "react-tooltip"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"
import {extractExtensionAndFileName, formatFileSize} from "../../utilities/file"
import classNames from "classnames"
import {toLower} from "lodash"
interface FileDetailsProps {
	name?: string
	size?: number
	operation?: FileOperation
	disabled?: boolean
}

type FileOperation = "upload" | "download"

const getFileIcon = (fileExtension?: string, operation?: FileOperation): IcomoonIconName | null => {
	switch (toLower(fileExtension)) {
		case "mp3":
		case "wav":
			return operation === "upload" ? "document-audio-music-upload" : "document-audio-music"
		case "jpeg":
		case "jpg":
		case "png":
			return operation === "upload" ? "document-image-upload" : "document-image-file"
		case "pdf":
			return operation === "upload" ? "document-file-upload" : "common-file---files-&-folders"
		case "docx":
			return operation === "upload" ? "document-text-upload" : "common-file-text---files-&-folders"
		case "mp4":
			return operation === "upload" ? "document-video-upload" : "document-video-file"
		default:
			return operation === "upload" ? "document-file-upload" : "common-file---files-&-folders"
	}
}

export function FileDetails({name, size, operation = "upload", disabled}: FileDetailsProps) {
	const {extension, nameWithoutExtension} = extractExtensionAndFileName(name)
	const tooltipText = `${nameWithoutExtension}${extension ? `.${extension}` : ""}`
	const icon = getFileIcon(extension, operation)
	return (
		<div className="file-details">
			<div className={classNames("icon-text file-details-file-icon", disabled && "disabled")}>
				{icon && (
					<span className="icon">
						<Icon icon={icon} size={16} />
					</span>
				)}
				<span
					className={classNames("file-details-file-name", disabled && "disabled")}
					data-tip={tooltipText}
					data-for={`file-name-tooltip-for-${name}`}
				>
					<span className="file-details-file-name-without-extension">{nameWithoutExtension}</span>
					{extension && <span>.{extension}</span>}
				</span>
			</div>
			<ReactTooltip
				id={`file-name-tooltip-for-${name}`}
				place="top"
				effect="solid"
				className="file-details-file-name-tooltip"
			/>
			{size && <span className="file-details-file-size">{formatFileSize(size)}</span>}
		</div>
	)
}
