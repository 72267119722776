import React from "react"
import {Circle, CircleSize} from "../../components/Circle/Circle"
import {AccountStatus, AccountStatusAudit} from "../../resources/account"
import classNames from "classnames"
import HorizontalField from "../../components/HorizontalField/HorizontalField"
import {StatusChangeTooltip} from "./StatusAudit"

function getAccountStatusColor(status: string): string {
	switch (status.toLowerCase()) {
		case AccountStatus.Closed.toLowerCase():
			return "account-status-color closed"
		case AccountStatus.Frozen.toLowerCase():
			return "account-status-color frozen"
		default:
			return "account-status-color"
	}
}

export function AccountStatusField({
	status,
	accountStatusAudit,
}: {
	status: AccountStatus
	accountStatusAudit: AccountStatusAudit | undefined
}) {
	const infoTooltip = accountStatusAudit?.attributes?.setAt && (
		<StatusChangeTooltip status={status} accountStatusAudit={accountStatusAudit}></StatusChangeTooltip>
	)
	return (
		<div>
			<HorizontalField label="Status">
				<div className="flex-container justify-content-start align-items-center">
					<Circle size={CircleSize.xs} className={classNames("margin-r-4", getAccountStatusColor(status))} />
					<span className="is-static">{status}</span>
					{infoTooltip}
				</div>
			</HorizontalField>
		</div>
	)
}
