import React, {PropsWithChildren} from "react"
import {
	Claims,
	useIsBankAdminUser,
	useIsBankAdminOrOperationsUser,
	useIsBankUser,
	useIsOrgAdmin,
	useIsOrgUser,
	useIsPartnerUser,
	useIsUnitAdminReadonlyUser,
	useIsUnitAdminUser,
	useIsUnitUser,
} from "../../services/auth"

export function UnitUserOnly({children}: PropsWithChildren<{}>) {
	if (useIsUnitUser()) return <>{children}</>
	return null
}

export function UnitAdminOnly({children, claims}: PropsWithChildren<{claims?: Claims}>) {
	if (useIsUnitAdminUser(claims)) return <>{children}</>
	return null
}

export function UnitAdminReadOnly({children, claims}: PropsWithChildren<{claims?: Claims}>) {
	if (useIsUnitAdminReadonlyUser(claims)) return <>{children}</>
	return null
}

export function UnitAdminOrgAdminBankOperationsOnly({children}: PropsWithChildren<{}>) {
	const isUnitAdmin = useIsUnitAdminUser()
	const isOrgAdmin = useIsOrgAdmin()
	const isBankAdminOrOperations = useIsBankAdminOrOperationsUser()

	if (isUnitAdmin || isOrgAdmin || isBankAdminOrOperations) return <>{children}</>
	return null
}

export function UnitOrOrgOrPartnerOnlyUser({children}: PropsWithChildren<{}>) {
	const isUnitUser = useIsUnitUser()
	const isOrgUser = useIsOrgUser()
	const isPartnerUser = useIsPartnerUser()

	if (isUnitUser || isOrgUser || isPartnerUser) return <>{children}</>
	return null
}

export function UnitOrBankUserOnly({children}: PropsWithChildren<{}>) {
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()

	if (isUnitUser || isBankUser) return <>{children}</>
	return null
}

export function UnitOrOrgOrPartnerOrBankAdminOnlyUser({children}: PropsWithChildren<{}>) {
	const isUnitUser = useIsUnitUser()
	const isOrgUser = useIsOrgUser()
	const isPartnerUser = useIsPartnerUser()
	const isBankAdminUser = useIsBankAdminUser()

	return isUnitUser || isOrgUser || isPartnerUser || isBankAdminUser ? <>{children}</> : null
}
