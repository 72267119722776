import React from "react"

export function DefaultIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={81} height={81} fill="none">
			<rect width={80} height={80} x={0.575} y={0.793} fill="#fff" rx={4.646} />
			<path
				fill="#F4F5F7"
				d="M22.037 66.964h36.946a4.592 4.592 0 0 0 4.592-4.592V31.768a3.516 3.516 0 0 0-1.013-2.468L49.014 15.64a3.458 3.458 0 0 0-2.449-1.023H22.037a3.45 3.45 0 0 0-2.448 1.022 3.504 3.504 0 0 0-1.014 2.468v45.367c0 .926.365 1.813 1.014 2.468a3.447 3.447 0 0 0 2.448 1.022Z"
			/>
			<path
				fill="#E2E4E7"
				d="M47.044 28.393V14.617c.735 0 1.53.612 1.837.919L62.656 29.31c.735.735.919 2.143.919 2.755H50.717a3.674 3.674 0 0 1-3.673-3.673Z"
			/>
		</svg>
	)
}
