import React, {ReactElement} from "react"
import {AllowedReceivedPaymentsColumns, ReceivedPaymentsColumns} from "./ReceivedPayments"
import {useNavigate} from "react-router-dom"
import {kebabCase, startCase, upperCase} from "lodash"
import {DataTableCell, DataTableRow} from "../DataTable/DataTable"
import classNames from "classnames"
import {OrgName} from "../OrgName/OrgName"
import numberal from "numeral"
import ReactTooltip from "react-tooltip"
import moment from "moment"
import {Resource} from "../../resources/common"
import {ReceivedPayment, ReceivedPaymentTypes} from "../../resources/receivedPayments"

interface ReceivedPaymentRowProps {
	receivedPayment: ReceivedPayment
	include?: Resource[]
	showOrgColumn?: boolean
	includedColumns: Array<AllowedReceivedPaymentsColumns>
	refresh: () => void
	selectedReceivedPaymentId?: string
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

export function ReceivedPaymentRow({
	receivedPayment,
	include,
	includedColumns,
	selectedReceivedPaymentId,
	onClick,
}: ReceivedPaymentRowProps) {
	const navigate = useNavigate()
	const id = receivedPayment.id
	const org = receivedPayment.relationships.org?.data.id
	const typeCasingFunction = receivedPayment.type === ReceivedPaymentTypes.ACH ? upperCase : startCase
	const type = typeCasingFunction(receivedPayment.type.replace(/received payment/gi, ""))
	const accountId = receivedPayment.relationships.account.data.id
	const customerId = receivedPayment.relationships.customer?.data.id
	const companyName = receivedPayment.attributes.companyName
	const amount = receivedPayment.attributes.amount
	const status = receivedPayment.attributes.status
	const reason = receivedPayment.attributes.returnReason
	const createdAt = receivedPayment.attributes.createdAt
	const completionDate = receivedPayment.attributes.completionDate

	const customer =
		customerId && include
			? include.find(
					(r) => (r.type == "individualCustomer" || r.type == "businessCustomer") && r.id == customerId.toString()
			  )
			: undefined
	let customerName = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedReceivedPaymentsColumns, ReactElement>> = {}

	if (includedColumns.includes(ReceivedPaymentsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.type)) {
		contentColumns["Type"] = <DataTableCell>{type} </DataTableCell>
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.companyName)) {
		contentColumns["Company Name"] = <DataTableCell>{companyName}</DataTableCell>
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} element={DataTableCell} />
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell
				className={classNames(
					receivedPayment.attributes.direction === "Credit" && "has-text-success",
					receivedPayment.attributes.direction === "Debit" && "has-text-danger"
				)}
			>
				<span>{numberal(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.status)) {
		let tooltipValue = undefined
		if (reason) {
			tooltipValue = startCase(reason)
		}

		contentColumns["Status"] = (
			<DataTableCell>
				<div
					data-tip="React-tooltip"
					data-for={`status-reason-tooltip-${id}`}
					className={classNames("incoming-payments-status", kebabCase(status))}
				>
					{startCase(status)}

					<ReactTooltip
						className="tooltip-info"
						id={`status-reason-tooltip-${id}`}
						disable={!tooltipValue}
						effect="solid"
					>
						{tooltipValue}
					</ReactTooltip>
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(ReceivedPaymentsColumns.completionDate)) {
		contentColumns["Completion Date"] = <DataTableCell>{moment(completionDate).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			className={classNames(id === selectedReceivedPaymentId && "selected-data-table-row")}
			onClick={(e) => onClick && onClick(e)}
		>
			{content}
		</DataTableRow>
	)
}
