import React, {useCallback} from "react"
import {Account} from "../../resources/account"
import {useAccessToken} from "../../services/auth"
import {Modal} from "../../components/Modals/Modal"

interface OriginateWireModalProps {
	account: Account
	closeModal: () => void
	onSuccess: () => void
}

export function OriginateWireModal({account, closeModal, onSuccess}: OriginateWireModalProps) {
	const accessToken = useAccessToken()

	const refreshAndClose = useCallback(() => {
		onSuccess()
		closeModal()
	}, [onSuccess, closeModal])

	return (
		<Modal close={refreshAndClose} enableClickAway={false} blockBodyScroll={true} classname="originate-wire-modal">
			<unit-elements-wire-payment customer-token={accessToken} account-id={account.id} />
		</Modal>
	)
}
