import {rejectDocument} from "../../resources/application"
import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"

interface RejectApplicationDocumentModalProps {
	applicationId: string
	documentId: string
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

function ReasonCode({
	reasonCode,
	setReasonCode,
	setReason,
}: {
	reasonCode: string
	setReasonCode: (reasonCode: string) => void
	setReason: (reason: string) => void
}) {
	return (
		<div className="field">
			<label className="label">Reason</label>
			<div className="select">
				<select
					value={reasonCode}
					onChange={(e) => {
						setReasonCode(e.target.value)
						setReason("")
					}}
				>
					<option value="AddressMismatch">Address Mismatch</option>
					<option value="DOBMismatch">DOB Mismatch</option>
					<option value="EINMismatch">EIN Mismatch</option>
					<option value="ExpiredId">Expired Id</option>
					<option value="NameMismatch">Name Mismatch</option>
					<option value="PoorQuality">Poor Image Quality</option>
					<option value="SSNMismatch">SSN Mismatch</option>
					<option value="StateMismatch">State Mismatch</option>
					<option value="Other">Other</option>
				</select>
			</div>
		</div>
	)
}

export function RejectApplicationDocumentModal({
	applicationId,
	documentId,
	close,
	onSuccess,
}: RejectApplicationDocumentModalProps) {
	const accessToken = useAccessToken()
	const [reasonCode, setReasonCode] = useState("AddressMismatch")
	const [reason, setReason] = useState("")
	const [state, reject] = useAsyncResultIdle(rejectDocument)

	return (
		<AsyncResultModal
			title="Reject Document"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Reject"
			successText="Rejected"
			onSubmit={() => reject(accessToken, applicationId, documentId, reasonCode, reason)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<ReasonCode reasonCode={reasonCode} setReasonCode={setReasonCode} setReason={setReason} />
				<label className="label">Other Reason</label>
				<div className="control">
					<textarea
						className="textarea"
						placeholder="Why are you rejecting the document?"
						value={reason}
						onChange={(e) => setReason(e.target.value)}
						disabled={reasonCode !== "Other"}
						required={reasonCode === "Other"}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
