import * as React from "react"
const EmptyStateGraphics = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={227} height={154} fill="none">
		<path stroke="url(#a)" strokeWidth={3.025} d="M11.224 9.238v143.794" />
		<circle cx={11.224} cy={17.04} r={8.319} fill="#E2E4E7" stroke="#fff" strokeWidth={4.537} />
		<circle cx={11.224} cy={60.901} r={8.319} fill="#EEEFF1" stroke="#fff" strokeWidth={4.537} />
		<circle cx={11.224} cy={104.5} r={8.319} fill="#F3F4F5" stroke="#fff" strokeWidth={4.537} />
		<rect width={190.987} height={31.762} x={35.083} y={1.159} fill="#F4F5F7" rx={5.294} />
		<rect
			width={7.084}
			height={80.161}
			x={131.125}
			y={13.498}
			fill="#E2E4E7"
			rx={3.025}
			transform="rotate(90 131.125 13.498)"
		/>
		<rect width={190.987} height={31.762} x={35.083} y={1.159} stroke="#E2E4E7" strokeWidth={1.512} rx={5.294} />
		<g opacity={0.6}>
			<rect width={190.987} height={31.762} x={35.083} y={45.02} fill="#F4F5F7" rx={5.294} />
			<rect
				width={7.084}
				height={128.555}
				x={179.519}
				y={57.36}
				fill="#E2E4E7"
				rx={3.025}
				transform="rotate(90 179.519 57.36)"
			/>
			<rect width={190.987} height={31.762} x={35.083} y={45.02} stroke="#E2E4E7" strokeWidth={1.512} rx={5.294} />
		</g>
		<g opacity={0.4}>
			<rect width={190.987} height={31.762} x={35.083} y={88.882} fill="#F4F5F7" rx={5.294} />
			<rect
				width={7.084}
				height={54.562}
				x={105.527}
				y={101.221}
				fill="#E2E4E7"
				rx={3.025}
				transform="rotate(90 105.527 101.221)"
			/>
			<rect width={190.987} height={31.762} x={35.083} y={88.882} stroke="#E2E4E7" strokeWidth={1.512} rx={5.294} />
		</g>
		<defs>
			<linearGradient id="a" x1={11.224} x2={11.224} y1={9.238} y2={174.239} gradientUnits="userSpaceOnUse">
				<stop stopColor="#EBECEE" />
				<stop offset={1} stopColor="#EBECEE" stopOpacity={0} />
			</linearGradient>
		</defs>
	</svg>
)
export default EmptyStateGraphics
