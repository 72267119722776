import {useAsyncResult} from "../../hooks/useAsyncResult"
import React from "react"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {EndCustomerDepositView, EndCustomerDepositViewPending} from "./EndCustomerDepositView"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {AccountsMetrics, getDepositAccountsMetrics} from "../../resources/org"

export default function EndCustomerDepositSummary({orgId, accessToken}: {orgId: string; accessToken: string}) {
	const endCustomerDepositAccounts = useAsyncResult<OkDocument<AccountsMetrics>, ErrorDocument>(() =>
		getDepositAccountsMetrics(accessToken, orgId)
	)

	return (
		<AsyncResultComponent asyncResult={endCustomerDepositAccounts} pendingComponent={<EndCustomerDepositViewPending />}>
			{({
				value: {
					data: {
						attributes: {accounts, balances},
					},
				},
			}) => {
				return <EndCustomerDepositView accounts={accounts} balances={balances} />
			}}
		</AsyncResultComponent>
	)
}
