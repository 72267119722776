import React, {ReactElement, useEffect} from "react"
import moment from "moment"
import {kebabCase} from "lodash"
import {
	BusinessDebitCard,
	canResetPin,
	Card,
	closeCard,
	getAccountCards,
	getCustomerCards,
	IndividualDebitCard,
	isCloseable,
	isReactivable,
	isReplaceable,
	resetCardPin,
	unfreezeCard,
} from "../../resources/card"
import CreatedAt from "../CreatedAt/CreatedAt"
import {usePaging} from "../../hooks/usePaging"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {IndividualCustomer} from "../../resources/customer"
import classNames from "classnames"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useAccessToken, useIsOrgOrUnitAdminUser, useIsOrgUnitPilot, useIsUnitTypeUser} from "../../services/auth"
import {useToasts} from "react-toast-notifications"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useModal} from "react-modal-hook"
import {FreezeCardModal} from "./FreezeCardModal"
import {ConfirmationModal} from "../ConfirmationModal/ConfirmationModal"
import {startCase} from "lodash"
import ReactTooltip from "react-tooltip"
import {UnitPilotLimitsNotice} from "../UnitPilotLimitsNotice/UnitPilotLimitsNotice"
import {ReplaceCardModal} from "./ReplaceCardModal"
import {isVirtualCard} from "../../resources/card"
import {hasPermission} from "../../services/permission"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useNavigate} from "react-router-dom"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"
import {useQueryState} from "use-location-state"
import {isProdEnv} from "../../utilities/environment"
import Icon from "../Icon/Icon"
import {ActivateCardModal} from "./ActivateCardModal"

export enum AccountCardsColumns {
	id = "Id",
	cardHolder = "Holder",
	last4Digits = "Last 4 Digits",
	accountId = "Account",
	expiration = "Expiration",
	status = "Status",
	cardType = "Type",
	createdAt = "Created At",
	updatedAt = "Updated At",
	actions = "Actions",
}

type AllowedAccountCardsColumns =
	| AccountCardsColumns.id
	| AccountCardsColumns.cardHolder
	| AccountCardsColumns.last4Digits
	| AccountCardsColumns.accountId
	| AccountCardsColumns.expiration
	| AccountCardsColumns.status
	| AccountCardsColumns.cardType
	| AccountCardsColumns.createdAt
	| AccountCardsColumns.updatedAt
	| AccountCardsColumns.actions

interface AccountCardsProps {
	customerId?: string
	accountId?: string
	limit?: number
	isUsingPaging?: boolean
	accessToken: string
	includedColumns: Array<AllowedAccountCardsColumns>
	externalRefreshToken?: number
	enableTutorial: boolean
}

function CardStatus({card}: {card: Card}) {
	const statusText = startCase(card.attributes.status)
	return (
		<div className="is-flex">
			<div className={classNames("status-badge", kebabCase(card.attributes.status))}>{statusText}</div>
			{card.attributes.status == "Frozen" && card.attributes.freezeReason ? (
				<a className="is-flex is-align-items-center ml-1" data-tip="React-tooltip" data-event="click focus">
					<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
					<ReactTooltip
						place="bottom"
						effect="solid"
						className="tooltip-info"
						globalEventOff="click"
						border={true}
						borderColor="#C4C4C4"
						backgroundColor="#F5F5F5"
						textColor="black"
					>
						{card.attributes.freezeReason}
					</ReactTooltip>
				</a>
			) : null}
		</div>
	)
}

function AccountCardRow({
	card,
	customer,
	refresh,
	includedColumns,
}: {
	card: Card
	customer?: IndividualCustomer
	refresh: () => void
	includedColumns: Array<AccountCardsColumns>
}) {
	const navigate = useNavigate()
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [closeState, close] = useAsyncResultIdle(closeCard)
	const [resetPinState, resetPin] = useAsyncResultIdle(resetCardPin)
	const [unfreezeState, unfreeze] = useAsyncResultIdle(unfreezeCard)
	const [showFreeze, hideFreeze] = useModal(() => (
		<FreezeCardModal close={hideFreeze} card={card} onSuccess={refresh} />
	))
	const [showReplace, hideReplace] = useModal(() => (
		<ReplaceCardModal
			customer={customer}
			close={hideReplace}
			card={card as IndividualDebitCard | BusinessDebitCard}
			onSuccess={refresh}
		/>
	))

	const [showActive, hideActive] = useModal(() => (
		<ActivateCardModal
			customer={customer}
			close={hideActive}
			card={card as IndividualDebitCard | BusinessDebitCard}
			onSuccess={refresh}
		/>
	))

	const [showCloseConfirmationModal, hideCloseConfirmationModal] = useModal(
		() => (
			<ConfirmationModal
				title="Close Card"
				close={hideCloseConfirmationModal}
				okButtonText={"Close Card"}
				okButtonClassname={"button is-danger is-outlined"}
				cancelButtonClassname="button is-white"
				onSubmit={() => {
					close(accessToken, card)
				}}
			>
				Are you sure you want to close this card?
			</ConfirmationModal>
		),
		[]
	)

	const [showResetPinConfirmationModal, hideResetPinConfirmationModal] = useModal(
		() => (
			<ConfirmationModal
				title="Reset PIN"
				close={hideResetPinConfirmationModal}
				okButtonText={"Reset PIN"}
				okButtonClassname={"button is-danger is-outlined"}
				cancelButtonClassname="button is-white"
				onSubmit={() => {
					resetPin(accessToken, card)
				}}
			>
				Are you sure you want to reset the PIN for this card?
				<br />
				This is a high-risk action that should only be taken following the card holder’s request, and after the process
				of customer identity verification has been completed
			</ConfirmationModal>
		),
		[]
	)

	useEffect(() => {
		closeState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(e.errors[0].title)
		)
	}, [closeState])

	useEffect(() => {
		unfreezeState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(e.errors[0].title)
		)
	}, [unfreezeState])

	useEffect(() => {
		resetPinState.match(
			() => null,
			() => null,
			() => {
				addToast("The PIN has been reset successfully", {appearance: "success"})
				refresh()
			},
			(e) => addToast(e.errors[0].title)
		)
	}, [resetPinState])

	const contentColumns: Partial<Record<AllowedAccountCardsColumns, ReactElement>> = {}

	if (includedColumns.includes(AccountCardsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell>
				<span className="data-table-id"> {card.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.last4Digits)) {
		contentColumns["Last 4 Digits"] = (
			<DataTableCell>
				{" "}
				<b> {card.attributes.last4Digits} </b>{" "}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.cardHolder)) {
		contentColumns["Holder"] =
			card.type === "individualDebitCard" || card.type === "individualVirtualDebitCard" ? (
				<DataTableCell>
					{" "}
					{customer?.attributes.fullName.first} {customer?.attributes.fullName.last}{" "}
				</DataTableCell>
			) : (
				<DataTableCell>
					{" "}
					{card.attributes.fullName.first} {card.attributes.fullName.last}{" "}
				</DataTableCell>
			)
	}

	if (includedColumns.includes(AccountCardsColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (card.relationships.account.data.id) {
							navigate(`/accounts/${card.relationships.account.data.id}`)
						}
					}}
				>
					{card.relationships.account.data.id}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.expiration)) {
		contentColumns["Expiration"] = (
			<DataTableCell>{moment(card.attributes.expirationDate).format("MM/YYYY")}</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<CardStatus card={card} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.cardType)) {
		contentColumns["Type"] = <DataTableCell>{isVirtualCard(card) ? "Virtual" : "Physical"}</DataTableCell>
	}

	if (includedColumns.includes(AccountCardsColumns.createdAt)) {
		contentColumns["Created At"] = (
			<DataTableCell>
				<CreatedAt createdAt={card.attributes.createdAt} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.updatedAt)) {
		contentColumns["Updated At"] = (
			<DataTableCell>
				{card.attributes.updatedAt ? (
					moment(card.attributes.updatedAt).format("L")
				) : (
					<span className="empty-date-placeholder"> --/--/---- </span>
				)}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountCardsColumns.actions)) {
		contentColumns["Actions"] = (
			<DataTableActionButton enableActions={isCloseable(card)}>
				{card.attributes.status == "Active" || card.attributes.status == "ActiveForOnlineUse" ? (
					<DataTableActionItem title={"Freeze card"} icon={"credit-card-lock"} onClick={() => showFreeze()} />
				) : null}
				{card.attributes.status == "Frozen" ? (
					<DataTableActionItem
						title={"Unfreeze card"}
						icon={"credit-card-unlock"}
						onClick={() => unfreeze(accessToken, card)}
					/>
				) : null}
				<DataTableActionItem
					title={"Close card"}
					icon={"credit-card-remove-cross"}
					onClick={() => showCloseConfirmationModal()}
				/>
				{isReplaceable(card) && hasPermission("card.replace", "create") ? (
					<DataTableActionItem
						title={"Replace card"}
						icon={"account-credit-card-replace"}
						onClick={() => showReplace()}
					/>
				) : null}
				{isReactivable(card) && useIsOrgOrUnitAdminUser() ? (
					<DataTableActionItem title={"Activate card"} icon={"credit-card-unlock"} onClick={() => showActive()} />
				) : null}
				{canResetPin(card) && useIsOrgOrUnitAdminUser() ? (
					<DataTableActionItem
						title={"Reset PIN"}
						icon={"security-password-lock--programing-apps-websites"}
						onClick={() => showResetPinConfirmationModal()}
					/>
				) : null}
			</DataTableActionButton>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow>{content}</DataTableRow>
}

function AccountCardsTable({
	cards,
	customersMap,
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	isUsingPaging,
	refresh,
	includedColumns,
	sortFunction,
	sortBy,
	enableTutorial,
}: {
	cards: Array<Card>
	customersMap: Map<string, IndividualCustomer>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	refresh: () => void
	includedColumns: Array<AllowedAccountCardsColumns>
	sortFunction: () => void
	sortBy: string
	enableTutorial: boolean
}) {
	const tutorialCallButton = document.querySelector(".tutorial-call-button")

	return (
		<div className={"account-cards-table"}>
			<DataTable
				isEmpty={cards.length === 0}
				fullHeight={false}
				stickyAction={false}
				noContentText={"No cards found"}
				noContentCtaButton={
					!isProdEnv() && enableTutorial && !useIsUnitTypeUser() ? (
						<button
							className="tutorial-cta-button"
							onClick={() => {
								if (tutorialCallButton) {
									sessionStorage.setItem("tutorial_action", "Create Card")
									ReactTooltip.rebuild()
									ReactTooltip.show(tutorialCallButton)
								}
							}}
						>
							<Icon icon={"programming-code--programing-apps-websites"} color="white" />
							Test Create Card API
						</button>
					) : null
				}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === AccountCardsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{cards.map((card) => (
						<AccountCardRow
							refresh={refresh}
							includedColumns={includedColumns}
							card={card}
							customer={customersMap.get(card.relationships.customer.data.id)}
							key={card.id}
						/>
					))}
				</DataTableBody>
			</DataTable>
			{useIsOrgUnitPilot() && cards.length >= 1 ? (
				<UnitPilotLimitsNotice text={"Maximum 1 card is available in Unit pilot"} />
			) : null}
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
			/>
		</div>
	)
}

export default function AccountCards({
	customerId,
	accountId,
	limit = 10,
	isUsingPaging = true,
	accessToken,
	includedColumns,
	externalRefreshToken,
	enableTutorial,
}: AccountCardsProps) {
	const prefix = "card-"
	const [refreshToken, refresh] = useRefreshToken()
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")

	function getCards(offset: number, limit: number) {
		if (accountId) {
			return getAccountCards(accessToken, offset, limit, accountId, sortBy)
		} else {
			return getCustomerCards(accessToken, offset, limit, customerId, sortBy)
		}
	}

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		getCards,
		(x) => x.data.length,
		[refreshToken, sortBy, externalRefreshToken],
		prefix,
		true
	)

	return (
		<DataTableCard className={"account-cards-card"}>
			<DataTableActionHeader title={"Cards"} />
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: cards}) => {
					const customersMap = cards.included
						? new Map(
								cards.included
									.filter((i) => i.type == "individualCustomer")
									.map((i) => i as IndividualCustomer)
									.map((i) => [i.id, i])
						  )
						: new Map<string, IndividualCustomer>()
					return (
						<AccountCardsTable
							cards={cards.data}
							customersMap={customersMap}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={isUsingPaging}
							refresh={refresh}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
							enableTutorial={enableTutorial}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
