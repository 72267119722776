import React, {useRef} from "react"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTrash} from "@fortawesome/free-solid-svg-icons"
import {useDebounce} from "react-use"
import Icon from "../Icon/Icon"

export default function SearchBox(
	initialValue: string,
	placeholder: string,
	debounceTime: number,
	onSearch: (search: string) => void,
	isImmediate = false
): [JSX.Element, (search: boolean) => void] {
	const [searchText, setSearchText] = React.useState<string>(initialValue)
	const [isSearchLoading, setIsSearchLoading] = React.useState(false)
	// an hack to skip first rendering of useDebounce - https://github.com/streamich/react-use/issues/759
	const isFirstRender = useRef(true)

	const [,] = useDebounce(
		() => {
			if (isFirstRender.current) {
				isFirstRender.current = false
			} else {
				onSearch(searchText)
				if (!isImmediate) setIsSearchLoading(true)
			}
		},
		debounceTime,
		[searchText, isFirstRender]
	)

	function onSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
		changeSearch(e.target.value)
	}

	function changeSearch(s: string) {
		setSearchText(s)
	}

	function getSearchIcon() {
		if (searchText != "") {
			return (
				<span className="icon is-small is-left is-clickable search-icon" onClick={() => changeSearch("")}>
					<FontAwesomeIcon icon={faTrash} />
				</span>
			)
		} else {
			return (
				<span className="search-icon icon is-small is-left">
					<Icon icon={"magnifier--interface-essential"} size={16} />
				</span>
			)
		}
	}

	const content = (
		<div className="field">
			<div className={classNames("control has-icons-left has-icons-right", isSearchLoading && "is-loading")}>
				<input
					className="input search-input"
					placeholder={placeholder}
					onChange={onSearchTextChange}
					value={searchText}
				/>
				{getSearchIcon()}
			</div>
		</div>
	)

	return [content, setIsSearchLoading]
}
