import React, {Dispatch, PropsWithChildren, useEffect, useRef} from "react"
import Dropdown from "./Dropdown"
import Textfield from "./Textfield"
import {caStates, usStates, CA_COUNTRY_CODE, US_COUNTRY_CODE, usPostalCode, caPostalCode} from "../../resources/address"
import ReactTooltip from "react-tooltip"
import * as Yup from "yup"
import {FormikErrors, FormikValues} from "formik"
import {flattenObject} from "../../utilities/helpers"
import classNames from "classnames"
import {countries} from "../../resources/countries"

interface Props {
	street: string
	street2?: string
	city: string
	country: string
	state: string
	postalCode: string
	handleChange: Dispatch<any>
	handleBlur: Dispatch<any>
	setFieldValue: Function
	isUsOnlyAddress?: boolean
	namePrefix?: string
	closed?: boolean
}

export const addressValidationSchema = {
	street: Yup.string().required("Please enter your street"),
	city: Yup.string().required("Please enter your city of residence"),
	state: Yup.string().when("country", {
		is: (country: string) => [US_COUNTRY_CODE, CA_COUNTRY_CODE].includes(country),
		then: Yup.string().required("Please select the state of residence"),
	}),
	postalCode: Yup.string()
		.required("Please enter your zip code (must be 5 or 9 digits long)")
		.when("country", {
			is: US_COUNTRY_CODE,
			then: Yup.string().matches(
				usPostalCode,
				"The zip code you have provided is invalid (US zip codes must be 5 or 9 digits long)"
			),
		})
		.when("country", {
			is: CA_COUNTRY_CODE,
			then: Yup.string().matches(
				caPostalCode,
				"The zip code you have provided is invalid (CA zip codes are in a A1A 1A1 format, where A is a letter and 1 is a digit)"
			),
		}),
	country: Yup.string().required("Please select the country of residence"),
}

export function focusFirstInvalidField(errors: FormikErrors<FormikValues>) {
	const fieldKeys = Array.from(Object.keys(flattenObject(errors)))
	const selectors = fieldKeys.map((key) => `input[name="${key}"], select[name="${key}"]`)
	const firstInvalidField = document.querySelector(selectors.join(", ")) as HTMLInputElement | HTMLSelectElement
	firstInvalidField?.focus()
}

export default function AddressComponent({
	closed = true,
	street,
	street2,
	city,
	country,
	state,
	postalCode,
	handleChange,
	handleBlur,
	setFieldValue,
	isUsOnlyAddress = true,
	namePrefix = "",
}: PropsWithChildren<Props>) {
	const addressElement = useRef<HTMLInputElement>(null)
	let autocomplete: google.maps.places.Autocomplete
	let street1Field: HTMLInputElement

	const inputFieldNames = {
		street: `${namePrefix}street`,
		street2: `${namePrefix}street2`,
		city: `${namePrefix}city`,
		country: `${namePrefix}country`,
		state: `${namePrefix}state`,
		postalCode: `${namePrefix}postalCode`,
	}

	function handleEnterKey(e: KeyboardEvent) {
		if (e.keyCode === 13) {
			e.preventDefault()
		}
	}

	function initAutocomplete() {
		if (addressElement.current) {
			street1Field = addressElement.current.querySelector(
				`input[name="${inputFieldNames["street"]}"]`
			) as HTMLInputElement

			autocomplete = new google.maps.places.Autocomplete(street1Field, {
				componentRestrictions: isUsOnlyAddress ? {country: ["us"]} : {country: []},
				fields: ["address_components", "geometry"],
				types: ["address"],
			})
			autocomplete.addListener("place_changed", fillInAddress)
			google.maps.event.addDomListener(street1Field, "keydown", function (e: KeyboardEvent) {
				handleEnterKey(e as KeyboardEvent)
			})
		}
	}

	function fillInAddress() {
		// Get the place details from the autocomplete object.
		const place = autocomplete.getPlace()
		let street = ""
		let postalCode = ""

		// Get each component of the address from the place details,
		// and then fill-in the corresponding field on the form.
		// place.address_components are google.maps.GeocoderAddressComponent objects
		// which are documented at http://goo.gle/3l5i5Mr
		for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
			const componentType = component.types[0]

			switch (componentType) {
				case "street_number": {
					street = `${component.long_name} ${street}`
					break
				}

				case "route": {
					street += component.short_name
					break
				}

				case "postal_code": {
					postalCode = `${component.long_name}${postalCode}`
					break
				}

				case "postal_code_suffix": {
					postalCode = `${postalCode}-${component.long_name}`
					break
				}

				case "locality":
					setFieldValue(inputFieldNames["city"], component.long_name)
					break

				case "administrative_area_level_1": {
					setFieldValue(inputFieldNames["state"], component.short_name)
					break
				}

				case "country":
					setFieldValue(inputFieldNames["country"], component.short_name)
					break
			}
		}

		setFieldValue(inputFieldNames["street"], street)
		setFieldValue(inputFieldNames["postalCode"], postalCode)
	}

	useEffect(() => {
		initAutocomplete()
	}, [])

	let statesDropdown: JSX.Element | null = null

	if (country === US_COUNTRY_CODE) {
		statesDropdown = (
			<Dropdown name={`${namePrefix}state`} label={"State"} value={state} onChange={handleChange} onBlur={handleBlur}>
				<option value="" disabled>
					Select state
				</option>
				{Object.keys(usStates).map((state) => (
					<option key={state} value={state}>
						{usStates[state]}
					</option>
				))}
			</Dropdown>
		)
	} else if (country === CA_COUNTRY_CODE) {
		statesDropdown = (
			<Dropdown name={`${namePrefix}state`} label={"State"} value={state} onChange={handleChange} onBlur={handleBlur}>
				<option value="" disabled>
					Select state
				</option>
				{Object.keys(caStates).map((state) => (
					<option key={state} value={state}>
						{caStates[state]}
					</option>
				))}
			</Dropdown>
		)
	}

	return (
		<div className={"address-placeholder"}>
			<div className={classNames("address", closed && "address-closed")} ref={addressElement}>
				<Textfield
					placeholder={"Type street address"}
					label={"Street"}
					name={`${namePrefix}street`}
					value={street}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
				<div className="form-row two">
					<Textfield
						placeholder={"Add apartment number"}
						label={"Apartment,unit,floor # (Optional)"}
						name={`${namePrefix}street2`}
						value={street2 ?? ""}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					<Textfield
						placeholder={"Enter city"}
						label={"City"}
						name={`${namePrefix}city`}
						value={city}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</div>
				{!isUsOnlyAddress ? (
					<div className="form-row two">
						<Dropdown
							name={`${namePrefix}country`}
							label={"Country"}
							value={country}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							{Object.keys(countries).map((countryCode) => (
								<option key={countryCode} value={countryCode}>
									{countries[countryCode]}
								</option>
							))}
						</Dropdown>
					</div>
				) : null}
				<div className="form-row two">
					{statesDropdown}
					<Textfield
						placeholder={"Enter zip code"}
						label={"Zip Code"}
						name={`${namePrefix}postalCode`}
						value={postalCode}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</div>
				<ReactTooltip effect={"solid"} className={"tooltip"} />
			</div>
			<span className={classNames("address", !closed && "address-closed")}>
				{" "}
				{street} {city} {state} {postalCode}{" "}
			</span>
		</div>
	)
}
