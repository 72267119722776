export enum UserType {
	Org = "Org",
	Unit = "Unit",
	Bank = "Bank",
	Partner = "Partner",
	Customer = "Customer",
	PartnerOnBehalf = "PartnerOnBehalf",
}

export type UserRole = UnitUserRole | BankUserRole | OrgUserRole | PartnerUserRole | PartnerOnBehalfUserRole

export enum UnitUserRole {
	super = "super",
	compliance = "compliance",
	complianceReadonly = "compliance-readonly",
	unitAdmin = "unit-admin",
	unitReadonly = "unit-readonly",
	unitSales = "unit-sales",
}

export enum BankUserRole {
	bank = "bank",
	bankReadonly = "bank-readonly",
	bankOperations = "bank-operations",
}

export enum OrgUserRole {
	admin = "admin",
	restricted = "restricted",
	it = "it",
	readonly = "readonly",
	readonlyNoPii = "readonly_no_pii",
	nineAgent = "9_agent",
}

export enum PartnerUserRole {
	partner = "partner",
}

export enum PartnerOnBehalfUserRole {
	argyle = "argyle_partner_on_behalf",
	astra = "astra_partner_on_behalf",
	unitPartnerTest = "unit_partner_test_partner_on_behalf",
	hurdlr = "hurdlr_partner_on_behalf",
	fragment = "fragment_partner_on_behalf",
	knot = "knot_partner_on_behalf",
	oatfi = "oatfi_partner_on_behalf",
	layer = "layer_partner_on_behalf",
	rainforest = "rainforest_partner_on_behalf",
	tabapay = "tabapay_partner_on_behalf",
	whiteLabelApp = "white_label_app_partner_on_behalf",
	sunlight = "sunlight_partner_on_behalf",
}
