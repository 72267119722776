import React, {ReactElement} from "react"
import {useAccessToken} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {
	findWebhooks,
	toggleWebhookStatus,
	Webhook,
	WebhookStatus,
	WebhookSubscriptionType,
} from "../../resources/webhook"
import {useModal} from "react-modal-hook"
import {Resource} from "../../resources/common"
import moment from "moment"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import EditWebhookModal from "./EditWebhookModal"
import {
	DataTableRow,
	DataTableCell,
	DataTableHead,
	DataTableBody,
	DataTableActionHeader,
	DataTable,
	DataTableCard,
	TablePending,
} from "../DataTable/DataTable"
import classNames from "classnames"
import {kebabCase, startCase} from "lodash"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {useQueryState} from "use-location-state"

export enum WebhooksColumns {
	id = "Id",
	org = "Org",
	label = "Label",
	url = "Url",
	status = "Status",
	contentType = "Content Type",
	delivery = "Delivery",
	createdAt = "Created At",
	subscriptionType = "Subscription Type",
	toggle = "Toggle",
}

type AllowedWebhooksColumns =
	| WebhooksColumns.id
	| WebhooksColumns.org
	| WebhooksColumns.label
	| WebhooksColumns.url
	| WebhooksColumns.status
	| WebhooksColumns.contentType
	| WebhooksColumns.delivery
	| WebhooksColumns.createdAt
	| WebhooksColumns.toggle
	| WebhooksColumns.subscriptionType

function isWhiteLabelAppWebhook(webhook: Webhook): boolean {
	return webhook.attributes.label === "White-Label App"
}

function WebhookRow({
	webhook,
	accessToken,
	refresh,
	includedColumns,
}: {
	webhook: Webhook
	included?: Resource[]
	accessToken: string
	refresh: () => void
	includedColumns: Array<AllowedWebhooksColumns>
}) {
	const readOnly = isWhiteLabelAppWebhook(webhook)
	const [showModal, hideModal] = useModal(() => (
		<EditWebhookModal webhookId={webhook.id} close={hideModal} refresh={refresh} readOnly={readOnly} />
	))

	const status = webhook.attributes.status

	const contentColumns: Partial<Record<AllowedWebhooksColumns, ReactElement>> = {}

	if (includedColumns.includes(WebhooksColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {webhook.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(WebhooksColumns.org)) {
		contentColumns["Org"] = <DataTableCell>{webhook.relationships.org.data.id}</DataTableCell>
	}

	if (includedColumns.includes(WebhooksColumns.label)) {
		contentColumns["Label"] = <DataTableCell>{webhook.attributes.label}</DataTableCell>
	}

	if (includedColumns.includes(WebhooksColumns.url)) {
		contentColumns["Url"] = <DataTableCell>{webhook.attributes.url}</DataTableCell>
	}
	if (includedColumns.includes(WebhooksColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("web-hooks-status", kebabCase(status))}>{startCase(status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(WebhooksColumns.contentType)) {
		contentColumns["Content Type"] = <DataTableCell>{webhook.attributes.contentType}</DataTableCell>
	}

	if (includedColumns.includes(WebhooksColumns.subscriptionType)) {
		contentColumns["Subscription Type"] = (
			<DataTableCell>{WebhookSubscriptionType[webhook.attributes.subscriptionType]}</DataTableCell>
		)
	}

	if (includedColumns.includes(WebhooksColumns.delivery)) {
		contentColumns["Delivery"] = <DataTableCell>{webhook.attributes.deliveryMode}</DataTableCell>
	}

	if (includedColumns.includes(WebhooksColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(webhook.attributes.createdAt).format("L")}</DataTableCell>
	}

	if (includedColumns.includes(WebhooksColumns.toggle)) {
		contentColumns["Toggle"] = (
			<DataTableCell>
				{readOnly ? (
					<span></span>
				) : (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							toggleWebhookStatus(accessToken, webhook.id, webhook.attributes.status as WebhookStatus).then(() =>
								refresh()
							)
						}}
					>
						{webhook.attributes.status === WebhookStatus.Enabled ? "Disable" : "Enable"}
					</a>
				)}
			</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow onClick={showModal}>{content}</DataTableRow>
}

export function Webhooks({
	fullHeight = true,
	enableTitle = false,
	includedColumns,
	refreshToken,
}: {
	fullHeight?: boolean
	enableTitle?: boolean
	includedColumns: Array<AllowedWebhooksColumns>
	refreshToken?: number
}) {
	const accessToken = useAccessToken()
	const [_, refresh] = useRefreshToken()
	const [since, setSince] = useQueryState("filter[since]", "")
	const [until, setUntil] = useQueryState("filter[until]", "")

	const [result, hasPrev, hasNext, prev, next, hasResults] = usePaging(
		25,
		(offset, limit) => findWebhooks(accessToken, offset, limit, since, until),
		(x) => x.data.length,
		[refreshToken, _, since, until],
		"",
		true
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	return (
		<DataTableCard className={"webhooks-card"}>
			<DataTableActionHeader
				dateTimePicker={dateTime}
				enableSticky={fullHeight}
				title={enableTitle ? "WebHooks" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: webhooks}) => {
					const rows = webhooks.data.map((w) => (
						<WebhookRow
							includedColumns={includedColumns}
							key={w.id}
							included={webhooks.included}
							webhook={w}
							accessToken={accessToken}
							refresh={refresh}
						/>
					))

					return (
						<div className={"webhooks-table"}>
							<DataTable>
								<DataTableHead>
									<DataTableRow>
										{Object.entries(includedColumns).map((column) => {
											return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
										})}
									</DataTableRow>
								</DataTableHead>
								<DataTableBody>{rows}</DataTableBody>
							</DataTable>
							<PagingNavBar hasResults={hasResults} hasPrev={hasPrev} hasNext={hasNext} prev={prev} next={next} />
						</div>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
