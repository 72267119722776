import React from "react"
import {IcomoonIconName} from "../../Icon/icons"
import {noop} from "lodash"

type FilterDropdownMenuProps = {
	toggleAll: typeof noop
	toggleNone: typeof noop
	isSearchable: boolean
	searchBox: JSX.Element
	buttons: JSX.Element[]
}
export function FilterDropdownMenu({
	toggleAll,
	toggleNone,
	isSearchable,
	searchBox,
	buttons,
}: FilterDropdownMenuProps & {filterIcon?: IcomoonIconName}) {
	const selectAllLimit = 50
	return (
		<div className="dropdown-menu filter-dropdown-menu" id="dropdown-menu" role="menu">
			<div className="dropdown-content">
				{isSearchable ? <div className="dropdown-item">{searchBox}</div> : null}
				<div className="panel is-shadowless is-marginless limeted-height">{buttons}</div>
				<hr className="dropdown-divider" />
				<div className="dropdown-item">
					<div className="is-inline-flex is-auto is-flex-wrap-nowrap">
						{buttons.length <= selectAllLimit && (
							<button className="button is-light mr-3 light-wide-button" onClick={(_) => toggleAll()}>
								Select all
							</button>
						)}
						<button className="button is-light light-wide-button" onClick={(_) => toggleNone()}>
							None
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
