import React, {useEffect, useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {Customer, getCustomerName} from "../../resources/customer"
import Admonition from "../../components/Admonitions/Admonition"
import {RiskRate} from "../../resources/common"
import {graduateOrg, Org} from "../../resources/org"
import {DepositAccount, getReserveAccounts, getRevenueAccounts} from "../../resources/account"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findBanks} from "../../resources/bank"

interface GoLiveModalProps {
	customers: Array<Customer>
	org: Org
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function GoLiveModal({org, customers, close, onSuccess}: GoLiveModalProps) {
	const accessToken = useAccessToken()
	const [graduateState, setGraduateState] = useAsyncResultIdle(graduateOrg)
	const [bankId, setBankId] = useState<string>("")
	const [customerId, setCustomerId] = useState<string>("")
	const [revenueAccountId, setRevenueAccountId] = useState<string>("")
	const [reserveAccountId, setReserveAccountId] = useState<string>("")
	const [defaultCustomerRiskRate, setDefaultCustomerRiskRate] = useState<RiskRate>(
		org.attributes.defaultCustomerRiskRate
	)

	const [revenueAccounts, setRevenueAccounts] = useState<DepositAccount[]>([])
	const [reserveAccounts, setReserveAccounts] = useState<DepositAccount[]>([])

	const [, getRevenueAccountsFromState] = useAsyncResultIdle((bankId) => getRevenueAccounts(accessToken, bankId))
	const [, getReserveAccountsFromState] = useAsyncResultIdle((bankId) => getReserveAccounts(accessToken, bankId))

	const banksResult = useAsyncResult(() => findBanks(accessToken, 0, 1000))

	useEffect(() => {
		if (bankId !== "") {
			resolveRevenueAccounts(bankId)
			resolveReserveAccounts(bankId)
		}
	}, [bankId])

	async function resolveRevenueAccounts(bankId: string) {
		setRevenueAccounts(
			(await getRevenueAccountsFromState(bankId)).match(
				(result) => {
					setRevenueAccountId(result[0].id)
					return result
				},
				() => []
			)
		)
	}

	async function resolveReserveAccounts(bankId: string) {
		setReserveAccounts(
			(await getReserveAccountsFromState(bankId)).match(
				(result) => {
					setReserveAccountId(result[0].id)
					return result
				},
				() => []
			)
		)
	}

	return (
		<AsyncResultModal
			title={"Move " + org.attributes.name + " to Live"}
			classname="go-live-modal"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-black"
			state={graduateState}
			blockBodyScroll={true}
			buttonText="Approve"
			successText={`${org.attributes.name} Moved to Live!`}
			enableClickAway={true}
			onSubmit={() => {
				setGraduateState(
					accessToken,
					org.id,
					bankId,
					customerId,
					revenueAccountId,
					reserveAccountId,
					defaultCustomerRiskRate
				)
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="columns">
				<div className="column is-6">
					<div className="field">
						<label className="label">Bank</label>
						<div className="control">
							<div className="select">
								<select
									value={bankId}
									required
									onChange={(e) => setBankId(e.target.value)}
									disabled={!banksResult.isOk()}
								>
									<option value="" disabled>
										Select A Bank
									</option>
									{banksResult.match(
										() => null,
										(banks) =>
											banks.map((b) => (
												<option key={b.id} value={b.id.toString()}>
													{b.attributes.name}
												</option>
											)),
										() => null
									)}
								</select>
							</div>
						</div>
					</div>
				</div>

				<div className="column is-6">
					<div className="field">
						<label className="label">Customer</label>
						<div className="field-body">
							<div className="select">
								<select value={customerId ? customerId : ""} onChange={(e) => setCustomerId(e.target.value)} required>
									<option value="" disabled>
										Select A Customer
									</option>
									{customers.map((c) => (
										<option key={c.id} value={c.id}>
											{`${c.id} - ${getCustomerName(c)}`}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="columns">
				<div className="column is-6">
					<div className="field">
						<label className="label">Org Reserve Account</label>
						<div className="field-body">
							<div className="select">
								<select
									required
									value={reserveAccountId}
									onChange={(e) => setReserveAccountId(e.target.value)}
									disabled={bankId == ""}
								>
									<option value="" disabled>
										Select A Reserve Account
									</option>
									{reserveAccounts?.map((a) => (
										<option key={a.id} value={a.id}>
											{a.attributes.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>

				<div className="column is-6">
					<div className="field">
						<label className="label">Org Revenue Account</label>
						<div className="field-body">
							<div className="select">
								<select
									required
									value={revenueAccountId}
									onChange={(e) => setRevenueAccountId(e.target.value)}
									disabled={bankId == ""}
								>
									<option value="" disabled>
										Select A Revenue Account
									</option>
									{revenueAccounts?.map((a) => (
										<option key={a.id} value={a.id}>
											{a.attributes.name}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column is-6">
					<div className="field">
						<label className="label">Default Risk Rate</label>
						<div className="field-body">
							<div className="select">
								<select
									value={defaultCustomerRiskRate}
									onChange={(e) => {
										const val = e.target.value

										if (val == RiskRate.Low || val == RiskRate.Medium || val == RiskRate.High) {
											setDefaultCustomerRiskRate(val)
										}
									}}
									disabled={bankId == ""}
								>
									<option value={RiskRate.Low} key={RiskRate.Low}>
										Low
									</option>
									<option value={RiskRate.Medium} key={RiskRate.Medium}>
										Medium
									</option>
									<option value={RiskRate.High} key={RiskRate.High}>
										High
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Admonition
				type={"is-danger"}
				message={
					<>
						<p>There are additional steps that need to be taken prior to going live:</p>
						<br />
						<ul>
							<li>Fund reserve account</li>
							<li>Configure the new deposit products</li>
							<li>Configure the new BIN and card designs</li>
						</ul>
					</>
				}
				title={"Do not approve this action if the client has not received final sign off to go live."}
			/>
		</AsyncResultModal>
	)
}
