import React from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {changeUserStatus, User} from "../../resources/user"
import Admonition from "../Admonitions/Admonition"

type CloseFunc = () => void
type RefreshFunc = () => void

interface ModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	user: User
}

export function DisableUserModal({close, refresh, user}: ModalProps) {
	const accessToken = useAccessToken()
	const [statusChangeState, changeStatus] = useAsyncResultIdle(changeUserStatus)
	return (
		<AsyncResultModal
			title="Disable User"
			onSubmit={() => changeStatus(accessToken, user.id, true)}
			close={close}
			state={statusChangeState}
			buttonClassname="is-danger"
			buttonText="Disable"
			successText="Disabled"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<Admonition
				title={"Warning"}
				type={"is-danger"}
				message={
					"The user you are about to disable might have API tokens associated with them. Disabling the user will disable the API keys they have created. Are you sure you want to continue?"
				}
			/>
		</AsyncResultModal>
	)
}
