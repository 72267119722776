import {useAccessToken, useIsUnitUser} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, Org} from "../../resources/org"
import {Bank, findBanks} from "../../resources/bank"
import {AsyncResult} from "../../types/asyncResult"
import Loading from "../../components/Loading/Loading"
import React, {useEffect, useRef, useState} from "react"
import {MainSection} from "../../containers/MainSection/MainSection"
import {
	DepositProduct,
	findActiveAssociatedAccounts,
	getDepositProduct,
	Status,
	updateDepositProduct,
} from "../../resources/depositProduct"
import {Attributes, DepositProductData} from "./Attributes"
import {useParams} from "react-router"
import TitleBar, {TitleBarAction} from "../../components/TitleBar/TitleBar"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {EditMode, ErrorDocument} from "../../resources/common"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {BankRouting, findBankRoutings} from "../../resources/bankRouting"
import {
	findTransactionMonitoringSettings,
	TransactionMonitoringSettings,
} from "../../resources/transactionMonitoringSettings"
import {Bin, findBins} from "../../resources/bin"
import {useNavigate} from "react-router-dom"
import {isNil} from "lodash"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import Icon from "../../components/Icon/Icon"
import {hasPermission} from "../../services/permission"
import {Formik, FormikValues} from "formik"
import {focusFirstInvalidField} from "../../components/Address/Address"
import * as Yup from "yup"
import General from "./DepositProductSections/General"
import Limits from "./DepositProductSections/Limits"
import Clearing from "./DepositProductSections/Clearing"
import CardSettings from "./DepositProductSections/CardSettings"
import AccountFees from "./DepositProductSections/AccountFees"
import Overdraft from "./DepositProductSections/Overdraft"
import TableOfContent from "../../components/TableOfContent/TableOfContent"
import classNames from "classnames"
import {useModal} from "react-modal-hook"
import ReactTooltip from "react-tooltip"
import BodyPortal from "../../components/BodyPortal/BodyPortal"
import {ToggleDepositProductModal} from "../../components/DepositProducts/ToggleDepositProductModal"
import {findInterestSettings, InterestSettings} from "../../resources/interestSettings"
import {AuditLogEventsHeaderMetaItemProps} from "../../components/AuditLogEventsHeader/AuditLogEventsHeader"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"

export interface DuplicateDepositProductState {
	duplicateDepositProduct?: DepositProductData
}

function Footer({
	state,
	isDisabled,
}: {
	state: AsyncResultIdleRequestState<DepositProduct, ErrorDocument>
	isDisabled: boolean
}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success" disabled={isDisabled}>
						Update
					</button>
				),
				() => (
					<button className="button is-success is-loading">Updating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].title}</div>
						<button className="button is-success">Update</button>
					</>
				)
			)}
		</div>
	)
}

type Props = {
	depositProduct: DepositProduct
	orgs: Array<Org>
	banks: Array<Bank>
	bankRoutings: Array<BankRouting>
	refresh: () => void
	transactionMonitoringSettings: Array<TransactionMonitoringSettings>
	bins: Array<Bin>
	hasAssociatedAccounts: boolean
	interestSettings: Array<InterestSettings>
}

function Page({
	depositProduct,
	orgs,
	banks,
	bankRoutings,
	refresh,
	transactionMonitoringSettings,
	bins,
	hasAssociatedAccounts,
	interestSettings,
}: Props) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const navigate = useNavigate()

	const userHasPermission = hasPermission("depositProduct", "update") || useIsUnitUser()
	const [state, update] = useAsyncResultIdle(updateDepositProduct)
	const [isEditing, setIsEditing] = useState(false)
	const [name, setName] = useState(depositProduct.attributes.name)
	const [orgId, setOrgId] = useState(depositProduct.relationships?.org?.data.id)
	const [transactionMonitoringSettingsId, setTransactionMonitoringSettingsId] = useState(
		depositProduct.relationships?.transactionMonitoringSettings?.data.id
	)
	const [incomingACHFee, setIncomingAchFee] = useState(depositProduct.attributes.incomingACHFee)
	const [outgoingACHFee, setOutgoingAchFee] = useState(depositProduct.attributes.outgoingACHFee)
	const [dailyACHDebitLimit, setDailyAchDebitLimit] = useState(depositProduct.attributes.dailyACHDebitLimit)
	const [dailyACHCreditLimit, setDailyAchCreditLimit] = useState(depositProduct.attributes.dailyACHCreditLimit)
	const [monthlyACHDebitLimit, setMonthlyAchDebitLimit] = useState(depositProduct.attributes.monthlyACHDebitLimit)
	const [monthlyACHCreditLimit, setMonthlyAchCreditLimit] = useState(depositProduct.attributes.monthlyACHCreditLimit)
	const [dailyACHDebitSoftLimit, setDailyAchDebitSoftLimit] = useState(depositProduct.attributes.dailyACHDebitSoftLimit)
	const [monthlyACHDebitSoftLimit, setMonthlyAchDebitSoftLimit] = useState(
		depositProduct.attributes.monthlyACHDebitSoftLimit
	)

	const [dailyBookDebitLimit, setDailyBookDebitLimit] = useState<number | undefined | null>(
		depositProduct.attributes.dailyBookDebitLimit
	)
	const [dailyBookCreditLimit, setDailyBookCreditLimit] = useState<number | undefined | null>(
		depositProduct.attributes.dailyBookCreditLimit
	)
	const [monthlyBookDebitLimit, setMonthlyBookDebitLimit] = useState<number | undefined | null>(
		depositProduct.attributes.monthlyBookDebitLimit
	)
	const [monthlyBookCreditLimit, setMonthlyBookCreditLimit] = useState<number | undefined | null>(
		depositProduct.attributes.monthlyBookCreditLimit
	)

	const [dailyCardWithdrawalLimit, setDailyCardWithdrawalLimit] = useState(
		depositProduct.attributes.dailyCardWithdrawalLimit
	)
	const [dailyCardDepositLimit, setDailyCardDepositLimit] = useState(depositProduct.attributes.dailyCardDepositLimit)
	const [dailyCardPurchaseLimit, setDailyCardPurchaseLimit] = useState(depositProduct.attributes.dailyCardPurchaseLimit)
	const [dailyCardTransactionLimit, setDailyCardTransactionLimit] = useState(
		depositProduct.attributes.dailyCardTransactionLimit
	)
	const [achDebitClearingDays, setACHDebitClearingDays] = useState(depositProduct.attributes.achDebitClearingDays)
	const [isAutoCloseEnabled, setIsAutoCloseEnabled] = useState<boolean | undefined>(
		depositProduct.attributes.isAutoCloseEnabled
	)
	const [autoCloseAfter, setAutoCloseAfter] = useState(depositProduct.attributes.autoCloseAfter)

	const [interestTerms] = useState(depositProduct.attributes.interestTerms)
	const [orgInterestShare, setOrgInterestShare] = useState<number | undefined>(interestTerms?.orgShare)
	const [customerInterestShare, setCustomerInterestShare] = useState<number | undefined>(interestTerms?.customerShare)
	const [sponsoredInterestFormula, setSetSponsoredInterestFormula] = useState<string | undefined>(
		interestTerms?.sponsoredInterestFormula
	)

	const [cardProduct] = useState(depositProduct.attributes.cardProduct)
	const [cardIssuingFee, setCardIssuingFee] = useState(cardProduct ? cardProduct.cardIssuingFee : null)
	const [cardQualifier, setCardQualifier] = useState<string | null>(cardProduct ? cardProduct.cardQualifier : null)
	const [individualBinId, setIndividualBinId] = useState<string | null>(
		cardProduct?.individualBinId ? cardProduct.individualBinId : null
	)
	const [businessBinId, setBusinessBinId] = useState<string | null>(
		cardProduct?.businessBinId ? cardProduct.businessBinId : null
	)
	const [limitGroup, setLimitGroup] = useState<string | null>(cardProduct ? cardProduct.limitGroup : null)
	const [maxNumberOfPhysicalCards, setMaxNumberOfPhysicalCards] = useState<number | null>(
		cardProduct ? cardProduct.maxNumberOfPhysicalCards : null
	)
	const [maxNumberOfVirtualCards, setMaxNumberOfVirtualCards] = useState<number | null>(
		cardProduct ? cardProduct.maxNumberOfVirtualCards : null
	)
	const [programmaticPurchaseAuthorizationEnabled, setProgrammaticPurchaseAuthorizationEnabled] = useState<
		boolean | null
	>(cardProduct ? cardProduct.programmaticPurchaseAuthorizationEnabled : null)
	const [programmaticPurchaseAuthorizationTimeoutStrategy, setProgrammaticPurchaseAuthorizationTimeoutStrategy] =
		useState<string | null>(
			cardProduct?.programmaticPurchaseAuthorizationTimeoutStrategy
				? cardProduct.programmaticPurchaseAuthorizationTimeoutStrategy
				: null
		)
	const [programmaticPurchaseAuthorizationIncludeCredit, setProgrammaticPurchaseAuthorizationIncludeCredit] = useState<
		boolean | null
	>(
		cardProduct?.programmaticPurchaseAuthorizationIncludeCredit
			? cardProduct.programmaticPurchaseAuthorizationIncludeCredit
			: null
	)
	const [programmaticCardTransactionAuthorizationEnabled, setProgrammaticCardTransactionAuthorizationEnabled] =
		useState<boolean | null>(cardProduct ? cardProduct.programmaticCardTransactionAuthorizationEnabled : null)
	const [
		programmaticCardTransactionAuthorizationTimeoutStrategy,
		setProgrammaticCardTransactionAuthorizationTimeoutStrategy,
	] = useState<string | null>(
		cardProduct?.programmaticCardTransactionAuthorizationTimeoutStrategy
			? cardProduct.programmaticCardTransactionAuthorizationTimeoutStrategy
			: null
	)
	const [
		programmaticCardTransactionAuthorizationIncludeCredit,
		setProgrammaticCardTransactionAuthorizationIncludeCredit,
	] = useState<boolean | null>(
		cardProduct?.programmaticCardTransactionAuthorizationIncludeCredit
			? cardProduct.programmaticCardTransactionAuthorizationIncludeCredit
			: null
	)
	const [programmaticAtmAuthorizationEnabled, setProgrammaticAtmAuthorizationEnabled] = useState<boolean | null>(
		cardProduct ? cardProduct.programmaticAtmAuthorizationEnabled : null
	)
	const [programmaticAtmAuthorizationTimeoutStrategy, setProgrammaticAtmAuthorizationTimeoutStrategy] = useState<
		string | null
	>(
		cardProduct?.programmaticAtmAuthorizationTimeoutStrategy
			? cardProduct.programmaticAtmAuthorizationTimeoutStrategy
			: null
	)

	const [interestSettingsId, setInterestSettingsId] = useState(
		depositProduct.relationships?.interestSettings?.data.id
			? depositProduct.relationships?.interestSettings?.data.id
			: null
	)

	const [isOverrideInterestSettingsId, setIsOverrideInterestSettingsId] = useState(!!interestSettingsId)

	const [programmaticAdviceAuthorizationEnabled, setProgrammaticAdviceAuthorizationEnabled] = useState<boolean | null>(
		cardProduct?.programmaticAdviceAuthorizationEnabled ? cardProduct.programmaticAdviceAuthorizationEnabled : null
	)

	const [incomingWireFee, setIncomingWireFee] = useState(depositProduct.attributes.incomingWireFee)
	const [outgoingWireFee, setOutgoingWireFee] = useState(depositProduct.attributes.outgoingWireFee)
	const [checkDepositClearingDays, setCheckDepositClearingDays] = useState(
		depositProduct.attributes.checkDepositClearingDays
	)

	const [checkDepositMaxClearingDays, setCheckDepositMaxClearingDays] = useState<number | undefined | null>(
		depositProduct.attributes.checkDepositMaxClearingDays ?? null
	)
	const [checkDepositMinClearingDays, setCheckDepositMinClearingDays] = useState<number | undefined | null>(
		depositProduct.attributes.checkDepositMinClearingDays ?? null
	)

	const [dailyCheckDepositLimit, setDailyCheckDepositLimit] = useState(depositProduct.attributes.dailyCheckDepositLimit)
	const [monthlyCheckDepositLimit, setMonthlyCheckDepositLimit] = useState(
		depositProduct.attributes.monthlyCheckDepositLimit
	)
	const [dailyCheckDepositSoftLimit, setDailyCheckDepositSoftLimit] = useState(
		depositProduct.attributes.dailyCheckDepositSoftLimit
	)
	const [monthlyCheckDepositSoftLimit, setMonthlyCheckDepositSoftLimit] = useState(
		depositProduct.attributes.monthlyCheckDepositSoftLimit
	)

	const [dailyWireLimit, setDailyWireLimit] = useState(depositProduct.attributes.dailyWireLimit)
	const [monthlyWireLimit, setMonthlyWireLimit] = useState(depositProduct.attributes.monthlyWireLimit)
	const [dailyWireSoftLimit, setDailyWireSoftLimit] = useState(depositProduct.attributes.dailyWireSoftLimit)
	const [monthlyWireSoftLimit, setMonthlyWireSoftLimit] = useState(depositProduct.attributes.monthlyWireSoftLimit)

	const [dailyCheckPaymentLimit, setDailyCheckPaymentLimit] = useState<number | undefined | null>(
		depositProduct.attributes.dailyCheckPaymentLimit != undefined
			? depositProduct.attributes.dailyCheckPaymentLimit
			: undefined
	)
	const [monthlyCheckPaymentLimit, setMonthlyCheckPaymentLimit] = useState<number | undefined | null>(
		depositProduct.attributes.monthlyCheckPaymentLimit ?? undefined
	)
	const [dailyCheckPaymentSoftLimit, setDailyCheckPaymentSoftLimit] = useState<number | undefined | null>(
		depositProduct.attributes.dailyCheckPaymentSoftLimit ?? undefined
	)
	const [monthlyCheckPaymentSoftLimit, setMonthlyCheckPaymentSoftLimit] = useState<number | undefined | null>(
		depositProduct.attributes.monthlyCheckPaymentSoftLimit ?? undefined
	)

	const [enableOverdraft, setEnableOverdraft] = useState(depositProduct.attributes.overdraftSettings?.enableOverdraft)
	const [overdraftDaysLimit, setOverdraftDaysLimit] = useState(
		depositProduct.attributes.overdraftSettings?.overdraftDaysLimit ?? null
	)

	const [enableCustomerInterestFormula, setEnableCustomerInterestFormula] = useState(
		depositProduct.attributes.enableCustomerInterestFormula
	)
	const [customerInterestFormula, setCustomerInterestFormula] = useState(
		depositProduct.attributes.customerInterestFormula ?? undefined
	)

	const [checkWritingEnabled, setCheckWritingEnabled] = useState(depositProduct.attributes.checkWritingEnabled ?? false)
	const [checkWritingOriginationEnabled, setCheckWritingOriginationEnabled] = useState(
		depositProduct.attributes.checkWritingOriginationEnabled ?? false
	)

	const [additionalVerificationThreshold, setAdditionalVerificationThreshold] = useState<number | undefined | null>(
		depositProduct.attributes.additionalVerificationThreshold ?? undefined
	)

	const [bankId, setBankId] = useState(depositProduct.relationships?.bank?.data.id)
	const [bankRoutingId, setBankRoutingId] = useState(depositProduct.relationships?.bankRouting?.data.id)

	const [enableCheckDepositDynamicClearingDays, setEnableCheckDepositDynamicClearingDays] = useState(
		depositProduct.attributes.enableCheckDepositDynamicClearingDays
	)
	const [enableDynamicClearingDaysAchDebit, setEnableDynamicClearingDaysAchDebit] = useState(
		depositProduct.attributes.enableDynamicClearingDaysAchDebit
	)

	const [achDebitMinClearingDays, setAchDebitMinClearingDays] = useState<number | undefined | null>(
		depositProduct.attributes.achDebitMinClearingDays ?? null
	)
	const [achDebitMaxClearingDays, setAchDebitMaxClearingDays] = useState<number | undefined | null>(
		depositProduct.attributes.achDebitMaxClearingDays ?? null
	)
	const formRef = useRef<HTMLFormElement>(null)
	const [isEnabled] = useState(depositProduct.attributes.status == Status.Enabled)

	const [showToggleDepositProductModal, hideToggleDepositProduct] = useModal(() => (
		<ToggleDepositProductModal
			depositProduct={depositProduct}
			disable={isEnabled}
			close={hideToggleDepositProduct}
			onSuccess={refresh}
		/>
	))

	const [isAchDebitFraudPredictEnabled, setIsAchDebitFraudPredictEnabled] = useState(
		depositProduct.attributes.isAchDebitFraudPredictEnabled
	)

	const [isSweepEnabled, setIsSweepEnabled] = useState(depositProduct.attributes.isSweepEnabled)
	const [orgInternationalServiceFee, setOrgInternationalServiceFee] = useState<number | null>(
		depositProduct.attributes.cardProduct?.orgInternationalServiceFee ?? 0
	)

	const [isAuditLogDrawerOpened, setIsAuditLogDrawerOpened] = useState<boolean>(false)
	useEffect(() => {
		if (state.isOk()) {
			addToast("Deposit Product Updated Successfully", {appearance: "success"})
			setIsEditing(false)
			refresh()
		}
	}, [state])

	const submit = () => {
		const anyCardProduct = individualBinId != null || businessBinId != null

		const cardProduct =
			cardIssuingFee != null &&
			cardQualifier != null &&
			limitGroup != null &&
			maxNumberOfPhysicalCards != null &&
			maxNumberOfVirtualCards != null &&
			(!programmaticPurchaseAuthorizationEnabled || programmaticPurchaseAuthorizationTimeoutStrategy != null) &&
			(!programmaticCardTransactionAuthorizationEnabled ||
				programmaticCardTransactionAuthorizationTimeoutStrategy != null) &&
			anyCardProduct
				? {
						cardIssuingFee: cardIssuingFee,
						cardQualifier: cardQualifier,
						limitGroup: limitGroup,
						maxNumberOfPhysicalCards: maxNumberOfPhysicalCards,
						maxNumberOfVirtualCards: maxNumberOfVirtualCards,
						programmaticPurchaseAuthorizationEnabled: !!programmaticPurchaseAuthorizationEnabled,
						programmaticPurchaseAuthorizationTimeoutStrategy: programmaticPurchaseAuthorizationTimeoutStrategy,
						programmaticPurchaseAuthorizationIncludeCredit: !!programmaticPurchaseAuthorizationIncludeCredit,
						programmaticCardTransactionAuthorizationEnabled: !!programmaticCardTransactionAuthorizationEnabled,
						programmaticCardTransactionAuthorizationTimeoutStrategy:
							programmaticCardTransactionAuthorizationTimeoutStrategy,
						programmaticCardTransactionAuthorizationIncludeCredit:
							!!programmaticCardTransactionAuthorizationIncludeCredit,
						programmaticAtmAuthorizationEnabled: !!programmaticAtmAuthorizationEnabled,
						programmaticAtmAuthorizationTimeoutStrategy: programmaticAtmAuthorizationTimeoutStrategy,
						programmaticAdviceAuthorizationEnabled: !!programmaticAdviceAuthorizationEnabled,
						individualBinId: individualBinId,
						businessBinId: businessBinId,
						orgInternationalServiceFee: orgInternationalServiceFee,
				  }
				: null

		const interestTerms = {
			orgShare: orgInterestShare,
			customerShare: customerInterestShare,
			sponsoredInterestFormula: sponsoredInterestFormula,
		}

		const overdraftSettings = {
			enableOverdraft,
			overdraftDaysLimit,
		}

		const editedAttributes = {
			incomingACHFee,
			outgoingACHFee,
			dailyACHDebitLimit,
			dailyACHCreditLimit,
			monthlyACHDebitLimit,
			monthlyACHCreditLimit,
			dailyACHDebitSoftLimit,
			monthlyACHDebitSoftLimit,
			dailyBookDebitLimit,
			dailyBookCreditLimit,
			monthlyBookDebitLimit,
			monthlyBookCreditLimit,
			dailyCardWithdrawalLimit,
			dailyCardDepositLimit,
			dailyCardPurchaseLimit,
			dailyCardTransactionLimit,
			achDebitClearingDays,
			incomingWireFee,
			outgoingWireFee,
			checkDepositClearingDays,
			checkDepositMinClearingDays,
			checkDepositMaxClearingDays,
			dailyCheckDepositLimit,
			monthlyCheckDepositLimit,
			dailyCheckDepositSoftLimit,
			monthlyCheckDepositSoftLimit,
			isAutoCloseEnabled,
			autoCloseAfter,
			overdraftSettings,
			dailyWireLimit,
			monthlyWireLimit,
			dailyWireSoftLimit,
			monthlyWireSoftLimit,
			dailyCheckPaymentLimit,
			monthlyCheckPaymentLimit,
			dailyCheckPaymentSoftLimit,
			monthlyCheckPaymentSoftLimit,
			checkWritingEnabled,
			enableCheckDepositDynamicClearingDays,
			enableDynamicClearingDaysAchDebit,
			achDebitMaxClearingDays,
			achDebitMinClearingDays,
			isAchDebitFraudPredictEnabled,
			isSweepEnabled,
			checkWritingOriginationEnabled,
			additionalVerificationThreshold,
			enableCustomerInterestFormula,
			customerInterestFormula,
		}

		const editedRelationships = {
			transactionMonitoringSettingsId,
			interestSettingsId,
		}
		update(accessToken, depositProduct, cardProduct, interestTerms, editedAttributes, editedRelationships)
	}

	function validate() {
		const anyCardProduct = individualBinId != null || businessBinId != null
		const deleteCardProduct = [
			cardIssuingFee,
			individualBinId,
			businessBinId,
			cardQualifier,
			limitGroup,
			maxNumberOfPhysicalCards,
			maxNumberOfVirtualCards,
			programmaticPurchaseAuthorizationEnabled,
			programmaticPurchaseAuthorizationTimeoutStrategy,
			programmaticCardTransactionAuthorizationEnabled,
			programmaticCardTransactionAuthorizationTimeoutStrategy,
			programmaticAtmAuthorizationEnabled,
			programmaticAtmAuthorizationTimeoutStrategy,
			programmaticPurchaseAuthorizationIncludeCredit,
			programmaticCardTransactionAuthorizationIncludeCredit,
			programmaticAdviceAuthorizationEnabled,
		].every((i) => i == null)

		return (
			(cardIssuingFee != null &&
				cardQualifier != null &&
				limitGroup != null &&
				maxNumberOfPhysicalCards != null &&
				maxNumberOfVirtualCards != null &&
				(programmaticPurchaseAuthorizationEnabled
					? !isNil(programmaticPurchaseAuthorizationTimeoutStrategy || null)
					: true) &&
				(programmaticCardTransactionAuthorizationEnabled
					? !isNil(programmaticCardTransactionAuthorizationTimeoutStrategy || null)
					: true) &&
				(programmaticAtmAuthorizationEnabled ? !isNil(programmaticAtmAuthorizationTimeoutStrategy || null) : true) &&
				anyCardProduct) ||
			deleteCardProduct
		)
	}

	function createDuplicateState(): DuplicateDepositProductState | undefined {
		if (
			bankId !== undefined &&
			bankRoutingId !== undefined &&
			customerInterestShare !== undefined &&
			orgId !== undefined &&
			orgInterestShare !== undefined &&
			transactionMonitoringSettingsId !== undefined &&
			isAutoCloseEnabled !== undefined &&
			autoCloseAfter !== undefined &&
			enableOverdraft !== undefined &&
			checkWritingEnabled !== undefined &&
			isAchDebitFraudPredictEnabled !== undefined &&
			isSweepEnabled !== undefined &&
			checkWritingOriginationEnabled !== undefined
		) {
			return {
				duplicateDepositProduct: {
					name,
					achDebitClearingDays,
					bankId,
					bankRoutingId,
					businessBinId,
					cardIssuingFee,
					cardQualifier,
					checkDepositClearingDays,
					checkDepositMinClearingDays,
					checkDepositMaxClearingDays,
					customerInterestShare,
					dailyACHCreditLimit,
					dailyACHDebitLimit,
					dailyACHDebitSoftLimit,
					dailyBookDebitLimit,
					dailyBookCreditLimit,
					dailyCardDepositLimit,
					dailyCardPurchaseLimit,
					dailyCardTransactionLimit,
					dailyCardWithdrawalLimit,
					dailyCheckDepositLimit,
					dailyCheckDepositSoftLimit,
					incomingACHFee,
					incomingWireFee,
					outgoingWireFee,
					individualBinId,
					limitGroup,
					maxNumberOfPhysicalCards,
					maxNumberOfVirtualCards,
					monthlyACHCreditLimit,
					monthlyACHDebitLimit,
					monthlyACHDebitSoftLimit,
					monthlyBookDebitLimit,
					monthlyBookCreditLimit,
					monthlyCheckDepositLimit,
					monthlyCheckDepositSoftLimit,
					orgId,
					orgInterestShare,
					outgoingACHFee,
					programmaticPurchaseAuthorizationEnabled,
					programmaticPurchaseAuthorizationTimeoutStrategy,
					programmaticPurchaseAuthorizationIncludeCredit,
					programmaticCardTransactionAuthorizationEnabled,
					programmaticCardTransactionAuthorizationTimeoutStrategy,
					programmaticCardTransactionAuthorizationIncludeCredit,
					transactionMonitoringSettingsId,
					sponsoredInterestFormula,
					programmaticAtmAuthorizationEnabled,
					programmaticAtmAuthorizationTimeoutStrategy,
					programmaticAdviceAuthorizationEnabled,
					isAutoCloseEnabled,
					autoCloseAfter,
					enableOverdraft,
					overdraftDaysLimit,
					dailyWireLimit,
					dailyWireSoftLimit,
					monthlyWireLimit,
					monthlyWireSoftLimit,
					dailyCheckPaymentLimit,
					monthlyCheckPaymentLimit,
					dailyCheckPaymentSoftLimit,
					monthlyCheckPaymentSoftLimit,
					checkWritingEnabled,
					interestSettingsId,
					enableCheckDepositDynamicClearingDays,
					enableDynamicClearingDaysAchDebit,
					achDebitMaxClearingDays,
					achDebitMinClearingDays,
					isAchDebitFraudPredictEnabled,
					isSweepEnabled,
					checkWritingOriginationEnabled,
					orgInternationalServiceFee,
					additionalVerificationThreshold,
					enableCustomerInterestFormula,
					customerInterestFormula,
				},
			}
		}
	}

	// const link = new Link<number | undefined>(5)

	function calcMaxAllocation(x: number, y: number) {
		return 100 - x - y
	}

	const initialFormValues = {
		name: depositProduct.attributes.name ?? undefined,
		orgId: depositProduct.relationships?.org?.data.id ?? undefined,
		bankId: depositProduct.relationships?.bank?.data.id ?? undefined,
		bankRoutingId: depositProduct.relationships?.bankRouting?.data.id ?? undefined,
		orgInterestShare: undefined,
		customerInterestShare: undefined,
		sponsoredInterestFormula: undefined,
		transactionMonitoringSettingsId: depositProduct.relationships?.transactionMonitoringSettings?.data.id ?? undefined,
		isAutoCloseEnabled: depositProduct.attributes.isAutoCloseEnabled ?? undefined,
		autoCloseAfter: depositProduct.attributes.autoCloseAfter ?? undefined,
		incomingACHFee: depositProduct.attributes.incomingACHFee ?? undefined,
		outgoingACHFee: depositProduct.attributes.outgoingACHFee ?? undefined,
		achDebitClearingDays: depositProduct.attributes.achDebitClearingDays ?? undefined,
		dailyACHDebitLimit: depositProduct.attributes.dailyACHDebitLimit ?? undefined,
		dailyACHCreditLimit: depositProduct.attributes.dailyACHCreditLimit ?? undefined,
		dailyACHDebitSoftLimit: depositProduct.attributes.dailyACHDebitSoftLimit ?? undefined,
		monthlyACHDebitLimit: depositProduct.attributes.monthlyACHDebitLimit ?? undefined,
		monthlyACHCreditLimit: depositProduct.attributes.monthlyACHCreditLimit ?? undefined,
		monthlyACHDebitSoftLimit: depositProduct.attributes.monthlyACHDebitSoftLimit ?? undefined,
		dailyBookDebitLimit: depositProduct.attributes.dailyBookDebitLimit ?? undefined,
		dailyBookCreditLimit: depositProduct.attributes.dailyBookCreditLimit ?? undefined,
		monthlyBookDebitLimit: depositProduct.attributes.monthlyBookDebitLimit ?? undefined,
		monthlyBookCreditLimit: depositProduct.attributes.monthlyBookCreditLimit ?? undefined,
		dailyCardWithdrawalLimit: depositProduct.attributes.dailyCardWithdrawalLimit ?? undefined,
		dailyCardDepositLimit: depositProduct.attributes.dailyCardDepositLimit ?? undefined,
		dailyCardPurchaseLimit: depositProduct.attributes.dailyCardPurchaseLimit ?? undefined,
		dailyCardTransactionLimit: depositProduct.attributes.dailyCardTransactionLimit ?? undefined,
		dailyCheckDepositLimit: depositProduct.attributes.dailyCheckDepositLimit ?? undefined,
		dailyCheckDepositSoftLimit: depositProduct.attributes.dailyCheckDepositSoftLimit ?? undefined,
		monthlyCheckDepositLimit: depositProduct.attributes.monthlyCheckDepositLimit ?? undefined,
		monthlyCheckDepositSoftLimit: depositProduct.attributes.monthlyCheckDepositSoftLimit ?? undefined,
		incomingWireFee: depositProduct.attributes.incomingWireFee ?? undefined,
		outgoingWireFee: depositProduct.attributes.outgoingWireFee ?? undefined,
		individualBinId: cardProduct?.individualBinId ? cardProduct.individualBinId : null ?? undefined,
		businessBinId: cardProduct?.businessBinId ? cardProduct.businessBinId : null ?? undefined,
		cardQualifier: cardProduct ? cardProduct.cardQualifier : null ?? undefined,
		limitGroup: cardProduct ? cardProduct.limitGroup : null ?? undefined,
		maxNumberOfPhysicalCards: cardProduct ? cardProduct.maxNumberOfPhysicalCards : null ?? undefined,
		maxNumberOfVirtualCards: cardProduct ? cardProduct.maxNumberOfVirtualCards : null ?? undefined,
		cardIssuingFee: cardProduct ? cardProduct.cardIssuingFee : null ?? undefined,
		dailyWireLimit: depositProduct.attributes.dailyWireLimit ?? undefined,
		dailyWireSoftLimit: depositProduct.attributes.dailyWireSoftLimit ?? undefined,
		monthlyWireLimit: depositProduct.attributes.monthlyWireLimit ?? undefined,
		monthlyWireSoftLimit: depositProduct.attributes.monthlyWireSoftLimit ?? undefined,
		dailyCheckPaymentLimit: depositProduct.attributes.dailyCheckPaymentLimit ?? undefined,
		dailyCheckPaymentSoftLimit: depositProduct.attributes.dailyCheckPaymentSoftLimit ?? undefined,
		monthlyCheckPaymentLimit: depositProduct.attributes.monthlyCheckPaymentLimit ?? undefined,
		monthlyCheckPaymentSoftLimit: depositProduct.attributes.monthlyCheckPaymentSoftLimit ?? undefined,
		programmaticPurchaseAuthorizationEnabled: cardProduct
			? cardProduct.programmaticPurchaseAuthorizationEnabled
			: null ?? undefined,
		programmaticPurchaseAuthorizationTimeoutStrategy: cardProduct?.programmaticPurchaseAuthorizationTimeoutStrategy
			? cardProduct.programmaticPurchaseAuthorizationTimeoutStrategy
			: null ?? undefined,
		programmaticPurchaseAuthorizationIncludeCredit: cardProduct
			? cardProduct.programmaticPurchaseAuthorizationIncludeCredit
			: null ?? undefined,
		programmaticCardTransactionAuthorizationEnabled: cardProduct
			? cardProduct.programmaticCardTransactionAuthorizationEnabled
			: null ?? undefined,
		programmaticCardTransactionAuthorizationTimeoutStrategy:
			cardProduct?.programmaticCardTransactionAuthorizationTimeoutStrategy
				? cardProduct.programmaticCardTransactionAuthorizationTimeoutStrategy
				: null ?? undefined,
		programmaticCardTransactionAuthorizationIncludeCredit: cardProduct
			? cardProduct.programmaticCardTransactionAuthorizationIncludeCredit
			: null ?? undefined,
		programmaticAtmAuthorizationEnabled: cardProduct
			? cardProduct.programmaticAtmAuthorizationEnabled
			: null ?? undefined,
		programmaticAtmAuthorizationTimeoutStrategy: cardProduct?.programmaticAtmAuthorizationTimeoutStrategy
			? cardProduct.programmaticAtmAuthorizationTimeoutStrategy
			: null ?? undefined,
		programmaticAdviceAuthorizationEnabled: cardProduct
			? cardProduct.programmaticAdviceAuthorizationEnabled
			: null ?? undefined,
		checkDepositClearingDays: depositProduct.attributes.checkDepositClearingDays ?? undefined,
		checkDepositMinClearingDays: depositProduct.attributes.checkDepositMinClearingDays ?? undefined,
		checkDepositMaxClearingDays: depositProduct.attributes.checkDepositMaxClearingDays ?? undefined,
		enableOverdraft: depositProduct.attributes.overdraftSettings?.enableOverdraft ?? undefined,
		overdraftDaysLimit: depositProduct.attributes.overdraftSettings?.overdraftDaysLimit ?? null ?? undefined,
		checkWritingEnabled: depositProduct.attributes.checkWritingEnabled ?? undefined,
		isOverrideInterestSettingsId: undefined,
		interestSettingsId: depositProduct.relationships?.interestSettings?.data.id ?? undefined,
		enableCheckDepositDynamicClearingDays: depositProduct.attributes.enableCheckDepositDynamicClearingDays ?? undefined,
		enableDynamicClearingDaysAchDebit: depositProduct.attributes.enableDynamicClearingDaysAchDebit ?? undefined,
		achDebitMinClearingDays: depositProduct.attributes.achDebitMinClearingDays ?? undefined,
		achDebitMaxClearingDays: depositProduct.attributes.achDebitMaxClearingDays ?? undefined,
		isAchDebitFraudPredictEnabled: depositProduct.attributes.isAchDebitFraudPredictEnabled ?? undefined,
		isSweepEnabled: depositProduct.attributes.isSweepEnabled ?? undefined,
		checkWritingOriginationEnabled: depositProduct.attributes.checkWritingOriginationEnabled ?? undefined,
		additionalVerificationThreshold: depositProduct.attributes.additionalVerificationThreshold ?? undefined,
		enableCustomerInterestFormula: depositProduct.attributes.enableCustomerInterestFormula ?? undefined,
		customerInterestFormula: depositProduct.attributes.customerInterestFormula ?? undefined,
	}

	// const getInterestShareValidationField = () => {
	//     return {
	//         name: "max",
	//         exclusive: false,
	//         params: { },
	//         message: "orgInterestShare must be less than 10% of the price",
	//         test: function (value: any) {
	//             return value <= calcMaxAllocation(this.parent.customerInterestShare, this.parent.unitInterestShare)
	//         }
	//     }
	// }

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(""),
		orgId: Yup.string(),
		bankId: Yup.string(),
		bankRoutingId: Yup.string(),
		orgInterestShare: Yup.number()
			.min(0, "Error")
			.test({
				name: "max",
				exclusive: false,
				params: {},
				message: "Error",
				test: function (value: any) {
					return value <= calcMaxAllocation(this.parent.customerInterestShare, this.parent.unitInterestShare)
				},
			})
			.required(""),
		customerInterestShare: Yup.number()
			.min(0, "Error")
			.test({
				name: "max",
				exclusive: false,
				params: {},
				message: "Error",
				test: function (value: any) {
					return value <= calcMaxAllocation(this.parent.orgInterestShare, this.parent.unitInterestShare)
				},
			})
			.required(""),
		unitInterestShare: Yup.number()
			.min(0, "Error")
			.test({
				name: "max",
				exclusive: false,
				params: {},
				message: "Error",
				test: function (value: any) {
					return value <= calcMaxAllocation(this.parent.customerInterestShare, this.parent.customerInterestShare)
				},
			})
			.required(""),
		sponsoredInterestFormula: Yup.number().nullable().min(0, ""),
		transactionMonitoringSettingsId: Yup.string().required(""),
		isAutoCloseEnabled: Yup.boolean(),
		autoCloseAfter: Yup.number().min(0, ""),
		incomingACHFee: Yup.number().required(""),
		outgoingACHFee: Yup.number().required(""),
		achDebitClearingDays: Yup.string(),
		dailyACHDebitLimit: Yup.number().required(""),
		dailyACHCreditLimit: Yup.number().required(""),
		dailyACHDebitSoftLimit: Yup.number().required(""),
		monthlyACHDebitLimit: Yup.number().required(""),
		monthlyACHCreditLimit: Yup.number().required(""),
		monthlyACHDebitSoftLimit: Yup.number().required(""),
		dailyCardWithdrawalLimit: Yup.number().required(""),
		dailyCardDepositLimit: Yup.number().required(""),
		dailyCardPurchaseLimit: Yup.number().required(""),
		dailyCardTransactionLimit: Yup.number().required(""),
		dailyCheckDepositLimit: Yup.number().required(""),
		dailyCheckDepositSoftLimit: Yup.number().required(""),
		monthlyCheckDepositLimit: Yup.number().required(""),
		monthlyCheckDepositSoftLimit: Yup.number().required(""),
		incomingWireFee: Yup.number().required(""),
		outgoingWireFee: Yup.number().required(""),
		individualBinId: Yup.string(),
		businessBinId: Yup.string(),
		cardQualifier: Yup.string(),
		limitGroup: Yup.string(),
		maxNumberOfPhysicalCards: Yup.number(),
		maxNumberOfVirtualCards: Yup.number(),
		cardIssuingFee: Yup.number(),
		programmaticPurchaseAuthorizationEnabled: Yup.boolean(),
		programmaticPurchaseAuthorizationTimeoutStrategy: Yup.string(),
		programmaticPurchaseAuthorizationIncludeCredit: Yup.boolean(),
		programmaticCardTransactionAuthorizationEnabled: Yup.boolean(),
		programmaticCardTransactionAuthorizationTimeoutStrategy: Yup.string(),
		programmaticCardTransactionAuthorizationIncludeCredit: Yup.boolean(),
		programmaticAtmAuthorizationEnabled: Yup.boolean(),
		programmaticAtmAuthorizationTimeoutStrategy: Yup.string(),
		programmaticAdviceAuthorizationEnabled: Yup.boolean(),
		checkDepositClearingDays: Yup.string(),
		enableOverdraft: Yup.boolean(),
		overdraftDaysLimit: Yup.number().min(0, ""),
		checkWritingEnabled: Yup.boolean().required(""),
		isOverrideInterestSettingsId: Yup.boolean(),
		isAchDebitFraudPredictEnabled: Yup.boolean(),
		isSweepEnabled: Yup.boolean(),
		checkWritingOriginationEnabled: Yup.boolean().required(""),
	})

	const submitForm = (values: FormikValues) => {
		const anyCardProduct = values.individualBinId != null || values.businessBinId != null

		const cardProduct =
			values.cardIssuingFee != null &&
			values.cardQualifier != null &&
			values.limitGroup != null &&
			values.maxNumberOfPhysicalCards != null &&
			values.maxNumberOfVirtualCards != null &&
			(!values.programmaticPurchaseAuthorizationEnabled ||
				values.programmaticPurchaseAuthorizationTimeoutStrategy != null) &&
			(!values.programmaticCardTransactionAuthorizationEnabled ||
				values.programmaticCardTransactionAuthorizationTimeoutStrategy != null) &&
			(!values.programmaticAtmAuthorizationEnabled || values.programmaticAtmAuthorizationTimeoutStrategy != null) &&
			!values.programmaticAdviceAuthorizationEnabled &&
			anyCardProduct
				? {
						cardIssuingFee: values.cardIssuingFee,
						cardQualifier: values.cardQualifier,
						limitGroup: values.limitGroup,
						maxNumberOfPhysicalCards: values.maxNumberOfPhysicalCards,
						maxNumberOfVirtualCards: values.maxNumberOfVirtualCards,
						programmaticPurchaseAuthorizationEnabled: !!values.programmaticPurchaseAuthorizationEnabled,
						programmaticPurchaseAuthorizationTimeoutStrategy: values.programmaticPurchaseAuthorizationTimeoutStrategy,
						programmaticPurchaseAuthorizationIncludeCredit: !!values.programmaticPurchaseAuthorizationIncludeCredit,
						programmaticCardTransactionAuthorizationEnabled: !!values.programmaticCardTransactionAuthorizationEnabled,
						programmaticCardTransactionAuthorizationTimeoutStrategy:
							values.programmaticCardTransactionAuthorizationTimeoutStrategy,
						programmaticCardTransactionAuthorizationIncludeCredit:
							!!values.programmaticCardTransactionAuthorizationIncludeCredit,
						programmaticAtmAuthorizationEnabled: !!values.programmaticAtmAuthorizationEnabled,
						programmaticAtmAuthorizationTimeoutStrategy: values.programmaticAtmAuthorizationTimeoutStrategy,
						programmaticAdviceAuthorizationEnabled: !!values.programmaticAdviceAuthorizationEnabled,
						individualBinId: values.individualBinId,
						businessBinId: values.businessBinId,
				  }
				: null

		const interestTerms = {
			orgShare: values.orgInterestShare,
			customerShare: values.customerInterestShare,
			unitShare: values.unitInterestShare,
			sponsoredInterestFormula: values.sponsoredInterestFormula,
		}

		const overdraftSettings = {
			enableOverdraft: values.enableOverdraft,
			overdraftDaysLimit: values.overdraftDaysLimit,
		}

		const editedAttributes = {
			incomingACHFee: values.incomingACHFee,
			outgoingACHFee: values.outgoingACHFee,
			dailyACHDebitLimit: values.dailyACHDebitLimit,
			dailyACHCreditLimit: values.dailyACHCreditLimit,
			monthlyACHDebitLimit: values.monthlyACHDebitLimit,
			monthlyACHCreditLimit: values.monthlyACHCreditLimit,
			dailyACHDebitSoftLimit: values.dailyACHDebitSoftLimit,
			monthlyACHDebitSoftLimit: values.monthlyACHDebitSoftLimit,
			dailyBookDebitLimit: values.dailyBookDebitLimit,
			dailyBookCreditLimit: values.dailyBookCreditLimit,
			monthlyBookDebitLimit: values.monthlyBookDebitLimit,
			monthlyBookCreditLimit: values.monthlyBookCreditLimit,
			dailyCardWithdrawalLimit: values.dailyCardWithdrawalLimit,
			dailyCardDepositLimit: values.dailyCardDepositLimit,
			dailyCardPurchaseLimit: values.dailyCardPurchaseLimit,
			dailyCardTransactionLimit: values.dailyCardTransactionLimit,
			achDebitClearingDays: values.achDebitClearingDays,
			incomingWireFee: values.incomingWireFee,
			outgoingWireFee: values.outgoingWireFee,
			checkDepositClearingDays: values.checkDepositClearingDays,
			checkDepositMinClearingDays: values.checkDepositMinClearingDays,
			checkDepositMaxClearingDays: values.checkDepositMaxClearingDays,
			dailyCheckDepositLimit: values.dailyCheckDepositLimit,
			monthlyCheckDepositLimit: values.monthlyCheckDepositLimit,
			dailyCheckDepositSoftLimit: values.dailyCheckDepositSoftLimit,
			monthlyCheckDepositSoftLimit: values.monthlyCheckDepositSoftLimit,
			dailyCheckPaymentLimit: values.dailyCheckPaymentLimit,
			monthlyCheckPaymentLimit: values.monthlyCheckPaymentLimit,
			dailyCheckPaymentSoftLimit: values.dailyCheckPaymentSoftLimit,
			monthlyCheckPaymentSoftLimit: values.monthlyCheckPaymentSoftLimit,
			isAutoCloseEnabled: values.isAutoCloseEnabled,
			autoCloseAfter: values.autoCloseAfter,
			overdraftSettings,
			checkWritingEnabled: values.checkWritingEnabled,
			enableCheckDepositDynamicClearingDays: values.enableCheckDepositDynamicClearingDays,
			enableDynamicClearingPeriod: values.enableDynamicClearingPeriod,
			achDebitMaxClearingDays: values.achDebitMaxClearingDays,
			achDebitMinClearingDays: values.achDebitMinClearingDays,
			isAchDebitFraudPredictEnabled: values.isAchDebitFraudPredictEnabled,
			isSweepEnabled: values.isSweepEnabled,
			checkWritingOriginationEnabled: values.checkWritingOriginationEnabled,
		}

		const editedRelationships = {
			transactionMonitoringSettingsId: values.transactionMonitoringSettingsId,
			interestSettingsId: values.interestSettingsId, // Test it when starting to use the Formik
		}

		update(accessToken, depositProduct, cardProduct, interestTerms, editedAttributes, editedRelationships)
	}

	const auditLogEventsHeaderMetaItems: AuditLogEventsHeaderMetaItemProps[] = [
		{
			text: depositProduct.attributes.name,
			icon: "list-bullet-point-square",
			tooltipText: "Deposit Product",
		},
		{
			text: banks.find((bank) => bank.id === bankId)?.attributes.name,
			icon: "bank",
			tooltipText: "Bank Name",
		},
		{
			text: orgs.find((org) => org.id === orgId)?.attributes.name,
			icon: "user-hierachy2",
			tooltipText: "Org Name",
		},
	]

	return (
		<>
			<TitleBar
				title={depositProduct.attributes.name}
				breadcrumbs={[{text: "Deposit Products", path: "/deposit-products/"}]}
				meta={
					userHasPermission
						? [
								{text: `#${bankId}`, icon: "bank"},
								{text: orgs.find((org) => org.id === orgId)?.attributes.name ?? "", icon: "user-hierachy2"},
						  ]
						: undefined
				}
			>
				{useIsUnitUser() && <AuditLogActionButton onClick={() => setIsAuditLogDrawerOpened(true)} />}
				{userHasPermission ? (
					<>
						{!isEditing ? (
							!isEnabled ? (
								<div data-tip={true} data-iscapture={true} data-for={"cannot-edit-when-disabled"}>
									<button className="button page-title-button is-static disabled">
										<Icon icon={"pencil-1--interface-essential"} size={16} />
										<span>{"Edit"}</span>
									</button>
								</div>
							) : (
								<button className="button page-title-button" onClick={() => setIsEditing(true)}>
									<Icon icon={"pencil-1--interface-essential"} size={16} />
									<span>{"Edit"}</span>
								</button>
							)
						) : (
							<>
								<button className="button button-cancel" onClick={() => refresh()}>
									<Icon icon={"interface-delete-interface-essential"} size={12} />
									<span>{"Discard"}</span>
								</button>
							</>
						)}

						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									onClick={() => navigate("/deposit-products/new", {state: createDuplicateState()})}
									title={"Duplicate"}
									icon={"doc-layer-copy"}
								/>
								<ActionItem
									data-tip
									disabled={hasAssociatedAccounts}
									data-iscapture={true}
									data-for={hasAssociatedAccounts && "disable-depositProduct"}
									title={isEnabled ? "Disable" : "Enable"}
									icon={isEnabled ? "disable-delete--interface-essential" : "approve-v-check-validation"}
									onClick={showToggleDepositProductModal}
								/>
							</ActionButton>
						</TitleBarAction>
					</>
				) : null}
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={() => {
						setIsAuditLogDrawerOpened(false)
					}}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="deposit-product"
					resourceId={depositProduct.id}
				/>
				<div className={classNames(!userHasPermission && "deposit-product")}>
					{!userHasPermission ? (
						<>
							<Formik
								initialValues={initialFormValues}
								validateOnMount={true}
								validationSchema={validationSchema}
								onSubmit={(values) => submitForm(values)}
							>
								{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
									<form
										className="deposit-product-form"
										noValidate
										ref={formRef}
										onSubmit={(e) => {
											e.preventDefault()
											handleSubmit(e)
											if (!isValid) {
												focusFirstInvalidField(errors)
											}
										}}
									>
										{isEditing && (
											<General
												editMode={EditMode.EDIT}
												values={values}
												onChange={handleChange}
												setFieldValue={setFieldValue}
												onBlur={handleBlur}
												transactionMonitoringSettings={transactionMonitoringSettings}
												banks={banks}
												bankRoutings={bankRoutings}
											/>
										)}
										<Limits
											editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
											values={values}
											onChange={handleChange}
											setFieldValue={setFieldValue}
											onBlur={handleBlur}
										/>
										<Clearing
											editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
											values={values}
											onChange={handleChange}
											setFieldValue={setFieldValue}
											onBlur={handleBlur}
										/>
										<CardSettings
											editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
											values={values}
											onChange={handleChange}
											setFieldValue={setFieldValue}
											onBlur={handleBlur}
											bins={bins}
										/>
										<AccountFees
											editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
											values={values}
											onChange={handleChange}
											setFieldValue={setFieldValue}
											onBlur={handleBlur}
										/>
										{isEditing && (
											<Overdraft
												editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
												values={values}
												onChange={handleChange}
												setFieldValue={setFieldValue}
												onBlur={handleBlur}
											/>
										)}
									</form>
								)}
							</Formik>
							<TableOfContent querySelector=".deposit-product-card-title .card-title" />
						</>
					) : (
						<form
							onSubmit={(e) => {
								e.preventDefault()
								submit()
							}}
						>
							<fieldset disabled={!isEditing}>
								<Attributes
									editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
									orgs={orgs}
									banks={banks}
									bankRoutings={bankRoutings}
									transactionMonitoringSettings={transactionMonitoringSettings}
									bins={bins}
									name={name}
									setName={setName}
									orgId={orgId as string}
									setOrgId={setOrgId as React.Dispatch<React.SetStateAction<string>>}
									bankId={bankId as string}
									setBankId={setBankId as React.Dispatch<React.SetStateAction<string>>}
									bankRoutingId={bankRoutingId as string}
									setBankRoutingId={setBankRoutingId as React.Dispatch<React.SetStateAction<string>>}
									transactionMonitoringSettingsId={transactionMonitoringSettingsId as string}
									setTransactionMonitoringSettingsId={
										setTransactionMonitoringSettingsId as React.Dispatch<React.SetStateAction<string>>
									}
									incomingACHFee={incomingACHFee}
									setIncomingAchFee={setIncomingAchFee}
									outgoingACHFee={outgoingACHFee}
									setOutgoingAchFee={setOutgoingAchFee}
									dailyACHDebitLimit={dailyACHDebitLimit}
									setDailyAchDebitLimit={setDailyAchDebitLimit}
									dailyACHCreditLimit={dailyACHCreditLimit}
									setDailyAchCreditLimit={setDailyAchCreditLimit}
									monthlyACHDebitLimit={monthlyACHDebitLimit}
									setMonthlyAchDebitLimit={setMonthlyAchDebitLimit}
									monthlyACHCreditLimit={monthlyACHCreditLimit}
									setMonthlyAchCreditLimit={setMonthlyAchCreditLimit}
									dailyACHDebitSoftLimit={dailyACHDebitSoftLimit}
									setDailyAchDebitSoftLimit={setDailyAchDebitSoftLimit}
									monthlyACHDebitSoftLimit={monthlyACHDebitSoftLimit}
									setMonthlyAchDebitSoftLimit={setMonthlyAchDebitSoftLimit}
									dailyBookDebitLimit={dailyBookDebitLimit}
									setDailyBookDebitLimit={setDailyBookDebitLimit}
									dailyBookCreditLimit={dailyBookCreditLimit}
									setDailyBookCreditLimit={setDailyBookCreditLimit}
									monthlyBookDebitLimit={monthlyBookDebitLimit}
									setMonthlyBookDebitLimit={setMonthlyBookDebitLimit}
									monthlyBookCreditLimit={monthlyBookCreditLimit}
									setMonthlyBookCreditLimit={setMonthlyBookCreditLimit}
									dailyCardWithdrawalLimit={dailyCardWithdrawalLimit}
									setDailyCardWithdrawalLimit={setDailyCardWithdrawalLimit}
									dailyCardDepositLimit={dailyCardDepositLimit}
									setDailyCardDepositLimit={setDailyCardDepositLimit}
									dailyCardPurchaseLimit={dailyCardPurchaseLimit}
									setDailyCardPurchaseLimit={setDailyCardPurchaseLimit}
									dailyCardTransactionLimit={dailyCardTransactionLimit}
									setDailyCardTransactionLimit={setDailyCardTransactionLimit}
									achDebitClearingDays={achDebitClearingDays}
									setACHDebitClearingDays={setACHDebitClearingDays}
									cardIssuingFee={cardIssuingFee}
									setCardIssuingFee={setCardIssuingFee}
									individualBinId={individualBinId}
									setIndividualBinId={setIndividualBinId}
									businessBinId={businessBinId}
									setBusinessBinId={setBusinessBinId}
									cardQualifier={cardQualifier}
									setCardQualifier={setCardQualifier}
									limitGroup={limitGroup}
									setLimitGroup={setLimitGroup}
									interestSettings={interestSettings}
									maxNumberOfPhysicalCards={maxNumberOfPhysicalCards}
									setMaxNumberOfPhysicalCards={setMaxNumberOfPhysicalCards}
									maxNumberOfVirtualCards={maxNumberOfVirtualCards}
									setMaxNumberOfVirtualCards={setMaxNumberOfVirtualCards}
									orgInterestShare={orgInterestShare as number}
									setOrgInterestShare={setOrgInterestShare as React.Dispatch<React.SetStateAction<number>>}
									customerInterestShare={customerInterestShare as number}
									setCustomerInterestShare={setCustomerInterestShare as React.Dispatch<React.SetStateAction<number>>}
									sponsoredInterestFormula={sponsoredInterestFormula}
									setSponsoredInterestFormula={
										setSetSponsoredInterestFormula as React.Dispatch<React.SetStateAction<string | undefined>>
									}
									incomingWireFee={incomingWireFee}
									setIncomingWireFee={setIncomingWireFee}
									outgoingWireFee={outgoingWireFee}
									setOutgoingWireFee={setOutgoingWireFee}
									programmaticPurchaseAuthorizationEnabled={programmaticPurchaseAuthorizationEnabled}
									setProgrammaticPurchaseAuthorizationEnabled={setProgrammaticPurchaseAuthorizationEnabled}
									programmaticPurchaseAuthorizationTimeoutStrategy={programmaticPurchaseAuthorizationTimeoutStrategy}
									setProgrammaticPurchaseAuthorizationTimeoutStrategy={
										setProgrammaticPurchaseAuthorizationTimeoutStrategy
									}
									programmaticPurchaseAuthorizationIncludeCredit={programmaticPurchaseAuthorizationIncludeCredit}
									setProgrammaticPurchaseAuthorizationIncludeCredit={setProgrammaticPurchaseAuthorizationIncludeCredit}
									programmaticCardTransactionAuthorizationEnabled={programmaticCardTransactionAuthorizationEnabled}
									setProgrammaticCardTransactionAuthorizationEnabled={
										setProgrammaticCardTransactionAuthorizationEnabled
									}
									programmaticCardTransactionAuthorizationTimeoutStrategy={
										programmaticCardTransactionAuthorizationTimeoutStrategy
									}
									setProgrammaticCardTransactionAuthorizationTimeoutStrategy={
										setProgrammaticCardTransactionAuthorizationTimeoutStrategy
									}
									programmaticCardTransactionAuthorizationIncludeCredit={
										programmaticCardTransactionAuthorizationIncludeCredit
									}
									setProgrammaticCardTransactionAuthorizationIncludeCredit={
										setProgrammaticCardTransactionAuthorizationIncludeCredit
									}
									programmaticAtmAuthorizationEnabled={programmaticAtmAuthorizationEnabled}
									setProgrammaticAtmAuthorizationEnabled={setProgrammaticAtmAuthorizationEnabled}
									programmaticAtmAuthorizationTimeoutStrategy={programmaticAtmAuthorizationTimeoutStrategy}
									setProgrammaticAtmAuthorizationTimeoutStrategy={setProgrammaticAtmAuthorizationTimeoutStrategy}
									programmaticAdviceAuthorizationEnabled={programmaticAdviceAuthorizationEnabled}
									setProgrammaticAdviceAuthorizationEnabled={setProgrammaticAdviceAuthorizationEnabled}
									checkDepositClearingDays={checkDepositClearingDays}
									setCheckDepositClearingDays={setCheckDepositClearingDays}
									checkDepositMinClearingDays={checkDepositMinClearingDays}
									setCheckDepositMinClearingDays={setCheckDepositMinClearingDays}
									checkDepositMaxClearingDays={checkDepositMaxClearingDays}
									setCheckDepositMaxClearingDays={setCheckDepositMaxClearingDays}
									dailyCheckDepositLimit={dailyCheckDepositLimit}
									setDailyCheckDepositLimit={setDailyCheckDepositLimit}
									monthlyCheckDepositLimit={monthlyCheckDepositLimit}
									setMonthlyCheckDepositLimit={setMonthlyCheckDepositLimit}
									dailyCheckDepositSoftLimit={dailyCheckDepositSoftLimit}
									setDailyCheckDepositSoftLimit={setDailyCheckDepositSoftLimit}
									monthlyCheckDepositSoftLimit={monthlyCheckDepositSoftLimit}
									setMonthlyCheckDepositSoftLimit={setMonthlyCheckDepositSoftLimit}
									isAutoCloseEnabled={isAutoCloseEnabled as boolean}
									setIsAutoCloseEnabled={setIsAutoCloseEnabled as React.Dispatch<React.SetStateAction<boolean>>}
									autoCloseAfter={autoCloseAfter as number}
									setAutoCloseAfter={setAutoCloseAfter as React.Dispatch<React.SetStateAction<number>>}
									enableOverdraft={enableOverdraft as boolean}
									setEnableOverdraft={setEnableOverdraft as React.Dispatch<React.SetStateAction<boolean>>}
									overdraftDaysLimit={overdraftDaysLimit}
									setOverdraftDaysLimit={setOverdraftDaysLimit}
									dailyWireLimit={dailyWireLimit}
									setDailyWireLimit={setDailyWireLimit}
									monthlyWireLimit={monthlyWireLimit}
									setMonthlyWireLimit={setMonthlyWireLimit}
									dailyWireSoftLimit={dailyWireSoftLimit}
									setDailyWireSoftLimit={setDailyWireSoftLimit}
									monthlyWireSoftLimit={monthlyWireSoftLimit}
									setMonthlyWireSoftLimit={setMonthlyWireSoftLimit}
									dailyCheckPaymentLimit={dailyCheckPaymentLimit}
									setDailyCheckPaymentLimit={setDailyCheckPaymentLimit}
									monthlyCheckPaymentLimit={monthlyCheckPaymentLimit}
									setMonthlyCheckPaymentLimit={setMonthlyCheckPaymentLimit}
									dailyCheckPaymentSoftLimit={dailyCheckPaymentSoftLimit}
									setDailyCheckPaymentSoftLimit={setDailyCheckPaymentSoftLimit}
									monthlyCheckPaymentSoftLimit={monthlyCheckPaymentSoftLimit}
									setMonthlyCheckPaymentSoftLimit={setMonthlyCheckPaymentSoftLimit}
									checkWritingEnabled={checkWritingEnabled as boolean}
									setCheckWritingEnabled={setCheckWritingEnabled as React.Dispatch<React.SetStateAction<boolean>>}
									isOverrideInterestSettingsId={isOverrideInterestSettingsId}
									setIsOverrideInterestSettingsId={setIsOverrideInterestSettingsId}
									interestSettingsId={interestSettingsId}
									setInterestSettingsId={setInterestSettingsId}
									enableCheckDepositDynamicClearingDays={enableCheckDepositDynamicClearingDays}
									setEnableCheckDepositDynamicClearingDays={setEnableCheckDepositDynamicClearingDays}
									enableDynamicClearingDaysAchDebit={enableDynamicClearingDaysAchDebit}
									setEnableDynamicClearingDaysAchDebit={setEnableDynamicClearingDaysAchDebit}
									achDebitMinClearingDays={achDebitMinClearingDays}
									setAchDebitMinClearingDays={setAchDebitMinClearingDays}
									achDebitMaxClearingDays={achDebitMaxClearingDays}
									setAchDebitMaxClearingDays={setAchDebitMaxClearingDays}
									isAchDebitFraudPredictEnabled={isAchDebitFraudPredictEnabled}
									setIsAchDebitFraudPredictEnabled={setIsAchDebitFraudPredictEnabled}
									isSweepEnabled={isSweepEnabled}
									setIsSweepEnabled={setIsSweepEnabled}
									checkWritingOriginationEnabled={checkWritingOriginationEnabled as boolean}
									setCheckWritingOriginationEnabled={
										setCheckWritingOriginationEnabled as React.Dispatch<React.SetStateAction<boolean>>
									}
									orgInternationalServiceFee={orgInternationalServiceFee}
									setOrgInternationalServiceFee={setOrgInternationalServiceFee}
									additionalVerificationThreshold={additionalVerificationThreshold}
									setAdditionalVerificationThreshold={setAdditionalVerificationThreshold}
									enableCustomerInterestFormula={enableCustomerInterestFormula}
									setEnableCustomerInterestFormula={setEnableCustomerInterestFormula}
									customerInterestFormula={customerInterestFormula}
									setCustomerInterestFormula={setCustomerInterestFormula}
								/>
								{isEditing ? (
									<div className="columns">
										<div className="column">
											<div className="card">
												<Footer state={state} isDisabled={!validate()} />
											</div>
										</div>
									</div>
								) : null}
							</fieldset>
						</form>
					)}
				</div>

				<BodyPortal>
					<ReactTooltip
						arrowColor="#FFF"
						place="bottom"
						type="light"
						effect="solid"
						id={"disable-depositProduct"}
						className="account-button-tooltip"
					>
						<p> There are active accounts on this deposit product, close all accounts first.</p>
					</ReactTooltip>

					<ReactTooltip
						arrowColor="#FFF"
						place="bottom"
						type="light"
						effect="solid"
						id={"cannot-edit-when-disabled"}
						className="account-button-tooltip"
					>
						<p>{` Editing isn't possible when Deposit Product is disabled`}</p>
					</ReactTooltip>
				</BodyPortal>
			</MainSection>
		</>
	)
}

function DepositProductThatCanEdit() {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const {depositProductId = ""} = useParams<{depositProductId: string}>()
	const depositProduct = useAsyncResult(() => getDepositProduct(accessToken, depositProductId), [refreshToken])
	const orgs = useAsyncResult(() => findOrgs(accessToken, 0, 10000))
	const banks = useAsyncResult(() => findBanks(accessToken, 0, 1000))
	const bankRoutings = useAsyncResult(() => findBankRoutings(accessToken))
	const transactionMonitoringSettings = useAsyncResult(() => findTransactionMonitoringSettings(accessToken, 0, 100))
	const bins = useAsyncResult(() => findBins(accessToken, 0, 2000))
	const interestSettings = useAsyncResult(() => findInterestSettings(accessToken, 0, 1000))
	const associatedAccounts = useAsyncResult(() => findActiveAssociatedAccounts(accessToken, parseInt(depositProductId)))
	return AsyncResult.zip7(
		depositProduct,
		orgs,
		AsyncResult.zip(banks, bankRoutings),
		transactionMonitoringSettings,
		bins,
		associatedAccounts,
		interestSettings
	).match(
		() => <Loading />,
		([
			depositProduct,
			orgs,
			[banks, bankRoutings],
			transactionMonitoringSettings,
			bins,
			associatedAccounts,
			interestSettings,
		]) => (
			<Page
				depositProduct={depositProduct}
				banks={banks}
				bankRoutings={bankRoutings}
				orgs={orgs}
				refresh={refresh}
				transactionMonitoringSettings={transactionMonitoringSettings}
				bins={bins.data}
				hasAssociatedAccounts={associatedAccounts.data.length > 0}
				interestSettings={interestSettings.data}
			/>
		),
		(_) => null
	)
}

function DepositProductViewOnly() {
	const accessToken = useAccessToken()
	const {depositProductId = ""} = useParams<{depositProductId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const depositProduct = useAsyncResult(() => getDepositProduct(accessToken, depositProductId), [refreshToken])

	return depositProduct.match(
		() => <Loading />,
		(dp) => (
			<Page
				depositProduct={dp}
				banks={[]}
				bankRoutings={[]}
				orgs={[]}
				refresh={refresh}
				transactionMonitoringSettings={[]}
				bins={[]}
				hasAssociatedAccounts={false}
				// TODO - is it correct ???
				interestSettings={[]}
			/>
		),
		(_) => null
	)
}

export function isDisabled(depositProduct: DepositProduct) {
	return depositProduct.attributes.status === "Disabled"
}

export default function DepositProductPage() {
	if (useIsUnitUser()) {
		return <DepositProductThatCanEdit />
	}

	return <DepositProductViewOnly />
}
