import {useAccessToken} from "../../services/auth"
import {useParams} from "react-router-dom"
import React from "react"
import {
	CheckActionButtons,
	CheckDepositInfo,
	CheckImageCard,
	disabledActionsEnsentaTooltipValue,
	getButtonsToShow,
} from "../../components/CheckDeposits/CheckDepositInfo"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {
	CheckDepositVendors,
	CheckImageSide,
	getCheckDepositImage,
	getCheckDepositRequest,
} from "../../resources/checkDeposits"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {kebabCase, startCase} from "lodash"
import classNames from "classnames"
import {hasPermission} from "../../services/permission"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"

export default function CheckDepositPage() {
	const accessToken = useAccessToken()
	const {checkId = ""} = useParams<{checkId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const getCheckDeposit = useAsyncResult(() => getCheckDepositRequest({accessToken, id: checkId}), [refreshToken])

	const frontImageState = useAsyncResult(() =>
		getCheckDepositImage({
			accessToken,
			id: checkId,
			side: CheckImageSide.Front,
		})
	)
	const backImageState = useAsyncResult(() =>
		getCheckDepositImage({
			accessToken,
			id: checkId,
			side: CheckImageSide.Back,
		})
	)
	const frontImage = frontImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)
	const backImage = backImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)

	return (
		<>
			{getCheckDeposit.match(
				() => null,
				(cd) => (
					<>
						<TitleBar
							title={`#${checkId}`}
							breadcrumbs={[{text: "Check Deposits", path: "/check-deposits/"}]}
							status={{
								text: startCase(cd.data.attributes.status),
								className: classNames("check-deposit-status", kebabCase(cd.data.attributes.status)),
							}}
							meta={[
								{
									text: "Account",
									icon: "bank-account--business-products",
									path: `/accounts/${cd.data.relationships.account?.data.id}`,
								},
								{
									text: "Customer",
									icon: "user-geometric-action-money-fund---users",
									path: `/customer/${cd.data.relationships.customer?.data.id}`,
								},
								...(cd.data.relationships.account?.data.id && cd.data.relationships.transaction?.data.id
									? [
											{
												text: "Transaction",
												icon: "money-transfer--business-products",
												path: `/transaction/${cd.data.relationships.account?.data.id}/${cd.data.relationships.transaction?.data.id}`,
											} as MetaItemProps,
									  ]
									: []),
							]}
						>
							{hasPermission("checkDeposit.approve", "update") && (
								<CheckActionButtons
									id={checkId}
									refresh={refresh}
									buttons={getButtonsToShow(cd.data.attributes.status)}
									title={
										cd.data.attributes.vendor === CheckDepositVendors.Ensenta ? disabledActionsEnsentaTooltipValue : ""
									}
									disabled={cd.data.attributes.vendor === CheckDepositVendors.Ensenta}
								/>
							)}
						</TitleBar>
						<MainSectionLayout
							outerWrapperClassname={"check-deposit-full-with-details"}
							fullWidth={true}
							renderAfter={
								<div className="check-image-card-section">
									<CheckImageCard
										frontImage={frontImage}
										backImage={backImage}
										id={checkId}
										status={cd.data.attributes.status}
									/>
								</div>
							}
						>
							<CheckDepositInfo
								id={checkId}
								token={accessToken}
								refresh={refresh}
								useDrawer={false}
								checkDepositAsyncResult={getCheckDeposit}
							/>
						</MainSectionLayout>
					</>
				),
				(_) => null
			)}
		</>
	)
}
