import React from "react"
import HorizontalField from "../HorizontalField/HorizontalField"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useModal} from "react-modal-hook"
import {RemoveAliasCustomerModal} from "../../pages/Customer/RemoveAliasCustomerModal"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import AddAliasCustomerModal from "../../pages/Customer/AddAliasCustomerModal"

interface Props {
	customerId: string
	name: string
	refresh: () => void
	fieldClassName: string
}

function CustomerKnownName({customerId, name, refresh, fieldClassName}: Props) {
	const [showRemoveAlias, hideRemoveAlias] = useModal(() => (
		<RemoveAliasCustomerModal close={hideRemoveAlias} customerId={customerId} alias={name} onSuccess={refresh} />
	))

	return (
		<>
			<HorizontalField
				label={name}
				fieldClassName={classNames("vertical-field-score", fieldClassName)}
				showLabel={false}
			>
				<span className="input is-static">{name}</span>
				<button className="button-remove" onClick={() => showRemoveAlias()}>
					<span className="icon">
						<FontAwesomeIcon icon={faTimes} />
					</span>
				</button>
			</HorizontalField>
		</>
	)
}

export default function CustomerKnownNames({
	customerId,
	names,
	refresh,
	fieldClassName,
}: {
	customerId: string
	names: string[]
	refresh: () => void
	fieldClassName: string
}) {
	const [showAddAliasCustomerModal, hideAddAliasCustomerModal] = useModal(() => (
		<AddAliasCustomerModal customerId={customerId} close={hideAddAliasCustomerModal} refresh={refresh} />
	))

	return (
		<div className="customer-known-names-container">
			{names.map((n) => (
				<CustomerKnownName key={n} customerId={customerId} name={n} refresh={refresh} fieldClassName={fieldClassName} />
			))}

			<UnitUserOnly>
				<div className="button-wrapper">
					<button className="add-button button" onClick={() => showAddAliasCustomerModal()}>
						<Icon className="plus-icon" icon="interface-add-1" size={12} /> {"Add Known Alias"}
					</button>
				</div>
			</UnitUserOnly>
		</div>
	)
}
