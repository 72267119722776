import React, {ReactElement, useEffect} from "react"
import {Claims, useAccessToken, useUserType} from "../../services/auth"
import {upperFirst} from "lodash"
import classNames from "classnames"
import numeral from "numeral"
import {
	Cents,
	Direction,
	getTransactionsRequest,
	prettyPrintType,
	Transaction,
	TransactionTypes,
} from "../../resources/transaction"
import moment from "moment"
import {usePaging} from "../../hooks/usePaging"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import SearchBox from "../SearchBox/SearchBox"
import {useQueryState} from "use-location-state"
import {useNavigate} from "react-router-dom"
import {isOrg} from "../../services/orgAuth"
import {ErrorDocument, Resource} from "../../resources/common"
import {OrgName} from "../OrgName/OrgName"
import {downloadFile} from "../../utilities/file"
import {useToasts} from "react-toast-notifications"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {Filter} from "../Filter/Filter"
import {startCase} from "lodash"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResult} from "../../types/asyncResult"
import {CurrencyRangeFilter} from "../Filter/CurrencyRangeFilter"

export enum TransactionsColumns {
	id = "Id",
	org = "Org",
	accountId = "Account",
	customer = "Customer",
	type = "Type",
	amount = "Amount",
	balance = "Balance",
	summary = "Summary",
	createdAt = "Created At",
}

type AllowedTransactionsColumns =
	| TransactionsColumns.id
	| TransactionsColumns.org
	| TransactionsColumns.accountId
	| TransactionsColumns.customer
	| TransactionsColumns.type
	| TransactionsColumns.amount
	| TransactionsColumns.balance
	| TransactionsColumns.summary
	| TransactionsColumns.createdAt

interface TransactionsProps {
	accountId?: string
	customerId?: string
	limit?: number
	isUsingPaging?: boolean
	enableSearch?: boolean
	enableDirectionFilter?: boolean
	enableOrgFilter?: boolean
	token?: string
	includedColumns: Array<AllowedTransactionsColumns>
	excludeGlTransaction?: boolean
	enableTitle?: boolean
	fullHeight?: boolean
	disableShadow?: boolean
	refreshToken?: number
	dataTableClassName?: string
	enableTypeFilter?: boolean
	enableAmountFilter?: boolean
	enableDateFilter?: boolean
	enableExport?: boolean
	title?: string
	claims?: Claims
	titleClassName?: string
	className?: string
	hideMinHeight?: boolean
	disabled?: boolean
}

function generateSummary(summary: string) {
	const splitByPipe = summary.split("|")

	return (
		<>
			{splitByPipe.map((summaryPart, index) => {
				return (
					<span key={index}>
						<span>{summaryPart}</span>
						{index < splitByPipe.length - 1 ? <span className="has-text-grey-light">|</span> : null}
					</span>
				)
			})}
		</>
	)
}

function TransactionRow({
	tx,
	include,
	includedColumns,
}: {
	tx: Transaction
	include?: Resource[]
	showOrgColumn?: boolean
	includedColumns: Array<AllowedTransactionsColumns>
}) {
	const navigate = useNavigate()
	const isOrgTransaction = isOrg()
	const id = tx.id
	const org = tx.relationships.org?.data.id
	const accountId = tx.relationships.account.data.id
	const customerId = tx.relationships.customer?.data.id
	const amount = tx.attributes.amount
	const balance = tx.attributes.balance
	const summary = generateSummary(tx.attributes.summary)
	const createdAt = tx.attributes.createdAt
	const customer =
		customerId && include
			? include.find(
					(r) => (r.type == "individualCustomer" || r.type == "businessCustomer") && r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedTransactionsColumns, ReactElement>> = {}

	if (includedColumns.includes(TransactionsColumns.id)) {
		contentColumns["Id"] = <DataTableCell className="data-table-id"> {id} </DataTableCell>
	}

	if (includedColumns.includes(TransactionsColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} />
	}

	if (includedColumns.includes(TransactionsColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(TransactionsColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(TransactionsColumns.type)) {
		contentColumns["Type"] = <DataTableCell>{prettyPrintType(tx)}</DataTableCell>
	}

	if (includedColumns.includes(TransactionsColumns.summary)) {
		contentColumns["Summary"] = <DataTableCell style={{whiteSpace: "pre-wrap"}}>{summary}</DataTableCell>
	}

	if (includedColumns.includes(TransactionsColumns.balance)) {
		contentColumns["Balance"] = (
			<DataTableCell className={classNames(tx.attributes.balance >= 0 ? "" : "has-text-danger")}>
				{numeral(balance / 100).format("$0,0.00")}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(TransactionsColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell
				className={classNames(
					tx.attributes.direction === "Credit" && "has-text-success",
					tx.attributes.direction === "Debit" && "has-text-danger"
				)}
			>
				{numeral(amount / 100).format("$0,0.00")}{" "}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(TransactionsColumns.createdAt)) {
		contentColumns["Created At"] = (
			<DataTableCell style={{whiteSpace: "nowrap"}}>{moment(createdAt).format("L LT")}</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/${isOrgTransaction ? "org-" : ""}transaction/${tx.relationships.account.data.id}/${id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

interface TransactionsTableProps {
	transactions: Array<Transaction>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedTransactionsColumns>
	fullHeight: boolean
	sortFunction: () => void
	sortBy: string
	isFiltering: boolean
	hideMinHeight?: boolean
	disabled?: boolean
}

function TransactionsTable({
	transactions,
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	isUsingPaging,
	include,
	includedColumns,
	fullHeight,
	sortFunction,
	sortBy,
	isFiltering,
	hideMinHeight,
	disabled,
}: TransactionsTableProps) {
	const noContent = transactions.length === 0

	return (
		<div className={"transaction-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyTitle={fullHeight}
				stickyAction={fullHeight}
				hideMinHeight={hideMinHeight}
				noContentText={
					!isFiltering ? (
						<>
							No{" "}
							<a className="doc-link" href="https://docs.unit.co/transactions/" target="_blank" rel="noreferrer">
								{" "}
								transactions
							</a>{" "}
							yet
						</>
					) : (
						"No transactions found"
					)
				}
				disabled={disabled}
			>
				<DataTableHead className={classNames(!fullHeight && "no-sticky")}>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === TransactionsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}

							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{transactions.map((tx) => (
						<TransactionRow
							tx={tx}
							key={`${tx.relationships.account.data.id}_${tx.id}`}
							include={include}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
			/>
		</div>
	)
}

export function Transactions({
	limit = 10,
	isUsingPaging = true,
	enableSearch = true,
	enableDirectionFilter = false,
	enableOrgFilter = false,
	token,
	accountId,
	customerId,
	includedColumns,
	excludeGlTransaction = false,
	enableTitle = true,
	fullHeight = true,
	disableShadow = false,
	enableTypeFilter = true,
	enableAmountFilter = true,
	enableDateFilter = true,
	enableExport = true,
	refreshToken,
	dataTableClassName,
	title = "Transactions",
	claims,
	titleClassName,
	className,
	hideMinHeight,
	disabled,
}: TransactionsProps) {
	const prefix = "tran"
	const userType = useUserType(claims)
	const accessToken = token ?? useAccessToken()
	const {addToast} = useToasts()
	const [query, setQuery] = useQueryState("query", "")
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Transactions", 500, onSearch)
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredByOrg, setFilteredByOrg] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [filteredTransactions, setFilteredTransactions] = useQueryState<string[]>(`${prefix}-filter[type]`, [])
	const [directions, setDirections] = useQueryState<Direction[]>(`${prefix}-filter[direction]`, [])
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		getTransactions,
		(x) => x.data.length,
		[
			query,
			since,
			until,
			sortBy,
			[...filteredTransactions, ...directions, ...filteredByOrg].join(","),
			refreshToken,
			fromAmount,
			toAmount,
		],
		`${prefix}-`,
		true
	)
	const directionsOptions = new Map([Direction.Debit, Direction.Credit].map((v) => [v, v]))
	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const isFiltering =
		filteredTransactions.length != 0 ||
		query != "" ||
		since != "" ||
		until != "" ||
		directions.length != 0 ||
		fromAmount != "" ||
		toAmount != ""

	const transactionTypesMap = new Map(
		Object.entries(TransactionTypes).map((t) => [upperFirst(t[1].replace("Transaction", "")), startCase(t[0])])
	)

	const typeFilter = (
		<Filter
			onFilterFunc={() => reset(limit)}
			options={transactionTypesMap}
			setStatuses={setFilteredTransactions}
			statuses={filteredTransactions}
			title="All types"
		/>
	)

	const directionsFilter = (
		<Filter
			statuses={directions}
			setStatuses={setDirections}
			onFilterFunc={() => reset(limit)}
			options={directionsOptions}
			title="Direction"
		/>
	)

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredByOrg}
				statuses={filteredByOrg}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const dateTime = (
		<DatePickerWithPresets
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
				reset(limit)
			}}
		/>
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={(value) => {
				setFromAmount(value)
				reset(limit)
			}}
			to={toAmount}
			setTo={(value) => {
				setToAmount(value)
				reset(limit)
			}}
		/>
	)

	function getTransactions(offset: number, limit: number) {
		return getTransactionsRequest({
			accessToken,
			offset,
			limit,
			searchQuery: enableSearch ? query : "",
			since,
			until,
			excludeGlTransaction: excludeGlTransaction,
			types: filteredTransactions,
			accountId,
			customerId,
			include: accountId && customerId ? "" : "customer,org",
			requestType: "json",
			direction: directions,
			fromAmount,
			toAmount,
			orgs: filteredByOrg,
			sort: sortBy,
		})
	}

	async function exportTransactions() {
		const response = await getTransactionsRequest({
			accessToken,
			offset: 0,
			searchQuery: enableSearch ? query : "",
			since,
			until,
			excludeGlTransaction: excludeGlTransaction,
			types: filteredTransactions,
			accountId,
			customerId,
			requestType: "csv",
			direction: directions,
			fromAmount,
			toAmount,
			orgs: filteredByOrg,
			include: "org",
			sort: sortBy,
		})

		response.match(
			(d) => downloadFile("Transactions.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)

		return true
	}

	useEffect(() => {
		let isActive = true
		if (isActive) {
			result.match(
				() => null,
				() => setIsSearchLoading(false),
				() => setIsSearchLoading(false)
			)
		}
		return () => {
			isActive = false
		}
	}, [result])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	return (
		<DataTableCard
			className={classNames(
				"transactions-card",
				disableShadow && "transactions-card-disable-shadow",
				dataTableClassName
			)}
			cardClassName={className}
		>
			<DataTableActionHeader
				searchBox={enableSearch ? searchBox : null}
				dateTimePicker={enableDateFilter ? dateTime : null}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableTypeFilter ? [typeFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
					...(enableDirectionFilter ? [directionsFilter] : []),
				]}
				exportFunc={enableExport ? exportTransactions : undefined}
				title={enableTitle ? title : null}
				enableSticky={fullHeight}
				titleClassName={classNames(titleClassName, disabled && "disabled")}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: transactions}) => {
					return (
						<TransactionsTable
							fullHeight={fullHeight}
							transactions={transactions.data}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={isUsingPaging}
							include={transactions.included}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
							isFiltering={isFiltering}
							hideMinHeight={hideMinHeight}
							disabled={disabled}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
