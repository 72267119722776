import React from "react"
import {IconProps} from "./types"

export function ImageIcon({disabled}: IconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={81} height={81} fill="none">
			<rect width={80} height={80} x={0.575} y={0.793} fill="#fff" rx={4.646} />
			<path
				fill="#F4F5F7"
				d="M22.057 66.632h36.947a4.592 4.592 0 0 0 4.592-4.592V31.436a3.516 3.516 0 0 0-1.013-2.468l-13.549-13.66a3.46 3.46 0 0 0-2.448-1.023H22.057c-.918 0-1.798.368-2.447 1.022a3.504 3.504 0 0 0-1.014 2.468v45.367c0 .926.364 1.813 1.014 2.468a3.447 3.447 0 0 0 2.447 1.022Z"
			/>
			<path
				stroke="#44D47F"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M28.645 33.945a5 5 0 1 0 9.999 0 5 5 0 0 0-10 0v0Zm29 22-8.987-13.003a2.285 2.285 0 0 0-.822-.726 2.381 2.381 0 0 0-2.164-.023 2.287 2.287 0 0 0-.84.707l-6.16 8.492-3.82-2.955a2.347 2.347 0 0 0-.85-.415 2.396 2.396 0 0 0-1.844.276c-.27.167-.502.386-.68.644l-4.834 7.003"
				opacity={disabled ? 0.5 : 1}
			/>
			<path
				fill="#E2E4E7"
				d="M47.065 28.06V14.286c.734 0 1.53.612 1.836.918L62.677 28.98c.734.735.918 2.143.918 2.755H50.738a3.673 3.673 0 0 1-3.673-3.673Z"
			/>
		</svg>
	)
}
