import {useIsOrgUnitPilot} from "../services/auth"

const envName = process.env.ENV_NAME

const enum Environment {
	"Local" = "Local",
	"Sandbox" = "Sandbox",
	"Production" = "LIVE",
}

type EnvironmentName = "pilot" | "sandbox" | "live"

export function isLocalEnv() {
	return envName === Environment.Local
}

export function isSandboxEnv() {
	return envName === Environment.Sandbox
}

export function isProdEnv() {
	return envName === Environment.Production
}

export function isSandboxOrLocalEnv() {
	return isSandboxEnv() || isLocalEnv()
}

export function getCurrentEnvironmentName(): EnvironmentName {
	if (useIsOrgUnitPilot()) {
		return "pilot"
	}
	if (isProdEnv()) {
		return "live"
	}
	return "sandbox"
}

export const hotjarSetup = {id: 2933398, version: 6}
