import React from "react"
import Icon from "../Icon/Icon"

interface AuditLogActionButtonProps {
	onClick: () => void
}

export function AuditLogActionButton({onClick}: AuditLogActionButtonProps) {
	return (
		<button className="button page-title-button" onClick={onClick}>
			<Icon icon={"clock-cycle-twenty-four-hour--business-products"} size={12} />
			<span>Audit Log</span>
		</button>
	)
}
export default AuditLogActionButton
