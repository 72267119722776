import HorizontalField from "../HorizontalField/HorizontalField"
import React from "react"

export function CountriesOfOperation(props: {countriesOfOperation?: string[]; isArchived: boolean}) {
	if (props.countriesOfOperation?.length !== 0 && props.countriesOfOperation) {
		return (
			<HorizontalField label={"Countries of Operation"} fieldClassName={props.isArchived ? "archived-field" : ""}>
				{props.countriesOfOperation.map((country) => (
					<span key={country} className="tag is-light country-tag">
						{country}
					</span>
				))}
			</HorizontalField>
		)
	} else {
		return (
			<HorizontalField label="Countries of Operation" fieldClassName={props.isArchived ? "archived-field" : ""}>
				<input type="text" readOnly className="input is-static" placeholder="Empty" />
			</HorizontalField>
		)
	}
}
