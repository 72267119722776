import React, {Dispatch, SetStateAction} from "react"
import classNames from "classnames"
import {ErrorMessage, useFormikContext} from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import {flattenObject} from "../../utilities/helpers"
import {isDateInputSupported} from "../../utilities/dom"

interface Props {
	name: string
	label: string
	value: string
	onChange: any
	onBlur: Dispatch<SetStateAction<any>>
}

export default function DateField({name, label, value, onChange, onBlur}: Props) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"textfield",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)

	return (
		<div className={className}>
			<label>
				<span className="label-text">{label}</span>
				{isDateInputSupported() ? (
					<input name={name} type="date" value={value} onBlur={onBlur} onChange={onChange} />
				) : (
					<DatePicker
						name={name}
						selected={value === "" ? null : moment(value, "YYYY-MM-DD").toDate()}
						onChange={(date: Date) => {
							if (date) {
								onChange(name, moment(date).format("YYYY-MM-DD"))
							}
						}}
						popperModifiers={{
							preventOverflow: {
								enabled: true,
							},
						}}
						onBlur={onBlur}
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
					/>
				)}
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
