import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {OrgSettings, update} from "../../resources/orgSettings"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"

export default function DashboardSettings({orgSettings, refresh}: {orgSettings: OrgSettings; refresh: () => void}) {
	const accessToken = useAccessToken()
	const [state, patch] = useAsyncResultIdle(update)
	const [sourceIp, setSourceIp] = useState(
		orgSettings.attributes.dashboardSettings ? orgSettings.attributes.dashboardSettings.whitelistSourceIps : ""
	)
	const {addToast} = useToasts()

	useEffect(() => {
		if (state.isOk()) {
			addToast("Dashboard Settings Updated Successfully", {appearance: "success"})
			refresh()
		}
	}, [state])

	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<h3> Dashboard Settings </h3>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						patch(accessToken, {
							dashboardSettings: {
								whitelistSourceIps: sourceIp,
							},
						})
					}}
				>
					<fieldset>
						<div className="field">
							<label className="label">Whitelist Source IPs</label>
							<div className="control">
								<div className="control">
									<input
										className="input is-medium"
										type="text"
										pattern="^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?))*$"
										value={sourceIp}
										placeholder="List source IPs separated by comma"
										onChange={(e) => setSourceIp(e.target.value)}
									/>
								</div>
							</div>
						</div>
					</fieldset>
					<div className="columns">
						<div className="column mt-4">
							{state.match(
								() => (
									<button className="button is-success">Update</button>
								),
								() => (
									<button className="button is-success is-loading">Updating</button>
								),
								(_) => null,
								(err) => (
									<>
										<div className="has-text-danger">{err.errors[0].title}</div>
										<button className="button is-success">Update</button>
									</>
								)
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
