import React from "react"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"

interface Props {
	type: "is-warning" | "is-danger" | "is-info" | "is-security-info"
	message: string | React.ReactNode
	title?: string
	icon?: IcomoonIconName
}

export default function Admonition({type, message, title = "", icon = "alert-circle--interface-essential"}: Props) {
	return (
		<article className={classNames("admonition", type)}>
			<div className="message-body admonition-message-body">
				<div className="admonition-message-body-title">
					<div className="admonition-message-body-title-icon">
						<Icon icon={icon} size={16} />
					</div>
					<h3> {title} </h3>
				</div>
				<div className="admonition-message-body-container">{message}</div>
			</div>
		</article>
	)
}
