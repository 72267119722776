import React, {HTMLProps, PropsWithChildren} from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import {flattenObject} from "../../utilities/helpers"

interface Props extends HTMLProps<HTMLSelectElement> {
	name: string
	label: string
	subLabel?: string
	children: React.ReactNode
}

export default function Dropdown({name, label, subLabel, children, ...inputProps}: PropsWithChildren<Props>) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"dropdown",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)
	return (
		<div className={className}>
			<label>
				<span className={classNames("label-text", inputProps.disabled && "disabled")}>{label}</span>
				{subLabel && <span className="sub-label-text">{`(${subLabel})`}</span>}
				<select name={name} value={inputProps.value} onChange={inputProps.onChange} {...inputProps}>
					{children}
				</select>
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
