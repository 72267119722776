import {useAccessToken, useIsBankUser, useIsUnitUser} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getOrg, Org, updateOrg} from "../../resources/org"
import Loading from "../../components/Loading/Loading"
import React, {FC, useEffect, useState} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {EditMode, ErrorDocument, RiskRate} from "../../resources/common"
import {useToasts} from "react-toast-notifications"
import {Customer, findCustomers} from "../../resources/customer"
import {useParams} from "react-router"
import {AsyncResult} from "../../types/asyncResult"
import {OrgAttributes} from "./OrgAttributes"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEdit, faWindowClose} from "@fortawesome/free-solid-svg-icons"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import Icon from "../../components/Icon/Icon"
import {useModal} from "react-modal-hook"
import {GoLiveModal} from "./GoLiveModal"
import OrgAccountsSummary from "../../components/OrgAccountsSummary/OrgAccountsSummary"
import OrgAndCustomerAccountsSummary from "../../components/OrgAccountsSummary/OrgAndCustomerAccountsSummary"
import InterestAndDepositFeeSummary from "../../components/InterestAndDepositFeeSummary/InterestAndDepositFeeSummary"
import EndCustomerDepositSummary from "../../components/EndCustomerDepositSummary/EndCustomerDepositSummary"

function Footer({state}: {state: AsyncResultIdleRequestState<Org, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Update</button>
				),
				() => (
					<button className="button is-success is-loading">Updating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].title}</div>
						<button className="button is-success">Create</button>
					</>
				)
			)}
		</div>
	)
}

interface PageProps {
	customers: Array<Customer>
	org: Org
	refresh: () => void
}

const Page: FC<PageProps> = ({customers, org, refresh}) => {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()

	const [state, update] = useAsyncResultIdle(updateOrg)
	const [customerId, setCustomerId] = useState<string | null>(
		org.attributes.customerId ? org.attributes.customerId.toString() : ""
	)
	const [verificationServiceId, setVerificationServiceId] = useState<string>(
		org.attributes.verificationServiceId ? org.attributes.verificationServiceId : ""
	)
	const [orgInterchangeShare, setOrgInterchangeShare] = useState<number>(org.attributes.orgInterchangeShare)
	const [defaultCustomerRiskRate, setDefaultCustomerRiskRate] = useState<RiskRate>(
		org.attributes.defaultCustomerRiskRate
	)

	const [enableTwoFactorAuthentication, setEnableTwoFactorAuthentication] = useState<boolean>(
		org.attributes.enableTwoFactorAuthentication
	)
	const [isEditing, setIsEditing] = useState(false)

	const [showGoLive, hideGoLive] = useModal(() => (
		<GoLiveModal org={org} customers={customers} close={hideGoLive} onSuccess={refresh} />
	))

	const [name, setName] = useState(org.attributes.name)

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Updated Successfully", {appearance: "success"})
			setIsEditing(false)
			refresh()
		}
	}, [state])

	const submit = () => {
		update(
			accessToken,
			org.id,
			customerId != "" ? customerId : null,
			defaultCustomerRiskRate,
			verificationServiceId != "" ? verificationServiceId : undefined,
			name,
			enableTwoFactorAuthentication
		)
	}

	const disabled = state.match(
		() => false,
		() => true,
		(_) => true,
		(_) => false
	)
	const isBankUser = useIsBankUser()
	const isUnitUser = useIsUnitUser()
	const orgTitle = org ? org.attributes.name : "New Org"

	return (
		<>
			<TitleBar
				avatar={{name: orgTitle}}
				title={orgTitle}
				meta={[]}
				status={
					org.attributes.isUnitPilot
						? {
								text: "Pilot",
								color: "#B0B2B5",
								backgroundColor: "#EBECEE",
						  }
						: undefined
				}
			>
				{org.attributes.isUnitPilot && (
					<button className="button button-create move-to-live" onClick={() => showGoLive()}>
						<span> Move to Unit Live! </span>
						<Icon icon={"astronomy-rocket-1--school-science"} size={14} />
					</button>
				)}
			</TitleBar>
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
							{!isBankUser && (
								<button className="button is-light" onClick={() => (!isEditing ? setIsEditing(true) : refresh())}>
									<span>{isEditing ? "Cancel" : "Edit"}</span>
									<span className="icon">
										<FontAwesomeIcon icon={isEditing ? faWindowClose : faEdit} />
									</span>
								</button>
							)}
						</div>
					</div>
				</div>
				{!isBankUser && (
					<div className="columns">
						<div className="column">
							<form
								onSubmit={(e) => {
									e.preventDefault()
									submit()
								}}
							>
								<fieldset disabled={disabled}>
									<OrgAttributes
										editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
										name={name}
										setName={setName}
										customerId={customerId}
										setCustomerId={setCustomerId}
										customers={customers}
										setOrgInterchangeShare={setOrgInterchangeShare}
										orgInterchangeShare={orgInterchangeShare}
										defaultCustomerRiskRate={defaultCustomerRiskRate}
										setDefaultCustomerRiskRate={setDefaultCustomerRiskRate}
										twilioVerificationServiceId={verificationServiceId}
										setTwilioVerificationServiceId={setVerificationServiceId}
										enableTwoFactorAuthentication={enableTwoFactorAuthentication}
										setEnableTwoFactorAuthentication={setEnableTwoFactorAuthentication}
									/>

									{isEditing ? (
										<div className="columns">
											<div className="column">
												<div className="card">
													<Footer state={state} />
												</div>
											</div>
										</div>
									) : null}
								</fieldset>
							</form>
						</div>
					</div>
				)}
				<div className="columns">
					<div className="column">
						{org.attributes.customerId ? (
							<OrgAndCustomerAccountsSummary
								orgId={org.id}
								customerId={org.attributes.customerId.toString()}
								customerAccountsAccessToken={accessToken}
								showIcon
							/>
						) : (
							<OrgAccountsSummary orgId={org.id} accessToken={accessToken} showIcon />
						)}
					</div>
				</div>
				{isBankUser && (
					<div className="columns">
						<div className="column is-flex is-flex-direction-column">
							<EndCustomerDepositSummary orgId={org.id} accessToken={accessToken} />
						</div>
						<div className="column">
							<InterestAndDepositFeeSummary orgId={org.id} accessToken={accessToken} />
						</div>
					</div>
				)}
				{isUnitUser && (
					<div className="columns">
						<div className="column is-flex is-flex-direction-column">
							<EndCustomerDepositSummary orgId={org.id} accessToken={accessToken} />
						</div>
					</div>
				)}
			</MainSection>
		</>
	)
}

export default function OrgPage() {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const {orgId = ""} = useParams<{orgId: string}>()

	const org = useAsyncResult(() => getOrg(accessToken, orgId), [refreshToken])
	const customers = useAsyncResult(() => findCustomers(accessToken, 0, 1000, "-createdAt", "", ["1"]))

	return AsyncResult.zip(org, customers).match(
		() => <Loading />,
		([org, customers]) => <Page org={org} customers={(customers as {data: Customer[]}).data} refresh={refresh} />,
		(_) => null
	)
}
