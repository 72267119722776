import React, {PropsWithChildren, ReactElement, useContext, useState} from "react"
import classNames from "classnames"
import {isNull, range} from "lodash"
import Icon from "../Icon/Icon"
import noContent from "../../images/empty-state.svg"
import Skeleton from "react-loading-skeleton"

interface DataTableActionHeaderProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
	searchBox?: JSX.Element | null
	dateTimePicker?: JSX.Element | null
	filters?: Array<JSX.Element | null> | null
	exportFunc?: () => Promise<boolean>
	enableSticky?: boolean
	className?: string
	title?: string | null | JSX.Element
	innerRef?: React.Ref<any>
	titleClassName?: string
}

interface WithClassname<T> extends Omit<React.HTMLAttributes<T>, "title"> {
	className?: string
	innerRef?: React.Ref<any>
}

interface TableCellProps<T> extends WithClassname<T> {
	clickable?: boolean
	sortable?: boolean
	sortApplied?: boolean
}

interface DataTableProps<T> extends Omit<React.HTMLAttributes<T>, "title"> {
	isEmpty?: boolean
	noContentText?: React.ReactNode
	noContentCtaButton?: React.ReactNode
	fullHeight?: boolean
	hoverable?: boolean
	clickable?: boolean
	stickyTitle?: boolean
	stickyAction?: boolean
	stickyActionWithTabsOnHeader?: boolean
	hideMinHeight?: boolean
	disabled?: boolean
}

const DataTableContext = React.createContext<string | null>(null)

export function TablePending({numberOfRows = 12}: {numberOfRows?: number}) {
	return (
		<div className="table-container">
			<table className="table data-table-pending-table">
				<thead>
					<tr></tr>
				</thead>
				<tbody>
					{range(16).map((i) => (
						<tr key={i}>
							{range(numberOfRows).map((j) => (
								<td key={j}>
									<Skeleton />
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

function DataTableTitle({children, className}: {children: React.ReactNode; className?: string}): ReactElement {
	return <div className={classNames("data-table-title", className)}>{children}</div>
}

export function DataTableActionHeader({
	searchBox,
	dateTimePicker,
	filters = null,
	className = "",
	exportFunc,
	enableSticky = false,
	title,
	innerRef,
	titleClassName,
	...props
}: DataTableActionHeaderProps): ReactElement {
	const noActions = !searchBox && !dateTimePicker && (!filters || filters?.length == 0) && !exportFunc

	const [isDownloadingFile, setIsDownloadingFile] = useState(false)
	return (
		<div
			ref={innerRef}
			{...props}
			className={classNames(
				"data-table-container-header",
				enableSticky && "data-table-container-header-sticky",
				className
			)}
		>
			{title ? (
				<DataTableTitle className={titleClassName}>
					<>
						<span style={noActions ? {marginBottom: "20px"} : {}}> {title} </span>
					</>
				</DataTableTitle>
			) : null}
			{!noActions ? (
				<div className={classNames("data-table-action-header")}>
					<div className="columns">
						<div className="column is-4 is-5-desktop is-6-fullhd">
							<div className="data-table-action-header-search">
								<div className="columns">
									<div className="column is-7-desktop is-8-fullhd">{searchBox}</div>
								</div>
							</div>
						</div>
						<div
							className={classNames(
								isNull(searchBox) ? "column is-8 is-7-desktop is-6-fullhd" : "column is-8 is-7-desktop is-6-fullhd"
							)}
						>
							<div
								className={classNames(
									"data-table-action-header-buttons",
									isNull(searchBox) && "data-table-action-header-buttons-without-search"
								)}
							>
								{filters
									? filters.map((filter, i) => {
											if (filter) {
												return (
													<div key={i} className="data-table-action-header-filter">
														{filter}
													</div>
												)
											}
									  })
									: null}
								{dateTimePicker ? <div className="data-table-action-header-day-picker">{dateTimePicker}</div> : null}

								{exportFunc ? (
									<div className="data-table-action-header-export">
										<a
											className={classNames(
												"button data-table-action-header-export-button",
												isDownloadingFile && "is-loading",
												"data-table-action-header-export-link"
											)}
											href="#"
											onClick={(e) => {
												e.preventDefault()
												setIsDownloadingFile(true)

												exportFunc().then(() => {
													setIsDownloadingFile(false)
												})
											}}
										>
											{" "}
											<Icon icon="move-snap-top--interface-essential" size={16} />
											<span> Export </span>
										</a>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	)
}

export function DataTableHead({
	children,
	innerRef,
	className,
	...props
}: PropsWithChildren<WithClassname<HTMLTableSectionElement>>): ReactElement {
	return (
		<DataTableContext.Provider value={"Head"}>
			<thead className={className} ref={innerRef} {...props}>
				{children}
			</thead>
		</DataTableContext.Provider>
	)
}

export function DataTableBody({
	children,
	innerRef,
	...props
}: PropsWithChildren<WithClassname<HTMLTableSectionElement>>): ReactElement {
	return (
		<tbody ref={innerRef} {...props}>
			{children}
		</tbody>
	)
}

export function DataTableRow({
	children,
	innerRef,
	...props
}: PropsWithChildren<WithClassname<HTMLTableRowElement>>): ReactElement {
	return (
		<tr ref={innerRef} {...props}>
			{children}
		</tr>
	)
}

export function DataTableCell({
	children,
	innerRef,
	sortable,
	sortApplied = false,
	clickable = false,
	...props
}: PropsWithChildren<TableCellProps<HTMLTableDataCellElement>>): ReactElement {
	const context = useContext(DataTableContext)

	switch (context) {
		case "Head":
			return (
				<th
					className={classNames(props.className, clickable && "clickable", sortable && "is-sortable")}
					ref={innerRef}
					{...props}
				>
					{children}

					{sortable ? (
						<span className="icon is-small">
							<span className="icon">
								<Icon size={15} icon={sortApplied ? "icon-keyboard-up-arrow" : "icon-keyboard-down-arrow"} />
							</span>
						</span>
					) : null}
				</th>
			)
		default:
			return (
				<td ref={innerRef} {...props}>
					{children}
				</td>
			)
	}
}

export function DataTable<T>({
	isEmpty,
	fullHeight = true,
	className,
	hoverable = true,
	clickable = true,
	stickyAction,
	stickyTitle,
	stickyActionWithTabsOnHeader,
	children,
	noContentText,
	noContentCtaButton,
	innerRef,
	hideMinHeight,
	disabled,
	...props
}: PropsWithChildren<WithClassname<T> & DataTableProps<HTMLDivElement>>): ReactElement {
	const stickyTitleAndAction = stickyAction && stickyTitle

	const containerClassNames = classNames(
		"data-table-container",
		!fullHeight ? (!hideMinHeight ? "data-table-container-minimal" : null) : "data-table-container-full-height",
		isEmpty && "data-table-container-no-content",
		stickyAction && "data-table-container-with-sticky-action",
		stickyTitle && "data-table-container-with-sticky-title",
		stickyTitleAndAction && "data-table-container-with-sticky-header",
		stickyActionWithTabsOnHeader && "data-table-container-with-sticky-action-with-tabs-on-header",
		className
	)

	return (
		<div ref={innerRef} {...props} className={containerClassNames}>
			{isEmpty ? (
				<div className="no-content-placeholder">
					<img src={noContent} />
					<div className="no-content-placeholder-text"> {noContentText} </div>
					{noContentCtaButton}
				</div>
			) : (
				<table
					className={classNames(
						"table",
						"is-fullwidth",
						hoverable && "is-hoverable",
						clickable && "is-clickable",
						disabled && "disabled"
					)}
				>
					{children}
				</table>
			)}
		</div>
	)
}

interface DataTableCardProps extends PropsWithChildren<WithClassname<HTMLDivElement>> {
	cardClassName?: string
}

export function DataTableCard({className, children, cardClassName, ...rest}: DataTableCardProps): ReactElement {
	return (
		<div {...rest} className={classNames("data-table-card", className)}>
			<div className="columns">
				<div className="column">
					<div className={classNames("card", cardClassName)}>{children}</div>
				</div>
			</div>
		</div>
	)
}
