import {useAccessToken} from "../../services/auth"
import React, {useCallback, useEffect, useState} from "react"
import {OrgSettings, update, JWTProviders} from "../../resources/orgSettings"
import Auth0Fields from "../../components/JwtProviders/Auth0Fields"
import StytchFileds from "../../components/JwtProviders/StytchFileds"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import CognitoFields from "../../components/JwtProviders/CognitoFields"
import OktaFields from "../../components/JwtProviders/OktaFields"
import Icon from "../../components/Icon/Icon"

export default function JwtSettings({orgSettings, refresh}: {orgSettings: OrgSettings; refresh: () => void}) {
	const accessToken = useAccessToken()
	const [state, patch] = useAsyncResultIdle(update)
	const [provider, setProvider] = useState<JWTProviders>(
		orgSettings?.attributes?.jwtSettings?.provider ?? JWTProviders.Auth0
	)
	const [jwksPath, setJwksPath] = useState<string>(orgSettings?.attributes?.jwtSettings?.jwksPath ?? "")
	const [issuer, setIssuer] = useState<string | undefined>(orgSettings?.attributes?.jwtSettings?.issuer ?? undefined)
	const {addToast} = useToasts()

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Settings Updated Successfully", {appearance: "success"})
			refresh()
		}
	}, [state])

	const updateField = useCallback((fieldName: string, value: string) => {
		switch (fieldName) {
			case "jwksPath":
				setJwksPath(value)
				break
			case "issuer":
				setIssuer(value)
				break
		}
	}, [])

	const onProviderChange = useCallback((selectedProvider: JWTProviders) => {
		let jwksPathInitializer: string | undefined = ""
		let issuerInitializer: string | undefined = undefined
		if (orgSettings?.attributes?.jwtSettings?.provider == selectedProvider) {
			jwksPathInitializer = orgSettings?.attributes?.jwtSettings?.jwksPath
			issuerInitializer = orgSettings?.attributes?.jwtSettings?.issuer
		}
		setProvider(selectedProvider)
		setJwksPath(jwksPathInitializer as string)
		setIssuer(issuerInitializer)
	}, [])

	const getProviderFields = useCallback(() => {
		switch (provider) {
			case JWTProviders.Auth0:
				return <Auth0Fields jwksPath={jwksPath} customDomain={issuer} updateSettings={updateField} />
			case JWTProviders.Stytch:
				return <StytchFileds jwksPath={jwksPath} issuer={issuer} updateSettings={updateField} />
			case JWTProviders.Cognito:
				return <CognitoFields jwksPath={jwksPath} updateSettings={updateField} />
			case JWTProviders.Okta:
				return <OktaFields jwksPath={jwksPath} issuer={issuer} updateSettings={updateField} />
			default:
				return null
		}
	}, [provider, jwksPath, issuer])

	return (
		<div className={"card"}>
			<div className={"org-settings-content"}>
				<h3> JWT Settings </h3>
				<div className="jwt-description">
					If you are using one of Unit&apos;s supported authentication providers, you can rely on the authentication you
					already implemented to obtain a Unit customer token. In order to enable Custom Authentication choose your
					authentication provider from the list below and follow the{" "}
					<a href="https://guides.unit.co/custom-authentication/" target="_blank" rel="noreferrer">
						Custom Authentication guide
					</a>{" "}
					<Icon icon="maximize-square-4--interface-essential" size={12} />
				</div>
				<label className="label">Provider</label>
				<div className="select">
					<select value={provider} onChange={(e) => onProviderChange(e.target.value as JWTProviders)}>
						<option value={JWTProviders.Auth0}>Auth0</option>
						<option value={JWTProviders.Stytch}>Stytch</option>
						<option value={JWTProviders.Cognito}>Amazon Cognito</option>
						<option value={JWTProviders.Okta}>Okta</option>
					</select>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						patch(accessToken, {
							jwtSettings: {
								provider,
								jwksPath,
								issuer,
							},
						})
					}}
				>
					{getProviderFields()}
					<div className="columns">
						<div className="column mt-4">
							{state.match(
								() => (
									<button className="button is-success">Update</button>
								),
								() => (
									<button className="button is-success is-loading">Updating</button>
								),
								(_) => null,
								(err) => (
									<>
										<div className="has-text-danger">{err.errors[0].title}</div>
										<button className="button is-success">Update</button>
									</>
								)
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
