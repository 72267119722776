import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {createWebhook, DeliveryMode, WebhookSubscriptionType} from "../../resources/webhook"
import {startCase} from "lodash"

type CloseFunc = () => void
type RefreshFunc = () => void

export default function NewWebhookModal({close, refresh}: {close: CloseFunc; refresh: RefreshFunc}) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createWebhook)
	const [label, setLabel] = useState("")
	const [url, setUrl] = useState("")
	const [webhookToken, setWebhookToken] = useState("")
	const [deliveryMode, setDeliveryMode] = useState<DeliveryMode>("AtMostOnce")
	const [contentType, setContentType] = useState<"Json" | "JsonAPI">("Json")
	const [subscriptionType, setSubscriptionType] = useState<keyof typeof WebhookSubscriptionType>(
		WebhookSubscriptionType.All
	)
	const [includeResources, setIncludeResources] = useState(false)

	return (
		<AsyncResultModal
			title="Create new webhook"
			onSubmit={() =>
				create(accessToken, label, url, webhookToken, contentType, deliveryMode, includeResources, subscriptionType)
			}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
			onSuccess={refresh}
		>
			<div className="field">
				<label className="label">Label</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="A label describing the webhook"
						value={label}
						required
						onChange={(e) => setLabel(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Url</label>
				<div className="control">
					<input
						className="input"
						type="url"
						placeholder="https://example.com"
						value={url}
						required
						onChange={(e) => setUrl(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Token</label>
				<div className="control">
					<input
						className="input"
						type="token"
						placeholder="Optional token to sign the webhook payload"
						value={webhookToken}
						onChange={(e) => setWebhookToken(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Attempt delivery</label>
				<div className="control">
					<div className="select">
						<select onChange={(e) => setDeliveryMode(e.target.value as DeliveryMode)} value={deliveryMode}>
							<option value="AtLeastOnce">At least once</option>
							<option value="AtMostOnce">At most once</option>
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Subscription Type</label>
				<div className="control">
					<div className="select">
						<select
							onChange={(e) => setSubscriptionType(e.target.value as keyof typeof WebhookSubscriptionType)}
							value={subscriptionType}
						>
							{Object.entries(WebhookSubscriptionType).map(([key, value]) => {
								return (
									<option key={key} value={key}>
										{startCase(value)}
									</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Request Content Type</label>
				<div className="control">
					<label className="radio">
						<input type="radio" name="answer" checked={contentType == "Json"} onClick={() => setContentType("Json")} />
						Json <span className="is-family-code has-background-grey-lighter">application/json</span>
					</label>
				</div>
				<div className="control">
					<label className="radio">
						<input
							type="radio"
							name="answer"
							checked={contentType == "JsonAPI"}
							onClick={() => setContentType("JsonAPI")}
						/>
						JsonAPI <span className="is-family-code has-background-grey-lighter">application/vnd.api+json</span>
					</label>
				</div>
			</div>

			<div className="field">
				<label className="label centered">
					<input type="checkbox" checked={includeResources} onChange={(e) => setIncludeResources(e.target.checked)} />{" "}
					Include Full resource in events
					<label className="tooltip-container">
						<div data-tip="React-tooltip" data-event="click focus">
							<FontAwesomeIcon icon={faInfoCircle} color="#545558" />
							<ReactTooltip
								place="right"
								effect="solid"
								className="tooltip-info"
								globalEventOff="click"
								border={true}
								borderColor="#C4C4C4"
								backgroundColor="#F5F5F5"
								textColor="black"
								data-html={true}
							>
								When this checkbox is checked, every webhook event that has a relationship with a resource will have the
								full resource included in the payload of the event (see docs{" "}
								<a rel="noreferrer" target="_blank" href="https://docs.unit.co/webhooks#include-resource">
									here
								</a>
								)
							</ReactTooltip>
						</div>
					</label>
				</label>
			</div>
		</AsyncResultModal>
	)
}
