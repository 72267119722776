import React from "react"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"

import {UserType, useUserType} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import moment from "moment"
import numeral from "numeral"
import Skeleton from "react-loading-skeleton"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardKey, KeyValueCardValue} from "../KeyValueCard/KeyValueCard"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {Account} from "../../resources/account"
import {AccountCard} from "../Accounts/AccountCard"
import {CustomerCard} from "../Customers/CustomerCard"
import {
	getIncomingCheckRequest,
	IncomingCheck,
	IncomingCheckStatus,
	PendingReviewReasons,
} from "../../resources/incomingCheck"
import {Customer} from "../../resources/customer"
import {Org} from "../../resources/org"
import {startCase} from "lodash"
import {BeneficialOwnerCards} from "../BeneficialOwners/BeneficalOwnerCard"
import Card from "../../containers/Card/Card"
import IconTag from "../Tag/IconTag"
import {Transactions, TransactionsColumns} from "../Transactions/Transactions"
import {NamesAtUnitCard} from "../NamesAtUnit/NamesAtUnit"
import IncomingChecks, {IncomingChecksColumns} from "./IncomingChecks"

const DATE_FORMAT = "MM/DD/YYYY"

interface SingleIncomingCheckProps {
	id: string
	accessToken: string
	incomingCheckAsyncResult?: AsyncResultRequestState<OkDocument<IncomingCheck>, ErrorDocument>
	customers?: Customer[]
	accounts?: Account[]
	org?: Org
}

type ActionButtons = Array<"Approve" | "Return"> | null

type DetailsCard = Array<"ReviewReasons" | "ReturnReasons"> | null

function IncomingCheckReasons({incomingCheckReasons}: {incomingCheckReasons: PendingReviewReasons}) {
	return (
		<Card title="Review Reasons" className="incoming-check-review-card">
			{incomingCheckReasons.map((reviewReason, i) => {
				return (
					<React.Fragment key={i}>
						{reviewReason.nameMissMatch && <IconReviewReason key={i} iconMessage={"NameMissMatch"} />}
						{reviewReason.monthlySoftLimit && <IconReviewReason iconMessage={"monthlySoftLimit"} key={i} />}
						{reviewReason.dailySoftLimit && <IconReviewReason iconMessage={"dailySoftLimit"} key={i} />}
					</React.Fragment>
				)
			})}
		</Card>
	)
}

function IconReviewReason({iconMessage}: {iconMessage: string}) {
	return (
		<div className="incoming-check-review-reason">
			<IconTag icon="award-flag-rewards-rating-filled" iconSize={15}>
				{iconMessage}
			</IconTag>
		</div>
	)
}

function IncomingCheckReason({incomingCheckReason}: {incomingCheckReason: string}) {
	return (
		<Card title="Review Reasons" className="incoming-check-review-card">
			<div className="incoming-check-review-reason">
				<IconTag icon="award-flag-rewards-rating-filled" iconSize={15}>
					{incomingCheckReason}
				</IconTag>
			</div>
		</Card>
	)
}

function CounterpartyDetails({payeeName, routingNumber}: {payeeName?: string; routingNumber?: string}) {
	return (
		<KeyValueCard title="Counterparty Details">
			<KeyValueCardContainer>
				{payeeName && (
					<>
						<KeyValueCardKey> Payee Name</KeyValueCardKey>
						<KeyValueCardValue> {payeeName} </KeyValueCardValue>
					</>
				)}
				{routingNumber && (
					<>
						<KeyValueCardKey> Routing Number</KeyValueCardKey>
						<KeyValueCardValue> {routingNumber} </KeyValueCardValue>
					</>
				)}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

function IncomingCheckInternal({
	incomingCheck,
	account,
	accessToken,
	customers,
	org,
}: {
	incomingCheck: IncomingCheck
	accessToken: string
	customers?: Customer[]
	org?: Org
	account?: Account
}) {
	const {attributes} = incomingCheck
	const status = attributes.status
	const customerId = incomingCheck.relationships.customer?.data.id
	const accountId = incomingCheck.relationships.account?.data.id

	const counterpartyDetails = {
		payeeName: incomingCheck.attributes.payeeName,
		counterpartyRoutingNumber: incomingCheck.attributes.counterpartyRoutingNumber,
	}

	return (
		<>
			<div className="check-content">
				<IncomingCheckDetailsCard incomingCheck={incomingCheck} cards={getDetailsToShow(status, useUserType())} />
				{customerId && <NamesAtUnitCard customerId={customerId} />}
				<IncomingCheckDetails incomingCheck={incomingCheck} />
				{customers?.map((customer) => {
					return (
						<React.Fragment key={customer.id}>
							<CustomerCard org={org} customer={customer} customerId={customer.id} />
							{customer.relationships.application && customer.type === "businessCustomer" && (
								<BeneficialOwnerCards
									applicationId={customer.relationships.application?.data.id}
									accessToken={accessToken}
								/>
							)}
						</React.Fragment>
					)
				})}
				{account && <AccountCard account={account} accessToken={accessToken} />}
				<CounterpartyDetails
					payeeName={counterpartyDetails.payeeName}
					routingNumber={counterpartyDetails.counterpartyRoutingNumber}
				/>
				<Transactions
					enableAmountFilter={false}
					enableDateFilter={false}
					enableTypeFilter={false}
					enableOrgFilter={false}
					enableDirectionFilter={false}
					isUsingPaging={false}
					fullHeight={false}
					accountId={accountId}
					limit={10}
					enableSearch={false}
					enableExport={false}
					title={"Recent Transactions"}
					token={accessToken}
					includedColumns={[
						TransactionsColumns.id,
						TransactionsColumns.type,
						TransactionsColumns.summary,
						TransactionsColumns.createdAt,
						TransactionsColumns.balance,
						TransactionsColumns.amount,
					]}
				/>
				<IncomingChecks
					title={"Recent Incoming Checks"}
					fullHeight={false}
					stickyAction={false}
					enablePaging={false}
					enableStatusFilter={false}
					enableOrgFilter={false}
					enableAmountFilter={false}
					enableBankFilter={false}
					enableDateFilter={false}
					enableSearch={false}
					enableExport={false}
					limit={10}
					includedColumns={[
						IncomingChecksColumns.id,
						IncomingChecksColumns.amount,
						IncomingChecksColumns.status,
						IncomingChecksColumns.createdAt,
					]}
				/>
			</div>
		</>
	)
}

function IncomingCheckInfoPending() {
	return (
		<div className="check-content">
			<div className="check-image-card">
				<a className="single-incoming-check-link-with-icon">
					<Skeleton />
				</a>

				<div className="check-image-card-container">
					<div className={"skeleton-placeholder"}>
						<Skeleton />
					</div>
				</div>

				<div className="check-image-card-preview-container">
					<Skeleton />
					<div className="check-image-card-preview-container-wrapper">
						<Skeleton />
					</div>
				</div>
			</div>

			<KeyValueCard>
				<KeyValueCardContainer>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
				</KeyValueCardContainer>
			</KeyValueCard>

			<KeyValueCard>
				<KeyValueCardContainer>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}

export const getButtonsToShow = (status: keyof typeof IncomingCheckStatus, userType: string): ActionButtons => {
	switch (userType) {
		case UserType.Org:
		case UserType.Unit:
			switch (status) {
				case IncomingCheckStatus.PendingReview:
					return ["Approve", "Return"]
				case IncomingCheckStatus.Processed:
					return ["Return"]
				default:
					return null
			}
		default:
			return null
	}
}

export const getDetailsToShow = (status: keyof typeof IncomingCheckStatus, userType: string): DetailsCard => {
	switch (userType) {
		case UserType.Org:
		case UserType.Unit:
			switch (status) {
				case IncomingCheckStatus.PendingReview:
					return ["ReviewReasons"]
				case IncomingCheckStatus.MarkedForReturn:
				case IncomingCheckStatus.Returned:
					return ["ReturnReasons"]
				default:
					return null
			}
		default:
			return null
	}
}

export function IncomingCheckDetailsCard({incomingCheck, cards}: {incomingCheck: IncomingCheck; cards: DetailsCard}) {
	if (!cards) return null
	const {attributes} = incomingCheck
	const incomingCheckReviewReasons = attributes.pendingReviewReasons
	const checkDepositReturnReason = attributes.checkDepositReturnReason

	return (
		<>
			{cards.includes("ReviewReasons") && incomingCheckReviewReasons && (
				<IncomingCheckReasons incomingCheckReasons={incomingCheckReviewReasons} />
			)}
			{cards.includes("ReturnReasons") && checkDepositReturnReason && (
				<IncomingCheckReason incomingCheckReason={checkDepositReturnReason} />
			)}
		</>
	)
}

export function IncomingCheckInfo({
	id,
	accessToken,
	incomingCheckAsyncResult: incomingCheckAsyncResult,
	customers,
	accounts,
	org,
}: SingleIncomingCheckProps) {
	const incomingCheckAsyncResultReq =
		incomingCheckAsyncResult || useAsyncResult(() => getIncomingCheckRequest({accessToken, id}))

	return (
		<AsyncResultComponent asyncResult={incomingCheckAsyncResultReq} pendingComponent={<IncomingCheckInfoPending />}>
			{({value: incomingCheck}) => {
				const incomingCheckCustomerIds = [
					...(incomingCheck.data.relationships.customers?.data.map(({id}) => id) ?? []),
					incomingCheck.data.relationships.customer?.data.id,
				]
				const checkPaymentCustomers = customers?.filter(({id}) => incomingCheckCustomerIds.includes(id))
				const account = accounts?.find(({id}) => id === incomingCheck.data.relationships.account?.data.id)
				return (
					<IncomingCheckInternal
						incomingCheck={incomingCheck.data}
						customers={checkPaymentCustomers}
						accessToken={accessToken}
						account={account}
						org={org}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}

function IncomingCheckDetails({incomingCheck}: {incomingCheck: IncomingCheck}) {
	const {amount, checkNumber, createdAt, description, returnCutoff, callbackStatus} = incomingCheck.attributes
	return (
		<div className="incoming-check-details">
			<KeyValueCard title={"Incoming Check Details"}>
				<KeyValueCardContainer>
					{checkNumber && (
						<>
							<KeyValueCardKey> Check Number</KeyValueCardKey>
							<KeyValueCardValue> {checkNumber} </KeyValueCardValue>
						</>
					)}

					{amount && (
						<>
							<KeyValueCardKey> Check Amount</KeyValueCardKey>
							<KeyValueCardValue> {numeral(amount / 100).format("$0,0.00")} </KeyValueCardValue>
						</>
					)}

					{createdAt && (
						<>
							<KeyValueCardKey> Created At</KeyValueCardKey>
							<KeyValueCardValue>{moment(createdAt).format(DATE_FORMAT)}</KeyValueCardValue>
						</>
					)}

					{description && (
						<>
							<KeyValueCardKey> Description</KeyValueCardKey>
							<KeyValueCardValue> {description} </KeyValueCardValue>
						</>
					)}
					{returnCutoff && (
						<>
							<KeyValueCardKey> Return Cutoff Time</KeyValueCardKey>
							<KeyValueCardValue>{moment(returnCutoff).format(DATE_FORMAT)}</KeyValueCardValue>
						</>
					)}
					{callbackStatus && (
						<>
							<KeyValueCardKey> Callback Status</KeyValueCardKey>
							<KeyValueCardValue>{startCase(callbackStatus)}</KeyValueCardValue>
						</>
					)}
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}
