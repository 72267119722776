import {useAccessToken, useUserType} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import React from "react"
import {ErrorDocument, Resource} from "../../resources/common"
import {useModal} from "react-modal-hook"
import moment from "moment"
import TitleBar from "../../components/TitleBar/TitleBar"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"
import {useQueryState} from "use-location-state"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResult} from "../../types/asyncResult"
import {Filter} from "../../components/Filter/Filter"
import Icon from "../../components/Icon/Icon"
import {findInterestSettings, InterestSettings} from "../../resources/interestSettings"
import InterestSettingsModal from "./InterestSettingsModal"
import {Bank, findBanks} from "../../resources/bank"
import {BankName} from "../../components/BankName/BankName"

function InterestSettingsTableRow({
	interestSettings,
	included,
	refresh,
}: {
	interestSettings: InterestSettings
	included?: Resource[]
	refresh: () => void
}) {
	const {
		id,
		attributes: {createdAt, interestFormula, name, unitInterest},
	} = interestSettings
	const bankId = interestSettings.relationships.bank.data.id

	const [showModal, hideModal] = useModal(() => (
		<InterestSettingsModal interestSettingsId={id} bankId={bankId} close={hideModal} refresh={refresh} mode={"edit"} />
	))

	return (
		<tr onClick={showModal}>
			<td>{id}</td>
			<td>
				<BankName bankId={bankId} included={included} />
			</td>
			<td>{name}</td>
			<td>{interestFormula}</td>
			<td>{unitInterest}</td>
			<td>{moment(createdAt).format("L")}</td>
		</tr>
	)
}

export default function InterestSettingsPage() {
	const PAGE_SIZE = 25
	const accessToken = useAccessToken()
	const userType = useUserType()

	const [refreshToken, refresh] = useRefreshToken()
	const [filteredBanks, setFilteredBanks] = useQueryState<string[]>("filter[banks]", [])

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		PAGE_SIZE,
		(offset, limit) => findInterestSettings(accessToken, offset, limit),
		(x) => x.data.length,
		[refreshToken, filteredBanks.join(",")]
	)
	const [showModal, hideModal] = useModal(() => (
		<InterestSettingsModal close={hideModal} refresh={refresh} mode="create" />
	))

	const banks =
		userType === "unit"
			? useAsyncResult(() => findBanks(accessToken, 0, 10000), [])
			: AsyncResult.pending<Bank[], ErrorDocument>()

	const bankFilter = banks.match(
		() => null,
		(banks) => (
			<Filter
				title="Banks Filter"
				isSearchable
				setStatuses={setFilteredBanks}
				statuses={filteredBanks}
				onFilterFunc={() => reset(PAGE_SIZE)}
				options={
					new Map<string, string>(
						banks
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((bank) => [bank.id, bank.attributes.name])
					)
				}
			/>
		),
		(_) => null
	)

	return (
		<>
			<TitleBar title={"Interest Settings"}>
				<button className="button button-create" onClick={() => showModal()}>
					<Icon icon="interface-add-1" size={12} /> Create
				</button>
			</TitleBar>
			<MainSectionTable>
				<DataTableCard className={"interest-settings-card"}>
					<DataTableActionHeader enableSticky={true} filters={[bankFilter]} />
					<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
						{({value: interestSettingsList}) => {
							const rows = interestSettingsList.data.map((cd) => (
								<InterestSettingsTableRow
									key={cd.id}
									included={interestSettingsList.included}
									interestSettings={cd}
									refresh={refresh}
								/>
							))

							return (
								<>
									<DataTable fullHeight={true} isEmpty={rows.length === 0} noContentText={"No interest settings found"}>
										<DataTableHead>
											<DataTableRow>
												<DataTableCell>Id</DataTableCell>
												<DataTableCell>Bank</DataTableCell>
												<DataTableCell>Name</DataTableCell>
												<DataTableCell>Formula</DataTableCell>
												<DataTableCell>Unit Interest</DataTableCell>
												<DataTableCell>Created At</DataTableCell>
											</DataTableRow>
										</DataTableHead>
										<DataTableBody>{rows}</DataTableBody>
									</DataTable>
									<PagingNavBar hasResults={hasResults} hasPrev={hasPrev} hasNext={hasNext} prev={prev} next={next} />
								</>
							)
						}}
					</AsyncResultComponent>
				</DataTableCard>
			</MainSectionTable>
		</>
	)
}
