import React from "react"
import {Box, BoxInfo} from "../../containers/Box/Box"
import {ProgressBar, ProgressBarSize, getPercentColor} from "../../components/ProgressBar/ProgressBar"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {calcPercent, div, toLongDollars} from "../../utilities/numbers"
import {CreditAccount} from "../../resources/account"

export function CreditAccountSummary({account}: {account: CreditAccount}) {
	const {balance, creditLimit, hold} = account.attributes
	const percent = calcPercent(balance, creditLimit)
	const color = getPercentColor(percent)
	const onHold = toLongDollars(hold, {decimalPoint: "optional"})
	const available = toLongDollars(account.attributes.available, {decimalPoint: "optional"})

	return (
		<Box className="account-box">
			<GridContainer rows={2} className="wh-100">
				<div className="flex-container w-100 justify-content-sb gap-10">
					<BoxInfo title="Spent">
						<div className="flex-container w-100 gap-10">
							<p className="font-32">{toLongDollars(balance, {decimalPoint: "optional"})}</p>
							<div className="flex-container w-100 align-items-end padding-b-6">
								<p className="font-14 font-normal box-info-value-secondary">
									<span className="font-bold">/ {toLongDollars(creditLimit, {decimalPoint: "optional"})}</span> limit
								</p>
							</div>
						</div>
					</BoxInfo>
				</div>
				<ProgressBar percent={percent} className={color} size={ProgressBarSize.sm} />
				<p className="font-14 margin-t-20 box-info-title">{`${onHold} on hold · ${available} available to spend`}</p>
			</GridContainer>{" "}
		</Box>
	)
}
