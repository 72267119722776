import React from "react"
import ReactTooltip from "react-tooltip"
import Icon from "../Icon/Icon"

export default function StytchFileds({
	jwksPath,
	issuer,
	updateSettings,
}: {
	jwksPath: string
	issuer: string | undefined
	updateSettings: (field: string, value: string) => void
}) {
	return (
		<fieldset className="jwt-fields">
			<div className="field">
				<label className="label">JWKs Path</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="https://{api|test}.stytch.com/v1/sessions/jwks/{projectId}"
						value={jwksPath ?? ""}
						pattern="https:\/\/(test|api)\.stytch\.com\/v1(?:\/b2b)?\/sessions\/jwks\/([A-Za-z0-9\-\.]*)"
						onChange={(e) => {
							updateSettings("jwksPath", e.target.value)
							if (e.target.validity.patternMismatch) {
								e.target.setCustomValidity(
									"invalid url, make sure your url is in this format: 'https://[api|test].stytch.com/v1/sessions/jwks/PROJECT_ID' or 'https://[api|test].stytch.com/v1/b2b/sessions/jwks/PROJECT_ID'"
								)
							} else {
								e.target.setCustomValidity("")
							}
						}}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">
					Issuer <span className="field-tag">(Optional)</span>
					<label className="tooltip-conatiner">
						<div data-tip="React-tooltip" data-for="issuer-info" className="tooltip-info">
							<Icon icon="information-circle--interface-essential" size={12} />

							<ReactTooltip className="tooltip-info" id="issuer-info" effect="solid">
								If your issuer is not in the following template: stytch.com/PROJECT_ID, please provide the correct one
								here
							</ReactTooltip>
						</div>
					</label>
				</label>
				<div className="control">
					<input
						className="input"
						type="text"
						value={issuer ?? ""}
						placeholder=" "
						onChange={(e) => {
							updateSettings("issuer", e.target.value)
						}}
					/>
				</div>
			</div>
		</fieldset>
	)
}
