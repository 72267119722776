import {getResource} from "./common"
import {findAgreements} from "./orgBankAgreement"
import {getEvaluatedInterest} from "./interestSettings"
import {getInterestRateByCalculationMethod} from "./interestRate"

export type Bank = {
	type: "bank"
	id: string
	attributes: {
		createdAt: Date
		name: string
		bankInterchangeShare: number
		interestCalculationMethod: string
	}
}

export async function getBank(accessToken: string, bankId: string) {
	const result = await getResource<Bank>(`banks/${bankId}`, accessToken)

	return result.map((v) => v.data)
}

export async function findBanks(accessToken: string, offset: number, limit: number) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			status,
		},
	}

	const result = await getResource<Array<Bank>>("banks", accessToken, query)

	return result.map((v) => v.data)
}

export async function fetchBankId(accessToken: string) {
	const result = await getResource<string>("banks/bank/getId", accessToken)

	return result.map(({data}) => data)
}

export async function getFedInterestRate(accessToken: string) {
	//Should be used by bank user only
	const bankIdResult = await fetchBankId(accessToken)
	return bankIdResult
		.asyncMap(async (bankId) => {
			return await getBank(accessToken, bankId)
		})
		.andThen((bankResult) => {
			return bankResult
				.asyncMap(async (bank) => {
					const calculationMethod = bank.attributes.interestCalculationMethod
					return await getInterestRateByCalculationMethod(accessToken, calculationMethod)
				})
				.andThen((interestRateResult) => {
					return interestRateResult.map((interestRate) => interestRate)
				})
		})
}

export async function getEvaluatedInterestSettings(accessToken: string, orgId: string) {
	//Should be used by bank user only
	const agreementsResult = await findAgreements({accessToken, offset: 0, limit: 1, orgs: [orgId]})
	return agreementsResult
		.asyncMap(async (agreements) => {
			return await getEvaluatedInterest(accessToken, agreements[0]?.relationships.interestSettings.data.id)
		})
		.andThen((interestSettingsResult) => {
			return interestSettingsResult.map((interestSettings) => interestSettings)
		})
}
