import React from "react"
import {IconProps} from "./types"

export function PDFIcon({disabled}: IconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={81} height={81} fill="none">
			<rect width={80} height={80} x={0.575} y={0.793} fill="#fff" rx={4.646} />
			<path
				fill="#F4F5F7"
				d="M21.037 66.964h36.946a4.592 4.592 0 0 0 4.592-4.592V31.768a3.516 3.516 0 0 0-1.013-2.468L48.014 15.64a3.458 3.458 0 0 0-2.449-1.023H21.037a3.45 3.45 0 0 0-2.448 1.022 3.504 3.504 0 0 0-1.014 2.468v45.367c0 .926.365 1.813 1.014 2.468a3.447 3.447 0 0 0 2.448 1.022Z"
			/>
			<path
				fill="#E2E4E7"
				d="M46.044 28.393V14.617c.735 0 1.53.612 1.837.919L61.656 29.31c.735.735.919 2.143.919 2.755H49.717a3.674 3.674 0 0 1-3.673-3.673Z"
			/>
			<path
				stroke="#FF2116"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeOpacity={disabled ? 0.5 : 1}
				strokeWidth={2}
				d="M37.41 31.244a7.757 7.757 0 0 0-1.013-2.904 3.143 3.143 0 0 0-2.546-1.512 3.003 3.003 0 0 0-2.645 2.546 5.53 5.53 0 0 0 .974 3.779 86.381 86.381 0 0 0 7.816 7.955 35.798 35.798 0 0 0 10.422 5.828 7.637 7.637 0 0 0 3.302.696 2.745 2.745 0 0 0 2.566-1.91 2.605 2.605 0 0 0-.955-2.386 5.392 5.392 0 0 0-2.466-1.074 21.402 21.402 0 0 0-10.581 1.114c-3.222.994-12.65 4.117-12.232 12.052 0 .18 0 1.512 1.69 1.313a5.132 5.132 0 0 0 3.402-2.347c2.128-3.182 2.366-7.08 2.645-10.66a47.33 47.33 0 0 0-.378-12.49v0Z"
			/>
		</svg>
	)
}
