import {useAccessToken} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useModal} from "react-modal-hook"
import React from "react"
import {BusinessCustomer, Customer, getBusiness, updateCustomerAuthorizedUsers} from "../../resources/customer"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AuthorizedUser, ErrorDocument, OkDocument} from "../../resources/common"
import {NewCustomerAuthorizedUserModal} from "./NewCustomerAuthorizedUserModal"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import Icon from "../../components/Icon/Icon"

function AuthorizedUsersRow({
	authorizedUsers,
	authorizedUser,
	accessToken,
	customer,
	refresh,
}: {
	authorizedUsers: AuthorizedUser[]
	authorizedUser: AuthorizedUser
	accessToken: string
	customer: OkDocument<Customer>
	refresh: () => void
}) {
	const [, remove] = useAsyncResultIdle((authorizedUser) =>
		updateCustomerAuthorizedUsers(
			accessToken,
			customer,
			authorizedUsers.filter((a) => a.email !== authorizedUser.email)
		)
	)

	const deleteAndRefresh = (t: AuthorizedUser) => remove(t).then(refresh)

	return (
		<DataTableRow>
			<DataTableCell>{`${authorizedUser.fullName.first} ${authorizedUser.fullName.last}`}</DataTableCell>
			<DataTableCell>{authorizedUser.email}</DataTableCell>
			<DataTableCell>{`${authorizedUser.phone.countryCode} ${authorizedUser.phone.number}`}</DataTableCell>
			<DataTableCell>
				<button className="delete" onClick={() => deleteAndRefresh(authorizedUser)} />
			</DataTableCell>
		</DataTableRow>
	)
}

function AuthorizedUserTable({
	customer,
	accessToken,
	refresh,
}: {
	customer: OkDocument<Customer>
	accessToken: string
	refresh: () => void
}) {
	const authorizedUsers = customer.data.attributes.authorizedUsers

	const [showModal, hideModal] = useModal(() => (
		<NewCustomerAuthorizedUserModal close={hideModal} refresh={refresh} customer={customer} />
	))

	return (
		<>
			<div className={"authorized-users-table"}>
				<DataTable
					hoverable={false}
					clickable={false}
					isEmpty={authorizedUsers.length === 0}
					fullHeight={false}
					stickyAction={false}
					noContentText={"No authorized users found"}
				>
					<DataTableHead>
						<DataTableRow>
							<DataTableCell> Name </DataTableCell>
							<DataTableCell> Email </DataTableCell>
							<DataTableCell> Phone </DataTableCell>
						</DataTableRow>
					</DataTableHead>
					<DataTableBody>
						{authorizedUsers.map((authorizedUser) => (
							<AuthorizedUsersRow
								authorizedUsers={authorizedUsers}
								customer={customer}
								authorizedUser={authorizedUser}
								key={authorizedUser.email}
								refresh={refresh}
								accessToken={accessToken}
							/>
						))}
					</DataTableBody>
				</DataTable>
				<div className="pagination-row">
					<div className="navigation-items">
						<button className="button button-create" onClick={() => showModal()}>
							<Icon icon="interface-add-1" size={12} /> Create
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export function AuthorizedUsers({customerId}: {customerId: string}) {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const result = useAsyncResult<OkDocument<BusinessCustomer>, ErrorDocument>(
		() => getBusiness(accessToken, customerId),
		[refreshToken]
	)

	return (
		<DataTableCard className={"authorized-users-card"}>
			<DataTableActionHeader title={"Authorized Users"} />
			<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
				{({value: customer}) => {
					return (
						<AuthorizedUserTable
							customer={customer as OkDocument<Customer>}
							accessToken={accessToken}
							refresh={refresh}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
