import React from "react"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {Application, getApplication, isBusinessApplicationDocument} from "../../resources/application"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {KeyValueCardPending} from "../KeyValueCard/KeyValueCard"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {BeneficialOwnerCardView} from "./BeneficalOwnerCardView"

interface BeneficialOwnerCardProps {
	applicationId: string
	applicationAsyncResult?: AsyncResultRequestState<OkDocument<Application>, ErrorDocument>
	accessToken: string
	singleColumn?: boolean
}

export function BeneficialOwnerCards({
	applicationId,
	applicationAsyncResult,
	accessToken,
	singleColumn,
}: BeneficialOwnerCardProps) {
	const applicationAsyncResultReq =
		applicationAsyncResult || useAsyncResult(() => getApplication(accessToken, applicationId))

	return (
		<AsyncResultComponent asyncResult={applicationAsyncResultReq} pendingComponent={<KeyValueCardPending rows={4} />}>
			{({value: result}) => {
				if (isBusinessApplicationDocument(result)) {
					return (
						<BeneficialOwnerCardView
							application={result.data}
							title={"Beneficial Owners"}
							singleColumn={singleColumn}
						/>
					)
				} else {
					return <> </>
				}
			}}
		</AsyncResultComponent>
	)
}
