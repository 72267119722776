import React, {PropsWithChildren} from "react"
import DepositProductFieldView from "./DepositProductFieldView"
import DepositProductFieldEdit from "./DepositProductFieldEdit"
import {DepositProductFieldProps} from "../types"
import {EditMode} from "../../../../resources/common"

export default function DepositProductField({
	mode,
	type,
	name,
	subName,
	label,
	subLabel,
	value,
	subValue,
	onChange,
	onBlur,
	checked,
	inputType,
	children,
	inputPrefix,
	inputSuffix,
	inputFormat,
	infoIconTooltipText,
	enableField,
	...inputProps
}: PropsWithChildren<DepositProductFieldProps>) {
	switch (mode) {
		case EditMode.VIEW:
			return (
				<DepositProductFieldView
					label={label}
					subLabel={subLabel}
					value={value}
					subValue={subValue}
					inputFormat={inputFormat}
					inputPrefix={inputPrefix}
					inputSuffix={inputSuffix}
					infoIconTooltipText={infoIconTooltipText}
					enableField={enableField}
				/>
			)
		case EditMode.EDIT:
			return (
				<DepositProductFieldEdit
					type={type}
					name={name}
					subName={subName}
					label={label}
					subLabel={subLabel}
					value={value}
					subValue={subValue}
					onChange={onChange}
					onBlur={onBlur}
					checked={checked}
					inputType={inputType}
					inputPrefix={inputPrefix}
					inputSuffix={inputSuffix}
					{...inputProps}
				>
					{children}
				</DepositProductFieldEdit>
			)
	}

	return <></>
}
