import {getFile, getResource} from "./common"
import {Cents, Direction} from "./transaction"

export enum ReceivedPaymentStatus {
	Pending = "Pending",
	Completed = "Completed",
	Advanced = "Advanced",
	Returned = "Returned",
	MarkedForReturn = "MarkedForReturn",
	PendingReview = "PendingReview",
}

export enum ReceivedPaymentTypes {
	ACH = "Ach",
}

export type ReceivedPayment = AchReceivedPayment

export type AchReceivedPayment = {
	id: string
	type: ReceivedPaymentTypes.ACH
	attributes: {
		createdAt: Date
		status: ReceivedPaymentStatus
		direction: Direction
		wasAdvanced: boolean
		completionDate?: Date
		returnReason: string
		achReturnReason: string
		amount: Cents
		description: string
		addenda: string
		companyName: string
		counterpartyRoutingNumber: string
		traceNumber: string
		secCode: string
		receivingEntityName?: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: "account"
				id: number
			}
		}
		customer?: {
			data: {
				type: "customer"
				id: number
			}
		}
		receivePaymentTransaction?: {
			data: {
				type: "transaction"
				id: number
			}
		}
		paymentAdvanceTransaction?: {
			data: {
				type: string
				id: number
			}
		}
		repayPaymentAdvanceTransaction?: {
			data: {
				type: "transaction"
				id: number
			}
		}
		org?: {
			data: {
				type: "transaction"
				id: number
			}
		}
		incomingAchItem?: {
			data: {
				type: "achItem"
				id: number
			}
		}
	}
}

export async function getReceivedPayment(accessToken: string, id: string, include?: string) {
	return await getResource<ReceivedPayment>(`received-payments/${id}`, accessToken, {include})
}

export async function findReceivedPayments(
	accessToken: string,
	offset: number,
	sort: string,
	searchQuery: string,
	requestType: "json" | "csv",
	limit?: number | null,
	statusFilter?: ReceivedPaymentStatus[],
	orgs?: string[],
	typeFilter?: string[],
	since?: string,
	until?: string,
	accountId?: string,
	fromAmount?: Cents | "",
	toAmount?: Cents | "",
	include?: string
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const query = {
		page: {
			limit: limit,
			offset,
		},
		filter: {
			status: statusFilter,
			query: searchQuery != "" ? searchQuery : null,
			orgs: orgFilter,
			type: typeFilter,
			since: since != "" ? since : null,
			until: until != "" ? until : null,
			accountId: accountId,
			fromAmount: fromAmount != "" ? fromAmount : null,
			toAmount: toAmount != "" ? toAmount : null,
		},
		sort: sort,
		include: include ? include : "customer,org",
	}

	switch (requestType) {
		case "csv":
			return await getFile("received-payments", accessToken, query)
		default:
			return await getResource<Array<ReceivedPayment>>("received-payments", accessToken, query)
	}
}
