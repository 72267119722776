import React, {useState} from "react"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useParams} from "react-router-dom"
import {useAccessToken} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {
	getIncomingWire,
	IncomingWire,
	PendingReviewReasons,
	OfacHitsReviewReason,
	WireCounterparty,
} from "../../resources/incomingWire"
import {kebabCase, startCase} from "lodash"
import classNames from "classnames"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {CustomerCard} from "../../components/Customers/CustomerCard"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardValue,
} from "../../components/KeyValueCard/KeyValueCard"
import numeral from "numeral"
import moment from "moment"
import {OrgName} from "../../components/OrgName/OrgName"
import {TagsViewerKeyValue} from "../../components/Tags/TagsViewer"
import Card from "../../containers/Card/Card"
import ReactJson from "react-json-view"
import Admonition from "../../components/Admonitions/Admonition"
import IncomingWires, {IncomingWireButtons, IncomingWiresColumns} from "../../components/IncomingWires/IncomingWires"
import {useToggle} from "react-use"
import Icon from "../../components/Icon/Icon"
import {NamesAtUnitCard} from "../../components/NamesAtUnit/NamesAtUnit"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {extractDataFromFromImadOmad} from "../../utilities/helpers"
import {Customer} from "../../resources/customer"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import {WireInvolvedFinancialInstitutions} from "../../components/WireInvolvedFinancialInstitutions/WireInvolvedFinancialInstitutions"

function WireDetailsCard({wire}: {wire: IncomingWire}) {
	const amount = wire.attributes.amount && numeral(wire.attributes.amount / 100).format("$0,0.00")
	const createdAt = wire.attributes.createdAt && moment(wire.attributes.createdAt).format("L")
	const org = wire.relationships.org?.data.id && (
		<OrgName orgId={wire.relationships.org.data.id.toString()} element="span" />
	)
	const direction = "Credit"
	const imad = wire.attributes.incomingMessage.imad
	const omad = wire.attributes.incomingMessage.omad
	const businessFunctionCode = wire.attributes.businessFunctionCode
	const isMonetary = wire.attributes.isMonetary
	const isReturn = wire.attributes.isReturn
	const dataFromOmad = omad && extractDataFromFromImadOmad(omad)
	const imadOmad = imad && omad && (
		<TagsViewerKeyValue
			tags={{
				IMAD: imad,
				OMAD: omad,
				...(dataFromOmad
					? {
							"OMAD Cycle Date": dataFromOmad.cycleDate,
							"OMAD Sequence Number": dataFromOmad.sequenceNumber,
					  }
					: {}),
			}}
		/>
	)
	const returnReason = wire.attributes.returnReason
	const decisionReason =
		wire.attributes.decision?.manuallyReturned?.decisionReason ||
		wire.attributes.decision?.automaticReturned?.decisionReason
	const decisionBy =
		wire.attributes.decision?.manuallyReturned?.decisionBy || wire.attributes.decision?.manuallyApproved?.decisionBy
	const decisionDate =
		wire.attributes.decision?.manuallyReturned?.timestamp ||
		wire.attributes.decision?.manuallyApproved?.timestamp ||
		wire.attributes.decision?.manuallyReturned?.timestamp ||
		wire.attributes.decision?.automaticApproved?.timestamp

	const wireDetails = {
		...(amount ? {amount} : {}),
		...(createdAt ? {createdAt} : {}),
		...(org ? {org} : {}),
		direction,
		isMonetary,
		isReturn,
		businessFunctionCode,
		...(imadOmad ? {"IMAD OMAD": imadOmad} : {}),
		...(returnReason ? {returnReason: startCase(returnReason)} : {}),
		...(decisionReason ? {decisionReason} : {}),
		...(decisionBy ? {decisionBy} : {}),
		...(decisionDate ? {decisionDate: moment(decisionDate).format("L LT")} : {}),
	}

	return (
		<KeyValueCard title={"Wire details"}>
			<KeyValueCardContainer>
				{Object.entries(wireDetails).map(([key, value]) => {
					return (
						<React.Fragment key={key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue>{typeof value === "boolean" ? value.toString() : value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

function WireCounterpartyCard({wireCounterparty}: {wireCounterparty: WireCounterparty}) {
	const name = wireCounterparty.name
	const routingNumber = wireCounterparty.routingNumber
	const accountNumber = wireCounterparty.accountNumber
	const accountType = wireCounterparty.accountType

	const counterpartyDetails = {
		...(name ? {name} : {}),
		...(routingNumber ? {routingNumber} : {}),
		...(accountNumber ? {accountNumber} : {}),
		...(accountType ? {accountType} : {}),
	}

	return (
		<KeyValueCard title={"Counterparty details"}>
			<KeyValueCardContainer>
				{Object.entries(counterpartyDetails).map(([key, value]) => {
					return (
						<React.Fragment key={key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

function OfacReviewReason({
	ofacReviewReason,
	isExpandedByDefault = false,
}: {
	ofacReviewReason: OfacHitsReviewReason
	isExpandedByDefault?: boolean
}) {
	const [isExpanded, setIsExpanded] = useToggle(isExpandedByDefault)
	const reviewReasonDetails = {
		...(ofacReviewReason.sdn.title ? {title: ofacReviewReason.sdn.title} : {}),
		...(ofacReviewReason.sdn.sdnType ? {"SDN Type": ofacReviewReason.sdn.sdnType} : {}),
		...(ofacReviewReason.sdn.program ? {program: ofacReviewReason.sdn.program.join(",")} : {}),
		...(ofacReviewReason.sdn.entityId ? {entityId: ofacReviewReason.sdn.entityId} : {}),
		...(ofacReviewReason.sdn.remarks ? {entityId: ofacReviewReason.sdn.remarks} : {}),
		...(ofacReviewReason.addresses
			? {
					address: ofacReviewReason.addresses
						.map((address) => address.address + "\n" + address.country + "\n" + address.cityStateProvincePostalCode)
						.join("\n"),
			  }
			: {}),
		...(ofacReviewReason.alts
			? {
					"Alternative Names": ofacReviewReason.alts
						.map((alt) => alt.alternateName + "\n" + alt.alternateType + "\n" + alt.entityID)
						.join("\n"),
			  }
			: {}),
	}

	const icon = isExpanded
		? "interface-arrows-button-up--interface-essential"
		: "interface-arrows-button-down--interface-essential"

	return (
		<div className={classNames("wire-review-reason", "ofac-review-reason", isExpanded && "expanded")}>
			<div className="admonition-wrapper" onClick={setIsExpanded}>
				<Admonition
					type={"is-warning"}
					title={`OFAC list matches #${ofacReviewReason.sdn.entityId}`}
					message={`${ofacReviewReason.sdn.sdnName}: ${numeral(ofacReviewReason.sdn.match).format("0%")}`}
				/>
				<Icon className={"arrow-icon"} icon={icon} />
			</div>
			<div className={"details"}>
				<KeyValueCard>
					<KeyValueCardContainer>
						{Object.entries(reviewReasonDetails).map(([key, value]) => {
							return (
								<React.Fragment key={key}>
									<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
									<KeyValueCardValue> {value} </KeyValueCardValue>
								</React.Fragment>
							)
						})}
					</KeyValueCardContainer>
				</KeyValueCard>
			</div>
		</div>
	)
}

function NameMismatchReviewReason({nameMismatchMap}: {nameMismatchMap: {namesScoreMap: Record<string, string>}}) {
	const message = Object.entries(nameMismatchMap.namesScoreMap).map((entry) => {
		return (
			<div key={entry[0]}>
				{entry[0]}: {entry[1]}%
			</div>
		)
	})

	return (
		<div className="wire-review-reason">
			<Admonition type={"is-warning"} title={"Name miss match"} message={message} />
		</div>
	)
}

function SoftLimitReviewReason() {
	return (
		<div className="wire-review-reason">
			<Admonition type={"is-warning"} title={"Soft limit"} message={""} />
		</div>
	)
}

function FedWireAmountLimit() {
	return (
		<div className="wire-review-reason">
			<Admonition type={"is-warning"} title="FedWire amount limit" message={""} />
		</div>
	)
}

export function ReviewReasons({pendingReviewReasons}: {pendingReviewReasons?: PendingReviewReasons}) {
	if (!pendingReviewReasons) {
		return null
	}

	return (
		<Card title="Review reasons">
			{pendingReviewReasons.map((reviewReason, i) => {
				return (
					<React.Fragment key={i}>
						{reviewReason.ofacHit?.hits.map((hit, j) => (
							<OfacReviewReason key={i} ofacReviewReason={hit} isExpandedByDefault={j === 0} />
						))}
						{reviewReason.nameMissMatch && (
							<NameMismatchReviewReason key={i} nameMismatchMap={reviewReason.nameMissMatch} />
						)}
						{reviewReason.softLimit && <SoftLimitReviewReason key={i} />}
						{reviewReason.fedWireAmountLimit && <FedWireAmountLimit key={i} />}
					</React.Fragment>
				)
			})}
		</Card>
	)
}

function FullDetails({rawData}: {rawData: object}) {
	return (
		<Card title="Raw data">
			<ReactJson src={rawData} displayDataTypes={false} />
		</Card>
	)
}

function IncomingWireInternal({
	value: wire,
	refresh,
}: {
	value: {data: IncomingWire; included: Array<Customer>}
	refresh: () => void
}) {
	const accessToken = useAccessToken()
	const {attributes, relationships, type, id} = wire.data
	const [currentTab, setCurrentTab] = useState<"Review" | "Full details">(
		attributes.status === "PendingReview" ? "Review" : "Full details"
	)
	const isReviewTab = currentTab === "Review"
	const customers =
		wire.included &&
		(wire.included.filter((i) => ["individualCustomer", "businessCustomer"].includes(i.type)) as Customer[])
	const singleCustomer = Array.isArray(customers) && customers.length === 1 ? customers[0] : null
	const involvedInstitutions = attributes.involvedInstitutions
	return (
		<>
			<TitleBar
				title={`${startCase(type).replace("Payment", "")} #${id}`}
				breadcrumbs={[{text: "Incoming Wires", path: "/incoming-wires/"}]}
				status={{
					text: startCase(attributes.status),
					className: classNames("incoming-wire-status", kebabCase(attributes.status)),
				}}
				meta={[
					...(relationships.account?.data.id && relationships.transaction?.data.id
						? [
								{
									text: "Account",
									icon: "bank-account--business-products",
									path: `/accounts/${relationships.account?.data.id}`,
								} as MetaItemProps,
								{
									text: "Customer",
									icon: "user-geometric-action-money-fund---users",
									path: `/customers/${relationships.customer?.data.id}`,
								} as MetaItemProps,
								...(singleCustomer
									? [
											{
												text: "Application",
												icon: "user-id-card",
												path: `/applications/${singleCustomer?.relationships.application?.data.id}`,
											} as MetaItemProps,
									  ]
									: []),
								{
									text: "Transaction",
									icon: "money-transfer--business-products",
									path: `/transaction/${relationships.account?.data.id}/${relationships.transaction?.data.id}`,
								} as MetaItemProps,
						  ]
						: []),
				]}
			>
				<IncomingWireButtons incomingWire={wire.data} refresh={refresh} />
			</TitleBar>
			{attributes.status === "PendingReview" && (
				<nav className="tabs-container">
					<div className="tabs">
						<ul>
							<li className={classNames(currentTab == "Review" && "is-active")} onClick={() => setCurrentTab("Review")}>
								<a>Review</a>
							</li>
							<li
								className={classNames(currentTab == "Full details" && "is-active")}
								onClick={() => setCurrentTab("Full details")}
							>
								<a>Raw Data</a>
							</li>
						</ul>
					</div>
				</nav>
			)}
			<div className={"incoming-wire-page"}>
				<section className="section pr-3">
					{/* TODO: do components/IncomingWires/IncomingWire*/}
					<WireDetailsCard wire={wire.data} />
					{relationships.customer?.data.id && (
						<>
							<CustomerCard
								customerId={relationships.customer.data.id.toString()}
								showBusinessDetails={!isReviewTab}
								showBusinessContactDetails={!isReviewTab}
							/>
							<UnitUserOnly>
								<NamesAtUnitCard customerId={relationships.customer.data.id.toString()} />
							</UnitUserOnly>
						</>
					)}
					{involvedInstitutions && (
						<Card title="Involved Financial Institutions">
							<WireInvolvedFinancialInstitutions involvedFinancialInstitutions={involvedInstitutions} />
						</Card>
					)}
					{attributes.wireCounterparty && <WireCounterpartyCard wireCounterparty={attributes.wireCounterparty} />}
					<Transactions
						enableAmountFilter={false}
						enableDateFilter={false}
						enableTypeFilter={false}
						enableOrgFilter={false}
						enableDirectionFilter={false}
						isUsingPaging={false}
						fullHeight={false}
						accountId={id}
						limit={5}
						enableSearch={false}
						enableExport={false}
						title={"Recent Transactions"}
						token={accessToken}
						includedColumns={[
							TransactionsColumns.id,
							TransactionsColumns.type,
							TransactionsColumns.summary,
							TransactionsColumns.createdAt,
							TransactionsColumns.balance,
							TransactionsColumns.amount,
						]}
					/>

					<IncomingWires
						title={"Recent Incoming Wires"}
						fullHeight={false}
						stickyAction={false}
						enablePaging={false}
						enableStatusFilter={false}
						limit={10}
						includedColumns={[
							IncomingWiresColumns.id,
							IncomingWiresColumns.amount,
							IncomingWiresColumns.status,
							IncomingWiresColumns.createdAt,
						]}
					/>
				</section>
				<section className="section pl-3">
					{attributes.status === "PendingReview" ? (
						<>
							{isReviewTab && <ReviewReasons pendingReviewReasons={attributes.pendingReviewReasons} />}
							{currentTab === "Full details" && <FullDetails rawData={attributes.incomingMessage} />}
						</>
					) : (
						<>
							<FullDetails rawData={attributes.incomingMessage} />
						</>
					)}
				</section>
			</div>
		</>
	)
}

export default function IncomingWirePage() {
	const {incomingWireId = ""} = useParams<{incomingWireId: string}>()
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const asyncResult = useAsyncResult<OkDocument<IncomingWire>, ErrorDocument>(
		() => getIncomingWire(accessToken, incomingWireId),
		[refreshToken, incomingWireId]
	)
	// const asyncResult = AsyncResult.ok<OkDocument<any>, ErrorDocument>({data: incomingWireMock})

	return (
		<AsyncResultComponent asyncResult={asyncResult} refresh={refresh}>
			{({value: value}) => {
				return (
					<IncomingWireInternal value={value as {data: IncomingWire; included: Array<Customer>}} refresh={refresh} />
				)
			}}
		</AsyncResultComponent>
	)
}
