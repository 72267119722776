import {useAccessToken} from "../../services/auth"
import React from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {removeCustomerKnownNames} from "../../resources/customerKnownNames"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"

const modalCloseDelay = 1000

interface RemoveAliasCustomerModalProps {
	customerId: string
	alias: string
	close: () => void
	onSuccess: () => void
}

function closeAndRefresh(refresh: () => void, close: () => void, delay = 0) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, delay)
	}
}

export function RemoveAliasCustomerModal({customerId, alias, close, onSuccess}: RemoveAliasCustomerModalProps) {
	const accessToken = useAccessToken()
	const [state, remove] = useAsyncResultIdle(removeCustomerKnownNames)

	return (
		<AsyncResultModal
			title="Remove alias"
			close={closeAndRefresh(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Remove this alias"
			successText="Removed"
			onSuccess={closeAndRefresh(onSuccess, close, modalCloseDelay)}
			onSubmit={() => remove(accessToken, customerId, alias)}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure you want to remove <b>{`"${alias}"`}</b> from known aliases?
		</AsyncResultModal>
	)
}
