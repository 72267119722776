import React from "react"
import {Assignee} from "../../resources/assignments"
import {KeyValueCardView} from "../RenderKeyValueCard/KeyValueCardView"
import {Downloader} from "../Downloader/Downloader"
import {AssigneeAvatarWithName} from "../AssigneeAvatarWithName/AssigneeAvatarWithName"
import {FileMetadata} from "../../utilities/file"
import classNames from "classnames"
import {useToasts} from "react-toast-notifications"
import {onDownloadClick} from "../AssignmentPageItemCallback/AssignmentPageItemCallback"

interface CallbackReviewDetailsProps {
	assignee: Assignee
	payload: CallbackReviewDetailsPayload
	disabled?: boolean
	accessToken: string
	id: string
}

interface CallbackSummaryProps {
	summary: string
	assignee: Assignee
	disabled?: boolean
}

export type CallbackReviewDetailsPayload = {
	files: FileMetadata[]
	callSummary?: string
	disabled?: boolean
}

function CallbackSummary({summary, assignee, disabled}: CallbackSummaryProps) {
	return (
		<div className="callback-details-summary-container">
			<AssigneeAvatarWithName assignees={[assignee]} />
			<div className={classNames("callback-details-summary", disabled && "disabled")}>{summary}</div>
		</div>
	)
}

type CallbackReviewDetailsObject = {
	evidenceCollector: string
	callbackEvidence?: JSX.Element[]
	callbackSummary?: JSX.Element
}
export function CallbackReviewDetails({assignee, payload, disabled, accessToken, id}: CallbackReviewDetailsProps) {
	const {addToast} = useToasts()
	const details: CallbackReviewDetailsObject = {
		evidenceCollector: assignee.name ?? assignee.type,
	}
	const files = payload.files
	if (files.length > 0) {
		details.callbackEvidence = files.map((file, index) => {
			const handleDownload = async () => {
				await onDownloadClick({
					preLoadedCallbackDocumentMetadata: {documentName: file.name, documentSize: file.size},
					addToast,
					accessToken,
					id,
				})
			}
			return (
				<div className="callback-details-review-downloader-container" key={index}>
					<Downloader
						key={file.name}
						fileName={file.name}
						sizeInBytes={file.size}
						onDownloadClick={handleDownload}
						disabled={disabled}
						enforceEnabledDownloadButton={true}
						expanded={true}
					/>
				</div>
			)
		})
	}
	const summary = payload.callSummary
	if (summary) {
		details.callbackSummary = <CallbackSummary summary={summary} assignee={assignee} disabled={disabled} />
	}
	return (
		<div className="callback-details-review-container">
			<KeyValueCardView
				title="Customer Evidence"
				details={details}
				keyPrefix={"callback-review-details"}
				singleColumn={true}
				disabled={disabled}
			/>
		</div>
	)
}
