import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {IncomingWire, rejectIncomingWire} from "../../resources/incomingWire"

interface RejectIncomingWireModalProps {
	incomingWire: IncomingWire
	close: () => void
	onSuccess: () => void
}

function Reason({reason, setReason}: {reason: string; setReason: (reason: string) => void}) {
	return (
		<div className="field">
			<label className="label">Reason</label>
			<div className="select">
				<select
					value={reason}
					onChange={(e) => {
						setReason(e.target.value)
					}}
				>
					<option value="NoAccount">No Account</option>
					<option value="AccountNotActive">Account Not Active</option>
					<option value="OFACHit">OFAC Hit</option>
					<option value="NameMissMatch">Name Miss Match</option>
					<option value="ReturnPerRequest">Return Per Request</option>
				</select>
			</div>
		</div>
	)
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function RejectIncomingWireModal({incomingWire, close, onSuccess}: RejectIncomingWireModalProps) {
	const accessToken = useAccessToken()
	const [returnReason, setReturnReason] = useState("NoAccount")
	const [returnReasonText, setReturnReasonText] = useState("")
	const [state, deny] = useAsyncResultIdle(rejectIncomingWire)

	return (
		<AsyncResultModal
			title="Reject Incoming Wire"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Reject"
			successText="Rejected"
			onSubmit={() => deny(accessToken, incomingWire.id, returnReason, returnReasonText)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<Reason reason={returnReason} setReason={setReturnReason} />
				<textarea
					className="textarea"
					placeholder="Why are you  denying this incoming wire?"
					value={returnReasonText}
					required
					onChange={(e) => setReturnReasonText(e.target.value)}
				/>
			</div>
		</AsyncResultModal>
	)
}
