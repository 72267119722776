import {createResource, deleteResource, getResource, updateResource} from "./common"

export enum CounterpartyAccountTypeEnum {
	Checking = "Checking",
	Savings = "Savings",
}

export type CounterpartyType = "Business" | "Person" | "Unknown"
export type CounterpartyPermission = "CreditOnly" | "CreditAndDebit" | "DebitOnly"
export type CounterpartyAccountType = CounterpartyAccountTypeEnum.Checking | CounterpartyAccountTypeEnum.Savings

export type Counterparty = {
	id: string
	attributes: {
		name: string
		routingNumber: string
		accountNumber: string
		accountType: CounterpartyAccountType
		type: CounterpartyType
		permissions: CounterpartyPermission
		createdAt: Date
		tags: {[key: string]: string}
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: number
			}
		}
	}
}

export async function findCounterparties(accessToken: string, offset: number, limit: number, customerId?: string) {
	const query = {
		filter: {
			customerId,
		},
		page: {
			limit,
			offset,
		},
	}

	return await getResource<Array<Counterparty>>("counterparties", accessToken, query)
}

export async function deleteCounterparty(accessToken: string, counterParty: Counterparty) {
	return await deleteResource<Counterparty>(`counterparties/${counterParty.id}`, accessToken)
}

interface CounterpartyCreateRequestParam {
	accessToken: string
	customerId: string
	name: string
	routingNumber: string
	accountNumber: string
	accountType: CounterpartyAccountType
	type: CounterpartyType
	permissions: CounterpartyPermission
	tags?: {[key: string]: string}
}

export async function createCounterparty(request: CounterpartyCreateRequestParam) {
	const query = {
		type: "achCounterparty",
		attributes: {
			name: request.name,
			routingNumber: request.routingNumber,
			accountNumber: request.accountNumber,
			accountType: request.accountType,
			type: request.type,
			permissions: request.permissions,
			tags: request.tags,
		},
		relationships: {
			customer: {
				data: {
					type: "customer",
					id: request.customerId,
				},
			},
		},
	}
	return await createResource<Counterparty>("counterparties", request.accessToken, query)
}

export async function updateCounterparty(
	accessToken: string,
	id: string,
	name?: string,
	permissions?: CounterpartyPermission,
	tags?: {[key: string]: string}
) {
	const query = {
		type: "counterparty",
		attributes: {
			name: name,
			permissions: permissions,
			tags: tags,
		},
	}
	return await updateResource<Counterparty>(`counterparties/${id}`, accessToken, query)
}
