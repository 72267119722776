import {CertificateStatus, deleteDomain} from "../../resources/bankSettings"

import ClientDomainConfig from "./ClientDomainConfig"
import React from "react"
import {useModal} from "react-modal-hook"
import DeleteDomainSettingsModal from "../../components/OrgAndBankSettings/DeleteDomainSettingsModal"

type RefreshFunc = () => void

interface DomainSetupProps {
	dnsDomain: string
	refresh: RefreshFunc
	status: CertificateStatus
}

export default function DnsDomainView({dnsDomain, refresh, status}: DomainSetupProps) {
	const [showEditModal, hideEditModal] = useModal(() => (
		<DeleteDomainSettingsModal
			domainName={dnsDomain}
			close={hideEditModal}
			refresh={refresh}
			deleteDomain={deleteDomain}
		/>
	))

	return (
		<>
			<div className="field ">
				<label className="label">Domain Name</label>
				<div className="field has-addons is-horizontal">
					<div className="control is-expanded">
						<input
							className="input"
							required
							type="text"
							value={dnsDomain}
							placeholder="A subdomain you own"
							disabled
						/>
					</div>
					<button
						type="button"
						className="button is-danger"
						onClick={(e) => {
							e.preventDefault()
							showEditModal()
						}}
					>
						Delete
					</button>
				</div>
			</div>
			<ClientDomainConfig refresh={refresh} status={status} />
		</>
	)
}
