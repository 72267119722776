import React from "react"
import {Officer} from "../../resources/application"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardSubHeader,
	KeyValueCardValue,
} from "../KeyValueCard/KeyValueCard"
import {startCase} from "lodash"

interface OfficerCardViewProps {
	officer: Officer
	title?: string
	singleColumn?: boolean
	disabled?: boolean
	className?: string
}

function OfficerCardContent({officer, title, disabled}: {officer: Officer; title?: string; disabled?: boolean}) {
	const name = officer.fullName
	const phone = officer.phone
	const lastSSN = officer.maskedSSN

	const officerDetails = {
		...(name ? {officerName: `${name.first} ${name.last}`} : {}),
		...(phone ? {officerPhoneNumber: `+${phone.countryCode} ${phone.number}`} : {}),
		...(lastSSN ? {officerLast4SSN: lastSSN} : {}),
	}

	return (
		<>
			{title && <KeyValueCardSubHeader title={title} />}
			<KeyValueCardContainer>
				{Object.entries(officerDetails).map(([key, value]) => {
					return (
						<React.Fragment key={key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue disabled={disabled}> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</>
	)
}

export function OfficerCardView({officer, title, singleColumn, disabled, className}: OfficerCardViewProps) {
	return (
		<KeyValueCard title={title} singleColumn={singleColumn} disabled={disabled} className={className}>
			<OfficerCardContent officer={officer} title={title} disabled={disabled} />
		</KeyValueCard>
	)
}
