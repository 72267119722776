import {Result} from "neverthrow"
import useAsyncResultIdle from "../hooks/useAsyncResultIdle"
import {post} from "../utilities/http"
import {SandboxClient} from "../resources/org"
import {ErrorDocument} from "../resources/common"

export function useSignUp() {
	return useAsyncResultIdle<Result<SandboxClient, ErrorDocument>, ErrorDocument, any>(async function (
		firstName: string,
		lastName: string,
		orgName: string,
		email: string,
		password: string,
		assessmentToken: string,
		subscribe: boolean
	) {
		const signupTemplateId = "d-ec0157e3e2db4bae8d2f04df674aeb3a"

		const sandboxMailingList = "341a7022-4631-41aa-bd6b-ab6cd074de4a"

		const sandboxDistributionList = "21"

		const config = {
			headers: {
				"Content-Type": "application/vnd.api+json",
			},
		}

		const mailingLists: Array<string> = []
		const distributionList: Array<string> = []

		if (subscribe) {
			mailingLists.push(sandboxMailingList)
			distributionList.push(sandboxDistributionList)
		}

		const data = {
			type: "sandboxClient",
			attributes: {
				orgName: orgName,
				primaryEmail: email,
				name: {
					first: firstName,
					last: lastName,
				},
				password: password,
				emailTemplateId: signupTemplateId,
				mailingLists: mailingLists,
				distributionLists: distributionList.length > 0 ? distributionList : undefined,
				assessmentToken: assessmentToken,
			},
		}

		return await post<Result<SandboxClient, ErrorDocument>, ErrorDocument>(
			`${process.env.API_URL}/signup`,
			data,
			config
		)
	},
	[])
}
