import {createResource} from "./common"
import {v4 as uuidv4} from "uuid"

export type ApplicationForm = {
	type: "applicationForm"
	id: string
	attributes: {
		url: string
	}
}

export type ApplicationFormV2 = {
	type: "applicationFormV2"
	id: string
	links: {
		related: {
			type: "text/html"
			href: string
		}
	}
}

export const isApplicationFormV2 = (data: any): data is ApplicationFormV2 => {
	return data.type === "applicationFormV2"
}

export type ApplicationFormVersion = "v1" | "v2"

export type PreviewApplicationFormPrefill =
	| "NoPrefill"
	| "PrefillIndividual"
	| "PrefillSoleProp"
	| "PrefillBusiness"
	| "PrefillSingleMemberBusiness"
	| "PrefillMultipleMemberBusiness"

type PreviewApplicationFormParams = {
	accessToken: string
	prefillType: PreviewApplicationFormPrefill
	version: ApplicationFormVersion
	whiteLabelThemeId?: string
	lendingProgramId?: string
}

export async function previewApplicationForm({
	accessToken,
	prefillType,
	version,
	whiteLabelThemeId,
	lendingProgramId,
}: PreviewApplicationFormParams) {
	let requestBody

	switch (prefillType) {
		case "NoPrefill": {
			requestBody = {
				type: "applicationForm",
				attributes: {},
			}
			break
		}
		case "PrefillIndividual": {
			requestBody = {
				type: "applicationForm",
				attributes: {
					applicantDetails: {
						applicationType: "Individual",
						fullName: {
							first: "Peter",
							last: "Parker",
						},
						ssn: "721074426",
						dateOfBirth: "2001-08-10",
						email: "peter@oscorp.com",
						address: {
							street: "5230 Newell Rd",
							city: "Palo Alto",
							state: "CA",
							postalCode: "94303",
							country: "US",
						},
						phone: {
							countryCode: "1",
							number: "2025550150",
						},
						occupation: "Doctor",
						sourceOfIncome: "BusinessOwnershipInterests",
						annualIncome: "Between100kAnd250k",
					},
				},
			}
			break
		}
		case "PrefillSoleProp": {
			requestBody = {
				type: "applicationForm",
				attributes: {
					applicantDetails: {
						applicationType: "SoleProprietorship",
						fullName: {
							first: "Peter",
							last: "Parker",
						},
						ssn: "721074426",
						dateOfBirth: "2001-08-10",
						email: "peter@oscorp.com",
						address: {
							street: "5230 Newell Rd",
							city: "Palo Alto",
							state: "CA",
							postalCode: "94303",
							country: "US",
						},
						phone: {
							countryCode: "1",
							number: "2025550150",
						},
						occupation: "Doctor",
						sourceOfIncome: "BusinessOwnershipInterests",
						annualIncome: "Between100kAnd250k",
						businessVertical: "TechnologyMediaOrTelecom",
						website: "https://www.piedpiper.com",
					},
				},
			}
			break
		}
		case "PrefillBusiness": {
			requestBody = {
				type: "applicationForm",
				attributes: {
					applicantDetails: {
						applicationType: "Business",
						email: "peter@oscorp.com",
						name: "Pied Piper",
						stateOfIncorporation: "DE",
						entityType: "Corporation",
						contact: {
							fullName: {
								first: "Richard",
								last: "Hendricks",
							},
							email: "richard@piedpiper.com",
							phone: {
								countryCode: "1",
								number: "2025550150",
							},
						},
						industry: "FreelanceProfessional",
						officer: {
							fullName: {
								first: "Richard",
								last: "Hendricks",
							},
							dateOfBirth: "2001-08-10",
							title: "CEO",
							ssn: "721074426",
							email: "richard@piedpiper.com",
							phone: {
								countryCode: "1",
								number: "2025550150",
							},
							address: {
								street: "5230 Newell Rd",
								city: "Palo Alto",
								state: "CA",
								postalCode: "94303",
								country: "US",
							},
							occupation: "Doctor",
							sourceOfIncome: "BusinessOwnershipInterests",
							annualIncome: "Between100kAnd250k",
						},
						beneficialOwners: [
							{
								fullName: {
									first: "Richard",
									last: "Hendricks",
								},
								dateOfBirth: "2001-08-10",
								ssn: "123456789",
								email: "richard@piedpiper.com",
								percentage: 75,
								phone: {
									countryCode: "1",
									number: "2025550150",
								},
								address: {
									street: "5230 Newell Rd",
									city: "Palo Alto",
									state: "CA",
									postalCode: "94303",
									country: "US",
								},
								occupation: "Doctor",
								sourceOfIncome: "BusinessOwnershipInterests",
								annualIncome: "Between100kAnd250k",
							},
						],
						website: "https://www.piedpiper.com",
						dba: "Piedpiper Inc",
						ein: "123456789",
						address: {
							street: "5230 Newell Rd",
							city: "Palo Alto",
							state: "CA",
							postalCode: "94303",
							country: "US",
						},
						phone: {
							countryCode: "1",
							number: "2025550150",
						},
						businessVertical: "TechnologyMediaOrTelecom",
						yearOfIncorporation: "1999",
						hasNonUsEntities: false,
					},
				},
			}
			break
		}
		case "PrefillSingleMemberBusiness": {
			requestBody = {
				type: "applicationForm",
				attributes: {
					applicantDetails: {
						applicationType: "SingleMemberBusiness",
						email: "peter@oscorp.com",
						name: "Pied Piper",
						stateOfIncorporation: "DE",
						entityType: "Corporation",
						contact: {
							fullName: {
								first: "Richard",
								last: "Hendricks",
							},
							email: "richard@piedpiper.com",
							phone: {
								countryCode: "1",
								number: "2025550150",
							},
						},
						industry: "FreelanceProfessional",
						officer: {
							fullName: {
								first: "Richard",
								last: "Hendricks",
							},
							dateOfBirth: "2001-08-10",
							title: "CEO",
							ssn: "721074426",
							email: "richard@piedpiper.com",
							phone: {
								countryCode: "1",
								number: "2025550150",
							},
							address: {
								street: "5230 Newell Rd",
								city: "Palo Alto",
								state: "CA",
								postalCode: "94303",
								country: "US",
							},
							occupation: "Doctor",
							sourceOfIncome: "BusinessOwnershipInterests",
							annualIncome: "Between100kAnd250k",
						},
						beneficialOwners: [
							{
								fullName: {
									first: "Richard",
									last: "Hendricks",
								},
								dateOfBirth: "2001-08-10",
								ssn: "123456789",
								email: "richard@piedpiper.com",
								percentage: 75,
								phone: {
									countryCode: "1",
									number: "2025550150",
								},
								address: {
									street: "5230 Newell Rd",
									city: "Palo Alto",
									state: "CA",
									postalCode: "94303",
									country: "US",
								},
								occupation: "Doctor",
								sourceOfIncome: "BusinessOwnershipInterests",
								annualIncome: "Between100kAnd250k",
							},
						],
						website: "https://www.piedpiper.com",
						dba: "Piedpiper Inc",
						ein: "123456789",
						address: {
							street: "5230 Newell Rd",
							city: "Palo Alto",
							state: "CA",
							postalCode: "94303",
							country: "US",
						},
						phone: {
							countryCode: "1",
							number: "2025550150",
						},
						businessVertical: "TechnologyMediaOrTelecom",
						yearOfIncorporation: "1999",
						hasNonUsEntities: false,
					},
				},
			}
			break
		}
		case "PrefillMultipleMemberBusiness": {
			requestBody = {
				type: "applicationForm",
				attributes: {
					applicantDetails: {
						applicationType: "MultipleMemberBusiness",
						email: "peter@oscorp.com",
						name: "Pied Piper",
						stateOfIncorporation: "DE",
						entityType: "Corporation",
						contact: {
							fullName: {
								first: "Richard",
								last: "Hendricks",
							},
							email: "richard@piedpiper.com",
							phone: {
								countryCode: "1",
								number: "2025550150",
							},
						},
						industry: "FreelanceProfessional",
						officer: {
							fullName: {
								first: "Richard",
								last: "Hendricks",
							},
							dateOfBirth: "2001-08-10",
							title: "CEO",
							ssn: "721074426",
							email: "richard@piedpiper.com",
							phone: {
								countryCode: "1",
								number: "2025550150",
							},
							address: {
								street: "5230 Newell Rd",
								city: "Palo Alto",
								state: "CA",
								postalCode: "94303",
								country: "US",
							},
							occupation: "Doctor",
							sourceOfIncome: "BusinessOwnershipInterests",
							annualIncome: "Between100kAnd250k",
						},
						beneficialOwners: [
							{
								fullName: {
									first: "Richard",
									last: "Hendricks",
								},
								dateOfBirth: "2001-08-10",
								ssn: "123456789",
								email: "richard@piedpiper.com",
								percentage: 75,
								phone: {
									countryCode: "1",
									number: "2025550150",
								},
								address: {
									street: "5230 Newell Rd",
									city: "Palo Alto",
									state: "CA",
									postalCode: "94303",
									country: "US",
								},
								occupation: "Doctor",
								sourceOfIncome: "BusinessOwnershipInterests",
								annualIncome: "Between100kAnd250k",
							},
						],
						website: "https://www.piedpiper.com",
						dba: "Piedpiper Inc",
						ein: "123456789",
						address: {
							street: "5230 Newell Rd",
							city: "Palo Alto",
							state: "CA",
							postalCode: "94303",
							country: "US",
						},
						phone: {
							countryCode: "1",
							number: "2025550150",
						},
						businessVertical: "TechnologyMediaOrTelecom",
						yearOfIncorporation: "1999",
						hasNonUsEntities: false,
					},
				},
			}
			break
		}
	}

	if (version === "v2") {
		requestBody = {
			type: requestBody.type,
			attributes: {
				idempotencyKey: uuidv4(), //This is just for demonstration purposes, in a real-world scenario, this should be generated by the client
				...requestBody.attributes,
			},
			relationships: {
				...(whiteLabelThemeId
					? {
							whiteLabelTheme: {
								data: {
									type: "whiteLabelTheme",
									id: whiteLabelThemeId,
								},
							},
					  }
					: {}),
				...(lendingProgramId
					? {
							lendingProgram: {
								data: {
									type: "lendingProgram",
									id: lendingProgramId,
								},
							},
					  }
					: {}),
			},
		}
	}

	const result = await createResource<ApplicationForm | ApplicationFormV2>(
		"application-forms",
		accessToken,
		requestBody,
		version === "v2"
			? {
					"X-Accept-Version": "V2024_06",
			  }
			: {}
	)
	return result.map((v) => v.data)
}
