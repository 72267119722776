import React, {useState} from "react"
import classNames from "classnames"

interface Props {
	children: React.ReactNode
	summary?: React.ReactNode | string
}

export function ShowMoreParagraph({summary, children}: Props) {
	const [expanded, setExpanded] = useState(false)

	return (
		<div className={classNames("show-more-paragraph-container")}>
			{summary && <div className={"accordion-summary"}>{summary}</div>}
			<div className={classNames("accordion", expanded && "expanded")}>{children}</div>
			<div className={"accordion-action"}>
				<span onClick={() => setExpanded(!expanded)}>Show {expanded ? "less" : "more"}</span>
			</div>
		</div>
	)
}
