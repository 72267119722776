import {BusinessVertical, BusinessVerticalMapping} from "../../resources/application"
import React from "react"
import {Editable, EditableDropDownField} from "../HorizontalField/EditableHorizontalField"

export interface EditableBusinessVerticalProps {
	businessVertical?: BusinessVertical
	setBusinessVertical: (value: BusinessVertical) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: BusinessVertical) => void
	className?: string
}

export function EditableBusinessVerticalField(props: EditableBusinessVerticalProps) {
	if (props.businessVertical) {
		return (
			<Editable
				label="Business Vertical"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setBusinessVertical}
				value={props.businessVertical}
				initial={props.businessVertical}
				update={props.update}
				className={props.className}
				possibleValues={BusinessVerticalMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}

	return null
}
