import React from "react"
import {Application, REASON_MESSAGE_TO_TOOLTIP} from "../../resources/application"
import {UnitOrBankUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import HorizontalField, {optionalHorizontalField} from "../HorizontalField/HorizontalField"
import {UserName} from "../Users/UserName"
import Card from "../../containers/Card/Card"
import {RiskRateField} from "../RiskRateField/RiskRateField"
import moment from "moment"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"
import Chips from "../Chips/Chips"
import {GridContainer} from "../../containers/GridContainer/GridContainer"

export default function ApplicationDecision({
	application,
	fieldClassName,
}: {
	application: Application
	fieldClassName?: string
}) {
	const attributes = application.attributes

	if (attributes.status !== "Approved" && attributes.status !== "Denied") return <React.Fragment />

	if (!(useIsBankUser() || useIsUnitUser()) && attributes.status == "Approved") return <React.Fragment />

	return (
		<Card
			className="application-decision"
			title={attributes.status == "Approved" ? "Approval Information" : "Denial Information"}
		>
			<UnitOrBankUserOnly>
				<RiskRateField riskRate={attributes.riskRate} fieldClassName={fieldClassName} />
			</UnitOrBankUserOnly>
			<UnitOrBankUserOnly>
				{optionalHorizontalField(
					"Method",
					attributes.decisionMethod,
					undefined,
					attributes.archived ? "archived-field" : ""
				)}
			</UnitOrBankUserOnly>
			{attributes.decisionDateTime ? (
				<HorizontalField
					label={attributes.status === "Approved" ? "Approved At" : "Denied At"}
					fieldClassName={fieldClassName}
				>
					<input
						type="text"
						readOnly
						value={moment(attributes.decisionDateTime).format("L LT")}
						className="input is-static"
					/>
				</HorizontalField>
			) : null}
			<UnitOrBankUserOnly>
				{attributes.decisionReason ? (
					<HorizontalField label="Notes" fieldClassName={fieldClassName}>
						<textarea readOnly value={attributes.decisionReason} className="textarea is-static has-fixed-size" />
					</HorizontalField>
				) : null}
			</UnitOrBankUserOnly>
			<UnitOrBankUserOnly>
				{attributes.decisionUserId ? (
					<HorizontalField
						label={attributes.status === "Approved" ? "Approved By" : "Denied By"}
						fieldClassName={fieldClassName}
					>
						<UserName
							element="div"
							userId={attributes.decisionUserId}
							onResult={(user) => (
								<input type="text" readOnly value={user.attributes.username} className="input is-static" />
							)}
						/>
					</HorizontalField>
				) : null}
			</UnitOrBankUserOnly>
			{attributes.decisionReasonCodes && attributes.decisionReasonCodes.length > 0 ? (
				<>
					<div className="horizontal-separator" />
					<div className="decision-title"> Reasons </div>
					<GridContainer gap="20px">
						{attributes.decisionReasonCodes.map((dr) => (
							<div key={dr.entity}>
								<div className="decision-entity-name">{dr.entity}</div>
								{dr.reasons && (
									<Chips
										labels={dr.reasons}
										tooltips={dr.reasons.map((r) => REASON_MESSAGE_TO_TOOLTIP.get(r.toLowerCase()))}
										tooltipKeyPrefix={dr.entity}
										readonly
									/>
								)}
							</div>
						))}
					</GridContainer>
				</>
			) : null}
		</Card>
	)
}
