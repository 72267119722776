import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {Card, freezeCard} from "../../resources/card"

interface FreezeCardModalProps {
	card: Card
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function FreezeCardModal({card, close, onSuccess}: FreezeCardModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState("")
	const [state, freeze] = useAsyncResultIdle(freezeCard)

	return (
		<AsyncResultModal
			title="Freeze Card"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Freeze"
			successText="Froze"
			onSubmit={() => freeze(accessToken, card, reason)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Common reasons</label>

				<div className="select is-fullwidth">
					<select
						defaultValue=""
						onChange={(e) => {
							setReason(e.target.value)
						}}
					>
						<option value="Suspected Fraud">Suspected Fraud</option>
						<option value="">Other</option>
					</select>
				</div>
			</div>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<textarea
						required
						className="textarea"
						placeholder="Why are you freezing the card?"
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
