import React from "react"
import {useParams} from "react-router-dom"
import {OrgAuthProvider, useCustomerAccessToken} from "../../services/orgAuth"
import {PaymentComponent} from "../../components/Payments/Payment"
import {useRefreshToken} from "../../hooks/useRefreshToken"

export function Internal({paymentId}: {paymentId: string}) {
	const accessToken = useCustomerAccessToken()
	const [, refresh] = useRefreshToken()

	return <PaymentComponent useDrawer={false} refresh={refresh} paymentId={paymentId} accessToken={accessToken} />
}

export default function OrgPaymentPage() {
	const {paymentId} = useParams<{paymentId: string}>()

	return (
		<OrgAuthProvider>
			<Internal paymentId={paymentId as string} />
		</OrgAuthProvider>
	)
}
