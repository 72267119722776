import React from "react"
import {Box, BoxInfo} from "../../../containers/Box/Box"
import {ProgressBar, ProgressBarSize, getPercentColor} from "../../../components/ProgressBar/ProgressBar"
import {DepositAccount, getAccount} from "../../../resources/account"
import {GridContainer} from "../../../containers/GridContainer/GridContainer"
import Skeleton from "react-loading-skeleton"
import {calcPercent, div, toLongDollars} from "../../../utilities/numbers"
import {Link} from "react-router-dom"
import {AsyncResultComponent} from "../../../containers/AsyncResult/AsyncResult"
import {useAccessToken} from "../../../services/auth"
import {useAsyncResult} from "../../../hooks/useAsyncResult"
import {getBankDetails} from "../../../utilities/getBankDetails"
import {LinkArrowIcon} from "../../../components/LinkArrowIcon/LinkArrowIcon"
import {BankNameAndLogo} from "../../../components/BankNameAndLogo/BankNameAndLogo"

export function FundingAccountTitle({title, bankId}: {title: string; bankId: string}) {
	const {name, logo} = getBankDetails(bankId)
	return (
		<div className="flex-container w-100 justify-content-sb gap-10">
			<p className="box-title font-18">{title}</p>
			<div className="flex-container font-14 align-items-center line-h-1-3">
				<BankNameAndLogo name={name} logo={logo} />
			</div>
		</div>
	)
}

function FundingAccountProgressBar({owed, balance}: {owed: number; balance: number}) {
	const percent = calcPercent(owed, balance)
	const color = getPercentColor(percent)

	return (
		<GridContainer rows={2} gap="8px">
			<div className="flex-container w-100 justify-content-sb gap-10">
				<BoxInfo title="Total owed to bank">{toLongDollars(owed)}</BoxInfo>
				<BoxInfo title="Balance" end>
					{toLongDollars(balance)}
				</BoxInfo>
			</div>
			<ProgressBar percent={percent} className={color} size={ProgressBarSize.md} />
		</GridContainer>
	)
}

function FundingAccountLink({accountId, state}: {accountId: string; state: any}) {
	return (
		<Link to={`/accounts/${accountId}`} state={state}>
			<div className="flex-container justify-content-end w-100">
				<div className="flex-container box-link">
					<span className="font-14 margin-r-10 text-black">See funding account</span>
					<LinkArrowIcon />
				</div>
			</div>
		</Link>
	)
}

function FundingAccountOnHomePage({account, bankId}: {account: DepositAccount; bankId: string}) {
	const {balance, reserve} = account.attributes

	return (
		<Box>
			<div className="flex-container flex-dir-col justify-content-sb wh-100 min-h-200">
				<FundingAccountTitle title="Funding Account" bankId={bankId} />
				<FundingAccountProgressBar owed={reserve} balance={balance} />
				<FundingAccountLink accountId={account.id} state={{bankId}} />
			</div>
		</Box>
	)
}

export function FundingAccountSkeleton() {
	return (
		<Box>
			<div className="flex-container flex-dir-col justify-content-sb wh-100 min-h-200">
				<Skeleton width="100%" />
				<Skeleton count={2} height={30} width="100%" />
				<Skeleton width="100%" />
			</div>
		</Box>
	)
}

interface AsyncFundingAccountProps {
	creditOperationalAccountId: string
	bankId: string
}

export function FundingAccount({creditOperationalAccountId, bankId}: AsyncFundingAccountProps) {
	const accessToken = useAccessToken()
	const creditOperationalAccount = useAsyncResult(() =>
		getAccount<DepositAccount>(accessToken, creditOperationalAccountId)
	)

	return (
		<AsyncResultComponent asyncResult={creditOperationalAccount} pendingComponent={<FundingAccountSkeleton />}>
			{({value}) => <FundingAccountOnHomePage account={value} bankId={bankId} />}
		</AsyncResultComponent>
	)
}
