import {useAccessToken} from "../../services/auth"
import React from "react"
import {Customer, getCustomer} from "../../resources/customer"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Err} from "../Err/Err"
import {getTransaction, Transaction} from "../../resources/transaction"
import {ErrorDocument, Resource} from "../../resources/common"
import {Adjustment} from "../../resources/adjustment"
import {getOrg, Org} from "../../resources/org"

export function GetCustomer(customerId: string, onResult: (customer: Customer) => JSX.Element, included?: Resource[]) {
	if (included) {
		const customer = included.find(
			(r) => (r.type === "individualCustomer" || r.type == "businessCustomer") && r.id === customerId
		)
		if (customer && (customer.type == "individualCustomer" || customer.type == "businessCustomer"))
			return onResult(customer)
	}

	const accessToken = useAccessToken()
	const result = useAsyncResult(() => getCustomer(accessToken, customerId), [customerId])
	return result.match(
		() => null,
		(customer) => onResult(customer.data),
		(err) => <Err err={err} />
	)
}

export function GetOrg(orgId: string, onResult: (org: Org) => JSX.Element) {
	const accessToken = useAccessToken()
	const result = useAsyncResult(() => getOrg(accessToken, orgId), [orgId])
	return result.match(
		() => null,
		(org) => onResult(org),
		(err) => <Err err={err} />
	)
}

export function GetTransaction(adjustment: Adjustment, onResult: (tx: Transaction) => JSX.Element) {
	if (adjustment.relationships.transaction) {
		const accessToken = useAccessToken()
		const result = useAsyncResult<Transaction, ErrorDocument>(() =>
			getTransaction(
				accessToken,
				String(adjustment.relationships.account.data.id),
				String(adjustment.relationships.transaction?.data.id)
			)
		)
		return result.match(
			() => null,
			(tx) => onResult(tx),
			(err) => <Err err={err} />
		)
	}
}
