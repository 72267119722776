import React, {Dispatch, SetStateAction} from "react"
import {toNumber} from "lodash"
import {EditMode} from "../../resources/common"
import {Bank} from "../../resources/bank"
import {isUndefined} from "lodash"
import {Org} from "../../resources/org"
import {DepositAccount, OrgGeneralLedgerAccount, OrgLoanAccount} from "../../resources/account"
import {CardTransactionFunding} from "../../resources/orgBankAgreement"
import {InterestSettings} from "../../resources/interestSettings"
import {useIsUnitUser} from "../../services/auth"

interface Props {
	editMode: EditMode
	name?: string
	orgId: string
	setOrgId: Dispatch<SetStateAction<string>>
	org?: Org
	bankId: string
	setBankId: Dispatch<SetStateAction<string>>
	banks?: Array<Bank>
	orgs?: Array<Org>
	revenueAccounts?: Array<DepositAccount>
	reserveAccounts?: Array<DepositAccount>
	overdraftReserveAccounts?: Array<DepositAccount>
	pushToCardAccounts?: Array<DepositAccount>
	sponsoredInterestAccounts?: Array<DepositAccount>
	provisionalCreditOperatingAccounts?: Array<DepositAccount>
	provisionalCreditReserveAccounts?: Array<DepositAccount>
	cashDepositSettlementAccounts?: Array<DepositAccount>
	capitalPartnerOrgs?: Array<Org>
	orgGeneralLedgerAccounts?: Array<OrgGeneralLedgerAccount>
	orgLoanAccounts?: Array<OrgLoanAccount>
	creditOperationalAccounts?: Array<DepositAccount>
	orgInterchangeShare: number
	setOrgInterchangeShare: Dispatch<SetStateAction<number>>
	bankInterchangeShare: number
	setBankInterchangeShare: Dispatch<SetStateAction<number>>
	revenueAccountId: string
	setRevenueAccountId: Dispatch<SetStateAction<string>>
	reserveAccountId: string
	setReserveAccountId: Dispatch<SetStateAction<string>>
	pushToCardAccountId?: string
	setPushToCardAccountId: Dispatch<SetStateAction<string | undefined>>
	overdraftReserveAccountId?: string
	setOverdraftReserveAccountId: Dispatch<SetStateAction<string | undefined>>
	sponsoredInterestAccountId: string | undefined
	setSponsoredInterestAccountId: Dispatch<SetStateAction<string | undefined>>
	provisionalCreditOperatingAccountId: string | undefined
	setProvisionalCreditOperatingAccountId: Dispatch<SetStateAction<string | undefined>>
	provisionalCreditReserveAccountId: string | undefined
	setProvisionalCreditReserveAccountId: Dispatch<SetStateAction<string | undefined>>
	cashDepositSettlementAccountId: string | undefined
	setCashDepositSettlementAccountId: Dispatch<SetStateAction<string | undefined>>
	unitInterest: number
	setUnitInterest: Dispatch<SetStateAction<number>>
	isUpdatableDepositProduct: boolean
	setIsUpdatableDepositProduct: Dispatch<SetStateAction<boolean>>
	isWirePaymentEnabled: boolean
	setIsWirePaymentEnabled: Dispatch<SetStateAction<boolean>>
	isBillPaymentEnabled: boolean
	setIsBillPaymentEnabled: Dispatch<SetStateAction<boolean>>
	isCheckDepositEnabled: boolean
	setIsCheckDepositEnabled: Dispatch<SetStateAction<boolean>>
	isPushToCardEnabled: boolean
	setIsPushToCardEnabled: Dispatch<SetStateAction<boolean>>
	isOverdraftEnabled: boolean
	setIsOverdraftEnabled: Dispatch<SetStateAction<boolean>>
	isCreditEnabled: boolean
	setIsCreditEnabled: Dispatch<SetStateAction<boolean>>
	isDacaEnabled: boolean
	setIsDacaEnabled: Dispatch<SetStateAction<boolean>>
	cardTransactionFunding: CardTransactionFunding
	setCardTransactionFunding: Dispatch<SetStateAction<CardTransactionFunding>>
	isChargebackEnabled: boolean
	setIsChargebackEnabled: Dispatch<SetStateAction<boolean>>
	isTrustApplicationsEnabled: boolean
	setIsTrustApplicationsEnabled: Dispatch<SetStateAction<boolean>>
	isAutoArchiveAccountsEnabled: boolean
	setIsAutoArchiveAccountsEnabled: Dispatch<SetStateAction<boolean>>
	autoArchiveAccountsAfterDays: number
	setAutoArchiveAccountsAfterDays: Dispatch<SetStateAction<number>>
	isAchTransactionReturnEnabled: boolean
	setIsAchTransactionReturnEnabled: Dispatch<SetStateAction<boolean>>
	isIncreasePilotCustomerLimits: boolean
	setIsIncreasePilotCustomerLimits: Dispatch<SetStateAction<boolean>>
	isSameDayAchEnabled: boolean
	setIsSameDayAchEnabled: Dispatch<SetStateAction<boolean>>
	isEnforceLimitsCustomerLevel: boolean
	setIsEnforceLimitsCustomerLevel: Dispatch<SetStateAction<boolean>>
	isActivationOfPhysicalCardsForOnlineUseEnabled: boolean
	setIsActivationOfPhysicalCardsForOnlineUseEnabled: Dispatch<SetStateAction<boolean>>
	isCashDepositEnabled: boolean
	setIsCashDepositEnabled: Dispatch<SetStateAction<boolean>>
	allowOrgReleaseAuthHolds: boolean
	setAllowOrgReleaseAuthHolds: Dispatch<SetStateAction<boolean>>
	allowInlineAchDebit: boolean
	setAllowInlineAchDebit: Dispatch<SetStateAction<boolean>>
	allowCounterpartyAchDebit: boolean
	setAllowCounterpartyAchDebit: Dispatch<SetStateAction<boolean>>
	isEnforceDailyACHDebitOrgLevel: boolean
	setIsEnforceDailyACHDebitOrgLevel: Dispatch<SetStateAction<boolean>>
	dailyACHDebitOrgLevel?: number
	setDailyACHDebitOrgLevel: Dispatch<SetStateAction<number | undefined>>
	overrideOriginatedAchSecCodeEnabled: boolean
	setOverrideOriginatedAchSecCodeEnabled: Dispatch<SetStateAction<boolean>>
	isCoverNegativeBalanceOnClose: boolean
	setIsCoverNegativeBalanceOnClose: Dispatch<SetStateAction<boolean>>
	isEnforceDailyACHCreditOrgLevel: boolean
	setIsEnforceDailyACHCreditOrgLevel: Dispatch<SetStateAction<boolean>>
	dailyACHCreditOrgLevel?: number
	setDailyACHCreditOrgLevel: Dispatch<SetStateAction<number | undefined>>
	isCheckWritingEnabled: boolean
	setIsCheckWritingEnabled: Dispatch<SetStateAction<boolean>>
	isCardTransactionEnrichmentEnabled: boolean
	setIsCardTransactionEnrichmentEnabled: Dispatch<SetStateAction<boolean>>
	isReactivatingSuspectedFraudCardsEnabled: boolean
	setIsReactivatingSuspectedFraudCardsEnabled: Dispatch<SetStateAction<boolean>>
	isSplitInterestDailyEnabled: boolean
	setIsSplitInterestDailyEnabled: Dispatch<SetStateAction<boolean>>
	minimumReserveBalance?: number
	setMinimumReserveBalance: Dispatch<SetStateAction<number | undefined>>
	sweepCode?: number
	setSweepCode: Dispatch<SetStateAction<number | undefined>>
	setIsCardSchemeFeeEnabled: Dispatch<SetStateAction<boolean>>
	isCardSchemeFeeEnabled: boolean
	interestSettingsId: string | undefined | null
	setInterestSettingsId: Dispatch<SetStateAction<string>>
	interestSettings: Array<InterestSettings>
	isLastFourSSNEnabled: boolean
	setIsLastFourSSNEnabled: Dispatch<SetStateAction<boolean>>
	allowCreatingFBOBusinessCustomer: boolean
	setAllowCreatingFBOBusinessCustomerEnabled: Dispatch<SetStateAction<boolean>>
	isCheckWritingOriginationEnabled: boolean
	setIsCheckWritingOriginationEnabled: Dispatch<SetStateAction<boolean>>
	overdraftLimitReserveRatio?: number
	setOverdraftLimitReserveRatio: Dispatch<SetStateAction<number | undefined>>
	accountCreationBlocked: boolean
	setAccountCreationBlocked: Dispatch<SetStateAction<boolean>>
	authorizedUsersCreationBlocked: boolean
	setAuthorizedUsersCreationBlocked: Dispatch<SetStateAction<boolean>>
	financialAccountsSupported: boolean
	setFinancialAccountsSupported: Dispatch<SetStateAction<boolean>>
	isReprocessReceivedAchDebitEnabled: boolean
	setIsReprocessReceivedAchDebitEnabled: Dispatch<SetStateAction<boolean>>
	isResetPinCardEnabled: boolean
	setIsResetPinCardEnabled: Dispatch<SetStateAction<boolean>>
}

/** Class formats and rounds interchange share
 * Due to weird behavior of JS on multiplying and dividing decimal numbers with 100, we need to manipulate the number
 * a bit for the end rounded calculation to be correct
 * TODO: Refactor this so the code will use a decimal library (such as decimal.js) to handle floating point numbers
 * */
class InterchangeShareFormatter {
	static getFormatted(interchangeShare: number): number {
		const interchangeSharePercentageString = (interchangeShare * 100).toFixed(3)
		const interchangeSharePercentage = toNumber(interchangeSharePercentageString)
		const splitFloatingPoint = interchangeSharePercentageString.split(".")
		const decimalPoints = splitFloatingPoint.length > 1 ? splitFloatingPoint[1].length : 0
		return decimalPoints > 1 ? Math.floor(interchangeSharePercentage * 10) / 10 : interchangeSharePercentage
	}

	static updateFormatted(value: string, setFunction: (interchangeShare: number) => void): void {
		const interchangeShare = toNumber(value) / 100
		const splitFloatingPoint = value.split(".")
		const decimalPoints = splitFloatingPoint.length > 1 ? splitFloatingPoint[1].length : 0
		const formattedInterchangeShare = decimalPoints > 1 ? Math.floor(interchangeShare * 1000) / 1000 : interchangeShare

		setFunction(formattedInterchangeShare)
	}
}

export function OrgBankAgreementAttributes(props: Props) {
	const isUnitUser = useIsUnitUser()
	const nameTextBox =
		props.editMode === EditMode.VIEW ? (
			<div className="field">
				<label className="label">Name</label>
				<div className="control">
					<input className="input" type="text" placeholder="Pied Piper" value={props.name} required disabled={true} />
				</div>
			</div>
		) : (
			<></>
		)

	const increasePilotCustomerLimitsCheckbox = (
		props.org
			? props.org?.attributes.isUnitPilot
			: props.orgs?.find((org) => org.id == props.orgId)?.attributes.isUnitPilot
	) ? (
		<label className="label">
			<input
				type="checkbox"
				checked={props.isIncreasePilotCustomerLimits}
				disabled={props.editMode == EditMode.VIEW}
				onChange={(e) => props.setIsIncreasePilotCustomerLimits(e.target.checked)}
			/>{" "}
			Increase Pilot Customer Limits
		</label>
	) : (
		<></>
	)

	return (
		<>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-content">
							{nameTextBox}

							{props.editMode == EditMode.NEW &&
							!isUndefined(props.bankId) &&
							!isUndefined(props.setBankId) &&
							!isUndefined(props.banks) ? (
								<div className="field">
									<label className="label">Bank</label>
									<div className="field-body">
										<div className="select">
											<select
												required
												value={props.bankId ? props.bankId : ""}
												onChange={(e) => (props.setBankId ? props.setBankId(e.target.value) : null)}
											>
												<option key="0" defaultValue=""></option>
												{props.banks.map((c) => (
													<option key={c.id} value={c.id}>
														{c.attributes.name}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							) : null}

							{props.editMode == EditMode.NEW &&
							!isUndefined(props.orgId) &&
							!isUndefined(props.setOrgId) &&
							!isUndefined(props.orgs) ? (
								<div className="field">
									<label className="label">Orgs</label>
									<div className="field-body">
										<div className="select">
											<select required value={props.orgId} onChange={(e) => props.setOrgId(e.target.value)}>
												<option key="0" defaultValue=""></option>
												{props.orgs.map((o) => (
													<option key={o.id} value={o.id}>
														{o.attributes.name}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							) : null}

							<div className="field">
								<label className="label">Org Reserve Account</label>
								<div className="field-body">
									<div className="select">
										<select
											required
											value={props.reserveAccountId}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setReserveAccountId(e.target.value)}
										>
											{props.reserveAccounts?.map((a) => (
												<option key={a.id} value={a.id}>
													{a.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<label className="label" htmlFor="minimum-balance">
								Minimum Reserve Balance
							</label>
							<div className="field">
								<div className="field-body">
									<div className="control">
										<input
											id="minimum-balance"
											className="input"
											type="number"
											min="0"
											value={toNumber(props.minimumReserveBalance)}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setMinimumReserveBalance(toNumber(e.target.value))}
										/>
									</div>{" "}
									<p className="control">
										<a className="button is-static">Cents</a>
									</p>
								</div>
							</div>

							<div className="field">
								<label className="label">Org Revenue Account</label>
								<div className="field-body">
									<div className="select">
										<select
											required
											value={props.revenueAccountId}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setRevenueAccountId(e.target.value)}
										>
											{props.revenueAccounts?.map((a) => (
												<option key={a.id} value={a.id}>
													{a.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className="field">
								<label className="label">Sponsored Interest Account</label>
								<div className="field-body">
									<div className="select">
										<select
											value={props.sponsoredInterestAccountId}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setSponsoredInterestAccountId(e.target.value || undefined)}
										>
											<option value="" />
											{props.sponsoredInterestAccounts?.map((a) => (
												<option key={a.id} value={a.id}>
													{a.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className="field">
								<label className="label">Provisional Credit Operating Account</label>
								<div className="field-body">
									<div className="select">
										<select
											value={props.provisionalCreditOperatingAccountId}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setProvisionalCreditOperatingAccountId(e.target.value || undefined)}
										>
											<option value="" />
											{props.provisionalCreditOperatingAccounts?.map((a) => (
												<option key={a.id} value={a.id}>
													{a.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className="field">
								<label className="label">Provisional Credit Reserve Account</label>
								<div className="field-body">
									<div className="select">
										<select
											value={props.provisionalCreditReserveAccountId}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setProvisionalCreditReserveAccountId(e.target.value || undefined)}
										>
											<option value="" />
											{props.provisionalCreditReserveAccounts?.map((a) => (
												<option key={a.id} value={a.id}>
													{a.attributes.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							{isUnitUser && (
								<>
									<div className="field">
										<label className="label">Bank Interchange share</label>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="number"
													min="0"
													max="100"
													value={InterchangeShareFormatter.getFormatted(props.bankInterchangeShare)}
													pattern="^[1-9][0-9]?(\.[05])?$|^100$|^0(\.[05])?$"
													step="0.5"
													disabled={props.editMode == EditMode.VIEW}
													onChange={(e) =>
														InterchangeShareFormatter.updateFormatted(
															e.target.value,
															props.setBankInterchangeShare.bind(props)
														)
													}
												/>
											</div>
											<p className="control">
												<a className="button is-static">%</a>
											</p>
										</div>
									</div>

									<div className="field">
										<label className="label">Org Interchange share</label>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="number"
													min="0"
													max="100"
													value={InterchangeShareFormatter.getFormatted(props.orgInterchangeShare)}
													pattern="^[1-9][0-9]?(\.[05])?$|^100$|^0(\.[05])?$"
													step="0.5"
													disabled={props.editMode == EditMode.VIEW}
													onChange={(e) =>
														InterchangeShareFormatter.updateFormatted(
															e.target.value,
															props.setOrgInterchangeShare.bind(props)
														)
													}
												/>
											</div>
											<p className="control">
												<a className="button is-static">%</a>
											</p>
										</div>
									</div>
								</>
							)}

							<div className="field">
								<label htmlFor={"InterestSettings"} className="label">
									Interest
								</label>
								<div className="field-body">
									<div className="select">
										<select
											id={"InterestSettings"}
											required
											value={props.interestSettingsId ?? undefined}
											onChange={(e) => props.setInterestSettingsId(e.target.value)}
											disabled={props.editMode == EditMode.VIEW}
										>
											<option key="0" defaultValue=""></option>
											{props.interestSettings
												.filter((interestSettings) => interestSettings.relationships.bank.data.id === props.bankId)
												.map(({id, attributes: {name}}) => (
													<option key={id} value={id.toString()}>
														{name}
													</option>
												))}
										</select>
									</div>
								</div>
							</div>

							<div className="field">
								<label className="label">Unit Interest</label>
								<div className="field-body">
									<div className="control">
										<input
											className="input"
											type="number"
											min="0"
											value={props.unitInterest}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => props.setUnitInterest(toNumber(e.target.value))}
										/>
									</div>
									<p className="control">
										<a className="button is-static">‱</a>
									</p>
								</div>
							</div>

							<div className="field">
								<label className="label">Card Transaction Funding</label>
								<div className="field-body">
									<div className="select">
										<select
											required
											value={props.cardTransactionFunding}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) => {
												const val = e.target.value
												if (
													val == CardTransactionFunding.AccountOfCard ||
													val == CardTransactionFunding.AccountOfCustomer ||
													val == CardTransactionFunding.AccountOfAnyCustomer
												)
													props.setCardTransactionFunding(val)
											}}
										>
											<option value={CardTransactionFunding.AccountOfCard} key={CardTransactionFunding.AccountOfCard}>
												Only The Card&apos;s Account
											</option>
											<option
												value={CardTransactionFunding.AccountOfCustomer}
												key={CardTransactionFunding.AccountOfCustomer}
											>
												Any Account Of The Customer
											</option>
											<option
												value={CardTransactionFunding.AccountOfAnyCustomer}
												key={CardTransactionFunding.AccountOfAnyCustomer}
											>
												Any Account Of Any Customer
											</option>
										</select>
									</div>
								</div>
							</div>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isSplitInterestDailyEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsSplitInterestDailyEnabled(e.target.checked)}
								/>{" "}
								Enable splitting deposit interest daily
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isUpdatableDepositProduct}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsUpdatableDepositProduct(e.target.checked)}
								/>{" "}
								Enable changing deposit product for accounts
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isWirePaymentEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsWirePaymentEnabled(e.target.checked)}
								/>{" "}
								Enable wire payments
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isBillPaymentEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsBillPaymentEnabled(e.target.checked)}
								/>{" "}
								Enable bill payments
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isCheckDepositEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsCheckDepositEnabled(e.target.checked)}
								/>{" "}
								Enable check deposit
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isPushToCardEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsPushToCardEnabled(e.target.checked)}
								/>{" "}
								Enable Push To Card
							</label>
							{props.isPushToCardEnabled ? (
								<div className="field">
									<label className="label">Org Push To Card Account</label>
									<div className="field-body">
										<div className="select">
											<select
												required
												value={props.pushToCardAccountId}
												disabled={props.editMode == EditMode.VIEW}
												onChange={(e) => props.setPushToCardAccountId(e.target.value)}
											>
												<option value="" />
												{props.pushToCardAccounts?.map((a) => (
													<option key={a.id} value={a.id}>
														{a.attributes.name + " | " + a.id}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							) : null}

							<label className="label">
								<input
									type="checkbox"
									checked={props.isOverdraftEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => {
										props.setIsOverdraftEnabled(e.target.checked)
										if (!e.target.checked) {
											props.setOverdraftReserveAccountId(undefined)
										}
									}}
								/>{" "}
								Enable Overdraft
							</label>
							{props.isOverdraftEnabled ? (
								<div>
									<div className="field">
										<label className="label">Org Overdraft Reserve Account</label>
										<div className="field-body">
											<div className="select">
												<select
													required
													value={props.overdraftReserveAccountId}
													disabled={props.editMode == EditMode.VIEW}
													onChange={(e) => props.setOverdraftReserveAccountId(e.target.value)}
												>
													<option value="" />
													{props.overdraftReserveAccounts?.map((a) => (
														<option key={a.id} value={a.id}>
															{a.attributes.name}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
									<div className="field">
										<label className="label">Reserve Ratio for Overdraft Limit</label>
										<div className="field-body">
											<div className="control">
												<input
													className="input"
													type="number"
													min="1"
													value={toNumber(props.overdraftLimitReserveRatio)}
													disabled={props.editMode == EditMode.VIEW}
													onChange={(e) => props.setOverdraftLimitReserveRatio(toNumber(e.target.value))}
												/>
											</div>
										</div>
									</div>
								</div>
							) : null}

							<label className="label">
								<input
									type="checkbox"
									checked={props.isCreditEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsCreditEnabled(e.target.checked)}
								/>{" "}
								Enable Business Charge Cards
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isDacaEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsDacaEnabled(e.target.checked)}
								/>{" "}
								Enable Daca
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isChargebackEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsChargebackEnabled(e.target.checked)}
								/>{" "}
								Enable Chargeback
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isTrustApplicationsEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsTrustApplicationsEnabled(e.target.checked)}
								/>{" "}
								Enable Trust Applications
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isAutoArchiveAccountsEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsAutoArchiveAccountsEnabled(e.target.checked)}
								/>{" "}
								Enable auto-archiving of customers
							</label>
							{props.isAutoArchiveAccountsEnabled ? (
								<div className="field">
									<label className="label">Auto archive customer after</label>
									<div className="field-body">
										<div className="control">
											<input
												className="input"
												type="number"
												min="0"
												value={toNumber(props.autoArchiveAccountsAfterDays)}
												disabled={props.editMode == EditMode.VIEW}
												onChange={(e) => props.setAutoArchiveAccountsAfterDays(toNumber(e.target.value))}
											/>
										</div>
										<p className="control">
											<a className="button is-static">Days</a>
										</p>
									</div>
								</div>
							) : null}
							<label className="label">
								<input
									type="checkbox"
									checked={props.isLastFourSSNEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsLastFourSSNEnabled(e.target.checked)}
								/>{" "}
								Enable verification with last 4 SSN digits
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.allowCreatingFBOBusinessCustomer}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setAllowCreatingFBOBusinessCustomerEnabled(e.target.checked)}
								/>{" "}
								Enable creation of fbo business customer
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isAchTransactionReturnEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsAchTransactionReturnEnabled(e.target.checked)}
								/>{" "}
								Enable ACH Transaction Return
							</label>

							{increasePilotCustomerLimitsCheckbox}
							<label className="label">
								<input
									type="checkbox"
									checked={props.isSameDayAchEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsSameDayAchEnabled(e.target.checked)}
								/>{" "}
								Enable Same Day ACH
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isEnforceLimitsCustomerLevel}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsEnforceLimitsCustomerLevel(e.target.checked)}
								/>{" "}
								Enforce deposit product limits on the customer level
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isActivationOfPhysicalCardsForOnlineUseEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsActivationOfPhysicalCardsForOnlineUseEnabled(e.target.checked)}
								/>{" "}
								Enable activation of physical cards for online use
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isCashDepositEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsCashDepositEnabled(e.target.checked)}
								/>{" "}
								Enable Cash Deposit
							</label>

							{props.isCashDepositEnabled ? (
								<div className="field">
									<label className="label">Cash Deposit Settlement Account</label>
									<div className="field-body">
										<div className="select">
											<select
												required
												value={props.cashDepositSettlementAccountId}
												disabled={props.editMode == EditMode.VIEW}
												onChange={(e) => props.setCashDepositSettlementAccountId(e.target.value)}
											>
												<option value="" />
												{props.cashDepositSettlementAccounts?.map((a) => (
													<option key={a.id} value={a.id}>
														{a.attributes.name}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							) : null}

							<label className="label">
								<input
									type="checkbox"
									checked={props.allowOrgReleaseAuthHolds}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setAllowOrgReleaseAuthHolds(e.target.checked)}
								/>{" "}
								Allow org to release authorization holds
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.allowInlineAchDebit}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setAllowInlineAchDebit(e.target.checked)}
								/>{" "}
								Enable inline ach debit
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.allowCounterpartyAchDebit}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setAllowCounterpartyAchDebit(e.target.checked)}
								/>{" "}
								Enable counterparty ach debit
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.overrideOriginatedAchSecCodeEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setOverrideOriginatedAchSecCodeEnabled(e.target.checked)}
								/>{" "}
								Allow org to provide a custom SEC code when originating ACH
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isCoverNegativeBalanceOnClose}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsCoverNegativeBalanceOnClose(e.target.checked)}
								/>{" "}
								Cover negative balance on account closure
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isCheckWritingEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => {
										if (!e.target.checked) {
											props.setIsCheckWritingOriginationEnabled(false)
										}
										props.setIsCheckWritingEnabled(e.target.checked)
									}}
								/>{" "}
								Enable Check Writing
							</label>
							<label className="label indented">
								<input
									type="checkbox"
									checked={props.isCheckWritingOriginationEnabled}
									disabled={!props.isCheckWritingEnabled || props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsCheckWritingOriginationEnabled(e.target.checked)}
								/>{" "}
								Enable Check Writing Origination
							</label>

							<label className="label">
								<input
									type="checkbox"
									checked={props.isCardTransactionEnrichmentEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsCardTransactionEnrichmentEnabled(e.target.checked)}
								/>{" "}
								Enable card transaction enrichment
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isReactivatingSuspectedFraudCardsEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsReactivatingSuspectedFraudCardsEnabled(e.target.checked)}
								/>{" "}
								Allow org to reactivate cards from suspected-fraud status
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isCardSchemeFeeEnabled}
									disabled={props.editMode != EditMode.EDIT}
									onChange={(e) => props.setIsCardSchemeFeeEnabled(e.target.checked)}
								/>{" "}
								CardScheme Fee
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.accountCreationBlocked}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setAccountCreationBlocked(e.target.checked)}
								/>{" "}
								Block new account creation
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.authorizedUsersCreationBlocked}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setAuthorizedUsersCreationBlocked(e.target.checked)}
								/>{" "}
								Block authorized users creation
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.financialAccountsSupported}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setFinancialAccountsSupported(e.target.checked)}
								/>{" "}
								Financial accounts supported
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isReprocessReceivedAchDebitEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsReprocessReceivedAchDebitEnabled(e.target.checked)}
								/>{" "}
								Reprocess Received Ach Debit Enabled
							</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isResetPinCardEnabled}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsResetPinCardEnabled(e.target.checked)}
								/>{" "}
								Reset pin for cards enabled
							</label>

							<br />
							<label className="label">Org Level limits</label>
							<label className="label">
								<input
									type="checkbox"
									checked={props.isEnforceDailyACHDebitOrgLevel}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsEnforceDailyACHDebitOrgLevel(e.target.checked)}
								/>{" "}
								Enforce Daily ACH debit limit
							</label>
							{props.isEnforceDailyACHDebitOrgLevel ? (
								<div className="field">
									<div className="field-body">
										<div className="control">
											<input
												className="input"
												type="number"
												min="0"
												value={toNumber(props.dailyACHDebitOrgLevel)}
												disabled={props.editMode == EditMode.VIEW}
												onChange={(e) => props.setDailyACHDebitOrgLevel(toNumber(e.target.value))}
											/>
										</div>{" "}
										<p className="control">
											<a className="button is-static">Cents</a>
										</p>
									</div>
								</div>
							) : null}
							<label className="label">
								<input
									type="checkbox"
									checked={props.isEnforceDailyACHCreditOrgLevel}
									disabled={props.editMode == EditMode.VIEW}
									onChange={(e) => props.setIsEnforceDailyACHCreditOrgLevel(e.target.checked)}
								/>{" "}
								Enforce Daily ACH Credit limit
							</label>
							{props.isEnforceDailyACHCreditOrgLevel ? (
								<div className="field">
									<div className="field-body">
										<div className="control">
											<input
												className="input"
												type="number"
												min="0"
												value={toNumber(props.dailyACHCreditOrgLevel)}
												disabled={props.editMode == EditMode.VIEW}
												onChange={(e) => props.setDailyACHCreditOrgLevel(toNumber(e.target.value))}
											/>
										</div>{" "}
										<p className="control">
											<a className="button is-static">Cents</a>
										</p>
									</div>
								</div>
							) : null}
							<div className="field">
								<label className="label">Sweep Identifier</label>
								<div className="field-body">
									<div className="control">
										<input
											className="input"
											type="number"
											min="1"
											max="999"
											value={props.sweepCode}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) =>
												props.setSweepCode(e.target.value.length === 0 ? undefined : toNumber(e.target.value))
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
