import {ErrorDocument, isErrorDocument} from "../../resources/common"
import React from "react"
import {isError} from "lodash"

export type ErrorMessage = ErrorDocument | Error | string
export interface ErrProps {
	err: ErrorMessage
}

function getErrorMessage(err: ErrorMessage) {
	if (isErrorDocument(err)) {
		return err.errors[0]?.detail || err.errors[0]?.title || err.toString()
	}

	if (isError(err)) {
		return err.message || ""
	}

	return err || ""
}

export function Err({err}: ErrProps) {
	const errorMessage = getErrorMessage(err)

	return (
		<div className="message is-danger">
			<div className="message-body">{errorMessage}</div>
		</div>
	)
}

export function DefaultErr() {
	return <Err err="Something went wrong." />
}
