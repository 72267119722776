import React from "react"
import Card from "../../../containers/Card/Card"
import DepositProductField from "./DepositProductField/DepositProductField"
import {DepositProductGeneralProps} from "./types"
import classNames from "classnames"
import {EditMode} from "../../../resources/common"
import {toInteger} from "lodash"

export default function General({
	editMode,
	values,
	onChange,
	onBlur,
	setFieldValue,
	transactionMonitoringSettings,
	banks,
	bankRoutings,
}: DepositProductGeneralProps) {
	return (
		<Card title="General">
			<div className={classNames("form-row two", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="name"
					label="Name"
					value={values.name}
					onChange={onChange}
					onBlur={onBlur}
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="orgId"
					label="Org Id"
					value={values.orgId}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</div>
			<div className={classNames("form-row two", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="select"
					name="bankId"
					label="Bank"
					value={values.bankId}
					onChange={onChange}
					onBlur={onBlur}
				>
					{banks.map((bank) => (
						<option key={bank.id} value={bank.id.toString()}>
							{bank.attributes.name}
						</option>
					))}
				</DepositProductField>
				<DepositProductField
					mode={editMode}
					type="select"
					name="bankRoutingId"
					label="Routing"
					value={values.bankRoutingId}
					onChange={onChange}
					onBlur={onBlur}
				>
					{bankRoutings
						.filter((bankRouting) => bankRouting.relationships.bank.data.id === values.bankId)
						.map((bankRouting) => (
							<option key={bankRouting.id} value={bankRouting.id.toString()}>
								{bankRouting.attributes.name}
							</option>
						))}
				</DepositProductField>
			</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="orgInterestShare"
					label="Org Interest Share"
					value={values.orgInterestShare}
					onChange={(value) => setFieldValue("orgInterestShare", toInteger(value))}
					inputSuffix={"%"}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="customerInterestShare"
					label="Customer Interest Share"
					value={values.customerInterestShare}
					onChange={(value) => setFieldValue("customerInterestShare", toInteger(value))}
					inputSuffix={"%"}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="unitInterestShare"
					label="Unit Interest Share"
					value={values.unitInterestShare}
					onChange={(value) => setFieldValue("unitInterestShare", toInteger(value))}
					inputSuffix={"%"}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="sponsoredInterestFormula"
					label="Sponsored Interest Formula"
					value={values.sponsoredInterestFormula}
					onChange={(value) => setFieldValue("sponsoredInterestFormula", toInteger(value))}
					inputSuffix={"%"}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="select"
					name="transactionMonitoringSettingsId"
					label="Transaction Monitoring Settings"
					value={values.transactionMonitoringSettingsId}
					onChange={onChange}
					onBlur={onBlur}
				>
					{transactionMonitoringSettings.map((a) => (
						<option key={a.id} value={a.id.toString()}>
							{a.attributes.name}
						</option>
					))}
				</DepositProductField>
			</div>
			<div className={classNames("form-row four checkbox", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="checkbox"
					inputType="input"
					name="isAutoCloseEnabled"
					subName="autoCloseAfter"
					label="Auto close of account"
					subLabel="Auto close after (#days)"
					checked={values.isAutoCloseEnabled}
					value={values.isAutoCloseEnabled}
					subValue={values.autoCloseAfter ?? ""}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</div>
		</Card>
	)
}
