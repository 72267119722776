import React, {useEffect} from "react"
import {
	AchPayment,
	cancelPayment,
	clearAchPayment,
	getPayment,
	getPaymentStatus,
	isAchPayment,
	isBookPayment,
	isWirePayment,
	Payment,
	PaymentStatus,
	PaymentStatusResponse,
	resendWirePayment,
	WirePayment,
	wirePaymentProcessed,
} from "../../resources/payment"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {kebabCase, startCase} from "lodash"
import {isOrg} from "../../services/orgAuth"
import {useModal} from "react-modal-hook"
import {RejectPaymentModal} from "./RejectPayment"
import {ApprovePaymentModal} from "./ApprovePayment"
import PlaidData from "../PlaidData/PlaidData"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {WirePaymentSentModal} from "./WirePaymentSent"
import {AsyncResult, AsyncResultRequestState} from "../../types/asyncResult"
import {Err} from "../Err/Err"
import {UnitAdminOnly, UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {useToasts} from "react-toast-notifications"
import {ConfirmationModal} from "../ConfirmationModal/ConfirmationModal"
import {ClearConfirmationModal, isValidForClearing} from "./ClearAchPaymentHelper"
import classNames from "classnames"
import {DrawerHeader} from "../Drawer/Drawer"
import {LinkMetaBox, LinkMetaBoxContainer} from "../LinkMetaBox/LinkMetaBox"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardPending,
	KeyValueCardValue,
} from "../KeyValueCard/KeyValueCard"
import Icon from "../Icon/Icon"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {CustomerCard} from "../Customers/CustomerCard"
import {NameWithId} from "../Customers/CustomerCardView"
import Payments, {PaymentColumns} from "./Payments"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"
import {Transactions, TransactionsColumns} from "../Transactions/Transactions"
import {Org} from "../../resources/org"
import {Customer, getCustomerName} from "../../resources/customer"
import {BeneficialOwnerCards} from "../BeneficialOwners/BeneficalOwnerCard"
import {Account} from "../../resources/account"
import {AccountCard} from "../Accounts/AccountCard"
import {ReviewReasons} from "../../pages/IncomingWires/IncomingWire"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {NamesAtUnitCard} from "../NamesAtUnit/NamesAtUnit"
import {PaymentDetails} from "../PaymentDetails/PaymentDetails"
import {WirePaymentCounterpartyFields} from "./WirePaymentCounterpartyFields"

interface PaymentComponentProps {
	paymentId: string
	paymentAsyncResult?: AsyncResultRequestState<OkDocument<Payment>, ErrorDocument>
	accessToken: string
	useDrawer: boolean
	refresh: () => void
	statusAsyncResult?: AsyncResultRequestState<PaymentStatusResponse, ErrorDocument>
	isReviewReasonsLayout?: boolean
	org?: Org
	customers?: Customer[]
	account?: Account
}

function PaymentInfoPending() {
	return (
		<div className="payment-content">
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
		</div>
	)
}

export function CancelConfirmationModal({close, onSubmit}: {close: () => void; onSubmit: () => void}) {
	return (
		<ConfirmationModal
			close={close}
			onSubmit={onSubmit}
			title={"Cancel Payment"}
			cancelButtonClassname="button is-white"
			okButtonClassname="button is-danger is-outlined"
		>
			Are you sure you want to cancel this payment?
		</ConfirmationModal>
	)
}

export function ResendConfirmationModal({close, onSubmit}: {close: () => void; onSubmit: () => void}) {
	return (
		<ConfirmationModal
			close={close}
			onSubmit={onSubmit}
			title={"Resend Payment"}
			cancelButtonClassname="button is-white"
			okButtonClassname="button is-success"
		>
			Are you sure you want to resend this payment?
		</ConfirmationModal>
	)
}

export function PaymentButtons({
	payment,
	status,
	refresh,
	accessToken,
}: {
	payment: Payment
	status: PaymentStatusResponse
	refresh: () => void
	accessToken: string
}) {
	const imad = isWirePayment(payment) && (payment.attributes.imadOmad?.imad || payment.attributes?.imad)
	const [showReject, hideReject] = useModal(() => (
		<RejectPaymentModal close={hideReject} payment={payment} onSuccess={refresh} />
	))
	const [showApprove, hideApprove] = useModal(() => (
		<ApprovePaymentModal close={hideApprove} payment={payment} onSuccess={refresh} />
	))
	const [cancelState, cancel] = useAsyncResultIdle(cancelPayment)
	const [resendState, resend] = useAsyncResultIdle(resendWirePayment)

	const [showCancelConfirmationModal, hideCancelConfirmationModal] = useModal(
		() => (
			<CancelConfirmationModal
				close={hideCancelConfirmationModal}
				onSubmit={() => {
					cancel(accessToken, payment.id)
				}}
			/>
		),
		[]
	)

	const [showResendConfirmationModal, hideResendConfirmationModal] = useModal(
		() => (
			<ResendConfirmationModal
				close={hideResendConfirmationModal}
				onSubmit={() => {
					if (imad) {
						resend(accessToken, payment.id, imad)
					}
				}}
			/>
		),
		[]
	)

	const [showWireSent, hideWireSent] = useModal(() => (
		<WirePaymentSentModal close={hideWireSent} payment={payment} onSuccess={refresh} />
	))
	const [showClearingConfirmationModal, hideClearingConfirmationModal] = useModal(
		() => (
			<ClearConfirmationModal
				payment={payment}
				close={hideClearingConfirmationModal}
				onSubmit={() => {
					clear(accessToken, payment.id)
				}}
			/>
		),
		[]
	)

	const [, processWire] = useAsyncResultIdle(wirePaymentProcessed)

	const {addToast} = useToasts()

	const [clearState, clear] = useAsyncResultIdle(clearAchPayment)

	useEffect(() => {
		clearState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(`${e.errors[0].title} - ${e.errors[0].detail}`, {appearance: "error"})
		)
	}, [clearState])

	useEffect(() => {
		cancelState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(`${e.errors[0].title} - ${e.errors[0].detail}`, {appearance: "error"})
		)
	}, [cancelState])

	useEffect(() => {
		resendState.match(
			() => null,
			() => null,
			() => refresh(),
			(e) => addToast(`${e.errors[0].title} - ${e.errors[0].detail}`, {appearance: "error"})
		)
	}, [resendState])

	const canCancelPayment =
		isAchPayment(payment) &&
		(payment.attributes.status == PaymentStatus.Pending || payment.attributes.status == PaymentStatus.PendingReview)

	const canResendPayment =
		isWirePayment(payment) &&
		payment.attributes.status == PaymentStatus.Sent &&
		status.attributes.statusPhase == "WaitingForAcknowledgment"

	return (
		<>
			{canCancelPayment && (
				<button className="button is-danger is-outlined is-right" onClick={() => showCancelConfirmationModal()}>
					<span className="icon">
						<Icon icon="disable-delete--interface-essential" size={12} />
					</span>
					<span> Cancel </span>
				</button>
			)}

			{canResendPayment && (
				<button className="button is-info" onClick={() => showResendConfirmationModal()}>
					<span className="icon">
						<Icon icon="synchronize-arrows-1--interface-essential" size={12} />
					</span>
					<span> Resend </span>
				</button>
			)}

			<HasPermission resource={isWirePayment(payment) ? "payment.wire.manage" : "payment.manage"} action="update">
				{["AwaitingProcessing", "WaitingForNotice"].includes(status.attributes.statusPhase) && (
					<button className="button is-danger" onClick={showReject}>
						<span className="icon">
							<Icon icon="interface-delete-interface-essential" size={12} />
						</span>
						<span> Reject </span>
					</button>
				)}

				{status.attributes.statusPhase == "AwaitingProcessing" && (
					<button
						className="button is-success"
						onClick={() => {
							processWire(accessToken, payment.id).then(() => refresh())
						}}
					>
						<span className="icon">
							<Icon icon="streamline-icon-navigation-arrows-right-2@20x20 (1)" size={12} />
						</span>
						<span> Processed </span>
					</button>
				)}

				{status.attributes.statusPhase == "WaitingForNotice" && (
					<button className="button is-success" onClick={showWireSent}>
						<span className="icon">
							<Icon icon="interface-validation-check--interface-essential" size={12} />
						</span>
						<span> Sent </span>
					</button>
				)}

				{payment.attributes.status == "PendingReview" && (
					<>
						<button className="button is-danger" onClick={() => showReject()}>
							<span className="icon">
								<Icon icon="interface-delete-interface-essential" size={12} />
							</span>
							<span> Reject </span>
						</button>

						<button className="button is-success" onClick={() => showApprove()}>
							<span className="icon">
								<Icon icon="interface-validation-check--interface-essential" size={12} />
							</span>
							<span> Approve </span>
						</button>
					</>
				)}

				{isValidForClearing(payment) && (
					<UnitAdminOnly>
						<button className="button is-success" onClick={() => showClearingConfirmationModal()}>
							<span className="icon">
								<Icon icon="payment-coin" size={12} />
							</span>
							<span> Clear ACH </span>
						</button>
					</UnitAdminOnly>
				)}
			</HasPermission>
		</>
	)
}

export function AchPaymentFields({payment, singleColumn}: {payment: AchPayment; singleColumn?: boolean}) {
	const counterparty = payment.attributes.counterparty.name && payment.attributes.counterparty.name
	const accountType = payment.attributes.counterparty.accountType && payment.attributes.counterparty.accountType
	const accountNumber = payment.attributes.counterparty.accountNumber && payment.attributes.counterparty.accountNumber
	const routingNumber = payment.attributes.counterparty.routingNumber && payment.attributes.counterparty.routingNumber
	const addenda = payment.attributes.addenda && payment.attributes.addenda

	const counterpartyDetails = {
		...(counterparty ? {counterparty} : {}),
		...(accountType ? {accountType} : {}),
		...(routingNumber ? {routingNumber} : {}),
		...(accountNumber ? {accountNumber} : {}),
		...(addenda ? {addenda} : {}),
	}

	return (
		<KeyValueCard title={"Counterparty Details"} singleColumn={singleColumn}>
			<KeyValueCardContainer>
				{Object.entries(counterpartyDetails).map(([key, value]) => {
					return (
						<React.Fragment key={key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

export function PaymentPlaidData({payment}: {payment: AchPayment}) {
	return (
		<UnitUserOnly>
			<div className={"payments-plaid-card"}>
				<div className={"payments-plaid-card-header"}>
					<span> Plaid Details </span>
				</div>
				<PlaidData payment={payment} />
			</div>
		</UnitUserOnly>
	)
}

function getCustomers(payment: Payment) {
	if (payment.relationships.customers && payment.relationships.customers.data.length != 0) {
		return payment.relationships.customers.data
	} else if (payment.relationships.customer) {
		return [payment.relationships.customer.data]
	} else {
		return []
	}
}

interface PaymentInternalProps {
	payment: Payment
	status: PaymentStatusResponse
	accessToken: string
	refresh: () => void
	useDrawer: boolean
	customers?: Customer[]
	org?: Org
	account?: Account
	singleColumn?: boolean
	isReviewReasonsLayout?: boolean
}
function PaymentInternal({
	payment,
	status,
	refresh,
	useDrawer,
	accessToken,
	customers,
	org,
	account,
	singleColumn,
	isReviewReasonsLayout,
}: PaymentInternalProps) {
	const isOrgPayment = isOrg()
	const {attributes} = payment
	const accountId = payment.relationships.account?.data.id || undefined
	const transactionId = payment.relationships.transaction?.data.id || undefined
	const counterpartyAccountId =
		(isBookPayment(payment) && payment.relationships.counterpartyAccount?.data.id) || undefined

	const paymentCustomers = getCustomers(payment)
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()

	const shouldShowContentCardsSideBySide = isReviewReasonsLayout && !useDrawer
	return (
		<>
			{useDrawer && (
				<DrawerHeader title={`Payment #${payment.id}`}>
					<div className={classNames("payments-status", kebabCase(attributes.status))}>
						{startCase(attributes.status)}
					</div>
				</DrawerHeader>
			)}

			<div className={classNames("payment-content", shouldShowContentCardsSideBySide && "pr-3")}>
				<PaymentDetails payment={payment} customers={customers} singleColumn={singleColumn} isUnitUser={isUnitUser} />

				{(isBankUser || isUnitUser) && account && (
					<AccountCard account={account} accessToken={accessToken} singleColumn={singleColumn} />
				)}

				{isUnitUser && !useDrawer && (
					<>
						{customers?.map((customer) => {
							return (
								<React.Fragment key={customer.id}>
									<CustomerCard org={org} customer={customer} customerId={customer.id} singleColumn={singleColumn} />
									{customer.relationships.application && customer.type === "businessCustomer" && (
										<BeneficialOwnerCards
											applicationId={customer.relationships.application?.data.id}
											accessToken={accessToken}
										/>
									)}
								</React.Fragment>
							)
						})}
					</>
				)}

				{isAchPayment(payment) && <AchPaymentFields payment={payment} singleColumn={singleColumn} />}
				{isWirePayment(payment) && (
					<WirePaymentCounterpartyFields
						counterpartyFields={payment.attributes.counterparty}
						singleColumn={singleColumn}
						involvedInstitutions={payment.attributes.involvedInstitutions}
					/>
				)}
				{isAchPayment(payment) && !useDrawer && (
					<UnitUserOnly>
						<>
							{customers?.map((customer, i) => {
								const title =
									customers.length > 1 ? (
										<NameWithId name={`Names at Unit for ${getCustomerName(customer)}`} id={customer.id} />
									) : (
										""
									)
								const titleProps = title ? {title} : {}
								return <NamesAtUnitCard key={`${customer.id}-${i}`} customerId={customer.id} {...titleProps} />
							})}
						</>
					</UnitUserOnly>
				)}

				{useDrawer && (
					<KeyValueCard title={"Related"} singleColumn={singleColumn}>
						<KeyValueCardContainer>
							<LinkMetaBoxContainer>
								<LinkMetaBox
									text={"Account"}
									icon={"bank-account--business-products"}
									path={`/${isOrgPayment ? "org-" : ""}accounts/${accountId}`}
								/>
								{accountId && transactionId && (
									<LinkMetaBox
										text={"Transaction"}
										icon={"money-transfer--business-products"}
										path={`/${isOrgPayment ? "org-" : ""}transaction/${accountId}/${transactionId}`}
									/>
								)}
								{counterpartyAccountId && (
									<LinkMetaBox
										text={"Counterparty"}
										icon={"bank-branch-client"}
										path={`/${isOrgPayment ? "org-" : ""}account/${counterpartyAccountId}`}
									/>
								)}
								{paymentCustomers &&
									paymentCustomers.map((customer) => {
										return (
											<LinkMetaBox
												key={`tag-${customer.id}`}
												text={`Customer #${customer.id}`}
												icon={"user-geometric-action-money-fund---users"}
												path={`/${isOrgPayment ? "org-" : ""}customers/${customer.id}`}
											/>
										)
									})}
							</LinkMetaBoxContainer>
						</KeyValueCardContainer>
					</KeyValueCard>
				)}
				{useDrawer && (
					<div className="drawer-action-buttons buttons is-right">
						<PaymentButtons payment={payment} accessToken={accessToken} refresh={refresh} status={status} />
					</div>
				)}
				{(isUnitUser || isBankUser) && !useDrawer && (
					<>
						<Payments
							enableDirectionFilter={false}
							disableDrawer={false}
							fullHeight={false}
							enableOrgFilter={false}
							enableStatusFilter={false}
							enableDateFilter={false}
							enableTitle={true}
							enableTypeFilter={false}
							enableStatusPhasesFilter={false}
							enableAmountFilter={false}
							limit={5}
							enableExport={false}
							title={`Recent ${startCase(payment.type).replace("Payment", "")} Payments`}
							enablePaging={false}
							accountId={accountId}
							paymentTypes={[payment.type]}
							includedColumns={[
								PaymentColumns.id,
								PaymentColumns.status,
								PaymentColumns.reason,
								PaymentColumns.createdAt,
								PaymentColumns.amount,
							]}
						/>

						<Transactions
							enableAmountFilter={false}
							enableDateFilter={false}
							enableTypeFilter={false}
							enableOrgFilter={false}
							enableDirectionFilter={false}
							isUsingPaging={false}
							fullHeight={false}
							accountId={accountId}
							limit={5}
							enableSearch={false}
							enableExport={false}
							title={"Recent Transactions"}
							token={accessToken}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.type,
								TransactionsColumns.summary,
								TransactionsColumns.createdAt,
								TransactionsColumns.balance,
								TransactionsColumns.amount,
							]}
						/>
					</>
				)}
			</div>
			{shouldShowContentCardsSideBySide && (payment as WirePayment).attributes.pendingReviewReasons && (
				<div className="pl-3">
					<ReviewReasons pendingReviewReasons={(payment as WirePayment).attributes.pendingReviewReasons} />
				</div>
			)}
		</>
	)
}

export function PaymentComponent({
	paymentId,
	accessToken,
	useDrawer,
	refresh,
	paymentAsyncResult,
	statusAsyncResult,
	isReviewReasonsLayout,
	customers,
	org,
	account,
}: PaymentComponentProps) {
	const isUnitUser = useIsUnitUser()
	const paymentAsyncResultReq =
		paymentAsyncResult ||
		useAsyncResult(() => getPayment(accessToken, paymentId, isUnitUser ? "customer,org" : undefined))
	const statusAsyncResultReq = statusAsyncResult || useAsyncResult(() => getPaymentStatus(accessToken, paymentId))

	return AsyncResult.zip(paymentAsyncResultReq, statusAsyncResultReq).match(
		() => <PaymentInfoPending />,
		([payment, status]) => (
			<PaymentInternal
				org={org}
				useDrawer={useDrawer}
				payment={payment.data}
				status={status}
				customers={customers}
				account={account}
				accessToken={accessToken}
				refresh={refresh}
				isReviewReasonsLayout={isReviewReasonsLayout}
			/>
		),
		(err) => <Err err={err} />
	)
}
