import React, {useEffect, useRef, useState} from "react"
import {WirePayment} from "../../resources/payment"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {AssignmentNavigator} from "../../components/AssignmentNavigator/AssignmentNavigator"
import {useQueryState} from "use-location-state"
import {AssignmentPageItemCallback} from "../../components/AssignmentPageItemCallback/AssignmentPageItemCallback"
import {Resource} from "../../resources/common"
import {AssignmentItem, AssignmentItemId, AssignmentStage, AssignmentType} from "../../resources/assignments"
import AssignmentsEmptyStateGraphics from "./AssignmentsEmptyStateGraphics"
import {Customer} from "../../resources/customer"
import {AssignmentPageItemCallbackReview} from "../../components/AssignmentPageItemCallbackReview/AssignmentPageItemCallbackReview"
import {AssignmentPageItemOFAC} from "../../components/AssignmentPageItemOFAC/AssignmentPageItemOFAC"
import {AssignmentPayload} from "./Assignments"
import {Claims} from "../../services/auth"
import {AssignmentPageItemGeneralReview} from "../../components/AssignmentPageItemGeneralReview/AssignmentPageItemGeneralReview"
import {Account, isDepositAccount} from "../../resources/account"

interface AssignmentsViewProps {
	resource: Resource
	included?: Resource[]
	payload?: AssignmentPayload
	assignments: AssignmentItem[]
	accessToken: string
	refresh: () => void
	claims?: Claims
}

export const getAssignmentIsDisabled = (stage?: AssignmentStage, isOwnersItem?: boolean) =>
	stage === AssignmentStage.Completed || !isOwnersItem

const getPaymentCustomerAndBusinessApplication = (
	resource: Resource,
	included?: Resource[],
	payload?: AssignmentPayload
) => {
	const customer = included?.find((resource) =>
		["individualCustomer", "businessCustomer", "businessFBOCustomer", "individualWalletCustomer"].includes(
			resource.type
		)
	) as Customer
	const businessApplication = payload?.businessApplication
	return {customer, businessApplication}
}

const AssignmentPageItemView = ({
	currentAssignmentItem,
	resource,
	included,
	payload,
	accessToken,
	refresh,
	claims,
}: {
	currentAssignmentItem: AssignmentItem
	resource: Resource
	included?: Resource[]
	payload?: AssignmentPayload
	accessToken: string
	refresh: () => void
	claims?: Claims
}) => {
	if (!currentAssignmentItem) {
		return <div>No assignment</div>
	}
	const commonProps = {
		disabled: getAssignmentIsDisabled(currentAssignmentItem.stage, currentAssignmentItem.isOwnersItem),
		accessToken,
		refresh,
	}
	switch (currentAssignmentItem.type) {
		case AssignmentType.callbackAssignment: {
			const {customer, businessApplication} = getPaymentCustomerAndBusinessApplication(resource, included, payload)
			return (
				<AssignmentPageItemCallback
					{...currentAssignmentItem}
					{...commonProps}
					wire={resource as WirePayment}
					customer={customer}
					businessApplication={businessApplication}
					claims={claims}
				/>
			)
		}
		case AssignmentType.callbackReviewAssignment: {
			const {customer, businessApplication} = getPaymentCustomerAndBusinessApplication(resource, included, payload)
			return (
				<AssignmentPageItemCallbackReview
					{...currentAssignmentItem}
					{...commonProps}
					wire={resource as WirePayment}
					customer={customer}
					businessApplication={businessApplication}
					claims={claims}
				/>
			)
		}
		case AssignmentType.ofacCheckAssignment: {
			return <AssignmentPageItemOFAC {...currentAssignmentItem} {...commonProps} />
		}
		case AssignmentType.generalReviewAssignment: {
			const {customer, businessApplication} = getPaymentCustomerAndBusinessApplication(resource, included, payload)
			const account = included && (included.find((i) => isDepositAccount(i)) as Account)
			return (
				<AssignmentPageItemGeneralReview
					{...currentAssignmentItem}
					{...commonProps}
					wire={resource as WirePayment}
					account={account}
					customer={customer}
					businessApplication={businessApplication}
					claims={claims}
				/>
			)
		}
		default:
			return <div>Unknown Assignment Type</div>
	}
}

export default function AssignmentsView({
	resource,
	included,
	payload,
	assignments,
	accessToken,
	refresh,
	claims,
}: AssignmentsViewProps) {
	const [currentAssignmentItemQuery, setCurrentAssignmentItemQuery] = useQueryState<AssignmentItemId | "">(
		"assignmentId",
		""
	)
	const [currentAssignmentItem, setCurrentAssignmentItem] = useState<AssignmentItem | undefined>()
	useEffect(() => {
		let defaultItemId: AssignmentItemId | undefined = currentAssignmentItemQuery
		if (!currentAssignmentItemQuery) {
			const firstEnabledOwnersItem = assignments?.find(
				({stage, isOwnersItem}) => isOwnersItem && !getAssignmentIsDisabled(stage, true)
			)
			const firstPendingItem = assignments?.find(({stage}) => stage === AssignmentStage.Pending)
			defaultItemId = (firstEnabledOwnersItem ?? firstPendingItem ?? assignments?.[0])?.id
			setCurrentAssignmentItemQuery(defaultItemId)
		}
		const defaultAssignmentItemByQuery = assignments?.find((assignment) => assignment.id === defaultItemId) ?? undefined
		setCurrentAssignmentItem(defaultAssignmentItemByQuery)
	}, [])
	const navigatorRef = useRef<HTMLDivElement>(null)
	const onClick = (assignmentItemId: AssignmentItemId) => {
		setCurrentAssignmentItemQuery(assignmentItemId)
		const item = assignments?.find((assignment) => assignment.id === assignmentItemId)
		if (!item) {
			console.error("Assignment not found")
			return
		}
		setCurrentAssignmentItem(item)
	}
	return (
		<div className="assignments-container">
			<GridContainer columns={2} rows={1} gap="0" className="assignments-grid has-full-height">
				<div className="assignments-navigator-container scrollable-container" ref={navigatorRef}>
					<AssignmentNavigator
						assignments={assignments ?? []}
						groupBy="stage"
						selectedId={currentAssignmentItem?.id}
						onClick={onClick}
						navigatorRef={navigatorRef}
					/>
				</div>
				<div className="scrollable-container">
					{currentAssignmentItem ? (
						<AssignmentPageItemView
							currentAssignmentItem={currentAssignmentItem}
							resource={resource}
							included={included}
							payload={payload}
							accessToken={accessToken}
							refresh={refresh}
							claims={claims}
						/>
					) : (
						<div className="assignments-empty-state">
							<AssignmentsEmptyStateGraphics />
							<span>No items to review</span>
						</div>
					)}
				</div>
			</GridContainer>
		</div>
	)
}
