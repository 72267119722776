import {getFile, getResource} from "./common"
import {startCase} from "lodash"

export enum TransactionTypes {
	ATM = "atmTransaction",
	Adjustment = "adjustmentTransaction",
	BillPay = "billPayTransaction",
	Book = "bookTransaction",
	PushToCard = "pushToCardTransaction",
	Card = "cardTransaction",
	CardReversal = "cardReversalTransaction",
	CardSchemeFee = "cardSchemeFeeTransaction",
	Chargeback = "chargebackTransaction",
	CheckDeposit = "checkDepositTransaction",
	DishonoredACH = "dishonoredAchTransaction",
	Dispute = "disputeTransaction",
	DisputeSettlement = "disputeSettlementTransaction",
	Fee = "feeTransaction",
	InterchangeShare = "interchangeShareTransaction",
	Interest = "interestTransaction",
	InterestShare = "interestShareTransaction",
	NegativeBalanceCoverage = "negativeBalanceCoverageTransaction",
	OriginatedACH = "originatedAchTransaction",
	PaymentAdvance = "paymentAdvanceTransaction",
	PaymentCanceled = "paymentCanceledTransaction",
	Purchase = "purchaseTransaction",
	ReceivedACH = "receivedAchTransaction",
	Release = "releaseTransaction",
	RepaidPaymentAdvance = "repaidPaymentAdvanceTransaction",
	ReturnedACH = "returnedAchTransaction",
	ReturnedCheckDeposit = "returnedCheckDepositTransaction",
	ReturnedReceivedACH = "returnedReceivedAchTransaction",
	Reward = "rewardTransaction",
	Settlement = "settlementTransaction",
	SponsoredInterest = "sponsoredInterestTransaction",
	Wire = "wireTransaction",
	ReturnedWire = "returnedWireTransaction",
	AccountLowBalanceClosure = "accountLowBalanceClosureTransaction",
	CashDeposit = "cashDepositTransaction",
	BankRepayment = "bankRepaymentTransaction",
	CustomerRepayment = "CustomerRepaymentTransaction",
	CustomerRepaymentReturned = "CustomerRepaymentReturnedTransaction",
	CheckPayment = "checkPaymentTransaction",
	ReturnedCheckPayment = "returnedCheckPaymentTransaction",
	TieredInterchangePayoutTransaction = "tieredInterchangePayoutTransaction",
	SweepTransaction = "sweepTransaction",
	PushToCardReversal = "pushToCardReversalTransaction",
}

export type OriginatedAch = {
	type: TransactionTypes.OriginatedACH
	id: string
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		description: string
		counterparty: AchCounterparty
		tags?: {[key: string]: string}
		secCode?: string
		traceNumber?: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		payment?: {
			data: {
				type: "payment"
				id: number
			}
		}
	}
}

export type ReceivedAch = {
	type: TransactionTypes.ReceivedACH
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		description: string
		companyName: string
		counterpartyRoutingNumber: string
		secCode: string
		traceNumber: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		incomingAch?: {
			data: {
				type: string
				id: number
			}
		}
		payment?: {
			data: {
				type: "payment"
				id: number
			}
		}
	}
}

export type ReturnedAch = {
	type: TransactionTypes.ReturnedACH
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		companyName: string
		counterpartyName: string
		counterpartyRoutingNumber: string
		reason: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		payment?: {
			data: {
				type: "payment"
				id: number
			}
		}
	}
}

export type ReturnedReceivedAch = {
	type: TransactionTypes.ReturnedReceivedACH
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		companyName: string
		counterpartyName: string
		counterpartyRoutingNumber: string
		reason: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		returned: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type DishonoredAch = {
	type: TransactionTypes.DishonoredACH
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		description: string
		summary: string
		companyName: string
		counterpartyName: string
		counterpartyRoutingNumber: string
		traceNumber: string
		returnReason: string
		secCode: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Book = {
	type: TransactionTypes.Book
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		counterparty: AchCounterparty
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		counterpartyAccount?: {
			data: {
				type: "account"
				id: string
			}
		}
		counterpartyCustomer?: {
			data: {
				type: "customer"
				id: string
			}
		}
		payment?: {
			data: {
				type: "payment"
				id: number
			}
		}
	}
}

export type Purchase = {
	type: TransactionTypes.Purchase
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		merchant: {
			name: string
			type: string
			category: string
			location?: string
		}
		interchange?: number | null
		grossInterchange?: number | null
		networkTransactionId?: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Atm = {
	type: TransactionTypes.ATM
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		atmName: string
		atmLocation?: string
		interchange?: number | null
		grossInterchange?: number | null
		networkTransactionId?: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type InterchangeShare = {
	type: TransactionTypes.InterchangeShare
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		interchangeShare: Cents
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Fee = {
	type: TransactionTypes.Fee
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		relatedTransaction?: {
			data: {
				type: string
				id: number
			}
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Reversal = {
	type: TransactionTypes.CardReversal
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		networkTransactionId?: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		relatedTransaction?: {
			data: {
				type: string
				id: number
			}
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type CardTransaction = {
	type: TransactionTypes.Card
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		interchange?: number | null
		grossInterchange?: number | null
		networkTransactionId?: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Release = {
	type: TransactionTypes.Release
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Wire = {
	type: TransactionTypes.Wire
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		imadOmad?: {
			omad: string
			imad: string
		}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type ReturnedWire = {
	type: TransactionTypes.ReturnedWire
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		imadOmad?: {
			omad: string
			imad: string
		}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Dispute = {
	type: TransactionTypes.Dispute
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Interest = {
	type: TransactionTypes.Interest
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type SponsoredInterest = {
	type: TransactionTypes.SponsoredInterest
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type InterestShare = {
	type: TransactionTypes.InterestShare
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Adjustment = {
	type: TransactionTypes.Adjustment
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type DisputeSettlement = {
	type: TransactionTypes.DisputeSettlement
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Settlement = {
	type: TransactionTypes.Settlement
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type CheckDeposit = {
	type: TransactionTypes.CheckDeposit
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type ReturnedCheckDeposit = {
	type: TransactionTypes.ReturnedCheckDeposit
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type PaymentCanceled = {
	type: TransactionTypes.PaymentCanceled
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type BillPay = {
	type: TransactionTypes.BillPay
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Chargeback = {
	type: TransactionTypes.Chargeback
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		counterparty: AchCounterparty
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		chargeback: {
			data: {
				type: string
				id: number
			}
		}
		receiverAccount: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Reward = {
	type: TransactionTypes.Reward
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		counterparty: AchCounterparty
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		reward: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type NegativeBalanceCoverage = {
	type: TransactionTypes.NegativeBalanceCoverage
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type AccountLowBalanceClosure = {
	type: TransactionTypes.AccountLowBalanceClosure
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type PaymentAdvance = {
	type: TransactionTypes.PaymentAdvance
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		receivedPayment?: {
			data: {
				type: "receivedPayment"
				id: number
			}
		}
	}
}

export type CustomerRepayment = {
	type: TransactionTypes.CustomerRepayment
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		payment?: {
			data: {
				type: "payment"
				id: number
			}
		}
	}
}

export type BankRepayment = {
	type: TransactionTypes.BankRepayment
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		paidForDate: string
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		receivingAccount: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type CustomerRepaymentReturned = {
	type: TransactionTypes.CustomerRepaymentReturned
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		payment?: {
			data: {
				type: "payment"
				id: number
			}
		}
	}
}

export type RepaidPaymentAdvance = {
	type: TransactionTypes.RepaidPaymentAdvance
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		receivedPayment?: {
			data: {
				type: "receivedPayment"
				id: number
			}
		}
		paymentAdvanceTransaction?: {
			data: {
				type: "paymentAdvanceTransaction"
				id: number
			}
		}
	}
}

export type CashDepositTransaction = {
	type: TransactionTypes.CashDeposit
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type CheckPayment = {
	type: TransactionTypes.CheckPayment
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		checkPayment: {
			data: {
				type: string
				id: number
			}
		}
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: number
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type ReturnedCheckPayment = {
	type: TransactionTypes.ReturnedCheckPayment
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		tags?: {[key: string]: string}
		reason: string
		returnReason: string
	}
	relationships: {
		checkPayment: {
			data: {
				type: string
				id: number
			}
		}
		returned: {
			data: {
				type: string
				id: number
			}
		}
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: number
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type SweepPayment = {
	type: TransactionTypes.SweepTransaction
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		description: string
		fileName: string
		summary: string
		tags?: {[key: string]: string}
		reason: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: number
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type PushToCardReversal = {
	type: TransactionTypes.PushToCardReversal
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		description: string
		fileName: string
		summary: string
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: number
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
		originalTransaction: {
			data: {
				type: string
				id: number
			}
		}
		payment: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type CardSchemeFee = {
	type: TransactionTypes.CardSchemeFee
	id: number
	attributes: {
		createdAt: Date
		amount: Cents
		direction: Direction
		balance: Cents
		summary: string
		day: string
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: string
				id: number
			}
		}
	}
}

export type Transaction =
	| OriginatedAch
	| ReceivedAch
	| ReturnedAch
	| ReturnedReceivedAch
	| DishonoredAch
	| Book
	| Purchase
	| Atm
	| InterchangeShare
	| Fee
	| Reversal
	| CardTransaction
	| Release
	| Wire
	| ReturnedWire
	| Dispute
	| Interest
	| SponsoredInterest
	| InterestShare
	| Adjustment
	| DisputeSettlement
	| Settlement
	| CheckDeposit
	| ReturnedCheckDeposit
	| PaymentCanceled
	| BillPay
	| PaymentAdvance
	| RepaidPaymentAdvance
	| Chargeback
	| Reward
	| NegativeBalanceCoverage
	| AccountLowBalanceClosure
	| CashDepositTransaction
	| BankRepayment
	| CustomerRepayment
	| CustomerRepaymentReturned
	| CheckPayment
	| ReturnedCheckPayment
	| SweepPayment
	| CardSchemeFee
	| PushToCardReversal

type DisputableTransaction =
	| OriginatedAch
	| ReceivedAch
	| ReturnedAch
	| ReturnedReceivedAch
	| CardTransaction
	| Atm
	| Purchase

export function isDisputableTransaction(transaction: Transaction): transaction is DisputableTransaction {
	return (
		transaction.type === TransactionTypes.OriginatedACH ||
		transaction.type === TransactionTypes.ReceivedACH ||
		transaction.type === TransactionTypes.ReturnedACH ||
		transaction.type === TransactionTypes.ReturnedReceivedACH ||
		transaction.type === TransactionTypes.Card ||
		transaction.type === TransactionTypes.ATM ||
		transaction.type === TransactionTypes.Purchase
	)
}

export type Cents = number

export enum Direction {
	Debit = "Debit",
	Credit = "Credit",
}

export type AchCounterparty = {
	name: string
	accountNumber: string
	routingNumber: string
	accountType: AccountType
}

export type AccountType = "Savings" | "Checking"

export type PaymentTransaction = OriginatedAch | ReturnedAch | Book | ReceivedAch

export async function getTransaction<Transaction>(accessToken: string, accountId: string, id: string) {
	const result = await getResource<Transaction>(`transactions/${id}`, accessToken, {
		filter: {
			accountId,
		},
	})

	return result.map((v) => v.data)
}

export async function getTransactionWithInclude<Transaction>(accessToken: string, accountId: string, id: string) {
	return await getResource<Transaction>(`transactions/${id}`, accessToken, {
		filter: {
			accountId,
		},
		include: "customer,payment",
	})
}

interface TransactionGetRequestParam {
	accessToken: string
	offset: number
	limit?: number
	searchQuery?: string
	since?: string
	until?: string
	excludeGlTransaction?: boolean
	types?: string[]
	accountId?: string | null
	customerId?: string | null
	requestType: "json" | "csv"
	include?: "customer,org" | "" | "org"
	direction?: Direction[]
	orgs?: string[]
	sort: string
	fromAmount?: Cents | ""
	toAmount?: Cents | ""
}

export async function getTransactionsRequest(requestParam: TransactionGetRequestParam) {
	const query = {
		page: {
			limit: requestParam.limit,
			offset: requestParam.offset,
		},
		filter: {
			query: requestParam.searchQuery != "" ? requestParam.searchQuery : null,
			since: requestParam.since != "" ? requestParam.since : null,
			until: requestParam.until != "" ? requestParam.until : null,
			accountId: requestParam.accountId != "" ? requestParam.accountId : null,
			customerId: requestParam.customerId != "" ? requestParam.customerId : null,
			excludeGlTransaction: requestParam.excludeGlTransaction ? requestParam.excludeGlTransaction : null,
			type: requestParam.types && requestParam.types.length > 0 ? requestParam.types : null,
			direction: requestParam.direction && requestParam.direction.length > 0 ? requestParam.direction : null,
			orgs: requestParam.orgs && requestParam.orgs.length > 0 ? requestParam.orgs : null,
			fromAmount: requestParam.fromAmount != "" ? requestParam.fromAmount : null,
			toAmount: requestParam.toAmount != "" ? requestParam.toAmount : null,
		},
		sort: requestParam.sort,
		include: requestParam.include != "" ? requestParam.include : null,
		dashboard: true,
	}

	switch (requestParam.requestType) {
		case "csv":
			return await getFile("transactions", requestParam.accessToken, query)
		default:
			return await getResource<Array<Transaction>>("transactions", requestParam.accessToken, query)
	}
}

export function isReceivedAch(transaction: Transaction): transaction is ReceivedAch {
	return transaction.type === TransactionTypes.ReceivedACH
}

export function isOriginatedAch(transaction: Transaction): transaction is OriginatedAch {
	return transaction.type === TransactionTypes.OriginatedACH
}

export function isReturnedAch(transaction: Transaction): transaction is ReturnedAch {
	return transaction.type === TransactionTypes.ReturnedACH
}

export function isPaymentTransaction(transaction: Transaction): transaction is PaymentTransaction {
	return isOriginatedAch(transaction) || isBook(transaction) || isReceivedAch(transaction) || isReturnedAch(transaction)
}

export function isCheckPaymentTransaction(transaction: Transaction): transaction is CheckPayment {
	return transaction.type === TransactionTypes.CheckPayment
}

export function isPurchase(transaction: Transaction): transaction is Purchase {
	return transaction.type === TransactionTypes.Purchase
}

export function isAtm(transaction: Transaction): transaction is Atm {
	return transaction.type === TransactionTypes.ATM
}

export function isCardReversal(transaction: Transaction): transaction is Reversal {
	return transaction.type === TransactionTypes.CardReversal
}

export function isCardTransaction(transaction: Transaction): transaction is CardTransaction {
	return transaction.type === TransactionTypes.Card
}

export function isCard(transaction: Transaction): transaction is Purchase | Atm | Reversal | CardTransaction {
	return isPurchase(transaction) || isAtm(transaction) || isCardReversal(transaction) || isCardTransaction(transaction)
}

export function isFee(transaction: Transaction): transaction is Fee {
	return transaction.type === "feeTransaction"
}

export function isBook(transaction: Transaction): transaction is Book {
	return transaction.type === "bookTransaction"
}

export function isWire(transaction: Transaction): transaction is Wire {
	return transaction.type === TransactionTypes.Wire
}

export function prettyPrintType(transaction: Transaction) {
	return startCase(transaction.type.replace("Transaction", "")).replace("Ach", "ACH").replace("Atm", "ATM")
}
