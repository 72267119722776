import React from "react"
import classNames from "classnames"
import {DataTableCell} from "./DataTable"
import {ActionButton, ActionButtonProps, ActionItem, ActionItemProps} from "../ActionButton/ActionButton"

export function DataTableActionButton({enableActions, children}: ActionButtonProps) {
	return (
		<DataTableCell className={classNames("data-table-action", "has-text-centered", "small-width")}>
			<ActionButton enableActions={enableActions}> {children}</ActionButton>
		</DataTableCell>
	)
}

export function DataTableActionItem({onClick, icon, title}: ActionItemProps) {
	return <ActionItem title={title} icon={icon} onClick={onClick} />
}
