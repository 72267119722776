import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	ApplicationFormVersion,
	isApplicationFormV2,
	previewApplicationForm,
	PreviewApplicationFormPrefill,
} from "../../resources/applicationForm"
import React, {useEffect, useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import {isLocalEnv, isSandboxEnv} from "../../utilities/environment"

interface Props {
	close: () => void
	accessToken: string
}

export function ApplicationFormPreviewModal({close, accessToken}: Props) {
	const versionSwitchVisible = isLocalEnv() || isSandboxEnv()
	const [previewState, preview] = useAsyncResultIdle(previewApplicationForm)
	const [previewPrefillType, setPreviewPrefillType] = useState<PreviewApplicationFormPrefill>("NoPrefill")
	const [version, setVersion] = useState<ApplicationFormVersion>("v1")
	const [whiteLabelThemeId, setWhiteLabelThemeId] = useState<string>("")
	const [lendingProgramId, setLendingProgramId] = useState<string>("")

	useEffect(() => {
		if (previewState.isOk()) {
			if (isApplicationFormV2(previewState.value)) {
				window.open(previewState.value.links.related.href, "_blank")
			} else {
				window.open(previewState.value.attributes.url, "_blank")
			}
		}
	}, [previewState])

	return (
		<AsyncResultModal
			title="Preview Application Form"
			close={close}
			state={previewState}
			onSubmit={() =>
				preview({accessToken, prefillType: previewPrefillType, version, whiteLabelThemeId, lendingProgramId})
			}
			onSuccess={() => close()}
			successText="Preview"
			buttonText="Preview"
			buttonClassname="button is-info"
			errorToText={(err) => (err.errors ? err.errors[0].title : "An unexpected error has occurred")}
		>
			<div className="control" style={{display: versionSwitchVisible ? "" : "none"}}>
				{"Version: "}
				<select value={version} onChange={(e) => setVersion(e.target.value as "v1" | "v2")}>
					<option key="v1" value="v1">
						v1
					</option>
					<option key="v2" value="v2">
						v2
					</option>
				</select>
			</div>
			<div className="control">
				{"Form Prefill: "}
				<select
					data-test={"prefill-select"}
					value={previewPrefillType}
					onChange={(e) => setPreviewPrefillType(e.target.value as PreviewApplicationFormPrefill)}
				>
					<option key="NoPrefill" value="NoPrefill">
						No Prefill
					</option>
					<option key="PrefillIndividual" value="PrefillIndividual">
						Prefill Individual
					</option>
					<option key="PrefillSoleProp" value="PrefillSoleProp">
						Prefill Sole Prop
					</option>
					{version === "v2" ? (
						<>
							<option key="PrefillSingleMemberBusiness" value="PrefillSingleMemberBusiness">
								Prefill Single - Member Business
							</option>
							<option key="PrefillMultipleMemberBusiness" value="PrefillMultipleMemberBusiness">
								Prefill Multiple - Member Business
							</option>
						</>
					) : (
						<option key="PrefillBusiness" value="PrefillBusiness">
							Prefill Business
						</option>
					)}
				</select>
			</div>
			{version === "v2" && (
				<>
					<div className="control">
						{"White-Label Theme ID: "}
						<input
							type="text"
							pattern="^\d+$"
							value={whiteLabelThemeId}
							onChange={(e) => setWhiteLabelThemeId(e.target.value)}
						/>
					</div>
					<div className="control">
						{"Lending Program ID: "}
						<input
							type="text"
							pattern="^\d+$"
							value={lendingProgramId}
							onChange={(e) => setLendingProgramId(e.target.value)}
						/>
					</div>
				</>
			)}

			<div></div>
			<div className="has-text-grey-light mt-2">Note: Please make sure all changes are saved before preview</div>
		</AsyncResultModal>
	)
}
