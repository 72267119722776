import {useAccessToken, useIsUnitUser} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import Loading from "../../components/Loading/Loading"
import React, {useEffect, useState} from "react"
import TitleBar, {TitleBarAction} from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {createResource, EditMode, ErrorDocument} from "../../resources/common"
import {useToasts} from "react-toast-notifications"
import {useParams} from "react-router"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEdit, faWindowClose} from "@fortawesome/free-solid-svg-icons"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {OrgBankAgreementAttributes} from "./OrgBankAgreementAttributes"
import {
	CardTransactionFunding,
	getAgreement,
	OrgBankAgreement,
	updateOrgBankAgreement,
} from "../../resources/orgBankAgreement"
import {
	DepositAccount,
	getOverdraftReserveAccounts,
	getReserveAccounts,
	getRevenueAccounts,
	getAccountsWithPurpose,
	getSponsoredAccounts,
	getOrgGeneralLedgerAccounts,
	OrgGeneralLedgerAccount,
	getOrgLoanAccounts,
	OrgLoanAccount,
} from "../../resources/account"
import {getCapitalPartnerOrgs, getOrg, Org} from "../../resources/org"
import {Status} from "../../resources/depositProduct"
import {useModal} from "react-modal-hook"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import ReactTooltip from "react-tooltip"
import BodyPortal from "../../components/BodyPortal/BodyPortal"
import {ToggleOrgBankAgreementModal} from "../../components/OrgBankAgreements/ToggleOrgBankAgreementModal"
import {findInterestSettings, InterestSettings} from "../../resources/interestSettings"

function Footer({state}: {state: AsyncResultIdleRequestState<OrgBankAgreement, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Update</button>
				),
				() => (
					<button className="button is-success is-loading">Updating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].title}</div>
					</>
				)
			)}
		</div>
	)
}

function Page({
	agreement,
	org,
	interestSettings,
	refresh,
}: {
	agreement: OrgBankAgreement
	org: Org
	interestSettings: Array<InterestSettings>
	refresh: () => void
}) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const unitOrgId = "1"

	const [state, update] = useAsyncResultIdle(updateOrgBankAgreement)
	const [id] = useState(agreement.id)
	const [name] = useState(agreement.attributes.name)
	const [bankId, setBankId] = useState<string>(agreement.relationships.bank.data.id)
	const [orgId, setOrgId] = useState<string>(org.id)
	const [orgInterchangeShare, setOrgInterchangeShare] = useState<number>(agreement.attributes.orgInterchangeShare)
	const [bankInterchangeShare, setBankInterchangeShare] = useState<number>(agreement.attributes.bankInterchangeShare)
	const [unitInterest, setUnitInterest] = useState<number>(agreement.attributes.unitInterest)
	const [isUpdatableDepositProduct, setIsUpdatableDepositProduct] = useState<boolean>(
		agreement.attributes.isUpdatableDepositProduct
	)
	const [isWirePaymentEnabled, setIsWirePaymentEnabled] = useState<boolean>(agreement.attributes.isWirePaymentEnabled)
	const [isBillPaymentEnabled, setIsBillPaymentEnabled] = useState<boolean>(agreement.attributes.isBillPaymentEnabled)
	const [isCheckDepositEnabled, setIsCheckDepositEnabled] = useState<boolean>(
		agreement.attributes.isCheckDepositEnabled
	)
	const [isOverdraftEnabled, setIsOverdraftEnabled] = useState<boolean>(agreement.attributes.isOverdraftEnabled)
	const [isPushToCardEnabled, setIsPushToCardEnabled] = useState<boolean>(agreement.attributes.isPushToCardEnabled)
	const [isCreditEnabled, setIsCreditEnabled] = useState<boolean>(agreement.attributes.isCreditEnabled)
	const [revenueAccountId, setRevenueAccountId] = useState<string>(agreement.relationships.revenueAccount.data.id)
	const [reserveAccountId, setReserveAccountId] = useState<string>(agreement.relationships.reserveAccount.data.id)
	const [sponsoredInterestAccountId, setSponsoredInterestAccountId] = useState<string | undefined>(
		agreement.relationships.sponsoredInterestAccount?.data.id
	)
	const [overdraftReserveAccountId, setOverdraftReserveAccountId] = useState<string | undefined>(
		agreement.relationships.overdraftReserveAccount?.data.id
	)
	const [pushToCardAccountId, setPushToCardAccountId] = useState<string | undefined>(
		agreement.relationships.pushToCardOperationalAccount?.data.id
	)
	const [provisionalCreditOperatingAccountId, setProvisionalCreditOperatingAccountId] = useState<string | undefined>(
		agreement.relationships.provisionalCreditOperatingAccount?.data.id
	)
	const [provisionalCreditReserveAccountId, setProvisionalCreditReserveAccountId] = useState<string | undefined>(
		agreement.relationships.provisionalCreditReserveAccount?.data.id
	)
	const [isDacaEnabled, setIsDacaEnabled] = useState<boolean>(agreement.attributes.isDacaEnabled)
	const [cardTransactionFunding, setCardTransactionFunding] = useState<CardTransactionFunding>(
		agreement.attributes.cardTransactionFunding
	)
	const [isChargebackEnabled, setIsChargebackEnabled] = useState<boolean>(agreement.attributes.isChargebackEnabled)
	const [isTrustApplicationsEnabled, setIsTrustApplicationsEnabled] = useState<boolean>(
		agreement.attributes.isTrustApplicationsEnabled
	)
	const [isAutoArchiveAccountsEnabled, setIsAutoArchiveAccountsEnabled] = useState<boolean>(
		agreement.attributes.isAutoArchiveAccountsEnabled
	)
	const [autoArchiveAccountsAfterDays, setAutoArchiveAccountsAfterDays] = useState<number>(
		agreement.attributes.autoArchiveAccountsAfterDays
	)
	const [isAchTransactionReturnEnabled, setIsAchTransactionReturnEnabled] = useState<boolean>(
		agreement.attributes.isAchTransactionReturnEnabled
	)
	const [isIncreasePilotCustomerLimits, setIsIncreasePilotCustomerLimits] = useState<boolean>(
		agreement.attributes.isIncreasePilotCustomerLimits
	)
	const [isSameDayAchEnabled, setIsSameDayAchEnabled] = useState<boolean>(agreement.attributes.isSameDayAchEnabled)
	const [isEnforceLimitsCustomerLevel, setIsEnforceLimitsCustomerLevel] = useState<boolean>(
		agreement.attributes.isEnforceLimitsCustomerLevel
	)
	const [isActivationOfPhysicalCardsForOnlineUseEnabled, setIsActivationOfPhysicalCardsForOnlineUseEnabled] =
		useState<boolean>(agreement.attributes.isActivationOfPhysicalCardsForOnlineUseEnabled)
	const [isCashDepositEnabled, setIsCashDepositEnabled] = useState<boolean>(agreement.attributes.isCashDepositEnabled)
	const [cashDepositSettlementAccountId, setCashDepositSettlementAccountId] = useState<string | undefined>(
		agreement.relationships.cashDepositSettlementAccount?.data.id
	)
	const [allowOrgReleaseAuthHolds, setAllowOrgReleaseAuthHolds] = useState<boolean>(
		agreement.attributes.allowOrgReleaseAuthHolds
	)
	const [allowInlineAchDebit, setAllowInlineAchDebit] = useState<boolean>(agreement.attributes.allowInlineAchDebit)
	const [allowCounterpartyAchDebit, setAllowCounterpartyAchDebit] = useState<boolean>(
		agreement.attributes.allowCounterpartyAchDebit
	)
	const [isEnforceDailyACHDebitOrgLevel, setIsEnforceDailyACHDebitOrgLevel] = useState<boolean>(
		agreement.attributes.isEnforceDailyACHDebitOrgLevel
	)
	const [dailyACHDebitOrgLevel, setDailyACHDebitOrgLevel] = useState<number | undefined>(
		agreement.attributes.dailyACHDebitOrgLevel
	)
	const [overrideOriginatedAchSecCodeEnabled, setOverrideOriginatedAchSecCodeEnabled] = useState<boolean>(
		agreement.attributes.overrideOriginatedAchSecCodeEnabled
	)
	const [isCoverNegativeBalanceOnClose, setIsCoverNegativeBalanceOnClose] = useState<boolean>(
		agreement.attributes.isCoverNegativeBalanceOnClose
	)
	const [isCheckWritingEnabled, setIsCheckWritingEnabled] = useState<boolean>(
		agreement.attributes.isCheckWritingEnabled
	)
	const [isCheckWritingOriginationEnabled, setIsCheckWritingOriginationEnabled] = useState<boolean>(
		agreement.attributes.isCheckWritingOriginationEnabled
	)

	const [isReactivatingSuspectedFraudCardsEnabled, setIsReactivatingSuspectedFraudCardsEnabled] = useState<boolean>(
		agreement.attributes.isReactivatingSuspectedFraudCardsEnabled
	)

	const [isSplitInterestDailyEnabled, setIsSplitInterestDailyEnabled] = useState<boolean>(
		agreement.attributes.isSplitInterestDailyEnabled
	)

	const [isCardTransactionEnrichmentEnabled, setIsCardTransactionEnrichmentEnabled] = useState<boolean>(
		agreement.attributes.isCardTransactionEnrichmentEnabled
	)

	const [isEnforceDailyACHCreditOrgLevel, setIsEnforceDailyACHCreditOrgLevel] = useState<boolean>(
		agreement.attributes.isEnforceDailyACHCreditOrgLevel
	)
	const [dailyACHCreditOrgLevel, setDailyACHCreditOrgLevel] = useState<number | undefined>(
		agreement.attributes.dailyACHCreditOrgLevel
	)
	const [minimumReserveBalance, setMinimumReserveBalance] = useState<number | undefined>(
		agreement.attributes.reserveAccountSettings?.minimumReserveBalance
	)

	const [isCardSchemeFeeEnabled, SetIsCardSchemeFeeEnabled] = useState<boolean>(
		agreement.attributes.isCardSchemeFeeEnabled
	)

	const [sweepCode, setSweepCode] = useState<number | undefined>(agreement.attributes.sweepCode)
	const [isLastFourSSNEnabled, setIsLastFourSSNEnabled] = useState<boolean>(agreement.attributes.isLastFourSSNEnabled)
	const [allowCreatingFBOBusinessCustomer, setAllowCreatingFBOBusinessCustomerEnabled] = useState<boolean>(
		agreement.attributes.allowCreatingFBOBusinessCustomer
	)

	const [revenueAccounts, setRevenueAccounts] = useState<DepositAccount[]>([])
	const [reserveAccounts, setReserveAccounts] = useState<DepositAccount[]>([])
	const [sponsoredInterestAccounts, setSponsoredInterestAccounts] = useState<DepositAccount[]>([])
	const [overdraftReserveAccounts, setOverdraftReserveAccounts] = useState<DepositAccount[]>([])
	const [pushToCardAccounts, setPushToCardAccounts] = useState<DepositAccount[]>([])
	const [capitalPartnerOrgs, setCapitalPartnerOrgs] = useState<Org[]>([])
	const [orgLoanAccounts, setOrgLoanAccounts] = useState<OrgLoanAccount[]>([])
	const [orgGeneralLedgerAccounts, setOrgGeneralAccounts] = useState<OrgGeneralLedgerAccount[]>([])
	const [creditOperationalAccounts, setCreditOperationalAccounts] = useState<DepositAccount[]>([])
	const [provisionalCreditOperatingAccounts, setProvisionalCreditOperatingAccounts] = useState<DepositAccount[]>([])
	const [provisionalCreditReserveAccounts, setProvisionalCreditReserveAccounts] = useState<DepositAccount[]>([])
	const [cashDepositSettlementAccounts, setCashDepositSettlementAccounts] = useState<DepositAccount[]>([])
	const [interestSettingsId, setInterestSettingsId] = useState<string>(
		agreement.relationships.interestSettings.data.id ?? ""
	)
	const [overdraftLimitReserveRatio, setOverdraftLimitReserveRatio] = useState<number | undefined>(
		agreement.attributes.overdraftLimitReserveRatio
	)
	const [accountCreationBlocked, setAccountCreationBlocked] = useState<boolean>(
		agreement.attributes.accountCreationBlocked
	)
	const [authorizedUsersCreationBlocked, setAuthorizedUsersCreationBlocked] = useState<boolean>(
		agreement.attributes.authorizedUsersCreationBlocked
	)

	const [financialAccountsSupported, setFinancialAccountsSupported] = useState<boolean>(
		agreement.attributes.financialAccountsSupported
	)

	const [isReprocessReceivedAchDebitEnabled, setIsReprocessReceivedAchDebitEnabled] = useState<boolean>(
		agreement.attributes.isReprocessReceivedAchDebitEnabled
	)

	const [isResetPinCardEnabled, setIsResetPinCardEnabled] = useState<boolean>(
		agreement.attributes.isResetPinCardEnabled
	)

	const [, getRevenueAccountsFromState] = useAsyncResultIdle((bankId) => getRevenueAccounts(accessToken, bankId))
	const [, getReserveAccountsFromState] = useAsyncResultIdle((bankId) => getReserveAccounts(accessToken, bankId))
	const [, getSponsoredInterestAccountsFromState] = useAsyncResultIdle((bankId) =>
		getSponsoredAccounts(accessToken, bankId)
	)
	const [, getOverdraftReserveAccountsFromState] = useAsyncResultIdle((bankId) =>
		getOverdraftReserveAccounts(accessToken, bankId)
	)
	const [, getProvisionalCreditOperatingAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getAccountsWithPurpose(accessToken, bankId, orgId, "credit_operating")
	)
	const [, getProvisionalCreditReserveAccountsFromState] = useAsyncResultIdle((bankId) =>
		getAccountsWithPurpose(accessToken, bankId, "1", "credit_reserve")
	)
	const [, getCashDepositSettlementAccountsFromState] = useAsyncResultIdle((bankId) =>
		getAccountsWithPurpose(accessToken, bankId, unitOrgId, "cash_deposit_settlement")
	)
	const [, getCreditOperationalAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getAccountsWithPurpose(accessToken, bankId, orgId, "credit_operational_account")
	)
	const [, getCapitalPartnerOrgsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getCapitalPartnerOrgs(accessToken, bankId, orgId)
	)
	const [, getPushToCardAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getAccountsWithPurpose(accessToken, bankId, orgId, "push_to_card_operational_account")
	)
	const [, getOrgGeneralAccountsFromState] = useAsyncResultIdle((bankId, orgId) =>
		getOrgGeneralLedgerAccounts(accessToken, bankId, orgId)
	)
	const [, getOrgLoanAccountsFromState] = useAsyncResultIdle((bankId) => getOrgLoanAccounts(accessToken, bankId, orgId))

	const [isEnabled] = useState(agreement.attributes.status == Status.Enabled)

	const [isEditing, setIsEditing] = useState(false)

	const [showToggleOrgBankAgreementModal, hideToggleOrgBankAgreement] = useModal(() => (
		<ToggleOrgBankAgreementModal
			orgBankAgreement={agreement}
			disable={isEnabled}
			close={hideToggleOrgBankAgreement}
			onSuccess={refresh}
		/>
	))

	useEffect(() => {
		if (bankId !== "") {
			resolveRevenueAccounts(bankId)
			resolveReserveAccounts(bankId)
			resolveSponsoredInterestAccounts(bankId)
			resolveOverdraftReserveAccounts(bankId)
			resolveProvisionalCreditReserveAccounts(bankId)
			resolveOrgLoanAccounts(bankId)
			resolveCashDepositSettlementAccounts(bankId)
		}
	}, [bankId])

	useEffect(() => {
		if (bankId !== "" && orgId !== "") {
			resolveProvisionalCreditOperatingAccounts(bankId, orgId)
			resolveCreditOperationalAccounts(bankId, orgId)
			resolvePushToCardAccounts(bankId, orgId)
			resolveOrgGeneralLedgerAccounts(bankId, orgId)
			resolveCapitalPartnerOrgs(bankId, orgId)
		}
	}, [bankId, orgId])

	async function resolveRevenueAccounts(bankId: string) {
		setRevenueAccounts(
			(await getRevenueAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveReserveAccounts(bankId: string) {
		setReserveAccounts(
			(await getReserveAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveSponsoredInterestAccounts(bankId: string) {
		setSponsoredInterestAccounts(
			(await getSponsoredInterestAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveOverdraftReserveAccounts(bankId: string) {
		setOverdraftReserveAccounts(
			(await getOverdraftReserveAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveProvisionalCreditOperatingAccounts(bankId: string, orgId: string) {
		setProvisionalCreditOperatingAccounts(
			(await getProvisionalCreditOperatingAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveOrgGeneralLedgerAccounts(bankId: string, orgId: string) {
		setOrgGeneralAccounts(
			(await getOrgGeneralAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveCreditOperationalAccounts(bankId: string, orgId: string) {
		setCreditOperationalAccounts(
			(await getCreditOperationalAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveCapitalPartnerOrgs(bankId: string, orgId: string) {
		setCapitalPartnerOrgs(
			(await getCapitalPartnerOrgsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolvePushToCardAccounts(bankId: string, orgId: string) {
		setPushToCardAccounts(
			(await getPushToCardAccountsFromState(bankId, orgId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveProvisionalCreditReserveAccounts(bankId: string) {
		setProvisionalCreditReserveAccounts(
			(await getProvisionalCreditReserveAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	async function resolveCashDepositSettlementAccounts(bankId: string) {
		setReserveAccounts(
			(await getCashDepositSettlementAccountsFromState(bankId)).match(
				(result) => {
					setCashDepositSettlementAccounts(result)
					return result
				},
				() => []
			)
		)
	}

	async function resolveOrgLoanAccounts(bankId: string) {
		setOrgLoanAccounts(
			(await getOrgLoanAccountsFromState(bankId)).match(
				(result) => {
					return result
				},
				() => []
			)
		)
	}

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Updated Successfully", {appearance: "success"})
			setIsEditing(false)
			refresh()
		}
	}, [state])

	const submit = () => {
		update(
			accessToken,
			id,
			bankId,
			orgId,
			revenueAccountId,
			reserveAccountId,
			sponsoredInterestAccountId,
			provisionalCreditOperatingAccountId,
			provisionalCreditReserveAccountId,
			cashDepositSettlementAccountId,
			name,
			bankInterchangeShare,
			orgInterchangeShare,
			unitInterest,
			isUpdatableDepositProduct,
			isWirePaymentEnabled,
			isBillPaymentEnabled,
			isCheckDepositEnabled,
			cardTransactionFunding,
			isOverdraftEnabled,
			isPushToCardEnabled,
			isCreditEnabled,
			interestSettingsId,
			overdraftReserveAccountId,
			pushToCardAccountId,
			isDacaEnabled,
			isChargebackEnabled,
			isTrustApplicationsEnabled,
			isAutoArchiveAccountsEnabled,
			autoArchiveAccountsAfterDays,
			isAchTransactionReturnEnabled,
			isIncreasePilotCustomerLimits,
			isSameDayAchEnabled,
			isEnforceLimitsCustomerLevel,
			isActivationOfPhysicalCardsForOnlineUseEnabled,
			isCashDepositEnabled,
			allowOrgReleaseAuthHolds,
			allowInlineAchDebit,
			allowCounterpartyAchDebit,
			isEnforceDailyACHDebitOrgLevel,
			dailyACHDebitOrgLevel,
			overrideOriginatedAchSecCodeEnabled,
			isCoverNegativeBalanceOnClose,
			isEnforceDailyACHCreditOrgLevel,
			dailyACHCreditOrgLevel,
			isCheckWritingEnabled,
			isCardTransactionEnrichmentEnabled,
			isReactivatingSuspectedFraudCardsEnabled,
			isSplitInterestDailyEnabled,
			minimumReserveBalance,
			sweepCode,
			isCardSchemeFeeEnabled,
			isLastFourSSNEnabled,
			allowCreatingFBOBusinessCustomer,
			isCheckWritingOriginationEnabled,
			overdraftLimitReserveRatio,
			accountCreationBlocked,
			authorizedUsersCreationBlocked,
			financialAccountsSupported,
			isReprocessReceivedAchDebitEnabled,
			isResetPinCardEnabled
		)
	}

	const disabled = state.match(
		() => false,
		() => true,
		(_) => true,
		(_) => false
	)

	const isUnitUser = useIsUnitUser()

	const editButton: JSX.Element = !isEnabled ? (
		<button className="button" disabled onClick={() => (!isEditing ? setIsEditing(true) : refresh())}>
			<div data-tip={true} data-iscapture={true} data-for={"cannot-edit-when-disabled"}>
				<span>{isEditing ? "Cancel" : "Edit"}</span>
				<span className="icon">
					<FontAwesomeIcon icon={isEditing ? faWindowClose : faEdit} />
				</span>
			</div>
		</button>
	) : (
		<button className="button is-light" onClick={() => (!isEditing ? setIsEditing(true) : refresh())}>
			<span>{isEditing ? "Cancel" : "Edit"}</span>
			<span className="icon">
				<FontAwesomeIcon icon={isEditing ? faWindowClose : faEdit} />
			</span>
		</button>
	)

	return (
		<>
			<TitleBar title={agreement.attributes.name} />
			<MainSection>
				<div className="columns org-bank-agreement-page">
					<div className="column">
						<div className="buttons">
							<div>
								<Back />
								{isUnitUser ? editButton : null}
							</div>
							<TitleBarAction>
								{isUnitUser && (
									<ActionButton enableActions={true}>
										<ActionItem
											data-tip
											data-iscapture={true}
											data-for={isEnabled ? "disableOrgBankAgreement" : "enableOrgBankAgreement"}
											title={isEnabled ? "Disable" : "Enable"}
											icon={isEnabled ? "disable-delete--interface-essential" : "approve-v-check-validation"}
											onClick={showToggleOrgBankAgreementModal}
										/>
									</ActionButton>
								)}
							</TitleBarAction>
						</div>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						submit()
					}}
				>
					<fieldset disabled={disabled}>
						<OrgBankAgreementAttributes
							editMode={isEditing ? EditMode.EDIT : EditMode.VIEW}
							name={name}
							bankId={bankId}
							setBankId={setBankId}
							orgId={orgId}
							setOrgId={setOrgId}
							org={org}
							bankInterchangeShare={bankInterchangeShare}
							setBankInterchangeShare={setBankInterchangeShare}
							orgInterchangeShare={orgInterchangeShare}
							setOrgInterchangeShare={setOrgInterchangeShare}
							revenueAccountId={revenueAccountId}
							setRevenueAccountId={setRevenueAccountId}
							reserveAccountId={reserveAccountId}
							setReserveAccountId={setReserveAccountId}
							sponsoredInterestAccountId={sponsoredInterestAccountId}
							setSponsoredInterestAccountId={setSponsoredInterestAccountId}
							provisionalCreditOperatingAccountId={provisionalCreditOperatingAccountId}
							setProvisionalCreditOperatingAccountId={setProvisionalCreditOperatingAccountId}
							provisionalCreditReserveAccountId={provisionalCreditReserveAccountId}
							setProvisionalCreditReserveAccountId={setProvisionalCreditReserveAccountId}
							cashDepositSettlementAccountId={cashDepositSettlementAccountId}
							setCashDepositSettlementAccountId={setCashDepositSettlementAccountId}
							unitInterest={unitInterest}
							setUnitInterest={setUnitInterest}
							isUpdatableDepositProduct={isUpdatableDepositProduct}
							setIsUpdatableDepositProduct={setIsUpdatableDepositProduct}
							isWirePaymentEnabled={isWirePaymentEnabled}
							setIsWirePaymentEnabled={setIsWirePaymentEnabled}
							revenueAccounts={revenueAccounts}
							reserveAccounts={reserveAccounts}
							sponsoredInterestAccounts={sponsoredInterestAccounts}
							overdraftReserveAccounts={overdraftReserveAccounts}
							pushToCardAccounts={pushToCardAccounts}
							capitalPartnerOrgs={capitalPartnerOrgs}
							orgGeneralLedgerAccounts={orgGeneralLedgerAccounts}
							orgLoanAccounts={orgLoanAccounts}
							creditOperationalAccounts={creditOperationalAccounts}
							provisionalCreditOperatingAccounts={provisionalCreditOperatingAccounts}
							provisionalCreditReserveAccounts={provisionalCreditReserveAccounts}
							cashDepositSettlementAccounts={cashDepositSettlementAccounts}
							isBillPaymentEnabled={isBillPaymentEnabled}
							setIsBillPaymentEnabled={setIsBillPaymentEnabled}
							isCheckDepositEnabled={isCheckDepositEnabled}
							setIsCheckDepositEnabled={setIsCheckDepositEnabled}
							isOverdraftEnabled={isOverdraftEnabled}
							isPushToCardEnabled={isPushToCardEnabled}
							setIsOverdraftEnabled={setIsOverdraftEnabled}
							setIsPushToCardEnabled={setIsPushToCardEnabled}
							overdraftReserveAccountId={overdraftReserveAccountId}
							pushToCardAccountId={pushToCardAccountId}
							setOverdraftReserveAccountId={setOverdraftReserveAccountId}
							setPushToCardAccountId={setPushToCardAccountId}
							isCreditEnabled={isCreditEnabled}
							setIsCreditEnabled={setIsCreditEnabled}
							isDacaEnabled={isDacaEnabled}
							setIsDacaEnabled={setIsDacaEnabled}
							cardTransactionFunding={cardTransactionFunding}
							setCardTransactionFunding={setCardTransactionFunding}
							isChargebackEnabled={isChargebackEnabled}
							setIsChargebackEnabled={setIsChargebackEnabled}
							isTrustApplicationsEnabled={isTrustApplicationsEnabled}
							setIsTrustApplicationsEnabled={setIsTrustApplicationsEnabled}
							setIsAutoArchiveAccountsEnabled={setIsAutoArchiveAccountsEnabled}
							isAutoArchiveAccountsEnabled={isAutoArchiveAccountsEnabled}
							setAutoArchiveAccountsAfterDays={setAutoArchiveAccountsAfterDays}
							autoArchiveAccountsAfterDays={autoArchiveAccountsAfterDays}
							isAchTransactionReturnEnabled={isAchTransactionReturnEnabled}
							setIsAchTransactionReturnEnabled={setIsAchTransactionReturnEnabled}
							isIncreasePilotCustomerLimits={isIncreasePilotCustomerLimits}
							setIsIncreasePilotCustomerLimits={setIsIncreasePilotCustomerLimits}
							isSameDayAchEnabled={isSameDayAchEnabled}
							setIsSameDayAchEnabled={setIsSameDayAchEnabled}
							isEnforceLimitsCustomerLevel={isEnforceLimitsCustomerLevel}
							setIsEnforceLimitsCustomerLevel={setIsEnforceLimitsCustomerLevel}
							isActivationOfPhysicalCardsForOnlineUseEnabled={isActivationOfPhysicalCardsForOnlineUseEnabled}
							setIsActivationOfPhysicalCardsForOnlineUseEnabled={setIsActivationOfPhysicalCardsForOnlineUseEnabled}
							isCashDepositEnabled={isCashDepositEnabled}
							setIsCashDepositEnabled={setIsCashDepositEnabled}
							allowOrgReleaseAuthHolds={allowOrgReleaseAuthHolds}
							setAllowOrgReleaseAuthHolds={setAllowOrgReleaseAuthHolds}
							allowInlineAchDebit={allowInlineAchDebit}
							setAllowInlineAchDebit={setAllowInlineAchDebit}
							allowCounterpartyAchDebit={allowCounterpartyAchDebit}
							setAllowCounterpartyAchDebit={setAllowCounterpartyAchDebit}
							isEnforceDailyACHDebitOrgLevel={isEnforceDailyACHDebitOrgLevel}
							setIsEnforceDailyACHDebitOrgLevel={setIsEnforceDailyACHDebitOrgLevel}
							dailyACHDebitOrgLevel={dailyACHDebitOrgLevel}
							setDailyACHDebitOrgLevel={setDailyACHDebitOrgLevel}
							overrideOriginatedAchSecCodeEnabled={overrideOriginatedAchSecCodeEnabled}
							setOverrideOriginatedAchSecCodeEnabled={setOverrideOriginatedAchSecCodeEnabled}
							isCoverNegativeBalanceOnClose={isCoverNegativeBalanceOnClose}
							setIsCoverNegativeBalanceOnClose={setIsCoverNegativeBalanceOnClose}
							isEnforceDailyACHCreditOrgLevel={isEnforceDailyACHCreditOrgLevel}
							setIsEnforceDailyACHCreditOrgLevel={setIsEnforceDailyACHCreditOrgLevel}
							dailyACHCreditOrgLevel={dailyACHCreditOrgLevel}
							setDailyACHCreditOrgLevel={setDailyACHCreditOrgLevel}
							isCheckWritingEnabled={isCheckWritingEnabled}
							setIsCheckWritingEnabled={setIsCheckWritingEnabled}
							isCardTransactionEnrichmentEnabled={isCardTransactionEnrichmentEnabled}
							setIsCardTransactionEnrichmentEnabled={setIsCardTransactionEnrichmentEnabled}
							isReactivatingSuspectedFraudCardsEnabled={isReactivatingSuspectedFraudCardsEnabled}
							setIsReactivatingSuspectedFraudCardsEnabled={setIsReactivatingSuspectedFraudCardsEnabled}
							isSplitInterestDailyEnabled={isSplitInterestDailyEnabled}
							setIsSplitInterestDailyEnabled={setIsSplitInterestDailyEnabled}
							minimumReserveBalance={minimumReserveBalance}
							setMinimumReserveBalance={setMinimumReserveBalance}
							sweepCode={sweepCode}
							setSweepCode={setSweepCode}
							isCardSchemeFeeEnabled={isCardSchemeFeeEnabled}
							setIsCardSchemeFeeEnabled={SetIsCardSchemeFeeEnabled}
							interestSettingsId={interestSettingsId}
							setInterestSettingsId={setInterestSettingsId}
							interestSettings={interestSettings}
							setIsLastFourSSNEnabled={setIsLastFourSSNEnabled}
							isLastFourSSNEnabled={isLastFourSSNEnabled}
							allowCreatingFBOBusinessCustomer={allowCreatingFBOBusinessCustomer}
							setAllowCreatingFBOBusinessCustomerEnabled={setAllowCreatingFBOBusinessCustomerEnabled}
							isCheckWritingOriginationEnabled={isCheckWritingOriginationEnabled}
							setIsCheckWritingOriginationEnabled={setIsCheckWritingOriginationEnabled}
							overdraftLimitReserveRatio={overdraftLimitReserveRatio}
							setOverdraftLimitReserveRatio={setOverdraftLimitReserveRatio}
							accountCreationBlocked={accountCreationBlocked}
							setAccountCreationBlocked={setAccountCreationBlocked}
							authorizedUsersCreationBlocked={authorizedUsersCreationBlocked}
							setAuthorizedUsersCreationBlocked={setAuthorizedUsersCreationBlocked}
							financialAccountsSupported={financialAccountsSupported}
							setFinancialAccountsSupported={setFinancialAccountsSupported}
							isReprocessReceivedAchDebitEnabled={isReprocessReceivedAchDebitEnabled}
							setIsReprocessReceivedAchDebitEnabled={setIsReprocessReceivedAchDebitEnabled}
							isResetPinCardEnabled={isResetPinCardEnabled}
							setIsResetPinCardEnabled={setIsResetPinCardEnabled}
						/>

						{isEditing ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<Footer state={state} />
									</div>
								</div>
							</div>
						) : null}
					</fieldset>
				</form>

				<BodyPortal>
					<ReactTooltip
						arrowColor="#FFF"
						place="bottom"
						type="light"
						effect="solid"
						id={"cannot-edit-when-disabled"}
						className="account-button-tooltip"
					>
						<p> {`Editing isn't possible when Bank Agreement is disabled`}</p>
					</ReactTooltip>
				</BodyPortal>
			</MainSection>
		</>
	)
}

function FetchOrg({
	agreement,
	refresh,
	refreshToken,
}: {
	agreement: OrgBankAgreement
	refresh: () => void
	refreshToken: number
}) {
	const accessToken = useAccessToken()
	const org = useAsyncResult(() => getOrg(accessToken, agreement.relationships.org.data.id), [refreshToken])
	return org.match(
		() => <Loading />,
		(org) => <FetchInterestSettings agreement={agreement} org={org} refresh={refresh} refreshToken={refreshToken} />,
		(_) => null
	)
}

function FetchInterestSettings({
	agreement,
	org,
	refresh,
	refreshToken,
}: {
	org: Org
	agreement: OrgBankAgreement
	refresh: () => void
	refreshToken: number
}) {
	const accessToken = useAccessToken()
	const interestSettings = useAsyncResult(() => findInterestSettings(accessToken, 0, 1000), [refreshToken])
	return interestSettings.match(
		() => <Loading />,
		(interestSettings) => (
			<Page agreement={agreement} org={org} interestSettings={interestSettings.data} refresh={refresh} />
		),
		(_) => null
	)
}

export default function OrgBankAgreementPage() {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const {id = ""} = useParams<{id: string}>()

	const agreement = useAsyncResult(() => getAgreement(accessToken, id), [refreshToken])

	return agreement.match(
		() => <Loading />,
		(agreement) => <FetchOrg agreement={agreement} refresh={refresh} refreshToken={refreshToken} />,
		(_) => null
	)
}

export async function disableOrgBankAgreement(accessToken: string, agreement: OrgBankAgreement) {
	return await createResource<OrgBankAgreement>(`org-bank-agreements/${agreement.id}/disable`, accessToken, {})
}

export async function enableOrgBankAgreement(accessToken: string, agreement: OrgBankAgreement) {
	return await createResource<OrgBankAgreement>(`org-bank-agreements/${agreement.id}/enable`, accessToken, {})
}
