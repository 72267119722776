import Card from "../../containers/Card/Card"
import ReactJson from "react-json-view"
import React, {useEffect} from "react"
import {
	getIncomingAch,
	IncomingAch,
	IncomingAchErrorReason,
	IncomingAchManualReviewInfo,
	IncomingAchStatus,
	reprocessIncomingAch,
} from "../../resources/incomingAch"
import Icon from "../Icon/Icon"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import {useModal} from "react-modal-hook"
import {ApproveIncomingAchModal} from "./ApproveIncomingAch"
import {DenyIncomingAchModal} from "./DenyIncomingAch"
import {AddAliasIncomingAchModal} from "./AddAliasIncomingAch"
import {ReturnErroredIncomingAchModal} from "./ReturnErroredIncomingAch"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToggle} from "react-use"
import {useToasts} from "react-toast-notifications"
import {useLocation, useNavigate} from "react-router-dom"
import {ErrorDocument, OkDocument, Resource} from "../../resources/common"
import {useAccessToken} from "../../services/auth"
import {Bank} from "../../resources/bank"
import {isDepositOrFBOAccount} from "../../resources/account"
import moment from "moment"
import {Org} from "../../resources/org"
import {toLongDollars} from "../../utilities/numbers"
import {NamesAtUnitCard} from "../NamesAtUnit/NamesAtUnit"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardPending,
	KeyValueCardValue,
} from "../KeyValueCard/KeyValueCard"
import {startCase} from "lodash"
import {AccountCard} from "../Accounts/AccountCard"
import {CustomerCard} from "../Customers/CustomerCard"
import {Transactions, TransactionsColumns} from "../Transactions/Transactions"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Err} from "../Err/Err"
import {MissingReturnPaymentIncomingAch} from "./MissingReturnPaymentIncomingAch"

interface IncomingAchComponentProps {
	incomingAchId: string
	incomingAchAsyncResult?: AsyncResultRequestState<OkDocument<IncomingAch>, ErrorDocument>
	accessToken: string
}

function IncomingAchPending() {
	return (
		<div className="payment-content">
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
		</div>
	)
}

export function IncomingAchComponent({incomingAchId, incomingAchAsyncResult, accessToken}: IncomingAchComponentProps) {
	const incomingAchAsyncResultReq =
		incomingAchAsyncResult || useAsyncResult(() => getIncomingAch(accessToken, incomingAchId))

	return incomingAchAsyncResultReq.match(
		() => <IncomingAchPending />,
		(incomingAch) => <IncomingAchInternal value={incomingAch.data} included={incomingAch.included as Resource[]} />,
		(err) => <Err err={err} />
	)
}

function IncomingAchInternal({value: ach, included}: {value: IncomingAch; included: Resource[]}) {
	const accessToken = useAccessToken()
	const {attributes} = ach
	const bank = included?.find((v) => v.type == "bank") as Bank
	const depositAccount = included?.find((v) => v.type == "depositAccount")
	const customerId = `${ach.relationships.customer?.data.id || ""}`
	const createdAt = moment(attributes.createdAt).format("L")
	const org = included.find((resource) => resource.type === "org") as Org

	const paymentDetails = {
		amount: toLongDollars(ach.attributes.item.amount),
		createdAt: createdAt,
		org: org?.attributes?.name || "",
		direction: ach.attributes.item.direction,
		bankName: bank?.attributes?.name || "",
		fileName: ach.attributes.fileName,
		isReturn: ach.attributes.item.isReturn,
		...(ach.attributes.reasonCode ? {reason: ach.attributes.reasonCode} : {}),
	}

	return (
		<>
			{customerId && <NamesAtUnitCard customerId={customerId} />}

			<KeyValueCard title={"Payment Details"}>
				<KeyValueCardContainer>
					{Object.entries(paymentDetails).map(([key, value]) => {
						return (
							<React.Fragment key={`payment-details-${key}`}>
								<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
								<KeyValueCardValue> {value.toString()} </KeyValueCardValue>
							</React.Fragment>
						)
					})}
				</KeyValueCardContainer>
			</KeyValueCard>
			{depositAccount && isDepositOrFBOAccount(depositAccount) && (
				<AccountCard account={depositAccount} accessToken={accessToken} />
			)}

			{ach.relationships.customer?.data.id && (
				<CustomerCard customerId={ach.relationships.customer.data.id.toString()} />
			)}
			<Transactions
				enableAmountFilter={false}
				enableDateFilter={false}
				enableTypeFilter={false}
				enableOrgFilter={false}
				enableDirectionFilter={false}
				isUsingPaging={false}
				fullHeight={false}
				accountId={`${ach.relationships.account?.data.id}`}
				limit={5}
				enableSearch={false}
				enableExport={false}
				title={"Recent Transactions"}
				token={accessToken}
				includedColumns={[
					TransactionsColumns.id,
					TransactionsColumns.type,
					TransactionsColumns.summary,
					TransactionsColumns.createdAt,
					TransactionsColumns.balance,
					TransactionsColumns.amount,
				]}
			/>
		</>
	)
}

export function IncomingAchItemData({rawData}: {rawData: object}) {
	return (
		<div className={"incoming-ach-details"}>
			<Card title={"ACH Item"}>
				<ReactJson src={rawData} displayDataTypes={false} />
			</Card>
		</div>
	)
}

export function IncomingAchButtons({
	incomingAch,
	refresh,
	accessToken,
}: {
	incomingAch: IncomingAch
	refresh: () => void
	accessToken: string
}) {
	const canApprove =
		incomingAch.attributes.status === IncomingAchStatus.PendingReview ||
		incomingAch.attributes.status === IncomingAchStatus.MarkedForReturn ||
		incomingAch.attributes.status == IncomingAchStatus.Returned
	const canDeny =
		incomingAch.attributes.status === IncomingAchStatus.PendingReview ||
		incomingAch.attributes.status === IncomingAchStatus.Processed ||
		incomingAch.attributes.status == IncomingAchStatus.Errored
	const canAddAlias = incomingAch.attributes.manualReviewInfo == IncomingAchManualReviewInfo.NameMismatch
	const isCustomerId = incomingAch.relationships?.customer?.data.id || undefined
	const canReprocess = incomingAch.attributes.status == IncomingAchStatus.Errored
	const isReturnPaymentNotFound = incomingAch.attributes.errorReason == IncomingAchErrorReason.ReturnPaymentNotFound
	const canReturnErrored = incomingAch.attributes.status == IncomingAchStatus.Errored
	const navigate = useNavigate()
	const location = useLocation()
	const {addToast} = useToasts()

	const [showApprove, hideApprove] = useModal(() => (
		<ApproveIncomingAchModal
			close={hideApprove}
			incomingAch={incomingAch}
			onSuccess={refresh}
			addToast={addToast}
			navigate={navigate}
			location={location}
		/>
	))
	const [showReject, hideReject] = useModal(() => (
		<DenyIncomingAchModal
			close={hideReject}
			incomingAch={incomingAch}
			onSuccess={refresh}
			addToast={addToast}
			navigate={navigate}
			location={location}
		/>
	))
	const [showAddAlias, hideAddAlias] = useModal(() => (
		<AddAliasIncomingAchModal
			close={hideAddAlias}
			incomingAch={incomingAch}
			onSuccess={refresh}
			addToast={addToast}
			navigate={navigate}
			location={location}
		/>
	))

	const [showReturnErrored, hideReturnErrored] = useModal(() => (
		<ReturnErroredIncomingAchModal
			close={hideReturnErrored}
			incomingAch={incomingAch}
			onSuccess={refresh}
			addToast={addToast}
			navigate={navigate}
			location={location}
		/>
	))

	const [showMissingReturnPaymentModal, hideMissingReturnPaymentModal] = useModal(() => (
		<MissingReturnPaymentIncomingAch
			close={hideMissingReturnPaymentModal}
			incomingAch={incomingAch}
			onSuccess={refresh}
		/>
	))

	const [reprocessState, setReprocessState] = useAsyncResultIdle(reprocessIncomingAch)
	const [isReprocessActive, setIsReprocessActive] = useToggle(false)

	useEffect(() => {
		reprocessState.match(
			() => null,
			() => setIsReprocessActive(true),
			() => refresh(),
			(e) => addToast(e.errors[0].title, {appearance: "error"})
		)
	}, [reprocessState])

	return (
		<>
			<button
				className="button is-danger"
				disabled={!canDeny}
				onClick={() => {
					if (canReturnErrored) {
						showReturnErrored()
					} else {
						showReject()
					}
				}}
			>
				Return
			</button>

			<button className="button is-success" disabled={!canApprove} onClick={() => showApprove()}>
				Approve
			</button>
			{canReprocess && (
				<button
					className="button button-reprocess-ach"
					disabled={isReprocessActive}
					onClick={() => {
						isReturnPaymentNotFound ? showMissingReturnPaymentModal() : setReprocessState(accessToken, incomingAch.id)
					}}
				>
					<Icon icon="synchronize-arrows-1--interface-essential" className={"mr-2"} size={16} />
					Reprocess
				</button>
			)}
			{canAddAlias ? (
				<button
					className="button-approve-with-action hover"
					onClick={() => {
						isCustomerId
							? showAddAlias()
							: addToast("Cannot add alias because customer id is missing", {appearance: "warning"})
					}}
				>
					<span className="checkIcon">
						<FontAwesomeIcon size="sm" icon={faCheck} />
					</span>
					{" Approve and add alias"}
				</button>
			) : null}
		</>
	)
}
