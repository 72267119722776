import {startCase} from "lodash"
import moment from "moment"
import React, {useEffect} from "react"
import {useToasts} from "react-toast-notifications"
import {useQueryState} from "use-location-state"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useEsc} from "../../hooks/useEsc"
import {usePaging} from "../../hooks/usePaging"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {CheckPaymentsStatus, findCheckPayments} from "../../resources/checkPayments"
import {ErrorDocument} from "../../resources/common"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {Cents} from "../../resources/transaction"
import {useAccessToken, useUserType} from "../../services/auth"
import {AsyncResult} from "../../types/asyncResult"
import {downloadFile} from "../../utilities/file"
import {DataTableActionHeader, DataTableCard, TablePending} from "../DataTable/DataTable"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {CurrencyRangeFilter} from "../Filter/CurrencyRangeFilter"
import {Filter} from "../Filter/Filter"
import SearchBox from "../SearchBox/SearchBox"
import {CheckPaymentsTable} from "./CheckPaymentsTable"

export enum CheckPaymentsColumns {
	id = "Id",
	accountId = "Account",
	org = "Org",
	customer = "Customer",
	amount = "Amount",
	status = "Status",
	createdAt = "Created At",
	sendAt = "Send At",
}

export type AllowedCheckPaymentsColumns =
	| CheckPaymentsColumns.id
	| CheckPaymentsColumns.org
	| CheckPaymentsColumns.accountId
	| CheckPaymentsColumns.customer
	| CheckPaymentsColumns.amount
	| CheckPaymentsColumns.status
	| CheckPaymentsColumns.createdAt
	| CheckPaymentsColumns.sendAt

interface CheckPaymentsProps {
	accountId?: string
	token?: string
	limit?: number
	includedColumns: AllowedCheckPaymentsColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	enableOrgFilter?: boolean
	enableStatusFilter?: boolean
	enableAmountFilter?: boolean
	disableDrawer?: boolean
	enablePaging?: boolean
	enableExport?: boolean
	enableDateFilter?: boolean
	title?: string
}

export default function CheckPayments({
	limit = 25,
	token,
	includedColumns,
	fullHeight,
	enableTitle,
	accountId,
	enableOrgFilter = false,
	enableStatusFilter = false,
	enableAmountFilter = false,
	enablePaging = true,
	enableDateFilter = true,
	disableDrawer = false,
	enableExport = true,
	title = "Check Payments",
}: CheckPaymentsProps) {
	const prefix = "checkPayments"
	const accessToken = token ?? useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType()
	const {addToast} = useToasts()
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const statusesOptions = new Map<CheckPaymentsStatus, string>(
		[
			CheckPaymentsStatus.PendingReview,
			CheckPaymentsStatus.MarkedForReturn,
			CheckPaymentsStatus.Processed,
			CheckPaymentsStatus.Returned,
			CheckPaymentsStatus.New,
			CheckPaymentsStatus.Pending,
			CheckPaymentsStatus.InDelivery,
			CheckPaymentsStatus.ReturnedToSender,
			CheckPaymentsStatus.Canceled,
			CheckPaymentsStatus.Delivered,
			CheckPaymentsStatus.InProduction,
			CheckPaymentsStatus.PendingCancellation,
			CheckPaymentsStatus.Rejected,
		].map((v) => [v, startCase(v)])
	)
	const [statuses, setStatuses] = useQueryState<Array<CheckPaymentsStatus>>(`${prefix}-filter[status]`, [])
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search by Check Payment Id", 500, onSearch)
	const [selectedCheckPaymentId, setSelectedCheckPaymentId] = useQueryState("id", "")

	useEsc(() => setSelectedCheckPaymentId(""))

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findCheckPayments(
				accessToken,
				offset,
				sortBy,
				query,
				"json",
				limit,
				statuses,
				filteredOrgs,
				since,
				until,
				accountId,
				fromAmount,
				toAmount
			),
		(x) => x.data.length,
		[refreshToken, since, until, sortBy, [...statuses, ...filteredOrgs].join(","), fromAmount, toAmount, query],
		`${prefix}-`,
		true
	)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={setFromAmount}
			to={toAmount}
			setTo={setToAmount}
		/>
	)

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	async function exportCheckPayments() {
		const response = await findCheckPayments(
			accessToken,
			0,
			sortBy,
			query,
			"csv",
			null,
			statuses,
			filteredOrgs,
			since,
			until,
			accountId,
			fromAmount,
			toAmount
		)

		response.match(
			(d) => downloadFile("CheckPayments.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
		return true
	}

	return (
		<DataTableCard className="payments-card">
			<DataTableActionHeader
				enableSticky={fullHeight}
				dateTimePicker={enableDateFilter ? dateTime : null}
				searchBox={searchBox}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
					...(enableStatusFilter ? [statusFilter] : []),
				]}
				exportFunc={enableExport ? exportCheckPayments : undefined}
				title={enableTitle ? title : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: checkPayments}) => {
					return (
						<CheckPaymentsTable
							checkPayments={checkPayments.data}
							meta={checkPayments.menta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={enablePaging}
							include={checkPayments.included}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
							selectedCheckPayment={selectedCheckPaymentId}
							setSelectedCheckPayment={setSelectedCheckPaymentId}
							disableDrawer={disableDrawer}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
