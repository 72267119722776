import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Counterparty, updateCounterparty} from "../../resources/counterparty"
import KeyValueEditor from "../KeyValueEditor/KeyValueEditor"
import {Formik} from "formik"
import * as Yup from "yup"
import {createCounterpartySchema} from "./CounterpartyValidationSchema"
import {focusFirstInvalidField} from "../Address/Address"
import Textfield from "../Address/Textfield"
import Dropdown from "../Address/Dropdown"
import jsonMergePatch from "json-merge-patch"

type CloseFunc = () => void
type RefreshFunc = () => void

const modalCloseDelay = 1000

function closeAndRefresh(refresh: () => void, close: () => void, delay = 0) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, delay)
	}
}

interface ModalProps {
	counterparty: Counterparty
	close: CloseFunc
	refresh: RefreshFunc
}

export type Tag = {key: string; value: string}

function Modal({counterparty, close, refresh}: ModalProps) {
	const validationSchema = Yup.object().shape(createCounterpartySchema)
	const accessToken = useAccessToken()
	const [state, update] = useAsyncResultIdle(updateCounterparty)
	const counterpartyName = counterparty.attributes.name
	const permissions = counterparty.attributes.permissions
	const [tags, setTags] = useState<Array<Tag> | undefined>(
		Object.keys(counterparty.attributes.tags).map((k) => ({key: k, value: counterparty.attributes.tags[k]}))
	)

	const initialFormValues = {
		counterpartyName: counterpartyName,
		routingNumber: counterparty.attributes.routingNumber,
		accountNumber: counterparty.attributes.accountNumber,
		accountType: counterparty.attributes.accountType,
		counterpartyType: counterparty.attributes.type,
		permissions: permissions,
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				if (values.accountType && values.counterpartyType && values.permissions) {
					update(
						accessToken,
						counterparty.id,
						values.counterpartyName,
						values.permissions,
						jsonMergePatch.generate(
							counterparty.attributes.tags,
							tags?.reduce((a, v) => ({...a, [v.key]: v.value}), {}) ?? {}
						) as {[key: string]: string}
					)
				}
			}}
		>
			{({values, handleSubmit, handleChange, errors, isValid, handleBlur}) => (
				<AsyncResultModal
					title="Edit Counterparty"
					classname="edit-counterparty-modal"
					enableClickAway={false}
					onSubmit={(e: React.FormEvent<HTMLFormElement> | undefined) => {
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
					close={() => {
						close()
					}}
					state={state}
					buttonClassname="is-black"
					buttonText="Save"
					successText="Updated successfully"
					errorToText={(err) => {
						return err.errors[0].detail ? err.errors[0].detail : err.errors[0].title
					}}
					onSuccess={() => {
						closeAndRefresh(refresh, close, modalCloseDelay)()
					}}
				>
					<Textfield
						label="Counterparty name"
						placeholder="Add counterparty name"
						name="counterpartyName"
						value={values.counterpartyName}
						onChange={handleChange}
						onBlur={() => null}
					/>
					<div className="field-row-wrapper">
						<div className="field">
							<Dropdown
								name="counterpartyType"
								label="Counterparty Type"
								value={values.counterpartyType}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled
							>
								<option value="" disabled>
									Select Counterparty Type
								</option>
								<option value="Business">Business</option>
								<option value="Person">Person</option>
								<option value="Unknown">Unknown</option>
							</Dropdown>
						</div>
						<div className="field">
							<Dropdown
								name="permissions"
								label="Permissions"
								value={values.permissions}
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value="" disabled>
									Select Permissions
								</option>
								<option value="CreditOnly">Credit Only</option>
								<option value="CreditAndDebit">Credit And Debit</option>
								<option value="DebitOnly">Debit Only</option>
							</Dropdown>
						</div>
					</div>
					<div className="field-row-wrapper">
						<div className="field">
							<Textfield
								label="Routing Number"
								placeholder="000000000"
								name="routingNumber"
								value={values.routingNumber}
								onChange={handleChange}
								onBlur={() => null}
								disabled
							/>
						</div>

						<div className="field">
							<Textfield
								label="Account Number"
								placeholder="00000000000"
								name="accountNumber"
								value={values.accountNumber}
								onChange={handleChange}
								onBlur={() => null}
								disabled
							/>
						</div>
					</div>
					<div className="field-row-wrapper">
						<div className="field">
							<Dropdown
								name="accountType"
								label="Account Type"
								value={values.accountType}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled
							>
								<option value="" disabled>
									Select Account Type
								</option>
								<option value="Checking">Checking</option>
								<option value="Savings">Savings</option>
							</Dropdown>
						</div>
					</div>

					<div className="field tags-editor">
						<label className="label">Tags (optional)</label>
						<KeyValueEditor
							keyValues={tags ?? []}
							setKeyValues={(e: React.SetStateAction<Tag[] | undefined>) => {
								setTags(e)
							}}
							keyName={"key"}
							valueName={"value"}
							itemName={"Tag"}
							keyPlaceholder={"Tag Key"}
							valuePlaceholder={"Tag Value"}
							valueInputType={"text"}
						/>
					</div>
				</AsyncResultModal>
			)}
		</Formik>
	)
}

export default function EditCounterpartyModal({
	counterparty,
	close,
	refresh,
}: {
	counterparty: Counterparty
	close: CloseFunc
	refresh: RefreshFunc
}) {
	return <Modal counterparty={counterparty} close={close} refresh={refresh} />
}
