import React from "react"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {
	AchTotalsDaily,
	AchTotalsMonthly,
	BookTransferLimits,
	BookTransferTotalsDaily,
	BookTransferTotalsMonthly,
	CardTotalsDaily,
	CheckPaymentLimits,
	CheckPaymentTotalsDaily,
	CheckPaymentTotalsMonthly,
	CheckTotalsDaily,
	CheckTotalsMonthly,
	getAccountLimits,
	WireLimits,
	WireTotalsDaily,
	WireTotalsMonthly,
} from "../../resources/account"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import RangeBox from "./RangeBox"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import BodyPortal from "../BodyPortal/BodyPortal"
import Skeleton from "react-loading-skeleton"
import {AchLimits, CardLimits, CheckLimits} from "../../resources/account"

function AccountLimitsBlockHeader({
	title,
	icon,
	infoTip = "",
}: {
	title: string
	icon: IcomoonIconName
	infoTip?: string
}) {
	return (
		<div className="account-limits-block-header">
			<div className="account-limits-block-header-icon-placeholder">
				<Icon icon={icon} size={20} />
			</div>
			<h4> {title} </h4>
			{infoTip ? (
				<div
					className="account-limits-block-header-info-icon-placeholder"
					data-tip={infoTip}
					data-iscapture={true}
					data-for={"account-limits-info-icon"}
				>
					<FontAwesomeIcon icon={faQuestionCircle} />
					<BodyPortal>
						<ReactTooltip
							arrowColor="#FFF"
							delayHide={300}
							className="account-limits-info-tooltip"
							place="right"
							type="light"
							effect="solid"
							id={"account-limits-info-icon"}
						/>
					</BodyPortal>
				</div>
			) : (
				""
			)}
		</div>
	)
}

export function AccountLimitsDescription() {
	return (
		<>
			Limits on Unit can be either hard or soft. Transactions exceeding <b>soft</b> limits are subjected to a review
			process by the Unit team, whereas transactions exceeding <b>hard</b> limits are automatically rejected.
		</>
	)
}

function AccountLimitsDescriptionWithLink() {
	return (
		<p className="account-limits-description">
			<AccountLimitsDescription />
			&nbsp;You can read more about account limits in the
			<a target="_blank" rel="noreferrer" href="https://guides.unit.co/limits/">
				{" "}
				<span>limits guide</span>{" "}
				<Icon icon="maximize-square-2--interface-essential" size={12} style={{position: "relative", top: "2px"}} />
			</a>
		</p>
	)
}

function AccountLimitsPending() {
	return (
		<div className="account-limits">
			<AccountLimitsDescriptionWithLink />
			<AccountLimitsBlockHeader title="Card Limits" icon="credit-card-money-fund--business-products" />
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<AccountLimitsBlockHeader title="Originated ACH Payments Limits " icon="money-transfer--business-products" />
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<AccountLimitsBlockHeader title="Originated ACH Payments Limits " icon="money-transfer--business-products" />
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="box">
				<table className="table is-hoverable is-fullwidth is-clickable">
					<tbody>
						<tr>
							<td>
								<Skeleton />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}

function CardAccountLimits({limits, total}: {limits: CardLimits; total: CardTotalsDaily}) {
	return (
		<>
			<AccountLimitsBlockHeader
				title="Card Limits"
				icon="credit-card-money-fund--business-products"
				infoTip="This limit is based on the limit set in the deposit product"
			/>

			<div className="account-limits-block-limits">
				<RangeBox
					title="Purchases"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyPurchase}
					current={total.purchases}
				/>
				<RangeBox
					title="Transaction"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyCardTransaction}
					current={total.cardTransactions}
				/>
				<RangeBox
					title="ATM deposit"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyDeposit}
					current={total.deposits}
				/>
				<RangeBox
					title="ATM Withdrawal"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyWithdrawal}
					current={total.withdrawals}
				/>
			</div>
		</>
	)
}

function AchAccountLimits({
	limits,
	total,
}: {
	limits: AchLimits
	total: {totalsDaily: AchTotalsDaily; totalsMonthly: AchTotalsMonthly}
}) {
	return (
		<>
			<AccountLimitsBlockHeader title="Originated ACH Payments Limits " icon="money-transfer--business-products" />

			<div className="account-limits-block-limits">
				<RangeBox
					title="Debit"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyDebit}
					current={total.totalsDaily.debits}
					softLimit={limits.dailyDebitSoft}
				/>
				<RangeBox
					title="Credit"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyCredit}
					current={total.totalsDaily.credits}
				/>
				<RangeBox
					title="Debit"
					description="(Monthly Limit)"
					className="account-limits-range-box"
					max={limits.monthlyDebit}
					current={total.totalsMonthly.debits}
					softLimit={limits.monthlyDebitSoft}
				/>
				<RangeBox
					title="Credit"
					description="(Monthly Limit)"
					className="account-limits-range-box"
					max={limits.monthlyCredit}
					current={total.totalsMonthly.credits}
				/>
			</div>
		</>
	)
}

function BookTransferAccountLimits({
	limits,
	total,
}: {
	limits: BookTransferLimits
	total: {totalsDaily: BookTransferTotalsDaily; totalsMonthly: BookTransferTotalsMonthly}
}) {
	return (
		<>
			<AccountLimitsBlockHeader title="Originated Book Payments Limits " icon="money-transfer--business-products" />

			<div className="account-limits-block-limits">
				{limits.dailyDebit && (
					<RangeBox
						title="Debit"
						description="(Daily Limit)"
						className="account-limits-range-box"
						max={limits.dailyDebit}
						current={total.totalsDaily.debits}
					/>
				)}
				{limits.dailyCredit && (
					<RangeBox
						title="Credit"
						description="(Daily Limit)"
						className="account-limits-range-box"
						max={limits.dailyCredit}
						current={total.totalsDaily.credits}
					/>
				)}
				{limits.monthlyDebit && (
					<RangeBox
						title="Debit"
						description="(Monthly Limit)"
						className="account-limits-range-box"
						max={limits.monthlyDebit}
						current={total.totalsMonthly.debits}
					/>
				)}
				{limits.monthlyCredit && (
					<RangeBox
						title="Credit"
						description="(Monthly Limit)"
						className="account-limits-range-box"
						max={limits.monthlyCredit}
						current={total.totalsMonthly.credits}
					/>
				)}
			</div>
		</>
	)
}

function CheckAccountLimits({
	limits,
	total,
}: {
	limits: CheckLimits
	total: {totalsDaily: CheckTotalsDaily; totalsMonthly: CheckTotalsMonthly}
}) {
	return (
		<>
			<AccountLimitsBlockHeader title="Check Deposits Limits" icon="check-write--business-products" />

			<div className="account-limits-block-limits">
				<RangeBox
					title="Check Deposits"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.daily}
					current={total.totalsDaily}
					softLimit={limits.dailySoft}
				/>
				<RangeBox
					title="Check Deposits"
					description="(Monthly Limit)"
					className="account-limits-range-box"
					max={limits.monthly}
					current={total.totalsMonthly}
					softLimit={limits.monthlySoft}
				/>
			</div>
		</>
	)
}

function WireAccountLimits({
	limits,
	total,
}: {
	limits: WireLimits
	total: {totalsDaily: WireTotalsDaily; totalsMonthly: WireTotalsMonthly}
}) {
	return (
		<>
			<AccountLimitsBlockHeader title="Wire Limits" icon="check-write--business-products" />

			<div className="account-limits-block-limits">
				<RangeBox
					title="Transfers"
					description="(Daily Limit)"
					className="account-limits-range-box"
					max={limits.dailyTransfer}
					current={total.totalsDaily.transfers}
					softLimit={limits.dailyTransferSoft}
				/>
				<RangeBox
					title="Transfers"
					description="(Monthly Limit)"
					className="account-limits-range-box"
					max={limits.monthlyTransfer}
					current={total.totalsMonthly.transfers}
					softLimit={limits.monthlyTransferSoft}
				/>
			</div>
		</>
	)
}

function CheckPaymentAccountLimits({
	limits,
	total,
}: {
	limits: CheckPaymentLimits
	total: {totalsDaily: CheckPaymentTotalsDaily; totalsMonthly: CheckPaymentTotalsMonthly}
}) {
	return (
		<>
			<AccountLimitsBlockHeader title="CheckPayment Limits" icon="check-write--business-products" />

			<div className="account-limits-block-limits">
				{limits.dailySent && (
					<RangeBox
						title="Sent"
						description="(Daily Limit)"
						className="account-limits-range-box"
						max={limits.dailySent}
						current={total.totalsDaily.sent}
						softLimit={limits.dailySentSoft}
					/>
				)}
				{limits.monthlySent && (
					<RangeBox
						title="Sent"
						description="(Monthly Limit)"
						className="account-limits-range-box"
						max={limits.monthlySent}
						current={total.totalsMonthly.sent}
						softLimit={limits.monthlySentSoft}
					/>
				)}
			</div>
		</>
	)
}

export default function AccountLimits({accountId, accessToken}: {accountId: string; accessToken: string}) {
	const accountLimits = useAsyncResult(() => getAccountLimits(accessToken, accountId))

	return (
		<AsyncResultComponent asyncResult={accountLimits} pendingComponent={<AccountLimitsPending />}>
			{({value: limits}) => {
				const isBookTransferLimitsExist =
					limits.attributes.bookTransfer.limits.dailyDebit ||
					limits.attributes.bookTransfer.limits.dailyCredit ||
					limits.attributes.bookTransfer.limits.monthlyDebit ||
					limits.attributes.bookTransfer.limits.monthlyCredit

				return (
					<div className="account-limits">
						<AccountLimitsDescriptionWithLink />

						<CardAccountLimits limits={limits.attributes.card.limits} total={limits.attributes.card.totalsDaily} />

						<AchAccountLimits
							limits={limits.attributes.ach.limits}
							total={{
								totalsDaily: {...limits.attributes.ach.totalsDaily},
								totalsMonthly: {...limits.attributes.ach.totalsMonthly},
							}}
						/>

						{isBookTransferLimitsExist && (
							<BookTransferAccountLimits
								limits={limits.attributes.bookTransfer.limits}
								total={{
									totalsDaily: limits.attributes.bookTransfer.totalsDaily,
									totalsMonthly: limits.attributes.bookTransfer.totalsMonthly,
								}}
							/>
						)}

						<CheckAccountLimits
							limits={limits.attributes.checkDeposit.limits}
							total={{
								totalsDaily: limits.attributes.checkDeposit.totalsDaily,
								totalsMonthly: limits.attributes.checkDeposit.totalsMonthly,
							}}
						/>

						<WireAccountLimits
							limits={limits.attributes.wire.limits}
							total={{
								totalsDaily: limits.attributes.wire.totalsDaily,
								totalsMonthly: limits.attributes.wire.totalsMonthly,
							}}
						/>

						{(limits.attributes.checkPayment.limits.dailySent || limits.attributes.checkPayment.limits.monthlySent) && (
							<CheckPaymentAccountLimits
								limits={limits.attributes.checkPayment.limits}
								total={{
									totalsDaily: limits.attributes.checkPayment.totalsDaily,
									totalsMonthly: limits.attributes.checkPayment.totalsMonthly,
								}}
							/>
						)}
					</div>
				)
			}}
		</AsyncResultComponent>
	)
}
