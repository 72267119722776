import {createResource, getResource, updateResource} from "./common"

export type TransactionMonitoringSettings = {
	type: "transactionMonitoringSettings"
	id: string
	attributes: {
		name: string
		fields: Map<string, number>
		paymentLimitInbound: number
		paymentLimitHistoricAvgInboundFactor: number
		paymentLimitHistoricAvgInboundNumber: number
		paymentLimitHistoricAvgInboundAmount: number
		paymentLimitOutbound: number
		paymentLimitHistoricAvgOutboundFactor: number
		paymentLimitHistoricAvgOutboundNumber: number
		paymentLimitHistoricAvgOutboundAmount: number
		structuringInbound: number
		structuringOutbound: number
		highFrequencyHistoricAverageInboundFactor: number
		highFrequencyHistoricAverageInboundNumber: number
		highFrequencyHistoricAverageOutboundFactor: number
		highFrequencyHistoricAverageOutboundNumber: number
		highFrequencyInbound: number
		highFrequencyOutbound: number
		peerGroupAverageInboundAmount: number
		peerGroupAverageInboundPercentage: number
		peerGroupAverageOutboundAmount: number
		peerGroupAverageOutboundPercentage: number
	}
}

export async function findTransactionMonitoringSettings(accessToken: string, offset: number, limit: number) {
	const query = {
		page: {
			limit,
			offset,
		},
	}

	const result = await getResource<Array<TransactionMonitoringSettings>>("transaction-monitoring", accessToken, query)

	return result.map((v) => v.data)
}

export async function getTransactionMonitoringSettings(accessToken: string, id: string) {
	const result = await getResource<TransactionMonitoringSettings>(`transaction-monitoring/${id}`, accessToken)

	return result.map((v) => v.data)
}

export async function createTransactionMonitoringSettings(accessToken: string, name: string, fields: object) {
	const result = await createResource<TransactionMonitoringSettings>("transaction-monitoring/", accessToken, {
		type: "transactionMonitoringSettings",
		attributes: {
			name,
			fields,
		},
	})

	return result.map((v) => v.data)
}

export async function updateTransactionMonitoringSettings(
	accessToken: string,
	id: string,
	name: string,
	fields: object
) {
	const obj = {
		name,
		fields,
	}

	const result = await updateResource<TransactionMonitoringSettings>(`transaction-monitoring/${id}`, accessToken, obj)
	return result.map((v) => v.data)
}
