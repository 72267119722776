import React, {Dispatch, SetStateAction, useState} from "react"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {BeneficialOwnerResource, updateBeneficialOwnerDeprecated} from "../../resources/beneficialOwner"
import {Formik} from "formik"
import * as Yup from "yup"
import LeftRightSlots from "../../containers/LeftRightSlots/LeftRightSlots"
import beneficialOwnerValidationSchema from "./beneficialOwnerValidationSchema"
import {US_COUNTRY_CODE} from "../../resources/address"
import {toPhoneNumber} from "../../resources/common"
import AddressComponent, {focusFirstInvalidField} from "../Address/Address"
import {parsePhoneNumber} from "libphonenumber-js"
import {AddToast} from "react-toast-notifications"
import Textfield from "../Address/Textfield"
import IdentificationTypeSelect from "../IdentificationTypeSelect/ApplicationIdentificationNumber"
import DateField from "../DateField/DateField"
import PhoneNumber from "../PhoneNumber/PhoneNumber"

export type Tag = {key: string; value: string}

interface ModalContentLeftSideProps {
	onIdentificationTypeChange: Dispatch<SetStateAction<string>>
	values: {
		firstName: string
		lastName: string
		ssn: string
		nationality: string
		passport: string
		matriculaConsular: string
		dateOfBirth: Date | string
		email: string
		phone: string
		percentage: number
	}
	handleChange: any
}
const ModalContentLeftSide = ({values, handleChange, onIdentificationTypeChange}: ModalContentLeftSideProps) => {
	const IdentificationType = !!values.ssn ? "ssn" : !!values.passport ? "passport" : "matriculaConsular"
	const [identificationType, setIdentificationType] = useState<string>(IdentificationType)

	return (
		<>
			<div className="beneficial-owner-section-title">Beneficial Owner details</div>
			<div className="field-row-wrapper">
				<Textfield
					label="First Name"
					placeholder="Add first name"
					name="firstName"
					value={values.firstName}
					onChange={handleChange}
					onBlur={() => null}
				/>
				<Textfield
					label="Last Name"
					placeholder="Add last name"
					name="lastName"
					value={values.lastName}
					onChange={handleChange}
					onBlur={() => null}
				/>
			</div>
			<div className="field-row-wrapper">
				<IdentificationTypeSelect
					value={identificationType}
					onChange={(val) => {
						onIdentificationTypeChange(val)
						setIdentificationType(val)
					}}
					label="Document Type"
				/>
				{identificationType === "passport" ? (
					<>
						<Textfield
							label="Nationality"
							placeholder="Add country"
							name="nationality"
							value={values.nationality}
							onChange={handleChange}
							onBlur={() => null}
						/>
						<Textfield
							label="Document ID #"
							placeholder="Enter Passport"
							name="passport"
							value={values.passport}
							onChange={handleChange}
							onBlur={() => null}
						/>
					</>
				) : null}
				{identificationType === "ssn" ? (
					<Textfield
						label="Document ID #"
						placeholder="Enter SSN"
						name="ssn"
						value={values.ssn}
						onChange={handleChange}
						onBlur={() => null}
					/>
				) : null}
				{identificationType === "matriculaConsular" ? (
					<Textfield
						label="Document ID #"
						placeholder="Enter Matricula Consular"
						name="matriculaConsular"
						value={values.matriculaConsular}
						onChange={handleChange}
						onBlur={() => null}
					/>
				) : null}
			</div>
			<div className="field-row-wrapper">
				<div className="date-of-birth-container">
					<DateField
						name="dateOfBirth"
						label="Add Date of birth"
						value={values.dateOfBirth as string}
						onChange={handleChange}
						onBlur={() => null}
					/>
				</div>
				<Textfield
					label="Percentage"
					placeholder="Add Percentage"
					name="percentage"
					value={values.percentage}
					onChange={handleChange}
					onBlur={() => null}
				/>
			</div>
			<div className="field-row-wrapper">
				<PhoneNumber value={values.phone} handleChange={handleChange} />
				<Textfield
					label="Email"
					placeholder="Add Email"
					name="email"
					value={values.email}
					onChange={handleChange}
					onBlur={() => null}
				/>
			</div>
		</>
	)
}

interface ModalContentRightSideProps {
	values: {
		street: string
		street2: string
		city: string
		country: string | undefined
		state: string
		postalCode: string
	}
	handleChange: any
	handleBlur: any
	setFieldValue: Function
}
const ModalContentRightSide = ({values, handleChange, handleBlur, setFieldValue}: ModalContentRightSideProps) => (
	<>
		<div className="beneficial-owner-section-title">Address</div>
		<div className="field-row-wrapper">
			<div className="edit-beneficial-owner-address">
				<AddressComponent
					closed={false}
					street={values.street}
					street2={values.street2}
					city={values.city}
					country={values.country || ""}
					state={values.state}
					postalCode={values.postalCode}
					isUsOnlyAddress={false}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldValue={setFieldValue}
				/>
			</div>
		</div>
	</>
)

interface ModalProps {
	bo: BeneficialOwnerResource
	close: () => void
	addToast: AddToast
	refresh: () => void
}

const closeSuccessfully = (refresh: () => void, close: () => void, addToast: AddToast, delay = 0) => {
	setTimeout(() => {
		addToast("Beneficial Owner Updated Successfully", {appearance: "success"})
		close()
		refresh()
	}, delay)
}

export default function EditBeneficialOwnerModal({bo, close, addToast, refresh}: ModalProps) {
	const accessToken = useAccessToken()
	const validationSchema = Yup.object().shape(beneficialOwnerValidationSchema)

	const [identificationType, setIdentificationType] = useState<string>(
		!!bo.attributes.ssn ? "ssn" : !!bo.attributes.matriculaConsular ? "matriculaConsular" : "passport"
	)
	const [state, update] = useAsyncResultIdle(updateBeneficialOwnerDeprecated)

	const getBeneficialOwnerFromFormValues = (values: any): BeneficialOwnerResource => {
		const updatedPhone = parsePhoneNumber(values.phone)
		return {
			id: bo.id,
			type: "beneficialOwner",
			attributes: {
				fullName: {first: values.firstName, last: values.lastName},
				email: values.email,
				address: {
					postalCode: values.postalCode,
					street: values.street,
					street2: values.street2,
					state: values.state,
					city: values.city,
					country: values.country,
				},
				phone: {
					countryCode: updatedPhone.countryCallingCode.toString(),
					number: updatedPhone.nationalNumber.toString(),
				},
				dateOfBirth: values.dateOfBirth,
				ssn: identificationType === "ssn" ? values.ssn : null,
				passport: identificationType === "passport" ? values.passport : null,
				nationality: identificationType === "passport" ? values.nationality : null,
				matriculaConsular: identificationType === "matriculaConsular" ? values.matriculaConsular : null,
				percentage: values.percentage ? +values.percentage : undefined,
				status: bo.attributes.status,
			},
			relationships: bo.relationships,
		}
	}
	const initialFormValues = {
		firstName: bo.attributes.fullName.first ?? "",
		lastName: bo.attributes.fullName.last ?? "",
		ssn: bo.attributes.ssn ?? "",
		nationality: bo.attributes.nationality ?? US_COUNTRY_CODE,
		passport: bo.attributes.passport ?? "",
		matriculaConsular: bo.attributes.matriculaConsular ?? "",
		street: bo.attributes.address?.street ?? "",
		street2: bo.attributes.address?.street2 ?? "",
		city: bo.attributes.address?.city ?? "",
		state: bo.attributes.address?.state ?? "",
		postalCode: bo.attributes.address?.postalCode ?? "",
		country: bo.attributes.address?.country ?? US_COUNTRY_CODE,
		dateOfBirth: bo.attributes.dateOfBirth ?? "",
		email: bo.attributes.email ?? "",
		phone: bo.attributes.phone ? toPhoneNumber(bo.attributes.phone) : "",
		percentage: bo.attributes.percentage ?? 25,
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				update(accessToken, getBeneficialOwnerFromFormValues(values))
			}}
		>
			{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
				<AsyncResultModal
					title="Edit Beneficial Owner"
					onSubmit={(e: React.FormEvent<HTMLFormElement> | undefined) => {
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
					close={close}
					state={state}
					classname="edit-beneficial-owner-modal"
					buttonClassname="is-success"
					buttonText="Save"
					successText="Updated successfully"
					errorToText={(err: any) => {
						return err.errors[0].detail ? err.errors[0].detail : err.errors[0].title
					}}
					onSuccess={() => closeSuccessfully(refresh, close, addToast)}
					enableClickAway={false}
				>
					<LeftRightSlots
						left={
							<ModalContentLeftSide
								values={values}
								onIdentificationTypeChange={setIdentificationType}
								handleChange={handleChange}
							/>
						}
						right={
							<ModalContentRightSide
								values={values}
								handleChange={handleChange}
								handleBlur={handleBlur}
								setFieldValue={setFieldValue}
							/>
						}
					/>
				</AsyncResultModal>
			)}
		</Formik>
	)
}
