import React, {useRef} from "react"
import {
	AuditLogEvent,
	AuditLogEventsByUserAndTimestamp,
	AuditLogEventsView,
} from "../AuditLogEventsView/AuditLogEventsView"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {AuditLog, AuditLogResource, findAuditLogs} from "../../resources/auditLog"
import {useAccessToken} from "../../services/auth"
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll"
import {InfiniteScroll} from "../InfiniteScroll/InfiniteScroll"

interface AuditLogEventsProps {
	resourceType: AuditLogResource
	resourceId: string
	limit?: number
}

function AuditLogPending() {
	return <AuditLogEventsView loading auditLogEventsByUserAndTimestamp={[]} />
}

const getProcessedAuditLogResults = (data: AuditLog[]) => {
	const groupedEvents: {[key: string]: AuditLogEventsByUserAndTimestamp} = {}
	data.forEach((event: AuditLog, index: number) => {
		const key = `${event.attributes.actorUserId}-${event.attributes.actionTimestamp}`
		if (!groupedEvents[key]) {
			groupedEvents[key] = {
				...(event.attributes.actorUserName && {actorUserName: event.attributes.actorUserName}),
				actorUserRole: event.attributes.actorUserRole,
				actorUserType: event.attributes.actorUserType,
				actionTimestamp: event.attributes.actionTimestamp,
				events: [],
			}
		}
		const auditLogEvent: AuditLogEvent = {
			intent: event.attributes.intent,
			changes: event.attributes.changes,
			originalIndex: index,
		}
		groupedEvents[key].events.push(auditLogEvent)
	})
	return Object.values(groupedEvents)
}

const LIMIT_DEFAULT = 50

export function AuditLogComponent({resourceType, resourceId, limit = LIMIT_DEFAULT}: AuditLogEventsProps) {
	const infiniteScrollRef = useRef<HTMLSpanElement>(null)
	const accessToken = useAccessToken()
	const [result, , hasNext, , next, , , isLoading] = useInfiniteScroll(
		limit,
		(offset, limit) => findAuditLogs(accessToken, offset, limit, [resourceId], [resourceType]),
		(x) => x.data.length,
		[],
		""
	)

	const shouldAnimateEvent = (originalIndex: number) => originalIndex >= limit

	return (
		<div className="audit-log-drawer-content">
			<AsyncResultComponent asyncResult={result} pendingComponent={<AuditLogPending />}>
				{({value: {data}}) => {
					return (
						<>
							<AuditLogEventsView
								auditLogEventsByUserAndTimestamp={getProcessedAuditLogResults(data)}
								shouldAnimateEvent={shouldAnimateEvent}
							/>
							{hasNext && (
								<span ref={infiniteScrollRef}>
									<InfiniteScroll
										observerElementRef={infiniteScrollRef}
										callback={next}
										isLoading={isLoading}
										className="audit-log-content-loader"
									/>
								</span>
							)}
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}
export default AuditLogComponent
