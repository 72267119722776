import React from "react"

export function BankNameAndLogo({name, logo}: {name: string; logo: string}) {
	if (!name) {
		return null
	}

	return (
		<>
			{logo && <img src={logo} alt={`${name} Bank logo`} />}
			<p className="box-title font-14 font-bold">{name}</p>
		</>
	)
}
