import React, {FC} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import {useAccessToken} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {useNavigate} from "react-router-dom"
import {
	findTransactionMonitoringSettings,
	TransactionMonitoringSettings,
} from "../../resources/transactionMonitoringSettings"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {
	DataTable,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"

const TransactionMonitoringRow: FC<{settings: TransactionMonitoringSettings}> = ({settings}) => {
	const navigate = useNavigate()

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/transaction-monitoring/${settings.id}`)
			}}
		>
			<DataTableCell>{settings.id}</DataTableCell>
			<DataTableCell>{settings.attributes.name}</DataTableCell>
		</DataTableRow>
	)
}

export default function TransactionMonitoringSettingsList() {
	const accessToken = useAccessToken()
	const [result, hasPrev, hasNext, prev, next, hasResults] = usePaging(
		25,
		(offset, limit) => findTransactionMonitoringSettings(accessToken, offset, limit),
		(x) => x.length,
		[]
	)
	const navigate = useNavigate()

	return (
		<>
			<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
				{({value: settings}) => {
					const rows = (settings as TransactionMonitoringSettings[]).map((s) => (
						<TransactionMonitoringRow key={s.id} settings={s} />
					))
					return (
						<>
							<TitleBar title={"Transactions Monitoring Settings"}>
								<button className="button button-create" onClick={() => navigate("/transaction-monitoring/new")}>
									+ New
								</button>
							</TitleBar>
							<MainSection>
								<DataTableCard className={"tr-monitoring-card"}>
									<DataTable>
										<DataTableHead>
											<DataTableRow>
												<DataTableCell>Id</DataTableCell>
												<DataTableCell>Name</DataTableCell>
											</DataTableRow>
										</DataTableHead>
										<DataTableBody>{rows}</DataTableBody>
									</DataTable>
									<PagingNavBar hasResults={hasResults} hasPrev={hasPrev} hasNext={hasNext} prev={prev} next={next} />
								</DataTableCard>
							</MainSection>
						</>
					)
				}}
			</AsyncResultComponent>
		</>
	)
}
