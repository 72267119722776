import classNames from "classnames"
import {kebabCase, startCase} from "lodash"
import React from "react"
import {useParams} from "react-router-dom"
import {CheckImageCard} from "../../components/CheckDeposits/CheckDepositInfo"
import {
	CheckPaymentComponent,
	getButtonsToShow,
	CheckActionButtons,
	isReturnWindowValid,
} from "../../components/CheckPayments/CheckPayment"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {Account} from "../../resources/account"
import {CheckImageSide} from "../../resources/checkDeposits"
import {getCheckPayment, getCheckPaymentImage} from "../../resources/checkPayments"
import {Customer, CustomerResourceTypes} from "../../resources/customer"
import {useAccessToken, useUserType} from "../../services/auth"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"

export default function CheckPaymentPage() {
	const accessToken = useAccessToken()
	const {checkPaymentId = ""} = useParams<{checkPaymentId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const checkPaymentAsyncResultReq = useAsyncResult(
		() => getCheckPayment(accessToken, checkPaymentId, "customer,account"),
		[refreshToken]
	)

	return (
		<div className="check-payment-full-page">
			<AsyncResultComponent asyncResult={checkPaymentAsyncResultReq}>
				{({value: checkPaymentResponse}) => {
					const checkPayment = checkPaymentResponse.data
					const id = checkPayment.id
					const accountId = checkPayment.relationships.account?.data.id
					const customerId = checkPayment.relationships.customer?.data.id
					const checkPaymentCustomerIds = [
						...(checkPayment.relationships.customers?.data.map(({id}) => id) ?? []),
						checkPayment.relationships.customer.data.id,
					]
					const status = checkPayment.attributes.status
					const checkPaymentTransactionId = checkPayment.relationships.transaction?.data.id
					const userType = useUserType()
					const additionalVerificationStatus = checkPayment.attributes.additionalVerificationStatus
					const customers =
						checkPaymentResponse.included &&
						(
							checkPaymentResponse.included.filter(({type}) => CustomerResourceTypes.includes(type)) as Customer[]
						).filter(({id}) => checkPaymentCustomerIds.includes(id))

					const account =
						checkPaymentResponse.included &&
						(checkPaymentResponse.included.find(
							({type, id}) => type === "depositAccount" && id == accountId
						) as Account)

					const frontImageState = useAsyncResult(() =>
						getCheckPaymentImage({accessToken, id, side: CheckImageSide.Front})
					)
					const backImageState = useAsyncResult(() =>
						getCheckPaymentImage({accessToken, id, side: CheckImageSide.Back})
					)
					const frontImage = frontImageState.match(
						() => undefined,
						(image) => image,
						() => null
					)
					const backImage = backImageState.match(
						() => undefined,
						(image) => image,
						() => null
					)
					return (
						<>
							<TitleBar
								title={`${startCase(checkPayment.type)} #${checkPaymentId}`}
								breadcrumbs={[{text: "Check Payments", path: "/check-payments/"}]}
								status={{
									text: startCase(checkPayment.attributes.status),
									className: classNames("check-payments-status", kebabCase(checkPayment.attributes.status)),
								}}
								meta={[
									{
										text: "Account",
										icon: "bank-account--business-products",
										path: `/accounts/${accountId}`,
									},
									{
										text: "Customer",
										icon: "user-geometric-action-money-fund---users",
										path: `/customer/${customerId}`,
									},
									...(accountId && checkPaymentTransactionId
										? [
												{
													text: "Transaction",
													icon: "money-transfer--business-products",
													path: `/transaction/${accountId}/${checkPaymentTransactionId}`,
												} as MetaItemProps,
										  ]
										: []),
								]}
							>
								{checkPayment.attributes.returnCutoffTime &&
								isReturnWindowValid(checkPayment.attributes.returnCutoffTime) ? (
									<CheckActionButtons
										checkPaymentId={checkPaymentId}
										refresh={refresh}
										buttons={getButtonsToShow(status, userType, additionalVerificationStatus)}
										disabled={false}
										title={""}
									/>
								) : null}
							</TitleBar>
							<MainSectionLayout
								fullWidth={true}
								outerWrapperClassname={classNames("received-payments-full-with-details")}
								renderAfter={<CheckImageCard frontImage={frontImage} backImage={backImage} id={id} status={status} />}
							>
								<CheckPaymentComponent
									checkPaymentId={checkPaymentId}
									accessToken={accessToken}
									useDrawer={false}
									refresh={refresh}
									customers={customers}
									accounts={account ? [account] : undefined}
								/>
							</MainSectionLayout>
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}
