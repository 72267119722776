import React from "react"
import {AsyncResultComponent} from "../../../containers/AsyncResult/AsyncResult"
import {Box, BoxInfo} from "../../../containers/Box/Box"
import {getAccount, OrgGeneralLedgerAccount} from "../../../resources/account"
import Skeleton from "react-loading-skeleton"
import {toLongDollars} from "../../../utilities/numbers"
import {useAccessToken} from "../../../services/auth"
import {useAsyncResult} from "../../../hooks/useAsyncResult"

export function TotalOwedByCustomersSkeleton() {
	return (
		<Box className="wh-100">
			<div className="flex-container flex-dir-col justify-content-center wh-100">
				<Skeleton width="100%" />
				<Skeleton width="100%" />
			</div>
		</Box>
	)
}

function TotalOwedByCustomersInternal({account}: {account: OrgGeneralLedgerAccount}) {
	const {balance} = account.attributes
	const owed = Math.abs(balance)

	return (
		<Box className="wh-100">
			<BoxInfo title="Total owed by customers">{toLongDollars(owed)}</BoxInfo>
		</Box>
	)
}

export function TotalOwedByCustomers({orgGeneralLedgerAccountId}: {orgGeneralLedgerAccountId: string}) {
	const accessToken = useAccessToken()
	const orgGeneralLedgerAccount = useAsyncResult(() =>
		getAccount<OrgGeneralLedgerAccount>(accessToken, orgGeneralLedgerAccountId)
	)

	return (
		<AsyncResultComponent asyncResult={orgGeneralLedgerAccount} pendingComponent={<TotalOwedByCustomersSkeleton />}>
			{({value}) => <TotalOwedByCustomersInternal account={value} />}
		</AsyncResultComponent>
	)
}
