import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {useIsUnitUser} from "../../services/auth"

export function AccountsPage(): ReactElement {
	const isUnitUser = useIsUnitUser()

	return (
		<>
			<TitleBar title={"Accounts"} />

			<MainSectionTable>
				<Accounts
					enableOrgFilter
					enableBankFilter={isUnitUser}
					enableStatusFilter
					enableSearch
					enableOverdraftStatusFilter
					enableDacaStatusFilter
					enableTypeFilter
					limit={40}
					fullHeight={true}
					includedColumns={[
						AccountsColumns.id,
						...(isUnitUser ? [AccountsColumns.org] : []),
						...(isUnitUser ? [AccountsColumns.bank] : []),
						AccountsColumns.name,
						AccountsColumns.type,
						AccountsColumns.createdAt,
						AccountsColumns.updatedAt,
						AccountsColumns.balance,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
