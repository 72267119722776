import React, {useCallback} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Account, activateDaca, deactivateDaca, enterDaca, terminateDaca} from "../../resources/account"
import {useAccessToken} from "../../services/auth"

export enum DacaStatusAction {
	Terminate = "Terminate",
	Enter = "Enter",
	Activate = "Activate",
	Deactivate = "Deactivate",
}

interface DacaStatusModalProps {
	account: Account
	action: DacaStatusAction
	closeModal: () => void
	onSuccess: () => void
}

export function DacaStatusModal({action, account, closeModal, onSuccess}: DacaStatusModalProps) {
	const accessToken = useAccessToken()

	const [enterDacaStatus, setEnterDacaState] = useAsyncResultIdle(enterDaca)
	const [terminateDacaStatus, setTerminateDacaState] = useAsyncResultIdle(terminateDaca)
	const [activateDacaStatus, setActivateDacaState] = useAsyncResultIdle(activateDaca)
	const [deactivateDacaStatus, setDeactivateDacaState] = useAsyncResultIdle(deactivateDaca)

	const refreshAndClose = useCallback(() => {
		onSuccess()
		closeModal()
	}, [onSuccess, closeModal])

	const currentState = (() => {
		switch (action) {
			case DacaStatusAction.Enter:
				return enterDacaStatus
			case DacaStatusAction.Terminate:
				return terminateDacaStatus
			case DacaStatusAction.Activate:
				return activateDacaStatus
			case DacaStatusAction.Deactivate:
				return deactivateDacaStatus
		}
	})()

	const actionInHumanLanguage = {
		[DacaStatusAction.Enter]: "enable",
		[DacaStatusAction.Terminate]: "terminate",
		[DacaStatusAction.Activate]: "activate",
		[DacaStatusAction.Deactivate]: "deactivate",
	}

	const onSubmit = () => {
		switch (action) {
			case DacaStatusAction.Enter:
				return setEnterDacaState(accessToken, account)
			case DacaStatusAction.Terminate:
				return setTerminateDacaState(accessToken, account)
			case DacaStatusAction.Activate:
				return setActivateDacaState(accessToken, account)
			case DacaStatusAction.Deactivate:
				return setDeactivateDacaState(accessToken, account)
		}
	}

	return (
		<AsyncResultModal
			title={`Are you sure you want to ${actionInHumanLanguage[action]} DACA?`}
			close={refreshAndClose}
			buttonClassname="is-black"
			state={currentState}
			buttonText={`Yes, ${actionInHumanLanguage[action]} DACA`}
			successText="Successfuly changed DACA status"
			onSubmit={onSubmit}
			errorToText={(err) => err.errors[0].title}
		></AsyncResultModal>
	)
}
