import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {FindLinkedAccounts, LinkedAccountsColumns} from "../../components/LinkedAccounts/LinkedAccountsColumns"

export function LinkedAccountsPage(): ReactElement {
	const [refreshToken] = useRefreshToken()
	return (
		<>
			<TitleBar title={"Linked Accounts"}></TitleBar>

			<MainSectionTable>
				<FindLinkedAccounts
					enableOrgFilter
					enableStatusFilter
					enableSearch={true}
					limit={40}
					refreshToken={refreshToken}
					fullHeight={true}
					includedColumns={[
						LinkedAccountsColumns.id,
						LinkedAccountsColumns.org,
						LinkedAccountsColumns.customerName,
						LinkedAccountsColumns.status,
						LinkedAccountsColumns.accountNumber,
						LinkedAccountsColumns.routingNumber,
						LinkedAccountsColumns.createdAt,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
