import React from "react"
import {Box, BoxInfo} from "../../../containers/Box/Box"
import {CircleProgressBar} from "../../../components/CircleProgressBar/CircleProgressBar"
import ReactTooltip from "react-tooltip"
import Skeleton from "react-loading-skeleton"
import {calcPercent, toLongDollars, toShortDollars} from "../../../utilities/numbers"

export function BankProgramLimitSkeleton() {
	return (
		<Box>
			<div className="flex-container flex-dir-col justify-content-sb wh-100 min-h-200">
				<Skeleton width="100%" />
				<div className="flex-container flex-center gap-10 h-100">
					<div className="flex-container align-items-center">
						<Skeleton width={120} height={120} circle />
					</div>
					<div className="flex-container flex-dir-col justify-content-center w-10p">
						<Skeleton width="100%" />
						<Skeleton width="100%" />
					</div>
				</div>
			</div>
		</Box>
	)
}

interface BankProgramSizeProps {
	creditProgramLimit: number
	accountsCreditLimit: number
}

export function BankProgramLimit({creditProgramLimit, accountsCreditLimit}: BankProgramSizeProps) {
	const actual = accountsCreditLimit || 0
	const bankLimit = creditProgramLimit || 0
	const remaining = bankLimit - actual
	const percent = calcPercent(remaining, bankLimit)

	return (
		<Box>
			<div className="flex-container wh-100 flex-dir-col justify-content-sb min-h-200">
				<p className="flex-container align-items-center font-18 box-title">Bank Program Limit</p>
				<div className="flex-container wh-100 justify-content-se align-items-center gap-30">
					<CircleProgressBar percent={percent} diameter="120px">
						<BoxInfo className="flex-center" title="remaining" titleLast>
							<span data-tip={toLongDollars(remaining, {decimalPoint: true})}>{toShortDollars(remaining)}</span>
						</BoxInfo>
					</CircleProgressBar>
					<div className="flex-container justify-content-end">
						<div className="flex-container flex-dir-col justify-content-center gap-25">
							<BoxInfo className="gap-5" title="Actual (used)" titleLast>
								<span className="font-18" data-tip={toLongDollars(actual, {decimalPoint: true})}>
									{toShortDollars(actual)}
								</span>
							</BoxInfo>
							<BoxInfo className="gap-5" title="Bank Limit" titleLast>
								<span className="font-18" data-tip={toLongDollars(bankLimit, {decimalPoint: true})}>
									{toShortDollars(bankLimit)}
								</span>
							</BoxInfo>
						</div>
					</div>
				</div>
			</div>
			<ReactTooltip effect="solid" className="tooltip" />
		</Box>
	)
}
