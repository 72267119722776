import React from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {unarchiveCustomer, Customer} from "../../resources/customer"

interface CloseCustomerModalProps {
	customer: Customer
	closeModal: () => void
	onSuccess: () => void
}

function closeAndRefresh(refresh: () => void, close: () => void, delay = 0) {
	return function () {
		setTimeout(() => {
			close()
			refresh()
		}, delay)
	}
}

export function UnarchiveCustomerModal({customer, closeModal, onSuccess}: CloseCustomerModalProps) {
	const accessToken = useAccessToken()
	const [state, unarchive] = useAsyncResultIdle(unarchiveCustomer)
	return (
		<AsyncResultModal
			title="Unarchive Customer"
			close={closeAndRefresh(onSuccess, closeModal)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Unarchive Customer"
			successText="Customer has been unarchived"
			onSubmit={() => unarchive(accessToken, customer.id)}
			onSuccess={closeAndRefresh(onSuccess, closeModal, 1000)}
			errorToText={(err) => err.errors[0].title}
		>
			<p>Are you sure you want to unarchive this customer?</p>
		</AsyncResultModal>
	)
}
