import React from "react"
import {ApplicationDocumentSide} from "../../resources/application"
import {SetQueryStateItemFn} from "use-location-state"

interface ModalOppositeSideButtonProps {
	activeDocumentSide: ApplicationDocumentSide
	toggleActiveSide: () => void
	backDocumentLink: string | undefined
	frontDocumentLink: string | undefined
	setDocSideQueryParam: SetQueryStateItemFn<string>
}

export function ModalOppositeSideButton(props: ModalOppositeSideButtonProps) {
	const {activeDocumentSide, toggleActiveSide, backDocumentLink, frontDocumentLink, setDocSideQueryParam} = props

	return (
		<button
			className="opposite-img-btn"
			aria-label="opposite doc image button"
			onClick={() => {
				const updatedSideSide =
					activeDocumentSide === ApplicationDocumentSide.Front
						? ApplicationDocumentSide.Back
						: ApplicationDocumentSide.Front

				toggleActiveSide()
				setDocSideQueryParam(updatedSideSide.toLowerCase())
			}}
			data-side={activeDocumentSide === ApplicationDocumentSide.Front ? "Back Side" : "Front Side"}
		>
			<img
				alt="Back Side"
				src={activeDocumentSide === ApplicationDocumentSide.Front ? backDocumentLink : frontDocumentLink}
			/>
		</button>
	)
}
