import React, {PropsWithChildren} from "react"
import {AssignmentPageItemHeader} from "../AssignmentPageItemHeader/AssignmentPageItemHeader"
import {Assignee, AssignmentStage, AssignmentStatus} from "../../resources/assignments"
import classNames from "classnames"
import {AssignmentPageItemTitleAndDescription} from "../AssignmentPageItemTitleAndDescription/AssignmentPageItemTitleAndDescription"

interface AssignmentPageItemProps {
	assignees: Assignee[]
	status: AssignmentStatus
	stage: AssignmentStage
	title: string
	description?: string | JSX.Element
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
	disabled?: boolean
	pageItemFooter?: JSX.Element
	pageCompletedItemHeader: JSX.Element
}

export function AssignmentPageItem({
	assignees,
	status,
	stage,
	title,
	description,
	createdAt,
	statusUpdatedAt,
	isOwnersItem,
	pageItemFooter,
	pageCompletedItemHeader,
	disabled,
	children,
}: PropsWithChildren<AssignmentPageItemProps>) {
	const isAssignmentCompleted = stage === AssignmentStage.Completed
	const headerToRender = isAssignmentCompleted ? (
		pageCompletedItemHeader
	) : (
		<AssignmentPageItemHeader assignees={assignees} status={status} />
	)
	return (
		<section className="assignment-page-item">
			<div className="assignment-page-item-header">
				<div className={classNames("assignment-page-item-header-metadata", isAssignmentCompleted && "completed")}>
					{headerToRender}
				</div>
				<div className="assignment-page-item-title-and-description-container">
					<AssignmentPageItemTitleAndDescription
						stage={stage}
						title={title}
						description={description}
						createdAt={createdAt}
						statusUpdatedAt={statusUpdatedAt}
						isOwnersItem={isOwnersItem}
						disabled={disabled}
					/>
				</div>
			</div>
			<div className={classNames("assignment-page-item-content", !pageItemFooter && "no-footer")}>{children}</div>
			{pageItemFooter && <div className="assignment-page-item-footer">{pageItemFooter}</div>}
		</section>
	)
}
