import {getResource} from "./common"

export type AccountEndOfDay = {
	type: "accountEndOfDay"
	id: number
	attributes: {
		date: Date
		balance: number
		available: number
		hold: number
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: number
			}
		}
		account: {
			data: {
				type: "account"
				id: number
			}
		}
	}
}

interface GetAccountsEndOfDayRequestParam {
	accessToken: string
	since: string
	until: string
	accountId: string
	offset: number
	limit: number
}

export async function getAccountEndOfDay(requestParams: GetAccountsEndOfDayRequestParam) {
	const query = {
		page: {
			limit: requestParams.limit,
			offset: requestParams.offset,
		},
		filter: {
			since: requestParams.since,
			until: requestParams.until,
			accountId: requestParams.accountId,
		},
	}
	return await getResource<AccountEndOfDay[]>("account-end-of-day", requestParams.accessToken, query)
}
