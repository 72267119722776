import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {Bins} from "../../components/Bins/Bins"
import {useModal} from "react-modal-hook"
import NewBinModal from "../../components/Bins/NewBinModal"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import Icon from "../../components/Icon/Icon"

export function BinsPage(): ReactElement {
	const [refreshToken, refresh] = useRefreshToken()
	const [showModal, hideModal] = useModal(() => <NewBinModal close={hideModal} refresh={refresh} />)

	return (
		<>
			<TitleBar title={"Bins"}>
				<button className="button button-create" onClick={() => showModal()}>
					<Icon icon="interface-add-1" size={12} /> Create
				</button>
			</TitleBar>

			<MainSectionTable>
				<Bins limit={40} fullHeight={true} enableTitle={false} refreshToken={refreshToken} />
			</MainSectionTable>
		</>
	)
}
