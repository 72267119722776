import React, {useState} from "react"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardKey, KeyValueCardValue} from "../KeyValueCard/KeyValueCard"
import {startCase} from "lodash"
import {Account, isDepositOrFBOAccount} from "../../resources/account"
import numeral from "numeral"
import Drawer from "../Drawer/Drawer"
import AccountLimits from "../AccountLimits/AccountLimits"
import {AccountLimitsStatus} from "../../pages/Account/Account"
import classNames from "classnames"

interface AccountCardProps {
	account: Account
	accessToken: string
	showLimits?: boolean
	singleColumn?: boolean
	className?: string
}

export function AccountCard({account, accessToken, showLimits = true, singleColumn, className}: AccountCardProps) {
	const accountName = <span> {account.attributes.name} </span>
	const status = (
		<div className={"account-card-account-status"}>
			{" "}
			<span
				className={classNames(
					"account-status-indicator",
					account.attributes.status === "Open" && "is-open",
					account.attributes.status === "Frozen" && "is-frozen"
				)}
			/>{" "}
			{account.attributes.status}{" "}
		</div>
	)
	const balance = (
		<span className={classNames(account.attributes.balance < 0 && "has-text-danger")}>
			{" "}
			{numeral(account.attributes.balance / 100).format("$0,0.00")}{" "}
		</span>
	)
	const available = isDepositOrFBOAccount(account) && (
		<span className={classNames(account.attributes.available < 0 && "has-text-danger")}>
			{" "}
			{numeral(account.attributes.available / 100).format("$0,0.00")}{" "}
		</span>
	)

	const accountDetails = {
		...(accountName ? {accountName} : {}),
		...(status ? {status} : {}),
		...(balance ? {balance} : {}),
		...(available ? {available} : {}),
	}

	const [isDrawerOpen, setIsDrawerOpen] = useState(false)

	const toggleDrawer = () => {
		setIsDrawerOpen((prevState) => !prevState)
	}

	const handleReviewAccountLimitsClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault()
		toggleDrawer()
	}

	return (
		<>
			<KeyValueCard
				title={"Account Details"}
				additionalHeaderContent={
					isDepositOrFBOAccount(account) && showLimits ? (
						<div className={"is-flex"}>
							<AccountLimitsStatus accountId={account.id} accessToken={accessToken} />
							<a className="account-limits-link mr-0" href="#" onClick={handleReviewAccountLimitsClick}>
								{" "}
								Review account limits{" "}
							</a>
						</div>
					) : null
				}
				singleColumn={singleColumn}
				className={className}
			>
				<KeyValueCardContainer>
					{Object.entries(accountDetails).map(([key, value]) => {
						return (
							<React.Fragment key={key}>
								<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
								<KeyValueCardValue> {value} </KeyValueCardValue>
							</React.Fragment>
						)
					})}
				</KeyValueCardContainer>
			</KeyValueCard>
			<Drawer
				open={isDrawerOpen}
				onClose={toggleDrawer}
				direction="right"
				className="account-side-drawer"
				title={"Account Limits"}
			>
				{isDrawerOpen && <AccountLimits accountId={account.id} accessToken={accessToken} />}
			</Drawer>
		</>
	)
}
