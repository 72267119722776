import React from "react"
import Card from "../../../containers/Card/Card"
import DepositProductField from "./DepositProductField/DepositProductField"
import {DepositProductAccountFeesProps} from "./types"
import {EditMode} from "../../../resources/common"
import classNames from "classnames"
import CardTitle from "../CardTitle"

export default function AccountFees({editMode, values, setFieldValue, onBlur}: DepositProductAccountFeesProps) {
	return (
		<Card
			className="deposit-product-card-title"
			title={CardTitle({
				elementId: "AccountFees",
				title: "Account Fees",
				linkText: "Read the account fee guide",
				linkUrl: "https://docs.unit.co/fees/",
				editMode: editMode,
			})}
		>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="incomingACHFee"
					label="Incoming ACH"
					subLabel="Per ACH"
					value={values.incomingACHFee / 100}
					inputFormat="0,0.00"
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("incomingACHFee", value * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="outgoingACHFee"
					label="Outgoing ACH"
					subLabel="Per ACH"
					value={values.outgoingACHFee / 100}
					inputFormat="0,0.00"
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("outgoingACHFee", value * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="incomingWireFee"
					label="Incoming wire"
					subLabel="Per Wire"
					value={values.incomingWireFee / 100}
					inputFormat="0,0.00"
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("incomingWireFee", value * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="outgoingWireFee"
					label="Outgoing wire"
					subLabel="Per Wire"
					value={values.outgoingWireFee / 100}
					inputFormat="0,0.00"
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("outgoingWireFee", value * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="cardIssuingFee"
					label="Card issuing fee"
					subLabel="Per card"
					value={values.cardIssuingFee / 100}
					inputFormat="0,0.00"
					inputPrefix={"$"}
					onChange={(value) => setFieldValue("cardIssuingFee", value * 100)}
					onBlur={onBlur}
					inputType="number"
				/>
			</div>
		</Card>
	)
}
