import React, {ReactElement, useEffect} from "react"
import {
	useAccessToken,
	useIsBankUser,
	useIsOrgUser,
	useIsUnitTypeUser,
	useIsUnitUser,
	useUserClaimsData,
	useUserType,
} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useQueryState} from "use-location-state"
import SearchBox from "../../components/SearchBox/SearchBox"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import moment from "moment"
import {
	Account,
	AccountStatus,
	AccountType,
	DacaStatusType,
	findAccounts,
	isDepositAccount,
	isDepositOrBatchOrFBOAccount,
	isDepositOrFBOAccount,
	OverdraftStatus,
} from "../../resources/account"
import {useNavigate} from "react-router-dom"
import {startCase} from "lodash"
import {OrgName} from "../OrgName/OrgName"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import numeral from "numeral"
import {Filter} from "../Filter/Filter"
import classNames from "classnames"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faUsers} from "@fortawesome/free-solid-svg-icons"
import {accountNumberPreview, InformationHiding} from "../InformationHiding/InformationHiding"
import {isProdEnv, isSandboxEnv} from "../../utilities/environment"
import ReactTooltip from "react-tooltip"
import Icon from "../Icon/Icon"
import {Cents} from "../../resources/transaction"
import {CurrencyRangeFilter, RangePreset} from "../Filter/CurrencyRangeFilter"
import {downloadFile} from "../../utilities/file"
import {useToasts} from "react-toast-notifications"
import {Bank, findBanks} from "../../resources/bank"
import {BankName} from "../BankName/BankName"

export enum AccountsColumns {
	id = "Id",
	org = "Org",
	bank = "Bank",
	name = "Name",
	type = "Type",
	createdAt = "Created At",
	updatedAt = "Updated At",
	balance = "Balance",
	product = "Product",
	status = "Status",
	routingNumber = "Routing Number",
	accountNumber = "Account Number",
	purpose = "Purpose",
}

type AllowedAccountsColumns =
	| AccountsColumns.id
	| AccountsColumns.org
	| AccountsColumns.bank
	| AccountsColumns.name
	| AccountsColumns.type
	| AccountsColumns.createdAt
	| AccountsColumns.updatedAt
	| AccountsColumns.balance
	| AccountsColumns.product
	| AccountsColumns.status
	| AccountsColumns.routingNumber
	| AccountsColumns.accountNumber
	| AccountsColumns.purpose

interface AccountsProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedAccountsColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	type?: keyof typeof AccountType
	customerId?: string
	enableSearch?: boolean
	enableOrgFilter?: boolean
	enableBankFilter?: boolean
	enableStatusFilter?: boolean
	enableDacaStatusFilter?: boolean
	enableOverdraftStatusFilter?: boolean
	enableTypeFilter?: boolean
	refreshToken?: number
	enableTutorial?: boolean
}

interface AccountsTableProps {
	accounts: Array<Account>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedAccountsColumns>
	meta?: Meta
	fullHeight?: boolean
	enableTutorial?: boolean
	isFiltering?: boolean
	sortFunction: (sortParam: string) => void
	sortBy: string
}

interface CustomerRowProps {
	account: Account
	include?: Resource[]
	includedColumns: Array<AllowedAccountsColumns>
}

const accountTypeToFilterOptions: Record<AccountType, string> = {
	[AccountType.deposit]: "Deposit Account",
	[AccountType.batch]: "Batch Account",
	[AccountType.gl]: "General Ledger Account",
	[AccountType.creditGL]: "Credit General Ledger Account",
	[AccountType.credit]: "Credit Account",
	[AccountType.orgGeneralLedger]: "Lending General Ledger Account",
	[AccountType.orgLoan]: "Org Loan Account",
	[AccountType.financialBusinessFBO]: "Financial Account",
	[AccountType.wallet]: "Wallet Account",
}

const WALLET_ALLOWED_ORG_ID = "4299"
const isOrgUserForWallet = () => {
	const orgId = useUserClaimsData().orgId
	return useIsOrgUser() && isSandboxEnv() && orgId === WALLET_ALLOWED_ORG_ID
}
function filterAccountTypesByUserType(): AccountType[] {
	if (useIsUnitUser()) {
		return Object.values(AccountType)
	}
	if (isOrgUserForWallet()) {
		return [
			AccountType.deposit,
			AccountType.batch,
			AccountType.credit,
			AccountType.orgGeneralLedger,
			AccountType.orgLoan,
			AccountType.financialBusinessFBO,
			AccountType.wallet,
		]
	}
	if (useIsBankUser()) {
		return [
			AccountType.deposit,
			AccountType.batch,
			AccountType.gl,
			AccountType.credit,
			AccountType.orgGeneralLedger,
			AccountType.orgLoan,
			AccountType.financialBusinessFBO,
			AccountType.creditGL,
		]
	}
	if (useIsOrgUser()) {
		return [
			AccountType.deposit,
			AccountType.batch,
			AccountType.credit,
			AccountType.orgGeneralLedger,
			AccountType.orgLoan,
			AccountType.financialBusinessFBO,
		]
	}

	// Partner user
	return [
		AccountType.deposit,
		AccountType.batch,
		AccountType.credit,
		AccountType.orgGeneralLedger,
		AccountType.orgLoan,
		AccountType.gl,
		AccountType.creditGL,
	]
}

function AccountRow({account, include, includedColumns}: CustomerRowProps) {
	const navigate = useNavigate()

	const id = account.id
	// Filter out account types that do not have org, like OrgLoanAccount and GLAccount
	const org = "org" in account.relationships ? account.relationships.org.data.id : null
	const bank = "bank" in account.relationships ? account.relationships.bank.data.id : null
	const name = account.attributes.name
	const type = account.type
	const createdAt = account.attributes.createdAt
	const updatedAt = account.attributes.updatedAt
	const balance = account.attributes.balance
	const status = account.attributes.closeReason
		? `${account.attributes.status} (${account.attributes.closeReason})`
		: account.attributes.status
	const routingNumber = isDepositOrBatchOrFBOAccount(account) ? account.attributes.routingNumber : null
	const depositProduct = isDepositOrBatchOrFBOAccount(account) ? account.attributes.depositProduct : null
	const accountNumber = isDepositOrBatchOrFBOAccount(account) ? account.attributes.accountNumber : null
	const maskedAccountNumber = isDepositOrBatchOrFBOAccount(account) ? account.attributes.maskedAccountNumber : null
	const purpose = isDepositOrFBOAccount(account) ? account.attributes.tags["purpose"] || "" : null
	const customers = isDepositAccount(account) ? account.relationships.customers : null

	const contentColumns: Partial<Record<AllowedAccountsColumns, ReactElement>> = {}

	if (includedColumns.includes(AccountsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className={classNames("data-table-id")}>{id}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountsColumns.type)) {
		contentColumns["Type"] = (
			<DataTableCell>
				{startCase(type)}
				{customers && customers?.data.length > 1 ? (
					<span className="icon ml-2">
						<FontAwesomeIcon size="sm" icon={faUsers} title={"Joint Account"} />
					</span>
				) : (
					<></>
				)}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountsColumns.org)) {
		if (org) {
			contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} element={DataTableCell} />
		} else {
			contentColumns["Org"] = <DataTableCell> -- </DataTableCell>
		}
	}

	if (includedColumns.includes(AccountsColumns.bank) && bank) {
		contentColumns["Bank"] = (
			<DataTableCell>
				<BankName bankId={bank.toString()} included={include} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountsColumns.name)) {
		contentColumns["Name"] = <DataTableCell>{name}</DataTableCell>
	}

	if (includedColumns.includes(AccountsColumns.balance)) {
		contentColumns["Balance"] = (
			<DataTableCell className={classNames(balance < 0 && "has-text-danger")}>
				{numeral(account.attributes.balance / 100).format("$0,0.00")}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AccountsColumns.product)) {
		contentColumns["Product"] = <DataTableCell>{depositProduct}</DataTableCell>
	}

	if (includedColumns.includes(AccountsColumns.status)) {
		contentColumns["Status"] = <DataTableCell>{status}</DataTableCell>
	}

	if (includedColumns.includes(AccountsColumns.routingNumber)) {
		contentColumns["Routing Number"] = <DataTableCell>{routingNumber}</DataTableCell>
	}

	if (includedColumns.includes(AccountsColumns.accountNumber)) {
		if (accountNumber) {
			contentColumns["Account Number"] = (
				<DataTableCell>
					<InformationHiding
						element={(accountNumber) => <>{accountNumber}</>}
						getValue={() => accountNumber ?? ""}
						placeholder={accountNumberPreview(accountNumber)}
					/>
				</DataTableCell>
			)
		} else {
			if (maskedAccountNumber) {
				contentColumns["Account Number"] = <DataTableCell> {maskedAccountNumber.replace(/[*]/g, "●")} </DataTableCell>
			} else {
				contentColumns["Account Number"] = <DataTableCell />
			}
		}
	}

	if (includedColumns.includes(AccountsColumns.purpose)) {
		contentColumns["Purpose"] = <DataTableCell>{purpose}</DataTableCell>
	}

	if (includedColumns.includes(AccountsColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(AccountsColumns.updatedAt)) {
		contentColumns["Updated At"] = (
			<DataTableCell>
				{updatedAt ? moment(updatedAt).format("L LT") : <span className="empty-date-placeholder"> --/--/---- </span>}
			</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})
	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/accounts/${id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function AccountsTable({
	accounts,
	includedColumns,
	hasPrev,
	hasNext,
	prev,
	next,
	sortFunction,
	sortBy,
	hasResults,
	isUsingPaging,
	include,
	meta,
	fullHeight,
	enableTutorial,
	isFiltering,
}: AccountsTableProps) {
	const noContent = accounts.length === 0
	const tutorialCallButton = document.querySelector(".tutorial-call-button")

	useEffect(() => {
		if (!noContent && tutorialCallButton) {
			tutorialCallButton.classList.add("tutorial-call-button-with-animation")
		}
	}, [])

	return (
		<div className={"accounts-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={
					!isFiltering ? (
						<>
							No{" "}
							<a className="doc-link" href="https://docs.unit.co/deposit-accounts/" target="_blank" rel="noreferrer">
								{" "}
								accounts
							</a>{" "}
							yet
						</>
					) : (
						"No accounts found"
					)
				}
				noContentCtaButton={
					!isProdEnv() &&
					enableTutorial &&
					!useIsUnitTypeUser() && (
						<button
							className="tutorial-cta-button"
							data-tracking-label="tutorial-accounts-cta"
							onClick={(e) => {
								const target = e.target as HTMLButtonElement
								target.classList.add("tutorial-cta-button-was-used")

								if (tutorialCallButton) {
									ReactTooltip.show(tutorialCallButton)
								}
							}}
						>
							<Icon icon={"programming-code--programing-apps-websites"} color="white" />
							Test Create Account API
						</button>
					)
				}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === AccountsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("createdAt")}
										key={column[0]}
										sortable={sortBy.includes("createdAt")}
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							if (column[1] === AccountsColumns.updatedAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("updatedAt")}
										key={column[0]}
										sortable={sortBy.includes("updatedAt")}
										sortApplied={sortBy === "updatedAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							if (column[1] === AccountsColumns.balance) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("balance")}
										key={column[0]}
										sortable={sortBy.includes("balance")}
										sortApplied={sortBy === "balance"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							if (column[1] === AccountsColumns.type) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("type")}
										key={column[0]}
										sortable={sortBy.includes("type")}
										sortApplied={sortBy === "type"}
									>
										{column[1]}
									</DataTableCell>
								)
							}

							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{accounts.map((a) => (
						<AccountRow include={include} includedColumns={includedColumns} account={a} key={a.id} />
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export function Accounts({
	limit = 25,
	includedColumns,
	enableTitle,
	fullHeight,
	type,
	customerId,
	enableSearch,
	enableStatusFilter,
	enableDacaStatusFilter,
	enableOverdraftStatusFilter,
	enableOrgFilter,
	enableBankFilter,
	enableTypeFilter,
	refreshToken,
	enableTutorial = false,
}: AccountsProps) {
	const prefix = "ac"
	const accessToken = useAccessToken()
	const userType = useUserType()

	const overdraftStatuses = new Map(
		[OverdraftStatus.Positive, OverdraftStatus.OverdrawnWithinLimit, OverdraftStatus.Negative].map((v) => [
			v,
			startCase(v),
		])
	)

	const accountTypeFilterOptions = new Map(
		filterAccountTypesByUserType().map((typeValue) => [typeValue, accountTypeToFilterOptions[typeValue]])
	)

	const statusesOptions = new Map([AccountStatus.Open, AccountStatus.Closed, AccountStatus.Frozen].map((v) => [v, v]))
	const dacaStatusesOptions = new Map([DacaStatusType.Entered, DacaStatusType.Activated].map((v) => [v, v]))

	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [overdraftStatus, setOverdraftStatus] = useQueryState<string[]>(`${prefix}-filter[overdraftStatus]`, [])
	const [statuses, setStatuses] = useQueryState<AccountStatus[]>(`${prefix}-filter[status]`, [])
	const [dacaStatuses, setDacaStatuses] = useQueryState<DacaStatusType[]>(`${prefix}-filter[dacaStatus]`, [])
	const [fromBalance, setFromBalance] = useQueryState<Cents | "">(`${prefix}-filter[fromBalance]`, "")
	const [toBalance, setToBalance] = useQueryState<Cents | "">(`${prefix}-filter[toBalance]`, "")
	const [filteredByOrg, setFilteredByOrg] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [filteredBanks, setFilteredBanks] = useQueryState<string[]>(`${prefix}-filter[banks]`, [])
	const [accountTypes, setAccountTypes] = useQueryState<(keyof typeof AccountType)[]>(`${prefix}-filter[type]`, [])

	const {addToast} = useToasts()
	const isFiltering =
		overdraftStatus.length != 0 ||
		query != "" ||
		filteredByOrg.length != 0 ||
		statuses.length != 0 ||
		filteredBanks.length != 0

	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()
	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable={true}
				setStatuses={setFilteredByOrg}
				statuses={filteredByOrg}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const banks =
		userType === "unit"
			? useAsyncResult(() => findBanks(accessToken, 0, 10000), [])
			: AsyncResult.pending<Bank[], ErrorDocument>()

	const bankFilter = banks.match(
		() => null,
		(banks) => (
			<Filter
				title="Banks"
				isSearchable
				setStatuses={setFilteredBanks}
				statuses={filteredBanks}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						banks
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((bank) => [bank.id, bank.attributes.name])
					)
				}
			/>
		),
		(_) => null
	)

	const accountTypeFilter = (
		<Filter
			title="Type"
			statuses={accountTypes}
			setStatuses={setAccountTypes}
			onFilterFunc={() => reset(limit)}
			options={accountTypeFilterOptions}
		/>
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const dacaStatusFilter = (
		<Filter
			statuses={dacaStatuses}
			setStatuses={setDacaStatuses}
			onFilterFunc={() => reset(limit)}
			options={dacaStatusesOptions}
			title="DACA Status"
		/>
	)

	const overdraftStatusFilter = (
		<Filter
			statuses={overdraftStatus}
			setStatuses={setOverdraftStatus}
			onFilterFunc={() => reset(limit)}
			options={overdraftStatuses}
			title={"Overdraft Status"}
		/>
	)

	const balanceOptions: RangePreset[] = [
		{name: "Positive", from: 1, to: ""},
		{name: "Zero", from: 0, to: 0},
		{name: "Negative", to: -1, from: ""},
	]

	const balanceFilter = (
		<CurrencyRangeFilter
			placeholder={"Balance"}
			presets={balanceOptions}
			from={fromBalance}
			setFrom={setFromBalance}
			to={toBalance}
			setTo={setToBalance}
		/>
	)

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findAccounts({
				accessToken,
				offset,
				limit,
				sort: sortBy,
				types: (type && [type]) ?? accountTypes,
				searchQuery: query,
				overdraftStatus,
				status: statuses,
				dacaStatus: dacaStatuses,
				orgs: filteredByOrg,
				banks: filteredBanks,
				customerId,
				fromBalance,
				toBalance,
				requestType: "json",
				extraFields: customerId
					? {
							account: "maskedAccountNumber",
					  }
					: undefined,
			}),
		(x) => x.data.length,
		[
			query,
			refreshToken,
			sortBy,
			[
				...accountTypes,
				...overdraftStatus,
				...statuses,
				...dacaStatuses,
				...filteredByOrg,
				...filteredBanks,
				fromBalance,
				toBalance,
			].join(","),
		],
		prefix,
		true
	)

	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Accounts", 500, onSearch)

	async function exportAccounts() {
		const response = await findAccounts({
			accessToken,
			offset: 0,
			types: (type && [type]) ?? accountTypes,
			sort: sortBy,
			searchQuery: query,
			overdraftStatus,
			status: statuses,
			orgs: filteredByOrg,
			banks: filteredBanks,
			customerId,
			fromBalance,
			toBalance,
			requestType: "csv",
			extraFields: customerId
				? {
						account: "maskedAccountNumber",
				  }
				: undefined,
		})

		response.match(
			(d) => downloadFile("Accounts.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)

		return true
	}

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function toggleSort(columnName: string) {
		const sortParam = sortBy == `-${columnName}` ? columnName : `-${columnName}`
		setSortBy(sortParam)
		reset(limit)
	}

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	const filters = [
		...(enableTypeFilter && !type ? [accountTypeFilter] : []),
		...(enableOrgFilter ? [orgFilter] : []),
		...(enableBankFilter ? [bankFilter] : []),
		...(enableOverdraftStatusFilter ? [balanceFilter] : []),
		...(enableStatusFilter ? [statusFilter] : []),
		...(enableOverdraftStatusFilter && useIsUnitUser() ? [overdraftStatusFilter] : []),
		...(enableDacaStatusFilter ? [dacaStatusFilter] : []),
	]
	return (
		<DataTableCard className={"accounts-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={enableSearch ? searchBox : null}
				filters={filters.length === 0 ? null : filters}
				title={enableTitle ? "Accounts" : null}
				exportFunc={fullHeight ? exportAccounts : undefined}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: accounts}) => {
					return (
						<AccountsTable
							fullHeight={fullHeight}
							accounts={accounts.data}
							include={accounts.included}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={true}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
							meta={accounts.meta}
							enableTutorial={enableTutorial}
							isFiltering={isFiltering}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
