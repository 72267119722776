import React from "react"
import {BusinessApplication} from "../../resources/application"
import {BeneficialOwner} from "../../resources/beneficialOwner"
import {Address} from "../../resources/common"
import {addressText} from "../../utilities/helpers"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardSubHeader,
	KeyValueCardValue,
} from "../KeyValueCard/KeyValueCard"
import {startCase} from "lodash"

interface BeneficialOwnerCardViewProps {
	application: BusinessApplication
	title: string
	singleColumn?: boolean
	disabled?: boolean
	className?: string
}

function AddressText({address}: {address: Address}) {
	return <span className={"address-placeholder"}>{addressText(address)}</span>
}

function BeneficialOwnerCardContent({
	beneficialOwner,
	title,
	disabled,
}: {
	beneficialOwner: BeneficialOwner
	title: string
	disabled?: boolean
}) {
	const address = beneficialOwner.address && <AddressText address={beneficialOwner.address} />
	const phone = beneficialOwner.phone && `+${beneficialOwner.phone.countryCode} ${beneficialOwner.phone.number}`
	const email = beneficialOwner.email

	const boDetails = {
		...(phone ? {phone} : {}),
		...(email ? {email} : {}),
		...(address ? {address} : {}),
	}

	return (
		<>
			<KeyValueCardSubHeader title={title} />
			<KeyValueCardContainer>
				{Object.entries(boDetails).map(([key, value]) => {
					return (
						<React.Fragment key={key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue disabled={disabled}> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</>
	)
}

export function BeneficialOwnerCardView({
	application,
	title,
	singleColumn,
	disabled,
	className,
}: BeneficialOwnerCardViewProps) {
	return (
		<KeyValueCard title={title} singleColumn={singleColumn} disabled={disabled} className={className}>
			{application.attributes.beneficialOwners.map((bo) => {
				return (
					<BeneficialOwnerCardContent
						key={bo.email}
						beneficialOwner={bo}
						title={`${bo.fullName.first} ${bo.fullName.last}`}
						disabled={disabled}
					/>
				)
			})}
		</KeyValueCard>
	)
}
