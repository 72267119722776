import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"
import ReceivedPayments, {ReceivedPaymentsColumns} from "../../components/ReceivedPayments/ReceivedPayments"

export default function ReceivedPaymentsPage(): ReactElement {
	return (
		<>
			<TitleBar title={"Received Payments"} />

			<MainSectionTable>
				<ReceivedPayments
					fullHeight={true}
					enableOrgFilter={true}
					enableStatusFilter={true}
					enableTypeFilter={true}
					enableAmountFilter={true}
					disableDrawer={useIsUnitUser()}
					includedColumns={[
						ReceivedPaymentsColumns.id,
						ReceivedPaymentsColumns.type,
						...(useIsUnitUser() || useIsBankUser() ? [ReceivedPaymentsColumns.org] : []),
						ReceivedPaymentsColumns.accountId,
						ReceivedPaymentsColumns.customer,
						ReceivedPaymentsColumns.companyName,
						ReceivedPaymentsColumns.amount,
						ReceivedPaymentsColumns.status,
						ReceivedPaymentsColumns.createdAt,
						ReceivedPaymentsColumns.completionDate,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
