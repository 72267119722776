import React, {useEffect, useRef} from "react"
import {isProdEnv} from "../../utilities/environment"
import Icon from "../Icon/Icon"
import BodyPortal from "../BodyPortal/BodyPortal"
import {TutorialModal} from "./TutorialModal"
import {TutorialModalProps} from "./TutorialModal"
import classNames from "classnames"
import {useIsUnitTypeUser} from "../../services/auth"
import ReactTooltip from "react-tooltip"

type TutorialProps = {
	tutorialModalProps: TutorialModalProps
	buttonClassname?: string
	openOnLoad?: boolean
}
export function TutorialButton(props: TutorialProps) {
	const ref = useRef(null)

	useEffect(() => {
		if (props.openOnLoad) {
			setTimeout(() => {
				if (ref.current) {
					ReactTooltip.show(ref.current)
				}
			}, 500)
		}
	}, [props.openOnLoad, ref])

	if (!isProdEnv() && !useIsUnitTypeUser()) {
		return (
			<>
				<button
					ref={ref}
					className={classNames(
						"tutorial-call-button",
						`tutorial-call-button-${props.tutorialModalProps.tooltipId}`,
						props.buttonClassname
					)}
					data-tip
					data-event="click"
					data-for={props.tutorialModalProps.tooltipId}
				>
					<Icon icon={"programming-code--programing-apps-websites"} color="white" size={18} />
				</button>
				<BodyPortal>
					<TutorialModal {...props.tutorialModalProps} />
				</BodyPortal>
			</>
		)
	}
	return null
}
