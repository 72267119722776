import {useParams} from "react-router-dom"
import React, {PropsWithChildren} from "react"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {isBookPayment, Payment} from "../../resources/payment"
import {kebabCase, startCase} from "lodash"
import classNames from "classnames"
import {Customer, CustomerResourceTypes} from "../../resources/customer"
import {Account} from "../../resources/account"

interface PaymentTitleBarProps {
	payment: Payment
	included: (Account | Customer)[]
	isOrgPayment: boolean
	leftSideClassName?: string
	className?: string
}
export default function PaymentTitleBar({
	payment,
	included,
	isOrgPayment,
	leftSideClassName,
	className,
	children,
}: PropsWithChildren<PaymentTitleBarProps>) {
	const {paymentId = ""} = useParams<{paymentId: string}>()
	const customers = included && (included.filter((i) => CustomerResourceTypes.includes(i.type)) as Customer[])
	const singleCustomer = Array.isArray(customers) && customers.length === 1 ? customers[0] : null
	return (
		<TitleBar
			title={`${startCase(payment.type).replace("Payment", "")} #${paymentId}`}
			breadcrumbs={[{text: "Payments", path: "/payments/"}]}
			status={{
				text: startCase(payment.attributes.status),
				className: classNames("payments-status", kebabCase(payment.attributes.status)),
			}}
			meta={[
				{
					text: "Account",
					icon: "bank-account--business-products",
					path: `/${isOrgPayment ? "org-" : ""}accounts/${payment.relationships.account?.data.id}`,
				},
				{
					text: "Customer",
					icon: "user-geometric-action-money-fund---users",
					path: `/customer/${payment.relationships.customer?.data.id}`,
				},
				...(singleCustomer
					? [
							{
								text: "Application",
								icon: "user-id-card",
								path: `/applications/${singleCustomer?.relationships.application?.data.id}`,
								targetBlank: true,
							} as MetaItemProps,
					  ]
					: []),
				...(payment.relationships.account?.data.id && payment.relationships.transaction?.data.id
					? [
							{
								text: "Transaction",
								icon: "money-transfer--business-products",
								path: `/${isOrgPayment ? "org-" : ""}transaction/${payment.relationships.account?.data.id}/${
									payment.relationships.transaction?.data.id
								}`,
							} as MetaItemProps,
					  ]
					: []),
				...(isBookPayment(payment) && payment.relationships.counterpartyAccount?.data.id
					? [
							{
								text: "Counterparty",
								icon: "bank-account--business-products",
								path: `/${isOrgPayment ? "org-" : ""}account/${payment.relationships.counterpartyAccount?.data.id}`,
							} as MetaItemProps,
					  ]
					: []),
			]}
			leftSideClassName={leftSideClassName}
			className={className}
		>
			{children}
		</TitleBar>
	)
}
