import React, {createContext, PropsWithChildren, useEffect} from "react"
import useAsyncResultIdle from "../hooks/useAsyncResultIdle"
import {getClaims} from "../resources/permissions"
import {Claims, useAccessToken, useIsAuthenticated, useIsSSOAuthenticated} from "./auth"
import Loading from "../components/Loading/Loading"
import {Err} from "../components/Err/Err"
import {ChangePasswordModal} from "../pages/ChangePassword/ChangePasswordModal"

type ClaimsContextState = Claims | undefined

export const claimsContext = createContext<ClaimsContextState>(undefined)

export function ClaimsProvider({children}: PropsWithChildren<{}>) {
	let accessToken: string | undefined = undefined
	const [getClaimsState, getClaimsFunc] = useAsyncResultIdle(getClaims)
	const isAuthenticated = useIsAuthenticated()
	const isSSOAuthenticated = useIsSSOAuthenticated()

	useEffect(() => {
		if (accessToken) {
			getClaimsFunc(accessToken)
		}
	}, [accessToken, isAuthenticated])

	if (isAuthenticated) {
		accessToken = useAccessToken()

		return getClaimsState.match(
			() => <Loading />,
			() => <Loading />,
			(c) => {
				if (isSSOAuthenticated || c.passwordSetAt)
					return <claimsContext.Provider value={c.claims}>{children}</claimsContext.Provider>
				else
					return (
						<claimsContext.Provider value={c.claims}>
							<ChangePasswordModal close={() => window.location.reload()} />
						</claimsContext.Provider>
					)
			},
			() => <Err err="Something went wrong." />
		)
	} else {
		return <claimsContext.Provider value={undefined}>{children}</claimsContext.Provider>
	}
}
