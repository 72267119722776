import React from "react"
import classNames from "classnames"
import EmptyStateGraphics from "./EmptyStateGraphics"
import {isEmpty} from "lodash"

export type TimelineItem = {
	header: JSX.Element
	content?: JSX.Element
}

export type TimelineProps = {
	title?: string
	items?: TimelineItem[]
	shouldAnimateItem?: (index: number) => boolean
}

function EmptyTimeline() {
	return (
		<div className="timeline-empty-state">
			<EmptyStateGraphics />
			<span>No records available yet</span>
		</div>
	)
}

export function Timeline({title, items}: TimelineProps) {
	return !items || isEmpty(items) ? (
		<EmptyTimeline />
	) : (
		<div>
			{title && <div className="timeline-title">{title}</div>}
			<div className="timeline-container">
				{items.map((item, index) => {
					return (
						<div key={index} className="timeline-grouping">
							<div className={classNames("timeline-header")}>
								<div className="circle" />
								{item.header}
							</div>
							{item.content && <ul className="timeline-content">{item.content}</ul>}
						</div>
					)
				})}
			</div>
		</div>
	)
}
