import React, {PropsWithChildren} from "react"
import Icon from "../Icon/Icon"

interface UnitPilotWelcomeModalInterface {
	close: () => void
}

export function UnitPilotWelcomeModal(props: PropsWithChildren<UnitPilotWelcomeModalInterface>) {
	return (
		<div className="modal is-active unit-pilot-welcome-modal">
			<div className="modal-background" />
			<div className="modal-card">
				<header className="modal-card-head">
					<span onClick={props.close} className="close-icon">
						<Icon icon="interface-delete-interface-essential" size={20} color="#FFFFFF" />
					</span>
					<div className="title">Welcome to pilot</div>
					<div className="subtitle">Build and test your product with real funds</div>
				</header>
				<div className="modal-card-body">
					<div className="description-title">
						{"Because pilot is in Unit's live environment, it has some limitations, such as:"}
					</div>
					<ul className="limitations-list">
						<li>
							<span className="icon-wrapper">
								<Icon icon="team-approve-disapprove--work-office" size={20} color="black" />
							</span>
							<div>
								You may create two customers. <strong>One individual and one business</strong>. Up to one account per
								customer
							</div>
						</li>
						<li>
							<span className="icon-wrapper">
								<Icon icon="billing-warning--business-products" size={20} />
							</span>
							<div>All transaction limits are set to low daily and monthly amounts</div>
						</li>
						<li>
							<span className="icon-wrapper">
								<Icon icon="account-mobile-payment-wiring--business-products" size={20} />
							</span>
							<div>
								You can create <strong>one virtual card</strong> for each account
							</div>
						</li>
						<li>
							<span className="icon-wrapper">
								<Icon icon="analytic-percentage--business-products" size={20} />
							</span>
							<div>
								<strong>All fees are set to $0</strong>, and no interest or interchange revenue is distributed
							</div>
						</li>
						<li>
							<span className="icon-wrapper">
								<Icon icon="money-transfer--business-products" size={20} />
							</span>
							<div>Outgoing wires, ACH debits and check deposits are not enabled</div>
						</li>
						<li>
							<span className="icon-wrapper">
								<Icon icon="user-geometric-action-lock---users" size={20} />
							</span>
							<div>Creating new Dashboard users has been disabled</div>
						</li>
					</ul>
					<button className="button is-success" onClick={props.close}>
						Get Started
					</button>
				</div>
			</div>
		</div>
	)
}
