import {createResource, getResource, RiskRate, updateResource} from "./common"
import {OrgCustomerToken} from "../services/orgAuth"

type OrgBalancesSummary = {
	accounts: number
	balances: number
}

export type Org = {
	type: "org"
	id: string
	attributes: {
		createdAt: Date
		name: string
		customerId?: number
		orgInterchangeShare: number
		statementFont?: string
		statementLogo?: string
		statementHeaderBackground?: string
		defaultCustomerRiskRate: RiskRate
		isUnitPilot?: boolean
		verificationServiceId?: string
		enableTwoFactorAuthentication: boolean
		orgBalancesSummary?: OrgBalancesSummary
	}
}

export type SandboxClient = {
	type: "sandboxClient"
	id: string
	attributes: {
		depositProductId: number
	}
	relationships: {
		revenueAccount: {
			data: {
				type: string
				id: string
			}
		}
		reserveAccount: {
			data: {
				type: string
				id: string
			}
		}
		customer: {
			data: {
				type: string
				id: string
			}
		}
	}
}

export enum OrgMode {
	Standard = "Standard",
	Pilot = "Pilot",
}

export type AccountsMetrics = {
	type: "orgAccountsMetrics"
	id: string
	attributes: {
		accounts: number
		balances: number
	}
}

export function isOrg(data: any): data is Org {
	return data?.type === "org"
}

export async function getOrg(accessToken: string, orgId: string) {
	const query = {
		fields: {
			org: "createdAt,name,customerId,orgInterchangeShare,statementFont,statementLogo,statementHeaderBackground,defaultCustomerRiskRate,isUnitPilot,verificationServiceId,enableTwoFactorAuthentication",
		},
	}
	const result = await getResource<Org>(`orgs/${orgId}`, accessToken, query)
	return result.map((v) => v.data)
}

async function getOrgs(
	accessToken: string,
	offset: number,
	limit: number,
	searchQuery?: string,
	orgMode?: OrgMode[],
	extraFields?: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			query: searchQuery != "" ? searchQuery : null,
			mode: orgMode,
		},
		fields: {
			org: "createdAt,name,customerId,orgInterchangeShare,statementFont,statementLogo,statementHeaderBackground,defaultCustomerRiskRate,isUnitPilot,verificationServiceId,enableTwoFactorAuthentication",
		},
		...(extraFields ? {extraFields: {org: extraFields}} : {}),
	}

	return await getResource<Array<Org>>("orgs", accessToken, query)
}

export async function getCapitalPartnerOrgs(accessToken: string, bankId: string, orgId: string) {
	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			bankId,
		},
	}

	const result = await getResource<Array<Org>>("orgs", accessToken, query)
	return result.map((v) => v.data.filter((org) => org.id != orgId))
}

export async function findOrgs(
	accessToken: string,
	offset: number,
	limit: number,
	sortByName = true,
	searchQuery?: string
) {
	const orgs = await getOrgs(accessToken, offset, limit, searchQuery)
	return orgs.map((v) => {
		const data = v.data
		if (sortByName) data.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
		return data
	})
}

export async function findOrgsWithPaginationMeta(
	accessToken: string,
	offset: number,
	limit: number,
	sortByName = true,
	searchQuery?: string,
	orgMode?: OrgMode[],
	extraFields?: string
) {
	const orgs = await getOrgs(accessToken, offset, limit, searchQuery, orgMode, extraFields)
	return orgs.map((v) => {
		if (sortByName) v.data.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
		return v
	})
}

export async function createOrg(
	accessToken: string,
	name: string,
	customerId: string | null,
	bankId: string | null,
	orgInterchangeShare: number,
	defaultCustomerRiskRate: RiskRate,
	enableTwoFactorAuthentication: boolean
) {
	const result = await createResource<Org>("orgs", accessToken, {
		type: "org",
		attributes: {
			name,
			customerId: customerId ? parseInt(customerId) : null,
			bankId: bankId ? parseInt(bankId) : null,
			orgInterchangeShare: orgInterchangeShare,
			defaultCustomerRiskRate,
			enableTwoFactorAuthentication,
		},
	})
	return result.map((v) => v.data)
}

export async function updateOrg(
	accessToken: string,
	id: string,
	customerId: string | null,
	defaultCustomerRiskRate: RiskRate,
	verificationServiceId: string | undefined,
	name: string,
	enableTwoFactorAuthentication: boolean
) {
	const result = await updateResource<Org>(`orgs/${id}/edit`, accessToken, {
		type: "org",
		customerId: customerId ? parseInt(customerId) : null,
		defaultCustomerRiskRate: defaultCustomerRiskRate,
		verificationServiceId: verificationServiceId,
		name: name,
		enableTwoFactorAuthentication,
	})
	return result.map((v) => v.data)
}

export async function getOrgCustomerToken(accessToken: string) {
	const result = await getResource<OrgCustomerToken>("orgs/token/getCustomerToken", accessToken)
	return result.map((v) => v.data)
}

export async function createSandboxClient(
	accessToken: string,
	orgName: string,
	firstName: string,
	lastName: string,
	primaryEmail: string,
	password: string,
	emailTemplateId: string
) {
	const result = await createResource<SandboxClient>("sandbox/client", accessToken, {
		type: "sandboxClient",
		attributes: {
			orgName: orgName,
			primaryEmail: primaryEmail,
			name: {
				first: firstName,
				last: lastName,
			},
			password: password,
			emailTemplateId: emailTemplateId,
		},
	})

	return result.map((v) => v.data)
}

export function getOrgName(org: Org) {
	return org.attributes.name + (org.attributes.isUnitPilot ? " (Pilot)" : "")
}

export async function fetchOrgName(accessToken: string) {
	const result = await getResource<string>("orgs/org/getName", accessToken)

	return result.map((v) => v.data)
}

export async function fetchOrgId(accessToken: string) {
	const result = await getResource<string>("orgs/org/getId", accessToken)

	return result.map((v) => v.data)
}

export async function graduateOrg(
	accessToken: string,
	id: string,
	bankId: string,
	customerId: string,
	revenueAccountId: string,
	reserveAccountId: string,
	defaultCustomerRiskRate: RiskRate
) {
	return await createResource<Org>(`orgs/${id}/graduate`, accessToken, {
		type: "graduateOrg",
		attributes: {
			defaultCustomerRiskRate: defaultCustomerRiskRate,
		},
		relationships: {
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
			revenueAccount: {
				data: {
					type: "account",
					id: revenueAccountId,
				},
			},
			reserveAccount: {
				data: {
					type: "account",
					id: reserveAccountId,
				},
			},
			customer: {
				data: {
					type: "customer",
					id: customerId,
				},
			},
		},
	})
}
export const getDepositAccountsMetrics = async (accessToken: string, orgId: string) =>
	await getResource<AccountsMetrics>(`orgs/${orgId}/deposit-accounts-metrics`, accessToken)
