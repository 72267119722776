import {Cents} from "./transaction"
import {createResource, getFile, getResource, getResourceAsArrayBuffer} from "./common"

export enum IncomingCheckStatus {
	PendingReview = "PendingReview",
	Processed = "Processed",
	MarkedForReturn = "MarkedForReturn",
	Returned = "Returned",
	Errored = "Errored",
}

export enum CheckTypeType {
	Check = "Check",
	Return = "Return",
}

export type IncomingCheckReasonCode =
	| "A"
	| "B"
	| "C"
	| "D"
	| "E"
	| "F"
	| "G"
	| "H"
	| "M"
	| "N"
	| "O"
	| "P"
	| "Q"
	| "S"
	| "U"
	| "Y"
	| "3"
	| "4"

interface IncomingCheckImageGetParam {
	accessToken: string
	id: string
	side: IncomingCheckImageSide
}

export enum IncomingCheckImageSide {
	Front,
	Back,
}

interface IncomingCheckReturnParam {
	accessToken: string
	id: string
	reason: string
}

interface IncomingCheckApproveParam {
	accessToken: string
	id: string
}

export async function findIncomingChecks(
	accessToken: string,
	offset: number,
	searchQuery: string,
	limit?: number | null,
	statusFilter?: IncomingCheckStatus[],
	orgs?: string[],
	banks?: string[],
	since?: string,
	until?: string,
	fromAmount?: Cents | "",
	toAmount?: Cents | "",
	requestType?: "json" | "csv"
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const bankFilter = banks && banks.length > 0 ? banks : undefined
	const query = {
		page: {
			limit: limit,
			offset,
		},
		filter: {
			status: statusFilter,
			query: searchQuery != "" ? searchQuery : null,
			orgs: orgFilter,
			banks: bankFilter,
			filterBySince: since != "" ? since : null,
			filterByUntil: until != "" ? until : null,
			filterByFromAmount: fromAmount != "" ? fromAmount : null,
			filterByToAmount: toAmount != "" ? toAmount : null,
		},
		include: "bank,account,customer,org",
	}

	switch (requestType) {
		case "csv":
			return await getFile("incoming-checks", accessToken, query)
		default:
			return await getResource<Array<IncomingCheck>>("incoming-checks", accessToken, query)
	}
}

export async function getIncomingCheckRequest(requestParam: IncomingCheckGetParam) {
	const query = {
		include: "customer,org,user,account",
	}

	return await getResource<IncomingCheck>(`incoming-checks/${requestParam.id}`, requestParam.accessToken, query)
}

export async function getIncomingCheckImage(requestParam: IncomingCheckImageGetParam) {
	switch (requestParam.side) {
		case IncomingCheckImageSide.Front:
			return await getResourceAsArrayBuffer(`incoming-checks/${requestParam.id}/front`, requestParam.accessToken)
		default:
			return await getResourceAsArrayBuffer(`incoming-checks/${requestParam.id}/back`, requestParam.accessToken)
	}
}

export async function returnIncomingCheck({id, accessToken, reason}: IncomingCheckReturnParam) {
	const data = {
		type: "incomingCheckReturn",
		attributes: {
			returnReasonCode: reason,
		},
	}

	return await createResource<IncomingCheck>(`incoming-checks/${id}/return`, accessToken, data)
}

export async function approveIncomingCheck({id, accessToken}: IncomingCheckApproveParam) {
	const data = {
		type: "incomingCheckApprove",
	}
	return await createResource<IncomingCheck>(`incoming-checks/${id}/approve`, accessToken, data)
}

interface IncomingCheckGetParam {
	accessToken: string
	id: string
	include?: "customer, account, org" | ""
}

type Customer = {
	type: string
	id: string
}

type Account = {
	type: string
	id: string
}

export enum CallbackStatusType {
	Required = "Required",
	Approved = "Approved",
}

export type NameMismatchReviewReasons = {
	namesScoreMap: {
		[key: string]: string
	}
}

export type PendingReviewReasons = Array<{
	nameMissMatch?: NameMismatchReviewReasons
	dailySoftLimit?: {}
	monthlySoftLimit?: {}
}>

export type IncomingCheck = {
	type: "incomingCheck"
	id: string
	attributes: {
		createdAt: Date
		updatedAt: Date
		status: IncomingCheckStatus
		checkType: CheckTypeType
		amount: Cents
		checkDepositReturnReason?: IncomingCheckReasonCode
		fileName: string
		checkNumber?: string
		itemSequenceNumber: string
		description?: string
		returnCutoff: Date
		accountNumber: string
		pendingReviewReasons: PendingReviewReasons
		callbackStatus: CallbackStatusType
		counterpartyRoutingNumber?: string
		payeeName?: string
	}
	relationships: {
		account?: {
			data: Account
		}
		bank: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		org?: {
			data: {
				type: string
				id: string | null
			}
		}
		transaction?: {
			data: {
				type: string
				id: number | null
			}
		}
	}
}
