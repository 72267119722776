import React, {PropsWithChildren, ReactNode, useState} from "react"
import {range} from "lodash"
import Skeleton from "react-loading-skeleton"
import Icon from "../Icon/Icon"
import classNames from "classnames"

interface KeyValueCardItemProps {
	disabled?: boolean
	children: React.ReactNode
	props?: React.HTMLProps<HTMLDivElement>
}

export function KeyValueCardKey({children, ...props}: PropsWithChildren<KeyValueCardItemProps>) {
	return (
		<div className={"key-value-card-container-key"} {...props}>
			{children}
		</div>
	)
}

export function KeyValueCardSubHeader({title}: {title?: string}) {
	return (
		<div className={"key-value-card-sub-header"}>
			<h4> {title} </h4>
		</div>
	)
}

export function KeyValueCardValue({disabled, children, ...props}: PropsWithChildren<KeyValueCardItemProps>) {
	return (
		<div className={classNames("key-value-card-container-value", disabled && "disabled")} {...props}>
			{children}
		</div>
	)
}

export function KeyValueCardPair(
	keyChildren: ReactNode,
	valueChildren?: ReactNode
): React.ReactFragment | undefined | null {
	return (
		valueChildren && (
			<>
				<KeyValueCardKey>{keyChildren}</KeyValueCardKey>
				<KeyValueCardValue>{valueChildren}</KeyValueCardValue>
			</>
		)
	)
}

export function KeyValueCardContainer({children, className}: {children: React.ReactNode; className?: string}) {
	return <div className={classNames("key-value-card-container", className)}>{children}</div>
}

interface KeyValueCardProps {
	title?: string
	children: React.ReactNode
	copyFunction?: () => void
	additionalHeaderContent?: React.ReactNode
	singleColumn?: boolean
	disabled?: boolean
	className?: string
	transparent?: boolean
}

export function KeyValueCard({
	title,
	children,
	copyFunction,
	additionalHeaderContent,
	singleColumn,
	disabled,
	className,
	transparent,
}: KeyValueCardProps) {
	const [copyState, setCopyState] = useState(false)
	return (
		<section
			className={classNames(
				"key-value-card",
				singleColumn && "single-column",
				!transparent && "with-background",
				className
			)}
		>
			{title && (
				<div className={classNames("key-value-card-header", singleColumn && "single-column")}>
					<h3 className={classNames("key-value-card-title", disabled && "disabled")}> {title} </h3>
					{copyFunction && (
						<button
							className={classNames("button is-white key-value-copy", copyState && "show-and-fade-out")}
							onClick={() => {
								setCopyState(true)
								copyFunction()
								setTimeout(() => {
									setCopyState(false)
								}, 500)
							}}
						>
							<Icon icon="common-files" color="#2B2C2F" size={16} />{" "}
							<span> {!copyState ? "Copy Data" : "Copied"} </span>
						</button>
					)}
					{additionalHeaderContent}
				</div>
			)}

			{children}
		</section>
	)
}

export function KeyValueCardPending({rows}: {rows: number}) {
	return (
		<KeyValueCard>
			<KeyValueCardContainer>
				{range(rows).map((i) => {
					return (
						<React.Fragment key={i}>
							<KeyValueCardKey>
								{" "}
								<Skeleton />{" "}
							</KeyValueCardKey>
							<KeyValueCardValue>
								{" "}
								<Skeleton />{" "}
							</KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}
