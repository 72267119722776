import React, {ReactElement, useEffect} from "react"
import {
	Application,
	ApplicationStatus,
	DecisionMethodType,
	exportApplications,
	findApplication,
	getApplicationName,
} from "../../resources/application"
import {
	useAccessToken,
	useIsBankUser,
	useIsUnitComplianceUser,
	useIsUnitSuperUser,
	useIsUnitTypeUser,
	useUserType,
} from "../../services/auth"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {usePaging} from "../../hooks/usePaging"
import {useQueryState} from "use-location-state"
import SearchBox from "../../components/SearchBox/SearchBox"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {Filter} from "../Filter/Filter"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import moment from "moment"
import {downloadFile} from "../../utilities/file"
import {useToasts} from "react-toast-notifications"
import classNames from "classnames"
import {kebabCase, startCase} from "lodash"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {OrgName} from "../OrgName/OrgName"
import {useNavigate} from "react-router-dom"
import Icon from "../Icon/Icon"
import ReactTooltip from "react-tooltip"
import {isProdEnv} from "../../utilities/environment"

export enum ApplicationsColumns {
	id = "Id",
	type = "Type",
	org = "Org",
	name = "Name",
	status = "Status",
	evaluation = "Evaluation",
	createdAt = "Created At",
	updatedAt = "Updated At",
}

type AllowedApplicationsColumns =
	| ApplicationsColumns.id
	| ApplicationsColumns.type
	| ApplicationsColumns.org
	| ApplicationsColumns.name
	| ApplicationsColumns.status
	| ApplicationsColumns.evaluation
	| ApplicationsColumns.createdAt
	| ApplicationsColumns.updatedAt

interface ApplicationsProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedApplicationsColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	setNonDeniedIndividualApplicationsNum?: (n: number) => void
	setNonDeniedBusinessApplicationsNum?: (n: number) => void
	refreshToken: number
}

interface ApplicationsTableProps {
	applications: Array<Application>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedApplicationsColumns>
	fullHeight?: boolean
	meta?: Meta
	isFiltering?: boolean
	sortFunction: () => void
	sortBy: string
}

interface ApplicationRowProps {
	application: Application
	include?: Resource[]
	includedColumns: Array<AllowedApplicationsColumns>
}

export function ArchivedBadge() {
	return (
		<div className={classNames("is-flex", "ml-2")}>
			<div className={classNames("status-badge", "archived")}>Archived</div>
		</div>
	)
}

function ApplicationRow({application, include, includedColumns}: ApplicationRowProps) {
	const navigate = useNavigate()

	const id = application.id
	const org = application.relationships.org?.data.id
	const status = application.attributes.status
	const createdAt = application.attributes.createdAt
	const updatedAt = application.attributes.updatedAt
	const name = getApplicationName(application)
	let evaluation = ""
	let iconType: JSX.Element = <></>

	switch (application.type) {
		case "businessApplication":
			evaluation = application.attributes.evaluationOutcome
			iconType = <Icon icon={"toolbox"} />
			break
		case "individualApplication":
			evaluation = application.attributes.evaluationOutcome
			iconType = <Icon icon={"user-geometric-action---users"} />
			break
	}

	const contentColumns: Partial<Record<AllowedApplicationsColumns, ReactElement>> = {}

	if (includedColumns.includes(ApplicationsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<div className={classNames("data-table-id", "is-flex", "is-align-items-center")}>{id}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ApplicationsColumns.type)) {
		contentColumns["Type"] = <DataTableCell>{iconType}</DataTableCell>
	}

	if (includedColumns.includes(ApplicationsColumns.org)) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} element={DataTableCell} />
	}

	if (includedColumns.includes(ApplicationsColumns.name)) {
		contentColumns["Name"] = <DataTableCell>{name}</DataTableCell>
	}

	if (includedColumns.includes(ApplicationsColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("applications-status", kebabCase(status))}>{startCase(status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ApplicationsColumns.evaluation)) {
		contentColumns["Evaluation"] = (
			<DataTableCell>
				<div className={classNames("applications-status", kebabCase(evaluation))}>{startCase(evaluation)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(ApplicationsColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	if (includedColumns.includes(ApplicationsColumns.updatedAt)) {
		contentColumns["Updated At"] = (
			<DataTableCell>
				{updatedAt ? moment(updatedAt).format("L LT") : <span className="empty-date-placeholder"> --/--/---- </span>}
			</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})
	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/applications/${id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function ApplicationsTable({
	applications,
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	isUsingPaging,
	include,
	includedColumns,
	fullHeight,
	meta,
	isFiltering,
	sortFunction,
	sortBy,
}: ApplicationsTableProps) {
	const noContent = applications.length === 0
	const tutorialCallButton = document.querySelector(".tutorial-call-button")

	useEffect(() => {
		if (!noContent && tutorialCallButton) {
			tutorialCallButton.classList.add("tutorial-call-button-with-animation")
		}
	}, [])

	return (
		<div className={"applications-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={
					<>
						{!isFiltering ? (
							<>
								No customer{" "}
								<a className="doc-link" href="https://docs.unit.co/#applications" target="_blank" rel="noreferrer">
									applications
								</a>{" "}
								yet
							</>
						) : (
							"No customer applications found"
						)}
					</>
				}
				noContentCtaButton={
					!isProdEnv() &&
					!isFiltering &&
					!useIsUnitTypeUser() && (
						<button
							className="tutorial-cta-button"
							data-tracking-label="tutorial-applications-cta"
							onClick={(e) => {
								const target = e.target as HTMLButtonElement
								target.classList.add("tutorial-cta-button-was-used")
								if (tutorialCallButton) {
									ReactTooltip.show(tutorialCallButton)
								}
							}}
						>
							<Icon icon={"programming-code--programing-apps-websites"} color="white" />
							Test Create Application API
						</button>
					)
				}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === ApplicationsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{applications.map((application) => (
						<ApplicationRow
							key={application.id}
							application={application}
							includedColumns={includedColumns}
							include={include}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export function Applications({
	limit = 25,
	includedColumns,
	fullHeight,
	enableTitle,
	setNonDeniedBusinessApplicationsNum,
	setNonDeniedIndividualApplicationsNum,
	refreshToken,
}: ApplicationsProps) {
	const prefix = "app"
	const userType = useUserType()
	const {addToast} = useToasts()
	const statusesOptions = new Map(
		[
			ApplicationStatus.Approved,
			ApplicationStatus.Denied,
			ApplicationStatus.PendingReview,
			ApplicationStatus.AwaitingDocuments,
			ApplicationStatus.Pending,
			ApplicationStatus.Canceled,
		].map((v) => [v, startCase(v)])
	)
	const [statuses, setStatuses] = useQueryState(`${prefix}-filter[status]`, Array.from(statusesOptions.keys()))
	const decisionMethodOptions = new Map(
		[DecisionMethodType.Manually, DecisionMethodType.InProgress, DecisionMethodType.Automatically].map((v) => [
			v,
			startCase(v),
		])
	)
	const [decisionMethods, setDecisionMethods] = useQueryState(
		`${prefix}-filter[decisionMethod]`,
		Array.from(decisionMethodOptions.keys())
	)

	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const accessToken = useAccessToken()
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findApplication(accessToken, offset, limit, statuses, sortBy, query, filteredOrgs, decisionMethods),
		(x) => x.data.length,
		[sortBy, query, [...statuses, ...filteredOrgs, ...decisionMethods].join(","), refreshToken],
		prefix,
		true
	)
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Applications", 500, onSearch)
	const isFiltering =
		filteredOrgs.length != 0 ||
		query != "" ||
		statuses.length != statusesOptions.size ||
		decisionMethods.length != decisionMethodOptions.size
	const orgs =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const decisionMethodFilter = (
		<Filter
			statuses={decisionMethods}
			setStatuses={setDecisionMethods}
			onFilterFunc={() => reset(limit)}
			options={decisionMethodOptions}
			title="Decision Method"
		/>
	)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	async function exportApplicationsFunc() {
		const response = await exportApplications(accessToken, statuses, sortBy, query, filteredOrgs)

		response.match(
			(d) => downloadFile("Applications.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)

		return true
	}

	return (
		<DataTableCard className={"applications-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={searchBox}
				exportFunc={exportApplicationsFunc}
				filters={[
					orgFilter,
					statusFilter,
					...(useIsUnitSuperUser() || useIsUnitComplianceUser() || useIsBankUser() ? [decisionMethodFilter] : []),
				]}
				title={enableTitle ? "Applications" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: applications}) => {
					const nonDeniedIndividualApplicationsNum = applications.data.filter(
						(a) => a.type === "individualApplication" && a.attributes.status !== ApplicationStatus.Denied
					).length
					const nonDeniedBusinessApplicationsNum = applications.data.filter(
						(a) => a.type === "businessApplication" && a.attributes.status !== ApplicationStatus.Denied
					).length
					useEffect(() => {
						if (setNonDeniedBusinessApplicationsNum && nonDeniedIndividualApplicationsNum) {
							setNonDeniedBusinessApplicationsNum(nonDeniedIndividualApplicationsNum)
						}
						if (setNonDeniedIndividualApplicationsNum && nonDeniedBusinessApplicationsNum) {
							setNonDeniedIndividualApplicationsNum(nonDeniedBusinessApplicationsNum)
						}
					}, [])

					return (
						<ApplicationsTable
							applications={applications.data}
							include={applications.included}
							isFiltering={isFiltering}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={true}
							includedColumns={includedColumns}
							fullHeight={fullHeight}
							meta={applications.meta}
							sortFunction={toggleSort}
							sortBy={sortBy}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
