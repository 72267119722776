import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import Admonition from "../Admonitions/Admonition"
import {returnIncomingCheck} from "../../resources/incomingCheck"

interface ReturnCheckModalProps {
	id: string
	closeModal: () => void
	onSuccess: () => void
}

const presetReasons = {
	"Not Sufficient Funds": "A",
	"Uncollected Funds Hold": "B",
	"Stop Payment": "C",
	"Closed Account": "D",
	"Unable to Locate Account": "E",
	"Frozen/Blocked Account": "F",
	"Stale Dated": "G",
	"Post Dated": "H",
	"Non-Cash Item": "M",
	"Altered/Fictitious": "N",
	"Unable to Process": "O",
	"Item Exceeds Dollar Limit": "P",
	"Not Authorized": "Q",
	"Refer to Maker": "S",
	"Unusable Image": "U",
	"Duplicate Presentment": "Y",
	"Warranty Breach": "3",
	"ROC Warranty Breach": "4",
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ReturnCheckModal({id, closeModal, onSuccess}: ReturnCheckModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState(presetReasons["Altered/Fictitious"])
	const [rejectedState, setReturnedState] = useAsyncResultIdle(returnIncomingCheck)

	return (
		<AsyncResultModal
			title={`You Are About to Return Check ID #${id}`}
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-danger"
			state={rejectedState}
			buttonText="return"
			successText="Check is returned"
			onSubmit={() => {
				setReturnedState({id, accessToken, reason})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<div className="select">
						<select onChange={(e) => setReason(e.target.value)} value={reason}>
							{Object.entries(presetReasons).map((reason) => {
								return (
									<option key={reason[1]} value={reason[1]}>
										{reason[1]} - {reason[0]}
									</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
			<Admonition
				type={"is-danger"}
				message={
					<p>
						{
							"Marking an incoming check as returned is a final action and cannot be undone. This will not actually initiate a return, it will only change the incoming check status to reflect that it has been returned by the bank that issued the check"
						}
					</p>
				}
				title={"Warning"}
			/>
		</AsyncResultModal>
	)
}
