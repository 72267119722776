import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {createPartnerUser} from "../../resources/user"
import {nameRegex, Username} from "./Shared"
import {SelectUserRoleField, UserRolesExplanationTooltip} from "./UserRoleField"
import PhoneNumber from "../PhoneNumberLine/PhoneNumber"

type CloseFunc = () => void
type RefreshFunc = () => void

interface ModalProps {
	close: CloseFunc
	refresh: RefreshFunc
}

export function PartnerNewUserModal({close, refresh}: ModalProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createPartnerUser)
	const [username, setUsername] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [role, setRole] = useState("partner")
	const [countryCode, setCountryCode] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")

	return (
		<AsyncResultModal
			title="Create new partner user"
			onSubmit={() => create(accessToken, username, firstName, lastName, role, {countryCode, number: phoneNumber})}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<Username username={username} setUsername={setUsername} />
			<div className="field">
				<label className="label">First Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="First Name"
						value={firstName}
						pattern={nameRegex}
						title="No special characters are allowed"
						required
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Last Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="Last Name"
						value={lastName}
						pattern={nameRegex}
						title="No special characters are allowed"
						required
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<PhoneNumber
					countryCodeValue={countryCode}
					countryCodeOnChange={setCountryCode}
					phoneNumberValue={phoneNumber}
					phoneNumberOnChange={setPhoneNumber}
				/>
			</div>
			<div className="field">
				<label className="label">
					User Role <UserRolesExplanationTooltip type="partner" />
				</label>
				<SelectUserRoleField type="partnerUser" role={role} setRole={setRole} />
			</div>
		</AsyncResultModal>
	)
}
