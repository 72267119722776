export type AsyncResultRequestState<T, E> = Pending<T, E> | Ok<T, E> | Err<T, E>

export const AsyncResult = {
	pending: <T, E>(): Pending<T, E> => new Pending(),
	ok: <T, E>(value: T): Ok<T, E> => new Ok(value),
	err: <T, E>(err: E): Err<T, E> => new Err(err),
	zip: <T1, T2, E>(
		a: AsyncResultRequestState<T1, E>,
		b: AsyncResultRequestState<T2, E>
	): AsyncResultRequestState<[T1, T2], E> => {
		return a.bind((aValue) => b.map((bValue) => [aValue, bValue]))
	},
	zip3: <T1, T2, T3, E>(
		a: AsyncResultRequestState<T1, E>,
		b: AsyncResultRequestState<T2, E>,
		c: AsyncResultRequestState<T3, E>
	): AsyncResultRequestState<[T1, T2, T3], E> => {
		return a.bind((aValue) => b.bind((bValue) => c.map((cValue) => [aValue, bValue, cValue])))
	},
	zip4: <T1, T2, T3, T4, E>(
		a: AsyncResultRequestState<T1, E>,
		b: AsyncResultRequestState<T2, E>,
		c: AsyncResultRequestState<T3, E>,
		d: AsyncResultRequestState<T4, E>
	): AsyncResultRequestState<[T1, T2, T3, T4], E> => {
		return a.bind((aValue) =>
			b.bind((bValue) => c.bind((cValue) => d.map((dValue) => [aValue, bValue, cValue, dValue])))
		)
	},
	zip5: <T1, T2, T3, T4, T5, E>(
		a: AsyncResultRequestState<T1, E>,
		b: AsyncResultRequestState<T2, E>,
		c: AsyncResultRequestState<T3, E>,
		d: AsyncResultRequestState<T4, E>,
		e: AsyncResultRequestState<T5, E>
	): AsyncResultRequestState<[T1, T2, T3, T4, T5], E> => {
		return a.bind((aValue) =>
			b.bind((bValue) =>
				c.bind((cValue) => d.bind((dValue) => e.map((eValue) => [aValue, bValue, cValue, dValue, eValue])))
			)
		)
	},
	zip6: <T1, T2, T3, T4, T5, T6, E>(
		a: AsyncResultRequestState<T1, E>,
		b: AsyncResultRequestState<T2, E>,
		c: AsyncResultRequestState<T3, E>,
		d: AsyncResultRequestState<T4, E>,
		e: AsyncResultRequestState<T5, E>,
		f: AsyncResultRequestState<T6, E>
	): AsyncResultRequestState<[T1, T2, T3, T4, T5, T6], E> => {
		return a.bind((aValue) =>
			b.bind((bValue) =>
				c.bind((cValue) =>
					d.bind((dValue) => e.bind((eValue) => f.map((fValue) => [aValue, bValue, cValue, dValue, eValue, fValue])))
				)
			)
		)
	},
	zip7: <T1, T2, T3, T4, T5, T6, T7, E>(
		a: AsyncResultRequestState<T1, E>,
		b: AsyncResultRequestState<T2, E>,
		c: AsyncResultRequestState<T3, E>,
		d: AsyncResultRequestState<T4, E>,
		e: AsyncResultRequestState<T5, E>,
		f: AsyncResultRequestState<T6, E>,
		g: AsyncResultRequestState<T7, E>
	): AsyncResultRequestState<[T1, T2, T3, T4, T5, T6, T7], E> => {
		return a.bind((aValue) =>
			b.bind((bValue) =>
				c.bind((cValue) =>
					d.bind((dValue) =>
						e.bind((eValue) =>
							f.bind((fValue) => g.map((gValue) => [aValue, bValue, cValue, dValue, eValue, fValue, gValue]))
						)
					)
				)
			)
		)
	},
}

export class Pending<T, E> {
	isOk(): this is Ok<T, E> {
		return false
	}

	isPending(): this is Pending<T, E> {
		return true
	}

	isErr(): this is Err<T, E> {
		return false
	}

	iter(_fn: (value: T) => void) {
		// only ok implements iter
	}

	bind<A>(_f: (t: T) => AsyncResultRequestState<A, E>): AsyncResultRequestState<A, E> {
		return AsyncResult.pending()
	}

	map<A>(_f: (t: T) => A): AsyncResultRequestState<A, E> {
		return AsyncResult.pending()
	}

	mapErr<U>(_f: (e: E) => U): AsyncResultRequestState<T, U> {
		return AsyncResult.pending()
	}

	match = <A>(pending: () => A, _ok: (t: T) => A, _err: (e: E) => A): A => {
		return pending()
	}
}

export class Ok<T, E> {
	value: T
	constructor(value: T) {
		this.value = value
	}

	isOk(): this is Ok<T, E> {
		return true
	}

	isPending(): this is Pending<T, E> {
		return false
	}

	isErr(): this is Err<T, E> {
		return false
	}

	iter(fn: (value: T) => void) {
		fn(this.value)
	}

	bind<A>(f: (t: T) => AsyncResultRequestState<A, E>): AsyncResultRequestState<A, E> {
		return f(this.value)
	}

	map<A>(f: (t: T) => A): AsyncResultRequestState<A, E> {
		return AsyncResult.ok(f(this.value))
	}

	mapErr<U>(_f: (e: E) => U): AsyncResultRequestState<T, U> {
		return AsyncResult.ok(this.value)
	}

	match = <A>(_pending: () => A, ok: (t: T) => A, _err: (e: E) => A): A => {
		return ok(this.value)
	}
}

export class Err<T, E> {
	error: E
	constructor(error: E) {
		this.error = error
	}

	isOk(): this is Ok<T, E> {
		return false
	}

	isPending(): this is Pending<T, E> {
		return false
	}

	isErr(): this is Err<T, E> {
		return !this.isOk()
	}

	iter(_fn: (value: T) => void) {
		// only ok implements iter
	}

	bind<A>(_f: (t: T) => AsyncResultRequestState<A, E>): AsyncResultRequestState<A, E> {
		return AsyncResult.err(this.error)
	}

	map<A>(_f: (t: T) => A): AsyncResultRequestState<A, E> {
		return AsyncResult.err(this.error)
	}

	mapErr<U>(f: (e: E) => U): AsyncResultRequestState<T, U> {
		return AsyncResult.err(f(this.error))
	}

	match = <A>(_pending: () => A, _ok: (t: T) => A, err: (e: E) => A): A => {
		return err(this.error)
	}
}
