import React, {FormEvent, useEffect, useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {useToasts} from "react-toast-notifications"
import {SecretsObject} from "../../resources/orgSettings"
import {ErrorDocument} from "../../resources/common"
import classNames from "classnames"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import Avatar from "../Avatar/Avatar"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"

interface Props {
	providerName: string
	providerLink?: string
	providerLogo: IcomoonIconName
	saved?: boolean
	description: React.ReactElement
	credentialsCallback: (accessToken: string, obj: SecretsObject) => Promise<any | ErrorDocument>
}

export function IntegrationCredentials({
	saved,
	description,
	providerLogo,
	providerLink,
	providerName,
	credentialsCallback,
}: Props) {
	const [isSaved, setIsSaved] = useState(saved)
	const [clientId, setClientId] = useState<string>("")
	const [secret, setClientSecret] = useState<string>("")
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [isLoading, setIsLoading] = useState(false)
	const [state, update] = useAsyncResultIdle(credentialsCallback)

	useEffect(() => {
		state.match(
			() => null,
			() => setIsLoading(true),
			() => {
				addToast(`Credentials Saved`, {appearance: "success"})
				setTimeout(() => {
					setIsLoading(false)
					setIsSaved(true)
					setClientId("")
					setClientSecret("")
				}, 200)
			},
			(e: any) => addToast(`${e.errors[0].title} - ${e.errors[0].detail}`, {appearance: "error"})
		)
	}, [state])

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()
		if (clientId && secret) {
			update(accessToken, {clientId, secret})
		}
	}
	return (
		<div className={"integration-credentials"}>
			<GridContainer gap={"16px"} className={"fit-content"} columns={2}>
				<Avatar size={42} name={providerName} icon={providerLogo} borderRadius={0}></Avatar>
				<div className={"provider-info"}>
					<span className={"provider-name"}> {providerName} </span>
					{providerLink && (
						<a href={`${providerLink}`} rel="noreferrer" target="_blank">
							<span>{`See credentials in ${providerName}`}</span>
							<Icon
								icon="maximize-square-2--interface-essential"
								size={12}
								style={{position: "relative", top: "2px"}}
							/>
						</a>
					)}
				</div>
			</GridContainer>

			<span className={"description"}>{description}</span>
			<form onSubmit={handleSubmit}>
				<div className="field">
					<label className="label">Client ID</label>
					<div className="control">
						<input
							autoComplete={"new-password"}
							className={classNames(isSaved && "is-saved", "input")}
							required
							type="password"
							value={clientId}
							placeholder={isSaved ? "•••••••••••" : "Ex: 619b66a529a9d0001c6e264e"}
							onChange={(e) => setClientId(e.target.value)}
						/>
					</div>
				</div>

				<div className="field">
					<label className="label">Client secret</label>
					<div className="control">
						<input
							autoComplete={"new-password"}
							className={classNames(isSaved && "is-saved", "input")}
							required
							type="password"
							value={secret}
							placeholder={isSaved ? "•••••••••••" : "Ex: 619b66a529a9d0001c6e264e"}
							onChange={(e) => setClientSecret(e.target.value)}
						/>
					</div>
				</div>

				<button type="submit" className={classNames(isLoading && "is-loading", "button is-success")}>
					Set credentials
				</button>
			</form>
		</div>
	)
}
