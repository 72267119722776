import HorizontalField from "../HorizontalField/HorizontalField"
import React from "react"
import {Editable, EditableElementProps} from "../HorizontalField/EditableHorizontalField"
import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getAccountDepositProducts} from "../../resources/account"

export interface EditableDepositProductProps {
	depositProduct: string
	setDepositProduct: (value: string) => void
	accountId: string
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: string) => void
}

export function DepositProductField({depositProduct}: {depositProduct: string}) {
	return (
		<HorizontalField label="Deposit Product">
			<input type="text" readOnly value={depositProduct} className="input is-static" />
		</HorizontalField>
	)
}

export function EditableDepositProductField(props: EditableDepositProductProps) {
	return (
		<Editable
			label="Deposit Product"
			isEditing={props.isEditing}
			isDisabled={props.isDisabled}
			isAllowed={props.isAllowed}
			onStartEdit={props.onStartEdit}
			onCancel={props.onCancel}
			setValue={props.setDepositProduct}
			value={props.depositProduct}
			initial={props.depositProduct}
			update={props.update}
			accountId={props.accountId}
		>
			{EditableDepositProduct}
		</Editable>
	)
}

export function EditableDepositProduct(props: EditableElementProps<string>) {
	const accessToken = useAccessToken()
	const result = useAsyncResult(() => getAccountDepositProducts(accessToken, props.accountId))

	if (props.isEditing) {
		return result.match(
			() => <></>,
			(depositProducts) => (
				<div className="select is-small">
					<select value={props.value} onChange={(e) => props.setValue(e.target.value)}>
						{depositProducts.data.map((dp) => (
							<option key={dp.attributes.name} value={dp.attributes.name}>
								{dp.attributes.name}
							</option>
						))}
					</select>
				</div>
			),
			(_) => <>Error</>
		)
	} else {
		return <input type="text" readOnly value={props.value} className="input is-static" />
	}
}
