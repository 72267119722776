import Textfield from "../../../../components/Address/Textfield"
import Dropdown from "../../../../components/Address/Dropdown"
import React from "react"
import {DepositProductFieldEditProps} from "../types"
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch"

export default function DepositProductFieldEdit({
	type,
	name,
	subName,
	label,
	subLabel,
	value,
	subValue,
	onChange,
	onBlur,
	checked,
	inputType,
	children,
	inputPrefix,
	inputSuffix,
	...inputProps
}: DepositProductFieldEditProps) {
	switch (type) {
		case "input":
			return (
				<Textfield
					name={name}
					label={label as string}
					subLabel={subLabel}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					type={inputType}
					prefix={inputPrefix}
					suffix={inputSuffix}
					{...inputProps}
				/>
			)
		case "select":
			return (
				<Dropdown
					name={name}
					label={label as string}
					subLabel={subLabel}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					{...inputProps}
				>
					{children}
				</Dropdown>
			)
		case "checkbox":
			return (
				<ToggleSwitch
					className="margin-bottom"
					inputType={inputType}
					name={name}
					subName={subName}
					text={label}
					subLabel={subLabel}
					checked={checked}
					subValue={subValue}
					onChange={onChange}
					onBlur={onBlur}
					{...inputProps}
				>
					{children}
				</ToggleSwitch>
			)
	}

	return <></>
}
