import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {declineLinkedAccount, LinkedAccount} from "../../resources/linkedAccount"

interface DeclineLinkedAccountModalProps {
	linkedAccount: LinkedAccount
	close: () => void
	onSuccess: () => void
}

function Reason({reason, setReason}: {reason: string; setReason: (reason: string) => void}) {
	return (
		<div className="field">
			<label className="label">Reason</label>
			<div className="control">
				<textarea
					className="textarea"
					maxLength={255}
					placeholder="Decline reason"
					value={reason}
					onChange={(e) => setReason(e.target.value)}
				/>
			</div>
		</div>
	)
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function DeclineLinkedAccountModal({linkedAccount, close, onSuccess}: DeclineLinkedAccountModalProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState("")
	const [state, decline] = useAsyncResultIdle(declineLinkedAccount)

	return (
		<AsyncResultModal
			title="Decline Linked Account"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Decline"
			successText="Declined"
			onSubmit={() => decline(accessToken, linkedAccount.id, reason)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<Reason reason={reason} setReason={setReason} />
			</div>
		</AsyncResultModal>
	)
}
