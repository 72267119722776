import {Result} from "neverthrow"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckSquare, faEdit, faWindowClose} from "@fortawesome/free-solid-svg-icons"
import {
	datePreview,
	InformationHiding,
	miltilinePreview,
	phonePreview,
	ssnPreview,
} from "../InformationHiding/InformationHiding"
import React, {useEffect, useState} from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import {Address, FullName, Phone} from "../../resources/common"
import {countryRegex} from "../../utilities/validation"
import {useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import {isEmpty, isUndefined} from "lodash"
import CountryDropdown from "../AddressDropdowns/CountryDropdown"
import StateDropdown from "../AddressDropdowns/StateDropdown"
import {US_COUNTRY_CODE, CA_COUNTRY_CODE, usPostalCode, caPostalCode} from "../../resources/address"

export interface EditableElementProps<T> {
	setValue: (value: T) => void
	value: T
	isEditing: boolean
	[x: string]: any
}

interface BaseEditableProps<T> {
	label?: string
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	setValue: (value: T) => void
	update: (value: T) => void
	children(props: EditableElementProps<T>): JSX.Element
	[x: string]: any
}

interface EditableProps<T> extends BaseEditableProps<T> {
	value: T
	initial: T
}

interface OptionalEditableProps<T> extends BaseEditableProps<T> {
	value?: T
	initial?: T
}

export function EditableHorizontalField<T, E, Args extends any[]>({
	attributeLabel,
	labelClassName,
	isAllowed = false,
	isEditing = false,
	isDisabled = false,
	showOnView,
	showOnEdit,
	onStartEdit,
	onCancel,
}: {
	attributeLabel: string
	attribute?: string
	labelClassName?: string
	isAllowed: boolean
	isEditing: boolean
	isDisabled: boolean
	onStartEdit: () => void
	onCancel: () => void
	showOnView: () => JSX.Element
	showOnEdit: () => JSX.Element
	onUpdate: (...args: Args) => Promise<Result<T, E>>
}) {
	return (
		<div className="static-field">
			<label className={classNames("label", labelClassName)}>{attributeLabel}</label>
			<div className="control">
				<div className="hiding-information">
					<div style={{flex: 1}}>{isEditing ? showOnEdit() : showOnView()}</div>
					{isAllowed ? (
						<div>
							<button
								className="button is-link is-inverted is-small"
								disabled={isDisabled && !isEditing}
								onClick={() => {
									isEditing ? onCancel() : onStartEdit()
								}}
							>
								<FontAwesomeIcon icon={isEditing ? faWindowClose : faEdit} />
							</button>
							{isEditing ? (
								<button className="button is-link is-inverted is-small">
									<FontAwesomeIcon icon={faCheckSquare} />
								</button>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

export function OptionalEditable<T>({value, initial, children, ...props}: OptionalEditableProps<T>) {
	if (!isUndefined(value) && !isUndefined(initial)) {
		return (
			<Editable initial={initial} value={value} {...props}>
				{children}
			</Editable>
		)
	}

	return null
}

export function Editable<T>({
	label,
	setValue,
	value,
	update,
	children,
	isEditing,
	isDisabled,
	isAllowed,
	onStartEdit,
	onCancel,
	...rest
}: EditableProps<T>) {
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					update(value)
				}}
			>
				<div className={classNames("static-field editable-field", rest?.className)}>
					{label ? <label className={classNames("label")}>{label}</label> : null}

					<div className="control">
						<div className="hiding-information">
							<div style={{flex: 1}}>{children({setValue, value, isEditing, ...rest})}</div>
							{rest.tooltip ? rest.tooltip : null}
							{isAllowed ? (
								<div>
									<button
										className="button is-link is-inverted is-small"
										disabled={isDisabled && !isEditing}
										onClick={(e) => {
											e.preventDefault()
											isEditing ? onCancel() : onStartEdit()
										}}
									>
										<FontAwesomeIcon icon={isEditing ? faWindowClose : faEdit} />
									</button>
									{isEditing ? (
										<button type="submit" className="button is-link is-inverted is-small">
											<FontAwesomeIcon icon={faCheckSquare} />
										</button>
									) : null}
								</div>
							) : null}
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export function EditableHiddenString(props: EditableElementProps<string>) {
	if (props.isEditing) {
		return (
			<input
				type={props.inputType ?? "text"}
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
				className="input"
				required
				pattern={props.pattern ?? undefined}
			/>
		)
	} else {
		return (
			<InformationHiding
				element={(value) => <input type="text" readOnly value={value} className="input is-static" />}
				getValue={() => props.value}
				placeholder={props.previewFunction ? props.previewFunction(props.value) : ssnPreview(props.value)}
				defaultIsHidden={!useIsUnitComplianceUser()}
				isHiddenIconVisible={props.isHiddenIconVisible}
			/>
		)
	}
}

export function EditableString(props: EditableElementProps<string>) {
	if (props.isEditing) {
		return (
			<input
				type={props.inputType ?? "text"}
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
				className="input"
				required
				pattern={props.pattern ?? undefined}
			/>
		)
	} else {
		return <input type="text" readOnly value={props.value} className="input is-static" />
	}
}

export function EditableOptionalString(props: EditableElementProps<string | undefined>) {
	if (props.isEditing) {
		return (
			<input
				type={props.inputType ?? "text"}
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
				className="input"
				required={props.required ?? true}
				pattern={props.pattern ?? undefined}
			/>
		)
	} else {
		return <input type="text" readOnly value={props.value} className="input is-static" placeholder="Empty" />
	}
}

export function EditableDate(props: EditableElementProps<Date>) {
	const CustomInput = (props: React.HTMLProps<HTMLInputElement>) => {
		return <input type="text" readOnly className="input" value={props.value} onClick={props.onClick} />
	}

	if (props.isEditing) {
		return (
			<DatePicker
				dateFormat="MM/dd/yyyy"
				selected={props.value}
				onChange={(date) => props.setValue(date as Date)}
				customInput={React.createElement(React.forwardRef(CustomInput))}
				showYearDropdown
				scrollableYearDropdown
			/>
		)
	} else {
		return (
			<InformationHiding
				element={(value) => <input type="text" readOnly value={value} className="input is-static" />}
				getValue={() => moment(props.value).format("L")}
				placeholder={datePreview()}
				defaultIsHidden={!useIsUnitComplianceUser()}
				isHiddenIconVisible={props.isHiddenIconVisible}
			/>
		)
	}
}

export function EditableYear(props: EditableElementProps<string>) {
	if (props.isEditing) {
		return (
			<input
				type="number"
				max={new Date().getFullYear()}
				value={props.value}
				onChange={(e) => props.setValue(e.target.value)}
				className="input"
				required
			/>
		)
	} else {
		return <input type="text" readOnly value={props.value} className="input is-static" />
	}
}

export function EditableAddress(props: EditableElementProps<Address>) {
	const [street, setStreet] = useState(props.value.street)
	const [street2, setStreet2] = useState(props.value.street2)
	const [city, setCity] = useState(props.value.city)
	const [state, setState] = useState(props.value.state)
	const [postalCode, setPostalCode] = useState(props.value.postalCode)
	const [country, setCountry] = useState(props.value.country)

	function addressText() {
		const street2 = props.value.street2 ? `${props.value.street2}\n` : ""
		const city = props.value.state
			? `${props.value.city}, ${props.value.state} ${props.value.postalCode}\n`
			: `${props.value.city} ${props.value.postalCode}\n`

		return `${props.value.street}\n${street2}${city}${props.value.country}`
	}

	useEffect(() => {
		props.setValue({
			street,
			street2: street2 === "" ? undefined : street2,
			city: city,
			state: state === "" ? undefined : state,
			postalCode: postalCode,
			country: country,
		})
	}, [street, street2, city, state, postalCode, country])

	const text = addressText()

	if (props.isEditing) {
		return (
			<div className="multiline-edit">
				<input
					type="text"
					placeholder="Street"
					value={street}
					required
					onChange={(e) => setStreet(e.target.value)}
					className="input"
				/>
				<input
					type="text"
					placeholder="Street2"
					value={street2}
					onChange={(e) => setStreet2(e.target.value)}
					className="input"
				/>
				<input
					type="text"
					placeholder="City"
					value={city}
					required
					className="input"
					onChange={(e) => setCity(e.target.value)}
				/>
				{country == US_COUNTRY_CODE || country == CA_COUNTRY_CODE ? (
					<StateDropdown
						value={state}
						countryValue={country}
						onChange={(e) => {
							setState(e.target.value)
						}}
					/>
				) : (
					<input
						type="text"
						placeholder="State"
						value={state}
						required
						className="input"
						onChange={(e) => setState(e.target.value)}
					/>
				)}
				<input
					type="text"
					placeholder="Postal Code"
					value={postalCode}
					required
					className="input"
					onChange={(e) => setPostalCode(e.target.value)}
					pattern={
						country == US_COUNTRY_CODE
							? usPostalCode.source
							: country == CA_COUNTRY_CODE
							? caPostalCode.source
							: undefined
					}
				/>
				<CountryDropdown value={country} onChange={(e) => setCountry(e.target.value)} />
			</div>
		)
	} else {
		if (!useIsUnitUser()) {
			return (
				<InformationHiding
					element={(value) => (
						<textarea
							readOnly
							value={value}
							rows={props.value.street2 ? 4 : 3}
							className="textarea is-static has-fixed-size"
						/>
					)}
					getValue={() => text}
					placeholder={miltilinePreview(text)}
				/>
			)
		}

		return (
			<textarea
				readOnly
				value={text}
				rows={props.value.street2 ? 4 : 3}
				className="textarea is-static has-fixed-size"
			/>
		)
	}
}

export function EditablePhone(props: EditableElementProps<Phone>) {
	const [countryCode, setCountryCode] = useState(props.value.countryCode)
	const [number, setNumber] = useState(props.value.number)
	const isEmptyPhoneNumber = isEmpty(countryCode) && isEmpty(number)

	function PhoneText() {
		return `+${props.value.countryCode} ${props.value.number}`
	}

	useEffect(() => {
		props.setValue({
			countryCode,
			number,
		})
	}, [countryCode, number])

	const text = PhoneText()

	if (props.isEditing) {
		return (
			<div className="multiline-edit">
				<input
					type="text"
					placeholder="Country Code"
					value={countryCode}
					required
					pattern="^(1|7|(\d{2,3})|(1\d{3}))$"
					onChange={(e) => setCountryCode(e.target.value)}
					className="input"
				/>
				<input
					type="text"
					placeholder="Number"
					value={number}
					required
					pattern="^\d+$"
					onChange={(e) => setNumber(e.target.value)}
					className="input"
				/>
			</div>
		)
	} else {
		return (
			<InformationHiding
				element={(value) => <input type="text" readOnly value={value} className="input is-static" />}
				getValue={() => text}
				placeholder={isEmptyPhoneNumber ? "" : phonePreview(text)}
				defaultIsHidden={!useIsUnitComplianceUser()}
				isHiddenIconVisible={!isEmptyPhoneNumber && props.isHiddenIconVisible}
			/>
		)
	}
}

export function EditablePassport(props: EditableElementProps<[string, string]>) {
	const [nationality, setNationality] = useState(props.value[0])
	const [passport, setPassport] = useState(props.value[1])

	function PhoneText() {
		return `${props.value[0]} ${props.value[1]}`
	}

	useEffect(() => {
		props.setValue([nationality, passport])
	}, [nationality, passport])

	const text = PhoneText()

	if (props.isEditing) {
		return (
			<div className="multiline-edit">
				<input
					type="text"
					placeholder="Nationality"
					value={nationality}
					required
					pattern={countryRegex}
					onChange={(e) => setNationality(e.target.value)}
					className="input"
				/>
				<input
					type="text"
					placeholder="Passport"
					value={passport}
					required
					onChange={(e) => setPassport(e.target.value)}
					className="input"
				/>
			</div>
		)
	} else {
		return (
			<InformationHiding
				element={(value) => <input type="text" readOnly value={value} className="input is-static" />}
				getValue={() => text}
				placeholder={phonePreview(text)}
				defaultIsHidden={!useIsUnitComplianceUser()}
				isHiddenIconVisible={props.isHiddenIconVisible}
			/>
		)
	}
}

export function EditableFullName(props: EditableElementProps<FullName>) {
	const [first, setFirst] = useState(props.value.first)
	const [last, setLast] = useState(props.value.last)

	useEffect(() => {
		props.setValue({
			first,
			last,
		})
	}, [first, last])

	if (props.isEditing) {
		return (
			<div className="multiline-edit">
				<input
					type="text"
					placeholder="First Name"
					value={first}
					required
					onChange={(e) => setFirst(e.target.value)}
					className="input"
				/>
				<input
					type="text"
					placeholder="Last Name"
					value={last}
					required
					onChange={(e) => setLast(e.target.value)}
					className="input"
				/>
			</div>
		)
	} else {
		return <input type="text" readOnly value={`${props.value.first} ${props.value.last}`} className="input is-static" />
	}
}

export interface EditableDropDownProps<T> extends EditableElementProps<T> {
	possibleValues: Map<string, T>
}

export function EditableDropDownField<T extends string>(props: EditableDropDownProps<T>) {
	if (props.isEditing) {
		return (
			<div className="select is-small">
				<select
					value={props.value}
					onChange={(e) => {
						const val = e.target.value
						props.setValue(val as T)
					}}
					className="drop-down-item"
				>
					{Array.from(props.possibleValues.keys()).map((key) => {
						return (
							<option value={key} key={key}>
								{props.possibleValues.get(key)}
							</option>
						)
					})}
				</select>
			</div>
		)
	} else {
		return (
			<input
				type="text"
				readOnly
				value={props.possibleValues.get(props.value)}
				className="input is-static drop-down-item"
				placeholder="Empty"
			/>
		)
	}
}
