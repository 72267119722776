import {createResource, getResource} from "./common"
import {Claims} from "../services/auth"

const bankSettingsPath = "bank-settings"

export enum CertificateStatus {
	Verified = "Verified",
	Unverified = "Unverified",
}

export type LegalDocsSettings = {
	dnsDomain: string
	certificateStatus: CertificateStatus
}

export type BankSettings = {
	type: "bankSettings"
	id: string
	attributes: {
		authorizedUserCreationPolicy: string
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: number
			}
		}
	}
}

export type BankSettingsWithLegalDocs = BankSettings & {
	attributes: {
		legalDocsSettings: LegalDocsSettings
	}
}

export function isBankSettingsWithLegalDocs(bankSettings: BankSettings): bankSettings is BankSettingsWithLegalDocs {
	return (bankSettings as BankSettingsWithLegalDocs).attributes.legalDocsSettings !== undefined
}

export type DnsRecords = {
	type: "dnsRecords"
	attributes: {
		domain: {
			key: string
			value: string
		}
		certificate: {
			key: string
			value: string
		}
	}
}

export async function getByToken(accessToken: string, claims: Claims) {
	const result = await getResource<Array<BankSettings>>(
		`${bankSettingsPath}?filter[banks][0]=${claims.bankId}`,
		accessToken
	)
	return result.map((v) => v.data[0])
}

export async function setupDomain(accessToken: string, domainName: string) {
	const result = await createResource<BankSettings>(`${bankSettingsPath}/setup-domain`, accessToken, {
		type: "bankSettings",
		attributes: {
			dnsDomain: domainName,
		},
	})
	return result.map((v) => v.data)
}

export async function verifyDomain(accessToken: string) {
	const result = await createResource<BankSettings>(`${bankSettingsPath}/verify-domain`, accessToken, {})
	return result.map((v) => v.data)
}

export async function deleteDomain(accessToken: string) {
	const result = await createResource<BankSettings>(`${bankSettingsPath}/delete-domain`, accessToken, {})
	return result.map((v) => v.data)
}

export async function getClientDomainConfig(accessToken: string) {
	const result = await getResource<DnsRecords>(`${bankSettingsPath}/dns-records`, accessToken)
	return result.map((v) => v.data)
}
