import React from "react"
import ItemsList from "../ItemsList/ItemsList"
import {DetailsList} from "../DetailsList/DetailsList"
import Admonition from "../Admonitions/Admonition"
import {startCase} from "lodash"

const instructionsData = [
	"Please perform a callback with the script below.",
	"In the call summary box below, enter the name and phone number of the representative you spoke with, and a summary of their responses to questions.",
	"Attach the audio recording of the call below.",
]
const guidanceData = [
	"Call should be under 5 minutes.",
	"If a message is left, you need to know when the customer calls back and what to do.",
	"It’s important to speak with the Business Contact, Business Officer or a designated authorized person.",
	'Do not provide the exact callback amount - "We may perform callbacks on wires from time to time. We determine thresholds that require a callback. Since wires are final we want to ensure this wire was originated by you."',
]
const introductionData = [
	"This is [support member name] calling from [client]. I am calling to confirm the outgoing wire that is pending on your account. Please note that this call is being recorded for auditing purposes.",
	"Before we get started with the wire details can you please confirm your identity by answering a couple questions:\n",
]
const detailsData = [
	"Purpose of Wire",
	"Amount of Wire",
	"Beneficiary Name and Account number",
	"How did you obtain the wire instructions?",
]

interface CallbackInstructionsAndGuidanceProps {
	disabled?: boolean
	orgNames: string[]
}

export default function CallbackInstructionsAndGuidance({disabled, orgNames}: CallbackInstructionsAndGuidanceProps) {
	const orgNamesDisplayText = orgNames.map((name) => startCase(name)).join(", ")
	const questionsData = [
		"What is the last 4 of SSN?",
		"What was the amount of your last deposit?",
		"What was the amount of your last withdrawal?",
		...(orgNames.length > 0 ? [`How many accounts do you currently have open with ${orgNamesDisplayText}?`] : []),
	]

	return (
		<div className="callback-guidance-container">
			<div className="callback-guidance-body">
				<ItemsList title="Instructions" items={instructionsData} showNumbering showBulletOutline disabled={disabled} />
				<ItemsList
					title="Guidance"
					items={guidanceData}
					itemIcon="add-plus-small"
					showBulletOutline
					disabled={disabled}
				/>
			</div>
			<div className="callback-guidance-script">
				<DetailsList
					title="Callback Script"
					icon="programming-language-code--programing-apps-websites"
					theme="secondary"
					className="callback-guidance-script-content"
					disabled={disabled}
				>
					<ItemsList title="Introduction" items={introductionData} className="introduction" disabled={disabled} />
					<ItemsList
						title="Select only 2 of the following questions:"
						items={questionsData}
						itemIcon="question-circle"
						className="questions"
						showBulletOutline
						iconSize={16}
						disabled={disabled}
					/>
					<ItemsList
						title="Please confirm all the following wire details:"
						items={detailsData}
						itemIcon="right-arrow"
						className="details"
						iconSize={16}
						disabled={disabled}
					/>
				</DetailsList>
				<Admonition
					type="is-warning"
					title="Note"
					message="If they received instructions via email, recommend that they also confirm the wire details over the phone or in person, as emails can be intercepted. Please note that wires are final and can not be returned."
				/>
			</div>
		</div>
	)
}
