import React from "react"
import HorizontalField from "../HorizontalField/HorizontalField"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import BodyPortal from "../BodyPortal/BodyPortal"
import ReactTooltip from "react-tooltip"

type Tags = {[key: string]: string}

export default function TagsViewer({tags, fieldClassName}: {tags: Tags; fieldClassName?: string}) {
	return (
		<>
			{Object.keys(tags).map((k) => (
				<HorizontalField
					key={k}
					label={k}
					fieldClassName={"vertical-field " + fieldClassName ?? ""}
					useLabelTitle={true}
				>
					<input type="text" readOnly value={tags[k]} title={tags[k]} className="input is-static" />
				</HorizontalField>
			))}
		</>
	)
}

type TagsViewerKeyValueProps = {
	tags: Tags
	size?: "small" | "medium"
	keyClassName?: string
	valueClassName?: string
	tooltipContent?: React.ReactNode
	noMargin?: boolean
	light?: boolean
}
export function TagsViewerKeyValue({
	tags,
	size = "medium",
	keyClassName,
	valueClassName,
	tooltipContent,
	noMargin,
	light,
}: TagsViewerKeyValueProps) {
	return (
		<div className="tags-key-value-viewer">
			{Object.entries(tags).map((tag) => {
				return (
					<div
						className={classNames("tags-key-value-viewer-tag", size === "small" && "is-small")}
						style={noMargin ? {margin: 0} : {}}
						key={tag[0]}
					>
						<span className={classNames("tags-key-value-viewer-tag-key")}>
							<div className={classNames(!!keyClassName && keyClassName)}>{tag[0]}</div>
						</span>
						<span
							className={classNames(
								"tags-key-value-viewer-tag-value",
								size === "small" && "is-small",
								light && "light",
								!!valueClassName && valueClassName
							)}
						>
							<span className={classNames(tooltipContent && "with-icon")}>{tag[1]}</span>
							{tooltipContent && (
								<>
									<Icon
										icon="information-circle--interface-essential"
										size={size === "small" ? 12 : 16}
										style={{verticalAlign: "middle"}}
										data-tip="React-tooltip"
									/>
									<BodyPortal>
										<ReactTooltip type="dark" effect="solid" className={"tooltip-info"}>
											{tooltipContent}
										</ReactTooltip>
									</BodyPortal>
								</>
							)}
						</span>
					</div>
				)
			})}
		</div>
	)
}
