import {createResource, getResource, updateResource} from "./common"

export enum CardTransactionFunding {
	AccountOfCard = "AccountOfCard",
	AccountOfCustomer = "AccountOfCustomer",
	AccountOfAnyCustomer = "AccountOfAnyCustomer",
}

export type ReserveAccountSettings = {
	minimumReserveBalance?: number
}

export type AccountRelation = {
	data: {
		type: "account"
		id: string
	}
}

export type OrgBankAgreement = {
	type: "orgBankAgreement"
	id: string
	attributes: {
		createdAt: Date
		name: string
		bankInterchangeShare: number
		orgInterchangeShare: number
		unitInterest: number
		isUpdatableDepositProduct: boolean
		isWirePaymentEnabled: boolean
		isBillPaymentEnabled: boolean
		isCheckDepositEnabled: boolean
		isOverdraftEnabled: boolean
		isPushToCardEnabled: boolean
		isCreditEnabled: boolean
		isDacaEnabled: boolean
		cardTransactionFunding: CardTransactionFunding
		isChargebackEnabled: boolean
		isTrustApplicationsEnabled: boolean
		isAutoArchiveAccountsEnabled: boolean
		autoArchiveAccountsAfterDays: number
		isAchTransactionReturnEnabled: boolean
		isIncreasePilotCustomerLimits: boolean
		isSameDayAchEnabled: boolean
		isEnforceLimitsCustomerLevel: boolean
		isActivationOfPhysicalCardsForOnlineUseEnabled: boolean
		isCashDepositEnabled: boolean
		allowOrgReleaseAuthHolds: boolean
		allowInlineAchDebit: boolean
		allowCounterpartyAchDebit: boolean
		isEnforceDailyACHDebitOrgLevel: boolean
		dailyACHDebitOrgLevel?: number
		overrideOriginatedAchSecCodeEnabled: boolean
		isCoverNegativeBalanceOnClose: boolean
		isEnforceDailyACHCreditOrgLevel: boolean
		dailyACHCreditOrgLevel?: number
		status: Status
		isCheckWritingEnabled: boolean
		isCardTransactionEnrichmentEnabled: boolean
		isCardSchemeFeeEnabled: boolean
		isReactivatingSuspectedFraudCardsEnabled: boolean
		isSplitInterestDailyEnabled: boolean
		reserveAccountSettings?: ReserveAccountSettings
		sweepCode?: number
		isLastFourSSNEnabled: boolean
		allowCreatingFBOBusinessCustomer: boolean
		isCheckWritingOriginationEnabled: boolean
		overdraftLimitReserveRatio?: number
		accountCreationBlocked: boolean
		authorizedUsersCreationBlocked: boolean
		financialAccountsSupported: boolean
		isReprocessReceivedAchDebitEnabled: boolean
		isResetPinCardEnabled: boolean
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
		interestSettings: {
			data: {
				type: "interestSettings"
				id: string
			}
		}
		revenueAccount: AccountRelation
		reserveAccount: AccountRelation
		sponsoredInterestAccount?: AccountRelation
		overdraftReserveAccount?: AccountRelation
		pushToCardOperationalAccount?: AccountRelation
		provisionalCreditOperatingAccount?: AccountRelation
		provisionalCreditReserveAccount?: AccountRelation
		cashDepositSettlementAccount?: AccountRelation
	}
}

export async function getAgreement(accessToken: string, id: string) {
	const result = await getResource<OrgBankAgreement>(`org-bank-agreements/${id}`, accessToken, {include: "org,bank"})
	return result.map((v) => v.data)
}

export async function findAgreements({
	accessToken,
	offset,
	limit,
	orgs,
	enabledOnly,
	searchQuery,
}: {
	accessToken: string
	offset: number
	limit: number
	orgs?: string[]
	enabledOnly?: boolean
	searchQuery?: string
}) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			orgs,
			enabledOnly,
			query: searchQuery != "" ? searchQuery : null,
		},
	}

	const result = await getResource<Array<OrgBankAgreement>>("org-bank-agreements", accessToken, query)

	return result.map((v) => v.data)
}

export enum Status {
	Enabled = "Enabled",
	Disabled = "Disabled",
}

export async function createOrgBankAgreement(
	accessToken: string,
	bankId: string,
	orgId: string,
	revenueAccountId: string,
	reserveAccountId: string,
	sponsoredInterestAccountId: string | undefined,
	provisionalCreditOperatingAccountId: string | undefined,
	provisionalCreditReserveAccountId: string | undefined,
	cashDepositSettlementAccount: string | undefined,
	bankInterchangeShare: number,
	orgInterchangeShare: number,
	unitInterest: number,
	isUpdatableDepositProduct: boolean,
	isWirePaymentEnabled: boolean,
	isBillPaymentEnabled: boolean,
	isCheckDepositEnabled: boolean,
	cardTransactionFunding: CardTransactionFunding,
	isOverdraftEnabled: boolean,
	isCreditEnabled: boolean,
	isPushToCardEnabled: boolean,
	isCoverNegativeBalanceOnClose: boolean,
	interestSettingsId: string,
	financialAccountsSupported: boolean,
	isReprocessReceivedAchDebitEnabled: boolean,
	isResetPinCardEnabled: boolean,
	overdraftReserveAccountId?: string,
	pushToCardAccountId?: string,
	isDacaEnabled?: boolean,
	isChargebackEnabled?: boolean,
	isTrustApplicationsEnabled?: boolean,
	isAutoArchiveAccountsEnabled?: boolean,
	autoArchiveAccountsAfterDays?: number,
	isAchTransactionReturnEnabled?: boolean,
	isIncreasePilotCustomerLimits?: boolean,
	isSameDayAchEnabled?: boolean,
	isEnforceLimitsCustomerLevel?: boolean,
	isActivationOfPhysicalCardsForOnlineUseEnabled?: boolean,
	isCashDepositEnabled?: boolean,
	allowOrgReleaseAuthHolds?: boolean,
	allowInlineAchDebit?: boolean,
	allowCounterpartyAchDebit?: boolean,
	isEnforceDailyACHDebitOrgLevel?: boolean,
	dailyACHDebitOrgLevel?: number,
	overrideOriginatedAchSecCodeEnabled?: boolean,
	isEnforceDailyACHCreditOrgLevel?: boolean,
	dailyACHCreditOrgLevel?: number,
	isCheckWritingEnabled?: boolean,
	isCardTransactionEnrichmentEnabled?: boolean,
	isReactivatingSuspectedFraudCardsEnabled?: boolean,
	isSplitInterestDailyEnabled?: boolean,
	minimumReserveBalance?: number,
	sweepCode?: number,
	isLastFourSSNEnabled?: boolean,
	allowCreatingFBOBusinessCustomer?: boolean,
	isCheckWritingOriginationEnabled?: boolean,
	overdraftLimitReserveRatio?: number,
	accountCreationBlocked?: boolean,
	authorizedUsersCreationBlocked?: boolean
) {
	const data = {
		type: "orgBankAgreement",
		attributes: {
			bankInterchangeShare: bankInterchangeShare,
			orgInterchangeShare: orgInterchangeShare,
			unitInterest: unitInterest,
			isUpdatableDepositProduct: isUpdatableDepositProduct,
			isWirePaymentEnabled: isWirePaymentEnabled,
			isBillPaymentEnabled: isBillPaymentEnabled,
			isCheckDepositEnabled: isCheckDepositEnabled,
			isOverdraftEnabled: isOverdraftEnabled,
			isCreditEnabled: isCreditEnabled,
			isDacaEnabled: isDacaEnabled,
			cardTransactionFunding: cardTransactionFunding,
			isChargebackEnabled: isChargebackEnabled,
			isTrustApplicationsEnabled: isTrustApplicationsEnabled,
			isAutoArchiveAccountsEnabled: isAutoArchiveAccountsEnabled,
			autoArchiveAccountsAfterDays: autoArchiveAccountsAfterDays,
			isAchTransactionReturnEnabled: isAchTransactionReturnEnabled,
			isIncreasePilotCustomerLimits: isIncreasePilotCustomerLimits,
			isSameDayAchEnabled: isSameDayAchEnabled,
			isEnforceLimitsCustomerLevel: isEnforceLimitsCustomerLevel,
			isActivationOfPhysicalCardsForOnlineUseEnabled: isActivationOfPhysicalCardsForOnlineUseEnabled,
			isCashDepositEnabled: isCashDepositEnabled,
			allowOrgReleaseAuthHolds: allowOrgReleaseAuthHolds,
			allowInlineAchDebit: allowInlineAchDebit,
			allowCounterpartyAchDebit: allowCounterpartyAchDebit,
			isEnforceDailyACHDebitOrgLevel: isEnforceDailyACHDebitOrgLevel,
			dailyACHDebitOrgLevel: dailyACHDebitOrgLevel,
			overrideOriginatedAchSecCodeEnabled: overrideOriginatedAchSecCodeEnabled,
			isCoverNegativeBalanceOnClose: isCoverNegativeBalanceOnClose,
			isEnforceDailyACHCreditOrgLevel: isEnforceDailyACHCreditOrgLevel,
			dailyACHCreditOrgLevel: dailyACHCreditOrgLevel,
			isCheckWritingEnabled: isCheckWritingEnabled,
			isPushToCardEnabled: isPushToCardEnabled,
			isCardTransactionEnrichmentEnabled: isCardTransactionEnrichmentEnabled,
			isReactivatingSuspectedFraudCardsEnabled: isReactivatingSuspectedFraudCardsEnabled,
			isSplitInterestDailyEnabled: isSplitInterestDailyEnabled,
			reserveAccountSettings: {minimumReserveBalance},
			sweepCode: sweepCode,
			isLastFourSSNEnabled: isLastFourSSNEnabled,
			allowCreatingFBOBusinessCustomer: allowCreatingFBOBusinessCustomer,
			isCheckWritingOriginationEnabled: isCheckWritingOriginationEnabled,
			overdraftLimitReserveRatio: overdraftLimitReserveRatio,
			accountCreationBlocked: accountCreationBlocked,
			authorizedUsersCreationBlocked: authorizedUsersCreationBlocked,
			financialAccountsSupported: financialAccountsSupported,
			isReprocessReceivedAchDebitEnabled: isReprocessReceivedAchDebitEnabled,
			isResetPinCardEnabled: isResetPinCardEnabled,
		},
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
			revenueAccount: {
				data: {
					type: "account",
					id: revenueAccountId,
				},
			},
			reserveAccount: {
				data: {
					type: "account",
					id: reserveAccountId,
				},
			},
			...(overdraftReserveAccountId && {
				overdraftReserveAccount: {
					data: {
						type: "account",
						id: overdraftReserveAccountId,
					},
				},
			}),
			...(pushToCardAccountId && {
				pushToCardOperationalAccount: {
					data: {
						type: "account",
						id: pushToCardAccountId,
					},
				},
			}),
			sponsoredInterestAccount:
				sponsoredInterestAccountId !== undefined
					? {
							data: {
								type: "account",
								id: sponsoredInterestAccountId,
							},
					  }
					: undefined,
			provisionalCreditOperatingAccount:
				provisionalCreditOperatingAccountId !== undefined
					? {
							data: {
								type: "account",
								id: provisionalCreditOperatingAccountId,
							},
					  }
					: undefined,
			provisionalCreditReserveAccount:
				provisionalCreditReserveAccountId !== undefined
					? {
							data: {
								type: "account",
								id: provisionalCreditReserveAccountId,
							},
					  }
					: undefined,
			cashDepositReserveAccount:
				cashDepositSettlementAccount !== undefined
					? {
							data: {
								type: "account",
								id: cashDepositSettlementAccount,
							},
					  }
					: undefined,
			interestSettings: {
				data: {
					type: "interestSettings",
					id: interestSettingsId,
				},
			},
		},
	}
	const result = await createResource<OrgBankAgreement>("org-bank-agreements", accessToken, data)
	return result.map((v) => v.data)
}

export async function updateOrgBankAgreement(
	accessToken: string,
	agreementId: string,
	bankId: string,
	orgId: string,
	revenueAccountId: string,
	reserveAccountId: string,
	sponsoredInterestAccountId: string | undefined,
	provisionalCreditOperatingAccountId: string | undefined,
	provisionalCreditReserveAccountId: string | undefined,
	cashDepositSettlementAccount: string | undefined,
	name: string,
	bankInterchangeShare: number,
	orgInterchangeShare: number,
	unitInterest: number,
	isUpdatableDepositProduct: boolean,
	isWirePaymentEnabled: boolean,
	isBillPaymentEnabled: boolean,
	isCheckDepositEnabled: boolean,
	cardTransactionFunding: CardTransactionFunding,
	isOverdraftEnabled: boolean,
	isPushToCardEnabled: boolean,
	isCreditEnabled: boolean,
	interestSettingsId: string,
	overdraftReserveAccountId?: string,
	pushToCardAccountId?: string,
	isDacaEnabled?: boolean,
	isChargebackEnabled?: boolean,
	isTrustApplicationsEnabled?: boolean,
	isAutoArchiveAccountsEnabled?: boolean,
	autoArchiveAccountsAfterDays?: number,
	isAchTransactionReturnEnabled?: boolean,
	isIncreasePilotCustomerLimits?: boolean,
	isSameDayAchEnabled?: boolean,
	isEnforceLimitsCustomerLevel?: boolean,
	isActivationOfPhysicalCardsForOnlineUseEnabled?: boolean,
	isCashDepositEnabled?: boolean,
	allowOrgReleaseAuthHolds?: boolean,
	allowInlineAchDebit?: boolean,
	allowCounterpartyAchDebit?: boolean,
	isEnforceDailyACHDebitOrgLevel?: boolean,
	dailyACHDebitOrgLevel?: number,
	overrideOriginatedAchSecCodeEnabled?: boolean,
	isCoverNegativeBalanceOnClose?: boolean,
	isEnforceDailyACHCreditOrgLevel?: boolean,
	dailyACHCreditOrgLevel?: number,
	isCheckWritingEnabled?: boolean,
	isCardTransactionEnrichmentEnabled?: boolean,
	isReactivatingSuspectedFraudCardsEnabled?: boolean,
	isSplitInterestDailyEnabled?: boolean,
	minimumReserveBalance?: number,
	sweepCode?: number,
	isCardSchemeFeeEnabled?: boolean,
	isLastFourSSNEnabled?: boolean,
	allowCreatingFBOBusinessCustomer?: boolean,
	isCheckWritingOriginationEnabled?: boolean,
	overdraftLimitReserveRatio?: number,
	accountCreationBlocked?: boolean,
	authorizedUsersCreationBlocked?: boolean,
	financialAccountsSupported?: boolean,
	isReprocessReceivedAchDebitEnabled?: boolean,
	isResetPinCardEnabled?: boolean
) {
	console.log("overdraftLimitReserveRatio" + JSON.stringify(overdraftLimitReserveRatio))

	const result = await updateResource<OrgBankAgreement>(`org-bank-agreements/${agreementId}`, accessToken, {
		type: "orgBankAgreement",
		attributes: {
			name: name,
			bankInterchangeShare: bankInterchangeShare,
			orgInterchangeShare: orgInterchangeShare,
			unitInterest: unitInterest,
			isUpdatableDepositProduct: isUpdatableDepositProduct,
			isWirePaymentEnabled: isWirePaymentEnabled,
			isBillPaymentEnabled: isBillPaymentEnabled,
			isCheckDepositEnabled: isCheckDepositEnabled,
			isOverdraftEnabled: isOverdraftEnabled,
			isPushToCardEnabled: isPushToCardEnabled,
			isCreditEnabled: isCreditEnabled,
			isDacaEnabled: isDacaEnabled,
			cardTransactionFunding: cardTransactionFunding,
			isChargebackEnabled: isChargebackEnabled,
			isTrustApplicationsEnabled: isTrustApplicationsEnabled,
			isAutoArchiveAccountsEnabled: isAutoArchiveAccountsEnabled,
			autoArchiveAccountsAfterDays: autoArchiveAccountsAfterDays,
			isAchTransactionReturnEnabled: isAchTransactionReturnEnabled,
			isIncreasePilotCustomerLimits: isIncreasePilotCustomerLimits,
			isSameDayAchEnabled: isSameDayAchEnabled,
			isEnforceLimitsCustomerLevel: isEnforceLimitsCustomerLevel,
			isActivationOfPhysicalCardsForOnlineUseEnabled: isActivationOfPhysicalCardsForOnlineUseEnabled,
			isCashDepositEnabled: isCashDepositEnabled,
			allowOrgReleaseAuthHolds: allowOrgReleaseAuthHolds,
			allowInlineAchDebit: allowInlineAchDebit,
			allowCounterpartyAchDebit: allowCounterpartyAchDebit,
			isEnforceDailyACHDebitOrgLevel: isEnforceDailyACHDebitOrgLevel,
			dailyACHDebitOrgLevel: dailyACHDebitOrgLevel,
			overrideOriginatedAchSecCodeEnabled: overrideOriginatedAchSecCodeEnabled,
			isCoverNegativeBalanceOnClose: isCoverNegativeBalanceOnClose,
			isEnforceDailyACHCreditOrgLevel: isEnforceDailyACHCreditOrgLevel,
			dailyACHCreditOrgLevel: dailyACHCreditOrgLevel,
			isCheckWritingEnabled: isCheckWritingEnabled,
			isCardTransactionEnrichmentEnabled: isCardTransactionEnrichmentEnabled,
			isReactivatingSuspectedFraudCardsEnabled: isReactivatingSuspectedFraudCardsEnabled,
			isSplitInterestDailyEnabled: isSplitInterestDailyEnabled,
			reserveAccountSettings: {
				minimumReserveBalance: minimumReserveBalance,
			},
			sweepCode: sweepCode ?? null,
			isCardSchemeFeeEnabled: isCardSchemeFeeEnabled,
			isLastFourSSNEnabled: isLastFourSSNEnabled,
			allowCreatingFBOBusinessCustomer: allowCreatingFBOBusinessCustomer,
			isCheckWritingOriginationEnabled: isCheckWritingOriginationEnabled,
			overdraftLimitReserveRatio: overdraftLimitReserveRatio,
			accountCreationBlocked: accountCreationBlocked,
			authorizedUsersCreationBlocked: authorizedUsersCreationBlocked,
			financialAccountsSupported: financialAccountsSupported,
			isReprocessReceivedAchDebitEnabled: isReprocessReceivedAchDebitEnabled,
			isResetPinCardEnabled: isResetPinCardEnabled,
		},
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
			revenueAccount: {
				data: {
					type: "account",
					id: revenueAccountId,
				},
			},
			reserveAccount: {
				data: {
					type: "account",
					id: reserveAccountId,
				},
			},
			...(overdraftReserveAccountId && {
				overdraftReserveAccount: {
					data: {
						type: "account",
						id: overdraftReserveAccountId,
					},
				},
			}),
			sponsoredInterestAccount:
				sponsoredInterestAccountId !== undefined
					? {
							data: {
								type: "account",
								id: sponsoredInterestAccountId,
							},
					  }
					: undefined,
			pushToCardOperationalAccount:
				pushToCardAccountId !== undefined
					? {
							data: {
								type: "account",
								id: pushToCardAccountId,
							},
					  }
					: undefined,
			provisionalCreditOperatingAccount:
				provisionalCreditOperatingAccountId !== undefined
					? {
							data: {
								type: "account",
								id: provisionalCreditOperatingAccountId,
							},
					  }
					: undefined,
			provisionalCreditReserveAccount:
				provisionalCreditReserveAccountId !== undefined
					? {
							data: {
								type: "account",
								id: provisionalCreditReserveAccountId,
							},
					  }
					: undefined,
			cashDepositSettlementAccount:
				cashDepositSettlementAccount !== undefined
					? {
							data: {
								type: "account",
								id: cashDepositSettlementAccount,
							},
					  }
					: undefined,
			interestSettings: {
				data: {
					type: "interestSettings",
					id: interestSettingsId,
				},
			},
		},
	})
	return result.map((v) => v.data)
}
