import React from "react"

export default function CognitoFields({
	jwksPath,
	updateSettings,
}: {
	jwksPath: string
	updateSettings: (field: string, value: string) => void
}) {
	return (
		<fieldset className="jwt-fields">
			<div className="field">
				<label className="label">JWKs Path</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="https://{cognitoIdp}.{region}.amazonaws.com/{yourUserPoolId}/.well-known/jwks.json"
						value={jwksPath ?? ""}
						pattern="https:\/\/([A-Za-z0-9\-]+\.){2,}amazonaws\.com\/([A-Za-z0-9\-\._]+)\/\.well-known\/jwks\.json"
						// pattern="https:\/\/([A-Za-z0-9\-\.]*)\.amazonaws\.com\/([A-Za-z0-9\-\._]*)\/\.well-known\/jwks\.json"
						onChange={(e) => {
							updateSettings("jwksPath", e.target.value)
							if (e.target.validity.patternMismatch) {
								e.target.setCustomValidity(
									"invalid url, make sure your url is in this format: 'https://cognito-idp.Region.amazonaws.com/Your_user_pool_ID/.well-known/jwks.json'"
								)
							} else {
								e.target.setCustomValidity("")
							}
						}}
					/>
				</div>
			</div>
		</fieldset>
	)
}
