import React, {useState} from "react"
import {useAccessToken, useIsUnitUser} from "../../services/auth"
import {useNavigate, useParams} from "react-router-dom"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import TagsViewer, {TagsViewerKeyValue} from "../../components/Tags/TagsViewer"
import {
	Atm,
	CardTransaction,
	getTransactionWithInclude,
	isBook,
	isCard,
	isCardReversal,
	isCheckPaymentTransaction,
	isDisputableTransaction,
	isFee,
	isOriginatedAch,
	isPaymentTransaction,
	isPurchase,
	isReceivedAch,
	isWire,
	OriginatedAch,
	prettyPrintType,
	Purchase,
	ReceivedAch,
	Reversal,
	Transaction,
} from "../../resources/transaction"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import moment from "moment"
import classNames from "classnames"
import numeral from "numeral"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import ReactJson from "react-json-view"
import LinkButton from "../../components/LinkButton/LinkButton"
import {ReturnReceivedAchModal} from "./ReturnReceivedAch"
import {useModal} from "react-modal-hook"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {Disputes, DisputesColumns} from "../../components/Disputes/Disputes"
import {getOrg, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {Customer} from "../../resources/customer"
import {uniq} from "lodash"
import {isOrg} from "../../services/orgAuth"
import {AchPayment} from "../../resources/payment"
import {getAchPaymentFeatures} from "../../components/PaymentDetails/PaymentDetails"
import {extractDataFromFromImadOmad} from "../../utilities/helpers"

type TransactionTabs = "Main" | "Advance"

function TransactionInternal({
	value,
	refresh,
}: {
	value: OkDocument<Transaction>
	accessToken: string
	refresh: () => void
}) {
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const tx = value.data as Transaction
	const {attributes} = tx
	const customers: Array<Customer> =
		value?.included
			?.filter((c) => c.type == "individualCustomer" || c.type == "businessCustomer")
			.map((c) => c as Customer) ?? []
	const payment = value?.included?.filter((p) => p.type == "achPayment").map((p) => p as AchPayment)[0] ?? undefined
	const paymentId = isPaymentTransaction(tx) && tx.relationships.payment?.data.id
	const checkPaymentId = isCheckPaymentTransaction(tx) && tx.relationships.checkPayment?.data.id
	const tags = "tags" in tx.attributes ? tx.attributes.tags : undefined
	const customerId = tx.relationships.customer?.data.id
	const orgId = tx.relationships.org?.data.id
	const org =
		useIsUnitUser() && orgId
			? useAsyncResult(() => getOrg(accessToken, orgId.toString()))
			: AsyncResult.pending<Org, ErrorDocument>()
	const orgName = org.match(
		() => null,
		(org) => org.attributes.name,
		(_) => null
	)
	const achPaymentFeatures = payment && getAchPaymentFeatures(payment)

	const customersIds = (tx.relationships.customers?.data as unknown as Array<Customer>).map((customer) => customer.id)
	const [currentTab, setCurrentTab] = useState<TransactionTabs>("Main")

	const [showReturnAch, hideReturnAch] = useModal(() => (
		<ReturnReceivedAchModal close={hideReturnAch} transaction={tx} onSuccess={refresh} />
	))

	const accountLink = `/account/${tx.relationships.account.data.id}`
	const paymentLink = `/payment/${paymentId}`
	const checkPaymentLink = `/check-payments/${checkPaymentId}`

	const imad = isWire(tx) && tx.attributes.imadOmad?.imad
	const dataFromImad = imad && extractDataFromFromImadOmad(imad)

	const imadOmadTags = isWire(tx) && tx.attributes.imadOmad && imad && tx.attributes.imadOmad.omad && (
		<TagsViewerKeyValue
			tags={{
				IMAD: imad,
				...(dataFromImad
					? {
							"IMAD Cycle Date": dataFromImad.cycleDate,
							"IMAD Sequence Number": dataFromImad.sequenceNumber,
					  }
					: {}),
				OMAD: tx.attributes.imadOmad.omad,
			}}
		/>
	)

	function TransactionActions() {
		return (
			<>
				{isDisputableTransaction(tx) && (customerId || (customersIds && customersIds.length > 0)) ? (
					<HasPermission resource="dispute" action="get">
						<Disputes
							enablePagination={false}
							singleDisputeView={true}
							enableTitle={true}
							enableExport={false}
							enableSearch={false}
							enableStatusFilter={false}
							fullHeight={false}
							limit={10}
							transaction={tx}
							includedColumns={[
								DisputesColumns.id,
								DisputesColumns.source,
								DisputesColumns.amount,
								DisputesColumns.createdAt,
								DisputesColumns.status,
								DisputesColumns.updatedAt,
							]}
						/>
					</HasPermission>
				) : null}

				{isReceivedAch(tx) && useIsUnitUser() ? (
					<div className={"mt-5"}>
						<button className="button is-danger" onClick={() => showReturnAch()}>
							Return ACH
						</button>
					</div>
				) : null}
			</>
		)
	}

	function TabContent() {
		if (currentTab == "Main" || !useIsUnitUser()) {
			return (
				<div className="columns">
					<div className="column is-12">
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Transaction Information</p>
									</div>
									<div className="card-content">
										{optionalHorizontalField("ID", tx.id.toString())}
										{optionalHorizontalField("Type", prettyPrintType(tx))}
										{achPaymentFeatures && (
											<HorizontalField label={"Payment Features"} fieldClassName={"is-align-items-center"}>
												{achPaymentFeatures}
											</HorizontalField>
										)}
										{!isOrg() ? (
											<HorizontalField label="Customers">
												<span>
													{uniq(
														[tx.relationships.customer?.data ?? [], tx.relationships.customers?.data ?? []]
															.flat()
															.map((c) => c.id.toString())
													).map((c, i) => (
														<span key={c}>
															{i ? ", " : ""}
															{getCustomerLink(c)}
														</span>
													))}
												</span>
											</HorizontalField>
										) : null}
										<HorizontalField label="Amount">
											<input
												type="text"
												readOnly
												value={numeral(tx.attributes.amount / 100).format("$0,0.00")}
												className={classNames(
													"input is-static",
													tx.attributes.direction === "Credit" && "has-text-success",
													tx.attributes.direction === "Debit" && "has-text-danger"
												)}
											/>
										</HorizontalField>
										{"interchange" in tx.attributes && tx.attributes.interchange ? (
											<HorizontalField label="Interchange">
												<input
													type="text"
													readOnly
													value={numeral(tx.attributes.interchange / 100).format("$0,0.00")}
													className="input is-static"
												/>
											</HorizontalField>
										) : null}
										{"grossInterchange" in tx.attributes && tx.attributes.grossInterchange ? (
											<HorizontalField label="Gross Interchange">
												<input
													type="text"
													readOnly
													value={numeral(tx.attributes.grossInterchange / 100).format("$0,0.00")}
													className="input is-static"
												/>
											</HorizontalField>
										) : null}
										{optionalHorizontalField("Summary", attributes.summary)}
										{optionalHorizontalField("Created At", moment(attributes.createdAt).format("L"))}
										{imadOmadTags && <HorizontalField label="IMAD OMAD">{imadOmadTags}</HorizontalField>}
										{(isFee(tx) || isCardReversal(tx)) && tx.relationships.relatedTransaction?.data.id ? (
											<HorizontalField label="Related Transaction">
												<a
													className="entity-link"
													href="#"
													onClick={(e) => {
														e.preventDefault()
														navigate(
															`/transaction/${tx.relationships.account.data.id}/${tx.relationships.relatedTransaction?.data.id}`
														)
													}}
												>
													{tx.relationships.relatedTransaction?.data.id}
												</a>
											</HorizontalField>
										) : null}
										{isBook(tx) && tx.relationships.counterpartyAccount?.data.id ? (
											<HorizontalField label="Counterparty Account">
												<a
													className="entity-link"
													href="#"
													onClick={(e) => {
														e.preventDefault()
														navigate(`/account/${tx.relationships.counterpartyAccount?.data.id}`)
													}}
												>
													{tx.relationships.counterpartyAccount?.data.id}
												</a>
											</HorizontalField>
										) : null}
										{isBook(tx) && tx.relationships.counterpartyCustomer?.data.id ? (
											<HorizontalField label="Counterparty Customer">
												<a
													className="entity-link"
													href="#"
													onClick={(e) => {
														e.preventDefault()
														navigate(`/customer/${tx.relationships.counterpartyCustomer?.data.id}`)
													}}
												>
													{tx.relationships.counterpartyCustomer?.data.id}
												</a>
											</HorizontalField>
										) : null}
										{isReceivedAch(tx) ? <IncomingAchFields transaction={tx} /> : null}
										{isOriginatedAch(tx) ? <OriginatedAchFields transaction={tx} /> : null}
										{isCard(tx) ? <NetworkTransactionId transaction={tx} /> : null}
										{isPurchase(tx) ? <MccCode transaction={tx} /> : null}
									</div>
								</div>
							</div>
						</div>
						<div>
							<TransactionActions />
							{tags ? (
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Tags</p>
									</div>
									<div className="card-content">
										<TagsViewer tags={tags} />
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			)
		}

		if (currentTab == "Advance") {
			return (
				<div className="columns">
					<div className="column is-6">
						<ReactJson name="Attributes" src={tx.attributes} displayDataTypes={false} />
					</div>
					<div className="column is-6">
						<ReactJson name="Relationships" src={tx.relationships} displayDataTypes={false} />
					</div>
				</div>
			)
		}

		return null
	}

	function getCustomerLink(customerId: string) {
		const customer = customers.find((c) => c.id == customerId)

		if (customer) {
			const name =
				customer.type === "businessCustomer" || customer.type === "businessFBOCustomer"
					? customer.attributes.name
					: `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
			return (
				<a
					className="entity-link"
					key={customerId}
					href="#"
					onClick={(e) => {
						e.preventDefault()
						navigate(`/customer/${customerId}`)
					}}
				>
					{name}
				</a>
			)
		}
	}

	return (
		<>
			<TitleBar
				title={`Transaction #${tx.id}`}
				meta={[
					{text: "Account", icon: "bank-account--business-products", path: accountLink},
					...(paymentId
						? [{text: "Payment", icon: "money-fund-move-found--business-products", path: paymentLink} as MetaItemProps]
						: []),
					...(checkPaymentId
						? [
								{
									text: "Check Payment",
									icon: "money-note-1--business-products",
									path: checkPaymentLink,
								} as MetaItemProps,
						  ]
						: []),
					...(orgName ? [{text: orgName, icon: "user-hierachy2"} as MetaItemProps] : []),
				]}
			/>
			<UnitUserOnly>
				<nav className="tabs-container">
					<div className="tabs">
						<ul>
							<li className={classNames(currentTab == "Main" && "is-active")} onClick={() => setCurrentTab("Main")}>
								<a>Details</a>
							</li>
							<li
								className={classNames(currentTab == "Advance" && "is-active")}
								onClick={() => setCurrentTab("Advance")}
							>
								<a>Raw Data</a>
							</li>
						</ul>
					</div>
				</nav>
			</UnitUserOnly>
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<UnitUserOnly>
								{isReceivedAch(tx) && tx.relationships.incomingAch?.data.id ? (
									<LinkButton to={`/incoming-ach/${tx.relationships.incomingAch.data.id}`}>Incoming Ach</LinkButton>
								) : null}
							</UnitUserOnly>
						</div>
					</div>
				</div>
				<TabContent />
			</MainSection>
		</>
	)
}

export function IncomingAchFields({transaction}: {transaction: ReceivedAch}) {
	return (
		<>
			{optionalHorizontalField("Company Name", transaction.attributes.companyName)}
			{optionalHorizontalField("Counterparty Routing Number", transaction.attributes.counterpartyRoutingNumber)}
			{optionalHorizontalField("Sec Code", transaction.attributes.secCode)}
			{optionalHorizontalField("Trace Number", transaction.attributes.traceNumber)}
		</>
	)
}

export function OriginatedAchFields({transaction}: {transaction: OriginatedAch}) {
	return (
		<>
			{optionalHorizontalField("Sec Code", transaction.attributes.secCode)}
			{optionalHorizontalField("Trace Number", transaction.attributes.traceNumber)}
		</>
	)
}

export function NetworkTransactionId({transaction}: {transaction: Purchase | Atm | Reversal | CardTransaction}) {
	return <>{optionalHorizontalField("Network Transaction ID", transaction.attributes.networkTransactionId)}</>
}

export function MccCode({transaction}: {transaction: Purchase}) {
	return (
		<>
			{optionalHorizontalField("Merchant name", transaction.attributes.merchant.name)}
			{optionalHorizontalField("Merchant type", transaction.attributes.merchant.type)}
			{optionalHorizontalField("Merchant category", transaction.attributes.merchant.category)}
			{optionalHorizontalField("Merchant location", transaction.attributes.merchant.location)}
		</>
	)
}

export function TransactionComponent({
	accountId,
	transactionId,
	accessToken,
}: {
	accountId: string
	transactionId: string
	accessToken: string
}) {
	const [refreshToken, refresh] = useRefreshToken()
	const transactionAsyncResult = useAsyncResult<OkDocument<Transaction>, ErrorDocument>(
		() => getTransactionWithInclude(accessToken, accountId, transactionId),
		[refreshToken, transactionId, accountId]
	)

	return (
		<AsyncResultComponent asyncResult={transactionAsyncResult} accessToken={accessToken} refresh={refresh}>
			{({value: value}) => {
				return (
					<TransactionInternal value={value as OkDocument<Transaction>} accessToken={accessToken} refresh={refresh} />
				)
			}}
		</AsyncResultComponent>
	)
}

export default function TransactionPage() {
	const accessToken = useAccessToken()
	const {accountId = "", transactionId = ""} = useParams<{accountId: string; transactionId: string}>()

	return <TransactionComponent accountId={accountId} transactionId={transactionId} accessToken={accessToken} />
}
