import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {createOrg, Org} from "../../resources/org"
import Loading from "../../components/Loading/Loading"
import React, {useEffect, useState} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {EditMode, ErrorDocument, RiskRate} from "../../resources/common"
import {useNavigate} from "react-router-dom"
import {useToasts} from "react-toast-notifications"
import {Customer, findCustomers} from "../../resources/customer"
import {OrgAttributes} from "./OrgAttributes"
import {Bank, findBanks} from "../../resources/bank"
import {AsyncResult} from "../../types/asyncResult"

function Footer({state}: {state: AsyncResultIdleRequestState<Org, ErrorDocument>}) {
	return (
		<div className="card-content">
			{state.match(
				() => (
					<button className="button is-success">Create</button>
				),
				() => (
					<button className="button is-success is-loading">Creating</button>
				),
				(_) => null,
				(err) => (
					<>
						<div className="has-text-danger">{err.errors[0].title}</div>
						<button className="button is-success">Create</button>
					</>
				)
			)}
		</div>
	)
}

function Page({customers, banks}: {customers: Array<Customer>; banks: Array<Bank>}) {
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const {addToast} = useToasts()

	const [state, create] = useAsyncResultIdle(createOrg)
	const [name, setName] = useState("")
	const [customerId, setCustomerId] = useState<string | null>("")
	const [bankId, setBankId] = useState<string | null>("")
	const [orgInterchangeShare, setOrgInterchangeShare] = useState<number>(0)
	const [defaultCustomerRiskRate, setDefaultCustomerRiskRate] = useState<RiskRate>(RiskRate.Low)
	const [enableTwoFactorAuthentication, setEnableTwoFactorAuthentication] = useState<boolean>(false)

	useEffect(() => {
		if (state.isOk()) {
			addToast("Org Created Successfully", {appearance: "success"})
			navigate(`/org/${state.value.id}`, {replace: true})
		}
	}, [state])

	const submit = () => {
		create(
			accessToken,
			name,
			customerId != "" ? customerId : null,
			bankId != "" ? bankId : null,
			orgInterchangeShare,
			defaultCustomerRiskRate,
			enableTwoFactorAuthentication
		)
	}

	const disabled = state.match(
		() => false,
		() => true,
		(_) => true,
		(_) => false
	)

	return (
		<>
			<TitleBar title={"New Org"} />
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
						</div>
					</div>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						submit()
					}}
				>
					<fieldset disabled={disabled}>
						<OrgAttributes
							editMode={EditMode.NEW}
							name={name}
							setName={setName}
							customerId={customerId}
							setCustomerId={setCustomerId}
							customers={customers}
							bankId={bankId}
							setBankId={setBankId}
							banks={banks}
							orgInterchangeShare={orgInterchangeShare}
							setOrgInterchangeShare={setOrgInterchangeShare}
							defaultCustomerRiskRate={defaultCustomerRiskRate}
							setDefaultCustomerRiskRate={setDefaultCustomerRiskRate}
							enableTwoFactorAuthentication={enableTwoFactorAuthentication}
							setEnableTwoFactorAuthentication={setEnableTwoFactorAuthentication}
						/>

						<div className="columns">
							<div className="column">
								<div className="card">
									<Footer state={state} />
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</MainSection>
		</>
	)
}

export default function NewOrg() {
	const accessToken = useAccessToken()

	const customers = useAsyncResult(() => findCustomers(accessToken, 0, 1000, "-createdAt", "", ["1"]))
	const banks = useAsyncResult(() => findBanks(accessToken, 0, 1000))

	return AsyncResult.zip(customers, banks).match(
		() => <Loading />,
		([customers, banks]) => <Page customers={customers.data} banks={banks} />,
		(_) => null
	)
}
