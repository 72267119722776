import React, {PropsWithChildren, useEffect, useRef} from "react"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {useClickAway} from "react-use"
import {useEsc} from "../../hooks/useEsc"

interface ModalProps {
	classname?: string
	close: () => void
	enableClickAway?: boolean
	blockBodyScroll?: boolean
}

export function Modal(props: PropsWithChildren<ModalProps>) {
	const ref = useRef(null)

	useEsc(props.close)

	if (props.enableClickAway) {
		useClickAway(ref, () => props.close())
	}

	useEffect(() => {
		if (props.blockBodyScroll) {
			const body = document.querySelector("body") as HTMLBodyElement
			body.classList.add("no-body-scroll")
			return () => {
				body.classList.remove("no-body-scroll")
			}
		}
	})

	return (
		<div className={classNames("modal", "basic-modal", "is-active", props.classname)}>
			<div className="modal-background" />
			<div className="modal-card" ref={ref}>
				<div className="close-modal" onClick={() => props.close()}>
					<Icon icon="interface-delete-interface-essential" size={16} />
				</div>
				<section className="modal-card-body">{props.children}</section>
			</div>
		</div>
	)
}

Modal.defaultProps = {
	enableClickAway: true,
	blockBodyScroll: false,
}
