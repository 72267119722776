import React from "react"
import OtpInput from "react-otp-input"
import classNames from "classnames"

type OTPInputProps = {
	numOfDigits: number
	isError?: boolean
	disabled?: boolean
	submit: (otp: string) => void
}

const OTPInput = ({numOfDigits, isError, disabled, submit}: OTPInputProps) => {
	const [otp, setOtp] = React.useState("")

	const handleChange = (otp: string) => {
		setOtp(otp)
		if (otp.length === numOfDigits) {
			submit(otp)
		}
	}

	return (
		<div className="otp-input">
			<OtpInput
				value={otp}
				onChange={handleChange}
				numInputs={numOfDigits}
				renderInput={(props) => <input {...props} disabled={disabled} />}
				shouldAutoFocus={true}
				inputStyle={classNames("otp-input-style", isError && "error")}
				containerStyle={"otp-container"}
			/>
		</div>
	)
}

export default OTPInput
