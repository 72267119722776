import {Result} from "neverthrow"
import {useClickAway, useToggle} from "react-use"
import {
	DeferredProvisionalCreditWillBeGrantedDTO,
	Dispute,
	DisputeType,
	showDeferredProvisionalCredit,
	StatusType,
	StatusTypeValue,
} from "../../resources/dispute"
import classNames from "classnames"
import {UnitAdminOnly, UnitAdminReadOnly} from "../../containers/PermissionedUser/PermissionedUser"
import Icon from "../Icon/Icon"
import moment from "moment/moment"
import {Link} from "react-router-dom"
import numeral from "numeral"
import {toCents} from "../../utilities/numbers"
import {kebabCase, startCase, toNumber} from "lodash"
import {descriptionPrefill} from "./NewDisputeModal"
import React, {useRef, useState} from "react"
import {ErrorDocument} from "../../resources/common"
import {useModal} from "react-modal-hook"
import {CancelDeferredProvisionalCreditModal, ChangeDeferredProvisionalCreditDateModal} from "./DisputeModals"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardPair} from "../KeyValueCard/KeyValueCard"
import {DataTable, DataTableCell, DataTableHead, DataTableRow, DataTableBody} from "../DataTable/DataTable"
import {StatusBox} from "../StatusBox/StatusBox"
import {useIsUnitAdminUser} from "../../services/auth"

export function DisputeInformation({
	accessToken,
	dispute,
	canUpdate,
	refresh,
	update,
}: {
	accessToken: string
	dispute: Dispute
	canUpdate: boolean
	refresh: () => void
	update: (
		accessToken: string,
		id: string,
		description: string,
		disputeType: DisputeType,
		amount: number | null,
		externalId?: string
	) => Promise<Result<Dispute, ErrorDocument>>
}) {
	const disputeAmount = dispute.attributes.amount
	const deferredProvisionalCredit = dispute.attributes.deferredProvisionalCredit
	const decisionReason = dispute.attributes.decisionReason
	const merchantCreditTransactionId = dispute.relationships.merchantCreditTransactionId?.data.id
	const accountId = dispute.relationships.account.data.id
	const statusHistory = dispute.attributes.statusHistory.sort(({updatedAt: updatedAt1}, {updatedAt: updatedAt2}) =>
		moment(updatedAt2).diff(moment(updatedAt1))
	)

	const updatableAmount = StatusTypeValue(statusHistory.slice(-1)[0].type) === StatusType.InvestigationStarted

	const [externalId, setExternalId] = useState<string | undefined>(dispute.attributes.externalId)
	const [description, setDescription] = useState<string>(dispute.attributes.description)

	const [disputeType, setDisputeType] = useState<DisputeType>(dispute.attributes.disputeType)
	const [amount, setAmount] = useState<number>(disputeAmount)
	const [isDropdownActive, toggleDropdown] = useToggle(false)

	const ref = useRef(null)
	useClickAway(ref, () => toggleDropdown(false))

	const [showChangeDate, hideChangeDate] = useModal(() => (
		<ChangeDeferredProvisionalCreditDateModal
			close={hideChangeDate}
			id={dispute.id}
			date={(deferredProvisionalCredit as DeferredProvisionalCreditWillBeGrantedDTO).attributes.provisionalCreditDate}
			possibleBussinesDays={dispute.attributes.possibleBussinesDays.filter((day) => {
				const today = new Date()
				const date = new Date(day.attributes.date)
				today.setHours(0, 0, 0, 0)
				return date >= today
			})}
			onSuccess={refresh}
		/>
	))

	const [showCancel, hideCancel] = useModal(() => (
		<CancelDeferredProvisionalCreditModal close={hideCancel} id={dispute.id} onSuccess={refresh} />
	))

	return (
		<KeyValueCard title="Dispute Information">
			<>
				{showDeferredProvisionalCredit(dispute) && (
					<article className={classNames("admonition", "is-security-info")}>
						<div className="message-body admonition-message-body">
							<div style={{display: "inline-block", verticalAlign: "top"}}>
								<h3>
									{" "}
									<b>{`Provisional credit will be granted on ${
										(deferredProvisionalCredit as DeferredProvisionalCreditWillBeGrantedDTO).attributes
											.provisionalCreditDate
									}`}</b>{" "}
								</h3>
								<div>
									<p style={{color: "#808285"}}>
										{
											dispute.attributes.possibleBussinesDays.find(
												(x) =>
													x.attributes.date ==
													(deferredProvisionalCredit as DeferredProvisionalCreditWillBeGrantedDTO).attributes
														.provisionalCreditDate
											)?.attributes.text
										}{" "}
										from dispute date
									</p>
								</div>
							</div>
							<div style={{margin: "20px 0"}}></div>
							<UnitAdminOnly>
								<div style={{display: "flex", justifyContent: "flex-end"}}>
									<button
										type={"button"}
										className="button change-button mr-2"
										style={{flex: "0 0 auto", backgroundColor: "transparent"}}
										onClick={showChangeDate}
									>
										<Icon icon={"calendar-interface-edit-date"} size={16} color="black" />
										<span>Change date</span>
									</button>
									<button
										type={"button"}
										className="button cancel-button"
										style={{flex: "0 0 auto", backgroundColor: "transparent"}}
										onClick={showCancel}
									>
										<Icon icon={"delete-close-x"} size={16} />
										<span>Cancel</span>
									</button>
								</div>
							</UnitAdminOnly>
						</div>
					</article>
				)}

				<form
					onSubmit={(e) => {
						e.preventDefault()
						update(accessToken, dispute.id, description, disputeType, updatableAmount ? amount : null, externalId)
					}}
				>
					<KeyValueCardContainer>
						{KeyValueCardPair("ID", dispute.id.toString())}
						{KeyValueCardPair("Type", dispute.attributes.source)}
						{KeyValueCardPair("Created At", moment(dispute.attributes.createdAt).format("L"))}
						{KeyValueCardPair(
							"External ID",
							<input
								type="text"
								className="input dispute-input-field external-id-input"
								value={externalId}
								required
								maxLength={255}
								onChange={(e) => setExternalId(e.target.value)}
							/>
						)}
						{KeyValueCardPair(
							"Reason",
							<div className="field has-addons">
								{Object.keys(DisputeType).map((option) => (
									<p key={option} className="control">
										<button
											type="button"
											className={classNames("button", disputeType === option && "is-active")}
											onClick={() => setDisputeType(DisputeType[option as keyof typeof DisputeType])}
										>
											<span>{startCase(DisputeType[option as keyof typeof DisputeType])}</span>
										</button>
									</p>
								))}
							</div>
						)}
						{KeyValueCardPair(
							"Amount",
							<div className="amount-input-container">
								<input
									className={classNames("input", "dispute-input-field", "amount-input", !canUpdate && "is-static")}
									type="number"
									disabled={!updatableAmount}
									placeholder={numeral(disputeAmount / 100).format("0.00")}
									value={amount && (amount / 100).toFixed(2)}
									pattern="^\$[0-9]+(\.[0-9][0-9])?$"
									required
									readOnly={!canUpdate}
									onChange={(e) => {
										setAmount(toCents(toNumber(e.target.value).toFixed(2)))
									}}
								></input>
								<span className="dollar-sign">$</span>
							</div>
						)}

						{decisionReason && (
							<UnitAdminReadOnly>
								{KeyValueCardPair(
									"Decision Reason",
									<>
										<span>{decisionReason}</span>
										{merchantCreditTransactionId && (
											<Link to={`/transaction/${accountId}/${merchantCreditTransactionId}`} className="link">
												Transaction #{merchantCreditTransactionId}
											</Link>
										)}
									</>
								)}
							</UnitAdminReadOnly>
						)}

						<UnitAdminOnly>
							{/* TODO: this needs to be shown but disabled for change for compliance-readonly? */}
							{KeyValueCardPair(
								"Description",
								<div className={classNames("dropdown", "is-up", isDropdownActive && "is-active")}>
									<div className="dropdown-trigger">
										<button
											type="button"
											className="button"
											aria-haspopup="true"
											aria-controls="dropdown-menu7"
											onClick={() => toggleDropdown()}
										>
											<span style={{width: "100%", textAlign: "left"}}>
												{(descriptionPrefill.includes(description) && description) || "Other"}
											</span>
											<span className="icon">
												<Icon icon="arrow-down-chevron" size={16} />
											</span>
										</button>
									</div>
									<div ref={ref} className="dropdown-menu" id="dropdown-menu7" role="menu">
										<div className="dropdown-content">
											{descriptionPrefill.map((option) => (
												<a
													key={option}
													className="dropdown-item"
													onClick={(e) => {
														e.preventDefault()
														setDescription(option)
														toggleDropdown()
													}}
												>
													{option}
												</a>
											))}
										</div>
									</div>
								</div>
							)}
						</UnitAdminOnly>
						{KeyValueCardPair(
							useIsUnitAdminUser() ? "" : "Description",
							<textarea
								placeholder={`${useIsUnitAdminUser() ? "Or " : ""}add description...`}
								className="textarea is-static has-fixed-size"
								value={description || ""}
								readOnly={!canUpdate}
								onChange={(e) => setDescription(e.target.value)}
							/>
						)}
					</KeyValueCardContainer>
					{canUpdate ? (
						<div className="card">
							<div className="card-content">
								<button className="button is-black">Update</button>
							</div>
						</div>
					) : null}
				</form>
			</>
			{statusHistory.length !== 0 && (
				<div className="status-history-table-wrapper">
					<DataTable clickable={false}>
						<DataTableHead className="status-history-th">
							<DataTableRow>
								<DataTableCell key="type">Status History</DataTableCell>
								<DataTableCell key="updatedAt">Updated at</DataTableCell>
								{statusHistory[0].updatedBy && <DataTableCell key="updatedBy">Updated by</DataTableCell>}
							</DataTableRow>
						</DataTableHead>
						<DataTableBody>
							{statusHistory.map(({type, updatedAt, updatedBy}, index) => (
								<DataTableRow key={index}>
									<DataTableCell style={{width: "40%"}}>
										<StatusBox
											text={StatusTypeValue(type)}
											className={classNames("disputes-status", kebabCase(type))}
										/>
									</DataTableCell>
									<DataTableCell>{moment(updatedAt).format("MM/DD/YY")}</DataTableCell>
									{updatedBy && <DataTableCell style={{width: "35%"}}>{updatedBy}</DataTableCell>}
								</DataTableRow>
							))}
						</DataTableBody>
					</DataTable>
				</div>
			)}
		</KeyValueCard>
	)
}
