import React from "react"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardKey, KeyValueCardValue} from "../KeyValueCard/KeyValueCard"
import {startCase} from "lodash"

interface KeyValueCardViewProps {
	title?: string
	details: {[key: string]: any}
	keyPrefix?: string
	copyFunction?: () => void
	additionalHeaderContent?: React.ReactNode
	singleColumn?: boolean
	disabled?: boolean
	transparent?: boolean
	className?: string
}

export function KeyValueCardView({
	title,
	details,
	keyPrefix,
	copyFunction,
	additionalHeaderContent,
	singleColumn,
	disabled,
	className,
	transparent,
}: KeyValueCardViewProps) {
	return (
		<KeyValueCard
			title={title}
			copyFunction={copyFunction}
			additionalHeaderContent={additionalHeaderContent}
			singleColumn={singleColumn}
			className={className}
			transparent={transparent}
			disabled={disabled}
		>
			<KeyValueCardContainer>
				{Object.entries(details).map(([key, value]) => {
					return (
						<React.Fragment key={keyPrefix ? `${keyPrefix}-${key}` : key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue disabled={disabled}> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}
