import {Result} from "neverthrow"
import {DependencyList, useEffect, useState} from "react"
import {AsyncResult, AsyncResultRequestState} from "../types/asyncResult"
import {NextFunction, PrevFunction, SetLimit, usePaging} from "./usePaging"
import {OkDocument} from "../resources/common"
import {mergeResults} from "../utilities/asyncResult"

function createResult<T, E>(
	result: AsyncResultRequestState<T, E>,
	list: OkDocument<T[]>
): AsyncResultRequestState<T, E> {
	return result.match(
		() => (list?.data.length ? AsyncResult.ok<T, E>(list as any) : AsyncResult.pending()),
		() => AsyncResult.ok<T, E>(list as any),
		(e) => AsyncResult.err(e)
	)
}

export function useInfiniteScroll<T, E>(
	initialLimit: number,
	get: (offset: number, limit: number) => Promise<Result<T, E>>,
	fLength: (x: T) => number,
	deps: DependencyList = [],
	prefix = ""
): [AsyncResultRequestState<T, E>, boolean, boolean, PrevFunction, NextFunction, boolean, SetLimit, boolean] {
	const [list, setList] = useState<OkDocument<T[]>>(mergeResults<T>())
	const [result, ...rest] = usePaging(initialLimit, get, fLength, deps, prefix, false, true)

	useEffect(() => {
		if (result.isOk()) {
			setList((list) => mergeResults(list, result.value as any))
		}
	}, [result])

	const combinedResult = createResult(result, list)

	return [combinedResult, ...rest]
}
