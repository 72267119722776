import {createResource, getResource, Phone, Resource, updateResource} from "./common"

export const userTypes = ["unitUser", "orgUser", "bankUser", "partnerUser"]

export type UnitUser = {
	type: "unitUser"
	id: string
	attributes: {
		createdAt: Date
		username: string
		role: string
		isDisabled: boolean
		phone?: Phone
	}
}

export type OrgUser = {
	type: "orgUser"
	id: string
	attributes: {
		createdAt: Date
		username: string
		role: string
		isDisabled: boolean
		phone?: Phone
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
	}
}

export type PartnerUser = {
	type: "partnerUser"
	id: string
	attributes: {
		createdAt: Date
		username: string
		role: string
		isDisabled: boolean
		phone?: Phone
	}
	relationships: {
		partner: {
			data: {
				type: "partner"
				id: string
			}
		}
	}
}

export type BankUser = {
	type: "bankUser"
	id: string
	attributes: {
		createdAt: Date
		username: string
		role: string
		isDisabled: boolean
		phone?: Phone
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export type User = UnitUser | OrgUser | BankUser | PartnerUser

export async function findUsers(
	accessToken: string,
	offset: number,
	limit: number,
	orgs?: string[],
	searchQuery?: string
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : null
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			status,
			orgs: orgFilter,
			query: searchQuery != "" ? searchQuery : null,
		},
		include: "org",
	}

	return await getResource<Array<User>>("users", accessToken, query)
}

export async function getUser<User>(accessToken: string, id: string) {
	return await getResource<User>(`users/${id}`, accessToken, {include: "org"})
}

export async function createUnitUser(
	accessToken: string,
	username: string,
	password: string,
	role: string,
	phone: Phone
) {
	const result = await createResource<User>("users", accessToken, {
		type: "unitUser",
		attributes: {
			username,
			password,
			role,
			phone,
		},
	})
	return result.map((v) => v.data)
}

export async function createBankUser(
	accessToken: string,
	username: string,
	password: string,
	bankId: string,
	role: string,
	phone: Phone
) {
	const result = await createResource<User>("users", accessToken, {
		type: "bankUser",
		attributes: {
			username,
			password,
			bankId,
			...{role: mapDisplayRoleToUserRole(role, "bankUser")},
			phone,
		},
	})
	return result.map(({data}) => data)
}

export async function createOrgUser(
	accessToken: string,
	orgId: string,
	username: string,
	firstName: string,
	lastName: string,
	role: string,
	phone: Phone
) {
	const result = await createResource<User>("users", accessToken, {
		type: "orgUser",
		attributes: {
			username,
			firstName,
			lastName,
			role,
			phone,
		},
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
		},
	})
	return result.map((v) => v.data)
}

export async function createPartnerUser(
	accessToken: string,
	username: string,
	firstName: string,
	lastName: string,
	role: string,
	phone: Phone,
	partnerId?: string
) {
	const result = await createResource<User>("users", accessToken, {
		type: "partnerUser",
		attributes: {
			username,
			firstName,
			lastName,
			role,
			partnerId,
			phone,
		},
	})
	return result.map((v) => v.data)
}

export async function createOrgUserByOrgUser(
	accessToken: string,
	username: string,
	firstName: string,
	lastName: string,
	role: string,
	phone: Phone
) {
	const result = await createResource<User>("users", accessToken, {
		type: "orgUser",
		attributes: {
			username,
			firstName,
			lastName,
			role,
			phone,
		},
	})
	return result.map((v) => v.data)
}

export async function changePassword(
	accessToken: string,
	userId: string,
	oldPassword: string | undefined,
	newPassword: string
) {
	const result = await updateResource<User>(`users/${userId}`, accessToken, {
		type: "user",
		id: userId,
		attributes: {
			oldPassword: oldPassword,
			newPassword: newPassword,
		},
	})
	return result.map((v) => v.data)
}

function mapDisplayRoleToUserRole(role: string, userType: string) {
	if (userType === "bankUser") {
		if (role === "admin") return "bank"
		if (role === "readonly") return "bank-readonly"
		if (role === "operations") return "bank-operations"
	}
	return role
}

export async function updateUser(accessToken: string, userId: string, role: string, userType: string) {
	const userRole = mapDisplayRoleToUserRole(role, userType)
	const result = await updateResource<User>(`users/${userId}`, accessToken, {
		type: userType,
		id: userId,
		attributes: {
			role: userRole,
		},
	})
	return result.map((v) => v.data)
}

export async function patchUser(accessToken: string, user: User, {role, phone}: {role?: string; phone?: Phone}) {
	const userRole = role ? mapDisplayRoleToUserRole(role, user.type) : undefined
	const request = {
		type: user.type,
		id: user.id,
		attributes: {
			role: userRole,
			phone: phone,
		},
	}
	const result = await updateResource<User>(`users/${user.id}`, accessToken, request)
	return result.map((v) => v.data)
}

export async function changeUserStatus(accessToken: string, userId: string, disable: boolean) {
	const result = await createResource<User>(
		disable ? `users/${userId}/disable` : `users/${userId}/enable`,
		accessToken,
		{
			type: "user",
			id: userId,
		}
	)
	return result.map((v) => v.data)
}

export function isUser(resource: Resource): resource is User {
	return userTypes.includes(resource.type)
}

export function mapUsersIdToName(resources: Resource[]) {
	return new Map(
		resources.filter<User>(isUser).map((user, _, __) => {
			return [user?.id, user?.attributes?.username]
		})
	)
}
