import * as React from "react"
const AssignmentsEmptyStateGraphics = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={225} height={179} fill="none">
		<rect width={223} height={51} x={1.143} y={1} fill="#F4F5F7" rx={3.5} />
		<rect width={223} height={51} x={1.143} y={1} stroke="#E2E4E7" rx={3.5} />
		<rect width={6} height={39} x={8.643} y={7.5} fill="#EBECEE" rx={3} />
		<rect width={9} height={53} x={75.643} y={17.5} fill="#E2E4E7" rx={2} transform="rotate(90 75.643 17.5)" />
		<rect width={5} height={27} x={50.643} y={31.5} fill="#EBECEE" rx={2} transform="rotate(90 50.643 31.5)" />
		<path stroke="#E2E4E7" strokeLinecap="round" strokeWidth={2} d="m194.643 27.563 4.837 3.937 8.163-9" />
		<g opacity={0.6}>
			<rect width={223} height={51} x={1.141} y={64} fill="#F4F5F7" rx={3.5} />
			<rect width={223} height={51} x={1.141} y={64} stroke="#E2E4E7" rx={3.5} />
			<rect width={6} height={39} x={8.641} y={70.5} fill="#EBECEE" rx={3} />
			<rect width={9} height={107} x={129.641} y={76.5} fill="#E2E4E7" rx={2} transform="rotate(90 129.641 76.5)" />
			<rect width={5} height={39} x={62.641} y={90.5} fill="#EBECEE" rx={2} transform="rotate(90 62.64 90.5)" />
			<path stroke="#E3E3E3" strokeLinecap="round" strokeWidth={2} d="m194.641 90.563 4.837 3.937 8.163-9" />
		</g>
		<g opacity={0.4}>
			<rect width={223} height={51} x={1.141} y={127} fill="#F4F5F7" rx={3.5} />
			<rect width={223} height={51} x={1.141} y={127} stroke="#E2E4E7" rx={3.5} />
			<rect width={6} height={39} x={8.641} y={132.5} fill="#EBECEE" rx={3} />
			<rect width={9} height={79} x={101.641} y={141.5} fill="#E2E4E7" rx={2} transform="rotate(90 101.641 141.5)" />
			<rect width={5} height={58} x={81.641} y={155.5} fill="#EBECEE" rx={2} transform="rotate(90 81.64 155.5)" />
			<path stroke="#E3E3E4" strokeLinecap="round" strokeWidth={2} d="m194.641 156.562 4.837 3.938 8.163-9" />
		</g>
	</svg>
)
export default AssignmentsEmptyStateGraphics
