import React, {useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Account, freezeAccount, isFrozen, unfreezeAccount} from "../../resources/account"
import {useAccessToken, useIsBankAdminOrOperationsUser, useIsUnitUser} from "../../services/auth"
import Admonition from "../../components/Admonitions/Admonition"

interface FreezeAccountModalProps {
	account: Account
	closeModal: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

type FreezeReason = {
	reason: string
	subReason?: string
}

type FreezeReasonsMapping = {
	[key: string]: FreezeReason
}

const baseFreezeReasons: FreezeReasonsMapping = {
	"Fraud - ACH Activity": {reason: "Fraud", subReason: "AchActivity"},
	"Fraud - Card Activity": {reason: "Fraud", subReason: "CardActivity"},
	"Fraud - Wire Activity": {reason: "Fraud", subReason: "WireActivity"},
	"Fraud - Check Activity": {reason: "Fraud", subReason: "CheckActivity"},
	"Fraud - Account Activity": {reason: "Fraud", subReason: "AccountActivity"},
	"Fraud - Hold Harmless Received": {reason: "Fraud", subReason: "HoldHarmlessReceived"},
	"Fraud - Linked To Fraudulent Customer": {reason: "Fraud", subReason: "LinkedToFraudulentCustomer"},
	Other: {reason: "Other"},
}

const byBankFreezeReasons: FreezeReasonsMapping = {
	"By Bank - Prohibited Business": {reason: "ByBank", subReason: "ProhibitedBusiness"},
	"By Bank - Missing CDD/EDD": {reason: "ByBank", subReason: "MissingCddEdd"},
	"By Bank - Non-US Operations": {reason: "ByBank", subReason: "NonUsOperations"},
	"By Bank - Suspected Fraud": {reason: "ByBank", subReason: "SuspectedFraud"},
	"By Bank - Inactive": {reason: "ByBank", subReason: "Inactive"},
	"By Bank - Unfunded": {reason: "ByBank", subReason: "Unfunded"},
}

function getFreezeReasonsByUserClaims(isUnitUser: boolean, isBankAdminOrOperationsUser: boolean): FreezeReasonsMapping {
	if (isUnitUser) {
		return {
			...baseFreezeReasons,
			...byBankFreezeReasons,
		}
	} else if (isBankAdminOrOperationsUser) {
		return byBankFreezeReasons
	} else {
		return baseFreezeReasons
	}
}

export function FreezeAccountModal({account, closeModal, onSuccess}: FreezeAccountModalProps) {
	const accessToken = useAccessToken()
	const isUnitUser = useIsUnitUser()
	const isBankAdminOrOperationsUser = useIsBankAdminOrOperationsUser()

	const defaultReasonForBankUser = "By Bank - Prohibited Business"
	const defaultReasonForUnitAndOrgUsers = "Fraud - ACH Activity"
	const [reason, setReason] = useState(
		isBankAdminOrOperationsUser ? defaultReasonForBankUser : defaultReasonForUnitAndOrgUsers
	)
	const [reasonText, setReasonText] = useState("")
	const [freezeState, setFreezeState] = useAsyncResultIdle(freezeAccount)
	const [unfreezeState, setUnfreezeState] = useAsyncResultIdle(unfreezeAccount)

	const freezeReasons = getFreezeReasonsByUserClaims(isUnitUser, isBankAdminOrOperationsUser)

	if (!isFrozen(account))
		return (
			<AsyncResultModal
				title="Freeze Account"
				close={refreshAndClose(onSuccess, closeModal)}
				buttonClassname="is-black"
				state={freezeState}
				buttonText="Freeze"
				successText="Account Frozen"
				onSubmit={() =>
					setFreezeState({
						accessToken,
						account,
						reason: freezeReasons[reason].reason,
						subReason: freezeReasons[reason].subReason,
						reasonText,
					})
				}
				errorToText={(err) => err.errors[0].title}
			>
				<div className="field">
					<label className="label">Reason</label>
					<div className="control">
						<div className="select">
							<select onChange={(e) => setReason(e.target.value)} value={reason}>
								{Object.keys(freezeReasons).map((reasonTitle, i) => (
									<option value={reasonTitle} key={i}>
										{reasonTitle}
									</option>
								))}
							</select>
						</div>
						<div className="control">
							<br />
							<label className="label">Other Reason</label>
							<textarea
								className="textarea"
								placeholder="Why are you freezing this account?"
								value={reasonText}
								onChange={(e) => setReasonText(e.target.value)}
								required={reason === "Other"}
							/>
						</div>
					</div>
				</div>
				<Admonition
					type={"is-warning"}
					message={
						<p>
							{" "}
							Freezing an account prevents the customer from initiating any new activity. It is a temporary status that
							allows for manual review and investigation of the account activity, until a decision is made to unfreeze
							or close the account. Freezing an account freezes all cards associated with that account.{" "}
						</p>
					}
					title={"Note"}
				/>
			</AsyncResultModal>
		)
	return (
		<AsyncResultModal
			title="Unfreeze Account"
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-success"
			state={unfreezeState}
			buttonText="Unfreeze account"
			successText="Account Unfrozen"
			onSubmit={() => setUnfreezeState(accessToken, account)}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure?
			<br />
			<br />
			<Admonition
				type={"is-warning"}
				message={
					<p>
						{" "}
						Unfreezing an account returns the account to an “Active” state. <br /> Unfreezing an account unfreezes all
						cards associated with the account.{" "}
					</p>
				}
				title={"Note"}
			/>
		</AsyncResultModal>
	)
}
