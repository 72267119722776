import React from "react"
import {UnitsPosition, ValueAndUnits} from "../ValueAndUnits/ValueAndUnits"
import Icon from "../Icon/Icon"
import {Accordion} from "../Accordion/Accordion"
import {IcomoonIconName} from "../Icon/icons"
import {isNil, isPlainObject, startCase, toLower} from "lodash"
import {AuditLogEventChanges} from "../AuditLogEventsView/AuditLogEventsView"
import classNames from "classnames"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import moment from "moment/moment"
import {Address, FullName, Phone} from "../../resources/common"
import {generateAddressPreview} from "../AddressReadonlyField/AddressReadonlyField"
import {AccountStatusChange} from "../../resources/account"
import {Quote} from "../Quote/Quote"
import {CustomerStatusChange} from "../../resources/customer"

export type AuditLogDetailsBoxProps = {
	title: string
	icon?: IcomoonIconName
	changes?: AuditLogEventChanges[]
	initiallyExpanded?: boolean
}

type valueTypeProps = {
	units?: string
	unitsPosition?: UnitsPosition
}

const getValueAndUnitsTypeProps = (valueType?: string): valueTypeProps => {
	switch (valueType) {
		case "Currency":
			return {units: "$", unitsPosition: "Prefix"}
		case "Percentage":
			return {units: "%", unitsPosition: "Suffix"}
		case "Bps":
			return {units: " bps", unitsPosition: "Suffix"}
		case "Days":
			return {units: " Days", unitsPosition: "Suffix"}
		case "Cards":
			return {units: " Cards", unitsPosition: "Suffix"}
		default:
			return {}
	}
}
const convertValueByType = (value: string, valueType?: string) => {
	if (valueType === "Currency") {
		return Number(value) / 100
	}
	return Number(value)
}
const renderNumericValue = (value: string, valueType?: string) => {
	const valueAndUnitsTypeProps = getValueAndUnitsTypeProps(valueType)
	return (
		<ValueAndUnits
			value={convertValueByType(value, valueType)}
			className="value-and-units"
			size="small"
			theme="light"
			formatMethod={valueType === "Currency" ? "with_commas" : undefined}
			{...valueAndUnitsTypeProps}
		/>
	)
}
const renderStringValue = (value: string, valueType?: string) => {
	switch (valueType) {
		case "Timestamp":
			return moment(value).format("MM/DD/YYYY h:mm:ss A")
		case "Date":
			return moment(value).format("MM/DD/YYYY")
		default:
			return value
	}
}

function isConvertibleToNumber(string: string) {
	return /^\d+(\.\d+)?$/.test(string)
}

export const generateAccountStatusPreview = (accountStatusChange: AccountStatusChange) => {
	const {status, reason, subReason, reasonText} = accountStatusChange
	return (
		<div className={classNames(reasonText && "is-fullwidth")}>
			{startCase(status)}
			{reason && ` | ${startCase(reason)}`}
			{subReason && ` - ${startCase(subReason)}`}
			{reasonText && <Quote text={reasonText} />}
		</div>
	)
}

export const generateCustomerStatusPreview = (customerStatusChange: CustomerStatusChange) => {
	const {status, reason} = customerStatusChange
	return (
		<div>
			{startCase(status)}
			{reason && ` | ${startCase(reason)}`}
		</div>
	)
}

const renderValueToDisplay = (value: string, valueType?: string) => {
	try {
		const parsedData = JSON.parse(value)
		switch (valueType) {
			case "FullName":
				const {first, last} = parsedData as FullName
				return `${first} ${last}`
			case "Phone":
				const {countryCode, number} = parsedData as Phone
				return `+${countryCode} ${number}`
			case "Address":
				return <div className="address-container">{generateAddressPreview(parsedData as Address)}</div>
			case "AccountStatus":
				return generateAccountStatusPreview(parsedData as AccountStatusChange)
			case "CustomerStatus":
				return generateCustomerStatusPreview(parsedData as CustomerStatusChange)

			default:
				if (isPlainObject(parsedData)) {
					return JSON.stringify(parsedData)
				}
				if (isConvertibleToNumber(parsedData)) {
					return renderNumericValue(parsedData, valueType)
				}
				return renderStringValue(parsedData, valueType)
		}
	} catch (error) {
		console.error("JSON parsing error:", error.message)
		return value
	}
}
export function AuditLogDetailsBox({title, icon, changes, initiallyExpanded}: AuditLogDetailsBoxProps) {
	return (
		<Accordion
			title={startCase(title)}
			icon={icon ? icon : undefined}
			className="audit-log-details-box-container"
			initiallyExpanded={initiallyExpanded}
			noContentPadding
			theme="full"
		>
			{!!changes?.length && (
				<div className="icon-text">
					{changes.map(
						({patch}, index) =>
							!!patch && (
								<div key={index} className={classNames("changes-patch", !!icon && "content-with-icon")}>
									<GridContainer columns={1} rows={2} gap="2px" className="is-fullwidth">
										{patch.attributeName && (
											<span className="change-title">{toLower(startCase(patch.attributeName))}</span>
										)}
										<div className="change-content">
											{!isNil(patch.oldValue) && (
												<>
													{renderValueToDisplay(patch.oldValue, patch.valueType)}
													<div className="icon-container">
														<span className="icon">
															<Icon
																icon="keyboard-right-arrow--interface-essential"
																className="value-and-units-icon"
																size={12}
															/>
														</span>
													</div>
												</>
											)}
											{!isNil(patch.newValue) && renderValueToDisplay(patch.newValue, patch.valueType)}
										</div>
									</GridContainer>
								</div>
							)
					)}
				</div>
			)}
		</Accordion>
	)
}
export default AuditLogDetailsBox
