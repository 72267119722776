import React from "react"
import {useAccessToken} from "../../services/auth"
import {useParams} from "react-router-dom"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import {MainSection} from "../../containers/MainSection/MainSection"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import classNames from "classnames"
import {Adjustment, getAdjustment, StatusTypeValue} from "../../resources/adjustment"
import moment from "moment"
import numeral from "numeral"
import {UserName} from "../../components/Users/UserName"
import {GetCustomer, GetOrg, GetTransaction} from "../../components/Adjustments/Helpers"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {ActionButtons} from "../../components/Adjustments/ActionButtons"
import {prettyPrintType} from "../../resources/transaction"
import TitleBar from "../../components/TitleBar/TitleBar"
import {getCustomerName} from "../../resources/customer"

function AdjustmentInternal({value: adjustment, refresh}: {value: Adjustment; refresh: () => void}) {
	return (
		<>
			<TitleBar title={`#${adjustment.id}`} breadcrumbs={[{text: "Adjustments", path: "/adjustments/"}]} />
			<MainSection>
				<div className="columns">
					<div className="column is-6">
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Adjustment Information</p>
										<div>
											<span className="card-header-title">
												<ActionButtons adj={adjustment} refresh={refresh} />
											</span>
										</div>
									</div>

									<div className="card-content">
										{optionalHorizontalField("ID", adjustment.id.toString())}
										{adjustment.relationships.org?.data.id ? (
											<HorizontalField label="Org">
												{GetOrg(String(adjustment.relationships.org?.data.id), (org) => (
													<span>{org.attributes.name}</span>
												))}
											</HorizontalField>
										) : null}
										{optionalHorizontalField("Account Id", adjustment.relationships.account.data.id.toString())}
										{adjustment.relationships.customer?.data.id ? (
											<HorizontalField label="Customer">
												{GetCustomer(String(adjustment.relationships.customer?.data.id), (customer) => (
													<span>{getCustomerName(customer)}</span>
												))}
											</HorizontalField>
										) : null}
										{optionalHorizontalField("Direction", adjustment.attributes.direction)}
										{optionalHorizontalField("Amount", numeral(adjustment.attributes.amount / 100).format("$0,0.00"))}
										<HorizontalField label="Status History">
											<table className="table is-narrow">
												<tbody>
													{adjustment.attributes.statusHistory.map((s) => (
														<tr key={s.type}>
															<td>{StatusTypeValue(s.type)}</td>
															<UserName
																userId={s.byUserId.toString()}
																onResult={(user) => <td>By {user.attributes.username}</td>}
															/>
															<td>{moment(s.updatedAt).format("L")}</td>
														</tr>
													))}
												</tbody>
											</table>
										</HorizontalField>
										{optionalHorizontalField("Created At", moment(adjustment.attributes.createdAt).format("L"))}
										{optionalHorizontalField("Description", adjustment.attributes.description)}
										{optionalHorizontalField("Internal Note", adjustment.attributes.internalNote)}
									</div>
								</div>
								{adjustment.relationships.transaction?.data.id
									? GetTransaction(adjustment, (tx) => (
											<div className="card">
												<div className="card-header">
													<p className="card-header-title">Transaction Information</p>
												</div>
												<div className="card-content">
													{optionalHorizontalField("ID", tx.id.toString())}
													{optionalHorizontalField("Type", prettyPrintType(tx))}
													<HorizontalField label="Amount">
														<input
															type="text"
															readOnly
															value={numeral(tx.attributes.amount / 100).format("$0,0.00")}
															className={classNames(
																"input is-static",
																tx.attributes.direction === "Credit" && "has-text-success",
																tx.attributes.direction === "Debit" && "has-text-danger"
															)}
														/>
													</HorizontalField>
													{optionalHorizontalField("Summary", tx.attributes.summary)}
													{optionalHorizontalField("Created At", moment(tx.attributes.createdAt).format("L"))}
												</div>
											</div>
									  ))
									: null}
							</div>
						</div>
					</div>
				</div>
			</MainSection>
		</>
	)
}

export default function AdjustmentPage() {
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const {adjustmentId = ""} = useParams<{adjustmentId: string}>()
	const asyncResult = useAsyncResult(() => getAdjustment(accessToken, adjustmentId), [refreshToken])

	return (
		<AsyncResultComponent asyncResult={asyncResult}>
			{({value: value}) => {
				return <AdjustmentInternal value={value as Adjustment} refresh={refresh} />
			}}
		</AsyncResultComponent>
	)
}
