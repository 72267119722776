import {useAccessToken} from "../../services/auth"
import React, {PropsWithChildren, useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {BankSettings} from "../../resources/bankSettings"
import {Result} from "neverthrow"
import {ErrorDocument} from "../../resources/common"
import {OrgSettings} from "../../resources/orgSettings"

type CloseFunc = () => void
type RefreshFunc = () => void

interface SetupDomainSettingsModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	setupDomain: (accessToken: string, domainName: string) => Promise<Result<BankSettings | OrgSettings, ErrorDocument>>
	domainExampleText: string
}

export default function SetupDomainSettingsModal({
	close,
	refresh,
	setupDomain,
	domainExampleText,
	children,
}: PropsWithChildren<SetupDomainSettingsModalProps>) {
	const accessToken = useAccessToken()
	const [state, update] = useAsyncResultIdle(setupDomain)
	const [domainName, setDomainName] = useState("")

	return (
		<AsyncResultModal
			title="Setup Domain"
			onSubmit={() => update(accessToken, domainName)}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
			onSuccess={refresh}
		>
			<div className="field">
				<label className="label">Domain Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="A sub domain you own"
						value={domainName}
						required
						pattern="^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$"
						onChange={(e) => setDomainName(e.target.value)}
					/>
				</div>
				<p className="has-text-grey is-small mt-4">{domainExampleText}</p>
			</div>
			{children}
		</AsyncResultModal>
	)
}
