import React, {ReactElement} from "react"
import CheckPayments, {CheckPaymentsColumns} from "../../components/CheckPayments/CheckPayments"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"

export default function CheckPaymentsPage(): ReactElement {
	return (
		<>
			<TitleBar title={"Check Payments"} />
			<MainSectionTable>
				<CheckPayments
					enableAmountFilter={true}
					enableOrgFilter={true}
					enableDateFilter={true}
					fullHeight={true}
					enableStatusFilter={true}
					disableDrawer={false}
					includedColumns={[
						CheckPaymentsColumns.id,
						...(useIsBankUser() || useIsUnitUser() ? [CheckPaymentsColumns.org] : []),
						CheckPaymentsColumns.accountId,
						CheckPaymentsColumns.customer,
						CheckPaymentsColumns.amount,
						CheckPaymentsColumns.status,
						CheckPaymentsColumns.createdAt,
						CheckPaymentsColumns.sendAt,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
