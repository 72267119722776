import React from "react"
import Button from "../Button/Button"
interface AssignmentPageItemApproveRejectFooterProps {
	approveLabel: string
	rejectLabel: string
	onApprove: () => void
	onReject: () => void
	approveTooltip?: string
	rejectTooltip?: string
}

export function AssignmentPageItemApproveRejectFooter({
	approveLabel,
	rejectLabel,
	onApprove,
	onReject,
	approveTooltip,
	rejectTooltip,
}: AssignmentPageItemApproveRejectFooterProps) {
	return (
		<div className="assignment-page-item-callback-review-footer">
			<div className="assignment-page-item-callback-review-footer-buttons">
				<Button
					prefixIcon="delete-close-x"
					theme="danger"
					size="normal"
					onClick={onReject}
					tooltip={rejectTooltip}
					className="assignment-page-item-callback-review-footer-button"
				>
					{rejectLabel}
				</Button>
				<Button
					prefixIcon="approve-v-check-validation"
					theme="success"
					size="normal"
					onClick={onApprove}
					tooltip={approveTooltip}
					className="assignment-page-item-callback-review-footer-button"
				>
					{approveLabel}
				</Button>
			</div>
		</div>
	)
}
