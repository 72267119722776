import React from "react"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {getReceivedPayment, ReceivedPayment, ReceivedPaymentStatus} from "../../resources/receivedPayments"
import {Org} from "../../resources/org"
import {Customer} from "../../resources/customer"
import {Account} from "../../resources/account"
import {
	KeyValueCard,
	KeyValueCardContainer,
	KeyValueCardKey,
	KeyValueCardPending,
	KeyValueCardValue,
} from "../KeyValueCard/KeyValueCard"
import {kebabCase, startCase} from "lodash"
import moment from "moment"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import {useIsBankUser, useIsUnitUser} from "../../services/auth"
import {DrawerHeader} from "../Drawer/Drawer"
import classNames from "classnames"
import {LinkMetaBox, LinkMetaBoxContainer} from "../LinkMetaBox/LinkMetaBox"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import numeral from "numeral"
import {AccountCard} from "../Accounts/AccountCard"
import {CustomerCard} from "../Customers/CustomerCard"
import {BeneficialOwnerCards} from "../BeneficialOwners/BeneficalOwnerCard"

interface ReceivedPaymentComponentProps {
	receivedPaymentId: string
	receivedPaymentAsyncResult?: AsyncResultRequestState<OkDocument<ReceivedPayment>, ErrorDocument>
	accessToken: string
	useDrawer: boolean
	refresh: () => void
}

function ReceivedPaymentInfoPending() {
	return (
		<div className="received-payment-content">
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
			<KeyValueCardPending rows={6} />
		</div>
	)
}

export function ReceivedAchFields({receivedPayment}: {receivedPayment: ReceivedPayment}) {
	const name = receivedPayment.attributes.companyName
	const routingNumber = receivedPayment.attributes.counterpartyRoutingNumber

	const counterpartyDetails = {
		...(name ? {name} : {}),
		...(routingNumber ? {routingNumber} : {}),
	}

	return (
		<KeyValueCard title={"Counterparty Details"}>
			<KeyValueCardContainer>
				{Object.entries(counterpartyDetails).map(([key, value]) => {
					return (
						<React.Fragment key={key}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

export function ReceivedPaymentDetails({receivedPayment}: {receivedPayment: ReceivedPayment}) {
	const amount = receivedPayment.attributes.amount && numeral(receivedPayment.attributes.amount / 100).format("$0,0.00")
	const createdAt = receivedPayment.attributes.createdAt && moment(receivedPayment.attributes.createdAt).format("L")
	const estimatedCompletion =
		receivedPayment.attributes.completionDate && moment(receivedPayment.attributes.completionDate).format("L")
	const type = receivedPayment.type
	const direction = receivedPayment.attributes.direction
	const reason = receivedPayment.attributes.returnReason
	const reasonCode = receivedPayment.attributes.achReturnReason

	const receivedPaymentDetails = {
		...(amount ? {amount} : {}),
		...(createdAt ? {createdAt} : {}),
		...(estimatedCompletion ? {estimatedCompletion} : {}),
		...(type ? {type} : {}),
		...(direction ? {direction} : {}),
		...(reason ? {reason: `${reasonCode} - ${reason}`} : {}),
	}

	return (
		<KeyValueCard title={"Payment Details"}>
			<KeyValueCardContainer>
				{Object.entries(receivedPaymentDetails).map(([key, value]) => {
					return (
						<React.Fragment key={`payment-details-${key}`}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

export function ReceivedPaymentAdditionalDetails({receivedPayment}: {receivedPayment: ReceivedPayment}) {
	const addenda = receivedPayment.attributes.addenda
	const tags = receivedPayment.attributes.tags && Object.keys(receivedPayment.attributes.tags).length > 0 && (
		<TagsViewerKeyValue tags={receivedPayment.attributes.tags} />
	)
	const traceNumber = receivedPayment.attributes.traceNumber
	const secCode = receivedPayment.attributes.secCode
	const advanced = receivedPayment.attributes.wasAdvanced ? "Yes" : "No"
	const receivingEntityName = receivedPayment.attributes.receivingEntityName ?? "N/A"

	const receivedPaymentAdditionalDetails = {
		...(receivingEntityName ? {receivingEntityName} : {}),
		...(addenda ? {addenda} : {}),
		...(tags ? {tags} : {}),
		...(traceNumber ? {traceNumber} : {}),
		...(secCode ? {secCode} : {}),
		...(advanced ? {advanced} : {}),
	}

	return (
		<KeyValueCard title={"Additional Payment Details"}>
			<KeyValueCardContainer>
				{Object.entries(receivedPaymentAdditionalDetails).map(([key, value]) => {
					return (
						<React.Fragment key={`payment-details-${key}`}>
							<KeyValueCardKey> {startCase(key)} </KeyValueCardKey>
							<KeyValueCardValue> {value} </KeyValueCardValue>
						</React.Fragment>
					)
				})}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

function ReceivedPaymentInternal({
	receivedPayment,
	useDrawer,
	account,
	accessToken,
	customers,
	org,
}: {
	receivedPayment: ReceivedPayment
	accessToken: string
	refresh: () => void
	useDrawer: boolean
	customers?: Customer[]
	org?: Org
	account?: Account
}) {
	const {attributes} = receivedPayment
	const accountId = receivedPayment.relationships.account.data.id || undefined
	const transactionId = receivedPayment.relationships.receivePaymentTransaction?.data.id || undefined
	const customer = receivedPayment.relationships.customer?.data || undefined
	const shouldShowContentCardsSideBySide =
		(useIsUnitUser() || useIsBankUser()) && !useDrawer && attributes.status == ReceivedPaymentStatus.PendingReview
	return (
		<>
			{useDrawer && (
				<DrawerHeader
					title={`${receivedPayment.type.replace("ReceivedPayment", "").toUpperCase()} #${receivedPayment.id}`}
				>
					<div className={classNames("incoming-payments-status", kebabCase(attributes.status))}>
						{startCase(attributes.status)}
					</div>
				</DrawerHeader>
			)}

			<div className={classNames("received-payment-content", shouldShowContentCardsSideBySide && "pr-3")}>
				<ReceivedPaymentDetails receivedPayment={receivedPayment} />
				<ReceivedPaymentAdditionalDetails receivedPayment={receivedPayment} />
				{account && <AccountCard account={account} accessToken={accessToken} />}
				{customers?.map((customer) => {
					return (
						<React.Fragment key={customer.id}>
							<CustomerCard org={org} customer={customer} customerId={customer.id} />
							{customer.relationships.application && customer.type === "businessCustomer" && (
								<BeneficialOwnerCards
									applicationId={customer.relationships.application?.data.id}
									accessToken={accessToken}
								/>
							)}
						</React.Fragment>
					)
				})}
				<ReceivedAchFields receivedPayment={receivedPayment} />

				{useDrawer && (
					<KeyValueCard title={"Related"}>
						<KeyValueCardContainer>
							<LinkMetaBoxContainer>
								<LinkMetaBox
									text={"Account"}
									icon={"bank-account--business-products"}
									path={`/accounts/${accountId}`}
								/>
								{accountId && transactionId && (
									<LinkMetaBox
										text={"Transaction"}
										icon={"money-transfer--business-products"}
										path={`/transaction/${accountId}/${transactionId}`}
									/>
								)}

								{customer && (
									<LinkMetaBox
										key={`tag-${customer.id}`}
										text={`Customer #${customer.id}`}
										icon={"user-geometric-action-money-fund---users"}
										path={`/customers/${customer.id}`}
									/>
								)}
							</LinkMetaBoxContainer>
						</KeyValueCardContainer>
					</KeyValueCard>
				)}
			</div>
		</>
	)
}

export function ReceivedPaymentComponent({
	receivedPaymentId: receivedPaymentId,
	accessToken,
	useDrawer,
	refresh,
	receivedPaymentAsyncResult,
}: ReceivedPaymentComponentProps) {
	const receivedPaymentAsyncReq =
		receivedPaymentAsyncResult ||
		useAsyncResult(() => getReceivedPayment(accessToken, receivedPaymentId, "customer,account,incomingAchItem"))

	return (
		<AsyncResultComponent asyncResult={receivedPaymentAsyncReq} pendingComponent={<ReceivedPaymentInfoPending />}>
			{({value: receivedPayment}) => {
				const include = receivedPayment.included
				const account = include && (include.find((i) => i.type === "depositAccount") as Account)
				const customers =
					include && (include.filter((i) => ["individualCustomer", "businessCustomer"].includes(i.type)) as Customer[])
				return (
					<ReceivedPaymentInternal
						useDrawer={useDrawer}
						receivedPayment={receivedPayment.data}
						customers={customers}
						accessToken={accessToken}
						refresh={refresh}
						account={account}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}
