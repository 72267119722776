import {createResource} from "./common"
import {IncomingAch} from "./incomingAch"

export async function returnReceivedAchTransaction(
	accessToken: string,
	transactionId: string | number,
	accountId: number,
	reason: string
) {
	const data = {
		type: "returnAch",
		attributes: {
			reason,
		},
		relationships: {
			account: {
				data: {
					type: "account",
					id: accountId,
				},
			},
		},
	}

	return await createResource<IncomingAch>(`returns/${transactionId}`, accessToken, data)
}
