import {Result} from "neverthrow"
import React, {createContext, PropsWithChildren, useContext} from "react"
import {useAsyncExpiredMemo} from "../hooks/useAsyncExpiredMemo"
import {getOrgCustomerToken} from "../resources/org"
import {ErrorDocument} from "../resources/common"
import {useAccessToken} from "./auth"
import {assertIsDefined} from "../utilities/assert"
import Loading from "../components/Loading/Loading"
import {Err} from "../components/Err/Err"

const context = createContext<OrgCustomerToken | undefined>(undefined)

export type OrgCustomerToken = {
	type: "orgCustomerToken"
	id: string
	attributes: {
		token: string
		expiresIn: number
		customerId: string
	}
}

function getCustomerToken(accessToken: string): Promise<Result<OrgCustomerToken, ErrorDocument>> {
	return getOrgCustomerToken(accessToken)
}

export function OrgAuthProvider({children, onError}: PropsWithChildren<{onError?: (e: ErrorDocument) => JSX.Element}>) {
	const accessToken = useAccessToken()
	const customerToken = useAsyncExpiredMemo(
		() => getCustomerToken(accessToken),
		(t) => t.attributes.expiresIn
	)

	return customerToken.match(
		() => <Loading />,
		(t) => <context.Provider value={t}>{children}</context.Provider>,
		(err) => (onError ? onError(err) : <Err err={err} />)
	)
}

export function useCustomerAccessToken() {
	const token = useContext(context)
	const accessToken = token?.attributes.token
	assertIsDefined(accessToken)
	return accessToken
}

export function getOrgTokenCustomer() {
	const token = useContext(context)
	const customerId = token?.attributes.customerId
	assertIsDefined(customerId)
	return customerId
}

export function useCustomerToken() {
	const token = useContext(context)
	assertIsDefined(token)
	return token
}

export function isOrg() {
	return !!useContext(context)
}
