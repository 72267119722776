import React, {PropsWithChildren, useEffect, useRef} from "react"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {useClickAway} from "react-use"
import {useEsc} from "../../hooks/useEsc"

interface AsyncModalProps<T, E> {
	classname?: string
	title?: string
	buttonText: string
	buttonClassname: string
	state: AsyncResultIdleRequestState<T, E>
	onSubmit: Function
	onSuccess?: Function
	successText: string
	successClassname?: string
	errorToText: (err: E) => string
	successComponent?: (value: T) => JSX.Element
	close: () => void
	disableCloseOnSuccess?: boolean
	enableClickAway?: boolean
	okComponent?: JSX.Element
	blockBodyScroll?: boolean
	outerChildren?: JSX.Element
	readOnly?: boolean
}

function Idle<T, E>(props: PropsWithChildren<AsyncModalProps<T, E>>) {
	const buttonClassname = classNames("button", props.buttonClassname)

	return (
		<>
			<section className="modal-card-body">
				<fieldset disabled={props.readOnly}>{props.children}</fieldset>
			</section>
			<footer className="modal-card-foot">
				{!props.readOnly && <input type="submit" className={buttonClassname} value={props.buttonText} />}
			</footer>
		</>
	)
}

function Pending<T, E>(props: PropsWithChildren<AsyncModalProps<T, E>>) {
	const buttonClassname = classNames("button", "is-loading", props.buttonClassname)

	return (
		<>
			<section className="modal-card-body">
				<fieldset disabled>{props.children}</fieldset>
			</section>
			<footer className="modal-card-foot">
				{!props.readOnly && (
					<button disabled className={buttonClassname}>
						{props.buttonText}
					</button>
				)}
			</footer>
		</>
	)
}

function Ok<T, E>(props: PropsWithChildren<AsyncModalProps<T, E> & {value: T}>) {
	useEffect(() => props.onSuccess?.(), [])

	useEffect(() => {
		if (!props.disableCloseOnSuccess) {
			setTimeout(() => props.close(), 1500)
		}
	}, [])

	if (props.successComponent) return props.successComponent(props.value)

	return props.okComponent ? (
		<>
			<section className="modal-card-body">{props.okComponent}</section>
		</>
	) : (
		<>
			<section className="modal-card-body">
				<fieldset disabled>{props.children}</fieldset>
			</section>
			<footer className="modal-card-foot">
				{!props.readOnly && <div className={props.successClassname ?? "has-text-success"}>{props.successText}</div>}
			</footer>
		</>
	)
}

function Err<T, E>(props: PropsWithChildren<AsyncModalProps<T, E>> & {err: E}) {
	const buttonClassname = classNames("button", props.buttonClassname)
	return (
		<>
			<section className="modal-card-body">
				<fieldset disabled={props.readOnly}>{props.children}</fieldset>
			</section>
			<footer className="modal-card-foot">
				<div className="has-text-danger">{props.errorToText(props.err)}</div>
				{!props.readOnly && <input type="submit" className={buttonClassname} value={props.buttonText} />}
			</footer>
		</>
	)
}

export function AsyncResultModal<T, E>(props: PropsWithChildren<AsyncModalProps<T, E>>) {
	// @TODO Remove delay on call. Search for: Dela

	const formRef = useRef<HTMLFormElement>(null)
	const content = props.state.match(
		() => <Idle {...props} />,
		() => <Pending {...props} />,
		(v) => <Ok value={v} {...props} />,
		(err) => <Err {...props} err={err} />
	)

	const ref = useRef(null)

	useEsc(props.close)

	if (props.enableClickAway) {
		useClickAway(ref, () => props.close())
	}

	useEffect(() => {
		if (props.blockBodyScroll) {
			const body = document.querySelector("body") as HTMLBodyElement
			body.classList.add("no-body-scroll")
			return () => {
				body.classList.remove("no-body-scroll")
			}
		}
	})

	return (
		<div className={classNames("modal", "is-active", props.classname)}>
			{props.outerChildren}
			<div className="modal-background" />
			<form
				ref={formRef}
				{...(props.readOnly
					? {}
					: {
							onSubmit: (e) => {
								e.preventDefault()
								props.onSubmit()
							},
					  })}
			>
				<div className="modal-card" ref={ref}>
					<header className="modal-card-head">
						<label className="delete" onClick={() => props.close()}>
							{" "}
							<Icon icon="interface-delete-interface-essential" size={16} />
						</label>
					</header>
					{props.title && <h3 className="modal-card-title">{props.title}</h3>}
					{content}
				</div>
			</form>
		</div>
	)
}

AsyncResultModal.defaultProps = {
	buttonDisabled: false,
	enableClickAway: true,
	blockBodyScroll: false,
}
