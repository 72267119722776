import {useAccessToken, useUserType} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {usePaging} from "../../hooks/usePaging"
import {findCardDesigns} from "../../resources/cardDesign"
import type {CardDesign} from "../../resources/cardDesign"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {OrgHeader} from "../../components/OrgHeader/OrgHeader"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import React from "react"
import {ErrorDocument, Resource} from "../../resources/common"
import {OrgName} from "../../components/OrgName/OrgName"
import {useModal} from "react-modal-hook"
import CardDesignModal from "./CardDesignModal"
import moment from "moment"
import TitleBar from "../../components/TitleBar/TitleBar"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"
import {useQueryState} from "use-location-state"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {Filter} from "../../components/Filter/Filter"
import Icon from "../../components/Icon/Icon"

function CardDesignTableRow({
	cardDesign,
	included,
	refresh,
}: {
	cardDesign: CardDesign
	included?: Resource[]
	refresh: () => void
}) {
	const [showModal, hideModal] = useModal(() => (
		<CardDesignModal cardDesignId={cardDesign.id} close={hideModal} refresh={refresh} mode={"edit"} />
	))

	return (
		<tr onClick={showModal}>
			<td>{cardDesign.id}</td>
			<OrgName orgId={cardDesign.relationships.org.data.id} included={included} />
			<th>{cardDesign.attributes.name}</th>
			<td>{cardDesign.attributes.cardQualifier}</td>
			<td>{moment(cardDesign.attributes.createdAt).format("L")}</td>
		</tr>
	)
}

export default function CardDesigns() {
	const PAGE_SIZE = 25
	const accessToken = useAccessToken()
	const userType = useUserType()

	const [refreshToken, refresh] = useRefreshToken()
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>("filter[orgs]", [])

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		PAGE_SIZE,
		(offset, limit) => findCardDesigns(accessToken, offset, limit, filteredOrgs),
		(x) => x.data.length,
		[refreshToken, filteredOrgs.join(",")]
	)
	const [showModal, hideModal] = useModal(() => (
		<CardDesignModal close={hideModal} refresh={refresh} mode="create" cardDesignId={undefined} />
	))

	const orgs =
		userType === "unit"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs Filter"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(PAGE_SIZE)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	return (
		<>
			<TitleBar title={"Card Designs"}>
				<button className="button button-create" onClick={() => showModal()}>
					<Icon icon="interface-add-1" size={12} /> Create
				</button>
			</TitleBar>
			<MainSectionTable>
				<DataTableCard className={"card-design-card"}>
					<DataTableActionHeader enableSticky={true} filters={[orgFilter]} />
					<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
						{({value: cardDesigns}) => {
							const rows = cardDesigns.data.map((cd) => (
								<CardDesignTableRow key={cd.id} included={cardDesigns.included} cardDesign={cd} refresh={refresh} />
							))

							return (
								<>
									<DataTable fullHeight={true} isEmpty={rows.length === 0} noContentText={"No card designs found"}>
										<DataTableHead>
											<DataTableRow>
												<DataTableCell>Id</DataTableCell>
												<OrgHeader>Org</OrgHeader>
												<DataTableCell>Name</DataTableCell>
												<DataTableCell>Card Qualifier</DataTableCell>
												<DataTableCell>Created At</DataTableCell>
											</DataTableRow>
										</DataTableHead>
										<DataTableBody>{rows}</DataTableBody>
									</DataTable>
									<PagingNavBar hasResults={hasResults} hasPrev={hasPrev} hasNext={hasNext} prev={prev} next={next} />
								</>
							)
						}}
					</AsyncResultComponent>
				</DataTableCard>
			</MainSectionTable>
		</>
	)
}
