import React from "react"
import Drawer from "../Drawer/Drawer"
import AuditLogEventsHeader, {AuditLogEventsHeaderMetaItemProps} from "../AuditLogEventsHeader/AuditLogEventsHeader"
import AuditLogComponent from "../AuditLogComponent/AuditLogComponent"
import {AuditLogResource} from "../../resources/auditLog"

interface AuditLogDrawerProps {
	open: boolean
	onClose: () => void
	auditLogEventsHeaderMetaItems: AuditLogEventsHeaderMetaItemProps[]
	resourceType: AuditLogResource
	resourceId: string
}

export function AuditLogDrawer({
	open,
	onClose,
	auditLogEventsHeaderMetaItems,
	resourceType,
	resourceId,
}: AuditLogDrawerProps) {
	return (
		<Drawer
			open={open}
			onClose={onClose}
			direction="right"
			size="670px"
			title={<AuditLogEventsHeader meta={auditLogEventsHeaderMetaItems} />}
			blockBody={false}
		>
			{open && <AuditLogComponent resourceType={resourceType} resourceId={resourceId} />}
		</Drawer>
	)
}
export default AuditLogDrawer
