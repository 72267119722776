import React, {useEffect} from "react"
import {useAccessToken} from "../../services/auth"
import {useNavigate, useParams} from "react-router-dom"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import Back from "../../components/Back/Back"
import {Authorization, AuthorizationStatus, get, release} from "../../resources/authorization"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import moment from "moment"
import {kebabCase, startCase} from "lodash"
import numeral from "numeral"
import LinkButton from "../../components/LinkButton/LinkButton"
import {isOrg} from "../../services/orgAuth"
import {OkDocument} from "../../resources/common"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useModal} from "react-modal-hook"
import {ConfirmationModal} from "../../components/ConfirmationModal/ConfirmationModal"
import {useToasts} from "react-toast-notifications"
import {getDeclineReason} from "../../components/AccountAuthorizations/AccountAuthorizations"

function AuthorizationInternal({
	value: authorizationDocument,
}: {
	value: OkDocument<Authorization>
	accessToken: string
}) {
	const {addToast} = useToasts()
	const isOrgAuthorization = isOrg()
	const au = authorizationDocument.data
	const {attributes} = au
	const accessToken = useAccessToken()
	const accountId = au.relationships.account?.data.id || undefined
	const customerId = au.relationships.customer?.data.id || undefined
	const [releaseState, releaseAction] = useAsyncResultIdle(release)
	const navigate = useNavigate()
	useEffect(() => {
		releaseState.match(
			() => null,
			() => null,
			() => navigate(`/accounts/${accountId}`, {replace: true}),
			(e) => addToast(e.errors[0].title, {appearance: "error"})
		)
	}, [releaseState])

	const [showReleaseConfirmationModal, hideReleaseConfirmationModal] = useModal(
		() => (
			<ConfirmationModal
				title="Release Authorization"
				close={hideReleaseConfirmationModal}
				okButtonClassname={"button is-success is-outlined"}
				cancelButtonClassname="button is-white"
				onSubmit={() => {
					releaseAction(accessToken, au.id)
				}}
			>
				You chose to cancel an authorization for <b>{au.attributes.amount}</b>, on the card ending in{" "}
				<b>{au.attributes.cardLast4Digits}</b>. This will release the hold on the account, Are you sure you want to
				release the authorization?
			</ConfirmationModal>
		),
		[]
	)

	return (
		<>
			<TitleBar
				title={"Authorization"}
				status={{
					text: startCase(attributes.status),
					className: `authorization-status ${kebabCase(attributes.status)}`,
				}}
			/>
			<MainSection>
				<div className="columns">
					<div className="column">
						<div className="buttons">
							<Back />
							{accountId ? (
								<LinkButton to={`/${isOrgAuthorization ? "org-" : ""}account/${accountId}`}>Account</LinkButton>
							) : null}
							{customerId && !isOrgAuthorization ? (
								<LinkButton to={`/customers/${customerId}`}>Customer</LinkButton>
							) : null}
						</div>
					</div>

					<div className="column">
						<UnitUserOnly>
							<div className="buttons is-right">
								<UnitUserOnly>
									<button className="button is-light" onClick={() => showReleaseConfirmationModal()}>
										<span className="icon">
											<FontAwesomeIcon icon={faAngleDoubleRight} />
										</span>
										<span>Release An Authorization</span>
									</button>
								</UnitUserOnly>
							</div>
						</UnitUserOnly>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Authorization Information</p>
									</div>
									<div className="card-content">
										{optionalHorizontalField("ID", au.id.toString())}
										{optionalHorizontalField("Type", startCase(au.type))}
										<HorizontalField label="Amount">
											<input
												type="text"
												readOnly
												className="input is-static"
												value={numeral(au.attributes.amount / 100).format("$0,0.00")}
											/>
										</HorizontalField>
										{optionalHorizontalField("Card Last 4 Digits", attributes.cardLast4Digits)}
										{optionalHorizontalField("Merchant Name", attributes.merchant.name)}
										{optionalHorizontalField("Merchant Type", attributes.merchant.type)}
										{optionalHorizontalField("Merchant Category", attributes.merchant.category)}
										{optionalHorizontalField("Merchant Location", attributes.merchant.location)}
										{attributes.status === AuthorizationStatus.Declined &&
											optionalHorizontalField(
												"Decline Reason",
												getDeclineReason(attributes.declineReason, attributes.status)
											)}
										{optionalHorizontalField("Created At", moment(attributes.createdAt).format("L LT"))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</MainSection>
		</>
	)
}

export function AuthorizationComponent({
	authorizationId,
	accessToken,
	includeNonAuthorized = true,
}: {
	authorizationId: string
	accessToken: string
	includeNonAuthorized?: boolean
}) {
	const authorizationAsyncResult = useAsyncResult(() => get(accessToken, authorizationId, includeNonAuthorized))

	return (
		<AsyncResultComponent asyncResult={authorizationAsyncResult} accessToken={accessToken}>
			{({value}) => {
				return <AuthorizationInternal value={value as OkDocument<Authorization>} accessToken={accessToken} />
			}}
		</AsyncResultComponent>
	)
}

export default function AuthorizationPage() {
	const accessToken = useAccessToken()
	const {authorizationId = ""} = useParams<{authorizationId: string}>()

	return <AuthorizationComponent authorizationId={authorizationId} accessToken={accessToken} />
}
