import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {Payment, approvePayment} from "../../resources/payment"

interface ApprovePaymentModalProps {
	payment: Payment
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ApprovePaymentModal({payment, close, onSuccess}: ApprovePaymentModalProps) {
	const accessToken = useAccessToken()
	const [state, approve] = useAsyncResultIdle(approvePayment)

	return (
		<AsyncResultModal
			title="Approve Payment"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSubmit={() => approve(accessToken, payment.id)}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure?
		</AsyncResultModal>
	)
}
