import React, {SetStateAction, Dispatch} from "react"
import Icon from "../Icon/Icon"

interface ModalPdfNavProps {
	pageNumber: number
	numPages: number
	setPageNumber: Dispatch<SetStateAction<number>>
}

export function ModalPdfNav({pageNumber, setPageNumber, numPages}: ModalPdfNavProps) {
	return (
		<nav className={"pdf-navigation"}>
			<button aria-label="previous button" disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)}>
				<span className="icon">
					<Icon icon="arrow-left-chevron" />
				</span>
			</button>

			<span className="pdf-nav-text">
				Page {pageNumber} of {numPages}
			</span>

			<button aria-label="next button" disabled={pageNumber === numPages} onClick={() => setPageNumber(pageNumber + 1)}>
				<span className="icon">
					<Icon icon="arrow-right-chevron" />
				</span>
			</button>
		</nav>
	)
}
