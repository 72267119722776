import React from "react"
import {Editable, EditableDropDownField} from "../HorizontalField/EditableHorizontalField"
import {
	BusinessAnnualRevenue,
	BusinessAnnualRevenueMapping,
	SoleProprietorshipAnnualRevenue,
	SoleProprietorshipAnnualRevenueMapping,
} from "../../resources/application"

export interface EditableSoleProprietorshipAnnualRevenueProps {
	annualRevenue?: SoleProprietorshipAnnualRevenue
	setAnnualRevenue: (value: SoleProprietorshipAnnualRevenue) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: SoleProprietorshipAnnualRevenue) => void
	className?: string
}

export interface EditableBusinessAnnualRevenueProps {
	annualRevenue?: BusinessAnnualRevenue
	setAnnualRevenue: (value: BusinessAnnualRevenue) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: BusinessAnnualRevenue) => void
	className?: string
}

export function EditableSolePropAnnualRevenueField(props: EditableSoleProprietorshipAnnualRevenueProps) {
	if (props.annualRevenue) {
		return (
			<Editable
				label="Annual Revenue"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setAnnualRevenue}
				value={props.annualRevenue}
				initial={props.annualRevenue}
				update={props.update}
				className={props.className}
				possibleValues={SoleProprietorshipAnnualRevenueMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}

	return null
}

export function EditableBusinessAnnualRevenueField(props: EditableBusinessAnnualRevenueProps) {
	if (props.annualRevenue) {
		return (
			<Editable
				label="Annual Revenue"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setAnnualRevenue}
				value={props.annualRevenue}
				initial={props.annualRevenue}
				update={props.update}
				className={props.className}
				possibleValues={BusinessAnnualRevenueMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}

	return null
}
