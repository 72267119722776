import {optionalHorizontalField} from "../HorizontalField/HorizontalField"
import {Accordion} from "../Accordion/Accordion"
import React from "react"
import {
	AnnualIncomeMapping,
	BusinessVerticalMapping,
	IndividualApplication,
	OccupationMapping,
	SoleProprietorshipAnnualRevenueMapping,
	SoleProprietorshipNumberOfEmployeesMapping,
	SourceOfIncomeMapping,
} from "../../resources/application"
import {OkDocument} from "../../resources/common"

export function IndividualAdditionalInformationFields({
	individualApplication,
	isArchived,
}: {
	individualApplication: OkDocument<IndividualApplication>
	isArchived: boolean
}) {
	const application = individualApplication.data
	const archivedClassName = isArchived ? "archived-field" : ""
	const occupation = OccupationMapping.get(application.attributes.occupation as string)
	const sourceOfIncome = SourceOfIncomeMapping.get(application.attributes.sourceOfIncome as string)
	const annualIncome = AnnualIncomeMapping.get(application.attributes.annualIncome as string)
	const businessVertical = BusinessVerticalMapping.get(application.attributes.businessVertical as string)
	const numberOfEmployees = SoleProprietorshipNumberOfEmployeesMapping.get(
		application.attributes.numberOfEmployees as string
	)
	const annualRevenue = SoleProprietorshipAnnualRevenueMapping.get(application.attributes.annualRevenue as string)

	return (
		<Accordion title={"Additional Information"} theme={"light"} noContentPadding={true}>
			{occupation ? optionalHorizontalField("Occupation", occupation, undefined, archivedClassName) : null}
			{sourceOfIncome
				? optionalHorizontalField("Source of Income", sourceOfIncome, undefined, archivedClassName)
				: null}
			{annualIncome ? optionalHorizontalField("Annual Income", annualIncome, undefined, archivedClassName) : null}
			{businessVertical
				? optionalHorizontalField("Business Vertical", businessVertical, undefined, archivedClassName)
				: null}
			{!application.attributes.hasNoWebsite && application.attributes.website
				? optionalHorizontalField("Website", application.attributes.website, undefined, archivedClassName)
				: null}
			{numberOfEmployees
				? optionalHorizontalField("Number of Employees", numberOfEmployees, undefined, archivedClassName)
				: null}
			{annualRevenue ? optionalHorizontalField("Annual Revenue", annualRevenue, undefined, archivedClassName) : null}
		</Accordion>
	)
}
