import {createResource, getResource, updateResource} from "./common"

export type SignatureAlgorithm = "sha1" | "sha256" | "sha512" | undefined

export enum JWTProviders {
	Auth0 = "Auth0",
	Stytch = "Stytch",
	Cognito = "Cognito",
	Okta = "Okta",
}

export interface SecretsObject {
	clientId: string
	secret: string
}

export interface PlaidSecrets extends SecretsObject {
	clientId: string
	secret: string
}

export interface AstraSecrets extends SecretsObject {
	clientId: string
	secret: string
}

export type OrgSettings = {
	type: "orgSettings"
	id: string
	attributes: {
		generalSettings: {
			preventDuplicateIndividualApplications: boolean
			showSensitiveDataForPhysicalCards: boolean
			enableTagInheritanceForCards: boolean
			programmaticAuthorizationHttpPostURL: string | null
			secretToken: string | null
		}
		samlSettings?: {
			orgWorkspace: string
			entryPoint: string
			cert: string
			signatureAlgorithm: SignatureAlgorithm
			enforceSSOLogin?: boolean
		}
		jwtSettings?: {
			provider: JWTProviders
			jwksPath: string
			issuer?: string
		}
		dashboardSettings?: {
			whitelistSourceIps: string
		}
		statementsSettings: {
			statementFont?: string
			statementLogo?: string
			statementHeaderBackground?: string
			statementHeaderColor?: string
			statementPhone: string
			statementEmail: string
			statementRepresentativeName?: string
		}
		applicationFormSettings: {
			applicationFormCustomCss?: string
			applicationFormLogo?: string
			applicationFormFavicon?: string
			applicationFormBrandName?: string
			applicationFormRedirectUrl?: string
			applicationFormRedirectButtonText?: string
			applicationFormPrivacyPolicyUrl?: string
			applicationFormElectronicDisclosuresUrl?: string
			applicationFormDepositTermsUrl?: string
			applicationFormClientTermsUrl?: string
			applicationFormCardholderTermsUrl?: string
			applicationFormCashAdvancedTermsUrl?: string
			applicationFormDebitCardDisclosureUrl?: string
			applicationFormContactUsEmail?: string
			applicationFormAdditionalDisclosures?: Array<ApplicationFormAdditionalDisclosure>
			applicationFormValidatePhoneNumber?: boolean
		}
		applicationFormDomainSettings: {
			applicationFormDomain?: string
			applicationFormCertificateStatus?: "Verified" | "Unverified"
		}
		createdAt: Date
		updatedAt?: Date
		plaidSecretsSet?: boolean
		astraSecretsSet?: boolean
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: number
			}
		}
	}
}

export type StatementLogo = {
	type: "orgSettingsStatementLogo"
	attributes: {
		statementLogo?: string
	}
}

type OrgSettingsPatch = {
	generalSettings?: {
		preventDuplicateIndividualApplications: boolean
		showSensitiveDataForPhysicalCards: boolean
		enableTagInheritanceForCards: boolean
		programmaticAuthorizationHttpPostURL: string | null
		secretToken: string | null
	} | null
	samlSettings?: {
		orgWorkspace: string
		entryPoint: string
		cert: string
		signatureAlgorithm: SignatureAlgorithm
		enforceSSOLogin?: boolean
	} | null
	jwtSettings?: {
		provider: JWTProviders
		jwksPath: string
		issuer?: string
	}
	dashboardSettings?: {
		whitelistSourceIps: string
	} | null
	statementsSettings?: {
		statementFont?: string
		statementLogo?: string
		statementHeaderBackground?: string
		statementHeaderColor?: string
		statementPhone: string
		statementEmail: string
		statementRepresentativeName?: string
	} | null
	applicationFormSettings?: {
		applicationFormCustomCss?: string
		applicationFormLogo?: string
		applicationFormFavicon?: string
		applicationFormBrandName?: string
		applicationFormRedirectUrl?: string
		applicationFormPrivacyPolicyUrl?: string
		applicationFormElectronicDisclosuresUrl?: string
		applicationFormDepositTermsUrl?: string
		applicationFormClientTermsUrl?: string
		applicationFormCardholderTermsUrl?: string
		applicationFormCashAdvancedTermsUrl?: string
		applicationFormContactUsEmail?: string
		applicationFormValidatePhoneNumber?: boolean
	}
}

export type DnsSetting = {
	type: "domainDnsSetting"
	attributes: {
		key: string
		value: string
	}
}

export type ApplicationFormAdditionalDisclosure = {title: string; url: string}

export async function getByToken(accessToken: string) {
	const result = await getResource<OrgSettings>("org-settings", accessToken)
	return result.map((v) => v.data)
}

export async function getStatementLogoByToken(accessToken: string) {
	const result = await getResource<StatementLogo>("org-settings/statement-logo", accessToken)
	return result.map((v) => v.data)
}

export async function update(accessToken: string, patch: OrgSettingsPatch) {
	const result = await updateResource<OrgSettings>("org-settings", accessToken, {
		type: "orgSettings",
		attributes: {
			...patch,
		},
	})
	return result.map((v) => v.data)
}

export async function setupDomain(accessToken: string, domainName: string) {
	const result = await createResource<OrgSettings>("org-settings/setup-domain", accessToken, {
		domainName,
	})
	return result.map((v) => v.data)
}

export async function VerifyDomain(accessToken: string) {
	const result = await createResource<OrgSettings>("org-settings/link-domain", accessToken, {})
	return result.map((v) => v.data)
}

export async function deleteDomain(accessToken: string) {
	const result = await createResource<OrgSettings>("org-settings/delete-domain", accessToken, {})
	return result.map((v) => v.data)
}

export async function getClientDomainConfig(accessToken: string) {
	const result = await getResource<Array<DnsSetting>>("org-settings/get-client-domain-config", accessToken)
	return result.map((v) => v.data)
}

export async function patchPlaidSecrets(accessToken: string, secretObject: PlaidSecrets) {
	const result = await updateResource("org-settings/plaid-secrets", accessToken, {
		type: "plaidSecrets",
		attributes: {...secretObject},
	})

	return result.map((v) => v.data)
}

export async function patchAstraSecrets(accessToken: string, secretObject: AstraSecrets) {
	const result = await updateResource("org-settings/astra-secrets", accessToken, {
		type: "astraSecrets",
		attributes: {...secretObject},
	})
	return result.map((v) => v.data)
}
