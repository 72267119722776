import {EditableString, OptionalEditable} from "../HorizontalField/EditableHorizontalField"
import {emailPreview} from "../InformationHiding/InformationHiding"
import {optionalHorizontalField} from "../HorizontalField/HorizontalField"
import React from "react"
export interface EditableWebsiteFieldProps {
	website?: string
	setWebsite: (value: string) => void
	isEditing: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: string) => void
	className?: string
	isArchived: boolean
	isUpdating: boolean
	isAllowed: boolean
	isDisabled: boolean
	hasNoWebsite?: boolean
}

export function EditableWebsiteField({
	website,
	setWebsite,
	isEditing,
	onStartEdit,
	onCancel,
	update,
	isArchived,
	isUpdating,
	isAllowed,
	hasNoWebsite,
	isDisabled,
}: EditableWebsiteFieldProps) {
	const archivedClassName = isArchived ? "archived-field" : ""
	if (!hasNoWebsite) {
		return (
			<OptionalEditable
				label="Website"
				isEditing={isEditing}
				isDisabled={isDisabled}
				isAllowed={isAllowed}
				isUpdating={isUpdating}
				onStartEdit={onStartEdit}
				onCancel={onCancel}
				setValue={setWebsite}
				value={website}
				initial={website}
				update={update}
				inputType="text"
				previewFunction={emailPreview}
				className={archivedClassName}
			>
				{EditableString}
			</OptionalEditable>
		)
	} else {
		return optionalHorizontalField("Website", "No Website", undefined, archivedClassName)
	}
}
