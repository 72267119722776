import React, {createContext, PropsWithChildren, useContext, useEffect} from "react"
import useAsyncResultIdle from "../hooks/useAsyncResultIdle"
import {getPermissions, Permissions} from "../resources/permissions"
import {useAccessToken, useIsAuthenticated} from "./auth"

const authContext = createContext<Map<string, [string]>>(new Map<string, [string]>())

export function PermissionProvider({children}: PropsWithChildren<{}>) {
	let accessToken: string | undefined = undefined
	const [getPermissionsState, getPermissionsFunc] = useAsyncResultIdle(getPermissions)

	useEffect(() => {
		if (accessToken) {
			getPermissionsFunc(accessToken)
		}
	}, [accessToken, useIsAuthenticated()])

	function generatePermissions(t: Permissions) {
		const res = new Map<string, [string]>()
		t.attributes.permissions.map((p) => res.set(p.resource, p.actions))
		return res
	}

	if (useIsAuthenticated()) {
		accessToken = useAccessToken()
		return (
			<authContext.Provider
				value={getPermissionsState.match(
					() => new Map<string, [string]>(),
					() => new Map<string, [string]>(),
					(t) => generatePermissions(t),
					() => new Map<string, [string]>()
				)}
			>
				{children}
			</authContext.Provider>
		)
	} else {
		accessToken = undefined
		return <authContext.Provider value={new Map<string, [string]>()}>{children}</authContext.Provider>
	}
}

export function hasPermission(resource: string, action: string) {
	const permission = useContext(authContext)
	const resourceActions = permission.get(resource)
	if (resourceActions) {
		return resourceActions.includes(action)
	}
	return false
}
