import React from "react"
import Card from "../../containers/Card/Card"
import {OverallInterest, OverallInterestPending} from "./OverallRateBox"

export function InterestAndDepositFeeView({
	overallInterestRatePercentage,
	fedUpperBound,
	fedLowerBound,
	interestFormula,
}: {
	overallInterestRatePercentage: number
	fedUpperBound: number
	fedLowerBound: number
	interestFormula: string
}) {
	return (
		<Card
			title={"Interest and Deposit Fee"}
			icon={"analytic-percentage--business-products"}
			className="interest-and-deposit-fee-card"
		>
			<OverallInterest
				overallInterestRatePercentage={overallInterestRatePercentage}
				fedUpperBound={fedUpperBound}
				fedLowerBound={fedLowerBound}
				interestFormula={interestFormula}
			/>
		</Card>
	)
}

export function InterestAndDepositFeeViewPending() {
	return (
		<Card
			title={"Interest and Deposit Fee"}
			icon={"analytic-percentage--business-products"}
			className="interest-and-deposit-fee-card"
		>
			<OverallInterestPending />
		</Card>
	)
}
