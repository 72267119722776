import {Resource} from "../../resources/common"
import React from "react"
import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getBank} from "../../resources/bank"
import Skeleton from "react-loading-skeleton"

export function BankName({bankId, included, token}: {bankId: string; included?: Resource[]; token?: string}) {
	if (included) {
		const bank = included.find((r) => r.type === "bank" && r.id === bankId)
		if (bank && bank.type == "bank") return <>{bank.attributes.name}</>
	}

	const accessToken = token ?? useAccessToken()

	// TODO: cache the requests
	const result = useAsyncResult(() => getBank(accessToken, bankId), [bankId])

	return result.match(
		() => <Skeleton />,
		(bank) => <>{bank.attributes.name}</>,
		(_err) => <>Error</>
	)
}
