import React from "react"
import {useModal} from "react-modal-hook"
import SetupDomainSettingsModal from "../../components/OrgAndBankSettings/SetupDomainSettingsModal"
import {setupDomain} from "../../resources/bankSettings"
import Admonition from "../../components/Admonitions/Admonition"

type RefreshFunc = () => void

interface DomainSetupProps {
	refresh: RefreshFunc
}

export default function DomainSetup({refresh}: DomainSetupProps) {
	const [showSetupModal, hideSetupModal] = useModal(() => (
		<SetupDomainSettingsModal
			close={hideSetupModal}
			refresh={refresh}
			setupDomain={setupDomain}
			domainExampleText="Example: 'disclosures.yourdomain.com'"
		>
			<Admonition
				type="is-info"
				title="Note"
				message={
					<>To complete the subdomain configuration, you have 72 hours to add the records to you DNS provider.</>
				}
			/>
		</SetupDomainSettingsModal>
	))

	return (
		<>
			<div className="field ">
				<label className="label">Domain Name</label>
				<p className="field has-text-grey">Click setup to configure your legal docs URL with your own subdomain</p>
				<div className="field has-addons is-horizontal">
					<button
						type="button"
						className="button is-success"
						onClick={(e) => {
							e.preventDefault()
							showSetupModal()
						}}
					>
						Setup
					</button>
				</div>
			</div>
		</>
	)
}
