import React, {useState} from "react"
import {generate} from "generate-password"
import {useCopyToClipboard} from "react-use"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faCopy} from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"

const passwordRequiredLength = 12
const strongPasswordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{" + `${passwordRequiredLength}` + ",}$"
export const nameRegex = "^[^\\^`_!¡÷?¿\\/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$"

export function Username({username, setUsername}: {username: string; setUsername: (username: string) => void}) {
	return (
		<div className="field">
			<label className="label">Username</label>
			<div className="control">
				<input
					className="input"
					type="email"
					placeholder="Email"
					value={username}
					autoComplete="new-username"
					required
					onChange={(e) => setUsername(e.target.value)}
				/>
			</div>
		</div>
	)
}

function generatePassword(length: number = passwordRequiredLength) {
	return generate({
		length: length,
		numbers: true,
		symbols: true,
		strict: true,
		exclude: "({}[]()/'\"`~,;:.<>?-+=|_)",
	})
}

export function Password({
	password,
	setPassword,
	onGenerated,
	title = "Password",
	isExpended = false,
}: {
	password: string
	setPassword: (password: string) => void
	onGenerated?: (password: string) => void
	title?: string
	isExpended?: boolean
}) {
	const [isClearText, setIsClearText] = useState(false)
	const [state, copyToClipboard] = useCopyToClipboard()

	return (
		<div className="field ">
			<label className="label">{title}</label>
			<div className="field has-addons">
				<div className={classNames("control", isExpended && "is-expanded")}>
					{isClearText ? (
						<input className="input" type="text" readOnly={true} disabled={true} value={password} />
					) : (
						<input
							className="input"
							type="password"
							placeholder="Password"
							pattern={strongPasswordRegex}
							title={`Must contain at least one number and special character and one uppercase and lowercase letter, and at least ${passwordRequiredLength} or more characters`}
							required
							value={password}
							autoComplete="new-password"
							onChange={(e) => setPassword(e.target.value)}
						/>
					)}
				</div>
				{!isClearText ? (
					<div className="control">
						<button
							className="button is-info is-full-height hidden"
							type="button"
							onClick={() => {
								const password = generatePassword()
								setPassword(password)
								if (onGenerated) {
									onGenerated(password)
								}
								setIsClearText(true)
							}}
						>
							Generate Password
						</button>
					</div>
				) : (
					<div className="control">
						<button type="button" className="button is-info is-full-height" onClick={() => copyToClipboard(password)}>
							<span className="icon">
								<FontAwesomeIcon size="sm" icon={state.value ? faCheck : faCopy} />
							</span>
							<span>{state.value ? "Copied" : "Copy"}</span>
						</button>
					</div>
				)}
			</div>
			{password && password.length > 0 && !RegExp(strongPasswordRegex).test(password) ? (
				<p className="help is-danger">
					{`Must contain at least one number and special character and one uppercase and lowercase letter, and at least ${passwordRequiredLength}
					or more characters`}
				</p>
			) : null}
		</div>
	)
}
