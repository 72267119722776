import React from "react"
import {
	AchPayment,
	Payment,
	PaymentDirection,
	getExpectedCompletionTimeField,
	isAchPayment,
	isCardToCardPayment,
	isPlaidVerificationMethod,
	isWirePayment,
} from "../../resources/payment"
import {Customer} from "../../resources/customer"
import {startCase} from "lodash"
import moment from "moment"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import {extractDataFromFromImadOmad} from "../../utilities/helpers"
import {useCopyToClipboard} from "react-use"
import IconTag from "../Tag/IconTag"
import numeral from "numeral"
import classNames from "classnames"
import {KeyValueCardView} from "../RenderKeyValueCard/KeyValueCardView"

const removeInvalidChars = (field?: string) => field?.replace(/[^a-zA-Z0-9\s.?!,;:_@&/\\'"`~()<>$#%+\-=]/g, " ")

export function getAchPaymentFeatures(payment: AchPayment) {
	const features: Array<JSX.Element> = []

	if (payment.attributes.sameDay) {
		features.push(
			<IconTag key="same-day" icon={"clock--interface-essential"} iconSize={20}>
				Same day ACH
			</IconTag>
		)
	}

	if (payment.relationships.recurringPayment) {
		features.push(
			<IconTag key="recurring-payment" icon={"money-recurring-payment"} iconSize={24}>
				Recurring payment <strong>{`#${payment.relationships.recurringPayment.data.id}`}</strong>
			</IconTag>
		)
	}

	if (payment.attributes.clearingDaysOverride) {
		features.push(
			<div className="icon-tag" key="clearing-days-override">
				Dynamic Clearing | {payment.attributes.clearingDaysOverride} days
			</div>
		)
	}

	if (features.length > 0) {
		return <div className="icon-tags-container">{features}</div>
	}

	return null
}

interface PaymentDetailsProps {
	payment: Payment
	customers?: Customer[]
	singleColumn?: boolean
	isUnitUser?: boolean
	liteVersion?: boolean
	className?: string
	disabled?: boolean
	title?: string
}

export function PaymentDetails({
	payment,
	customers,
	singleColumn,
	isUnitUser,
	liteVersion,
	className,
	disabled,
	title = "Payment Details",
}: PaymentDetailsProps) {
	const type =
		isAchPayment(payment) && payment.attributes.direction === PaymentDirection.Debit
			? `${startCase(payment.type.replace("Payment", ""))} ${payment.attributes.direction}`
			: startCase(payment.type.replace("Payment", ""))
	const settlementDate =
		isAchPayment(payment) && payment.attributes.settlementDate && moment(payment.attributes.settlementDate).format("L")
	const counterpartyVerificationMethod =
		isAchPayment(payment) && isPlaidVerificationMethod(payment)
			? payment.attributes.counterpartyVerificationMethod
			: undefined
	const direction = payment.attributes.direction && payment.attributes.direction
	const amount = payment.attributes.amount && numeral(payment.attributes.amount / 100).format("$0,0.00")
	const description = (
		<span className={"payment-description-text"}>
			{" "}
			{payment.attributes.description && payment.attributes.description}{" "}
		</span>
	)
	const reason = payment.attributes.reason && payment.attributes.reason
	const traceNumber = isAchPayment(payment) && payment.attributes.traceNumber
	const astraRoutineId = isCardToCardPayment(payment) && payment.attributes.astraRoutineId

	const createdAt = payment.attributes.createdAt && moment(payment.attributes.createdAt).format("L")
	const expectedCompletionTime =
		isAchPayment(payment) &&
		getExpectedCompletionTimeField(payment) &&
		moment(getExpectedCompletionTimeField(payment)).format("L")
	const paymentFeatures = isAchPayment(payment) && getAchPaymentFeatures(payment)
	const tags = payment.attributes.tags && <TagsViewerKeyValue tags={payment.attributes.tags} />
	const imad = isWirePayment(payment) && (payment.attributes.imadOmad?.imad || payment.attributes?.imad)
	const dataFromImad = imad && extractDataFromFromImadOmad(imad)
	const imadOmadTags = isWirePayment(payment) &&
		payment.attributes.imadOmad &&
		imad &&
		payment.attributes.imadOmad.omad && (
			<TagsViewerKeyValue
				tags={{
					IMAD: imad,
					...(dataFromImad
						? {
								"IMAD Cycle Date": dataFromImad.cycleDate,
								"IMAD Sequence Number": dataFromImad.sequenceNumber,
						  }
						: {}),
					OMAD: payment.attributes.imadOmad.omad,
				}}
			/>
		)
	const imadTags = isWirePayment(payment) && !imadOmadTags && payment.attributes.imad && (
		<TagsViewerKeyValue tags={{IMAD: payment.attributes.imad}} />
	)

	const [, copyToClipboard] = useCopyToClipboard()
	const paymentsFieldsToCopy = {}
	const secCode = isAchPayment(payment) && payment.attributes.secCode && payment.attributes.secCode
	const counterpartyName = isWirePayment(payment) && removeInvalidChars(payment.attributes.counterparty.name)
	const paymentDetails = liteVersion
		? {
				...(amount ? {amount} : {}),
				...(createdAt ? {createdAt} : {}),
				...(direction ? {direction} : {}),
				...(counterpartyName ? {counterpartyName} : {}),
		  }
		: {
				...(type ? {type} : {}),
				...(paymentFeatures ? {paymentFeatures} : {}),
				...(direction ? {direction} : {}),
				...(settlementDate ? {settlementDate} : {}),
				...(counterpartyVerificationMethod ? {counterpartyVerificationMethod} : {}),
				...(amount ? {amount} : {}),
				...(description ? {description} : {}),
				...(reason ? {reason} : {}),
				...(createdAt ? {createdAt} : {}),
				...(astraRoutineId ? {astraRoutineId} : {}),
				...(expectedCompletionTime ? {expectedCompletionTime} : {}),
				...(secCode ? {secCode} : {}),
				...(traceNumber ? {traceNumber} : {}),
				...(imadOmadTags ? {"IMAD OMAD": imadOmadTags} : {}),
				...(imadTags ? {IMAD: imadTags} : {}),
				...(tags ? {tags} : {}),
		  }

	if (isWirePayment(payment)) {
		let customerName = ""

		if (customers) {
			customers.forEach((customer) => {
				const name =
					customer.type === "businessCustomer" || customer.type === "businessFBOCustomer"
						? customer.attributes.name
						: `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
				if (customerName === "") {
					customerName += name
				} else {
					customerName += ", " + name
				}
			})
		}

		Object.assign(paymentsFieldsToCopy, {
			wireName: payment.id,
			routingNumber: payment.attributes.counterparty.routingNumber,
			accountNumber: payment.attributes.counterparty.accountNumber,
			counterpartyAddressStreet: removeInvalidChars(`${payment.attributes.counterparty.address.street}`),
			counterpartyName: removeInvalidChars(payment.attributes.counterparty.name),
			counterpartyAddressCity: removeInvalidChars(payment.attributes.counterparty.address.state)
				? removeInvalidChars(
						`${payment.attributes.counterparty.address.city}, ${payment.attributes.counterparty.address.state} ${payment.attributes.counterparty.address.postalCode}`
				  )
				: removeInvalidChars(
						`${payment.attributes.counterparty.address.city} ${payment.attributes.counterparty.address.postalCode}`
				  ),
			counterpartyAddressCountry: removeInvalidChars(payment.attributes.counterparty.address.country),
			description: removeInvalidChars(`${payment.attributes.description} Sent on behalf of ${customerName}`),
			amount: payment.attributes.amount,
		})
	}

	return (
		<KeyValueCardView
			title={title}
			details={paymentDetails}
			keyPrefix="payment-details"
			copyFunction={
				isWirePayment(payment) && isUnitUser ? () => copyToClipboard(JSON.stringify(paymentsFieldsToCopy)) : undefined
			}
			singleColumn={singleColumn}
			className={classNames(className)}
			disabled={disabled}
		/>
	)
}
