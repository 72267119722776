import React from "react"
import classNames from "classnames"
import ReactTooltip from "react-tooltip"

interface AmountInfoCircleProps {
	amount: number
	tooltip?: string | JSX.Element
	isActive: boolean
}

export function AmountInfoCircle({amount, tooltip, isActive}: AmountInfoCircleProps) {
	return (
		<span
			className={classNames("amount-info-circle", isActive && "is-active")}
			data-tip="React-tooltip"
			data-for="amount-info-circle"
		>
			{amount}
			{tooltip && (
				<ReactTooltip className="amount-info-circle-tooltip" id="amount-info-circle" effect="solid">
					{tooltip}
				</ReactTooltip>
			)}
		</span>
	)
}
