import React from "react"
import {Claims, isOrgUser, useAccessToken, useIsUnitUser, useUserClaimsData} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {
	Assignee,
	AssigneeType,
	Assignment,
	AssignmentItem,
	AssignmentItemCommonProps,
	AssignmentStage,
	AssignmentStatus,
	AssignmentType,
	CallbackAssignmentAttributes,
	CallbackAssignmentItemPayload,
	CallbackReviewAssignmentAttributes,
	CallbackReviewAssignmentItemPayload,
	CallbackReviewAssignmentRelationships,
	findAssignments,
	GeneralReviewAssignmentAttributes,
	GeneralReviewAssignmentItemPayload,
	isCallbackAssignment,
	OfacCheckAssignmentAttributes,
	OfacCheckAssignmentItemPayload,
} from "../../resources/assignments"
import {AsyncResultZipComponent} from "../../containers/AsyncResult/AsyncResult"
import AssignmentsView from "./AssignmentsView"
import {Resource} from "../../resources/common"
import {Org} from "../../resources/org"
import {Bank} from "../../resources/bank"
import {toLower} from "lodash"
import {isWirePayment, Payment} from "../../resources/payment"
import {BusinessApplication} from "../../resources/application"
import {mergeResults} from "../../utilities/asyncResult"

interface AssignmentsProps {
	resource: Resource
	included?: Resource[]
	payload?: AssignmentPayload
	refreshToken: number
	refresh: () => void
}

export type AssignmentPayload = {
	businessApplication?: BusinessApplication
}

const getAssignmentTitle = (assignmentType: AssignmentType, id: string) => {
	switch (assignmentType) {
		case AssignmentType.callbackAssignment:
			return `Callback #${id}`
		case AssignmentType.callbackReviewAssignment:
			return `Callback Review #${id}`
		case AssignmentType.ofacCheckAssignment:
			return `OFAC List Match #${id}`
		case AssignmentType.generalReviewAssignment:
			return `General Review #${id}`
		default:
			return `Unknown Assignment #${id}`
	}
}

const getAssignmentDescription = (assignment: Assignment) => {
	switch (assignment.type) {
		case AssignmentType.ofacCheckAssignment:
			const sdn = assignment.attributes.ofacHit.sdn
			return (
				<div>
					{sdn.sdnName} <span className="has-text-weight-bold">{`${(sdn.match * 100).toFixed(0)}% Match`}</span>
				</div>
			)
		default:
			return undefined
	}
}

export const getIsOwnersItem = ({
	assigneeType,
	bankId,
	orgId,
	claims,
	isUnitUser,
}: {
	assigneeType: AssigneeType
	bankId: string
	orgId: string
	claims: Claims
	isUnitUser: boolean
}) => {
	switch (assigneeType) {
		case AssigneeType.Unit:
			return isUnitUser
		case AssigneeType.Bank:
			return !!bankId && bankId === claims.bankId
		case AssigneeType.Org:
			return !!orgId && orgId === claims.orgId
		default:
			return false
	}
}

const getAssignee = ({
	type,
	resourceIdToName,
	claims,
	bankId,
	orgId,
}: {
	type: AssigneeType
	resourceIdToName: Map<string, string>
	claims: Claims
	bankId?: string
	orgId?: string
}): Assignee => {
	switch (type) {
		case AssigneeType.Unit:
			return {type, name: "Unit"}
		case AssigneeType.Bank:
			const bankKey = `bank_${bankId}`
			return {type, name: resourceIdToName.get(bankKey) ?? "Bank"}
		case AssigneeType.Org:
			if (isOrgUser(claims.userType)) {
				return {type, name: claims.org}
			}
			const orgKey = `org_${orgId}`
			return {type, name: resourceIdToName.get(orgKey) ?? "Org"}
		default:
			return {type, name: "Unknown"}
	}
}

const getCallbackAssignmentItemPayload = (attributes: CallbackAssignmentAttributes) => {
	const {callbackSummary, callbackDocumentMetadata} = attributes
	const callbackAssignmentItemPayload: CallbackAssignmentItemPayload = {
		...(callbackSummary ? {callbackSummary} : {}),
		...(callbackDocumentMetadata ? {callbackDocumentMetadata} : {}),
	}
	return callbackAssignmentItemPayload
}

const getCallbackReviewAssignmentItemPayload = (
	relationships: CallbackReviewAssignmentRelationships,
	buildAssignee: BuildAssignee,
	attributes: CallbackReviewAssignmentAttributes,
	assignmentsIncluded?: Resource[]
) => {
	const callbackAssignment = assignmentsIncluded?.find(
		(resource) =>
			resource.type === AssignmentType.callbackAssignment && resource.id === relationships.callbackAssignment.data.id
	)
	if (!callbackAssignment || !isCallbackAssignment(callbackAssignment)) {
		return undefined
	}
	const {callbackSummary, callbackDocumentMetadata, assignee: assigneeType} = callbackAssignment.attributes
	if (!callbackSummary) {
		console.error("Callback Review Assignment missing Callback Summary")
		return undefined
	}
	const callbackReviewAssignmentItemPayload: CallbackReviewAssignmentItemPayload = {
		callbackSummary: callbackSummary,
		...(callbackDocumentMetadata ? {callbackDocumentMetadata} : {}),
		evidenceCollector: buildAssignee({
			assigneeType: assigneeType,
			bankId: callbackAssignment.relationships.bank.data.id,
			orgId: callbackAssignment.relationships.org.data.id,
		}),
		reasonText: attributes.reasonText,
	}
	return callbackReviewAssignmentItemPayload
}

const getOfacCheckAssignmentItemPayload = (attributes: OfacCheckAssignmentAttributes) => {
	const {ofacHit, counterpartyDetails, reason, reasonText} = attributes
	const ofacCheckAssignmentItemPayload: OfacCheckAssignmentItemPayload = {
		ofacHit,
		wireDetails: counterpartyDetails,
		reason,
		reasonText,
	}
	return ofacCheckAssignmentItemPayload
}

const getGeneralReviewAssignmentItemPayload = (attributes: GeneralReviewAssignmentAttributes) => {
	const {reason, reasonText} = attributes
	const generalReviewAssignmentItemPayload: GeneralReviewAssignmentItemPayload = {
		reason,
		reasonText,
	}
	return generalReviewAssignmentItemPayload
}

type BuildAssignee = ({
	assigneeType,
	bankId,
	orgId,
}: {
	assigneeType: AssigneeType
	bankId?: string
	orgId?: string
}) => Assignee

const processAssignment = ({
	assignment,
	claims,
	isUnitUser,
	resourceIdToName,
	assignmentsIncluded,
}: {
	assignment: Assignment
	claims: Claims
	isUnitUser: boolean
	resourceIdToName: Map<string, string>
	assignmentsIncluded?: Resource[]
}): AssignmentItem => {
	const {id, attributes, relationships} = assignment
	const {org, bank} = relationships
	const {createdAt, statusUpdatedAt, assignee: assigneeType, status, stage} = attributes
	const bankId = bank?.data.id
	const orgId = org?.data.id
	const buildAssignee: BuildAssignee = ({assigneeType, bankId, orgId}) =>
		getAssignee({type: assigneeType, resourceIdToName, claims, bankId, orgId})
	const assignmentItemCommonProps: AssignmentItemCommonProps = {
		id,
		title: getAssignmentTitle(assignment.type, id),
		description: getAssignmentDescription(assignment),
		status: status as AssignmentStatus,
		stage: stage as AssignmentStage,
		assignees: [buildAssignee({assigneeType, bankId, orgId})],
		createdAt: new Date(createdAt),
		statusUpdatedAt: new Date(statusUpdatedAt),
		isOwnersItem: getIsOwnersItem({
			assigneeType,
			bankId,
			orgId,
			claims,
			isUnitUser,
		}),
	}
	switch (assignment.type) {
		case AssignmentType.callbackAssignment: {
			return {
				...assignmentItemCommonProps,
				type: assignment.type,
				payload: getCallbackAssignmentItemPayload(assignment.attributes),
			}
		}
		case AssignmentType.callbackReviewAssignment: {
			return {
				...assignmentItemCommonProps,
				type: assignment.type,
				payload: getCallbackReviewAssignmentItemPayload(
					assignment.relationships,
					buildAssignee,
					assignment.attributes,
					assignmentsIncluded
				),
			}
		}
		case AssignmentType.ofacCheckAssignment: {
			return {
				...assignmentItemCommonProps,
				type: assignment.type,
				payload: getOfacCheckAssignmentItemPayload(assignment.attributes),
			}
		}
		case AssignmentType.generalReviewAssignment: {
			return {
				...assignmentItemCommonProps,
				type: assignment.type,
				payload: getGeneralReviewAssignmentItemPayload(assignment.attributes),
			}
		}
	}
}

export default function Assignments({resource, included, payload, refreshToken, refresh}: AssignmentsProps) {
	const accessToken = useAccessToken()
	const claims = useUserClaimsData()
	const isUnitUser = useIsUnitUser()
	const payment = resource as Payment
	const approvals = isWirePayment(payment) ? payment.relationships.approvals?.data.id ?? [] : []
	const findAssignmentsInclude = ["org", "bank", "callbackAssignment"].join(",")
	const pendingAssignmentsAsyncResult = useAsyncResult(
		() =>
			findAssignments({
				approvals,
				accessToken,
				stages: [AssignmentStage.Pending],
				include: findAssignmentsInclude,
				sort: "createdAt",
			}),
		[refreshToken]
	)
	const completedAssignmentsAsyncResult = useAsyncResult(
		() =>
			findAssignments({
				approvals,
				accessToken,
				stages: [AssignmentStage.Completed],
				include: findAssignmentsInclude,
				sort: "-statusUpdatedAt",
			}),
		[refreshToken]
	)
	return (
		<AsyncResultZipComponent
			asyncResult1={pendingAssignmentsAsyncResult}
			asyncResult2={completedAssignmentsAsyncResult}
		>
			{({value1, value2}) => {
				const {data: assignments, included: assignmentsIncluded} = mergeResults(value1, value2)
				const resourceIdToName: Map<string, string> = new Map()
				assignmentsIncluded
					?.filter((item) => ["org", "bank"].includes(item.type))
					?.map((item) => {
						const key = toLower(`${item.type}_${item.id}`)
						resourceIdToName.set(key, (item as Org | Bank).attributes.name ?? "")
					})
				return (
					<AssignmentsView
						resource={resource}
						included={included}
						payload={payload}
						assignments={assignments.map((assignment) =>
							processAssignment({
								assignment,
								claims,
								isUnitUser,
								resourceIdToName,
								assignmentsIncluded,
							})
						)}
						accessToken={accessToken}
						refresh={refresh}
					/>
				)
			}}
		</AsyncResultZipComponent>
	)
}
