import React, {Dispatch, SetStateAction, useState} from "react"
import {toNumber} from "lodash"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {Counterparty, findCounterparties} from "../../resources/counterparty"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
	faCheckCircle,
	faCircleNotch,
	faDollarSign,
	faExclamationCircle,
	faTimes,
} from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {createLinkedCounterpartyAchPayment, Payment, PaymentStatus} from "../../resources/payment"
import {DepositAccount, FinancialBusinessFBOAccount} from "../../resources/account"
import numeral from "numeral"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {lowerCase} from "lodash"

interface WithdrawFromAccountModalProps {
	close: () => void
	accessToken: string
	account: DepositAccount | FinancialBusinessFBOAccount
}

interface FromProps {
	amount: number | undefined
	setAmount: Dispatch<SetStateAction<number | undefined>>
	counterpartyId: string | undefined
	setCounterpartyId: Dispatch<SetStateAction<string | undefined>>
	description: string | undefined
	setDescription: Dispatch<SetStateAction<string | undefined>>
	counterparties: Array<Counterparty>
	transferState: AsyncResultIdleRequestState<OkDocument<Payment>, ErrorDocument>
}

enum TransferStage {
	Initial = "Initial",
	Summary = "Summary",
	Confirmation = "Confirmation",
}

function StageIndicator({stage}: {stage: TransferStage}) {
	return (
		<div className="is-flex is-align-items-center is-justify-content-center stage-indicator">
			<div className={classNames("is-flex-grow-1 stage", "active")}>
				<span className="stage-number">1</span>
				<span className="stage-title">Transfer Details</span>
				<span className="stage-bullet" />
			</div>
			<div
				className={classNames(
					"is-flex-grow-1 stage",
					[TransferStage.Confirmation, TransferStage.Summary].includes(stage) && "active"
				)}
			>
				<span className="stage-number">2</span>
				<span className="stage-title">Transfer Summary</span>
				<span className="stage-bullet" />
			</div>
			<div className={classNames("is-flex-grow-1 stage", stage == TransferStage.Confirmation && "active")}>
				<span className="stage-number">3</span>
				<span className="stage-title">Confirmation</span>
				<span className="stage-bullet" />
			</div>
		</div>
	)
}

function Summary(props: FromProps) {
	return (
		<>
			<div className="main-title">Transfer Summary</div>
			<div className="is-normal">
				<label className="label">Send to</label>
			</div>
			<CounterpartiesCards
				readonly
				counterparties={props.counterparties}
				counterpartyId={props.counterpartyId}
				setCounterpartyId={props.setCounterpartyId}
			/>
			<div className="field seperated spaced">
				<div className="is-normal">
					<label className="label">Amount</label>
				</div>
				<div className="field-body">
					<div className="field">
						<div className="control">
							<input
								className="input is-medium is-static"
								type="text"
								value={`$ ${props.amount}`}
								required
								placeholder="$ 00.00"
								readOnly
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="field seperated">
				<div className="is-normal">
					<label className="label">Description</label>
				</div>
				<div className="field-body">
					<input
						required
						className="input is-medium is-static"
						placeholder="Type anything..."
						value={props.description}
						onChange={(e) => props.setDescription(e.target.value)}
						readOnly
					/>
				</div>
			</div>
		</>
	)
}

function Confirmation(props: FromProps) {
	const counterparty = props.counterparties.find((c) => c.id == props.counterpartyId)

	function isPaymentIsSuccessful(payment: Payment) {
		return payment.attributes.status != PaymentStatus.Rejected
	}

	const boxClassname = props.transferState.match(
		() => "",
		() => "",
		(p) => (isPaymentIsSuccessful(p.data) ? "" : "disabled"),
		() => "disabled"
	)

	return (
		<div className="confirmation-container">
			<div className="confirmation-logo">
				{props.transferState.match(
					() => (
						<FontAwesomeIcon size="4x" icon={faCircleNotch} spin />
					),
					() => (
						<FontAwesomeIcon size="4x" icon={faCircleNotch} spin />
					),
					(p) =>
						isPaymentIsSuccessful(p.data) ? (
							<FontAwesomeIcon size="4x" icon={faCheckCircle} className="success" />
						) : (
							<FontAwesomeIcon size="4x" icon={faExclamationCircle} className="error" />
						),
					() => (
						<FontAwesomeIcon size="4x" icon={faExclamationCircle} className="error" />
					)
				)}
			</div>
			<div className="confirmation-title">
				{props.transferState.match(
					() => "Processing",
					() => "Processing",
					(p) => (isPaymentIsSuccessful(p.data) ? "Transferred successfully!" : "Payment failed"),
					() => "Payment failed"
				)}
			</div>
			{props.transferState.match(
				() => null,
				() => null,
				(p) =>
					isPaymentIsSuccessful(p.data) ? null : (
						<div className="confirmation-error-message">{lowerCase(p.data.attributes.reason)}</div>
					),
				(e) => {
					if (e.errors.length > 0) {
						return <div className="confirmation-error-message">{e.errors[0].title}</div>
					}
					return <div className="confirmation-error-message">Something went wrong try again later</div>
				}
			)}
			<div className={classNames("confirmation-summary-box", boxClassname)}>
				<div className="account-title">Account name</div>
				<div className="account-number">{counterparty?.attributes.accountNumber}</div>
				<div className="amount-title">Amount</div>
				<div className="amount-number">{numeral(props.amount).format("$ 0,0.00")}</div>
			</div>
		</div>
	)
}

function CounterpartiesCards({
	counterparties,
	counterpartyId,
	setCounterpartyId,
	readonly = false,
}: {
	counterparties: Array<Counterparty>
	counterpartyId: string | undefined
	setCounterpartyId: Dispatch<SetStateAction<string | undefined>>
	readonly?: boolean
}) {
	return (
		<div className="counterparties-container">
			{counterparties.map((c) =>
				!readonly || c.id == counterpartyId ? (
					<CounterpartyCard
						key={c.id}
						counterparty={c}
						counterpartyId={counterpartyId}
						setCounterpartyId={setCounterpartyId}
						readonly={readonly}
					/>
				) : null
			)}
		</div>
	)
}

function CounterpartyCard({
	counterparty,
	counterpartyId,
	setCounterpartyId,
	readonly = false,
}: {
	counterparty: Counterparty
	counterpartyId: string | undefined
	setCounterpartyId: Dispatch<SetStateAction<string | undefined>>
	readonly: boolean
}) {
	return (
		<div
			className={classNames("counterparty-box", counterparty.id == counterpartyId && !readonly && "selected")}
			key={counterparty.id}
			onClick={() => setCounterpartyId(counterparty.id)}
		>
			{!readonly ? (
				<input type="radio" checked={counterparty.id == counterpartyId} name="counterparty" required />
			) : null}
			<div className="counterparty-name">{counterparty.attributes.name}</div>
			<div className="counterparty-account-number">{counterparty.attributes.accountNumber}</div>
		</div>
	)
}

function WithdrawForm(props: FromProps) {
	return (
		<>
			<div className="main-title">Transfer Details</div>
			<div className="is-normal">
				<label className="label">Send to</label>
			</div>
			<CounterpartiesCards
				counterparties={props.counterparties}
				counterpartyId={props.counterpartyId}
				setCounterpartyId={props.setCounterpartyId}
			/>
			<div className="field">
				<div className="is-normal">
					<label className="label">Amount</label>
				</div>
				<div className="field-body">
					<div className="field">
						<div className="control has-icons-left">
							<input
								className="input is-medium"
								type="number"
								value={props.amount}
								required
								placeholder="00.00"
								step=".01"
								onChange={(e) => props.setAmount(Math.floor(toNumber(e.target.value) * 100) / 100)}
							/>
							<span className="icon is-left">
								<FontAwesomeIcon icon={faDollarSign} />
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="field">
				<div className="is-normal">
					<label className="label mt-30">Description</label>
				</div>
				<div className="field-body">
					<input
						required
						className="input is-medium"
						maxLength={50}
						placeholder="Type anything..."
						value={props.description}
						onChange={(e) => props.setDescription(e.target.value)}
					/>
				</div>
			</div>
		</>
	)
}

export function WithdrawFromAccountModal(props: WithdrawFromAccountModalProps) {
	const [transferState, transfer] = useAsyncResultIdle((amount: number, description: string, counterpartyId: string) =>
		createLinkedCounterpartyAchPayment(
			props.accessToken,
			props.account.id,
			counterpartyId,
			Math.round(amount * 100),
			description
		)
	)
	const counterpartiesResponse = useAsyncResult(() => findCounterparties(props.accessToken, 0, 100))
	const [stage, setStage] = useState(TransferStage.Initial)
	const [amount, setAmount] = useState<number | undefined>(undefined)
	const [counterpartyId, setCounterpartyId] = useState<string | undefined>(undefined)
	const [description, setDescription] = useState<string | undefined>(undefined)
	const counterparties = counterpartiesResponse.match<Array<Counterparty>>(
		() => [],
		(c) => c.data,
		() => []
	)

	function getPageForStage() {
		switch (stage) {
			case TransferStage.Initial:
				return (
					<WithdrawForm
						amount={amount}
						setAmount={setAmount}
						counterpartyId={counterpartyId}
						setCounterpartyId={setCounterpartyId}
						description={description}
						setDescription={setDescription}
						counterparties={counterparties}
						transferState={transferState}
					/>
				)
			case TransferStage.Summary:
				return (
					<Summary
						amount={amount}
						setAmount={setAmount}
						counterpartyId={counterpartyId}
						setCounterpartyId={setCounterpartyId}
						description={description}
						setDescription={setDescription}
						counterparties={counterparties}
						transferState={transferState}
					/>
				)
			case TransferStage.Confirmation:
				return (
					<Confirmation
						amount={amount}
						setAmount={setAmount}
						counterpartyId={counterpartyId}
						setCounterpartyId={setCounterpartyId}
						description={description}
						setDescription={setDescription}
						counterparties={counterparties}
						transferState={transferState}
					/>
				)
		}
	}

	return (
		<div className="modal is-active transfer-revenue-modal">
			<div className="modal-background" />
			<form
				onSubmit={(e) => {
					e.preventDefault()
					if (stage == TransferStage.Initial) {
						setStage(TransferStage.Summary)
					} else {
						if (amount && counterpartyId && description) {
							setStage(TransferStage.Confirmation)
							transfer(amount, description, counterpartyId)
						}
					}
				}}
			>
				<div className="modal-card modal-wide">
					<section className="modal-card-body">
						<FontAwesomeIcon className="modal-close-button" icon={faTimes} onClick={() => props.close()} />
						<StageIndicator stage={stage} />
						<fieldset className="form-container" disabled={!counterpartiesResponse.isOk()}>
							{getPageForStage()}
						</fieldset>
						{stage != TransferStage.Confirmation ? (
							<div className="buttons-row">
								{counterpartiesResponse.match(
									() => (
										<div className="button is-success mr-2">
											<FontAwesomeIcon icon={faCircleNotch} spin />
										</div>
									),
									() => (
										<>
											{stage == TransferStage.Initial ? (
												<input type="submit" className="button is-black is-medium" value="Continue" />
											) : null}
											{stage == TransferStage.Summary ? (
												<input
													type="submit"
													className="button has-background-black has-text-white is-medium"
													value="Transfer funds"
												/>
											) : null}
											{stage == TransferStage.Summary ? (
												<input
													type="button"
													className="button is-white is-medium"
													value="Back"
													onClick={() => setStage(TransferStage.Initial)}
												/>
											) : null}
										</>
									),
									(e) => (
										<>{`${e.errors[0].title} - ${e.errors[0].detail}`}</>
									)
								)}
							</div>
						) : null}
					</section>
				</div>
			</form>
		</div>
	)
}
