import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import App from "./App"
import {DefaultErr} from "./components/Err/Err"

export const envName = process.env.ENV_NAME

export const baseDocumentTitle = document.title
export const documentTitle = document.title + " - " + envName

export const reCaptchaSiteKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY

if (envName != null) document.title = documentTitle

if (envName && envName !== "Local") {
	Sentry.init({
		dsn: "https://a42459956f854a55bdaec62a2296162d@o1071400.ingest.sentry.io/6070239",
		integrations: [Sentry.browserTracingIntegration()],
		environment: envName,
	})
}

ReactDOM.render(
	<Sentry.ErrorBoundary fallback={<DefaultErr />}>
		<App />
	</Sentry.ErrorBoundary>,
	document.getElementById("app")
)

if (module.hot) module.hot.accept()

if (envName != null) initWhiteLabelScript(envName)

function initWhiteLabelScript(envName: string) {
	const whiteLabelScriptSrc: Record<string, string> = {
		Sandbox: "https://ui.s.unit.sh/release/master/components.js",
		LIVE: "https://ui.unit.co/release/master/components.js",
		Cert: "https://ui.s.unit.sh/release/master/components.js",
		Dev: "https://ui.s.unit.sh/release/master/components.js",
		Local: "http://localhost:3010/components-local-dev.js",
	}

	const whiteLabelScript = document.createElement("script")
	whiteLabelScript.src = whiteLabelScriptSrc[envName]
	whiteLabelScript.type = "text/javascript"
	whiteLabelScript.async = true
	document.body.append(whiteLabelScript)
}
