import React from "react"
import {useParams} from "react-router-dom"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useAccessToken} from "../../services/auth"
import {getIncomingAch, IncomingAch} from "../../resources/incomingAch"
import classNames from "classnames"
import {kebabCase, startCase} from "lodash"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {IncomingAchButtons, IncomingAchComponent, IncomingAchItemData} from "../../components/IncomingAch/IncomingAch"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"

export default function IncomingAchPage() {
	const {incomingAchId = ""} = useParams<{incomingAchId: string}>()
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const incomingAchAsyncResult = useAsyncResult(() => getIncomingAch(accessToken, incomingAchId), [refreshToken])

	return (
		<div className={"incoming-ach-full-page"}>
			<AsyncResultComponent asyncResult={incomingAchAsyncResult} refresh={refresh}>
				{({value: incomingAchResponse}) => {
					const incomingAch = incomingAchResponse.data as IncomingAch
					const accountId = incomingAch.relationships.account?.data.id
					const customerId = incomingAch.relationships.customer?.data.id
					const transactionId = incomingAch.relationships.transaction?.data.id
					const originatedPaymentId = incomingAch.relationships.originatedPayment?.data.id
					return (
						<>
							<TitleBar
								title={`${startCase(incomingAchResponse.data.type).replace("Incoming ACH", "")} #${incomingAch.id}`}
								breadcrumbs={[{text: "Incoming ACH", path: "/incoming-achs/"}]}
								status={{
									text: startCase(incomingAch.attributes.status),
									className: classNames("payments-status", kebabCase(incomingAch.attributes.status)),
								}}
								meta={[
									{
										text: "Account",
										icon: "bank-account--business-products",
										path: `/accounts/${accountId}`,
									},
									{
										text: "Customer",
										icon: "user-geometric-action-money-fund---users",
										path: `/customer/${customerId}`,
									},
									...(accountId && transactionId
										? [
												{
													text: "Transaction",
													icon: "money-transfer--business-products",
													path: `/transaction/${accountId}/${transactionId}`,
												} as MetaItemProps,
										  ]
										: []),
									...(accountId && originatedPaymentId
										? [
												{
													text: "Originated Payment",
													icon: "payment-coin",
													path: `/payment/${originatedPaymentId}`,
												} as MetaItemProps,
										  ]
										: []),
								]}
							>
								<IncomingAchButtons incomingAch={incomingAch} refresh={refresh} accessToken={accessToken} />
							</TitleBar>
							<MainSectionLayout
								outerWrapperClassname={"incoming-ach-full-with-details"}
								fullWidth={true}
								renderAfter={<IncomingAchItemData rawData={incomingAch.attributes.item} />}
							>
								<IncomingAchComponent
									incomingAchId={incomingAchId}
									incomingAchAsyncResult={incomingAchAsyncResult}
									accessToken={accessToken}
								/>
							</MainSectionLayout>
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}
