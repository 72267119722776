import React, {useEffect, useState} from "react"
import {BatchAccount, DepositAccount} from "../../resources/account"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useNavigate} from "react-router"
import {IcomoonIconName} from "../Icon/icons"
import {getAccountEndOfDay} from "../../resources/accountEndOfDay"
import moment from "moment"
import AccountBoxView from "./AccountBoxView"

export default function AccountBox({
	title,
	additionalTitle,
	amount,
	className,
	account,
	icon,
	accessToken,
	minimumBalance,
}: {
	title?: string
	additionalTitle?: string
	amount?: number
	className?: string
	account?: DepositAccount | BatchAccount
	icon: IcomoonIconName
	accessToken?: string
	minimumBalance?: number
}) {
	const navigate = useNavigate()
	const [accountChange, setAccountChange] = useState<number>(0)

	if (account && accessToken) {
		const since = moment().subtract(1, "week").format("YYYY-MM-DD")
		const until = moment().subtract(1, "day").format("YYYY-MM-DD")
		const accountEndOfDays = useAsyncResult(
			() =>
				getAccountEndOfDay({
					accessToken: accessToken,
					accountId: account.id,
					since: since,
					until: until,
					limit: 100,
					offset: 0,
				}),
			[]
		)

		useEffect(() => {
			accountEndOfDays.match(
				() => null,
				(response) => {
					const data = response.data
					if (data.length > 0) {
						const endBalance = data[data.length - 1].attributes.balance
						const startBalance = data[0].attributes.balance
						if (startBalance !== 0) {
							const change = ((endBalance - startBalance) / startBalance) * 100
							setAccountChange(Math.round(change))
						}
					}
				},
				(_) => null
			)
		}, [accountEndOfDays])
	}

	const onAccountBoxClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault()
		if (account) {
			navigate(`/${account.type == "depositAccount" ? "org-" : ""}account/${account.id}`)
		}
	}

	return (
		<AccountBoxView
			accountChange={accountChange}
			onClick={onAccountBoxClick}
			title={title}
			additionalTitle={additionalTitle}
			amount={amount}
			showCTA={!!account}
			className={className}
			icon={icon}
			minimumBalance={minimumBalance}
		/>
	)
}
