import React, {useState} from "react"
import {ApiRequestData} from "../AssignmentPageItemCallback/AssignmentPageItemCallback"
import {useToasts} from "react-toast-notifications"
import {
	AssignmentActionRequestType,
	CallbackCancelRequest,
	submitDocumentAndSubmitAssignment,
	update,
} from "../../resources/assignments"
import Button from "../Button/Button"
import {PreLoadedData} from "./AssignmentPageItemCallbackFooter"
import {FileMetadata, getMimeTypeByFileName} from "../../utilities/file"
import {useModal} from "react-modal-hook"
import {AssignmentApproveRejectModal} from "../AssignmentApproveRejectModal/AssignmentApproveRejectModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"

interface CallbackSubmitAndCancelButtonsProps {
	callSummaryTextValue: string
	file?: File
	setFile?: React.Dispatch<React.SetStateAction<File | undefined>>
	preLoadedData: PreLoadedData
	disabled?: boolean
	apiRequestData: ApiRequestData
	wireId: string
}

const getFileMetadata = (file: File | undefined): FileMetadata | undefined => {
	if (file) {
		return {name: file.name, size: file.size}
	}
	return undefined
}

export function CallbackSubmitAndCancelButtons({
	callSummaryTextValue,
	file,
	setFile,
	preLoadedData: {preLoadedCallbackSummary},
	disabled,
	apiRequestData: {accessToken, id, refresh},
	wireId,
}: CallbackSubmitAndCancelButtonsProps) {
	const [submitLoading, setSubmitLoading] = useState<boolean>(false)
	const {addToast} = useToasts()
	const validateSubmissionFields = () => {
		if (!callSummaryTextValue && !preLoadedCallbackSummary) {
			addToast("Summary is required", {appearance: "info"})
			return false
		}
		return true
	}

	const [showSubmit, hideSubmit] = useModal(() => {
		const successText = "Submitted successfully"
		const [state, callback] = useAsyncResultIdle(submitDocumentAndSubmitAssignment)
		const onSubmit = () =>
			callback({
				accessToken,
				id,
				document: file,
				documentMetadata: getFileMetadata(file),
				documentType: getMimeTypeByFileName(file?.name),
				setFile,
				summary: callSummaryTextValue,
			})
		return (
			<AssignmentApproveRejectModal
				title="Submit Callback"
				description={
					<p>
						Are you sure you want to submit <span className="has-text-weight-bold">{`Callback #${id}?`}</span>
					</p>
				}
				state={state}
				onSubmit={onSubmit}
				primaryButtonText="Submit"
				primaryButtonClassname="is-success"
				closeModal={hideSubmit}
				onSuccess={refresh}
				successText={successText}
			/>
		)
	}, [id, callSummaryTextValue, file])

	const [showCancel, hideCancel] = useModal(() => {
		const successText = "Cancelled successfully"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = () => {
			const data: CallbackCancelRequest = {
				type: AssignmentActionRequestType.callbackCancelRequest,
				attributes: {
					summary: callSummaryTextValue,
				},
			}
			return callback({
				accessToken,
				id,
				data,
			})
		}
		return (
			<AssignmentApproveRejectModal
				title="Cancel Item"
				description={
					<div>
						<p>
							Are you sure you want to cancel <span className="has-text-weight-bold">{`Callback #${id}?`}</span>
						</p>
						<p>
							This action will cancel <span className="has-text-weight-bold">{`Wire #${wireId}.`}</span>
						</p>
					</div>
				}
				state={state}
				onSubmit={onSubmit}
				primaryButtonText="Cancel Wire"
				primaryButtonClassname="is-danger"
				closeModal={hideCancel}
				onSuccess={refresh}
				successText={successText}
				successClassname="has-text-black"
			/>
		)
	}, [id, callSummaryTextValue, file])

	const onSubmit = async () => {
		setSubmitLoading(true)
		if (!validateSubmissionFields()) {
			setSubmitLoading(false)
			return
		}
		if (file) {
			const documentType = getMimeTypeByFileName(file.name)
			if (!documentType) {
				addToast("File type is not supported", {appearance: "error"})
				setSubmitLoading(false)
				return
			}
		}
		setSubmitLoading(false)
		showSubmit()
	}

	const isSummaryInputEmpty = !callSummaryTextValue && !preLoadedCallbackSummary
	return (
		<>
			<Button
				prefixIcon="delete-close-x"
				theme="danger"
				size="normal"
				onClick={showCancel}
				disabled={disabled || isSummaryInputEmpty}
				tooltip={isSummaryInputEmpty ? "Summary is required<br/>for cancellation" : undefined}
				tooltipKey={callSummaryTextValue ? undefined : "cancel-button"}
				tooltipClassName="assignment-page-item-callback-footer-button-tooltip"
				className="assignment-page-item-callback-footer-button"
			>
				Cancel Wire
			</Button>
			<Button
				prefixIcon="approve-v-check-validation"
				theme="success"
				size="normal"
				loading={submitLoading}
				onClick={onSubmit}
				disabled={disabled || isSummaryInputEmpty}
				tooltip={isSummaryInputEmpty ? "Summary is required<br/>for submission" : undefined}
				tooltipKey={callSummaryTextValue ? undefined : "submit-button"}
				tooltipClassName="assignment-page-item-callback-footer-button-tooltip"
				className="assignment-page-item-callback-footer-button"
			>
				Submit
			</Button>
		</>
	)
}
