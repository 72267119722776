import React from "react"
import {CardTitleProps} from "./DepositProductSections/types"
import {EditMode} from "../../resources/common"
import Icon from "../../components/Icon/Icon"

export default function CardTitle({editMode, title, elementId, description, linkText, linkUrl}: CardTitleProps) {
	if (editMode === EditMode.VIEW) {
		return (
			<p>
				<span className="card-title" id={elementId}>
					{title}
				</span>
				<a className="title-info-text" target="_blank" rel="noreferrer" href={linkUrl}>
					<span className="text">{linkText}</span>
					<Icon icon="maximize-square-2--interface-essential" size={10} />
				</a>
				{description && <p className="description">{description}</p>}
			</p>
		)
	}
	return title
}
