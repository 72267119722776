import {useAccessToken} from "../../services/auth"
import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Bin, BinPrinter, BinProduct, BinType, Brand, getBin, isCreditBinType, updateBin} from "../../resources/bin"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import Loading from "../../components/Loading/Loading"
import {ErrorDocument} from "../../resources/common"
import {findOrgs, Org} from "../../resources/org"
import {Bank, findBanks} from "../../resources/bank"
import {AsyncResult} from "../../types/asyncResult"

type CloseFunc = () => void
type RefreshFunc = () => void

export function EditBinModalContent({
	close,
	refresh,
	Bin,
	orgs,
	banks,
}: {
	close: CloseFunc
	refresh: RefreshFunc
	Bin: Bin
	orgs: Array<Org>
	banks: Array<Bank>
}) {
	const accessToken = useAccessToken()
	const [state, update] = useAsyncResultIdle(updateBin)
	const [name, setName] = useState(Bin.attributes.name)
	const [institutionId, setInstitutionId] = useState(Bin.attributes.institutionId)
	const [bin, setBin] = useState(Bin.attributes.bin)
	const [binType, setBinType] = useState(Bin.attributes.binType)
	const [brand, setBrand] = useState(Bin.attributes.brand)
	const [product, setProduct] = useState(Bin.attributes.product)
	const [printer, setPrinter] = useState(Bin.attributes.printer || null)
	const [orgId, setOrgId] = useState(Bin.relationships.org.data.id)
	const [bankId, setBankId] = useState(Bin.relationships.bank.data.id)

	const [creditSpendAssessmentInfo, setCreditSpendAssessmentInfo] = useState(Bin.attributes.creditSpendAssessmentInfo)

	return (
		<AsyncResultModal
			title={`Bin - ${name}`}
			onSubmit={() =>
				update(
					accessToken,
					name,
					institutionId,
					bin,
					orgId,
					bankId,
					binType,
					product,
					Bin,
					brand,
					printer,
					creditSpendAssessmentInfo?.creditSpendProductId && creditSpendAssessmentInfo?.creditSpendRpin
						? creditSpendAssessmentInfo
						: null
				)
			}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Update"
			successText="Updated"
			errorToText={(err) => (err.errors[0].detail ? err.errors[0].detail : err.errors[0].title)}
			onSuccess={refresh}
		>
			<div className="field">
				<label className="label">Name</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="A name describing the Bin"
						value={name}
						required
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Institution Id</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="123456789"
						pattern="^\d{9}$"
						value={institutionId}
						required
						onChange={(e) => setInstitutionId(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">bin</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="123456789"
						pattern="^\d{9}$"
						value={bin}
						required
						onChange={(e) => setBin(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">type</label>
				<div className="control">
					<div className="select">
						<select value={binType} onChange={(e) => setBinType(e.target.value as BinType)}>
							{Object.keys(BinType).map((o) => (
								<option key={o} value={o}>
									{BinType[o as BinType]}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			{isCreditBinType(binType) && (
				<>
					<div className="field">
						<label className="label">Visa Card Program Enrollment - Product ID (Optional)</label>
						<div className="control">
							<input
								className="input"
								type="text"
								placeholder="G"
								value={creditSpendAssessmentInfo?.creditSpendProductId}
								required={!!creditSpendAssessmentInfo?.creditSpendRpin}
								maxLength={2}
								onChange={(e) =>
									setCreditSpendAssessmentInfo((creditSpendAssessmentInfo) =>
										creditSpendAssessmentInfo
											? {...creditSpendAssessmentInfo, creditSpendProductId: e.target.value}
											: {creditSpendProductId: e.target.value, creditSpendRpin: ""}
									)
								}
							/>
						</div>
					</div>
					<div className="field">
						<label className="label">Visa Card Program Enrollment - RPIN (Optional)</label>
						<div className="control">
							<input
								className="input"
								type="text"
								placeholder="123456"
								value={creditSpendAssessmentInfo?.creditSpendRpin}
								required={!!creditSpendAssessmentInfo?.creditSpendProductId}
								maxLength={6}
								onChange={(e) =>
									setCreditSpendAssessmentInfo((creditSpendAssessmentInfo) =>
										creditSpendAssessmentInfo
											? {...creditSpendAssessmentInfo, creditSpendRpin: e.target.value}
											: {creditSpendRpin: e.target.value, creditSpendProductId: ""}
									)
								}
							/>
						</div>
					</div>
				</>
			)}
			<div className="field">
				<label className="label">product</label>
				<div className="control">
					<div className="select">
						<select value={product} onChange={(e) => setProduct(e.target.value as BinProduct)}>
							{Object.keys(BinProduct).map((o) => (
								<option key={o} value={o}>
									{BinProduct[o as BinProduct]}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">printer</label>
				<div className="control">
					<div className="select">
						<select
							value={printer || undefined}
							onChange={(e) => setPrinter(e.target.value != "" ? (e.target.value as BinPrinter) : null)}
						>
							<option value="">None</option>
							{Object.keys(BinPrinter).map((o) => (
								<option key={o} value={o}>
									{BinPrinter[o as BinPrinter]}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Org</label>
				<div className="control">
					<div className="select">
						<select value={orgId} onChange={(e) => setOrgId(e.target.value)}>
							{orgs.map((o) => (
								<option key={o.id} value={o.id.toString()}>
									{o.attributes.name}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Bank</label>
				<div className="control">
					<div className="select">
						<select value={bankId} onChange={(e) => setBankId(e.target.value)}>
							{banks.map((b) => (
								<option key={b.id} value={b.id.toString()}>
									{b.attributes.name}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className="field">
				<label className="label">Brand</label>
				<div className="control">
					<div className="select">
						<select value={brand} onChange={(e) => setBrand(e.target.value as Brand)}>
							{Object.keys(Brand).map((o) => (
								<option key={o} value={o}>
									{Brand[o as Brand]}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
		</AsyncResultModal>
	)
}

export default function EditBinModal({BinId, refresh, close}: {BinId: string; close: CloseFunc; refresh: RefreshFunc}) {
	const accessToken = useAccessToken()

	const binAsync = useAsyncResult<Bin, ErrorDocument>(() => getBin(accessToken, BinId))
	const orgs = useAsyncResult(() => findOrgs(accessToken, 0, 10000))
	const banks = useAsyncResult(() => findBanks(accessToken, 0, 1000))

	return AsyncResult.zip3(binAsync, orgs, banks).match(
		() => <Loading />,
		([Bin, orgs, banks]) => <EditBinModalContent close={close} refresh={refresh} Bin={Bin} orgs={orgs} banks={banks} />,
		(_) => null
	)
}
