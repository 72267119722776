import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {Disputes, DisputesColumns} from "../../components/Disputes/Disputes"
import {useIsUnitUser} from "../../services/auth"

export default function DisputesPage(): ReactElement {
	return (
		<>
			<TitleBar title={"Disputes"} />

			<MainSectionTable>
				<Disputes
					enableUpload={true}
					enableOrgFilter={true}
					fullHeight={true}
					includedColumns={[
						DisputesColumns.id,
						...(useIsUnitUser() ? [DisputesColumns.org] : []),
						DisputesColumns.customerName,
						DisputesColumns.customerId,
						DisputesColumns.source,
						DisputesColumns.status,
						DisputesColumns.createdAt,
						DisputesColumns.updatedAt,
						DisputesColumns.amount,
					]}
				/>
			</MainSectionTable>
		</>
	)
}
