import React, {Fragment} from "react"
import moment from "moment"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardKey, KeyValueCardValue} from "../KeyValueCard/KeyValueCard"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {addressText} from "../../utilities/helpers"
import {useModal} from "react-modal-hook"
import {RejectApplicationDocumentModal} from "./RejectApplicationDocument"
import {
	Application,
	ApplicationDocument,
	ApplicationDocumentSide,
	ApplicationDocumentStatus,
	ApplicationDocumentType,
	approveDocument,
	DocumentLink,
	getDocumentLink,
} from "../../resources/application"
import {startCase} from "lodash"
import {ApplicationDocumentModalHeader} from "./ApplicationDocumentModalHeader"
import {ImageCard} from "../ImageCard/ImageCard"
import Icon from "../Icon/Icon"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"

interface ModalAsideProps {
	application: Application
	document: ApplicationDocument
	accessToken: string
	refresh: () => void
	close: () => void
}

const getKeyValCard = (application: Application, document: ApplicationDocument) => {
	const notAvailable = "N.A"
	const {name, dateOfBirth, passport, ssn, ein, matriculaConsular} = document.attributes
	const addressData =
		document.attributes.address || ("address" in application.attributes && application.attributes?.address)
	const industry = "industry" in application.attributes && application.attributes.industry
	const dba = "dba" in application.attributes && application.attributes.dba
	const documentType = document.attributes.documentType

	const birthDate = dateOfBirth ? moment(dateOfBirth).format("L") : notAvailable
	const address = addressData && <span className={"address-placeholder"}> {addressText(addressData)} </span>

	let docAttributes: {[key: string]: boolean | string | JSX.Element} = {}

	switch (documentType) {
		case ApplicationDocumentType.addressVerification:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(address ? {address} : {}),
			}
			break

		case ApplicationDocumentType.idDocument:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(birthDate ? {birthDate} : {}),
				...(address ? {address} : {}),
			}
			break

		case ApplicationDocumentType.selfieVerification:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(address ? {address} : {}),
			}
			break

		case ApplicationDocumentType.passport:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(birthDate ? {birthDate} : {}),
				...(passport ? {passport} : {}),
				...(address ? {address} : {}),
			}
			break

		case ApplicationDocumentType.matriculaConsular:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(birthDate ? {birthDate} : {}),
				...(matriculaConsular ? {matriculaConsular} : {}),
				...(address ? {address} : {}),
			}
			break

		case ApplicationDocumentType.socialSecurityCard:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(ssn ? {SSN: ssn} : {}),
			}
			break

		case ApplicationDocumentType.certificateOfIncorporation:
		case ApplicationDocumentType.employerIdentificationNumberConfirmation:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(name ? {name} : {}),
				...(ein ? {EIN: ein} : {}),
				...(industry ? {industry} : {}),
				...(dba ? {DBA: dba} : {}),
			}
			break

		case ApplicationDocumentType.powerOfAttorney:
			docAttributes = {
				...(documentType ? {type: startCase(documentType)} : {}),
				...(birthDate ? {birthDate} : {}),
				...(address ? {address} : {}),
				...(ssn ? {ssn} : {}),
			}
			break

		default:
			break
	}

	return docAttributes
}

export function ApplicationDocumentModalAside(props: ModalAsideProps) {
	const {application, document, accessToken, refresh, close} = props

	const canReject =
		application.attributes.status !== "Approved" &&
		application.attributes.status !== "Denied" &&
		document.attributes.status !== ApplicationDocumentStatus.Invalid

	const canApprove =
		application.attributes.status !== "Approved" &&
		application.attributes.status !== "Denied" &&
		document.attributes.status !== ApplicationDocumentStatus.Approved

	const [showReject, hideReject] = useModal(() => (
		<RejectApplicationDocumentModal
			close={() => null}
			applicationId={application.id}
			documentId={document.id}
			onSuccess={() => {
				hideReject()
				close()
				refresh()
			}}
		/>
	))

	const additionalDocumentLinkState =
		document.attributes.documentType === ApplicationDocumentType.selfieVerification &&
		document.attributes.status !== ApplicationDocumentStatus.Required
			? useAsyncResult(() =>
					getDocumentLink(application.id, document.id, accessToken, ApplicationDocumentSide.Additional)
			  )
			: AsyncResult.pending<OkDocument<DocumentLink>, ErrorDocument>()

	const additionalDocumentLink = additionalDocumentLinkState.match(
		() => undefined,
		(image) => image,
		() => null
	)

	const imageUrl = additionalDocumentLink?.data?.attributes?.link
	const selfieImage = imageUrl
		? {
				src: imageUrl,
				alt: "Selfie",
		  }
		: null

	return (
		<aside className="modal-sidebar">
			<ApplicationDocumentModalHeader id={document.id} status={document.attributes.status} close={close} />
			{selfieImage && (
				<ImageCard
					title={"Selfie"}
					prefixIcon={<Icon size={16} icon={"face-id-user--interface-essential"} />}
					image={selfieImage}
				></ImageCard>
			)}
			<KeyValueCard title={"Document Info"}>
				<KeyValueCardContainer>
					{Object.entries(getKeyValCard(application, document)).map(([key, value]) => (
						<Fragment key={key}>
							<KeyValueCardKey>{startCase(key)}</KeyValueCardKey>
							<KeyValueCardValue>{value}</KeyValueCardValue>
						</Fragment>
					))}
				</KeyValueCardContainer>
			</KeyValueCard>

			{document.attributes.documentType !== ApplicationDocumentType.clientRequested && (
				<UnitUserOnly>
					<div className="sidebar-buttons">
						<button className="button is-danger" disabled={!canReject} onClick={showReject}>
							Reject
						</button>
						<button
							className="button is-success"
							disabled={!canApprove}
							onClick={() =>
								approveDocument(accessToken, application.id, document.id).then(() => {
									close()
									refresh()
								})
							}
						>
							Approve
						</button>
					</div>
				</UnitUserOnly>
			)}
		</aside>
	)
}
