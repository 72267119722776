import React, {useState} from "react"
import {optionalHorizontalField} from "../HorizontalField/HorizontalField"
import {isMasked, Permissible} from "../../resources/common"
import {
	Editable,
	EditableElementProps,
	EditableHiddenString,
	EditablePassport,
} from "../HorizontalField/EditableHorizontalField"
import {isUndefined} from "lodash"
import {useIsUnitUser} from "../../services/auth"
import IdentificationTypeSelect from "../IdentificationTypeSelect/ApplicationIdentificationNumber"

interface Props {
	data: {
		initialSsn: string
		initialPassport: [string, string]
		initialMatriculaConsular: string
		documentTitle: string | undefined
		archived: boolean
	}
	state: {
		isEditing: string | undefined
		isEditable: boolean
		isUpdating: boolean
		isHiddenIconVisible?: boolean
	}
	actions: {
		setIsEditing: Function
		refresh: () => void
		setIsUpdating: Function
		updateSSN: Function
		updatePassport: Function
		updateMatriculaConsular: Function
	}
}

export default function IdentificationNumber(props: Props) {
	const {initialSsn, initialPassport, initialMatriculaConsular, documentTitle, archived} = props.data
	const {isEditing, isEditable, isUpdating, isHiddenIconVisible} = props.state
	const {setIsEditing, refresh, setIsUpdating, updateSSN, updatePassport, updateMatriculaConsular} = props.actions

	const [ssn, setSSN] = useState(initialSsn)
	const [matriculaConsular, setMatriculaConsular] = useState(initialMatriculaConsular)
	const [passport, setPassport] = useState<[string, string]>(initialPassport)

	const [currentEditingType, setCurrentEditingType] = useState(getEditingType(props))

	const getEditingComp = (editingType: string) => {
		if (editingType == "ssn") {
			return getEditSSNComp()
		}
		if (editingType == "passport") {
			return getEditPassportComp()
		}

		if (editingType == "matriculaConsular") {
			return getEditMatriculaConsularComp()
		}

		return undefined
	}

	const getEditSSNComp = () => (
		<Editable
			label={"SSN"}
			isEditing={isEditing === documentTitle}
			isDisabled={!isUndefined(isEditing)}
			isAllowed={useIsUnitUser() && isEditable}
			isUpdating={isUpdating}
			isHiddenIconVisible={isHiddenIconVisible}
			onStartEdit={() => setIsEditing(documentTitle)}
			onCancel={refresh}
			setValue={setSSN}
			value={ssn}
			initial={ssn}
			update={() => {
				updateSSN(ssn)
				setIsUpdating(true)
			}}
			pattern="^\d{9}$"
			className={archived ? "archived-field" : ""}
		>
			{(props: EditableElementProps<any>) => (
				<>
					{getSelectIdTypeComp()}
					{EditableHiddenString(props)}
				</>
			)}
		</Editable>
	)

	const getEditMatriculaConsularComp = () => (
		<Editable
			label={"Matricula Consular"}
			isEditing={isEditing === documentTitle}
			isDisabled={!isUndefined(isEditing)}
			isAllowed={useIsUnitUser() && isEditable}
			isUpdating={isUpdating}
			onStartEdit={() => setIsEditing(documentTitle)}
			onCancel={refresh}
			setValue={setMatriculaConsular}
			value={matriculaConsular}
			initial={matriculaConsular}
			update={() => {
				updateMatriculaConsular(matriculaConsular)
				setIsUpdating(true)
			}}
			className={archived ? "archived-field" : ""}
		>
			{(props: EditableElementProps<any>) => (
				<>
					{getSelectIdTypeComp()}
					{EditableHiddenString(props)}
				</>
			)}
		</Editable>
	)

	const getEditPassportComp = () => (
		<Editable
			label={"Passport"}
			isEditing={isEditing === documentTitle}
			isDisabled={!isUndefined(isEditing)}
			isAllowed={useIsUnitUser() && isEditable}
			isUpdating={isUpdating}
			onStartEdit={() => setIsEditing(documentTitle)}
			onCancel={refresh}
			setValue={setPassport}
			value={passport}
			initial={passport}
			update={() => {
				updatePassport(passport)
				setIsUpdating(true)
			}}
			className={archived ? "archived-field" : ""}
		>
			{(props) => (
				<>
					{getSelectIdTypeComp()}
					{EditablePassport(props)}
				</>
			)}
		</Editable>
	)

	const getSelectIdTypeComp = () =>
		isEditing == "SSN" || isEditing == "Passport" || isEditing == "Matricula Consular" ? (
			<IdentificationTypeSelect value={currentEditingType} onChange={setCurrentEditingType} />
		) : (
			<></>
		)

	function renderSSN() {
		if (!ssn || documentTitle !== "SSN") {
			return null
		}
		return isMasked(ssn) ? (
			optionalHorizontalField(
				documentTitle,
				(ssn || "").replace(/[*]/g, "●"),
				undefined,
				archived ? "archived-field" : ""
			)
		) : (
			<>{getEditingComp(currentEditingType)}</>
		)
	}

	function renderMatriculaConsular() {
		if (!matriculaConsular || documentTitle !== "Matricula Consular") {
			return null
		}
		return isMasked(matriculaConsular) ? (
			optionalHorizontalField(
				documentTitle,
				(matriculaConsular || "").replace(/[*]/g, "●"),
				undefined,
				archived ? "archived-field" : ""
			)
		) : (
			<>{getEditingComp(currentEditingType)}</>
		)
	}

	function renderPassport() {
		if (!passport || documentTitle !== "Passport") {
			return null
		}
		return isMasked(passport[1]) ? (
			optionalHorizontalField(
				documentTitle,
				`●● ${(passport[1] || "").replace(/[*]/g, "●")}`,
				undefined,
				archived ? "archived-field" : ""
			)
		) : (
			<>{getEditingComp(currentEditingType)}</>
		)
	}

	return renderSSN() || renderPassport() || renderMatriculaConsular()
}

function getEditingType(props: Props) {
	if (props.data.documentTitle == "Passport") {
		return "passport"
	}
	if (props.data.documentTitle == "Matricula Consular") {
		return "matriculaConsular"
	}
	return "ssn"
}

export function getDocumentTitle(
	ssn: Permissible<string>,
	maskedSSN: Permissible<string>,
	passport: Permissible<string>,
	maskedPassport: Permissible<string>,
	matriculaConsular: Permissible<string>,
	maskedMatriculaConsular: Permissible<string>
) {
	if (matriculaConsular || maskedMatriculaConsular) {
		return "Matricula Consular"
	}
	if (passport || maskedPassport) {
		return "Passport"
	}
	if (ssn || maskedSSN) {
		return "SSN"
	}
	return undefined
}
