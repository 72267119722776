import {useState} from "react"
import {hasPermission} from "../../services/permission"

interface UseAuditLogProps {
	showAuditLog?: boolean
}

interface AuditLogHookResult {
	showAuditLog: boolean
	openAuditLogDrawer: () => void
	closeAuditLogDrawer: () => void
	isAuditLogDrawerOpened: boolean
}

export function useAuditLog({showAuditLog: showAuditLogParam}: UseAuditLogProps = {}): AuditLogHookResult {
	const showAuditLog = showAuditLogParam !== undefined ? showAuditLogParam : hasPermission("audit-log", "get")
	const [isAuditLogDrawerOpened, setIsAuditLogDrawerOpened] = useState<boolean>(false)
	const openAuditLogDrawer = () => setIsAuditLogDrawerOpened(true)
	const closeAuditLogDrawer = () => setIsAuditLogDrawerOpened(false)
	return {
		showAuditLog,
		openAuditLogDrawer,
		closeAuditLogDrawer,
		isAuditLogDrawerOpened,
	}
}
