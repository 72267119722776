import React from "react"
import {DepositsOverTimeOverview} from "./TotalDepositOverview"

export default function DepositsOverTime() {
	return (
		<div className="card">
			<div className="deposit-overtime-container">
				<h4>Deposits</h4>
				<DepositsOverTimeOverview />
			</div>
		</div>
	)
}
