import React from "react"
import {
	Assignee,
	AssignmentActionRequestType,
	AssignmentStage,
	AssignmentStatus,
	GeneralReviewApproveRequest,
	GeneralReviewAssignmentItemPayload,
	GeneralReviewRejectReason,
	GeneralReviewRejectRequest,
	update,
} from "../../resources/assignments"
import {WirePayment} from "../../resources/payment"
import {Customer} from "../../resources/customer"
import {BusinessApplication} from "../../resources/application"
import {useResetScrollTop} from "../../hooks/useResetScrollTop"
import {AssignmentPageItem} from "../AssignmentPageItem/AssignmentPageItem"
import {AssignmentPageItemApproveRejectFooter} from "../AssignmentPageItemApproveRejectFooter/AssignmentPageItemApproveRejectFooter"
import {DetailsList} from "../DetailsList/DetailsList"
import AssignmentWireDetails from "../AssignmentWireDetails/AssignmentWireDetails"
import {Claims, useUserClaimsData} from "../../services/auth"
import {useModal} from "react-modal-hook"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	AssignmentApproveRejectModal,
	AssignmentStatusUpdateReasonsMapping,
} from "../AssignmentApproveRejectModal/AssignmentApproveRejectModal"
import {AssignmentPageCompletedItemHeader} from "../AssignmentPageCompletedItemHeader/AssignmentPageCompletedItemHeader"
import {Account} from "../../resources/account"

interface AssignmentPageItemGeneralReviewProps {
	id: string
	assignees: Assignee[]
	status: AssignmentStatus
	stage: AssignmentStage
	title: string
	description?: string | JSX.Element
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
	wire?: WirePayment
	account?: Account
	customer?: Customer
	businessApplication?: BusinessApplication
	payload: GeneralReviewAssignmentItemPayload
	accessToken: string
	refresh: () => void
	disabled?: boolean
	claims?: Claims
}

export enum GeneralReviewStatusUpdateReasonKeys {
	SuspectedFraud = "SuspectedFraud",
	ClientRequest = "ClientRequest",
	InsufficientFunds = "InsufficientFunds",
	InvalidName = "InvalidName",
	InvalidRoutingNumber = "InvalidRoutingNumber",
	Other = "Other",
}

const generalReviewAssignmentStatusUpdateReasons: AssignmentStatusUpdateReasonsMapping = {
	[GeneralReviewStatusUpdateReasonKeys.SuspectedFraud]: {reason: "SuspectedFraud", displayName: "Suspected Fraud"},
	[GeneralReviewStatusUpdateReasonKeys.ClientRequest]: {reason: "ClientRequest", displayName: "Client Request"},
	[GeneralReviewStatusUpdateReasonKeys.InsufficientFunds]: {
		reason: "InsufficientFunds",
		displayName: "Insufficient Funds",
	},
	[GeneralReviewStatusUpdateReasonKeys.InvalidName]: {
		reason: "InvalidName",
		displayName: "Invalid Name",
	},
	[GeneralReviewStatusUpdateReasonKeys.InvalidRoutingNumber]: {
		reason: "InvalidRoutingNumber",
		displayName: "Invalid Routing Number",
	},
	[GeneralReviewStatusUpdateReasonKeys.Other]: {reason: "Other", displayName: "Other"},
}

export function AssignmentPageItemGeneralReview({
	id,
	assignees,
	status,
	stage,
	title,
	description,
	createdAt,
	statusUpdatedAt,
	isOwnersItem,
	wire,
	account,
	customer,
	businessApplication,
	payload: {reason, reasonText},
	accessToken,
	refresh,
	disabled,
	claims,
}: AssignmentPageItemGeneralReviewProps) {
	const wireDetailsContentRef = React.useRef<HTMLDivElement>(null)
	useResetScrollTop(wireDetailsContentRef, [id])
	const userClaims = useUserClaimsData(claims)

	const [showApprove, hideApprove] = useModal(() => {
		const successText = "Approved successfully"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = (args: {reasonText: string} | null) => {
			const data: GeneralReviewApproveRequest = {
				type: AssignmentActionRequestType.generalReviewApproveRequest,
				attributes: {
					reasonText: args?.reasonText,
				},
			}
			return callback({accessToken, id, data})
		}
		return (
			<AssignmentApproveRejectModal
				title="Approve Item"
				description={
					<p>
						Are you sure you want to approve <span className="has-text-weight-bold">{`General Review #${id}?`}</span>
					</p>
				}
				state={state}
				onSubmit={onSubmit}
				withReasonText
				isOptionalReasonText
				reasonTextPlaceholder="Please provide a comment if there were initial concerns cleared by additional outreach or investigation."
				primaryButtonText="Approve"
				primaryButtonClassname="is-success"
				closeModal={hideApprove}
				onSuccess={refresh}
				successText={successText}
			/>
		)
	}, [id])

	const [showReject, hideReject] = useModal(() => {
		const successText = "Rejected successfully"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = ({reason, reasonText}: {reason: GeneralReviewRejectReason; reasonText?: string}) => {
			const data: GeneralReviewRejectRequest = {
				type: AssignmentActionRequestType.generalReviewRejectRequest,
				attributes: {
					reason,
					...(reason === GeneralReviewRejectReason.Other ? {reasonText} : {}),
				},
			}
			return callback({accessToken, id, data})
		}
		return (
			<AssignmentApproveRejectModal
				title="Reject Item"
				state={state}
				onSubmit={onSubmit}
				withReasonText
				reasonTextPlaceholder="Why are you rejecting this based on general review?"
				reasons={generalReviewAssignmentStatusUpdateReasons}
				reasonsToEnableReasonText={[GeneralReviewStatusUpdateReasonKeys.Other]}
				primaryButtonText="Reject"
				primaryButtonClassname="is-danger"
				closeModal={hideReject}
				onSuccess={refresh}
				successText={successText}
				successClassname="has-text-black"
			/>
		)
	}, [id])

	return (
		<AssignmentPageItem
			assignees={assignees}
			status={status}
			stage={stage}
			title={title}
			description={description}
			createdAt={createdAt}
			statusUpdatedAt={statusUpdatedAt}
			isOwnersItem={isOwnersItem}
			disabled={disabled}
			pageItemFooter={
				!disabled ? (
					<AssignmentPageItemApproveRejectFooter
						approveLabel="Approve"
						rejectLabel="Reject"
						onApprove={showApprove}
						onReject={showReject}
					/>
				) : undefined
			}
			pageCompletedItemHeader={
				<AssignmentPageCompletedItemHeader
					assignees={assignees}
					status={status}
					reason={reason}
					reasonText={reasonText}
				/>
			}
		>
			<DetailsList
				title="Wire Summary"
				icon="payment-box-incoming"
				className="scrollable-container"
				contentRef={wireDetailsContentRef}
				disabled={disabled}
			>
				{wire && (
					<div className="general-review-wire-details">
						<AssignmentWireDetails
							wire={wire}
							account={account}
							customer={customer}
							businessApplication={businessApplication}
							disabled={disabled}
							accessToken={accessToken}
							claims={userClaims}
							className="modified-padding"
						/>
					</div>
				)}
			</DetailsList>
		</AssignmentPageItem>
	)
}
