import React, {useEffect, useState} from "react"
import {Customer, getCustomer} from "../../resources/customer"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {ErrorDocument, OkDocument, Resource} from "../../resources/common"
import {useAccessToken, useIsBankUser, useIsUnitUser} from "../../services/auth"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {Org} from "../../resources/org"
import {CustomerKnownNames, getCustomerKnownNames} from "../../resources/customerKnownNames"
import {CustomerCardView} from "./CustomerCardView"
import {OrgName} from "../OrgName/OrgName"

const getOrgName = (
	org: Org | undefined,
	isUnitUser: boolean,
	isBankUser: boolean,
	customer: Customer,
	included: Resource[] | undefined
) => {
	if (org) {
		return org.attributes.name
	} else {
		return isUnitUser || isBankUser ? (
			<OrgName orgId={customer.relationships.org.data.id} included={included} element={"span"} />
		) : (
			""
		)
	}
}
interface CustomerCardProps {
	customerAsyncResult?: AsyncResultRequestState<OkDocument<Customer>, ErrorDocument>
	customerId: string
	title?: string
	customer?: Customer
	org?: Org
	showBusinessDetails?: boolean
	showBusinessContactDetails?: boolean
	singleColumn?: boolean
	liteVersion?: boolean
}

export function CustomerCard({
	customerAsyncResult,
	customerId,
	title = "Customer Details",
	customer,
	org,
	showBusinessDetails = true,
	showBusinessContactDetails = true,
	singleColumn,
	liteVersion = false,
}: CustomerCardProps) {
	const accessToken = useAccessToken()
	const [customerKnownNames, setCustomerKnownNames] = useState<CustomerKnownNames | undefined>(undefined)
	const customerKnownNamesResult = useIsUnitUser()
		? useAsyncResult<OkDocument<CustomerKnownNames>, ErrorDocument>(() =>
				getCustomerKnownNames(accessToken, customerId)
		  )
		: null

	if (customerKnownNamesResult) {
		useEffect(() => {
			customerKnownNamesResult.match(
				() => null,
				(aliases) => setCustomerKnownNames(aliases.data as CustomerKnownNames),
				() => null
			)
		}, [customerKnownNamesResult])
	}

	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()

	const customerCardViewCommonProps = {
		title,
		customerKnownNames,
		showBusinessDetails,
		showBusinessContactDetails,
		singleColumn,
		liteVersion,
	}

	if (customer) {
		return (
			<CustomerCardView
				{...customerCardViewCommonProps}
				customer={customer}
				orgName={getOrgName(org, isUnitUser, isBankUser, customer, [])}
			/>
		)
	}

	const customerAsyncResultReq = customerAsyncResult || useAsyncResult(() => getCustomer(accessToken, customerId))
	return (
		<AsyncResultComponent asyncResult={customerAsyncResultReq}>
			{({value: result}) => {
				const customer = result.data
				const included = result.included
				return (
					<CustomerCardView
						{...customerCardViewCommonProps}
						customer={customer}
						orgName={getOrgName(org, isUnitUser, isBankUser, customer, included)}
					/>
				)
			}}
		</AsyncResultComponent>
	)
}
