import React from "react"
import {WirePayment} from "../../resources/payment"
import {Customer} from "../../resources/customer"
import {CustomerCardView} from "../Customers/CustomerCardView"
import {BeneficialOwnerCardView} from "../BeneficialOwners/BeneficalOwnerCardView"
import {BusinessApplication} from "../../resources/application"
import {OfficerCardView} from "../Officer/OfficerCardView"
import {PaymentDetails} from "../PaymentDetails/PaymentDetails"
import classNames from "classnames"
import Payments, {PaymentColumns} from "../Payments/Payments"
import {startCase} from "lodash"
import {Claims, useIsBankUser, useIsUnitUser} from "../../services/auth"
import {Transactions, TransactionsColumns} from "../Transactions/Transactions"
import {WirePaymentCounterpartyFields} from "../Payments/WirePaymentCounterpartyFields"
import {Account} from "../../resources/account"
import {AccountCard} from "../Accounts/AccountCard"

interface AssignmentWireDetailsProps {
	wire?: WirePayment
	title?: string
	account?: Account
	customer?: Customer
	businessApplication?: BusinessApplication
	disabled?: boolean
	singleColumn?: boolean
	liteVersion?: boolean
	accessToken?: string
	claims?: Claims
	className?: string
}

export default function AssignmentWireDetails({
	wire,
	title = "Payment Details",
	account,
	customer,
	businessApplication,
	disabled,
	singleColumn,
	liteVersion,
	accessToken,
	claims,
	className = "default-padding",
}: AssignmentWireDetailsProps) {
	const isUnitUser = useIsUnitUser(claims)
	const isBankUser = useIsBankUser(claims)
	const showAccountCard = (isUnitUser || isBankUser) && account && accessToken
	return (
		<div className="assignment-wire-details-container">
			{wire && (
				<>
					<PaymentDetails
						payment={wire}
						singleColumn={singleColumn}
						isUnitUser={isUnitUser}
						liteVersion={liteVersion}
						className={classNames(className, "is-marginless", "no-radius")}
						disabled={disabled}
						title={title}
					/>
					{!liteVersion && <hr />}
				</>
			)}
			{showAccountCard && (
				<>
					<AccountCard
						account={account}
						accessToken={accessToken}
						singleColumn={singleColumn}
						className={classNames(className, "is-marginless", "no-radius")}
					/>
					{!liteVersion && <hr />}
				</>
			)}
			{customer && (
				<>
					<CustomerCardView
						title="Customer Details"
						customer={customer}
						singleColumn={singleColumn}
						liteVersion={liteVersion}
						disabled={disabled}
						className={classNames(className, "is-marginless", "no-radius")}
					/>
					{businessApplication && (
						<OfficerCardView
							officer={businessApplication.attributes.officer}
							singleColumn={singleColumn}
							disabled={disabled}
							className={classNames(className, "is-marginless", "no-radius")}
						/>
					)}
					{!liteVersion && <hr />}
				</>
			)}
			{businessApplication && (
				<>
					<BeneficialOwnerCardView
						application={businessApplication}
						title="Beneficial Owners"
						singleColumn={singleColumn}
						disabled={disabled}
						className={classNames(className, "is-marginless", "no-radius")}
					/>
					{!liteVersion && <hr />}
				</>
			)}
			{wire && !liteVersion && (
				<>
					<WirePaymentCounterpartyFields
						counterpartyFields={wire.attributes.counterparty}
						singleColumn={singleColumn}
						involvedInstitutions={wire.attributes.involvedInstitutions}
						className={classNames(className, "is-marginless", "no-radius")}
						disabled={disabled}
					/>
					<hr />
					<Payments
						fullHeight={false}
						enableDateFilter={false}
						enableTitle={true}
						disableDrawer={false}
						limit={5}
						enableExport={false}
						title={`Recent ${startCase(wire.type).replace("Payment", "")} Payments`}
						enablePaging={false}
						accountId={wire?.relationships.account.data.id}
						paymentTypes={[wire.type]}
						includedColumns={[
							PaymentColumns.id,
							PaymentColumns.status,
							PaymentColumns.reason,
							PaymentColumns.createdAt,
							PaymentColumns.amount,
						]}
						token={accessToken}
						claims={claims}
						className={classNames(className, "is-marginless", "no-radius", "no-border")}
						titleClassName="table-title"
						hideMinHeight
						disabled={disabled}
						showUploadWireFileButton={false}
					/>
					<hr />
					<Transactions
						enableAmountFilter={false}
						enableDateFilter={false}
						enableTypeFilter={false}
						isUsingPaging={false}
						fullHeight={false}
						accountId={wire?.relationships.account.data.id}
						limit={5}
						enableSearch={false}
						enableExport={false}
						title={"Recent Transactions"}
						token={accessToken}
						includedColumns={[
							TransactionsColumns.id,
							TransactionsColumns.type,
							TransactionsColumns.summary,
							TransactionsColumns.createdAt,
							TransactionsColumns.balance,
							TransactionsColumns.amount,
						]}
						claims={claims}
						titleClassName="table-title"
						className={classNames(className, "is-marginless", "no-radius", "no-border")}
						hideMinHeight
						disabled={disabled}
					/>
				</>
			)}
		</div>
	)
}
