import React, {useRef, useState} from "react"
import classNames from "classnames"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import {useIsOrgUnitPilot, useIsUnitUser} from "../../services/auth"
import {Applications, ApplicationsColumns} from "../../components/Applications/Applications"
import {TutorialModalProps} from "../../components/Tutorial/TutorialModal"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {TutorialButton} from "../../components/Tutorial/TutorialButton"
import {generateCreateApplicationRequest} from "../../components/Tutorial/TutorialPayloads"
import {useQueryState} from "use-location-state"

function Limit({current, limit}: {current: number; limit: number}) {
	return (
		<strong>
			<span className={classNames("current", {"at-limit": current >= limit})}>{current}</span> / {limit}
		</strong>
	)
}

function UnitPilotLimits({
	nonDeniedIndividualApplicationsNum,
	nonDeniedBusinessApplicationsNum,
}: {
	nonDeniedIndividualApplicationsNum: number
	nonDeniedBusinessApplicationsNum: number
}) {
	return (
		<div className="unit-pilot-limits">
			<div className="limits-box">
				<span>Non-declined individual applications</span>
				<Limit limit={1} current={nonDeniedIndividualApplicationsNum} />
			</div>
			<div className="limits-box">
				<span>Non-declined business applications</span>
				<Limit limit={1} current={nonDeniedBusinessApplicationsNum} />
			</div>
		</div>
	)
}

export function ApplicationsPage() {
	const [nonDeniedIndividualApplicationsNum, setNonDeniedIndividualApplicationsNum] = useState<number>(0)
	const [nonDeniedBusinessApplicationsNum, setNonDeniedBusinessApplicationsNum] = useState<number>(0)
	const [payload, setPayload] = useState(generateCreateApplicationRequest)
	const [token, refresh] = useRefreshToken()
	const [showTutorial, setShowTutorial] = useQueryState("showTutorial", false)

	const ref = useRef(null)
	const tutorialProps: TutorialModalProps = {
		tooltipId: "applicationTutorial",
		parentRef: ref,

		actions: [
			{
				actionName: "Create Application",
				title: "Test Create Application API",
				description: "Create an application. Once approved, a customer is automatically created",
				successMessage: "Success! You can now see your new application and customer in the dashboard",
				errorMessage: "There was an error creating the application",
				request: {
					method: "POST",
					path: "applications",
					payload: payload,
					headers: [
						{headerKey: "Content-Type", headerValue: "application/vnd.api+json"},
						{headerKey: "Cache-Control", headerValue: "no-cache"},
					],
				},
				refreshFunction: () => {
					refresh()
					setShowTutorial(false)
				},
				onSendAnotherRequest: () => {
					setPayload(generateCreateApplicationRequest())
				},
			},
		],
	}
	return (
		<>
			<TitleBar title={"Applications"}>
				<div className="is-flex is-justify-content-space-between is-align-items-center">
					{useIsOrgUnitPilot() ? (
						<UnitPilotLimits
							nonDeniedIndividualApplicationsNum={nonDeniedIndividualApplicationsNum}
							nonDeniedBusinessApplicationsNum={nonDeniedBusinessApplicationsNum}
						/>
					) : null}
					<TutorialButton tutorialModalProps={tutorialProps} openOnLoad={showTutorial} />
				</div>
			</TitleBar>

			<MainSectionTable>
				<Applications
					limit={40}
					fullHeight={true}
					enableTitle={false}
					setNonDeniedBusinessApplicationsNum={setNonDeniedBusinessApplicationsNum}
					setNonDeniedIndividualApplicationsNum={setNonDeniedIndividualApplicationsNum}
					includedColumns={[
						ApplicationsColumns.id,
						ApplicationsColumns.type,
						...(useIsUnitUser() ? [ApplicationsColumns.org] : []),
						ApplicationsColumns.name,
						ApplicationsColumns.status,
						...(useIsUnitUser() ? [ApplicationsColumns.evaluation] : []),
						ApplicationsColumns.createdAt,
						ApplicationsColumns.updatedAt,
					]}
					refreshToken={token}
				/>
			</MainSectionTable>
		</>
	)
}
