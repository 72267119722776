import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {
	denyIncomingAch,
	IncomingAch,
	IncomingAchDishonoredReturnReason,
	IncomingAchRejectReason,
	isReturnAch,
} from "../../resources/incomingAch"
import {AddToast} from "react-toast-notifications"
import {NavigateFunction, Location} from "react-router-dom"

const modalExitDelay = 1000

interface DenyIncomingAchModalProps {
	incomingAch: IncomingAch
	close: () => void
	onSuccess: () => void
	addToast: AddToast
	navigate: NavigateFunction
	location: Location
}

function ReasonCode({
	reasonCode,
	setReasonCode,
	transactionCode,
}: {
	reasonCode: string
	setReasonCode: (reasonCode: string) => void
	transactionCode: number
}) {
	return (
		<div className="field">
			<label className="label">Reason</label>
			<div className="select">
				<select
					value={reasonCode}
					onChange={(e) => {
						setReasonCode(e.target.value)
					}}
				>
					{isReturnAch.includes(transactionCode)
						? Array.from(IncomingAchDishonoredReturnReason.entries()).map((e) => (
								<option key={e[0]} value={e[0]}>
									{e[0]} - {e[1]}
								</option>
						  ))
						: Array.from(IncomingAchRejectReason.entries()).map((e) => (
								<option key={e[0]} value={e[0]}>
									{e[0]} - {e[1]}
								</option>
						  ))}
				</select>
			</div>
		</div>
	)
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

function closeAndExit(close: () => void, addToast: AddToast, navigate: NavigateFunction, location: Location) {
	const path = location?.state?.previousPage || "/incoming-achs"
	return function () {
		setTimeout(() => {
			close()
			addToast("ACH Returned", {appearance: "success"})
			navigate && navigate(path)
		}, modalExitDelay)
	}
}

export function DenyIncomingAchModal({
	incomingAch,
	close,
	onSuccess,
	addToast,
	navigate,
	location,
}: DenyIncomingAchModalProps) {
	const accessToken = useAccessToken()
	const [reasonCode, setReasonCode] = useState(incomingAch.attributes.reasonCode?.toString() ?? "R03")
	const [state, deny] = useAsyncResultIdle(denyIncomingAch)

	return (
		<AsyncResultModal
			title="Return Incoming ACH"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-danger"
			state={state}
			buttonText="Return"
			successText="Returned"
			onSuccess={closeAndExit(close, addToast, navigate, location)}
			onSubmit={() => deny(accessToken, incomingAch.id, reasonCode)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<ReasonCode
					reasonCode={reasonCode}
					setReasonCode={setReasonCode}
					transactionCode={incomingAch.attributes.item.transactionCode}
				/>
			</div>
		</AsyncResultModal>
	)
}
