import {getParents} from "./dom"

function trackElementIfNeeded(elementsArr: HTMLElement[]) {
	for (let i = 0; i < elementsArr.length; i++) {
		const element = elementsArr[i]
		if (element.hasAttribute("data-tracking-label")) {
			trackEvent(element.getAttribute("data-tracking-label") ?? "")
			break
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function trackEvent(eventName: string) {
	// TODO: implement tracking (via product analytics)
}

export function initializeClickTracking() {
	document.onclick = (e: MouseEvent) => {
		if (e && e.target) {
			const target = e.target as HTMLElement
			const parents = getParents(target, 5)
			trackElementIfNeeded([target, ...parents])
		}
	}
}
