import React, {CSSProperties, FC, useState} from "react"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {
	CheckDeposit,
	CheckDepositStatus,
	CheckDepositVendors,
	CheckImageSide,
	getCheckDepositImage,
	getCheckDepositRequest,
} from "../../resources/checkDeposits"
import {useAccessToken, useIsUnitUser} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {ErrorDocument, OkDocument, Resource} from "../../resources/common"
import moment from "moment"
import Icon from "../Icon/Icon"
import numeral from "numeral"
import {b64DecodeArrayBuffer} from "../../utilities/helpers"
import Skeleton from "react-loading-skeleton"
import {useModal} from "react-modal-hook"
import {RejectCheckModal} from "./RejectCheckModal"
import {ApproveCheckModal} from "./ApproveCheckModal"
import {hasPermission} from "../../services/permission"
import checkFrontNoCheck from "../../images/check-front-no-check.svg"
import checkBackNoCheck from "../../images/check-back-no-check.svg"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"
import {BackCheckImageModal, FrontCheckImageModal} from "./CheckImageModal"
import {useNavigate} from "react-router-dom"
import {ReturnCheckModal} from "./ReturnCheckModal"
import {kebabCase, startCase} from "lodash"
import classNames from "classnames"
import {OrgName} from "../OrgName/OrgName"
import {DrawerHeader} from "../Drawer/Drawer"
import ReactTooltip from "react-tooltip"
import BodyPortal from "../BodyPortal/BodyPortal"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardKey, KeyValueCardValue} from "../KeyValueCard/KeyValueCard"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {mapUsersIdToName} from "../../resources/user"
import {ImageTransformControls} from "../ImageTransformControls/ImageTransformControls"
import {useToolbar} from "../../hooks/useToolbar"
import {ApplicationDocumentSide} from "../../resources/application"
import {NamesAtUnitCard} from "../NamesAtUnit/NamesAtUnit"
import {isDepositOrFBOAccount} from "../../resources/account"
import {AccountCard} from "../Accounts/AccountCard"
import {CustomerCard} from "../Customers/CustomerCard"
import {Transactions, TransactionsColumns} from "../Transactions/Transactions"
import {CheckDepositColumns, CheckDeposits} from "./CheckDeposits"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"

interface SingleCheckDepositProps {
	id: string
	token: string
	refresh: () => void
	useDrawer: boolean
	checkDepositAsyncResult?: AsyncResultRequestState<OkDocument<CheckDeposit>, ErrorDocument>
}

interface CheckDepositDetails {
	amount: number
	number?: string
	customerId?: number
	accountId?: number
	depositDate: Date
	description: string
	include?: Resource[]
	settlementDate?: Date
	orgId?: string
	tags?: {[key: string]: string}
}

interface DecisionDetailsProps {
	decision: "Approved" | "Rejected"
	reason?: string
	reasonText?: string
	time?: Date
	user?: string
}

const internalDecisionReadyStatuses: Array<keyof typeof CheckDepositStatus> = [
	CheckDepositStatus.Pending,
	CheckDepositStatus.Rejected,
	CheckDepositStatus.Clearing,
	CheckDepositStatus.Sent,
	CheckDepositStatus.Canceled,
	CheckDepositStatus.Returned,
]

interface CheckImageCardProps {
	frontImage?: ArrayBuffer | null
	backImage?: ArrayBuffer | null
	id: string
	status: string
	originated?: boolean
}

type ActionButtons = Array<"Reject" | "Approve" | "Return"> | null

export function CheckImageCard({frontImage, backImage, id, status, originated}: CheckImageCardProps) {
	enum Side {
		front = "Front side",
		back = "Back side",
	}

	const frontImageLink = frontImage ? `data:image/png;base64, ${b64DecodeArrayBuffer(frontImage)}` : ""
	const backImageLink = backImage ? `data:image/png;base64,  ${b64DecodeArrayBuffer(backImage)}` : ""
	const [side, setSide] = useState(Side.front)
	const {zoom, rotation} = useToolbar(ApplicationDocumentSide.Front)
	const inlineImgStyle = {
		"--img-rotation": rotation.val + "deg",
		"--img-scale": zoom.val,
		"--img-container-align": rotation.val === 90 || rotation.val === 270 ? "center" : "start",
	} as CSSProperties

	const changeSide = () => {
		if (side === Side.front) {
			setSide(Side.back)
		} else {
			setSide(Side.front)
		}
	}

	let frontImageObject: JSX.Element, backImageObject: JSX.Element

	switch (backImage) {
		case undefined:
			backImageObject = (
				<div className={"skeleton-placeholder"}>
					<Skeleton />
				</div>
			)
			break
		case null:
			backImageObject =
				side == Side.front ? (
					<img style={inlineImgStyle} className="check-front-image" src={checkBackNoCheck} alt={Side.front} />
				) : (
					<div className="no-check-placeholder">
						<p>
							{" "}
							{originated
								? "Images will be available once the check is deposited"
								: "There is no back image to show."}{" "}
						</p>{" "}
						<br /> <img style={inlineImgStyle} className="check-front-image" src={checkBackNoCheck} alt={Side.front} />
					</div>
				)
			break
		default:
			backImageObject = <img style={inlineImgStyle} className="check-back-image" src={backImageLink} alt={Side.back} />
	}

	switch (frontImage) {
		case undefined:
			frontImageObject = (
				<div className={"skeleton-placeholder"}>
					<Skeleton />
				</div>
			)
			break
		case null:
			frontImageObject =
				side == Side.back ? (
					<img style={inlineImgStyle} className="check-front-image" src={checkFrontNoCheck} alt={Side.front} />
				) : (
					<div className="no-check-placeholder">
						<p>
							{" "}
							{originated
								? "Images will be available once the check is deposited"
								: "There is no front image to show."}{" "}
						</p>{" "}
						<br /> <img style={inlineImgStyle} className="check-front-image" src={checkFrontNoCheck} alt={Side.front} />
					</div>
				)
			break
		default:
			frontImageObject = (
				<img style={inlineImgStyle} className="check-front-image" src={frontImageLink} alt={Side.front} />
			)
	}

	const [showFrontImage, hideFrontImage] = useModal(
		() => (
			<FrontCheckImageModal
				id={id}
				status={status}
				frontImage={frontImageObject}
				backImage={backImageObject}
				closeModal={hideFrontImage}
			/>
		),
		[frontImageObject]
	)

	const [showBackImage, hideBackImage] = useModal(
		() => (
			<BackCheckImageModal
				id={id}
				status={status}
				frontImage={frontImageObject}
				backImage={backImageObject}
				closeModal={hideBackImage}
			/>
		),
		[backImageObject]
	)

	const showImage = () => {
		if (side === Side.front && frontImage) {
			showFrontImage()
		}

		if (side === Side.back && backImage) {
			showBackImage()
		}
	}

	return (
		<div className="check-image-card">
			<header>
				<a
					className="single-check-deposit-link-with-icon"
					target="_blank"
					rel="noreferer"
					href=""
					onClick={(e) => {
						e.preventDefault()
					}}
				>
					<span onClick={showImage}>{side}</span>
					<Icon icon="maximize-square-2--interface-essential" size={13} style={{position: "relative", top: "2px"}} />
				</a>
				{/** @TODO - need to rename component! */}
				{((side === Side.front && frontImage) || (side === Side.back && backImage)) && (
					<ImageTransformControls
						changeRotation={rotation.changeRotation}
						zoomIn={zoom.zoomIn}
						zoomOut={zoom.zoomOut}
						zoomVal={zoom.val}
					/>
				)}
			</header>

			<div
				style={inlineImgStyle}
				className="check-image-card-container"
				data-tracking-label="open-check-image-in-full-screen"
				onClick={showImage}
			>
				{side === Side.front ? frontImageObject : backImageObject}
			</div>

			<div className="check-image-card-preview-container" onClick={changeSide}>
				<span>{side === Side.front ? Side.back : Side.front}</span>
				<div className="check-image-card-preview-container-wrapper">
					<div className="check-image-card-preview-container-mask" />
					{side === Side.front ? backImageObject : frontImageObject}{" "}
				</div>
			</div>
		</div>
	)
}

function CheckDetails({
	amount,
	number,
	customerId,
	orgId,
	accountId,
	depositDate,
	description,
	include,
	settlementDate,
	tags,
}: CheckDepositDetails) {
	const navigate = useNavigate()
	const customer =
		customerId && include
			? include.find(
					(r) =>
						(r.type == "individualCustomer" || r.type == "businessCustomer" || r.type == "businessFBOCustomer") &&
						r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer" || customer.type == "businessFBOCustomer") {
			customerName = customer.attributes.name
		}
	}

	return (
		<KeyValueCard title={"Check Details"}>
			<KeyValueCardContainer>
				{amount && (
					<>
						<KeyValueCardKey> Check Amount</KeyValueCardKey>
						<KeyValueCardValue> {numeral(amount / 100).format("$0,0.00")} </KeyValueCardValue>
					</>
				)}

				{number && (
					<>
						<KeyValueCardKey> Check Number</KeyValueCardKey>
						<KeyValueCardValue> {number} </KeyValueCardValue>
					</>
				)}

				{orgId && (
					<>
						<KeyValueCardKey> Org</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<OrgName orgId={orgId.toString()} included={include} element={"span"} />
						</KeyValueCardValue>
					</>
				)}

				{customerName && customer && (
					<>
						<KeyValueCardKey> Customer</KeyValueCardKey>
						<KeyValueCardValue>
							<a
								className="link"
								href={`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
								}}
							>
								{customerName}
							</a>
						</KeyValueCardValue>
					</>
				)}

				{accountId && (
					<>
						<KeyValueCardKey> Account ID</KeyValueCardKey>
						<KeyValueCardValue>
							<a
								className="link"
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									if (accountId) {
										navigate(`/accounts/${accountId}`)
									}
								}}
							>
								{accountId}
							</a>
						</KeyValueCardValue>
					</>
				)}

				{depositDate && (
					<>
						<KeyValueCardKey> Deposit Date</KeyValueCardKey>
						<KeyValueCardValue> {moment(depositDate).format("MM/DD/YYYY h:mm A")} </KeyValueCardValue>
					</>
				)}

				{settlementDate && (
					<>
						<KeyValueCardKey> Expected Completion Date</KeyValueCardKey>
						<KeyValueCardValue>
							<span
								data-tip="React-tooltip"
								data-for={`check-deposit-drawer-expected-completion-time-tooltip-${number}`}
							>
								{moment(settlementDate).format("MM/DD/YYYY")}
							</span>
							<BodyPortal>
								<ReactTooltip
									className="tooltip-info"
									id={`check-deposit-drawer-expected-completion-time-tooltip-${number}`}
									disable={!settlementDate}
									effect="solid"
								>
									{checkDepositTooltipValue}
								</ReactTooltip>
							</BodyPortal>
						</KeyValueCardValue>
					</>
				)}

				{tags && (
					<>
						<KeyValueCardKey> Tags</KeyValueCardKey>
						<KeyValueCardValue>
							<TagsViewerKeyValue tags={tags} />
						</KeyValueCardValue>
					</>
				)}

				{description && (
					<>
						<KeyValueCardKey> Description</KeyValueCardKey>
						<KeyValueCardValue> {description} </KeyValueCardValue>
					</>
				)}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

const DecisionDetails: FC<DecisionDetailsProps> = ({decision, reason, time, user, reasonText}) => {
	return (
		<KeyValueCard title={"Decision Details"}>
			<KeyValueCardContainer>
				{decision && (
					<>
						<KeyValueCardKey> Decision</KeyValueCardKey>
						<KeyValueCardValue> {decision} </KeyValueCardValue>
					</>
				)}

				{reason && (
					<>
						<KeyValueCardKey> Reason</KeyValueCardKey>
						<KeyValueCardValue> {reasonText ? `${reason} (${reasonText})` : reason} </KeyValueCardValue>
					</>
				)}

				{time && (
					<>
						<KeyValueCardKey> Decision Date</KeyValueCardKey>
						<KeyValueCardValue> {moment(time).format("MM/DD/YYYY h:mm A")} </KeyValueCardValue>
					</>
				)}

				{user && (
					<>
						<KeyValueCardKey> Decisioned By</KeyValueCardKey>
						<KeyValueCardValue> {user} </KeyValueCardValue>
					</>
				)}
			</KeyValueCardContainer>
		</KeyValueCard>
	)
}

export function CheckActionButtons({
	id,
	refresh,
	buttons,
	disabled,
	title,
}: {
	id: string
	refresh: () => void
	buttons: ActionButtons
	disabled: boolean
	title: string
}) {
	if (!buttons) return null

	const [showRejectCheck, hideRejectCheck] = useModal(() => (
		<RejectCheckModal closeModal={hideRejectCheck} id={id} onSuccess={refresh} />
	))

	const [showApproveCheck, hideApproveCheck] = useModal(() => (
		<ApproveCheckModal closeModal={hideApproveCheck} id={id} onSuccess={refresh} />
	))

	const [showReturnCheck, hideReturnCheck] = useModal(() => (
		<ReturnCheckModal closeModal={hideReturnCheck} id={id} onSuccess={refresh} />
	))

	return (
		<>
			{buttons.includes("Reject") && (
				<button className="button is-danger" onClick={() => showRejectCheck()} disabled={disabled} title={title}>
					<span className="icon">
						<Icon icon="interface-delete-interface-essential" size={12} />
					</span>
					<span> Reject </span>
				</button>
			)}

			{buttons.includes("Approve") && (
				<button className="button is-approved" onClick={() => showApproveCheck()} disabled={disabled} title={title}>
					<span className="icon">
						<Icon icon="interface-validation-check--interface-essential" size={12} />
					</span>
					<span> Approve </span>
				</button>
			)}

			{buttons.includes("Return") && (
				<button className="button is-danger" onClick={() => showReturnCheck()} disabled={disabled} title={title}>
					<span className="icon">
						<Icon icon="interface-validation-check--interface-essential" size={12} />
					</span>
					<span> Mark as returned </span>
				</button>
			)}
		</>
	)
}

function CheckDepositInfoPending() {
	return (
		<div className="check-content">
			<div className="check-image-card">
				<a className="single-check-deposit-link-with-icon">
					<Skeleton />
				</a>

				<div className="check-image-card-container">
					<div className={"skeleton-placeholder"}>
						<Skeleton />
					</div>
				</div>

				<div className="check-image-card-preview-container">
					<Skeleton />
					<div className="check-image-card-preview-container-wrapper">
						<Skeleton />
					</div>
				</div>
			</div>

			<KeyValueCard>
				<KeyValueCardContainer>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
				</KeyValueCardContainer>
			</KeyValueCard>

			<KeyValueCard>
				<KeyValueCardContainer>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
					<>
						<KeyValueCardKey>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardKey>
						<KeyValueCardValue>
							{" "}
							<Skeleton />{" "}
						</KeyValueCardValue>
					</>
				</KeyValueCardContainer>
			</KeyValueCard>
		</div>
	)
}

export const getButtonsToShow = (status: keyof typeof CheckDepositStatus): ActionButtons => {
	switch (status) {
		case CheckDepositStatus.PendingReview:
			return ["Approve", "Reject"]
		case CheckDepositStatus.Clearing:
			return ["Return"]
		case CheckDepositStatus.Sent:
			return ["Return"]
		default:
			return null
	}
}

export const checkDepositTooltipValue =
	"The funds from checks that are deposited before the daily cutoff, are received by Unit on the next business day, and held for the clearing period defined on the account’s deposit product"

export const disabledActionsEnsentaTooltipValue = "Cannot perform actions for this vendor"

export function CheckDepositInfo({id, token, refresh, useDrawer, checkDepositAsyncResult}: SingleCheckDepositProps) {
	const accessToken = token ?? useAccessToken()
	const getCheckDeposit = checkDepositAsyncResult || useAsyncResult(() => getCheckDepositRequest({accessToken, id}))
	const frontImageState = useAsyncResult(() => getCheckDepositImage({accessToken, id, side: CheckImageSide.Front}))
	const backImageState = useAsyncResult(() => getCheckDepositImage({accessToken, id, side: CheckImageSide.Back}))
	const frontImage = frontImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)
	const backImage = backImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)

	return (
		<AsyncResultComponent asyncResult={getCheckDeposit} pendingComponent={<CheckDepositInfoPending />}>
			{({value}) => {
				const checkDeposit = value as {data: CheckDeposit; included: Resource[]}
				const checkId = checkDeposit.data.id
				const attributes = checkDeposit.data.attributes
				const relationships = checkDeposit.data.relationships
				const included = checkDeposit.included
				const vendor = attributes?.vendor
				const reason = attributes?.reason
				const reasonText = attributes?.reasonText
				const status = attributes?.status
				const updatedById = attributes?.statusSetBy
				const updatedAt = attributes?.statusCreatedAt
				const tags = attributes?.tags
				const userIdToName = mapUsersIdToName(included)
				const updateByName = updatedById ? userIdToName.get(updatedById) : undefined
				const depositAccount = included?.find((v) => v.type == "depositAccount")

				return (
					<>
						{useDrawer && (
							<DrawerHeader title={`Check #${id}`}>
								<div className={classNames("check-deposit-status", kebabCase(status))}>{startCase(status)}</div>
							</DrawerHeader>
						)}
						<div className="check-content">
							{useDrawer && (
								<CheckImageCard frontImage={frontImage} backImage={backImage} id={checkId} status={attributes.status} />
							)}
							<CheckDetails
								amount={attributes.amount}
								number={attributes.checkNumber}
								customerId={relationships.customer?.data.id}
								accountId={relationships.account?.data.id}
								depositDate={attributes.createdAt}
								description={attributes.description}
								include={included}
								settlementDate={attributes.settlementDate}
								tags={tags}
								orgId={useIsUnitUser() ? relationships.org?.data.id : undefined}
							/>
							{!useDrawer && (
								<>
									{relationships.customer?.data.id && (
										<UnitUserOnly>
											<NamesAtUnitCard customerId={`${relationships.customer?.data.id || ""}`} />
										</UnitUserOnly>
									)}
									{depositAccount && isDepositOrFBOAccount(depositAccount) && (
										<AccountCard account={depositAccount} accessToken={accessToken} />
									)}
									{relationships.customer?.data.id && (
										<CustomerCard customerId={relationships.customer.data.id.toString()} />
									)}
									{attributes.status === "PendingReview" && (
										<>
											<CheckDeposits
												title={"Recent Check Deposits"}
												fullHeight={false}
												stickyAction={false}
												enablePaging={false}
												enableStatusFilter={false}
												enableDateFilter={false}
												enableSearch={false}
												enableExport={false}
												enableRowClick={false}
												enableTitle
												disableDrawer
												limit={10}
												includedColumns={[
													CheckDepositColumns.id,
													CheckDepositColumns.status,
													CheckDepositColumns.reason,
													CheckDepositColumns.depositDate,
													CheckDepositColumns.amount,
												]}
												customerId={relationships.customer?.data.id.toString()}
											/>
											<Transactions
												enableAmountFilter={false}
												enableDateFilter={false}
												enableTypeFilter={false}
												enableOrgFilter={false}
												enableDirectionFilter={false}
												isUsingPaging={false}
												fullHeight={false}
												accountId={`${relationships.account?.data.id}`}
												limit={5}
												enableSearch={false}
												enableExport={false}
												title={"Recent Transactions"}
												token={accessToken}
												includedColumns={[
													TransactionsColumns.id,
													TransactionsColumns.type,
													TransactionsColumns.summary,
													TransactionsColumns.createdAt,
													TransactionsColumns.balance,
													TransactionsColumns.amount,
												]}
											/>
										</>
									)}
								</>
							)}
							{internalDecisionReadyStatuses.includes(status) && (
								<DecisionDetails
									time={updatedAt}
									user={updateByName}
									decision={status == CheckDepositStatus.Rejected ? CheckDepositStatus.Rejected : "Approved"}
									reason={reason}
									reasonText={reasonText}
								/>
							)}
						</div>

						{useDrawer && hasPermission("checkDeposit.approve", "update") && (
							<div className="drawer-action-buttons buttons is-right">
								<CheckActionButtons
									id={checkId}
									refresh={refresh}
									buttons={getButtonsToShow(status)}
									title={vendor === CheckDepositVendors.Ensenta ? disabledActionsEnsentaTooltipValue : ""}
									disabled={vendor === CheckDepositVendors.Ensenta}
								/>
							</div>
						)}
					</>
				)
			}}
		</AsyncResultComponent>
	)
}
