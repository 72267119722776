import React, {ReactElement} from "react"
import {Assignment, AssignmentStage, AssignmentType} from "../../resources/assignments"
import {Resource} from "../../resources/common"
import {AllowedAssignmentsHubColumns, AssignmentsHubColumns} from "./AssignmentsHub"
import {getIsOwnersItem} from "../Assignments/Assignments"
import {Claims} from "../../services/auth"
import {DataTableCell, DataTableRow} from "../../components/DataTable/DataTable"
import {OrgName} from "../../components/OrgName/OrgName"
import moment from "moment"
import {AssignmentItemStatus} from "../../components/AssignmentItemStatus/AssignmentItemStatus"
import {startCase} from "lodash"
import {BankName} from "../../components/BankName/BankName"
import {PaymentTabConstants, paymentTabQueryStateName} from "../Payments/Payment"
import {CustomerName} from "../../components/CustomerName/CustomerName"

interface AssignmentRowProps {
	assignment: Assignment
	include?: Resource[]
	includedColumns: AllowedAssignmentsHubColumns[]
	refresh: () => void
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>, redirectLink: string) => void
	claims: Claims
	isUnitUser: boolean
	accessToken?: string
}

function extractResourceData(assignment: Assignment) {
	let redirectLink = ""
	let resourceId = ""
	if (assignment.relationships.payment) {
		resourceId = assignment.relationships.payment.data.id
		redirectLink = `/payments/${resourceId}/assignments#assignmentId=${assignment.id}&${paymentTabQueryStateName}=${PaymentTabConstants.ActionsRequired}`
	}
	return {redirectLink, resourceId}
}

const getAssignmentTypeDisplayName = (type: AssignmentType) => {
	if (type === AssignmentType.ofacCheckAssignment) {
		return "OFAC Check"
	}
	return startCase(type.replace("Assignment", ""))
}
export function AssignmentRow({
	assignment,
	include,
	includedColumns,
	onClick,
	claims,
	isUnitUser,
	accessToken,
}: AssignmentRowProps) {
	const {id, type, attributes, relationships} = assignment
	const {assignee: assigneeType, status, createdAt, resourceType} = assignment.attributes
	const orgId = relationships.org.data.id
	const bankId = relationships.bank.data.id
	const customerId = relationships.customer?.data.id
	const isOwner = getIsOwnersItem({
		assigneeType,
		bankId,
		orgId,
		claims,
		isUnitUser,
	})
	const {stage} = attributes

	const resourceData = extractResourceData(assignment)

	const contentColumns: Partial<Record<AllowedAssignmentsHubColumns, ReactElement>> = {}

	if (includedColumns.includes(AssignmentsHubColumns.isOwner)) {
		contentColumns[AssignmentsHubColumns.isOwner] = (
			<DataTableCell>
				{isOwner && stage === AssignmentStage.Pending && <div className="assignment-hub-table-item-dot" />}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AssignmentsHubColumns.resourceType)) {
		contentColumns[AssignmentsHubColumns.resourceType] = <DataTableCell>{startCase(resourceType)}</DataTableCell>
	}

	if (includedColumns.includes(AssignmentsHubColumns.resourceId)) {
		contentColumns[AssignmentsHubColumns.resourceId] = <DataTableCell>{resourceData.resourceId}</DataTableCell>
	}

	if (includedColumns.includes(AssignmentsHubColumns.org) && orgId) {
		contentColumns[AssignmentsHubColumns.org] = (
			<OrgName orgId={orgId.toString()} included={include} token={accessToken} element={DataTableCell} />
		)
	}

	if (includedColumns.includes(AssignmentsHubColumns.bank) && bankId) {
		contentColumns[AssignmentsHubColumns.bank] = (
			<DataTableCell>
				<BankName bankId={bankId.toString()} included={include} token={accessToken} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AssignmentsHubColumns.customer) && customerId) {
		contentColumns[AssignmentsHubColumns.customer] = (
			<DataTableCell>
				<CustomerName customerId={customerId.toString()} included={include} token={accessToken} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AssignmentsHubColumns.assignmentId)) {
		contentColumns[AssignmentsHubColumns.assignmentId] = <DataTableCell>{id}</DataTableCell>
	}

	if (includedColumns.includes(AssignmentsHubColumns.assignmentType)) {
		contentColumns[AssignmentsHubColumns.assignmentType] = (
			<DataTableCell>{getAssignmentTypeDisplayName(type)}</DataTableCell>
		)
	}

	if (includedColumns.includes(AssignmentsHubColumns.assignee)) {
		contentColumns[AssignmentsHubColumns.assignee] = <DataTableCell>{assigneeType}</DataTableCell>
	}

	if (includedColumns.includes(AssignmentsHubColumns.assignmentStatus)) {
		contentColumns[AssignmentsHubColumns.assignmentStatus] = (
			<DataTableCell>
				<AssignmentItemStatus status={status} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(AssignmentsHubColumns.createdAt)) {
		contentColumns[AssignmentsHubColumns.createdAt] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow onClick={(e) => onClick && onClick(e, resourceData.redirectLink)}>{content}</DataTableRow>
}
