import React, {useState} from "react"
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

interface Props {
	getValue: () => string
	placeholder?: string | JSX.Element
	element: (value: string) => JSX.Element
	defaultIsHidden: boolean
	isHiddenIconVisible?: boolean
}

export function emailPreview(email: string) {
	return email.substr(0, 2) + "●●●●@●●●●.●●●"
}

export function phonePreview(phone: string) {
	return `+●●●●●●●●${phone.substr(phone.length - 2)}`
}

export function ssnPreview(ssn: string) {
	return `●●●●●●●${ssn.substr(ssn.length - 2)}`
}

export function datePreview() {
	return "●●/●●/●●●●"
}

export function miltilinePreview(text: string) {
	return (
		<div className="address-placeholder">
			{text
				.split("\n")
				.map((_) => "●".repeat(Math.random() * 4 + 2))
				.join("\n")}
		</div>
	)
}

export function accountNumberPreview(ssn: string) {
	return `●●●●●●●●${ssn.substr(ssn.length - 4)}`
}

export function InformationHiding({element, getValue, placeholder, defaultIsHidden, isHiddenIconVisible}: Props) {
	const [isHidden, setIsHidden] = useState(defaultIsHidden)

	function show() {
		if (isHidden) {
			setIsHidden(false)
		} else {
			setIsHidden(true)
		}
	}

	return (
		<div className="hiding-information">
			{isHidden ? placeholder : element(getValue())}
			{isHiddenIconVisible && (
				<FontAwesomeIcon
					icon={isHidden ? faEye : faEyeSlash}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						show()
					}}
				/>
			)}
		</div>
	)
}

InformationHiding.defaultProps = {
	placeholder: "●●●●",
	defaultIsHidden: true,
	isHiddenIconVisible: true,
}
