import {getResource} from "./common"

export type Partner = {
	type: "partner"
	id: string
	attributes: {
		createdAt: Date
		name: string
	}
}

export async function findPartners(accessToken: string) {
	const result = await getResource<Array<Partner>>("partners", accessToken)
	return result.map((v) => v.data)
}
