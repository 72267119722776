import React, {useEffect} from "react"
import {useAccessToken, useUserType} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useToasts} from "react-toast-notifications"
import {useQueryState} from "use-location-state"
import {startCase} from "lodash"
import {Cents} from "../../resources/transaction"
import {usePaging} from "../../hooks/usePaging"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument} from "../../resources/common"
import {Filter} from "../Filter/Filter"
import moment from "moment"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {CurrencyRangeFilter} from "../Filter/CurrencyRangeFilter"
import {downloadFile} from "../../utilities/file"
import {DataTableActionHeader, DataTableCard, TablePending} from "../DataTable/DataTable"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {ReceivedPaymentsTable} from "./ReceivedPaymentsTable"
import {findReceivedPayments, ReceivedPaymentStatus, ReceivedPaymentTypes} from "../../resources/receivedPayments"
import SearchBox from "../SearchBox/SearchBox"
import {useEsc} from "../../hooks/useEsc"

export enum ReceivedPaymentsColumns {
	id = "Id",
	org = "Org",
	type = "Type",
	accountId = "Account",
	customer = "Customer",
	companyName = "Company Name",
	amount = "Amount",
	status = "Status",
	createdAt = "Created At",
	completionDate = "Completion Date",
}

export type AllowedReceivedPaymentsColumns =
	| ReceivedPaymentsColumns.id
	| ReceivedPaymentsColumns.org
	| ReceivedPaymentsColumns.type
	| ReceivedPaymentsColumns.accountId
	| ReceivedPaymentsColumns.customer
	| ReceivedPaymentsColumns.companyName
	| ReceivedPaymentsColumns.amount
	| ReceivedPaymentsColumns.status
	| ReceivedPaymentsColumns.createdAt
	| ReceivedPaymentsColumns.completionDate

export interface ReceivedPaymentsProps {
	accountId?: string
	token?: string
	limit?: number
	includedColumns: AllowedReceivedPaymentsColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	enableOrgFilter?: boolean
	enableStatusFilter?: boolean
	enableTypeFilter?: boolean
	enableAmountFilter?: boolean
	enableExport?: boolean
	disableDrawer?: boolean
}

export default function ReceivedPayments({
	limit = 25,
	token,
	includedColumns,
	fullHeight,
	enableTitle,
	accountId,
	enableOrgFilter = false,
	enableStatusFilter = false,
	enableTypeFilter = false,
	enableAmountFilter = false,
	enableExport = true,
	disableDrawer = false,
}: ReceivedPaymentsProps) {
	const prefix = "receivedPayments"
	const accessToken = token ?? useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType()
	const {addToast} = useToasts()
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [query, setQuery] = useQueryState(`${prefix}-filter[query]`, "")
	const statusesOptions = new Map(
		[
			ReceivedPaymentStatus.Advanced,
			ReceivedPaymentStatus.Completed,
			ReceivedPaymentStatus.Pending,
			ReceivedPaymentStatus.PendingReview,
			ReceivedPaymentStatus.Returned,
			ReceivedPaymentStatus.MarkedForReturn,
		].map((v) => [v, startCase(v)])
	)
	const typesOptions = new Map(
		[ReceivedPaymentTypes.ACH].map((v) => [
			v,
			startCase(v)
				.replace(/payment/gi, "")
				.replace(/ach/gi, "ACH"),
		])
	)
	const [statuses, setStatuses] = useQueryState(
		`${prefix}-filter[status]`,
		enableStatusFilter ? [ReceivedPaymentStatus.Pending] : []
	)
	const [types, setTypes] = useQueryState<string[]>(`${prefix}-filter[type]`, [])
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")
	const [sortBy, setSortBy] = useQueryState(`${prefix}-sort`, "-createdAt")
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search by Customer Id", 500, onSearch)
	const [selectedReceivedPaymentId, setSelectedReceivedPaymentId] = useQueryState("id", "")
	useEsc(() => setSelectedReceivedPaymentId(""))

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findReceivedPayments(
				accessToken,
				offset,
				sortBy,
				query,
				"json",
				limit,
				statuses,
				filteredOrgs,
				types,
				since,
				until,
				accountId,
				fromAmount,
				toAmount,
				"org,customer,account,incomingAch"
			),
		(x) => x.data.length,
		[
			refreshToken,
			since,
			until,
			sortBy,
			[...statuses, ...types, ...filteredOrgs].join(","),
			fromAmount,
			toAmount,
			query,
		],
		`${prefix}-`,
		true
	)

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	const orgs =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const typesFilter = (
		<Filter
			statuses={types}
			setStatuses={setTypes}
			onFilterFunc={() => reset(limit)}
			options={typesOptions}
			title="Type"
		/>
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={setFromAmount}
			to={toAmount}
			setTo={setToAmount}
		/>
	)

	function toggleSort(columnName: string) {
		const sortParam = sortBy == `-${columnName}` ? columnName : `-${columnName}`
		setSortBy(sortParam)
		reset(limit)
	}

	async function exportPayments() {
		const response = await findReceivedPayments(
			accessToken,
			0,
			sortBy,
			query,
			"csv",
			null,
			statuses,
			filteredOrgs,
			types,
			since,
			until,
			accountId,
			fromAmount,
			toAmount
		)

		response.match(
			(d) => downloadFile("Received Payments.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
		return true
	}

	return (
		<DataTableCard className={"received-payments-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				dateTimePicker={dateTime}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableStatusFilter ? [statusFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
					...(enableTypeFilter ? [typesFilter] : []),
				]}
				searchBox={searchBox}
				exportFunc={enableExport ? exportPayments : undefined}
				title={enableTitle ? "Received Payments" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: receivedPayments}) => {
					return (
						<ReceivedPaymentsTable
							receivedPayments={receivedPayments.data}
							meta={receivedPayments.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={true}
							include={receivedPayments.included}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							sortFunction={toggleSort}
							sortBy={sortBy}
							selectedReceivedPaymentId={selectedReceivedPaymentId}
							setSelectedReceivedPaymentId={setSelectedReceivedPaymentId}
							disableDrawer={disableDrawer}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
