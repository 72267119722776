import React from "react"
import {useParams} from "react-router-dom"
import {AccountComponent} from "../Account/Account"
import {OrgAuthProvider, useCustomerAccessToken} from "../../services/orgAuth"
import {useAccessToken, useIsBankUser, useIsUnitUser} from "../../services/auth"

export function Internal({accountId}: {accountId: string}) {
	const accessToken = useCustomerAccessToken()

	return <AccountComponent accountId={accountId} accessToken={accessToken} isOrgAccount />
}

export default function OrgAccountPage() {
	const {accountId} = useParams<{accountId: string}>()
	const isBankUser = useIsBankUser()
	const isUnitUser = useIsUnitUser()
	const accessToken = useAccessToken()
	return isBankUser || isUnitUser ? (
		<AccountComponent accountId={accountId as string} accessToken={accessToken} isOrgAccount />
	) : (
		<OrgAuthProvider>
			<Internal accountId={accountId as string} />
		</OrgAuthProvider>
	)
}
