import React from "react"
import {IcomoonIconName} from "../Icon/icons"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {useNavigate} from "react-router-dom"

export function LinkMetaBox({text, path, icon}: {text: string; path: string; icon: IcomoonIconName}) {
	const navigate = useNavigate()

	return (
		<a
			className={classNames("link-meta-box")}
			href={path}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				navigate(`${path}`)
			}}
		>
			<Icon icon={icon} size={14} />
			{text}
			<Icon icon={"right-arrow"} size={14} />
		</a>
	)
}

export function LinkMetaBoxContainer({children}: {children: React.ReactNode}) {
	return <div className={"link-meta-box-container"}>{children}</div>
}
