import React, {useEffect, useState} from "react"
import TitleBar, {MetaItemProps, TitleBarAction} from "../../components/TitleBar/TitleBar"
import {
	ApplicationDocument,
	ApplicationType,
	ApplicationTypeIcons,
	evaluateApplicationLocation,
	IndividualApplication,
	isApplicationDocument,
	updateIndividualApplication,
} from "../../resources/application"
import {useAccessToken, useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import {MainSection} from "../../containers/MainSection/MainSection"
import {ApplicationStatusTag, CreatedAtTag, EvaluationOutcomeTag, Tags, UpdatedAtTag} from "../../components/Tag"
import ApplicationDocuments from "../../components/ApplicationDocuments/ApplicationDocuments"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import {
	Editable,
	EditableAddress,
	EditableDate,
	EditableFullName,
	EditableHiddenString,
	EditableOptionalString,
	EditablePhone,
	OptionalEditable,
} from "../../components/HorizontalField/EditableHorizontalField"
import {UnitOrBankUserOnly, UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {
	DenyApplicationModal,
	ApproveApplicationModal,
	CancelApplicationModal,
} from "../../components/Applications/ApplicationModals"
import {useModal} from "react-modal-hook"
import {OkDocument, Resource} from "../../resources/common"
import LinkButton from "../../components/LinkButton/LinkButton"
import ApplicationDecision from "../../components/ApplicationDecision/ApplicationDecision"
import {emailPreview} from "../../components/InformationHiding/InformationHiding"
import TagsViewer from "../../components/Tags/TagsViewer"
import {EvaluationFlags} from "../../components/EvaluationFlags/EvaluationFlags"
import {isUndefined} from "lodash"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useToasts} from "react-toast-notifications"
import moment from "moment"
import {IndividualRequestDocumentModal} from "../../components/RequestDocumentModal/RequestDocumentModal"
import EvaluationScores from "../../components/EvaluationScores/EvaluationScores"
import IdentificationNumber, {getDocumentTitle} from "../../components/IdentificationNumber/IdentificationNumber"
import {OrgName} from "../../components/OrgName/OrgName"
import Icon from "../../components/Icon/Icon"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {EditableOccupationField} from "../../components/ApplicationAdditionalInformationFields/OccupationField"
import {EditableBusinessVerticalField} from "../../components/ApplicationAdditionalInformationFields/BusinessVerticalField"
import {EditableAnnualIncomeField} from "../../components/ApplicationAdditionalInformationFields/AnnualIncomeField"
import {EditableSolePropAnnualRevenueField} from "../../components/ApplicationAdditionalInformationFields/AnnualRevenueField"
import {EditableSolePropNumberOfEmployeesField} from "../../components/ApplicationAdditionalInformationFields/NumberOfEmployeesField"
import {Accordion} from "../../components/Accordion/Accordion"
import {EditableWebsiteField} from "../../components/EditableWebsiteField/EditableWebsiteField"
import {EditableSourceOfIncomeField} from "../../components/ApplicationAdditionalInformationFields/SourceOfIncomeField"
import {getAuditLogEventsHeaderMetaItems} from "../Applications/auditLogMetaItems"
import {isOrg, Org} from "../../resources/org"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"

function IndividualApplicationInternal({
	individual,
	documents,
	refresh,
	customEvaluationFlags,
	included,
}: {
	individual: IndividualApplication
	documents: ApplicationDocument[]
	refresh: () => void
	customEvaluationFlags: string[]
	included?: Resource[]
}) {
	const accessToken = useAccessToken()
	const {addToast} = useToasts()
	const [showApprove, hideApprove] = useModal(() => (
		<ApproveApplicationModal close={hideApprove} application={individual} onSuccess={refresh} />
	))

	const [showDeny, hideDeny] = useModal(() => (
		<DenyApplicationModal close={hideDeny} application={individual} onSuccess={refresh} accessToken={accessToken} />
	))

	const [showCancel, hideCancel] = useModal(() => (
		<CancelApplicationModal close={hideCancel} application={individual} onSuccess={refresh} />
	))

	const [showRequestDocument, hideRequestDocument] = useModal(() => (
		<IndividualRequestDocumentModal
			application={individual}
			close={hideRequestDocument}
			accessToken={accessToken}
			onSuccess={refresh}
		/>
	))

	const [isEditing, setIsEditing] = useState<string | undefined>(undefined)
	const [isUpdating, setIsUpdating] = useState(false)

	const {attributes} = individual
	const name = `${attributes.fullName.first} ${attributes.fullName.last}`
	const documentTitle = getDocumentTitle(
		attributes.ssn,
		attributes.maskedSSN,
		attributes.passport,
		attributes.maskedPassport,
		attributes.matriculaConsular,
		attributes.maskedMatriculaConsular
	)
	const [fullName, setFullName] = useState(attributes.fullName)
	const [dob, setDob] = useState(attributes.dateOfBirth ? moment(attributes.dateOfBirth).toDate() : undefined)
	const [address, setAddress] = useState(attributes.address)
	const [phone, setPhone] = useState(attributes.phone)
	const [email, setEmail] = useState(attributes.email)
	const [ein, setEin] = useState(attributes.ein)
	const [dba, setDba] = useState(attributes.dba)
	const [updateState, updateApp] = useAsyncResultIdle(updateIndividualApplication)
	const [occupation, setOccupation] = useState(attributes.occupation)
	const [sourceOfIncome, setSourceOfIncome] = useState(attributes.sourceOfIncome)
	const [annualIncome, setAnnualIncome] = useState(attributes.annualIncome)
	const [numberOfEmployees, setNumberOfEmployees] = useState(attributes.numberOfEmployees)
	const [annualRevenue, setAnnualRevenue] = useState(attributes.annualRevenue)
	const [businessVertical, setBusinessVertical] = useState(attributes.businessVertical)
	const [website, setWebsite] = useState(attributes.website)
	const hasNoWebsite = attributes.hasNoWebsite
	const archived = attributes.archived
	const isApplicationEditable = !archived && attributes.status != "Canceled"
	const isHiddenIconVisible = !useIsUnitComplianceUser()

	const canApprove = attributes.status !== "Approved" && isApplicationEditable
	const canDeny = attributes.status !== "Approved" && attributes.status !== "Denied" && isApplicationEditable
	const canCancel = attributes.status !== "Approved" && attributes.status !== "Denied" && isApplicationEditable
	const idTheftScore = attributes?.idTheftScore

	const evaluationScores = {
		...attributes.evaluationScores,
		...(idTheftScore ? {idTheftScore} : {}),
	}

	const org = included?.find((r) => isOrg(r) && r.id == individual.relationships.org.data.id)

	const auditLogEventsHeaderMetaItems = getAuditLogEventsHeaderMetaItems({
		applicationName: name,
		applicationType: ApplicationType.individual,
		pageIcon: ApplicationTypeIcons[ApplicationType.individual],
		org: org as Org,
	})

	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()

	useEffect(() => {
		if (updateState.isOk()) {
			addToast("Application Updated Successfully", {appearance: "success"})
			setIsUpdating(false)
			refresh()
		} else if (updateState.isErr()) {
			addToast(updateState.error.errors[0].detail || updateState.error.errors[0].title, {appearance: "error"})
			setIsUpdating(false)
			refresh()
		}
	}, [updateState])

	const customerLink = individual.relationships.customer
		? `/customers/individual/${individual.relationships.customer.data.id}`
		: undefined

	return (
		<>
			<TitleBar
				title={name}
				breadcrumbs={[{text: "Applications", path: "/applications/"}]}
				meta={[
					{text: "Individual", icon: "user-geometric-action---users"},
					...(customerLink
						? [
								{
									text: "Customer",
									icon: "user-geometric-action-money-fund---users",
									path: customerLink,
								} as MetaItemProps,
						  ]
						: []),
				]}
				status={attributes.archived ? {text: "Archived", color: "#B0B2B5", backgroundColor: "#EBECEE"} : undefined}
			>
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				<TitleBarAction>
					<HasPermission resource="application.status.cancel" action="update">
						{canCancel && (
							<button className="button button-cancel mr-0 is-flex is-align-items-center" onClick={showCancel}>
								<Icon icon={"user-geometric-action-cross-remove"} size={16} />
								<span>Cancel Application</span>
							</button>
						)}
					</HasPermission>
				</TitleBarAction>
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="application"
					resourceId={individual.id}
				/>
				<div className="columns is-mobile">
					<div className="column is-narrow">
						<div className="buttons">
							{individual.attributes.evaluationEntityId ? (
								<LinkButton to={`https://app.alloy.co/entities/${individual.attributes.evaluationEntityId}`}>
									Alloy
								</LinkButton>
							) : null}
						</div>
					</div>
					<div className="column">
						<UnitUserOnly>
							<div className="buttons is-right">
								{attributes.status == "AwaitingDocuments" || attributes.status == "PendingReview" ? (
									<button
										className="button is-info"
										disabled={attributes.status !== "AwaitingDocuments" && attributes.status !== "PendingReview"}
										onClick={() => showRequestDocument()}
									>
										Request Documents
									</button>
								) : null}

								<button className="button is-danger" disabled={!canDeny} onClick={() => showDeny()}>
									Deny
								</button>

								<button className="button is-success" disabled={!canApprove} onClick={() => showApprove()}>
									Approve
								</button>
							</div>
						</UnitUserOnly>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<Tags>
							<CreatedAtTag createdAt={attributes.createdAt} />
							<UpdatedAtTag updatedAt={attributes.updatedAt} />
							<ApplicationStatusTag status={attributes.status} />
							<UnitUserOnly>
								<EvaluationOutcomeTag value={attributes.evaluationOutcome} label="Individual Evaluation" />
							</UnitUserOnly>
						</Tags>
					</div>
				</div>

				<div className="columns">
					<div className="column is-4">
						<ApplicationDecision
							application={individual}
							fieldClassName={attributes.archived ? "archived-field" : ""}
						/>
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Individual Information</p>
									</div>
									<div className="card-content">
										<UnitOrBankUserOnly>
											<HorizontalField label="Org">
												<OrgName orgId={individual.relationships.org.data.id} included={included} element={"span"} />
											</HorizontalField>

											<HorizontalField label="Application Id">
												<input type="text" className="input is-static" readOnly value={individual.id} />
											</HorizontalField>
										</UnitOrBankUserOnly>
										{individual.relationships.customer ? (
											<HorizontalField label="Customer Id" fieldClassName={attributes.archived ? "archived-field" : ""}>
												<input
													type="text"
													readOnly
													value={individual.relationships.customer.data.id}
													className="input is-static"
												/>
											</HorizontalField>
										) : null}
										<Editable
											label="Name"
											isEditing={isEditing === "Full Name"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Full Name")}
											onCancel={refresh}
											setValue={setFullName}
											value={fullName}
											initial={fullName}
											update={() => {
												updateApp(accessToken, individual, {fullName: fullName})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableFullName}
										</Editable>
										<IdentificationNumber
											data={{
												initialSsn: attributes.ssn ?? attributes.maskedSSN ?? "",
												initialPassport: [
													attributes.nationality ?? "",
													attributes.passport ?? attributes.maskedPassport ?? "",
												],
												initialMatriculaConsular:
													attributes.matriculaConsular ?? attributes.maskedMatriculaConsular ?? "",
												documentTitle,
												archived,
											}}
											state={{
												isEditing,
												isEditable: isApplicationEditable,
												isUpdating,
												isHiddenIconVisible,
											}}
											actions={{
												setIsEditing,
												refresh,
												updateSSN: (ssn: string) => updateApp(accessToken, individual, {ssn}),
												updatePassport: (passport: [string, string]) =>
													updateApp(accessToken, individual, {passport: passport[1], nationality: passport[0]}),
												updateMatriculaConsular: (matriculaConsular: string) =>
													updateApp(accessToken, individual, {matriculaConsular}),
												setIsUpdating,
											}}
										/>
										<OptionalEditable
											label="Birth Date"
											isEditing={isEditing === "Birth Date"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Birth Date")}
											onCancel={refresh}
											setValue={setDob}
											value={dob}
											initial={dob}
											update={(v) => {
												updateApp(accessToken, individual, {dob: v})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableDate}
										</OptionalEditable>
										<OptionalEditable
											label="Phone"
											isEditing={isEditing === "Phone"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Phone")}
											onCancel={refresh}
											setValue={setPhone}
											value={phone}
											initial={phone}
											update={() => {
												updateApp(accessToken, individual, {phone: phone})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditablePhone}
										</OptionalEditable>
										{optionalHorizontalField(
											"Phone Location ",
											attributes.phoneLocationDetails
												? Object.values(attributes.phoneLocationDetails)
														.filter((a) => a)
														.join(", ")
												: undefined,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										{optionalHorizontalField(
											"IP Address",
											attributes.ip,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										{optionalHorizontalField(
											"IP Location",
											attributes.ipLocationDetails
												? Object.values(attributes.ipLocationDetails)
														.filter((a) => a)
														.join(", ")
												: undefined,
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										<OptionalEditable
											label="Address"
											isEditing={isEditing === "Address"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("Address")}
											onCancel={refresh}
											setValue={setAddress}
											value={address}
											initial={address}
											update={(v) => {
												updateApp(accessToken, individual, {address: v})
												setIsUpdating(true)
											}}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableAddress}
										</OptionalEditable>
										<OptionalEditable
											label="Email"
											isEditing={isEditing === "Email"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser() && isApplicationEditable}
											isUpdating={isUpdating}
											isHiddenIconVisible={isHiddenIconVisible}
											onStartEdit={() => setIsEditing("Email")}
											onCancel={refresh}
											setValue={setEmail}
											value={email}
											initial={email}
											update={() => {
												updateApp(accessToken, individual, {email: email})
												setIsUpdating(true)
											}}
											inputType="email"
											previewFunction={emailPreview}
											className={attributes.archived ? "archived-field" : ""}
										>
											{EditableHiddenString}
										</OptionalEditable>
										<OptionalEditable
											label="EIN"
											isEditing={isEditing === "EIN"}
											isDisabled={!isUndefined(isEditing)}
											isAllowed={useIsUnitUser()}
											isUpdating={isUpdating}
											onStartEdit={() => setIsEditing("EIN")}
											onCancel={refresh}
											setValue={setEin}
											value={ein}
											initial={ein}
											update={() => {
												updateApp(accessToken, individual, {ein: ein})
												setIsUpdating(true)
											}}
											pattern="^\d{9}$"
										>
											{EditableHiddenString}
										</OptionalEditable>
										{useIsUnitUser() ? (
											<Editable
												label="Doing Business As"
												isEditing={isEditing === "DBA"}
												isDisabled={!isUndefined(isEditing)}
												isAllowed={useIsUnitUser() && isApplicationEditable}
												isUpdating={isUpdating}
												onStartEdit={() => setIsEditing("DBA")}
												onCancel={refresh}
												setValue={setDba}
												value={dba}
												initial={dba}
												update={() => {
													updateApp(accessToken, individual, {dba: dba})
													setIsUpdating(true)
												}}
												pattern={"^.*$"}
											>
												{EditableOptionalString}
											</Editable>
										) : null}
										{optionalHorizontalField(
											"Sole Proprietorship",
											attributes.soleProprietorship ? "Yes" : "No",
											undefined,
											attributes.archived ? "archived-field" : ""
										)}
										<Accordion title={"Additional Information"} theme={"light"}>
											<div>
												<EditableOccupationField
													occupation={occupation}
													setOccupation={setOccupation}
													isEditing={isEditing === "Occupation"}
													isDisabled={!isUndefined(isEditing)}
													isAllowed={useIsUnitUser() && isApplicationEditable}
													onStartEdit={() => setIsEditing("Occupation")}
													onCancel={refresh}
													update={(v) => {
														updateApp(accessToken, individual, {occupation: v})
														setIsUpdating(true)
													}}
												/>
												<div>
													<EditableAnnualIncomeField
														annualIncome={annualIncome}
														setAnnualIncome={setAnnualIncome}
														isEditing={isEditing === "AnnualIncome"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={useIsUnitUser() && isApplicationEditable}
														onStartEdit={() => setIsEditing("AnnualIncome")}
														onCancel={refresh}
														update={(v) => {
															updateApp(accessToken, individual, {annualIncome: v})
															setIsUpdating(true)
														}}
													/>
													<EditableSourceOfIncomeField
														sourceOfIncome={sourceOfIncome}
														setSourceOfIncome={setSourceOfIncome}
														isEditing={isEditing === "SourceOfFunds"}
														isDisabled={!isUndefined(isEditing)}
														isAllowed={useIsUnitUser() && isApplicationEditable}
														onStartEdit={() => setIsEditing("SourceOfFunds")}
														onCancel={refresh}
														update={(v) => {
															updateApp(accessToken, individual, {sourceOfIncome: v})
															setIsUpdating(true)
														}}
													/>
												</div>
												<EditableBusinessVerticalField
													businessVertical={businessVertical}
													setBusinessVertical={setBusinessVertical}
													isEditing={isEditing === "BusinessVertical"}
													isDisabled={!isUndefined(isEditing)}
													isAllowed={useIsUnitUser() && isApplicationEditable}
													onStartEdit={() => setIsEditing("BusinessVertical")}
													onCancel={refresh}
													update={(v) => {
														updateApp(accessToken, individual, {businessVertical: v})
														setIsUpdating(true)
													}}
												/>
												<EditableWebsiteField
													website={website}
													setWebsite={setWebsite}
													isEditing={isEditing === "Website"}
													onStartEdit={() => setIsEditing("Website")}
													onCancel={refresh}
													update={(v) => {
														updateApp(accessToken, individual, {website: v})
														setIsUpdating(true)
													}}
													isArchived={archived}
													isUpdating={isUpdating}
													isDisabled={!isUndefined(isEditing)}
													isAllowed={useIsUnitUser() && isApplicationEditable}
													hasNoWebsite={hasNoWebsite}
												/>

												<EditableSolePropNumberOfEmployeesField
													numberOfEmployees={numberOfEmployees}
													setNumberOfEmployees={setNumberOfEmployees}
													isEditing={isEditing === "NumberOfEmployees"}
													isDisabled={!isUndefined(isEditing)}
													isAllowed={useIsUnitUser() && isApplicationEditable}
													onStartEdit={() => setIsEditing("NumberOfEmployees")}
													onCancel={refresh}
													update={(v) => {
														updateApp(accessToken, individual, {numberOfEmployees: v})
														setIsUpdating(true)
													}}
												/>
												<EditableSolePropAnnualRevenueField
													annualRevenue={annualRevenue}
													setAnnualRevenue={setAnnualRevenue}
													isEditing={isEditing === "AnnualRevenue"}
													isDisabled={!isUndefined(isEditing)}
													isAllowed={useIsUnitUser() && isApplicationEditable}
													onStartEdit={() => setIsEditing("AnnualRevenue")}
													onCancel={refresh}
													update={(v) => {
														updateApp(accessToken, individual, {annualRevenue: v})
														setIsUpdating(true)
													}}
												/>
											</div>
										</Accordion>
									</div>
								</div>
							</div>
						</div>
						{Object.keys(attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={attributes.tags} fieldClassName={attributes.archived ? "archived-field" : ""} />
										</div>
									</div>
								</div>
							</div>
						) : null}
					</div>
					<div className="column">
						<div className="columns">
							<div className="column">
								<div className="card has-full-height">
									<div className="card-header">
										<p className="card-header-title">Evaluation Flags</p>
									</div>
									<div className="card-content">
										<EvaluationFlags
											title="Individual"
											flags={attributes.evaluationFlags?.concat(customEvaluationFlags)}
										/>
									</div>
								</div>
							</div>

							{evaluationScores && Object.keys(Object.keys(evaluationScores)).length > 0 ? (
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title">Evaluation Scores</p>
										</div>
										<div className="card-content">
											<EvaluationScores scores={evaluationScores} />
										</div>
									</div>
								</div>
							) : null}
						</div>

						<ApplicationDocuments application={individual} documents={documents} refresh={refresh} />
					</div>
				</div>
			</MainSection>
		</>
	)
}

export default function IndividualApplicationPage({
	value,
	refresh,
}: {
	value: OkDocument<IndividualApplication>
	refresh: () => void
}) {
	const customEvaluationFlags: string[] = []
	if (value.data.attributes.phoneLocationDetails && value.data.attributes.address) {
		customEvaluationFlags.push(
			evaluateApplicationLocation(value.data.attributes.address, value.data.attributes.phoneLocationDetails, "Phone")
		)
	}

	if (value.data.attributes.ipLocationDetails && value.data.attributes.address) {
		customEvaluationFlags.push(
			evaluateApplicationLocation(value.data.attributes.address, value.data.attributes.ipLocationDetails, "IP")
		)
	}

	return (
		<IndividualApplicationInternal
			individual={value.data}
			documents={value.included?.filter(isApplicationDocument) || []}
			refresh={refresh}
			customEvaluationFlags={customEvaluationFlags}
			included={value.included}
		/>
	)
}
