import {getFile, getResource} from "./common"

export type AccountStatement = {
	id: string
	attributes: {
		period: string
	}
	relationships: {
		account: {
			data: {
				type: "account"
				id: number
			}
		}
		customer?: {
			data: {
				type: "customer"
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
	}
}

export async function getAccountStatements(accessToken: string, offset: number, limit: number, accountId: string) {
	return findStatements(accessToken, offset, limit, undefined, accountId)
}

export async function findStatements(
	accessToken: string,
	offset: number,
	limit: number,
	customerId?: string,
	accountId?: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			customerId,
			accountId,
		},
		sort: "-period",
	}

	const result = await getResource<Array<AccountStatement>>("statements", accessToken, query)

	return result.map((v) => v.data)
}

export async function getStatementHtml(accessToken: string, id: string, customerId?: string) {
	const query = customerId
		? {
				filter: {
					customerId: customerId,
				},
		  }
		: {}

	return await getFile(`statements/${id}/html`, accessToken, query, "text/html")
}

export async function getStatementPdf(accessToken: string, id: string, customerId?: string) {
	const query = customerId
		? {
				filter: {
					customerId: customerId,
				},
		  }
		: {}

	return await getFile(`statements/${id}/pdf`, accessToken, query, "application/pdf", "blob")
}

export async function getBankVerificationPdf(accessToken: string, id: string, includeProofOfFunds = false) {
	return await getFile(
		`statements/${id}/bank/pdf`,
		accessToken,
		{includeProofOfFunds: includeProofOfFunds},
		"application/pdf",
		"blob"
	)
}
