import React, {InputHTMLAttributes, Dispatch, PropsWithChildren} from "react"
import {countries} from "../../resources/countries"

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
	value: string
	onChange: Dispatch<any>
}

export default function CountryDropdown({value, onChange, className}: PropsWithChildren<Props>) {
	return (
		<select className={className} value={value} onChange={onChange}>
			{Object.keys(countries).map((country) => (
				<option key={country} value={country}>
					{countries[country]}
				</option>
			))}
		</select>
	)
}
