import React, {DependencyList, useEffect} from "react"

const resetScrollTopAndHideScrollbar = (contentRef: React.RefObject<HTMLElement>) => {
	let timeoutId: number | undefined
	if (contentRef.current) {
		contentRef.current.style.overflowY = "hidden"

		timeoutId = window.setTimeout(() => {
			if (contentRef.current) {
				contentRef.current.scrollTop = 0
				contentRef.current.style.overflowY = "auto"
			}
		}, 0)
	}
	return timeoutId
}
export function useResetScrollTop(ref: React.RefObject<HTMLElement>, deps: DependencyList = []) {
	useEffect(() => {
		const timeoutId = resetScrollTopAndHideScrollbar(ref)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [ref, ...deps])
}
