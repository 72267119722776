import React, {ImgHTMLAttributes} from "react"
import classNames from "classnames"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"

export interface AvatarProps {
	src?: ImgHTMLAttributes<HTMLImageElement>["src"]
	alt?: ImgHTMLAttributes<HTMLImageElement>["alt"]
	icon?: IcomoonIconName
	size?: number
	borderRadius?: number
	name: string
}

const getInitials = (str: string) => {
	if (!str) {
		return null
	}
	return str.match(/[A-Z]/g)?.join("")
}
export default function Avatar({src, alt, name, icon, borderRadius = 50, size = 62}: AvatarProps) {
	const avatarDescription = alt ?? `${name}'s avatar`
	const nameInitials = getInitials(name) ?? ""
	let avatarToDisplay: React.ReactElement | string = nameInitials.length > 2 ? nameInitials[0] : nameInitials

	if (src) {
		avatarToDisplay = <img src={src} alt={avatarDescription} />
	} else if (icon) {
		avatarToDisplay = <Icon size={24} icon={icon} />
	}

	return (
		<div
			className="avatar-container"
			style={{"--border-radius": `${borderRadius}%`, "--avatar-size": `${size}px`} as React.CSSProperties}
		>
			<div className={classNames("avatar-content", src && "image-avatar", icon && "icon-avatar")}>
				{avatarToDisplay}
			</div>
		</div>
	)
}
