import React, {useEffect} from "react"
import NavbarUserDropdown from "./NavbarUserDropdown"
import NavbarDeveloperDropdown from "./NavbarDeveloperDropdown"
import {useIsOrgUnitPilot, useUserClaimsData} from "../../services/auth"
import {useLocalStorage, useLocation} from "react-use"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {isSandboxOrLocalEnv} from "../../utilities/environment"
import {Link} from "react-router-dom"

function SandboxBanner({isOpen}: {isOpen: boolean}) {
	return (
		<a
			href="https://www.unit.co/book-demo"
			target="_blank"
			rel="noreferrer"
			data-tracking-label="book-1-on-1"
			className={classNames(isOpen && "open")}
		>
			{"Want some help getting started? "}
			Book 1:1 time with Unit →
		</a>
	)
}

function PilotBanner({isOpen}: {isOpen: boolean}) {
	return (
		<Link to="/pilot-overview" className={classNames(isOpen && "open")}>
			{"You're in Pilot, where you can test our core features using real funds and accounts."} Learn more →
		</Link>
	)
}

function AnnouncementBanner() {
	return (
		<a
			href="https://www.unit.co/a-note-from-our-ceo"
			target="_blank"
			rel="noreferrer"
			data-tracking-label="message-from-unit-on-svb"
		>
			<Icon icon={"email-envelope-1--emails"} size={16} className="letter-icon" />A message from our CEO on SVB and
			upcoming resources. Read more here. →
		</a>
	)
}

function NavbarBanner({type, isAlwaysOpen}: {type: "sandbox" | "pilot" | "announcement"; isAlwaysOpen: boolean}) {
	const [isOpen, setIsOpen] = isAlwaysOpen ? [true, () => null] : useLocalStorage("showNavbarAnnouncementBanner", true)

	useEffect(() => {
		if (isOpen) {
			document.documentElement.style.setProperty("--navbar-height", "92px")
		}

		return () => {
			// on unmount
			document.documentElement.style.setProperty("--navbar-height", "60px")
		}
	}, [isOpen])

	if (!isOpen) {
		return null
	}

	let bannerComponent
	switch (type) {
		case "sandbox": {
			bannerComponent = <SandboxBanner isOpen={isOpen} />
			break
		}
		case "pilot": {
			bannerComponent = <PilotBanner isOpen={isOpen} />
			break
		}
		case "announcement": {
			bannerComponent = <AnnouncementBanner />
			break
		}
	}

	return (
		<div className={classNames("navbar-banner-wrapper", type, isOpen && "open")}>
			{bannerComponent}

			{!isAlwaysOpen ? (
				<span className="close-button" onClick={() => setIsOpen(false)}>
					<Icon icon={"interface-delete-interface-essential"} size={12} color="#808285" />
				</span>
			) : null}
		</div>
	)
}

function NavbarLaunchNotesDropdown() {
	const userTokenData = useUserClaimsData()

	useEffect(() => {
		// Workaround for adding launchnotes to our body, we can't do this on index.html since parcel wants "type=module"
		// scripts to be local ones only
		const script = document.createElement("script")
		script.src = "https://embed.launchnotes.io/0.3.6/dist/esm/launchnotes-embed.js"
		script.async = true
		script.type = "module"
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<>
			<div className="navbar-launchnotes-dropdown">
				<Icon icon="flash-enable-allow-1--interface-essential" className="navbar-launchnotes-dropdown-trigger" />
				<launchnotes-embed
					token="public_nah4Lc2WGzMnmzRk9fzFodsr"
					project="pro_D5rDIT9v8FB40"
					toggle-selector=".navbar-launchnotes-dropdown-trigger"
					heading="Product Updates"
					heading-color="#000000"
					subheading-color="rgba(0, 0, 0, 0.8)"
					primary-color="#000000"
					widget-placement="auto-start"
					widget-offset-skidding="40"
					anonymous-user-id={userTokenData.userId}
					widget-offset-distance="-20"
					show-unread="true"
					unread-placement="right-start"
					unread-offset-skidding="-4"
					unread-offset-distance="-4"
					unread-background-color="#FF506F"
					unread-text-color="#ffffff"
				/>
			</div>
		</>
	)
}

export default function Navbar() {
	const isHomePage = useLocation()?.pathname === "/"
	const isOrgUnitPilot = useIsOrgUnitPilot()
	const showPilotBanner = window.location.pathname != "/pilot-overview" && isOrgUnitPilot
	const showSandboxBanner = isSandboxOrLocalEnv() && !isOrgUnitPilot
	// const showAnnouncementBanner = true

	return (
		<header>
			<div className={classNames("top-nav-container-wrapper")}>
				<div className={classNames("top-nav-container", {"top-nav-container--unfixed": isHomePage})}>
					{showPilotBanner && <NavbarBanner type="pilot" isAlwaysOpen={true} />}
					{showSandboxBanner && <NavbarBanner type="sandbox" isAlwaysOpen={false} />}
					{/*{showAnnouncementBanner && <NavbarBanner type={"announcement"} isAlwaysOpen={false}/>}*/}

					<nav className={classNames("navbar", {"is-homepage": isHomePage})}>
						<NavbarDeveloperDropdown />
						<NavbarLaunchNotesDropdown />
						<NavbarUserDropdown />
					</nav>
				</div>
			</div>
		</header>
	)
}
