import {Result} from "neverthrow"
import {DependencyList, useCallback, useRef, useState} from "react"
import {AsyncResultIdleRequestState, AsyncResultIdle} from "../types/asyncResultIdle"
import {useMountedState} from "react-use"

export default function useAsyncResultIdle<T, E, Args extends any[]>(
	fn: (...args: Args) => Promise<Result<T, E>>,
	deps: DependencyList = []
): [AsyncResultIdleRequestState<T, E>, (...args: Args) => Promise<Result<T, E>>, () => void] {
	const [state, set] = useState<AsyncResultIdleRequestState<T, E>>(AsyncResultIdle.idle)
	const lastCallId = useRef(0)

	const isMounted = useMountedState()

	const callback = useCallback((...args: Args) => {
		const callId = ++lastCallId.current
		set(AsyncResultIdle.pending())

		return fn(...args).then((result) => {
			result.match(
				(value) => isMounted() && callId === lastCallId.current && set(AsyncResultIdle.ok(value)),
				(err) => isMounted() && callId === lastCallId.current && set(AsyncResultIdle.err(err))
			)

			return result
		})
	}, deps)

	return [state, callback, () => set(AsyncResultIdle.idle())]
}
