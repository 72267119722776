import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {approveLinkedAccount, LinkedAccount} from "../../resources/linkedAccount"

interface ApproveLinkedAccountModalProps {
	linkedAccount: LinkedAccount
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ApproveLinkedAccountModal({linkedAccount, close, onSuccess}: ApproveLinkedAccountModalProps) {
	const accessToken = useAccessToken()
	const [state, approve] = useAsyncResultIdle(approveLinkedAccount)

	return (
		<AsyncResultModal
			title="Approve Linked Account"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSubmit={() => approve(accessToken, linkedAccount.id)}
			errorToText={(err) => err.errors[0].title}
		>
			Are you sure?
		</AsyncResultModal>
	)
}
