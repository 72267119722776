import React, {RefObject, useEffect, useRef} from "react"
import {AssignmentNavigatorItemProps} from "../AssignmentNavigatorItem/AssignmentNavigatorItem"
import {AssignmentNavigatorGroupedByStage} from "../AssignmentNavigatorWithGrouping/AssignmentNavigatorGroupedByStage"
import {
	AssignmentItem,
	AssignmentItemCommonProps,
	AssignmentItemId,
	AssignmentItemPayload,
	AssignmentStage,
	AssignmentType,
} from "../../resources/assignments"
import {AssignmentNavigatorItemsList} from "../AssignmentNavigatorItemsList/AssignmentNavigatorItemsList"

type AssignmentsGroupBy = "none" | "stage"

interface AssignmentNavigatorProps {
	assignments: AssignmentItem[]
	groupBy?: AssignmentsGroupBy
	selectedId?: AssignmentItemId
	onClick: (assignmentItemId: AssignmentItemId) => void
	navigatorRef: RefObject<HTMLDivElement>
	className?: string
}

export interface CombinedAssignmentItem extends AssignmentNavigatorItemProps, AssignmentItemCommonProps {
	type: AssignmentType
	payload?: AssignmentItemPayload
}

const getGroupByLabel = (groupBy: AssignmentsGroupBy) => {
	switch (groupBy) {
		case "stage":
			return "Item Status"
		case "none":
			return "-"
	}
}

const autoScrollToSelectedAssignment = (
	navigatorRef: RefObject<HTMLDivElement>,
	navigatorItemRef: RefObject<HTMLLIElement>
) => {
	const timeoutId = setTimeout(() => {
		if (!navigatorRef.current) {
			return
		}
		navigatorRef.current.style.overflowY = "hidden"
		if (navigatorItemRef.current) {
			navigatorItemRef.current.scrollIntoView({block: "center"})
		}
		navigatorRef.current.style.overflowY = "auto"
	}, 0)
	return () => clearTimeout(timeoutId)
}
export function AssignmentNavigator({
	assignments,
	groupBy = "none",
	selectedId,
	onClick,
	navigatorRef,
	className,
}: AssignmentNavigatorProps) {
	const navigatorItemRef = useRef<HTMLLIElement>(null)

	useEffect(() => autoScrollToSelectedAssignment(navigatorRef, navigatorItemRef), [])

	const assignmentNavigatorItems: CombinedAssignmentItem[] = assignments.map((item) => ({
		...item,
		disabled: item.stage === AssignmentStage.Completed,
		selected: selectedId === item.id,
		onClick: () => onClick(item.id),
	}))

	const renderAssignments = () => {
		switch (groupBy) {
			case "stage":
				return (
					<AssignmentNavigatorGroupedByStage
						assignments={assignmentNavigatorItems}
						navigatorItemRef={navigatorItemRef}
					/>
				)
			case "none":
				return (
					<AssignmentNavigatorItemsList assignments={assignmentNavigatorItems} navigatorItemRef={navigatorItemRef} />
				)
			default:
				return null
		}
	}

	return (
		<div className={className}>
			{groupBy !== "none" && <div className="assignment-navigator-group-by">Group by ({getGroupByLabel(groupBy)})</div>}
			<div className="assignment-navigator-items">{renderAssignments()}</div>
		</div>
	)
}
