import {isBankUser, isUnitUser, useAccessToken, useUserType} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import {DepositProduct, findDepositProducts} from "../../resources/depositProduct"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {ReactElement, useEffect} from "react"
import moment from "moment"
import {OrgName} from "../OrgName/OrgName"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {useNavigate} from "react-router-dom"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {useQueryState} from "use-location-state"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {Filter} from "../Filter/Filter"
import SearchBox from "../../components/SearchBox/SearchBox"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import classNames from "classnames"
import {BankName} from "../BankName/BankName"

export enum DepositProductsColumns {
	id = "Id",
	status = "Status",
	org = "Org",
	name = "Name",
	bank = "Bank",
	createdAt = "Created At",
}

export type AllowedDepositProductsColumns =
	| DepositProductsColumns.id
	| DepositProductsColumns.status
	| DepositProductsColumns.org
	| DepositProductsColumns.name
	| DepositProductsColumns.bank
	| DepositProductsColumns.createdAt

interface DepositProductsProps {
	token?: string
	limit?: number
	includedColumns: AllowedDepositProductsColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	enableOrgFilter?: boolean
}

interface DepositProductsTableProps {
	depositProducts: DepositProduct[]
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedDepositProductsColumns[]
	meta?: Meta
	refresh?: () => void
	fullHeight?: boolean
}

interface DepositProductsRowProps {
	depositProduct: DepositProduct
	include?: Resource[]
	showOrgColumn?: boolean
	includedColumns: Array<AllowedDepositProductsColumns>
	refresh?: () => void
}

function DepositProductRow({depositProduct, include, includedColumns}: DepositProductsRowProps) {
	const navigate = useNavigate()

	const contentColumns: Partial<Record<AllowedDepositProductsColumns, ReactElement>> = {}

	if (includedColumns.includes(DepositProductsColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {depositProduct.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(DepositProductsColumns.status) && depositProduct.attributes.status == "Disabled") {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className="deposit-products-status"> {depositProduct.attributes.status} </div>
			</DataTableCell>
		)
	} else {
		contentColumns["Status"] = <DataTableCell> </DataTableCell>
	}

	if (includedColumns.includes(DepositProductsColumns.org)) {
		if (depositProduct?.relationships?.org) {
			contentColumns["Org"] = <OrgName orgId={depositProduct.relationships.org.data.id} included={include} />
		} else {
			contentColumns["Org"] = <DataTableCell> -- </DataTableCell>
		}
	}

	if (includedColumns.includes(DepositProductsColumns.name)) {
		contentColumns["Name"] = <DataTableCell>{depositProduct.attributes.name}</DataTableCell>
	}

	if (includedColumns.includes(DepositProductsColumns.bank)) {
		if (depositProduct?.relationships?.bank) {
			contentColumns["Bank"] = (
				<DataTableCell>
					<BankName bankId={depositProduct.relationships.bank.data.id} included={include} />
				</DataTableCell>
			)
		} else {
			contentColumns["Bank"] = <DataTableCell> -- </DataTableCell>
		}
	}

	if (includedColumns.includes(DepositProductsColumns.createdAt)) {
		contentColumns["Created At"] = (
			<DataTableCell>{moment(depositProduct.attributes.createdAt).format("L")}</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				navigate(`/deposit-products/${depositProduct.id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

export function DepositProductsTable({
	depositProducts,
	fullHeight,
	includedColumns,
	hasNext,
	hasPrev,
	hasResults,
	isUsingPaging,
	meta,
	prev,
	next,
	include,
}: DepositProductsTableProps) {
	const noContent = depositProducts.length === 0
	return (
		<div className={"deposit-products-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={"No deposit products found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{depositProducts.map((p) => (
						<DepositProductRow key={p.id} include={include} includedColumns={includedColumns} depositProduct={p} />
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export default function DepositProducts({limit = 25, enableTitle, fullHeight, includedColumns}: DepositProductsProps) {
	const accessToken = useAccessToken()
	const userType = useUserType()
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>("filter[orgs]", [])
	const [query, setQuery] = useQueryState("filter[query]", "")
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) => findDepositProducts(accessToken, offset, limit, filteredOrgs, query),
		(x) => x.data.length,
		[filteredOrgs.join(","), query]
	)

	const orgs = useAsyncResult(() =>
		isUnitUser(userType) || isBankUser(userType)
			? findOrgs(accessToken, 0, 10000)
			: new Promise(() => AsyncResult.pending<Org[], ErrorDocument>())
	)

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs Filter"
				isSearchable
				setStatuses={setFilteredOrgs}
				onFilterFunc={() => reset(limit)}
				statuses={filteredOrgs}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Deposit Products", 500, onSearch)
	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	return (
		<DataTableCard className={"payments-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={searchBox}
				filters={[orgFilter]}
				title={enableTitle ? "Deposit Products" : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: depositProducts}) => {
					return (
						<DepositProductsTable
							depositProducts={depositProducts.data}
							meta={depositProducts.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={true}
							include={depositProducts.included}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
