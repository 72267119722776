import React from "react"
import {useAccessToken} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {approveCheckPayment} from "../../resources/checkPayments"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import Admonition from "../Admonitions/Admonition"

interface ApproveCheckPaymentModalProps {
	id: string
	closeModal: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ApproveCheckPaymentModal({id, closeModal, onSuccess}: ApproveCheckPaymentModalProps) {
	const accessToken = useAccessToken()
	const [approveState, setApprovedState] = useAsyncResultIdle(approveCheckPayment)

	return (
		<AsyncResultModal
			title={`You Are About To Approve Check ID #${id}`}
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-success"
			state={approveState}
			buttonText="Approve"
			successText="Check is approved"
			onSubmit={() => {
				setApprovedState({id, accessToken})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<Admonition
				type="is-warning"
				message={<p>Approving a check is a final action and cannot be undone.</p>}
				title="Note"
			/>
		</AsyncResultModal>
	)
}
