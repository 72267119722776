import React from "react"
import Textfield from "../Address/Textfield"

interface Props {
	value: string
	handleChange: any
}

export default function PhoneNumber(props: Props) {
	const {value, handleChange} = props

	return (
		<div className="phone-number-container">
			<Textfield
				placeholder="Add Country code"
				label="Phone Number"
				name="phone"
				value={value}
				onChange={handleChange}
				onBlur={() => null}
			/>
		</div>
	)
}
