import * as Yup from "yup"
import {ParseError, parsePhoneNumberFromString} from "libphonenumber-js"
import {US_COUNTRY_CODE} from "../resources/address"

export function escapeRegExp(string: string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export function addCustomValidationMethods() {
	Yup.addMethod(Yup.string, "phoneNumber", function (message) {
		return this.test("phoneNumberTest", message, function (value) {
			let isError = false
			let phoneNumber

			try {
				isError = false
				const phone = value ?? ""

				phoneNumber = parsePhoneNumberFromString(phone, {
					defaultCountry: US_COUNTRY_CODE,
				})
			} catch (error) {
				if (error instanceof ParseError) {
					isError = true
				}

				console.log(error)
			}

			if (typeof phoneNumber === "undefined") {
				return false
			}

			return !isError && phoneNumber.isValid()
		})
	})
}

export const countryRegex =
	"^(A(D|E|F|G|I|L|M|N|O|R|S|T|Q|U|W|X|Z)|B(A|B|D|E|F|G|H|I|J|L|M|N|O|Q|R|S|T|V|W|Y|Z)|C(A|C|D|F|G|H|I|K|L|M|N|O|R|U|V|W|X|Y|Z)|D(E|J|K|M|O|Z)|E(C|E|G|H|R|S|T)|F(I|J|K|M|O|R)|G(A|B|D|E|F|G|H|I|L|M|N|P|Q|R|S|T|U|W|Y)|H(K|M|N|R|T|U)|I(D|E|Q|L|M|N|O|R|S|T)|J(E|M|O|P)|K(E|G|H|I|M|N|P|R|W|Y|Z)|L(A|B|C|I|K|R|S|T|U|V|Y)|M(A|C|D|E|F|G|H|K|L|M|N|O|Q|P|R|S|T|U|V|W|X|Y|Z)|N(A|C|E|F|G|I|L|O|P|R|U|Z)|OM|P(A|E|F|G|H|K|L|M|N|R|S|T|W|Y)|QA|R(E|O|S|U|W)|S(A|B|C|D|E|G|H|I|J|K|L|M|N|O|R|T|V|X|Y|Z)|T(C|D|F|G|H|J|K|L|M|N|O|R|T|V|W|Z)|U(A|G|M|S|Y|Z)|V(A|C|E|G|I|N|U)|W(F|S)|XK|Y(E|T)|Z(A|M|W))$"
export const stateRegex =
	"^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$"
export const ssnRegex = /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/
