import React from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {AccountType} from "../../resources/account"
import {InterestRate} from "../../components/InterestRate/InterestRate"
import {useIsUnitUser} from "../../services/auth"

export default function UnitDashboard() {
	return (
		<>
			<TitleBar title={"Home"} className={"is-static"} />
			<MainSection>
				<div className="columns">
					<div className="column">
						<Accounts
							limit={10}
							fullHeight={false}
							enableTitle={true}
							includedColumns={[
								AccountsColumns.id,
								...(useIsUnitUser() ? [AccountsColumns.bank] : []),
								AccountsColumns.name,
								AccountsColumns.balance,
								AccountsColumns.status,
								AccountsColumns.createdAt,
							]}
							type={AccountType.gl}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<InterestRate />
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<Transactions
							fullHeight={false}
							limit={20}
							enableSearch={true}
							excludeGlTransaction={true}
							enableDirectionFilter={true}
							enableOrgFilter={true}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.org,
								TransactionsColumns.accountId,
								TransactionsColumns.customer,
								TransactionsColumns.type,
								TransactionsColumns.summary,
								TransactionsColumns.balance,
								TransactionsColumns.amount,
								TransactionsColumns.createdAt,
							]}
						/>
					</div>
				</div>
			</MainSection>
		</>
	)
}
