import React, {useRef} from "react"
import {Assignment} from "../../resources/assignments"
import {Meta, Resource} from "../../resources/common"
import {AllowedAssignmentsHubColumns} from "./AssignmentsHub"
import {
	DataTable,
	DataTableBody,
	DataTableCell,
	DataTableHead,
	DataTableRow,
} from "../../components/DataTable/DataTable"
import {Claims, useUserClaimsData} from "../../services/auth"
import {AssignmentRow} from "./AssignmentRow"
import {useNavigate} from "react-router-dom"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"

interface AssignmentsTableProps {
	assignments: Assignment[]
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedAssignmentsHubColumns[]
	meta?: Meta
	refresh: () => void
	fullHeight?: boolean
	token?: string
	claims?: Claims
	isUnitUser: boolean
}

export function AssignmentsTable({
	assignments,
	fullHeight,
	includedColumns,
	include,
	refresh,
	token,
	claims,
	isUnitUser,
	isUsingPaging,
	meta,
	hasResults,
	hasPrev,
	hasNext,
	next,
	prev,
}: AssignmentsTableProps) {
	const userClaims = useUserClaimsData(claims)
	const noContent = assignments.length === 0
	const ref = useRef(null)
	const navigate = useNavigate()
	const onRowClick = (e: React.MouseEvent<HTMLTableRowElement>, redirectLink: string) => {
		navigate(redirectLink, {state: {previousPage: location.pathname + location.hash}})
	}

	return (
		<div ref={ref} className="assignments-hub-table">
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				stickyActionWithTabsOnHeader
				noContentText={"No assignments found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{assignments.map((assignment) => (
						<AssignmentRow
							assignment={assignment}
							key={assignment.id}
							includedColumns={includedColumns}
							include={include}
							refresh={refresh}
							claims={userClaims}
							isUnitUser={isUnitUser}
							accessToken={token}
							onClick={(e, redirectLink) => onRowClick(e, redirectLink)}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}
