const names = [
	{first: "Susan", last: "Wheeler"},
	{first: "Sharon", last: "Brown"},
	{first: "Michael", last: "Hernandez"},
	{first: "Kevin", last: "Simon"},
	{first: "Adrienne", last: "Anderson"},
	{first: "Adam", last: "Garza"},
	{first: "Christopher", last: "Bullock"},
	{first: "David", last: "Scott"},
	{first: "Cathy", last: "Blair"},
	{first: "Bruce", last: "Cook"},
	{first: "Tracy", last: "Dennis"},
	{first: "Cheryl", last: "Mercado"},
	{first: "Justin", last: "Hardin"},
	{first: "Ashley", last: "Lopez"},
	{first: "Natasha", last: "Fernandez"},
	{first: "Catherine", last: "Green"},
	{first: "Cynthia", last: "Myers"},
	{first: "Daniel", last: "Taylor"},
	{first: "Linda", last: "Schultz"},
	{first: "Gregory", last: "Duke"},
	{first: "James", last: "Murphy"},
	{first: "Megan", last: "Wilkins"},
	{first: "Julia", last: "Wilkerson"},
	{first: "Jillian", last: "Morgan"},
	{first: "David", last: "Cook"},
	{first: "Ryan", last: "Huerta"},
	{first: "Rebecca", last: "Webb"},
	{first: "Melinda", last: "Hernandez"},
	{first: "David", last: "Kramer"},
	{first: "Jodi", last: "Schmidt"},
	{first: "Derrick", last: "Parker"},
	{first: "Carl", last: "Gillespie"},
	{first: "Alicia", last: "Burns"},
	{first: "Melissa", last: "Johnson"},
	{first: "Matthew", last: "Thomas"},
	{first: "Benjamin", last: "Gay"},
	{first: "Arthur", last: "Powers"},
	{first: "Rachel", last: "Bullock"},
	{first: "Tamara", last: "Anderson"},
	{first: "Elizabeth", last: "Bailey"},
	{first: "Michelle", last: "Waller"},
	{first: "Tara", last: "Wilson"},
	{first: "Richard", last: "Johnston"},
	{first: "Karina", last: "Vaughan"},
	{first: "Mary", last: "Smith"},
	{first: "Haley", last: "Johnson"},
	{first: "Wanda", last: "Rosales"},
	{first: "Mason", last: "Blankenship"},
	{first: "Derrick", last: "Fowler"},
	{first: "Rachel", last: "Williams"},
]

const addresses = [
	{street: "33 Liberty Street", city: "Santa Cruz", state: "CA", postalCode: "95060"},
	{street: "8785 Ashley Street", city: "Quakertown", state: "PA", postalCode: "18951"},
	{street: "55 Spring Lane", city: "Independence", state: "KY", postalCode: "41051"},
	{street: "9421 Morris Road", city: "Douglasville", state: "GA", postalCode: "30134"},
	{street: "8609 Woodsman Ave.", city: "Chicopee", state: "MA", postalCode: "01020"},
	{street: "45 Lafayette St.", city: "Elizabeth", state: "NJ", postalCode: "07202"},
	{street: "394 Buttonwood Street", city: "West Des Moines", state: "IA", postalCode: "50265"},
	{street: "21 San Pablo Street", city: "Springboro", state: "OH", postalCode: "45066"},
	{street: "60 Canal St.", city: "La Crosse", state: "WI", postalCode: "54601"},
	{street: "26 Cardinal Dr.", city: "Cedar Falls", state: "IA", postalCode: "50613"},
	{street: "322 Williams St.", city: "Apex", state: "NC", postalCode: "27502"},
	{street: "321 Wall Dr.", city: "Lewis Center", state: "OH", postalCode: "43035"},
]

const birthdays = [
	"1946-04-11",
	"1946-06-28",
	"1947-01-19",
	"1947-05-08",
	"1947-09-30",
	"1951-06-28",
	"1956-03-31",
	"1958-05-06",
	"1958-05-11",
	"1958-07-03",
	"1960-11-08",
	"1962-05-03",
	"1968-02-21",
	"1968-03-01",
	"1969-10-06",
	"1970-07-04",
	"1971-06-20",
	"1972-12-27",
	"1977-01-26",
	"1977-07-01",
	"1978-08-24",
	"1981-12-03",
	"1982-02-23",
	"1985-03-18",
	"1987-11-03",
]

const phones = [
	"9176277108",
	"2252124876",
	"7083277934",
	"9142737758",
	"7147251139",
	"6059856996",
	"6262855435",
	"4025156413",
	"7166005119",
	"5807029867",
	"5124777925",
	"6628257748",
	"7876668885",
	"9286377813",
	"5805193589",
	"6168489024",
	"3476042441",
	"7023223090",
	"4122730168",
	"7194999068",
	"5409258536",
	"3124673507",
	"6609870860",
	"5056909951",
	"2812023936",
	"6176590270",
	"4048774483",
	"7863644523",
]

export function generateCreateApplicationRequest() {
	const name = names[Math.floor(Math.random() * names.length)]
	const address = addresses[Math.floor(Math.random() * addresses.length)]
	const dob = birthdays[Math.floor(Math.random() * birthdays.length)]
	const phone = phones[Math.floor(Math.random() * phones.length)]

	const request = {
		data: {
			type: "individualApplication",
			attributes: {
				ssn: randomSSN(),
				fullName: {
					first: name.first,
					last: name.last,
				},
				dateOfBirth: dob,
				address: {
					street: address.street,
					city: address.city,
					state: address.state,
					postalCode: address.postalCode,
					country: "US",
				},
				email: `${name.first.toLocaleLowerCase()}.${name.last.toLocaleLowerCase()}@mymail.com`,
				phone: {
					countryCode: "1",
					number: phone,
				},
				occupation: "Doctor",
				tags: {
					test: "webhook-tag",
					key: "another-tag",
					number: "111",
				},
			},
		},
	}
	return JSON.stringify(request)
}

const purposes = ["savings", "taxes", "spending", "money market"]

export function generateCreateAccountRequest(id: string) {
	const purpose = purposes[Math.floor(Math.random() * purposes.length)]

	const request = {
		data: {
			type: "depositAccount",
			attributes: {
				depositProduct: "checking",
				tags: {
					purpose,
				},
			},
			relationships: {
				customer: {
					data: {
						type: "customer",
						id: id,
					},
				},
			},
		},
	}
	return JSON.stringify(request)
}

const achs = [
	{amount: 780000, description: "Monthly Salary"},
	{amount: 12000, description: "Birthday Present from Grandma"},
	{amount: 4300, description: "Split Dinner"},
	{amount: 100000, description: "Annual Bonus"},
	{amount: 8000, description: "Reimbusement for gas"},
	{amount: 1900, description: "Reimbusement for pizza"},
]

export function generateIncomingAchRequest(accountId: string) {
	const ach = achs[Math.floor(Math.random() * achs.length)]

	const request = {
		data: {
			type: "achPayment",
			attributes: {
				amount: ach.amount,
				direction: "Credit",
				description: ach.description,
			},
			relationships: {
				account: {
					data: {
						type: "depositAccount",
						id: accountId,
					},
				},
			},
		},
	}
	return JSON.stringify(request)
}

export function generateCreateCardRequest(accountId: string) {
	const address = addresses[Math.floor(Math.random() * addresses.length)]
	const request = {
		data: {
			type: "individualDebitCard",
			attributes: {
				shippingAddress: {
					street: address.street,
					city: address.city,
					state: address.state,
					postalCode: address.postalCode,
					country: "US",
				},
			},
			relationships: {
				account: {
					data: {
						type: "depositAccount",
						id: accountId,
					},
				},
			},
		},
	}
	return JSON.stringify(request)
}

function randomSSN() {
	return Math.floor(100000000 + Math.random() * 900000000).toString()
}
