import {useAccessToken, useUserType} from "../../services/auth"
import {useParams} from "react-router-dom"
import React from "react"
import {useRefreshToken} from "../../hooks/useRefreshToken"

import {useAsyncResult} from "../../hooks/useAsyncResult"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {kebabCase, startCase} from "lodash"
import classNames from "classnames"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"
import {getIncomingCheckImage, getIncomingCheckRequest, IncomingCheckImageSide} from "../../resources/incomingCheck"
import {getButtonsToShow, IncomingCheckInfo} from "../../components/IncomingChecks/IncomingCheckInfo"
import {useModal} from "react-modal-hook"
import {ApproveCheckModal} from "../../components/IncomingChecks/ApproveCheckModal"
import {ReturnCheckModal} from "../../components/IncomingChecks/ReturnCheckModal"
import Icon from "../../components/Icon/Icon"
import {Customer, CustomerResourceTypes} from "../../resources/customer"
import {Account} from "../../resources/account"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {Org} from "../../resources/org"
import {CheckImageCard} from "../../components/CheckDeposits/CheckDepositInfo"

type ActionButtons = Array<"Approve" | "Return"> | null

export function IncomingCheckActionButtons({
	id,
	refresh,
	buttons,
	disabled,
	title,
}: {
	id: string
	refresh: () => void
	buttons: ActionButtons
	disabled: boolean
	title: string
}) {
	if (!buttons) return null

	const [showApproveCheck, hideApproveCheck] = useModal(() => (
		<ApproveCheckModal closeModal={hideApproveCheck} id={id} onSuccess={refresh} />
	))

	const [showReturnCheck, hideReturnCheck] = useModal(() => (
		<ReturnCheckModal closeModal={hideReturnCheck} id={id} onSuccess={refresh} />
	))

	return (
		<>
			{buttons.includes("Approve") && (
				<button className="button is-approved" onClick={() => showApproveCheck()} disabled={disabled} title={title}>
					<span className="icon">
						<Icon icon="interface-validation-check--interface-essential" size={12} />
					</span>
					<span> Approve </span>
				</button>
			)}

			{buttons.includes("Return") && (
				<button className="button is-danger" onClick={() => showReturnCheck()} disabled={disabled} title={title}>
					<span className="icon">
						<Icon icon="interface-validation-check--interface-essential" size={12} />
					</span>
					<span> return </span>
				</button>
			)}
		</>
	)
}

export default function IncomingCheckPage() {
	const accessToken = useAccessToken()
	const {incomingCheckId = ""} = useParams<{incomingCheckId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const getIncomingCheck = useAsyncResult(
		() => getIncomingCheckRequest({accessToken, id: incomingCheckId, include: "customer, account, org"}),
		[refreshToken, incomingCheckId]
	)
	const frontImageState = useAsyncResult(() =>
		getIncomingCheckImage({accessToken, id: incomingCheckId, side: IncomingCheckImageSide.Front})
	)
	const backImageState = useAsyncResult(() =>
		getIncomingCheckImage({accessToken, id: incomingCheckId, side: IncomingCheckImageSide.Back})
	)
	const frontImage = frontImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)
	const backImage = backImageState.match(
		() => undefined,
		(image) => image,
		() => null
	)

	return (
		<div className="incoming-check-full-page">
			<AsyncResultComponent asyncResult={getIncomingCheck}>
				{({value: incomingCheckResponse}) => {
					const incomingCheck = incomingCheckResponse.data
					const accountId = incomingCheck.relationships.account?.data.id
					const customerId = incomingCheck.relationships.customer?.data.id
					const incomingCheckCustomerIds = [
						...(incomingCheck.relationships.customers?.data.map(({id}) => id) ?? []),
						incomingCheck.relationships.customer?.data.id,
					]
					const status = incomingCheck.attributes.status
					const checkPaymentTransactionId = incomingCheck.relationships.transaction?.data.id
					const customers =
						incomingCheckResponse.included &&
						(
							incomingCheckResponse.included.filter(({type}) => CustomerResourceTypes.includes(type)) as Customer[]
						).filter(({id}) => incomingCheckCustomerIds.includes(id))

					const account =
						incomingCheckResponse.included &&
						(incomingCheckResponse.included.find(
							({type, id}) => type === "depositAccount" && id == accountId
						) as Account)

					const org =
						incomingCheckResponse.included &&
						(incomingCheckResponse.included.find((i) => (i.type as string) === "org") as unknown as Org)

					return (
						<>
							<TitleBar
								title={`${startCase(incomingCheck.type)} #${incomingCheckId}`}
								breadcrumbs={[{text: "Incoming Checks", path: "/incoming-checks/"}]}
								status={{
									text: startCase(incomingCheck.attributes.status),
									className: classNames("incoming-check-status", kebabCase(incomingCheck.attributes.status)),
								}}
								meta={[
									{
										text: "Account",
										icon: "bank-account--business-products",
										path: `/accounts/${accountId}`,
									},
									{
										text: "Customer",
										icon: "user-geometric-action-money-fund---users",
										path: `/customer/${customerId}`,
									},
									...(accountId && checkPaymentTransactionId
										? [
												{
													text: "Transaction",
													icon: "money-transfer--business-products",
													path: `/transaction/${accountId}/${checkPaymentTransactionId}`,
												} as MetaItemProps,
										  ]
										: []),
								]}
							>
								<IncomingCheckActionButtons
									id={incomingCheckId}
									refresh={refresh}
									buttons={getButtonsToShow(status, useUserType())}
									disabled={false}
									title={""}
								/>
							</TitleBar>
							<MainSectionLayout
								outerWrapperClassname={classNames("incoming-check-full-with-details")}
								fullWidth={true}
								renderAfter={
									<div className="check-image-card-section">
										<CheckImageCard
											frontImage={frontImage}
											backImage={backImage}
											id={incomingCheckId}
											status={incomingCheck.attributes.status}
										/>
									</div>
								}
							>
								<IncomingCheckInfo
									id={incomingCheckId}
									accessToken={accessToken}
									incomingCheckAsyncResult={getIncomingCheck}
									customers={customers}
									accounts={account ? [account] : undefined}
									org={org}
								/>
							</MainSectionLayout>
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}
