import React from "react"
import Card from "../../containers/Card/Card"
import {AccountsBox} from "./AccountsBox"
import {BalancesBox} from "./BalancesBox"
import {PendingBox} from "./PendingBox"
import {GridContainer} from "../../containers/GridContainer/GridContainer"

function EndCustomerDepositViewContainer({children}: {children: React.ReactNode}) {
	return (
		<Card title={"Deposits"} icon={"bank-account--business-products"} className="end-customer-deposits-card">
			<GridContainer rows={1} columns={2} gap={"16px"} className="end-customer-deposits-grid-container">
				{children}
			</GridContainer>
		</Card>
	)
}
export function EndCustomerDepositView({accounts, balances}: {accounts: number; balances: number}) {
	return (
		<EndCustomerDepositViewContainer>
			<AccountsBox accounts={accounts} />
			<BalancesBox balances={balances / 100} />
		</EndCustomerDepositViewContainer>
	)
}

export function EndCustomerDepositViewPending() {
	return (
		<EndCustomerDepositViewContainer>
			<PendingBox />
			<PendingBox />
		</EndCustomerDepositViewContainer>
	)
}
