import {createResource, getResource} from "./common"
import {Cents, Direction} from "./transaction"

export type IncomingAch = {
	type: "incomingAch"
	id: string
	attributes: {
		createdAt: Date
		fileName: string
		status: IncomingAchStatus
		errorReason?: IncomingAchErrorReason
		reasonCode?: IncomingAchReasonCode
		manualReviewInfo?: IncomingAchManualReviewInfo
		item: {
			serviceClassCode: number
			originatorName: string
			entryDescription: string
			companyIdentification: string
			effectiveEntryDate: string
			originatorStatusCode: string
			originatingDfiIdentification: string
			standardEntryClassCode: string
			transactionCode: number
			receivingDfiIdentification: string
			dfiAccountNumber: string
			amount: number
			recipientName: string
			traceNumber: string
			direction: Direction
			companyName: string
			isReturn: boolean
		}
	}
	relationships: {
		account?: {
			data: {
				type: string
				id: number | null
			}
		}
		transaction?: {
			data: {
				type: string
				id: number | null
			}
		}
		bank?: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number | null
			}
		}
		org?: {
			data: {
				type: string
				id: number | null
			}
		}
		originatedPayment?: {
			data: {
				type: string
				id: number | null
			}
		}
	}
}

export enum IncomingAchStatus {
	MarkedForReturn = "MarkedForReturn",
	Returned = "Returned",
	Processed = "Processed",
	Warehoused = "Warehoused",
	PendingReview = "PendingReview",
	Errored = "Errored",
}

export type IncomingAchReasonCode =
	| "R03"
	| "R05"
	| "R06"
	| "R07"
	| "R09"
	| "R10"
	| "R11"
	| "R16"
	| "R17"
	| "R23"
	| "R29"
	| "R61"
	| "R62"
	| "R67"
	| "R68"
	| "R69"
	| "R70"
export type IncomingAchDishonoredReasonCode = "R61" | "R62" | "R67" | "R68" | "R69" | "R70"

export enum IncomingAchErrorReason {
	TransactionError = "TransactionError",
	ReturnPaymentNotFound = "ReturnPaymentNotFound",
	ReturnPaymentReasonNotFound = "ReturnPaymentReasonNotFound",
}

export const IncomingAchRejectReason = new Map<IncomingAchReasonCode, string>([
	["R03", "No Account / Unable to Locate Account"],
	["R05", "Unauthorized Debit to Consumer Account using Corporate SEC Code"],
	["R06", "Return per ODFI Request"],
	["R07", "Authorization Revoked by Customer"],
	["R09", "Uncollected Funds"],
	["R10", "Customer Advises Not Authorized"],
	["R11", "Customer Advises Entry Not in Accordance with the Terms of the Authorization"],
	["R16", "Account Frozen / Entry Returned per OFAC Instruction"],
	["R17", "Questionable Transaction"],
	["R23", "Credit Entry Refused By Receiver"],
	["R29", "Corporate Customer Advises Not Authorized"],
])

export const IncomingAchDishonoredReturnReason = new Map<IncomingAchDishonoredReasonCode, string>([
	["R61", "Misrouted Return"],
	["R62", "Return of Erroneous or Reversing Debit"],
	["R67", "Duplicate Return"],
	["R68", "Untimely Return"],
	["R69", "Field Error(s)"],
	["R70", "Permissible Return Entry Not Accepted/Return Not Requested by ODFI"],
])

export const isReturnAch = [21, 26, 31, 36, 51, 56, 41, 46]

export enum IncomingAchManualReviewInfo {
	NameMismatch = "NameMismatch",
	IAT = "IAT",
}

export async function findIncomingAch(
	searchQuery: string,
	accessToken: string,
	offset: number,
	limit: number,
	sort: string,
	statusFilter?: IncomingAchStatus[],
	orgFilter?: string[],
	since?: string,
	until?: string,
	fromAmount?: Cents | "",
	toAmount?: Cents | ""
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			status: statusFilter,
			query: searchQuery != "" ? searchQuery : null,
			orgs: orgFilter,
			since: since != "" ? since : null,
			until: until != "" ? until : null,
			fromAmount: fromAmount != "" ? fromAmount : null,
			toAmount: toAmount != "" ? toAmount : null,
		},
		sort: sort,
		include: "bank,account,customer,org",
	}

	return await getResource<Array<IncomingAch>>("incoming-ach", accessToken, query)
}

export async function getIncomingAch(accessToken: string, incomingAchId: string) {
	const query = {
		include: "bank,account,customer,org",
	}
	return await getResource<IncomingAch>(`incoming-ach/${incomingAchId}`, accessToken, query)
}

export async function approveIncomingAch(accessToken: string, incomingAchId: string) {
	const data = {
		type: "incomingAchApprove",
	}
	return await createResource<IncomingAch>(`incoming-ach/${incomingAchId}/approve`, accessToken, data)
}

export async function denyIncomingAch(accessToken: string, incomingAchId: string, reasonCode: string) {
	const data = {
		type: "incomingAchDeny",
		attributes: {
			reasonCode,
		},
	}
	return await createResource<IncomingAch>(`incoming-ach/${incomingAchId}/deny`, accessToken, data)
}

export async function returnErroredIncomingAch(accessToken: string, incomingAchId: string, reasonCode: string) {
	const data = {
		type: "incomingAchErroredReturn",
		attributes: {
			reasonCode,
		},
	}
	return await createResource<IncomingAch>(`incoming-ach/${incomingAchId}/returnErrored`, accessToken, data)
}

export async function reprocessIncomingAch(accessToken: string, incomingAchId: string, originatedPaymentId?: string) {
	const data = {
		type: "incomingAchReprocess",
		relationships: {
			...(originatedPaymentId && {
				payment: {
					data: {
						type: "payment",
						id: originatedPaymentId,
					},
				},
			}),
		},
	}
	return await createResource<IncomingAch>(`incoming-ach/${incomingAchId}/reprocess`, accessToken, data)
}
