import React, {useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {Transaction} from "../../resources/transaction"
import {returnReceivedAchTransaction} from "../../resources/returnAch"

interface ReturnReceivedAchModalProps {
	transaction: Transaction
	close: () => void
	onSuccess: () => void
}

const AchReturnReasons = new Map<string, string>([
	["InsufficientFunds", "R01 Insufficient Funds"],
	["ReturnedPerOdfiRequest", "R06 Return per ODFI Request"],
	["AuthorizationRevokedByCustomer", "R07 Authorization Revoked by Customer"],
	["UncollectedFunds", "R09 Uncollected Funds"],
	["ConsumerPaymentUnauthorized", "R10 Consumer Payment Unauthorized"],
	["CustomerAdvisesNotWithTerms", "R11 Customer Advises Not In Accordance With Terms"],
	["BeneficiaryOrBankAccountHolderDeceased", "R15 Beneficiary Or Bank Account Holder Deceased"],
	["AccountFrozen", "R16 Account Frozen"],
	["CreditEntryRefusedByReceiver", "R23 Refused By Receiver"],
	["CorporatePaymentUnauthorized", "R29 Corporate Payment Unauthorized"],
])

function ReturnReason({
	returnReason,
	setReturnReason,
}: {
	returnReason: string
	setReturnReason: (returnReason: string) => void
}) {
	return (
		<div className="field">
			<label className="label">Reason</label>
			<div className="is-fullwidth select">
				<select
					value={returnReason}
					onChange={(e) => {
						setReturnReason(e.target.value)
					}}
				>
					{Array.from(AchReturnReasons.entries()).map((e) => (
						<option key={e[0]} value={e[0]}>
							{e[1]}
						</option>
					))}
				</select>
			</div>
		</div>
	)
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ReturnReceivedAchModal({transaction, close, onSuccess}: ReturnReceivedAchModalProps) {
	const accessToken = useAccessToken()
	const [returnReason, setReturnReason] = useState("AccountFrozen")
	const [state, returnAch] = useAsyncResultIdle(returnReceivedAchTransaction)

	return (
		<AsyncResultModal
			title="Return Received ACH"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Return ACH"
			successText="Returned"
			onSubmit={() =>
				returnAch(accessToken, transaction.id.toString(), transaction.relationships.account?.data.id, returnReason)
			}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<ReturnReason returnReason={returnReason} setReturnReason={setReturnReason} />
			</div>
		</AsyncResultModal>
	)
}
