import {useAccessToken} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {approveIncomingReturnWire, IncomingWire} from "../../resources/incomingWire"
import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"

interface ApproveIncomingWireModalProps {
	incomingWire: IncomingWire
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}
export function ApproveReturnIncomingWireMissingPayment({
	incomingWire,
	close,
	onSuccess,
}: ApproveIncomingWireModalProps) {
	const accessToken = useAccessToken()
	const [state, approveReturnIncomingWire] = useAsyncResultIdle(approveIncomingReturnWire)
	const [paymentId, setPaymentId] = useState<string>("")

	return (
		<AsyncResultModal
			title="Approve Incoming Return Wire"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSubmit={() => {
				approveReturnIncomingWire(accessToken, incomingWire.id, paymentId)
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Payment Id</label>
				<div className="control">
					<input
						className="input"
						type="text"
						pattern="^[0-9]*$"
						value={paymentId}
						onChange={(e) => setPaymentId(e.target.value)}
					/>
				</div>
			</div>
		</AsyncResultModal>
	)
}
