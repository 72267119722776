import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getOrg, getOrgName} from "../../resources/org"
import React, {ComponentClass, FunctionComponent} from "react"
import Skeleton from "react-loading-skeleton"
import {Resource} from "../../resources/common"

type ElementProps<P> = Pick<Props<P> & P, Exclude<keyof P, "element" | "orgId">>

interface Props<P> {
	orgId: string
	included?: Resource[]
	token?: string
	element: FunctionComponent<ElementProps<P>> | ComponentClass<ElementProps<P>> | string
}

function AsyncOrgName<P>({orgId, element, ...props}: Props<P> & P) {
	const accessToken = props.token ?? useAccessToken()
	const result = useAsyncResult(() => getOrg(accessToken, orgId), [orgId])
	const Elem = element

	return result.match(
		() => (
			<Elem {...props}>
				<Skeleton />
			</Elem>
		),
		(org) => <Elem {...props}>{getOrgName(org)}</Elem>,
		(_) => <Elem {...props}>Error</Elem>
	)
}

function SyncOrgName<P>({orgId, element, ...props}: Props<P> & P) {
	const org = props?.included?.find((r) => r.type === "org" && r.id === orgId)
	if (org && org.type == "org") {
		const Elem = element
		return <Elem {...props}>{getOrgName(org)}</Elem>
	}

	return null
}

export function OrgName<P extends {}>(props: Props<P> & P) {
	if (props.included) {
		return <SyncOrgName {...props} />
	}

	return <AsyncOrgName {...props} />
}

OrgName.defaultProps = {
	element: "td",
}
