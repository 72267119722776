import {createResource, deleteResource, getResource} from "./common"
import moment from "moment"

export type ApiToken = {
	type: "apiToken"
	id: string
	attributes: {
		createdAt: Date
		description: string
		expiration: Date
		sourceIp?: string
	}
}

export type ApiTokenPresent = ApiToken & {attributes: {token: string}}

export async function getUserApiTokens(accessToken: string, userId: string) {
	return await getResource<Array<ApiToken>>(`users/${userId}/api-tokens`, accessToken)
}

export function isExpired(token: ApiToken) {
	return moment().isAfter(moment(token.attributes.expiration))
}

export async function createApiToken(
	accessToken: string,
	userId: string,
	description: string,
	scope: string,
	expiration: Date,
	sourceIp?: string
) {
	const result = await createResource<ApiTokenPresent>(`users/${userId}/api-tokens`, accessToken, {
		type: "apiToken",
		attributes: {
			description,
			expiration,
			scope,
			sourceIp,
		},
	})
	return result.map((v) => v.data)
}

export async function revokeApiToken(accessToken: string, userId: string, tokenId: string) {
	const result = await deleteResource(`users/${userId}/api-tokens/${tokenId}`, accessToken)
	return result.map((v) => v.data)
}
