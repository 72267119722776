import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {useAccessToken, useIsUnitUser, useUserType} from "../../services/auth"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {ReactElement, useRef} from "react"
import {useQueryState} from "use-location-state"
import {usePaging} from "../../hooks/usePaging"
import {findIncomingWires, IncomingWire, IncomingWireStatus} from "../../resources/incomingWire"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {Filter} from "../Filter/Filter"
import {kebabCase, startCase} from "lodash"
import {useNavigate} from "react-router-dom"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {ErrorDocument, Resource} from "../../resources/common"
import classNames from "classnames"
import numeral from "numeral"
import ReactTooltip from "react-tooltip"
import moment from "moment"
import {useModal} from "react-modal-hook"
import {RejectIncomingWireModal} from "./RejectIncomingWireModal"
import {ApproveIncomingWireModal} from "./ApproveIncomingWire"
import Icon from "../Icon/Icon"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {extractDataFromFromImadOmad} from "../../utilities/helpers"
import {BankName} from "../BankName/BankName"
import {Org, findOrgs, getOrgName} from "../../resources/org"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {AsyncResult} from "../../types/asyncResult"
import {Cents} from "../../resources/transaction"
import {CurrencyRangeFilter} from "../Filter/CurrencyRangeFilter"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {Bank, findBanks} from "../../resources/bank"

function OrgName({orgId, included}: {orgId: number | undefined | null; included?: Resource[]}) {
	if (included && orgId) {
		const org = included.find((r) => r.type == "org" && r.id === orgId.toString()) as Org
		if (org) {
			return <td>{org.attributes.name}</td>
		}
	}
	return <td></td>
}

export enum IncomingWiresColumns {
	id = "Id",
	org = "Org",
	bank = "Bank",
	accountId = "Account",
	customer = "Customer",
	amount = "Amount",
	status = "Status",
	businessFunctionCode = "Business Function Code",
	isMonetary = "Monetary",
	cycleDate = "Cycle Date",
	createdAt = "Created At",
}

type AllowedIncomingWiresColumns =
	| IncomingWiresColumns.id
	| IncomingWiresColumns.org
	| IncomingWiresColumns.bank
	| IncomingWiresColumns.accountId
	| IncomingWiresColumns.customer
	| IncomingWiresColumns.amount
	| IncomingWiresColumns.status
	| IncomingWiresColumns.businessFunctionCode
	| IncomingWiresColumns.isMonetary
	| IncomingWiresColumns.cycleDate
	| IncomingWiresColumns.createdAt

interface IncomingWiresProps {
	limit?: number
	includedColumns: AllowedIncomingWiresColumns[]
	enableStatusFilter?: boolean
	enableOrgFilter?: boolean
	enableBankFilter?: boolean
	enableAmountFilter?: boolean
	title?: string
	fullHeight?: boolean
	stickyAction?: boolean
	enablePaging?: boolean
}

interface IncomingWiresTableProps {
	incomingWires: IncomingWire[]
	includedColumns: AllowedIncomingWiresColumns[]
	isUsingPaging: boolean
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	refresh: () => void
	include?: Resource[]
	fullHeight?: boolean
	stickyAction?: boolean
}

interface IncomingWireRowProps {
	incomingWire: IncomingWire
	includedColumns: AllowedIncomingWiresColumns[]
	refresh: () => void
	include?: Resource[]
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

function IncomingWireRow({incomingWire, includedColumns, include, onClick}: IncomingWireRowProps) {
	const navigate = useNavigate()
	const id = incomingWire.id
	const org = incomingWire.relationships.org?.data.id
	const bank = incomingWire.relationships.bank.data.id
	const customerId = incomingWire.relationships.customer?.data.id
	const accountId = incomingWire.relationships.account?.data.id
	const status = incomingWire.attributes.status
	const createdAt = incomingWire.attributes.createdAt
	const amount = incomingWire.attributes.amount
	const businessFunctionCode = incomingWire.attributes.businessFunctionCode
	const isMonetary = incomingWire.attributes.isMonetary
	const dataFromOmad = extractDataFromFromImadOmad(incomingWire.attributes.incomingMessage.omad)

	const customer =
		customerId && include
			? include.find(
					(r) => (r.type == "individualCustomer" || r.type == "businessCustomer") && r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedIncomingWiresColumns, ReactElement>> = {}

	if (includedColumns.includes(IncomingWiresColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingWiresColumns.org)) {
		contentColumns["Org"] = <OrgName orgId={org} included={include} />
	}

	if (useIsUnitUser() && includedColumns.includes(IncomingWiresColumns.bank) && bank) {
		contentColumns["Bank"] = (
			<DataTableCell>
				<BankName bankId={bank.toString()} included={include} />
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingWiresColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingWiresColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingWiresColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<span className="incoming-wires-amount">{numeral(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingWiresColumns.status)) {
		const tooltipValue = undefined

		contentColumns["Status"] = (
			<DataTableCell>
				<div
					data-tip="React-tooltip"
					data-for={`status-reason-tooltip-${id}`}
					className={classNames("incoming-wire-status", kebabCase(status))}
				>
					{startCase(status)}

					<ReactTooltip
						className="tooltip-info"
						id={`status-reason-tooltip-${id}`}
						disable={!tooltipValue}
						effect="solid"
					>
						{tooltipValue}
					</ReactTooltip>
				</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(IncomingWiresColumns.businessFunctionCode)) {
		contentColumns["Business Function Code"] = <DataTableCell>{businessFunctionCode}</DataTableCell>
	}

	if (includedColumns.includes(IncomingWiresColumns.isMonetary)) {
		contentColumns["Monetary"] = <DataTableCell>{isMonetary ? "yes" : "no"}</DataTableCell>
	}

	if (includedColumns.includes(IncomingWiresColumns.cycleDate)) {
		contentColumns["Cycle Date"] = <DataTableCell>{dataFromOmad ? dataFromOmad.cycleDate : ""}</DataTableCell>
	}

	if (includedColumns.includes(IncomingWiresColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(createdAt).format("L LT")}</DataTableCell>
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return <DataTableRow onClick={(e) => onClick && onClick(e)}>{content}</DataTableRow>
}

interface IncomingWireButtonsProps {
	incomingWire: IncomingWire
	refresh: () => void
}

export function IncomingWireButtons({incomingWire, refresh}: IncomingWireButtonsProps) {
	const [showReject, hideReject] = useModal(() => (
		<RejectIncomingWireModal close={hideReject} incomingWire={incomingWire} onSuccess={refresh} />
	))
	const [showApprove, hideApprove] = useModal(() => (
		<ApproveIncomingWireModal close={hideApprove} incomingWire={incomingWire} onSuccess={refresh} />
	))

	return (
		<>
			{["PendingReview", "Errored"].includes(incomingWire.attributes.status) && (
				<>
					<HasPermission resource="incomingWire.return" action="update">
						<button className="button is-danger" onClick={showReject}>
							<span className="icon">
								<Icon icon="interface-delete-interface-essential" size={12} />
							</span>
							<span> Reject </span>
						</button>
					</HasPermission>

					<HasPermission resource="incomingWire.process" action="update">
						<button className="button is-success" onClick={showApprove}>
							<span className="icon">
								<Icon icon="interface-validation-check--interface-essential" size={12} />
							</span>
							<span> Approve </span>
						</button>
					</HasPermission>
				</>
			)}
			{["Processed"].includes(incomingWire.attributes.status) && (
				<>
					<HasPermission resource="incomingWire.return" action="update">
						<button className="button is-danger" onClick={showReject}>
							<span className="icon">
								<Icon icon="interface-delete-interface-essential" size={12} />
							</span>
							<span> Return </span>
						</button>
					</HasPermission>
				</>
			)}
		</>
	)
}

export function IncomingWiresTable({
	incomingWires,
	includedColumns,
	hasResults,
	isUsingPaging,
	hasPrev,
	hasNext,
	prev,
	next,
	refresh,
	include,
	fullHeight = true,
	stickyAction = true,
}: IncomingWiresTableProps) {
	const navigate = useNavigate()
	const noContent = incomingWires.length === 0
	const ref = useRef(null)

	return (
		<div ref={ref} className={"incoming-wires-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={stickyAction}
				noContentText={"No incoming wires found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === IncomingWiresColumns.createdAt) {
								return <DataTableCell key={column[1]}>{column[1]}</DataTableCell>
							}

							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{incomingWires.map((wire) => (
						<IncomingWireRow
							incomingWire={wire}
							key={wire.id}
							include={include}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								navigate(`/incoming-wires/${wire.id}`)
							}}
							refresh={refresh}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
			/>
		</div>
	)
}

export default function IncomingWires({
	limit = 25,
	includedColumns,
	stickyAction = false,
	fullHeight = false,
	enablePaging = true,
	enableStatusFilter = true,
	enableOrgFilter = true,
	enableAmountFilter = true,
	enableBankFilter = true,
	title,
}: IncomingWiresProps) {
	const prefix = "inc-wire"
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType()

	const [statuses, setStatuses] = useQueryState(
		`${prefix}-filter[status]`,
		enableStatusFilter ? [IncomingWireStatus.PendingReview] : []
	)
	const statusesOptions = new Map(Object.values(IncomingWireStatus).map((v) => [v, startCase(v)]))
	const statusFilter = (
		<Filter
			statuses={statuses}
			setStatuses={setStatuses}
			onFilterFunc={() => reset(limit)}
			options={statusesOptions}
			title="Status"
		/>
	)

	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [filteredBanks, setFilteredBanks] = useQueryState<string[]>(`${prefix}-filter[banks]`, [])
	const [fromAmount, setFromAmount] = useQueryState<Cents | "">(`${prefix}-filter[fromAmount]`, "")
	const [toAmount, setToAmount] = useQueryState<Cents | "">(`${prefix}-filter[toAmount]`, "")
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findIncomingWires(
				accessToken,
				offset,
				limit,
				statuses,
				filteredOrgs,
				filteredBanks,
				fromAmount,
				toAmount,
				since,
				until
			),
		(x) => x.data.length,
		[refreshToken, [...statuses, ...filteredOrgs, ...filteredBanks].join(","), fromAmount, toAmount, since, until],
		`${prefix}-`,
		true
	)

	const orgs =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const banks = useIsUnitUser()
		? useAsyncResult(() => findBanks(accessToken, 0, 10000), [])
		: AsyncResult.pending<Bank[], ErrorDocument>()
	const bankFilter = banks.match(
		() => null,
		(banks) => (
			<Filter
				title="Banks"
				isSearchable={true}
				setStatuses={setFilteredBanks}
				statuses={filteredBanks}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						banks
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((bank) => [bank.id, bank.attributes.name])
					)
				}
			/>
		),
		(_) => null
	)

	const amountFilter = (
		<CurrencyRangeFilter
			placeholder={"Amount"}
			presets={[]}
			from={fromAmount}
			setFrom={setFromAmount}
			to={toAmount}
			setTo={setToAmount}
		/>
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	return (
		<DataTableCard className={"incoming-wires-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				dateTimePicker={dateTime}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableBankFilter ? [bankFilter] : []),
					...(enableStatusFilter ? [statusFilter] : []),
					...(enableAmountFilter ? [amountFilter] : []),
				]}
				title={title}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: incomingWires}) => {
					return (
						<IncomingWiresTable
							incomingWires={incomingWires.data}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							include={incomingWires.included}
							includedColumns={includedColumns}
							isUsingPaging={enablePaging}
							stickyAction={stickyAction}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
