import React from "react"

export default function Auth0Fields({
	jwksPath,
	customDomain,
	updateSettings,
}: {
	jwksPath: string
	customDomain: string | undefined
	updateSettings: (field: string, value: string) => void
}) {
	return (
		<fieldset className="jwt-fields">
			<div className="field">
				<label className="label">JWKs Path</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="https://{yourAuth0Domain}/jwks.json"
						value={jwksPath ?? ""}
						pattern="https:\/\/([A-Za-z0-9\-\.]*)\.auth0\.com\/\.well-known\/jwks\.json"
						onChange={(e) => {
							updateSettings("jwksPath", e.target.value)
							if (e.target.validity.patternMismatch) {
								e.target.setCustomValidity(
									"invalid url, make sure your url is in this format: 'https://domain-name.auth0.com/jwks.json'"
								)
							} else {
								e.target.setCustomValidity("")
							}
						}}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">
					Custom Domain <span className="field-tag">(Optional)</span>
				</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="https://your-custome-domain.com"
						value={customDomain ?? ""}
						onChange={(e) => {
							updateSettings("issuer", e.target.value)
						}}
					/>
				</div>
			</div>
		</fieldset>
	)
}
