import React, {Dispatch, SetStateAction} from "react"
import {Customer, getCustomerName} from "../../resources/customer"
import {toNumber} from "lodash"
import {EditMode, RiskRate} from "../../resources/common"
import {Bank} from "../../resources/bank"
import {isUndefined} from "lodash"
import DescriptiveToggleSwitch from "../../components/DescriptiveToggleSwitch/DescriptiveToggleSwitch"

interface Props {
	editMode: EditMode
	name: string
	setName: Dispatch<SetStateAction<string>>
	customerId: string | null
	setCustomerId: Dispatch<SetStateAction<string | null>>
	customers: Array<Customer>
	bankId?: string | null
	setBankId?: Dispatch<SetStateAction<string | null>>
	banks?: Array<Bank>
	orgInterchangeShare: number
	setOrgInterchangeShare: Dispatch<SetStateAction<number>>
	defaultCustomerRiskRate: RiskRate
	setDefaultCustomerRiskRate: Dispatch<SetStateAction<RiskRate>>
	twilioVerificationServiceId?: string
	setTwilioVerificationServiceId?: Dispatch<SetStateAction<string>>
	enableTwoFactorAuthentication: boolean
	setEnableTwoFactorAuthentication: Dispatch<SetStateAction<boolean>>
}

export function OrgAttributes(props: Props) {
	return (
		<>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Basic</p>
						</div>
						<div className="card-content">
							<div className="field">
								<label className="label">Name</label>
								<div className="control">
									<input
										className="input"
										type="text"
										placeholder="Pied Piper"
										value={props.name}
										required
										disabled={props.editMode == EditMode.VIEW}
										onChange={(e) => props.setName(e.target.value)}
									/>
								</div>
							</div>
							{props.editMode == EditMode.NEW &&
							!isUndefined(props.bankId) &&
							!isUndefined(props.setBankId) &&
							!isUndefined(props.banks) ? (
								<div className="field">
									<label className="label">Bank</label>
									<div className="field-body">
										<div className="select">
											<select
												required
												value={props.bankId ? props.bankId : ""}
												onChange={(e) => (props.setBankId ? props.setBankId(e.target.value) : null)}
											>
												<option key="0" defaultValue=""></option>
												{props.banks.map((c) => (
													<option key={c.id} value={c.id}>
														{c.attributes.name}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							) : null}
							<div className="field">
								<label className="label">Customer</label>
								<div className="field-body">
									<div className="select">
										<select
											value={props.customerId ? props.customerId : ""}
											onChange={(e) => props.setCustomerId(e.target.value)}
											disabled={props.editMode == EditMode.VIEW}
											required={props.editMode == EditMode.NEW}
										>
											<option key="0" defaultValue=""></option>
											{props.customers.map((c) => (
												<option key={c.id} value={c.id}>
													{`${c.id} - ${getCustomerName(c)}`}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							{props.editMode != EditMode.NEW &&
							!isUndefined(props.setTwilioVerificationServiceId) &&
							!isUndefined(props.twilioVerificationServiceId) ? (
								<div className="field">
									<label className="label">Twilio Verification Service Id</label>
									<div className="control">
										<input
											className="input"
											type="text"
											value={props.twilioVerificationServiceId}
											disabled={props.editMode == EditMode.VIEW}
											onChange={(e) =>
												props.setTwilioVerificationServiceId
													? props.setTwilioVerificationServiceId(e.target.value)
													: null
											}
										/>
									</div>
								</div>
							) : null}
							{props.editMode == EditMode.NEW ? (
								<div className="field">
									<label className="label">Org Interchange share</label>
									<div className="field-body">
										<div className="control">
											<input
												className="input"
												type="number"
												min="0"
												max="100"
												value={toNumber(props.orgInterchangeShare) * 100}
												pattern="^[1-9][0-9]?$|^100$|^0$"
												onChange={(e) => props.setOrgInterchangeShare(toNumber(e.target.value) / 100)}
											/>
										</div>
										<p className="control">
											<a className="button is-static">%</a>
										</p>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Applications</p>
						</div>
						<div className="card-content">
							<div className="field">
								<label className="label">Default Risk Rate</label>
								<div className="field-body">
									<div className="select">
										<select
											value={props.defaultCustomerRiskRate}
											onChange={(e) => {
												const val = e.target.value

												if (val == RiskRate.Low || val == RiskRate.Medium || val == RiskRate.High) {
													props.setDefaultCustomerRiskRate(val)
												}
											}}
											disabled={props.editMode == EditMode.VIEW}
										>
											<option value={RiskRate.Low} key={RiskRate.Low}>
												Low
											</option>
											<option value={RiskRate.Medium} key={RiskRate.Medium}>
												Medium
											</option>
											<option value={RiskRate.High} key={RiskRate.High}>
												High
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Security</p>
						</div>
						<div className="card-content">
							<div className="field">
								<label className="label">Two-Factor Authentication</label>
								<div className="field-body">
									<DescriptiveToggleSwitch
										text={props.enableTwoFactorAuthentication ? "Enabled" : "Disabled"}
										description={
											props.enableTwoFactorAuthentication
												? "Activating 2FA is effective immediately. Org users who do not have a valid phone number will not be able to access the dashboard."
												: ""
										}
										checked={props.enableTwoFactorAuthentication}
										onChange={(e) => props.setEnableTwoFactorAuthentication(e.target.checked)}
										disabled={props.editMode == EditMode.VIEW}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
