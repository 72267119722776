import * as Yup from "yup"
import {CA_COUNTRY_CODE, caPostalCode, US_COUNTRY_CODE, usPostalCode} from "../../resources/address"
import {addCustomValidationMethods, ssnRegex} from "../../utilities/validation"

addCustomValidationMethods()

const validationErrorMessages = {
	firstName: {
		required: "Please enter your first name",
	},
	lastName: {
		required: "Please enter your last name",
	},
	phone: {
		required: "Please enter your mobile phone number",
		invalid: "The phone number you entered is invalid",
	},
	email: {
		required: "Please enter your email address",
		invalid: "The email address you entered is invalid",
	},
	ssn: {
		required: "Please enter your full, 9-digit Social Security Number",
		invalid: "The Social Security Number you have provided is invalid (format is xxx-xx-xxxx)",
	},
	nationality: {
		required: "Please select the nationality",
	},
	passport: {
		required: "Please enter your full passport",
	},
	street: {
		required: "Please enter the street name",
	},
	city: {
		required: "Please enter your city of residence",
	},
	state: {
		required: "Please select the state of residence",
	},
	postalCode: {
		required: "Please enter your zip code (must be 5 or 9 digits long)",
		usInvalid: "The zip code you have provided is invalid (US zip codes must be 5 or 9 digits long)",
		caInvalid:
			"The zip code you have provided is invalid (CA zip codes are in a A1A 1A1 format, where A is a letter and 1 is a digit)",
	},
	country: {
		required: "Please select the country of residence",
	},
	dateOfBirth: {
		required: "Please enter the date of birth",
		max: "Must be earlier than today",
	},
	beneficialOwnershipPercentage: {
		range: "Range must be between 25 and 100",
	},
}

export default {
	firstName: Yup.string().required(validationErrorMessages.firstName.required),
	lastName: Yup.string().required(validationErrorMessages.lastName.required),
	ssn: Yup.string().when("nationality", {
		is: US_COUNTRY_CODE,
		then: Yup.string()
			.required(validationErrorMessages.ssn.required)
			.matches(ssnRegex, validationErrorMessages.ssn.invalid),
	}),
	nationality: Yup.string().required(validationErrorMessages.nationality.required),
	passport: Yup.string().when("nationality", {
		is: (nationality: string) => nationality !== US_COUNTRY_CODE,
		then: Yup.string().required(validationErrorMessages.passport.required),
	}),
	street: Yup.string().required(validationErrorMessages.street.required),
	// street2:
	city: Yup.string().required(validationErrorMessages.city.required),
	state: Yup.string().when("country", {
		is: (country: string) => [US_COUNTRY_CODE, CA_COUNTRY_CODE].includes(country),
		then: Yup.string().required(validationErrorMessages.state.required),
	}),
	postalCode: Yup.string()
		.required(validationErrorMessages.postalCode.required)
		.when("country", {
			is: US_COUNTRY_CODE,
			then: Yup.string().matches(usPostalCode, validationErrorMessages.postalCode.usInvalid),
		})
		.when("country", {
			is: CA_COUNTRY_CODE,
			then: Yup.string().matches(caPostalCode, validationErrorMessages.postalCode.caInvalid),
		}),
	country: Yup.string().required(validationErrorMessages.country.required),
	dateOfBirth: Yup.date()
		.required(validationErrorMessages.dateOfBirth.required)
		.max(new Date(), validationErrorMessages.dateOfBirth.max),
	email: Yup.string().email(validationErrorMessages.email.invalid).required(validationErrorMessages.email.required),
	phone: Yup.string()
		.required(validationErrorMessages.phone.required)
		.phoneNumber(validationErrorMessages.phone.invalid),
	percentage: Yup.number()
		.typeError("Percentage must be a number")
		.min(25, validationErrorMessages.beneficialOwnershipPercentage.range)
		.max(100, validationErrorMessages.beneficialOwnershipPercentage.range),
}
