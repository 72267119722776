import React, {ReactElement} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import Payments, {PaymentColumns} from "../../components/Payments/Payments"
import {useQueryState} from "use-location-state"
import {useAccessToken, useIsBankUser, useIsUnitUser, useUserClaimsData} from "../../services/auth"
import {hasPermission} from "../../services/permission"
import {startCase} from "lodash"
import {AssigneeType, AssignmentStage, findAssignments, FindAssignmentsArgs} from "../../resources/assignments"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {TabsMeta, TitleBarTabs} from "../../components/TitleBarTabs/TitleBarTabs"
import {AssignmentsHub, AssignmentsHubColumns} from "../AssignmentsHub/AssignmentsHub"
import {isProdEnv, isSandboxOrLocalEnv} from "../../utilities/environment"
import {AmountInfoCircle} from "../../components/AmountInfoCircle/AmountInfoCircle"

enum PaymentsTabs {
	All = "All",
	Assignments = "Assignments",
}

type AssignmentsAmountIndicatorProps = {
	findAssignmentsArgs: FindAssignmentsArgs
	refreshToken?: number
	isActive: boolean
	tooltip?: string | JSX.Element
}
export function AssignmentsAmountIndicator({
	findAssignmentsArgs,
	refreshToken,
	isActive,
	tooltip,
}: AssignmentsAmountIndicatorProps) {
	const findAssignmentsResult = useAsyncResult(() => findAssignments(findAssignmentsArgs), [refreshToken])
	return (
		<AsyncResultComponent asyncResult={findAssignmentsResult} pendingComponent={<></>}>
			{({value: result}) => {
				const assignmentAmount = result.meta?.pagination.total
				const assignmentAmountNumber = assignmentAmount ? Number(assignmentAmount) : undefined
				return !!assignmentAmountNumber && assignmentAmountNumber > 0 ? (
					<AmountInfoCircle amount={assignmentAmountNumber} tooltip={tooltip} isActive={isActive} />
				) : (
					<></>
				)
			}}
		</AsyncResultComponent>
	)
}

export default function PaymentsPage(): ReactElement {
	const [currentTab, setCurrentTab] = useQueryState<PaymentsTabs>("tab", PaymentsTabs.All)
	const accessToken = useAccessToken()
	const claims = useUserClaimsData()
	const assigneeType = startCase(claims.userType) as AssigneeType
	const liClassName = "assignments-tab-li" as const
	const commonTabProps = {liClassName}
	const tabs: TabsMeta<PaymentsTabs>[] = [
		{label: "All", key: PaymentsTabs.All},
		{
			label: "Assignments",
			key: PaymentsTabs.Assignments,
			suffixElement: (
				<AssignmentsAmountIndicator
					findAssignmentsArgs={{
						accessToken,
						assignees: [assigneeType],
						stages: [AssignmentStage.Pending],
						limit: 0,
					}}
					isActive={currentTab == PaymentsTabs.Assignments}
					tooltip={
						<>
							Pending assignment
							<br /> for {assigneeType} user
						</>
					}
				/>
			),
		},
	].map((tab) => ({...tab, ...commonTabProps}))
	const paymentsProps = {
		enableDirectionFilter: true,
		fullHeight: true,
		enableOrgFilter: true,
		enableBankFilter: true,
		enableStatusFilter: true,
		disableDrawer: useIsUnitUser() || useIsBankUser(),
		enableTypeFilter: true,
		enableStatusPhasesFilter: true,
		enableAmountFilter: true,
		enableFeaturesFilter: true,
		includedColumns: [
			PaymentColumns.id,
			...(useIsUnitUser() ? [PaymentColumns.org] : []),
			...(useIsUnitUser() ? [PaymentColumns.bank] : []),
			PaymentColumns.type,
			PaymentColumns.accountId,
			PaymentColumns.customer,
			PaymentColumns.amount,
			PaymentColumns.status,
			PaymentColumns.createdAt,
			PaymentColumns.expectedCompletionDate,
			...(hasPermission("payment.achDebit.clear", "create") ? [PaymentColumns.actions] : []),
		],
	}
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()
	const isBankOrUnitUser = isUnitUser || isBankUser
	const sandboxAndLocalOrgIds = ["3475", "2654", "5297", "5314", "5326", "5328", "5329", "5335", "5336"]
	const sandboxAndLocalBankIds = ["20", "26"]
	const showAssignmentsOnSandBox =
		isSandboxOrLocalEnv() &&
		(sandboxAndLocalOrgIds.includes(claims.orgId) || sandboxAndLocalBankIds.includes(claims.bankId) || isUnitUser) //TODO: Remove once going live
	const showAssignmentsOnProd = isProdEnv()
	const showAssignments = showAssignmentsOnSandBox || showAssignmentsOnProd
	return (
		<>
			<TitleBar title="Payments" noBorder={showAssignments} />
			{showAssignments && (
				<TitleBarTabs
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
					tabs={tabs}
					navClassName="assignments-tab-nav"
				/>
			)}
			<MainSectionTable>
				{showAssignments ? (
					<>
						{currentTab == PaymentsTabs.All && <Payments {...paymentsProps} showTabsOnHeader />}
						{currentTab == PaymentsTabs.Assignments && (
							<AssignmentsHub
								token={accessToken}
								includedColumns={[
									AssignmentsHubColumns.isOwner,
									AssignmentsHubColumns.resourceType,
									AssignmentsHubColumns.resourceId,
									...(isBankOrUnitUser ? [AssignmentsHubColumns.org] : []),
									...(isUnitUser ? [AssignmentsHubColumns.bank] : []),
									AssignmentsHubColumns.customer,
									AssignmentsHubColumns.assignmentId,
									AssignmentsHubColumns.assignmentType,
									AssignmentsHubColumns.assignee,
									AssignmentsHubColumns.assignmentStatus,
									AssignmentsHubColumns.createdAt,
								]}
								fullHeight
								enableResourceTypeFilter
								enableAssigneeFilter
								enableAssignmentStageFilter
								enableAssignmentTypeFilter
								enableOrgFilter
								enableBankFilter
							/>
						)}
					</>
				) : (
					<Payments {...paymentsProps} />
				)}
			</MainSectionTable>
		</>
	)
}
