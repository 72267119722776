import HorizontalField from "../HorizontalField/HorizontalField"
import {startCase} from "lodash"
import React from "react"
import classNames from "classnames"
import {RiskRate} from "../../resources/common"
import {Editable, EditableElementProps} from "../HorizontalField/EditableHorizontalField"

export interface EditableRiskRateProps {
	riskRate: RiskRate
	setRiskRate: (value: RiskRate) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: RiskRate) => void
	className?: string
	label?: string
}

export function RiskRateField({riskRate, fieldClassName}: {riskRate?: RiskRate; fieldClassName?: string}) {
	if (riskRate) {
		let className = ""

		if (riskRate == RiskRate.Low) {
			className = "has-text-success"
		}
		if (riskRate == RiskRate.Medium) {
			className = "has-text-warning"
		}
		if (riskRate == RiskRate.High) {
			className = "has-text-danger"
		}
		return (
			<HorizontalField label="Risk Rate" fieldClassName={fieldClassName}>
				<input type="text" readOnly value={startCase(riskRate)} className={classNames("input is-static", className)} />
			</HorizontalField>
		)
	}

	return null
}

export function EditableRiskRateField(props: EditableRiskRateProps) {
	if (props.riskRate) {
		return (
			<Editable
				label={props.label ?? "Risk Rate"}
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setRiskRate}
				value={props.riskRate}
				initial={props.riskRate}
				update={props.update}
				className={props.className}
			>
				{EditableRiskRate}
			</Editable>
		)
	}

	return null
}

export function EditableRiskRate(props: EditableElementProps<RiskRate>) {
	let className = ""

	if (props.value == RiskRate.Low) {
		className = "has-text-success"
	}
	if (props.value == RiskRate.Medium) {
		className = "has-text-warning"
	}
	if (props.value == RiskRate.High) {
		className = "has-text-danger"
	}

	if (props.isEditing) {
		return (
			<div className="select is-small">
				<select
					value={props.value}
					onChange={(e) => {
						const val = e.target.value

						if (val == RiskRate.Low || val == RiskRate.Medium || val == RiskRate.High) {
							props.setValue(val)
						}
					}}
				>
					<option value={RiskRate.Low} key={RiskRate.Low}>
						Low
					</option>
					<option value={RiskRate.Medium} key={RiskRate.Medium}>
						Medium
					</option>
					<option value={RiskRate.High} key={RiskRate.High}>
						High
					</option>
				</select>
			</div>
		)
	} else {
		return (
			<input type="text" readOnly value={startCase(props.value)} className={classNames("input is-static", className)} />
		)
	}
}
