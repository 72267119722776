import React from "react"
import Skeleton from "react-loading-skeleton"
import classNames from "classnames"
import {isUndefined} from "lodash"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"
import {LinkArrowIcon} from "../LinkArrowIcon/LinkArrowIcon"
import ReactTooltip from "react-tooltip"

function displayAmount(amount: number) {
	return `$${(amount / 100).toLocaleString("en-US", {minimumFractionDigits: 2})}`
}

function MinimumReserveBalance({minimum, amount}: {minimum: number; amount: number}) {
	return (
		<div className={classNames("minimum-balance", minimum > amount && "negative", minimum <= amount && "positive")}>
			{minimum >= amount ? (
				<span
					id="reserve-balance-tooltip"
					data-tip="You’re under your minimum balance"
					data-for="reserve-balance-tooltip"
					className="minimum-balance-warning-icon"
				>
					<Icon icon="alert-circle--interface-essential" size={12} />
					<ReactTooltip id="reserve-balance-tooltip" className="minimum-balance-tooltip" place="top" effect="solid" />
				</span>
			) : null}
			<span className={"minimum-balance-amount"}>{displayAmount(minimum)} Minimum Balance </span>
		</div>
	)
}

export default function AccountBoxView({
	title,
	additionalTitle,
	amount,
	className,
	showCTA,
	icon,
	onClick,
	accountChange,
	minimumBalance,
}: {
	title?: string
	additionalTitle?: string
	amount?: number
	className?: string
	showCTA?: boolean
	icon: IcomoonIconName
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void
	accountChange: number
	minimumBalance?: number
}) {
	const isLoadingFinished = !!title
	return (
		<div className={classNames("account-box", className, {inactive: !title || !showCTA})} onClick={onClick}>
			<div className={"account-box-title-container"}>
				{isLoadingFinished ? <Icon icon={icon} size={20} /> : <Skeleton />}
				{isLoadingFinished ? <span> {title} </span> : <Skeleton />}
				{isLoadingFinished && additionalTitle && (
					<span className="account-box-additional-title"> {additionalTitle} </span>
				)}
			</div>
			{isLoadingFinished ? (
				<div className={"account-box-data-container"}>
					<span className={"account-box-data-amount"}>{!isUndefined(amount) ? displayAmount(amount) : "--"} </span>
					<span
						className={classNames(
							"account-box-data-percentage",
							accountChange > 0 && "positive",
							accountChange < 0 && "negative"
						)}
					>
						{" "}
						{accountChange != 0 && <Icon icon={accountChange > 0 ? "tren up" : "tren-down"} size={10} />}{" "}
						{accountChange}%{" "}
					</span>
					<span className={"account-box-data-range"}> last 7 days </span>
				</div>
			) : (
				<div className={"account-box-data-container"}>
					<Skeleton />
				</div>
			)}
			{!isUndefined(minimumBalance) && !isUndefined(amount) && (
				<MinimumReserveBalance minimum={minimumBalance} amount={amount} />
			)}

			{isLoadingFinished && showCTA ? (
				<div className="account-box-arrow-icon-container">
					<LinkArrowIcon />
				</div>
			) : null}
		</div>
	)
}
