import React, {useState} from "react"
import {Application, approveApplication, cancelApplication, getApplicationName} from "../../resources/application"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getOrg, Org} from "../../resources/org"
import Loading from "../Loading/Loading"
import {Resource, RiskRate} from "../../resources/common"
import {Err} from "../Err/Err"
import Admonition from "../Admonitions/Admonition"
import {Modal} from "../Modals/Modal"
import ApplicationDeny from "../ApplicationDeny/ApplicationDeny"

interface ApplicationModalProps {
	application: Application
	close: () => void
	onSuccess: () => void
	resources?: Resource[]
}

interface DenyApplicationModalProps extends ApplicationModalProps {
	accessToken: string
}

interface ApproveApplicationModalContentProps {
	application: Application
	close: () => void
	onSuccess: () => void
	org: Org
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function DenyApplicationModal({
	application,
	close,
	onSuccess,
	resources,
	accessToken,
}: DenyApplicationModalProps) {
	return (
		<Modal classname="deny-application-modal" close={refreshAndClose(onSuccess, close)}>
			<ApplicationDeny
				application={application}
				resources={resources}
				onSuccess={refreshAndClose(onSuccess, close)}
				accessToken={accessToken}
			/>
		</Modal>
	)
}

export function ApproveApplicationModalContent({
	application,
	close,
	onSuccess,
	org,
}: ApproveApplicationModalContentProps) {
	const accessToken = useAccessToken()
	const [reason, setReason] = useState("")
	const [riskRate, setRiskRate] = useState<RiskRate>(org.attributes.defaultCustomerRiskRate)
	const [state, approve] = useAsyncResultIdle(approveApplication)

	return (
		<AsyncResultModal
			title="Approve Customer"
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-success"
			state={state}
			buttonText="Approve"
			successText="Approved"
			onSubmit={() => approve(accessToken, application, reason, riskRate)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label">Common reasons</label>

				<div className="select is-fullwidth">
					<select
						defaultValue=""
						onChange={(e) => {
							setReason(e.target.value)
						}}
					>
						<option value="ID approved. ID, phone and IP address from same state.">
							ID approved. ID, phone and IP address from same state.
						</option>
						<option value="SSN miskeyed. Updated and reran and auto approved.">
							SSN miskeyed. Updated and reran and auto approved.
						</option>
						<option value="DOB miskeyed. Updated and reran and auto approved.">
							DOB miskeyed. Updated and reran and auto approved.
						</option>
						<option value="Alloy job failed due to invalid email address. Updated and reran and auto approved.">
							Alloy job failed due to invalid email address. Updated and reran and auto approved.
						</option>
						<option value="ID approved. Phone in a different state than ID and IP address but LexisNexis found address history in that state.">
							ID approved. Phone in a different state than ID and IP address but LexisNexis found address history in
							that state.
						</option>
						<option value="Passport verified name, DOB and ID number. Address verification letter verified address.">
							Passport verified name, DOB and ID number. Address verification letter verified address.
						</option>
						<option value="">Other</option>
					</select>
				</div>
			</div>
			<div className="field">
				<label className="label">Reason</label>
				<div className="control">
					<textarea
						required
						className="textarea"
						placeholder="Why are you approving the customer?"
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">Customer Risk Rate</label>
				<div className="field-body">
					<div className="select">
						<select
							value={riskRate}
							onChange={(e) => {
								const val = e.target.value

								if (val == RiskRate.Low || val == RiskRate.Medium || val == RiskRate.High) {
									setRiskRate(val)
								}
							}}
						>
							<option value={RiskRate.Low} key={RiskRate.Low}>
								Low
							</option>
							<option value={RiskRate.Medium} key={RiskRate.Medium}>
								Medium
							</option>
							<option value={RiskRate.High} key={RiskRate.High}>
								High
							</option>
						</select>
					</div>
				</div>
			</div>
		</AsyncResultModal>
	)
}

export function ApproveApplicationModal({application, close, onSuccess}: ApplicationModalProps) {
	const accessToken = useAccessToken()
	const result = useAsyncResult(() => getOrg(accessToken, application.relationships.org.data.id))
	return result.match(
		() => <Loading />,
		(org) => <ApproveApplicationModalContent application={application} close={close} onSuccess={onSuccess} org={org} />,
		(err) => <Err err={err} />
	)
}

export function CancelApplicationModal({application, close, onSuccess}: ApplicationModalProps) {
	const accessToken = useAccessToken()
	const [state, cancel] = useAsyncResultIdle(cancelApplication)
	const [requestedBy, setRequestedBy] = useState<"Customer" | "Org">("Customer")
	const [reason, setReason] = useState<string>("")
	const textReason = reason ? `By ${requestedBy} - ${reason}` : `By ${requestedBy}`

	return (
		<AsyncResultModal
			title={`Cancel Application for ${getApplicationName(application)}`}
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-black"
			state={state}
			buttonText="Confirm"
			successText="Canceled"
			onSubmit={() => cancel(accessToken, application, textReason)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<label className="label cancel-application-requested-by">Requested by</label>
				<div className={"radio-select"}>
					<label>
						{" "}
						<input
							readOnly
							type="radio"
							checked={requestedBy == "Customer"}
							name="requestedBy"
							value={"customer"}
							onClick={() => setRequestedBy("Customer")}
						/>{" "}
						Customer{" "}
					</label>
					<label>
						{" "}
						<input
							readOnly
							type="radio"
							checked={requestedBy == "Org"}
							name="requestedBy"
							value={"org"}
							onClick={() => setRequestedBy("Org")}
						/>
						Org{" "}
					</label>
				</div>
				<div className="control">
					<label className="label">
						Description <span className={"optional"}> (Optional) </span>
					</label>
					<textarea
						className="textarea"
						placeholder="Why are you canceling this application? "
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</div>
			</div>
			<Admonition
				type={"is-danger"}
				message={
					<p>
						{
							"Canceling an application cannot be undone. To become a customer, a new application will have to be created."
						}
					</p>
				}
				title={"Warning"}
			/>
		</AsyncResultModal>
	)
}
