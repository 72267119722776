import classNames from "classnames"
import React from "react"

export type StatusBoxProps = {
	text: string
	color?: string
	backgroundColor?: string
	className?: string
}

export function StatusBox({text, color, backgroundColor, className}: StatusBoxProps) {
	return (
		<div className={classNames("title-bar-status", className)} style={{color: color, backgroundColor: backgroundColor}}>
			{text}
		</div>
	)
}
