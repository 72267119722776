import React, {RefObject} from "react"
import {AssignmentNavigatorItem} from "../AssignmentNavigatorItem/AssignmentNavigatorItem"
import {CombinedAssignmentItem} from "../AssignmentNavigator/AssignmentNavigator"

interface AssignmentNavigatorItemsListProps {
	assignments: CombinedAssignmentItem[]
	navigatorItemRef: RefObject<HTMLLIElement>
	noItemsMessage?: string
}
export function AssignmentNavigatorItemsList({
	assignments,
	navigatorItemRef,
	noItemsMessage,
}: AssignmentNavigatorItemsListProps) {
	return assignments.length > 0 ? (
		<ul className="assignment-navigator-items-list">
			{assignments.map((item, index) => (
				<li key={index} ref={item.selected ? navigatorItemRef : null}>
					<AssignmentNavigatorItem
						title={item.title}
						description={item.description}
						status={item.status}
						stage={item.stage}
						assignees={item.assignees}
						createdAt={item.createdAt}
						statusUpdatedAt={item.statusUpdatedAt}
						selected={item.selected}
						isOwnersItem={item.isOwnersItem}
						disabled={item.disabled}
						onClick={item.onClick}
					/>
				</li>
			))}
		</ul>
	) : (
		<span className="assignment-navigator-items-list-no-items">{noItemsMessage ?? "No Items"}</span>
	)
}
