import React from "react"
import classNames from "classnames"
import {startCase} from "lodash"
import {Assignee} from "../../resources/assignments"
import {AssigneeAvatar} from "../AssigneeAvatar/AssigneeAvatar"

interface AssigneeAvatarWithNameProps {
	assignees: Assignee[]
	size?: "small" | "medium"
}

const getAssigneeNames = (assignees: Assignee[]) => {
	return assignees.map(({name, type}) => startCase(name ?? type)).join(", ")
}

export function AssigneeAvatarWithName({assignees, size = "small"}: AssigneeAvatarWithNameProps) {
	const names = getAssigneeNames(assignees)
	return (
		<div className="assignee-avatar-with-name">
			<AssigneeAvatar assignees={assignees} size={size} />
			<div className={classNames("assignee-names", size)}>
				<span>{names}</span>
			</div>
		</div>
	)
}
