import {CashFlow, CashFlowMapping} from "../../resources/application"
import React from "react"
import {Editable, EditableDropDownField} from "../HorizontalField/EditableHorizontalField"

export interface EditableCashFlowProps {
	cashFlow?: CashFlow
	setCashFlow: (value: CashFlow) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: CashFlow) => void
	className?: string
}

export function EditableCashFlowField(props: EditableCashFlowProps) {
	if (props.cashFlow) {
		return (
			<Editable
				label="Cash Flow"
				isEditing={props.isEditing}
				isDisabled={props.isDisabled}
				isAllowed={props.isAllowed}
				onStartEdit={props.onStartEdit}
				onCancel={props.onCancel}
				setValue={props.setCashFlow}
				value={props.cashFlow}
				initial={props.cashFlow}
				update={props.update}
				className={props.className}
				possibleValues={CashFlowMapping}
			>
				{EditableDropDownField}
			</Editable>
		)
	}

	return null
}
