import React, {useState} from "react"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {createBankUser} from "../../resources/user"
import {Password, Username} from "./Shared"
import {SelectUserRoleField, UserRolesExplanationTooltip} from "./UserRoleField"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {fetchBankId} from "../../resources/bank"
import PhoneNumber from "../PhoneNumberLine/PhoneNumber"

type CloseFunc = () => void
type RefreshFunc = () => void

interface ModalProps {
	close: CloseFunc
	refresh: RefreshFunc
}

export function BankNewUserModal({close, refresh}: ModalProps) {
	const accessToken = useAccessToken()
	const [state, create] = useAsyncResultIdle(createBankUser)
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [role, setRole] = useState("admin")
	const bankIdState = useAsyncResult(() => fetchBankId(accessToken))
	const [countryCode, setCountryCode] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const bankId = bankIdState.match(
		() => undefined,
		(o) => o,
		() => undefined
	)

	return bankId ? (
		<AsyncResultModal
			title="Create new bank user"
			onSubmit={() => create(accessToken, username, password, bankId, role, {countryCode, number: phoneNumber})}
			close={close}
			state={state}
			buttonClassname="is-success"
			buttonText="Create"
			successText="Created"
			errorToText={(err) => err.errors[0].detail || err.errors[0].title}
			onSuccess={refresh}
		>
			<div className="field">
				<label className="label">
					User Role <UserRolesExplanationTooltip type="bankUser" />
				</label>
				<SelectUserRoleField type="bankUser" role={role} setRole={setRole} />
			</div>
			<Username username={username} setUsername={setUsername} />
			<Password password={password} setPassword={setPassword} />
			<div className="field">
				<PhoneNumber
					countryCodeValue={countryCode}
					countryCodeOnChange={setCountryCode}
					phoneNumberValue={phoneNumber}
					phoneNumberOnChange={setPhoneNumber}
				/>
			</div>
		</AsyncResultModal>
	) : null
}
