import React from "react"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"
import Skeleton from "react-loading-skeleton"

export interface InterestRateItemProps {
	icon: IcomoonIconName
	title?: string
	value?: string | number
	additionalInfo?: string
}
export const InterestRateItem = ({icon, title, value, additionalInfo}: InterestRateItemProps) => (
	<div className="interest-rate-item">
		<div className="interest-rate-item-title-container">
			{title ? <Icon icon={icon} size={20} /> : <Skeleton />}
			{title ? <span> {title} </span> : <Skeleton />}
		</div>
		<div className="interest-rate-item-value-container">{value ? <span>{value}</span> : <Skeleton />}</div>
		<div className="interest-rate-item-additional-info-container">
			{additionalInfo ? <span>{additionalInfo}</span> : <Skeleton />}
		</div>
	</div>
)
