import React, {useRef, useState} from "react"
import TitleBar, {MetaItemProps, TitleBarAction} from "../../components/TitleBar/TitleBar"
import {useAccessToken, useIsUnitComplianceUser, useIsUnitUser} from "../../services/auth"
import {MainSection} from "../../containers/MainSection/MainSection"
import HorizontalField, {optionalHorizontalField} from "../../components/HorizontalField/HorizontalField"
import moment from "moment"
import {CustomerStatus, CustomerType, CustomerTypeIcons, IndividualWalletCustomer} from "../../resources/customer"
import Card from "../../containers/Card/Card"
import {Transactions, TransactionsColumns} from "../../components/Transactions/Transactions"
import TagsViewer from "../../components/Tags/TagsViewer"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {emailPreview, InformationHiding, phonePreview} from "../../components/InformationHiding/InformationHiding"
import CustomerKnownNamesComponent from "../../components/CustomerKnownNames/CustomerKnownNames"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {getCustomerKnownNames, CustomerKnownNames} from "../../resources/customerKnownNames"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import CustomerCounterparties from "../../components/CustomerCounterParties/CustomerCounterparties"
import {HasPermission} from "../../containers/PermissionedUser/Permission"
import {useModal} from "react-modal-hook"
import {ArchiveCustomerModal} from "../Customer/ArchiveCustomerModal"
import {UnarchiveCustomerModal} from "../Customer/UnarchiveCustomerModal"
import {Disputes, DisputesColumns} from "../../components/Disputes/Disputes"
import {Accounts, AccountsColumns} from "../../components/Accounts/Accounts"
import {ActionButton, ActionItem} from "../../components/ActionButton/ActionButton"
import {TutorialModalProps} from "../../components/Tutorial/TutorialModal"
import {generateCreateAccountRequest} from "../../components/Tutorial/TutorialPayloads"
import {TutorialButton} from "../../components/Tutorial/TutorialButton"
import Chips from "../../components/Chips/Chips"
import {startCase} from "lodash"
import {isOrg, Org} from "../../resources/org"
import {SpecificCustomerProps} from "../Customer/Customer"
import {getAuditLogEventsHeaderMetaItems} from "../Customer/auditLogMetaItems"
import {useAuditLog} from "../../components/AuditLogDrawer/useAuditLog"
import AuditLogActionButton from "../../components/AuditLogActionButton/AuditLogActionButton"
import AuditLogDrawer from "../../components/AuditLogDrawer/AuditLogDrawer"

export function IndividualWallet({value: customer, refresh}: SpecificCustomerProps<IndividualWalletCustomer>) {
	const accessToken = useAccessToken()
	const individualWallet = customer.data
	const {attributes} = individualWallet
	const name = `${attributes.fullName.first} ${attributes.fullName.last}`
	const org = customer.included?.find((r) => isOrg(r) && r.id == individualWallet.relationships.org.data.id)
	const [refreshToken] = useRefreshToken()
	const [payload, setPayload] = useState(generateCreateAccountRequest(individualWallet.id))
	const [accountsRefreshToken, refreshAccounts] = useRefreshToken()

	const isArchived = attributes.status == CustomerStatus.Archived
	const isHiddenIconVisible = !useIsUnitComplianceUser()
	const [showArchiveCustomer, hideArchiveCustomer] = useModal(() => (
		<ArchiveCustomerModal closeModal={hideArchiveCustomer} customer={individualWallet} onSuccess={refresh} />
	))
	const [showUnarchiveCustomer, hideUnarchiveCustomer] = useModal(() => (
		<UnarchiveCustomerModal closeModal={hideUnarchiveCustomer} customer={individualWallet} onSuccess={refresh} />
	))

	const customerKnownNamesResult = useIsUnitUser()
		? useAsyncResult<OkDocument<CustomerKnownNames>, ErrorDocument>(
				() => getCustomerKnownNames(accessToken, individualWallet.id),
				[refreshToken]
		  )
		: null

	const applicationLink = individualWallet.relationships.application
		? `/applications/individual/${individualWallet.relationships.application.data.id}`
		: undefined

	const renderPhone = () => (
		<HorizontalField label="Phone" fieldClassName={isArchived ? "archived-field" : ""}>
			<InformationHiding
				element={(phoneNumber) => <>{phoneNumber}</>}
				getValue={() => `+${attributes.phone?.countryCode} ${attributes.phone?.number}`}
				placeholder={phonePreview(attributes.phone.number)}
				defaultIsHidden={isHiddenIconVisible}
			/>
		</HorizontalField>
	)

	const renderEmail = () =>
		attributes.email ? (
			<HorizontalField label="Email" fieldClassName={isArchived ? "archived-field" : ""}>
				<InformationHiding
					element={(email) => <>{email}</>}
					getValue={() => attributes.email ?? ""}
					placeholder={emailPreview(attributes.email)}
					defaultIsHidden={isHiddenIconVisible}
				/>
			</HorizontalField>
		) : null

	const ref = useRef(null)
	const tutorialProps: TutorialModalProps = {
		tooltipId: "accountTutorial",
		parentRef: ref,

		actions: [
			{
				actionName: "Create Account",
				title: "Test Create Account API",
				description: "Create a deposit account for your customer",
				successMessage: "Success! You can now see your new deposit account in the dashboard",
				errorMessage: "There was an error creating the deposit account",
				request: {
					method: "POST",
					path: "accounts",
					payload: payload,
					headers: [
						{headerKey: "Content-Type", headerValue: "application/vnd.api+json"},
						{headerKey: "Cache-Control", headerValue: "no-cache"},
					],
				},
				refreshFunction: refreshAccounts,
				onSendAnotherRequest: () => {
					setPayload(generateCreateAccountRequest(individualWallet.id))
				},
			},
		],
	}
	const customerType = CustomerType.individual //individual and not individualWallet - by purpose
	const customerTypeDisplayText = startCase(customerType)
	const customerTypeIcon = CustomerTypeIcons[customerType]
	const auditLogEventsHeaderMetaItems = getAuditLogEventsHeaderMetaItems({
		customerName: name,
		customerType: customerTypeDisplayText,
		pageIcon: customerTypeIcon,
		org: org as Org,
	})
	const {showAuditLog, openAuditLogDrawer, closeAuditLogDrawer, isAuditLogDrawerOpened} = useAuditLog()
	return (
		<>
			<TitleBar
				title={name}
				breadcrumbs={[{text: "Customers", path: "/customers/"}]}
				meta={[
					{text: customerTypeDisplayText, icon: customerTypeIcon},
					...(applicationLink
						? [{text: "Application", icon: "user-id-card", path: applicationLink} as MetaItemProps]
						: []),
				]}
				status={isArchived ? {text: "Archived", color: "#B0B2B5", backgroundColor: "#EBECEE"} : undefined}
			>
				{showAuditLog && <AuditLogActionButton onClick={openAuditLogDrawer} />}
				{attributes.status != CustomerStatus.Archived ? (
					<HasPermission resource="customer.status" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Archive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showArchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				) : (
					<HasPermission resource="customer.status.unarchive" action="update">
						<TitleBarAction>
							<ActionButton enableActions={true}>
								<ActionItem
									title={"Unarchive customer"}
									color={"#FF506F"}
									icon={"user-geometric-action-cross-remove"}
									onClick={showUnarchiveCustomer}
								/>
							</ActionButton>
						</TitleBarAction>
					</HasPermission>
				)}
				<TutorialButton tutorialModalProps={tutorialProps} buttonClassname="space-left" />
			</TitleBar>

			<MainSection>
				<AuditLogDrawer
					open={isAuditLogDrawerOpened}
					onClose={closeAuditLogDrawer}
					auditLogEventsHeaderMetaItems={auditLogEventsHeaderMetaItems}
					resourceType="customer"
					resourceId={individualWallet.id}
				/>
				<div className="columns">
					<div className="column is-4">
						<Card title="Supported Financial Products">
							<Chips labels={["walletAccount"]} readonly />
						</Card>
						<div className="columns">
							<div className="column">
								<div className="card">
									<div className="card-header">
										<p className="card-header-title">Individual Information</p>
									</div>
									<div className="card-content">
										<UnitUserOnly>
											{optionalHorizontalField(
												"Org",
												org && org.type == "org" ? org.attributes.name : undefined,
												undefined,
												isArchived ? "archived-field" : ""
											)}
										</UnitUserOnly>
										<HorizontalField label="Customer Id" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" className="input is-static" readOnly value={individualWallet.id} />
										</HorizontalField>
										<HorizontalField label="Name" fieldClassName={isArchived ? "archived-field" : ""}>
											<input type="text" className="input is-static" readOnly value={name} />
										</HorizontalField>
										<HorizontalField label="Created At" fieldClassName={isArchived ? "archived-field" : ""}>
											<input
												type="text"
												readOnly
												value={moment(attributes.createdAt).format("L LT")}
												className="input is-static"
											/>
										</HorizontalField>
										{renderPhone()}
										{renderEmail()}
									</div>
								</div>
							</div>
						</div>

						<Card title="Counterparties">
							<CustomerCounterparties customerId={individualWallet.id} limit={100} isArchived={isArchived} />
						</Card>

						{Object.keys(attributes.tags).length > 0 ? (
							<div className="columns">
								<div className="column">
									<div className="card">
										<div className="card-header">
											<p className="card-header-title archived-field">Tags</p>
										</div>
										<div className="card-content">
											<TagsViewer tags={attributes.tags} fieldClassName={isArchived ? "archived-field" : ""} />
										</div>
									</div>
								</div>
							</div>
						) : null}
						{customerKnownNamesResult?.match(
							() => null,
							(aliases: any) => (
								<div className="columns">
									<div className="column">
										<div className="card">
											<div className="card-header">
												<p className="card-header-title">Known Aliases</p>
											</div>
											<div className="card-content">
												<CustomerKnownNamesComponent
													customerId={individualWallet.id}
													names={aliases.data.attributes.names}
													refresh={refresh}
													fieldClassName={"archived-field"}
												/>
											</div>
										</div>
									</div>
								</div>
							),
							() => null
						)}
					</div>
					<div className="column">
						<Accounts
							customerId={individualWallet.id}
							enableTitle
							fullHeight={false}
							includedColumns={[
								AccountsColumns.id,
								AccountsColumns.balance,
								AccountsColumns.type,
								AccountsColumns.product,
								AccountsColumns.status,
								AccountsColumns.routingNumber,
								AccountsColumns.accountNumber,
								AccountsColumns.purpose,
								AccountsColumns.createdAt,
							]}
							refreshToken={accountsRefreshToken}
							enableTutorial={true}
						/>
						<Transactions
							fullHeight={false}
							customerId={individualWallet.id}
							limit={10}
							isUsingPaging={true}
							includedColumns={[
								TransactionsColumns.id,
								TransactionsColumns.type,
								TransactionsColumns.amount,
								TransactionsColumns.balance,
								TransactionsColumns.summary,
								TransactionsColumns.createdAt,
							]}
						/>

						<Disputes
							enableTitle={true}
							fullHeight={false}
							limit={10}
							customerId={individualWallet.id}
							includedColumns={[
								DisputesColumns.id,
								DisputesColumns.source,
								DisputesColumns.amount,
								DisputesColumns.createdAt,
								DisputesColumns.status,
								DisputesColumns.updatedAt,
							]}
						/>
					</div>
				</div>
			</MainSection>
		</>
	)
}
