import {useCopyToClipboard} from "react-use"
import React, {useState} from "react"
import Editor, {useMonaco} from "@monaco-editor/react"
import classNames from "classnames"
import Icon from "../Icon/Icon"
import {editor} from "monaco-editor"
import BuiltinTheme = editor.BuiltinTheme

export default function JsonEditor({payload}: {payload: string}) {
	const [, copyToClipboard] = useCopyToClipboard()
	const [isVisible, setIsVisible] = useState(false)
	const monaco = useMonaco()
	const theme = {
		base: "vs-dark" as BuiltinTheme,
		inherit: true,
		rules: [
			{token: "string.key.json", foreground: "30E87A"},
			{token: "string.value.json", foreground: "E2E4E7"},
		],
		colors: {
			"editor.background": "#000000",
		},
	}
	monaco?.editor.defineTheme("test", theme)
	monaco?.editor.setTheme("test")

	return (
		<div className="json-editor-container">
			<Editor
				language="json"
				value={formatJSON(payload)}
				theme="test"
				options={{
					readOnly: true,
					scrollBeyondLastLine: false,
					minimap: {enabled: false},
					renderIndentGuides: false,
					tabSize: 1,
					insertSpaces: true,
				}}
			/>
			<div className="copy-to-clipboard-container">
				<div className={classNames("copied-label-container", isVisible && "show-and-fade-out")}>
					<Icon icon={"interface-validation-check--interface-essential"} size={12} />
					<span className="copied-label">Copied!</span>
				</div>
				<button
					className="copy-to-clipboard"
					onClick={() => {
						setIsVisible(false)
						copyToClipboard(payload)
						setTimeout(() => {
							setIsVisible(true)
						}, 10)
					}}
				>
					<Icon icon="paginate-filter-document-copy" color="#808285" size={16} />
				</button>
			</div>
		</div>
	)
}

function formatJSON(val = "{}") {
	try {
		const res = JSON.parse(val)
		return JSON.stringify(res, null, 2)
	} catch {
		const errorJson = {
			error: `${val}`,
		}
		return JSON.stringify(errorJson, null, 2)
	}
}
