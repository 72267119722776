import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import {BusinessDebitCard, IndividualDebitCard, isBusinessDebitCard, replaceCard} from "../../resources/card"
import {IndividualCustomer} from "../../resources/customer"
import Admonition from "../Admonitions/Admonition"
import {Address} from "../../resources/common"
import {Formik} from "formik"
import AddressComponent, {addressValidationSchema, focusFirstInvalidField} from "../Address/Address"
import * as Yup from "yup"
import classNames from "classnames"
import Icon from "../Icon/Icon"

interface ReplaceCardModalProps {
	card: BusinessDebitCard | IndividualDebitCard
	close: () => void
	onSuccess: () => void
	customer?: IndividualCustomer
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

function ReplaceCardSuccess({cardLast, name}: {cardLast: string; name: string}) {
	return (
		<div className={"replace-card-success"}>
			<div className={"replace-card-success-icon-placeholder"}>
				<Icon icon={"credit-card-check-approved--business-products"} size={32} />
			</div>
			<h3>Replacement card ordered successfully!</h3>

			<p className={"replace-card-description"}>
				You order for a replacement card for card ending in <span>*****{cardLast}</span> for <span>{name}</span>{" "}
				processed successfully
			</p>
		</div>
	)
}

export function ReplaceCardModal({card, close, onSuccess, customer}: ReplaceCardModalProps) {
	const supportedCountry = "US"
	const accessToken = useAccessToken()
	const [replaceState, setReplaceState] = useAsyncResultIdle(replaceCard)
	const validationSchema = Yup.object().shape(addressValidationSchema)
	const [otherAddress, showOtherAddress] = useState(false)
	const [isResult, receiveResult] = useState(false)
	let cardHolderName = ""
	let cardHolderAddress: Address = {
		street: "",
		street2: "",
		city: "",
		state: "",
		postalCode: "",
		country: supportedCountry,
	}

	if (isBusinessDebitCard(card)) {
		cardHolderName = `${card.attributes.fullName.first} ${card.attributes.fullName.last}`
		cardHolderAddress = card.attributes.address
	} else if (customer) {
		cardHolderName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		cardHolderAddress = customer.attributes.address as Address
	}

	const initialFormValues = {
		street: cardHolderAddress?.street ?? "",
		street2: cardHolderAddress?.street2 ?? "",
		city: cardHolderAddress?.city ?? "",
		country: supportedCountry,
		state: cardHolderAddress?.state ?? "",
		postalCode: cardHolderAddress?.postalCode ?? "",
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				setReplaceState(accessToken, card, values as Address).then(() => {
					receiveResult(true)
				})
			}}
		>
			{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur, resetForm}) => (
				<AsyncResultModal
					title="Order Replacement Card"
					classname={classNames("card-replacement-modal", isResult && "card-replacement-modal-final")}
					close={refreshAndClose(onSuccess, close)}
					buttonClassname="is-black"
					blockBodyScroll={true}
					state={replaceState}
					okComponent={<ReplaceCardSuccess name={cardHolderName} cardLast={card.attributes.last4Digits} />}
					disableCloseOnSuccess={true}
					buttonText="Order Replacement"
					successText="Done"
					enableClickAway={false}
					onSubmit={(e: React.FormEvent<HTMLFormElement> | undefined) => {
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
					errorToText={(err) => err.errors[0].title}
				>
					<p className={"replace-card-description"}>
						{" "}
						You are about to order a replacement card for card ending in <br />{" "}
						<span> *****{card.attributes.last4Digits} </span> for <span> {cardHolderName} </span>
					</p>

					<div className="field">
						<div className={"field-address"}>
							<span className={"field-address-delivery"}> Delivery Address </span>

							<div className={"field-address-delivery-box"}>
								<label>
									<input
										checked={!otherAddress}
										onChange={() => {
											showOtherAddress(false)
											resetForm({
												values: initialFormValues,
											})
										}}
										type="radio"
										name="custom-address"
									/>
									Customer address
								</label>
								{!otherAddress ? (
									<AddressComponent
										closed={true}
										street={values.street}
										street2={values.street2}
										city={values.city}
										country={supportedCountry}
										state={values.state}
										postalCode={values.postalCode}
										handleChange={handleChange}
										handleBlur={handleBlur}
										setFieldValue={setFieldValue}
									/>
								) : null}
							</div>

							<div className={classNames(otherAddress && "field-address-delivery-box-placeholder")}>
								<div className={"field-address-delivery-box"}>
									<label>
										<input
											checked={otherAddress}
											onChange={() => {
												showOtherAddress(true)
												resetForm({
													values: {
														street: "",
														street2: "",
														city: "",
														country: supportedCountry,
														state: "",
														postalCode: "",
													},
												})
											}}
											type="radio"
											name="custom-address"
										/>
										Other address
									</label>
								</div>
								{otherAddress ? (
									<AddressComponent
										closed={false}
										street={values.street}
										street2={values.street2}
										city={values.city}
										country={supportedCountry}
										state={values.state}
										postalCode={values.postalCode}
										handleChange={handleChange}
										handleBlur={handleBlur}
										setFieldValue={setFieldValue}
									/>
								) : null}
							</div>
						</div>
					</div>

					<Admonition
						type={"is-warning"}
						message={
							<p>
								{" "}
								The current card will still be active. If you have previously ordered a replacement card and it has not
								arrived yet, please reach out to Unit to check on its status.{" "}
							</p>
						}
						title={"Note"}
					/>
				</AsyncResultModal>
			)}
		</Formik>
	)
}
