import {createResource, getResource, updateResource} from "./common"
import {isEmpty, omitBy} from "lodash"
import jsonMergePatch from "json-merge-patch"

export enum BinType {
	Credit = "Credit",
	Debit = "Debit",
	Prepaid = "Prepaid",
	BankCredit = "BankCredit",
}

export function isCreditBinType(binType: BinType): boolean {
	switch (binType) {
		case BinType.Credit:
		case BinType.BankCredit:
			return true
		case BinType.Debit:
		case BinType.Prepaid:
			return false
	}
}

export enum BinProduct {
	Business = "Business",
	Individual = "Individual",
	FSA = "FSA",
	HRA = "HRA",
	HSA = "HSA",
}
export enum BinPrinter {
	CPI = "CPI",
	Idemia = "Idemia",
}

export enum Brand {
	Visa = "Visa",
	Mastercard = "Mastercard",
}

export type CreditSpendAssessmentInfo = {
	creditSpendProductId: string
	creditSpendRpin: string
}

export type Bin = {
	type: "bin"
	id: string
	attributes: {
		name: string
		institutionId: string
		bin: string
		binType: BinType
		product: BinProduct
		printer?: BinPrinter
		brand: Brand
		creditSpendAssessmentInfo?: CreditSpendAssessmentInfo
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
	token: string | null
}

export async function findBins(
	accessToken: string,
	offset: number,
	limit: number,
	orgs?: string[],
	searchQuery?: string,
	include?: string
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : null

	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			orgs: orgFilter,
			query: searchQuery != "" ? searchQuery : null,
		},
		include: include,
	}

	return await getResource<Array<Bin>>("bins", accessToken, query)
}

export async function getBin<Bin>(accessToken: string, id: string) {
	const result = await getResource<Bin>(`bins/${id}`, accessToken)

	return result.map((v) => v.data)
}

export async function createBin(
	accessToken: string,
	name: string,
	institutionId: string,
	bin: string,
	orgId: string,
	bankId: string,
	binType: BinType,
	product: BinProduct,
	brand: Brand,
	printer?: BinPrinter,
	creditSpendAssessmentInfo?: CreditSpendAssessmentInfo
) {
	const result = await createResource<Bin>("bins", accessToken, {
		type: "bin",
		attributes: omitBy(
			{
				name,
				institutionId,
				bin,
				binType,
				product,
				printer,
				brand,
				creditSpendAssessmentInfo,
			},
			isEmpty
		),
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
		},
	})
	return result.map((v) => v.data)
}

export async function updateBin(
	accessToken: string,
	name: string,
	institutionId: string,
	bin: string,
	orgId: string,
	bankId: string,
	binType: BinType,
	product: BinProduct,
	currentBin: Bin,
	brand: Brand,
	printer?: BinPrinter | null,
	creditSpendAssessmentInfo?: CreditSpendAssessmentInfo | null
) {
	const currentPatchBin = omitBy(
		{
			attributes: {
				name: currentBin.attributes.name,
				institutionId: currentBin.attributes.institutionId,
				bin: currentBin.attributes.bin,
				binType: currentBin.attributes.binType,
				product: currentBin.attributes.product,
				printer: currentBin.attributes.printer,
				brand: currentBin.attributes.brand,
				creditSpendAssessmentInfo: currentBin.attributes.creditSpendAssessmentInfo,
			},
			relationships: {
				org: {
					data: {
						type: "org",
						id: currentBin.relationships.org.data.id,
					},
				},
				bank: {
					data: {
						type: "bank",
						id: currentBin.relationships.bank.data.id,
					},
				},
			},
		},
		isEmpty
	)
	const newBin = {
		type: "bin",
		attributes: {
			name,
			institutionId,
			bin,
			binType,
			product,
			printer,
			brand,
			creditSpendAssessmentInfo,
		},
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
			bank: {
				data: {
					type: "bank",
					id: bankId,
				},
			},
		},
	}

	const result = await updateResource<Bin>(
		`bins/${currentBin.id}/edit`,
		accessToken,
		jsonMergePatch.generate(currentPatchBin, newBin)
	)

	return result.map((v) => v.data)
}
