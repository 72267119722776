import {useAccessToken, useIsBankUser, useIsUnitUser} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {findAgreements, OrgBankAgreement, Status} from "../../resources/orgBankAgreement"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import React, {useEffect} from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSectionTable} from "../../containers/MainSection/MainSection"
import moment from "moment"
import PagingNavBar from "../../components/PagingNavBar/PagingNavBar"
import {useNavigate} from "react-router-dom"
import {Filter} from "../../components/Filter/Filter"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument} from "../../resources/common"
import {useQueryState} from "use-location-state"
import SearchBox from "../../components/SearchBox/SearchBox"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../../components/DataTable/DataTable"
import Icon from "../../components/Icon/Icon"
import classNames from "classnames"
import {hasPermission} from "../../services/permission"

function OrgBankAgreementTableRow({orgBankAgreement}: {orgBankAgreement: OrgBankAgreement}) {
	const navigate = useNavigate()
	const disabledStatusOnly =
		orgBankAgreement.attributes.status == Status.Disabled ? orgBankAgreement.attributes.status : undefined
	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/org-bank-agreements/${orgBankAgreement.id}`)
			}}
		>
			<DataTableCell className={"data-table-id-cell"}>
				<span className="data-table-id"> {orgBankAgreement.id} </span>
			</DataTableCell>
			<DataTableCell>
				{" "}
				{disabledStatusOnly && <div className={classNames("agreement-status")}>{disabledStatusOnly}</div>}
			</DataTableCell>
			<DataTableCell>{orgBankAgreement.attributes.name}</DataTableCell>
			<td>{moment(orgBankAgreement.attributes.createdAt).format("L")}</td>
		</DataTableRow>
	)
}

export default function OrgBankAgreements() {
	const PAGE_SIZE = 25
	const accessToken = useAccessToken()
	const navigate = useNavigate()
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()
	const isUnitOrBankUser = isUnitUser || isBankUser
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>("filter[orgs]", [])
	const filterEnabledOnly = !isUnitUser
	const orgs = isUnitOrBankUser
		? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
		: AsyncResult.pending<Org[], ErrorDocument>()

	const [query, setQuery] = useQueryState("filter[query]", "")

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		PAGE_SIZE,
		(offset, limit) =>
			findAgreements({
				accessToken,
				offset,
				limit,
				orgs: filteredOrgs,
				enabledOnly: filterEnabledOnly,
				searchQuery: query,
			}),
		(x) => x.length,
		[filteredOrgs.join(","), query]
	)

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs Filter"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(PAGE_SIZE)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Agreements", 500, onSearch)
	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(PAGE_SIZE)
	}

	return (
		<AsyncResultComponent asyncResult={result} pendingComponent={<TablePending />}>
			{({value: agreements}) => {
				const rows = (agreements as OrgBankAgreement[]).map((a) => (
					<OrgBankAgreementTableRow key={a.attributes.name} orgBankAgreement={a} />
				))

				return (
					<>
						<TitleBar title={"Org Bank Agreements"}>
							{hasPermission("orgBankAgreement", "create") && (
								<button className="button button-create" onClick={() => navigate("/org-bank-agreements/new")}>
									<Icon icon="interface-add-1" size={12} /> Create
								</button>
							)}
						</TitleBar>
						<MainSectionTable>
							<DataTableCard className={"bank-agreements-card"}>
								<DataTableActionHeader enableSticky={true} searchBox={searchBox} filters={[orgFilter]} />
								<div className={"bank-agreements-table"}>
									<DataTable>
										<DataTableHead>
											<DataTableRow>
												<DataTableCell>Id</DataTableCell>
												<DataTableCell>Status</DataTableCell>
												<DataTableCell>Name</DataTableCell>
												<DataTableCell>Created At</DataTableCell>
											</DataTableRow>
										</DataTableHead>
										<DataTableBody>{rows}</DataTableBody>
									</DataTable>
								</div>
								<PagingNavBar hasResults={hasResults} hasPrev={hasPrev} hasNext={hasNext} prev={prev} next={next} />
							</DataTableCard>
						</MainSectionTable>
					</>
				)
			}}
		</AsyncResultComponent>
	)
}
