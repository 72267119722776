import React, {useState} from "react"
import {useAccessToken, useUserId} from "../../services/auth"
import {changePassword} from "../../resources/user"
import TitleBar from "../../components/TitleBar/TitleBar"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import classNames from "classnames"
import {MainSection} from "../../containers/MainSection/MainSection"
import {useParams} from "react-router"
import {ChangePasswordAttributes} from "./ChangePasswordAttributes"

interface FormProps {
	savePassword: (
		accessToken: string,
		userId: string,
		oldPassword: string | undefined,
		newPassword: string,
		confirmNewPassword: string
	) => void
	formStatus: FormStatus
	error: string
	userId: string
}

enum FormStatus {
	Idle = 1,
	InProgress,
	Success,
	Failure,
}

function Form({savePassword, formStatus, error, userId}: FormProps) {
	const accessToken = useAccessToken()

	const [newPassword, setNewPassword] = useState("")
	const [oldPassword, setOldPassword] = useState<string | undefined>(undefined)
	const [confirmNewPassword, setConfirmNewPassword] = useState("")
	const [isPasswordGenerated, setIsPasswordGenerated] = useState(false)

	const buttonClassnames = classNames("button is-primary", {"is-loading": formStatus === FormStatus.InProgress})

	return (
		<div>
			<TitleBar title={"Change Password"} />
			<MainSection>
				<div className="columns">
					<div className="column is-half-widescreen is-one-third-fullhd">
						<form
							onSubmit={(e) => {
								e.preventDefault()
								savePassword(accessToken, userId, oldPassword, newPassword, confirmNewPassword)
							}}
						>
							<div className="box">
								<fieldset disabled={formStatus === FormStatus.InProgress || formStatus === FormStatus.Success}>
									<ChangePasswordAttributes
										userId={userId}
										oldPassword={oldPassword}
										setOldPassword={setOldPassword}
										newPassword={newPassword}
										setNewPassword={setNewPassword}
										isPasswordGenerated={isPasswordGenerated}
										setIsPasswordGenerated={setIsPasswordGenerated}
										confirmNewPassword={confirmNewPassword}
										setConfirmNewPassword={setConfirmNewPassword}
									/>
									<div className="field">
										<div className="control">
											<input
												type="submit"
												className={buttonClassnames}
												value={formStatus === FormStatus.Success ? "Saved" : "Save"}
												disabled={formStatus === FormStatus.InProgress || formStatus === FormStatus.Success}
											/>
										</div>
										{error ? <p className="help is-danger">{error}</p> : null}
									</div>
								</fieldset>
							</div>
						</form>
					</div>
				</div>
			</MainSection>
		</div>
	)
}

export default function ChangePassword() {
	const {userId: paramUserId} = useParams<{userId: string}>()
	const currentUserId = useUserId()

	const userId = paramUserId || currentUserId

	const [state, save] = useAsyncResultIdle(changePassword)
	return state.match(
		() => <Form userId={userId} formStatus={FormStatus.Idle} savePassword={save} error="" />,
		() => <Form userId={userId} formStatus={FormStatus.InProgress} savePassword={save} error="" />,
		(_) => <Form userId={userId} formStatus={FormStatus.Success} savePassword={save} error="" />,
		(e) => <Form userId={userId} formStatus={FormStatus.Failure} savePassword={save} error={e.errors[0].title} />
	)
}
