import React, {Dispatch, SetStateAction} from "react"
import {toNumber} from "lodash"
import {EditMode} from "../../resources/common"

interface Props {
	editMode: EditMode
	name: string
	// DataSeers
	achLoadsDaily: string
	achLoadsMonthly: string
	cardLoadsDaily: string
	cardLoadsMonthly: string
	cashLoadsPerTransaction: string
	cashLoadsDaily: string
	cashLoadsMonthly: string
	totalCashMonthly: string
	checkLoadsDaily: string
	checkLoadsMonthly: string
	wireTransferLoadsDaily: string
	wireTransferLoadsMonthly: string
	totalPurchasesDaily: string
	totalPurchasesMonthly: string
	achDebitDaily: string
	achDebitMonthly: string
	totalATMTransactionsDaily: string
	totalATMTransactionsMonthly: string
	totalDebitsDaily: string
	totalDebitsMonthly: string
	totalCreditsDaily: string
	totalCreditsMonthly: string
	P2PTransfersDebitDaily: string
	P2PTransfersDebitMonthly: string
	P2PTransfersCreditDaily: string
	P2PTransfersCreditMonthly: string
	totalP2PTransfersMonthly: string
	networkLoadsMonthly: string
	manualLoadsMonthly: string
	automatedLoadsMonthly: string
	cashLoadsDailyCount: string
	cashLoadsWeeklyCount: string
	cashLoadsWeeklyVolume: string
	totalATMTransactionsDailyCount: string
	totalATMTransactionsWeeklyVolume: string
	totalATMTransactionsWeeklyCount: string
	internationalATMTransactionsDailyVolume: string
	internationalATMTransactionsDailyCount: string
	internationalATMTransactionsWeeklyVolume: string
	internationalATMTransactionsWeeklyCount: string
	outgoingWireTransferLoadsDailyVolume: string
	p2pTransfersDebitWeeklyVolume: string
	p2pTransfersCreditWeeklyVolume: string
	c2cTransfersDebitWeeklyVolume: string
	c2cTransfersCreditWeeklyVolume: string
	outgoingWireTransferLoadsMonthlyVolume: string

	// CA
	varPlinAmount: number
	varPloutAmount: number
	varHfinNumber: number
	varHfoutNumber: number
	varPlmhinNumber: number
	varPlmhinAmount: number
	varPlmhinFactor: number
	varPlmhoutNumber: number
	varPlmhoutAmount: number
	varPlmhoutFactor: number
	varHfhainNumber: number
	varHfhainFactor: number
	varHfhaoutNumber: number
	varHfhaoutFactor: number
	varStrinNumber: number
	varStroutNumber: number
	varPgainAmount: number
	varPgainPercent: number
	varPgaoutAmount: number
	varPgaoutPercent: number
	varHsumcIAmount: number
	varHsumcOAmount: number
	varHvalcOAmount: number
	varHvalcIAmount: number
	varPbalBalance: number
	varPbalPercentage: number
	varPHsumiAmount: number
	varPHsumoAmount: number
	varOutinAmount: number
	varOutinPercentageLow: number
	varOutinPercentageHigh: number
	varInoutAmount: number
	varInoutPercentageLow: number
	varInoutPercentageHigh: number
	varHrcouAmount: number
	varNumcrdrfNumber: number
	varCrefsumAmount: number
	varCrefsumNumber: number
	varCrefsumFactor: number
	varHrmrchntAmount: number
	varHasumiAmount: number
	varHasumiFactor: number
	varHasumoAmount: number
	varHasumoFactor: number

	setName: Dispatch<SetStateAction<string>>
	//DataSeers
	setAchLoadsDaily: Dispatch<SetStateAction<string>>
	setAchLoadsMonthly: Dispatch<SetStateAction<string>>
	setCardLoadsDaily: Dispatch<SetStateAction<string>>
	setCardLoadsMonthly: Dispatch<SetStateAction<string>>
	setCashLoadsPerTransaction: Dispatch<SetStateAction<string>>
	setCashLoadsDaily: Dispatch<SetStateAction<string>>
	setCashLoadsMonthly: Dispatch<SetStateAction<string>>
	setTotalCashMonthly: Dispatch<SetStateAction<string>>
	setCheckLoadsDaily: Dispatch<SetStateAction<string>>
	setCheckLoadsMonthly: Dispatch<SetStateAction<string>>
	setWireTransferLoadsDaily: Dispatch<SetStateAction<string>>
	setWireTransferLoadsMonthly: Dispatch<SetStateAction<string>>
	setTotalPurchasesDaily: Dispatch<SetStateAction<string>>
	setTotalPurchasesMonthly: Dispatch<SetStateAction<string>>
	setAchDebitDaily: Dispatch<SetStateAction<string>>
	setAchDebitMonthly: Dispatch<SetStateAction<string>>
	setTotalATMTransactionsDaily: Dispatch<SetStateAction<string>>
	setTotalATMTransactionsMonthly: Dispatch<SetStateAction<string>>
	setTotalDebitsDaily: Dispatch<SetStateAction<string>>
	setTotalDebitsMonthly: Dispatch<SetStateAction<string>>
	setTotalCreditsDaily: Dispatch<SetStateAction<string>>
	setTotalCreditsMonthly: Dispatch<SetStateAction<string>>
	setP2PTransfersDebitDaily: Dispatch<SetStateAction<string>>
	setP2PTransfersDebitMonthly: Dispatch<SetStateAction<string>>
	setP2PTransfersCreditDaily: Dispatch<SetStateAction<string>>
	setP2PTransfersCreditMonthly: Dispatch<SetStateAction<string>>
	setTotalP2PTransfersMonthly: Dispatch<SetStateAction<string>>
	setNetworkLoadsMonthly: Dispatch<SetStateAction<string>>
	setManualLoadsMonthly: Dispatch<SetStateAction<string>>
	setAutomatedLoadsMonthly: Dispatch<SetStateAction<string>>
	setCashLoadsDailyCount: Dispatch<SetStateAction<string>>
	setCashLoadsWeeklyCount: Dispatch<SetStateAction<string>>
	setCashLoadsWeeklyVolume: Dispatch<SetStateAction<string>>
	setTotalATMTransactionsDailyCount: Dispatch<SetStateAction<string>>
	setTotalATMTransactionsWeeklyVolume: Dispatch<SetStateAction<string>>
	setTotalATMTransactionsWeeklyCount: Dispatch<SetStateAction<string>>
	setInternationalATMTransactionsDailyVolume: Dispatch<SetStateAction<string>>
	setInternationalATMTransactionsDailyCount: Dispatch<SetStateAction<string>>
	setInternationalATMTransactionsWeeklyVolume: Dispatch<SetStateAction<string>>
	setInternationalATMTransactionsWeeklyCount: Dispatch<SetStateAction<string>>
	setOutgoingWireTransferLoadsDailyVolume: Dispatch<SetStateAction<string>>
	setP2PTransfersDebitWeeklyVolume: Dispatch<SetStateAction<string>>
	setP2PTransfersCreditWeeklyVolume: Dispatch<SetStateAction<string>>
	setC2CTransfersDebitWeeklyVolume: Dispatch<SetStateAction<string>>
	setC2CTransfersCreditWeeklyVolume: Dispatch<SetStateAction<string>>
	setOutgoingWireTransferLoadsMonthlyVolume: Dispatch<SetStateAction<string>>
	//CA
	setVarPlinAmount: Dispatch<SetStateAction<number>>
	setVarPloutAmount: Dispatch<SetStateAction<number>>
	setVarHfinNumber: Dispatch<SetStateAction<number>>
	setVarHfoutNumber: Dispatch<SetStateAction<number>>
	setVarPlmhinNumber: Dispatch<SetStateAction<number>>
	setVarPlmhinAmount: Dispatch<SetStateAction<number>>
	setVarPlmhinFactor: Dispatch<SetStateAction<number>>
	setVarPlmhoutNumber: Dispatch<SetStateAction<number>>
	setVarPlmhoutAmount: Dispatch<SetStateAction<number>>
	setVarPlmhoutFactor: Dispatch<SetStateAction<number>>
	setVarHfhainNumber: Dispatch<SetStateAction<number>>
	setVarHfhainFactor: Dispatch<SetStateAction<number>>
	setVarHfhaoutNumber: Dispatch<SetStateAction<number>>
	setVarHfhaoutFactor: Dispatch<SetStateAction<number>>
	setVarStrinNumber: Dispatch<SetStateAction<number>>
	setVarStroutNumber: Dispatch<SetStateAction<number>>
	setVarPgainAmount: Dispatch<SetStateAction<number>>
	setVarPgainPercent: Dispatch<SetStateAction<number>>
	setVarPgaoutAmount: Dispatch<SetStateAction<number>>
	setVarPgaoutPercent: Dispatch<SetStateAction<number>>
	setVarHsumcIAmount: Dispatch<SetStateAction<number>>
	setVarHsumcOAmount: Dispatch<SetStateAction<number>>
	setVarHvalcOAmount: Dispatch<SetStateAction<number>>
	setVarHvalcIAmount: Dispatch<SetStateAction<number>>
	setVarPbalBalance: Dispatch<SetStateAction<number>>
	setVarPbalPercentage: Dispatch<SetStateAction<number>>
	setVarPHsumiAmount: Dispatch<SetStateAction<number>>
	setVarPHsumoAmount: Dispatch<SetStateAction<number>>
	setVarOutinAmount: Dispatch<SetStateAction<number>>
	setVarOutinPercentageLow: Dispatch<SetStateAction<number>>
	setVarOutinPercentageHigh: Dispatch<SetStateAction<number>>
	setVarInoutAmount: Dispatch<SetStateAction<number>>
	setVarInoutPercentageLow: Dispatch<SetStateAction<number>>
	setVarInoutPercentageHigh: Dispatch<SetStateAction<number>>
	setVarHrcouAmount: Dispatch<SetStateAction<number>>
	setVarNumcrdrfNumber: Dispatch<SetStateAction<number>>
	setVarCrefsumAmount: Dispatch<SetStateAction<number>>
	setVarCrefsumNumber: Dispatch<SetStateAction<number>>
	setVarCrefsumFactor: Dispatch<SetStateAction<number>>
	setVarHrmrchntAmount: Dispatch<SetStateAction<number>>
	setVarHasumiAmount: Dispatch<SetStateAction<number>>
	setVarHasumiFactor: Dispatch<SetStateAction<number>>
	setVarHasumoAmount: Dispatch<SetStateAction<number>>
	setVarHasumoFactor: Dispatch<SetStateAction<number>>
}

export function Attributes(props: Props) {
	return (
		<>
			<div className="columns">
				<div className="column">
					<div className="card">
						<div className="card-header">
							<p className="card-header-title">Basic</p>
						</div>
						<div className="card-content">
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Name</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.name}
												required
												onChange={(e) => props.setName(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<u>DataSeers</u> <br />
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">ACH Loads Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.achLoadsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setAchLoadsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">ACH Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.achLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setAchLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Card Loads Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cardLoadsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCardLoadsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Card Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cardLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCardLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Cash Loads Per Transaction</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cashLoadsPerTransaction}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCashLoadsPerTransaction(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Cash Loads Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cashLoadsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCashLoadsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Cash Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cashLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCashLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Cash Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalCashMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalCashMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Check Loads Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.checkLoadsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCheckLoadsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Check Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.checkLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCheckLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Wire Transfer Loads Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.wireTransferLoadsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setWireTransferLoadsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Wire Transfer Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.wireTransferLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setWireTransferLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Purchases Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalPurchasesDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalPurchasesDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Purchases Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalPurchasesMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalPurchasesMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">ACH Debit Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.achDebitDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setAchDebitDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">ACH Debit Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.achDebitMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setAchDebitMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total ATM Transactions Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalATMTransactionsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalATMTransactionsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total ATM Transactions Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalATMTransactionsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalATMTransactionsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Debits Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalDebitsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalDebitsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Debits Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalDebitsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalDebitsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Credits Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalCreditsDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalCreditsDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total Credits Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalCreditsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalCreditsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">P2P Transfers Debit Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.P2PTransfersDebitDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setP2PTransfersDebitDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">P2P Transfers Debit Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.P2PTransfersDebitMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setP2PTransfersDebitMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">P2P Transfers Credit Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.P2PTransfersCreditDaily}
												pattern={"[0-9]*"}
												onChange={(e) => props.setP2PTransfersCreditDaily(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">P2P Transfers Credit Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.P2PTransfersCreditMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setP2PTransfersCreditMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total P2P Transfers Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalP2PTransfersMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalP2PTransfersMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Network Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.networkLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setNetworkLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Manual Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.manualLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setManualLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Automated Loads Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.automatedLoadsMonthly}
												pattern={"[0-9]*"}
												onChange={(e) => props.setAutomatedLoadsMonthly(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Cash Loads Daily Count</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cashLoadsDailyCount}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCashLoadsDailyCount(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Cash Loads Weekly Count</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cashLoadsWeeklyCount}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCashLoadsWeeklyCount(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Cash Loads Weekly Volume</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.cashLoadsWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setCashLoadsWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total ATM Transactions Daily Count</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalATMTransactionsDailyCount}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalATMTransactionsDailyCount(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total ATM Transactions Weekly Volume</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalATMTransactionsWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalATMTransactionsWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Total ATM Transactions Weekly Count</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.totalATMTransactionsWeeklyCount}
												pattern={"[0-9]*"}
												onChange={(e) => props.setTotalATMTransactionsWeeklyCount(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">International ATM Transactions Daily Volume</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.internationalATMTransactionsDailyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setInternationalATMTransactionsDailyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">International ATM Transactions Daily Count</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.internationalATMTransactionsDailyCount}
												pattern={"[0-9]*"}
												onChange={(e) => props.setInternationalATMTransactionsDailyCount(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">International ATM Transactions Weekly Volume</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.internationalATMTransactionsWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setInternationalATMTransactionsWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">International ATM Transactions Weekly Count</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.internationalATMTransactionsWeeklyCount}
												pattern={"[0-9]*"}
												onChange={(e) => props.setInternationalATMTransactionsWeeklyCount(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Outgoing Wire Transfer Daily</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.outgoingWireTransferLoadsDailyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setOutgoingWireTransferLoadsDailyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">P2P Transfers Out Weekly Velocity</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.p2pTransfersDebitWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setP2PTransfersDebitWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">P2P Transfers in Weekly Velocity</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.p2pTransfersCreditWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setP2PTransfersCreditWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">C2C Transfers Out Weekly Velocity</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.c2cTransfersDebitWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setC2CTransfersDebitWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">C2C Transfers In Weekly Velocity</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.c2cTransfersCreditWeeklyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setC2CTransfersCreditWeeklyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">Outgoing Wire Transfer Monthly</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="text"
												value={props.outgoingWireTransferLoadsMonthlyVolume}
												pattern={"[0-9]*"}
												onChange={(e) => props.setOutgoingWireTransferLoadsMonthlyVolume(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<u>Comply Advantage</u> <br />
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plin_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlinAmount}
												required
												onChange={(e) => props.setVarPlinAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plout_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPloutAmount}
												required
												onChange={(e) => props.setVarPloutAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hfin_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHfinNumber}
												required
												onChange={(e) => props.setVarHfinNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hfout_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHfoutNumber}
												required
												onChange={(e) => props.setVarHfoutNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plmhin_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlmhinNumber}
												required
												onChange={(e) => props.setVarPlmhinNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plmhin_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlmhinAmount}
												required
												onChange={(e) => props.setVarPlmhinAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plmhin_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlmhinFactor}
												required
												onChange={(e) => props.setVarPlmhinFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plmhout_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlmhoutNumber}
												required
												onChange={(e) => props.setVarPlmhoutNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plmhout_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlmhoutAmount}
												required
												onChange={(e) => props.setVarPlmhoutAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_plmhout_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPlmhoutFactor}
												required
												onChange={(e) => props.setVarPlmhoutFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hfhain_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHfhainNumber}
												required
												onChange={(e) => props.setVarHfhainNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hfhain_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHfhainFactor}
												required
												onChange={(e) => props.setVarHfhainFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hfhaout_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHfhaoutNumber}
												required
												onChange={(e) => props.setVarHfhaoutNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hfhaout_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHfhaoutFactor}
												required
												onChange={(e) => props.setVarHfhaoutFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_strin_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varStrinNumber}
												required
												onChange={(e) => props.setVarStrinNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_strout_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varStroutNumber}
												required
												onChange={(e) => props.setVarStroutNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_pgain_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPgainAmount}
												required
												onChange={(e) => props.setVarPgainAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_pgain_percent</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPgainPercent}
												required
												onChange={(e) => props.setVarPgainPercent(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_pgaout_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPgaoutAmount}
												required
												onChange={(e) => props.setVarPgaoutAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_pgaout_percent</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPgaoutPercent}
												required
												onChange={(e) => props.setVarPgaoutPercent(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hsumc_i_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHsumcIAmount}
												required
												onChange={(e) => props.setVarHsumcIAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hsumc_o_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHsumcOAmount}
												required
												onChange={(e) => props.setVarHsumcOAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hvalc_o_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHvalcOAmount}
												required
												onChange={(e) => props.setVarHvalcOAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hvalc_i_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHvalcIAmount}
												required
												onChange={(e) => props.setVarHvalcIAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_pbal_balance</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPbalBalance}
												required
												onChange={(e) => props.setVarPbalBalance(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_pbal_percentage</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPbalPercentage}
												required
												onChange={(e) => props.setVarPbalPercentage(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_p_hsumi_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPHsumiAmount}
												required
												onChange={(e) => props.setVarPHsumiAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_p_hsumo_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varPHsumoAmount}
												required
												onChange={(e) => props.setVarPHsumoAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_outin_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varOutinAmount}
												required
												onChange={(e) => props.setVarOutinAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_outin_percentage_low</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varOutinPercentageLow}
												required
												onChange={(e) => props.setVarOutinPercentageLow(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_outin_percentage_high</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varOutinPercentageHigh}
												required
												onChange={(e) => props.setVarOutinPercentageHigh(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_inout_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varInoutAmount}
												required
												onChange={(e) => props.setVarInoutAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_inout_percentage_low</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varInoutPercentageLow}
												required
												onChange={(e) => props.setVarInoutPercentageLow(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_inout_percentage_high</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varInoutPercentageHigh}
												required
												onChange={(e) => props.setVarInoutPercentageHigh(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hrcou_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHrcouAmount}
												required
												onChange={(e) => props.setVarHrcouAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_numcrdrf_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varNumcrdrfNumber}
												required
												onChange={(e) => props.setVarNumcrdrfNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_crefsum_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varCrefsumAmount}
												required
												onChange={(e) => props.setVarCrefsumAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_crefsum_number</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varCrefsumNumber}
												required
												onChange={(e) => props.setVarCrefsumNumber(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_crefsum_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varCrefsumFactor}
												required
												onChange={(e) => props.setVarCrefsumFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hrmrchnt_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHrmrchntAmount}
												required
												onChange={(e) => props.setVarHrmrchntAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hasumi_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHasumiAmount}
												required
												onChange={(e) => props.setVarHasumiAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hasumi_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHasumiFactor}
												required
												onChange={(e) => props.setVarHasumiFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hasumo_amount</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHasumoAmount}
												required
												onChange={(e) => props.setVarHasumoAmount(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="field is-horizontal">
								<div className="field-label is-normal">
									<label className="label">var_hasumo_factor</label>
								</div>
								<div className="field-body">
									<div className="field has-addons">
										<div className="control">
											<input
												className="input"
												type="number"
												value={props.varHasumoFactor}
												required
												onChange={(e) => props.setVarHasumoFactor(toNumber(e.target.value))}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
