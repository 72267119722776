import {useAccessToken, useIsBankUser, useIsUnitUser} from "../../services/auth"
import {useParams} from "react-router-dom"
import React from "react"
import TitleBar, {MetaItemProps} from "../../components/TitleBar/TitleBar"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import classNames from "classnames"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {Customer} from "../../resources/customer"
import {Org} from "../../resources/org"
import {Account} from "../../resources/account"
import {getLinkedAccount, LinkedAccount} from "../../resources/linkedAccount"
import {
	LinkedAccountButtons,
	LinkedAccountComponent,
	LinkedAccountPlaidData,
} from "../../components/LinkedAccounts/LinkedAccount"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"

export default function LinkedAccountPage() {
	const accessToken = useAccessToken()
	const {linkedAccountId = ""} = useParams<{linkedAccountId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const include = useIsUnitUser() || useIsBankUser()
	const linkedAccountAsyncResultReq = useAsyncResult(
		() => getLinkedAccount(accessToken, linkedAccountId, include ? "customer,org" : undefined),
		[refreshToken]
	)
	return (
		<div className={"linked-accounts-full-page"}>
			<AsyncResultComponent asyncResult={linkedAccountAsyncResultReq}>
				{(value) => {
					const linkedAccountResponse = value.value as {data: LinkedAccount; included: Array<Account | Customer>}
					const linkedAccount = linkedAccountResponse.data
					const customers =
						linkedAccountResponse.included &&
						(linkedAccountResponse.included.filter((i) =>
							["individualCustomer", "businessCustomer"].includes(i.type)
						) as Customer[])
					const org =
						linkedAccountResponse.included &&
						(linkedAccountResponse.included.find((i) => (i.type as string) === "org") as unknown as Org)
					const account =
						linkedAccountResponse.included &&
						(linkedAccountResponse.included.find((i) => i.type === "depositAccount") as Account)
					const singleCustomer = Array.isArray(customers) && customers.length === 1 ? customers[0] : null
					const isPlaidLayout = useIsUnitUser()

					return (
						<>
							<TitleBar
								title={`#${linkedAccountId}`}
								breadcrumbs={[{text: "Linked Accounts", path: "/linked-accounts/"}]}
								meta={[
									{
										text: "Customer",
										icon: "user-geometric-action-money-fund---users",
										path: `/customer/${linkedAccount.relationships.customer?.data.id}`,
									},
									...(singleCustomer
										? [
												{
													text: "Application",
													icon: "user-id-card",
													path: `/applications/${singleCustomer?.relationships.application?.data.id}`,
													targetBlank: true,
												} as MetaItemProps,
										  ]
										: []),
								]}
							>
								<LinkedAccountButtons linkedAccount={linkedAccount} accessToken={accessToken} refresh={refresh} />
							</TitleBar>

							<MainSectionLayout
								outerWrapperClassname={classNames(isPlaidLayout && "linked-accounts-full-page-with-plaid")}
								renderAfter={isPlaidLayout ? <LinkedAccountPlaidData linkedAccount={linkedAccount} /> : <></>}
								fullWidth={isPlaidLayout}
							>
								<LinkedAccountComponent
									linkedAccountAsyncResult={linkedAccountAsyncResultReq}
									account={account}
									org={org}
									refresh={refresh}
									useDrawer={false}
									linkedAccountId={linkedAccountId}
									accessToken={accessToken}
									customers={customers}
								/>
							</MainSectionLayout>
						</>
					)
				}}
			</AsyncResultComponent>
		</div>
	)
}
