import HorizontalField from "../HorizontalField/HorizontalField"
import React from "react"
import {Editable, EditableElementProps} from "../HorizontalField/EditableHorizontalField"
import {startCase} from "lodash"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import {isSandboxEnv} from "../../utilities/environment"
import {BankUserRole, PartnerUserRole, UnitUserRole, UserRole, UserType} from "./types"

const orgRoles: Map<string, string> = new Map()
orgRoles.set(
	"admin",
	"Admin users are allowed to execute all the operations that are enabled for the organization, including creating and managing users."
)
orgRoles.set(
	"restricted",
	"Restricted users are allowed to execute most of the operations that are enabled for the organization. They are not allowed to create users, or to initiate operations that move funds outside of the Unit ecosystem."
)
orgRoles.set("it", "IT users are allowed to create and manage users for the organization.")
orgRoles.set(
	"readonly",
	"Readonly users are allowed to see relevant customer information, but are not allowed to make any changes."
)
orgRoles.set("readonly_no_pii", "This role allows read-only access excluding PII and sensitive-data.")

const orgsSpecificRoles: Map<string, Map<string, string>> = new Map()
orgsSpecificRoles.set("9", new Map([["9_agent", "This role allows read-only access excluding PII"]]))

const unitRoles: Map<string, string> = new Map()
//TODO add unit roles descriptions
unitRoles.set("unit-admin", "Unit admin user")
unitRoles.set("compliance", "Unit compliance user")
unitRoles.set("compliance-readonly", "Unit compliance readonly user")
unitRoles.set("unit-readonly", "Unit readonly user")
unitRoles.set("unit-sales", "Unit sales user")

const bankRoles: Map<string, string> = new Map()
bankRoles.set(
	"admin",
	"Admin users are allowed to manage users (create new users, disable existing users, change passwords); view all information; and export available information. Only two admin users from the bank may be enabled at a given time."
)
bankRoles.set(
	"operations",
	"Operations users are allowed to approve or reject payments; view all information; and export available information."
)
bankRoles.set("readonly", "Readonly users are allowed to view all information; and export available information.")

const partnerRoles: Map<string, string> = new Map()
partnerRoles.set("partner", "")

const rolesSet: Map<string, Map<string, string>> = new Map()
rolesSet.set("orgUser", orgRoles)
rolesSet.set("unitUser", unitRoles)
rolesSet.set("bankUser", bankRoles)
rolesSet.set("partnerUser", partnerRoles)

const specialRolesForOrgIds: {[key: string]: {[key: string]: string[]}} = {
	it: {
		prod: [
			"56", // Wix Prod
		],
		sandbox: [
			"45", // Wix Sandbox
		],
	},
} // Roles to show only for specific orgs

const getRoles = (type: string, orgId?: string) => {
	if (type == "orgUser" && orgId && !isSandboxEnv()) {
		const emptyMap = new Map<string, string>()
		return new Map<string, string>([
			...Array.from(rolesSet.get(type) ?? emptyMap),
			...Array.from(orgsSpecificRoles.get(orgId) ?? emptyMap),
		])
	}
	if (type == "bankUser") {
		const emptyMap = new Map<string, string>()
		return new Map<string, string>(Array.from(rolesSet.get(type) ?? emptyMap))
	}
	return rolesSet.get(type) || new Map<string, string>()
}

export function getRoleDisplayName(role: string) {
	let userRole
	switch (role) {
		case "bank":
			userRole = "bank-admin"
			break
		case "it":
			userRole = "IT"
			break
		default:
			userRole = role
	}
	return startCase(userRole.replace(/^\d*_/, ""))
}

export interface EditableUserRoleProps {
	orgId?: string
	role: string
	type: string
	setRole: (value: string) => void
	isEditing: boolean
	isDisabled: boolean
	isAllowed: boolean
	onStartEdit: () => void
	onCancel: () => void
	update: (value: string) => void
}

export interface SelectUserRoleProps {
	type: string
	role: string
	setRole: (value: string) => void
	orgId?: string
	partnerId?: string
}

export function UserRoleField({role}: {role: string}) {
	return (
		<HorizontalField label="User Role">
			<input type="text" readOnly value={getRoleDisplayName(role)} className="input is-static" />
		</HorizontalField>
	)
}

export function SelectUserRoleField(props: SelectUserRoleProps) {
	const isSandbox = isSandboxEnv()
	return (
		<div className="select">
			<select value={props.role} onChange={(e) => props.setRole(e.target.value)}>
				{Array.from(getRoles(props.type, props.orgId).keys()).map((r) => {
					const rolesArray = specialRolesForOrgIds[r]
						? specialRolesForOrgIds[r][isSandbox ? "sandbox" : "prod"]
						: undefined
					const showRoleInOptions = (props.orgId && rolesArray && rolesArray.includes(props.orgId)) || !rolesArray
					if (showRoleInOptions) {
						return (
							<option key={r} value={r}>
								{getRoleDisplayName(r)}
							</option>
						)
					}
				})}
			</select>
		</div>
	)
}

export function EditableUserRoleField(props: EditableUserRoleProps) {
	return (
		<Editable
			label="User Role"
			isEditing={props.isEditing}
			isDisabled={props.isDisabled}
			isAllowed={props.isAllowed}
			onStartEdit={props.onStartEdit}
			onCancel={props.onCancel}
			setValue={props.setRole}
			value={props.role}
			initial={props.role}
			update={props.update}
			type={props.type}
			tooltip={<UserRolesExplanationTooltip type={props.type} orgId={props.orgId} />}
			orgId={props.orgId}
		>
			{EditableUserRole}
		</Editable>
	)
}
export const getTypeAndRoleCombined = (userType: UserType, userRole: UserRole) => {
	if (userType === UserType.PartnerOnBehalf) {
		return userRole
	}
	let adjustedUerRole = ""
	if (userType === UserType.Unit) {
		switch (userRole as UnitUserRole) {
			case UnitUserRole.unitAdmin:
				adjustedUerRole = "admin"
				break
			case UnitUserRole.unitReadonly:
				adjustedUerRole = "readonly"
				break
			case UnitUserRole.unitSales:
				adjustedUerRole = "sales"
				break
			case UnitUserRole.compliance:
			case UnitUserRole.complianceReadonly:
			default:
				adjustedUerRole = userRole
				break
		}
	}
	if (userType === UserType.Bank) {
		switch (userRole as BankUserRole) {
			case BankUserRole.bank:
				adjustedUerRole = "admin"
				break
			case BankUserRole.bankReadonly:
				adjustedUerRole = "readonly"
				break
			case BankUserRole.bankOperations:
				adjustedUerRole = "operations"
				break
			default:
				adjustedUerRole = userRole
				break
		}
	}
	if (userType === UserType.Org) {
		adjustedUerRole = userRole
	}
	if (userType === UserType.Partner) {
		switch (userRole as PartnerUserRole) {
			case PartnerUserRole.partner:
				adjustedUerRole = "admin"
				break
			default:
				adjustedUerRole = userRole
				break
		}
	}
	return `${userType} ${adjustedUerRole}`
}

function mapUserRoleToOptionDisplayRole(role: string) {
	switch (role) {
		case "bank":
			return "admin"
		case "bank-readonly":
			return "readonly"
		case "bank-operations":
			return "operations"
		default:
			return role
	}
}

export function EditableUserRole(props: EditableElementProps<string>) {
	if (props.isEditing) {
		const isSandbox = isSandboxEnv()
		return (
			<div className="select is-small">
				<select value={mapUserRoleToOptionDisplayRole(props.value)} onChange={(e) => props.setValue(e.target.value)}>
					{Array.from(getRoles(props.type, props.orgId).keys()).map((r: string) => {
						const rolesArray = specialRolesForOrgIds[r]
							? specialRolesForOrgIds[r][isSandbox ? "sandbox" : "prod"]
							: undefined
						const showRoleInOptions = (props.orgId && rolesArray && rolesArray.includes(props.orgId)) || !rolesArray
						if (showRoleInOptions) {
							return (
								<option key={r} value={r}>
									{getRoleDisplayName(r)}
								</option>
							)
						}
					})}
				</select>
			</div>
		)
	} else {
		return <input type="text" readOnly value={getRoleDisplayName(props.value)} className="input is-static" />
	}
}

export function UserRolesExplanationTooltip({type, orgId}: {type: string; orgId?: string}) {
	const info: Array<JSX.Element> = []
	Array.from(getRoles(type, orgId).entries()).forEach(([roleId, role]) => {
		info.push(
			<div key={roleId}>
				<p>
					<span className="tooltip-info-header">{getRoleDisplayName(roleId)}</span>
					<br />
					<span className="tooltip-info-text">{role}</span>
				</p>
				<br />
			</div>
		)
	})

	return (
		<a data-tip="React-tooltip" data-event="click focus">
			<FontAwesomeIcon size="sm" icon={faQuestionCircle} />
			<ReactTooltip
				place="bottom"
				effect="solid"
				className="tooltip-info"
				globalEventOff="click"
				border={true}
				borderColor="#C4C4C4"
				backgroundColor="#F5F5F5"
			>
				<p className="tooltip-info-title mt-3">About user roles</p>
				<br />
				{info}
			</ReactTooltip>
		</a>
	)
}
