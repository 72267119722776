import React, {useEffect, useRef} from "react"
import {usePrevious} from "react-use"
import Icon from "../Icon/Icon"

interface Props {
	keyValues: {[key: string]: string}[]
	keyName?: string
	valueName?: string
	setKeyValues: Function
	keyPlaceholder?: string
	valuePlaceholder?: string
	itemName?: string
	valueInputType?: string
}

export default function KeyValueEditor({
	keyValues,
	keyName = "key",
	valueName = "value",
	setKeyValues,
	keyPlaceholder = "Key",
	valuePlaceholder = "Value",
	itemName = "Item",
	valueInputType = "text",
}: Props) {
	const keyValueEditorElement = useRef<HTMLDivElement>(null)
	const prevKeyValues = usePrevious(keyValues)

	useEffect(() => {
		// Focusing the latest "key" input if the user just added one
		if (prevKeyValues && keyValues.length > prevKeyValues.length) {
			const input = keyValueEditorElement.current?.querySelector(
				`.item:nth-child(${keyValues.length}) .key`
			) as HTMLInputElement
			input.focus()
		}
	}, [keyValues])

	const addNew = () => {
		const newKeyValues = [...keyValues, {[keyName]: "", [valueName]: ""}]
		setKeyValues(newKeyValues)
	}

	const onChange = (index: number, key: string, value: string) => {
		const newKeyValues = keyValues.slice()
		newKeyValues[index][keyName] = key
		newKeyValues[index][valueName] = value
		setKeyValues(newKeyValues)
	}

	const removeItem = (index: number) => {
		const newKeyValues = keyValues.slice()
		newKeyValues.splice(index, 1)
		setKeyValues(newKeyValues)
	}

	return (
		<div className={"key-value-editor"} ref={keyValueEditorElement}>
			{keyValues.map((keyValue, index) => {
				return (
					<div key={index} className="item">
						<fieldset className="key-value">
							<input
								className="input key"
								value={keyValue[keyName]}
								placeholder={keyPlaceholder}
								required
								onChange={(e) => onChange(index, e.target.value, keyValue[valueName])}
							/>
							<input
								className="input value"
								value={keyValue[valueName]}
								placeholder={valuePlaceholder}
								required
								type={valueInputType}
								onChange={(e) => onChange(index, keyValue[keyName], e.target.value)}
							/>
						</fieldset>
						<span className="remove-item" onClick={() => removeItem(index)}>
							<Icon icon="interface-delete-interface-essential" size={16} />
						</span>
					</div>
				)
			})}
			<div className="add-item-button" onClick={addNew}>
				<Icon icon="interface-add-1" size={12} /> Add {itemName}
			</div>
		</div>
	)
}
