import React, {useState, useEffect} from "react"
import moment, {Moment} from "moment"
import "react-dates/initialize"
import {
	DayPickerRangeController,
	DayPickerRangeControllerShape,
	FocusedInputShape,
	isInclusivelyBeforeDay,
} from "react-dates"
import Icon from "../Icon/Icon"
import {isUndefined} from "lodash"

interface CalendarProps {
	onChange: (startDate: Moment | null, endDate: Moment | null) => void
}

export function Calendar({onChange, ...rest}: CalendarProps & Partial<DayPickerRangeControllerShape>) {
	const [startDate, setStartDate] = useState<Moment | null>(rest.startDate ?? moment())
	const [endDate, setEndDate] = useState<Moment | null>(rest.endDate ?? moment())
	const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(rest.focusedInput ?? "startDate")

	const handleDatesChange = (arg: {startDate: Moment | null; endDate: Moment | null}) => {
		if (!arg.endDate) {
			arg.endDate = arg.startDate
		}
		setStartDate(arg.startDate)
		setEndDate(arg.endDate)

		onChange(arg.startDate, arg.endDate)
	}

	useEffect(() => {
		setStartDate(isUndefined(rest.startDate) ? null : rest.startDate)
		setEndDate(isUndefined(rest.endDate) ? null : rest.endDate)
	}, [rest.startDate, rest.endDate])

	const handleFocusChange = (focus: "startDate" | "endDate" | null) => {
		setFocusedInput(!focus ? "startDate" : focus)
	}

	return (
		<DayPickerRangeController
			startDate={startDate}
			initialVisibleMonth={() => moment().add(-1, "M")}
			endDate={endDate}
			firstDayOfWeek={1}
			maxDate={rest.maxDate}
			onDatesChange={({startDate, endDate}) => handleDatesChange({startDate, endDate})}
			focusedInput={focusedInput}
			onFocusChange={(focus) => {
				handleFocusChange(focus)
			}}
			isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
			numberOfMonths={2}
			daySize={36}
			hideKeyboardShortcutsPanel={true}
			weekDayFormat="ddd"
			renderMonthElement={({month}) => (
				<>
					<span className={"calendar-month"}> {month.format("MMMM")} </span>
					<span className={"calendar-year"}> {month.year()} </span>
				</>
			)}
			navPrev={
				<div className="DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_button__horizontalDefault">
					<Icon icon={"interface-arrows-button-left--interface-essential"} size={16} />
				</div>
			}
			navNext={
				<div className="DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_button__horizontalDefault">
					<Icon icon={"interface-arrows-button-right--interface-essential"} size={16} />
				</div>
			}
			navPosition="navPositionTop"
		/>
	)
}
