import {CounterpartyAccountType} from "./counterparty"
import {Cents} from "./transaction"
import {createResource, getResource} from "./common"
import {IncomingAch} from "./incomingAch"
import {WireInvolvedInstitutions} from "./payment"

export const incomingWireMock = {
	type: "incomingWire",
	id: "1",
	attributes: {
		createdAt: "1970-01-01T00:00:00.000Z",
		updatedAt: "1970-01-01T00:00:00.000Z",
		status: "PendingReview",
		amount: 1023,
		wireCounterparty: {
			name: "UNIT FINANCE INC.",
			routingNumber: "121140399",
			accountNumber: "3302844444",
			accountType: "Checking",
		},
		pendingReviewReasons: [
			{
				ofacHit: {
					hits: [
						{
							sdn: {
								entityId: "entityId",
								sdnName: "sdnName",
								sdnType: "sdnType",
								title: "title",
								remarks: "remarks",
								program: ["program"],
								match: 98,
							},
							addresses: [
								{
									address: "address",
									cityStateProvincePostalCode: "cityStateProvincePostalCode",
									country: "country",
								},
							],
							alts: [
								{
									entityID: "entityId",
									alternateType: "alternateType",
									alternateName: "alternateName",
								},
							],
						},
					],
				},
			},
		],
		incomingMessage: {
			disposition: {
				testProductionCode: "P",
				messageDuplicationCode: " ",
				messageStatusIndicator: "N",
			},
			receiptTimeStamp: {date: "--02-11", time: "13:46:00"},
			omad: "20210211MMQFMPK000002502111346FT01",
			wireType: {typeCode: 10, subTypeCode: "00"},
			imad: "20210211L1B77D1C004056",
			amount: 1023,
			senderInstitution: {routingNumber: "121140399", name: null},
			senderReference: "20210421621400",
			receiverInstitution: {routingNumber: "051402372", name: null},
			businessFunctionCode: "CTR",
			intermediaryFI: null,
			beneficiary: {
				idCode: "D",
				identifier: "1000000000",
				name: "UNIT FINANCE INC",
				address: {line1: "550 ALLERTON ST.", line2: "REDWOOD CITY, CA", line3: null},
			},
			originator: {
				identifier: ["D", "3302844444"],
				name: "UNIT FINANCE INC.",
				address: {line1: "550 ALLERTON ST.", line2: "REDWOOD CITY, CA  94063", line3: null},
			},
		},
	},
	relationships: {
		bank: {data: {type: "bank", id: "1"}},
		account: {data: {type: "account", id: "10000"}},
		customer: {data: {type: "customer", id: "10053"}},
		org: {data: {type: "org", id: "1"}},
	},
}

export enum IncomingWireStatus {
	PendingReview = "PendingReview",
	MarkedForReturn = "MarkedForReturn",
	Returned = "Returned",
	Processed = "Processed",
	Errored = "Errored",
}

export async function findIncomingWires(
	accessToken: string,
	offset: number,
	limit?: number | null,
	statusFilter?: IncomingWireStatus[],
	orgs?: string[],
	banks?: string[],
	fromAmount?: Cents | "",
	toAmount?: Cents | "",
	since?: string,
	until?: string,
	accountId?: string,
	customerId?: string,
	bankId?: string
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const bankFilter = banks && banks.length > 0 ? banks : undefined
	const query = {
		page: {
			limit: limit,
			offset,
		},
		filter: {
			status: statusFilter,
			accountId: accountId,
			customerId: customerId,
			bankId: bankId,
			orgs: orgFilter,
			banks: bankFilter,
			fromAmount: fromAmount != "" ? fromAmount : null,
			toAmount: toAmount != "" ? toAmount : null,
			since: since != "" ? since : null,
			until: until != "" ? until : null,
		},
		include: "bank,account,customer,org",
	}
	return await getResource<Array<IncomingWire>>("incoming-wire", accessToken, query)
}

export async function getIncomingWire(accessToken: string, incomingWireId: string) {
	const query = {
		include: "bank,account,customer,org",
	}
	return await getResource<IncomingWire>(`incoming-wire/${incomingWireId}`, accessToken, query)
}

export async function approveIncomingReturnWire(accessToken: string, incomingWireId: string, paymentId: string) {
	const data = {
		type: "incomingReturnWireApprove",
		relationships: {
			payment: {
				data: {
					type: "payment",
					id: paymentId,
				},
			},
		},
	}
	return await createResource<IncomingWire>(`incoming-wire/${incomingWireId}/approve-return`, accessToken, data)
}

export async function approveIncomingWire(
	accessToken: string,
	incomingWireId: string,
	accountId?: number | null,
	wireCounterparty?: WireCounterparty | null
) {
	const data = {
		type: "incomingWireApprove",
		attributes: {
			...(wireCounterparty && {
				wireCounterparty: wireCounterparty,
			}),
		},
		relationships: {
			...(accountId && {
				account: {
					data: {
						type: "account",
						id: accountId,
					},
				},
			}),
		},
	}
	return await createResource<IncomingWire>(`incoming-wire/${incomingWireId}/approve`, accessToken, data)
}

export async function rejectIncomingWire(
	accessToken: string,
	incomingWireId: string,
	returnReason: string,
	returnReasonText: string
) {
	const data = {
		type: "incomingWireDeny",
		attributes: {
			returnReason,
			returnReasonText,
		},
	}
	return await createResource<IncomingAch>(`incoming-wire/${incomingWireId}/deny`, accessToken, data)
}

export type IncomingWire = {
	type: "incomingWire"
	id: string
	attributes: {
		createdAt: Date
		updatedAt: Date
		amount: Cents
		businessFunctionCode: string
		isMonetary: boolean
		isReturn: boolean
		errorReason?: string
		wireCounterparty?: WireCounterparty
		pendingReviewReasons: PendingReviewReasons
		status: IncomingWireStatus
		incomingMessage: {
			omad: string
			imad: string
		}
		returnReason?: string
		decision?: {
			manuallyReturned?: {
				decisionReason: string
				decisionBy: string
				timestamp: Date
			}
			manuallyApproved?: {
				decisionBy: string
				timestamp: Date
			}
			automaticReturned?: {
				decisionReason: string
				timestamp: Date
			}
			automaticApproved?: {
				timestamp: Date
			}
		}
		involvedInstitutions?: WireInvolvedInstitutions
	}
	relationships: {
		account?: {
			data: {
				type: string
				id: number | null
			}
		}
		bank: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number | null
			}
		}
		org?: {
			data: {
				type: string
				id: number | null
			}
		}
		transaction?: {
			data: {
				type: string
				id: number | null
			}
		}
	}
	// TODO: finish type
}

export type WireCounterparty = {
	name: string
	routingNumber: string
	accountNumber: string
	accountType: CounterpartyAccountType
}

export type OfacHitSdn = {
	entityId: string
	sdnName: string
	sdnType: string
	title: string
	remarks: string
	program: string[]
	match: number
}

export type OfacAddress = {
	address: string
	cityStateProvincePostalCode: string
	country: string
}

export type OfacAlt = {
	entityID: string
	alternateType: string
	alternateName: string
}
export type OfacHitsReviewReason = {
	sdn: OfacHitSdn
	addresses: OfacAddress[]
	alts: OfacAlt[]
}

export type OfacHitsReviewReasons = {
	hits: Array<OfacHitsReviewReason>
}

export type NameMismatchReviewReasons = {
	namesScoreMap: {
		[key: string]: string
	}
}

export type PendingReviewReasons = Array<{
	ofacHit?: OfacHitsReviewReasons
	nameMissMatch?: NameMismatchReviewReasons
	softLimit?: {}
	fedWireAmountLimit?: {}
}>
