import React from "react"
import {WireDepositInstitution, WireFinancialInstitution, WireInvolvedInstitutions} from "../../resources/payment"
import {Accordion} from "../Accordion/Accordion"
import {Timeline, TimelineItem} from "../Timeline/Timeline"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {startCase} from "lodash"

function depositInstitutionDetails(institution: WireDepositInstitution) {
	const {name, routingNumber, address} = institution
	return {
		name,
		routingNumber,
		...(address && {Address: address}),
	}
}

function financialInstitutionDetails(institution: WireFinancialInstitution) {
	const {identifierType, identifier, fiName, fiAddress} = institution
	return {
		...(identifierType && {idType: identifierType}),
		...(identifier && {ID: identifier}),
		...(fiName && {Name: fiName}),
		...(fiAddress && {Address: fiAddress}),
	}
}

function InstitutionDetails({institution}: {institution: WireDepositInstitution | WireFinancialInstitution}) {
	const isDepositInstitution = "routingNumber" in institution
	const details = isDepositInstitution
		? depositInstitutionDetails(institution)
		: financialInstitutionDetails(institution)
	return (
		<GridContainer columns={3} rows={2} className="wire-involved-institution-details">
			{Object.entries(details).map(([key, value], index) => (
				<div key={`${key}-${index}`} className="wire-involved-institution-detail">
					<span className="wire-involved-institution-detail-key">{startCase(key)}</span>
					<span className="wire-involved-institution-detail-value">{value}</span>
				</div>
			))}
		</GridContainer>
	)
}

function getInstitutionSection({
	institution,
	title,
}: {
	institution: WireDepositInstitution | WireFinancialInstitution
	title: string
}) {
	return {
		header: (
			<Accordion title={title} theme="full" className="wire-involved-institution" noContentPadding>
				<InstitutionDetails institution={institution} />
			</Accordion>
		),
	} as TimelineItem
}

export function WireInvolvedFinancialInstitutions({
	involvedFinancialInstitutions,
}: {
	involvedFinancialInstitutions: WireInvolvedInstitutions
}) {
	const {
		receiverDepositInstitution,
		instructingFinancialInstitution,
		intermediaryFinancialInstitution,
		originatorFinancialInstitution,
		senderDepositInstitution,
	} = involvedFinancialInstitutions

	const sender =
		senderDepositInstitution &&
		getInstitutionSection({institution: senderDepositInstitution, title: "Sender Deposit Institution"})
	const intermediary =
		intermediaryFinancialInstitution &&
		getInstitutionSection({institution: intermediaryFinancialInstitution, title: "Intermediary Financial Institution"})
	const instructing =
		instructingFinancialInstitution &&
		getInstitutionSection({institution: instructingFinancialInstitution, title: "Instructing Financial Institution"})
	const originator =
		originatorFinancialInstitution &&
		getInstitutionSection({institution: originatorFinancialInstitution, title: "Originator Financial Institution"})
	const receiver =
		receiverDepositInstitution &&
		getInstitutionSection({institution: receiverDepositInstitution, title: "Receiver Deposit Institution"})

	const institutions = [sender, intermediary, instructing, originator, receiver]

	const items = institutions.filter((institution) => institution) as TimelineItem[]

	return <Timeline items={items} />
}
