import React, {useCallback, useRef} from "react"
import {Meta, Pagination} from "../../resources/common"
import numeral from "numeral"
import {getParents} from "../../utilities/dom"
import {Circle, CircleSize} from "../Circle/Circle"
import Icon from "../Icon/Icon"

function PaginationMetaItems({pagination, hasNext}: {pagination?: Pagination; hasNext: boolean}) {
	if (pagination) {
		const {offset, limit, total} = pagination
		const firstInd = +offset + 1
		const lastInd = hasNext ? +offset + +limit : +total

		return (
			<div className="pagination-items">
				<div>
					<b>
						{numeral(firstInd).format("0,0")}-{numeral(lastInd).format("0,0")}
					</b>
					{` of ${numeral(total).format("0,0")}`}
				</div>
			</div>
		)
	}

	return null
}

function PaginationButton({previous, disabled, onClick}: {previous?: boolean; disabled: boolean; onClick: () => void}) {
	const className = `pagination-${previous ? "previous" : "next"} button pagination-button`

	return (
		<button className={className} disabled={disabled} onClick={onClick}>
			<PagingIcon previous={previous} />
		</button>
	)
}

export function PagingIcon({size = 14, previous}: {size?: number | string; previous?: boolean}) {
	return (
		<Circle size={CircleSize.lg} className="bg-box-link">
			<Icon icon={`${previous ? "left" : "right"}-arrow`} size={size} />
		</Circle>
	)
}

export default function PagingNavBar({
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	meta,
	isShow = true,
	isShowIfNoResults = false,
}: {
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	meta?: Meta
	isShow?: boolean
	isShowIfNoResults?: boolean
}) {
	const elementRef = useRef<HTMLElement>(null)
	const navigation = useCallback((callback: () => void) => {
		callback()
		if (elementRef?.current) {
			const parentsElement = getParents(elementRef.current)
			const element = parentsElement.find((element) => element.className.includes("data-table-card"))
			setTimeout(() => {
				element?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
			}, 0)
		}
	}, [])

	const showPaginationNavBar = !(!isShow || (!hasPrev && !hasNext) || (!isShowIfNoResults && !hasResults))

	return (
		<>
			{showPaginationNavBar && (
				<div className="pagination-row">
					<div className="navigation-items">
						<nav ref={elementRef} className="pagination" role="navigation" aria-label="pagination">
							<PaginationButton disabled={!hasPrev} onClick={() => navigation(prev)} previous />
							<PaginationMetaItems hasNext={hasNext} pagination={meta?.pagination} />
							<PaginationButton disabled={!hasNext} onClick={() => navigation(next)} />
							<ul className="pagination-list"></ul>
						</nav>
					</div>
				</div>
			)}
		</>
	)
}
