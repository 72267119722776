export function isDateInputSupported() {
	const input = document.createElement("input")
	input.setAttribute("type", "date")

	// Some browsers pretend that they have a "date" input type but don't offer a UI that supports it,
	// so we will attempt to put an invalid date value and see if the field rejects it
	const notADateValue = "not-a-date"
	input.setAttribute("value", notADateValue)

	return input.value !== notADateValue
}

export function getParents(elem: Node | null, maxDepth?: number) {
	const parents: Array<Node> = []
	let currDepth = 0

	for (; (!maxDepth || currDepth < maxDepth) && elem && elem !== document; elem = elem.parentNode) {
		parents.push(elem)
		currDepth++
	}

	return parents as HTMLElement[]
}
