import {IcomoonIconName} from "../../components/Icon/icons"
import {Org} from "../../resources/org"
import {AuditLogEventsHeaderMetaItemProps} from "../../components/AuditLogEventsHeader/AuditLogEventsHeader"

interface getAuditLogEventsHeaderMetaItemsProps {
	applicationName: string
	applicationType: string
	pageIcon: IcomoonIconName
	org?: Org
}

export const getAuditLogEventsHeaderMetaItems = ({
	applicationName,
	applicationType,
	pageIcon,
	org,
}: getAuditLogEventsHeaderMetaItemsProps): AuditLogEventsHeaderMetaItemProps[] => {
	return [
		{
			text: applicationName,
			icon: "user-contact",
			tooltipText: "Name",
		},
		{
			text: applicationType,
			icon: pageIcon,
			tooltipText: "Type",
		},
		...(!!org
			? [
					{
						text: org.attributes.name,
						icon: "user-hierachy2" as IcomoonIconName,
						tooltipText: "Org Name",
					},
			  ]
			: []),
	]
}
