import {getResource, updateResource} from "./common"

export type CustomerKnownNames = {
	type: "customerKnownNames"
	attributes: {
		names: string[]
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: number
			}
		}
	}
}

export async function getCustomerKnownNames(accessToken: string, customerId: string) {
	return await getResource<CustomerKnownNames>(`customer-known-names/${customerId}`, accessToken)
}

export async function addCustomerKnownNames(accessToken: string, customerId: number | string, name: string) {
	return await updateResource<CustomerKnownNames>(`customer-known-names/${customerId}/add`, accessToken, {
		type: "customerKnownNames",
		attributes: {
			name,
		},
		relationships: {
			customer: {
				data: {
					type: "customer",
					id: customerId,
				},
			},
		},
	})
}

export async function removeCustomerKnownNames(accessToken: string, customerId: string, name: string) {
	return await updateResource<CustomerKnownNames>(`customer-known-names/${customerId}/remove`, accessToken, {
		type: "customerKnownNames",
		attributes: {
			name,
		},
		relationships: {
			customer: {
				data: {
					type: "customer",
					id: customerId,
				},
			},
		},
	})
}
