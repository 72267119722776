import React from "react"
import {GridContainer} from "../../containers/GridContainer/GridContainer"
import {DetailsList} from "../DetailsList/DetailsList"
import {AssignmentPageItem} from "../AssignmentPageItem/AssignmentPageItem"
import {
	Assignee,
	AssignmentOfacAddress,
	AssignmentActionRequestType,
	AssignmentStage,
	AssignmentStatus,
	OfacCheckAssignmentItemPayload,
	OfacCheckEscalateRequest,
	OfacCheckFalsePositiveRequest,
	OfacMatchDetails,
	update,
	OfacCheckAssignmentFalsePositiveReason,
} from "../../resources/assignments"
import {WirePayment} from "../../resources/payment"
import {useResetScrollTop} from "../../hooks/useResetScrollTop"
import {WirePaymentCounterpartyFields} from "../Payments/WirePaymentCounterpartyFields"
import {KeyValueCardView} from "../RenderKeyValueCard/KeyValueCardView"
import Icon from "../Icon/Icon"
import {AssignmentPageItemApproveRejectFooter} from "../AssignmentPageItemApproveRejectFooter/AssignmentPageItemApproveRejectFooter"
import {useModal} from "react-modal-hook"
import {
	AssignmentApproveRejectModal,
	AssignmentStatusUpdateReasonsMapping,
} from "../AssignmentApproveRejectModal/AssignmentApproveRejectModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {AssignmentPageCompletedItemHeader} from "../AssignmentPageCompletedItemHeader/AssignmentPageCompletedItemHeader"

interface AssignmentPageItemOFACProps {
	id: string
	assignees: Assignee[]
	status: AssignmentStatus
	stage: AssignmentStage
	title: string
	description?: string | JSX.Element
	createdAt: Date
	statusUpdatedAt?: Date
	isOwnersItem?: boolean
	wire?: WirePayment
	payload: OfacCheckAssignmentItemPayload
	accessToken: string
	refresh: () => void
	disabled?: boolean
}

function OFACExternalLink() {
	return (
		<div className="icon-text">
			<a className="ofac-external-link" href="https://sanctionssearch.ofac.treas.gov/" target="_blank" rel="noreferrer">
				Sanctions List Search
			</a>
			<span className="icon">
				<Icon icon="maximize-square-4--interface-essential" size={12} />
			</span>
		</div>
	)
}
const getOfacAddressElement = (ofacAddress: AssignmentOfacAddress) => (
	<span
		className={"address-placeholder"}
	>{`${ofacAddress.address}\n${ofacAddress.cityStateProvincePostalCode}\n${ofacAddress.country}`}</span>
)

const getOfacAddressesElement = (ofacAddresses: AssignmentOfacAddress[]) => (
	<div className="ofac-addresses">
		{ofacAddresses.map((ofacAddress, index) => (
			<div key={index}>{getOfacAddressElement(ofacAddress)}</div>
		))}
	</div>
)

export enum OfacCheckStatusUpdateReasonKeys {
	NotAVessel = "NotAVessel",
	NotAnEntity = "NotAnEntity",
	NotAnIndividual = "NotAnIndividual",
	NameMismatch = "NameMismatch",
	DateOfBirthMismatch = "DateOfBirthMismatch",
	AddressMismatch = "AddressMismatch",
}

const ofacCheckAssignmentStatusUpdateReasons: AssignmentStatusUpdateReasonsMapping = {
	[OfacCheckStatusUpdateReasonKeys.NotAVessel]: {reason: "NAV", displayName: "NAV - Not a vessel"},
	[OfacCheckStatusUpdateReasonKeys.NotAnEntity]: {reason: "NAE", displayName: "NAE - Not an entity"},
	[OfacCheckStatusUpdateReasonKeys.NotAnIndividual]: {reason: "NAI", displayName: "NAI - Not an individual"},
	[OfacCheckStatusUpdateReasonKeys.NameMismatch]: {reason: "NMM", displayName: "NMM - Name mismatch"},
	[OfacCheckStatusUpdateReasonKeys.DateOfBirthMismatch]: {reason: "DOB", displayName: "DOB - Date of birth mismatch"},
	[OfacCheckStatusUpdateReasonKeys.AddressMismatch]: {reason: "ADD", displayName: "ADD - Address mismatch"},
}

const OFAC_CHECK_ASSIGNMENT_FALSE_POSITIVE_REASONS = {
	[OfacCheckAssignmentFalsePositiveReason.NAV]: "NAV - Not a vessel",
	[OfacCheckAssignmentFalsePositiveReason.NAE]: "NAE - Not an entity",
	[OfacCheckAssignmentFalsePositiveReason.NAI]: "NAI - Not an individual",
	[OfacCheckAssignmentFalsePositiveReason.NMM]: "NMM - Name mismatch",
	[OfacCheckAssignmentFalsePositiveReason.DOB]: "DOB - Date of birth mismatch",
	[OfacCheckAssignmentFalsePositiveReason.ADD]: "ADD - Address mismatch",
}

export function AssignmentPageItemOFAC({
	id,
	assignees,
	status,
	stage,
	title,
	description,
	createdAt,
	statusUpdatedAt,
	isOwnersItem,
	payload: {ofacHit, wireDetails, reason, reasonText},
	accessToken,
	refresh,
	disabled,
}: AssignmentPageItemOFACProps) {
	const wireDetailsContentRef = React.useRef<HTMLDivElement>(null)
	const ofacSdnListContentRef = React.useRef<HTMLDivElement>(null)
	useResetScrollTop(wireDetailsContentRef, [id])
	useResetScrollTop(ofacSdnListContentRef, [id])
	const className = "is-paddingless"
	const ofacMatchDetails: OfacMatchDetails | undefined = ofacHit
		? {
				SDNName: ofacHit.sdn.sdnName,
				addresses: getOfacAddressesElement(ofacHit.addresses),
				aliases: ofacHit.alts?.map(({alternateName}) => alternateName).join("; ") ?? "-",
				SDNType: ofacHit.sdn.sdnType,
				program: ofacHit.sdn.program?.join(", ") ?? "-",
				remarks: ofacHit.sdn.remarks,
		  }
		: undefined

	const [showFalsePositive, hideFalsePositive] = useModal(() => {
		const successText = "Successfully marked as false positive"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = ({reason, reasonText}: {reason: OfacCheckAssignmentFalsePositiveReason; reasonText: string}) => {
			const data: OfacCheckFalsePositiveRequest = {
				type: AssignmentActionRequestType.ofacCheckFalsePositiveRequest,
				attributes: {
					reason,
					reasonText,
				},
			}
			return callback({accessToken, id, data})
		}
		return (
			<AssignmentApproveRejectModal
				title="False Positive"
				description="Are you sure this match is a false positive?"
				state={state}
				onSubmit={onSubmit}
				withReasonText
				reasonTextPlaceholder="Please explain the reason for false positive."
				reasonsToEnableReasonText={Object.keys(ofacCheckAssignmentStatusUpdateReasons)}
				reasons={ofacCheckAssignmentStatusUpdateReasons}
				primaryButtonText="False Positive"
				primaryButtonClassname="is-success"
				closeModal={hideFalsePositive}
				onSuccess={refresh}
				successText={successText}
			/>
		)
	}, [id])

	const [showEscalate, hideEscalate] = useModal(() => {
		const successText = "Escalated successfully"
		const [state, callback] = useAsyncResultIdle(update)
		const onSubmit = ({reasonText}: {reasonText: string}) => {
			const data: OfacCheckEscalateRequest = {
				type: AssignmentActionRequestType.ofacCheckEscalateRequest,
				attributes: {
					reasonText,
				},
			}
			return callback({accessToken, id, data})
		}
		return (
			<AssignmentApproveRejectModal
				title="Escalate Match"
				description="STOP! Do NOT press the Escalate button unless you are the Unit VP Financial Crimes. This action will place the wire in an indefinite holding state."
				state={state}
				onSubmit={onSubmit}
				withReasonText
				reasonTextPlaceholder="Why are you escalating this match?"
				primaryButtonText="Escalate"
				primaryButtonClassname="is-danger"
				closeModal={hideEscalate}
				onSuccess={refresh}
				successText={successText}
				successClassname="has-text-black"
			/>
		)
	}, [id])

	return (
		<AssignmentPageItem
			assignees={assignees}
			status={status}
			stage={stage}
			title={title}
			description={description}
			createdAt={createdAt}
			statusUpdatedAt={statusUpdatedAt}
			isOwnersItem={isOwnersItem}
			disabled={disabled}
			pageItemFooter={
				!disabled ? (
					<AssignmentPageItemApproveRejectFooter
						approveLabel="False Positive"
						rejectLabel="Escalate"
						onApprove={showFalsePositive}
						onReject={showEscalate}
						rejectTooltip="Do not escalate unless this is a confirmed true hit"
					/>
				) : undefined
			}
			pageCompletedItemHeader={
				<AssignmentPageCompletedItemHeader
					assignees={assignees}
					status={status}
					reason={reason ? OFAC_CHECK_ASSIGNMENT_FALSE_POSITIVE_REASONS[reason] : undefined}
					reasonText={reasonText}
				/>
			}
		>
			<GridContainer rows={1} columns={2} gap="8px" className="has-full-height assignment-page-item-default-inner-grid">
				<DetailsList
					title="Wire Summary"
					icon="payment-box-incoming"
					className="scrollable-container"
					contentRef={wireDetailsContentRef}
					disabled={disabled}
				>
					{wireDetails && (
						<WirePaymentCounterpartyFields
							counterpartyFields={wireDetails}
							singleColumn
							disabled={disabled}
							className={className}
						/>
					)}
				</DetailsList>
				<DetailsList
					title="OFAC SDN List"
					icon="document-folder-list"
					className="scrollable-container"
					contentRef={ofacSdnListContentRef}
					disabled={disabled}
					additionalHeaderContent={<OFACExternalLink />}
				>
					{ofacMatchDetails && (
						<KeyValueCardView
							details={ofacMatchDetails}
							title="OFAC Match Details"
							singleColumn
							disabled={disabled}
							className={className}
						/>
					)}
				</DetailsList>
			</GridContainer>
		</AssignmentPageItem>
	)
}
