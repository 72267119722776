import {useAccessToken} from "../../services/auth"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import React, {useRef, useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import {
	BussinessDayDTO,
	cancelDeferedProvisionalCredit,
	changeDeferedProvisionalDateCredit,
} from "../../resources/dispute"
import classNames from "classnames"
import {useClickAway, useToggle} from "react-use"
import Icon from "../Icon/Icon"
import moment from "moment"

interface DisputeDeferredProvisionalCreditModalProps {
	id: string
	close: () => void
	onSuccess: () => void
}

interface DisputeDeferredProvisionalCreditChangeDateModalProps {
	id: string
	date: Date
	possibleBussinesDays: BussinessDayDTO[]
	close: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function CancelDeferredProvisionalCreditModal({
	id,
	close,
	onSuccess,
}: DisputeDeferredProvisionalCreditModalProps) {
	const accessToken = useAccessToken()
	const [state, setCancel] = useAsyncResultIdle(cancelDeferedProvisionalCredit)

	return (
		<AsyncResultModal
			classname="dispute-modal"
			title={`Cancel Deferred Provisional Credit`}
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-black"
			state={state}
			buttonText="Yes, I'm sure"
			successText="Canceled"
			onSubmit={() => setCancel(accessToken, id)}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="field">
				<text>
					Are you sure you want to cancel the deferred provisional credit?
					<br />
					The dispute will remain under &quot;Investigation Started&quot; status, and will require a decision.
				</text>
			</div>
		</AsyncResultModal>
	)
}

function isDateEqualToToday(dateToCheck: Date): boolean {
	const today = new Date()
	today.setHours(0, 0, 0, 0)
	const date = new Date(dateToCheck)

	return (
		today.getFullYear() === date.getFullYear() &&
		today.getMonth() === date.getMonth() &&
		today.getDate() === date.getDate()
	)
}

export function ChangeDeferredProvisionalCreditDateModal({
	id,
	date,
	possibleBussinesDays,
	close,
	onSuccess,
}: DisputeDeferredProvisionalCreditChangeDateModalProps) {
	const currentDateObject =
		possibleBussinesDays.find(({attributes}) => attributes.date === date) || possibleBussinesDays[0]

	const accessToken = useAccessToken()
	const [state, sentChangeDate] = useAsyncResultIdle(changeDeferedProvisionalDateCredit)
	const [currentBusinessDay, setBusinessDay] = useState<BussinessDayDTO>(currentDateObject)
	const ref = useRef(null)

	const [isDropdownActive, toggleDropdown] = useToggle(false)

	useClickAway(ref, () => toggleDropdown(false))

	return (
		<AsyncResultModal
			classname="change-deffered-provisional-credit-date-modal dispute-modal"
			title={`Update Deferred Provisional Credit`}
			close={refreshAndClose(onSuccess, close)}
			buttonClassname="is-black"
			state={state}
			buttonText="Confirm"
			successText="Updated"
			onSubmit={() => sentChangeDate(accessToken, id, currentBusinessDay.attributes.date.toString())}
			errorToText={(err) => err.errors[0].title}
		>
			<div className="modal-content-container">
				<p>Once confirmed, the customer will be given provisional credit automatically on the selected date.</p>
				<div className={classNames("dropdown", isDropdownActive && "is-active")} ref={ref}>
					<div className="dropdown-trigger">
						<button
							className="button"
							type="button"
							aria-haspopup="true"
							aria-controls="drowdown-menu3"
							onClick={() => toggleDropdown()}
						>
							<div className="dropdown-placeholder">
								<div className="icon-container">
									<Icon icon="calendar-2--interface-essential" size={20} />
								</div>
								<div className="business-day-wrapper">
									<div className="date">{moment(currentBusinessDay.attributes.date).format("MM/DD/YY")}</div>
									<div className="text"> · {currentBusinessDay.attributes.text}</div>
								</div>
								<Icon className="dropdown-icon" icon="arrow-down-chevron" size={16} color="#000000"></Icon>
							</div>
						</button>
					</div>
					<div className="dropdown-menu" role="menu">
						<div className="dropdown-content">
							{possibleBussinesDays.map((day, index) => (
								<a
									key={index}
									href="#"
									className="dropdown-item"
									onClick={(e) => {
										e.preventDefault()
										setBusinessDay(day)
									}}
								>
									<div
										className={classNames(
											"business-day-wrapper",
											currentBusinessDay.attributes.date === day.attributes.date && "is-selected"
										)}
									>
										<div className="business-day-content">
											<div className="date">{moment(day.attributes.date).format("MM/DD/YY")}</div>
											<div className="text">
												{" "}
												· {day.attributes.text}
												{isDateEqualToToday(day.attributes.date) && "  (Today)"}
											</div>
										</div>
										{currentBusinessDay === day && (
											<div className="selected-date-icon-container">
												<Icon icon="approve-v-check-validation" size={16} color="#000000" />
											</div>
										)}
									</div>
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</AsyncResultModal>
	)
}
