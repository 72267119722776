import React from "react"
import {getAccount, OrgLoanAccount} from "../../../resources/account"
import {Box, BoxInfo} from "../../../containers/Box/Box"
import {Circle, CircleSize} from "../../../components/Circle/Circle"
import Skeleton from "react-loading-skeleton"
import {calcPercent, formatPercent, toLongDollars} from "../../../utilities/numbers"
import {AsyncResultComponent} from "../../../containers/AsyncResult/AsyncResult"
import {useAccessToken} from "../../../services/auth"
import {useAsyncResult} from "../../../hooks/useAsyncResult"
import {getPercentColor} from "../../../components/ProgressBar/ProgressBar"

export function DailyCardSpendSkeleton() {
	return (
		<Box className="wh-100">
			<div className="flex-container flex-dir-col justify-content-center wh-100">
				<Skeleton width="100%" />
				<Skeleton width="100%" />
			</div>
		</Box>
	)
}

function DailyCardSpendInternal({account}: {account: OrgLoanAccount}) {
	const {balance: spent, creditLimit: limit} = account.attributes
	const spentAndLimit = `${toLongDollars(spent)} of ${toLongDollars(limit)} limit`
	const percent = calcPercent(spent, limit)
	const color = getPercentColor(percent)

	return (
		<Box className="wh-100">
			<BoxInfo title="Daily card spend">
				<div className="flex-container gap-8">
					<Circle className={color} size={CircleSize.sm} center />
					<p className="flex-container font-bold font-24 line-h-1">{formatPercent(percent)}</p>
					<p className="flex-container font-bold font-14 line-h-1-2 align-items-end box-info-value-secondary">
						{spentAndLimit}
					</p>
				</div>
			</BoxInfo>
		</Box>
	)
}

export function DailyCardSpend({orgLoanAccountId}: {orgLoanAccountId: string}) {
	const accessToken = useAccessToken()
	const orgLoanAccount = useAsyncResult(() => getAccount<OrgLoanAccount>(accessToken, orgLoanAccountId))

	return (
		<AsyncResultComponent asyncResult={orgLoanAccount} pendingComponent={<DailyCardSpendSkeleton />}>
			{({value}) => <DailyCardSpendInternal account={value} />}
		</AsyncResultComponent>
	)
}
