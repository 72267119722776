import React from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAccessToken} from "../../services/auth"
import Admonition from "../../components/Admonitions/Admonition"
import {approveIncomingCheck} from "../../resources/incomingCheck"

interface ApproveCheckModalProps {
	id: string
	closeModal: () => void
	onSuccess: () => void
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function ApproveCheckModal({id, closeModal, onSuccess}: ApproveCheckModalProps) {
	const accessToken = useAccessToken()
	const [approveState, setApprovedState] = useAsyncResultIdle(approveIncomingCheck)

	return (
		<AsyncResultModal
			title={`You Are About To Approve Incoming Check ID #${id}`}
			close={refreshAndClose(onSuccess, closeModal)}
			buttonClassname="is-success"
			state={approveState}
			buttonText="Approve"
			successText="Incoming Check is approved"
			onSubmit={() => {
				setApprovedState({id, accessToken})
			}}
			errorToText={(err) => err.errors[0].title}
		>
			<Admonition
				type={"is-warning"}
				message={
					<p>
						{
							"Approving an incoming check is a final action and cannot be undone. Incoming Checks may still be rejected later on in the check deposit process."
						}
					</p>
				}
				title={"Note"}
			/>
		</AsyncResultModal>
	)
}
