import React, {useEffect, useRef, useState} from "react"

import {Meta, Resource} from "../../resources/common"
import {AllowedReceivedPaymentsColumns, ReceivedPaymentsColumns} from "./ReceivedPayments"
import {DataTable, DataTableBody, DataTableCell, DataTableHead, DataTableRow} from "../DataTable/DataTable"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {ReceivedPaymentRow} from "./ReceivedPaymentRow"
import {ReceivedPayment} from "../../resources/receivedPayments"
import {useNavigate} from "react-router-dom"
import {useAccessToken} from "../../services/auth"
import Drawer from "../Drawer/Drawer"
import {ReceivedPaymentComponent} from "./ReceivedPayment"
import {upperCase} from "lodash"

interface IncomingPaymentsTableProps {
	receivedPayments: ReceivedPayment[]
	selectedReceivedPaymentId: string
	setSelectedReceivedPaymentId: (id: string) => void
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: AllowedReceivedPaymentsColumns[]
	meta?: Meta
	refresh: () => void
	fullHeight?: boolean
	sortFunction: (sortParam: string) => void
	sortBy: string
	disableDrawer?: boolean
}

export function ReceivedPaymentsTable({
	receivedPayments,
	selectedReceivedPaymentId,
	setSelectedReceivedPaymentId,
	fullHeight,
	includedColumns,
	hasNext,
	hasPrev,
	hasResults,
	isUsingPaging,
	include,
	refresh,
	meta,
	prev,
	next,
	sortFunction,
	disableDrawer = false,
	sortBy,
}: IncomingPaymentsTableProps) {
	const navigate = useNavigate()
	const accessToken = useAccessToken()
	const noContent = receivedPayments.length === 0
	const [isDrawerOpen, setIsDrawerOpen] = useState(!!selectedReceivedPaymentId)
	const ref = useRef(null)

	const toggleDrawer = (reopenWithId = false) => {
		setIsDrawerOpen((prevState) => !prevState)
		if (reopenWithId) {
			setTimeout(() => {
				setIsDrawerOpen(true)
			}, 200)
		}
	}

	useEffect(() => {
		if (!selectedReceivedPaymentId) {
			setIsDrawerOpen(false)
		}
	}, [selectedReceivedPaymentId])

	return (
		<div ref={ref} className={"received-payments-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={"No received payments found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === ReceivedPaymentsColumns.createdAt) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("createdAt")}
										key={column[0]}
										sortable={sortBy.includes("createdAt")}
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							if (column[1] === ReceivedPaymentsColumns.completionDate) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("completionDate")}
										key={column[0]}
										sortable={sortBy.includes("completionDate")}
										sortApplied={sortBy === "completionDate"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							if (column[1] === ReceivedPaymentsColumns.amount) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction("amount")}
										key={column[0]}
										sortable={sortBy.includes("amount")}
										sortApplied={sortBy === "amount"}
									>
										{column[1]}
									</DataTableCell>
								)
							}
							const columnHeader = column[1] === ReceivedPaymentsColumns.id ? upperCase(column[1]) : column[1]

							return <DataTableCell key={column[0]}>{columnHeader}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{receivedPayments.map((receivedPayment) => (
						<ReceivedPaymentRow
							receivedPayment={receivedPayment}
							key={receivedPayment.id}
							include={include}
							includedColumns={includedColumns}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								if (!disableDrawer) {
									setSelectedReceivedPaymentId(receivedPayment.id)
									toggleDrawer(true)
									return
								}
								navigate(`/received-payments/${receivedPayment.id}`)
							}}
							selectedReceivedPaymentId={selectedReceivedPaymentId}
							refresh={refresh}
						/>
					))}
				</DataTableBody>
			</DataTable>

			<div className="pagination-row">
				<div className="navigation-items">
					<PagingNavBar
						hasResults={hasResults}
						hasPrev={hasPrev}
						hasNext={hasNext}
						prev={prev}
						next={next}
						isShow={isUsingPaging}
						meta={meta}
					/>

					{!disableDrawer && (
						<Drawer
							open={isDrawerOpen}
							onClose={() => {
								toggleDrawer(false)
								setSelectedReceivedPaymentId("")
							}}
							direction="right"
							className="received-payments-side-drawer"
							size={"50%"}
							zIndex={33}
							blockBody={true}
							linkToCopy={`${window.location.origin}/received-payments/${selectedReceivedPaymentId}`}
						>
							{isDrawerOpen && (
								<ReceivedPaymentComponent
									receivedPaymentId={selectedReceivedPaymentId}
									accessToken={accessToken}
									useDrawer={true}
									refresh={refresh}
								/>
							)}
						</Drawer>
					)}
				</div>
			</div>
		</div>
	)
}
