import {ok, Result} from "neverthrow"
import {createResource, ErrorDocument, getFile, getResource, OkDocument, Permissible, updateResource} from "./common"
import {BusinessFBOCustomer, IndividualCustomer} from "./customer"
import {Cents} from "./transaction"
import {AccountsTotalCreditLimit} from "./accountsTotalCreditLimit"

export type FinancialBusinessFBOAccount = {
	type: "financialBusinessFBOAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		depositProduct: string
		routingNumber: string
		accountNumber: Permissible<string>
		maskedAccountNumber?: string
		secondaryAccountNumber?: {
			routingNumber: string
			accountNumber: string
		}
		balance: number
		hold: number
		reserve: number
		available: number
		overdraftLimit?: number
		currency: string
		tags: {[key: string]: string}
		status: AccountStatus
		dacaStatus?: DacaStatusType
		closeReason?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
		isOverdrawnWithinLimit?: boolean
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: string
			}
		}
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
	included?: BusinessFBOCustomer[]
}

export type DepositAccount = {
	type: "depositAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		depositProduct: string
		routingNumber: string
		accountNumber: Permissible<string>
		maskedAccountNumber?: string
		secondaryAccountNumber?: {
			routingNumber: string
			accountNumber: string
		}
		balance: number
		hold: number
		reserve: number
		available: number
		overdraftLimit?: number
		currency: string
		tags: {[key: string]: string}
		status: AccountStatus
		dacaStatus?: DacaStatusType
		closeReason?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
		isOverdrawnWithinLimit?: boolean
	}
	relationships: {
		customer?: {
			data: {
				type: "customer"
				id: string
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
	included?: IndividualCustomer[]
}

export type AccountsMetrics = {
	type: "accountsMetrics"
	attributes: {
		numberOfBatchAccounts: number
		numberOfDepositAccounts: number
		totalBatchBalance: number
		totalDepositBalance: number
	}
}

export type AccountDepositProduct = {
	type: "accountDepositProduct"
	attributes: {
		name: string
	}
}

export type ChartData = {
	date: Date
	balance: Cents
}

export type TotalDepositChartData = {
	type: "chartData"
	attributes: {
		data: [ChartData]
	}
}

export type BatchAccount = {
	type: "batchAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		depositProduct: string
		routingNumber: string
		accountNumber: Permissible<string>
		maskedAccountNumber?: string
		balance: number
		hold: number
		reserve: number
		currency: string
		status: AccountStatus.Open | AccountStatus.Closed
		closeReason?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
	}
}

export type OrgGeneralLedgerAccount = {
	type: "orgGeneralLedgerAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		balance: number
		hold: number
		reserve: number
		currency: string
		status: AccountStatus.Open | AccountStatus.Closed
		tags: {[key: string]: string}
		closeReason?: string
		closeReasonText?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		freezeReason?: string
		category: Category
		available: Cents
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export type GLAccount = {
	type: "glAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		balance: number
		hold: number
		reserve: number
		category: Category
		status: AccountStatus.Open | AccountStatus.Closed
		closeReason?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export type CreditGLAccount = {
	type: "creditGLAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		balance: number
		hold: number
		reserve: number
		category: Category
		status: AccountStatus.Open | AccountStatus.Closed
		closeReason?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export type CreditAccount = {
	type: "creditAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		creditTerms: string
		balance: number
		hold: number
		reserve: number
		available: number
		creditLimit: number
		currency: string
		tags: {[key: string]: string}
		status: AccountStatus
		closeReason?: CloseReason
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
	}
	relationships: {
		customer: {
			data: {
				type: "customer"
				id: string
			}
		}
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
}

export type OrgLoanAccount = {
	type: "orgLoanAccount"
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		name: string
		balance: number
		hold: number
		reserve: number
		available: number
		creditLimit: number
		currency: string
		tags: {[key: string]: string}
		status: AccountStatus
		closeReason?: CloseReason
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
	}
	relationships: {
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
		borrowingOrg: {
			data: {
				type: "org"
				id: string
			}
		}
	}
}

export type Wallet = {
	type: "walletAccount"
	id: string
	attributes: {
		name: string
		createdAt: Date
		depositProduct: string
		balance: number
		hold: number
		available: number
		reserve: number
		tags: {[key: string]: string}
		currency: string
		status: AccountStatus
		closeReason?: string
		fraudReason?: FraudReason
		bankReason?: BankReason
		closeReasonText?: string
		freezeReason?: string
		updatedAt?: Date
		bankDetails?: {name: string; address: string}
	}
	relationships: {
		customer?: {
			data: {
				type: "customer"
				id: string
			}
		}
		org: {
			data: {
				type: "org"
				id: string
			}
		}
		bank: {
			data: {
				type: "bank"
				id: string
			}
		}
	}
	included?: IndividualCustomer[]
}

export type Category = "Asset" | "Liability"

export enum CloseReason {
	Fraud = "Fraud",
	ByCustomer = "ByCustomer",
	NegativeBalance = "NegativeBalance",
	ProgramChange = "ProgramChange",
	BreachOfTermsAndConditions = "BreachOfTermsAndConditions",
	ByBank = "ByBank",
	NoAccountActivity = "NoAccountActivity",
}

export enum OverdraftStatus {
	Positive = "NoOverdraft",
	OverdrawnWithinLimit = "OverdrawnWithinLimit",
	Negative = "Overdrawn",
}

export enum AccountStatus {
	Open = "Open",
	Closed = "Closed",
	Frozen = "Frozen",
}

export enum FraudReason {
	ACHActivity = "ACHActivity",
	CardActivity = "CardActivity",
	CheckActivity = "CheckActivity",
	ApplicationHistory = "ApplicationHistory",
	AccountActivity = "AccountActivity",
	ClientIdentified = "ClientIdentified",
	IdentityTheft = "IdentityTheft",
	LinkedToFraudulentCustomer = "LinkedToFraudulentCustomer",
}

export enum DacaStatusType {
	Entered = "Entered",
	Activated = "Activated",
}

export enum BankReason {
	ProhibitedBusiness = "ProhibitedBusiness",
	MissingCddEdd = "MissingCddEdd",
	NonUsOperations = "NonUsOperations",
	SuspectedFraud = "SuspectedFraud",
	Unfunded = "Unfunded",
	Inactive = "Inactive",
}

export type Account =
	| DepositAccount
	| GLAccount
	| CreditGLAccount
	| BatchAccount
	| OrgGeneralLedgerAccount
	| CreditAccount
	| OrgLoanAccount
	| FinancialBusinessFBOAccount
	| Wallet

export type AccountStatusAudit = {
	type: "accountStatusAudit"
	attributes: {
		setBy?: string
		setAt?: Date
	}
	relationships: {
		account: {
			data: {
				type: "account"
				id: string
			}
		}
	}
}

export type AccountWithOwners = DepositAccount & IndividualCustomer[]

export type AchLimits = {
	dailyDebit: number
	dailyCredit: number
	monthlyDebit: number
	monthlyDebitSoft: number
	dailyDebitSoft: number
	monthlyCredit: number
}

export type BookTransferLimits = {
	dailyDebit?: number
	dailyCredit?: number
	monthlyDebit?: number
	monthlyCredit?: number
}

export type CardLimits = {
	dailyWithdrawal: number
	dailyDeposit: number
	dailyPurchase: number
	dailyCardTransaction: number
}

export type CheckLimits = {
	daily: number
	monthly: number
	dailySoft: number
	monthlySoft: number
}

export type AchTotalsDaily = {
	debits: number
	credits: number
}
export type AchTotalsMonthly = {
	debits: number
	credits: number
}

export type BookTransferTotalsDaily = {
	debits: number
	credits: number
}
export type BookTransferTotalsMonthly = {
	debits: number
	credits: number
}

export type CardTotalsDaily = {
	withdrawals: number
	deposits: number
	purchases: number
	cardTransactions: number
}

export type CheckTotalsDaily = number
export type CheckTotalsMonthly = number

export type WireLimits = {
	dailyTransfer: number
	monthlyTransfer: number
	dailyTransferSoft: number
	monthlyTransferSoft: number
}

export type WireTotalsDaily = {
	transfers: number
}
export type WireTotalsMonthly = {
	transfers: number
}

export type CheckPaymentLimits = {
	dailySent?: number
	monthlySent?: number
	dailySentSoft?: number
	monthlySentSoft?: number
}

export type CheckPaymentTotalsDaily = {
	sent: number
}
export type CheckPaymentTotalsMonthly = {
	sent: number
}

export type Limits = {
	type: "limits"
	attributes: {
		ach: {
			limits: AchLimits
			totalsDaily: AchTotalsDaily
			totalsMonthly: AchTotalsMonthly
		}
		bookTransfer: {
			limits: BookTransferLimits
			totalsDaily: BookTransferTotalsDaily
			totalsMonthly: BookTransferTotalsMonthly
		}
		card: {
			limits: CardLimits
			totalsDaily: CardTotalsDaily
		}
		checkDeposit: {
			limits: CheckLimits
			totalsDaily: CheckTotalsDaily
			totalsMonthly: CheckTotalsMonthly
		}
		wire: {
			limits: WireLimits
			totalsDaily: WireTotalsDaily
			totalsMonthly: WireTotalsMonthly
		}
		checkPayment: {
			limits: CheckPaymentLimits
			totalsDaily: CheckPaymentTotalsDaily
			totalsMonthly: CheckPaymentTotalsMonthly
		}
	}
}

export enum AccountType {
	deposit = "deposit",
	batch = "batch",
	gl = "gl",
	creditGL = "creditGL",
	credit = "credit",
	orgGeneralLedger = "orgGeneralLedger",
	orgLoan = "orgLoan",
	financialBusinessFBO = "financialBusinessFBO",
	wallet = "wallet",
}

export interface FindAccountsRequestParam {
	accessToken: string
	offset: number
	limit?: number
	sort?: string
	searchQuery?: string
	overdraftStatus?: string[]
	status?: AccountStatus[]
	dacaStatus?: DacaStatusType[]
	orgs?: string[]
	banks?: string[]
	types: (keyof typeof AccountType)[]
	customerId?: string
	fromBalance?: Cents | ""
	toBalance?: Cents | ""
	depositProductId?: number
	requestType: "json" | "csv"
	extraFields?: {
		account?: "maskedAccountNumber"
	}
}

export async function findAccounts(requestParam: FindAccountsRequestParam) {
	const query = {
		page: {
			limit: requestParam.limit,
			offset: requestParam.offset,
		},
		filter: {
			query: requestParam.searchQuery != "" ? requestParam.searchQuery : null,
			overdraftStatus: requestParam.overdraftStatus,
			status: requestParam.status,
			dacaStatus: requestParam.dacaStatus,
			orgs: requestParam.orgs && requestParam.orgs.length > 0 ? requestParam.orgs : null,
			banks: requestParam.banks && requestParam.banks.length > 0 ? requestParam.banks : null,
			types: requestParam.types,
			customerId: requestParam.customerId,
			fromBalance: requestParam.fromBalance != "" ? requestParam.fromBalance : null,
			toBalance: requestParam.toBalance != "" ? requestParam.toBalance : null,
			depositProductId: requestParam.depositProductId,
		},
		sort: requestParam.sort,
		include: "org",
		extraFields: requestParam.extraFields,
	}

	switch (requestParam.requestType) {
		case "csv":
			return await getFile("accounts", requestParam.accessToken, query)
		default:
			return await getResource<Array<Account>>("accounts", requestParam.accessToken, query)
	}
}

export async function getRevenueAccounts(accessToken: string, bankId: string) {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: "1",
			bankId,
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)

	return result.map((v) =>
		v.data.filter(isDepositAccount).filter((a: DepositAccount) => a.attributes.tags["purpose"] === "revenue")
	)
}

export async function getReserveAccounts(accessToken: string, bankId: string) {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: "1",
			bankId,
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)

	return result.map((v) =>
		v.data.filter(isDepositAccount).filter((a: DepositAccount) => a.attributes.tags["purpose"] === "reserve")
	)
}

export async function getSponsoredAccounts(accessToken: string, bankId: string) {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: "1",
			bankId,
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)

	return result.map((v) =>
		v.data.filter(isDepositAccount).filter((a: DepositAccount) => a.attributes.tags["purpose"] === "sponsoredInterest")
	)
}

export async function getOverdraftReserveAccounts(accessToken: string, bankId: string) {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: "1",
			bankId,
			tags: JSON.stringify({purpose: "overdraft_reserve"}),
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)
	return result.map((v) =>
		v.data.filter(isDepositAccount).filter((a: DepositAccount) => a.attributes.tags["purpose"] === "overdraft_reserve")
	)
}

export async function getOrgGeneralLedgerAccounts(accessToken: string, bankId: string, orgId: string) {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: orgId,
			type: AccountType.orgGeneralLedger,
			...(bankId ? {bankId} : {}),
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)
	return result.map((v) => v.data.filter(isOrgGeneralLedgerAccount))
}

export async function getOrgLoanAccounts(
	accessToken: string,
	bankId: string,
	orgId: string
): Promise<Result<OrgLoanAccount[], ErrorDocument>> {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			type: AccountType.orgLoan,
			...(bankId ? {bankId} : {}),
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)
	return result.map((v) => v.data.filter(isOrgLoanAccount).filter((x) => x.relationships.borrowingOrg.data.id == orgId))
}

export async function getAccountsWithPurpose(accessToken: string, bankId: string, orgId: string, purpose: string) {
	if (bankId === "0") return ok([])

	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: orgId,
			bankId,
			tags: JSON.stringify({purpose: purpose}),
		},
	}
	const result = await getResource<Array<Account>>("accounts", accessToken, query)

	return result.map((v) =>
		v.data.filter(isDepositAccount).filter((a: DepositAccount) => a.attributes.tags["purpose"] === purpose)
	)
}

export async function getCustomerAccounts(accessToken: string, offset: number, limit: number, customerId: string) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			customerId,
		},
		extraFields: {
			account: "maskedAccountNumber",
		},
	}

	const result = await getResource<Array<DepositAccount>>("accounts", accessToken, query)

	return result.map((v) => v.data)
}

export async function getAccount<Account>(accessToken: string, id: string): Promise<Result<Account, ErrorDocument>> {
	const result = await getResource<Account>(`accounts/${id}`, accessToken, {
		extraFields: {
			account: "maskedAccountNumber",
		},
	})

	return result.map((v) => v.data)
}

export async function getAccountWithOwners<AccountWithOwners>(accessToken: string, id: string) {
	const result = await getResource<AccountWithOwners>(`accounts/${id}`, accessToken, {
		include: "customer",
		extraFields: {
			account: "maskedAccountNumber",
		},
	})
	return result.map((v) => {
		return {account: v.data, included: v.included}
	})
}

export async function getAccountStatusAudit(accessToken: string, id: string) {
	const result = await getResource<AccountStatusAudit>(`accounts/${id}/status-audit`, accessToken)
	return result.map((v) => v.data)
}

export function isDepositAccount(account: any): account is DepositAccount {
	return account.type === "depositAccount"
}

export function isDepositOrFBOAccount(account: any): account is DepositAccount | FinancialBusinessFBOAccount {
	return isDepositAccount(account) || isFinancialBusinessFBOAccount(account)
}

export function isFinancialBusinessFBOAccount(account: any): account is FinancialBusinessFBOAccount {
	return account.type === "financialBusinessFBOAccount"
}

export function isOrgGeneralLedgerAccount(account: Account): account is OrgGeneralLedgerAccount {
	return account.type === "orgGeneralLedgerAccount"
}

export function isBatchAccount(account: Account): account is DepositAccount {
	return account.type === "batchAccount"
}

export function isCreditAccount(account: Account): account is CreditAccount {
	return account.type === "creditAccount"
}

export function isCreditOperationalAccount(account: Account): account is DepositAccount {
	return isDepositAccount(account) && account.attributes?.tags?.purpose === "credit_operational_account"
}

export function isWalletAccount(account: any): account is Wallet {
	return account.type === "walletAccount"
}

export function isDepositOrBatchOrFBOAccount(
	account: Account
): account is DepositAccount | BatchAccount | FinancialBusinessFBOAccount {
	return isDepositOrFBOAccount(account) || isBatchAccount(account)
}

export function isDepositOrCreditOrFBOAccountOrWallet(
	account: Account
): account is DepositAccount | CreditAccount | FinancialBusinessFBOAccount | Wallet {
	return isDepositOrFBOAccount(account) || isCreditAccount(account) || isWalletAccount(account)
}

export function isBankInAccount(
	account: Account
): account is
	| DepositAccount
	| CreditAccount
	| FinancialBusinessFBOAccount
	| OrgGeneralLedgerAccount
	| GLAccount
	| Wallet
	| CreditGLAccount
	| OrgLoanAccount {
	return (
		isDepositOrCreditOrFBOAccountOrWallet(account) ||
		isOrgGeneralLedgerAccount(account) ||
		isGlAccount(account) ||
		isCreditGLAccount(account) ||
		isOrgLoanAccount(account)
	)
}

export function isGlAccount(account: Account): account is GLAccount {
	return account.type == "glAccount"
}

export function isCreditGLAccount(account: Account): account is CreditGLAccount {
	return account.type == "creditGLAccount"
}

export function isOrgLoanAccount(account: Account): account is OrgLoanAccount {
	return account.type == "orgLoanAccount"
}

export function isFrozen(account: Account): account is DepositAccount {
	return account.attributes.status === AccountStatus.Frozen
}

export function isClosed(account: Account): account is DepositAccount {
	return account.attributes.status === AccountStatus.Closed
}

export async function getBatchAccounts(accessToken: string, offset: number, limit: number, orgId?: string) {
	const query = {
		page: {
			limit,
			offset,
		},
		include: "org",
		filter: {
			type: AccountType.batch,
			...(orgId && {orgs: [orgId]}),
		},
	}

	return await getResource<Array<BatchAccount>>("accounts", accessToken, query)
}

export async function getAccountsMetrics(accessToken: string) {
	return await getResource<AccountsMetrics>("accounts/org/metrics", accessToken)
}

export async function getAccountDepositProducts(accessToken: string, id: string) {
	return await getResource<Array<AccountDepositProduct>>(`accounts/${id}/deposit-products`, accessToken)
}

function getCloseAccountType(account: Account) {
	if (isDepositOrFBOAccount(account)) {
		return "depositAccountClose"
	} else if (isWalletAccount(account)) {
		return "walletAccountClose"
	} else if (isCreditAccount(account)) {
		return "creditAccountClose"
	} else if (isBatchAccount(account)) {
		return "batchAccountClose"
	}
	return "accountClose"
}
export async function closeAccount(
	accessToken: string,
	account: Account,
	reason: CloseReason,
	reasonText?: string,
	fraudReason?: string,
	bankReason?: string
) {
	const data = {
		type: getCloseAccountType(account),
		attributes: {
			reason,
			reasonText,
			fraudReason,
			bankReason,
		},
	}

	return await createResource<Account>(`accounts/${account.id}/close`, accessToken, data)
}

export async function enterDaca(accessToken: string, account: Account) {
	return await createResource<Account>(`accounts/${account.id}/enter-daca`, accessToken, {})
}

export async function terminateDaca(accessToken: string, account: Account) {
	return await createResource<Account>(`accounts/${account.id}/terminate-daca`, accessToken, {})
}

export async function activateDaca(accessToken: string, account: Account) {
	return await createResource<Account>(`accounts/${account.id}/activate-daca`, accessToken, {})
}

export async function deactivateDaca(accessToken: string, account: Account) {
	return await createResource<Account>(`accounts/${account.id}/deactivate-daca`, accessToken, {})
}

export async function reopenAccount(accessToken: string, account: Account) {
	return await createResource<Account>(`accounts/${account.id}/reopen`, accessToken, {})
}

export interface AccountFreezeDetails {
	reason: string
	subReason?: string
	reasonText?: string
}

interface FreezeAccountParams extends AccountFreezeDetails {
	accessToken: string
	account: Account
}

export interface AccountStatusChange extends AccountFreezeDetails {
	status: string
}

export async function freezeAccount({accessToken, account, reason, subReason, reasonText}: FreezeAccountParams) {
	const data = {
		type: "accountFreeze",
		attributes: {
			reason,
			subReason,
			reasonText,
		},
	}

	return await createResource<Account>(`accounts/${account.id}/freeze`, accessToken, data)
}

export async function unfreezeAccount(accessToken: string, account: Account) {
	const data = {
		type: "accountUnfreeze",
	}

	return await createResource<Account>(`accounts/${account.id}/unfreeze`, accessToken, data)
}

export async function getTotalDepositsChart(accessToken: string, since?: string, until?: string) {
	//Removing next line till the issue with accounts/org/total-deposit-chart will be solved
	return new Promise(() => {
		return {
			error: {
				errors: [
					{
						title: "Not Found",
						status: "404",
						detail: "",
					},
				],
			},
		}
	}) as Promise<Result<OkDocument<TotalDepositChartData>, ErrorDocument>>

	return await getResource<TotalDepositChartData>("accounts/org/total-deposit-chart", accessToken, {
		filter: {
			since,
			until,
		},
	})
}

export async function updateDepositAccount(
	accessToken: string,
	id: string,
	{depositProduct, name}: {depositProduct?: string; name?: string}
) {
	const result = await updateResource<Account>(`accounts/${id}/`, accessToken, {
		type: "depositAccount",
		attributes: Object.assign(
			{},
			depositProduct ? {depositProduct: depositProduct} : undefined,
			name ? {name: name} : undefined
		),
	})
	return result.map((v) => v.data)
}

export async function addSecondaryAccountNumber(accessToken: string, id: string, bankRountingId: string) {
	const data = {
		relationships: {
			bankRouting: {
				data: {
					id: bankRountingId,
				},
			},
		},
	}

	return await createResource<Account>(`accounts/${id}/secondary-routing-number`, accessToken, data)
}

export async function createBatchAccount(accessToken: string, orgId: string, name: string, depositProduct: string) {
	const data = {
		type: "batchAccount",
		attributes: {
			depositProduct: depositProduct,
			name: name,
		},
		relationships: {
			org: {
				data: {
					id: orgId,
					type: "org",
				},
			},
		},
	}

	return await createResource<Account>("accounts", accessToken, data)
}

export async function getAccountLimits(accessToken: string, id: string) {
	const result = await getResource<Limits>(`accounts/${id}/limits`, accessToken)
	return result.map((v) => v.data)
}

export async function getCreditAccounts(accessToken: string, orgId: string) {
	const query = {
		page: {
			limit: 1000,
			offset: 0,
		},
		filter: {
			orgId: orgId,
			type: AccountType.credit,
		},
	}

	const result = await getResource<Array<Account>>("accounts", accessToken, query)
	return result.map((v) => v.data.filter(isCreditAccount))
}

export async function getOrgAccountsTotalCreditLimit(accessToken: string, bankId: string) {
	const query = {bankId}
	const result = await getResource<AccountsTotalCreditLimit>("accounts/org/total-credit-limit", accessToken, query)
	return result.map((v) => v.data)
}
