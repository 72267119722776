import React from "react"
import {IncomingWire} from "../../resources/incomingWire"
import {ApproveIncomingWireMissingDetails} from "./ApproveIncomingWireMissingDetails"
import {ApproveReturnIncomingWireMissingPayment} from "./ApproveReturnIncomingWireMissingPayment"
interface ApproveIncomingWireModalProps {
	incomingWire: IncomingWire
	close: () => void
	onSuccess: () => void
}

export function ApproveIncomingWireModal({incomingWire, close, onSuccess}: ApproveIncomingWireModalProps) {
	const isReturnedWithMissingPayment =
		incomingWire.attributes.errorReason === "ReturnPaymentNotFound" && incomingWire.attributes.isReturn

	return isReturnedWithMissingPayment ? (
		<ApproveReturnIncomingWireMissingPayment incomingWire={incomingWire} close={close} onSuccess={onSuccess} />
	) : (
		<ApproveIncomingWireMissingDetails incomingWire={incomingWire} close={close} onSuccess={onSuccess} />
	)
}
