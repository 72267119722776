import React, {useRef, useState} from "react"
import numeral from "numeral"
import CurrencyInput from "react-currency-input-field"
import {useDebounce} from "react-use"
import {camelCase} from "lodash"

interface RangeFilterItemProps {
	title: string
	selected: boolean
	onSelect: () => void
	onChange: (value: number | "") => void
	defaultValue?: number
}

interface RangeFilterItemInstanceProps {
	selected: boolean
	onSelect: () => void
	onChange: (value: number | "") => void
	defaultValue?: number
}

interface InBetweenProps {
	selected: boolean
	defaultFrom?: number
	defaultTo?: number
	onSelect: () => void
	onChangeFrom: (value: number | "") => void
	onChangeTo: (value: number | "") => void
}

export function RangeFilterItem({title, selected, onSelect, onChange, defaultValue}: RangeFilterItemProps) {
	const [innerDefault, setInnerDefault] = useState<number | undefined | "">(defaultValue)
	const [value, setValue] = useState<number | string | undefined>(defaultValue || "")
	const isFirstRender = useRef(true)

	const [,] = useDebounce(
		() => {
			if (isFirstRender.current) {
				isFirstRender.current = false
			} else {
				if (value === undefined) {
					onChange("")
				} else {
					const v = numeral(value).value()
					onChange(Math.round(v * 100))
				}
			}
		},
		500,
		[value]
	)

	return (
		<a
			className={"dropdown-item"}
			onClick={(e) => {
				e.stopPropagation()
				e.preventDefault()
				setInnerDefault("")
				if (!selected) {
					onSelect()
				}
			}}
		>
			<div className="control">
				<label className="radio">
					<input readOnly type="radio" name={camelCase(title)} checked={selected} />
					{title}
				</label>
				{selected && (
					<CurrencyInput
						placeholder={"$"}
						autoFocus={true}
						prefix="$"
						className={"range-filter-item-input"}
						defaultValue={innerDefault}
						decimalsLimit={2}
						onClick={(e) => {
							e.stopPropagation()
						}}
						onValueChange={(value) => {
							setValue(value)
						}}
					/>
				)}
			</div>
		</a>
	)
}

export function LessThan({selected, onSelect, onChange, defaultValue}: RangeFilterItemInstanceProps) {
	return (
		<RangeFilterItem
			onSelect={onSelect}
			defaultValue={defaultValue}
			title={"Less than"}
			selected={selected}
			onChange={onChange}
		/>
	)
}

export function GreaterThan({selected, onSelect, onChange, defaultValue}: RangeFilterItemInstanceProps) {
	return (
		<RangeFilterItem
			onSelect={onSelect}
			defaultValue={defaultValue}
			title={"Greater than"}
			selected={selected}
			onChange={onChange}
		/>
	)
}

export function InBetween({selected, onSelect, onChangeFrom, onChangeTo, defaultTo, defaultFrom}: InBetweenProps) {
	const [innerDefaultTo, setInnerDefaultTo] = useState<number | undefined | "">(defaultTo)
	const [innerDefaultFrom, setInnerDefaultFrom] = useState<number | undefined | "">(defaultFrom)
	const [to, setTo] = useState<number | string>(defaultTo || "")
	const [from, setFrom] = useState<number | string>(defaultFrom || "")
	const [errorMessage, setErrorMessage] = useState<string>("")
	const isFirstRender = useRef(true)

	const [,] = useDebounce(
		() => {
			if (isFirstRender.current) {
				isFirstRender.current = false
			} else {
				const fromValue = numeral(from).value()
				const toValue = numeral(to).value()

				if (from === "" || to === "") {
					if (from === "") {
						setErrorMessage("**Specify start range")
						return
					}
					if (to === "") {
						setErrorMessage("**Specify end range")
						return
					}

					setErrorMessage("")
					return
				}
				if (fromValue > toValue) {
					setErrorMessage("**End should be greater than start")
					return
				}

				if (from != defaultFrom && to != defaultTo) {
					onChangeFrom(Math.round(fromValue * 100))
					onChangeTo(Math.round(toValue * 100))
					setErrorMessage("")
				}
			}
		},
		800,
		[to, from]
	)

	return (
		<a
			className={"dropdown-item"}
			onClick={(e) => {
				e.preventDefault()
				e.stopPropagation()
				if (!selected) {
					setErrorMessage("")
					setInnerDefaultTo("")
					setInnerDefaultFrom("")
					setTo("")
					setFrom("")
					onSelect()
				}
			}}
		>
			<div className="control">
				<label className="radio">
					<input readOnly type="radio" name="inBetween" checked={selected} />
					In between
				</label>
				{selected && (
					<>
						<div className={"range-filter-item-input-multiple-placeholder"}>
							<CurrencyInput
								placeholder={"$"}
								autoFocus={true}
								prefix="$"
								className={"range-filter-item-input"}
								defaultValue={innerDefaultFrom}
								decimalsLimit={2}
								onClick={(e) => {
									e.stopPropagation()
								}}
								onValueChange={(value) => {
									setFrom(value || "")
								}}
							/>

							<span> to </span>
							<CurrencyInput
								placeholder={"$"}
								prefix="$"
								className={"range-filter-item-input"}
								defaultValue={innerDefaultTo}
								decimalsLimit={2}
								onClick={(e) => {
									e.stopPropagation()
								}}
								onValueChange={(value) => {
									setTo(value || "")
								}}
							/>
						</div>
						{errorMessage && <div className={"error-message"}>{errorMessage}</div>}
					</>
				)}
			</div>
		</a>
	)
}
