import React, {CSSProperties, ReactNode} from "react"

interface CircleProgressBarProps {
	percent: number
	diameter?: string
	thickness?: string
	fillColor?: string
	emptyColor?: string
	innerColor?: string
	children?: ReactNode
}

/**
 * Circle progress bar with default size of 100px and thickness of 10px
 * @param percent - progress in percent
 * @param children - children to render inside the inner circle
 * @param diameter - diameter of the outer circle
 * @param thickness - thickness of the progress bar
 * @param fillColor - color of the filled part of the bar
 * @param emptyColor - color of the empty part of the bar
 * @param innerColor - background color of the inner circle
 * @constructor
 */
export function CircleProgressBar({
	percent,
	children,
	diameter = "100px",
	thickness = "10px",
	fillColor = "#545558",
	emptyColor = "#EBECEE",
	innerColor = "white",
}: CircleProgressBarProps) {
	const degrees = percent * 3.6 // (percent / 100) * 360
	const style = {
		"--diameter": diameter,
		"--degrees": `${degrees}deg`,
		"--fill-color": fillColor,
		"--empty-color": emptyColor,
		"--bg-color": innerColor,
		"--thickness": thickness,
	} as CSSProperties

	return (
		<div role="progressbar" aria-valuenow={percent} className="circle-progress-bar" style={style}>
			<div className="inner-circle">{children}</div>
		</div>
	)
}
