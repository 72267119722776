import React from "react"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Login from "./pages/Login/Login"
import {AuthProvider} from "./services/auth"
import {ModalProvider} from "react-modal-hook"
import {CookiesProvider} from "react-cookie"
import LoginSSO from "./pages/Login/LoginSSO"
import SignUp from "./pages/SignUp/SignUp"
import {PermissionProvider} from "./services/permission"
import {isSandboxEnv, isSandboxOrLocalEnv} from "./utilities/environment"
import {useHotjar} from "./hooks/UseHotjar"
import {AuthenticatedDefaultLayout} from "./containers/DefaultLayout/DefaultLayout"
import {BulmaToastProvider} from "./components/BulmaToast/BulmaToast"
import {ClaimsProvider} from "./services/claims"
import MFA from "./pages/Login/LoginMFA"

export default function App() {
	useHotjar(isSandboxEnv())

	return (
		<CookiesProvider>
			<AuthProvider>
				<ClaimsProvider>
					<PermissionProvider>
						<ModalProvider>
							<BulmaToastProvider>
								<Router>
									<Routes>
										<Route path="/login/two-factor" element={<MFA />} />
										<Route path="/login/*" element={<Login />} />
										<Route path="/sso" element={<LoginSSO />} />
										{isSandboxOrLocalEnv() && <Route path="/signup" element={<SignUp />} />}
										<Route path="/*" element={<AuthenticatedDefaultLayout />} />
									</Routes>
								</Router>
							</BulmaToastProvider>
						</ModalProvider>
					</PermissionProvider>
				</ClaimsProvider>
			</AuthProvider>
		</CookiesProvider>
	)
}
