import React, {Dispatch, InputHTMLAttributes, SetStateAction} from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import InputMask from "react-input-mask"
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import {flattenObject} from "../../utilities/helpers"
import CurrencyInput from "react-currency-input-field"
import {isNumber, isString} from "lodash"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	label: string
	subLabel?: string
	value: string | number
	onChange: Dispatch<SetStateAction<any>>
	onBlur: Dispatch<SetStateAction<any>>
	inputMask?: string
	alwaysShowMask?: boolean
	showInfoTooltip?: boolean
	infoTooltipText?: string
	placeholder?: string
	fieldClassName?: string
	prefix?: string
	suffix?: string
}

export default function Textfield({
	name,
	label,
	subLabel,
	value,
	onChange,
	onBlur,
	inputMask,
	alwaysShowMask = true,
	showInfoTooltip = false,
	infoTooltipText,
	placeholder,
	fieldClassName,
	prefix,
	suffix,
	...inputProps
}: Props) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"textfield",
		fieldClassName,
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)

	return (
		<div className={className}>
			<label>
				<span className={classNames("label-text", inputProps.disabled && "disabled")}>
					{label} {showInfoTooltip ? <FontAwesomeIcon data-tip={infoTooltipText} icon={faQuestionCircle} /> : null}
				</span>
				{subLabel && <span className="sub-label-text">{`(${subLabel})`}</span>}

				{(prefix || suffix) && (isString(value) || isNumber(value)) ? (
					<CurrencyInput
						name={name}
						prefix={prefix}
						suffix={suffix}
						decimalsLimit={2}
						onBlur={onBlur}
						onValueChange={(value) => onChange(value)}
						defaultValue={value}
					/>
				) : inputMask ? (
					<InputMask
						name={name}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						{...inputProps}
						mask={inputMask}
						alwaysShowMask={alwaysShowMask}
					/>
				) : (
					<input
						placeholder={placeholder}
						autoComplete="off"
						name={name}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						{...inputProps}
					/>
				)}
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
			<ReactTooltip effect={"solid"} className={"tooltip"} />
		</div>
	)
}
