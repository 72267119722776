import React, {useEffect, useState} from "react"
import {useAccessToken} from "../../services/auth"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {range} from "lodash"
import Skeleton from "react-loading-skeleton"
import {usePaging} from "../../hooks/usePaging"
import {
	AccountStatement,
	getAccountStatements,
	getStatementHtml,
	getStatementPdf,
} from "../../resources/accountStatement"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFilePdf} from "@fortawesome/free-regular-svg-icons"
import {downloadFile} from "../../utilities/file"
import {useToasts} from "react-toast-notifications"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
} from "../DataTable/DataTable"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons"

interface Props {
	accountId: string
	token?: string
	limit?: number
	isUsingPaging?: boolean
}

function StatementRow({
	token,
	statement,
	customerId,
}: {
	token: string
	statement: AccountStatement
	customerId?: string
}) {
	const [htmlResultState, getHtmlRequest] = useAsyncResultIdle(getStatementHtml)
	const [pdfResultState, getPdfRequest] = useAsyncResultIdle(getStatementPdf)
	const [isPdfLoading, setIsPdfLoading] = useState(false)
	const {addToast} = useToasts()

	useEffect(() => {
		htmlResultState.match(
			() => null,
			() => null,
			(html) => {
				const newTab = window.open()
				if (newTab) {
					newTab.document.write(html)
					newTab.document.close()
					newTab.document.location.href = "#"
				}
			},
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
	}, [htmlResultState])

	useEffect(() => {
		pdfResultState.match(
			() => null,
			() => null,
			(pdf) => {
				setIsPdfLoading(false)
				downloadFile(`Statement-${statement.id}.pdf`, pdf, "application/pdf")
			},
			() => {
				setIsPdfLoading(false)
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
	}, [pdfResultState])

	return (
		<DataTableRow
			onClick={() => {
				getHtmlRequest(token, statement.id, customerId)
			}}
		>
			<DataTableCell>{statement.id}</DataTableCell>
			<DataTableCell>{customerId !== undefined ? customerId : statement.relationships.customer?.data.id}</DataTableCell>
			<DataTableCell>{statement.attributes.period}</DataTableCell>
			<DataTableCell
				className="has-text-centered small-width hide"
				onClick={(e) => {
					e.stopPropagation()
					if (isPdfLoading) {
						return
					}
					setIsPdfLoading(true)
					getPdfRequest(token, statement.id, customerId)
				}}
			>
				<FontAwesomeIcon icon={isPdfLoading ? faCircleNotch : faFilePdf} spin={isPdfLoading} />
			</DataTableCell>
		</DataTableRow>
	)
}

export function StatementsTable({
	token,
	statements,
	hasResults,
	hasPrev,
	hasNext,
	prev,
	next,
	isUsingPaging,
}: {
	token: string
	statements: Array<AccountStatement>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
}) {
	return (
		<div className={"account-cards-table"}>
			<DataTable
				isEmpty={statements.length === 0}
				fullHeight={false}
				stickyAction={false}
				noContentText={"No statements found"}
			>
				<DataTableHead>
					<DataTableRow>
						<DataTableCell> Id </DataTableCell>
						<DataTableCell> Customer ID </DataTableCell>
						<DataTableCell> Period </DataTableCell>
						<DataTableCell> Download </DataTableCell>
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{statements.map((a) =>
						a.relationships.customers ? (
							a.relationships.customers.data.map((customer, index) => {
								return <StatementRow key={index} token={token} statement={a} customerId={customer.id} />
							})
						) : (
							<StatementRow key={a.id} token={token} statement={a} />
						)
					)}
				</DataTableBody>
			</DataTable>
			{isUsingPaging ? (
				<PagingNavBar
					hasResults={hasResults}
					hasPrev={hasPrev}
					hasNext={hasNext}
					prev={prev}
					next={next}
					isShow={isUsingPaging}
				/>
			) : null}
		</div>
	)
}

export function Pending() {
	return (
		<div className="table-container">
			<table className="table">
				<thead>
					<tr>
						<th>Id</th>
						<th>Customer ID</th>
						<th>Period</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{range(6).map((i) => (
						<tr key={i}>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
							<td>
								<Skeleton />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export function AccountStatements({accountId, token, limit = 5, isUsingPaging = true}: Props) {
	const accessToken = token ?? useAccessToken()

	const [result, hasPrev, hasNext, prev, next, hasResults] = usePaging(
		limit,
		(o, l) => getAccountStatements(accessToken, o, l, accountId),
		(x) => x.length,
		[],
		"statement-"
	)

	return (
		<DataTableCard className={"account-statements"}>
			<DataTableActionHeader title={"Statements"} />
			<AsyncResultComponent asyncResult={result} pendingComponent={<Pending />}>
				{({value: statements}) => {
					return (
						<StatementsTable
							token={accessToken}
							statements={statements}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							isUsingPaging={isUsingPaging}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
