import React, {useEffect, useState} from "react"
import {useCookies} from "react-cookie"
import {useToasts} from "react-toast-notifications"
import {Link} from "react-router-dom"
import {useLocalStorage} from "react-use"
import {Token} from "../../services/auth"
import LoginContainer from "./LoginContainer"

export default function LoginSSO() {
	const {addToast} = useToasts()
	const [workspaceName, setWorkspaceName] = useLocalStorage<string | undefined>("workspaceName", undefined)
	const [, setToken] = useLocalStorage<Token | undefined>("token")
	const [orgWorkspace, setOrgWorkspace] = useState(workspaceName ?? "")
	const [cookies, , removeCookie] = useCookies(["login_failure"])

	useEffect(() => {
		try {
			const errorCookie = cookies.login_failure
			if (errorCookie) addToast(errorCookie.reason, {appearance: "error"})
		} finally {
			removeCookie("login_failure")
		}
	}, [])

	const redirectToSSOLogin = (orgWorkspace: string) => {
		setToken(undefined)
		setWorkspaceName(orgWorkspace)
		window.location.replace(`${process.env.API_URL}/sso/saml/login/${orgWorkspace}`)
		return null
	}

	return (
		<LoginContainer>
			<div className="login-container is-flex is-flex-grow-1 is-flex-direction-column is-align-self-center is-justify-content-center">
				<span className="label login-title">Sign in with SSO</span>
				<div className="login-form">
					<div className="field">
						<label className="label">Workspace</label>
						<div className="control">
							<input
								className="input is-medium"
								type="text"
								value={orgWorkspace}
								onKeyPress={(e) => {
									e.key === "Enter" ? redirectToSSOLogin(orgWorkspace) : null
								}}
								placeholder="Your Workspace name"
								onChange={(e) => setOrgWorkspace(e.target.value)}
								required
								autoComplete="on"
							/>
						</div>
					</div>
					<div className="field login-buttons">
						<div className="control is-flex is-align-items-center buttons">
							<button
								type="button"
								className="button is-black is-medium is-fullwidth"
								onClick={() => {
									redirectToSSOLogin(orgWorkspace)
								}}
							>
								Continue
							</button>
							<Link to="/login" className="back-to-login-link">
								Back To Sign in
							</Link>
						</div>
					</div>
				</div>
			</div>
		</LoginContainer>
	)
}
