import {createResource, getResource, updateResource} from "./common"

export type CardDesign = {
	type: "cardDesign"
	id: string
	attributes: {
		name: string
		cardQualifier: string
		createdAt: Date
		lowerMark: boolean
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: string
			}
		}
	}
}

export async function findCardDesigns(
	accessToken: string,
	offset: number,
	limit: number,
	orgs?: string[],
	cardQualifier?: string
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : null
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			orgs: orgFilter,
			cardQualifier: cardQualifier,
		},
		include: "org",
	}

	return await getResource<Array<CardDesign>>("card-designs", accessToken, query)
}

export async function getCardDesign<ICardDesign>(accessToken: string, id: string) {
	const result = await getResource<ICardDesign>(`card-designs/${id}`, accessToken)

	return result.map((v) => v.data)
}

export async function createCardDesign(
	accessToken: string,
	name: string,
	cardQualifier: string,
	orgId: string,
	lowerMark: boolean
) {
	return await createResource<CardDesign>("card-designs", accessToken, {
		type: "cardDesign",
		attributes: {
			name,
			cardQualifier,
			lowerMark,
		},
		relationships: {
			org: {
				data: {
					type: "org",
					id: orgId,
				},
			},
		},
	})
}

export async function updateCardDesign(
	accessToken: string,
	id: string,
	name: string,
	cardQualifier: string,
	lowerMark: boolean
) {
	const result = await updateResource<CardDesign>(`card-designs/${id}`, accessToken, {
		type: "cardDesign",
		attributes: {
			name: name,
			cardQualifier: cardQualifier,
			lowerMark: lowerMark,
		},
	})
	return result.map((v) => v.data)
}
