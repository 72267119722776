import React from "react"
import {MainSectionItem, MainSectionItemFullWidth} from "./MainSection"

type PaymentLayoutProps = {
	outerWrapperClassname?: string
	innerWrapperClassname?: string
	renderBefore?: JSX.Element | null
	renderAfter?: JSX.Element | null
	fullWidth?: boolean
	children: React.ReactNode
}
export default function MainSectionLayout({
	outerWrapperClassname,
	innerWrapperClassname,
	renderBefore = null,
	renderAfter = null,
	fullWidth,
	children,
}: PaymentLayoutProps) {
	const MainSection = fullWidth ? MainSectionItemFullWidth : MainSectionItem
	return (
		<div className={outerWrapperClassname}>
			{renderBefore}
			<MainSection>
				<div className={innerWrapperClassname}>{children}</div>
			</MainSection>
			{renderAfter}
		</div>
	)
}
