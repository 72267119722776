import Icon from "../Icon/Icon"
import React from "react"

interface ImageTransformControlsProps {
	changeRotation: () => void
	zoomIn: () => void
	zoomOut: () => void
	zoomVal: number
}

export function ImageTransformControls(props: ImageTransformControlsProps) {
	const {changeRotation, zoomIn, zoomOut, zoomVal} = props

	return (
		<div className="transform-buttons-wrapper image-transform-controls">
			<button aria-label="rotation button" onClick={changeRotation}>
				<Icon icon="design-tool-rotate-clockwise" />
			</button>

			<button aria-label="zoom in button" onClick={zoomIn} disabled={zoomVal === 2}>
				<Icon icon="zoom-in" />
			</button>
			<button aria-label="zoom out button" onClick={zoomOut} disabled={zoomVal === 1}>
				<Icon icon="zoom-out" />
			</button>
		</div>
	)
}
