import React, {ReactElement} from "react"
import classNames from "classnames"
import {SetQueryStateItemFn} from "use-location-state"
import Icon from "../Icon/Icon"
import {IcomoonIconName} from "../Icon/icons"

export type TabsMeta<T> = {
	label: string
	key: T
	suffixElement?: JSX.Element
	liClassName?: string
	prefixIcon?: IcomoonIconName
}

type TitleBarTabsProps<T> = {
	currentTab: T
	setCurrentTab: SetQueryStateItemFn<T>
	tabs: TabsMeta<T>[]
	navClassName?: string
}

export function TitleBarTabs<T>({currentTab, setCurrentTab, tabs, navClassName}: TitleBarTabsProps<T>): ReactElement {
	return (
		<nav className={classNames("title-bar-tabs-container", navClassName)}>
			<div className="tabs title-bar-tabs">
				<ul>
					{tabs.map((tab, index) => (
						<li
							key={`tab-${tab.key}-${index}`}
							className={classNames(currentTab === tab.key && "is-active", !!tab.liClassName && tab.liClassName)}
							onClick={() => setCurrentTab(tab.key)}
						>
							<a className="icon-text">
								{tab.prefixIcon && (
									<span className="icon">
										<Icon icon={tab.prefixIcon} size={16} />
									</span>
								)}
								<span className={classNames(tab.prefixIcon && "has-prefix-icon")}>{tab.label}</span>
								{!!tab.suffixElement && tab.suffixElement}
							</a>
						</li>
					))}
				</ul>
			</div>
		</nav>
	)
}
