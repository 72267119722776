import React from "react"

interface Props {
	left: React.ReactNode
	right: React.ReactNode
}

export default function LeftRightSlots({left, right}: Props) {
	return (
		<div className="left-right-slots_container">
			<div className="left-right-slots_left-slot">{left}</div>
			<div className="left-right-slots_divider" />
			<div className="left-right-slots_right-slot">{right}</div>
		</div>
	)
}
