import React from "react"
import classNames from "classnames"
import {AssignmentNavigatorItemDate} from "../AssignmentNavigatorItemDate/AssignmentNavigatorItemDate"
import {AssigneeAvatarWithName} from "../AssigneeAvatarWithName/AssigneeAvatarWithName"
import ReactTooltip from "react-tooltip"
import {AssignmentItemStatus} from "../AssignmentItemStatus/AssignmentItemStatus"
import {AssignmentItem, AssignmentStage} from "../../resources/assignments"
import {getAssignmentIsDisabled} from "../../pages/Assignments/AssignmentsView"

export interface AssignmentNavigatorItemProps extends Omit<AssignmentItem, "payload" | "type" | "id"> {
	selected?: boolean
	disabled?: boolean
	onClick: () => void
}

export function AssignmentNavigatorItem({
	title,
	description,
	status,
	stage,
	assignees,
	createdAt,
	statusUpdatedAt,
	selected,
	isOwnersItem,
	disabled,
	onClick,
}: AssignmentNavigatorItemProps) {
	const showOwnersIndicator = isOwnersItem && !getAssignmentIsDisabled(stage, true)
	const areDescriptionsVisible = false // TODO: We don't want to show the description until every assignment type has one
	const date = stage === AssignmentStage.Completed && statusUpdatedAt ? statusUpdatedAt : createdAt
	return (
		<section
			className={classNames("assignment-navigator-item", selected && "selected", disabled && "disabled")}
			onClick={onClick}
		>
			{showOwnersIndicator && <div className="assignment-navigator-item-dot" />}
			<div className="assignment-navigator-item-container">
				<div
					className={classNames(
						"assignment-navigator-item-title",
						showOwnersIndicator && "is-owners-item",
						disabled && "disabled"
					)}
				>
					{title}
				</div>
				{description && areDescriptionsVisible && (
					<div
						className={classNames(
							"assignment-navigator-item-description",
							showOwnersIndicator && "is-owners-item",
							disabled && "disabled"
						)}
					>
						{description}
					</div>
				)}
				<div className="assignment-item-status-container">
					<AssignmentItemStatus status={status} />
				</div>
				{(assignees || date) && (
					<>
						<div className="assignment-navigator-item-divider" />
						<div className="assignment-navigator-item-footer">
							<div className="assignment-navigator-item-avatar-with-name-container">
								{assignees && <AssigneeAvatarWithName assignees={assignees} />}
							</div>
							<div className="assignment-navigator-item-date-container">
								{date && (
									<>
										<span
											data-tip={
												stage === AssignmentStage.Completed && statusUpdatedAt ? "Completion Date" : "Creation Date"
											}
											data-for={`date-tooltip-for-${title}`}
										>
											<AssignmentNavigatorItemDate date={date} />
										</span>
										<ReactTooltip
											id={`date-tooltip-for-${title}`}
											place="top"
											effect="solid"
											className="assignment-navigator-item-footer-date-tooltip"
											delayShow={100}
											disable={true} //TODO: it should be false, but there's a bug when showing the tooltip while scrolling the navigator makes the navigator have an unwanted vertical scroll
										/>
									</>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	)
}
