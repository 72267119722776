import {Cents} from "./transaction"
import {Address, createResource, getFile, getResource, getResourceAsArrayBuffer} from "./common"
import {CheckImageSide} from "./checkDeposits"

type Account = {
	type: string
	id: string
}

type Customer = {
	type: string
	id: string
}

type Transaction = {
	type: string
	id: number
}

type Org = {
	type: "org"
	id: string
}

interface CheckPaymentImageGetParam {
	accessToken: string
	id: string
	side: CheckImageSide
}

export enum CheckPaymentsStatus {
	PendingReview = "PendingReview",
	MarkedForReturn = "MarkedForReturn",
	Processed = "Processed",
	Returned = "Returned",
	New = "New",
	Pending = "Pending",
	InDelivery = "InDelivery",
	ReturnedToSender = "ReturnedToSender",
	Canceled = "Canceled",
	Delivered = "Delivered",
	InProduction = "InProduction",
	PendingCancellation = "PendingCancellation",
	Rejected = "Rejected",
}

export enum CheckPaymentDeliveryStatus {
	Mailed = "Mailed",
	InLocalArea = "InLocalArea",
	Rerouted = "Rerouted",
}

export type CheckOriginationCounterparty = {
	name: string
	address: Address
	counterpartyMoved?: boolean
}

export enum CheckPaymentAdditionalVerificationStatus {
	"Required" = "Required",
	"NotRequired" = "NotRequired",
	"Approved" = "Approved",
}

export type CheckPayment = {
	type: "checkPayment"
	id: string
	attributes: {
		createdAt: Date
		updatedAt: Date
		sendAt?: Date
		amount: Cents
		status: CheckPaymentsStatus
		tags?: {[key: string]: string}
		description?: string
		returnReason?: string
		returnStatusReason?: string
		pendingReviewReasons?: string[]
		checkNumber?: string
		onUsAuxiliary?: string
		onUs?: string
		counterpartyRoutingNumber?: string
		counterpartyAccountNumber?: string
		payeeName?: string
		returnCutoffTime?: Date
		additionalVerificationStatus: CheckPaymentAdditionalVerificationStatus
		memo?: string
		expectedDelivery?: Date
		deliveryStatus?: CheckPaymentDeliveryStatus
		trackedAt?: Date
		postalCode?: string
		counterparty?: CheckOriginationCounterparty
		rejectReason?: string
		originated?: boolean
	}
	relationships: {
		account: {
			data: Account
		}
		customer: {
			data: Customer
		}
		customers?: {
			data: Customer[]
		}
		org?: {
			data: Org
		}
		transaction?: {
			data: Transaction
		}
		returnedTransaction?: {
			data: Transaction
		}
	}
}

export type NameMismatchReviewReasons = {
	namesScoreMap: {
		[key: string]: string
	}
}

export type PendingReviewReasons = Array<{
	softLimit?: {}
	nameMissMatch?: NameMismatchReviewReasons
}>

export async function findCheckPayments(
	accessToken: string,
	offset: number,
	sort: string,
	searchQuery: string,
	requestType: "json" | "csv",
	limit?: number | null,
	statusFilter?: CheckPaymentsStatus[],
	orgs?: string[],
	since?: string,
	until?: string,
	accountId?: string,
	fromAmount?: Cents | "",
	toAmount?: Cents | ""
) {
	const orgFilter = orgs && orgs.length > 0 ? orgs : undefined
	const query = {
		page: {
			limit: limit,
			offset,
		},
		filter: {
			status: statusFilter,
			query: searchQuery != "" ? searchQuery : null,
			orgs: orgFilter,
			since: since != "" ? since : null,
			until: until != "" ? until : null,
			accountId: accountId,
			fromAmount: fromAmount != "" ? fromAmount : null,
			toAmount: toAmount != "" ? toAmount : null,
		},
		sort: sort,
		include: "customer,account,org",
	}

	switch (requestType) {
		case "csv":
			return await getFile("check-payments", accessToken, query)
		default:
			return await getResource<Array<CheckPayment>>("check-payments", accessToken, query)
	}
}

export async function getCheckPayment(accessToken: string, id: string, include?: string) {
	return await getResource<CheckPayment>(`check-payments/${id}`, accessToken, {include})
}

export async function returnCheckPayment({id, accessToken, reason}: {id: string; accessToken: string; reason: string}) {
	const data = {
		type: "checkPaymentReturn",
		attributes: {
			reason: reason,
		},
	}
	return await createResource<CheckPayment>(`check-payments/${id}/return`, accessToken, data)
}

export async function approveCheckPayment({id, accessToken}: {id: string; accessToken: string}) {
	const data = {
		type: "additionalVerification",
	}
	return await createResource<CheckPayment>(`check-payments/${id}/approve`, accessToken, data)
}

export async function getCheckPaymentImage(requestParam: CheckPaymentImageGetParam) {
	switch (requestParam.side) {
		case CheckImageSide.Front:
			return await getResourceAsArrayBuffer(`check-payments/${requestParam.id}/front`, requestParam.accessToken)
		default:
			return await getResourceAsArrayBuffer(`check-payments/${requestParam.id}/back`, requestParam.accessToken)
	}
}
