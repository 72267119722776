import React from "react"
import {startCase} from "lodash"
import {
	AssigneeType,
	AssignmentResourceType,
	AssignmentStage,
	AssignmentType,
	findAssignments,
} from "../../resources/assignments"
import {Claims, useAccessToken, useIsBankUser, useIsOrgUser, useIsUnitUser, useUserType} from "../../services/auth"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useQueryState} from "use-location-state"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument} from "../../resources/common"
import {Filter} from "../../components/Filter/Filter"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../../components/DatePicker/DatePicker"
import {usePaging} from "../../hooks/usePaging"
import {DataTableActionHeader, DataTableCard, TablePending} from "../../components/DataTable/DataTable"
import moment from "moment"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {AssignmentsTable} from "./AssignmentsTable"
import {Bank, findBanks} from "../../resources/bank"

export enum AssignmentsHubColumns {
	isOwner = "",
	resourceType = "Resource Type",
	resourceId = "Resource Id",
	org = "Org",
	bank = "Bank",
	customer = "Customer",
	assignmentId = "Assignment Id",
	assignmentType = "Assignment Type",
	assignee = "Assignee",
	assignmentStatus = "Assignment Status",
	createdAt = "Created At",
}

export type AllowedAssignmentsHubColumns =
	| AssignmentsHubColumns.isOwner
	| AssignmentsHubColumns.resourceType
	| AssignmentsHubColumns.resourceId
	| AssignmentsHubColumns.org
	| AssignmentsHubColumns.bank
	| AssignmentsHubColumns.customer
	| AssignmentsHubColumns.assignmentId
	| AssignmentsHubColumns.assignmentType
	| AssignmentsHubColumns.assignee
	| AssignmentsHubColumns.assignmentStatus
	| AssignmentsHubColumns.createdAt

const getDefaultAssigneeFilter = ({
	isUnitUser,
	isBankUser,
	isOrgUser,
}: {
	isUnitUser: boolean
	isBankUser: boolean
	isOrgUser: boolean
}) => {
	if (isUnitUser) {
		return [AssigneeType.Unit]
	} else if (isBankUser) {
		return [AssigneeType.Bank]
	} else if (isOrgUser) {
		return [AssigneeType.Org]
	} else {
		return []
	}
}

export interface AssignmentsHubProps {
	token?: string
	limit?: number
	includedColumns: AllowedAssignmentsHubColumns[]
	enableTitle?: boolean
	fullHeight?: boolean
	enableResourceTypeFilter?: boolean
	enableAssigneeFilter?: boolean
	enableAssignmentStageFilter?: boolean
	enableAssignmentTypeFilter?: boolean
	enableOrgFilter?: boolean
	enableBankFilter?: boolean
	claims?: Claims
}

export function AssignmentsHub({
	limit = 25,
	token,
	includedColumns,
	fullHeight,
	enableTitle,
	enableResourceTypeFilter,
	enableAssigneeFilter,
	enableAssignmentStageFilter,
	enableAssignmentTypeFilter,
	enableOrgFilter,
	enableBankFilter,
	claims,
}: AssignmentsHubProps) {
	const prefix = "assignmentsHub"
	const accessToken = token ?? useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType(claims)
	const isUnitUser = useIsUnitUser(claims)
	const isBankUser = useIsBankUser(claims)
	const isOrgUser = useIsOrgUser(claims)
	const [since, setSince] = useQueryState(`${prefix}-filter[since]`, "")
	const [until, setUntil] = useQueryState(`${prefix}-filter[until]`, "")
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>(`${prefix}-filter[orgs]`, [])
	const [filteredBanks, setFilteredBanks] = useQueryState<string[]>(`${prefix}-filter[banks]`, [])
	const typesOptions = new Map(
		[
			AssignmentType.callbackAssignment,
			AssignmentType.callbackReviewAssignment,
			AssignmentType.generalReviewAssignment,
			AssignmentType.ofacCheckAssignment,
		].map((v) => [v, startCase(v.replace("Assignment", ""))])
	)

	const stageOptions = new Map([AssignmentStage.Completed, AssignmentStage.Pending].map((v) => [v, startCase(v)]))

	const assigneeOptions = new Map(
		[AssigneeType.Unit, AssigneeType.Bank, AssigneeType.Org].map((v) => [v, startCase(v)])
	)

	const resourceTypeOptions = new Map(
		[AssignmentResourceType.IncomingWire, AssignmentResourceType.OutgoingWire].map((v) => [v, startCase(v)])
	)

	const [stages, setStages] = useQueryState<AssignmentStage[]>(`${prefix}-filter[stage]`, [AssignmentStage.Pending])
	const [assignees, setAssignees] = useQueryState<AssigneeType[]>(
		`${prefix}-filter[assignee]`,
		getDefaultAssigneeFilter({isUnitUser, isBankUser, isOrgUser})
	)
	const [assignmentTypes, setAssignmentTypes] = useQueryState<AssignmentType[]>(`${prefix}-filter[type]`, [])
	const [filteredResourceType, setFilteredResourceType] = useQueryState<AssignmentResourceType[]>(
		`${prefix}-filter[resourceType]`,
		[]
	)

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findAssignments({
				accessToken,
				include: "org,bank,customer",
				offset,
				limit,
				orgs: filteredOrgs,
				banks: filteredBanks,
				stages,
				assignmentTypes,
				assignees,
				resourceTypes: filteredResourceType,
				since,
				until,
			}),
		(x) => x.data.length,
		[
			refreshToken,
			since,
			until,
			[
				...assignmentTypes,
				...filteredOrgs,
				...filteredBanks,
				...filteredBanks,
				...filteredResourceType,
				...stages,
				...assignees,
				...assignmentTypes,
			].join(","),
		],
		`${prefix}-`,
		true
	)

	const orgs =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const banks = useIsUnitUser(claims)
		? useAsyncResult(() => findBanks(accessToken, 0, 10000), [])
		: AsyncResult.pending<Bank[], ErrorDocument>()
	const bankFilter = banks.match(
		() => null,
		(banks) => (
			<Filter
				title="Banks"
				isSearchable={true}
				setStatuses={setFilteredBanks}
				statuses={filteredBanks}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						banks
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((bank) => [bank.id, bank.attributes.name])
					)
				}
			/>
		),
		(_) => null
	)

	const stagesFilter = (
		<Filter
			statuses={stages}
			setStatuses={setStages}
			onFilterFunc={() => reset(limit)}
			options={stageOptions}
			title="Stage"
		/>
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const typesFilter = (
		<Filter
			statuses={assignmentTypes}
			setStatuses={setAssignmentTypes}
			onFilterFunc={() => reset(limit)}
			options={typesOptions}
			title="Type"
		/>
	)

	const assigneesFilter = (
		<Filter
			statuses={assignees}
			setStatuses={setAssignees}
			onFilterFunc={() => reset(limit)}
			options={assigneeOptions}
			title="Assignee"
		/>
	)

	const resourceTypeFilter = (
		<Filter
			statuses={filteredResourceType}
			setStatuses={setFilteredResourceType}
			onFilterFunc={() => reset(limit)}
			options={resourceTypeOptions}
			title="Resource Type"
		/>
	)

	return (
		<DataTableCard className="assignments-hub-card">
			<DataTableActionHeader
				enableSticky={fullHeight}
				dateTimePicker={dateTime}
				filters={[
					...(enableOrgFilter ? [orgFilter] : []),
					...(enableBankFilter ? [bankFilter] : []),
					...(enableAssignmentTypeFilter ? [typesFilter] : []),
					...(enableAssignmentStageFilter ? [stagesFilter] : []),
					...(enableAssigneeFilter ? [assigneesFilter] : []),
					...(enableResourceTypeFilter ? [resourceTypeFilter] : []),
				]}
				title={enableTitle ? "Assignments" : null}
				className="with-title-bar-tabs"
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value: assignments}) => {
					return (
						<AssignmentsTable
							assignments={assignments.data}
							meta={assignments.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={true}
							include={assignments.included}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							isUnitUser={isUnitUser}
							claims={claims}
							token={token}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
