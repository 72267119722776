import {useAccessToken, useUserType} from "../../services/auth"
import {usePaging} from "../../hooks/usePaging"
import {uploadBulkDispute, Dispute, findDisputes, StatusType, StatusTypeValue} from "../../resources/dispute"
import React, {ReactElement, useEffect, useState} from "react"
import moment from "moment"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {Filter} from "../Filter/Filter"
import {useQueryState} from "use-location-state"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {useNavigate} from "react-router-dom"
import numeral from "numeral"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import SearchBox from "../SearchBox/SearchBox"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircleNotch, faUpload} from "@fortawesome/free-solid-svg-icons"
import {useToasts} from "react-toast-notifications"
import {kebabCase, startCase} from "lodash"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableCell,
	DataTableHead,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import classNames from "classnames"
import {UnitAdminOnly} from "../../containers/PermissionedUser/PermissionedUser"
import {NewDisputeModal} from "./NewDisputeModal"
import {useModal} from "react-modal-hook"
import {Transaction} from "../../resources/transaction"
import {hasPermission} from "../../services/permission"
import {OrgName} from "../OrgName/OrgName"
import {downloadFile} from "../../utilities/file"

export enum DisputesColumns {
	id = "Id",
	org = "Org",
	source = "Source",
	amount = "Amount",
	createdAt = "Created At",
	status = "Status",
	updatedAt = "Updated At",
	customerName = "Customer Name",
	customerId = "Customer Id",
}

type AllowedDisputesColumns =
	| DisputesColumns.id
	| DisputesColumns.org
	| DisputesColumns.customerName
	| DisputesColumns.customerId
	| DisputesColumns.status
	| DisputesColumns.source
	| DisputesColumns.createdAt
	| DisputesColumns.updatedAt
	| DisputesColumns.amount

interface DisputesProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedDisputesColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	enableUpload?: boolean
	customerId?: string
	transaction?: Transaction
	enableSearch?: boolean
	enableOrgFilter?: boolean
	enableStatusFilter?: boolean
	enablePagination?: boolean
	singleDisputeView?: boolean
	enableExport?: boolean
}

interface DisputesTableProps {
	disputes: Array<Dispute>
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedDisputesColumns>
	meta?: Meta
	token?: string
	refresh: () => void
	fullHeight?: boolean
	singleDisputeView?: boolean
}

export function DisputeRow({
	dispute,
	include,
	includedColumns,
}: {
	dispute: Dispute
	include?: Resource[]
	showCustomerId?: boolean
	includedColumns: Array<AllowedDisputesColumns>
}) {
	const navigate = useNavigate()
	const customerId = dispute.relationships.customer.data.id
	const customer =
		customerId && include
			? include.find(
					(r) => (r.type == "individualCustomer" || r.type == "businessCustomer") && r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined
	const updatedAt = dispute.attributes.updatedAt
	const status = dispute.attributes.statusHistory.slice(-1)[0].type
	const org = dispute.relationships.org?.data.id

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedDisputesColumns, ReactElement>> = {}

	if (includedColumns.includes(DisputesColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell className={classNames("data-table-id-cell")}>
				<span className="data-table-id"> {dispute.id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(DisputesColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} />
	}

	if (includedColumns.includes(DisputesColumns.source)) {
		contentColumns["Source"] = <DataTableCell>{dispute.attributes.source}</DataTableCell>
	}

	if (includedColumns.includes(DisputesColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>{numeral(dispute.attributes.amount / 100).format("$0,0.00")}</DataTableCell>
		)
	}

	if (includedColumns.includes(DisputesColumns.createdAt)) {
		contentColumns["Created At"] = <DataTableCell>{moment(dispute.attributes.createdAt).format("L")}</DataTableCell>
	}

	if (includedColumns.includes(DisputesColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("disputes-status", kebabCase(status))}>{StatusTypeValue(status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(DisputesColumns.updatedAt)) {
		contentColumns["Updated At"] = (
			<DataTableCell>
				{updatedAt ? moment(updatedAt).format("L") : <span className="empty-date-placeholder"> --/--/---- </span>}
			</DataTableCell>
		)
	}

	if (includedColumns.includes(DisputesColumns.customerName)) {
		contentColumns["Customer Name"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(DisputesColumns.customerId)) {
		contentColumns["Customer Id"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{dispute.relationships.customer.data.id}
				</a>
			</DataTableCell>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			onClick={(e) => {
				e.preventDefault()
				navigate(`/disputes/${dispute.id}`)
			}}
		>
			{content}
		</DataTableRow>
	)
}

function CreateDispute({transaction, refresh}: {transaction: Transaction; refresh: () => void}) {
	const [showDisputeModal, hideDisputeModal] = useModal(() => {
		return (
			<NewDisputeModal
				close={hideDisputeModal}
				refresh={refresh}
				accountId={transaction.relationships.account.data.id}
				transaction={transaction}
			/>
		)
	})

	if (hasPermission("dispute", "create")) {
		return (
			<div className="pagination-row">
				<div className="navigation-items">
					<button className="button is-primary" onClick={() => showDisputeModal()}>
						Create
					</button>
				</div>
			</div>
		)
	}
	return <> </>
}

function DisputesTable({
	disputes,
	includedColumns,
	hasPrev,
	hasNext,
	prev,
	next,
	hasResults,
	isUsingPaging,
	include,
	meta,
	fullHeight,
	singleDisputeView,
}: DisputesTableProps): ReactElement {
	const noContent = disputes.length === 0

	return (
		<div className={classNames("disputes-table", singleDisputeView && "single-disputes-table")}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={fullHeight}
				noContentText={"No disputes found"}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							return <DataTableCell key={column[0]}>{column[1]}</DataTableCell>
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{disputes.map((d) => (
						<DisputeRow
							includedColumns={includedColumns}
							key={d.id}
							dispute={d}
							include={include}
							showCustomerId={true}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>
		</div>
	)
}

export function Disputes({
	enableTitle,
	fullHeight,
	includedColumns,
	enableUpload = false,
	customerId,
	transaction,
	enableOrgFilter = false,
	enableSearch = true,
	enableStatusFilter = true,
	enablePagination = true,
	singleDisputeView = false,
	limit = 25,
	enableExport = true,
}: DisputesProps) {
	const pageTitle = singleDisputeView ? "Dispute" : "Disputes"
	const accessToken = useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType()
	const {addToast} = useToasts()
	const [isFileLoading, setIsFileLoading] = useState(false)
	const [filteredOrgs, setFilteredOrgs] = useQueryState<string[]>("filter[orgs]", [])
	const [query, setQuery] = useQueryState("filter[query]", "")
	const [filteredDisputeStatuses, setFilteredDisputeStatuses] = useQueryState<string[]>(
		"filter[status]",
		Object.keys(StatusType)
	)
	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		(offset, limit) =>
			findDisputes(
				accessToken,
				offset,
				"json",
				limit,
				customerId,
				filteredDisputeStatuses,
				transaction?.id,
				filteredOrgs,
				query,
				"customer,org"
			),
		(x) => x.data.length,
		[filteredDisputeStatuses.join(","), refreshToken, filteredOrgs.join(","), query],
		"",
		true
	)
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search Disputes", 500, onSearch)
	const [bulkDisputeState, bulkDispute] = useAsyncResultIdle(uploadBulkDispute)

	const statusFilter = (
		<Filter
			title="Status"
			setStatuses={setFilteredDisputeStatuses}
			statuses={filteredDisputeStatuses}
			onFilterFunc={() => reset(limit)}
			options={new Map(Object.keys(StatusType).map((t) => [t, StatusTypeValue(t)]))}
		/>
	)

	const orgs =
		(userType === "unit" || userType === "bank") && enableOrgFilter
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredOrgs}
				statuses={filteredOrgs}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	useEffect(() => {
		result.match(
			() => null,
			() => setIsSearchLoading(false),
			() => setIsSearchLoading(false)
		)
	}, [result])

	useEffect(() => {
		bulkDisputeState.match(
			() => null,
			() => setIsFileLoading(true),
			(ok) => {
				addToast(
					`Successfully handled disputes imported file: ${Object.entries(ok.attributes)
						.map((v) => startCase(v[0]) + ": " + v[1])
						.join(", ")}`,
					{appearance: "success"}
				)
				setIsFileLoading(false)
				refresh()
			},
			(err) => {
				const errorTitle = err.errors ? err.errors[0].title : "An unexpected error has occurred"
				const errorDetail = err.errors ? err.errors[0].detail : ""
				addToast(`${errorTitle} - ${errorDetail}`, {appearance: "error"})
				setIsFileLoading(false)
			}
		)
	}, [bulkDisputeState])

	function onSearch(searchTerm: string) {
		setQuery(searchTerm)
		reset(limit)
	}

	function onFileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files
		if (files) {
			const file = files[0]
			if (file) {
				setIsFileLoading(true)
				bulkDispute(accessToken, file, "text/csv", file.name)
			}
		}
	}

	async function exportDisputes() {
		const response = await findDisputes(
			accessToken,
			0,
			"csv",
			null,
			customerId,
			filteredDisputeStatuses,
			transaction?.id,
			filteredOrgs,
			query,
			"org"
		)

		response.match(
			(d) => downloadFile("Disputes.csv", d, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
		return true
	}

	return (
		<DataTableCard className={"disputes-card"}>
			<UnitAdminOnly>
				{enableUpload ? (
					<div className="file column is-right is-flex is-align-items-right">
						<a className="is-flex is-align-items-right ml-1">
							<label className="file-label">
								<input
									className="file-input"
									type="file"
									disabled={isFileLoading}
									onChange={(e) => onFileUploaded(e)}
									accept=".text,.csv"
								/>
								<span className={classNames("file-cta", isFileLoading && "file-input-disabled")}>
									<span className="file-icon">
										<FontAwesomeIcon icon={isFileLoading ? faCircleNotch : faUpload} spin={isFileLoading} />
									</span>
									<span className="file-label">Upload Disputes CSV file</span>
								</span>
							</label>
						</a>
					</div>
				) : null}
			</UnitAdminOnly>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={enableSearch ? searchBox : null}
				filters={[...(enableOrgFilter ? [orgFilter] : []), ...(enableStatusFilter ? [statusFilter] : [])]}
				exportFunc={enableExport ? exportDisputes : undefined}
				title={enableTitle ? pageTitle : null}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value}) => {
					const disputes = value as {data: Dispute[]; meta: Meta; included: Resource[]}

					if (singleDisputeView && disputes.data.length === 0) {
						return <CreateDispute transaction={transaction as Transaction} refresh={refresh} />
					}
					return (
						<DisputesTable
							disputes={disputes.data}
							meta={disputes.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={enablePagination}
							include={disputes.included}
							token={accessToken}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							singleDisputeView={singleDisputeView}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
