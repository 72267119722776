import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react"
import {AsyncResultComponent} from "../../containers/AsyncResult/AsyncResult"
import {usePaging} from "../../hooks/usePaging"
import {
	CheckDeposit,
	CheckDepositStatus,
	CheckDepositVendors,
	findCheckDepositRequest,
} from "../../resources/checkDeposits"
import {useAccessToken, useIsUnitUser, useUserType} from "../../services/auth"
import {useQueryState} from "use-location-state"
import SearchBox from "../SearchBox/SearchBox"
import DatePickerWithPresets, {DatePickerPresetKeys} from "../DatePicker/DatePicker"
import {Filter} from "../Filter/Filter"
import classNames from "classnames"
import PagingNavBar from "../PagingNavBar/PagingNavBar"
import {ErrorDocument, Meta, Resource} from "../../resources/common"
import {useNavigate} from "react-router-dom"
import {OrgName} from "../OrgName/OrgName"
import moment from "moment"
import {kebabCase, startCase, upperFirst} from "lodash"
import numeral from "numeral"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {findOrgs, getOrgName, Org} from "../../resources/org"
import {AsyncResult} from "../../types/asyncResult"
import Drawer from "../Drawer/Drawer"
import {CheckDepositInfo, checkDepositTooltipValue} from "./CheckDepositInfo"
import {useModal} from "react-modal-hook"
import {RejectCheckModal} from "./RejectCheckModal"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {ApproveCheckModal} from "./ApproveCheckModal"
import {ReturnCheckModal} from "./ReturnCheckModal"
import ReactTooltip from "react-tooltip"
import {
	DataTable,
	DataTableActionHeader,
	DataTableBody,
	DataTableCard,
	DataTableHead,
	DataTableCell,
	DataTableRow,
	TablePending,
} from "../DataTable/DataTable"
import {DataTableActionButton, DataTableActionItem} from "../DataTable/DataTableActionButton"
import {downloadFile} from "../../utilities/file"
import {useToasts} from "react-toast-notifications"
import {hasPermission} from "../../services/permission"

export enum CheckDepositColumns {
	id = "Id",
	org = "Org",
	vendor = "Vendor",
	customer = "Customer",
	accountId = "Account",
	checkNumber = "Check number",
	status = "Status",
	depositDate = "Deposit Date",
	amount = "Amount",
	expectedCompletionDate = "Expected Completion Date",
	actions = "Actions",
	reason = "Reason",
}

type AllowedCheckDepositColumns =
	| CheckDepositColumns.id
	| CheckDepositColumns.org
	| CheckDepositColumns.vendor
	| CheckDepositColumns.customer
	| CheckDepositColumns.accountId
	| CheckDepositColumns.checkNumber
	| CheckDepositColumns.status
	| CheckDepositColumns.depositDate
	| CheckDepositColumns.amount
	| CheckDepositColumns.expectedCompletionDate
	| CheckDepositColumns.actions
	| CheckDepositColumns.reason

interface CheckDepositsProps {
	token?: string
	limit?: number
	includedColumns: Array<AllowedCheckDepositColumns>
	enableTitle?: boolean
	fullHeight?: boolean
	disableDrawer?: boolean
	enableExport?: boolean
	enableStatusFilter?: boolean
	title?: string
	stickyAction?: boolean
	enablePaging?: boolean
	enableSearch?: boolean
	enableDateFilter?: boolean
	enableRowClick?: boolean
	customerId?: string
	initialStatusFilter?: Array<CheckDepositStatus> | []
}

interface CheckDepositsTableProps {
	checkDeposits: Array<CheckDeposit>
	selectedCheckDepositId: string
	setSelectedCheckDepositId: (id: string) => void
	hasResults: boolean
	hasPrev: boolean
	hasNext: boolean
	prev: () => void
	next: () => void
	isUsingPaging: boolean
	include?: Resource[]
	includedColumns: Array<AllowedCheckDepositColumns>
	meta: Meta
	token?: string
	refresh: () => void
	fullHeight?: boolean
	disableDrawer?: boolean
	sortFunction: () => void
	sortBy: string
	stickyAction?: boolean
	enableRowClick?: boolean
}

interface CheckDepositRowProps {
	cd: CheckDeposit
	include?: Resource[]
	includedColumns: Array<AllowedCheckDepositColumns>
	onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
	refresh: () => void
	selectedCheckDepositId?: string
}

function CheckDepositRow({
	cd,
	include,
	includedColumns,
	onClick,
	refresh,
	selectedCheckDepositId,
}: CheckDepositRowProps) {
	const navigate = useNavigate()
	const id = cd.id
	const org = cd.relationships.org?.data.id
	const vendor = cd.attributes?.vendor
	const customerId = cd.relationships.customer?.data.id
	const accountId = cd.relationships.account.data.id
	const checkNumber = cd.attributes?.checkNumber
	const status = cd.attributes.status
	const depositDate = cd.attributes.createdAt
	const settlementDate = cd.attributes?.settlementDate
	const amount = cd.attributes.amount
	const reason = cd.attributes.reason
	const customer =
		customerId && include
			? include.find(
					(r) =>
						(r.type == "individualCustomer" || r.type == "businessCustomer" || r.type == "businessFBOCustomer") &&
						r.id == customerId.toString()
			  )
			: undefined
	let customerName: string | undefined = undefined

	const enableRejectAndApprove = status === CheckDepositStatus.PendingReview && vendor !== CheckDepositVendors.Ensenta
	const enableReturn = status === CheckDepositStatus.Clearing || status === CheckDepositStatus.Sent
	const enableActions = enableRejectAndApprove || enableReturn

	const [showRejectCheck, hideRejectCheck] = useModal(() => (
		<RejectCheckModal closeModal={hideRejectCheck} id={id} onSuccess={refresh} />
	))

	const [showApproveCheck, hideApproveCheck] = useModal(() => (
		<ApproveCheckModal closeModal={hideApproveCheck} id={id} onSuccess={refresh} />
	))

	const [showReturnCheck, hideReturnCheck] = useModal(() => (
		<ReturnCheckModal closeModal={hideReturnCheck} id={id} onSuccess={refresh} />
	))

	if (customer) {
		if (customer.type == "individualCustomer") {
			customerName = `${customer.attributes.fullName.first} ${customer.attributes.fullName.last}`
		} else if (customer.type == "businessCustomer" || customer.type == "businessFBOCustomer") {
			customerName = customer.attributes.name
		}
	}

	const contentColumns: Partial<Record<AllowedCheckDepositColumns, ReactElement>> = {}

	if (includedColumns.includes(CheckDepositColumns.id)) {
		contentColumns["Id"] = (
			<DataTableCell
				className={classNames(id == selectedCheckDepositId && "selected-data-table-id", "data-table-id-cell")}
			>
				<span className="data-table-id"> {id} </span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckDepositColumns.org) && org) {
		contentColumns["Org"] = <OrgName orgId={org.toString()} included={include} />
	}

	if (includedColumns.includes(CheckDepositColumns.customer)) {
		contentColumns["Customer"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (customer) {
							navigate(`/${customer.type == "individualCustomer" ? "individual" : "business"}/${customerId}`)
						}
					}}
				>
					{customerName}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckDepositColumns.accountId)) {
		contentColumns["Account"] = (
			<DataTableCell>
				<a
					className="link"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						if (accountId) {
							navigate(`/accounts/${accountId}`)
						}
					}}
				>
					{accountId}
				</a>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckDepositColumns.checkNumber)) {
		contentColumns["Check number"] = <DataTableCell>{checkNumber}</DataTableCell>
	}

	if (includedColumns.includes(CheckDepositColumns.status)) {
		contentColumns["Status"] = (
			<DataTableCell>
				<div className={classNames("check-deposit-status", kebabCase(status))}>{startCase(status)}</div>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckDepositColumns.depositDate)) {
		contentColumns["Deposit Date"] = <DataTableCell>{moment(depositDate).format("MM/DD/YYYY")}</DataTableCell>
	}

	if (includedColumns.includes(CheckDepositColumns.reason)) {
		contentColumns["Reason"] = <DataTableCell>{reason ? reason : "-"}</DataTableCell>
	}

	if (includedColumns.includes(CheckDepositColumns.vendor)) {
		contentColumns["Vendor"] = <DataTableCell>{vendor}</DataTableCell>
	}

	if (includedColumns.includes(CheckDepositColumns.expectedCompletionDate)) {
		let tooltipValue: string | undefined = undefined
		if (settlementDate) {
			tooltipValue = checkDepositTooltipValue
		}

		contentColumns["Expected Completion Date"] = (
			<DataTableCell>
				<span className="is-flex is-align-items-center ml-1">
					<span data-tip="React-tooltip" data-for={`check-deposit-expected-completion-time-tooltip-${id}`}>
						{settlementDate ? (
							moment(settlementDate).format("MM/DD/YYYY")
						) : (
							<span className="empty-date-placeholder"> - </span>
						)}
					</span>
					<ReactTooltip
						className="tooltip-info"
						id={`check-deposit-expected-completion-time-tooltip-${id}`}
						disable={!tooltipValue}
						effect="solid"
					>
						{tooltipValue}
					</ReactTooltip>
				</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckDepositColumns.amount)) {
		contentColumns["Amount"] = (
			<DataTableCell>
				<span className="check-deposit-amount">{numeral(amount / 100).format("$0,0.00")}</span>
			</DataTableCell>
		)
	}

	if (includedColumns.includes(CheckDepositColumns.actions)) {
		contentColumns["Actions"] = (
			<DataTableActionButton enableActions={enableActions}>
				{enableRejectAndApprove ? (
					<>
						<DataTableActionItem
							title={"Reject check"}
							icon={"interface-delete-interface-essential"}
							onClick={() => showRejectCheck()}
						/>
						<DataTableActionItem
							title={"Approve check"}
							icon={"interface-validation-check--interface-essential"}
							onClick={() => showApproveCheck()}
						/>
					</>
				) : null}
				{enableReturn ? (
					<DataTableActionItem
						title={"Mark as returned"}
						icon={"interface-validation-check--interface-essential"}
						onClick={() => showReturnCheck()}
					/>
				) : null}
			</DataTableActionButton>
		)
	}

	const content: Array<ReactElement | null> = []

	includedColumns.forEach((col, i) => {
		if (col in contentColumns && contentColumns[col]) {
			const column = {...contentColumns[col]} as ReactElement
			column.key = i
			content.push(column)
		}
	})

	return (
		<DataTableRow
			className={classNames(id === selectedCheckDepositId && "selected-data-table-row")}
			onClick={(e) => (onClick ? onClick(e) : null)}
		>
			{content}
		</DataTableRow>
	)
}

export function CheckDepositsTable({
	checkDeposits,
	selectedCheckDepositId,
	setSelectedCheckDepositId,
	includedColumns,
	hasPrev,
	hasNext,
	prev,
	next,
	hasResults,
	isUsingPaging,
	include,
	meta,
	token,
	refresh,
	fullHeight = false,
	disableDrawer = false,
	stickyAction = true,
	sortFunction,
	sortBy,
	enableRowClick = true,
}: CheckDepositsTableProps): ReactElement {
	const accessToken = token ?? useAccessToken()
	const noContent = checkDeposits.length === 0
	const [isDrawerOpen, setIsDrawerOpen] = useState(!!selectedCheckDepositId)
	const ref = useRef(null)
	const navigate = useNavigate()
	const userHasPermission = hasPermission("checkDeposit.approve", "update")

	const toggleDrawer = (reopenWithId = false) => {
		setIsDrawerOpen((prevState) => !prevState)
		if (reopenWithId) {
			setTimeout(() => {
				setIsDrawerOpen(true)
			}, 200)
		}
	}
	useEffect(() => {
		if (!selectedCheckDepositId) {
			setIsDrawerOpen(false)
		}
	}, [selectedCheckDepositId])

	return (
		<div ref={ref} className={"check-deposits-table"}>
			<DataTable
				isEmpty={noContent}
				fullHeight={fullHeight}
				stickyAction={stickyAction}
				noContentText={"No check deposits found"}
				clickable={enableRowClick}
				hoverable={enableRowClick}
			>
				<DataTableHead>
					<DataTableRow>
						{Object.entries(includedColumns).map((column) => {
							if (column[1] === CheckDepositColumns.depositDate) {
								return (
									<DataTableCell
										clickable
										onClick={() => sortFunction()}
										key={column[1]}
										sortable
										sortApplied={sortBy === "createdAt"}
									>
										{column[1]}
									</DataTableCell>
								)
							}

							return (
								<DataTableCell
									className={classNames(column[1] === CheckDepositColumns.actions && "has-text-centered")}
									key={column[0]}
								>
									{column[1]}
								</DataTableCell>
							)
						})}
					</DataTableRow>
				</DataTableHead>
				<DataTableBody>
					{checkDeposits.map((cd) => (
						<CheckDepositRow
							refresh={refresh}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								if (userHasPermission && enableRowClick) {
									navigate(`/check-deposits/${cd.id}`)
								} else if (!disableDrawer && enableRowClick) {
									setSelectedCheckDepositId(cd.id)
									toggleDrawer(true)
									return
								}
							}}
							selectedCheckDepositId={selectedCheckDepositId}
							include={include}
							key={cd.id}
							cd={cd}
							includedColumns={includedColumns}
						/>
					))}
				</DataTableBody>
			</DataTable>
			<PagingNavBar
				hasResults={hasResults}
				hasPrev={hasPrev}
				hasNext={hasNext}
				prev={prev}
				next={next}
				isShow={isUsingPaging}
				meta={meta}
			/>

			{!disableDrawer ? (
				<Drawer
					open={isDrawerOpen}
					onClose={() => {
						toggleDrawer(false)
						setSelectedCheckDepositId("")
					}}
					direction="right"
					className="check-deposits-side-drawer"
					size={"50%"}
					zIndex={33}
					blockBody={false}
					linkToCopy={`${window.location.origin}/check-deposits/${selectedCheckDepositId}`}
				>
					{isDrawerOpen ? (
						<CheckDepositInfo useDrawer={true} refresh={refresh} id={selectedCheckDepositId} token={accessToken} />
					) : null}
				</Drawer>
			) : null}
		</div>
	)
}

export function CheckDeposits({
	token,
	limit = 20,
	includedColumns,
	enableTitle = false,
	fullHeight = false,
	disableDrawer = false,
	enableExport = true,
	stickyAction = false,
	enablePaging = true,
	enableStatusFilter = true,
	enableSearch = true,
	enableDateFilter = true,
	enableRowClick = true,
	title,
	customerId,
	initialStatusFilter = [],
}: CheckDepositsProps): ReactElement {
	const accessToken = token ?? useAccessToken()
	const [refreshToken, refresh] = useRefreshToken()
	const userType = useUserType()
	const {addToast} = useToasts()
	const [query, setQuery] = useQueryState("query", "")
	const [searchBox, setIsSearchLoading] = SearchBox(query, "Search for check", 500, onSearch)
	const [since, setSince] = useQueryState("filter[since]", "")
	const [until, setUntil] = useQueryState("filter[until]", "")
	const isUnitUser = useIsUnitUser()
	const [filterByVendor, setFilterByVendor] = useQueryState<string[]>(
		"filter[vendor]",
		isUnitUser ? [CheckDepositVendors.Smartpay] : []
	)
	const [filteredByStatus, setFilteredByStatus] = useQueryState<string[]>("filter[status]", initialStatusFilter)
	const [filteredByOrg, setFilteredByOrg] = useQueryState<string[]>("filter[orgs]", [])
	const [selectedCheckDepositId, setSelectedCheckDepositId] = useQueryState("id", "")
	const [sortBy, setSortBy] = useQueryState("sort", "-createdAt")
	const orgs =
		userType === "unit" || userType === "bank"
			? useAsyncResult(() => findOrgs(accessToken, 0, 10000), [])
			: AsyncResult.pending<Org[], ErrorDocument>()

	const escFunction = useCallback((event) => {
		if (event.key === "Escape") {
			setSelectedCheckDepositId("")
		}
		return null
	}, [])

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false)
	}, [])

	const statusFilterMap = new Map(
		Object.entries(CheckDepositStatus).map((status) => [upperFirst(status[0]), startCase(status[1])])
	)

	const vendorFilterMap = new Map(
		Object.entries(CheckDepositVendors).map((vendor) => [upperFirst(vendor[0]), startCase(vendor[1])])
	)

	const dateTime = (
		<DatePickerWithPresets
			calendarPosition={"right"}
			presets={[
				DatePickerPresetKeys.allTime,
				DatePickerPresetKeys.lastMonth,
				DatePickerPresetKeys.last3Months,
				DatePickerPresetKeys.last6Months,
				DatePickerPresetKeys.last30Days,
				DatePickerPresetKeys.last7Days,
				DatePickerPresetKeys.custom,
			]}
			onDateChanged={(s, u) => {
				setSince(s)
				setUntil(u)
			}}
		/>
	)

	const orgFilter = orgs.match(
		() => null,
		(orgs) => (
			<Filter
				title="Orgs"
				isSearchable
				setStatuses={setFilteredByOrg}
				statuses={filteredByOrg}
				onFilterFunc={() => reset(limit)}
				options={
					new Map<string, string>(
						orgs
							.sort((a, b) => moment(b.attributes.createdAt).diff(moment(a.attributes.createdAt)))
							.map((org) => [org.id, getOrgName(org)])
					)
				}
			/>
		),
		(_) => null
	)

	const statusFilter = (
		<Filter
			onFilterFunc={() => reset(limit)}
			options={statusFilterMap}
			setStatuses={setFilteredByStatus}
			statuses={filteredByStatus}
			title="Status"
		/>
	)

	const vendorFilter = isUnitUser ? (
		<Filter
			onFilterFunc={() => reset(limit)}
			options={vendorFilterMap}
			setStatuses={setFilterByVendor}
			statuses={filterByVendor}
			title="Vendor"
		/>
	) : null

	const getCheckDeposit = (offset: number, limit: number) => {
		return findCheckDepositRequest({
			accessToken,
			offset,
			limit,
			since,
			until,
			statuses: filteredByStatus,
			vendors: filterByVendor,
			orgs: filteredByOrg,
			include: "customer,org",
			requestType: "json",
			searchQuery: query,
			sort: sortBy,
			customerId,
		})
	}

	function onSearch(searchTerm: string) {
		setSelectedCheckDepositId("")
		setQuery(searchTerm)
		reset(limit)
	}

	const [result, hasPrev, hasNext, prev, next, hasResults, reset] = usePaging(
		limit,
		getCheckDeposit,
		(x) => x.data.length,
		[refreshToken, query, since, until, sortBy, [...filteredByStatus, ...filteredByOrg, ...filterByVendor].join(",")],
		"",
		true
	)

	useEffect(() => {
		let isActive = true
		if (isActive) {
			result.match(
				() => null,
				() => setIsSearchLoading(false),
				() => setIsSearchLoading(false)
			)
		}
		return () => {
			isActive = false
		}
	}, [result])

	function toggleSort() {
		setSortBy(sortBy == "-createdAt" ? "createdAt" : "-createdAt")
		reset(limit)
	}

	async function exportCheckDeposits() {
		const response = await findCheckDepositRequest({
			accessToken,
			offset: 0,
			since,
			until,
			statuses: filteredByStatus,
			orgs: filteredByOrg,
			requestType: "csv",
			searchQuery: query,
			include: "org",
			sort: sortBy,
		})

		response.match(
			(cd) => downloadFile("Check Deposit.csv", cd, "text/csv"),
			() => {
				addToast("Something went wrong", {appearance: "warning"})
			}
		)
		return true
	}

	return (
		<DataTableCard className={"check-deposit-card"}>
			<DataTableActionHeader
				enableSticky={fullHeight}
				searchBox={enableSearch ? searchBox : null}
				dateTimePicker={enableDateFilter ? dateTime : null}
				filters={[...(enableStatusFilter ? [orgFilter, statusFilter, vendorFilter] : [])]}
				title={enableTitle ? (title ? title : "Check Deposits") : null}
				exportFunc={enableExport ? exportCheckDeposits : undefined}
			/>
			<AsyncResultComponent
				asyncResult={result}
				pendingComponent={<TablePending numberOfRows={includedColumns.length} />}
			>
				{({value}) => {
					const checkDeposits = value as {data: CheckDeposit[]; meta: Meta; included: any[]}
					return (
						<CheckDepositsTable
							checkDeposits={checkDeposits.data}
							selectedCheckDepositId={selectedCheckDepositId}
							setSelectedCheckDepositId={setSelectedCheckDepositId}
							meta={checkDeposits.meta}
							hasResults={hasResults}
							hasPrev={hasPrev}
							hasNext={hasNext}
							prev={prev}
							next={next}
							refresh={refresh}
							isUsingPaging={enablePaging}
							include={checkDeposits.included}
							token={accessToken}
							fullHeight={fullHeight}
							includedColumns={includedColumns}
							disableDrawer={disableDrawer}
							sortFunction={toggleSort}
							sortBy={sortBy}
							stickyAction={stickyAction}
							enableRowClick={enableRowClick}
						/>
					)
				}}
			</AsyncResultComponent>
		</DataTableCard>
	)
}
