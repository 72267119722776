import React from "react"

interface Props {
	countryCodeValue: string
	countryCodeOnChange: any
	phoneNumberValue: string
	phoneNumberOnChange: any
}

export default function PhoneNumber({
	countryCodeValue,
	countryCodeOnChange,
	phoneNumberValue,
	phoneNumberOnChange,
}: Props) {
	return (
		<div className="field">
			<label className="label">Phone Number</label>
			<div className="field-body">
				<p className="control">
					<a className="button is-static">+</a>
				</p>
				<div className="control">
					<input
						className="input"
						placeholder="Country Code"
						type="text"
						value={countryCodeValue}
						pattern="^(1|7|(\d{2,3})|(1\d{3}))$"
						required
						onChange={(e) => countryCodeOnChange(e.target.value)}
					/>
				</div>
				<div className="control w-100">
					<input
						className="input"
						placeholder="Number"
						type="text"
						value={phoneNumberValue}
						pattern="^\d+$"
						required
						onChange={(e) => phoneNumberOnChange(e.target.value)}
					/>
				</div>
			</div>
		</div>
	)
}
