import classNames from "classnames"
import React, {PropsWithChildren} from "react"
import Icon from "../../components/Icon/Icon"
import {IcomoonIconName} from "../../components/Icon/icons"

interface Props {
	title?: string | JSX.Element
	className?: string
	icon?: IcomoonIconName
}

export type CardProps = PropsWithChildren<Props>

export default function Card({title, className, icon, children}: CardProps) {
	return (
		<div className={classNames("columns", className)}>
			<div className="column">
				<div className="card">
					{title && (
						<div className="card-header">
							{typeof title === "string" ? (
								<p className="card-header-title">
									{" "}
									{icon && <Icon icon={icon} size={20} />} {title}
								</p>
							) : (
								<div className="card-header-title card-header-title-block">{title}</div>
							)}
						</div>
					)}
					<div className="card-content">{children}</div>
				</div>
			</div>
		</div>
	)
}
