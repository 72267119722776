import {DependencyList, useEffect, useRef} from "react"

export default function useHtmlClassname(fn: () => string, deps: DependencyList = []) {
	const originalClassname = useRef("")

	useEffect(() => {
		originalClassname.current = window.document.documentElement.className

		return () => {
			window.document.documentElement.className = originalClassname.current
		}
	}, [])

	useEffect(() => {
		window.document.documentElement.className = fn()
	}, deps)
}
