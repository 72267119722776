import React, {Dispatch, SetStateAction, PropsWithChildren, InputHTMLAttributes} from "react"
import classNames from "classnames"
import Dropdown from "../Address/Dropdown"
import Textfield from "../Address/Textfield"

interface DescriptiveToggleSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
	text?: string | React.ReactNode
	description?: string
	className?: string
	checked?: boolean
	onChange: Dispatch<SetStateAction<any>>
	onBlur?: Dispatch<SetStateAction<any>>
	subName?: string
	subLabel?: string
	subValue?: string | number
	inputType?: string
}

export default function DescriptiveToggleSwitch({
	name,
	subName,
	text,
	description,
	subLabel,
	className,
	checked,
	subValue,
	onChange,
	inputType,
	children,
	...inputProps
}: PropsWithChildren<DescriptiveToggleSwitchProps>) {
	return (
		<div className="descriptive-toggle-switch">
			<div className={classNames("toggle", className)}>
				<label className="switch">
					<input name={name} type="checkbox" checked={checked} onChange={onChange} {...inputProps} />
					<span className="slider round"></span>
				</label>
				<div className="toggle-text">
					<span className={classNames("text", inputProps.disabled && "text-disabled")}>{text}</span>
					<span className="description">{description}</span>
				</div>
			</div>
			{subName != null && subLabel != null && subValue != null && (
				<div className="form-row four">
					{inputType === "select" ? (
						<Dropdown
							name={subName}
							label={subLabel}
							value={subValue}
							onChange={onChange}
							disabled={!checked || !!inputProps.disabled}
						>
							{children}
						</Dropdown>
					) : inputType === "input" && inputProps.onBlur !== undefined ? (
						<Textfield
							name={subName}
							label={subLabel}
							value={subValue}
							onBlur={inputProps.onBlur}
							onChange={onChange}
							disabled={!checked || !!inputProps.disabled}
						/>
					) : null}
				</div>
			)}
		</div>
	)
}
