import React from "react"
import ReactTooltip from "react-tooltip"
import numeral from "numeral"
import {camelCase} from "lodash"
import BodyPortal from "../BodyPortal/BodyPortal"
import classNames from "classnames"

type Props = {
	title?: string
	description?: string
	rangeColor?: string
	className?: string
	max: number
	current: number
	softLimit?: number
}

function selectRangeBoxTrackColor(max: number, current: number, soft = max): string {
	if (soft != max && current > soft && current / max < 0.85) return "account-limits-reach-soft-limit"
	if (current / max > 0.85) return "account-limits-reach-hard-limit"
	else return "account-limits-no-limit"
}

function SoftLimitView({soft, max, id}: {soft: number; max: number; id: string}) {
	return (
		<div
			className="range-box-range-container"
			style={{left: `${(soft * 100) / max}%`}}
			data-tip={true}
			data-iscapture={true}
			data-for={id}
		>
			<div className="range-box-range-determination" />
			<div className="range-box-range-picker" />
			<BodyPortal>
				<ReactTooltip
					arrowColor="#FFF"
					delayHide={100}
					className="range-box-range-container-tooltip"
					place="bottom"
					type="light"
					effect="solid"
					id={id}
				>
					<>
						<span className="range-box-range-container-tooltip-title"> Soft limit: &nbsp; </span>
						<span className="range-box-range-container-tooltip-number"> {numeral(soft).format("$0,0")} </span>
						<p>
							{" "}
							Transactions over your <br /> soft limit will go to manual review
						</p>
						<a href="https://guides.unit.co/limits/" rel="noreferrer" target="_blank">
							{" "}
							Learn more{" "}
						</a>
					</>
				</ReactTooltip>
			</BodyPortal>
		</div>
	)
}

function Range({
	max,
	current,
	soft = max,
	id,
	defaultColor,
}: {
	max: number
	current: number
	soft: number
	id: string
	defaultColor: string
}) {
	return (
		<div className="range-box-range" style={{backgroundColor: defaultColor}}>
			{soft != max ? <SoftLimitView soft={soft} max={max} id={id} /> : ""}

			<div
				className={classNames("range-box-range-rail", selectRangeBoxTrackColor(max, current, soft))}
				style={{width: `${(current / max) * 100}%`}}
			></div>
		</div>
	)
}

export default function RangeBox({
	title = "",
	description = "",
	rangeColor = "#E2E4E7",
	className = "",
	max,
	current,
	softLimit = max,
}: Props): JSX.Element {
	const boxId = camelCase(`${title} ${description}`)

	return (
		<div className={`range-box ${className}`}>
			<div className="range-box-header">
				<span className="range-box-header-title">{title}</span>
				<span className="range-box-header-description"> {description} </span>
			</div>

			<Range max={max / 100} current={current / 100} soft={softLimit / 100} id={boxId} defaultColor={rangeColor} />

			<div className="range-box-range-description">
				<span className="range-box-range-description-current">{numeral(current / 100).format("$0,0.00")}</span>/
				<span className="range-box-range-description-max">{numeral(max / 100).format("$0,0.00")}</span>
			</div>
		</div>
	)
}
