import React from "react"
import ReactDOM from "react-dom"

const domNode = document.createElement("div")
document.body.appendChild(domNode)

/*
Using this component in order to render the children in the <body>, sometimes it makes sense to have the logic in an actual component
but due to how HTML is being rendered it makes sense to render it outside a the component HTML tree (modals, tooltips, loaders)
https://reactjs.org/docs/portals.html
*/

export default function BodyPortal({children}: {children: React.ReactNode}) {
	return ReactDOM.createPortal(children, domNode)
}
