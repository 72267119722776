import React, {useEffect} from "react"
import {useLocation} from "react-router-dom"
import {useToggle} from "react-use"
import classNames from "classnames"
import LogoLight from "../../images/logo-light.svg"
import LogoU from "../../images/logo-u.svg"
import {useIsBankUser, useIsOrgUnitPilot, useIsOrgUser, useIsUnitUser} from "../../services/auth"
import {UnitUserOnly} from "../../containers/PermissionedUser/PermissionedUser"
import useHtmlClassname from "../../hooks/useHtmlClassname"
import {IcomoonIconName} from "../Icon/icons"
import Icon from "../Icon/Icon"
import {hasPermission} from "../../services/permission"
import {getCurrentEnvironmentName} from "../../utilities/environment"
import {NavLink} from "../NavLink/NavLink"

interface SidebarItemProps {
	label: string
	link: string
	icon: IcomoonIconName
	isExactRoute?: boolean
}

function SidebarEnvironmentIndicator({isExpanded}: {isExpanded: boolean}) {
	let envTitle
	let icon: IcomoonIconName
	const env = getCurrentEnvironmentName()

	switch (env) {
		case "pilot": {
			envTitle = "Pilot"
			icon = "flash-enable-allow-1--interface-essential"
			break
		}
		case "live": {
			envTitle = "Live"
			icon = "astronomy-rocket-1--school-science"
			break
		}
		case "sandbox": {
			envTitle = "Sandbox"
			icon = "shape-3d-box"
			break
		}
	}

	return (
		<div className={classNames("sidebar-environment-indicator-wrapper", isExpanded && "is-expanded")}>
			<div className="sidebar-environment-indicator">
				{isExpanded ? (
					<>
						<Icon size={12} icon={icon} />
						{envTitle}
					</>
				) : (
					<img src={LogoU} alt="Unit" />
				)}
			</div>
		</div>
	)
}

function SidebarDesktopToggleIcon({isExpanded, toggle}: {isExpanded: boolean; toggle: () => void}) {
	return (
		<div
			className={classNames("sidebar-toggle-icon sidebar-desktop-toggle-icon", isExpanded && "expanded")}
			onClick={toggle}
		>
			<Icon
				size={12}
				icon={
					isExpanded
						? "interface-arrows-button-left--interface-essential"
						: "interface-arrows-button-right--interface-essential"
				}
			/>
		</div>
	)
}

function SidebarMobileToggleIcon({isExpanded, toggle}: {isExpanded: boolean; toggle: () => void}) {
	return (
		<div
			className={classNames("sidebar-toggle-icon sidebar-mobile-toggle-icon", isExpanded && "expanded")}
			onClick={toggle}
		>
			<Icon
				size={12}
				icon={
					isExpanded
						? "interface-arrows-button-left--interface-essential"
						: "interface-arrows-button-right--interface-essential"
				}
			/>
		</div>
	)
}

function SidebarItem({label, link, icon, isExactRoute = false}: SidebarItemProps) {
	return (
		<li>
			<NavLink className="has-icon" activeClassName="is-active" to={link} end={isExactRoute}>
				<div className="border-effect"></div>
				<span className="icon">
					<Icon size={16} icon={icon} />
				</span>
				<span className="nav-link-label">{label}</span>
			</NavLink>
		</li>
	)
}

export default function Sidebar() {
	const location = useLocation()
	const [isAsideDesktopExpanded, toggleAsideDesktop] = useToggle(true)
	const [isAsideMobileExpanded, toggleAsideMobile] = useToggle(false)
	useHtmlClassname(
		() =>
			classNames(
				"has-aside-left has-aside-mobile-transition",
				isAsideDesktopExpanded && "has-aside-expanded",
				isAsideMobileExpanded && "has-aside-mobile-expanded"
			),
		[isAsideMobileExpanded, isAsideDesktopExpanded]
	)

	const sidebarMenuClassname = classNames("sidebar", (isAsideDesktopExpanded || isAsideMobileExpanded) && "is-expanded")
	const isUnitUser = useIsUnitUser()
	const isBankUser = useIsBankUser()
	const isOrgUser = useIsOrgUser()
	const isOrgUnitPilot = useIsOrgUnitPilot()
	const env = getCurrentEnvironmentName()

	useEffect(() => {
		toggleAsideMobile(false)
	}, [location])

	const sidebarItems = (
		<>
			<SidebarItem label="Home" link="/" icon="home-button" isExactRoute />
			<SidebarItem label="Applications" link="/applications" icon="user-id-card" />
			<SidebarItem label="Customers" link="/customers" icon="user-multiple" />
			{isUnitUser ? (
				<SidebarItem label="Deposit Products" link="/deposit-products" icon="list-bullet-point-square" />
			) : null}
			{isUnitUser ? <SidebarItem label="Bins" link="/bins" icon="credit-card-edit--business-products" /> : null}
			<SidebarItem label="Accounts" link="/accounts" icon="bank-account--business-products" />
			{isUnitUser || isBankUser ? (
				<SidebarItem label="Orgs" link="/orgs" icon="real-estate-building-5--building-construction" />
			) : null}
			<SidebarItem label="Payments" link="/payments" icon="money-note-1--business-products" />
			{!isBankUser && <SidebarItem label="Received Payments" link="/received-payments" icon="payment-box-incoming" />}
			<SidebarItem label="Check Payments" link="/check-payments" icon="money-note-1--business-products" />
			{hasPermission("checkDeposit", "get") ? (
				<SidebarItem label="Check Deposits" link="/check-deposits" icon="check-write--business-products" />
			) : null}
			{hasPermission("dispute", "get") ? (
				<SidebarItem label="Disputes" link="/disputes" icon="law-hammer--business-products" />
			) : null}
			{isOrgUser && isOrgUnitPilot ? (
				<SidebarItem label="Pilot Overview" link="/pilot-overview" icon="astronomy-rocket-1--school-science" />
			) : null}
			{isUnitUser ? <SidebarItem label="Card Designs" link="/card-designs" icon="credit-card-setting" /> : null}
			{isBankUser && <SidebarItem label="Incoming Wires" link="/incoming-wires" icon="payment-box-incoming" />}
			{isBankUser && (
				<SidebarItem label="Incoming ACH" link="/incoming-achs" icon="money-transfer--business-products" />
			)}
			{isBankUser && (
				<SidebarItem label="Incoming Checks" link="/incoming-checks" icon="check-write--business-products" />
			)}
			{!isUnitUser && hasPermission("depositProduct", "get") ? (
				<SidebarItem label="Deposit Products" link="/deposit-products" icon="list-bullet-point-square" />
			) : null}
			{isBankUser && (
				<SidebarItem
					label="Agreements"
					link="/org-bank-agreements"
					icon="programming-language-code--programing-apps-websites"
				/>
			)}
		</>
	)

	const riskAndComplianceSidebarItems = (
		<>
			<SidebarItem label="Adjustments" link="/adjustments" icon="cypto-currency-exchange-rate--business-products" />
			<SidebarItem label="Batch Accounts" link="/batch-accounts" icon="module-three--interface-essential" />
			<SidebarItem label="Linked Accounts" link="/linked-accounts" icon="Linked-accounts" />
			<SidebarItem label="Incoming ACH" link="/incoming-achs" icon="money-transfer--business-products" />
			<SidebarItem label="Incoming Wires" link="/incoming-wires" icon="payment-box-incoming" />
			<SidebarItem label="Incoming Checks" link="/incoming-checks" icon="check-write--business-products" />
			<SidebarItem label="Interest Settings" link="/interest-settings" icon="wayfinding-money-pass--travel" />
			<SidebarItem
				label="Transaction Monitoring"
				link="/transaction-monitoring"
				icon="electronics-resistor--computers-devices-electronics"
			/>
			<SidebarItem
				label="Agreements"
				link="/org-bank-agreements"
				icon="programming-language-code--programing-apps-websites"
			/>
		</>
	)

	return (
		<aside className={classNames(sidebarMenuClassname, env)}>
			<div className="sidebar-top-area">
				<NavLink to="/" onClick={(_) => toggleAsideMobile(false)}>
					<>
						{isAsideDesktopExpanded ? (
							<span className="logo-wrapper">
								<img src={LogoLight} alt={"Unit"} />
							</span>
						) : null}
						<SidebarEnvironmentIndicator isExpanded={isAsideDesktopExpanded} />
					</>
				</NavLink>
				<SidebarDesktopToggleIcon isExpanded={isAsideDesktopExpanded} toggle={toggleAsideDesktop} />
				<SidebarMobileToggleIcon isExpanded={isAsideMobileExpanded} toggle={toggleAsideMobile} />
			</div>

			<div className="sidebar-menu-wrapper">
				<ul>{sidebarItems}</ul>

				<UnitUserOnly>
					<p className="subsection-header">Risk & Compliance</p>
					<ul>{riskAndComplianceSidebarItems}</ul>
				</UnitUserOnly>
			</div>
		</aside>
	)
}
