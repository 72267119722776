import React, {useState} from "react"
import {AsyncResultModal} from "../AsyncResultModal/AsyncResultModal"
import classNames from "classnames"
import {Result} from "neverthrow"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {Assignment} from "../../resources/assignments"
import {AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {isEmpty} from "lodash"

interface AssignmentApproveRejectModalProps {
	title: string
	description?: string | JSX.Element
	state: AsyncResultIdleRequestState<OkDocument<Assignment>, ErrorDocument>
	onSubmit: (...args: any[]) => Promise<Result<OkDocument<Assignment>, ErrorDocument>>
	primaryButtonText: string
	primaryButtonClassname: string
	withReasonText?: boolean
	isOptionalReasonText?: boolean
	reasonTextPlaceholder?: string
	reasonsToEnableReasonText?: string[]
	reasons?: AssignmentStatusUpdateReasonsMapping
	closeModal: () => void
	onSuccess: () => void
	successText: string
	successClassname?: string
}

export type AssignmentStatusUpdateReasonsMapping = {
	[key: string]: {
		reason: string
		displayName: string
	}
}

function refreshAndClose(refresh: () => void, close: () => void) {
	return function () {
		refresh()
		close()
	}
}

export function AssignmentApproveRejectModal({
	title,
	description,
	state,
	onSubmit,
	primaryButtonText,
	primaryButtonClassname,
	withReasonText,
	isOptionalReasonText,
	reasonTextPlaceholder,
	reasonsToEnableReasonText,
	reasons,
	closeModal,
	onSuccess,
	successText,
	successClassname,
}: AssignmentApproveRejectModalProps) {
	const [reasonKey, setReasonKey] = useState<string>(reasons ? Object.keys(reasons)[0] : "")
	const [reasonText, setReasonText] = useState<string>("")
	const hasModalBody = withReasonText || reasons
	const modalOnSubmit = async () => {
		const onSubmitArgs: {reason?: string; reasonText?: string} = {}
		if (reasons) {
			onSubmitArgs.reason = reasons[reasonKey].reason
		}
		if (withReasonText && !isEmpty(reasonText)) {
			onSubmitArgs.reasonText = reasonText
		}
		return await (isEmpty(onSubmitArgs) ? onSubmit() : onSubmit(onSubmitArgs))
	}

	return (
		<AsyncResultModal
			state={state}
			close={closeModal}
			buttonText={primaryButtonText}
			buttonClassname={primaryButtonClassname}
			successText={successText}
			successClassname={successClassname}
			onSubmit={modalOnSubmit}
			onSuccess={() => {
				setTimeout(refreshAndClose(onSuccess, closeModal), 1500)
			}}
			disableCloseOnSuccess
			errorToText={(err) => err.errors?.[0].title ?? "An error has occurred"}
		>
			<div className="assignment-approve-reject-modal-header">
				<h3 className={classNames("modal-title", description && "has-description")}>{title}</h3>
				{description && <span className="modal-description">{description}</span>}
			</div>
			{hasModalBody && (
				<>
					<br />
					<div className="field">
						{reasons && (
							<div className="control">
								<label className="label">Reason</label>
								<div className="select is-fullwidth">
									<select onChange={(e) => setReasonKey(e.target.value)} value={reasonKey}>
										{Object.entries(reasons).map(([reasonKey, {displayName}], index) => (
											<option key={index} value={reasonKey}>
												{displayName}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
						{reasons && withReasonText && <br />}
						{withReasonText && (
							<div className="control assignment-status-update-reason-text">
								<textarea
									className="textarea"
									placeholder={reasonTextPlaceholder}
									value={reasonText}
									onChange={(e) => setReasonText(e.target.value)}
									disabled={reasonsToEnableReasonText && !reasonsToEnableReasonText.includes(reasonKey)}
									maxLength={255}
									required={!isOptionalReasonText && (!reasons || reasonsToEnableReasonText?.includes(reasonKey))}
								/>
							</div>
						)}
					</div>
				</>
			)}
		</AsyncResultModal>
	)
}
