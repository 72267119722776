import React from "react"
import {useNavigate} from "react-router"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"

export default function Back() {
	const navigate = useNavigate()
	const goBack = () => navigate(-1)

	return (
		<button className="button is-light" onClick={goBack}>
			<span className="icon">
				<FontAwesomeIcon icon={faArrowLeft} />
			</span>
			<span>Back</span>
		</button>
	)
}
