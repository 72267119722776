import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {AsyncResultModal} from "../../components/AsyncResultModal/AsyncResultModal"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import Loading from "../../components/Loading/Loading"
import {AsyncResultIdle, AsyncResultIdleRequestState} from "../../types/asyncResultIdle"
import {ErrorDocument} from "../../resources/common"
import Admonition from "../../components/Admonitions/Admonition"
import {
	createInterestSettings,
	findInterestSettings,
	getInterestSettings,
	InterestSettings,
	updateInterestSettings,
} from "../../resources/interestSettings"
import {Bank, findBanks} from "../../resources/bank"
import {BankName} from "../../components/BankName/BankName"
import {toNumber} from "lodash"

type CloseFunc = () => void
type RefreshFunc = () => void
type Mode = "create" | "edit"

interface CreateModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	mode: "create"
	banks: Array<Bank>
}

interface EditModalProps {
	close: CloseFunc
	refresh: RefreshFunc
	mode: "edit"
	interestSettings: InterestSettings
}

function isEditMode(props: Omit<CreateModalProps | EditModalProps, "close" | "refresh">): props is EditModalProps {
	return props.mode == "edit"
}

function Modal({close, refresh, ...props}: CreateModalProps | EditModalProps) {
	const [findState, find] = useAsyncResultIdle(findInterestSettings)
	const accessToken = useAccessToken()
	const [createState, create] = useAsyncResultIdle(createInterestSettings)
	const [updateState, update] = useAsyncResultIdle(updateInterestSettings)
	const [name, setName] = useState(isEditMode(props) ? props.interestSettings.attributes.name : undefined)
	const [formula, setFormula] = useState(isEditMode(props) ? props.interestSettings.attributes.interestFormula : "")
	const [unitInterest, setUnitInterest] = useState(
		isEditMode(props) ? props.interestSettings.attributes.unitInterest : 0
	)
	const [bankId, setBankId] = useState(
		isEditMode(props)
			? props.interestSettings.relationships.bank.data.id
			: (props as CreateModalProps).banks[0].id.toString()
	)
	const [state, setState] = useState<AsyncResultIdleRequestState<any, ErrorDocument>>(AsyncResultIdle.idle)
	const [existingInterestSettings, setExistingInterestSettings] = useState<JSX.Element[]>([])

	const isNew = !isEditMode(props)

	useEffect(() => {
		findState.match(
			() => setState(AsyncResultIdle.idle),
			() => setState(AsyncResultIdle.pending),
			(result) => {
				setExistingInterestSettings(
					result.data.map((d) => (
						<span key={d.id}>
							Formula <code>{d.attributes.interestFormula}</code> already defined for bank <BankName bankId={bankId} />.
						</span>
					))
				)
				if (result.data.length == 0) {
					const updateProps = props as EditModalProps
					isNew
						? create(accessToken, bankId, formula, unitInterest, name)
						: update(
								accessToken,
								updateProps.interestSettings.id,
								updateProps.interestSettings.relationships.bank.data.id,
								formula,
								unitInterest,
								name
						  )
				} else {
					setState(AsyncResultIdle.ok)
				}
			},
			(err) => setState(AsyncResultIdle.err(err))
		)
	}, [findState])

	useEffect(() => {
		createState.match(
			() => setState(AsyncResultIdle.idle),
			() => setState(AsyncResultIdle.pending),
			() => setState(AsyncResultIdle.ok),
			(err) => setState(AsyncResultIdle.err(err))
		)
	}, [createState])

	useEffect(() => {
		updateState.match(
			() => setState(AsyncResultIdle.idle),
			() => setState(AsyncResultIdle.pending),
			() => setState(AsyncResultIdle.ok),
			(err) => setState(AsyncResultIdle.err(err))
		)
	}, [updateState])

	function getExistingFormulaModal(
		buttonText: string,
		successText: string,
		state: AsyncResultIdleRequestState<any, ErrorDocument>,
		onSubmit: Function,
		isEditing?: boolean
	) {
		return (
			<AsyncResultModal
				classname={"interest-settings-approve-modal"}
				buttonText={buttonText}
				onSubmit={onSubmit}
				buttonClassname={"is-success"}
				errorToText={(err) => err.errors[0].detail ?? err.errors[0].title}
				successText={successText}
				close={close}
				title={"Would you like to proceed?"}
				state={state}
				onSuccess={refresh}
			>
				{!isEditing && (
					<Admonition
						type={"is-warning"}
						message={existingInterestSettings.map((msg) => (
							<p key={msg.key}>
								{msg}
								<br />
							</p>
						))}
						title={"Note"}
					/>
				)}
			</AsyncResultModal>
		)
	}

	function getMainModal(
		title: string,
		buttonText: string,
		successText: string,
		namePlaceholder: string,
		formulaPlaceholder: string
	) {
		return (
			<AsyncResultModal
				title={title}
				onSubmit={() => find(accessToken, 0, 1000, bankId, formula)}
				close={close}
				state={state}
				buttonClassname="is-success"
				buttonText={buttonText}
				successText={successText}
				errorToText={(err: ErrorDocument) => err.errors[0].detail ?? err.errors[0].title}
				onSuccess={refresh}
			>
				{isNew ? (
					<div className="field">
						<label className="label">Bank</label>
						<div className="select">
							<select value={bankId} onChange={(e) => setBankId(e.target.value)}>
								{(props as CreateModalProps).banks.map((o) => (
									<option key={o.id} value={o.id.toString()}>
										{o.attributes.name}
									</option>
								))}
							</select>
						</div>
					</div>
				) : (
					<></>
				)}
				<div className="field">
					<label className="label">Formula</label>
					<div className="control">
						<input
							className="input"
							type="text"
							placeholder={formulaPlaceholder}
							value={formula}
							required
							onChange={(e) => setFormula(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<label className="label">Name</label>
					<div className="control">
						<input
							className="input"
							type="text"
							placeholder={namePlaceholder}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<label className="label">Unit interest</label>
					<div className="control">
						<input
							className="input"
							type="text"
							placeholder="0"
							value={unitInterest}
							required
							pattern={"[0-9]*"}
							onChange={(e) => setUnitInterest(toNumber(e.target.value))}
						/>
					</div>
				</div>
			</AsyncResultModal>
		)
	}

	if (existingInterestSettings.length == 0) {
		if (isNew) {
			return getMainModal(
				"Create new interest settings",
				"Create",
				"Created",
				"Name (optional)",
				"e.g. FED_UPPER_BOUND - 10"
			)
		} else {
			return getMainModal("Edit interest settings", "Update", "Updated", "Name (optional)", "e.g. FED_UPPER_BOUND - 10")
		}
	} else {
		if (isNew) {
			return getExistingFormulaModal("Create Anyway", "Created", createState, () =>
				create(accessToken, bankId, formula, unitInterest, name)
			)
		} else {
			const updateProps = props as EditModalProps
			return getExistingFormulaModal(
				"Update",
				"Updated",
				updateState,
				() =>
					update(
						accessToken,
						updateProps.interestSettings.id,
						updateProps.interestSettings.relationships.bank.data.id,
						formula,
						unitInterest,
						name
					),
				true
			)
		}
	}
}

export default function InterestSettingsModal({
	close,
	refresh,
	mode,
	interestSettingsId,
	bankId,
}: {
	close: CloseFunc
	refresh: RefreshFunc
	mode: Mode
	interestSettingsId?: string
	bankId?: string
}) {
	const accessToken = useAccessToken()

	if (mode == "edit" && interestSettingsId && bankId) {
		const interestSettingsAsync = useAsyncResult<InterestSettings, ErrorDocument>(() =>
			getInterestSettings(accessToken, interestSettingsId, bankId)
		)

		return interestSettingsAsync.match(
			() => <Loading />,
			(interestSettings) => <Modal close={close} refresh={refresh} interestSettings={interestSettings} mode={"edit"} />,
			(_) => null
		)
	} else {
		const banks = useAsyncResult(() => findBanks(accessToken, 0, 10000))

		return banks.match(
			() => <Loading />,
			(banks) => <Modal banks={banks} close={close} refresh={refresh} mode={"create"} />,
			(_) => null
		)
	}
}
