import React, {useRef} from "react"
import {Counterparty} from "../../resources/counterparty"

import {useClickAway} from "react-use"
import Icon from "../Icon/Icon"
import {TagsViewerKeyValue} from "../Tags/TagsViewer"

type CloseFunc = () => void

interface ModalProps {
	counterparty: Counterparty
	close: CloseFunc
}

export type Tag = {key: string; value: string}

function Modal({counterparty, close}: ModalProps) {
	const ref = useRef(null)

	useClickAway(ref, () => close())

	return (
		<div className="modal is-active view-counterparty-modal">
			<div className="modal-background" />
			<div className="modal-card" ref={ref}>
				<header className="modal-card-head">
					<label className="close-modal-button" onClick={() => close()}>
						<Icon icon="interface-delete-interface-essential" size={16} />
					</label>
				</header>
				<div className="modal-card-body">
					<div className="counterparty-title">
						<span className="counterparty-name">{counterparty.attributes.name}</span>
						<span className="counterparty-separator" />
						<span className="counterparty-id">#{counterparty.id}</span>
					</div>
					<div className="counterparty-field">
						<span className="counterparty-field-label">Counterparty Type</span>
						<span className="counterparty-field-value">{counterparty.attributes.type}</span>
					</div>
					<div className="separator" />
					<div className="counterparty-field">
						<span className="counterparty-field-label">Permissions</span>
						<span className="counterparty-field-value">{counterparty.attributes.permissions}</span>
					</div>
					<div className="separator" />
					<div className="counterparty-field">
						<span className="counterparty-field-label">Routing Number</span>
						<span className="counterparty-field-value">{counterparty.attributes.routingNumber}</span>
					</div>
					<div className="separator" />
					<div className="counterparty-field">
						<span className="counterparty-field-label">Account Number</span>
						<span className="counterparty-field-value">{counterparty.attributes.accountNumber}</span>
					</div>
					<div className="separator" />
					<div className="counterparty-field">
						<span className="counterparty-field-label">Account Type</span>
						<span className="counterparty-field-value account-type-row">
							{counterparty.attributes.accountType == "Savings" ? (
								<Icon icon="saving-profit-interest" size={18} />
							) : null}
							{counterparty.attributes.accountType == "Checking" ? (
								<Icon icon="money-transfer--business-products" size={18} />
							) : null}
							{counterparty.attributes.accountType}
						</span>
					</div>
					<div className="separator" />
					<div className="counterparty-field">
						<span className="counterparty-field-label">Tags</span>
						<span className="counterparty-field-value">
							<TagsViewerKeyValue tags={counterparty.attributes.tags} />
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default function ViewCounterpartyModal({counterparty, close}: {counterparty: Counterparty; close: CloseFunc}) {
	return <Modal counterparty={counterparty} close={close} />
}
