import React, {PropsWithChildren} from "react"
import logoDark from "../../images/logo-dark.svg"
import partners from "../../images/partners.svg"

export default function LoginContainer({children}: PropsWithChildren<{}>) {
	return (
		<section className="columns login-page">
			<div className="column is-7 is-full-height is-half is-flex is-flex-direction-column">
				<a href="https://www.unit.co/" className="unit-logo">
					<img src={logoDark} />
				</a>
				{children}
			</div>
			<div className="login-side column is-5 is-full-height is-half is-justify-content-center is-flex-direction-column is-align-items-center">
				<div className="login-side-content is-flex is-flex-direction-column is-justify-content-space-between">
					<div className="login-side-top is-flex is-flex-direction-column is-align-items-center">
						<div className="login-side-title">Build banking in minutes</div>
					</div>
					<div className="partners-container">
						<div className="partners-title">Trusted by</div>
						<div className="partners is-flex">
							<img className="is-flex-grow-1" src={partners} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
