import {Address, createResource, FullName, getResource, Phone} from "./common"
import {Brand} from "./bin"

export interface AccountRelationship {
	data: {
		type: string
		id: string
	}
}

export interface CustomerRelationship {
	data: {
		type: string
		id: string
	}
}

export type IndividualDebitCard = {
	type: CardType.individualDebitCard
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		last4Digits: string
		expirationDate: string
		shippingAddress?: Address
		status: string
		freezeReason?: string
		brand?: Brand
	}
	relationships: {
		account: AccountRelationship
		customer: CustomerRelationship
	}
}

export type BusinessCreditCard = {
	type: CardType.businessCreditCard
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		last4Digits: string
		expirationDate: string
		shippingAddress?: Address
		fullName: FullName
		nationality: string
		ssn?: string
		passport?: string
		address: Address
		dateOfBirth: Date
		email: string
		phone: Phone
		status: string
		freezeReason?: string
		brand?: Brand
	}
	relationships: {
		account: AccountRelationship
		customer: CustomerRelationship
	}
}

export type BusinessDebitCard = {
	type: CardType.businessDebitCard
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		last4Digits: string
		expirationDate: string
		shippingAddress?: Address
		fullName: FullName
		nationality: string
		ssn?: string
		passport?: string
		address: Address
		dateOfBirth: Date
		email: string
		phone: Phone
		status: string
		freezeReason?: string
		brand?: Brand
	}
	relationships: {
		account: AccountRelationship
		customer: CustomerRelationship
	}
}

export type IndividualVirtualDebitCard = {
	type: CardType.individualVirtualDebitCard
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		last4Digits: string
		expirationDate: string
		status: string
		freezeReason?: string
		brand?: Brand
	}
	relationships: {
		account: AccountRelationship
		customer: CustomerRelationship
	}
}

export type BusinessVirtualCreditCard = {
	type: CardType.businessVirtualCreditCard
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		last4Digits: string
		expirationDate: string
		fullName: FullName
		nationality: string
		ssn?: string
		passport?: string
		address: Address
		dateOfBirth: Date
		email: string
		phone: Phone
		status: string
		freezeReason?: string
		brand?: Brand
	}
	relationships: {
		account: AccountRelationship
		customer: CustomerRelationship
	}
}

export type BusinessVirtualDebitCard = {
	type: CardType.businessVirtualDebitCard
	id: string
	attributes: {
		createdAt: Date
		updatedAt?: Date
		last4Digits: string
		expirationDate: string
		fullName: FullName
		nationality: string
		ssn?: string
		passport?: string
		address: Address
		dateOfBirth: Date
		email: string
		phone: Phone
		status: string
		freezeReason?: string
		brand?: Brand
	}
	relationships: {
		account: AccountRelationship
		customer: CustomerRelationship
	}
}

export const enum CardType {
	// Physical Cards
	businessCreditCard = "businessCreditCard",
	businessDebitCard = "businessDebitCard",
	individualDebitCard = "individualDebitCard",

	// Virtual Cards
	businessVirtualDebitCard = "businessVirtualDebitCard",
	businessVirtualCreditCard = "businessVirtualCreditCard",
	individualVirtualDebitCard = "individualVirtualDebitCard",
}

export type Card = PhysicalCard | VirtualCard
export type PhysicalCard = BusinessCreditCard | BusinessDebitCard | IndividualDebitCard
export type VirtualCard = BusinessVirtualDebitCard | BusinessVirtualCreditCard | IndividualVirtualDebitCard

export function isBusinessDebitCard(card: Card): card is BusinessDebitCard {
	return card.type === CardType.businessDebitCard
}

export function isIndividualDebitCard(card: Card): card is IndividualDebitCard {
	return card.type === CardType.individualDebitCard
}

export function isBusinessVirtualDebitCard(card: Card): card is BusinessVirtualDebitCard {
	return card.type === CardType.businessVirtualDebitCard
}

export function isBusinessVirtualCreditCard(card: Card): card is BusinessVirtualCreditCard {
	return card.type === CardType.businessVirtualCreditCard
}

export function isIndividualVirtualDebitCard(card: Card): card is IndividualVirtualDebitCard {
	return card.type === CardType.individualVirtualDebitCard
}

export function isVirtualCard(card: Card): card is VirtualCard {
	return isIndividualVirtualDebitCard(card) || isBusinessVirtualDebitCard(card) || isBusinessVirtualCreditCard(card)
}

export async function findCards(accessToken: string, offset: number, limit: number) {
	const query = {
		page: {
			limit,
			offset,
		},
		extraFields: {
			card: "freezeReason",
		},
	}

	const result = await getResource<Array<Card>>("cards", accessToken, query)

	return result.map((v) => v.data)
}

export async function getCustomerCards(
	accessToken: string,
	offset: number,
	limit: number,
	customerId?: string,
	sort?: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			customerId,
		},
		extraFields: {
			card: "freezeReason",
		},
		sort,
	}

	return await getResource<Array<Card>>("cards", accessToken, query)
}

export async function getAccountCards(
	accessToken: string,
	offset: number,
	limit: number,
	accountId: string,
	sort?: string
) {
	const query = {
		page: {
			limit,
			offset,
		},
		filter: {
			accountId,
		},
		include: "customer",
		extraFields: {
			card: "freezeReason",
		},
		sort,
	}

	return await getResource<Array<Card>>("cards", accessToken, query)
}

export async function freezeCard(accessToken: string, card: Card, reason: string) {
	const data = {
		type: "freezeCard",
		attributes: {
			reason,
		},
	}
	return await createResource<Card>(`cards/${card.id}/freeze`, accessToken, data)
}

export async function unfreezeCard(accessToken: string, card: Card) {
	return await createResource<Card>(`cards/${card.id}/unfreeze`, accessToken, undefined)
}

export async function closeCard(accessToken: string, card: Card) {
	return await createResource<Card>(`cards/${card.id}/close`, accessToken, undefined)
}

export function isCloseable(card: Card): boolean {
	return ["Inactive", "Active", "ActiveForOnlineUse", "Frozen", "SuspectedFraud"].includes(card.attributes.status)
}

export function isReplaceable(card: Card): boolean {
	return !isVirtualCard(card) && ["Inactive", "Active"].includes(card.attributes.status)
}

export function isReactivable(card: Card): boolean {
	return card.attributes.status == "SuspectedFraud"
}

export function canResetPin(card: Card): boolean {
	return (
		["Active", "ActiveForOnlineUse", "Frozen", "SuspectedFraud"].includes(card.attributes.status) &&
		![CardType.businessCreditCard, CardType.businessVirtualCreditCard].includes(card.type)
	)
}

export async function reActivateCard(accessToken: string, card: Card) {
	return await createResource<Card>(`cards/${card.id}/unsuspectedFraud`, accessToken, undefined)
}

export async function replaceCard(accessToken: string, card: Card, address: Address) {
	const data = {
		type: "replaceCard",
		attributes: {
			shippingAddress: address,
		},
	}

	return await createResource<Card>(`cards/${card.id}/replace`, accessToken, data)
}

export async function resetCardPin(accessToken: string, card: Card) {
	return await createResource<Card>(`cards/${card.id}/secure-data/pin/reset`, accessToken, undefined)
}
