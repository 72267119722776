import TitleBar from "../../components/TitleBar/TitleBar"
import {MainSection} from "../../containers/MainSection/MainSection"
import React from "react"
import {useUserId} from "../../services/auth"
import {ApiTokens, ApiTokensColumns} from "../../components/ApiTokens/ApiTokens"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import NewApiTokenModal from "../../components/ApiTokens/NewApiTokenModal"
import {useModal} from "react-modal-hook"
import Icon from "../../components/Icon/Icon"

export default function ApiTokensPage() {
	const userId = useUserId()
	const [refreshToken, refresh] = useRefreshToken()
	const [showModal, hideModal] = useModal(() => (
		<NewApiTokenModal close={hideModal} refresh={refresh} userIdentifier={userId} />
	))

	return (
		<>
			<TitleBar title={"API Tokens"}>
				<button className="button button-create" onClick={() => showModal()}>
					<Icon icon="interface-add-1" size={12} /> Create
				</button>
			</TitleBar>

			<MainSection>
				<ApiTokens
					refreshToken={refreshToken}
					userId={userId}
					includedColumns={[
						ApiTokensColumns.id,
						ApiTokensColumns.description,
						ApiTokensColumns.createdAt,
						ApiTokensColumns.expiration,
						ApiTokensColumns.sourceIPs,
						ApiTokensColumns.actions,
					]}
				/>
			</MainSection>
		</>
	)
}
