import {useAccessToken, useIsBankUser, useIsUnitUser, useUserClaimsData} from "../../services/auth"
import {useParams} from "react-router-dom"
import React from "react"
import {PaymentButtons, PaymentComponent, PaymentPlaidData} from "../../components/Payments/Payment"
import {useRefreshToken} from "../../hooks/useRefreshToken"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {
	getPayment,
	getPaymentStatus,
	isAchPayment,
	isPlaidVerificationMethod,
	isWirePayment,
	Payment,
	PaymentStatus,
	PaymentStatusResponse,
	WirePayment,
} from "../../resources/payment"
import {isOrg} from "../../services/orgAuth"
import classNames from "classnames"
import {AsyncResultZipComponent} from "../../containers/AsyncResult/AsyncResult"
import {Customer, CustomerResourceTypes, isBusinessCustomer} from "../../resources/customer"
import {Org} from "../../resources/org"
import {Account} from "../../resources/account"
import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"
import PaymentTitleBar from "./PaymentTitleBar"
import {PaymentAssignments, PaymentAssignmentsProps} from "./PaymentAssignments"
import {isProdEnv, isSandboxOrLocalEnv} from "../../utilities/environment"
import {useQueryState} from "use-location-state"
import {TabsMeta, TitleBarTabs} from "../../components/TitleBarTabs/TitleBarTabs"
import {startCase} from "lodash"
import {AsyncResultRequestState} from "../../types/asyncResult"
import {ErrorDocument, OkDocument} from "../../resources/common"
import {AssignmentsAmountIndicator} from "./Payments"
import {AssigneeType, AssignmentStage} from "../../resources/assignments"
import {IcomoonIconName} from "../../components/Icon/icons"

export enum PaymentTabConstants {
	FullWireDetails = "FullWireDetails",
	ActionsRequired = "ActionsRequired",
}

function PaymentTabs({
	currentTab,
	setCurrentTab,
	wirePayment,
	refreshToken,
}: {
	currentTab: PaymentTabConstants
	setCurrentTab: (tab: PaymentTabConstants) => void
	wirePayment: WirePayment
	refreshToken: number
}) {
	const accessToken = useAccessToken()
	const claims = useUserClaimsData()
	const assigneeType = startCase(claims.userType) as AssigneeType
	const liClassName = "payment-tab-li" as const
	const commonTabProps = {liClassName}
	const tabs: TabsMeta<PaymentTabConstants>[] = [
		{
			label: startCase(PaymentTabConstants.FullWireDetails),
			key: PaymentTabConstants.FullWireDetails,
			prefixIcon: "payment-box-incoming" as IcomoonIconName,
		},
		{
			label: startCase(PaymentTabConstants.ActionsRequired),
			key: PaymentTabConstants.ActionsRequired,
			prefixIcon: "dialpad--business-products" as IcomoonIconName,
			suffixElement: (
				<AssignmentsAmountIndicator
					findAssignmentsArgs={{
						approvals: wirePayment.relationships.approvals?.data.id,
						accessToken,
						assignees: [assigneeType],
						stages: [AssignmentStage.Pending],
						limit: 0,
					}}
					refreshToken={refreshToken}
					isActive={currentTab == PaymentTabConstants.ActionsRequired}
				/>
			),
		},
	].map((tab) => ({...tab, ...commonTabProps}))
	return <TitleBarTabs currentTab={currentTab} setCurrentTab={setCurrentTab} tabs={tabs} />
}

interface PaymentMainSectionProps {
	isPlaidLayout: boolean
	isReviewReasonsLayout: boolean
	payment: Payment
	paymentAsyncResultReq: AsyncResultRequestState<OkDocument<Payment>, ErrorDocument>
	account: Account
	statusAsyncResultReq: AsyncResultRequestState<PaymentStatusResponse, ErrorDocument>
	org: Org
	refresh: () => void
	paymentId: string
	accessToken: string
	customers: Customer[]
}
function PaymentMainSection({
	isPlaidLayout,
	isReviewReasonsLayout,
	payment,
	paymentAsyncResultReq,
	account,
	statusAsyncResultReq,
	org,
	refresh,
	paymentId,
	accessToken,
	customers,
}: PaymentMainSectionProps) {
	return (
		<MainSectionLayout
			outerWrapperClassname={classNames(isPlaidLayout && "payments-full-page-with-plaid")}
			innerWrapperClassname={classNames(isReviewReasonsLayout && "payments-full-page-with-review-reasons")}
			renderAfter={isPlaidLayout && isAchPayment(payment) ? <PaymentPlaidData payment={payment} /> : <></>}
			fullWidth={isPlaidLayout || isReviewReasonsLayout}
		>
			<PaymentComponent
				paymentAsyncResult={paymentAsyncResultReq}
				isReviewReasonsLayout={isReviewReasonsLayout}
				account={account}
				statusAsyncResult={statusAsyncResultReq}
				org={org}
				refresh={refresh}
				useDrawer={false}
				paymentId={paymentId}
				accessToken={accessToken}
				customers={customers}
			/>
		</MainSectionLayout>
	)
}

interface PaymentPageContentProps {
	isShowAssignments: boolean
	currentTab: PaymentTabConstants
	paymentAsMainSectionProps: PaymentMainSectionProps
	paymentAssignmentsProps: PaymentAssignmentsProps
}
function PaymentPageContent({
	isShowAssignments,
	currentTab,
	paymentAsMainSectionProps,
	paymentAssignmentsProps,
}: PaymentPageContentProps) {
	if (!isShowAssignments) {
		return <PaymentMainSection {...paymentAsMainSectionProps} />
	}
	switch (currentTab) {
		case PaymentTabConstants.FullWireDetails:
			return <PaymentMainSection {...paymentAsMainSectionProps} />
		case PaymentTabConstants.ActionsRequired:
			return <PaymentAssignments {...paymentAssignmentsProps} />
	}
}

export const paymentTabQueryStateName = "paymentTab" as const

export default function PaymentPage({assignmentsView = false}: {assignmentsView?: boolean}) {
	const [currentTab, setCurrentTab] = useQueryState<PaymentTabConstants>(
		paymentTabQueryStateName,
		PaymentTabConstants.ActionsRequired
	)
	const accessToken = useAccessToken()
	const {paymentId = ""} = useParams<{paymentId: string}>()
	const [refreshToken, refresh] = useRefreshToken()
	const isUnitUser = useIsUnitUser()
	const include = isUnitUser || useIsBankUser()
	const includeFields = include ? "customer,org,account" : "customer"
	const paymentAsyncResultReq = useAsyncResult(() => getPayment(accessToken, paymentId, includeFields), [refreshToken])
	const statusAsyncResultReq = useAsyncResult(() => getPaymentStatus(accessToken, paymentId), [refreshToken])
	const isOrgPayment = isOrg()

	return (
		<div className={"payment-full-page"}>
			<AsyncResultZipComponent asyncResult1={paymentAsyncResultReq} asyncResult2={statusAsyncResultReq}>
				{(values) => {
					const paymentResponse = values.value1 as {data: Payment; included: Array<Account | Customer>}
					const status = values.value2 as PaymentStatusResponse
					const payment = paymentResponse.data
					const customers =
						paymentResponse.included &&
						(paymentResponse.included.filter((i) => CustomerResourceTypes.includes(i.type)) as Customer[])
					const org =
						paymentResponse.included &&
						(paymentResponse.included.find((i) => (i.type as string) === "org") as unknown as Org)
					const account =
						paymentResponse.included && (paymentResponse.included.find((i) => i.type === "depositAccount") as Account)
					const isPlaidLayout = isUnitUser && isAchPayment(payment) && isPlaidVerificationMethod(payment)
					const hasApprovals = isWirePayment(payment) && !!payment.relationships.approvals
					const sandboxAndLocalOrgIds = ["3475", "2654", "5297", "5314", "5326", "5328", "5329", "5335", "5336"]
					const sandboxAndLocalBankIds = ["20", "26"]
					const showAssignmentsOnSandBox =
						isSandboxOrLocalEnv() &&
						(sandboxAndLocalOrgIds.includes(payment.relationships.org?.data.id ?? "") ||
							sandboxAndLocalBankIds.includes(payment.relationships.bank?.data.id ?? "") ||
							isUnitUser) // For MS1 Only, remove when needed.
					const showAssignmentsOnProd = isProdEnv()
					const isShowAssignments =
						hasApprovals && assignmentsView && (showAssignmentsOnSandBox || showAssignmentsOnProd)
					const isReviewReasonsLayout =
						(isUnitUser || useIsBankUser()) &&
						isWirePayment(payment) &&
						payment.attributes.status === PaymentStatus.PendingReview &&
						!isShowAssignments
					const applicationId =
						customers && customers.length > 0 && isBusinessCustomer(customers[0])
							? customers[0].relationships.application?.data.id
							: undefined
					const paymentAsMainSectionProps = {
						isPlaidLayout,
						isReviewReasonsLayout,
						payment,
						paymentAsyncResultReq,
						account,
						statusAsyncResultReq,
						org,
						refresh,
						paymentId,
						accessToken,
						customers,
					}
					const paymentAssignmentsProps = {
						payment,
						included: paymentResponse.included,
						businessApplicationId: applicationId,
						accessToken,
						refreshToken,
						refresh,
					}
					return (
						<>
							<PaymentTitleBar
								payment={payment}
								included={paymentResponse.included}
								isOrgPayment={isOrgPayment}
								leftSideClassName={classNames(isShowAssignments && "assignments-title-bar-left-side")}
								className={classNames(isShowAssignments && ["assignments-title-bar", "no-padding", "no-align-items"])}
							>
								{isShowAssignments ? (
									<PaymentTabs
										currentTab={currentTab}
										setCurrentTab={setCurrentTab}
										wirePayment={payment}
										refreshToken={refreshToken}
									/>
								) : (
									!hasApprovals && (
										<PaymentButtons payment={payment} accessToken={accessToken} refresh={refresh} status={status} />
									)
								)}
							</PaymentTitleBar>
							<PaymentPageContent
								isShowAssignments={isShowAssignments}
								currentTab={currentTab}
								paymentAsMainSectionProps={paymentAsMainSectionProps}
								paymentAssignmentsProps={paymentAssignmentsProps}
							/>
						</>
					)
				}}
			</AsyncResultZipComponent>
		</div>
	)
}
