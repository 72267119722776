import React from "react"
import Icon from "../Icon/Icon"
import ReactTooltip from "react-tooltip"

export default function OktaFields({
	jwksPath,
	issuer,
	updateSettings,
}: {
	jwksPath: string
	issuer: string | undefined
	updateSettings: (field: string, value: string) => void
}) {
	return (
		<fieldset className="jwt-fields">
			<div className="field">
				<label className="label">JWKs Path</label>
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="https://{yourOktaDomain}/oauth2/{authorizationServerId}/v1/keys"
						value={jwksPath ?? ""}
						pattern="https:\/\/([A-Za-z0-9\-]+\.)+okta\.com\/oauth2\/[A-Za-z0-9]+\/v1\/keys"
						onChange={(e) => {
							updateSettings("jwksPath", e.target.value)
							if (e.target.validity.patternMismatch) {
								e.target.setCustomValidity(
									"invalid url, make sure your url is in this format: 'https://{oktaDomain}/oauth2/{authorization_server_id}/v1/keys'"
								)
							} else {
								e.target.setCustomValidity("")
							}
						}}
					/>
				</div>
			</div>
			<div className="field">
				<label className="label">
					Issuer <span className="field-tag">(Optional)</span>
					<label className="tooltip-conatiner">
						<div data-tip="React-tooltip" data-for="issuer-info" className="tooltip-info">
							<Icon icon="information-circle--interface-essential" size={12} />

							<ReactTooltip className="tooltip-info" id="issuer-info" effect="solid">
								If you use a are using a custom domain, please provide the custom domain you chose for your
								authorization server.
							</ReactTooltip>
						</div>
					</label>
				</label>
				<div className="control">
					<input
						className="input"
						type="text"
						value={issuer ?? ""}
						placeholder=" "
						onChange={(e) => {
							updateSettings("issuer", e.target.value)
						}}
					/>
				</div>
			</div>
		</fieldset>
	)
}
