import React from "react"
import classNames from "classnames"
import {Assignee, AssigneeType} from "../../resources/assignments"
import {toLower} from "lodash"

interface AssigneeAvatarProps {
	assignees: Assignee[]
	size?: "small" | "medium"
}

const getTypeClassName = (type: AssigneeType) => toLower(`type-${type}`)

const renderAvatars = (assignees: Assignee[], size: string) =>
	assignees.map(({type, name}, index) => (
		<div key={index} className={classNames("assignee-avatar", getTypeClassName(type), size)}>
			<div className="assignee-avatar-content">{name?.[0] ?? type[0]}</div>
		</div>
	))

export function AssigneeAvatar({assignees, size = "small"}: AssigneeAvatarProps) {
	const avatars = renderAvatars(assignees, size)
	return <div className="assignee-avatars-container">{avatars}</div>
}
