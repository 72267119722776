import React from "react"
import Icon from "../Icon/Icon"
import {Link} from "react-router-dom"
import {IcomoonIconName} from "../Icon/icons"
import classNames from "classnames"

export interface TutorialStep {
	title: string
	url: string
	isExternalLink: boolean
	icon: IcomoonIconName
	backgroundColor: string
	onClick?: Function
}

interface TutorialBoxesProps {
	title: string
	steps: TutorialStep[]
	isExpanded: boolean
	toggleIsExpanded: () => void
}

interface TutorialStepProps extends TutorialStep {
	stepNumber: number
}

function TutorialStepLink({title, url, isExternalLink, icon, backgroundColor, onClick, stepNumber}: TutorialStepProps) {
	const tutorialStepContent = (
		<>
			<div className="icon-wrapper" style={{backgroundColor: backgroundColor}}>
				<Icon icon={icon} />
			</div>
			<div className="text">
				<div className="step-number">Step {stepNumber}</div>
				<div className="step-title">{title}</div>
			</div>
		</>
	)

	if (isExternalLink) {
		return (
			<a
				target="_blank"
				rel="noreferrer"
				href={url}
				data-tracking-label={`tutorial-box-step-${stepNumber}-${title}`}
				onClick={() => onClick && onClick()}
			>
				{tutorialStepContent}
			</a>
		)
	}

	return (
		<Link
			to={url}
			data-tracking-label={`tutorial-box-step-${stepNumber}-${title}`}
			onClick={() => onClick && onClick()}
		>
			{tutorialStepContent}
		</Link>
	)
}

export function TutorialBoxes({title, steps, isExpanded, toggleIsExpanded}: TutorialBoxesProps) {
	return (
		<div
			className={classNames("card tutorial-boxes", isExpanded && "is-expanded")}
			onClick={() => (isExpanded ? null : toggleIsExpanded())}
			data-tracking-label="tutorial-boxes-expand"
		>
			<div className="tutorial-title">
				{title}
				<span
					onClick={() => (isExpanded ? toggleIsExpanded() : null)}
					data-tracking-label={isExpanded ? "tutorial-boxes-shrink" : "tutorial-boxes-expand"}
				>
					{isExpanded ? <Icon icon={"shrink-diagonal"} /> : <Icon icon={"expand-diagonal"} />}
				</span>
			</div>
			<div className="tutorial-steps">
				{steps.map((step, index) => {
					return <TutorialStepLink key={step.title} {...step} stepNumber={index + 1} />
				})}
			</div>
		</div>
	)
}
