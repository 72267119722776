import React from "react"
import {Box} from "../../containers/Box/Box"

export type BoundType = "Upper" | "Lower"
function FedBoundView({bound, percentageValue}: {bound: BoundType; percentageValue: number}) {
	const boundText = bound === "Upper" ? "FED_UPPER_BOUND" : "FED_LOWER_BOUND"
	return (
		<Box className={"fed-bound-view"}>
			<span className="bound-text">{boundText}</span>
			<span className="bound-value">{percentageValue.toFixed(2) + "%"}</span>
		</Box>
	)
}
export function BoundsTooltip({fedUpperBound, fedLowerBound}: {fedUpperBound: number; fedLowerBound: number}) {
	return (
		<div className="bounds-tooltip-container">
			<FedBoundView bound={"Upper"} percentageValue={fedUpperBound / 100}></FedBoundView>
			<FedBoundView bound={"Lower"} percentageValue={fedLowerBound / 100}></FedBoundView>
		</div>
	)
}
