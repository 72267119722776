import {Result} from "neverthrow"
import {useCallback, useEffect, useRef} from "react"
import {useUpdate} from "react-use"
import {useAsyncResult} from "./useAsyncResult"

type Fn<T, E> = () => Promise<Result<T, E>>

export function useAsyncExpiredMemo<T, E>(fn: Fn<T, E>, fnExpiresIn: (t: T) => number) {
	const timeout = useRef<ReturnType<typeof setTimeout>>()
	const update = useUpdate()
	const result = useAsyncResult(fn, [update])

	const clear = useCallback(() => {
		timeout.current && clearTimeout(timeout.current)
	}, [])

	const start = useCallback((ms: number) => {
		timeout.current && clearTimeout(timeout.current)
		timeout.current = setTimeout(() => {
			update()
		}, ms)
	}, [])

	// Destructor
	useEffect(() => clear, [])

	useEffect(() => {
		result.match(
			() => clear(),
			(t) => start(fnExpiresIn(t)),
			(_) => clear()
		)
	}, [result])

	return result
}
