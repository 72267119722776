import MainSectionLayout from "../../containers/MainSection/MainSectionLayout"
import React from "react"
import {prettyPrintType, Transaction} from "../../resources/transaction"
import numeral from "numeral"
import classNames from "classnames"
import moment from "moment/moment"
import {KeyValueCard, KeyValueCardContainer, KeyValueCardPair} from "../KeyValueCard/KeyValueCard"

export function TransactionInformation({transaction}: {transaction: Transaction}) {
	return (
		<MainSectionLayout fullWidth outerWrapperClassname="transaction-info-wrapper">
			<KeyValueCard title="Transaction Information">
				<KeyValueCardContainer>
					{KeyValueCardPair("ID", transaction.id.toString())}
					{KeyValueCardPair("Type", prettyPrintType(transaction))}
					{KeyValueCardPair(
						"Amount",
						<>
							<span className="dollar-sign">$</span>
							<span
								className={classNames(
									transaction.attributes.direction === "Credit" && "has-text-success",
									transaction.attributes.direction === "Debit" && "has-text-danger"
								)}
							>
								{numeral(transaction.attributes.amount / 100).format("0.00")}
							</span>
							{/*<input*/}
							{/*  type="text"*/}
							{/*  readOnly*/}
							{/*  value={numeral(transaction.attributes.amount / 100).format("0,0.00")}*/}
							{/*  className={classNames(*/}
							{/*    "input is-static",*/}
							{/*    transaction.attributes.direction === "Credit" && "has-text-success",*/}
							{/*    transaction.attributes.direction === "Debit" && "has-text-danger"*/}
							{/*  )}*/}
							{/*/>*/}
						</>
					)}

					{/*<HorizontalField label="Amount">*/}
					{/*  <span className="dollar-sign">$</span>*/}
					{/*  <input*/}
					{/*    type="text"*/}
					{/*    readOnly*/}
					{/*    value={numeral(transaction.attributes.amount / 100).format("0,0.00")}*/}
					{/*    className={classNames(*/}
					{/*      "input is-static",*/}
					{/*      transaction.attributes.direction === "Credit" && "has-text-success",*/}
					{/*      transaction.attributes.direction === "Debit" && "has-text-danger"*/}
					{/*    )}*/}
					{/*  />*/}
					{/*</HorizontalField>*/}
					{KeyValueCardPair("Summary", transaction.attributes.summary)}
					{KeyValueCardPair("Created", moment(transaction.attributes.createdAt).format("L"))}
				</KeyValueCardContainer>
			</KeyValueCard>
		</MainSectionLayout>
	)
}
