import {createResource, getFile, getResource, getResourceAsArrayBuffer} from "./common"
import {Cents} from "./transaction"

export enum CheckImageSide {
	Front,
	Back,
}

export enum CheckDepositStatus {
	AwaitingImages = "AwaitingImages",
	AwaitingFrontImage = "AwaitingFrontImage",
	AwaitingBackImage = "AwaitingBackImage",
	PendingReview = "PendingReview",
	AwaitingCustomerConfirmation = "AwaitingCustomerConfirmation",
	Pending = "Pending",
	Rejected = "Rejected",
	Clearing = "Clearing",
	Sent = "Sent",
	Canceled = "Canceled",
	Returned = "Returned",
}

export enum CheckDepositVendors {
	Ensenta = "Ensenta",
	Smartpay = "Smartpay",
}

export type CheckCounterparty = {
	name: string
	accountNumber: string
	routingNumber: string
}

export type StatusEvent = {
	status: {
		[key: string]: any
	}
	updatedAt: Date
	updatedBy?: string
}

export type CheckDeposit = {
	id: string
	type: string
	attributes: {
		createdAt: Date
		status: keyof typeof CheckDepositStatus
		reason?: string
		reasonText?: string
		description: string
		amount: Cents
		vendor?: CheckDepositVendors
		checkNumber?: string
		counterparty: CheckCounterparty
		frontImage?: string
		backImage?: string
		settlementDate?: Date
		statusCreatedAt?: Date
		statusSetBy?: string
		statusHistory: StatusEvent[]
		tags?: {[key: string]: string}
	}
	relationships: {
		account: {
			data: {
				type: string
				id: number
			}
		}
		customer?: {
			data: {
				type: string
				id: number
			}
		}
		customers?: {
			data: [
				{
					type: "customer"
					id: string
				}
			]
		}
		org?: {
			data: {
				type: "org"
				id: string
			}
		}
		transaction?: {
			data: {
				type: "transaction"
				id: string
			}
		}
	}
}

interface CheckDepositFindParam {
	accessToken: string
	offset: number
	limit?: number
	searchQuery?: string
	since?: string
	until?: string
	statuses?: string[]
	vendors?: string[]
	orgs?: string[]
	accountId?: string | null
	customerId?: string | null
	requestType: "json" | "csv"
	include?: "customer,org" | "" | "org"
	sort: string
}

interface CheckDepositGetParam {
	accessToken: string
	id: string
	include?: "customer,org" | ""
}

interface CheckDepositImageGetParam {
	accessToken: string
	id: string
	side: CheckImageSide
}

interface CheckDepositRejectParam {
	accessToken: string
	id: string
	reason: string
	reasonText?: string
}

interface CheckDepositReturnParam {
	accessToken: string
	id: string
	reason: string
}

interface CheckDepositApproveParam {
	accessToken: string
	id: string
}

export async function findCheckDepositRequest(requestParam: CheckDepositFindParam) {
	const query = {
		page: {
			limit: requestParam.limit,
			offset: requestParam.offset,
		},
		filter: {
			query: requestParam.searchQuery != "" ? requestParam.searchQuery : null,
			since: requestParam.since != "" ? requestParam.since : null,
			until: requestParam.until != "" ? requestParam.until : null,
			accountId: requestParam.accountId != "" ? requestParam.accountId : null,
			customerId: requestParam.customerId != "" ? requestParam.customerId : null,
			status: requestParam.statuses && requestParam.statuses.length > 0 ? requestParam.statuses : null,
			vendors: requestParam.vendors && requestParam.vendors.length > 0 ? requestParam.vendors : null,
			orgs: requestParam.orgs && requestParam.orgs.length > 0 ? requestParam.orgs : null,
		},
		sort: requestParam.sort,
		include: requestParam.include != "" ? requestParam.include : null,
	}

	switch (requestParam.requestType) {
		case "csv":
			return await getFile("check-deposits", requestParam.accessToken, query)
		default:
			return await getResource<Array<CheckDeposit>>("check-deposits", requestParam.accessToken, query)
	}
}

export async function getCheckDepositRequest(requestParam: CheckDepositGetParam) {
	const query = {
		include: "customer,org,user,account",
	}

	return await getResource<CheckDeposit>(`check-deposits/${requestParam.id}`, requestParam.accessToken, query)
}

export async function getCheckDepositImage(requestParam: CheckDepositImageGetParam) {
	switch (requestParam.side) {
		case CheckImageSide.Front:
			return await getResourceAsArrayBuffer(`check-deposits/${requestParam.id}/front`, requestParam.accessToken)
		default:
			return await getResourceAsArrayBuffer(`check-deposits/${requestParam.id}/back`, requestParam.accessToken)
	}
}

export async function rejectCheckDeposit({id, accessToken, reason, reasonText}: CheckDepositRejectParam) {
	const data = {
		type: "checkDepositReject",
		attributes: {
			reason,
			reasonText,
		},
	}

	return await createResource<CheckDeposit>(`check-deposits/${id}/reject`, accessToken, data)
}

export async function returnCheckDeposit({id, accessToken, reason}: CheckDepositReturnParam) {
	const data = {
		type: "checkDepositReturn",
		attributes: {
			reason,
		},
	}

	return await createResource<CheckDeposit>(`check-deposits/${id}/return`, accessToken, data)
}

export async function approveCheckDeposit({id, accessToken}: CheckDepositApproveParam) {
	return await createResource<CheckDeposit>(`check-deposits/${id}/approve`, accessToken, {})
}
