import React, {ReactElement} from "react"
import {useIsAuthenticated} from "../../services/auth"
import {useLocalStorage} from "react-use"
import {useLocation} from "react-router-dom"
import {Redirect} from "react-router6-redirect"

export function AuthenticatedRoute({children}: {children: ReactElement}): ReactElement {
	const isAuthenticated = useIsAuthenticated()
	const [workspaceName] = useLocalStorage<string | undefined>("workspaceName", undefined)
	const location = useLocation()

	return isAuthenticated ? children : <Redirect to={workspaceName ? "/sso" : "/login"} state={{from: location}} />
}
