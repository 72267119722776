import {useAccessToken} from "../../services/auth"
import React, {useEffect, useState} from "react"
import {DnsSetting, getClientDomainConfig, OrgSettings, VerifyDomain} from "../../resources/orgSettings"
import {useAsyncResult} from "../../hooks/useAsyncResult"
import {ErrorDocument} from "../../resources/common"
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {useRefreshToken} from "../../hooks/useRefreshToken"

function ClientDomainConfigInternal({
	dnsSettings,
	refresh,
	orgSettings,
}: {
	dnsSettings: Array<DnsSetting>
	refresh: () => void
	orgSettings: OrgSettings
}) {
	const accessToken = useAccessToken()
	const status = orgSettings.attributes.applicationFormDomainSettings.applicationFormCertificateStatus
	const [isLoading, setIsLoading] = useState(false)
	const [verifyDomainState, verifyDomain] = useAsyncResultIdle(VerifyDomain)

	useEffect(() => {
		verifyDomainState.match(
			() => setIsLoading(false),
			() => setIsLoading(true),
			() => {
				setIsLoading(false)
				refresh()
			},
			() => setIsLoading(false)
		)
	}, [verifyDomainState])

	return (
		<div className="field">
			<div className="box">
				<span className="has-text-weight-bold">
					DNS Settings -{" "}
					<span className={status == "Verified" ? "has-text-success" : "has-text-warning"}>
						{orgSettings.attributes.applicationFormDomainSettings.applicationFormCertificateStatus}
					</span>
				</span>
				<p className="mt-2 mb-2">
					Please config these records in your DNS provider within 72 hours to verify the domain ownership.
				</p>
				<table className="table is-fullwidth">
					<thead>
						<tr>
							<td>Record Name</td>
							<td>Type</td>
							<td>Record Name</td>
						</tr>
					</thead>
					<tbody>
						{dnsSettings.map((d) => (
							<tr key={d.attributes.key}>
								<td>{d.attributes.key}</td>
								<td>CNAME</td>
								<td>{d.attributes.value}</td>
							</tr>
						))}
					</tbody>
				</table>
				{status == "Unverified" ? (
					<button
						className="button is-success"
						disabled={isLoading}
						onClick={(e) => {
							e.preventDefault()
							setIsLoading(true)
							verifyDomain(accessToken)
						}}
					>
						{isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Verify"}
					</button>
				) : null}
				{verifyDomainState.isErr() ? (
					<p className="has-text-danger mt-2">
						{" "}
						{verifyDomainState.error.errors[0].title} - {verifyDomainState.error.errors[0].detail}{" "}
					</p>
				) : null}
			</div>
		</div>
	)
}

function ClientDomainConfigError({retry}: {retry: () => void}) {
	return (
		<div className="field">
			<div className="box">
				<span className="has-text-weight-bold">
					DNS Settings - <span className="has-text-warning">Failed to retrieve data</span>
				</span>
				<p className="mt-2 mb-2">Please try again</p>

				<button
					type="button"
					className="button is-success"
					onClick={(e) => {
						e.preventDefault()
						retry()
					}}
				>
					Retry
				</button>
			</div>
		</div>
	)
}

function ClientDomainConfigPending() {
	return (
		<div className="field">
			<div className="box">
				<span className="has-text-weight-bold">
					DNS Settings -{" "}
					<span>
						<FontAwesomeIcon className="card-icon" icon={faCircleNotch} spin />
					</span>
				</span>
			</div>
		</div>
	)
}

export default function ClientDomainConfig({refresh, orgSettings}: {orgSettings: OrgSettings; refresh: () => void}) {
	if (!orgSettings.attributes.applicationFormDomainSettings.applicationFormDomain) {
		return null
	}

	const accessToken = useAccessToken()

	const [retryToken, retry] = useRefreshToken()
	const dnsSettingsAsync = useAsyncResult<Array<DnsSetting>, ErrorDocument>(
		() => getClientDomainConfig(accessToken),
		[retryToken]
	)

	return dnsSettingsAsync.match(
		() => <ClientDomainConfigPending />,
		(dnsSettings) => (
			<ClientDomainConfigInternal refresh={refresh} dnsSettings={dnsSettings} orgSettings={orgSettings} />
		),
		(_) => <ClientDomainConfigError retry={retry} />
	)
}
