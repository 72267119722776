import React, {useState} from "react"
import Card from "../../../containers/Card/Card"
import DepositProductField from "./DepositProductField/DepositProductField"
import {CardSettingsProps, DepositProductCardSettingsProps} from "./types"
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch"
import {EditMode} from "../../../resources/common"
import classNames from "classnames"
import {LimitGroup, LimitGroupType, TimeoutStrategy, TimeoutStrategyType} from "../../../resources/depositProduct"

function CardTitle({title, toggleText, checked, onChange}: CardSettingsProps) {
	return (
		<div className="card-settings">
			<span className="card-title" id="CardSettings">
				{title}
			</span>
			<ToggleSwitch text={toggleText} checked={checked} onChange={onChange} />
		</div>
	)
}

export default function CardSettings({
	editMode,
	values,
	onChange,
	setFieldValue,
	onBlur,
	bins,
}: DepositProductCardSettingsProps) {
	const [cardEnabled, setCardEnabled] = useState(false)

	const cardEnabledHandler = () => {
		setCardEnabled((prevCardEnabled) => !prevCardEnabled)
	}

	const getCardTitle = () => {
		if (editMode === EditMode.VIEW) {
			return (
				<span className="card-title" id="CardSettings">
					Card Settings
				</span>
			)
		}
		return CardTitle({
			title: "Card Settings",
			toggleText: "Card Enabled",
			checked: cardEnabled,
			onChange: cardEnabledHandler,
		})
	}

	const _onChange = (value: any, name: string) => {
		if (value.target.value !== "on") {
			setFieldValue(name, value.target.value)
		} else {
			onChange(value)
			if (!value.target.checked) {
				setFieldValue(name, undefined)
			}
		}
	}

	return (
		<Card className="deposit-product-card-title" title={getCardTitle()}>
			{editMode !== EditMode.VIEW && (
				<>
					<div className="sub-title">General</div>
					<div className={classNames("form-row four")}>
						<DepositProductField
							mode={editMode}
							type="select"
							name="individualBinId"
							label="Individual Bin"
							value={values.individualBinId}
							onChange={onChange}
							onBlur={onBlur}
							disabled={!cardEnabled}
						>
							<option key="default" defaultValue="" />
							{bins
								?.filter(
									(b) =>
										b.relationships.bank.data.id == values.bankId &&
										(b.relationships.org.data.id == values.orgId || b.relationships.org.data.id == "1")
								)
								.map((b) => (
									<option key={b.id} value={b.id.toString()}>
										{b.attributes.name}
									</option>
								))}
						</DepositProductField>
						<DepositProductField
							mode={editMode}
							type="select"
							name="businessBinId"
							label="Business BIN"
							value={values.businessBinId}
							onChange={onChange}
							onBlur={onBlur}
							disabled={!cardEnabled}
						>
							<option key="default" defaultValue="" />
							{bins
								.filter(
									(b) =>
										b.relationships.bank.data.id == values.bankId &&
										(b.relationships.org.data.id == values.orgId || b.relationships.org.data.id == "1")
								)
								.map((b) => (
									<option key={b.id} value={b.id.toString()}>
										{b.attributes.name}
									</option>
								))}
						</DepositProductField>
						<DepositProductField
							mode={editMode}
							type="input"
							name="cardQualifier"
							label="Card Qualifier"
							value={values.cardQualifier ?? ""}
							onChange={onChange}
							onBlur={onBlur}
							disabled={!cardEnabled}
						/>
						<DepositProductField
							mode={editMode}
							type="select"
							name="limitGroup"
							label="Limit Group"
							value={values.limitGroup ?? ""}
							onChange={onChange}
							onBlur={onBlur}
							disabled={!cardEnabled}
						>
							{<option key={1} defaultValue=""></option>}
							{Object.keys(LimitGroup).map((o) => (
								<option key={o} value={o}>
									{LimitGroup[o as LimitGroupType]}
								</option>
							))}
						</DepositProductField>
					</div>
				</>
			)}
			<div className="sub-title">Number of cards</div>
			<div className={classNames("form-row four", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="input"
					name="maxNumberOfPhysicalCards"
					label="Physical cards"
					value={values.maxNumberOfPhysicalCards ?? ""}
					inputPrefix="up to"
					onChange={onChange}
					onBlur={onBlur}
					inputType="number"
					disabled={!cardEnabled}
				/>
				<DepositProductField
					mode={editMode}
					type="input"
					name="maxNumberOfVirtualCards"
					label="Virtual cards"
					value={values.maxNumberOfVirtualCards ?? ""}
					inputPrefix="up to"
					onChange={onChange}
					onBlur={onBlur}
					inputType="number"
					disabled={!cardEnabled}
				/>
			</div>
			<a
				className="sub-title link"
				target="_blank"
				rel="noreferrer"
				href="https://docs.unit.co/cards-authorization-requests/#programmatic-authorization-of-card-use"
			>
				Programatic Autorizations
			</a>
			<div className={classNames("form-row four checkbox", editMode === EditMode.VIEW && "view")}>
				<DepositProductField
					mode={editMode}
					type="checkbox"
					inputType="select"
					name="programmaticPurchaseAuthorizationEnabled"
					subName="programmaticPurchaseAuthorizationTimeoutStrategy"
					label="Card Purchases"
					subLabel="On timeout"
					checked={values.programmaticPurchaseAuthorizationEnabled}
					value={values.programmaticPurchaseAuthorizationEnabled}
					subValue={values.programmaticPurchaseAuthorizationTimeoutStrategy ?? ""}
					onChange={(value) => _onChange(value, "programmaticPurchaseAuthorizationTimeoutStrategy")}
					onBlur={onBlur}
					disabled={!cardEnabled}
				>
					{<option key={1} defaultValue="" />}
					{Object.keys(TimeoutStrategy).map((o) => (
						<option key={o} value={o}>
							{TimeoutStrategy[o as TimeoutStrategyType]}
						</option>
					))}
				</DepositProductField>
				<DepositProductField
					mode={editMode}
					type="checkbox"
					inputType="select"
					name="programmaticCardTransactionAuthorizationEnabled"
					subName="programmaticCardTransactionAuthorizationTimeoutStrategy"
					label="Card Transactions"
					subLabel="On timeout"
					checked={values.programmaticCardTransactionAuthorizationEnabled}
					value={values.programmaticCardTransactionAuthorizationEnabled}
					subValue={values.programmaticCardTransactionAuthorizationTimeoutStrategy ?? ""}
					onChange={(value) => _onChange(value, "programmaticCardTransactionAuthorizationTimeoutStrategy")}
					onBlur={onBlur}
					disabled={!cardEnabled}
				>
					{<option key={1} defaultValue="" />}
					{Object.keys(TimeoutStrategy).map((o) => (
						<option key={o} value={o}>
							{TimeoutStrategy[o as TimeoutStrategyType]}
						</option>
					))}
				</DepositProductField>
				<DepositProductField
					mode={editMode}
					type="checkbox"
					inputType="select"
					name="programmaticAtmAuthorizationEnabled"
					subName="programmaticAtmAuthorizationTimeoutStrategy"
					label="ATM activity"
					subLabel="On timeout"
					checked={values.programmaticAtmAuthorizationEnabled}
					value={values.programmaticAtmAuthorizationEnabled}
					subValue={values.programmaticAtmAuthorizationTimeoutStrategy ?? ""}
					onChange={(value) => _onChange(value, "programmaticAtmAuthorizationTimeoutStrategy")}
					onBlur={onBlur}
					disabled={!cardEnabled}
				>
					{<option key={1} defaultValue="" />}
					{Object.keys(TimeoutStrategy).map((o) => (
						<option key={o} value={o}>
							{TimeoutStrategy[o as TimeoutStrategyType]}
						</option>
					))}
				</DepositProductField>
			</div>
		</Card>
	)
}
