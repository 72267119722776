import {useState} from "react"
import {ApplicationDocumentSide} from "../resources/application"

type RotationDegrees = 0 | 90 | 180 | 270

export function useToolbar(side: ApplicationDocumentSide) {
	const [degree, setDegree] = useState<RotationDegrees>(0)
	const [zoom, setZoom] = useState(1)
	const [docActiveSide, setDocActiveSide] = useState(side)

	const changeRotation = () => {
		switch (degree) {
			case 0:
				setDegree(90)
				break
			case 90:
				setDegree(180)
				break
			case 180:
				setDegree(270)
				break
			default:
				setDegree(0)
				break
		}
	}

	const toggleActiveSide = () => {
		docActiveSide === ApplicationDocumentSide.Front
			? setDocActiveSide(ApplicationDocumentSide.Back)
			: setDocActiveSide(ApplicationDocumentSide.Front)

		setDegree(0)
	}

	const zoomIn = () => setZoom(zoom + 0.25)
	const zoomOut = () => setZoom(zoom - 0.25)

	return {
		zoom: {
			val: zoom,
			zoomIn,
			zoomOut,
		},
		rotation: {
			val: degree,
			changeRotation,
		},
		activeDocumentSide: {
			val: docActiveSide,
			toggleActiveSide,
		},
	}
}
