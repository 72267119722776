import {useEffect, useState, useRef} from "react"

export function useHeadsObserver(querySelector: string, rootMargin = "-20% 0px -35% 0px") {
	const observer = useRef<any>(null)
	const [activeId, setActiveId] = useState("")

	useEffect(() => {
		const handleObsever = (entries: any) => {
			for (const entry of entries) {
				if (entry?.isIntersecting) {
					setActiveId(entry.target.id)
					break
				}
			}
		}

		observer.current = new IntersectionObserver(handleObsever, {
			rootMargin,
		})

		const elements = document.querySelectorAll(querySelector)
		elements.forEach((elem) => observer.current.observe(elem))
		return () => observer.current?.disconnect()
	}, [])

	return {activeId, setActiveId}
}
